var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"cdm-report-confirmation-modal\" tabindex=\"-1\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <br/>\n        <div class=\"text-center\">\n          <img src=\"images/cdm/gdpr-certified-white.svg\" />\n        </div>\n        <br/>\n        <h1 class=\"text-center\">Data Usage Report</h1>\n        <br/><br/>\n        <p class=\"text-center\">Generate a PDF report of all processing history for "
    + alias4(((helper = (helper = lookupProperty(helpers,"customerName") || (depth0 != null ? lookupProperty(depth0,"customerName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customerName","hash":{},"data":data,"loc":{"start":{"line":12,"column":83},"end":{"line":12,"column":99}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"salesforceId") || (depth0 != null ? lookupProperty(depth0,"salesforceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"salesforceId","hash":{},"data":data,"loc":{"start":{"line":12,"column":101},"end":{"line":12,"column":117}}}) : helper)))
    + ") to be used as a historical audit of all call and message data collected as of "
    + alias4(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":12,"column":197},"end":{"line":12,"column":205}}}) : helper)))
    + "</p>\n        <br/><br/>\n        <div class=\"text-center\">\n          <button type=\"button\" class=\"btn btn-info generate-report\">Generate Report</button>\n        </div>\n        <br/>\n        <div class=\"text-center\">\n          <a data-dismiss=\"modal\">Cancel</a>\n        </div>\n        <br/>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});