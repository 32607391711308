import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceCampaignIcon({ size = 16, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.667c-.734 0-1.333.6-1.333 1.333S7.267 9.333 8 9.333 9.333 8.733 9.333 8 8.733 6.667 8 6.667zm0 4A2.674 2.674 0 015.333 8c0-1.466 1.2-2.666 2.667-2.666 1.467 0 2.667 1.2 2.667 2.666S9.467 10.666 8 10.666zm0-7.333A4.68 4.68 0 003.333 8 4.68 4.68 0 008 12.666 4.68 4.68 0 0012.667 8 4.68 4.68 0 008 3.334zm0 10.665c-3.3 0-6-2.7-6-6C2 4.7 4.7 2 8 2s6 2.701 6 6c0 3.3-2.7 6-6 6zM8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
        fill={color || Colors.SALESFORCE_CAMPAIGN}
      />
    </svg>
  )
}

export default SalesforceCampaignIcon
