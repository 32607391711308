class QueryString

  constructor: (delimiter = '&', url = '') ->
    @delimiter = delimiter
    @bindings = {}
    @parse(url)

  bind: (bindings) =>
    _.extend(@bindings, bindings)

  get: (key) =>
    @params[key]

  getAllParams: =>
    @params

  # Due to a bug Backbone fails with regularly encoded query string values, so we double encode to get around the issue.
  decode: (val) ->
    decodeURIComponent(decodeURIComponent(val))

  encode: (val) ->
    encodeURIComponent(encodeURIComponent(val))

  navigate: (fragment = @fragment) =>
    params = []
    for name, element of @bindings
      value = element.val()
      console.info(name, element, value)

      if Array.isArray(value)
        params.push("#{name}=#{@encode(value)}") if value.length > 0
      else
        params.push("#{name}=#{@encode(value)}") if value

    for name, value of @params
      params.push("#{name}=#{@encode(value)}") if value

    location = fragment
    if params.length > 0
      location += '?' + params.join(@delimiter)

    App.navigate(location)

  parse: (qs = '') =>
    qs = qs.substring(1) if (qs[0] is '#' or qs[0] is '?')

    @params = _
      .chain(qs.split(@delimiter))
      .map((item) =>
        if item
          [key, val] = item.split('=')
          [key, @decode(val)]
      )
      .compact()
      .object()
      .value()

  restore: =>
    for name, el of @bindings
      val = @params[name]
      if val
        if el.is?('select') and el.attr('multiple')
          @restoreMultiSelect(el, val)
        else
          el.val(val)

  restoreMultiSelect: (el, values) ->
    values.split(',').forEach((val) ->
      options = el.find("option[value='#{val}']")
      if options.length is 0
        el.append("<option value='#{val}' selected>#{val}</option>")
      else
        options.attr('selected', true)
    )

  setFragment: (fragment) =>
    @fragment = fragment

module.exports = QueryString
