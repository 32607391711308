BaseView = require('base/view')

class ErrorView extends BaseView
  template: require('./template')

  bindings:
    '.description': 'html:msg'

  events:
    'click button[data-action="support"]': 'support'
    'click a[data-action="logout"]': 'logout'

  initialize: (options) ->
    return unless options
    message = options.msg
    if message.includes('API_CURRENTLY_DISABLED')
      link = 'https://support.revenue.io/hc/en-us/articles/8990078390427-API-access-is-disabled-for-this-User'
      message = "API Access is disabled for your user.
                  Contact your Salesforce Administrator for assistance.
                  Click <a href=#{link} target='_blank'>here</a> for more info."
    @model = new Backbone.Model(msg: message)

  support: ->
    window.open('https://support.revenue.io/hc/en-us/requests/new', '_blank')

  logout: ->
    @trigger('logout')

module.exports = ErrorView
