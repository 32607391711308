import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// utils
import { useAccount } from '../../../../store/api/accounts-api'
import { useUser } from '../../../common/services/UserContext'
import {
  hasContinentRecordingSettingsChangedSelector,
  isCallRecordingByCountryOnSelector,
  isCallRecordingDisabledSelector,
  useCallRecordingDispatch,
  useCallRecordingState
} from '../../utils/contextReducer'

// components
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import { ToggleRowContainer } from '../styles'
import InternationalCards from './InternationalCards'
import SaveAllSettingsButton from '../SaveAllSettingsButton'
import { useUpdateAccountSettings } from '../../../../store/api/call-recording-api'
import useToast from '../../../common/hooks/useToast'
import InternationalConfirmModal from './InternationalConfirmModal'

export const INT_CALL_RECORDING_TEXT = `To record calls to and from international countries, please enable International Call Recording controls. When enabled, you can designate the type of recordings generated and countries requiring an outbound call recording announcement to be played. When International call recordings are disabled, ringDNA will not generate any recordings for calls to and from international countries.`

type InternationalSubTabProps = {
  accountId: string
}

export default React.memo(InternationalSubTab)
function InternationalSubTab({ accountId }: InternationalSubTabProps) {
  const toastr = useToast(false)
  useAccount({ meta: { id: accountId } })
  const { accountId: userAccountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && userAccountId.toString() === accountId.toString()
  const state = useCallRecordingState()
  const dispatch = useCallRecordingDispatch()
  const isOn = isCallRecordingByCountryOnSelector(state)
  const hasContinentRecordingSettingsChanged = hasContinentRecordingSettingsChangedSelector(state)
  const [showModal, setShowModal] = useState(false)
  const [isChecked, setIsChecked] = useState(isOn)
  const updateGranularControls = useUpdateAccountSettings()

  const cancelModal = () => {
    setShowModal(false)
    setIsChecked(false)
  }

  const dispatchGranualarControls = async (value: boolean) => {
    dispatch({ type: 'CHANGE_ACCOUNT_SETTINGS', payload: { 'settings.callRecordingByCountry': value } })
    try {
      await updateGranularControls({
        json: { callRecordingByCountry: value },
        accountId: accountId
      })
      toastr.success('Call Recording Settings Saved')
      dispatch({ type: 'UPDATE_ORIGINAL_SETTINGS' })
    } catch (error) {
      toastr.error(JSON.parse(error.fail.message).message)
    }
  }

  const confirmAgreement = () => {
    setShowModal(false)
    dispatchGranualarControls(true)
  }

  const toggleIntGranularControls = (value: boolean) => {
    setIsChecked(value)
    if (value) {
      setShowModal(true)
    } else {
      dispatchGranualarControls(false)
    }

    return null
  }

  useEffect(() => {
    if (!isOn && hasContinentRecordingSettingsChanged) {
      toastr.warning('Any changes made to the call recording settings have been reset')
      dispatch({ type: 'RESET_TO_ORIGINAL_SETTINGS', payload: 'continentRecordingSettings' })
    }
  }, [isOn, hasContinentRecordingSettingsChanged, dispatch, toastr])

  return (
    <Container>
      <ToggleRowContainer $align={'space-between'}>
        <RdnaText variant="body2">{INT_CALL_RECORDING_TEXT}</RdnaText>
        <InternationalConfirmModal open={showModal} onClose={cancelModal} onConfirm={confirmAgreement} />
        <RdnaToggleSwitch
          disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          value={isChecked}
          onChange={toggleIntGranularControls}
        />
      </ToggleRowContainer>
      {isOn && <InternationalCards accountId={accountId} />}
      <SaveAllSettingsButton />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing * 2}px 0;
  grid-template-columns: 1fr min-content;
  align-items: center;

  div {
    grid-column: span 2;
  }
`
