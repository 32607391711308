BaseModel = require('base/model')
BaseView = require('base/view')
BaseCollection  = require('base/collection')
AutoDispositionVoicemailDropsView = require('./auto-disposition-voicemail-drops/view')
CallDispositionCollection = require('./call-dispositions/collection')
CentralNumbersView = require('./central-numbers/view')
CallScriptsView = require('./call-scripts/view')
VoicemailDropsView = require('./voicemail-drops/view')
TaskNoteSubjectsView = require('./task-subjects/view')
CallCustomLinksView = require('./call-custom-links/view')
CallNoteTemplatesView = require('./call-note-templates/view')
ConfirmCallDispositionModal = require('./call-dispositions/modals/confirm-view')
CallDispositionsView = require('./call-dispositions/view')
CallTasksView = require('./call-tasks/view')
FilePickerView = require('widgets/file-picker/view')
CountriesView = require('./countries/view')
GranularControlSettingCollection = require('./collection')
WebLeadsView = require('./web-leads/view')
ComplianceHoursView = require('./compliance-hours/view')
CustomUserStatusView = require('./user-status/view')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')
LockedSettingView = require('widgets/locked-setting/view')
LockedSettingModel = require('widgets/locked-setting/model')

class AudioFileCollection extends BaseCollection
  setParams: (options) ->
    {@accountId} = options
  url: -> "accounts/#{@accountId}/audio"
  toJSON: =>
    fields = @toPlayJSON('files')
    fields

class ImportDispositionsModel extends BaseModel
  url: 'callDispositions/import'

class IntelligentDialerView extends BaseView
  className: 'intelligent-dialer'

  regions:
    autoDispositionVoicemailDrops  : '.auto-disposition-voicemail-drops-container'
    callScripts             : '.call-scripts-container'
    voicemailDrops          : '.voicemail-drops-container'
    taskNoteSubjects        : '.task-note-subjects-container'
    eventSubjects           : '.event-subjects-container'
    callDispositions        : '.call-dispositions-container'
    centralNumbers          : '.central-numbers-container'
    modal                   : 'div.modal-container'
    callCustomLinks         : '.call-custom-links-container'
    callNoteTemplates       : '.call-note-templates-container'
    callTasks               : '.call-tasks-container'
    voicemailTranscriptions : '.voicemail-transcriptions-container'
    countries               : '.voicemail-transcriptions-by-country-container'
    webLeads                : '.web-leads-container'
    complianceHours         : '.compliance-hours-container'
    customUserStatus        : '.custom-user-status-container'
    callForwardingLocked    : '.callForwardingLocked'
    callForwardingTimeoutLocked: '.callForwardingTimeoutLocked'
    callForwardingPromptLocked: '.callForwardingPromptLocked'
    callForwardingDisabledOfflineLocked: '.callForwardingDisabledOfflineLocked'
    webLeadsLocked: '.webLeadsEnabledLocked'
    holdWaitMusic: '.wait-music-for-hold-container'

  bindings:
    '.callDispositionPrompt'          : 'checked:callDispositionPrompt'
    '.callDispositionRequiredDate'    : 'toggle:callDispositionRequiredDate,text:callDispositionRequiredDateText'
    '.callDispositionRequiredState'   : 'value:callDispositionRequiredState'
    '.manageCallDispositionRequiredByTeam' : 'checked:manageCallDispositionRequiredByTeam'
    '.multiMatchRequiredDate'         : 'toggle:multiMatchRequired,text:multiMatchRequiredDateText'
    '.callRating'                     : 'checked:CallRating'
    '.chatterBox'                     : 'checked:Chatter'
    '.generalSettings'                : 'checked:GeneralSettings'
    '.callToolsMenu'                  : 'checked:CallToolsMenu'
    '.leadStatus'                     : 'checked:LeadStatus'
    '.manageCallDispositions'         : 'checked:manageCallDispositions'
    '.callNoteTemplatesEnabled'       : 'checked:callNoteTemplates'
    '.localPresenceUseVerifiedNumbers': 'checked:localPresenceUseVerifiedNumbers'
    '.appendTransferInfo'             : 'checked:appendTransferInfo'
    '.voicemailTranscriptionEnabled'  : 'checked:voicemailTranscriptionEnabled'
    '.voiceTranscriptionByCountry'    : 'checked:voicemailTranscriptionByCountry,enabled:voicemailTranscriptionEnabled'
    '.voicemail-transcriptions-by-country-container'  : 'toggle:all(voicemailTranscriptionEnabled,showTranscriptionsByCountry)'
    '.sfdcMultiMatchSetting'          : 'checked:multiMatchSettingValue'
    '.voicemailEnabled'               : 'checked:voicemailEnabled'
    '.voicemailIncludeLink'           : 'checked:voicemailIncludeLink'
    '.voicemailNotifyQueues'          : 'checked:voicemailNotifyQueues'
    '.voicemailTimeout'               : 'value:voicemailTimeout'
    '.voicemailTranscriptionBlacklist': 'value:voicemailTranscriptionBlacklist'
    '.callNotification'               : 'checked:callNotification'
    '.continuousChime'                : 'checked:continuousChime'
    '.callForwarding'                 : 'checked:callForwarding'
    '.callForwardingPrompt'           : 'checked:callForwardingPrompt'
    '.callForwardingTimeout'          : 'value:callForwardingTimeout'
    '.callForwardingDisabledOffline'  : 'checked:callForwardingDisabledOffline'
    '.callerId'                       : 'checked:callerIdEnabled'
    '.callTimeout'                    : 'value:callTimeout'
    '.maxHoldTime'                    : 'value:maxHoldTime'
    '.advancedSearch'                 : 'checked:advancedSearch'
    '.clickToVoicemail'               : 'checked:clickToVoicemail'
    '.clickToCallDomainBlacklist'     : 'value:clickToCallDomainBlacklist,classes:{"has-error":clickToCallDomainBlacklistError}'
    '.continuousBridge'               : 'checked:continuousBridge'
    '.continuousBridgeTimeout'        : 'value:continuousBridgeTimeout'
    '.emailSendViaSalesforce'         : 'checked:emailSendViaSalesforce'
    '.lightningEmail'                 : 'classes:{"disabled": not (emailSendViaSalesforce)}'
    '.lightningEmailCheckbox'         : 'checked:lightningEmailTemplates'
    '.sfdcHonorDoNotCallOrEmail'      : 'checked:sfdcHonorDoNotCallOrEmail'
    '.sfdcCreateLeadOnMultiMatch'     : 'checked:sfdcCreateLeadOnMultiMatch'
    '.sfdcCreateLeadOnSFDCCampaign'   : 'checked:sfdcCreateLeadOnSFDCCampaign'
    '.sfdcCreateLeadOnUnansweredCall' : 'checked:sfdcCreateLeadOnUnansweredCall'
    '.sfdcCreateLeadSection'          : 'classes:{"disabled": not (sfdcCreateLeadOnMultiMatch)}'
    '.sfdcDefaultMatchTypeOption'     : 'value: sfdcDefaultMatchType'
    '.sfdcMatchAccounts'              : 'checked:sfdcMatchAccounts'
    '.sfdcMatchContacts'              : 'checked:sfdcMatchContacts'
    '.sfdcMatchLeads'                 : 'checked:sfdcMatchLeads'
    '.sfdcMatchOnExternal'            : 'checked:sfdcMatchOnExternal'
    '.sfdcMatchOpportunities'         : 'checked:sfdcMatchOpportunities'
    '.sfdcClickToCallReuseTask'       : 'checked:sfdcClickToCallReuseTask'
    '.renewTaskSubjectSection'        : 'classes:{"disabled": not (sfdcClickToCallReuseTask)}'
    '.sfdcClickToCallTaskSubjectState': 'value:sfdcClickToCallTaskSubjectState'
    '.call-actions-react'             : 'toggle:not(callActionsReact)'

  events:
    'click .delete-call-tone' : 'deleteCallTone'
    'change .manageCallDispositions' : 'manageCallDispositions'
    'change .manageCallDispositionRequiredByTeam' : 'manageCallDispositionRequiredByTeam'
    'change .callNoteTemplatesEnabled' : 'manageСallNoteTemplates'
    'change .voicemailTranscriptionEnabled' : 'enableTranscriptionsByCountry'

  computeds:
    showTranscriptionsByCountry:
      deps: ['voicemailTranscriptionByCountry']
      get: (voicemailTranscriptionByCountry) ->
        if voicemailTranscriptionByCountry
          @showCountryTable()
          true
        else
          false

    callDispositionRequiredDateText:
      deps: ['callDispositionRequiredDate']
      get: (callDispositionRequiredDate) ->
        'as of ' + moment(callDispositionRequiredDate).format('MM/DD/YYYY hh:mm a')
    multiMatchRequiredDateText:
      deps: ['multiMatchRequiredDate']
      get: (multiMatchRequiredDate) ->
        'as of ' + moment(multiMatchRequiredDate).format('MM/DD/YYYY hh:mm a')
    multiMatchRequired:
      deps: ['sfdcMultiMatchSetting']
      get: (sfdcMultiMatchSetting) ->
        sfdcMultiMatchSetting and 'None' isnt sfdcMultiMatchSetting
    multiMatchSettingValue:
      deps: ['sfdcMultiMatchSetting']
      get: (sfdcMultiMatchSetting) ->
        sfdcMultiMatchSetting
      set: (value) ->
        @model.set('sfdcMultiMatchSetting', value)
        if value and 'None' isnt value
          @model.set('multiMatchRequiredDate', moment().format('YYYY-MM-DD HH:mm:ss'))
        else
          @model.set('multiMatchRequiredDate', null)
    callActionsReact:
      get: ->
        App.featureFlag.isFlagged(App.featureFlag.flags.CALL_ACTIONS_REACT, false)

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  onAttach: =>
    $('input[type=text], select').addClass('form-control input-sm')

    $('input[type=checkbox]:not(.basic-checkbox)').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  initialize: (options) =>
    { @model, @accountId } = options
    @countryCollection = new GranularControlSettingCollection(isUs: false)
    @listenTo(@model, 'change:callDispositionRequiredState', =>
      if @model.get('callDispositionRequiredState') is 'None'
        @model.set('callDispositionRequiredDate', null)
      else
        @model.set('callDispositionRequiredDate', moment().format('YYYY-MM-DD HH:mm:ss'))
    )
    @callDispositionsCollection = new CallDispositionCollection()

    @viewModel = new Backbone.Model(
      clickToCallDomainBlacklistError: false
    )

  onRender: =>
    @listenTo(@model, 'change:callForwarding', (model) ->
      @model.set('webRTCEnabled', true) if not model.get('callForwarding')
    )
    @showChildView('callScripts', new CallScriptsView(accountId: @accountId)) unless @getBinding('callActionsReact')
    @showChildView('voicemailDrops', new VoicemailDropsView(accountId: @accountId))
    @showChildView('taskNoteSubjects', new TaskNoteSubjectsView(accountId: @accountId, eventView: false))
    @showChildView('eventSubjects', new TaskNoteSubjectsView(accountId: @accountId, eventView: true))

    @callDispositionsCollection.fetch(data: accountId: @accountId).done(=>
      @showChildView('autoDispositionVoicemailDrops', new AutoDispositionVoicemailDropsView(
        accountSettings: @model
        dispositions: @callDispositionsCollection
      ))

      if @model.get('manageCallDispositions')
        @showCallDispositions(true)
    ) unless @getBinding('callActionsReact')
    @listenTo(@callDispositionsCollection, 'reloadCallDispositions', (cb) =>
      @callDispositionsCollection.fetch(data: accountId: @accountId).done( => cb(true))
    )

    @holdWaitMusicView = new FilePickerView(src: @model.get('waitUrlForHoldMusic'), accept: '.mp3')
    @showChildView('holdWaitMusic', @holdWaitMusicView)
    @listenTo(@holdWaitMusicView, 'deleteAudio', => @model.set('waitUrlForHoldMusic', null))

    @showChildView('centralNumbers', new CentralNumbersView(accountId: @model.id))
    @webLeadsView = new WebLeadsView(accountId: @accountId, accountSettings: @model, teams: new BaseCollection(@model.get('teams')), leadFields: @model.get('leadFields'))
    @showChildView('webLeads', @webLeadsView)
    complianceHoursView = new ComplianceHoursView(accountId: @accountId, complianceHours: @model.get('complianceHours'))
    @showChildView('complianceHours', complianceHoursView)
    @listenTo(complianceHoursView, 'complianceHoursChange', (value) => @model.set('complianceHours', value))
    if @user().isSupport() or @model.get('customUserStatusEnabled')
      customUserStatusView = new CustomUserStatusView(
        accountId: @accountId
        customUserStatusEnabled: @model.get('customUserStatusEnabled')
        customUserStatusAutoSwitch: @model.get('customUserStatusAutoSwitch')
        customUserStatusTimeOverride: @model.get('customUserStatusTimeOverride')
        teams: new BaseCollection(@model.get('teams')))
      @showChildView('customUserStatus', customUserStatusView)
      @listenTo(customUserStatusView, 'customUserStatusEnabledChange', (value) => @model.set('customUserStatusEnabled', value))
      @listenTo(customUserStatusView, 'customUserStatusAutoSwitchChange', (value) => @model.set('customUserStatusAutoSwitch', value))
      @listenTo(customUserStatusView, 'customUserStatusTimeOverrideChange', (value) => @model.set('customUserStatusTimeOverride', value))

    @showChildView('callCustomLinks', new CallCustomLinksView(accountId: @accountId)) unless @getBinding('callActionsReact')
    if @model.get('callNoteTemplates') and not @getBinding('callActionsReact')
      @callNoteTemplatesView = new CallNoteTemplatesView(accountId: @accountId, teams: new BaseCollection(@model.get('teams')))
      @showChildView('callNoteTemplates', @callNoteTemplatesView)

    @showChildView('callTasks', new CallTasksView(accountId: @accountId, model: @model))
    @initLockedSettings()

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  onSaveClicked: (button) =>
    return if not @validate()
    @countryCollection.setParams(accountId: @accountId, isUS: false)

    ladda = Ladda.create(button)
    ladda.start()
    audioCollection = new AudioFileCollection()
    audioCollection.setParams(accountId: @model.id)

    holdWaitVal = @holdWaitMusicView.val()
    if holdWaitVal and (holdWaitVal.type is 'audio/mp3' or holdWaitVal.type is 'audio/mpeg' )
      holdAudioModel = new BaseModel(
        file      : holdWaitVal
        fileName  : holdWaitVal.name
        setting   : 'waitUrlForHoldMusic'
      )
      audioCollection.add(holdAudioModel)

    $.when(@model.persist(),(@countryCollection.persist()))
      .done((modelResponse) =>
        $.when((audioCollection.upload() if audioCollection.length))
          .done((audioFilesResponse) =>
            if audioFilesResponse?[0]
              for audioModel in audioFilesResponse?[0]
                switch audioModel.setting
                  when 'waitUrlForHoldMusic' then @holdWaitMusicView.val(audioModel.url, audioModel.fileName)
            @lockedSettingModel.persist(data: @lockedSettingModel.toPlayJSON('lockedSetting'))
            if @callDispositionsView and @model.get('manageCallDispositions')
              @callDispositionsView.updateActions(true)
            toastr.info('RingDNA Dialer Settings saved.')
          )
          .always(-> ladda.stop())
      )

  manageCallDispositions: (e) =>
    if @model.get('manageCallDispositions')
      v = new ConfirmCallDispositionModal()
      @showChildView('modal', v)
      @listenTo(v, 'agree', (importData) ->
        if importData
          importModel = new ImportDispositionsModel(accountId: @accountId)
          importModel.persist().done?(=>
            @callDispositionsCollection.fetch(data: accountId: @accountId).done(=> @showCallDispositions(false))
          )
        else
          @showCallDispositions(false)
      )
      @listenTo(v, 'disagree', =>
        $('.manageCallDispositions').bootstrapToggle('off')
      )
      $('#confirm-call-disposition-modal').modal('show')
      $('#confirm-call-disposition-modal').on('hidden.bs.modal', (e) ->
        v.destroy() if v
      )
    else if @callDispositionsView
      @callDispositionsView.destroy()

  manageCallDispositionRequiredByTeam: =>
    unless @model.get('manageCallDispositionRequiredByTeam')
      state = @model.get('callDispositionRequiredState')
      message = "You are about to disable the ability for Teams to manage their preferences for Call Disposition Required.
        The account preference (Call Disposition Required: #{state}) will be applied to all teams."
      bootbox.dialog(
        closeButton: false
        title: 'Warning'
        message: message
        buttons:
          cancel:
            label: 'Cancel'
            className: 'btn btn-default'
            callback: =>
              @model.set(manageCallDispositionRequiredByTeam: true)
              true
          ok:
            label: 'Disable Manage By Team'
            className: 'btn btn-info'
            callback: =>
              if @model.get('callDispositionRequiredState') isnt 'None'
                @model.set(callDispositionRequiredDate: moment().format('YYYY-MM-DD HH:mm:ss'))
              true
      )

  manageСallNoteTemplates: (e) =>
    if @model.get('callNoteTemplates') and not @getBinding('callActionsReact')
      @callNoteTemplatesView = new CallNoteTemplatesView(accountId: @accountId, teams: new BaseCollection(@model.get('teams')))
      @showChildView('callNoteTemplates', @callNoteTemplatesView)
    else if @callNoteTemplatesView
      @callNoteTemplatesView.destroy()

  showCountryTable: ->
    if not @countriesView
      @countriesView = new CountriesView(
        accountId: @accountId
        collection: @countryCollection
      )
      @showChildView('countries', @countriesView)

  showCallDispositions: (saved) =>
    return if @getBinding('callActionsReact')
    @callDispositionsView = new CallDispositionsView(
      accountId: @accountId
      dispositions: @callDispositionsCollection
      saved: saved
    )
    @showChildView('callDispositions', @callDispositionsView) if @getRegion('callDispositions')

  enableTranscriptionsByCountry: (e) ->
    enable = if e.target.checked then 'enable' else 'disable'
    $('.voiceTranscriptionByCountry').bootstrapToggle(enable)

  initLockedSettings: =>
    @lockedSettingModel = new LockedSettingModel(accountId: @model.id)
    @lockedSettingModel.set(@model.get('lockedSetting'))
    @callForwardingLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'callForwarding')
    @showChildView('callForwardingLocked', @callForwardingLocked) if @getRegion('callForwardingLocked')
    @callForwardingTimeoutLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'callForwardingTimeout')
    @showChildView('callForwardingTimeoutLocked', @callForwardingTimeoutLocked) if @getRegion('callForwardingTimeoutLocked')
    @callForwardingPromptLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'callForwardingPrompt')
    @showChildView('callForwardingPromptLocked', @callForwardingPromptLocked) if @getRegion('callForwardingPromptLocked')
    @callForwardingDisabledOfflineLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'callForwardingDisabledOffline')
    @showChildView('callForwardingDisabledOfflineLocked', @callForwardingDisabledOfflineLocked) if @getRegion('callForwardingDisabledOfflineLocked')
    @webLeadsLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'webLeads')
    @showChildView('webLeadsLocked', @webLeadsLocked) if @getRegion('webLeadsLocked')

  validate: ->
    result = @validateClickToCallDomainBlacklist(@model.get('clickToCallDomainBlacklist'))
    if result isnt true
      errorMessage = 'There was an issue with the domains added to the Click-to-Call Domain Blacklist. ' +
        'Please correct the errors and save the page again. Invalid domain(s): ' + _.escape(result.join(', '))
      @viewModel.set('clickToCallDomainBlacklistError', true)
      toastr.warning(errorMessage)
      false
    else unless @validateMaxHoldTime(@model.get('maxHoldTime'))
      toastr.warning('Invalid value for "Max Hold Time"')
      false
    else
      true

  validateClickToCallDomainBlacklist: (blacklist) ->
    return true if not blacklist
    invalidDomains = []
    for domain in blacklist.split(',')
      domain = domain.trim()
      invalidDomains.push(domain) if not validator.isFQDN(domain)
    if _.isEmpty(invalidDomains) then true else invalidDomains

  validateMaxHoldTime: (maxHoldTime) =>
    return true if not maxHoldTime
    if typeof maxHoldTime is 'string'
      maxHoldTimeNumber = Number(maxHoldTime)
    else
      maxHoldTimeNumber = maxHoldTime
    if (Number.isNaN(maxHoldTimeNumber) or maxHoldTimeNumber < 1 or maxHoldTimeNumber > 240)
      return false
    true

module.exports = IntelligentDialerView
