import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceAccountIcon({ size = 14, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 13" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.962 3.355a.492.492 0 01-.496.495H7a.511.511 0 01-.496-.495v-.496c0-.263.205-.496.496-.496h.497a.51.51 0 01.495.496v.496h-.029zm-2.654 0a.491.491 0 01-.496.495h-.496a.51.51 0 01-.495-.495v-.496c0-.263.204-.496.495-.496h.496a.51.51 0 01.496.496v.496zm0 2.479a.49.49 0 01-.496.495h-.496a.51.51 0 01-.495-.495v-.497c0-.262.204-.495.495-.495h.496a.51.51 0 01.496.495v.497zm0 2.478a.491.491 0 01-.496.496h-.496a.51.51 0 01-.495-.496v-.495c0-.263.204-.497.495-.497h.496c.263 0 .496.234.496.497v.495zm0 2.508a.49.49 0 01-.496.496h-.496a.51.51 0 01-.495-.496v-.495c0-.263.204-.496.495-.496h.496a.51.51 0 01.496.495v.496zM2.654 3.355a.492.492 0 01-.495.495H1.69a.511.511 0 01-.495-.495v-.496c0-.263.204-.496.495-.496h.497a.51.51 0 01.495.496v.496h-.029zm0 2.479a.491.491 0 01-.495.495H1.69a.51.51 0 01-.495-.495v-.497c0-.262.204-.495.495-.495h.497c.262 0 .495.233.495.495v.497h-.029zm0 2.478a.492.492 0 01-.495.496H1.69a.511.511 0 01-.495-.496v-.495c0-.263.204-.497.495-.497h.497c.262 0 .495.234.495.497v.495h-.029zm0 2.508a.491.491 0 01-.495.496H1.69a.51.51 0 01-.495-.496v-.495c0-.263.204-.496.495-.496h.497c.262 0 .495.233.495.495v.496h-.029zm6.504-6.474V1.518c.029-.497-.322-.643-.467-.643H.525C.06.875 0 1.372 0 1.4v11.14h6.27V5.426s0-.583.526-.583H8.69c.292 0 .467-.292.467-.496zm3.645 4.229a.491.491 0 01-.496.495h-.496a.511.511 0 01-.495-.495v-.496c0-.262.204-.496.495-.496h.496c.262 0 .496.234.496.496v.496zm0 2.479a.491.491 0 01-.496.495h-.496a.51.51 0 01-.495-.495v-.497c0-.262.204-.495.495-.495h.496a.51.51 0 01.496.495v.497zm-2.42-2.48a.492.492 0 01-.497.496h-.495a.51.51 0 01-.496-.495v-.496c0-.262.204-.496.496-.496h.495c.263 0 .497.234.497.496v.496zm0 2.48a.492.492 0 01-.497.495h-.495a.51.51 0 01-.496-.495v-.497c0-.262.204-.495.496-.495h.495a.51.51 0 01.497.495v.497zm3.615-4.083c.03-.496-.349-.642-.495-.642h-5.25c-.466 0-.525.496-.525.525v5.687h6.27v-5.57z"
        fill={color || Colors.SALESFORCE_ACCOUNT}
      />
    </svg>
  )
}

export default SalesforceAccountIcon
