BaseGrid = require('base/grid')
BaseView = require('base/view')
SubjectModal = require('./editor/view')
SubjectModel = require('./model')
SubjectCollection = require('./collection')

class SubjectsView extends BaseView

  template: require('./template')

  className: 'subjects'

  bindings:
    '.titleText': 'text:title'

  computeds:
    title:
      get: ->
        if @eventView then 'Event' else 'Task & Note'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add': 'createSubject'

  initialize: (options) ->
    { @accountId, @eventView } = options
    @collection = new SubjectCollection()
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId, event: @eventView)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#edit-subject-modal">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'subject', label: 'Subject', cell: 'string' },
      { name: '',        label: 'Update',  cell: actionCell },
      { name: '',        label: 'Delete',  cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No subjects found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    model = options.model.clone()
    $('div.modal-container').html('')
    @showChildView('modal', new SubjectModal(
      model       : model
      collection  : @collection
      eventView   : @eventView
    ))

  createSubject: =>
    $('div.modal-container').html('')
    @showChildView('modal', new SubjectModal(
      accountId : @accountId
      model     : new SubjectModel(event: @eventView)
      eventView : @eventView
      collection: @collection
    ))

module.exports = SubjectsView