import React, { useCallback, useState } from 'react'

// utils
import { useUser } from '../../../common/services/UserContext'
import {
  isCallRecordingDisabledSelector,
  isOutboundCallRecordingAnnouncementDisabledSelector,
  recordCallOptions,
  RecordTypes,
  useCallRecordingDispatch,
  useCallRecordingState
} from '../../utils/contextReducer'

// components
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'

type RecordCallsToggleProps = {
  recordTypeKey: string
  requiresConsent: boolean
  name: string
}

export const RecordCallsOption = React.memo(function Component({
  recordTypeKey,
  requiresConsent,
  name
}: RecordCallsToggleProps) {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [preConsentValue, setPreConsentValue] = useState<RecordTypes | null>(
    state.unitedStatesRecordingSettings[recordTypeKey]
  )

  const changeCallRecording = useCallback(
    // @ts-ignore
    event => {
      const value = event.target.value as RecordTypes
      if (requiresConsent && value === RecordTypes.ALL) {
        setIsModalOpen(true)
      } else {
        setPreConsentValue(value)
        dispatch({
          type: 'CHANGE_US_RECORDING_SETTINGS',
          payload: {
            [recordTypeKey]: value,
            // Temp fix since old field is still in DB to prevent downtime - https://ringdna.atlassian.net/browse/ADMIN-1311
            enabled: value !== RecordTypes.NONE
          }
        })
      }
    },

    [dispatch, recordTypeKey, requiresConsent]
  )

  const cancelCallRecording = useCallback(() => {
    dispatch({
      type: 'CHANGE_US_RECORDING_SETTINGS',
      payload: { [recordTypeKey]: preConsentValue }
    })

    setIsModalOpen(false)
  }, [setIsModalOpen, recordTypeKey, dispatch, preConsentValue])

  const confirmConsent = useCallback(() => {
    dispatch({
      type: 'CHANGE_US_RECORDING_SETTINGS',
      payload: { [recordTypeKey]: RecordTypes.ALL }
    })

    setIsModalOpen(false)
  }, [setIsModalOpen, recordTypeKey, dispatch])

  return (
    <>
      <RdnaSelect
        options={recordCallOptions}
        value={state.unitedStatesRecordingSettings[recordTypeKey]}
        disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
        onChange={changeCallRecording}
      />
      {requiresConsent && (
        <RdnaModal
          open={isModalOpen}
          minWidth={396}
          maxWidth="md"
          heading="Warning"
          onClose={cancelCallRecording}
          onConfirm={confirmConsent}
          cancelButtonText="No, disable"
          confirmButtonText="Yes, enable"
        >
          <RdnaText>{`${name} requires all-party consent to record calls. Do you want to continue enabling call recording?`}</RdnaText>
        </RdnaModal>
      )}
    </>
  )
})

type PlayOutboundRecordingToggleProps = {
  outboundAnnouncementKey: string
}

export const PlayOutboundRecordingToggle = React.memo(function Component({
  outboundAnnouncementKey
}: PlayOutboundRecordingToggleProps) {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()

  return (
    <RdnaToggleSwitch
      margin={'none'}
      disabled={!isEditingAllowed || isOutboundCallRecordingAnnouncementDisabledSelector(state)}
      value={state.unitedStatesRecordingSettings[outboundAnnouncementKey]}
      onChange={useCallback(
        (checked: boolean) =>
          dispatch({
            type: 'CHANGE_US_RECORDING_SETTINGS',
            payload: {
              [outboundAnnouncementKey]: checked
            }
          }),
        [dispatch, outboundAnnouncementKey]
      )}
    />
  )
})
