import React, { createContext, useContext, useEffect } from 'react'
import PubNub from 'pubnub'
import config from '../../config'
import { useUser } from './UserContext'

export enum PubNubChannels {
  PACKAGE_UPDATE = 'PackageUpdate'
}

export type MessageEvent = {
  channel: string
  message: any
  userMetadata: string
}

type ContextType = {
  pubnub: any
}

const PubNubContext = createContext<ContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const usePubNub = () => {
  const context = useContext(PubNubContext)
  if (context === undefined) {
    throw new Error('usePubNub must be used within a PubNubProvider')
  }
  return context
}

export const PubNubProvider = ({ children }: Props) => {
  const { userId, channel } = useUser()
  const pubnub = new PubNub({
    subscribeKey: config.pubnubKey,
    logVerbosity: true,
    uuid: `${userId}`
  })
  pubnub.subscribe({ channels: [channel] })

  useEffect(() => {
    return () => {
      pubnub.unsubscribe({ channels: [channel] })
    }
  })
  return <PubNubContext.Provider value={{ pubnub }}>{children}</PubNubContext.Provider>
}
