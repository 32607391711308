import { Direction } from './types'

export const getRotateStyle = (direction: Direction) => {
  switch (direction) {
    case Direction.UP:
      return { transform: 'rotate(90deg)' }
    case Direction.DOWN:
      return { transform: 'rotate(270deg)' }
    case Direction.LEFT:
      return { transform: 'rotate(0deg)' }
    case Direction.RIGHT:
      return { transform: 'rotate(180deg)' }
    default:
      return {}
  }
}
