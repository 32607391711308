import { RdnaAccount } from './accounts'

export type DialerSettings = {
  account: RdnaAccount
  features: Feature[]
}

export type Feature = {
  feature: FeatureType
  visible: boolean
}

export enum FeatureType {
  CallQueues = 'CallQueues',
  CallRating = 'CallRating',
  CallToolsMenu = 'CallToolsMenu',
  Chatter = 'Chatter',
  GeneralSettings = 'GeneralSettings',
  LeadStatus = 'LeadStatus',
  NewTask = 'NewTask',
  EmailButton = 'EmailButton',
  TypeTool = 'TypeTool',
  PriorityTool = 'PriorityTool',
  DueDateTool = 'DueDateTool',
  ReminderTool = 'ReminderTool',
  RelatedRecordsTool = 'RelatedRecordsTool',
  WebLeads = 'WebLeads'
}
