BaseView = require('base/view')
BackgridCell = require('helpers/backgrid-cell')
ProfileUserCollection = require('./collection')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')

class ProfileUsersView extends BaseView

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  className: 'salesforce-profile-users'

  regions:
    grid: '.grid'

  bindings:
    '.profile-name-title': 'text:name'
    '.account': 'text:accountName'
    '.profile-name': 'value:name'
    '.profile-id': 'value:id'
    '.user-count': 'text:userCount'

  events:
    'click .account': 'goToAccountView'

  computeds:
    accountName:
      deps: ['account']
      get: (account) ->
        account.name
    accountId:
      deps: ['account']
      get: (account) ->
        account.id

  initialize: (options) =>
    @userCollection = new ProfileUserCollection()

  onRender: =>
    @showChildView('grid', @buildGrid())
    @userCollection.fetch(data: id: @model.get('id'))

  goToAccountView: =>
    App.navigate("#accounts/#{@model.get('account').id}", {trigger: true})

  buildGrid: =>
    cols = [
      {label: 'Name',          name: 'displayName', cell: @nameCell()}
      {label: 'Email',         name: 'email',       cell: 'string'}
      {label: 'Salesforce ID', name: 'sfUserId',    cell: 'string'}
      {label: 'Teams',         name: 'teams',       cell: @teamsCell(), sortable: false}
    ]

    new Backgrid.Grid(
      columns    : cols
      collection : @userCollection
      emptyText  : 'No Users Found.'
    )

  nameCell: =>
    BackgridCell.usersCell('id', 'displayName')

  teamsCell: =>
    class extends Backgrid.Cell
      render: ->
        userTeams = @model.get('userTeams')
        html = ''
        if userTeams?.length
          count = userTeams.length
          sortedUserTeams = _.sortBy(userTeams, (userTeam) => userTeam.team.name)
          for userTeam in sortedUserTeams
            href = "#teams/#{userTeam.team.id}"
            if count > 1
              count--
              html += "<a href='#{href}'>#{userTeam.team.name},</a> "
            else
              html += "<a href='#{href}'>#{userTeam.team.name}</a>"
        $(@el).html(html)
        @


module.exports = ProfileUsersView
