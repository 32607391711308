import React, { useEffect } from 'react'
import styled from 'styled-components'

// utils
import { useAccount } from '../../../../store/api/accounts-api'
import { useUser } from '../../../common/services/UserContext'
import {
  hasUSRecordingSettingsChangedSelector,
  isCallRecordingByStateOnSelector,
  isCallRecordingDisabledSelector,
  useCallRecordingDispatch,
  useCallRecordingState
} from '../../utils/contextReducer'

// components
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import UnitedStatesTable from './UnitedStatesTable'
import SaveAllSettingsButton from '../SaveAllSettingsButton'
import { ToggleRowContainer } from '../styles'

type UnitedStatesSubTabProps = {
  accountId: string
}

export default React.memo(UnitedStatesSubTab)
function UnitedStatesSubTab({ accountId }: UnitedStatesSubTabProps) {
  useAccount({ meta: { id: accountId } })
  const { accountId: userAccountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && userAccountId.toString() === accountId.toString()
  const state = useCallRecordingState()
  const dispatch = useCallRecordingDispatch()
  const isOn = isCallRecordingByStateOnSelector(state)
  const hasUSRecordingSettingsChanged = hasUSRecordingSettingsChangedSelector(state)

  useEffect(() => {
    if (!isOn && hasUSRecordingSettingsChanged) {
      toastr.warning('Any changes made to the call recording settings have been reset')
      dispatch({ type: 'RESET_TO_ORIGINAL_SETTINGS', payload: 'unitedStatesRecordingSettings' })
    }
  }, [isOn, hasUSRecordingSettingsChanged, dispatch])

  return (
    <Container>
      <ToggleRowContainer $align={'space-between'}>
        <RdnaText variant="body2">Would you like to enable granular controls for call recordings in the US?</RdnaText>
        <RdnaToggleSwitch
          disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          value={isOn}
          onChange={() =>
            dispatch({ type: 'CHANGE_ACCOUNT_SETTINGS', payload: { 'settings.callRecordingByState': !isOn } })
          }
          margin={'none'}
        />
      </ToggleRowContainer>
      {isOn && (
        <div>
          <RdnaText variant="h6">CALL RECORDING</RdnaText>
          <RdnaText style={{ whiteSpace: 'pre-wrap' }}>
            Special Consent - indicates that the state may require legal consent to record calls.{'\n'}
            Calls Within a Single Enabled State - Calls originating from AND terminating in those states will be
            recorded.{'\n'}
            Calls Within Two Enabled States - Calls originating from one enabled state and terminating in another
            enabled state will be recorded.{'\n'}
            Calls Involving One Enabled State and One Non-Enabled State - will not be recorded.{'\n'}
            Calls Involving Two Non-Enabled States - will not be recorded.{'\n'}
          </RdnaText>
          <UnitedStatesTable accountId={accountId} />
        </div>
      )}
      <SaveAllSettingsButton />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing * 2}px 0;
  grid-template-columns: 1fr min-content;
  align-items: center;

  .ringdna-table {
    padding-top: ${({ theme }) => theme.spacing * 3}px;
  }
  div {
    grid-column: span 2;
  }
`
