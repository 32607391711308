import React, { useEffect, useState } from 'react'
import RdnaChip from '@ringdna/common/src/components/RdnaChip'
import { useDeleteMedia, useUploadMedia } from '../api'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { Attachment } from '../types'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import { getFileSize, initIcon } from '@ringdna/common/src/utils/fileUtils'
import styled from 'styled-components'
import { sanitizeString } from '@ringdna/common/src/utils/stringUtils'

type Props = {
  file: Attachment
  onFileUpload: () => void
  onFileDelete: (file: Attachment) => void
}

export default function FileLoader({ file, onFileUpload, onFileDelete }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const uploadFile = useUploadMedia()
  const deleteFile = useDeleteMedia()

  useEffect(() => {
    const upload = async () => {
      setIsLoading(true)
      const params = {
        body: { file: file, contentType: file.type }
      }
      try {
        const response = await uploadFile(params)
        const payload = response?.success?.payload
        if (payload) {
          file.url = payload.url
          file.signedUrl = payload.signedUrl
          file.loaded = true
          onFileUpload()
        }
      } catch (error) {
        toastr.error(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }
    !file.loaded && upload()
  }, [file, onFileUpload, uploadFile])

  async function handleDelete() {
    try {
      !file.id && (await deleteFile({ body: { url: file.url } }))
      onFileDelete(file)
    } catch (e) {
      toastr.error(getErrorMessage(e))
    }
  }

  const isImage = () => {
    return file.type.startsWith('image/') && !file.type.includes('tif')
  }

  return (
    <>
      {isLoading ? (
        <StyledFile className={'full-width'}>
          <RdnaChip
            className={'media-file'}
            icon={
              <RdnaLoader data={[]} loading size={20}>
                <></>
              </RdnaLoader>
            }
            label={
              <>
                <span style={{ margin: '0 10px' }}>uploading...</span>
                <span>
                  {file.name} | {getFileSize(file.size)}
                </span>
              </>
            }
            disableHover
          />
        </StyledFile>
      ) : (
        <StyledFile className={isImage() ? '' : 'full-width'}>
          {isImage() ? (
            <>
              <img className={'media-image'} alt="" src={sanitizeString(file.signedUrl)} />
              <RdnaButton onClick={handleDelete} variant="text" text={'x'} />
            </>
          ) : (
            <RdnaChip
              className={'media-file'}
              icon={initIcon(file.type)}
              label={`${file.name} | ${getFileSize(file.size)}`}
              onDelete={handleDelete}
              disableHover
            />
          )}
        </StyledFile>
      )}
    </>
  )
}

export const StyledFile = styled.div`
  display: block;
  .media-file {
    border-radius: ${props => props.theme.spacing * 2}px;
    margin: ${props => props.theme.spacing}px 0;
    width: 100%;
    background-color: ${props => props.theme.palette.background.main};
    min-height: ${props => props.theme.spacing * 7.5}px;
    justify-content: flex-start;
    cursor: auto;
  }
  .media-image {
    border-radius: ${props => props.theme.spacing * 2}px;
    height: ${props => props.theme.spacing * 10}px;
    width: auto;
  }
  .MuiChip-deleteIcon {
    margin-left: auto !important;
  }
`
