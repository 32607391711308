import React from 'react'

export default function EmptyCalendarBooking() {
  return (
    <svg width="400" height="335" viewBox="0 0 400 335" fill="none">
      <rect
        x="39.1067"
        y="75.3088"
        width="268.793"
        height="182.442"
        rx="7.3783"
        fill="white"
        stroke="#5C5C5C"
        strokeWidth="1.34151"
      />
      <mask id="path-2-inside-1_10260_1638" fill="white">
        <path d="M38.436 96.5127H308.571V108.244H38.436V96.5127Z" />
      </mask>
      <path d="M38.436 96.5127H308.571V108.244H38.436V96.5127Z" fill="#899BF9" />
      <path
        d="M307.229 96.5127V108.244H309.912V96.5127H307.229ZM39.7775 108.244V96.5127H37.0945V108.244H39.7775Z"
        fill="#5C5C5C"
        mask="url(#path-2-inside-1_10260_1638)"
      />
      <line x1="38.436" y1="95.7997" x2="308.571" y2="95.7997" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="38.436" y1="137.575" x2="308.571" y2="137.575" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="38.436" y1="167.618" x2="308.571" y2="167.618" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="38.436" y1="197.662" x2="308.571" y2="197.662" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="38.436" y1="227.706" x2="308.571" y2="227.706" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="38.436" y1="107.531" x2="308.571" y2="107.531" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="77.6043" y1="108.201" x2="77.6043" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="116.102" y1="108.201" x2="116.102" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="154.599" y1="108.201" x2="154.599" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="193.097" y1="108.201" x2="193.097" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="231.595" y1="108.201" x2="231.595" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <line x1="270.092" y1="108.201" x2="270.092" y2="258.421" stroke="#5C5C5C" strokeWidth="1.34151" />
      <circle cx="96.3545" cy="82.2957" r="2.60685" fill="#CCCCCC" />
      <circle cx="250.652" cy="82.2958" r="2.60685" fill="#CCCCCC" />
      <g style={{ mixBlendMode: 'screen' }}>
        <path
          d="M40.454 76.6257L93.8463 34.3928L93.5706 213.792L357.387 214.9L310.446 257.247C307.981 259.47 304.779 260.701 301.46 260.701H45.4474C41.0021 260.701 37.3984 257.097 37.3984 252.652V82.9386C37.3984 80.4779 38.524 78.1523 40.454 76.6257Z"
          fill="url(#paint0_linear_10260_1638)"
        />
      </g>
      <rect x="92.0963" y="34.3928" width="270.135" height="183.783" rx="8.04906" fill="white" />
      <mask id="path-20-inside-2_10260_1638" fill="white">
        <path d="M92.0963 56.2676H362.231V67.9984H92.0963V56.2676Z" />
      </mask>
      <path d="M92.0963 56.2676H362.231V67.9984H92.0963V56.2676Z" fill="#EBEEFE" />
      <path
        d="M360.89 56.2676V67.9984H363.573V56.2676H360.89ZM93.4378 67.9984V56.2676H90.7547V67.9984H93.4378Z"
        fill="#CCCCCC"
        mask="url(#path-20-inside-2_10260_1638)"
      />
      <line x1="92.0963" y1="55.5545" x2="362.231" y2="55.5545" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="92.0963" y1="97.3292" x2="362.231" y2="97.3292" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="92.0963" y1="127.373" x2="362.231" y2="127.373" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="92.0963" y1="157.417" x2="362.231" y2="157.417" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="92.0963" y1="187.461" x2="362.231" y2="187.461" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="92.0963" y1="67.2853" x2="362.231" y2="67.2853" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="131.265" y1="67.9561" x2="131.265" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="169.762" y1="67.9561" x2="169.762" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="208.26" y1="67.9561" x2="208.26" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="246.757" y1="67.9561" x2="246.757" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="285.255" y1="67.9561" x2="285.255" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <line x1="323.753" y1="67.9561" x2="323.753" y2="218.176" stroke="#CCCCCC" strokeWidth="1.34151" />
      <circle cx="150.015" cy="44.7334" r="2.60685" fill="#EBEEFE" />
      <circle cx="304.313" cy="44.7334" r="2.60685" fill="#EBEEFE" />
      <rect
        x="92.767"
        y="35.0636"
        width="268.793"
        height="182.442"
        rx="7.3783"
        stroke="#5C5C5C"
        strokeWidth="1.34151"
      />
      <path
        d="M298.303 124.053L289.61 123.355L290.853 133.463C291.131 135.731 289.151 137.655 286.742 137.462L286.696 138.038L300.037 139.11L300.083 138.535L298.303 124.053Z"
        fill="#DBA270"
      />
      <path
        d="M294.17 132.114L286.127 132.144C284.9 132.149 283.902 131.157 283.898 129.93L283.865 121.094C283.845 115.448 288.406 110.853 294.052 110.833L294.092 110.833C299.277 110.814 303.543 115.002 303.562 120.189C303.479 125.951 298.828 129.189 294.17 132.114Z"
        fill="#DBA270"
      />
      <path
        d="M286.491 118.261C286.48 117.928 286.05 117.802 285.861 118.077L282.083 123.573C281.619 124.247 282.059 125.171 282.876 125.235L285.571 125.447C286.187 125.495 286.708 124.998 286.688 124.38L286.491 118.261Z"
        fill="#DBA270"
      />
      <path
        d="M305.086 118.241C305.495 114.055 304.044 112.004 298.89 111.5C297.086 111.324 292.895 109.02 291.414 108.286C290.22 107.763 289.984 107.228 289.296 109.605C287.815 108.871 287.479 108.983 286.934 109.266C285.768 109.871 285.33 110.438 285.16 111.597C285.062 112.258 285.874 114.153 286.23 114.638C287.261 113.73 287.81 113.015 290.685 113.901L289.98 117.093L291.679 118.361C292.595 119.045 292.85 120.34 292.267 121.347L291.97 121.861C291.782 122.45 291.972 122.358 292.818 122.706L293.621 121.749L295.322 123.391L295.755 123.834C299.207 126.478 297.076 130.444 299.485 130.922L304.192 120.915C304.425 120.529 304.617 120.123 304.765 119.698C304.881 119.366 304.97 119.024 305.029 118.671C305.053 118.529 305.072 118.386 305.086 118.241Z"
        fill="#333333"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M285.645 126.763C285.645 126.763 286.66 126.895 287.756 126.544"
          stroke="#1C4D51"
          strokeWidth="0.172781"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M286.483 121.192C286.703 121.192 286.882 120.864 286.882 120.46C286.882 120.055 286.703 119.727 286.483 119.727C286.262 119.727 286.084 120.055 286.084 120.46C286.084 120.864 286.262 121.192 286.483 121.192Z"
          fill="#1C4D51"
        />
        <path
          d="M285.94 119.651C285.94 119.651 286.725 119.508 287.932 120.522"
          stroke="#1C4D51"
          strokeWidth="0.172781"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M285.422 118.228C285.506 117.534 286.293 117.136 286.884 117.252C287.379 117.312 287.771 117.611 288.144 117.944C288.468 118.233 288.409 118.326 288.005 118.168C287.543 117.986 287 117.784 286.859 117.781C286.367 117.75 285.847 117.915 285.467 118.25L285.422 118.228Z"
        fill="#333333"
      />
      <path
        d="M293.082 121.368C293.082 121.368 293.688 120.347 294.697 120.307C295.708 120.267 296.233 121.116 296.354 122.085C296.475 123.054 295.689 124.469 293.023 124.913"
        fill="#DBA270"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M293.082 121.368C293.082 121.368 293.688 120.347 294.697 120.307C295.708 120.267 296.233 121.116 296.354 122.085C296.475 123.054 295.689 124.469 293.023 124.913"
          stroke="#1C4D51"
          strokeWidth="0.172781"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M295.191 121.978C295.191 121.978 294.937 121.321 294.521 121.321C294.104 121.321 293.569 121.65 293.569 122.616C293.569 123.581 293.178 123.54 293.178 123.54"
          stroke="#1C4D51"
          strokeWidth="0.172781"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <ellipse
        cx="285.564"
        cy="121.993"
        rx="0.725177"
        ry="2.17553"
        fill="#163E41"
        stroke="#163E41"
        strokeWidth="0.543882"
      />
      <path d="M301.114 178.678L302.33 178.591V183.803H301.114V178.678Z" fill="#001733" />
      <path
        d="M270.489 121.422L270.104 123.643L280.279 120.289L278.353 118.567H276.359L275.951 119.11V119.518L275.724 119.79L274.636 119.518L273.685 120.062L272.982 119.79L271.532 120.062L270.489 121.422Z"
        fill="#DBA270"
      />
      <path
        d="M274.834 124.361L270.219 122.637L269.108 126.334L267.955 130.135L272.37 131.846L275.283 129.429C275.562 129.198 275.823 128.946 276.064 128.675L278.874 125.524L277.741 123.484L274.834 124.361Z"
        fill="#DBA270"
      />
      <path d="M271.758 120.674H278.557V125.605H271.758V120.674Z" fill="#112E31" />
      <rect x="276.245" y="119.535" width="9.32603" height="4.9312" fill="#163E41" />
      <path
        d="M279.531 125.524C279.554 124.096 279.554 121.399 278.784 119.858C278.058 119.042 277.22 118.77 276.517 118.725C275.815 118.68 274.999 117.479 277.356 117.569C278.256 117.604 278.489 117.909 278.784 117.909C279.531 117.569 281.201 118.588 281.458 119.042L282.84 120.47L282.624 119.778C282.409 119.088 282.016 118.468 281.485 117.978L280.682 117.239C280.459 117.034 280.405 116.703 280.551 116.437C280.75 116.072 281.233 115.981 281.552 116.248L283.135 117.569L284.222 119.541L284.902 122.351L285.31 128.084L286.851 131.37L282.296 133.365C281.903 132.813 280.991 131.57 280.483 131.008C279.849 130.305 279.509 126.951 279.531 125.524Z"
        fill="#DBA270"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.977 153.002C259.905 155.056 262.323 155.967 264.376 155.039L284.387 145.99C286.441 145.062 287.353 142.644 286.424 140.591C285.496 138.537 281.311 136.908 279.257 137.837L261.014 147.602C258.96 148.531 258.048 150.949 258.977 153.002Z"
        fill="#DBA270"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.006 148.032L279.395 137.806C284.028 134.841 285.353 138.449 286.251 140.606C287.338 143.214 286.718 146.133 284.653 147.143L262.309 155.981L260.006 148.032Z"
        fill="#CC7A29"
      />
      <path
        d="M270.059 123.597L270.452 121.732C270.536 121.335 270.716 120.965 270.977 120.654L271.197 120.392C271.484 120.051 271.895 119.839 272.34 119.805L273.129 119.744C273.302 119.731 273.466 119.82 273.548 119.972V119.972C273.642 120.146 273.608 120.361 273.465 120.497L273.38 120.578C273.27 120.683 273.124 120.742 272.972 120.742H272.371C271.74 120.742 271.235 121.267 271.26 121.898V121.898V124.232"
        stroke="#AF6E36"
        strokeWidth="0.333581"
        strokeLinecap="round"
      />
      <path
        d="M274.742 126.151C273.7 126.442 272.534 127.775 272.096 128.877"
        stroke="#AF6E36"
        strokeWidth="0.333264"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M257.437 149.711L268.186 129.68L272.457 131.712L265.054 153.497C264.158 155.562 261.52 156.703 259.479 155.756C257.454 154.817 256.473 151.723 257.437 149.711Z"
        fill="#DBA270"
      />
      <path
        d="M257.438 149.71L260.31 144.363L267.261 147.242L265.055 153.496C264.159 155.561 261.522 156.702 259.48 155.755C257.456 154.815 256.474 151.722 257.438 149.71Z"
        fill="#CC7A29"
      />
      <path
        d="M260.579 147.466C259.817 149.068 266.294 150.627 266.082 151.327C265.818 152.202 260.893 148.99 260.403 150.453C259.912 151.916 265.631 151.208 265.858 152.133C266.085 153.058 262.262 152.44 261.087 153.609"
        stroke="#663D14"
        strokeWidth="0.34747"
        strokeLinecap="round"
      />
      <path
        d="M266.781 150.285L267.229 147.213L260.291 144.347L258.648 146.571L266.781 150.285Z"
        fill="#CC7A29"
        stroke="#663D14"
        strokeWidth="0.34747"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M289.499 264.253L281.026 264.694C281.026 264.694 282.986 278.489 282.401 281.363C281.711 284.759 274.163 293.149 276.367 294.957C278.57 296.763 287.184 290.947 288.606 286.915C289.235 285.132 288.802 280.936 288.882 276.316C288.983 270.49 289.499 264.253 289.499 264.253Z"
        fill="#DBA270"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.865 260.634L306.481 260.135C306.481 260.135 308.048 272.217 309.383 280.807C310.056 285.136 310.623 288.722 310.996 289.135C312.64 290.956 315.472 291.324 318.551 292.925C320.989 294.193 321.476 294.889 324.376 295.024C328.487 295.216 326.383 293.774 321.185 289.009C316.554 284.762 316.507 282.474 316.507 282.474L314.865 260.634Z"
        fill="#DBA270"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.212 168.853C280.212 168.853 279.318 165.473 279.396 156.661C279.415 154.506 280.68 147.437 280.846 144.378C281.073 140.188 280.271 139.909 280.846 137.036C283.062 136.319 286.651 135.028 290.533 135.028C291.471 135.136 290.796 135.682 295.183 135.91C299.569 136.138 298.679 134.681 300.755 134.789C308.398 134.908 310.712 137.701 310.712 137.701L307.243 170.081L306.255 178.789C299.794 179.978 284.476 180.881 280.633 178.693C279.228 177.893 280.578 172.443 280.212 168.853Z"
        fill="#CC7A29"
      />
      <path
        d="M295.183 135.91C290.796 135.682 291.433 135.135 290.494 135.027L279.289 169.308C279.655 172.898 279.228 177.893 280.633 178.693C284.476 180.881 299.794 179.978 306.255 178.789L307.244 170.081L300.755 134.789C298.68 134.681 299.57 136.138 295.183 135.91Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.151 280.177C317.151 280.177 310.496 185.654 306.339 178.624C285.697 179.152 280.556 178.864 280.556 178.864C280.556 178.864 277.98 188.806 277.9 203.359C277.843 213.694 281.022 279.81 281.022 279.81L283.677 280.629L289.807 280.059L293.163 203.167L293.789 203.349L308.524 280.849L313.359 281.382L317.151 280.177Z"
        fill="#001733"
      />
      <path
        d="M281.655 177.896C281.655 177.896 285.999 178.847 294.83 178.847C303.662 178.847 308.006 177.896 308.006 177.896V181.475C308.006 181.475 303.054 182.427 294.83 182.427C286.607 182.427 281.655 181.475 281.655 181.475V177.896Z"
        fill="#663D14"
      />
      <path d="M286.006 178.678L287.222 178.591V183.803H286.006V178.678Z" fill="#001733" />
      <path
        d="M300.311 190.534L313.311 281.293"
        stroke="#003A7F"
        strokeWidth="0.337394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M283.694 191.462L283.592 280.613"
        stroke="#003A7F"
        strokeWidth="0.337394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M295.141 182.774L294.381 200.09"
        stroke="#003A7F"
        strokeWidth="0.337394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M292.644 182.519C292.644 184.932 292.036 195.487 291.664 200.063"
        stroke="#003A7F"
        strokeWidth="0.337394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M288.408 184.181L290.568 134.996L288.083 135.331L279.395 156.434L277.854 180.682L288.408 184.181Z"
        fill="#CC7A29"
      />
      <path d="M297.291 178.808L290.95 178.634V182.5H297.291V178.808Z" stroke="#CAAD2A" strokeWidth="1.04241" />
      <path
        d="M293.99 184.671L299.741 134.983H302.135L312.482 147.324L308.584 166.813L308.856 182.586L293.99 184.671Z"
        fill="#CC7A29"
      />
      <path
        d="M291.888 137.854C293.729 138.827 295.836 138.936 298.596 137.494"
        stroke="#B3C4D9"
        strokeWidth="0.415149"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.485 156.57C279.485 156.049 280.574 147.415 280.574 147.415"
        stroke="#663D14"
        strokeWidth="0.415149"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M285.483 137.242L288.436 135.157H289.638L288.905 168.507L284.962 145.929L287.741 142.28L285.483 137.242Z"
        stroke="#663D14"
        strokeWidth="0.34747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M304.123 136.894L302.59 134.81H299.811L296.074 167.176L305.309 145.234L302.529 141.933L304.123 136.894Z"
        stroke="#663D14"
        strokeWidth="0.34747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.965 285.949C280.285 283.23 282.599 283.691 283.523 283.815C284.448 283.913 285.925 284.818 285.025 286.729"
        fill="#DBDBDB"
      />
      <path
        d="M284.884 286.27C284.884 286.27 285.604 285.175 286.039 285.224C286.474 285.273 288.076 285.227 288.392 284.463C288.603 283.944 288.654 283.699 289.008 283.749C289.525 283.824 290.224 285.554 290.4 286.61C290.576 287.667 290.115 292.261 289.691 293.162C289.373 293.791 286.362 294.017 284.726 295.666C284.028 296.353 284.25 297.144 283.929 297.528C282.805 298.872 281.899 299.935 280.09 300.744C279.037 301.219 275.064 300.479 274.081 300.029C273.098 299.58 272.36 296.928 273.426 295.231C274.038 294.247 277.843 290.047 278.8 288.298C279.756 286.548 280.992 284.456 280.992 284.456L282.138 283.789L281.784 285.911"
        fill="#DBDBDB"
      />
      <path
        d="M285.395 285.911C284.639 288.526 284.808 291.321 283.41 292.424C282.011 293.526 279.362 296.708 278.281 297.182C277.201 297.657 275.086 297.9 274.421 296.767C273.589 295.338 273.849 294.358 274.653 293.506C275.457 292.655 279.763 287.173 280.543 284.286"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.034 287.508C280.837 288.409 282.672 289.718 284.288 290.92"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M284.457 289.261C282.469 288.742 280.43 288.55 278.395 288.683"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M277.914 289.232C278.733 289.683 279.528 290.244 280.214 290.887C281.093 291.691 281.64 292.092 282.63 293.112"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M283.888 291.604C282.273 290.456 279.336 290.111 277.033 290.491"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M276.605 291.039C278.653 292.046 279.343 292.961 281.076 294.787"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.229 293.632C281.302 293.291 277.183 291.684 275.75 292.19"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.802 297.71C273.819 298.702 276.133 299.272 277.87 299.197C279.607 299.121 282.744 298.052 283.355 296.986C283.994 295.946 284.695 295.476 284.695 295.476C284.695 295.476 284.312 297.544 283.453 298.369C282.594 299.194 280.774 301.307 277.944 300.825C275.115 300.343 274.082 300.274 273.393 299.386C272.703 298.471 272.802 297.71 272.802 297.71Z"
        fill="white"
      />
      <path
        d="M289.996 291.503C289.996 291.503 285.763 293.97 285.279 294.356C284.795 294.742 284.697 295.639 284.697 295.639C284.697 295.639 286.608 294.259 288.126 293.996C290.618 293.559 289.996 291.503 289.996 291.503Z"
        fill="white"
      />
      <path
        d="M279.147 285.552C278.657 285.449 278.113 285.428 277.737 285.759C277.576 285.923 277.471 286.169 277.554 286.358C277.666 286.628 278.02 286.651 278.291 286.675C278.916 286.695 279.514 286.715 280.138 286.734C280.627 286.756 281.116 286.777 281.604 286.689C282.01 286.63 282.416 286.517 282.823 286.43C283.31 286.343 283.799 286.31 284.287 286.304C284.667 286.3 285.048 286.322 285.403 286.481C285.757 286.612 286.086 286.88 286.199 287.259C286.312 287.61 286.182 288.073 285.858 288.267C285.454 288.489 284.99 288.278 284.58 288.066C283.678 287.588 282.723 287.192 281.821 286.714C280.974 286.263 280.154 285.757 279.147 285.552Z"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M287.527 292.909C287.538 293.017 287.403 292.574 286.974 292.421C286.433 292.227 285.533 293.258 285.05 294.913"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.535 286.212C309.331 286.214 308.533 291.433 309.058 292.085C310.266 293.604 312.145 292.479 314.903 295.563C317.661 298.648 321.665 299.508 325.414 299.751C329.163 299.993 328.592 294.991 326.065 293.625C323.537 292.259 318.52 287.365 318.066 286.086C317.612 284.807 314.513 285.267 314.384 286.395C314.256 287.524 314.256 287.524 314.256 287.524C314.256 287.524 313.375 287.487 312.602 287.181C311.504 286.761 310.317 285.978 309.535 286.212Z"
        fill="white"
      />
      <path
        d="M309.712 284.772C309.071 285.003 308.561 290.581 309.125 291.239C310.423 292.772 311.429 292.702 314.879 294.448C318.035 295.547 316.527 299.283 325.62 298.689C327.345 298.577 328.691 293.716 325.989 292.323C323.287 290.931 319.322 285.987 318.83 284.702C318.337 283.417 315.008 283.875 314.879 285.003C314.751 286.131 314.751 286.131 314.751 286.131C314.751 286.131 313.808 286.118 312.983 285.803C311.814 285.341 310.523 284.479 309.712 284.772Z"
        fill="#DBDBDB"
      />
      <path
        d="M314.993 286.455C316.2 290.013 316.922 289.26 319.05 291.736C320.03 292.876 320.827 294.374 322.308 294.646C323.704 294.903 326.434 293.604 324.87 291.736"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.34 285.352C319.474 285.149 319.636 284.95 319.845 284.822C320.054 284.694 320.31 284.637 320.514 284.795C320.824 285 320.815 285.573 320.584 285.889C320.354 286.205 319.978 286.34 319.612 286.381C319.021 286.452 318.42 286.362 317.812 286.334C317.204 286.307 316.572 286.245 316.018 286.48C315.463 286.716 314.932 287.242 314.799 287.923C314.788 288.017 314.781 288.08 314.801 288.147C314.845 288.249 314.927 288.261 315.032 288.309C315.541 288.48 316.08 288.625 316.549 288.408C316.816 288.257 317.008 288.031 317.231 287.777C317.585 287.352 317.939 286.927 318.344 286.542C318.718 286.183 318.97 285.679 319.34 285.352Z"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M320.929 287.908C320.435 287.561 319.605 287.71 318.998 287.927C318.171 288.197 317.376 288.477 316.571 288.793"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.95 286.778C319.293 288.375 318.935 288.825 317.902 290.15"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.271 290.62C319.765 289.976 320.913 289.702 322.506 289.692"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M321.623 288.527C320.966 289.759 320.276 290.862 319.386 291.939"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M323.45 290.513C323.329 290.523 321.547 293.096 321.431 293.68"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.875 292.746C320.199 292.266 320.672 291.962 321.171 291.708C321.67 291.455 322.172 291.336 322.711 291.226C323.261 291.076 323.884 290.947 324.398 291.227"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M310.541 292.558C310.808 292.438 311.343 292.133 311.861 291.992C312.514 291.815 313.158 292.025 313.446 292.156C313.981 292.395 314.443 292.814 314.879 293.241C315.249 293.589 316.009 294.536 316.43 295.783"
        stroke="white"
        strokeWidth="0.34747"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M276.155 118.68C275.747 118.929 275.701 119.405 275.996 119.541"
        stroke="#AF6E36"
        strokeWidth="0.333581"
      />
      <path
        d="M278.625 117.887C279.554 117.479 281.412 118.68 282.183 119.79C282.953 120.901 284.109 121.558 284.086 122.6"
        stroke="#AF6E36"
        strokeWidth="0.333581"
      />
      <ellipse
        cx="271.758"
        cy="123.108"
        rx="0.725177"
        ry="2.17553"
        fill="#C4C4C4"
        stroke="#217F5D"
        strokeWidth="0.543882"
      />
      <ellipse
        cx="276.245"
        cy="121.993"
        rx="0.725177"
        ry="2.17553"
        fill="#C4C4C4"
        stroke="#217F5D"
        strokeWidth="0.543882"
      />
      <path
        d="M273.707 120.062C274.5 119.156 275.271 119.586 275.724 120.062C275.777 120.334 275.779 120.629 275.362 120.629C274.945 120.629 274.78 120.387 274.75 120.266"
        stroke="#AF6E36"
        strokeWidth="0.333581"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M293.627 151.902L305.547 140.39C307.541 140.39 308.669 135.862 310.623 137.625C312.986 139.757 314.747 146.372 311.121 149.45L302.102 155.231L300.005 156.238C296.919 157.719 293.384 155.316 293.627 151.902V151.902Z"
        fill="#CC7A29"
      />
      <path
        d="M300.063 145.965C302.435 143.351 306.245 138.804 308.312 139.529"
        stroke="#663D14"
        strokeWidth="0.348085"
        strokeLinecap="round"
      />
      <path
        d="M301.287 148.583L285.469 129.512L281.571 131.914L293.739 154.484C295.195 156.56 298.364 157.222 300.402 155.715C302.423 154.22 302.807 150.585 301.287 148.583Z"
        fill="#DBA270"
      />
      <path
        d="M301.398 148.641L297.134 143.295L290.014 147.89L293.722 154.375C295.132 156.483 298.285 157.214 300.357 155.752C302.41 154.302 302.873 150.676 301.398 148.641Z"
        fill="#CC7A29"
      />
      <path
        d="M291.193 151.196L290.003 147.909L297.011 143.175L299.327 145.257L291.193 151.196Z"
        fill="#CC7A29"
        stroke="#663D14"
        strokeWidth="0.347471"
      />
      <path
        d="M303.661 152.244C301.747 152.819 301.115 145.342 300.299 145.459C299.28 145.604 302.018 151.634 300.305 151.931C298.592 152.229 300.355 145.981 299.363 145.571C298.372 145.161 298.41 149.523 296.907 150.632"
        stroke="#663D14"
        strokeWidth="0.347471"
      />
      <path
        d="M312.119 148.457C303.915 156.434 297.751 156.434 297.751 156.434"
        stroke="#663D14"
        strokeWidth="0.415149"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M276.2 124.867C275.548 125.429 278.225 126.838 279.645 127.473L280.732 125.682L279.645 124.3L278.058 124.504C277.016 124.368 276.853 124.305 276.2 124.867Z"
        fill="#DBA270"
      />
      <path
        d="M280.483 131.008C279.849 130.305 279.509 126.951 279.531 125.524C279.554 124.096 279.554 121.399 278.784 119.858C278.058 119.042 277.22 118.77 276.517 118.725C275.815 118.68 274.999 117.479 277.356 117.569C279.241 117.642 280.695 119.397 281.186 120.266"
        stroke="#AF6E36"
        strokeWidth="0.333581"
        strokeLinecap="round"
      />
      <path
        d="M279.645 127.473C278.225 126.838 275.548 125.429 276.2 124.867C276.853 124.305 278.074 124.753 278.602 125.048"
        stroke="#AF6E36"
        strokeWidth="0.333581"
      />
      <path
        d="M282.749 120.447C282.817 120.176 282.817 119.446 282.274 118.702C281.594 117.773 279.735 117.094 280.574 116.21C281.412 115.326 282.953 117.207 283.701 118.385C284.449 119.564 284.698 121.535 284.88 123.031C285.061 124.527 285.219 127.382 285.31 128.152C285.383 128.769 287.501 131.771 288.007 132.594"
        stroke="#AF6E36"
        strokeWidth="0.333581"
        strokeLinecap="round"
      />
      <path
        d="M275.474 125.274C273.979 126.317 275.815 136.537 275.905 147.007C275.996 157.477 275.77 162.326 278.806 162.326C281.236 162.326 281.934 143.2 282.024 133.501"
        stroke="#112E31"
        strokeWidth="0.725177"
        strokeLinecap="round"
      />
      <path
        d="M285.585 121.49C287.289 122.614 286.269 124.217 285.469 124.957"
        stroke="#112E31"
        strokeWidth="0.725177"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10260_1638"
          x1="150.273"
          y1="169.594"
          x2="38.7399"
          y2="259.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#899BF9" />
          <stop offset="0.499295" stopColor="#9B7CF0" />
          <stop offset="1" stopColor="#7549EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}
