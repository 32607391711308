import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SipIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_8032_43866)">
        <path
          d="M10.496 7.66792C10.496 8.36392 9.944 8.91592 9.248 8.91592C8.552 8.91592 8 8.36392 8 7.66792C8 6.97192 8.552 6.41992 9.248 6.41992C9.944 6.41992 10.496 6.97192 10.496 7.66792Z"
          stroke={color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9608 12.4433C17.9608 13.1393 17.4088 13.6913 16.7128 13.6913C16.0168 13.6913 15.4648 13.1393 15.4648 12.4433C15.4648 11.7473 16.0168 11.1953 16.7128 11.1953C17.4088 11.1953 17.9608 11.7473 17.9608 12.4433Z"
          stroke={color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.496 17.1718C10.496 17.8678 9.944 18.4198 9.248 18.4198C8.552 18.4198 8 17.8678 8 17.1718C8 16.4758 8.552 15.9238 9.248 15.9238C9.944 15.9238 10.496 16.4758 10.496 17.1718Z"
          stroke={color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.24609 8.91602V12.444V15.924"
          stroke={color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.24609 12.4434H15.4861"
          stroke={color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8032_43866">
          <rect width={size} height={size} fill={color} transform="translate(0 0.419922)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SipIcon
