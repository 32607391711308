import React from 'react'
import { SnackbarProvider } from 'notistack'
import SchedulingTabs from './SchedulingTabs'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'

type Props = {
  calendarLicense: boolean
}

function CalendarDisabled() {
  const link = 'https://support.revenue.io/hc/en-us/articles/9009249501595'
  return (
    <>
      <RdnaHeaderSection headingText="Calendar Booking" />
      <RdnaText>
        Revenue’s Calendar Booking provides external parties the ability to book meetings on their own terms. Finding
        times that align between two parties can be cumbersome, so by presenting to a party all available times on your
        calendar, as dictated by your preferences and control, you can confidently allow individuals to book time on
        your calendar. Contact your Administrator to request a Calendar Booking license or click{' '}
        <a href={link} target="_blank" rel="noopener noreferrer">
          here
        </a>{' '}
        to learn more.
      </RdnaText>
    </>
  )
}

export default function Calendar({ calendarLicense }: Props) {
  return (
    <>
      {calendarLicense ? (
        <SnackbarProvider maxSnack={1}>
          <SchedulingTabs />
        </SnackbarProvider>
      ) : (
        <CalendarDisabled />
      )}
    </>
  )
}
