import React, { useContext } from 'react'
import { IntegrationsActions } from '../../constants'
import { InitIntegrationsContext } from '../../model'
import { IntegrationsContext } from '../../store/IntegrationsProvider'
import { Grid, Box } from '@mui/material'
import AccountDetails from './AccountDetails'
import RecordingSettings from './RecordingSettings'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export default function ZoomUserManager() {
  const { dispatch } = useContext<InitIntegrationsContext>(IntegrationsContext)

  const closeManager = () => {
    dispatch({ type: IntegrationsActions.DISPLAY_ZOOM_USER_MANAGER, payload: false })
  }

  return (
    <>
      <Box mb={3}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <RdnaText color="neutral">Connected Accounts &gt; Zoom Integration</RdnaText>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <RdnaButton
                name="back-to-integrations"
                variant="text"
                text="&lt; Back To Integrations"
                onClick={closeManager}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AccountDetails />
      <RecordingSettings />
    </>
  )
}
