import React from 'react'
import { BaseIconProps, Direction } from '../types'
import { Colors } from '../../../constants/colors'
import { getRotateStyle } from '../iconUtils'

type CaretProps = { direction?: Direction } & BaseIconProps

function CaretIcon({
  size = 24,
  color = Colors.N100,
  direction = Direction.LEFT,
  title = `caret-${direction}-icon`,
  ...props
}: CaretProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      // @ts-ignore
      style={{ ...getRotateStyle(direction), display: 'block' }}
      {...props}
    >
      <title>{title}</title>
      <path
        d="M15 4L7.21213 11.7879C7.09497 11.905 7.09497 12.095 7.21213 12.2121L15 20"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CaretIcon
