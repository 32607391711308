import React, {useCallback, useMemo, useState} from 'react'

import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { Column } from 'react-table'
import {
  useSalesforceFields,
  Field,
  useDeleteSalesforceField
} from '../../../../../store/api/salesforce-fields-api'
import { getFormattedFullDate } from '@ringdna/common/src/utils/timeUtils'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import styled from 'styled-components'
import SideFieldEditor from './SideFieldEditor'
import RdnaMenu from "@ringdna/common/src/components/RdnaMenu";
import RdnaModal from "@ringdna/common/src/components/RdnaModal";
import SvgDelete from "@ringdna/common/src/assets/icons/svg/Delete";

type Props = {
  accountId: number
  type: string
}

const DELETE_MODAL_TEXT = 'Are you sure you want to delete this field? This will also remove the field from each users softphone.'

export default Table
function Table({ accountId, type }: Props) {
  const [displaySide, setDisplaySide] = useState(false)
  const [selectedField, setSelectedField] = useState<Field>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [fields, , loading, refetch] = useSalesforceFields({ meta: { id: accountId, type: type } })
  const deleteSalesforceField = useDeleteSalesforceField()

  const editField = (field: Field | undefined) => {
    setSelectedField(field)
    setDisplaySide(true)
  }

  async function handleDelete() {
    try {
      await deleteSalesforceField({ meta: { id: selectedField?.id as number} })
      setIsDeleteModalOpen(false)
      refetch()
    } catch (e) {
      toastr.error(e)
    }
  }

  const menuOptions = (field: Field) => {
    const options = [{ text: 'Edit' }]
    if (!field.isDefault) {
      options.push({ text: 'Delete' })
    }
    return options
  }

  const handleActionMenu = useCallback((action: string, field: Field) => {
    if (action === 'Edit') {
      editField(field)
    } else if (action === 'Delete') {
      setSelectedField(field)
      setIsDeleteModalOpen(true)
    }
  }, [])

  const columns = useMemo<Column<Field>[]>(
    () => [
      {
        Header: 'Name',
        accessor: field => field,
        Cell: function createdCell(data: { value: Field }) {
          return (
            <RdnaText color={'link'} onClick={() => editField(data.value)}>
              {data.value.name}
            </RdnaText>
          )
        }
      },
      { Header: 'Salesforce Field Name', accessor: 'sfdcName' },
      { Header: 'Type', accessor: 'dataType', width: 50 },
      { Header: 'Profiles', accessor: 'settingsCount', width: 50 },
      {
        Header: 'Last Updated',
        accessor: 'updatedDate',
        width: 100,
        Cell: (data: { value?: number }) => getFormattedFullDate(data.value as number)
      },
      {
        Header: 'Actions',
        accessor: field => field,
        width: 50,
        disableSortBy: true,
        Cell: function createdCell(data: { value: Field }) {
          return <RdnaMenu options={menuOptions(data.value)} onSelect={item => handleActionMenu(item, data.value)} />
        }
      }
    ],
    []
  )

  return (
    <Body>
      <SectionHeader
        rightColumn={
          <RdnaButton text={`Add ${type} Field`} name="add-salesforce-field" onClick={() => editField(undefined)} />
        }
      />
      <RdnaSmartTable
        columns={columns}
        data={fields || []}
        isFetching={!!loading}
        globalSearchLabel={'Search'}
        paginationSize={10}
      />
      {displaySide && (
        <RightContainer>
          <SideFieldEditor
            onClose={(reload: boolean) => {
              reload && refetch()
              setDisplaySide(false)
            }}
            accountId={accountId}
            type={type}
            field={selectedField}
          />
        </RightContainer>
      )}
      <RdnaModal
        open={isDeleteModalOpen}
        confirmButtonText={'Delete'}
        heading={`Delete ${selectedField?.name || ''}`}
        headingIcon={<SvgDelete />}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      >
        <RdnaText>{DELETE_MODAL_TEXT}</RdnaText>
      </RdnaModal>
    </Body>
  )
}

const Body = styled.div`
  background-color: ${({ theme }) => theme.palette.background.main};
  padding: ${({ theme }) => theme.spacing * 3}px;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`

const RightContainer = styled.div`
  width: 50%;
  padding: ${props => 5 * props.theme.spacing}px;
  overflow: auto;
  background-color: ${props => props.theme.palette.white};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
`
