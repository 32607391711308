BaseView = require('base/view')
BaseCollection = require('base/collection')
BaseModel = require('base/model')
TeamCollection = require('widgets/teams-picker/user-teams-collection')
TeamsPicker  = require('widgets/teams-picker/view')

class JoinedTeamCollection extends BaseCollection
  url: -> 'userTeams/batch'
  setParams: (options) ->
    { @userId } = options
  toJSON: =>
    fields = {}
    fields['userId'] = @userId
    @each((model, index) =>
      fields["teamIds[#{index}]"] = model.get('id')
    )
    fields

class UserTeamModal extends BaseView

  template: require('./template')
  className: 'user-team-modal'

  regions:
    userTeamPicker : 'span.team-picker'

  ui:
    modal  : '#user-team-modal'
    save   : 'button.save-user-teams'

  events:
    'click @ui.save': 'save'

  initialize: (options) =>
    { @user, @userTeamCollection } = options
    @joinedTeamCollection = new JoinedTeamCollection()
    for model in @userTeamCollection.models
      @joinedTeamCollection.add(new BaseModel(model.get('team'))) if model.get('type') is 'Regular'
    @joinedTeamCollection.setParams(userId: @user.id)
    @teamCollection = new TeamCollection()

  onRender: =>
    @teamCollection
    .fetch(
      data:
        userId : @user.id
    )
    .done( =>
      return unless @getRegion('userTeamPicker')
      @userTeamPicker = new TeamsPicker(
        list: @teamCollection
        placeholder: null
        addedTeams: @joinedTeamCollection
      )

      @listenTo(@userTeamPicker, 'select', (value) =>
        model = @teamCollection.findWhere(id: parseInt(value))
        @joinedTeamCollection.add(model) if model
      )
      @listenTo(@userTeamPicker, 'deselect', (value) =>
        @joinedTeamCollection.remove(parseInt(value))
      )
      @showChildView('userTeamPicker', @userTeamPicker)
    )

  save: =>
    @joinedTeamCollection
    .persist(ladda: @ui.save[0])
    .done((response) =>
      @userTeamCollection.reset(response.filter((ut) -> not ut.team.isDeleted))
      @ui.modal.modal('hide')
    )

module.exports = UserTeamModal
