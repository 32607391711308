BaseView        = require('base/view')
SmsSettings           = require('src/components/sms-settings').default
ProductConstant           = require('@ringdna/common/src/constants/products')
ReactComponent            = require('src/ReactComponent').default

class SmsSettingsView extends BaseView
  template: _.template('<div class="sms-settings"></div>')
  className: 'sms-settings-view'

  regions:
    container  : '.sms-settings'

  initialize: (options) =>
    { @qs } = options
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @qs.setFragment('dialer/sms-settings')

  onRender: =>
    unless @user().isSalesSupport()
      @showChildView('container', new ReactComponent({component: SmsSettings, theme: ProductConstant.Products.ADMIN, props: { accountId: @accountId }}))

module.exports = SmsSettingsView
