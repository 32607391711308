import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import { availableFlags } from './available-flags'
import { TypeofType } from '../../types/common'
import { GenericObject } from '../../../types/generic'
import { LDFlagSet } from 'launchdarkly-js-sdk-common'

// Expose the available feature flags from a single file
export const flags = availableFlags

export type AllFlagValues = LDFlagSet
type User = {
  id: number
  email: string
  name: string
  accountId: number
  username: string
  firstName?: string
  lastName?: string
  accountName: string
}

const DEFAULT_VALUE = false
const INIT_TIMEOUT = 10000
const DISABLE_QUERY_PARAM_KEY = 'disableFeatureFlags'
let ldClient: LDClient | undefined

export const init = ({
  apiKey,
  user: { username, email, id, accountId, accountName, name, firstName, lastName }
}: {
  apiKey: string
  user: User
}) => {
  ldClient = initialize(
    apiKey,
    {
      kind: 'user',
      key: email,
      email,
      name,
      firstName,
      lastName,
      id,
      accountId,
      accountName,
      username
    },
    {
      bootstrap: 'localStorage',
      fetchGoals: false,
      useReport: true
    }
  )
  return Promise.race([ldClient.waitUntilReady(), new Promise(resolve => setTimeout(resolve, INIT_TIMEOUT))])
}

/**
 * Allow local flag override via localStorage keys.
 * LD flag values can be of most any type but typically boolean, string, number, or JSON.
 *
 * Example: enable Real Time Call Alerts
 *    Insert into local storage (key: real-time-call-alerts value: true)
 */
const isLocalFlagged = (key: string, type: TypeofType): string | boolean | number | GenericObject | null => {
  const value = localStorage.getItem(key)
  try {
    if (type === 'object' && value) return JSON.parse(value)
  } catch (error) {
    console.error('JSON Feature flag override is not formatted properly:', error)
  }

  // LocalStorage returns booleans as a string so we coerse to booleans.
  if (value === 'false') return false
  if (value === 'true') return true

  return value
}

export const isFlagged = (key: string, defaultValue: boolean | any = DEFAULT_VALUE) => {
  const localOverride = isLocalFlagged(key, typeof defaultValue)

  if (localOverride != null) {
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`%c***\n-- LAUNCH DARKLY OVERRIDE:  ${key}:${localOverride}\n***`, 'color: #76b027;')
    }
    return localOverride
  }

  if (ldClient && !window.location.href.match(DISABLE_QUERY_PARAM_KEY)) {
    return ldClient.variation(key, defaultValue)
  } else {
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.warn(' ## featureFlag has not been initialized')
    }
    return defaultValue
  }
}

export const getAllFlagValues = (): AllFlagValues => {
  let flagValues: AllFlagValues = {}
  if (ldClient) {
    flagValues = ldClient.allFlags()
    for (const key of Object.keys(flagValues)) {
      const localOverride = isLocalFlagged(key, 'boolean')
      if (localOverride != null) {
        flagValues[key] = localOverride
      }
    }
  }
  return flagValues
}
