var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block dial\">\n  <div class=\"sub-subtitle\">Call Forwarding</div>\n  <div>Select where the call should be transferred to</div>\n  <div class=\"picker\">\n    <label for=\"dialUserQueueSkill\">Dial a ringDNA User, Call Queue, Skill or Record Owner:</label><br/>\n    <div></div>\n    <div class=\"col-xs-1\">\n      <input id=\"dialUserQueueSkill\" type=\"radio\" name=\"dialType\" data-key=\"dialType\" class=\"dialType\" value=\"\"/>\n    </div>\n    <div class=\"col-xs-11 users-queues-skills-view\"></div>\n    <span class=\"error-step ugn\"></span>\n  </div>\n  <div class=\"center\">OR</div>\n  <div class=\"manual picker\">\n    <label for=\"dialManual\">Dial a Phone Number:</label><br />\n    <input id=\"dialManual\" type=\"radio\" name=\"dialType\" data-key=\"dialType\" class=\"dialType\" value=\"multiNumber\"/>\n\n    <div class=\"value\">\n      <input type=\"text\" data-key=\"phoneNumbers\" class=\"input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"phoneNumbers") : stack1), depth0))
    + "\"/>\n    </div>\n  </div>\n  <div id=\"record-call-flow-div\">\n    <input type=\"checkbox\" class=\"input record-call-flow\" data-key=\"record-call-flow\"/> Record All Parties\n    <span class=\"glyphicon glyphicon-question-sign blue queueTimeoutTooltip\" data-toggle=\"tooltip\" data-original-title=\"Calls are be recorded based on your account settings and the state or country policies. Select this checkbox to override account settings and record all parties for all calls that enter this dial step.\"></span>\n  </div>\n  <div id=\"dial-call-disposition-not-required-div\">\n    <input type=\"checkbox\" class=\"input dial-call-disposition-not-required\" data-key=\"dial-call-disposition-not-required\"/> Call Disposition Not Required\n  </div>\n  <div id=\"skip-call-recording-notification-div\" class=\"hidden\">\n    <input type=\"checkbox\" class=\"input\" data-key=\"skip-call-recording-notification\"/> Do not play call recording notification\n  </div>\n  <span class=\"error-step phoneNumbers\"></span>\n  <div class=\"dial-queue-settings\">\n    <div class=\"sub-subtitle\">Call Queue Dial Settings</div>\n    <p>\n      <input type=\"checkbox\" class=\"input\" data-key=\"gotoNextStepIfNoSubscribers\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"gotoNextStepIfNoSubscribers") : stack1), depth0))
    + "\" />\n      Go to No Answer step if Call Queue members are offline or there are no subscribers?\n    </p>\n    <div>\n      <label class=\"hold-music\">Please select hold music (optional):</label>\n      <label class=\"fileupload hold-music\">\n        <strong class=\"selected-file\">Select an MP3 file from your computer</strong>\n        <input class=\"fileupload\" type=\"file\" name=\"audioFile\" accept=\".mp3\" data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"hold-music-id") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"hold-music-name") : stack1), depth0))
    + "\" />\n      </label>\n      <div class=\"spinner hold-music\">\n        <span class=\"icon-spinner\"></span>\n      </div>\n    </div>\n  </div>\n  <p>Timeout after <input type=\"text\" class=\"input\" data-key=\"timeout\" maxlength=\"3\" size=\"7\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"timeout") : stack1), depth0))
    + "\" placeholder=\"&nbsp;&nbsp;&nbsp;0-999\" />\n    <span class=\"queue-timeout\"></span>\n    seconds\n    <span class=\"glyphicon glyphicon-question-sign blue queueTimeoutTooltip\" data-toggle=\"tooltip\" data-original-title=\"Timeout values are set at the call queue level.\"></span>\n    <span class=\"error-step dialTimeout\"></span>\n  </p>\n  <div class=\"append-text\">\n    <div class=\"sub-subtitle\">What text would you like to append to the Call Log activity?</div>\n    <b>Subject</b><br />\n    <input type=\"text\" class=\"input\" data-key=\"calllog-subject\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"calllog-subject") : stack1), depth0))
    + "\" /><br />\n    <b>Comments</b><br />\n    <textarea cols=\"80\" rows=\"10\" data-key=\"calllog-comments\" class=\"input\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"calllog-comments") : stack1), depth0))
    + "</textarea>\n  </div>\n</div>\n";
},"useData":true});