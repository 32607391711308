import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function LeaderboardIcon({ size = 18, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <rect x="3.25" y="3.25" width="0.5" height="17.5" rx="0.25" fill={color} stroke={color} strokeWidth="0.5" />
      <mask id="path-2-inside-1_10185_20286" fill="white">
        <path d="M3 20H20.5C20.7761 20 21 20.2239 21 20.5C21 20.7761 20.7761 21 20.5 21H3V20Z" />
      </mask>
      <path d="M3 20H20.5C20.7761 20 21 20.2239 21 20.5C21 20.7761 20.7761 21 20.5 21H3V20Z" fill={color} />
      <path
        d="M3 20V19H2V20H3ZM3 21H2V22H3V21ZM3 21H20.5V19H3V21ZM20.5 20H3V22H20.5V20ZM4 21V20H2V21H4ZM20 20.5C20 20.2239 20.2239 20 20.5 20V22C21.3284 22 22 21.3284 22 20.5H20ZM20.5 21C20.2239 21 20 20.7761 20 20.5H22C22 19.6716 21.3284 19 20.5 19V21Z"
        fill="black"
        mask="url(#path-2-inside-1_10185_20286)"
      />
      <rect x="6.5" y="15.5" width="12" height="2" rx="0.5" stroke={color} />
      <rect x="6.5" y="7.5" width="5" height="2" rx="0.5" stroke={color} />
      <rect x="6.5" y="11.5" width="9" height="2" rx="0.5" stroke={color} />
    </svg>
  )
}

export default LeaderboardIcon
