import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import RdnaButton, { variants } from '../RdnaButton'
import { IconButton } from '@mui/material'
import styled from 'styled-components'
import RdnaText, { colorpalettes } from '../RdnaText'
import Pause from '../../assets/icons/player/Pause'
import Play from '../../assets/icons/player/Play'
import Delete from '../../assets/icons/svg/Delete'
import { PageAudioContext } from '../contexts/PageAudioContext'
import IconWithThemeColor from '../../assets/icons/IconWithThemeColor'

export type FileChangeEvent = {
  name: string
  fileName: string | undefined
  fileBinary: File | null
}

export type UploadFileType = '.mp3' | '.wav' | undefined

type Props = {
  onChange: (arg0: FileChangeEvent) => void
  name: string
  mbFileSizeLimit?: number
  onError: (error: string) => void
  existingFile?: string
  placeholderText?: string
  disabled?: boolean
  fileType?: UploadFileType
  label?: string
  variant?: keyof typeof variants
  color?: keyof typeof colorpalettes
}

const FilePickerContainer = styled.div`
  display: inline-flex;
  align-items: center;
`

const StyledType = styled(RdnaText)`
  margin: 10px;
  min-width: 120px;
`

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 3px;
  }
`

const IconContainer = styled.div`
  display: inline-flex;
  margin: 4px;
`

export default function RdnaFilePicker({
  onChange,
  name,
  fileType,
  existingFile,
  placeholderText,
  disabled,
  label = 'Browse',
  variant = 'contained',
  color = 'primary',
  mbFileSizeLimit,
  onError
}: Props) {
  const existingFileName = existingFile ? existingFile.split('/').pop()?.split('?').shift() : ''
  const [audioSrc, setAudioSrc] = useState(existingFile || '')
  const inputEl = useRef<HTMLInputElement>(null)
  const audioEl = useRef<HTMLAudioElement>(null)
  const [fileName, setFileName] = useState(existingFileName)
  const [isPlaying, setIsPlaying] = useState(false)
  const { currentAudioPlaying, setCurrentAudioPlaying } = useContext(PageAudioContext)

  function isFileSizeTooLarge(fileSize: number): boolean {
    const megaByte = 1000000
    let fileTooLarge = false

    const megaByteFileSize = fileSize / megaByte

    if (mbFileSizeLimit) {
      if (megaByteFileSize > mbFileSizeLimit) {
        onError(
          `This file could not be uploaded because it exceeds the maximum file size limit of ${mbFileSizeLimit}MB. Please create a smaller file and try again.`
        )

        fileTooLarge = true
      }
    }

    return fileTooLarge
  }

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && isFileSizeTooLarge(event.target.files[0]?.size)) return null
    if (event.target.files) {
      const fullFilePath = event.target.value.split('\\')
      const newFile = fullFilePath.pop()
      setFileName(newFile || event.target.files[0]?.name || '')
      onChange({ name: name, fileName: newFile, fileBinary: event.target.files[0] })
    }
    if (audioEl.current && event.target.files) {
      setAudioSrc(URL.createObjectURL(event.target.files[0]))
    }
  }

  const openFileDialog = () => {
    if (inputEl.current) {
      inputEl.current.click()
    }
  }

  const onPlayPauseClick = useCallback(() => {
    setIsPlaying(!isPlaying)
  }, [isPlaying, setIsPlaying])

  useEffect(() => {
    if (audioEl.current) {
      if (isPlaying) {
        audioEl.current.play()
        setCurrentAudioPlaying(audioSrc)
      } else {
        audioEl.current.pause()
      }
    }
  }, [isPlaying, setCurrentAudioPlaying, audioSrc])

  useEffect(() => {
    if (currentAudioPlaying !== audioSrc && audioEl.current) {
      setIsPlaying(false)
    }
  }, [currentAudioPlaying, audioSrc])

  const onDeleteClick = useCallback(() => {
    setFileName('')
    setIsPlaying(false)
    onChange({ name: name, fileName: '', fileBinary: null })
  }, [setFileName, onChange, name, setIsPlaying])
  return (
    <FilePickerContainer>
      <RdnaButton variant={variant} color={color} text={label} disabled={disabled} onClick={openFileDialog} />
      <input
        type="file"
        multiple={false}
        name={name}
        accept={fileType}
        ref={inputEl}
        data-testid={'file-upload'}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      {fileName ? (
        <StyledType variant="meta" align="left" color={!disabled ? 'primary' : 'neutral'}>
          {fileName}
        </StyledType>
      ) : (
        <StyledType variant="meta" align="left" color="neutral">
          {placeholderText}
        </StyledType>
      )}
      <audio ref={audioEl} src={audioSrc} id="sound" />
      {!disabled && fileName && (fileType === '.mp3' || fileType === '.wav') && (
        <StyledIconButton size="small" aria-label="play" onClick={onPlayPauseClick}>
          <IconContainer>
            <IconWithThemeColor size={15} icon={isPlaying ? Pause : Play} color={'link'} />
          </IconContainer>
        </StyledIconButton>
      )}
      {!disabled && fileName && (
        <StyledIconButton size="small" aria-label="delete" onClick={onDeleteClick}>
          <Delete size={18} />
        </StyledIconButton>
      )}
    </FilePickerContainer>
  )
}
