import React, { useState } from 'react'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { usePutAccountLockedSettings, useUpdateAccount } from '../../../store/api/accounts-api'
import useToast from '../../common/hooks/useToast'

type props = {
  accountSettings: { [key: string]: any }
  lockedSettings: { [key: string]: any }
}

export default function SaveButton({ accountSettings, lockedSettings }: props) {
  const toastr = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const updateAccount = useUpdateAccount()
  const putAccountLockedSettings = usePutAccountLockedSettings()

  const onSave = async () => {
    try {
      setIsLoading(true)

      // @ts-ignore
      await updateAccount({ body: accountSettings })
      // @ts-ignore
      await putAccountLockedSettings({ body: lockedSettings })

      toastr.success('Account Settings Saved')
      setIsLoading(false)
    } catch (error) {
      toastr.error(`An error has occurred, ${error}`)
      setIsLoading(false)
    }
  }

  return (
    <RdnaButton
      style={{ width: 'fit-content' }}
      disabled={isLoading}
      text={isLoading ? 'Saving...' : 'Save'}
      name="save-support-settings"
      onClick={onSave}
    />
  )
}
