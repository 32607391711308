export const API_BASE_URL = '/api/v2/app/nylas/users'

export enum Provider {
  ALL = '',
  AOL = 'AOL',
  EXCHANGE = 'EXCHANGE',
  GMAIL = 'GMAIL',
  HOTMAIL = 'HOTMAIL',
  ICLOUD = 'ICLOUD',
  IMAP = 'IMAP',
  YAHOO = 'YAHOO'
}

export const providerOptions = [
  { label: 'All', value: Provider.ALL },
  { label: 'Gmail', value: Provider.GMAIL },
  { label: 'Microsoft', value: Provider.EXCHANGE }
]
