BaseGrid = require('base/grid')
BaseView = require('base/view')
BackgridCell = require('helpers/backgrid-cell')
UserSkillModal = require('./editor/view')
UserSkillModel = require('./model')
UserSkillCollection = require('./collection')

class UserSkillView extends BaseView

  template: require('./template')

  className: 'userSkill'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add-user-skill': 'showEditor'

  bindings:
    '.add-user-skill' : 'toggle:userCanAddSkill,classes:{"disabled":not(skillsExists)}'

  computeds:
    userCanAddSkill:
      get: -> (App.session.user.isSupport() or App.session.user.isAdmin()) and not @user.get('isDeleted')
    skillsExists:
      get: ->
        !!@user.get('account').skills.length

  initialize: (options) ->
    { @user } = options
    @collection = new UserSkillCollection(@user.get('userSkills'))

  onRender: ->
    @showChildView('grid', @buildGrid())

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    editCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#user-skill-modal">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'skill.name',   label: 'Name',             cell: 'string' },
      { name: 'level',        label: 'Level',            cell: 'String' },
      { name: 'lastCallDate', label: 'Most Recent Call', cell: datetime }
    ]
    columns.push(
      { name: '', label: 'Update', cell: editCell },
      { name: '', label: 'Delete', cell: BaseGrid.DeleteCell }
    ) if @getBinding('userCanAddSkill')

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No User Skills Found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    if options.model
      model = new UserSkillModel(
        id: options.model.get('id')
        skillId: options.model.get('skill').id
        level: options.model.get('level')
        userId: @user.get('id')
      )
    else
      model = new UserSkillModel(
        skillId: null
        level: null
        userId: @user.get('id')
      )
    @showChildView('modal', new UserSkillModal(model: model, accountId: @user.get('account').id, userSkillCollection: @collection))

module.exports = UserSkillView
