import React from 'react'
import { Colors } from '../../../constants/colors'

type Props = {
  color?: Colors
  fill?: string
  size?: number
  innerFill?: Colors
}

const LibrariesIcon = ({ color = Colors.N100, fill, size = 30, innerFill = color }: Props) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <rect width={30} height={30} rx={8} fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6403 15.7276C13.6112 15.7123 13.5763 15.7123 13.5472 15.7276L11.2934 16.9125C11.22 16.9511 11.1343 16.8888 11.1483 16.8071L11.5787 14.2974C11.5843 14.265 11.5735 14.2319 11.55 14.2089L9.72658 12.4315C9.66722 12.3737 9.69997 12.2729 9.782 12.2609L12.3019 11.8948C12.3344 11.8901 12.3626 11.8696 12.3772 11.8401L13.5041 9.5567C13.5408 9.48237 13.6467 9.48237 13.6834 9.5567L14.8103 11.8401C14.8249 11.8696 14.8531 11.8901 14.8856 11.8948L17.4055 12.2609C17.4875 12.2729 17.5203 12.3737 17.4609 12.4315L15.6375 14.2089C15.614 14.2319 15.6032 14.265 15.6088 14.2974L16.0392 16.8071C16.0532 16.8888 15.9675 16.9511 15.8941 16.9125L13.6403 15.7276Z"
      fill={innerFill}
      stroke={color}
      strokeLinejoin="round"
    />
    <rect x={5.625} y={5.625} width={15.9375} height={15.9375} rx={0.5} stroke={color} strokeWidth={1} />
    <path
      d="M21.5625 8.4375V9.375H23.4375V23.4375H9.375V21.5625H8.4375V23.875C8.4375 24.1511 8.66136 24.375 8.9375 24.375H23.875C24.1511 24.375 24.375 24.1511 24.375 23.875V8.9375C24.375 8.66136 24.1511 8.4375 23.875 8.4375L21.5625 8.4375Z"
      fill={color}
    />
  </svg>
)

export default LibrariesIcon
