StepModel = require('../../model')

class HangupStepModel extends StepModel

  defaults:
    args: {}
    type: 'Hangup'
    info: 'End the call'
    leaf: true

module.exports = HangupStepModel
