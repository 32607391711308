import { ButtonGroupButtonOptions } from '../RdnaFormElements/RdnaButtonGroup'
import { ApiBase } from './index'

type ChatRooms = ButtonGroupButtonOptions & {
  apiPath: string
  apiBase: ApiBase
  queryParams?: {
    [key: string]: string
  }
  starterQuestions?: string[]
}

export const revIqId = 'rev-iq'

export const chatRooms: ChatRooms[] = [
  {
    label: 'Contact Search [gpt-3.5-turbo]',
    value: 'contact',
    apiPath: '/demo2/dnb',
    apiBase: 'apiLabs'
  },
  {
    label: 'Streaming RevenueGPT [Llama-2-70b-chat-hf]',
    value: 'revenuegpt-llama-streaming',
    apiBase: 'apiGpu',
    apiPath: '/demo/pdf'
  },
  {
    label: 'RevenueGPT [gpt-3.5-turbo]',
    value: 'revenuegpt-openai',
    apiPath: '/demo/pdf',
    apiBase: 'apiLabs'
  },
  {
    label: 'RevenueGPT [claude-instant-1.2]',
    value: 'revenuegpt-anthropic',
    apiPath: '/demo/pdf',
    apiBase: 'apiLabs',
    queryParams: {
      anthropic: '1'
    }
  },
  {
    label: 'Ask Revenue about Conversation',
    value: 'ask-revenue-conversation',
    apiPath: '/llm-svc/conversation',
    apiBase: 'apiPlay',
    starterQuestions: [
      'Can you highlight any significant moments or turning points in the conversation?',
      'Are there any notable quotes or statements made during this conversation?',
      'Summarize any objections from this conversation and provide answers in bullet form.',
      'Summarize into bullets the customer pain points brought up on the call.'
    ]
  },
  {
    label: 'Ask Revenue about Opportunity',
    value: 'ask-revenue-opportunity',
    apiPath: '/llm-svc/opportunity',
    apiBase: 'apiPlay',
    starterQuestions: [
      'Summarize the conversations that lead to key changes in the opportunity.',
      'What are their most important metrics?',
      'Who are the key stakeholders involved?',
      'Please provide me a checklist of 5 items to move this deal forward.'
    ]
  },
  {
    label: 'FinanceGPT [gpt-3.5-turbo]',
    value: 'financegpt-openai',
    apiPath: '/demo/finance',
    apiBase: 'apiLabs'
  },
  {
    label: 'FinanceGPT [claude-instant-1.2]',
    value: 'financegpt-anthropic',
    apiPath: '/demo/finance',
    apiBase: 'apiLabs',
    queryParams: {
      anthropic: '1'
    }
  },
  {
    label: 'RevIQ [gpt-3.5-turbo]',
    value: revIqId,
    apiPath: '/demo/persona',
    apiBase: 'apiLabs'
  },
  {
    label: 'MEDDICC [Llama-2-70b-chat-hf]',
    value: 'revenuegpt-llama-streaming-meddicc',
    apiBase: 'apiGpu',
    apiPath: '/demo/pdf',
    queryParams: {
      methodology: 'Meddicc'
    }
  },
  {
    label: 'Lumen [gpt-3.5-turbo]',
    value: 'revenuegpt-openai-lumen',
    apiBase: 'apiLabs',
    apiPath: '/demo/pdf',
    queryParams: {
      customer: 'Lumen'
    }
  },
  {
    label: 'Lumen [Llama-2-70b-chat-hf]',
    value: 'revenuegpt-llama-streaming-lumen',
    apiBase: 'apiGpu',
    apiPath: '/demo/pdf',
    queryParams: {
      customer: 'Lumen'
    }
  },
  {
    label: 'GTMGPT [gpt-3.5-turbo]',
    value: 'gtmgpt',
    apiPath: '/demo/gtmgpt',
    apiBase: 'apiLabs'
  },
  {
    label: 'NutanixGPT [gpt-3.5-turbo]',
    value: 'nutanixgpt',
    apiPath: '/demo/nutanixgpt',
    apiBase: 'apiLabs'
  },
  {
    label: 'NutanixGPT [claude-instant-1.2]',
    value: 'nutanixgpt-claude',
    apiPath: '/demo/nutanixgpt',
    apiBase: 'apiLabs',
    queryParams: {
      anthropic: '1'
    }
  },
  {
    label: 'NutanixGPT [claude-3-haiku-20240307]',
    value: 'nutanixgpt-haiku',
    apiPath: '/demo/nutanixgpt',
    apiBase: 'apiLabs',
    queryParams: {
      haiku: '1'
    }
  },
  {
    label: 'Support [gpt-3.5-turbo]',
    value: 'support',
    apiPath: '/demo/support',
    apiBase: 'apiLabs'
  },
  {
    label: 'Support [claude-instant-1.2]',
    value: 'support-claude',
    apiPath: '/demo/support',
    apiBase: 'apiLabs',
    queryParams: {
      anthropic: '1'
    }
  },
  {
    label: 'Support [claude-3-haiku-20240307]',
    value: 'support-haiku',
    apiPath: '/demo/support',
    apiBase: 'apiLabs',
    queryParams: {
      haiku: '1'
    }
  }
]

export const GenAiEvents = {
  OPEN_CHAT: 'Open Chat',
  CLOSE_CHAT: 'Close Chat',
  SEND_MESSAGE: 'Send Message'
}
