import React, { Suspense } from 'react'
import styled from 'styled-components'

import { INITIAL_ROUTE } from '../Routing'
import { INSIGHT_SUBROUTES, RoutePaths } from '../LeftNav'
import { NavigationItem } from '@ringdna/common/src/components/RdnaNav/RdnaLeftNav'
import { ReportFilterType, CustomReportType } from '@ringdna/common/src/components/RdnaReports/types'
import { ReportParams } from './api'

import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import GutterContainer from '../common/GutterContainer'
import Report from '../common/Report'

const QUESTIONS = [
  'activity-record-percent',
  'activity-time-day',
  'activity-dispositions',
  'activity-call-directions',
  'activity-local-presence',
  'keyword-group-heatmap',
  'moments-notifications-by-disposition'
]

export const getFiltersForReport = (routePath: RoutePaths): ReportFilterType[] => {
  switch (routePath) {
    case RoutePaths.DETAIL_ROUTE:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.COACHING_TYPE,
        ReportFilterType.COACHING_DIRECTION,
        ReportFilterType.RELATIONSHIP
      ]
    case RoutePaths.ETIQUETTE_VIDEO_ROUTE:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION
      ]
    case RoutePaths.ETIQUETTE_PHONE_ROUTE:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.CALL_DISPOSITION,
        ReportFilterType.LOCATION
      ]
    case RoutePaths.OCCURRENCE_ROUTE:
    case RoutePaths.OVER_TIME_ROUTE:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.CALL_DISPOSITION,
        ReportFilterType.CONVERSATION_TYPE,
        ReportFilterType.LOCATION,
        ReportFilterType.KEYWORD_GROUP,
        ReportFilterType.SPEAKER_TYPE
      ]
    case RoutePaths.VIDEO_CONVO_ROUTE:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.CALL_DISPOSITION,
        ReportFilterType.CONVERSATION_TYPE,
        ReportFilterType.LOCATION,
        ReportFilterType.KEYWORD_GROUP
      ]
    case RoutePaths.MOMENTS_DISPOSITIONS:
    case RoutePaths.MOMENTS_OUTCOMES:
    case RoutePaths.MOMENTS_CONTENT_LINKS:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.DISPOSITION_OUTCOME,
        ReportFilterType.MOMENTS_CATEGORY,
        ReportFilterType.MOMENTS_CONTENT_TYPE,
        ReportFilterType.TRIGGERING_CRITERIA,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_INTERACTIONS:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.DISPOSITION_OUTCOME,
        ReportFilterType.MOMENTS_CATEGORY,
        ReportFilterType.MOMENTS_CONTENT_TYPE,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_COMPLIANCE:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.NOTIFICATION_NAMES,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_BEHAVIORAL_INSIGHTS:
    case RoutePaths.MOMENTS_CONVERSATION_CONVERSION:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_CHECKLIST_ADHERENCE:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.DISPOSITION_OUTCOME,
        ReportFilterType.TRIGGERING_CRITERIA,
        ReportFilterType.NOTIFICATION_NAMES,
        ReportFilterType.MOMENTS_CATEGORY,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_DELIVERY:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.NOTIFICATION_NAMES,
        ReportFilterType.MOMENTS_CATEGORY,
        ReportFilterType.MOMENTS_CONTENT_TYPE,
        ReportFilterType.TRIGGERING_CRITERIA,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    case RoutePaths.MOMENTS_FREQUENCY:
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.DISPOSITION_OUTCOME,
        ReportFilterType.MOMENTS_CATEGORY,
        ReportFilterType.MOMENTS_CONTENT_TYPE,
        ReportFilterType.TRIGGERING_CRITERIA,
        ReportFilterType.NOTIFICATION_NAMES,
        ReportFilterType.CALL_TYPE,
        ReportFilterType.CALL_DURATION
      ]
    default:
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION
      ]
  }
}

type LazyLoadReport = React.LazyExoticComponent<(props: ReportParams) => JSX.Element | null> | undefined

// CUSTOM METABASE REPORT IMPORTS
// these dynamic imports are mapped by route (ie metabaseId)
const CUSTOM_METABASE_REPORTS: Record<string, LazyLoadReport> = {
  '/keyword-group-heatmap': React.lazy(() => import('./apiComponents/KeywordGroupHeatmap'))
}

// TODO: We wouldn't have to search through subroutes if we stored
// all of our routes in a Map<routeId, NavigationRoute>

const getRouteInfo = (
  routes: NavigationItem[],
  id = INITIAL_ROUTE.id,
  defaultRoute = INITIAL_ROUTE
): NavigationItem => {
  for (const curRoute of routes) {
    if (curRoute.id === id) return curRoute
    else if (curRoute.subroutes) {
      const match = getRouteInfo(curRoute.subroutes, id, curRoute)
      if (match !== curRoute) return match
    }
  }
  return defaultRoute
}

export default function MetabaseRoute() {
  const pathname = window.location.pathname
  const metabaseId = pathname.split('/insights/')[1]
  const { title, subTitle } = getRouteInfo(INSIGHT_SUBROUTES, pathname)
  const isDashboard = QUESTIONS.indexOf(metabaseId || '') === -1
  const customMetabaseReport = CUSTOM_METABASE_REPORTS[`/${metabaseId}`]

  if (!metabaseId) return null

  return (
    <Container>
      <GutterContainer width="100%">
        <ContentContainer>
          <Suspense
            fallback={
              <RdnaLoader data loading>
                {null}
              </RdnaLoader>
            }
          >
            <Report
              id={metabaseId}
              title={title}
              description={subTitle}
              filters={getFiltersForReport(pathname as RoutePaths)}
              CustomReport={customMetabaseReport as CustomReportType}
              isDashboard={isDashboard}
            />
          </Suspense>
        </ContentContainer>
      </GutterContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const ContentContainer = styled.div`
  flex: 1;
`
