BaseView        = require('base/view')
MetabaseViewModel = require('features/reports/metabase/view-model')
MetabaseView = require('features/reports/metabase/view')

class CallRecordingView extends BaseView
  template: require('./template')
  className: 'call-recordings-view'

  regions:
    container  : '.reporting-container'

  initialize: (options) =>
    @qs = options.qs
    @qs.setFragment('call-recordings')

  onRender: ->
    viewModel = new MetabaseViewModel(
      qs: @qs
      name: 'call-recordings'
      title:  'Call Recordings'
    )

    @showMetabaseView(viewModel)

  showMetabaseView: (viewModel) =>
    view = new MetabaseView(viewModel: viewModel, userPickerPlaceholder: 'Agent Name')
    @showChildView('container', view)

module.exports = CallRecordingView
