import { HttpMethods } from '@ringdna/common/src/client/constants'
import { ServiceUser } from '@ringdna/common/src/types/service-user'
import { createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

type ServiceUserParam = {
  query: {
    accountId: number
  }
}

export const useFetchServiceUser = createUseFetchAction<{ serviceUser: ServiceUser }, ServiceUserParam>({
  key: 'get-service-user',
  path: '/api/v2/temporary/getServiceUser'
})

export const useCreateServiceUser = createUseFetchAction<ServiceUser, ServiceUserParam>({
  key: 'post-service-user',
  path: '/api/v2/temporary/createServiceUser',
  method: HttpMethods.Post
})
