import { formatOrderDescriptions, formatRulesDescriptions, typeRecordCell } from './utils/contextReducer'

export const standardSalesforceTypes = ['Account', 'Case', 'Campaign', 'Contact', 'Opportunity']

export const relatedRecordsColumns = [
  {
    Header: 'Record Type',
    accessor: 'typeRecord',
    disableSortBy: true,
    Cell: (data: { value: string; row: any }) => typeRecordCell(data.value, data.row.original)
  },
  {
    Header: 'Search',
    accessor: 'searches',
    disableSortBy: true,
    className: 'rules-column',
    Cell: (data: { value: string[]; row: any }) =>
      formatRulesDescriptions(data.value, data.row.original.listAvailableSearches)
  },
  {
    Header: 'Suggest',
    accessor: 'suggests',
    disableSortBy: true,
    className: 'rules-column',
    Cell: (data: { value: string[]; row: any }) =>
      formatRulesDescriptions(data.value, data.row.original.listAvailableSuggests)
  },
  {
    Header: 'View',
    accessor: 'views',
    disableSortBy: true,
    className: 'rules-column',
    Cell: (data: { value: string[]; row: any }) =>
      formatRulesDescriptions(data.value, data.row.original.listAvailableViews)
  },
  {
    Header: 'Sort By',
    accessor: 'orderBy',
    disableSortBy: true,
    width: 100,
    Cell: (data: { value: string; row: any }) =>
      formatOrderDescriptions(data.value, data.row.original.listAvailableOrders)
  },
  {
    Header: 'Results',
    accessor: 'limit',
    disableSortBy: true,
    width: 80,
    styles: {
      align: 'center'
    }
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }
