import { FieldValues } from '../types'
import { InputField } from '@ringdna/common/src/types/form-inputs'
import { InputTypes } from '@ringdna/common/src/constants/input-types'

export const CREATE_SCHEDULING_PAGE_CTA = 'Create Scheduling Page'

export const eventDurationFieldOptionDefault = '30'

export const eventNameField = (defaultValues: FieldValues): InputField => ({
  label: 'Event Name',
  type: InputTypes.TEXT,
  name: 'name',
  defaultValue: getDefault('name', defaultValues),
  required: true,
  maxTextLength: 60
})

export const eventDurationFieldOptions = [
  { label: '60 Minutes', value: '60' },
  { label: '45 Minutes', value: '45' },
  { label: '30 Minutes', value: '30' },
  { label: '15 Minutes', value: '15' }
]

const getDefault = (name: string, defaultValues: FieldValues = {}, defaultValue = '') =>
  typeof defaultValues[name] !== 'undefined'
    ? defaultValues[name] === null
      ? ''
      : `${defaultValues[name]}`
    : defaultValue

export const getSchedulerFormData = (
  editValues: FieldValues
): { formFields: InputField[]; formInitialValues: FieldValues } => {
  const formFields = [eventNameField(editValues), eventDurationField(editValues)]

  const formInitialValues: FieldValues = {}
  formFields.forEach(({ name, defaultValue }) => {
    if (name && defaultValue) formInitialValues[name] = defaultValue
  })

  return { formFields, formInitialValues }
}

export const eventDurationField = (defaultValues: FieldValues): InputField => ({
  label: 'Event Duration',
  type: InputTypes.DROPDOWN,
  name: 'duration',
  options: eventDurationFieldOptions,
  defaultValue: getDefault('duration', defaultValues, eventDurationFieldOptionDefault),
  required: true
})
