BaseCollection = require('base/collection')

class SelectedUsersAndEmailsCollection extends BaseCollection
  url: =>
    "teams/#{@teamId}/notifications/selectedUsersAndEmails"

  initialize: (models, options) =>
    @teamId = options.teamId

  toJSON: =>
    fields = {}
    emailsCounter = 0
    usersCounter = 0
    fields['teamId'] = @teamId
    @each((model) =>
      if !!model.get('email')
        fields["emails[#{emailsCounter}]"] = model.get('email')
        emailsCounter++
      else
        fields["userIds[#{usersCounter}]"] = model.get('id')
        usersCounter++
    )
    fields

  persist: (options) =>
    defaults =
      data        : @toJSON()
      emulateJSON : true
      url         : @url()

    options = _.extend(defaults, options)
    Backbone
      .sync('update', @, options)
      .done()

module.exports = SelectedUsersAndEmailsCollection