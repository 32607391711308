import React, { useMemo } from 'react'
import { FormSelectOption } from '@ringdna/common/src/types/form-inputs'
import styled from 'styled-components'
import { usePlayPicklist } from '@ringdna/common/src/components/RdnaReports/api'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'

type Props = {
  accountId: string
  value: string
  onChoose: (a: number | null) => void
}

export default function UserPicklist({ accountId, value, onChoose }: Props) {
  const [usersPayload, , loading] = usePlayPicklist({
    meta: { accountId: parseInt(accountId), name: '', type: 'users', pageSize: 1000 }
  })

  const options: FormSelectOption[] = useMemo(() => {
    let options = [{ label: '(none)', value: '' }]
    if (usersPayload) {
      options = options.concat(usersPayload.map(val => ({ label: val.text, value: val.value })))
    }
    return options
  }, [usersPayload])

  return (
    <Container>
      {!loading && (
        <RdnaSelect
          onChange={e => onChoose(e.target.value !== '' ? parseInt(e.target.value) : null)}
          name={'undeliverable-SMS-message-user'}
          options={options}
          value={value || ''}
          numOfRows={6}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 300px;
`
