BaseGrid                = require('base/grid')
BaseView                = require('base/view')
BaseModel               = require('base/model')
BaseCollection          = require('base/collection')
BackgridCell            = require('helpers/backgrid-cell')
BackgridRow             = require('helpers/backgrid-row')
PagedCollection         = require('base/paged-collection')
PagerView               = require('widgets/pager/view')
LocationPickerView      = require('widgets/location-picker/view')
LocationCollection      = require('widgets/location-picker/collection')

class ListSelectedSalesforceUsers extends BaseView
  template: require('./template')

  regions:
    grid           : 'div.grid'
    locations      : 'div.locations'

  ui:
    content        : '.content'

  events:
    'keyup .form-control.input-sm'  : 'onlyNumbers'
    'click .assign-checkbox': 'setTotalValue'

  bindings:
    '.area-code' : 'value:areaCode'
    '.extension' : 'value:extension'
    '.assign-area-code' : 'checked:assignAreaCode,enabled:areaCode'
    '.assign-extension' : 'checked:assignExtension,enabled:extension'
    '.assign-location'  : 'checked:assignLocation,enabled:location'

  initialize: (options) =>
    { @collection, @accountId } = options
    @locations = new LocationCollection()
    @viewModel = new Backbone.Model(
      areaCode: null
      extension: null
      location: null
      assignAreaCode: false
      assignExtension: false
      assignLocation: false
    )

  onRender: =>
    @locations
      .fetch(data: accountId: @accountId)
      .done( =>
        return unless @getRegion('locations')
        @locationPicker = new LocationPickerView(
          accountId: @accountId
          fullList: true
          locations: @locations
          emptyOption: 'Select location'
        )
        @showChildView('locations', @locationPicker)
        @listenTo(@locationPicker, 'change', (value) =>
          @viewModel.set(location: value)
          if not value
            @viewModel.set(assignLocation: false)
            $('.locations').prop('disabled', false)
        )
        @showChildView('grid', @buildGrid(@collection))
      )
  onAttach: =>
    @listenTo(@collection, 'remove', (model) =>
      @collection.trigger('removeFromSelectedView', model.get('salesforceId'))
    )

  onDetach: =>
    @stopListening(@collection, 'remove')

  @getLicenses: =>
    [{text:'RingDNA Dialer', value:'dialerLicense'},
      {text:'Administrator',  value:'adminLicense'},
      {text:'Calendar Booking', value:'calendarLicense'},
      {text:'Guided Selling', value:'gsLicense'},
      {text:'Conversation AI',value:'caiLicense'},
      {text:'Moments™',       value:'momentsLicense'}]

  onlyNumbers: (e) =>
    value = $(e.target).val()
    $(e.target).val(value.replace(/[^0-9]/g, ''))
    @checkEmptyTotalValue(e) if not value
    @viewModel.set('areaCode', $(e.target).val()) if e.currentTarget.classList.contains('area-code')
    @viewModel.set('extension', $(e.target).val()) if e.currentTarget.classList.contains('extension')

  setTotalValue: (e) =>
    $('.areaCodeCell').prop('disabled', e.target.checked) if e.currentTarget.classList.contains('assign-area-code')
    $('.extensionCodeCell').prop('disabled', e.target.checked) if e.currentTarget.classList.contains('assign-extension')
    $('.locations').prop('disabled', e.target.checked) if e.currentTarget.classList.contains('assign-location')

  checkEmptyTotalValue: (e) =>
    if e.currentTarget.classList.contains('area-code')
      @viewModel.set('assignAreaCode', false)
      $('.areaCodeCell').prop('disabled', false)
    if e.currentTarget.classList.contains('extension')
      @viewModel.set('assignExtension', false)
      $('.extensionCodeCell').prop('disabled', false)

  buildGrid: (collection) =>
    mainClass = @
    areaCodeCell = class extends Backgrid.Cell
      events:
        'change .areaCodeCell': 'areaCodeChanged'

      areaCodeChanged: (e) =>
        value = $(e.target).val()
        $(e.target).val(value.replace(/[^0-9]/g, ''))
        @model.set('areaCode', $(e.target).val())

      render: =>
        disabled = if mainClass?.viewModel?.get('assignAreaCode') then 'disabled' else ''
        html = """<input type='text' class='form-control input-sm areaCodeCell' #{disabled}/>"""
        $(@el).html(html)
        @

    extensionCell = class extends Backgrid.Cell
      events:
        'change .extensionCodeCell': 'extensionChanged'

      extensionChanged: (e) =>
        value = $(e.target).val()
        $(e.target).val(value.replace(/[^0-9]/g, ''))
        @model.set('extensionCode', $(e.target).val())

      render: =>
        disabled = if mainClass?.viewModel?.get('assignExtension') then 'disabled' else ''
        html = """<input type='text' class='form-control input-sm extensionCodeCell' #{disabled}/>"""
        $(@el).html(html)
        @

    locationCell = @selectedLocationCell(@locations)
    licenseCell = @selectedLicenseCell(ListSelectedSalesforceUsers.getLicenses())

    columns = [
      {label: 'Name',         name: 'name',      cell: 'string'}
      {label: 'Email',        name: 'email',     cell: 'string'}
      {label: 'Title',        name: 'title',     cell: 'string'}
      {label: 'Licenses',     name: 'licenses',  cell: licenseCell}
      {label: 'Area Code',    name: 'code',      cell: areaCodeCell}
      {label: 'Extension',    name: 'extension', cell: extensionCell}
      {label: 'Location',     name: 'location',  cell: locationCell}
      {label: 'Remove',       name: 'delete',    cell: BaseGrid.DeleteCell}
    ]

    new BaseGrid.Grid(
      collection : collection,
      columns    : columns,
      emptyText  : 'No users found.'
      onRemove   : @showRemoved
    )

  selectedLocationCell: (locations) =>
    mainClass = @
    class extends Backgrid.Cell
      events:
        'change .locations': 'locationChanged'

      locationChanged: (e) =>
        @model.set('locationId', if $(e.target).val() is 'none' then null else $(e.target).val())

      render: =>
        disabled = if mainClass?.viewModel?.get('assignLocation') then 'disabled' else ''
        html = """<select class='form-control locations' #{disabled}><option value='none'>Select Location</option>"""
        options = ''
        _.each(locations.models, (model) ->
          options = options + """<option value=\"#{model.get('id')}\">#{model.get('name')}</option>"""
        )
        html = html + options + '</select>'
        $(@el).html(html)
        @

  selectedLicenseCell: (licenses) =>
    class extends Backgrid.Cell
      className: 'licenses-cell'
      render: =>
        $(@el).html("<select class='licenses-#{@model.cid}'></select>")
        _.defer(=>
          items = []
          _.each(licenses, (license) => items.push(license.value) if @model.get(license.value))
          ListSelectedSalesforceUsers.initLicensesSelectize(@model, licenses, items)
        )
        @

  @initLicensesSelectize: (model, options, items) =>
    $(".licenses-#{model.cid}").selectize(
      maxItems: 10
      selectOnTab: true
      placeholder: 'Licenses'
      options: options
      items: items
      onChange: (value) =>
        _.each(options, (license) =>
          model.set(license.value, value?.includes(license.value))
        )
    )

  showRemoved: =>
    if @collection.length is 0
      @viewModel.set(
        assignAreaCode: false
        assignExtension: false
        assignLocation: false
      )
    @triggerMethod('selected', @collection.length > 0)

module.exports = ListSelectedSalesforceUsers
