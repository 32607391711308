import React from 'react'
import RdnaText from '../RdnaText'
import styled from 'styled-components'
import IconHelper from '../../assets/icons/IconHelper'

export type SettingRowProps = {
  /** Label of field */
  heading: string
  /** Value of field */
  description: string | React.ReactNode
  /** Content for right side of row, typically a form input. */
  children: React.ReactNode
  icon?: any
  iconSize?: number
}

const SettingContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing}px;
  padding: ${({ theme }) => `${theme.spacing * 3}px ${theme.spacing * 4}px`};
  background-color: ${({ theme }) => theme.palette.background.main};
  margin-bottom: ${({ theme }) => theme.spacing}px;
`
const Heading = styled(RdnaText)`
  display: block;
`

const Description = styled(RdnaText)`
  display: block;
  margin: 0;
`

const RightSideContainer = styled.div`
  max-height: ${({ theme }) => theme.spacing * 6}px;
  min-height: ${({ theme }) => theme.spacing * 4}px;
  display: flex;
  align-self: center;
  align-items: center;
`

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  margin-right: ${({ theme }) => 4 * theme.spacing}px;
`

const RdnaSettingRow = ({ heading, description, children, icon, iconSize }: SettingRowProps) => (
  <SettingContainer>
    <LeftSideContainer>
      {icon && (
        <IconContainer>
          <IconHelper icon={icon} iconSize={iconSize} />
        </IconContainer>
      )}
      <div>
        <Heading bold color="link">
          {heading}
        </Heading>
        <Description>{description}</Description>
      </div>
    </LeftSideContainer>
    <RightSideContainer>{children}</RightSideContainer>
  </SettingContainer>
)

export default RdnaSettingRow
