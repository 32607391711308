import React from 'react'
import { useHistory } from 'react-router-dom'
import { userSettingsStaticAtom, caiSfdcEnhancedAnalyticsAtom } from '../../../store/api/user-settings'
import { useAtomValue } from 'jotai'
import { RoutePaths } from '../../LeftNav'

export enum RevenueSections {
  OPPORTUNITIES = 'opportunities',
  XRAY = 'xray'
}

export default function useRevenueSection(
  Section: () => JSX.Element,
  revenueSection: RevenueSections = RevenueSections.OPPORTUNITIES
) {
  const history = useHistory()
  const { accountSfdcEnhancedAnalytics } = useAtomValue(userSettingsStaticAtom)
  const { sfdcEnhancedAnalytics } = useAtomValue(caiSfdcEnhancedAnalyticsAtom)
  const isAuthorized = accountSfdcEnhancedAnalytics && sfdcEnhancedAnalytics
  if (!isAuthorized) {
    history.push(`${RoutePaths.GET_REVENUE}?ref=${revenueSection}`)
    return null
  }
  return <Section />
}
