/**
 * This is a wrapper for the backbone user-model.
 * This prevents direct access to any methods or properties in the original class and
 * provides an abstraction that we can swap out at anytime in the future.
 *
 * NEVER ACCESS BACKBONE DATA DIRECTLY!
 */

export default class UserService {
  private app: any

  constructor(app: any) {
    this.app = app
  }

  get user(): any {
    return this.app.session.user
  }

  get caiLocationEnabled(): boolean {
    return this.user.get('conversationAiLoc')
  }

  get caiManager(): boolean {
    return this.user.get('caiManager')
  }

  get isSupport(): boolean {
    return this.user.isSupport()
  }

  get isAuthorizedAdmin(): boolean {
    return this.user.userAccountAuthorizations?.length > 0
  }

  get isAdmin(): boolean {
    return this.user.isAdmin()
  }

  get userId(): number {
    return this.user.get('userId')
  }

  get accountId(): number {
    return this.user.get('accountId')
  }

  get userName(): string {
    return this.user.get('userName')
  }

  get userEmail(): string {
    return this.user.get('userEmail')
  }

  get accountName(): string {
    return this.user.get('accountName')
  }

  get pubsub(): any {
    return this.app.pubsub
  }

  get licenses(): {
    admin: boolean
    intelligentDialer: boolean
    calendar: boolean
    conversationAI: boolean
    guidedSelling: boolean
    moments: boolean
    salesSupport: boolean
    supervisor: boolean
    support: boolean
  } {
    return this.user.get('licenses')
  }

  getAll() {
    return this.user.toJSON()
  }
}
