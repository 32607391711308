import React, { useCallback, useMemo, useState } from 'react'
import { HolidayProps, EventProps, DeleteModalProps } from '../../types'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import { DELETE, EDIT, NO_RESULT_STATE } from '../../const'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { HEADER_TEXT, HolidayCalendarColumns, initEvent, initHoliday } from './const'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import {
  useHolidayCalendarCreate,
  useHolidayCalendarUpdate,
  useHolidayCalendarDelete,
  useHolidayCalendars,
  useHolidayEventCreate,
  useHolidayEventUpdate
} from '../../api'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'
import EditHolidayContent from './EditHolidayContent'
import EditEventContent from './EditEventContent'
import styled from 'styled-components'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import useToast from '../../../common/hooks/useToast'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'

type tabProps = {
  accountId: number
}

export default function HolidayCalendarTab({ accountId }: tabProps) {
  const toastr = useToast()
  const [holiday, setHoliday] = useState<HolidayProps>(initHoliday)
  const [orderError, setOrderError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [event, setEvent] = useState<EventProps>(initEvent)
  const [page, setPage] = useState(1)
  const [isCalendarDrawerOpen, setIsCalendarDrawerOpen] = useState(false)
  const [isCalendarDeleteOpen, setIsCalendarDeleteOpen] = useState(false)
  const deleteHolidayCalendar = useHolidayCalendarDelete()
  const createHolidayCalendar = useHolidayCalendarCreate()
  const updateHolidayCalendar = useHolidayCalendarUpdate()
  const createHolidayEvent = useHolidayEventCreate()
  const updateHolidayEvent = useHolidayEventUpdate()
  const [payload, , loading, refetch] = useHolidayCalendars({ query: { accountId } })

  const closeDrawer = () => {
    setIsCalendarDrawerOpen(false)
    setPage(1)
    setHoliday(initHoliday)
  }

  async function handleSave() {
    setIsLoading(true)
    try {
      if (page !== 1) {
        await saveEvent()
        setPage(1)
      } else {
        await saveCalendar()
        if (page !== 1 || holiday.id) {
          closeDrawer()
        }
        refetch()
      }
    } catch (e) {
      toastr.error(getErrorMessage(e))
    }
    setIsLoading(false)
  }

  async function saveCalendar() {
    const bodyCalendar = { accountId: accountId, name: holiday.name, description: holiday.description }
    if (holiday.id) {
      await updateHolidayCalendar({
        meta: { id: holiday.id },
        body: bodyCalendar
      })
    } else {
      await createCalendar()
    }
  }

  const createCalendar = async () => {
    const bodyCalendar = { accountId: accountId, name: holiday.name, description: holiday.description }
    const payloadCalendar = await createHolidayCalendar({ body: bodyCalendar })
    const holidayId = payloadCalendar?.success?.payload?.id
    setHoliday({ ...holiday, id: holidayId })
    return holidayId
  }

  async function saveEvent() {
    let holidayId
    if (!holiday.id) {
      holidayId = await createCalendar()
      refetch()
    } else {
      holidayId = holiday.id
    }
    const bodyEvent = {
      accountId: accountId,
      holidayScheduleId: holidayId,
      name: event.name,
      fromDate: event.fromDate,
      toDate: event.toDate
    }
    if (event.id) {
      await updateHolidayEvent({
        meta: { id: event.id },
        body: bodyEvent
      })
    } else {
      await createHolidayEvent({ body: bodyEvent })
    }
  }

  async function handleDelete() {
    setIsLoading(true)
    try {
      await deleteHolidayCalendar({ meta: { id: holiday.id } })
      setIsCalendarDeleteOpen(false)
      setHoliday(initHoliday)
      refetch()
    } catch (e) {
      toastr.error(getErrorMessage(e))
    }
    setIsLoading(false)
  }

  const handleActionMenu = useCallback((action: string, holiday: HolidayProps) => {
    setHoliday(holiday)
    if (action === DELETE) {
      setIsCalendarDeleteOpen(true)
    } else if (action === EDIT) {
      setIsCalendarDrawerOpen(true)
    }
  }, [])

  const memoPayload = useMemo(() => {
    return payload?.map((template: HolidayProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={[{ text: EDIT }, { text: DELETE }]} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  const disableSaveButton = useMemo(() => {
    if (isLoading) {
      return isLoading
    }
    if (page === 1) {
      return !holiday.name
    } else {
      return !event.name || !event.toDate || !event.fromDate || orderError
    }
  }, [page, orderError, isLoading, holiday.name, event.name, event.toDate, event.fromDate])

  return (
    <>
      <RdnaFormLayout container>
        <RdnaFormLayout>
          <RdnaHeaderSection descriptionText={HEADER_TEXT} rightColumn={CreateHolidayButton(setIsCalendarDrawerOpen)} />
          <RdnaSmartTable
            searchPlaceholder="Search"
            //@ts-ignore
            columns={HolidayCalendarColumns}
            data={memoPayload || []}
            noResultState={NO_RESULT_STATE}
            isFetching={!!loading}
            paginationSize={10}
          />
        </RdnaFormLayout>
      </RdnaFormLayout>
      <RdnaDrawer
        open={isCalendarDrawerOpen}
        heading={
          page !== 1 ? `${event.id ? 'Edit' : 'Add'} Holiday Event` : `${holiday.id ? 'Edit' : 'Add'} Holiday Calendar`
        }
        onClose={closeDrawer}
        onBack={page !== 1 ? () => setPage(1) : undefined}
        contentContainerProps={{ style: { height: '100%', width: '600px' } }}
      >
        {page === 1 ? (
          <EditHolidayContent
            setPage={setPage}
            holiday={holiday}
            setHoliday={setHoliday}
            event={event}
            setEvent={setEvent}
          />
        ) : (
          <EditEventContent event={event} setEvent={setEvent} setOrderError={setOrderError} />
        )}
        <Footer>
          <RdnaButton
            text={'Cancel'}
            color="neutral"
            variant="text"
            onClick={page !== 1 ? () => setPage(1) : closeDrawer}
            className={'button-cancel'}
          />
          <RdnaButton
            text={isLoading ? 'Saving...' : 'Save'}
            onClick={handleSave}
            className={'button-save'}
            disabled={disableSaveButton}
          ></RdnaButton>
        </Footer>
      </RdnaDrawer>
      <ConfirmCalendarDeleteModal
        open={isCalendarDeleteOpen}
        onClose={setIsCalendarDeleteOpen}
        onConfirm={handleDelete}
        isLoadingDelete={isLoading}
      />
    </>
  )
}

const CreateHolidayButton = (setIsDrawerOpen: (value: boolean) => void) => {
  return (
    <RdnaButton
      data-analyticsid="add-holiday-calendar"
      text={'Add Holiday'}
      onClick={() => {
        setIsDrawerOpen(true)
      }}
    />
  )
}

const ConfirmCalendarDeleteModal = ({ open, onClose, onConfirm, isLoadingDelete }: DeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Holiday Schedule`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`Are you sure you want to delete this holiday schedule. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}

const Footer = styled.div`
  display: flex;
  justify-content: end;
`
