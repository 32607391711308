import React, { useState } from 'react'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { useUpdateAccountDialerSettings } from '../../store/api/accounts-api'
import { SaveProps } from './types'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import {
  callDispositionPromptSettings,
  manageCallDispositionRequiredByTeamSettings,
  manageCallDispositionsSettings,
  callDispositionRequiredStateSettings,
  voicemailDropDispositionSettings,
  callNoteTemplatesSettings,
  appendTransferInfoSettings,
  TabKeys,
  postCallPromptSettings
} from './const'

export default function SaveButton({
  accountSettings,
  featureVisibilities,
  refetchSettings,
  activeTab,
  dialerSettings
}: SaveProps) {
  const { error, success } = useRdnaNotification()
  const [isLoading, setIsLoading] = useState(false)
  const updateAccountDialerSettings = useUpdateAccountDialerSettings()

  const onSave = async () => {
    try {
      setIsLoading(true)

      const visibilities = {}
      const playModel: { [key: string]: any } = {
        'account.id': accountSettings['account.id'],
        'settings.id': accountSettings['settings.id'],
        // @ts-ignore
        'settings.voicemailDropDisposition[id]': dialerSettings?.account.settings['voicemailDropDisposition']?.id
      }
      if (activeTab === TabKeys.MANAGE_CALL_DISPOSITIONS) {
        playModel[callDispositionPromptSettings] = accountSettings[callDispositionPromptSettings]
        playModel[callDispositionRequiredStateSettings] = accountSettings[callDispositionRequiredStateSettings]
        playModel[manageCallDispositionRequiredByTeamSettings] = accountSettings[manageCallDispositionRequiredByTeamSettings]
        playModel[manageCallDispositionsSettings] = accountSettings[manageCallDispositionsSettings]
        playModel[`${voicemailDropDispositionSettings}[id]`] = accountSettings[voicemailDropDispositionSettings]?.id
      } else if (activeTab === TabKeys.CALL_NOTES_TEMPLATES) {
        playModel[callNoteTemplatesSettings] = accountSettings[callNoteTemplatesSettings]
      } else if (activeTab === TabKeys.CALL_TOOLS) {
        playModel[appendTransferInfoSettings] = accountSettings[appendTransferInfoSettings]
        featureVisibilities.forEach((visibility, i) => {
          // @ts-ignore
          visibilities[`featureVisibilities[${i}].feature`] = visibility.feature
          // @ts-ignore
          visibilities[`featureVisibilities[${i}].visible`] = visibility.visible
        })
      } else if (activeTab === TabKeys.POSTCALL_PROMPT) {
        playModel[postCallPromptSettings] = accountSettings[postCallPromptSettings]
      }

      // @ts-ignore
      await  updateAccountDialerSettings({ body: { ...visibilities, ...playModel } })

      success('Settings Saved')
    } catch (e) {
      const message = getErrorMessage(e) + (e?.fail?.payload?.code ? '\n' +e?.fail?.payload?.code : '')
      error(message)
    } finally {
      setIsLoading(false)
      refetchSettings()
    }
  }

  return (
    <RdnaButton
      style={{ width: 'fit-content' }}
      text={'Save'}
      name="save-call-actions-settings"
      processing={isLoading}
      onClick={onSave}
    />
  )
}
