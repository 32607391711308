import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function PlusIcon({ size = 12, color = Colors.N0, ...props }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" {...props}>
      <path d="M11 6H1M6 11V1" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default PlusIcon
