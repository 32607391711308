import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function SpamCallsIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="spam-calls-icon">
      <g clipPath="url(#clip0_9043_75772)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5263 52.0964C24.6794 52.0453 24.7827 51.902 24.7827 51.7406V43.7439C24.7827 43.5718 24.6656
              43.4218 24.4987 43.3801L20.0321 42.2634C19.9416 42.2408 19.8643 42.1875 19.8165 42.1074C19.4057
              41.4191 17.4458 37.791 17.4458 31.2228C17.4458 24.6547 19.4057 21.0265 19.8165 20.3383C19.8643 20.2582
              19.9416 20.2049 20.0321 20.1822L24.4987 19.0656C24.6656 19.0239 24.7827 18.8739 24.7827
              18.7018V10.7051C24.7827 10.5437 24.6794 10.4004 24.5263 10.3493L17.6472 8.05629C17.5222 8.01463
              17.3904 8.0368 17.2942 8.12685C16.148 9.20056 8.88599 16.5807 8.88599 31.2228C8.88599 45.865
              16.148 53.2451 17.2942 54.3188C17.3904 54.4089 17.5222 54.4311 17.6472 54.3894L24.5263 52.0964Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <circle cx={39.4565} cy={29.9999} r={10.3696} stroke={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1463 31.8165C40.1167 32.0313 39.9324 32.1913 39.7158 32.1913H38.5637C38.3254 32.1913 38.1315
              31.9991 38.1289 31.7617L38.035 23.9183C38.0332 23.8026 38.0793 23.6904 38.161 23.6078C38.2428 23.5252
              38.3532 23.4783 38.4697 23.4783H40.8158C40.941 23.4783 41.061 23.5331 41.1428 23.6278C41.2263 23.7226
              41.2637 23.8487 41.2463 23.9731L40.1463 31.8165Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.1298 36.5218C38.2802 36.5218 37.6628 35.9235 37.6628 35.0983C37.6628 34.2609 38.2802 33.6531
              39.1298 33.6531C39.9915 33.6531 40.5941 34.247 40.5941 35.0983C40.5941 35.9366 39.9915 36.5218 39.1298 36.5218Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9043_75772">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
