import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const CallPromptIcon = ({ color = Colors.N100, size = 18 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24359 15.8355C7.39671 15.7844 7.5 15.6411 7.5 15.4797V13.4178C7.5 13.2457 7.38289 13.0957 7.21595 13.054L6.14065 12.7852C6.05015 12.7625 5.97163 12.7072 5.9274 12.6251C5.73918 12.2755 5.25 11.1921 5.25 9.375C5.25 7.55786 5.73918 6.4745 5.9274 6.12491C5.97163 6.04278 6.05015 5.98746 6.14065 5.96484L7.21595 5.69601C7.38289 5.65428 7.5 5.50428 7.5 5.33221V3.27028C7.5 3.10887 7.39671 2.96557 7.24359 2.91453L5.45143 2.31714C5.32646 2.27549 5.19042 2.30161 5.09803 2.3955C4.53844 2.96414 2.625 5.21285 2.625 9.375C2.625 13.5371 4.53844 15.7859 5.09803 16.3545C5.19042 16.4484 5.32646 16.4745 5.45143 16.4329L7.24359 15.8355Z"
      stroke={color}
      strokeWidth="0.9975"
    />
    <path
      d="M9.85 7.08398H10.4167H15.0833H15.65C15.7052 7.08398 15.75 7.12876 15.75 7.18398V11.7507C15.75 12.3029 15.3023 12.7507 14.75 12.7507H10.75C10.1977 12.7507 9.75 12.3029 9.75 11.7507V7.18398C9.75 7.12876 9.79477 7.08398 9.85 7.08398Z"
      stroke={color}
      strokeWidth="0.9975"
    />
    <path d="M9.75 8.41797H15.75" stroke={color} strokeWidth="0.9975" strokeLinecap="round" />
  </svg>
)

export default CallPromptIcon
