AdWordsModal       = require('./modals/main-view')
AdwordsCallTrackingCollection = require('./adwords-collection')
AddressCollection  = require('widgets/smart-numbers-widget/address-collection')
AddressStepModal   = require('features/call-tracking/address-step/address-step-view')
BackgridCell       = require('helpers/backgrid-cell')
BackgridRow        = require('helpers/backgrid-row')
BaseView           = require('base/view')
CallflowPickerView = require('widgets/callflow-picker/view')
CallTrackingHelper = require('features/call-tracking/helper')
BaseCollection     = require('base/collection')
PagedCollection    = require('base/paged-collection')
PagerView          = require('widgets/pager/view')

class AdWordsPagedCollection extends PagedCollection
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    for adwords in response.results
      adwords['labelTracking'] = adwords.label
      result.push(adwords)
    result

class AnalyticsEmailsCollection extends BaseCollection
  url: -> 'adwords/analyticsEmails'

class AdWordsView extends BaseView
  template: require('./template')

  regions:
    grid    : 'div.grid'
    pager   : 'span.pager'
    modal   : 'div.modal-container'
    callFlowPicker: '.call-flow-picker'
    addressStepModal: 'div.address-step-container'

  ui:
    search  : 'button.search'
    labelTracking  : 'input.labelTracking'
    trackingType: 'select.tracking-type'
    analyticsEmailsPicker: '.analytics-emails'

  events:
    'click @ui.search' : 'search'
    'click a.add' : 'create'

  bindings:
    '.create-connection': 'attr:{href:url}'
    '.adwords-connection': 'toggle:not(isSupport)'
    'a.add': "classes:{'disabled':any(hasAddAdWords,isSupport)}"

  computeds:
    hasAddAdWords:
      deps: ['selectedEmail']
      get: (selectedEmail) ->
        not selectedEmail?.length

    isSupport:
      get: -> @user().isSupport()

  initialize: (options) =>
    { @qs, @accountId } = options
    @qs.setFragment('call-tracking/adwords')
    @connection()
    @viewModel = new Backbone.Model(
      url: null
      managerId: null
      customerId: null
      selectedEmail: null
      viewTracking: 'campaigns'
      segment: 'none'
      finishEnable: false
    )

  onRender: =>
    @adwordsСollection = new AdwordsCallTrackingCollection()
    @callFlowPickerView = new CallflowPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      label: 'none'
    )
    @callFlowPicker = @getRegion('callFlowPicker')
    @callFlowPicker.show(@callFlowPickerView)
    @qs.bind(
      labelTracking: @ui.labelTracking
      callFlowId: @callFlowPicker.currentView
      trackingType: @ui.trackingType
    )
    @qs.restore()
    @search()

  onDestroy: =>
    @stopListening()

  search: =>
    new AnalyticsEmailsCollection().fetch().done((emails) =>
      @setAnalyticsEmails(emails)
    ) if not @user().isSupport()

    @collection = new AdWordsPagedCollection(
      page: @qs.get('page')
      queryParams:
        labelTracking: @ui.labelTracking.val().trim()
        callFlowId: @callFlowPicker.currentView.val()
        trackingType: if @ui.trackingType.val() isnt 'none' then @ui.trackingType.val() else ''
        accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      url: 'adwords'
    )
    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, qs: @qs))
    @collection.fetch(reset: true)

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:smartNumber.id',
      label : ':smartNumber.number',
      attrs : ['smartNumber.id', 'smartNumber.number'])

    flowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    columns = [
      {label: 'Account',         name: 'account.name',   cell: 'string', renderable: @getBinding('isSupport')}
      {label: 'Name',            name: 'labelTracking',  cell: 'string'}
      {label: 'Tracking Type',   name: 'type',           cell: 'string'}
      {label: 'Additional',      name: 'name',           cell: 'string'}
      {label: 'Call Flow',       name: 'callFlow',       cell: flowCell}
      {label: 'Number',          name: 'number',         cell: numbCell}
      {label: 'Campaign',        name: 'campaign',       cell: 'string'}
      {label: 'Date Created',    name: 'createdDate',    cell: datetime}
      {label: 'Date Last Used',  name: 'smartNumber.dateLastUsed', cell: datetime}
    ]

    new Backgrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No google ads call tracking found.'
    )

  initModal: =>
    @adWordsModal = new AdWordsModal(
      accountId: @accountId
      viewModel:  @viewModel
      collection: @adwordsСollection
    )
    @listenTo(@adwordsСollection, 'newCallTracking', (adWord) =>
      adWord['name'] = adWord.channel
      @collection?.unshift(adWord)
      @collection?.pop() if @collection.length > 30
      @collection.state.totalRecords += 1
      @collection.trigger('pager:state:changed', @collection.state)
      toastr.info('Google Ads Call Tracking saved.')
      @search() if @collection.length > 30
    ) if not @adwordsСollection?._events?.newCallTracking
    @listenTo(@adWordsModal, 'openAddressStepModal', => @initAddressStepModal())

  create: =>
    @initModal()
    @showChildView('modal', @adWordsModal)

  initAddressStepModal: =>
    @addressCollection = new AddressCollection()
    addressStepModal = new AddressStepModal(
      accountId: @accountId
      addressCollection: @addressCollection
      title: 'Add Google Ads Call Tracking'
      collection: @adwordsСollection
    )
    @showChildView('addressStepModal', addressStepModal)
    $('#address-step-modal').modal()
    @listenTo(addressStepModal, 'repeatCreateCallTrackings', (ladda, notCreatedAddresses) =>
      notAddressesNumbers =
        CallTrackingHelper.filterRepeatCollection(notCreatedAddresses, @adwordsСollection) if notCreatedAddresses.length isnt 0
      @adwordsСollection.createAdWords(notAddressesNumbers, =>
        ladda.stop()
        createdAddresses = @addressCollection.where(created: true)
        @addressCollection.remove(createdAddresses) if createdAddresses.length isnt 0
        $('#address-step-modal').modal('hide') if not notAddressesNumbers or notAddressesNumbers.length is 0
      ))

  connection: =>
    App.api.get(
      path    : 'googleoauth/adwords/connect'
      success :
        (response) =>
          @viewModel.set('url', response.url))

  setAnalyticsEmails: (emails) =>
    @ui.analyticsEmailsPicker.selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      load: =>
        selectize = @ui.analyticsEmailsPicker[0].selectize
        selectize.addOption(value: email, text: email) for email in emails
      onChange: (value) =>
        @viewModel.set('selectedEmail', value)
        @viewModel.set('managerId', null)
        @viewModel.set('customerId', null)
    )

module.exports = AdWordsView
