BaseView = require('base/view')

class EditComplianceHoursModal extends BaseView

  template: require('./editor-template')
  className: 'compliance-hours-editor'

  ui:
    modal : '#edit-compliance-hours-modal'
    go    : 'button.persist'

  events:
    'click @ui.go': 'save'

  bindings:
    'label.day-of-week': 'text:dayOfWeek'
    'input.all-day': 'checked:allDay'
    'input.start-time': 'value:startTime24,disabled:allDay'
    'input.end-time': 'value:endTime24,disabled:allDay'

  save: =>
    @model.persist(
      ladda: @ui.go[0]
    ).done?(=>
      @collection.remove(@model)
      @collection.add(@model)
      @ui.modal.modal('hide')
    )

module.exports = EditComplianceHoursModal