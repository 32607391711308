var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-heading\">\n  <h4 class=\"panel-title\">\n    <a data-toggle=\"collapse\" href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"countryCode") || (depth0 != null ? lookupProperty(depth0,"countryCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countryCode","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":52}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"addressRequirements") || (depth0 != null ? lookupProperty(depth0,"addressRequirements") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addressRequirements","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":76}}}) : helper)))
    + "-address\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"countryName") || (depth0 != null ? lookupProperty(depth0,"countryName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countryName","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":101}}}) : helper)))
    + "</a>\n    <span class=\"text-danger created-error\">There is an error with the address provided.</span>\n  </h4>\n</div>\n<div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"countryCode") || (depth0 != null ? lookupProperty(depth0,"countryCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countryCode","hash":{},"data":data,"loc":{"start":{"line":7,"column":9},"end":{"line":7,"column":24}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"addressRequirements") || (depth0 != null ? lookupProperty(depth0,"addressRequirements") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addressRequirements","hash":{},"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":48}}}) : helper)))
    + "-address\" class=\"panel-collapse collapse\">\n  <div class=\"panel-body\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Friendly Name:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control friendly-name\" type=\"text\" placeholder=\"A name for the address\">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Business Name:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control business-name\" type=\"text\" placeholder=\"Name of the business\">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Country:</label>\n      <div class=\"col-md-8 country-container\">\n        <select class=\"form-control country-select\"></select>\n        <input class=\"form-control country-input\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"countryCode") || (depth0 != null ? lookupProperty(depth0,"countryCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countryCode","hash":{},"data":data,"loc":{"start":{"line":25,"column":57},"end":{"line":25,"column":72}}}) : helper)))
    + "\" disabled>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Address:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control street\" type=\"text\" placeholder=\"Street address, PO box, suit, unit, etc.\">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">City:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control city\" type=\"text\">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">State/Province/Region:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control region\" type=\"text\">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">ZIP/Postal Code:</label>\n      <div class=\"col-md-8\">\n        <input class=\"form-control postal-code\" type=\"text\">\n      </div>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n</div>\n";
},"useData":true});