BaseModel = require('base/model')
BaseView  = require('base/view')

class ActionModel extends BaseModel
  url: 'cleanup/cache'
  isNew: -> false

class ClearCacheView extends BaseView
  template: require('./template')
  className: 'clear-cache'

  events:
    'click .ladda-button': 'action'

  action: (e) =>
    button = $(e.currentTarget)
    action = button.attr('data-action')

    model = new ActionModel(action: action)
    model.persist(ladda: button[0])

module.exports = ClearCacheView