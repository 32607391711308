import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function Alert({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none">
      <circle cx={12} cy={12} r={9.5} stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6346 13.6712C12.6074 13.8688 12.4378 14.016 12.2386 14.016H11.1786C10.9594 14.016 10.781 13.8392 10.7786 13.6208L10.6922 6.4048C10.6906 6.2984 10.733 6.1952 10.8082 6.1192C10.8834 6.0432 10.985 6 11.0922 6H13.2506C13.3658 6 13.4762 6.0504 13.5514 6.1376C13.6282 6.2248 13.6626 6.3408 13.6466 6.4552L12.6346 13.6712Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6995 17.9999C10.9179 17.9999 10.3499 17.4495 10.3499 16.6903C10.3499 15.9199 10.9179 15.3607 11.6995 15.3607C12.4923 15.3607 13.0467 15.9071 13.0467 16.6903C13.0467 17.4615 12.4923 17.9999 11.6995 17.9999Z"
        fill={color}
      />
    </svg>
  )
}

export default Alert
