AccountModel              = require('features/accounts/model')
BaseView                  = require('base/view')
LocalPresenceView         = require('features/accounts/show/local-presence/main-view')
EmergencyView             = require('features/accounts/show/emergency/view')

class ReactToMarionetteView extends BaseView
  template: require('./template')
  className: 'react-to-marionette-view'

  regions:
    content: '.content'

  initialize: (options) =>
    { @tabId } = options
    @content    = @getRegion('content')
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')

  onRender: =>
    @showTab()

  showTab: =>
    switch @tabId
      when 'emergency'
        model = new AccountModel(id: @accountId)
        model.fetch().done(=> @content.show(new EmergencyView(accountId: @accountId, model: model)))
      when 'local-presence'
        model = new AccountModel(id: @accountId)
        model.fetch().done(=> @content.show(new LocalPresenceView(model: model)))
      else
        throw new Error("Unknown @tabId: #{@tabId}")

module.exports = ReactToMarionetteView
