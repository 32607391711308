BaseCollection  = require('base/collection')
BaseModel       = require('base/model')

class NumberModel extends BaseModel
  parse: (response) ->
    response.extraInfo = JSON.stringify(response.extraInfo) if response.extraInfo
    response

  urlRoot: -> "callFlows/#{@get('callFlow').id}/smartNumbers"

class NumberCollection extends BaseCollection
  model: NumberModel

  initialize: (options) ->
    @callflowId = options.callflowId

  url: -> "callFlows/#{@callflowId}/smartNumbers"

module.exports = NumberCollection