import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '../../../../../common/src/client'

export const useRelatedRecords = createUseFetch<any, { meta: { accountId: number } }>({
  key: 'get-related-records',
  independent: true,
  path: params => `/api/v2/app/relatedRecordRules/${params.meta.accountId}`
})

type RelatedRecordsUpdateParam = {
  relatedRecordId: number
} & RelatedRecordsCreateParam

type RelatedRecordsCreateParam = {
  body: {
    'relatedRecord.id'?: number
    'relatedRecord.account.id': number
    'relatedRecord.apiName': string
    'relatedRecord.typeRecord': string
    'relatedRecord.orderBy': string
    'relatedRecord.limitRows': number
    'relatedRecord.priority': number
    searchesRules: string[]
    suggestsRules: string[]
    viewsRules: string[]
  }
}

type RelatedRecordsUpdatePriorityParam = {
  relatedRecordId: number
  body: {
    accountId: number
    newPriority: number
    oldPriority: number
  }
}

export const useRelatedRecordUpdate = createUseFetchAction<void, RelatedRecordsUpdateParam>({
  key: 'update-related-records',
  path: params => `/api/v2/app/relatedRecordRules/${params.relatedRecordId}`,
  method: HttpMethods.Put
})

export const useRelatedRecordCreate = createUseFetchAction<void, RelatedRecordsCreateParam>({
  key: 'create-related-records',
  path: `/api/v2/app/relatedRecordRules`,
  method: HttpMethods.Post
})

export const useCustomSalesforceRecords = createUseFetchAction<any, { meta: { accountId: number } }>({
  key: 'get-custom-salesforce-related-records',
  independent: true,
  path: params => `/api/v2/app/relatedRecordRules/salesforceCustomRecords/${params.meta.accountId}`
})

export const useRecordsDelete = createUseFetchAction<{ meta: { relatedRecordId: number } }, any>({
  key: 'delete-related-records',
  path: params => `/api/v2/app/relatedRecordRules/${params.meta.relatedRecordId}`,
  method: HttpMethods.Delete
})

export const useRelatedRecordUpdatePriority = createUseFetchAction<void, RelatedRecordsUpdatePriorityParam>({
  key: 'update-related-records-priority',
  path: params => `/api/v2/app/relatedRecordRules/priority/${params.relatedRecordId}`,
  method: HttpMethods.Post
})
