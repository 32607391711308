import React from 'react'

// utils
import { useCallRecordingDispatch } from '../../../utils/contextReducer'

// components
import RdnaFilePicker from '@ringdna/common/src/components/RdnaFilePicker'
import useToast from '../../../../common/hooks/useToast'

type AnnouncementFilePickerProps = {
  isDisabled: boolean
  accountSetting: string
  existingFile: string
  mbFileSizeLimit: number
}
const AnnouncementFilePicker = ({
  isDisabled,
  accountSetting,
  existingFile,
  mbFileSizeLimit
}: AnnouncementFilePickerProps) => {
  const dispatch = useCallRecordingDispatch()
  const toastr = useToast()
  const handleOnChange = (e: React.SetStateAction<any>) => {
    if (!e.fileName) {
      dispatch({ type: 'CHANGE_ACCOUNT_SETTINGS', payload: { ['settings.' + accountSetting]: '' } })
      dispatch({ type: 'REMOVE_FROM_AUDIO_UPLOAD_SETTINGS', payload: accountSetting })
    } else {
      const data = {
        file: e.fileBinary,
        fileName: e.fileName,
        setting: accountSetting
      }
      dispatch({ type: 'REMOVE_FROM_AUDIO_UPLOAD_SETTINGS', payload: accountSetting })
      dispatch({ type: 'ADD_TO_AUDIO_UPLOAD_SETTINGS', payload: data })
    }
  }

  return (
    <RdnaFilePicker
      disabled={isDisabled}
      fileType=".mp3"
      name="picker"
      existingFile={existingFile}
      onChange={handleOnChange}
      placeholderText="File can be no more than 10 MB"
      mbFileSizeLimit={mbFileSizeLimit}
      onError={error => toastr.error(error)}
    />
  )
}

export default React.memo(AnnouncementFilePicker)
