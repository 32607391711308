BaseView        = require('base/view')
SupportSettings           = require('src/components/support-settings').default
ProductConstant           = require('@ringdna/common/src/constants/products')
ReactComponent            = require('src/ReactComponent').default

class SupportSettingsView extends BaseView
  template: require('./template')
  className: 'support-settings-view'

  regions:
    container  : '.support-settings'

  initialize: (options) =>
    { @qs } = options
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @qs.setFragment('dialer/support-settings')

  onRender: =>
    return unless @user().can('system:manage')
    @showChildView('container', new ReactComponent({component: SupportSettings, theme: ProductConstant.Products.ADMIN, props: { accountId: @accountId }}))

module.exports = SupportSettingsView
