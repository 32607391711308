AccountPickerView = require('widgets/account-picker/view')
BaseView  = require('base/view')
OverviewView = require('./overview/view')

class SplitAccountView extends BaseView
  template: require('./template')
  className: 'split-account'

  regions:
    accountData   : '.account-data'
    accountPicker : 'span.account-picker'

  ui:
    results : '.results'
    getData : 'button.get-data'

  events:
    'click @ui.getData' : 'search'

  initialize: (options) ->
    @accountData = @getRegion('accountData')
    @accountPicker = @getRegion('accountPicker')

  onRender: =>
    v = new AccountPickerView()
    @accountPicker.show(v)

  search: =>
    if @accountPicker.currentView.val()
      App.api.get(
        path    : 'splitAccount/' + @accountPicker.currentView.val()
        success : (response) =>
          v = new OverviewView(model: new Backbone.Model(response))
          @accountData.show(v)
          @listenTo(v, 'split', @split)
      )

  split: (sourceId, targetId, sfOrgId, users, callQueues, teams, callFlows, skills, smartNumbers, holidaySchedules) =>
    App.api.post(
      path: 'splitAccount/' + sourceId
      data:
        targetId : targetId
        sfOrgId : sfOrgId
        users : users
        callQueues: callQueues
        teams: teams
        callFlows : callFlows
        skills: skills
        smartNumbers : smartNumbers
        holidaySchedules : holidaySchedules
      success: =>
        toastr.info('Start splitting/moving.')
    )

module.exports = SplitAccountView
