import React, { useEffect, useMemo, useState } from 'react'

import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import { useAdminUsers } from '../../../../../store/api/users-api'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'

type Props = {
  accountId: number
  setAdminNotifications: (admins: { [userId: string]: boolean }) => void
  sfdcInvalidNotificationsAll: boolean
}

const ALL = 'all'

export default Notifications
function Notifications({ accountId, setAdminNotifications, sfdcInvalidNotificationsAll }: Props) {
  const [adminUsers] = useAdminUsers({ meta: { id: accountId } })
  const [selectedAdmins, setSelectedAdmins] = useState<string[]>([])
  const [placeholder, setPlaceholder] = useState<string>()

  const listAdminUsers = useMemo(
    () => [
      { label: 'Select All/Clear All', value: ALL },
      ...(adminUsers?.map(user => ({ label: user.displayName, value: user.id.toString() })) || [])
    ],
    [adminUsers]
  )

  useEffect(() => {
    if (adminUsers) {
      if (sfdcInvalidNotificationsAll) {
        setSelectedAdmins([...adminUsers.map(u => u.id.toString()), ALL])
      } else {
        setSelectedAdmins(adminUsers.filter(u => u.settings.sendInvalidNotification).map(u => u.id.toString()))
      }
    }
  }, [sfdcInvalidNotificationsAll, adminUsers])

  useEffect(() => {
    let text = 'None'
    if (selectedAdmins.length > 0 && selectedAdmins.includes(ALL)) {
      text = 'All Administrator'
    } else if (selectedAdmins.length > 0) {
      text = `Administrators (${selectedAdmins.length})`
    }
    setPlaceholder(text)
  }, [selectedAdmins])

  useEffect(() => {
    const adminNotifications: any = {}
    adminUsers?.forEach(user => {
      adminNotifications[user.id] = selectedAdmins.includes(user.id.toString())
      user.settings.sendInvalidNotification = selectedAdmins.includes(user.id.toString())
    })
    setAdminNotifications(adminNotifications)
  }, [adminUsers, selectedAdmins, setAdminNotifications])

  const onChangeAdmin = (admins: string[], { props }: any) => {
    const all = admins.includes(ALL)
    const tempAdmins = admins.filter(val => val !== ALL)
    if (all && props.value === ALL && adminUsers) {
      setSelectedAdmins([...new Set([...admins, ...adminUsers.map(u => u.id.toString())])])
    } else if (!all && props.value === ALL) {
      setSelectedAdmins([])
    } else if (all && adminUsers && tempAdmins.length === adminUsers.length - 1) {
      setSelectedAdmins(tempAdmins)
    } else if (!all && adminUsers && tempAdmins.length === adminUsers.length) {
      setSelectedAdmins([...tempAdmins, ALL])
    } else {
      setSelectedAdmins(admins)
    }
  }

  return (
    <>
      <RdnaSettingRow
        heading={'Notifications'}
        description={"Select the recipient(s) for the 'Invalid Salesforce Connect User' emails"}
      >
        {placeholder && (
          <RdnaSelect
            name={'invalid-notification-admins'}
            className={'rdna-select'}
            multiple
            placeholder={placeholder}
            showPlaceholderAsOption={false}
            displayChips={false}
            options={listAdminUsers}
            // @ts-ignore
            value={selectedAdmins}
            onChange={(event, child) =>
              // @ts-ignore
              onChangeAdmin(event.target.value as string[], child)
            }
          />
        )}
      </RdnaSettingRow>
    </>
  )
}
