import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

// context
import { NavigationItem, useNavContext } from './RdnaLeftNav'

// styled
import { Colors } from '../../constants/colors'
import RdnaText from '../RdnaText'
import { Collapse, IconButton } from '@mui/material'
import CaretFilled from '../../assets/icons/svg/CaretFilled'
import { Direction } from '../../assets/icons/types'
import MenuIcon from '../../assets/icons/svg/Menu'
import RdnaTooltip from '../RdnaTooltip'
import RdnaMenu, { MenuOption } from '../RdnaMenu'
import IconHelper from '../../assets/icons/IconHelper'
import LinkIcon from '../../assets/icons/svg/Link'
import Alert from '../../assets/icons/svg/Alert'

type NavLinkProps = {
  subroutes: NavigationItem[] | undefined
  fullUrl: string | undefined
  children: React.ReactNode
}

const NavLink = ({ subroutes, fullUrl, children }: NavLinkProps) => {
  const { isReactBrowserRouter } = useNavContext()
  const path = subroutes?.length ? '' : fullUrl || ''
  if (!path) return <div>{children}</div>
  return isReactBrowserRouter ? (
    <Link to={path} className="rdnaLink">
      {children}
    </Link>
  ) : (
    <a href={path} className="rdnaLink">
      {children}
    </a>
  )
}

const PlaceholderIcon = () => <div style={{ width: 20, height: 20 }} />

const NavItemMain = ({
  title,
  id,
  Icon,
  subroutes,
  fullUrl,
  sectionTitle,
  clickableLink,
  AfterLabelIcon
}: NavigationItem) => {
  const ItemIcon = Icon || PlaceholderIcon
  const {
    activeRouteId,
    clickedRouteId,
    hideMainNav,
    showAllSubroutes,
    minimizedNav,
    onClickRoute,
    onRouteSelect,
    activeSubrouteId,
    isReactBrowserRouter,
    userSettings,
    showVerticalLines
  } = useNavContext()
  const history = useHistory()
  const subRouteActive = subroutes?.some(function (subroute) {
    return subroute.id === activeSubrouteId
  })
  const selected = id === activeRouteId
  const isNavGroupLabel = id === 'title' || sectionTitle
  const [isOpen, setIsOpen] = useState<boolean>(subRouteActive || !!showAllSubroutes || !!hideMainNav || selected)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleOnClick = useCallback(() => {
    if (onRouteSelect && !clickableLink) {
      if (subroutes) {
        setIsOpen(!isOpen)
        if (onClickRoute) {
          onClickRoute(id)
        }
      } else onRouteSelect(id, title)
    } else if (subroutes && !clickableLink) {
      setIsOpen(!isOpen)
    }
  }, [id, title, onRouteSelect, isOpen, subroutes, onClickRoute, clickableLink])

  useEffect(() => {
    if (isOpen && clickedRouteId && clickedRouteId !== id) {
      setIsOpen(!isOpen)
    }
  }, [clickedRouteId, id, isOpen])

  const handleMenuClick = useCallback(
    (name: string) => {
      if (subroutes) {
        const subrouteClicked = subroutes.find(subroute => subroute.title === name)
        if (subrouteClicked) {
          if (isReactBrowserRouter) {
            history.push(`${subrouteClicked.fullUrl}`)
          }
        }
      }
    },
    [subroutes, isReactBrowserRouter, history]
  )

  const subRouteMenuOptions: MenuOption[] =
    subroutes?.map(subroute => {
      return { text: subroute.title }
    }) || []
  return (
    <LeftNavWrapper>
      {isNavGroupLabel && (
        <NavGroupContainer>
          <RdnaTextTitle bold style={{ textTransform: 'uppercase' }}>
            {title}
          </RdnaTextTitle>
          <Trailing> </Trailing>
        </NavGroupContainer>
      )}
      {!hideMainNav &&
        !isNavGroupLabel &&
        (!clickableLink ? (
          <NavLink subroutes={subroutes} fullUrl={fullUrl}>
            <ItemContainer
              $dataTab={id}
              name={`${id}-${activeRouteId}`}
              $selected={selected && (!subRouteActive || !!minimizedNav)}
              onClick={handleOnClick}
              $minimized={minimizedNav}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
              $showVerticalLines={showVerticalLines}
            >
              {minimizedNav ? (
                <RdnaTooltip title={title} placement={'right'} open={tooltipOpen}>
                  {subroutes ? (
                    <div>
                      <StyledMenu
                        options={subRouteMenuOptions}
                        onSelect={handleMenuClick}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        onOpen={() => setTooltipOpen(false)}
                      >
                        <IconContainer>
                          <ItemIcon />
                        </IconContainer>
                      </StyledMenu>
                    </div>
                  ) : (
                    <IconContainer>
                      <ItemIcon />
                    </IconContainer>
                  )}
                </RdnaTooltip>
              ) : (
                <>
                  <IconContainer>
                    <ItemIcon />
                  </IconContainer>
                  <TitleContainer>
                    <RdnaText>{title}</RdnaText>
                  </TitleContainer>
                  {AfterLabelIcon && (
                    <IconContainer>
                      <AfterLabelIcon />
                    </IconContainer>
                  )}
                </>
              )}
              {(id === 'conversations' || id === 'lightning/n/RDNACadence__Settings') &&
                !minimizedNav &&
                !clickableLink && (
                  <IconHelperOpen>
                    <IconHelper icon={LinkIcon} color={Colors.M60} />
                  </IconHelperOpen>
                )}
              {id === 'salesforce-profiles' && userSettings?.hasNewProfiles && !minimizedNav && (
                <IconHelperOpen>
                  <IconHelper icon={Alert} shape="circle" variant="filled" color={Colors.F60} iconSize={24} />
                </IconHelperOpen>
              )}
              {subroutes && !minimizedNav && (
                <CaretFilled size={12} color={Colors.N100} direction={isOpen ? Direction.UP : Direction.DOWN} />
              )}
            </ItemContainer>
          </NavLink>
        ) : (
          <NavItemContainer onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
            {minimizedNav ? (
              <RdnaTooltip title={title} placement={'right'} open={tooltipOpen}>
                <IconContainer>
                  <ItemIcon />
                </IconContainer>
              </RdnaTooltip>
            ) : (
              <>
                <IconContainer>
                  <ItemIcon />
                </IconContainer>
                <TitleContainer>
                  <RdnaText>{title}</RdnaText>
                </TitleContainer>
                {AfterLabelIcon && (
                  <IconContainer>
                    <AfterLabelIcon />
                  </IconContainer>
                )}
              </>
            )}
          </NavItemContainer>
        ))}
      {subroutes && (
        <Collapse in={isOpen && !minimizedNav}>
          {subroutes.map(item => (
            <NavItemSub level={0} key={item.id} parentTitle={title} {...item} />
          ))}
        </Collapse>
      )}
    </LeftNavWrapper>
  )
}

const NavItemSub = React.memo(
  ({ title, id, subroutes, level, fullUrl, parentTitle }: NavigationItem & { level: number; parentTitle: string }) => {
    const { activeRouteId, activeSubrouteId, onSubrouteSelect, minimizedNav, showVerticalLines, showHorizontalLines } =
      useNavContext()
    const selected = id === activeSubrouteId
    const subrouteSelected =
      subroutes?.some(subroute => {
        return subroute.id === activeSubrouteId
      }) || false

    const [isOpen, setIsOpen] = useState<boolean>(selected || subrouteSelected)

    useEffect(() => {
      if (subroutes) {
        const subRouteDeeplink = subroutes.some((subroute: NavigationItem) => subroute.id === activeSubrouteId)
        if (subRouteDeeplink) {
          setIsOpen(true)
        }
      }
    }, [subroutes, activeSubrouteId])

    const handleOnClick = useCallback(() => {
      if (onSubrouteSelect) {
        if (subroutes) {
          setIsOpen(!isOpen)
        } else onSubrouteSelect(id, title, parentTitle)
      }
    }, [onSubrouteSelect, subroutes, isOpen, id, title, parentTitle])

    return (
      <>
        <NavLink subroutes={subroutes} fullUrl={fullUrl}>
          <ItemContainer
            name={`${id}-${activeRouteId}`}
            onClick={handleOnClick}
            $level={level}
            $selected={selected && !subrouteSelected && !minimizedNav}
            $minimized={minimizedNav}
            $showVerticalLines={showVerticalLines}
          >
            {!minimizedNav && (
              <>
                <IconContainer>
                  {showVerticalLines && <Vertical />}
                  {showHorizontalLines && <Line />}
                  {!showVerticalLines && !showHorizontalLines && <Bullet />}
                </IconContainer>
                <TitleContainer $showVerticalLines={showVerticalLines}>
                  <RdnaText bold={selected}>{title}</RdnaText>
                </TitleContainer>
                {subroutes && (
                  <CaretFilled size={6} color={Colors.N100} direction={isOpen ? Direction.UP : Direction.DOWN} />
                )}
              </>
            )}
          </ItemContainer>
        </NavLink>
        {subroutes && (
          <Collapse in={isOpen}>
            {subroutes.map(item => (
              <NavItemSub level={level + 1} key={item.id} parentTitle={title} {...item} />
            ))}
          </Collapse>
        )}
      </>
    )
  }
)

NavItemSub.displayName = 'NavItemSub'

type Props = {
  initToFirstSubroute?: boolean
  margin?: boolean
  onToggle?: (isCollapsed: boolean) => void
}

const LeftNavContent = ({ initToFirstSubroute, margin = true, onToggle }: Props) => {
  const { routes, onSubrouteSelect, activeRouteId, hideMainNav, activeSubrouteId, minimizedNav, setMinimizedNav } =
    useNavContext()
  const initialized = useRef(false)
  const activeRoute = routes.find(route => route.id === activeRouteId)
  const subroutes = activeRoute && activeRoute.subroutes

  const toggleMenu = useCallback(() => {
    if (onToggle) onToggle(!minimizedNav)
    if (setMinimizedNav) {
      setMinimizedNav(!minimizedNav)
    }
  }, [minimizedNav, setMinimizedNav, onToggle])

  useEffect(() => {
    if (initToFirstSubroute && subroutes && subroutes.length && onSubrouteSelect && !initialized.current) {
      const id = activeSubrouteId || subroutes[0].id
      const title = subroutes.find(route => route.id === id)?.title || ''
      onSubrouteSelect(id, title, activeRoute.title)
      initialized.current = true
    }
  }, [initToFirstSubroute, subroutes, onSubrouteSelect, activeSubrouteId, activeRoute])

  return (
    <NavContainer
      data-testid={`left-nav-${minimizedNav ? 'closed' : 'open'}`}
      $margin={margin}
      data-analyticsid="global-left-nav"
      className={minimizedNav ? 'nav-lg' : 'nav-sm'}
    >
      {!hideMainNav && (
        <MenuToggle onClick={toggleMenu} data-testid="global-nav-toggle">
          <MenuIcon />
        </MenuToggle>
      )}
      {routes.map(({ title, id, Icon, fullUrl, subroutes, sectionTitle, clickableLink, AfterLabelIcon }) => (
        <NavItemMain
          key={`${title}-id`}
          id={id}
          title={title}
          Icon={Icon}
          AfterLabelIcon={AfterLabelIcon}
          subroutes={subroutes}
          fullUrl={fullUrl}
          sectionTitle={sectionTitle}
          clickableLink={clickableLink}
        />
      ))}
    </NavContainer>
  )
}

const Vertical = () => <VerticalLine />

const Bullet = () => (
  <BulletOuter>
    <BulletInner />
  </BulletOuter>
)

const Line = () => (
  <BulletOuter>
    <LineInner />
  </BulletOuter>
)

export default LeftNavContent

const LeftNavWrapper = styled.div`
  a {
    text-decoration: none !important;
  }
`

const VerticalLine = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-left: 10px;
  background: ${Colors.N40};
  width: 1px;
`

const BulletOuter = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`

const BulletInner = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${Colors.N100};
  border-radius: 2px;
`

const LineInner = styled.div`
  width: 10px;
  height: 1px;
  background-color: ${Colors.N100};
  border-radius: 2px;
`

type ItemContainerProps = {
  $dataTab?: string | number
  name?: string | number
  $level?: number
  $selected: boolean
  $minimized?: boolean
  $showVerticalLines?: boolean
}

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  flex-direction: row;
  padding: 6px ${props => (props.$level ? ` 0 9px ${props.$level * 18}px` : '0')};
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background-color: ${props => (props.$selected ? Colors.N30 : Colors.TRANSPARENT)};
  font-weight: ${props => (props.$selected ? 'bold' : 'regular')};
  width: ${props => (props.$minimized ? `${props.theme.spacing * 8}px` : `${props.theme.spacing * 38}px`)};
  margin-bottom: ${props => props.theme.spacing}px;
  position: relative;

  ${props =>
    props.$showVerticalLines &&
    !props.$dataTab &&
    css`
      margin: 0;
      padding: 0;
    `}

  .caret-filled {
    margin-left: auto;
    margin-right: ${props => props.theme.spacing * 2}px;
    padding: 0;
  }
`

const NavContainer = styled.div<{ $margin?: boolean }>`
  ${props => props.$margin && `margin: 0 ${props.theme.spacing * 2}px;`}
`

const IconContainer = styled.div`
  height: ${props => props.theme.spacing * 4}px;
  padding: 0 ${props => props.theme.spacing}px 0 ${props => props.theme.spacing * 2}px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  display: flex;
  svg {
    width: 24px;
  }
`

const NavItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  border-radius: 6px;
  align-items: center;
  text-decoration: none;

  .caret-filled {
    margin-left: auto;
    margin-right: 12px;
    padding: 0;
  }
`

type TitleContainerProps = {
  $showVerticalLines?: boolean
}

const TitleContainer = styled.div<TitleContainerProps>`
  margin: 0 10px;
  padding: ${props => (props.$showVerticalLines ? `8px 12px` : '0')};
`

const MenuToggle = styled(IconButton)`
  &.MuiButtonBase-root {
    margin: 16px 2px 6px;
  }
`

const NavGroupContainer = styled.div`
  display: flex;
  margin: 10px 0;
`

const RdnaTextTitle = styled(RdnaText)`
  font-size: 11px;
  color: ${Colors.N70};
`

const Trailing = styled(RdnaText)`
  border-bottom: 1px solid ${Colors.N40};
  flex-grow: 1;
  height: 13px;
  margin-left: 5px;
`

const IconHelperOpen = styled.div`
  margin-left: auto;
`

const StyledMenu = styled(RdnaMenu)`
  .rdna-menu-toggle {
    margin: 0;
  }
`
