import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function WebRTCIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <mask id="path-2-inside-1_8036_47904" fill="#fff">
        <rect x="4.5" y="6.75" width="15" height="10.5" rx="0.375" />
      </mask>
      <rect
        x="4.5"
        y="6.75"
        width="15"
        height="10.5"
        rx="0.375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_8036_47904)"
      />
      <path
        d="M15.375 12C15.375 13.864 13.864 15.375 12 15.375C10.136 15.375 8.625 13.864 8.625 12C8.625 10.136 10.136 8.625 12 8.625C13.864 8.625 15.375 10.136 15.375 12Z"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 12C13.125 13 12.9622 13.8881 12.7125 14.5124C12.5872 14.8255 12.4474 15.0538 12.3114 15.1976C12.1761 15.3407 12.0708 15.375 12 15.375C11.9292 15.375 11.8239 15.3407 11.6886 15.1976C11.5526 15.0538 11.4128 14.8255 11.2875 14.5124C11.0378 13.8881 10.875 13 10.875 12C10.875 11 11.0378 10.1119 11.2875 9.48762C11.4128 9.17446 11.5526 8.9462 11.6886 8.80236C11.8239 8.65931 11.9292 8.625 12 8.625C12.0708 8.625 12.1761 8.65931 12.3114 8.80236C12.4474 8.9462 12.5872 9.17446 12.7125 9.48762C12.9622 10.1119 13.125 11 13.125 12Z"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12H15"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default WebRTCIcon
