import React, { ReactNode } from 'react'
import { Card, CardActionArea, CardContent, CardProps } from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors } from '../../constants/colors'
import ConditionalWrapper from '../../utils/conditionalWrapper'

type WidthOptions = 'fit' | 'fill'
type PaddingOptions = 'dense' | 'normal' | 'none'

export interface CardStyleProps extends CardProps {
  /** Width of the card. Fit will expand to the width on the card's content. Fill will expand to the width of the card's parent. */
  width?: WidthOptions
  /** Padding of card content. normal = 24px, dense = 12px */
  padding?: PaddingOptions
  /** Color of border. If border color passed, no drop shadow */
  bordercolor?: Colors
  /** Background color of card content */
  backgroundcolor?: Colors | string
}

export interface RdnaCardProps extends CardStyleProps {
  children: ReactNode
  className?: string
  onClick?: () => void
}

export default function RdnaCard({
  children,
  width = 'fit',
  padding = 'normal',
  backgroundcolor,
  className = '',
  onClick,
  ...props
}: RdnaCardProps) {
  return (
    <StyledCard width={width} className={className} {...props}>
      <ConditionalWrapper
        condition={!!onClick}
        wrapper={children => <CardActionArea onClick={onClick}>{children}</CardActionArea>}
      >
        <StyledCardContent padding={padding} backgroundcolor={backgroundcolor}>
          {children}
        </StyledCardContent>
      </ConditionalWrapper>
    </StyledCard>
  )
}

const StyledCard = styled(Card)<CardStyleProps>`
  width: ${props => (props.width === 'fit' ? 'fit-content' : 'auto')};
  ${props =>
    props.bordercolor &&
    css`
      border: 1px solid ${props.bordercolor};
      box-shadow: none !important;
    `}
`

const StyledCardContent = styled(CardContent)<{ padding: PaddingOptions; backgroundcolor?: Colors | string }>`
  position: relative;
  padding: ${({ theme, padding }) =>
    padding === 'none' ? 0 : padding === 'dense' ? theme.spacing * 2 : theme.spacing * 3}px !important;
  &:last-child {
    padding-bottom: ${({ theme, padding }) =>
      padding === 'dense' ? theme.spacing * 2 : theme.spacing * 3}px !important;
  }
  ${props =>
    props.backgroundcolor &&
    css`
      background-color: ${props.backgroundcolor};
    `}
`
