BaseCollection = require('base/collection')
CallTrackingHelper = require('features/call-tracking/helper')
Model = require('./adwords-model')

class AdWordsCallTrackingCollection extends BaseCollection
  url: -> 'adwords'
  setParams: (options) ->
    { @accountId } = options
    @
  toJSON: =>
    fields = @toPlayJSON('adWordCallTracking')
    fields['accountId'] = @accountId
    fields

  model: Model

  createAdWords: (notAddressesCollection, callback) =>
    @setParams(accountId: @accountId)
    @create().done((adwordses) =>
      needAddresses = CallTrackingHelper.filterCallTracking(@, adwordses)
      @reset(needAddresses) if needAddresses.length isnt 0
      @reset(notAddressesCollection.models) if notAddressesCollection and notAddressesCollection.length isnt 0
      callback(needAddresses.length isnt 0) if callback
    )

module.exports = AdWordsCallTrackingCollection