import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceCustomIcon({ size = 14, color = Colors.SALESFORCE_CUSTOM }: BaseIconProps) {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 13 14">
      <g fill={color} clipPath="url(#a)">
        <path d="M6.836 7.412v5.56c0 .183.183.274.342.206 1.094-.616 4.421-2.507 4.421-2.507.433-.25.707-.707.707-1.208v-4.99c0-.183-.183-.274-.342-.206l-4.9 2.758a.47.47 0 0 0-.228.387ZM6.38 5.862l4.923-2.757c.16-.091.16-.296 0-.388C10.209 2.103 6.859.188 6.859.188c-.432-.25-.98-.25-1.413 0 0 0-3.35 1.891-4.443 2.53-.16.09-.16.296 0 .387l4.922 2.757a.532.532 0 0 0 .456 0ZM5.241 7.024l-4.9-2.757C.183 4.176 0 4.29 0 4.472V9.44c0 .501.273.957.706 1.208 0 0 3.328 1.891 4.421 2.506.16.091.342-.022.342-.204V7.412a.47.47 0 0 0-.228-.388Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M0 0h12.306v13.2H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SalesforceCustomIcon
