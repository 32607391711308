import React, { useState } from 'react'
import Chat, { serverIdentity } from './Chat'
import { atom } from 'jotai'
import styled from 'styled-components'
import RdnaSelect, { RdnaSelectOptions } from '../RdnaFormElements/RdnaSelect'
import { SelectChangeEvent } from '@mui/material'
import RdnaIconHelper from '../RdnaFormElements/RdnaIconButton'
import ChatIcon from '../../assets/icons/svg/Chat'
import RdnaText from '../RdnaText'
import CloseIcon from '../../assets/icons/svg/Close'
import { Colors } from '../../constants/colors'
import { TrackEvent } from '../../utils/analyticsUtils'
import { chatRooms, GenAiEvents, revIqId } from './config'

export type ChatMessage = {
  message: React.ReactNode | string
  owner: string
  isAgent: boolean
  id: string
}

export type ApiBase = 'apiLabs' | 'apiGpu' | 'apiPlay'

export const DrawerHeaderBackground = 'linear-gradient(252deg, #7549EA 0%, #FF4545 100%)'
export const widgetWidthMultiplier = 72
const headerHeightMultiplier = 9

export const contentWidthMultiplier = widgetWidthMultiplier - 6

const options: RdnaSelectOptions<string>[] = chatRooms.map(room => {
  return {
    label: room.label,
    value: room.value
  }
})

export const chatAtom = atom<ChatMessage[]>([
  {
    message: 'What is the title of the person you want to better communicate with?',
    id: revIqId,
    ...serverIdentity
  }
])

export type GenAiProps = {
  userName: string
  contextQueryParams?: { [key: string]: string }
  contextLabel?: string
  defaultChatRoom?: string
  playBasePath: string
  analytics: { trackEvent: TrackEvent }
}

export const streamAtom = atom<{ [key: string]: string }>({})
export default function RdnaGenAi({
  userName,
  contextQueryParams,
  contextLabel,
  defaultChatRoom,
  playBasePath,
  analytics
}: GenAiProps) {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [activeChat, setActiveChat] = useState(defaultChatRoom || chatRooms[0].value)

  const onSelect = (event: SelectChangeEvent<unknown>) => {
    setActiveChat(event.target.value as string)
  }

  const onTriggerClick = () => {
    setDrawerOpen(prev => {
      analytics.trackEvent(!prev ? GenAiEvents.OPEN_CHAT : GenAiEvents.CLOSE_CHAT)
      return !prev
    })
  }

  return (
    <>
      <TriggerButton>
        <RdnaIconHelper
          icon={ChatIcon}
          size={60}
          iconSize={36}
          onClick={onTriggerClick}
          variant={'filled'}
          testId={'gen-ai-trigger-btn'}
          color={DrawerHeaderBackground}
        />
      </TriggerButton>
      {drawerOpen && (
        <WidgetContainer>
          <WidgetHeader>
            <RdnaText variant={'body3'} bold color={'contrast'}>
              Revenue AI
            </RdnaText>
            <RdnaIconHelper
              icon={CloseIcon}
              onClick={() => setDrawerOpen(false)}
              color={Colors.N0}
              iconSize={24}
              testId={'chat-close'}
            />
          </WidgetHeader>
          <WidgetContent>
            {!defaultChatRoom && (
              <RoomSelector>
                <RdnaSelect
                  options={options}
                  name={'llm'}
                  label="Select LLM"
                  placeholder="Select LLM"
                  value={activeChat}
                  onChange={onSelect}
                />
              </RoomSelector>
            )}
            {chatRooms.map(room => {
              return (
                <Chatroom
                  key={`${room.value}-2`}
                  className={room.value === activeChat ? '' : 'hidden'}
                  $showDropdown={!defaultChatRoom}
                >
                  <Chat
                    apiPath={room.apiPath}
                    apiBase={room.apiBase}
                    playBasePath={playBasePath}
                    userName={userName}
                    queryParams={{ ...room.queryParams, ...contextQueryParams }}
                    id={room.value}
                    isActive={room.value === activeChat}
                    starterQuestions={room.starterQuestions}
                    analytics={analytics}
                    contextLabel={contextLabel}
                  />
                </Chatroom>
              )
            })}
          </WidgetContent>
        </WidgetContainer>
      )}
    </>
  )
}

const TriggerButton = styled.div`
  position: fixed !important;
  bottom: ${({ theme }) => theme.spacing * 2}px;
  right: ${({ theme }) => theme.spacing * 2}px;
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
  transition: transform ${({ theme }) => theme.transitions.duration.short}ms ease-in-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.15);
  }
`

const WidgetContainer = styled.div`
  width: ${({ theme }) => theme.spacing * widgetWidthMultiplier}px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.spacing}px;
  height: ${({ theme }) => theme.spacing * 106}px;
  bottom: ${({ theme }) => theme.spacing * 14}px;
  right: ${({ theme }) => theme.spacing * 2}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  position: fixed !important;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  overflow: hidden;
`

const WidgetHeader = styled.div`
  height: ${({ theme }) => theme.spacing * headerHeightMultiplier}px;
  padding: 0 ${({ theme }) => theme.spacing * 3}px;
  background: ${DrawerHeaderBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WidgetContent = styled.div`
  padding: 0 ${({ theme }) => theme.spacing * 3}px ${({ theme }) => theme.spacing * 3}px;
  height: calc(100% - ${({ theme }) => theme.spacing * headerHeightMultiplier}px);
`

const RoomSelector = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
`

const Chatroom = styled.div<{ $showDropdown: boolean }>`
  height: calc(100% - ${({ $showDropdown, theme }) => ($showDropdown ? theme.spacing * 10 : 0)}px);
`
