BaseView                  = require('base/view')
AccountModel              = require('features/accounts/model')
LicenseModel              = require('./licenses/model')
LicensesView              = require('./licenses/view')
LogsView                  = require('./logs/view')
OverviewView              = require('./overview/view')

class AccountTabsView extends BaseView
  template: require('./tabs-template')

  className: 'account-tabs'

  regions:
    tab: '.content-tabs'

  ui:
    accountName : '.account-name'
    saveButton  : '.save'

  bindings:
    'a[data-tab="local-presence"]'  : 'toggle:all(isSupport)'
    'a[data-tab="emergency"]'       : 'toggle:all(isSupport)'

  events:
    'click @ui.saveButton' : 'saveClicked'
    'click a[data-tab]'    : 'tabClicked'

  initialize: (options) =>
    @accountId = options.accountId
    @selected  = options.tab ? 'overview'
    @tab       = @getRegion('tab')
    [path, querystring] = window.location.hash.split('?')
    @qs = querystring

  onAttach: =>
    @showTab(@selected)

  saveClicked: =>
    @tab.currentView.triggerMethod('save:clicked', @ui.saveButton[0])

  tabClicked: (e) =>
    tab = $(e.currentTarget).attr('data-tab')
    @showTab(tab)

  showTab: (name) =>
    @ui.saveButton.hide()
    @selected = name
    fragment = '' if @selected is 'overview'
    fragment = "/#{name}"

    $("a[data-tab='#{name}']").tab('show')
    App.navigate("accounts/#{@accountId}#{fragment}")

    if @user().accountPickerRenderable() and
        (@user().getSelectedSupportAccountId() is null or @user().getSelectedSupportAccountId()?.toString() isnt @accountId?.toString())
      accountModel = new AccountModel(id: @accountId)
      accountModel.fetch().done(=>
        if @user().getSelectedSupportAccountId() is null
          App.view?.header?.currentView?.updateAccountPicker(@accountId, accountModel.get('name'))
        else if @user().getSelectedSupportAccountId()?.toString() isnt @accountId?.toString()
          @confirmSwitchAccount(@user().getSelectedSupportAccountName(), accountModel.get('name'), @accountId)
      )

    switch name

      when 'licensing'
        model = new LicenseModel()
        model.fetch(data: { accountId: @accountId }).done(=>
          @ui.accountName.text(model.get('accountName')) if typeof @ui.accountName is 'object'
          @tab.show(new LicensesView(accountId: @accountId, model: model))
        )

      when 'logs'
        model = new AccountModel(id: @accountId)
        model.fetch().done(=>
          @ui.accountName.text(model.get('name')) if typeof @ui.accountName is 'object'
          @tab.show(new LogsView(accountId: @accountId))
        )

      when 'overview'
        model = new AccountModel(id: @accountId)
        model.fetch().done(=>
          @ui.accountName.text(model.get('name')) if typeof @ui.accountName is 'object'
          @tab.show(new OverviewView(model: model))
          @ui.saveButton.show() if typeof @ui.saveButton is 'object'
        )
      else
        throw new Error("Unknown tab: #{name}")

  confirmSwitchAccount: (sourceName, targetName, targetAccountId) =>
    bootbox.dialog(
      closeButton: false
      title: 'Please confirm'
      message: "By proceeding, the “Account” filter value will be updated from #{sourceName} to #{targetName}. Click OK to confirm and proceed."
      buttons:
        cancel:
          label: 'Cancel'
          className: 'btn btn-default'
          callback: =>
            App.navigatePrevious()
            true
        ok:
          label: 'OK'
          className: 'btn btn-info'
          callback: =>
            App.view?.header?.currentView?.updateAccountPicker(targetAccountId, targetName)
            true
    )

module.exports = AccountTabsView
