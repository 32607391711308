BaseModel = require('base/model')

class BaseCallTrackingModel extends BaseModel
  defaults: ->
    areaCode: ''
    callFlow: ''
    campaignId: ''
    channel: ''
    extraInfo: ''
    labelTracking: ''
    source: ''
    type: 'Label'
    typeNumber: ''
    smartNumberId: null
    number: null

  addNumber: (smartNumberId, number) =>
    @set('smartNumberId', smartNumberId)
    @set('number', number)

  removeNumber: =>
    @set('typeNumber', 'none')
    @set('number', null)
    @set('smartNumberId', null)

  updateNumberType: (newType) =>
    if (@get('typeNumber') == 'Existing' && newType != 'Existing')
      @set('smartNumberId', null)
      @set('number', null)
    @set('typeNumber', if newType is 'none' then null else newType)


module.exports = BaseCallTrackingModel
