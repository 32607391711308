import React from 'react'

export type TableRowProps = {
  children: React.ReactNode
  className?: string
}

export const RdnaTableRow = ({ children, className = '' }: TableRowProps) => {
  return (
    <div className={`ringdna-table-row ${className}`} data-testid="ringdna-table-row">
      {children}
    </div>
  )
}
