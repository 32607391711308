/* global globalThis */
import {
  ApiClient,
  RequestStage,
  MethodOptions,
  ParamsGeneric,
  HttpMethods,
  CacheType,
  ResourceFlag,
  Resource
} from './constants'
import { inferCacheKey } from './inferCacheKey'

// @ts-ignore
const isBuild = import.meta.env.PROD
function createFetch<Payload, Params extends ParamsGeneric = void | null>(methodOptions: MethodOptions<Params>) {
  if (!isBuild) {
    // @NOTE if you change any of this code, createUseFetch needs to be updated too
    // @ts-ignore
    const ck = globalThis._client_keys || new Set()
    if (ck.has(methodOptions.key))
      throw new Error(
        `You are trying to create a second api method with key: ${methodOptions.key}. This is not supported and can lead to aberrant behavior.`
      )
    ck.add(methodOptions.key)
    // @ts-ignore
    globalThis._client_keys = ck
  }

  return async (client: ApiClient, params: Params): Promise<Resource<Payload, Params>> => {
    const { cache, execute } = client
    const independent =
      methodOptions.independent === undefined ? methodOptions.method !== HttpMethods.Get : methodOptions.independent
    const cacheType = methodOptions.cache || CacheType.Memory

    // the logic gets a little tricky in here, but basically we have a set of rules to allow a refetch to override the cacheKey or params.
    const realizedPath = typeof methodOptions.path === 'function' ? methodOptions.path(params) : methodOptions.path
    const cacheKey = inferCacheKey(
      methodOptions.api,
      methodOptions.key,
      methodOptions.method,
      cacheType,
      independent,
      JSON.stringify(params)
    )

    const resource = {
      // if not independent, build on existing resource
      ...(!independent && cache.get(cacheKey)),
      // "realize" methodOptions
      methodOptions: { ...methodOptions, path: realizedPath, independent },
      flag: ResourceFlag.Stable,
      params,
      timestamp: Date.now(),
      stage: RequestStage.Latent,
      cacheKey
    }
    if (!cache.isReady) await cache.readyPromise
    return execute(resource, client) as Promise<Resource<Payload, Params>>
  }
}

export { createFetch }
