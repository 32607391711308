/* eslint-disable camelcase */
import { HttpMethods } from '@ringdna/common/src/client/constants'

import {
  Reviewer,
  ReviewerOption,
  SavedSearch,
  Sentiment,
  SmartRecording,
  SmartRecordingSearchItem,
  SmartRecordingTag,
  TagSentimentCount,
  Annotation,
  Library,
  SearchVisibility,
  NotificationFrequency,
  TriggeredCall,
  SupportAccessDeniedRecording
} from '@ringdna/common/src/types/smart-recording'
import { NotificationSetting } from '../../components/settings/notifications/model'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { UserSettings } from './user-settings'
import { TranscriptionLanguage } from '@ringdna/common/src/types/user-settings'
import { KeywordFilter } from '../../components/Calls/filters/AutocompleteFilterKeywordGroup'

type SmartRecordingDetailsParam = {
  query?: {
    callKey: string
    accountId: number
    includeZoomData: boolean
  }
}

export const useSmartRecordingDetails = createUseFetchAction<
  SmartRecording | SupportAccessDeniedRecording,
  SmartRecordingDetailsParam
>({
  key: 'fetch-smart-recording-details',
  path: '/api/v3/app/smartRecordings/play'
})

type SmartRecordingDetailsExternalParam = {
  meta?: {
    callKey: string
    shareId: string
  }
}

export const useSmartRecordingDetailsExternal = createUseFetchAction<
  SmartRecording,
  SmartRecordingDetailsExternalParam
>({
  key: 'fetch-smart-recording-details-external',
  path: params => `/api/v3/externalShare/smartRecordings/${params.meta?.callKey}/play/${params.meta?.shareId}`
})

export const useSmartRecordingSettings = createUseFetch<NotificationSetting[], AccountIdQueryParam>({
  key: 'fetch-smart-recording-settings',
  path: '/api/v3/app/smartRecordings/notifications',
  independent: true
})

type UpdateSmartRecordingSettingParam = {
  meta: { id: number }
  json: any
}
export const useUpdateSmartRecordingSetting = createUseFetchAction<void, UpdateSmartRecordingSettingParam>({
  key: 'update-smart-recording-setting',
  path: params => `/api/v3/app/smartRecordings/notification/${params.meta.id}`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type AccountIdQueryParam = {
  query: {
    accountId: number
  }
}

export const useLibraries = createUseFetch<Library[], AccountIdQueryParam>({
  key: 'use-smart-recording-libraries',
  path: '/api/v3/app/smartRecordings/libraries',
  independent: true
})

type AddLibraryParam = {
  meta: { id: number }
  json: {
    libraryId: number
    accountId: number
  }
}

export const useAddLibrary = createUseFetchAction<void, AddLibraryParam>({
  key: 'put-smart-recording-library',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/library`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type RemoveLibraryParam = {
  meta: { id: number }
  query: {
    libraryId: number
    accountId: number
  }
}
export const useRemoveLibrary = createUseFetchAction<void, RemoveLibraryParam>({
  key: 'delete-smart-recording-library',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/library`,
  method: HttpMethods.Delete
})

type PutSmartRecordingShareParam = {
  meta: { id: number }
  json: {
    message: string
    startPlaybackAt: number
    userIds: string[]
    accountId: number
  }
}
export const usePutSmartRecordingShare = createUseFetchAction<void, PutSmartRecordingShareParam>({
  key: 'put-smart-recording-share',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/share`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type FetchReviewersParam = {
  query: {
    name: string
    recordingId: number
    accountId: number
  }
}
export const useFetchReviewers = createUseFetchAction<Array<ReviewerOption>, FetchReviewersParam>({
  key: 'fetch-smart-recording-reviewers',
  path: '/api/v3/app/smartRecordings/reviewers'
})

type PostReviewerParam = {
  json: {
    recordingId: number
    userIds: Array<number>
    accountId: number
  }
}
export const usePostReviewer = createUseFetchAction<void, PostReviewerParam>({
  key: 'post-smart-recording-reviewer',
  path: '/api/v3/app/smartRecordings/reviewers',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type PostAnnotationParam = {
  meta: { id: number }
  json: {
    duration: number
    startTime: string
    endTime: string
    notes: string
    editable: boolean
    visible: boolean
    accountId: number
  }
}
export const usePostAnnotation = createUseFetchAction<Annotation, PostAnnotationParam>({
  key: 'post-smart-recording-annotation',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/annotations`,
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type PutAnnotationParam = {
  meta: { id: number }
  json: {
    annotationId: number
    duration: number
    startTime: string
    endTime: string
    notes: string
    editable: boolean
    visible: boolean
    accountId: number
  }
}
export const usePutAnnotation = createUseFetchAction<Annotation, PutAnnotationParam>({
  key: 'put-smart-recording-annotation',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/annotations/${params.json.annotationId}`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type DeleteAnnotationTagParam = {
  meta: { id: number; tagId: number }
  query: { accountId: number }
}
export const useDeleteAnnotationTag = createUseFetchAction<void, DeleteAnnotationTagParam>({
  key: 'delete-smart-recording-annotation-tag',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/tags/${params.meta.tagId}`,
  method: HttpMethods.Delete
})

type PutAnnotationTagParam = {
  meta: { id: number }
  json: {
    annotationId: number
    tagId: number
    sentiment: Sentiment
    accountId: number
  }
}
export const usePutAnnotationTag = createUseFetchAction<SmartRecordingTag, PutAnnotationTagParam>({
  key: 'put-smart-recording-annotation-tag',
  path: params => `/api/v3/app/smartRecordings/${params.meta.id}/tags`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type PostUploadCallParam = {
  json: {
    callKey: string
    accountId: number
  }
}

export const usePostUploadCall = createUseFetchAction<void, PostUploadCallParam>({
  key: 'post-smart-recording-upload-call',
  path: '/api/v3/app/smartRecordingsSystem/uploadCall',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type PutCallNotesParam = {
  meta: { callKey: string }
  json: {
    callKey: string
    callNotes: string
    accountId: number
  }
}
export const usePutCallNotes = createUseFetchAction<void, PutCallNotesParam>({
  key: 'put-calls-call-notes',
  path: params => `/api/v3/calls/${params.meta.callKey}/callNotes`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type PutSupervisorNotesParam = {
  meta: { callKey: string }
  json: {
    callKey: string
    supervisorNotes: string
    app: string
    accountId: number
  }
}
export const usePutSupervisorNotes = createUseFetchAction<void, PutSupervisorNotesParam>({
  key: 'put-calls-supervisor-notes',
  path: params => `/api/v3/calls/${params.meta.callKey}/supervisorNotes`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type PutReviewParam = {
  json: {
    id: number
    accountId: number
  }
}
export const usePutReviewerParam = createUseFetchAction<Reviewer, PutReviewParam>({
  key: 'put-smart-recordings-reviewed',
  path: '/api/v3/app/smartRecordings/reviewed',
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

export const useFetchTagSentimentCounts = createUseFetch<TagSentimentCount[], AccountIdQueryParam>({
  key: 'get-tags-sentiment-counts',
  path: '/api/v3/app/smartRecordings/tags/sentiments'
})

type DeleteTagParam = {
  query: {
    accountId: number
  }
  meta: {
    id: number
  }
}
export const useDeleteTag = createUseFetchAction<void, DeleteTagParam>({
  key: 'delete-tag',
  path: ({ meta }) => `/api/v3/app/tags/${meta.id}`,
  method: HttpMethods.Delete
})

type PostTagParam = {
  json: {
    name: string
    accountId: number
  }
}

export const usePostTag = createUseFetchAction<void, PostTagParam>({
  key: 'post-tags',
  path: '/api/v3/app/tags',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

const TAG_SENTIMENT_SEPARATOR = ':'
export type TagSentimentString = string // tag name:(POSITIVE | NEGATIVE | NONE)
const isSentiment = (sentiment: string | undefined): sentiment is Sentiment => {
  return sentiment === 'POSITIVE' || sentiment === 'NEGATIVE' || sentiment === 'NONE'
}
export const isTagSentimentString = (tag: any): tag is TagSentimentString => {
  if (typeof tag !== 'string') return false
  const separatorIndex = tag.lastIndexOf(TAG_SENTIMENT_SEPARATOR)
  const parsedTag = tag.slice(0, separatorIndex)
  const parsedSentiment = tag.slice(separatorIndex + 1)
  return parsedTag.length > 0 && isSentiment(parsedSentiment)
}
export const parseTagSentiment = (tag: TagSentimentString) => {
  const separatorIndex = tag.lastIndexOf(TAG_SENTIMENT_SEPARATOR)
  const parsedTag = tag.slice(0, separatorIndex)
  const parsedSentiment = tag.slice(separatorIndex + 1)
  return {
    tag: parsedTag,
    sentiment: isSentiment(parsedSentiment) ? parsedSentiment : undefined
  }
}
export const getUniqueTags = (tags: string[]): string[] => {
  const uniqueTagSet = new Set(tags)
  return [...uniqueTagSet]
}

export enum SpeakerType {
  ANY = 'Any',
  AGENT = 'Agent',
  PARTICIPANT = 'Participant'
}

export type EngagementFilter =
  | 'hasNotes'
  | 'hasSupervisorNotes'
  | 'hasAnnotations'
  | 'sharedOthers'
  | 'hasTasks'
  | 'flagged'
export type CallDirectionFilter = 'inbound' | 'outbound'
export type CallRatings = string[]
export type RangeFilter = [number, number]
export type SpeakerFilter = { speaker: SpeakerType; value: string }
export type Keywords = { [groupName: string]: Array<KeywordFilter> }
export type Timeframe = { alias: TimeframeAlias; range?: string[] }
export enum TimeframeAlias {
  'All Time' = 'All Time',
  'Today' = 'Today',
  '1 week' = '1 week',
  '1 month' = '1 month',
  '3 months' = '3 months',
  '1 year' = '1 year',
  'Custom' = 'Custom'
}

export type CallType = 'ZOOM' | 'TWILIO'
export type ExcludeFilterKey = keyof Pick<
  Filters,
  | 'agents'
  | 'company'
  | 'name'
  | 'title'
  | 'callDispositions'
  | 'leadStatuses'
  | 'opportunityStages'
  | 'libraries'
  | 'tags'
  | 'timeframe'
  | 'callRatings'
  | 'engagement'
  | 'keywords'
  | 'query'
>
const EXCLUDE_FILTER_KEYS: ExcludeFilterKey[] = [
  'agents',
  'timeframe',
  'company',
  'name',
  'title',
  'tags',
  'callDispositions',
  'leadStatuses',
  'opportunityStages',
  'callRatings',
  'libraries',
  'engagement',
  'keywords',
  'query'
]
export const isExcludeFilterKey = (key: any): key is ExcludeFilterKey => {
  return EXCLUDE_FILTER_KEYS.includes(key)
}
export type ExcludeFilters = {
  [key in ExcludeFilterKey]?: Array<string | SpeakerFilter>
}

export type Filters = Partial<{
  agents: string // would be string[]
  agentAverageTalkStreak: RangeFilter
  agentLongestStreak: RangeFilter
  agentOvertalkIncidents: RangeFilter
  agentOvertalkRatio: RangeFilter
  agentTalkRatio: RangeFilter
  averageTalkStreak: RangeFilter
  callDirections: CallDirectionFilter[]
  callDispositions: string[]
  callDuration: RangeFilter
  callRatings: CallRatings
  company: string // would be string[]
  engagement: EngagementFilter[]
  // need to add all filters here as well
  teams: string // would be string[]
  interactions: RangeFilter
  leadStatuses: string[]
  libraries: string[]
  tags: TagSentimentString[]
  longestTalkStreak: RangeFilter
  name: string // would be string[]
  opportunityStages: string[]
  opportunityId: string
  overtalkIncidents: RangeFilter
  overtalkRatio: RangeFilter
  query: SpeakerFilter[]
  silenceRatio: RangeFilter
  timeframe: Timeframe
  // timeframes?: string[] // unused
  title: string // would be string[]
  type: CallType[]
  keywords: Keywords
  exclude: ExcludeFilters
  excludeKeywords: ExcludeKeywords
}>
export type ExcludeKeywords = {
  [groupName: string]: Array<KeywordFilter> | undefined
}

export enum SortKey {
  CALL_START_TIME = 'callStartTime',
  OWNER_NAME = 'ownerName',
  CALL_DURATION = 'callDuration',
  ALPHABETICAL = 'alphabetical'
}
export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc'
}
export type FetchSortKey = SortKey.CALL_START_TIME | SortKey.OWNER_NAME | SortKey.CALL_DURATION
type FetchSmartRecordingsParam = {
  query: {
    includeZoomData: boolean
    filters?: Filters
    page: number
    size: number
    sortKey: FetchSortKey
    sortDirection: SortDirection
    accountId: number
  }
}

export const useSmartRecordings = createUseFetch<SmartRecordingSearchItem[], FetchSmartRecordingsParam>({
  key: 'fetch-smart-recordings',
  path: '/api/v3/app/smartRecordings',
  independent: true
})

export const useSavedSearches = createUseFetch<SavedSearch[], AccountIdQueryParam>({
  key: 'fetch-saved-searches',
  path: '/api/v3/app/smartRecordings/search'
})

type LibrarySavedSearchesParam = {
  meta: { id: number }
  query: {
    accountId: number
    includeZoomData: boolean
  }
}
export const useLibrarySavedSearches = createUseFetch<SavedSearch[], LibrarySavedSearchesParam>({
  key: 'fetch-library-saved-searches',
  path: ({ meta }) => `/api/v3/app/smartRecordings/search/library/${meta.id}`
})

type SavedSearchCallsParam = {
  meta: { id: number }
  query: { accountId: number }
}
export const useSavedSearch = createUseFetch<SavedSearch, SavedSearchCallsParam>({
  key: 'fetch-saved-search',
  path: ({ meta }) => `/api/v3/app/smartRecordings/search/${meta.id}`
})

type PutSavedSearchParam = {
  meta: { id: number }
  json: Omit<SavedSearch, 'libraries'> & { libraries: Library[]; accountId: number }
}
export const usePutSavedSearch = createUseFetchAction<void, PutSavedSearchParam>({
  key: 'put-saved-search',
  path: ({ meta }) => `/api/v3/app/smartRecordings/search/${meta.id}`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type PostSavedSearchParam = {
  json: {
    name: string
    visibility: SearchVisibility | null
    notification: NotificationFrequency | null
    filters: string
    filterJson: string // Not sure why both filters and filterJson is passed, but copied over from current implementation
    accountId: number
    libraryIds: Array<number>
  }
}
export const usePostSavedSearch = createUseFetchAction<SavedSearch, PostSavedSearchParam>({
  key: 'post-saved-search',
  path: '/api/v3/app/smartRecordings/search',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type DeleteSavedSearchParam = {
  meta: { id: number }
  query: { accountId: number }
}
export const useDeleteSavedSearch = createUseFetchAction<void, DeleteSavedSearchParam>({
  key: 'delete-saved-search',
  path: ({ meta }) => `/api/v3/app/smartRecordings/search/${meta.id}`,
  method: HttpMethods.Delete
})

type PutSavedSearchShareParam = {
  meta: { id: number }
  json: {
    id: number
    message: string
    sharedIds: string[]
    accountId: number
  }
}
export const usePutSavedSearchShare = createUseFetchAction<void, PutSavedSearchShareParam>({
  key: 'put-saved-search-share',
  path: params => `/api/v3/app/smartRecordings/search/${params.meta.id}/share`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type InboxUnreadCount = {
  unreadCount: number
}

export const useInboxUnreadCount = createUseFetch<
  InboxUnreadCount,
  { query: { includeZoomData: boolean; accountId: number } }
>({
  key: 'fetch-inbox-unread-count',
  path: `/api/v3/app/smartRecordings/inboxUnreadCount`
})

// check if works
type UseTriggeredCallsParam = {
  meta: { id: number }
  query: {
    accountId: number
  }
}
export const useTriggeredCalls = createUseFetch<TriggeredCall[], UseTriggeredCallsParam>({
  key: 'fetch-triggered-calls',
  path: params => `/api/v3/app/inbox/triggeredCalls/${params.meta.id}`
})

type DeleteLibraryParam = {
  meta: { id: number }
  query: {
    accountId: number
  }
}
export const useDeleteLibrary = createUseFetchAction<void, DeleteLibraryParam>({
  key: 'delete-library',
  path: params => `/api/v3/app/library/${params.meta.id}`,
  method: HttpMethods.Delete
})

type EditLibraryParam = {
  meta: { id: number }
  json: {
    id: number
    name: string
    accountId: number
  }
}
export const useEditLibrary = createUseFetchAction<void, EditLibraryParam>({
  key: 'update-library',
  path: params => `/api/v3/app/library/${params.meta.id}`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

type CreateLibraryParam = {
  json: {
    accountId: number
    name: string
  }
}
type CreateLibraryResponse = {
  id: number
}
export const useCreateLibrary = createUseFetchAction<CreateLibraryResponse, CreateLibraryParam>({
  key: 'create-library',
  path: '/api/v3/app/library',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type TagNumericalSentimentString = string // tag name:(1 | 0 | -1)
export const parseTagNumericalSentiment = (tagSentiment: TagSentimentString) => {
  const separatorIndex = tagSentiment.lastIndexOf(TAG_SENTIMENT_SEPARATOR)
  const tag = tagSentiment.slice(0, separatorIndex)
  const numericalSentiment = tagSentiment.slice(separatorIndex + 1)

  let sentiment: Sentiment
  switch (Number(numericalSentiment)) {
    case 2:
      sentiment = 'POSITIVE'
      break
    case 0:
      sentiment = 'NEGATIVE'
      break
    case 1:
    default:
      sentiment = 'NONE'
  }
  return {
    tag,
    sentiment
  }
}

type Range = {
  max: number
  min: number
}

export type AvailableFilters = {
  agentAverageTalkStreak: Range
  agentLongestStreak: Range
  agentOvertalkIncidents: Range
  agentOvertalkRatio: Range
  agentTalkRatio: Range
  agents: string[]
  averageTalkStreak: Range
  callDispositions: string[]
  callerNames: string[]
  callerTitle: string[]
  company: string[]
  interactions: Range
  keywords: {
    [group: string]: string[]
  }
  leadStatuses: string[]
  libraries: string[]
  longestTalkStreak: Range
  opportunityStages: string[]
  overtalkIncidents: Range
  overtalkRatio: Range
  silenceRatio: Range
  tags: TagNumericalSentimentString[]
  teams: string[]
}

type AvailableFiltersParams = {
  query: { accountId: number }
}

export const useAvailableFilters = createUseFetch<AvailableFilters, AvailableFiltersParams>({
  key: 'fetch-available-filters',
  path: '/api/v3/app/smartRecordings/filters'
})

type UpdateUserSettingsParam = {
  meta: {
    id: number
  }
  json: Partial<
    UserSettings & {
      conversationAnalyticsManager: boolean
      caiRecordingVisibility: string
      transcriptionLanguage: TranscriptionLanguage
    }
  >
}

export const useUpdateUserSettings = createUseFetchAction<void, UpdateUserSettingsParam>({
  key: 'update-user-settings',
  path: params => `/api/v3/cai/users/${params.meta.id}/settings?x-http-method-override=PATCH`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})
