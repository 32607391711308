import React, { useState } from 'react'
import { FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import { InputComponentProps } from '../../../types/form-inputs'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Checkbox from './Checkbox'

export type CheckboxValues = {
  [name: string]: boolean
}

const CheckboxGroup = ({ inputData, isError, errorMessage, onChange }: InputComponentProps) => {
  const startValues: CheckboxValues = {}
  ;(inputData.options as string[]).forEach((name: string) => {
    startValues[name] = !!(inputData.defaultValue && inputData.defaultValue.split(',').indexOf(name) > -1)
  })
  const [state, setState] = useState(startValues)

  const handleCheck = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked })
  }

  useDeepCompareEffect(() => {
    if (startValues !== state) {
      onChange(inputData.name, state)
    }
  }, [state, onChange, inputData.name, startValues])

  return (
    <FormControl component="fieldset">
      {inputData.label && (
        <FormLabel component="legend">
          {inputData.label}
          {inputData.required ? '*' : ''}
        </FormLabel>
      )}
      <FormGroup row className={inputData.className}>
        {inputData.options &&
          inputData.options.length &&
          (inputData.options as Array<string>).map((optionItem: string) => (
            <Checkbox
              key={optionItem}
              label={optionItem}
              checked={state[optionItem]}
              onChange={handleCheck(optionItem)}
            />
          ))}
      </FormGroup>
      <FormHelperText>{isError ? errorMessage : inputData.helperText}</FormHelperText>
    </FormControl>
  )
}

export default CheckboxGroup
