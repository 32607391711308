import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import enhancer from './enhancer'

import reducer from './reducer'
import AsyncStorage from './storage'
import { ClientStore, CacheType } from '../constants'

declare global {
  interface Window {
    store: any
    persistor: any
  }
}

const persistedReducer = persistReducer(
  {
    storage: AsyncStorage,
    key: 'client-store',
    whitelist: [CacheType.Persist, 'queues']
  },
  reducer
)

// @ts-ignore redux Store -> ClientStore disagreement on dispatch
const store: ClientStore = createStore(persistedReducer, enhancer)
// @ts-ignore redux Store -> ClientStore disagreement on dispatch
const persistor = persistStore(store)
if (window) {
  window.store = store
  window.persistor = persistor
}

// persistor.purge()

export default store
export { persistor }
