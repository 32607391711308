import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function TableIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <mask id="path-2-inside-1_8019_43835" fill="#fff">
        <rect x={4.5} y={6.75} width={15} height={10.5} rx={0.375} />
      </mask>
      <rect
        x={4.5}
        y={6.75}
        width={15}
        height={10.5}
        rx={0.375}
        stroke={color}
        strokeWidth={1.5}
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_8019_43835)"
      />
      <path d="M5.25 9H18.75" stroke={color} strokeWidth={0.75} />
      <path d="M7.5 9V16.5" stroke={color} strokeWidth={0.75} />
    </svg>
  )
}

export default TableIcon
