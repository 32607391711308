BaseModel = require('base/model')
isEmail = require('@ringdna/common/src/utils/validationUtils').default.isEmail

# This is a flattened view of RDAccount and AccountSettings.
class AccountModel extends BaseModel
  urlRoot: 'accounts'

  parse: (response) =>
    settings = response.settings
    @settingsId = settings.id

    @apiAttrs =
      account: ['id', 'defaultPhoneNumber', 'name', 'alias', 'packageName', 'packageVersion', 'primaryEmail', 'sfAccountId', 'sfOrgId', 'type']
      settings: (key for key of settings)

    delete response.settings
    _.extend(settings, response)

  toJSON: =>
    fields = {}
    fields["account.#{key}"] = @get(key) for key in @apiAttrs.account
    fields["settings.#{key}"] = @get(key) for key in @apiAttrs.settings
    fields['settings.id'] = @settingsId
    fields

  validate: (attrs) ->
    if attrs.primaryEmail and !isEmail(attrs.primaryEmail)
      return 'Please enter a valid primary email.'
    false

module.exports = AccountModel
