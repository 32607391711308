BaseView     = require('base/view')
ProfileModel = require('./model')
ProfileFieldsView = require('./fields/view')
ProfileUsersView = require('./users/view')

class ProfileShowView extends BaseView

  template: require('./template')

  regions:
    tabRegion: '.tab'

  initialize: (options) =>
    { @id, @tab = 'fields', @qs } = options

  onAttach: =>
    @tabRegion = @getRegion('tabRegion')
    App.navigate("#salesforce-profiles/#{@id}/#{@tab}")

    switch @tab
      when 'fields'
        model = new ProfileModel(id: @id)
        model.fetch().done(=>
          @tabRegion.show(new ProfileFieldsView(model: model))
        )
      when 'users'
        model = new ProfileModel(id: @id)
        model.fetch().done(=>
          @tabRegion.show(new ProfileUsersView(model: model))
        )
      else
        throw new Error("Unknown tab: #{name}")

module.exports = ProfileShowView
