BackgridCell    = require('helpers/backgrid-cell')
BackgridRow     = require('helpers/backgrid-row')
BaseView = require('base/view')
BaseCollection = require('base/collection')
BaseGrid = require('base/grid')
CallDispositionTeams = require('./model')
Cells = require('./cells')
SettingsCollection = require('./settings-collection')
PagerView       = require('widgets/pager/view')

class Step2 extends BaseView
  template: require('./step-2-template.hbs')

  className: 'call-disposition-step-2-editor'

  regions:
    grid   : 'div.grid'
    pager  : 'span.pager'
    filter : 'span.filter'

  bindings:
    '.modal-title': 'text:modalTitle'

  computeds:
    modalTitle:
      deps: ['disposition']
      get: (disposition) -> "Assign \"#{disposition}\" Disposition to Teams"

  initialize: (options) =>
    { @accountId } = options
    @callDispositionTeams = new CallDispositionTeams.Model(
      accountId: @accountId
      disposition: @model
    )
    @settingsCollection = new SettingsCollection(
      queryParams:
        accountId : @getAccountORNull()
        dispositionId : @model.get('id')
    )

  onRender: =>
    @showChildView('pager', new PagerView(collection: @settingsCollection, disableDownload: true))
    @showChildView('filter', @filter())
    @settingsCollection.fetch().done( =>
      @showChildView('grid', @buildGrid(@settingsCollection))
    )

  filter: =>
    @filterTable = new Backgrid.Extension.ClientSideFilter(
      collection: @settingsCollection
      fields: ['name']
      events:
        'click .search': 'search'
      template: =>
        "<input type='search' placeholder='Search for Team'>" +
        "<button onclick='return false' class='btn btn-success search'>Search</button>"
    )
    @settingsCollection.shadowCollection = @filterTable.shadowCollection
    @filterTable

  buildGrid: (collection) ->
    cols = [
      { label: 'Team Name', name: 'name',     cell: 'string' }
      { label: 'Inbound',   name: 'inbound',  cell: Cells.CheckboxCell, editable: true, headerCell: Cells.HeaderCell }
      { label: 'Outbound',  name: 'outbound', cell: Cells.CheckboxCell, editable: true, headerCell: Cells.HeaderCell }
    ]
    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No teams found.',
      row        : BackgridRow.deleted()
    )

module.exports = Step2
