BaseGrid                  = require('base/grid')
BaseView                  = require('base/view')
BaseModel                 = require('base/model')
BaseCollection            = require('base/collection')
BackgridCell              = require('helpers/backgrid-cell')
BackgridRow               = require('helpers/backgrid-row')
PagedCollection           = require('base/paged-collection')
PagerView                 = require('widgets/pager/view')
ProfilePickerView         = require('widgets/salesforce-profile-picker/view')
SfdcUserModel             = require('features/users/salesforce/salesforce-model')

class ListSalesforceUsersView extends BaseView
  template: require('./template')

  regions:
    grid    : 'div.grid'
    pager   : 'span.pager'
    picker  : 'div.picker'
    profile : 'div.search-field'

  ui:
    search       : 'button.search'
    searchSfdc   : 'input.search-sfdc'
    city         : 'input.city-sfdc'
    country      : 'input.country-sfdc'
    profile      : 'div.search-field'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  initialize: (options) =>
    { @qs, @accountId, @salesforceUsers } = options
    @qs.setFragment('user/salesforce-users')
    @viewModel = new BaseModel(
      selectedCount: 0
      isNotDeletedCount: 0
    )

  bindings:
    '#picker': 'toggle:profileSearch'

  computeds:
    profileSearch:
      get: -> @user().get('hasAccessProfile')

  onRender: =>
    @picker = @getRegion('picker')
    @picker.show(new ProfilePickerView())
    @qs.bind(
      searchSfdc: @ui.searchSfdc
      city: @ui.city
      country: @ui.country
      profileId: @picker.currentView
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    collection = new PagedCollection(
      page: @qs.get('page')
      queryParams:
        searchSfdc  : @ui.searchSfdc.val().trim()
        city        : @ui.city.val().trim()
        country     : @ui.country.val().trim()
        profileId   : @picker.currentView.val()
      url: 'users/salesforce'
    )
    @listenTo(collection, 'selectedAllCells', (checked) =>
      @showSelected(collection, checked)
    )
    @listenTo(@salesforceUsers, 'selectedCell', (checked) =>
      @triggerMethod('selected', checked)
    )
    @listenTo(@salesforceUsers, 'removeFromSelectedView', (salesforceId) =>
      model = collection.findWhere(salesforceId: salesforceId)
      model.trigger('removeFromSelectedView') if model
    )
    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))
    collection
      .fetch(reset: true)
      .done( =>
        @updateCounts(collection)
        @listenTo(collection, 'pager:state:changed', (state) =>
          _.defer( => @updateCounts(collection))
        )
      )

  updateCounts: (collection) =>
    selectedCount = 0
    isNotDeletedCount = 0
    for model in collection.models
      selectedCount++ if @salesforceUsers.findWhere(salesforceId: model.get('salesforceId'))
      isNotDeletedCount++ unless model.get('isDeleted')
    @viewModel.set(
      selectedCount: selectedCount
      isNotDeletedCount: isNotDeletedCount
    )
    @viewModel.trigger('countsUpdated')

  buildGrid: (collection) =>
    mainClass = @
    headerSelectCell = class extends Backgrid.HeaderCell
      events:
        'click input': 'selectedAll'
        'change select': 'selectChanged'

      selectedAll: (e) =>
        collection.trigger('selectedAllCells', e.target.checked)
        @$('select').val(if e.target.checked then 'all' else 'none')

      selectChanged: (e) =>
        value = e.currentTarget.value
        switch value
          when 'none'
            collection.trigger('selectedAllCells', false)
            @$('input').prop('checked', false)
          when 'all'
            collection.trigger('selectedAllCells', true)
            @$('input').prop('checked', true)

      render: ->
        $(@el).html('''
          <input type='checkbox'/>
          <select class='backgrid-select-options'>
            <option value="all">All</option>
            <option value="none" selected>None</option>
          </select>
        ''')
        @listenTo(mainClass.viewModel, 'countsUpdated', =>
          selectedCount = mainClass.viewModel.get('selectedCount')
          isNotDeletedCount = mainClass.viewModel.get('isNotDeletedCount')
          if selectedCount is isNotDeletedCount and selectedCount isnt 0
            @$('input').prop('checked', true)
            @$('select').val('all')
          else
            @$('input').prop('checked', false)
            @$('select').val('none')
        )
        @

    selectCell = @selectedUserCell()

    userCell = class extends Backgrid.Cell
      render: ->
        if @model.get('userId')
          html = """<a href='#users/#{@model.get('userId')}'>#{_.escape(@model.get('name'))}</a>"""
        else
          html = """#{_.escape(@model.get('name'))}"""
        $(@el).html(html) if @model.get('name')
        @

    columns = [
      {label: 'Select',             name: 'select',            cell: selectCell, headerCell: headerSelectCell}
      {label: 'Name',               name: 'name',              cell: userCell}
      {label: 'Email',              name: 'email',             cell: 'string'}
      {label: 'Title',              name: 'title',             cell: 'string'}
      {label: 'City',               name: 'city',              cell: 'string'}
      {label: 'Country',            name: 'country',           cell: 'string'}
      {label: 'Salesforce Profile', name: 'profile',           cell: 'string', renderable: @user().get('hasAccessProfile')}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : columns,
      emptyText  : 'No users found.'
      row        : BackgridRow.deleted()
    )

  selectedUserCell: =>
    mainClass = @
    class extends Backgrid.BooleanCell
      events:
        'click .selectedCell': 'selected'

      selected: (e) =>
        model = new SfdcUserModel(
          salesforceId: @model.get('salesforceId')
          name: @model.get('name')
          email: @model.get('email')
          title: @model.get('title')
          city: @model.get('city')
          country: @model.get('country')
          profile: @model.get('profile')
        )
        user = mainClass.salesforceUsers.findWhere(salesforceId: @model.get('salesforceId'))
        if user
          mainClass.salesforceUsers.remove(user)
          mainClass.viewModel.set(selectedCount: mainClass.viewModel.get('selectedCount') - 1)
          mainClass.viewModel.trigger('countsUpdated')
        else
          mainClass.salesforceUsers.add(model)
          mainClass.viewModel.set(selectedCount: mainClass.viewModel.get('selectedCount') + 1)
          mainClass.viewModel.trigger('countsUpdated')
        mainClass.salesforceUsers.trigger('selectedCell', mainClass.salesforceUsers?.length > 0)
        mainClass.applyBindings()

      render: =>
        @listenTo(@model, 'removeFromSelectedView', =>
          mainClass.viewModel.set(selectedCount: mainClass.viewModel.get('selectedCount') - 1)
          @$('.selectedCell').prop('checked', false)
          mainClass.viewModel.trigger('countsUpdated')
        )
        checked = if mainClass.salesforceUsers.findWhere(salesforceId: @model.get('salesforceId')) then 'checked' else ''
        html = """<input class='selectedCell' tabindex=\'-1\' type=\'checkbox\' data-exist-user=\"#{@model.get('isDeleted')}\" #{checked}>"""
        $(@el).html(html) unless @model.get('isDeleted')
        @

  showSelected: (collection, checked) =>
    @$('*[data-exist-user=false]').prop( 'checked', checked )
    @removeUnselectedUsers(collection, checked)
    @saveSelectUsers(collection, checked) if checked
    if checked
      @viewModel.set(selectedCount: @viewModel.get('isNotDeletedCount'))
    else
      @viewModel.set(selectedCount: 0)
    @triggerMethod('selected', @salesforceUsers.length > 0)

  saveSelectUsers: (collection, checked) =>
    collection.each((model) =>
      user = new SfdcUserModel(
        salesforceId: model.get('salesforceId')
        name: model.get('name')
        email: model.get('email')
        title: model.get('title')
        city: model.get('city')
        country: model.get('country')
        profile: model.get('profile')
      )
      @salesforceUsers.add(user) if not model.get('isDeleted')
    )

  removeUnselectedUsers: (collection, checked) =>
    unless checked
      collection.each((model) =>
        existingModel = @salesforceUsers.findWhere(salesforceId: model.get('salesforceId'))
        @salesforceUsers.remove(existingModel) if existingModel
      )

module.exports = ListSalesforceUsersView
