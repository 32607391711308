import React, { useCallback, useMemo, useState } from 'react'

import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import { Methodology, Topic } from './Models'
import { useResetMethodology, useSalesMethodologies } from '../../store/api/salesmethodology-api'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import styled from 'styled-components'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'
import Drawer from './Drawer'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { Column } from 'react-table'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import ExpandedCell from './ExpandedCell'
import config, { ProductDomains } from '../../config'
import ExplainationMark from '@ringdna/common/src/assets/icons/svg/ExplainationMark'
import { Colors } from '@ringdna/common/src/constants/colors'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'

type Props = {
  accountId: number
}

export default function SalesMethodology({ accountId }: Props) {
  const [salesMethodologies = [], , loading, refetch] = useSalesMethodologies({ meta: { accountId: accountId } })
  const resetMethodology = useResetMethodology()
  const [selectedMethodology, setSelectedMethodology] = useState<Methodology>()
  const [resetModalOpen, setResetModalOpen] = useState<boolean>(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const handleConfirm = () => {
    setResetModalOpen(false)
    onReset()
  }

  const onSaveTopics = (reload: boolean) => {
    setShowDrawer(false)
    reload && refetch()
  }

  const onReset = async () => {
    const params = {
      body: {
        accountId: accountId,
        methodologyId: selectedMethodology?.id
      }
    }
    try {
      await resetMethodology(params)
      refetch()
      toastr.info('Sales Methodology saved.')
    } catch (error) {
      toastr.error(error.message)
    }
  }

  const handleActionMenu = useCallback((action: string, methodology: Methodology) => {
    setSelectedMethodology(methodology)
    action === 'Edit' && setShowDrawer(true)
    action === 'Reset' && setResetModalOpen(true)
  }, [])

  const showRole = (roleId: number) => {
    window.location.assign(`${config[ProductDomains.WEB_ADMIN]}/#roles/${roleId}`)
  }

  const columns = useMemo<Column<Methodology>[]>(
    () => [
      {
        Header: 'Status',
        accessor: methodology => methodology,
        width: 30,
        disableSortBy: true,
        Cell: function createdCell(data: { value: Methodology }) {
          const configured = data.value.topics.filter(topic => topic.configured)
          return <RdnaCheckbox value={configured.length > 0} shape="circle" className={'status-methodology'} />
        }
      },
      { Header: 'Methodology', accessor: 'name' },
      {
        Header: 'Description',
        accessor: methodology => methodology,
        Cell: function createdCell(data: { value: Methodology }) {
          return (
            <ExpandedCell value={data.value.description} opened={expandedRows.includes(data.value.id)}></ExpandedCell>
          )
        }
      },
      {
        Header: 'Roles',
        accessor: methodology => methodology,
        disableSortBy: true,
        Cell: function createdCell(data: { value: Methodology }) {
          return (
            <>
              {!data.value.roles || data.value.roles.length === 0 ? (
                <RdnaText>{'-'}</RdnaText>
              ) : (
                <ExpandedCell
                  value={data.value.roles.map((role, index) => (
                    <div key={role.id} style={{ display: 'inline' }}>
                      <RdnaText color={'link'} onClick={() => showRole(role.id)}>
                        {role.name}
                      </RdnaText>
                      {index !== data.value.roles.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  ))}
                  opened={expandedRows.includes(data.value.id)}
                ></ExpandedCell>
              )}
            </>
          )
        }
      },
      {
        Header: 'Topics Used',
        accessor: 'topics',
        width: 60,
        disableSortBy: true,
        Cell: function fn({ value }: { value: Topic[] }) {
          const configured = value.filter(topic => topic.configured)
          return <RdnaText>{`${configured.length} of ${value.length}`}</RdnaText>
        }
      },
      {
        Header: ' ',
        accessor: methodology => methodology,
        width: 30,
        disableSortBy: true,
        Cell: function createdCell(data: { value: Methodology }) {
          const configured = data.value.topics.filter(topic => topic.configured)
          return (
            <RdnaMenu
              options={[{ text: 'Edit' }, { text: 'Reset', disabled: configured.length === 0 }]}
              onSelect={item => handleActionMenu(item, data.value)}
            />
          )
        }
      }
    ],
    [expandedRows, handleActionMenu]
  )

  const onExpandRow = (row: any) => {
    const expanded = expandedRows
    const index = expanded.indexOf(row.original.id)
    !row.isExpanded && index > -1 && expanded.splice(index, 1)
    row.isExpanded && index === -1 && expanded.push(row.original.id)
    expandedRows.length != expanded.length && setExpandedRows(expanded)
    return <></>
  }

  return (
    <>
      <SectionHeader
        headingText="Methodologies"
        descriptionText="Help tailor your team's experience in the Revenue platform by configuring your org's Methodologies."
      />
      <Container>
        <RdnaSmartTable
          columns={columns}
          data={salesMethodologies || []}
          isFetching={!!loading}
          globalSearchLabel={'Search'}
          paginationSize={10}
          rowHeight={'200'}
          renderRowSubComponent={onExpandRow}
        />
      </Container>
      {selectedMethodology && (
        <MethodologyDrawer
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          heading={selectedMethodology.name}
          subHeading={selectedMethodology.description}
        >
          <Drawer accountId={accountId} methodology={selectedMethodology} onSave={onSaveTopics} />
        </MethodologyDrawer>
      )}
      <RdnaModal
        headingIcon={
          <IconHelper icon={ExplainationMark} variant="outlined" color={Colors.BLACK} iconSize={24} size={24} />
        }
        heading={'Reset Methodology'}
        open={resetModalOpen}
        onClose={() => setResetModalOpen(false)}
        onConfirm={handleConfirm}
        confirmButtonText="Reset Methodology"
      >
        <RdnaText>
          {"You're about to reset this methodology and remove all configurations. Do you wish to continue?"}
        </RdnaText>
      </RdnaModal>
    </>
  )
}

const MethodologyDrawer = styled(RdnaDrawer)`
  > div {
    width: 50%;
  }
`

const Container = styled.div`
  .status-methodology {
    margin: 0;
    padding-left: ${({ theme }) => 2 * theme.spacing}px;
  }
`
