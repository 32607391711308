/**
 * @deprecated for `RdnaFormElements/RdnaToggleSwitch`.
 */
import React, { useCallback, useState } from 'react'
import Switch, { SwitchProps } from '@mui/material/Switch'

type BaseProps = {
  color?: SwitchProps['color']
  testId?: string
} & SwitchProps

type UncontrolledProps = {
  isChecked?: boolean
  onToggle: (isChecked: boolean) => void
  // disable controlled props
  controlledState?: undefined
} & BaseProps

type ControlledProps = {
  controlledState?: { isChecked: boolean; setIsChecked: (isChecked: boolean) => void }
  // disable uncontrolled props
  isChecked?: undefined
  onToggle?: undefined
} & BaseProps

const RdnaToggleSwitch = React.memo(
  ({
    color = 'primary',
    isChecked: initialCheckedState = false,
    onToggle,
    controlledState,
    testId,
    name,
    ...rest
  }: UncontrolledProps | ControlledProps) => {
    const [checked, setChecked] = useState(initialCheckedState)

    const toggleChecked = useCallback(
      (isChecked: boolean) => {
        if (controlledState) {
          controlledState.setIsChecked(isChecked)
        } else {
          setChecked(isChecked)
          onToggle && onToggle(isChecked)
        }
      },
      [controlledState, onToggle]
    )

    return (
      <Switch
        color={color}
        data-testid={testId || `${name}-toggle-switch`}
        checked={controlledState ? controlledState.isChecked : checked}
        onClick={e => {
          e.stopPropagation()
        }}
        onChange={e => {
          toggleChecked(e.target.checked)
        }}
        name={name}
        {...rest}
      />
    )
  }
)

RdnaToggleSwitch.displayName = 'RdnaToggleSwitch'

export default RdnaToggleSwitch
