import React, { useState } from 'react'

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { Action, useUpdateSalesforceInspector } from '../../../../../store/api/salesforceInspector-api'

type Props = {
  accountId: number
}

export default SalesforceConnectTestButton
function SalesforceConnectTestButton({ accountId }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const updateSalesforceInspector = useUpdateSalesforceInspector()

  const onTest = async () => {
    try {
      setIsLoading(true)
      await updateSalesforceInspector({ body: { action: Action.Connect, accountId: accountId } })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return <RdnaButton variant="contained" text={'Test'} color="action" onClick={onTest} disabled={isLoading} />
}
