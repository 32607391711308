BaseModel = require('base/model')

class AddressModel extends BaseModel
  defaults:
    addressRequirements: ''
    countryCode: ''
    addressCountry: ''
    countryName: ''
    friendlyName: ''
    businessName: ''
    city: ''
    postalCode: ''
    region: ''
    street: ''
    created: false

module.exports = AddressModel