AbandonedCallsView    = require('../abandoned-calls/view')
BaseView              = require('base/view')
CallFlowPickerView    = require('widgets/callflow-picker/view')
CallFlowStepView      = require('../call-flow-steps/view')
FlowModel             = require('./model')
SmartNumbersView      = require('../smart-numbers/view')
UserPickerView        = require('widgets/user-picker/view')
Handlebars            = require('handlebars/runtime').default

class MainView extends BaseView

  template: require('./main-template')
  className: 'call-flow-overview'

  bindings:
    'input.name'        : "value:name, disabled: readOnly, events:['keyup']"
    'input.description' : "value:description, disabled: readOnly, events:['keyup']"
    'select.status'     : 'value:statusSelected, disabled: readOnly'
    'button.save'       : 'toggle:not(readOnly)'
    'button.copy'       : 'toggle:not(any(isNew,readOnly))'
    'button.delete'     : 'toggle:not(any(isNew,readOnly)),disabled:not(canRemove)'
    '.validation-error' : 'toggle:not(valid)'
    '.finalStepDetails' : 'checked:finalStepDetails'
    '.updatedOn'        : 'text:formattedUpdatedOn'
    '.updatedBy'        : 'html:formattedUpdatedBy'
    '.createdOn'        : 'text:formattedCreatedOn'
    '.createdBy'        : 'html:formattedCreatedBy'

  computeds:
    statusSelected:
      deps: ['paused']
      get: (paused) ->
        if paused then 'Paused' else 'Active'
    isNew:
      get: ->
        @model.isNew()
    canRemove:
      deps: ['paused', 'valid']
      get: (paused, valid) ->
        paused or not valid
    formattedUpdatedOn:
      deps: ['updatedDate']
      get: (updatedDate) -> moment(updatedDate).format('MM/DD/YYYY hh:mm a')
    formattedUpdatedBy:
      deps: ['updatedBy']
      get: (updatedBy) -> @getFormattedBy(updatedBy)
    formattedCreatedOn:
      deps: ['createdDate']
      get: (createdDate) -> moment(createdDate).format('MM/DD/YYYY hh:mm a')
    formattedCreatedBy:
      deps: ['createdBy']
      get: (createdBy) -> @getFormattedBy(createdBy)

  regions:
    callFlowSteps     : 'div.call-flow-steps'
    smartNumbers      : 'div.smart-numbers'
    connectUserPicker : 'div.connect-user'
    abandonedCalls    : 'div.abandoned-calls'
    callFlowPicker    : 'div.call-flow-picker'

  ui:
    saveButton    : 'button.save'
    copyButton    : 'button.copy'
    deleteButton  : 'button.delete'

  events:
    'click @ui.saveButton'    : 'saveClicked'
    'click @ui.copyButton'    : 'copyCallFlow'
    'click @ui.deleteButton'  : 'deleteCallFlow'
    'change .status'          : 'changeStatus'

  onAttach: =>
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  initialize: (options) =>
    @addNew = options.addNew
    user = @user()
    if @addNew
      account =
        id: user.get('accountId')
        name: user.get('accountName')
      if user.accountPickerRenderable() and user.getSelectedSupportAccountId(true)?.toString() isnt user.get('accountId')?.toString()
        account =
          id: user.getSelectedSupportAccountId(true)
          name: user.getSelectedSupportAccountName()
      @model = new FlowModel(
        createdBy:
          id: user.get('userId')
          displayName: user.get('userName')
        account: account
      )
    else
      @model = new FlowModel(id: options.flowId)
      @model.fetch().done(=>
        @render()
        @model.set('steps', @model.steps())
        @initializeChildViews()
      )
    @model.set(skillsEnabled: user.get('accountUserSkills'))
    @model.set(readOnly: not user.can('call-flows:edit:me'))

  onRender: =>
    @listenTo(@model, 'invalid', (model, error) -> toastr.error(error))
    if @model.isNew()
      @initializeChildViews()
    if @model.get('account')
      @connectUserPicker = @getRegion('connectUserPicker')
      @userPickerView = new UserPickerView(accountId: @model.get('account').id, forceLoad: true)
      @connectUserPicker.show(@userPickerView)
      @userPickerView.addDefaultValue()
      @userPickerView.populate(new Backbone.Model(@model.get('connectUser'))) if @model.get('connectUser')
      @listenTo(@userPickerView, 'selected deselected', (value) => @model.set('connectUser', {id:if value then parseInt(value) else null}))
      @callFlowPicker = @getRegion('callFlowPicker')
      App.api.get(
        path: 'postCallFlows/picklist'
        data: accountId: @model.get('account').id
        success: (response) =>
          response = _.filter(response, (item) => item.value isnt @model.get('id')?.toString())
          @callFlowPickerView = new CallFlowPickerView(
            accountId: @model.get('account').id
            allowEmptyOption: true
            selectedFlowId: @model.get('postCallFlow')?.id
            data: response
          )
          @listenTo(@callFlowPickerView, 'change', (value) => @model.set(postCallFlow: if value then {id:value} else null))
          @callFlowPicker.show(@callFlowPickerView)
      )

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  changeStatus: (e) =>
    @model.set('paused', $(e.target).val() is 'Paused')

  copyCallFlow: =>
    @model.copyCallFlow()

  deleteCallFlow: =>
    bootbox.dialog(
      title: 'Delete Call Flow'
      message:  "Are you sure you want to delete the call flow: #{@model.get('name')}. This action cannot be undone",
      buttons:
        cancel:
          label: 'Cancel'
          className: 'btn btn-default delete-cancel'
          callback: -> true
        ok:
          label: 'Delete'
          className: 'btn-danger'
          callback: =>
            @model.delete(ladda: @ui.deleteButton[0]).done(-> App.router.navigate('#call-routing/call-flows', {trigger: true}))
    )

  initializeChildViews: ->
    return unless @getRegion('callFlowSteps')
    @callFlowStepView = new CallFlowStepView(model: @model.get('steps'), callflow: @model)
    @showChildView('callFlowSteps',  @callFlowStepView)
    @showChildView('abandonedCalls', new AbandonedCallsView(model: @model))
    unless @model.isNew()
      @showChildView('smartNumbers',   new SmartNumbersView(model: @model))
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )
    @listenTo(@callFlowStepView, 'updateTree', => @model.persist())

  saveClicked: =>
    @model.get('changedStep').saveStep() if @model.get('changedStep')
    @model.saveCallFlow(@ui.saveButton[0], @addNew)
    @userPickerView.addDefaultValue() if @userPickerView
    @applyBindings()

  getFormattedBy: (el) =>
    if el and el.id
      id = Handlebars.Utils.escapeExpression(el.id)
      name = Handlebars.Utils.escapeExpression(el.displayName)
      new Handlebars.SafeString("<a href='#users/#{id}'>#{name}</a>").string
    else
      ''

module.exports = MainView
