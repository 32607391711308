BaseModel = require('base/model')

class CallScriptModel extends BaseModel
  urlRoot: 'callScripts'

  defaults:
    'name': ''
    'data': ''
    'description': ''

  validate: (attrs) ->
    if not attrs.name.trim()
      return 'Please enter a call script name.'
    return

module.exports = CallScriptModel