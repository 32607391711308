import config, { Env } from './'

export const isProd = config.rdEnv === Env.Prod
export const isQa = config.rdEnv === Env.Qa

const parts = config.appDomain.split('.')
export const subDomain = parts.length > 1 ? parts[0] : ''

// compatability layer for CookieHelper config
export const cookieConfig = {
  domain: config.appDomain,
  rootDomain: config.rootDomain,
  deployTarget: config.deployTarget,
  isQa,
  isProd
}
