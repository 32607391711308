export enum ReportFilterType {
  AGENT = 'agentName',
  AGENT_MULTIPLE = 'agentNames',
  CALL_CATEGORY = 'callCategory',
  CALL_DISPOSITION = 'callDisposition',
  CALL_DISPOSITION_MULTIPLE = 'callDispositions',
  CALL_DURATION = 'callDuration',
  CALL_TYPE = 'callType',
  CALL_QUEUE_NAME = 'callQueueName',
  CALLER = 'caller',
  CATEGORY = 'category',
  COACHING_DIRECTION = 'coachingDirection',
  COACHING_TYPE = 'coachingType',
  CONVERSATION_TYPE = 'conversationType',
  DIRECTION = 'direction',
  DISPOSITION_OUTCOME = 'dispositionOutcome',
  END_DATE = 'endDate',
  FROM_NUMBER = 'fromNumber',
  FISCAL_PERIOD = 'fiscalPeriod',
  KEYWORD_GROUP = 'keywordGroup',
  LOCATION = 'locationName',
  MOMENTS_CONTENT_TYPE = 'contentType',
  MOMENTS_CATEGORY = 'momentsCategory',
  NOTIFICATION_NAMES = 'notificationNames',
  OPPORTUNITY_AMOUNT = 'opportunityAmount',
  OPPORTUNITY_CLOSE_DATE = 'closeDate',
  OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE = 'opportunityForecastCategories',
  OPPORTUNITY_NAME = 'opportunityName',
  OPPORTUNITY_OWNER_MULTIPLE = 'opportunityOwners',
  OPPORTUNITY_TYPE = 'opportunityType',
  OPPORTUNITY_TYPE_MULTIPLE = 'opportunityTypes',
  OPPORTUNITY_STAGE_MULTIPLE = 'opportunityStages',
  OPPORTUNITY_WIN_PROBABILITY = 'opportunityWinProbability',
  RELATIONSHIP = 'relationship',
  SPEAKER_TYPE = 'speakerType',
  START_DATE = 'startDate',
  START_DATE_QUARTER = 'startDateQuarter',
  SUPERVISOR_NAME = 'supervisorName',
  TEAM = 'teamName',
  TEAM_MULTIPLE = 'teamNames',
  TO_NUMBER = 'toNumber',
  TRANSFER_NUMBER = 'transferToNumber',
  TIMEZONE = 'timezone',
  TRIGGERING_CRITERIA = 'phraseMatching',
  USER_STATUS = 'userStatus',
  VOICEMAIL = 'voicemail'
}

export type PersistedFilters = {
  [key in ReportFilterType]?:
    | {
        label: string | string[]
        value: string | string[]
      }
    | string
    | string[]
}

export type FilterFormValues = {
  [key in ReportFilterType]?: string
}

export type MetabaseReportParams = {
  isDashboard: boolean
  query?: {
    [key: string]: string
  }
}

type CustomReport = (props: MetabaseReportParams | undefined) => JSX.Element | null

export type CustomReportType = React.LazyExoticComponent<CustomReport> | CustomReport | undefined
