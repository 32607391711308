import React, { useState } from 'react'
import ExportModal from './ExportModal'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import ShareIcon from '@ringdna/common/src/assets/icons/svg/SharedWithMe'

export enum UserStatus {
  ALL = 'all',
  DELETED = 'deleted',
  ACTIVE = 'active'
}

export type Props = {
  accountId: string
  userName?: string
  userEmail?: string
  salesforceId?: string
  userStatus: UserStatus
}

export default function ExportUsersButton(data: Props) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <IconHelper
        data-testid={'export-icon'}
        icon={ShareIcon}
        iconSize={60}
        strokeWidth={2}
        onClick={() => setIsOpen(!isOpen)}
      />
      <ExportModal data={data} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}
