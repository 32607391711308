BaseView = require('base/view')
BaseModel = require('base/model')
BaseCollection = require('base/collection')
PagedCollection = require('base/paged-collection')
ReactComponent = require('src/ReactComponent').default
MetabaseReports = require('src/components/reports/MetabaseReports').default
ProductConstant = require('@ringdna/common/src/constants/products')

class MetabaseView extends BaseView
  template: _.template('<div class="cai-reports-filters"></div>')
  className: 'metabase'

  regions:
    react: 'div.cai-reports-filters'

  initialize: (opts) ->
    @viewModel = opts.viewModel

  onRender: =>
    @showChildView('react', new ReactComponent({ component: MetabaseReports, theme: ProductConstant.Products.ADMIN, props: { headline: @viewModel.get('title'), subHeadline: @viewModel.get('subtitle'), name: @viewModel.get('name')}}))

module.exports = MetabaseView
