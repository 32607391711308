/**
 * @deprecated for `RdnaFormElements`.
 */
import React, { useState } from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'
import { Box, Grid } from '@mui/material'
import RdnaText from '../RdnaText'
import { ThemeProvider } from '@mui/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { AutoCompleteInputData, InputChangeEvent, InputField } from '../../types/form-inputs'
import { InputTypes } from '../../constants/input-types'
import theme from '../../constants/material-ui-theme'
import { validateEmail, validatePhoneNumber } from './validations'
import RdnaDropdown from './RdnaDropdown'
import RdnaTextInput from './RdnaTextInput'
import RdnaDatePicker from './RdnaDatePicker'
import AutoCompleteInput from './AutoCompleteInput'
import HiddenInput from './HiddenInput'
import { getObjectDiff } from '../../utils/objectUtils'
import ButtonsContainer from './ButtonsContainer'
import SelectedChips from './SelectedChips'
import { isBefore, isSameDay } from 'date-fns'
import CheckboxGroup from './RdnaCheckbox/CheckboxGroup'
import RdnaSlider from './RdnaSlider'
import RdnaRadio from './RdnaRadio'
import { OnChangeEffect } from './FormikEffects'

export type Alignment = 'left' | 'right' | 'center'

type Props = {
  inputFields: InputField[]
  onSubmit?: (values: any, actions?: any) => void
  onCancel?: () => void
  onEveryChange?: (values: any, dirty: boolean) => void
  onChange?: (values: any, diff: string[]) => void
  isLoading?: boolean
  headline?: string
  subHeadline?: string
  cancelText?: string
  resetText?: string
  submitText?: string
  disableSubmit?: boolean
  clearOnSubmit?: boolean
  forcedError?: ForcedError
  alignElements?: Alignment
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  inlineButtons?: boolean
  showChips?: boolean
  className?: string
  themeOverride?: Record<string, unknown>
}

export type ForcedError = {
  field: string
  errorMsg: string
  show: boolean
}

export type FormikValues = {
  [name: string]: string | string[] | AutoCompleteInputData
}

const FormStyle = styled.div`
  text-align: left;
`

const blankForcedError: ForcedError = {
  field: '',
  errorMsg: '',
  show: false
}

const RdnaForm = React.memo(
  ({
    headline,
    subHeadline,
    cancelText,
    submitText,
    disableSubmit,
    resetText,
    inputFields,
    onSubmit,
    onChange,
    onEveryChange,
    onCancel,
    themeOverride,
    className,
    forcedError = blankForcedError,
    isLoading = false,
    alignElements = 'left',
    inlineButtons = false,
    flexDirection = 'row',
    showChips = true,
    clearOnSubmit = false
  }: Props) => {
    let showGroupChips = showChips
    const defaultValues: FormikValues = {}
    inputFields.map(input => {
      if (input.showChipsLocally) {
        showGroupChips = false
      }
      if (input.multipleValues) {
        if (input.defaultValue) {
          // make sure that previously implemented code still works
          if (!Array.isArray(input.defaultValue)) {
            defaultValues[input.name] = input.defaultValue.split(',')
          }
        } else if (input.defaultValues) {
          defaultValues[input.name] = input.defaultValues
        } else {
          defaultValues[input.name] = []
        }
      } else {
        defaultValues[input.name] = input.defaultValue || ''
      }
      return input
    })

    const formTheme = themeOverride ? { ...theme, ...themeOverride } : {}
    const [formValues, setFormValues] = useState(defaultValues)

    const updateValues = (values: FormikValues) => {
      setFormValues(values)
      if (onChange) {
        onChange(values, getObjectDiff(values, formValues))
      }
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={formTheme}>
          <FormStyle className={className}>
            {(headline || subHeadline) && (
              <Box pb={2}>
                {headline && (
                  <RdnaText variant="h3" component="h2">
                    {headline}
                  </RdnaText>
                )}
                {subHeadline && <RdnaText variant="body2">{subHeadline}</RdnaText>}
              </Box>
            )}
            <Formik
              initialValues={defaultValues}
              validate={values => {
                const errors: FormikValues = {}

                inputFields.map(input => {
                  if (
                    input.required &&
                    ((input.multipleValues && (values[input.name] as string[] | AutoCompleteInputData[]).length < 1) ||
                      (!input.multipleValues && !values[input.name]) ||
                      (input.type === InputTypes.AUTOCOMPLETE &&
                        !(values[input.name] as AutoCompleteInputData).value &&
                        !input.multipleValues))
                  ) {
                    errors[input.name] = input.validationText || 'This field is required.'
                  }
                  return input
                })

                if (defaultValues.email !== undefined && values.email) {
                  if (!validateEmail(values.email as string)) {
                    errors.email = 'Invalid email address'
                  }
                }

                if (defaultValues.phone !== undefined && values.phone) {
                  if (!validatePhoneNumber(values.phone as string)) {
                    errors.phone = 'Invalid phone number'
                  }
                }

                if (values.startDate && values.endDate) {
                  if (
                    isBefore(new Date(values.endDate as string), new Date(values.startDate as string)) &&
                    !isSameDay(new Date(values.endDate as string), new Date(values.startDate as string))
                  ) {
                    errors.startDate = 'Start date must be before end date'
                    errors.endDate = 'End date must be after start date'
                  }
                }

                if (Object.entries(errors).length === 0 && errors.constructor === Object) {
                  updateValues(values)
                }
                return errors
              }}
              onSubmit={(values, { resetForm }) => {
                if (onSubmit) {
                  onSubmit(values)
                }

                if (clearOnSubmit) {
                  resetForm()
                }
              }}
              onReset={() => {
                updateValues(defaultValues)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleReset,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <>
                  {onEveryChange && <OnChangeEffect onChange={onEveryChange} />}
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{ flexDirection: flexDirection }}>
                      {inputFields.map((input: InputField) =>
                        (() => {
                          let InputComponentName
                          let changeFunction: InputChangeEvent = handleChange
                          const columnGrids = input.gridLayout || { xs: 12 }
                          switch (input.type) {
                            case InputTypes.AUTOCOMPLETE:
                              InputComponentName = AutoCompleteInput
                              changeFunction = setFieldValue
                              break
                            case InputTypes.DROPDOWN:
                              InputComponentName = RdnaDropdown
                              changeFunction = handleChange
                              break
                            case InputTypes.SLIDER:
                              InputComponentName = RdnaSlider
                              changeFunction = setFieldValue
                              break
                            case InputTypes.CHECKBOX:
                              InputComponentName = CheckboxGroup
                              changeFunction = setFieldValue
                              break
                            case InputTypes.RADIO:
                              InputComponentName = RdnaRadio
                              changeFunction = setFieldValue
                              break
                            case InputTypes.DATE:
                              InputComponentName = RdnaDatePicker
                              changeFunction = setFieldValue
                              break
                            case InputTypes.HIDDEN:
                              InputComponentName = HiddenInput
                              break
                            default:
                              InputComponentName = RdnaTextInput
                              changeFunction = handleChange
                          }
                          return (
                            <Grid
                              key={input.name}
                              className={input.type === InputTypes.HIDDEN ? 'hidden' : ''}
                              item
                              {...columnGrids}
                            >
                              <Box mt={-1}>
                                <InputComponentName
                                  /* @ts-ignore */
                                  value={values[input.name]}
                                  isMultiple={input.multipleValues}
                                  isAutoWidth={input.autoWidth}
                                  errorMessage={errors[input.name] || forcedError.errorMsg}
                                  isDisabled={input.disabled}
                                  isError={
                                    (typeof errors[input.name] !== 'undefined' && !!touched[input.name]) ||
                                    (forcedError.field === input.name && forcedError.show)
                                  }
                                  inputData={input}
                                  onChange={changeFunction}
                                  onBlur={setFieldTouched}
                                />
                              </Box>
                              {input.showChipsLocally && (
                                <SelectedChips
                                  inputs={inputFields}
                                  onChange={setFieldValue}
                                  onFieldTouched={setFieldTouched}
                                />
                              )}
                            </Grid>
                          )
                        })()
                      )}
                      {inlineButtons && (
                        <ButtonsContainer
                          alignElements={alignElements}
                          resetText={getObjectDiff(values, defaultValues).length ? resetText : ''}
                          submitText={submitText}
                          disableSubmit={disableSubmit}
                          isLoading={isLoading}
                          handleReset={handleReset}
                          inline={inlineButtons}
                        />
                      )}
                    </Grid>
                    {showGroupChips && (
                      <SelectedChips inputs={inputFields} onChange={setFieldValue} onFieldTouched={setFieldTouched} />
                    )}
                    {!inlineButtons && (
                      <ButtonsContainer
                        alignElements={alignElements}
                        resetText={resetText}
                        submitText={submitText}
                        disableSubmit={disableSubmit}
                        cancelText={cancelText}
                        isLoading={isLoading}
                        handleReset={handleReset}
                        handleCancel={onCancel}
                        inline={inlineButtons}
                      />
                    )}
                  </form>
                </>
              )}
            </Formik>
          </FormStyle>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
)

RdnaForm.displayName = 'RdnaForm'

export default RdnaForm
