BackgridCell    = require('helpers/backgrid-cell')
BackgridRow     = require('helpers/backgrid-row')
BaseView        = require('base/view')
PagedCollection = require('base/paged-collection')
PagerView       = require('widgets/pager/view')
ReactComponent  = require('src/ReactComponent').default
CreateBtn       = require('src/components/common/buttons/CreateBtn').default

class TeamSearchView extends BaseView

  template: require('./template')
  className: 'team-search'

  regions:
    grid   : 'div.grid'
    pager  : 'span.pager'
    addBtn : '.add-new-btn'

  computeds:
    isSupport:
      get: ->
        @user().can('teams:search:*')

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  ui:
    name   : 'input.team-name'
    search : 'button.search'

  initialize: (options) ->
    @qs = options.qs
    @qs.setFragment('teams')

  onRender: =>
    @showChildView('addBtn', new ReactComponent({
      component: CreateBtn,
      props: { onClick: @onCreateNew }
    })) if @user().can('teams:edit:me') or @user().can('teams:edit:*')
    @qs.bind(
      name      : @ui.name
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    return unless @validate()
    collection = new PagedCollection(
      page: @qs.get('page')
      queryParams:
        name      : @ui.name.val()
        accountId : @getAccountORNull()
      url: 'teams'
    )

    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))

    collection.fetch(reset: true)

  buildGrid: (collection) ->
    teamCell = BackgridCell.href(
      url   : '#teams/:id',
      label : ':name',
      attrs : ['id', 'name'])

    acctCell = BackgridCell.accountCell('account.id', 'account.name')

    datetime = BackgridCell.dateTime()
    accountCellRenderable = @user().canByUserRole('accounts:edit:*')

    cols = [
      {label: 'Name',          name: 'name',         cell: teamCell}
      {label: 'Account',       name: 'account.name', cell: acctCell, renderable: accountCellRenderable}
      {label: 'Description',   name: 'description',  cell: 'string'}
      {label: 'Created Date',  name: 'createdAt',    cell: datetime}
      {label: 'Modified Date', name: 'updatedAt',    cell: datetime}
      {label: 'ID',            name: 'id',           cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No teams found.',
      row        : BackgridRow.deleted()
    )

  validate: =>
    if @ui.name.val() and @ui.name.val().length < 3
      toastr.warning('Team name must be at least three characters.')
      return false

    true

  onCreateNew: ->
    App.router.navigate('#team/add-new', {trigger: true})

module.exports = TeamSearchView
