import React, { useEffect, useState } from 'react'
import { MainProps } from './types'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import {useTCPA, useTCPAInsert} from './api/index'
import { SHeader, SettingSelect } from './styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import ShieldIcon from '@ringdna/common/src/assets/icons/svg/Shield'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { useUser } from '../common/services/UserContext'

export default function TCPA({ selectedAccountId }: MainProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error, success } = useRdnaNotification()
  const insertTCPA = useTCPAInsert()
  const [config, , loading, ] = useTCPA({ meta: { accountId: selectedAccountId.toString() } })
  const [leadField, setLeadField] = useState<string|null>(null)
  const [contactField, setContactField] = useState<string|null>(null)
  const [pAccountField, setPAccountField] = useState<string|null>(null)
  const { isSupport, accountId } = useUser()
  const isReadOnly = isSupport && accountId != selectedAccountId

  useEffect(() => {
    if (config) {
      setLeadField(config.config.leadField ? config.config.leadField : null)
      setContactField(config.config.contactField ? config.config.contactField : null)
      setPAccountField(config.config.pAccountField ? config.config.pAccountField : null)
    }
  }, [config])
  const leadOptions = !!config ? config.availableLeadFields.map(field => ({ label: field, value: field })) : []
  const contactOptions = !!config ? config.availableContactFields.map(field => ({ label: field, value: field })) : []
  const pAccountOptions = !!config ? config.availablePAccountFields.map(field => ({ label: field, value: field })) : []

  async function handleInsert() {
    setProcessing(true)
    try {
      await insertTCPA({body: serializeJsonIntoPlay({accountId: selectedAccountId, leadField, contactField, pAccountField})})
      success('Settings Saved')
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
  }

  return (
    <RdnaLoader loading={!!loading} top="150px" data="data">
      <SectionHeader
        headingText={`RingDNA: TCPA`}
        rightColumn={
          !loading && (
            <RdnaButton
              style={{ width: 'fit-content' }}
              text={'Save'}
              name="save-tcpa-settings"
              processing={processing}
              onClick={handleInsert}
            />
          )
        }
      />
      {!loading && (
        <RdnaCard>
          <SHeader>
            <IconHelper icon={ShieldIcon} variant="filled" />
            <RdnaText className={'rdna-text'}>TCPA Check</RdnaText>
          </SHeader>
          <div style={{marginBottom: 20}}>
            <RdnaText >Designate Lead, Contact, or Person Account Fields to check when placing an outbound call to determine if a call can be made. TCPA checks support checkbox fields and, when true, will disallow the ability to place an outbound call to a phone number.</RdnaText>
          </div>
          <RdnaSettingRow heading={"Lead"} description={""}>
            <SettingSelect
              disabled={isReadOnly}
              id={'lead-options'}
              options={leadOptions}
              placeholder={'Select'}
              // @ts-ignore
              value={leadField}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value
              }}
              onChange={(event: any, option: any) => {
                setLeadField(option ? option.value : null)
              }}
            />
          </RdnaSettingRow>
          <RdnaSettingRow heading={"Contact"} description={""}>
            <SettingSelect
              disabled={isReadOnly}
              id={'contact-options'}
              options={contactOptions}
              placeholder={'Select'}
              // @ts-ignore
              value={contactField}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value
              }}
              onChange={(event: any, option: any) => {
                setContactField(option ? option.value : null)
              }}
            />
          </RdnaSettingRow>
          <RdnaSettingRow heading={"Person Account"} description={""}>
            <SettingSelect
              disabled={isReadOnly}
              id={'p-account-options'}
              options={pAccountOptions}
              placeholder={'Select'}
              // @ts-ignore
              value={pAccountField}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value
              }}
              onChange={(event: any, option: any) => {
                setPAccountField(option ? option.value : null)
              }}
            />
          </RdnaSettingRow>
        </RdnaCard>
      )}
    </RdnaLoader>
  )
}
