import React, { useState } from 'react'
import RdnaText from '../../RdnaText'
import Info from '../../../assets/icons/svg/Info'
import IconHelper from '../../../assets/icons/IconHelper'
import { Colors } from '../../../constants/colors'
import RdnaModal from '../../RdnaModal'

type InfoModalProps = {
  title: string
  body: string
  iconColor?: Colors
}

export default function RdnaInfoModal({ title, body, iconColor }: InfoModalProps) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      <IconHelper
        icon={Info}
        color={iconColor || Colors.BLACK}
        size={16}
        iconSize={16}
        testId="info-modal-button"
        onClick={() => setOpenModal(true)}
      />
      <RdnaModal
        headingIcon={<Info size={25} color={Colors.BLACK} />}
        open={openModal}
        heading={title}
        onClose={() => setOpenModal(false)}
      >
        <RdnaText>{body}</RdnaText>
      </RdnaModal>
    </>
  )
}
