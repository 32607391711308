import React from 'react'
import { FormikHelpers } from 'formik'
import { SipEnabled, SipRoute, SipRouteEditables } from '@ringdna/common/src/types/sip-routing'
import { usePostSipRoute } from '../../store/api/sip-route-api'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaForm from '@ringdna/common/src/components/RdnaForm'
import { InputField } from '@ringdna/common/src/types/form-inputs'
import { InputTypes } from '@ringdna/common/src/constants/input-types'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  onSubmit: () => void
  sipRoute: SipRoute | void
}

function SipRouteEditModal({ onRequestClose, onSubmit, isOpen, sipRoute }: Props) {
  const postSipRoute = usePostSipRoute()

  const _onSubmit = async (values: SipRouteEditables, actions: FormikHelpers<SipRouteEditables>) => {
    values.enabled = (values.enabled as SipEnabled)['Enabled']
    if (sipRoute?.id) values.id = sipRoute.id
    try {
      await postSipRoute({
        query: values
      })
      onSubmit()
      onRequestClose()
    } catch (err) {
      toastr.warning(err.message)
      actions.setSubmitting(false)
    }
  }

  const inputFields: InputField[] = [
    {
      type: InputTypes.TEXT,
      label: 'Domain',
      name: 'domain',
      required: true,
      defaultValue: sipRoute ? sipRoute.domain : ''
    },
    {
      type: InputTypes.TEXT,
      label: 'Username',
      name: 'username',
      required: true,
      defaultValue: sipRoute ? sipRoute.username : ''
    },
    {
      type: InputTypes.TEXT,
      label: 'Password',
      name: 'password',
      required: false,
      defaultValue: sipRoute && sipRoute.password ? sipRoute.password : ''
    },
    {
      type: InputTypes.TEXT,
      label: 'Params',
      name: 'params',
      required: false,
      defaultValue: sipRoute && sipRoute.params ? sipRoute.params : ''
    },
    {
      type: InputTypes.CHECKBOX,
      options: ['Enabled'],
      name: 'enabled',
      required: false,
      defaultValue: sipRoute && sipRoute.enabled ? 'Enabled' : ''
    }
  ]

  return (
    <RdnaModal open={isOpen} onClose={onRequestClose}>
      <RdnaForm
        inputFields={inputFields}
        headline="SIP Routing Editor"
        submitText="Save"
        cancelText="Cancel"
        alignElements="center"
        onCancel={onRequestClose}
        onSubmit={_onSubmit}
      />
    </RdnaModal>
  )
}

export default SipRouteEditModal
