import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceMultimatchIcon({ size = 19, color = Colors.SALESFORCE_MULTIMATCH }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 19 14" fill="none">
      <path d="M17.52 3.4V13H3.4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M1 1h14.12v9.6H1z" />
      <path
        clipRule="evenodd"
        d="M7.933 5.133a1.067 1.067 0 100-2.133 1.067 1.067 0 000 2.133z"
        stroke={color}
        strokeWidth={0.775}
      />
      <path
        clipRule="evenodd"
        d="M5.8 7.133s.8-.8 2.133-.8 2.133.8 2.133.8V8.2H5.8V7.134z"
        stroke={color}
        strokeWidth={0.775}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SalesforceMultimatchIcon
