BaseView                = require('base/view')

class CallTasksView extends BaseView

  template: require('./template')

  bindings:
    '.type'                  : 'checked:TypeTool'
    '.priority'              : 'checked:PriorityTool'
    '.dueDate'               : 'checked:DueDateTool'
    '.reminder'              : 'checked:ReminderTool'
    '.relatedRecords'        : 'checked:RelatedRecordsTool'
    '.relatedRecordsDiv'     : 'toggle:relatedRecords'

  initialize: (options) ->
    { @model, @accountId } = options

module.exports = CallTasksView
