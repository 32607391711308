BaseView = require('base/view')
SalesforceField = require('../model')

class EditorView extends BaseView
  template: require('./template')

  ui:
    save            : '.persist'
    modal           : '#add-salesforce-field-setting-modal'
    availableFields : '.available-fields'

  events:
    'click .persist' : 'save'

  bindings:
    '.modal-title'      : 'text:modalTitleText'
    '.name'             : 'value:name'
    '.available-fields' : 'value:sfdcName'
    '.editable-checkbox': 'checked:editable,disabled:any(not(updateable),noChange,sfdcRequired),attr:{title:editableTooltipText}'
    '.visible-checkbox' : 'checked:visible,disabled:noChange'
    '.required-checkbox': 'checked:required,disabled:any(not(editable),noChange,sfdcRequired),attr:{title:requiredTooltipText}'
    '.editable-tooltip' : 'attr:{"data-original-title":editableTooltipText}'
    '.required-tooltip' : 'attr:{"data-original-title":requiredTooltipText}'
    '.persist'          : 'disabled:noChange'

  computeds:
    modalTitleText:
      get: ->
        "Add Additional #{@type} Field"
    editableTooltipText:
      deps: ['updateable']
      get: (updateable) ->
        if updateable
          'Select this setting to allow users to edit this field in the Softphone'
        else
          'This field is read-only within SFDC. Contact your Salesforce admin to update permissions.'
    requiredTooltipText:
      deps: ['editable']
      get: (editable) ->
        if editable
          'Select this setting to require input on the creation or edit of a record.'
        else
          'Only editable fields may be required'
    noChange:
      deps: ['parentType', 'sfdcName']
      get: (parentType, sfdcName) ->
        return true if parentType is 'Task' and sfdcName is 'Owner.Name'
        return true if parentType isnt 'Account' and sfdcName is 'Account.Name'
        false

  initialize: (options) ->
    { @availableFields, @type } = options
    @model = new SalesforceField.SettingModel(
      parentType: @type
      accountId: options.accountId
      profileId: options.profileId
      sortOrder: options.sortOrder
    )
    @viewModel = new Backbone.Model(
      sfdcRequired: false
      updateable: false
    )
    @selectOptions = []
    @optGroups = []
    @optGroups.push(label: @type, value: @type)
    @availableFields.forEach((field) =>
      group = @type
      nameArray = field.get('sfdcName')?.split('.')
      if nameArray and nameArray.length > 1
        group = nameArray[0]
        @optGroups.push({
          label: "#{@type} #{group}"
          value: group
        }) unless _.findWhere(@optGroups, {value: group})
      @selectOptions.push({
        text: "#{field.get('name')} (#{field.get('sfdcName')})"
        value: field.get('sfdcName')
        optgroup: group
      })
    )
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @model.on('change:editable', () => @model.set('required', false))

  onRender: =>
    @ui.availableFields.selectize(
      options: @selectOptions
      optgroups: @optGroups
      placeholder: 'Select a Salesforce field'
      persist: false
    )

    selectize = @ui.availableFields[0].selectize
    selectize.on('change', @updateField)

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  updateField: =>
    selectize = @ui.availableFields[0].selectize
    val = selectize.getValue()
    field = @availableFields.findWhere(sfdcName: val)
    sfdcRequired = false
    updateable = false
    editable = false
    name = ''
    sfdcName = ''
    dataType = ''
    if field
      sfdcRequired = field.get('required')
      updateable = field.get('updateable')
      editable = true if sfdcRequired
      sfdcName = field.get('sfdcName')
      dataType = field.get('dataType')
      nameSuffix = ''
      nameArray = field.get('sfdcName')?.split('.')
      if nameArray and nameArray.length > 1
        nameSuffix = " (#{nameArray[0]})"
      name = "#{field.get('name')}#{nameSuffix}"
    @model.set(
      editable: editable
      required: sfdcRequired
      visible: false
      sfdcName: sfdcName
      name: name
      dataType: dataType
    , silent: true)
    @viewModel.set(
      updateable: updateable
      sfdcRequired: sfdcRequired
    )
    @applyBindings()

  validate: ->
    valid = true
    if not @model.get('name')?.trim()
      toastr.warning('Please enter a name')
      valid = false
    else if @model.get('name')?.length > 30
      toastr.warning('Name must be 30 characters or less')
      valid = false

    if not @model.get('sfdcName') or not @model.get('dataType')
      toastr.warning('Please select a Salesforce field')
      valid = false

    valid

  save: =>
    return unless @validate()
    @model
      .persist(ladda: @ui.save[0])
      .done?((response) =>
        response.isDefault = false
        model = new Backbone.Model(response)
        @trigger("add:setting", model)
        @ui.modal.modal('hide')
        toastr.info("The #{_.escape(@model.get('name')?.trim())} field has been added.")
      )

module.exports = EditorView
