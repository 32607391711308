import React, { ChangeEvent, useCallback, useEffect, useState, useMemo, SyntheticEvent } from 'react'
import { DataListItem, useDataPicklistApiFactory } from '../../../api/account-data-api'
import RdnaAutocomplete, { RdnaAutocompleteProps } from '../../RdnaForm/RdnaAutocomplete'
import { differenceBy, isEqual, uniqBy } from 'lodash-es'
import RdnaUserLabel from '../../RdnaUserLabel'
import RdnaChip from '../../RdnaChip'
import { Box } from '@mui/material'
import { AutocompleteValue } from '@mui/material/useAutocomplete'
import { DefaultApi } from '@ringdna/client/src/constants'

const INITIAL_MESSAGE = 'Start typing...'
const EMPTY_MESSAGE = 'No options'

export type AutoCompleteApiData = {
  value: string
  text: string
  [key: string]: string
}

type OnChange<Multiple extends boolean | undefined> = (
  arg: AutocompleteValue<AutoCompleteApiData, Multiple, true, false>
) => void
type SetInputValue<Multiple extends boolean | undefined> = React.Dispatch<
  React.SetStateAction<AutocompleteValue<AutoCompleteApiData, Multiple, true, false> | undefined>
>
type ValueProps<Multiple extends boolean | undefined> = {
  onChange: OnChange<Multiple>
  multiple: boolean | undefined
  setInputValue: SetInputValue<Multiple>
  inputValue: AutocompleteValue<AutoCompleteApiData, Multiple, true, false> | undefined
}

function isValuePropsMultiple(valueProps: ValueProps<boolean | undefined>): valueProps is ValueProps<true> {
  return !!valueProps.multiple
}
function isValuePropsSingle(valueProps: ValueProps<boolean | undefined>): valueProps is ValueProps<false> {
  return !valueProps.multiple
}

interface RdnaApiAutoComplete<Multiple extends boolean | undefined>
  extends Omit<RdnaAutocompleteProps<AutoCompleteApiData, Multiple>, 'onChange' | 'options' | 'value'> {
  name: string
  searchUrl: string
  searchDomain?: string
  queryName?: string
  searchQuery?: any
  required?: boolean
  helperText?: string
  labelAttribute?: string
  valueAttribute?: string
  error?: boolean
  defaultSearchTerm?: string
  onChange: OnChange<Multiple>
}

const RdnaApiAutoComplete = <Multiple extends boolean | undefined = false>({
  multiple,
  searchUrl,
  queryName = 'name',
  searchDomain = DefaultApi,
  searchQuery,
  labelAttribute = 'text',
  valueAttribute = 'value',
  error,
  helperText,
  defaultValue,
  defaultSearchTerm,
  onChange,
  name,
  ...restOfAutoCompleteProps
}: RdnaApiAutoComplete<Multiple>) => {
  const [dropdownOptions, setDropdownOptions] = useState<AutoCompleteApiData[]>([])
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm || '')
  const [inputValue, setInputValue] = useState<
    AutocompleteValue<AutoCompleteApiData, Multiple, true, false> | undefined
  >(defaultValue)
  const valueProps: ValueProps<Multiple> = useMemo(
    () => ({ onChange, multiple, inputValue, setInputValue }),
    [onChange, multiple, inputValue, setInputValue]
  )

  const updateForm = useCallback(
    (newValue?: AutoCompleteApiData) => {
      if (isValuePropsMultiple(valueProps)) {
        const updatedValue = uniqBy([...(valueProps.inputValue || []), newValue], a =>
          a ? a[valueAttribute] : 'value'
        ).filter((item): item is AutoCompleteApiData => item?.value !== '')
        if (!isEqual(valueProps.inputValue, updatedValue)) {
          valueProps.setInputValue(updatedValue)
          valueProps.onChange(updatedValue)
          setSearchTerm('')
        }
      } else if (isValuePropsSingle(valueProps)) {
        if (!isEqual(valueProps.inputValue, newValue)) {
          const updatedValue = newValue || { text: '', value: '' }
          valueProps.setInputValue(updatedValue)
          valueProps.onChange(updatedValue)
          setSearchTerm(updatedValue.text)
        }
      }
    },
    [valueProps]
  )

  const clearData = function () {
    setDropdownOptions([])
    updateForm(undefined)
  }

  const handleChange = function (
    event: SyntheticEvent<Element, Event>,
    value: AutoCompleteApiData | AutoCompleteApiData[]
  ) {
    if (value) {
      updateForm(value as AutoCompleteApiData)
    } else {
      if (!multiple) {
        // If user backspaces entire field or hits the clear button. We don't do this for multiple values
        // as existing choices are represented via chips.
        clearData()
      } else {
        setSearchTerm('')
      }
    }
  }

  const deleteFromChip = useCallback(
    (id: string) => {
      if (isValuePropsMultiple(valueProps)) {
        const newValue = (valueProps.inputValue || []).filter((item: AutoCompleteApiData) => item.value !== id)
        valueProps.setInputValue(newValue)
        valueProps.onChange(newValue)
      }
    },
    [valueProps]
  )

  const [data, , loading, refetch] = useDataPicklistApiFactory(searchDomain)(
    {
      meta: {
        searchUrl
      },
      query: { ...searchQuery, [queryName]: searchTerm }
    },
    { paused: true }
  )

  const updateSearch = function (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    // When user types we update what is searched for from the API in the useEffect below
    setSearchTerm(event.currentTarget.value)
  }

  useEffect(() => {
    // Search API everytime user types a new character
    if (searchTerm.length > 1) {
      refetch()
    } else if (searchTerm.length === 0) {
      // If search term is empty, we clear out options for the dropdown.
      setDropdownOptions([])
    }
  }, [searchTerm, refetch])

  // Filter out values coming from the API that the user has already selected
  useEffect(() => {
    if (data) {
      const uniq = uniqBy(data, a => a[valueAttribute])
      const difference = differenceBy(uniq, (inputValue as AutoCompleteApiData[]) || [], a => a[valueAttribute])
      const mapped = difference.map(item => {
        return {
          text: item[labelAttribute]?.toString() || '',
          value: item[valueAttribute]?.toString() || ''
        }
      })
      setDropdownOptions(mapped)
    }
  }, [data, inputValue])

  const isInputEmpty = searchTerm.length < 1

  return (
    <div>
      <RdnaAutocomplete
        id={name}
        disableCloseOnSelect={multiple}
        renderOption={(props, option) => (
          <li {...props} key={`${props.id}-${option.text}-${option.value}`}>
            <RdnaUserLabel
              name={(option as DataListItem).text}
              email={(option as DataListItem).email}
              showAvatar={false}
            />
          </li>
        )}
        inputValue={searchTerm}
        loading={!!loading}
        onInputChange={updateSearch}
        noOptionsText={isInputEmpty ? INITIAL_MESSAGE : EMPTY_MESSAGE}
        inputError={error}
        inputHelperText={helperText}
        {...restOfAutoCompleteProps}
        // @ts-ignore
        onChange={handleChange}
        options={dropdownOptions}
        getOptionLabel={option => (option as DataListItem)?.text || ''}
      />
      {isValuePropsMultiple(valueProps) &&
        valueProps.inputValue?.map((option: AutoCompleteApiData) => (
          <Box key={option.value} mr={1} mt={1} display="inline-block" position="relative">
            <RdnaChip label={option.text} onDelete={() => deleteFromChip(option.value)} />
          </Box>
        ))}
    </div>
  )
}

export default RdnaApiAutoComplete
