import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { callFlowLink, callFlowsColumns } from './const'
import { COPYEDIT, DELETE, EDIT, NO_RESULT_STATE, PAGE_SIZE } from '../../const'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { CallFlowDeleteModalProps, CallFlowProps } from '../../types'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useCallFlowsCopy, useCallFlowsDelete } from '../../api'
import useToast from '../../../common/hooks/useToast'

export type CallFlowTableProps = {
  payload: any | undefined
  refetch: any
  onQueryState: any
  loading: boolean
  isSupport: boolean
}

export default function CallFlowsTable({ payload, onQueryState, loading, refetch, isSupport }: CallFlowTableProps) {
  const toastr = useToast()
  const [isCallFlowDeleteModalOpen, setIsCallFlowDeleteModalOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [callFlow, setCallFlow] = useState<CallFlowProps>()
  const copyCallFlow = useCallFlowsCopy()
  const deleteCallFlow = useCallFlowsDelete()
  const totalRowsRef = useRef(0)
  if (payload?.total) totalRowsRef.current = payload?.total

  const menuOptions = (callFlow: CallFlowProps) => {
    const options = [{ text: COPYEDIT }, { text: EDIT }]
    if (callFlow.paused || !callFlow.valid) {
      options.push({ text: DELETE })
    }
    return options
  }

  const handleActionMenu = useCallback(
    (action: string, callFlow: CallFlowProps) => {
      if (action === DELETE) {
        setCallFlow(callFlow)
        setIsCallFlowDeleteModalOpen(true)
      } else if (action === EDIT) {
        window.location.href = `${callFlowLink}${callFlow.id}`
      } else {
        handleCopyCallFlow(callFlow.id)
      }
    },
    [handleCopyCallFlow]
  )

  const memoPayload = useMemo(() => {
    return payload?.results?.map((template: CallFlowProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={menuOptions(template)} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleCopyCallFlow(id: number) {
    try {
      const copyPayload = await copyCallFlow({
        meta: { id: id }
      })
      window.location.href = `/#call-flows/${copyPayload?.success?.payload}`
    } catch (error) {
      toastr.error(getErrorMessage(error))
    }
  }

  async function handleDelete() {
    setIsLoadingDelete(true)
    try {
      await deleteCallFlow({ meta: { id: callFlow?.id } })
      setIsCallFlowDeleteModalOpen(false)
      refetch()
    } catch (e) {
      toastr.error(e)
    }
    setIsLoadingDelete(false)
  }

  return (
    <>
      <RdnaSmartTable
        searchPlaceholder="Search by name or number"
        initialTableState={{
          globalFilter: '',
          sortBy: [{ id: 'id', desc: true }],
          pageIndex: 0,
          pageSize: PAGE_SIZE
        }}
        // @ts-ignore
        columns={callFlowsColumns(isSupport)}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        setQueryState={onQueryState}
        isFetching={loading}
        totalItems={totalRowsRef?.current}
        paginationSize={PAGE_SIZE}
      />
      <ConfirmCallFlowDeleteModal
        open={isCallFlowDeleteModalOpen}
        onClose={setIsCallFlowDeleteModalOpen}
        callFlowName={callFlow?.name}
        onConfirm={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  )
}

const ConfirmCallFlowDeleteModal = ({
  open,
  onClose,
  onConfirm,
  callFlowName,
  isLoadingDelete
}: CallFlowDeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Call Flow`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`Are you sure you want to delete the call flow: ${callFlowName}. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}
