import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseBreakpoints from '../baseBreakpoints'
import baseTransitions from '../baseTransitions'

const palette = {
  ...basePalette
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const breakpoints = {
  ...baseBreakpoints,
  mobile: {
    min: 0,
    max: 640,
    mediaQuery: '@media (max-width: 640px)'
  },
  tablet: {
    min: 641,
    max: 1024,
    mediaQuery: '@media (max-width: 1024px) and (min-width: 641px)'
  },
  desktop: {
    min: 1025,
    max: 'infinity',
    mediaQuery: '@media (min-width: 1025px)',
    smallerThan: '@media (max-width: 1024px)'
  }
}

const zIndex = {
  rdnaMenu: 100,
  appBar: 1200,
  drawer: 5555, // to go on top of pendo guide
  modal: 5556,
  tooltip: 5557
}

const theme = {
  breakpoints,
  palette,
  typography,
  transitions,
  zIndex,
  spacing: 6
}

export default theme
