BaseModel = require('base/model')

class WebLeadsModel extends BaseModel

  urlRoot: 'webLeadsMapping'

  defaults:
    name: ''
    value: ''

  parse: (response) ->
    attrs =
      id: response.id
      name: response.name
      value: response.value
      team: response.team
      teamName: response.team?.name
    attrs

  toJSON: ->
    fields = {}
    fields = @toPlayJSON('mapping')
    fields

  validate: (attrs) ->
    return 'Field Name Cannot Be Empty.' unless attrs.name?.trim()
    return 'Field Value Cannot Be Empty.' unless attrs.value?.trim()
    return 'Team Cannot Be Empty.' unless attrs.team?.id
    return

module.exports = WebLeadsModel