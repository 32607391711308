BaseView = require('base/view')
BaseGrid = require('base/grid')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')
UnassignedNumbersGrid = require('../../__components/unassigned-numbers-grid/view')

class Step3Modal extends BaseView
  template: require('./step-3-template')

  regions:
    grid: 'div.grid'
    numbersGrid: 'div.numbers-grid'

  bindings:
    '.table-selected-channels': 'classes:{"unset-main-grid":hasUnset}'

  initialize: (options) =>
    { @accountId } = options
    @viewModel = new Backbone.Model(hasUnset: @collection.length <= 3)
    @listenTo(@collection, 'change:typeNumber', (model) => @checkChangedTypeNumber(model))
    @activeModelForUnassignedNumber = null

  onRender: =>
    @showSpinner($(@el), left: '45%')
    CallTrackingWidgets.getInfo(@accountId, (campaigns, callflows, countries) =>
      @hideSpinner($(@el))
      @showChildView('grid', @buildGrid(campaigns, callflows, countries)) if @getRegion('grid')
      $('[data-toggle="tooltip"]').tooltip()
    )

  checkChangedTypeNumber: (changedModel) =>
    if changedModel.get('typeNumber') is 'Existing'
      @activeModelForUnassignedNumber = changedModel
      @showChildView('numbersGrid', new UnassignedNumbersGrid(accountId: @accountId, changedModel: changedModel))
    else
      if @activeModelForUnassignedNumber?.cid == changedModel.cid
        @getRegion('numbersGrid').destroy() if @getRegion('numbersGrid')

  buildGrid: (campaigns, callflows, countries) =>
    channelCell = class extends Backgrid.Cell
      events:
        'mouseover .google-adwords': 'mouseover'
        'mouseout .google-adwords': 'mouseout'

      mouseover: =>
        @$('[data-toggle="popover"]').popover('show')

      mouseout: =>
        setTimeout(=>
          $('[data-toggle="popover"]').popover('hide')
        , 1000)

      render: =>
        if @model.get('channel') is 'Google AdWords'
          html = """Ads <span class='glyphicon glyphicon-info-sign red google-adwords' aria-hidden='true'
          data-placement='top' data-toggle='popover'
          data-content='<p>Ads require auto tagging to be enabled. Click
            <a href="https://support.google.com/adwords/answer/3095550" target="_blank"> here</a>
            for more information.</p>'
            data-html='true'></span>"""
        else
          html = @model.get('channel')
        $(@el).html(html) if @model.get('channel')
        @

    columns = [
      {label: 'Channel',       name: 'channel',  cell: channelCell, sortable: false}
      {label: 'Country',       name: 'country',  cell: CallTrackingWidgets.countryCell(countries),          sortable: false}
      {label: 'Smart Number',  name: 'type',     cell: CallTrackingWidgets.typeSmartNumberCell(),           sortable: false}
      {label: '',              name: 'smartNumberId', cell: CallTrackingWidgets.typeSmartNumberValueCell(), sortable: false}
      {label: 'Area Code',     name: 'code',     cell: CallTrackingWidgets.areaCodeCell(),                  sortable: false}
      {label: 'Call Flow',     name: 'callFlow', cell: CallTrackingWidgets.callFlowCell(callflows),         sortable: false}
      {label: 'SFDC Campaign', name: 'campaign', cell: CallTrackingWidgets.campaignCell(campaigns),         sortable: false}
    ]

    new BaseGrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No channels found.'
    )

module.exports = Step3Modal
