BaseView = require('base/view')

class SMSStepView extends BaseView

  template: require('./template')

  bindings:
    'input#smsType'                   : 'checked: smsType'
    '.sms-stats'                      : 'text: textLength'
    'a[data-action="add-sms-number"]' : 'classes:{disabled:not(allowAddNumber)}'

  computeds:
    smsType:
      get: -> @model.args().smsType is 'nonAgent'
    textLength:
      get: ->
        textLength = @$('.sms-text').val().length
        "#{textLength} characters (#{160-textLength} left)"
    allowAddNumber:
      get: ->
        numbers = @$('.smsNumbers')
        (value for value in (number.value for number in numbers) when value).length is numbers.length

  events:
    'click a[data-action="add-sms-number"]'     : 'addSMSNumber'
    'click a[data-action="remove-sms-number"]'  : 'removeSMSNumber'
    'keyup.length-text'                         : 'handleSMSText'

  initialize: ->
    smsNumbersList = @model.args().smsNumbers.split(',')
    @updateViewData(smsNumbersList)

  addSMSNumber: ->
    listNumbers = @model.args().smsNumbers.split(',')
    return unless listNumbers[0].trim()
    listNumbers.push('')
    @updateViewData(listNumbers)
    @model.args().smsNumbers = @model.args().smsNumbers.concat(',')
    @render()
    @model.trigger('refreshErrors', @model)

  handleSMSText: ->
    txt = @$('.sms-text').val()
    if txt.length > 160
      @$('.sms-text').val(txt.substring(0, 160))
    @applyBindings()

  removeSMSNumber: (e) ->
    listNumbers = @model.args().smsNumbers.split(',')
    value = $(e.target).parent().parent().find('.smsNumbers').val()
    listNumbers = (number for number in listNumbers when number isnt value)
    listNumbers.push('') if listNumbers.length < 1
    @model.args().smsNumbers = listNumbers.join()
    @updateViewData(listNumbers)
    @render()
    @model.trigger('refreshErrors', @model)

  updateViewData: (smsNumbersList) ->
    @model.set(
      firstNumber: smsNumbersList.shift()
      smsNumbersList: smsNumbersList
    ) if smsNumbersList.length > 0

module.exports = SMSStepView