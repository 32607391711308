import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function TeamsIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_9963_77889)">
        <path
          d="M14.8502 5.3957C15.775 5.3957 16.5246 4.64604 16.5246 3.72129C16.5246 2.79654 15.775 2.04688 14.8502 2.04688C13.9254 2.04688 13.1758 2.79654 13.1758 3.72129C13.1758 4.64604 13.9254 5.3957 14.8502 5.3957Z"
          fill="white"
        />
        <path
          d="M9.43977 11.7833V6.13867H12.8673C13.2531 6.14822 13.5583 6.46839 13.5494 6.85421V11.1478C13.6033 13.463 11.7715 15.3843 9.45636 15.441C7.46786 15.3923 5.83602 13.9681 5.44922 12.1002C5.47572 12.2227 5.50731 12.3441 5.54395 12.4642H8.75884C9.13373 12.4614 9.43693 12.1582 9.43977 11.7833Z"
          fill="white"
        />
        <path
          d="M13.1705 12.9557C12.2958 12.5375 11.6916 11.6446 11.6914 10.6105V6.50816C11.6914 6.39827 11.7394 6.29958 11.8155 6.2319H12.6917L12.6975 6.23197C13.1868 6.24397 13.5739 6.64551 13.5641 7.12995V11.1072C13.5792 11.7678 13.4366 12.3958 13.1705 12.9557Z"
          fill="white"
        />
        <path
          d="M13.3858 13.0469C13.6643 12.4582 13.8135 11.7985 13.7981 11.1046V7.13213C13.8058 6.70656 13.571 6.33193 13.2192 6.13867H16.1937C16.5842 6.13867 16.9007 6.45519 16.9007 6.84564V10.6101C16.9007 12.0451 15.7374 13.2084 14.3024 13.2084H14.2901C13.9721 13.2085 13.6675 13.1514 13.3858 13.0469Z"
          fill="white"
        />
        <path
          d="M12.0599 2.97524C12.0599 4.311 10.977 5.39384 9.64126 5.39384C9.57517 5.39384 9.5097 5.39119 9.44495 5.38599V4.57837C9.44352 4.19806 9.13949 3.89011 8.76402 3.88867H7.40109C7.28605 3.60684 7.22266 3.29844 7.22266 2.97524C7.22266 1.63949 8.3055 0.556641 9.64126 0.556641C10.977 0.556641 12.0599 1.63949 12.0599 2.97524Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M9.04651 4.73567V5.6C8.92091 5.591 8.79635 5.56947 8.67442 5.53568C7.92093 5.34289 7.29842 4.77197 7 4H8.36558C8.74105 4.00154 9.04508 4.33001 9.04651 4.73567Z"
          fill="#003A7F"
        />
        <path
          opacity="0.2"
          d="M9.08458 4.58718V5.32764C8.33108 5.1492 7.70858 4.62077 7.41016 3.90625H8.40364C8.77912 3.90768 9.08315 4.21171 9.08458 4.58718Z"
          fill="#003A7F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.40243 3.90625H1.58048C1.2038 3.90625 0.898438 4.21161 0.898438 4.58829V11.4102C0.898438 11.7869 1.2038 12.0923 1.58048 12.0923H8.40243C8.77911 12.0923 9.08448 11.7869 9.08448 11.4102V4.58829C9.08448 4.21161 8.77912 3.90625 8.40243 3.90625ZM5.42154 6.50357H6.78527V5.7832H3.19531V6.50357H4.55271V10.2171H5.42154V6.50357Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9963_77889">
          <rect width="16" height="14.8837" fill="white" transform="translate(0.898438 0.558594)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TeamsIcon
