import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import InfoIcon from '@ringdna/common/src/assets/icons/svg/Info'
import RdnaTooltip from '@ringdna/common/src/components/RdnaTooltip'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import SmsTemplates from './components/sms-templates'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import SmsIcon from '@ringdna/common/src/assets/icons/svg/Sms'
import MessagesIcon from '@ringdna/common/src/assets/icons/svg/Messages'
import AgreementModal from './components/AgreementModal'
import UserPicklist from './components/UserPicklist'
import { useAccountDialerSettings, useUpdateAccountDialerSettings } from '../../store/api/accounts-api'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import { AccountDialerSettings } from '../support-settings/utils/types'
import { parseAccountSettings } from '../../utils/account'
import { SMS_SETTINGS_STR } from './strings'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'
import { useUser } from '../common/services/UserContext'

type AccountProviderProps = {
  accountId: string
}

export default function SmsSettings({ accountId }: AccountProviderProps) {
  const { isSupport } = useUser()

  const [payload] = useAccountDialerSettings({ meta: { id: accountId } })
  const [dialerSettings, setDialerSettings] = useState<AccountDialerSettings>()

  const [loading, setLoading] = useState(true)
  const [modalOpened, setModalOpened] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)

  // Settings
  const [smsEnabled, setSmsEnabled] = useState(false)
  const [smsTemplatesEnabled, setSmsTemplatesEnabled] = useState(false)
  const [undeliverableSmsOwnerId, setUndeliverableSmsOwnerId] = useState<number | null>(null)

  const updateAccountDialerSettings = useUpdateAccountDialerSettings()

  useEffect(() => {
    if (payload) {
      setLoading(false)
      const parsedSettings = parseAccountSettings(payload.account)
      setDialerSettings(parsedSettings)
      setSmsEnabled(parsedSettings['settings.messaging'])
      setSmsTemplatesEnabled(parsedSettings['settings.smsTemplates'])
      if (parsedSettings['settings.undeliverableSMSOwner']) {
        setUndeliverableSmsOwnerId(parsedSettings['settings.undeliverableSMSOwner']['id'])
      }
    }
  }, [payload, setLoading, setSmsEnabled, setSmsTemplatesEnabled, setUndeliverableSmsOwnerId])

  function modalClosed(enabled: boolean) {
    setModalOpened(false)
    setSmsEnabled(enabled)
  }

  async function onSave() {
    if (payload && dialerSettings) {
      setSaveDisabled(true)

      await updateAccountDialerSettings({
        body: {
          ...dialerSettings,
          ['settings.messaging']: smsEnabled,
          ['settings.smsTemplates']: smsTemplatesEnabled,
          ...(undeliverableSmsOwnerId
            ? { ['settings.undeliverableSMSOwner.id']: undeliverableSmsOwnerId }
            : { ['settings.undeliverableSMSOwner']: null }),
          ...serializeJsonIntoPlay({ featureVisibilities: [...payload.features] })
        }
      })
        .then(() => {
          toastr.success(SMS_SETTINGS_STR.saveSuccess)
          setSaveDisabled(false)
        })
        .catch(error => toastr.error(SMS_SETTINGS_STR.saveError(error)))
    }
  }

  return (
    <Container>
      <RdnaLoader<AccountDialerSettings> data={dialerSettings} loading={loading} top="150px">
        <SectionHeader
          headingText={SMS_SETTINGS_STR.heading}
          rightColumn={
            <RdnaButton
              text={SMS_SETTINGS_STR.saveButtonText}
              disabled={saveDisabled}
              processing={saveDisabled}
              onClick={() => onSave()}
            />
          }
        />
        <StyledCard width={'fill'}>
          <ToggleRowContainer>
            <SettingDescription>
              <IconHelper icon={MessagesIcon} iconSize={20} strokeWidth={2} variant="filled" />
              <RdnaText className={'rdna-text'}>{SMS_SETTINGS_STR.smsSettingText}</RdnaText>
              <RdnaTooltip data-testid="sms-tooltip" title={SMS_SETTINGS_STR.smsSettingTooltip}>
                <div>
                  <IconHelper icon={InfoIcon} />
                </div>
              </RdnaTooltip>
            </SettingDescription>
            <RdnaToggleSwitch
              data-testid={'sms-toggle'}
              disabled={(dialerSettings && !dialerSettings['settings.messagingFeaturesEnabled']) || isSupport}
              controlledState={{
                isChecked: smsEnabled,
                setIsChecked: () => (smsEnabled ? setSmsEnabled(false) : setModalOpened(true))
              }}
            />
          </ToggleRowContainer>
          {smsEnabled && (
            <RdnaSettingRow heading={SMS_SETTINGS_STR.undeliverableSmsOwnerSettingText} description={''}>
              <UserPicklist
                data-testid={'user-picklist'}
                accountId={accountId}
                value={undeliverableSmsOwnerId?.toString() || ''}
                onChoose={a => setUndeliverableSmsOwnerId(a)}
              />
            </RdnaSettingRow>
          )}
        </StyledCard>
        {smsEnabled && (
          <>
            <RdnaCard width={'fill'}>
              <ToggleRowContainer>
                <SettingDescription>
                  <IconHelper icon={SmsIcon} iconSize={18} variant="filled" />
                  <RdnaText className={'rdna-text'}>{SMS_SETTINGS_STR.smsTemplatesSettingText}</RdnaText>
                  <RdnaTooltip title={SMS_SETTINGS_STR.smsTemplatesSettingTooltip}>
                    <div>
                      <IconHelper icon={InfoIcon} />
                    </div>
                  </RdnaTooltip>
                </SettingDescription>
                <RdnaToggleSwitch
                  disabled={false}
                  isChecked={smsTemplatesEnabled}
                  onToggle={() => setSmsTemplatesEnabled(!smsTemplatesEnabled)}
                />
              </ToggleRowContainer>
            </RdnaCard>
            {smsTemplatesEnabled && <SmsTemplates accountId={accountId} smsSettingsPage={true} />}
          </>
        )}
        <AgreementModal open={modalOpened} onClose={() => modalClosed(false)} onConfirm={() => modalClosed(true)} />
      </RdnaLoader>
    </Container>
  )
}

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
`

const SettingDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing * 2}px;
`

const StyledCard = styled(RdnaCard)`
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`

export const ToggleRowContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
