BaseModel             = require('base/model')
BaseView              = require('base/view')
ConversationAiView    = require('./conversation-ai/view')
IntegrationsReactView = require('./integrations-react/view')
OverviewModel         = require('./overview/model')
OverviewView          = require('./overview/view')
SettingsModel         = require('./settings/model')
SettingsView          = require('./settings/view')
UserModel             = require('./model')
isProd                = require('../../../../src/config/legacy').isProd
ReactComponent        = require('src/ReactComponent').default
ReactCalendarView     = require('src/components/users/calendar').default

class UserTabsView extends BaseView
  template: require('./tabs-template')
  className: 'user-tabs'

  regions:
    tab: '.content-tabs'
    tab2: '.content-tabs2'

  ui:
    userName   : '.user-name'
    saveButton : '.save'

  bindings:
    'a[data-tab="conversation-ai"]' : 'toggle:caiTabEnabled'
    'a[data-tab="settings"]' : 'toggle:settingsTabEnabled'

  events:
    'click @ui.saveButton' : 'saveClicked'
    'click a[data-tab]'    : 'tabClicked'

  childViewEvents:
    'tab:show': 'showTab'

  computeds:
    settingsTabEnabled:
      deps: ['isIntelligentDialerLicenseActive']
      get: (isIntelligentDialerLicenseActive) -> isIntelligentDialerLicenseActive
    caiTabEnabled:
      deps: ['isConversationAILicenseActive']
      get: (isConversationAILicenseActive) ->
        @user().can('system:manage') and isConversationAILicenseActive and !isProd and !@user().isSalesSupport()

  initialize: (options) =>
    {@accountId, @userId, @qs} = options
    @userId = App.session.user.get('userId') if (@userId is 'me')
    @selected = options.tab ? 'overview'
    @tab = @getRegion('tab')
    @tab2 = @getRegion('tab2')

    @model = new UserModel(id: @userId)
    @model.fetch().done(=>
      @ui.userName.text(@model.get('displayName')) if typeof @ui.userName is 'object'
      @showTab(@selected)
      @applyBindings()
    )

  saveClicked: =>
    @tab.currentView.triggerMethod('save:clicked', @ui.saveButton[0])

  tabClicked: (e) =>
    tab = $(e.currentTarget).attr('data-tab')
    @showTab(tab)

  # FIXME: put this in a controller
  showTab: (name) =>
    @ui.saveButton.hide() if typeof @ui.saveButton is 'object'
    @selected = name
    if @selected is 'overview'
      fragment = ''
    else
      fragment = "/#{name}"
    $("a[data-tab='#{name}']").tab('show')

    if @selected == 'integrations' and @qs
      App.navigate("users/#{@userId}#{fragment}?#{@qs}")
    else
      App.navigate("users/#{@userId}#{fragment}")

    switch name

      when 'overview'
        model = new OverviewModel(userId: @userId)
        model
          .fetch()
          .done(=>
            @tab.show(new OverviewView(model: model, user: @model))
            @ui.saveButton.show() if typeof @ui.saveButton is 'object'
          )
          .fail(=>
            App.router.navigate('#users', {trigger: true})
          )

      when 'settings'
        model = new SettingsModel(id: @userId)
        model.fetch()
          .done(=>
            @tab.show(new SettingsView(model: model, button: @ui.saveButton))
            @ui.saveButton.show() if typeof @ui.saveButton is 'object'
          )
          .fail(=>
            App.router.navigate('#users', {trigger: true})
          )

      when 'calendar'
        @tab.show(new ReactComponent({
          component: ReactCalendarView,
          props: {
            calendarLicense: @model?.get('licenses')?.calendar
          }
        }))

      when 'integrations'
        if @qs?.params?.error
          toastr.warning(@qs.params.error)
          @qs.params.error = null

        @tab.show(new IntegrationsReactView())

      when 'conversation-ai'
        return unless @user().can('system:manage')
        @tab.show(new ConversationAiView(userId: @userId))

      else
        throw new Error("Unknown tab: #{name}")

module.exports = UserTabsView
