import styled from 'styled-components'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'

export const RoleDrawer = styled(RdnaDrawer)`
  > div {
    width: 70%;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: ${props => props.theme.spacing * 5}px;
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.spacing * 3}px;
  width: 100%;
`

export const SelectContainer = styled.div`
  width: ${({ theme }) => theme.spacing * 50}px;
  margin: ${({ theme }) => theme.spacing}px;
  > div {
    margin: 0;
  }
`
