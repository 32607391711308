var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-title\">\n  <h2>Overview</h2>\n  <div class=\"clearfix\"></div>\n</div>\n\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">License</label>\n    <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"licenseName") || (depth0 != null ? lookupProperty(depth0,"licenseName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseName","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":42}}}) : helper)))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Activation Date</label>\n    <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"activationDate") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":60}}}))
    + "</span>\n  </div>\n</div>\n<div class=\"clearfix\"></div>\n<br />\n\n<div class=\"x-title\">\n  <h2>License Usage</h2>\n  <div class=\"navbar-right\">\n    <button class=\"ladda-button btn btn-info add-request\" data-toggle=\"modal\" data-target=\"#license-request-modal\">Add Request</button>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n\n<table class=\"table table-striped account-mods\">\n  <thead>\n  <tr>\n    <th>Type</th>\n    <th>Allowed</th>\n    <th>Used</th>\n    <th>Remaining</th>\n  </tr>\n  </thead>\n  <tbody>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":39,"column":32},"end":{"line":39,"column":45}}}) : helper)))
    + ";dialerUsers=true;userStatus=active\">RingDNA Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"dialerUserLimit") || (depth0 != null ? lookupProperty(depth0,"dialerUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dialerUserLimit","hash":{},"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":27}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"dialerUserCount") || (depth0 != null ? lookupProperty(depth0,"dialerUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dialerUserCount","hash":{},"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":42,"column":27}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"dialerUsersRemaining") || (depth0 != null ? lookupProperty(depth0,"dialerUsersRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dialerUsersRemaining","hash":{},"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":43,"column":32}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":45}}}) : helper)))
    + ";adminUsers=true;userStatus=active\">Admin Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"adminUserLimit") || (depth0 != null ? lookupProperty(depth0,"adminUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"adminUserLimit","hash":{},"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":49,"column":26}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"adminUserCount") || (depth0 != null ? lookupProperty(depth0,"adminUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"adminUserCount","hash":{},"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":50,"column":26}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"adminUsersRemaining") || (depth0 != null ? lookupProperty(depth0,"adminUsersRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"adminUsersRemaining","hash":{},"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":51,"column":31}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#smart-numbers?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":55,"column":40},"end":{"line":55,"column":53}}}) : helper)))
    + "\">Smart Numbers</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"smartNumberLimit") || (depth0 != null ? lookupProperty(depth0,"smartNumberLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smartNumberLimit","hash":{},"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":57,"column":28}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"smartNumberCount") || (depth0 != null ? lookupProperty(depth0,"smartNumberCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smartNumberCount","hash":{},"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":58,"column":28}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"smartNumberRemaining") || (depth0 != null ? lookupProperty(depth0,"smartNumberRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smartNumberRemaining","hash":{},"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":59,"column":32}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":63,"column":32},"end":{"line":63,"column":45}}}) : helper)))
    + ";cai=true;userStatus=active\">Conversation AI Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"caiUserLimit") || (depth0 != null ? lookupProperty(depth0,"caiUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"caiUserLimit","hash":{},"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":65,"column":24}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"caiUserCount") || (depth0 != null ? lookupProperty(depth0,"caiUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"caiUserCount","hash":{},"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":66,"column":24}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"caiUsersRemaining") || (depth0 != null ? lookupProperty(depth0,"caiUsersRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"caiUsersRemaining","hash":{},"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":67,"column":29}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":71,"column":32},"end":{"line":71,"column":45}}}) : helper)))
    + ";nylasUsers=true\">Calendar Booking Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"nylasUserLimit") || (depth0 != null ? lookupProperty(depth0,"nylasUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nylasUserLimit","hash":{},"data":data,"loc":{"start":{"line":73,"column":8},"end":{"line":73,"column":26}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"nylasUserCount") || (depth0 != null ? lookupProperty(depth0,"nylasUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nylasUserCount","hash":{},"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":74,"column":26}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"nylasUserRemaining") || (depth0 != null ? lookupProperty(depth0,"nylasUserRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nylasUserRemaining","hash":{},"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":75,"column":30}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":45}}}) : helper)))
    + ";guidedSellingUsers=true\">Guided Selling Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"guidedSellingUserLimit") || (depth0 != null ? lookupProperty(depth0,"guidedSellingUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"guidedSellingUserLimit","hash":{},"data":data,"loc":{"start":{"line":81,"column":8},"end":{"line":81,"column":34}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"guidedSellingUserCount") || (depth0 != null ? lookupProperty(depth0,"guidedSellingUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"guidedSellingUserCount","hash":{},"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":82,"column":34}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"guidedSellingUsersRemaining") || (depth0 != null ? lookupProperty(depth0,"guidedSellingUsersRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"guidedSellingUsersRemaining","hash":{},"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":83,"column":39}}}) : helper)))
    + "</td>\n  </tr>\n  <tr>\n    <td>\n      <a href=\"#users?accountId="
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":87,"column":32},"end":{"line":87,"column":45}}}) : helper)))
    + ";momentsUsers=true\">Moments™ Users</a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"momentsUserLimit") || (depth0 != null ? lookupProperty(depth0,"momentsUserLimit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"momentsUserLimit","hash":{},"data":data,"loc":{"start":{"line":89,"column":8},"end":{"line":89,"column":28}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"momentsUserCount") || (depth0 != null ? lookupProperty(depth0,"momentsUserCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"momentsUserCount","hash":{},"data":data,"loc":{"start":{"line":90,"column":8},"end":{"line":90,"column":28}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"momentsUsersRemaining") || (depth0 != null ? lookupProperty(depth0,"momentsUsersRemaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"momentsUsersRemaining","hash":{},"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":91,"column":33}}}) : helper)))
    + "</td>\n  </tr>\n  </tbody>\n</table>\n\n<div class=\"modal-container\"></div>\n";
},"useData":true});