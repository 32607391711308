BaseGrid                 = require('base/grid')
BaseView                 = require('base/view')
BackgridCell             = require('helpers/backgrid-cell')
OutboundNumberCollection = require('./collection')
OutboundNumberModel      = require('./model')
OutboundNumberModal      = require('./modals/view')

class OutboundNumbersView extends BaseView
  template: require('./template')

  className: 'outbound-numbers'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add-outbound-number' : 'showOutboundNumberModal'

  initialize: (options) ->
    {@userModel} = options
    @collection = new OutboundNumberCollection()
    @modal = @getRegion('modal')

  onRender: ->
    @collection
      .fetch(data: userId: @userModel.get('id'))
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  selectedCell: =>
    user = @userModel
    sessionUser = @user()
    class extends Backgrid.Cell
      events:
        'change .is-default': 'selectOutbound'
      render: =>
        сhecked = if @model.get('isDefault') then 'checked' else ''
        disabled = if user.get('id') isnt sessionUser.get('userId') then 'disabled' else ''
        html = "<input class='is-default' type='checkbox' #{сhecked} #{disabled}>"
        @listenTo(@model.collection, 'selected', => @render())
        $(@el).html(html)
        @
      selectOutbound: (e) ->
        isDefault = e.target.checked
        outboundNumber = new OutboundNumberModel(
          id: @model.get('id')
          isDefault: isDefault
          userId: user.get('id')
        )
        outboundNumber
          .persist()
          .done( =>
            for model in @model.collection.models
              previous = model if model.get('isDefault')
              model.set(isDefault: false) if model.get('id') isnt @model.get('id') and isDefault
            @model.set(isDefault: isDefault)
            @model.collection.trigger('selected') if isDefault
            previousPart = if previous then previous.get('name') else 'Default'
            currentPart = if isDefault then @model.get('name') else 'Default'
            toastr.info("You’ve changed your Outbound Number from #{previousPart} to #{currentPart}")
          )
          .fail( =>
            @model.set(isDefault: not isDefault)
            @render()
          )

  deleteCell: =>
    user = @userModel
    class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a.delete': 'delete'
      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          if ok
            @model.destroy(data: userId: user.get('id'))
            @column.channel?.trigger('remove')
          true
        )
      render: ->
        $(@el).html('''
            <a class="delete">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          ''')
        @

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    columns = [
      { name: '',                            label: 'Selected',      cell: @selectedCell() }
      { name: 'number',                      label: 'Number',        cell: 'string' }
      { name: 'name',                        label: 'Label',         cell: 'string' },
      { name: 'outboundNumber.verifiedDate', label: 'Date Verified', cell: datetime },
      { name: 'outboundNumber.sid',          label: 'Twilio SID',    cell: 'string' },
      { name: 'id',                          label: 'ID',            cell: 'string' }
      { name: '',                            label: 'Delete',        cell:  @deleteCell() }
    ]


    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No outbound numbers found.'
    )

  showOutboundNumberModal: =>
    @showChildView('modal', new OutboundNumberModal(
      userId          : @userModel.get('id')
      outboundNumbers : @collection
    ))

module.exports = OutboundNumbersView
