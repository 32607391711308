import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function CheckmarkIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M7.282 13.425a.5.5 0 0 0-.707.707l2.829 2.828a.5.5 0 0 0 .707 0l7.071-7.07a.5.5 0 0 0-.707-.708l-6.718 6.717-2.475-2.474Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckmarkIcon
