import { Colors } from '../constants/colors'
import Pdf from '../assets/icons/svg/Pdf'
import Speaker from '../assets/icons/svg/Speaker'
import Video from '../assets/icons/svg/Video'
import CallList from '../assets/icons/svg/CallList'
import React from 'react'

export const ACCEPTED_TWILIO_CONTENT_TYPES_MMS = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/bmp',
  'image/tiff',
  'image/gif',
  'video/quicktime',
  '.mov',
  'video/mp4',
  'video/3gpp',
  '.3gp',
  'audio/mpeg',
  'audio/wav',
  'application/pdf',
  'application/rtf',
  'text/vcard',
  'text/x-vcard',
  '.vcf',
  'text/csv'
]

export const urlMock =
  'https://ringdna-dev-mms.s3.us-west-2.amazonaws.com/local/media/10000042/665/efa32292-4d80-4653-acff-ee31ed12ea90/Cat12.jpg'

export const signedUrlMock =
  'https://ringdna-dev-mms.s3.us-west-2.amazonaws.com/local/media/10000042/665/efa32292-4d80-4653-acff-ee31ed12ea90/Cat12.jpg?X-Amz-Security-Token=FwoGZXIvYXdzEOj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDAGGHoIQS2DKhMLP9iKCAbpr4WMUHLxwAfodYc36uQ1PJPs8%2F2YgYLk4gf1xOCHkWeVnl%2B%2F41LhX1LXUhjRNvrBhE5zN61V0%2Fu7X0yxXZQWjEHlVPPgSn0h8QOk9tayl07n1xTGuW20VjTRsbtvDSZtFFDN4%2BOBllt7MoyqNPxCmOcqoDzkcyDgO4jzWkMnkm5Iop8jTsAYyKE%2BpgnIYK7HnnTK44OxfQNzCD%2Fgf2u6n8lvOfTtGkLNSItb3Ur1xD2U%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240409T081646Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=ASIARQLPWXOOOQH5LSRC%2F20240409%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=d3ada41eda2220a299aa72d289425b34e9fd51dd4aa65d5adc03bcf89221394a'

export const getFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`
  } else if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(1)} KB`
  } else if (size >= 1048576) {
    return `${(size / 1048576).toFixed(1)} MB`
  }
}

export const loadFile = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl, { cache: 'no-cache' })
    const data = await response.blob()
    const name = (fileUrl.split('/').pop()?.split('?').shift() || '').replace(/%2B/ig, ' ')
    return new File([data], decodeURI(decodeURI(name)), { type: data.type })
  } catch (error) {
    console.error(error?.message || error)
  }
}

export const initIcon = (type: string) => {
  const props = { color: Colors.N100, size: 24 }
  if (type == 'application/pdf') {
    return <Pdf {...props} />
  } else if (type.startsWith('audio/')) {
    return <Speaker {...props} />
  } else if (type.startsWith('video/')) {
    return <Video {...props} />
  } else {
    return <CallList {...props} />
  }
}
