import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function InsightsIcon({ size = 18, color = Colors.N100, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" data-testid="insights-icon">
      <rect width={strokeWidth} height={18} rx={0.5} fill={color} />
      <rect height={strokeWidth} width={18} y={18 - strokeWidth} fill={color} />
      <path
        d="M3 11l3 1 3-4 3 1 3-8.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default InsightsIcon
