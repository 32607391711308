import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import React, { useEffect, useState } from 'react'
import { SelectEvent, User } from '../types'
import { useUserRoleUpdate } from '../api'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import styled from 'styled-components'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'

export type UserReassignModalProps = {
  user: User | undefined
  open: boolean
  onClose: () => void
  currentRoleId: number
  refetchUsers: any
  roleOptions: { label: string; value: number }[]
}

export default function ReassignUserModal({
  open,
  onClose,
  user,
  currentRoleId,
  refetchUsers,
  roleOptions
}: UserReassignModalProps) {
  const { error: showError } = useRdnaNotification()
  const [roleId, setRoleId] = useState<number>(currentRoleId)
  const [, error, loading, refetch] = useUserRoleUpdate(
    { meta: { userId: user?.id }, json: { roleId: roleId } },
    { paused: true }
  )

  useEffect(() => {
    if (error) showError(getErrorMessage(error))
  }, [error, showError])

  async function handleReassign() {
    await refetch()
    onClose()
    refetchUsers()
  }

  const onRoleChange = (event: SelectEvent) => {
    setRoleId(event.target.value)
  }

  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={loading ? 'Saving...' : 'Save'}
      confirmButtonDisabled={!!loading || roleId === currentRoleId}
      heading={`Reassign User Role`}
      subHeading={'To reassign this user to a different role, please select an option from the dropdown.'}
      onClose={() => {
        onClose()
        setRoleId(currentRoleId)
      }}
      onConfirm={handleReassign}
      maxWidth={'md'}
    >
      <RdnaFormLayout container spacing={3}>
        <RdnaFormLayout xs={6}>
          <UserName variant="body1">{`${user?.displayName}`}</UserName>
        </RdnaFormLayout>
        <RdnaFormLayout xs={6}>
          <RdnaSelect
            name={'reassign'}
            options={roleOptions || []}
            value={roleId}
            onChange={e => onRoleChange(e as SelectEvent)}
          ></RdnaSelect>
        </RdnaFormLayout>
      </RdnaFormLayout>
    </RdnaModal>
  )
}

const UserName = styled(RdnaText)`
  line-height: ${({ theme }) => theme.spacing * 6}px;
  vertical-align: middle;
`
