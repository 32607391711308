import RdnaCard from '../../RdnaCard'
import RdnaText from '../../RdnaText'
import React from 'react'
import styled from 'styled-components'

type Props = {
  starterQuestions?: string[]
  onSelect?: (question: string) => void
}

export default function StarterQuestions({ starterQuestions, onSelect }: Props) {
  return (
    <Questions>
      {starterQuestions?.map((question, index) => (
        <RdnaCard key={index} width={'fill'} onClick={() => onSelect && onSelect(question)}>
          <CardContent>
            <RdnaText variant={'body2'} bold color={'link'}>
              {question}
            </RdnaText>
          </CardContent>
        </RdnaCard>
      ))}
    </Questions>
  )
}

const Questions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing * 2}px;
`

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
