import { createUseFetch } from '@ringdna/client/src/react/createUseFetch'

export type DataListItem = {
  text: string
  value: string
  id?: number
  name?: string
  email?: string
  [key: string]: string | number | undefined
}

type UseDataPicklistParams = {
  meta: {
    searchUrl: string
  }
  query: {
    accountId?: string
    'pager.currentPage'?: number
    'pager.pageSize'?: number
    name: string
    search?: string
    criteria?: string
    field?: string
    userStatus?: string
  }
}

export const useDataPicklist = createUseFetch<DataListItem[], UseDataPicklistParams>({
  key: 'get-data-list-hook',
  path: params => `${params.meta.searchUrl}`
})

const DATA_PICKLIST_APIS: Record<string, typeof useDataPicklist> = {}

export const useDataPicklistApiFactory = (api: string) => {
  if (!DATA_PICKLIST_APIS[api]) {
    DATA_PICKLIST_APIS[api] = createUseFetch<DataListItem[], UseDataPicklistParams>({
      key: `get-data-list-hook-${api}`,
      path: params => `${params.meta.searchUrl}`,
      api
    })
  }
  return DATA_PICKLIST_APIS[api]
}
