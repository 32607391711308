var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"cdm-delete-confirmation-modal\" tabindex=\"-1\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <br/>\n        <div class=\"text-center\">\n          <img src=\"images/cdm/gdpr-certified-white.svg\" />\n        </div>\n        <br/>\n        <h1 class=\"text-center\">User Deletion Request</h1>\n        <br/><br/>\n        <p class=\"text-center\">By selecting Delete Customer Data you are removing all call and message data for salesforce id ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"salesforceId") || (depth0 != null ? lookupProperty(depth0,"salesforceId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"salesforceId","hash":{},"data":data,"loc":{"start":{"line":12,"column":127},"end":{"line":12,"column":143}}}) : helper)))
    + "). This action cannot be undone.</p>\n        <br/><br/>\n        <div class=\"text-center\">\n          <button type=\"button\" class=\"btn btn-danger delete-confirm\">Delete Customer Data</button>\n        </div>\n        <br/>\n        <div class=\"text-center\">\n          <a data-dismiss=\"modal\">Cancel</a>\n        </div>\n        <br/>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});