var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <button type=\"button\" class=\"close\"><span>&times;</span></button>\n  <h4 class=\"modal-title\">Add Basic Call Tracking</h4>\n</div>\n<div class=\"modal-body\">\n  <p>Select channels to link to this domain: <label class=\"domain\"></label></p>\n  <table class=\"table btn-group-justified table-basic\">\n    <tbody><tr class=\"center\">\n      <td class=\"img-thumbnail logos\" data-name=\"Google\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/google.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":106}}}))
    + "\" data-name=\"Google\" alt=\"Google\" title=\"Google\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"Google AdWords\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/adwordsg.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":75},"end":{"line":10,"column":116}}}))
    + "\" data-name=\"Google AdWords\" alt=\"AdWords\" title=\"AdWords\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"Yahoo\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/yahoo.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":66},"end":{"line":11,"column":104}}}))
    + "\" data-name=\"Yahoo\" alt=\"Yahoo\" title=\"Yahoo\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"Bing\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/bing.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":102}}}))
    + "\" data-name=\"Bing\" alt=\"Bing\" title=\"Bing\"></td>\n    </tr><tr class=\"center\">\n      <td class=\"img-thumbnail logos\" data-name=\"facebook.com\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/facebook.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":114}}}))
    + "\" data-name=\"facebook.com\" alt=\"Facebook\" title=\"Facebook\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"twitter.com\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/twitter.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":15,"column":72},"end":{"line":15,"column":112}}}))
    + "\" data-name=\"twitter.com\" alt=\"Twitter\" title=\"Twitter\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"linkedin.com\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/linkedin.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":16,"column":73},"end":{"line":16,"column":114}}}))
    + "\" data-name=\"linkedin.com\" alt=\"linkedin.com\" title=\"Linkedin\"></td>\n      <td class=\"img-thumbnail logos\" data-name=\"instagram.com\"><img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-tracking/instagram.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":74},"end":{"line":17,"column":116}}}))
    + "\" data-name=\"instagram.com\" alt=\"Instagram\" title=\"Instagram\"></td></tr>\n    </tbody>\n</table>\n  <div class=\"form-group col-md-12\">\n    <label>Add a custom channel: </label>\n    <div class=\"col-md-8\">\n      <div class=\"col-md-10\">\n        <input type=\"text\" class=\"form-control channel\" placeholder=\"ex: pinterest.com\"/>\n      </div>\n      <button class=\"btn bbm-button add-channel\">Add</button>\n    </div>\n  </div>\n  <div class=\"clearfix\"></div>\n  <div class=\"grid backgrid-container main-grid channels\"></div>\n</div>\n<div class=\"modal-footer\">\n  <a href=\"#\" class=\"bbm-button previous stepPrevious left\">Previous</a>\n  <a href=\"#\" class=\"bbm-button next btn-success stepNext\">Next</a>\n</div>";
},"useData":true});