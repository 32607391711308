import { ApiConfig, MethodOptions } from './constants'

class NoSetOnExistingKeysMap<K, V> extends Map<K, V> {
  set(key: K, value: V) {
    if (this.has(key)) {
      throw new Error(`Key "${key}" cannot be overriden`)
    }
    super.set(key, value)
    return this
  }
}

// key is anything, value is config for the given api
const apiConfigMap = new Map<any, ApiConfig>()

// key is a resourceId, value is the number of active uses
const resourceUseCounts = new Map<string, number>()

// key is a requestId, value is a Set of requestIds
const requestDependencyMap = new Map<string, Set<string>>()

// @NOTE this ideally would not be a singleton, maybe can be stored on the store itself?
const methodOptionsMap = new NoSetOnExistingKeysMap<string, MethodOptions<any, any>>()
export { apiConfigMap, resourceUseCounts, requestDependencyMap, methodOptionsMap }
