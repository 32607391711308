import React, { useEffect, useMemo } from 'react'
import { useSchedulingPages } from '../../../../store/api/personal-calendar-api'
import { useActiveUser } from '../../../../hooks/useActiveUser'
import Loader from '@ringdna/common/src/components/RdnaLoader'
import useToast from '../../../common/hooks/useToast'
import SchedulingEditor from './SchedulingEditor'

export default function SchedulingPages() {
  const toastr = useToast()
  const { activeUserId } = useActiveUser()
  const useRequestParam = useMemo(
    () => ({
      query: {
        userId: activeUserId
      }
    }),
    [activeUserId]
  )
  const [meetingTypesPayload, mtError, mtLoading, refetchMeetingTypes] = useSchedulingPages(useRequestParam)

  useEffect(() => {
    const errorMsg = `${mtError ? mtError.message : ''}`
    if (errorMsg.length > 2) toastr.error(errorMsg)
  }, [mtError, toastr])

  return (
    <Loader
      data={meetingTypesPayload && [meetingTypesPayload]}
      loading={!!mtLoading}
      top="0px"
    >
      {meetingTypesPayload && (
        <SchedulingEditor
          userId={activeUserId}
          schedulingPagesPayload={meetingTypesPayload}
          refetchSchedulingPages={refetchMeetingTypes}
        />
      )}
    </Loader>
  )
}
