import React, { useContext, useState, useMemo } from 'react'
import { orderBy } from 'lodash-es'
import { AccountIntegration, InitIntegrationsContext } from '../../model'
import { IntegrationsActions, integrationItems } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { IntegrationsContext } from '../../store/IntegrationsProvider'
import {
  useDeleteIntegrationRequest,
  useDeleteZoomIntegrationRequest,
  useOauthUrl
} from '../../../../../store/api/user-integrations-api'
import { RdnaTable, RdnaTableHead, RdnaTableCell, OrderByProps } from '@ringdna/common/src/components/RdnaTable'
import ActiveIntegrationsItem from './ActiveIntegrationsItem'
import useToast from '../../../../common/hooks/useToast'
import UiCenteredBox from '@ringdna/common/src/components/layout/UiCenteredBox'
import CircularProgress from '@mui/material/CircularProgress'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

type Props = {
  integrationsLoading: boolean
}

export default function ActiveIntegrations({ integrationsLoading }: Props) {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { activeIntegrations, activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [sortBy, setSortBy] = useState<OrderByProps | undefined>()
  const [integrationToDelete, setIntegrationToDelete] = useState<AccountIntegration | undefined>()
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false)
  const [deletePending, setDeletePending] = useState<boolean>(false)
  const [reconnectPending, setReconnectPending] = useState<IntegrationProviderTypes | null>()
  const sortProps = activeIntegrations.length > 1 ? { sortBy, setSortBy } : {}
  const deleteZoomIntegrationRequest = useDeleteZoomIntegrationRequest()
  const deleteIntegrationRequest = useDeleteIntegrationRequest()
  const getOauthUrl = useOauthUrl()
  const isReconnectFlagEnabled = isFlagged(flags.NYLAS_RECONNECT, false)

  const sortedIntegrations: AccountIntegration[] = useMemo(() => {
    return !sortBy ? activeIntegrations : orderBy(activeIntegrations, sortBy.key, sortBy.order)
  }, [sortBy, activeIntegrations])

  const openDeleteConfirmModal = (integrationToDelete: AccountIntegration) => {
    setIntegrationToDelete(integrationToDelete)
    setDeleteConfirmModalOpen(true)
  }

  const onDeleteConfirmModalClose = (userConfirmed: boolean) => {
    if (userConfirmed && !deletePending) deleteIntegration()
    setDeleteConfirmModalOpen(false)
  }

  const deleteIntegration = async () => {
    if (integrationToDelete && integrationToDelete.id) {
      const apiRequest =
        integrationToDelete.provider === IntegrationProviderTypes.ZOOM
          ? deleteZoomIntegrationRequest
          : deleteIntegrationRequest
      setDeletePending(true)
      try {
        await apiRequest({ meta: { id: integrationToDelete.id } })
        dispatch({ type: IntegrationsActions.DELETE_INTEGRATION, payload: integrationToDelete.provider })
        setDeletePending(false)
      } catch (error) {
        toastr.error(error.message)
        setDeletePending(false)
      }
    }
  }

  const manageZoomIntegration = () => {
    dispatch({ type: IntegrationsActions.DISPLAY_ZOOM_USER_MANAGER, payload: true })
  }

  const reconnectIntegration = async (integration: AccountIntegration) => {
    try {
      setReconnectPending(integration.provider)
      if (!isReconnectFlagEnabled) {
        await deleteIntegrationRequest({ meta: { id: integration.id } })
        dispatch({ type: IntegrationsActions.DELETE_INTEGRATION, payload: integration.provider })
        setReconnectPending(null)
      }
      if (integration.provider === IntegrationProviderTypes.EXCHANGE) {
        const res = await getOauthUrl({
          query: {
            userId: activeUser.userId,
            service: integration.provider.toLowerCase(),
            reconnect: isReconnectFlagEnabled ? true : undefined,
            email: isReconnectFlagEnabled ? integration.email : undefined,
            nylasId: isReconnectFlagEnabled ? integration.nylasId : undefined
          }
        })
        if (res?.success?.payload) {
          window.location.href = res.success.payload.oauth_url
        }
      } else {
        dispatch({ type: IntegrationsActions.SET_SELECTED_INTEGRATION, payload: integration })
        setReconnectPending(null)
      }
    } catch (error) {
      toastr.error(error.message)
      setReconnectPending(null)
    }
  }

  return (
    <>
      <RdnaModal
        open={deleteConfirmModalOpen}
        heading="Warning"
        onClose={() => onDeleteConfirmModalClose(false)}
        onConfirm={() => onDeleteConfirmModalClose(true)}
        confirmButtonText="Remove"
      >
        {integrationToDelete && (
          <RdnaText>{`You are about to remove the ${
            integrationItems[integrationToDelete.provider].label
          } integration from your ringDNA account.`}</RdnaText>
        )}
      </RdnaModal>
      <RdnaTable $shadedRows>
        <RdnaTableHead>
          <RdnaTableCell width="90px" sortKey="provider" {...sortProps}>
            Connection
          </RdnaTableCell>
          <RdnaTableCell width="130px" />
          <RdnaTableCell sortKey="email" {...sortProps}>
            User
          </RdnaTableCell>
          <RdnaTableCell sortKey="status" {...sortProps}>
            Status
          </RdnaTableCell>
          <RdnaTableCell>Actions</RdnaTableCell>
        </RdnaTableHead>
        {!integrationsLoading &&
          sortedIntegrations.map(integration => (
            <ActiveIntegrationsItem
              key={integration.provider}
              integration={integration}
              reconnectIntegration={reconnectIntegration}
              removeIntegration={openDeleteConfirmModal}
              manageZoomIntegration={manageZoomIntegration}
              areButtonsDisabled={reconnectPending === integration.provider || deletePending || integrationsLoading}
            />
          ))}
        {integrationsLoading && (
          <UiCenteredBox minHeight="80px">
            <CircularProgress />
          </UiCenteredBox>
        )}
      </RdnaTable>
    </>
  )
}
