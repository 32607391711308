import styled from 'styled-components'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'

export const BlockBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => 5 * theme.spacing}px;
`
export const SectionSearch = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => 4 * theme.spacing}px;
  margin-top: ${({ theme }) => -3 * theme.spacing}px;
`

export const StyledCard = styled(RdnaCard)`
  margin: ${({ theme }) => 4 * theme.spacing}px ${({ theme }) => 3 * theme.spacing}px 0 0;
  &:nth-child(3n) {
    margin: 0;
  }
  width: 32%;
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`

export const CardDescription = styled.div`
  height: ${({ theme }) => 6 * theme.spacing}px;
  margin-bottom: ${({ theme }) => 3 * theme.spacing}px;
  text-align: center;
`
