import React from 'react'
import styled from 'styled-components'
import { formatMessage } from './utils'
import { ChatMessage } from '../index'
import { Colors } from '../../../constants/colors'
import RdnaAvatar from '../../RdnaAvatar'
import RdnaCard from '../../RdnaCard'
import RdnaText from '../../RdnaText'
import RevenueLogo from '../../../assets/logos/Revenue'
import TypingIndicator from './TypingIndicator'

export default function ChatBubble({ message, loading }: { message: ChatMessage; loading?: boolean }) {
  const displayMessage = typeof message.message === 'string' ? formatMessage(message.message) : message.message
  return (
    <Line $isAgent={message.isAgent}>
      <div>
        <Speaker $isAgent={message.isAgent}>
          {message.isAgent ? (
            <RdnaAvatar name={message.owner} />
          ) : (
            <LogoHolder>
              <RevenueLogo iconOnly size={14} />
            </LogoHolder>
          )}
          <RdnaText variant={'body1'} bold>
            {message.isAgent ? 'You' : 'Revenue AI'}
            {loading && <TypingIndicator />}
          </RdnaText>
        </Speaker>
        {!loading && (
          <Message padding={'dense'} backgroundcolor={Colors.N10} bordercolor={Colors.N10}>
            {displayMessage}
          </Message>
        )}
      </div>
    </Line>
  )
}

const Line = styled.div<{ $isAgent: boolean }>`
  display: flex;
  flex-direction: ${({ $isAgent }) => ($isAgent ? 'row-reverse' : 'row')};
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${({ $isAgent }) => ($isAgent ? 'flex-end' : 'flex-start')};
    gap: ${({ theme }) => theme.spacing}px;
  }
`

const Message = styled(RdnaCard)`
  max-width: 100%;
  p {
    margin: 0;
    padding: 0;
  }
`
const Speaker = styled.div<{ $isAgent: boolean }>`
  align-items: center;
  gap: ${({ theme }) => theme.spacing * 2}px;
  display: flex;
  flex-direction: ${({ $isAgent }) => ($isAgent ? 'row-reverse' : 'row')};
`

const LogoHolder = styled.div`
  width: ${({ theme }) => theme.spacing * 4}px;
  height: ${({ theme }) => theme.spacing * 4}px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
`
