import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const PhraseBubble = ({ color = Colors.N100, size = 30 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47059 4H15.5294C19.103 4 22 6.89698 22 10.4706C22 14.0442 19.103 16.9412 15.5294 16.9412L12.5882 19.8824V16.9412H8.47059C4.89698 16.9412 2 14.0442 2 10.4706C2 6.89698 4.89698 4 8.47059 4Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M14.1303 10.475C14.4336 10.5333 14.6553 10.6733 14.7953 10.895C14.9469 11.105 15.0228 11.3266 15.0228 11.56C15.0228 11.8633 14.9178 12.1258 14.7078 12.3475C14.5094 12.5691 14.2469 12.68 13.9203 12.68C13.5819 12.68 13.3019 12.5633 13.0803 12.33C12.8586 12.085 12.7478 11.7525 12.7478 11.3325C12.7478 11.1341 12.7769 10.9183 12.8353 10.685C12.8936 10.44 12.9694 10.195 13.0628 9.94997C13.1678 9.6933 13.2844 9.4483 13.4128 9.21497C13.5411 8.96997 13.6694 8.74247 13.7978 8.53247H14.7078L14.1303 10.475ZM9.78777 10.475C10.0911 10.5333 10.3128 10.6733 10.4528 10.895C10.6044 11.105 10.6803 11.3266 10.6803 11.56C10.6803 11.8633 10.5753 12.1258 10.3653 12.3475C10.1669 12.5691 9.90444 12.68 9.57777 12.68C9.23944 12.68 8.95944 12.5633 8.73777 12.33C8.51611 12.085 8.40527 11.7525 8.40527 11.3325C8.40527 11.1341 8.43444 10.9183 8.49277 10.685C8.55111 10.44 8.62694 10.195 8.72027 9.94997C8.82527 9.6933 8.94194 9.4483 9.07027 9.21497C9.19861 8.96997 9.32694 8.74247 9.45527 8.53247H10.3653L9.78777 10.475Z"
      fill={color}
    />
  </svg>
)

export default PhraseBubble
