import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function SupervisorDashboardIcon({ color = Colors.N100, size = 60 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="supervisor-dashboard-icon">
      <mask id="path-2-inside-1_9043_76187" fill={color}>
        <rect x={5.48779} y={12.5} width={48.7805} height={35} rx={0.5} />
      </mask>
      <rect
        x={5.48779}
        y={12.5}
        width={48.7805}
        height={35}
        rx={0.5}
        stroke={color}
        strokeWidth={2}
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_9043_76187)"
      />
      <path d="M7.92676 20H51.8292" stroke={color} />
      <path d="M15.2439 20V45" stroke={color} />
      <path d="M48.7805 26.25L19.5122 26.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M48.7805 32.5L19.5122 32.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M48.7805 40L41.4634 40" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.8048 40L30.4877 40" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.8292 40L19.5121 40" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
