BackgridCell    = require('helpers/backgrid-cell')
BackgridRow     = require('helpers/backgrid-row')
BaseView        = require('base/view')
PagedCollection = require('base/paged-collection')
PagerView       = require('widgets/pager/view')
ReactComponent  = require('src/ReactComponent').default
CreateBtn       = require('src/components/common/buttons/CreateBtn').default

class UsersPagedCollection extends PagedCollection
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    for user in response.results when response.results.length
      user['userStatus'] = if not user['isDeleted'] then 'Active' else 'Deleted'
      result.push(user)
    result

class UserListView extends BaseView
  template: require('./template')
  className: 'user-search'

  regions:
    grid   : 'div.grid'
    pager  : 'span.pager'
    addBtn : '.add-new-btn'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  ui:
    username     : 'input.user-name'
    email        : 'input.email'
    salesforceId : 'input.salesforce-id'
    search       : 'button.search'
    userStatus   : 'select.user-status'

  initialize: (options) ->
    @qs = options.qs
    @qs.setFragment('users')

  onRender: =>
    isUserAccountSelected = @user().get('accountId')?.toString() is @user().getSelectedSupportAccountId()?.toString()
    @showChildView('addBtn', new ReactComponent({
      component: CreateBtn,
      props: {
        onClick: @onCreateNew
        text: 'Add New'
      }
    })) if (@user().can('users:search:me') or @user().can('users:search:*')) and isUserAccountSelected
    @qs.bind(
      email        : @ui.email
      salesforceId : @ui.salesforceId
      username     : @ui.username
      userStatus   : @ui.userStatus
    )

    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    return unless @validate()

    collection = new UsersPagedCollection(
      page: @qs.get('page')
      queryParams:
        name         : @ui.username.val()
        email        : @ui.email.val()
        salesforceId : @ui.salesforceId.val()
        accountId    : @getAccountORNull()
        userStatus   : @ui.userStatus.val()
        cai          : @qs.get('cai')?
        adminUsers   : @qs.get('adminUsers')
        dialerUsers  : @qs.get('dialerUsers')
        guidedSellingUsers: @qs.get('guidedSellingUsers')?
        nylasUsers   : @qs.get('nylasUsers')?
        momentsUsers : @qs.get('momentsUsers')?
      url: 'users'
    )

    @showChildView('grid', @buildGrid(collection))
    disableDownload = @user().isSupport() and not @user().getSelectedSupportAccountId()
    @showChildView('pager', new PagerView(collection: collection, qs: @qs, disableDownload: disableDownload, reactExport: true))
    collection.fetch(reset: true)

  buildGrid: (collection) ->
    acctCell = BackgridCell.accountCell('account.id', 'account.name')

    userCell = BackgridCell.usersCell('id', 'displayName')

    datetime = BackgridCell.dateTime()

    cols = [
      {label: 'Status',        name: 'userStatus',   cell: 'string'}
      {label: 'Name',          name: 'displayName',  cell: userCell}
      {label: 'Account',       name: 'account.name', cell: acctCell, renderable: @user().accountPickerRenderable()}
      {label: 'Email',         name: 'email',        cell: 'string'}
      {label: 'Salesforce ID', name: 'sfUserId',     cell: 'string'}
      {label: 'Extension',     name: 'extension',    cell: 'string'}
      {label: 'Created Date',  name: 'createdDate',  cell: datetime}
      {label: 'Last Login',    name: 'loginDate',    cell: datetime}
      {label: 'ID',            name: 'id',           cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No users found.'
      row        : BackgridRow.deleted()
    )

  validate: =>
    if @ui.username.val() and @ui.username.val().length < 3
      toastr.warning('User name must be at least three characters.')
      return false

    if @ui.email.val() and @ui.email.val().length < 3
      toastr.warning('Email must be at least three characters.')
      return false

    if @ui.salesforceId.val() and @ui.salesforceId.val().length < 3
      toastr.warning('Salesforce ID must be at least three characters.')
      return false

    true

  onCreateNew: ->
    App.router.navigate('#user/salesforce-users', {trigger: true})

module.exports = UserListView
