import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ActivityMetrics = ({ color = Colors.N100, size = 30 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M4.23926 19.3911V21.5029"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.982 15.0092C5.982 15.9595 5.19009 16.7514 4.23978 16.7514C3.28948 16.7514 2.49756 15.9595 2.49756 15.0092C2.49756 14.0588 3.28948 13.2669 4.23978 13.2669C5.19009 13.2669 5.982 14.0061 5.982 15.0092Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M19.7607 8.67395V12V14.3758V16.7515V19.1273V21.503"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5035 4.23911C21.5035 5.1894 20.7116 5.98131 19.7613 5.98131C18.811 5.98131 18.019 5.1894 18.019 4.23911C18.019 3.2888 18.811 2.49689 19.7613 2.49689C20.7116 2.49689 21.5035 3.2888 21.5035 4.23911Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M12 14.1117V16.7514V19.1271V21.5029"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7427 9.72972C13.7427 10.68 12.9508 11.4719 12.0005 11.4719C11.0502 11.4719 10.2583 10.68 10.2583 9.72972C10.2583 8.7794 11.0502 7.98749 12.0005 7.98749C12.9508 7.98749 13.7427 8.72661 13.7427 9.72972Z"
      stroke={color}
      strokeMiterlimit="10"
    />
  </svg>
)

export default ActivityMetrics
