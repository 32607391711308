BaseModel = require('base/model')

class SubjectModel extends BaseModel
  urlRoot: 'taskSubjects'

  defaults:
    subject: ''

  validate: (attrs) ->
    if not attrs.subject.trim()
      return 'Please enter a task or note subject.'
    return

module.exports = SubjectModel