StepModel = require('../../model')

class CalloutStepModel extends StepModel

  defaults:
    type: 'Callout'
    info: 'Invoke an external system'
    fixedPlaceholders: [ '_' ]
    leaf: false
    args:
      calloutInputs: ''
      calloutValues: ''

  path: ->
    @args()['path']

module.exports = CalloutStepModel
