import React, { useCallback, useState, useMemo } from 'react'
import {Body} from '../../../styles'
import { Disposition, OptionCategory, DispositionProps } from '../../../types'
import {NO_RESULT_STATE, MANAGE_DISPOSITIONS_STR, initDisposition, optionsOutcomeCategory} from './const'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import ManageCallDispositionsModal from './ManageCallDispositionsModal'
import ConfirmSyncCallDispositionModal from './ConfirmSyncCallDispositionModal'
import ConfirmCallDispositionDeleteModal from './ConfirmCallDispositionDeleteModal'
import { TableFilterProps } from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { GenericObject } from '@ringdna/common/types/generic'
import { useUser } from '../../../../common/services/UserContext'


export default function ManageCallDispositionsTable({ accountId, accountSettings, setAccountSettings, dispositions, refetchDispositions }: DispositionProps) {
  const { isSupport } = useUser()
  const [disposition, setDisposition] = useState<Disposition>(initDisposition)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false)
  const callDispositionsColumns = [
    {
      Header: 'Disposition Name',
      accessor: MANAGE_DISPOSITIONS_STR.name
    },
    {
      Header: 'Outcome Category',
      accessor: MANAGE_DISPOSITIONS_STR.category,
      disableFilters: false,
      Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
        return (
          <>
            <RdnaAutocomplete
              id={'outcome-category-filter'}
              options={optionsOutcomeCategory}
              placeholder={'Filter by Outcome Category'}
              isOptionEqualToValue={(option: OptionCategory, value: OptionCategory) => {
                return option.value === value.value
              }}
              onChange={(event: React.SyntheticEvent<Element, Event>, option: OptionCategory) => {
                // @ts-ignore
                filterProps.setFilter(filterProps.column.id, option?.value)
              }}
            />
          </>
        )
      }
    },
    {
      Header: 'Assigned Teams (Inbound)',
      accessor: MANAGE_DISPOSITIONS_STR.assignedInbound
    },
    {
      Header: 'Assigned Teams (Outbound)',
      accessor: MANAGE_DISPOSITIONS_STR.assignedOutbound
    },
    {
      Header: 'Actions',
      accessor: 'menu',
      disableSortBy: true,
      styles: {
        align: 'right'
      }
    }
  ]

  const handleActionMenu = useCallback((action: string, disposition: Disposition) => {
    setDisposition({...disposition, teams: []})
    if (action === MANAGE_DISPOSITIONS_STR.delete) {
      setIsDeleteModalOpen(true)
    } else if (action === MANAGE_DISPOSITIONS_STR.edit) {
      setIsEditModalOpen(true)
    }
  }, [])

  async function closeModals(isFetch: boolean) {
    setIsEditModalOpen(false)
    setIsDeleteModalOpen(false)
    if (isFetch) {
      refetchDispositions()
    }
  }

  const memoPayload = useMemo(() => {
    return dispositions.map((disposition: Disposition) => ({
      ...disposition,
      outcomeCategory: disposition.outcomeCategory != null ? disposition.outcomeCategory : 'Uncategorized',
      menu: (
        <RdnaMenu options={[{ text: MANAGE_DISPOSITIONS_STR.edit }, { text: MANAGE_DISPOSITIONS_STR.delete }]} onSelect={item => handleActionMenu(item, disposition)} />
      )
    }))
  }, [dispositions, handleActionMenu])

  return (
    <Body>
      <RdnaHeaderSection
        headingText={'Dispositions'}
        descriptionText={MANAGE_DISPOSITIONS_STR.header}
        rightColumn={ <>
          {!isSupport && (<RdnaButton
            style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
            text={'Sync to Salesforce'}
            name="sync-to-salesforce"
            variant="outlined"
            onClick={() => {
              setIsSyncModalOpen(true)
            }}
          />)}
          <RdnaButton
            style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
            text={'Add Disposition'}
            name="add-disposition"
            onClick={() => {
              setDisposition(initDisposition)
              setIsEditModalOpen(true)
            }}
          />
        </>
        }
      />
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={callDispositionsColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        paginationSize={10}>
      </RdnaSmartTable>
      <ConfirmCallDispositionDeleteModal
        open={isDeleteModalOpen}
        onClose={closeModals}
        id={disposition.id}
      />
      <ConfirmSyncCallDispositionModal
        open={isSyncModalOpen}
        onClose={setIsSyncModalOpen}
        accountId={accountId}
      />
      <ManageCallDispositionsModal
        open={isEditModalOpen}
        accountId={accountId}
        disposition={disposition}
        setDisposition={setDisposition}
        onClose={closeModals}
      />
    </Body>
  )
}

