BaseView           = require('base/view')
DynamicInsertionModel = require('./model')
config                = require('../../../../src/config').default

class DynamicNumberInsertionView extends BaseView
  template: require('./template')

  ui:
    copy : 'button.copy'
    snippet : '.snippet'

  events:
    'click @ui.copy' : 'copy'
    'click a.add' : 'create'

  initialize: (options) =>
    { @qs, @accountId } = options
    @qs.setFragment('call-tracking/dynamic-number-insertion')

    host = config.apiDomain
    @model = new DynamicInsertionModel(accountId: @accountId, host: host)

  onRender: =>
    @qs.restore()

  copy: ->
    text = @ui.snippet.text()
    infoMessage = 'Snippet copied to clipboard'

    textArea = document.createElement('textarea')

    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')

    toastr.info(infoMessage)

    textArea.remove()

module.exports = DynamicNumberInsertionView
