import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function CallTrackingIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        clipRule="evenodd"
        d="M16.545 14.973a.5.5 0 00-.094-.577l-1.032-1.033a.5.5 0 00-.61-.075l-.616.37a.424.424 0 01-.352.047c-.37-.122-1.257-.497-2.27-1.51-1.014-1.014-1.389-1.9-1.511-2.272a.424.424 0 01.047-.35l.37-.617a.5.5 0 00-.076-.61L9.37 7.312a.5.5 0 00-.577-.093l-1.211.605a.444.444 0 00-.255.41c.027.797.319 3.16 2.681 5.522 2.363 2.363 4.726 2.655 5.523 2.682a.444.444 0 00.41-.255l.605-1.211z"
        stroke={color}
      />
      <path
        d="M21.346 15.73c.253-.61.422-1.22.563-1.858.112-.638.196-1.276.196-1.914a8.85 8.85 0 00-.197-1.914 8.845 8.845 0 00-.562-1.858 10.433 10.433 0 00-.928-1.748 8.474 8.474 0 00-1.293-1.553 8.544 8.544 0 00-1.575-1.276 11.632 11.632 0 00-1.743-.916c-.59-.25-1.238-.416-1.856-.554A14.42 14.42 0 0012.039 2a8.77 8.77 0 00-1.912.194 8.422 8.422 0 00-1.856.555M2.731 8.241c-.253.61-.422 1.22-.562 1.859A10.46 10.46 0 002 11.986c0 .638.056 1.276.197 1.914.112.638.31 1.248.562 1.859.253.61.563 1.192.928 1.747a8.471 8.471 0 001.294 1.554c.478.499 1.012.915 1.574 1.276.563.36 1.153.665 1.744.915.618.25 1.237.416 1.856.555.646.11 1.265.194 1.912.194a8.77 8.77 0 001.912-.194 8.417 8.417 0 001.856-.555M5.487 7.492V4.663M5.487 4.663h-2.84M18.703 16.369v2.83M18.703 19.198h2.84"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CallTrackingIcon
