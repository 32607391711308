/**
 * Prepends a given string before an object property with a period delimiter.
 *
 * const foo = { bar: 1, baz: 2 }
 *
 * prependString('example', foo)
 *
 * // { 'example.bar': 1, 'example.baz': 2 }
 *
 * @param str - name of the string to prepend
 * @param obj - object properties that need to be prepended
 */
export function prependString(str: string, obj: Record<string, any>) {
  const arr: any = Object.entries(obj)
    .map(item => {
      return `${str}.${item[0]}: ${item[1]}`
    })
    .map(item => item.split(':'))
    .map(item => {
      const obj: Record<string, any> = {}
      obj[item[0]] = item[1].trim()
      return obj
    })

  // eslint-disable-next-line prefer-spread
  return Object.assign.apply(Object, arr)
}

/**
 * This serializes an object property with arrays into a Play format
 *
 * const arr = [{ bar: 1, baz: 2 }, { foo: 1, bam: 2 }]
 * let state = {}
 * const arrKey: string = 'example'
 *
 * arr.map((object, i) => {
 *   state = {
 *     ...state,
 *     ...serializePlayArray(object, i, arrKey)
 *   }
 * })
 *
 * state
 * // [{ 'example[0].bar': 1, 'example[0].baz': 2 }, { 'example[1].foo: 1, 'example[1].bam': 2 }]
 *
 * String Case:
 * const arr = ["rc1", "rc2"]
 * const arrKey: string = 'example'
 * state
 * // [{ 'example[0]': "rc1", 'example[1]': "rc2" }]
 *
 * @param object - the object within the array being returned
 * @param i - index of play array key
 * @param arrKey - name of the play array key
 */
export function serializePlayArray(object: any, i: number, arrKey: string) {
  let tempObj: Record<string, any> = {}

  if (typeof object === 'string') {
    const tempProps = { [`${arrKey}[${i}]`]: object }
    tempObj = {
      ...tempObj,
      ...tempProps
    }
    return tempObj
  }

  Object.entries(object as Record<string, any>).map(obj => {
    const key = obj[0]
    const value = obj[1]

    const tempProps =
      typeof value === 'object' && value !== null
        ? {
            ...prependString(`${arrKey}[${i}].${key}`, value)
          }
        : {
            [`${arrKey}[${i}].${key}`]: value
          }
    tempObj = {
      ...tempObj,
      ...tempProps
    }
  })

  return tempObj
}

/**
 * Parses the availability and meeting-settings state together.
 * The arrKey and objKey properties have an array and object type, respectively, so they need to be parsed differently.
 *
 * const payload = {
 *   settings: {
 *     foo: 1,
 *     bar: 2
 *   },
 *   availability: [
 *      {
 *       foo: 1,
 *       bar: 2
 *     }
 *   ]
 * }
 *
 * serializeJsonIntoPlay(payload)
 *
 * //
 * {
 *  'example.foo': 1,
 *  'example.bar': 2,
 *  'baz[0].foo': 1,
 *  'baz.[0].bar': 2
 * }
 *
 * @param response - api response
 */
export function serializeJsonIntoPlay(response: Record<string, any>): Record<string, any> {
  let arrayState: Record<string, any> = {}
  let objectState: Record<string, any> = {}
  let state: Record<string, any> = {}

  response &&
    Object.entries(response).map(item => {
      const key = item[0]
      const value = item[1]
      if (Array.isArray(value)) {
        value.map((availability: Record<string, any>, i: number) => {
          arrayState = {
            ...arrayState,
            ...serializePlayArray(availability, i, key)
          }
        })
      } else if (typeof value === 'object' && !Array.isArray(key) && value !== null) {
        objectState = prependString(key, value)
      } else {
        state = {
          ...state,
          [key]: value
        }
      }
    })

  return {
    ...arrayState,
    ...objectState,
    ...state
  }
}
