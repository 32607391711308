import { createUseFetch } from '@ringdna/client/src/react/createUseFetch'
import { CaiRecordingVisibility, UserLicenses } from '@ringdna/common/src/types/user-settings'
import { AccountAuthorizations } from '@ringdna/common/src/types/user'
import { NylasStatusTypes } from '@ringdna/common/src/types/nylas'
import { HttpMethods } from '@ringdna/common/src/client/constants'
import { atom } from 'jotai'
import { selectAtom } from 'jotai/utils'

const API_BASE = '/api/v2/session/setup'

export type UserSettings = {
  shareId?: string
  sharePath?: string
  accountDateEnabledSfdcEnhancedAnalytics: number | null
  accountGenAIEnabled: boolean
  accountGenAINonRecordedEnabled: boolean
  accountFollowUpEmailLinkEnabled: boolean
  accountSfdcAdditionalContactsLogging: boolean
  accountSfdcPrimaryContactLogging: boolean
  accountSfdcSharedActivities: boolean
  accountSfdcMultipleMatchAccountField: string | null
  accountSfdcMultipleMatchContactField: string | null
  accountSfdcMultipleMatchLeadField: string | null
  accountId: number
  accountName: string
  accountSfOrgId: string
  accountSfdcEnhancedAnalytics: boolean
  accountType: string
  sfdcEnhancedAnalytics: boolean
  caiFilterColleagueCalls: boolean
  caiManager: boolean
  caiRecordingVisibility: CaiRecordingVisibility
  calendarBooking: boolean
  channel: string
  coachingFeedbackEnabled: boolean
  conversationSummaryEnabled: boolean
  currentFiscalQuarter: string
  eventSubjects: string[]
  exchangeIntegrationEnabled: boolean
  exchangeIntegrationStatus: keyof typeof NylasStatusTypes
  firstName: string
  followUpEmailEnabled: boolean
  googleIntegrationEnabled: boolean
  googleIntegrationStatus: keyof typeof NylasStatusTypes
  hasMomentsAdminAccess: boolean
  isAuthorizedAdmin: boolean
  isOnlyAgent?: boolean
  lastName: string
  licenses: UserLicenses
  momentsUserCount?: number
  nylasEmailEnabled: boolean
  opportunityAmount: string
  opportunityWinProbability: string
  userAccountAuthorizations: AccountAuthorizations[]
  pictureUrl: string
  salesforceAccessToken: string
  salesforceUrl: string
  supervisorChannels: string[] | null
  supportAccountId?: number
  taskSubjects: string[]
  title: string
  userEmail: string
  userId: number
  userLocationName: string
  userName: string
  userUserName: string
}

type UserSettingsParams = {
  query: {
    device: string
  }
}

export const useUserSettings = createUseFetch<UserSettings, UserSettingsParams>({
  key: 'get-user-settings',
  path: `${API_BASE}`,
  method: HttpMethods.Post
})

type UserSettingsExternalParams = {
  meta: {
    shareId: string
  }
}

export const useUserSettingsExternal = createUseFetch<UserSettings, UserSettingsExternalParams>({
  key: 'get-user-settings-external',
  path: params => `/api/v3/externalShare/setup/${params.meta.shareId}`
})

type AccountSettingsPayload = Pick<
  UserSettings,
  | 'opportunityAmount'
  | 'opportunityWinProbability'
  | 'currentFiscalQuarter'
  | 'accountSfdcEnhancedAnalytics'
  | 'accountDateEnabledSfdcEnhancedAnalytics'
  | 'sfdcEnhancedAnalytics'
  | 'accountGenAIEnabled'
  | 'accountGenAINonRecordedEnabled'
  | 'accountFollowUpEmailLinkEnabled'
  | 'accountSfdcAdditionalContactsLogging'
  | 'accountSfdcPrimaryContactLogging'
  | 'accountSfdcSharedActivities'
>

export const useAccountSettings = createUseFetch<AccountSettingsPayload, { meta: { accountId: number } }>({
  key: 'get-account-settings',
  path: params => `/api/v3/accounts/${params.meta.accountId}/settings`
})

// do not use, this has all user settings including ones that may update
export const userSettingsAtom = atom<UserSettings>({} as UserSettings)

export const userSettingsStaticAtom = selectAtom(userSettingsAtom, (rawUserSettingsAtom: UserSettings) => {
  const settings: Partial<UserSettings> = { ...rawUserSettingsAtom }
  delete settings.caiManager
  delete settings.caiFilterColleagueCalls
  delete settings.sfdcEnhancedAnalytics
  return settings as Omit<UserSettings, 'caiManager' | 'caiFilterColleagueCalls' | 'sfdcEnhancedAnalytics'>
})

export const caiAccountAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  accountId: userSettingsAtom.accountId,
  accountName: userSettingsAtom.accountName,
  supportAccountId: userSettingsAtom.supportAccountId
}))

export const viewOnlySupportAtom = selectAtom(
  userSettingsAtom,
  (userSettingsAtom: UserSettings) =>
    userSettingsAtom.licenses.readOnlySupport &&
    !!userSettingsAtom.supportAccountId &&
    userSettingsAtom.accountId !== userSettingsAtom.supportAccountId
)

export const caiManagerAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  caiManager: userSettingsAtom.caiManager
}))

export const caiFilterColleagueCallsAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  caiFilterColleagueCalls: userSettingsAtom.caiFilterColleagueCalls
}))

export const caiSfdcEnhancedAnalyticsAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  sfdcEnhancedAnalytics: userSettingsAtom.sfdcEnhancedAnalytics
}))

export const sfdcSalesForceLoggingAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  accountSfdcAdditionalContactsLogging: userSettingsAtom.accountSfdcAdditionalContactsLogging,
  accountSfdcPrimaryContactLogging: userSettingsAtom.accountSfdcPrimaryContactLogging
}))

export const genAIAtom = selectAtom(userSettingsAtom, (userSettingsAtom: UserSettings) => ({
  accountGenAIEnabled: userSettingsAtom.accountGenAIEnabled,
  accountGenAINonRecordedEnabled: userSettingsAtom.accountGenAINonRecordedEnabled,
  accountFollowUpEmailLinkEnabled: userSettingsAtom.accountFollowUpEmailLinkEnabled,
  coachingFeedbackEnabled: userSettingsAtom.coachingFeedbackEnabled,
  conversationSummaryEnabled: userSettingsAtom.conversationSummaryEnabled,
  followUpEmailEnabledEnabled: userSettingsAtom.followUpEmailEnabled
}))
