import React from 'react'
import {
  useForm,
  UseFormOptions,
  UseFormMethods,
  UseFieldArrayMethods,
  SubmitHandler,
  SubmitErrorHandler,
  FormProvider,
  useFormContext,
  useFieldArray,
  useWatch,
  FieldValues
} from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { useTypedController, ControllerProps, DeepPath } from '@hookform/strictly-typed'
import { useGetMuiProps } from './_helpers'

// TODO: fix types
type RenderFormChildren<T extends FieldValues> = (
  props: UseFormMethods<T>,
  customProps: {
    RdnaFormController: (props: ControllerProps<T, DeepPath<T, keyof T>, any>) => JSX.Element
    getMuiProps: (renderProps: any) => any
  }
) => React.ReactNode

type RdnaFormProps<T extends FieldValues> = {
  children: RenderFormChildren<T> | React.ReactNode
  onValid?: SubmitHandler<T>
  onInvalid?: SubmitErrorHandler<T>
  schema?: any
  debug?: boolean
} & UseFormOptions<T>

export const useFieldWatch = useWatch

export default function RdnaForm<T extends FieldValues>({
  children,
  onValid,
  onInvalid,
  schema,
  debug,
  ...restOfFormOptions
}: RdnaFormProps<T>) {
  const methods = useForm<T>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    ...restOfFormOptions
  })

  const values = useWatch<T>({ control: methods.control })
  // @ts-ignore
  const RdnaFormController = useTypedController<T>({ control: methods.control })
  const getMuiProps = useGetMuiProps<T>(methods.errors)

  return (
    <FormProvider<T> {...methods}>
      {/* in some cases you may not want to use the form onSubmit event. You can use a button instead.
      Just make sure it gets passed the 'submit' type and all other buttons have the 'button' type */}
      <form onSubmit={onValid ? methods.handleSubmit(onValid, onInvalid) : undefined}>
        {/*@ts-ignore*/}
        {typeof children === 'function' ? children({ ...methods }, { RdnaFormController, getMuiProps, values }) : children}
      </form>
      {debug && (
        <DevTool
          // @ts-ignore
          control={methods.control}
        />
      )}
    </FormProvider>
  )
}

// should only used this as a deeply nested child of RdnaForm
export function RdnaConnectedForm<T extends FieldValues>({ children }: { children: RenderFormChildren<T> }) {
  const methods = useFormContext<T>()
  // @ts-ignore
  const RdnaFormController = useTypedController<T>({ control: methods.control })
  const getMuiProps = useGetMuiProps<T>(methods.errors)

  // @ts-ignore
  return <>{children({ ...methods }, { RdnaFormController, getMuiProps })}</>
}

export function RdnaConnectedFieldArray<T extends Record<string, any>, A extends FieldValues>({
  children,
  name
}: {
  children: (props: UseFieldArrayMethods<A, any>) => React.ReactNode // TODO: fix typing
  name: Extract<keyof T, string>
}) {
  const { control } = useFormContext<T>()
  const methods = useFieldArray<A>({
    // @ts-ignore
    control,
    name
  })

  return <>{children({ ...methods })}</>
}

// Extras - for flexibility
export function useRdnaConnectedController<T extends Record<string, any>>() {
  const methods = useFormContext<T>()
  // @ts-ignore
  const RdnaFormController = useTypedController<T>({ control: methods.control })

  return { RdnaFormController }
}
// probably no use case for this...
export function RdnaConnectedController<T extends Record<string, any>>(props: any) {
  const methods = useFormContext<T>()
  // @ts-ignore
  const RdnaFormController = useTypedController<T>({ control: methods.control })

  return <RdnaFormController {...props} />
}
