import { createUseFetch } from '@ringdna/client/src/react/createUseFetch'

const API_BASE = '/api/v2/app/users'

export type PicklistUser = {
  text: string
  value: string
}

export const useUserSettings = createUseFetch<{ [key: string]: any }, { meta: { id: number } }>({
  key: 'fetch-user-settings',
  path: params => `${API_BASE}/${params.meta.id}`
})

export type PicklistAdminUser = {
  id: number
  displayName: string
  settings: {
    sendInvalidNotification: boolean
  }
}

export const useAdminUsers = createUseFetch<PicklistAdminUser[], { meta: { id: number } }>({
  key: 'fetch-admin-users',
  path: params => `${API_BASE}/adminUsers?accountId=${params.meta.id}`,
  independent: true
})
