CookieHelper    = require('@ringdna/common/app/helpers/cookie-helper')
LoginController = require('core/login/controller')
QueryString     = require('helpers/query-string')
UserModel       = require('./user-model')
config = require('../../src/config').default
cookieConfig = require('../../src/config/legacy').cookieConfig

class SessionService
  constructor: (app) ->
    @app = app

    @cookies = new CookieHelper(cookieConfig)
    @user = new UserModel()

    @findSession()
    @controller = new LoginController(app: @app, model: @user)

  findSession: =>
    qs = new QueryString('&', window.location.hash)

    # If we have a sessionId on the querystring, that means the user just logged in through Salesforce so we use that.
    # Otherwise pull the sessionId from the cookie and stash the current url so that we can navigate back to it if
    # needed.
    if qs.get('sessionId')
      @sessionId = qs.get('sessionId')
    else
      @sessionId = @cookies.getSessionId()
      @cookies.set('url', window.location.hash)

  save: (sessionId) =>
    @sessionId = sessionId
    @cookies.setSessionId(sessionId)

  start: =>
    if @sessionId
      @setup()
    else
      @login()

  login: =>
    @reset()
    @user
      .login()
      .done(=>
        console.info("Salesforce OAuth [url=#{@user.get('salesforceUrl')}]")
        @controller.show(@user)
      )

  logout: =>
    @user
      .logout()
      .always(=>
        @reset()
        @app.radio.trigger('session:ended')
      )

  reset: =>
    @sessionId = null
    @cookies.removeSessionId()
    @user = new UserModel()

  setup: =>
    @user
      .setup(@sessionId)
      .done(=>
        sentryClient.setUser({id: @user.get('userId')})
        @save(@sessionId)

        # redirect to destination if requested
        redirectHref = Cookies.get('auth-redirect')
        if redirectHref
          Cookies.remove('auth-redirect', { domain: config.rootDomain })
          window.location.href = redirectHref
          return

        @app.pubsub.setup(@user.get('channel'))
        @app.radio.trigger('session:started', @sessionId, @user)

        userData = {
          id: @user.get('userId'),
          name: @user.get('userName'),
          email: @user.get('userEmail'),
          accountId: @user.get('accountId')
          username: @user.get('userUserName')
          firstName: @user.get('firstName')
          lastName: @user.get('lastName')
          accountName: @user.get('accountName')
        }

        @app.featureFlag.init({
          apiKey: config.launchDarklyKey,
          user: userData,
        }).finally(=>
          qs = new QueryString('&', window.location.hash)
          url =  if qs.get('sessionId') then @cookies.get('url') else window.location.hash
          @app.router.controller.start(url)
          @cookies.remove('url')
          @app.radio.trigger('featureflag:started')
        )
      )
      .fail((xhr, status, error) =>
        msg = 'Could not start session with ringDNA server'
        msg = "#{msg}: #{xhr.responseJSON?.message}" if xhr.responseJSON?.message
        @app.error(msg)
      )

module.exports = SessionService
