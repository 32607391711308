import React from 'react'
import styled, { css } from 'styled-components'

type RdnaAccordionProps = {
  children: React.ReactNode
  noSidePadding?: boolean
  border?: AccordionBorder
  margin?: AccordionMargin
}

const RdnaAccordion = React.memo(
  ({ children, noSidePadding = false, border = 'none', margin = 'normal' }: RdnaAccordionProps) => (
    <StyledRdnaAccordion $noSidePadding={noSidePadding} border={border} margin={margin}>
      {children}
    </StyledRdnaAccordion>
  )
)

RdnaAccordion.displayName = 'RdnaAccordion'

export default RdnaAccordion

type AccordionMargin = 'dense' | 'normal' | 'none'
type AccordionBorder = 'all' | 'bottom' | 'none'

type StyledRdnaAccordionProps = {
  $noSidePadding: boolean
  border: AccordionBorder
  margin: AccordionMargin
}

export const StyledRdnaAccordion = styled.div<StyledRdnaAccordionProps>`
  ${props =>
    props.border === 'bottom' &&
    css`
      border-radius: ${props => props.theme.spacing}px;
      overflow: hidden;
    `}
   }
 
   .rdna-accordion-row {
   background: ${props => props.theme.palette.white};
  ${props =>
    !props.$noSidePadding &&
    css`
      padding: 0 ${props => props.theme.spacing * 3}px;
    `}
    ${props =>
      props.border === 'all' &&
      css`
        border: 1px solid ${props => props.theme.palette.border};
        margin-bottom: ${props => props.theme.spacing * 3}px;
        border-radius: ${props => props.theme.spacing}px;
      `}
   ${props =>
     props.border === 'bottom' &&
     css`
       border-bottom: 1px solid ${props => props.theme.palette.border};
     `}
    ${props =>
      props.border === 'none' &&
      css`
        border-radius: ${props => props.theme.spacing}px;
      `}
   }
  .rdna-accordion-heading {
  
  ${props =>
    props.margin === 'dense' &&
    css`
        padding: ${props => props.theme.spacing * 2}px 0;
      }
    `}
  ${props =>
    props.margin === 'none' &&
    css`
      padding: 0;
    `}
  }
`
