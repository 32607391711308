module.exports = class BackgridCell

  @accountCell: (id, name) -> @href(
    url   : "#accounts/:#{id}",
    label : ":#{name}",
    attrs : ["#{id}", "#{name}"]
  )

  @checkbox: ->
    class extends Backgrid.BooleanCell
      events: ->
        'change input': (e) ->
          @model.set(@column.get('name'), e.target.checked)
          @column.channel.trigger('edit', { model: @model }) if @column?.channel

  @href: (options) ->
    class extends Backgrid.BooleanCell
      render: ->
        {attrs, label, url, tool} = options

        # TODO: Look up attrs dynamically. (use @model.attributes?)
        for attr in attrs
          val = @model.get(attr)
          val = '' if not val

          url = url.replace(":#{attr}", val)
          label = label?.replace(":#{attr}", val)

        if tool is 'edit'
          label = '<span class="glyphicon glyphicon-edit"></span>'
          @el.classList.add('toolbar')

        html = "<a href='#{url}'>#{_.escape(label)}</a>"
        $(@el).html(html)
        @

  @sfdc: (options) ->
    class extends Backgrid.BooleanCell
      render: ->
        {id} = options
        sfdcId = @model.get(id)
        url = App.session.user.salesforceUrl(sfdcId)
        html = "<a href='#{url}' target='_blank'>#{sfdcId}</a>"
        $(@el).html(_.template(html))
        @

  @dateTime: (format='MM/DD/YYYY hh:mm a') ->
    class extends Backgrid.DatetimeCell
      formatter:
        fromRaw: (rawValue) ->
          moment(rawValue).format(format) if rawValue

  @placeHolder: (placeHolder) ->
    class extends Backgrid.Cell
      render: ->
        $(@el).empty()
        value = @formatter.fromRaw(@model.get(@column.get('name')), @model)
        text = value or placeHolder
        $(@el).text(text)
        @delegateEvents()
        @

  @usersCell: (id, name) -> @href(
    url   : "#users/:#{id}"
    label : ":#{name}"
    attrs : ["#{id}", "#{name}"])
