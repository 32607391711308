BaseView            = require('base/view')
BaseModel           = require('base/model')

class Picklist extends Backbone.Collection
  url: 'callScripts/picklist'

class AddCallScriptModal extends BaseView

  template: require('./template')
  className: 'add-call-script'

  ui:
    picker: '.selector'

  events:
    'click button.save': 'save'

  initialize: (options) ->
    @accountId = options.accountId

  onRender: =>
    @ui.picker.selectize(
      selectOnTab: true
      maxItems: 1
      preload: true
      placeholder  : 'Select Call Script'
      load: (query, callback) =>
        return if @picklist
        @picklist = new Picklist()
        @picklist.fetch(data: {accountId: @accountId}).done((response) =>
          unless response?.length
            $('.select-container').html('No call scripts are available.')
            $('button.save').text('Done')
          callback(response)
        )
    )

  save: =>
    if @ui.picker.val()
      @trigger('save', @ui.picker.val())

module.exports = AddCallScriptModal