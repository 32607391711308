TwilioCallbacksItemView = require('./item-view')

module.exports = class TwilioCallbacksCollectionView extends Marionette.CollectionView

  tagName   : 'ul'
  className : 'timeline'

  childView: TwilioCallbacksItemView

  childViewOptions: ->
    callModel : @options.callModel
    collection: @options.collection