BaseView             = require('base/view')
Columns              = require('./columns')
CsvUserCollection    = require('./csv-collection')
BackgridRow          = require('helpers/backgrid-row')
ResultUserCollection = require('./result-collection')

module.exports = class UserImportMainView extends BaseView

  template: require('./main-template')
  className: 'user-import'

  regions:
    results: '.import-results'

  ui:
    importButton: 'button.import'
    uploadButton: 'button.upload'
    results: '.import-results'
    file: '.file'

  bindings:
    'button.import': 'toggle:hasUsersForImport'

  events:
    'click @ui.importButton': '_import'
    'click @ui.uploadButton': '_upload'

  initialize: (options) ->
    @viewModel = new Backbone.Model(hasUsersForImport: false)
    @csvCollection = new CsvUserCollection()
    @resultCollection = new ResultUserCollection()
    App.pubsub.bind('UserImportStatus', @onProgress)
    App.pubsub.bind('UserImportFinished', @onFinish)

  onFinish: (data) =>
    toastr.info('The import has completed.', 'User Import')
    @ui.file.show()
    @ui.file.val('')

  onProgress: (data) =>
    @resultCollection?.add(data.message)

  onRender: =>
    @ui.file.filestyle(
      buttonBefore: true,
      buttonText: '&nbsp; Choose file'
    )

  _import: =>
    @_spin(true)
    App.api.post(
      path: 'users/import'
      data: collection: @csvCollection.toJSON()
      success: =>
        @_showResultView()
        @ui.file.hide()
      complete: =>
        @_spin(false)
    )
    @_resetData()

  _upload: =>
    if not @ui.file.val()
      toastr.warning('Please choose a file.')
      return

    @_resetData()
    @_spin(true)

    App.api.post(
      path: 'users/validate'
      data: file: @ui.file[0].files[0]
      upload: true
      success: (response) =>
        @csvCollection.add(response)
        if @csvCollection.toJSON().length is 0
          toastr.warning('Nothing to import.', 'User Import')
        else
          @viewModel.set(hasUsersForImport: true)
        @_showCsvView()
      complete: =>
        @_spin(false)
    )

  _resetData: =>
    @csvCollection.reset()
    @resultCollection.reset()
    @viewModel.set(hasUsersForImport: false)

  _showCsvView: =>
    csvView = new Backgrid.Grid(
      row: BackgridRow.error('errorDesc', 'error-row'),
      columns: Columns.CSV,
      collection: @csvCollection,
      emptyText: 'No Data'
    )
    @showChildView('results', csvView) if @getRegion('results')

  _showResultView: =>
    resultView = new Backgrid.Grid(columns: Columns.RESULT, collection: @resultCollection, emptyText: 'No Data')
    @showChildView('results', resultView) if @getRegion('results')

  _spin: (enabled) =>
    if enabled
      @showSpinner(@ui.results, left: '45%')
    else
      @hideSpinner(@ui.results)
