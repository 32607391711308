BaseView = require('base/view')
OverviewView = require('./overview/view')

class CleanupRecordingsView extends BaseView
  template: require('./template')
  className: 'cleanup-recordings'

  regions:
    accountInfo   : '.account-info'

  ui:
    results : '.results'

  initialize: (options) ->
    App.pubsub.bind('CleanupStatus', @onStatusUpdate)
    @accountInfo = @getRegion('accountInfo')

  onRender: =>
    @search()

  onDestroy: =>
    App.pubsub.unbind('CleanupStatus')

  onStatusUpdate: (status) =>
    $(@ui.results).append("<p class=#{status.type}>#{status.message}</p>")
    if status.message.indexOf('Finish cleanup') > -1
      setTimeout(@search, 15000)

  search: =>
    if @user().getSelectedSupportAccountId()
      App.api.get(
        path    : 'cleanup/recordings/' + @user().getSelectedSupportAccountId()
        success : (response) =>
          v = new OverviewView(model: new Backbone.Model(response))
          @accountInfo.show(v)
          @listenTo(v, 'cleanup', @cleanup)
      )

  cleanup: (accountId) =>
    @ui.results.text('')
    @ui.results.show()
    App.api.post(
      path: 'cleanup/recordings'
      data: accountId: accountId
      success: =>
        toastr.info('Start cleanup job.')
    )

module.exports = CleanupRecordingsView
