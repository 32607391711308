BaseModel = require('base/model')

class VoicemailGreetingModel extends BaseModel
  urlRoot: 'voicemailGreetings'

  default:
    modalName: '#voicemail-greeting-modal'

  defaultGreeting: (selected) ->
    deletable : false
    isDefault : true
    name      : 'Default Greeting'
    url       : 'https://s3.amazonaws.com/voicemails/default.wav'
    selected  : selected

  getFilename: ->
    @get('url').split('/').pop()

module.exports = VoicemailGreetingModel
