BaseView = require('base/view')
ChannelCollection = require('./collection')

class ChannelPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    {@accountId} = options

  onRender: =>
    @ui.picker.selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      placeholder: 'Channel'
      load: (query, callback) =>
        return if @picklist
        @picklist = new ChannelCollection()
        @picklist
        .fetch(data: {accountId: @accountId})
        .done((response) =>
          callback(response)
          selectize = @ui.picker[0].selectize
          return unless selectize
          @add(channel) for channel in response
          selectize.addItem('', true)
      )
    )
    @selectize = @ui.picker[0].selectize

  val: (accountId) =>
    if accountId
      channels = new ChannelCollection()
      channels.fetch(data: accountId: accountId).done((response) =>
        @selectize.clearOptions()
        @add(channel) for channel in response
        @selectize.addItem('', true)
      )
    else
      @ui.picker.val()

  add: (channel) =>
    @selectize.addOption(text: channel, value: channel)

module.exports = ChannelPickerView
