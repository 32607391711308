BaseView = require('base/view')
LocationModel = require('./model')
LocationCollection = require('./collection')

class LocationPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  events:
    'change @ui.picker': 'go'

  bindings:
    '.selector': 'classes:{"hide-arrow": not(fullList)}'

  computeds:
    fullList:
      get: -> @fullList

  initialize: (options) ->
    {@accountId, @locations, @fullList, @multiple, @selectedLocations, @emptyOption, @placeholder} = options
    @picklist = null
    @presenceFlow = false if not @presenceFlow
    @placeholder = 'Location' unless @placeholder

  onRender: =>
    if @fullList
      unless @locations
        @locations = new LocationCollection()
        @locations
          .fetch(
            data:
              name: null
              accountId: @accountId
          )
          .done( => @initFullSelectize())
      else
        @initFullSelectize()
    else
      @locations = new LocationCollection()
      @initQuerySelectize()

  initQuerySelectize: =>
    @ui.picker.selectize(
      placeholder: @placeholder
      load: (query, callback) =>
        return if query?.length < 2
        if @accountId
          @locations
            .fetch(
              data:
                name: query
                accountId: @accountId
            )
            .done((response) =>
              items = []
              items.push(text: location.name, value: location.id) for location in response
              callback(items)
            )

      render:
        option: (item, escape) ->
          "<div>#{escape(item.text)} <span class='picker-value'>#{escape(item.value)}</span></div>"

      onChange: (value) =>
        @trigger('change', value)
    )
    @selectize = @ui.picker[0].selectize

  initFullSelectize: =>
    if @selectedLocations
      @initMultipleSelectize()
    else
      @initSingleSelectize()

  initSingleSelectize: =>
    @ui.picker.selectize(
      placeholder: @placeholder
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      onInitialize: =>
        selectize = @ui.picker[0].selectize
        return unless selectize
        selectize.addOption(text: @emptyOption, value: '') if @emptyOption
        selectize.addOption(value: model.get('id'), text: model.get('name')) for model in @locations.models
        selectize.addItem('', true) unless @emptyOption

        selectize.on('item_add', (value, item) =>
          @trigger('select', value)
        )
        selectize.on('item_remove', (value, item) =>
          @trigger('deselect')
        )
        selectize.on('change', (value) =>
          @trigger('change', value)
        )
    )
    @selectize = @ui.picker[0].selectize

  initMultipleSelectize: =>
    @ui.picker.selectize(
      placeholder: @placeholder
      closeAfterSelect: true
      maxItems: null
      plugins: ['remove_button']
      onInitialize: =>
        selectize = @ui.picker[0].selectize
        selectize.addOption(value: model.get('id'), text: model.get('name')) for model in @locations.models
        selectize.addItem(model.get('id'), true) for model in @selectedLocations.models

        selectize.on('item_add', (value, item) =>
          model = @locations.findWhere(id: parseInt(value))
          @selectedLocations.add(model) if model
        )
        selectize.on('item_remove', (value, item) =>
          @selectedLocations.remove(parseInt(value))
        )
      onDropdownClose: (dropdown) ->
        $(dropdown).prev().find('input').blur()
    )
    @selectize = @ui.picker[0].selectize

  disable: =>
    @selectize.disable()

  enable: =>
    @selectize.enable()

  # HACK: This method does two different things. When passed no args, it'll return the selected locationId. When passed
  # a locationId, it'll look up the location name and populate the widget.  A little wonky, but it's convenient to mimic
  # the standard form element val() api.
  val: (locationId) =>
    if arguments.length is 0
      return @ui.picker.val()
    else
      @selectize.addOption(text: '', value: locationId)
      @selectize.addItem(locationId, true)

      location = new LocationModel(id: locationId)
      location.fetch().done(=>
        label = location.get('name')
        @selectize.updateOption(locationId, {text: label, value: locationId})
      )

  clearOptions: =>
    @selectize.clearOptions()

  clear: =>
    @selectize.clear()
    @trigger('deselect')

module.exports = LocationPickerView
