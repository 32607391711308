StepModel = require('../../model')

class MenuStepModel extends StepModel

  defaults:
    type: 'Menu'
    info: 'Offer the caller a list of next steps to select from'
    fixedPlaceholders: [ 'No Response' ]
    placeholders: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#' ]
    leaf: false
    args:
      repeatMenu: '0'
      spacing: '10'

  orderChildren: (children) ->
    result = []
    for placeholder in _.union(@placeholders(), @fixedPlaceholders())
      childId = @args()[placeholder]
      if childId
        result.push(child if parseInt(child.get('id')) == parseInt(childId)) for child in children
    result

  errors: ->
    messages = []
    selectors = []

    spacing = @args().spacing
    repeatMenu = @args().repeatMenu
    if spacing != '' and not @isInteger(spacing)
      messages.push('Must be a positive integer or 0')
      selectors.push('.spacing')
    if repeatMenu != '' and not @isInteger(repeatMenu)
      messages.push('Must be a positive integer or 0')
      selectors.push('.repeatMenu')

    audioErrors = @audioErrors(['audio', 'invalidAudio'])
    errors = _.zip(messages, selectors)
    if audioErrors.length > 0
      errors = _.union(errors, audioErrors)

    errors

module.exports = MenuStepModel