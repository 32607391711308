import React, { useEffect, useState } from 'react'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useFetchServiceUser, useCreateServiceUser } from '../../store/api/service-user-api'
import { RdnaTable, RdnaTableCell, RdnaTableHead, RdnaTableRow } from '@ringdna/common/src/components/RdnaTable'
import { ServiceUser } from '@ringdna/common/src/types/service-user'
import { SectionDescription, SectionHeading } from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaText from '@ringdna/common/src/components/RdnaText'

const ServiceUserTable = () => {
  const [data, setData] = useState<ServiceUser>()
  const accountId =
    sessionStorage.getItem('support-accountId') !== 'ALL'
      ? parseInt(sessionStorage.getItem('support-accountId') || '')
      : null
  const getServiceUser = useFetchServiceUser()
  const createServiceUser = useCreateServiceUser()

  useEffect(() => {
    if (accountId) {
      const loadServiceUser = async () => {
        const response = await getServiceUser({ query: { accountId: accountId } })
        setData(response.success?.payload.serviceUser)
      }
      loadServiceUser()
    }
  }, [accountId, getServiceUser])

  const createUser = async () => {
    if (accountId) {
      try {
        const response = await createServiceUser({ query: { accountId: accountId } })
        setData(response.success?.payload)
      } catch (err) {
        toastr.error(err.message)
      }
    }
  }

  return (
    <div className="x-panel">
      <SectionHeading headingText="Service Users" />
      <SectionDescription descriptionText="Manage service users credentials across accounts." />
      {data && (
        <RdnaTable $shadedRows>
          <RdnaTableHead>
            <RdnaTableCell>Name</RdnaTableCell>
            <RdnaTableCell>Value</RdnaTableCell>
          </RdnaTableHead>
          <RdnaTableRow>
            <RdnaTableCell>Account ID</RdnaTableCell>
            <RdnaTableCell>{accountId}</RdnaTableCell>
          </RdnaTableRow>
          <RdnaTableRow>
            <RdnaTableCell>Salesforce Org ID</RdnaTableCell>
            <RdnaTableCell>{data.sfOrgId}</RdnaTableCell>
          </RdnaTableRow>
          <RdnaTableRow>
            <RdnaTableCell>API Key</RdnaTableCell>
            <RdnaTableCell>
              <code>{data.apiKey}</code>
            </RdnaTableCell>
          </RdnaTableRow>
          <RdnaTableRow>
            <RdnaTableCell>API Secret</RdnaTableCell>
            <RdnaTableCell>
              <code>{data.apiSecret}</code>
            </RdnaTableCell>
          </RdnaTableRow>
        </RdnaTable>
      )}
      {!data && accountId && (
        <div>
          <RdnaText component="p">No Service User found</RdnaText>
          <RdnaButton text="Create New Service User" onClick={createUser} />
        </div>
      )}
    </div>
  )
}
export default ServiceUserTable
