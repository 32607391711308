var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='alert alert-warning center m-0 rounded-0'><i class='glyphicon glyphicon-exclamation-sign' ></i> The holiday schedule, <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eventName") || (depth0 != null ? lookupProperty(depth0,"eventName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eventName","hash":{},"data":data,"loc":{"start":{"line":1,"column":138},"end":{"line":1,"column":151}}}) : helper)))
    + "</strong>, is currently active on your account and may influence the routing of your calls.</div>\n";
},"useData":true});