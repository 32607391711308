import { format } from 'date-fns'

const DATE_FORMAT = 'MM/dd/yyyy'
const TIME_FORMAT = 'hh:mm a'
export const NOT_AVAILABLE = 'n/a'

export const formatPercent = (percent: number) => percent.toFixed(1) + '%'

export const formatSeconds = (seconds: number) => new Date(seconds * 1000).toISOString().substr(11, 8)

export const formatDate = (timestamp: number | string | undefined) =>
  timestamp === undefined ? NOT_AVAILABLE : format(new Date(timestamp), DATE_FORMAT)

export const formatTime = (timestamp: number | string | undefined) =>
  timestamp === undefined ? NOT_AVAILABLE : format(new Date(timestamp), TIME_FORMAT)

export const formatDateTime = (timestamp: number | string | undefined) =>
  timestamp === undefined ? NOT_AVAILABLE : format(new Date(timestamp), `${DATE_FORMAT}, ${TIME_FORMAT}`)

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const formatDollars = (value: number | string | undefined) =>
  value === undefined
    ? NOT_AVAILABLE
    : dollarFormatter.format(typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value)

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}
