import React, { useEffect, useMemo, useState } from 'react'
import { Role, RoleMapping } from '../types'
import styled, { useTheme } from 'styled-components'
import { HEAD_AUTOMAPPING, SUB_HEAD_AUTOMAPPING } from '../const'
import RoleMappingCell from './RoleMappingCell'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { useRoleMapping, useRoleMappingUpdate } from '../api'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { Footer } from '../styles'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import RdnaLogoAnimated from '@ringdna/common/src/components/RdnaLogoAnimated'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { uniq } from 'lodash-es'

export type AutoMapRolesPanelProps = {
  onClose: () => void
  accountId: number
  roleDefinitions: Role[]
  refetchRoleTable: any
}

export default function AutoMapRolesPanel({
  onClose,
  accountId,
  roleDefinitions,
  refetchRoleTable
}: AutoMapRolesPanelProps) {
  const { error: showError } = useRdnaNotification()
  const [refresh, setRefresh] = useState(false)
  const [firstInit, setFirstInit] = useState(false)
  const [payload, error, loading, refetch] = useRoleMapping({ meta: { accountId, refresh } })
  const updateRoleMapping = useRoleMappingUpdate()
  const [listRoles, setListRoles] = useState<RoleMapping[]>()
  const [listUpdateRoles, setListUpdateRoles] = useState<RoleMapping[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { spacing } = useTheme()

  useEffect(() => {
    if (payload) {
      setListRoles(payload)
      if (payload.every(item => item.roleId == null)) {
        setFirstInit(true)
      }
    }
  }, [payload])

  useEffect(() => {
    if (error) showError(getErrorMessage(error))
  }, [error, showError])

  const onRefreshRoles = async () => {
    setRefresh(true)
    setFirstInit(true)
    refetch()
  }

  async function handleSave() {
    setIsLoading(true)
    try {
      const savedListRoles = firstInit && listRoles ? uniq([...listUpdateRoles, ...listRoles]) : listUpdateRoles
      const body = { json: { accountId: accountId, listRoles: savedListRoles } }
      await updateRoleMapping(body)
    } catch (e) {
      showError(getErrorMessage(e))
    }
    setIsLoading(false)
    refetchRoleTable()
    onClose()
  }

  const changeItem = (item: RoleMapping) => {
    setListUpdateRoles([...listUpdateRoles, item])
  }

  const disableSaveButton = useMemo(() => {
    return isLoading || listUpdateRoles.length < 1
  }, [isLoading, listUpdateRoles.length])

  return (
    <>
      <RdnaFormLayout style={{ marginTop: spacing * 2 }}>
        <SectionHeader
          headingText={HEAD_AUTOMAPPING}
          rightColumn={<RdnaButton text={'Refresh Roles'} variant="outlined" onClick={onRefreshRoles} />}
        />
        <RdnaText>{SUB_HEAD_AUTOMAPPING}</RdnaText>
      </RdnaFormLayout>
      <RdnaLoader
        CustomLoader={() => (
          <ProgressDiv $top={'150px'} data-testid="loader">
            <RdnaFormLayout>
              <RdnaLogoAnimated size={160} />
              {refresh && <RdnaText>Detecting New Roles</RdnaText>}
            </RdnaFormLayout>
          </ProgressDiv>
        )}
        size={160}
        loading={!!loading}
        top="150px"
        data="data"
      >
        <RdnaFormLayout>
          {listRoles?.map((item, index) => (
            <RoleMappingCell
              key={`${index}`}
              roleDefinitions={roleDefinitions}
              salesforceRole={item}
              onChange={changeItem}
            />
          ))}
        </RdnaFormLayout>
        <Footer>
          <RdnaButton text={'Cancel'} color="neutral" variant="text" onClick={onClose} className={'button-cancel'} />
          <RdnaButton
            text={isLoading ? 'Saving...' : 'Save'}
            onClick={handleSave}
            className={'button-save'}
            disabled={disableSaveButton}
          ></RdnaButton>
        </Footer>
      </RdnaLoader>
    </>
  )
}

const ProgressDiv = styled.div<{ $top: string }>`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: ${props => props.$top};
`
