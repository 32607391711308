import React from 'react'
import RdnaText from '../../RdnaText'
import styled from 'styled-components'
import { LARGE_MARGIN, SMALL_MARGIN } from './const'
import RdnaInfoModal from '../RdnaInfoModal'

const StyledSection = styled.section`
  margin-bottom: ${LARGE_MARGIN};
  .MuiInputLabel-root {
    top: 18px;
    position: relative;
    margin-bottom: 5px;
    left: 2px;
  }
  min-width: 300px;
`

const StyledDiv = styled.div`
  margin-bottom: ${SMALL_MARGIN};
`

const SectionTitle = styled(RdnaText)`
  display: inline-block;
  margin-right: ${props => props.theme.spacing}px;
`

const StyledFormSubtitle = styled.div`
  margin-top: ${SMALL_MARGIN};
  margin-bottom: ${LARGE_MARGIN};
`
const SubtitleWithIcon = styled.div`
  display: flex;
`

type SectionProps = {
  formTitle?: string
  formSubtitle?: string
  sectionTitle?: string
  subtitle?: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  infoModal?: {
    title: string
    body: string
  }
  testId?: string
}

export default function RdnaSection({
  formTitle,
  formSubtitle,
  sectionTitle,
  subtitle,
  children,
  className,
  infoModal,
  testId
}: SectionProps) {
  return (
    <StyledSection className={className} data-testid={`section-${testId}`}>
      <StyledDiv>
        {formTitle && <RdnaText variant="h2">{formTitle}</RdnaText>}
        {formSubtitle && (
          <StyledFormSubtitle>
            <RdnaText className="subtitle" variant="body1">
              {formSubtitle}
            </RdnaText>
          </StyledFormSubtitle>
        )}
        {sectionTitle && (
          <SubtitleWithIcon>
            <SectionTitle variant="h5">{sectionTitle}</SectionTitle>
            {infoModal && <RdnaInfoModal title={infoModal.title} body={infoModal.body} />}
          </SubtitleWithIcon>
        )}
        {subtitle && (
          <RdnaText className="subtitle" variant="body1">
            {subtitle}
          </RdnaText>
        )}
      </StyledDiv>
      {children}
    </StyledSection>
  )
}
