BackgridCell = require('helpers/backgrid-cell')
BaseView = require('base/view')
PagedCollection = require('base/paged-collection')
PagerView = require('widgets/pager/view')
ProfileCollection = require('./collection')

class ProfileSearchView extends BaseView

  template: require('./template')
  className: 'salesforce-profile-search'

  regions:
    pager: '.pager'
    grid: '.grid'

  ui:
    search: '.search'
    searchTerm : '.search-term'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  initialize: (options) =>
    @qs = options.qs
    @qs.setFragment('salesforce-profiles')

  onRender: =>
    @qs.bind(
      searchTerm  : @ui.searchTerm
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    collection = new ProfileCollection(
      page: @qs.get('page')
      queryParams:
        accountId : @getAccountORNull()
        searchTerm : @ui.searchTerm.val()
    )
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))
    collection
      .fetch(reset: true)
      .done(=> @showChildView('grid', @buildGrid(collection)) if @getRegion('grid'))

  buildGrid: (collection) ->
    cols = [
      {label: 'Profile Name',    name: 'name',         cell: @profileNameCell()}
      {label: 'Account',         name: 'account.name', cell: @accountCell(), renderable: @user().accountPickerRenderable()}
      {label: 'Profile ID',      name: 'id',           cell: 'string'}
      {label: 'Number of Users', name: 'userCount',    cell: @userCountCell(), sortable: false}
      {label: 'Edit',            name: '',             cell: @editCell(), sortable: false}
    ]

    new Backgrid.Grid(
      columns    : cols
      collection : collection
      emptyText  : 'No Profiles Found.'
    )

  accountCell: =>
    BackgridCell.accountCell('account.id', 'account.name')

  userCountCell: =>
    BackgridCell.href(
      url   : '#salesforce-profiles/:id/users',
      label : ':userCount',
      attrs : ['id', 'userCount'])

  profileNameCell: =>
    mainView = @
    class extends BaseView
      template: _.template("
        <span class='glyphicon glyphicon-exclamation-sign red alert-tooltip'></span>
        <span class='profile-name'></span>
      ")
      tagName: 'td'
      className: 'renderable profile-name-cell'

      ui:
        tooltip: '.alert-tooltip'

      bindings:
        '.alert-tooltip': 'toggle:not(isViewed)'
        '.profile-name': 'text:name'

      events:
        'mouseover @ui.tooltip': 'showTooltip'

      showTooltip: =>
        @ui.tooltip.tooltip(
          title: "This Profile has inherited the field permissions previously defined on the Salesforce Field tabs
                  in the \"Account\" section. To customize the fields that users of this Profile can see and
                  interact with, click the “Edit” icon."
          container: ".#{mainView.className}"
        )
        @ui.tooltip.tooltip('show')

  editCell: =>
    class extends BaseView
      template: _.template("<span class='glyphicon glyphicon-pencil edit-profile'></span>")
      tagName: 'td'
      className: 'renderable edit-profile-cell'

      ui:
        editProfile: '.edit-profile'

      events:
        'click @ui.editProfile': -> App.navigate("#salesforce-profiles/#{@model.get('id')}/fields", {trigger: true})


module.exports = ProfileSearchView
