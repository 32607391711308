

export const SMS_SETTINGS_STR = {
  heading: 'RingDNA: SMS',
  smsSettingText: 'SMS',
  smsSettingTooltip:
    'Enable SMS messaging in RingDNA. To unlock this setting, reach out to your Revenue.io customer success contact.',
  undeliverableSmsOwnerSettingText: 'Undeliverable SMS Message',
  smsTemplatesSettingText: 'Enable SMS Templates',
  smsTemplatesSettingTooltip: 'Allow users to use preconfigured SMS Templates.',
  saveButtonText: 'Save',
  saveSuccess: 'SMS Settings Saved',
  saveError: (error: string) => `An error has occurred, ${error}`
}

export const AGREEMENT_MODAL_STR = {
  header: 'SMS Agreement',
  cancel: 'Cancel',
  confirm: 'Enable SMS Messaging',
  agreementText: `
  The Telephone Consumer Protection Act of 1991 (TCPA) is a U.S. Federal law that governs, among several areas, the use of SMS. Unlawful use of SMS can lead to fines, civil suits and also criminal prosecution. ringDNA Customers need to ensure that their company’s SMS procedures comply with all applicable TCPA laws and regulations including but not limited to:
  1) obtaining prior express written consent from prospects and customers to receive SMS,
  2) that prospects and customers are made aware that this consent is not a condition of making a purchase,
  3) ability for prospects and customers to opt out,
  4) sending time frame from 8:00am to 9:00pm of the prospect or customer’s time zone and
  5) record keeping of prospect or customer consent. ringDNA is not responsible for Customer compliance.
  By using the ringDNA Messaging (SMS) feature, Customer is required and agrees to maintain compliance with all applicable laws and regulations.
  Customer understands and agrees that Customer is solely responsible and liable for compliance with such laws and regulations and under no circumstances shall ringDNA be responsible or held liable for such compliance.
  Customer agrees to indemnify ringDNA for any violations of such laws and regulations.
  Customer acknowledges that other countries may have similar regulations and laws related to SMS procedures and compliance. As a result, Customer is also responsible for all aspects of compliance outside of the U.S.
  Customer should consult with an attorney prior to sending any SMS. The information above does not constitute legal advice.
`,
  agreementCheckboxLabel: 'I Agree to the Terms'
}
