import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function ChecklistIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.706 7h-9.412C10.132 7 10 6.776 10 6.5s.132-.5.294-.5h9.412c.162 0 .294.224.294.5s-.132.5-.294.5M19.706 13h-9.412c-.162 0-.294-.224-.294-.5s.132-.5.294-.5h9.412c.162 0 .294.224.294.5s-.132.5-.294.5M10.294 19c-.162 0-.294-.224-.294-.5s.132-.5.294-.5h9.412c.162 0 .294.224.294.5s-.132.5-.294.5h-9.412Z"
        fill={color}
      />
      <rect x={3.667} y={4.667} width={3.667} height={3.667} rx={0.5} stroke={color} />
      <rect x={3.667} y={10.667} width={3.667} height={3.667} rx={0.5} stroke={color} />
      <rect x={3.667} y={16.667} width={3.667} height={3.667} rx={0.5} stroke={color} />
    </svg>
  )
}

export default ChecklistIcon
