var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <option value=\"LocalPresence\">LocalPresence</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span>&times;</span></button>\n    <h4 class=\"modal-title\">Search for Available Number</h4>\n</div>\n<div class=\"modal-body\">\n  <div class=\"header-search\">\n    <div class=\"col-md-9\">\n      <div class=\"col-md-10\">\n        <div class=\"col-md-4\">\n          <select class=\"form-control type-of-number\">\n            <option value=\"All\" selected>All</option>\n            <option value=\"Additional\">Additional</option>\n            <option value=\"Tracking\">Tracking</option>\n            <option value=\"CentralNumber\">CentralNumber</option>\n            <option value=\"Default\">Default</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showLPNumbers") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "            <option value=\"Unassigned\">Unassigned</option>\n          </select>\n        </div>\n        <div class=\"col-md-4\">\n          <input type=\"text\" class=\"form-control existing-number\" placeholder=\"Phone number\">\n        </div>\n        <div class=\"col-md-4\">\n          <input type=\"text\" class=\"form-control owner-name\" placeholder=\"Owner\">\n        </div>\n      </div>\n      <div class=\"col-md-2\">\n        <button class=\"btn btn-success search-existing-numbers\">Search</button>\n      </div>\n    </div>\n    <div class=\"col-md-3\">\n      <span class=\"pager\"></span>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"existing-numbers-list\"></div>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"modal-footer\">\n  <button href=\"#\" class=\"ladda-button bbm-button previous btn stepPrevious left\">Back</button>\n  <button href=\"#\" class=\"ladda-button bbm-button next btn-success stepNext\">Next</button>\n</div>\n";
},"useData":true});