import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Field,
  FieldSettings,
  useNewSalesforceFieldSettings,
  usePostSalesforceFieldSettings,
  usePutSalesforceFieldSettings,
  useSalesforceFieldSettings
} from '../../../../../store/api/salesforce-fields-api'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { Column } from 'react-table'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import styled from 'styled-components'
import RdnaTooltip from '@ringdna/common/src/components/RdnaTooltip'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'
import config, { ProductDomains } from '../../../../../config'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import useToast from '../../../../common/hooks/useToast'

type Props = {
  field: Field
  onClose: (reload: boolean) => void
  accountId: number
  type: string
}

const HIDDEN_TOOLTIP =
  'The field cannot be added because Read Access has not been granted to this Profile in Salesforce. ' +
  'Please contact your Salesforce administrator if you believe this is in error.'

const LIMIT_TOOLTIP =
  'The field cannot be added to this Profile because it has reached its maximum number of allowed fields.'

const COLUMN_WIDTH = 60

enum Keys {
  VIEW = 'view',
  VISIBLE = 'visible',
  EDITABLE = 'editable',
  REQUIRED = 'required'
}
const defaultKeys = {
  view: false,
  visible: false,
  editable: false,
  required: false
}

export default EditSettings
function EditSettings({ field, onClose, accountId, type }: Props) {
  const [settings, setSettings] = useState<FieldSettings[]>()
  const [fieldId, setFieldId] = useState<number>()
  const [sfdcName, setSfdcName] = useState<string>(field.sfdcName)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const fetchFieldSettings = useSalesforceFieldSettings()
  const fetchNewFieldSettings = useNewSalesforceFieldSettings()
  const createSalesforceFieldSettings = usePostSalesforceFieldSettings()
  const updateSalesforceFieldSettings = usePutSalesforceFieldSettings()
  const toastr = useToast()

  useEffect(() => {
    const getFieldSettings = async (fieldId: number) => {
      try {
        const response = await fetchFieldSettings({ meta: { id: fieldId } })
        setSettings(response.success?.payload || [])
      } catch (e) {
        toastr.error(getErrorMessage(e))
      }
      setIsLoading(false)
    }
    const getNewFieldSettings = async (sfdcName: string) => {
      try {
        const response = await fetchNewFieldSettings({
          meta: { accountId: accountId, type: type, sfdcName: sfdcName }
        })
        setSettings(response.success?.payload.map(m => ({ ...m, ...defaultKeys })) || [])
      } catch (e) {
        toastr.error(getErrorMessage(e))
      }
      setIsLoading(false)
    }
    if (field.id && field.id !== fieldId) {
      // edit field
      setIsLoading(true)
      setFieldId(field.id)
      getFieldSettings(field.id)
    } else if (field.sfdcName && field.sfdcName !== sfdcName) {
      // add new field
      setIsLoading(true)
      setSfdcName(field.sfdcName)
      getNewFieldSettings(field.sfdcName)
    } else if (!field.id && !field.sfdcName) {
      // show table empty state
      setIsLoading(false)
      setSettings([])
    }
  }, [accountId, fetchFieldSettings, fetchNewFieldSettings, field, type, sfdcName, fieldId])

  const onSave = async () => {
    setIsLoading(true)
    try {
      settings &&
        field.id &&
        (await updateSalesforceFieldSettings({
          meta: { id: field.id },
          body: serializeJsonIntoPlay({ field: { ...field }, settings: settings })
        }))
      settings &&
        !field.id &&
        (await createSalesforceFieldSettings({
          body: serializeJsonIntoPlay({ newField: { ...field }, newFieldSettings: settings, accountId: accountId })
        }))
    } catch (error) {
      toastr.error(error.message)
    } finally {
      setIsLoading(false)
      onClose(true)
    }
  }

  const onChangeCheckbox = useCallback(
    (key: string, checked: boolean, profileId: number) => {
      switch (key) {
        case Keys.EDITABLE:
          setSettings(
            settings?.map(model =>
              model.profile.id === profileId ? { ...model, [key]: checked, [Keys.REQUIRED]: false } : model
            )
          )
          break
        default:
          setSettings(settings?.map(model => (model.profile.id === profileId ? { ...model, [key]: checked } : model)))
      }
    },
    [settings]
  )

  const onChangeHeaderCheckbox = useCallback(
    (key: string, checked: boolean) => {
      switch (key) {
        case Keys.VIEW:
          setSettings(
            settings?.map(model => (model.hidden || model.profile.limit ? model : { ...model, [key]: checked }))
          )
          break
        case Keys.VISIBLE:
          setSettings(settings?.map(model => (model.view ? { ...model, [key]: checked } : model)))
          break
        case Keys.EDITABLE:
          setSettings(
            settings?.map(model =>
              model.updateable && model.view
                ? { ...model, [key]: checked, [Keys.REQUIRED]: checked ? model.required : false }
                : model
            )
          )
          break
        case Keys.REQUIRED:
          setSettings(
            settings?.map(model => (model.updateable && model.editable ? { ...model, [key]: checked } : model))
          )
          break
      }
    },
    [settings]
  )

  const showProfile = (profileId: number) => {
    window.location.assign(`${config[ProductDomains.WEB_ADMIN]}/#salesforce-profiles/${profileId}/fields`)
  }

  const columns = useMemo<Column<FieldSettings>[]>(
    () => [
      {
        Header: 'Profile Name',
        accessor: setting => setting,
        Cell: function createdCell(data: { value: FieldSettings }) {
          return (
            <RdnaText color={'link'} onClick={() => showProfile(data.value.profile.id)}>
              {data.value.profile.name}
            </RdnaText>
          )
        }
      },
      {
        Header: 'Profile ID',
        accessor: setting => setting,
        width: 50,
        Cell: function createdCell(data: { value: FieldSettings }) {
          return data.value.profile.id
        }
      },
      {
        accessor: Keys.VIEW,
        width: COLUMN_WIDTH,
        disableSortBy: true,
        Header: function headerCell(data) {
          return (
            <RdnaCheckbox
              className={'header-setting'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(Keys.VIEW, checked)}
              value={
                !isLoading &&
                data.data.filter(m => !m.hidden && !m.profile.limit).length > 0 &&
                data.data.filter(m => !m.hidden && !m.profile.limit).every(value => value.view)
              }
              disabled={!isLoading && data.data.filter(m => !m.hidden && !m.profile.limit).length === 0}
              label={'View'}
              labelPlacement={'top'}
            />
          )
        },
        Cell: function createdCell(row) {
          return (
            <RdnaTooltip
              title={row.row.original.profile.limit ? LIMIT_TOOLTIP : row.row.original.hidden ? HIDDEN_TOOLTIP : ''}
              className={'tooltip-setting'}
            >
              <div>
                <RdnaCheckbox
                  onChange={(checked: boolean) => onChangeCheckbox(Keys.VIEW, checked, row.row.original.profile.id)}
                  value={row.row.original.view}
                  disabled={row.row.original.hidden || row.row.original.profile.limit}
                />
              </div>
            </RdnaTooltip>
          )
        }
      },
      {
        accessor: Keys.VISIBLE,
        width: COLUMN_WIDTH,
        disableSortBy: true,
        Header: function headerCell(data) {
          return (
            <RdnaCheckbox
              className={'header-setting'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(Keys.VISIBLE, checked)}
              value={
                !isLoading &&
                data.data.filter(m => m.view).length > 0 &&
                data.data.filter(m => m.view).every(value => value.visible)
              }
              disabled={!isLoading && data.data.filter(m => m.view).length === 0}
              label={'Always Visible'}
              labelPlacement={'top'}
            />
          )
        },
        Cell: function createdCell(row) {
          return (
            <RdnaCheckbox
              className={'checkbox-setting'}
              onChange={(checked: boolean) => onChangeCheckbox(Keys.VISIBLE, checked, row.row.original.profile.id)}
              value={row.row.original.visible}
              disabled={!row.row.original.view}
            />
          )
        }
      },
      {
        accessor: Keys.EDITABLE,
        width: COLUMN_WIDTH,
        disableSortBy: true,
        Header: function headerCell(data) {
          return (
            <RdnaCheckbox
              className={'header-setting'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(Keys.EDITABLE, checked)}
              value={
                !isLoading &&
                data.data.filter(m => m.updateable && m.view).length > 0 &&
                data.data.filter(m => m.updateable && m.view).every(value => value.editable && value.view)
              }
              disabled={!isLoading && data.data.filter(m => m.updateable && m.view).length === 0}
              label={'Editable'}
              labelPlacement={'top'}
            />
          )
        },
        Cell: function createdCell(row) {
          return (
            <RdnaCheckbox
              className={'checkbox-setting'}
              onChange={(checked: boolean) => onChangeCheckbox(Keys.EDITABLE, checked, row.row.original.profile.id)}
              value={row.row.original.editable}
              disabled={!row.row.original.view || !row.row.original.updateable}
            />
          )
        }
      },
      {
        accessor: Keys.REQUIRED,
        width: COLUMN_WIDTH,
        disableSortBy: true,
        Header: function headerCell(data) {
          return (
            <RdnaCheckbox
              className={'header-setting'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(Keys.REQUIRED, checked)}
              value={
                !isLoading &&
                data.data.filter(m => m.updateable && m.editable && m.view).length > 0 &&
                data.data.filter(m => m.updateable && m.editable && m.view).every(value => value.required)
              }
              disabled={!isLoading && data.data.filter(m => m.updateable && m.editable && m.view).length === 0}
              label={'Required'}
              labelPlacement={'top'}
            />
          )
        },
        Cell: function createdCell(row) {
          return (
            <RdnaCheckbox
              className={'checkbox-setting'}
              onChange={(checked: boolean) => onChangeCheckbox(Keys.REQUIRED, checked, row.row.original.profile.id)}
              value={row.row.original.required}
              disabled={!row.row.original.view || !row.row.original.updateable || !row.row.original.editable}
            />
          )
        }
      }
    ],
    [onChangeCheckbox, onChangeHeaderCheckbox, isLoading]
  )

  const noResultState = useMemo(
    () => ({
      renderCustom: () => (
        <RdnaCard className={'empty-salesforce-state'} padding="normal" width="fill">
          Select Salesforce field to view associated profiles.
        </RdnaCard>
      )
    }),
    []
  )

  return (
    <>
      <RdnaSmartTable
        columns={columns}
        data={settings || []}
        isFetching={isLoading}
        hideAboveContainer
        noResultState={noResultState}
      />
      <Actions>
        <RdnaButton
          text={'Cancel'}
          color="neutral"
          variant="text"
          name="cancel-save-field-settings"
          onClick={() => onClose(false)}
        />
        <RdnaButton
          disabled={
            isLoading ||
            !field.sfdcName ||
            !field.name.length ||
            field.name.length > 30 ||
            (!field.id && settings?.filter(s => s.view).length === 0)
          }
          text={'Save'}
          name="save-field-settings"
          onClick={onSave}
        />
      </Actions>
    </>
  )
}

export const Actions = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: ${props => 10 * props.theme.spacing}px;
`
