export const inputStrings = {
  notificationNames: {
    label: 'Notification Name',
    placeholder: 'Enter Notification Name Here'
  },
  teams: {
    label: 'Team Name',
    placeholder: 'Select Teams',
    placeholderSingle: 'Select Team'
  },
  users: {
    label: 'User Name',
    placeholder: 'Select Users',
    placeholderSingle: 'Select User'
  },
  callDisposition: {
    label: 'Call Disposition',
    placeholderSingle: 'Enter Call Disposition Here'
  },
  callQueueName: {
    label: 'Call Queue Name',
    placeholderSingle: 'Enter Call Queue Name Here'
  },
  keywordGroup: {
    label: 'Keyword Group',
    placeholderSingle: 'Enter Keyword Group Name Here'
  },
  locations: {
    label: 'Location',
    placeholder: 'Enter Location Name Here'
  },
  opportunityForecastCategories: {
    label: 'Forecast Category',
    placeholder: 'Select Forecast Categories'
  },
  opportunityOwners: {
    label: 'Owner',
    placeholder: 'Select Owners'
  },
  opportunityType: {
    label: 'Opportuntiy Type',
    placeholder: 'Enter Opportunity Type Here'
  },
  opportunityStage: {
    label: 'Opportuntiy Stage',
    placeholder: 'Enter Opportunity Stage Here'
  },
  supervisor: {
    label: 'Supervisor Name',
    placeholder: 'Enter Supervisor Name Here'
  },
  fiscalPeriod: {
    label: 'Fiscal Period',
    placeholder: 'Enter Fiscal Period Here'
  },
  userStatus: {
    label: 'User Status at time of missed ring',
    placeholder: 'Select User Status'
  }
}
