import DOMPurify from 'dompurify'

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeString(string: string): string {
  return string.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
}

export function capitalizeHyphenatedString(string: string): string {
  return capitalizeString(string.split('-').join(' '))
}

export function getNumberWithOrdinal(number: number): string {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const value = number % 100
  return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
}

export function camelCaseToTitleCase(string: string): string {
  const result = string.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const sanitizeString = (dirty: string, options: DOMPurify.Config = { USE_PROFILES: { html: true } }) =>
  `${DOMPurify.sanitize(dirty, options)}`

export function snakeCaseToTitleCase(string: string): string {
  return string.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
}
