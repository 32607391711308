import { InputTypes } from '@ringdna/common/src/constants/input-types'

export const API_BASE = '/api/v2/app/'

export const defaultWelcomeMessage = 'Add a custom message that will appear on  your personal booking page.'

export const getInputFields = (welcomeMessage: string) => [
  {
    label: 'Welcome Message',
    type: InputTypes.TEXT_AREA,
    name: 'welcomeMessage',
    placeholder: defaultWelcomeMessage,
    defaultValue: welcomeMessage === defaultWelcomeMessage ? '' : welcomeMessage,
    rows: 5,
    required: false
  }
]
