BaseView = require('base/view')
AudioView = require('../../widgets/audio/view')

class GreetingStepView extends BaseView

  template: require('./template')

  regions:
    audio: 'div.audio-view'

  initialize: (options) ->
    @callflow = options.callflow

  onRender: =>
    @showChildView('audio', new AudioView(model: @model, prefix: 'audio', callflow: @callflow))

module.exports = GreetingStepView