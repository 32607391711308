import React from 'react'
import Integration from './Integration'

type AccountProviderProps = {
  accountId: number
}

export default IntegrationProvider
export function IntegrationProvider({ accountId }: AccountProviderProps) {
  return <Integration accountId={accountId} />
}
