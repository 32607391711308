import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function BarChartIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M9.33333 7.55556H5.42222C4.9244 7.55556 4.67549 7.55556 4.48534 7.65244C4.31809 7.73766 4.1821 7.87364 4.09688 8.0409C4 8.23104 4 8.47995 4 8.97778V18.5778C4 19.0756 4 19.3245 4.09688 19.5147C4.1821 19.6819 4.31809 19.8179 4.48534 19.9031C4.67549 20 4.9244 20 5.42222 20H9.33333M9.33333 20H14.6667M9.33333 20L9.33333 5.42222C9.33333 4.9244 9.33333 4.67549 9.43022 4.48534C9.51544 4.31809 9.65142 4.1821 9.81868 4.09688C10.0088 4 10.2577 4 10.7556 4L13.2444 4C13.7423 4 13.9912 4 14.1813 4.09688C14.3486 4.1821 14.4846 4.31809 14.5698 4.48534C14.6667 4.67549 14.6667 4.9244 14.6667 5.42222V20M14.6667 11.1111H18.5778C19.0756 11.1111 19.3245 11.1111 19.5147 11.208C19.6819 11.2932 19.8179 11.4292 19.9031 11.5965C20 11.7866 20 12.0355 20 12.5333V18.5778C20 19.0756 20 19.3245 19.9031 19.5147C19.8179 19.6819 19.6819 19.8179 19.5147 19.9031C19.3245 20 19.0756 20 18.5778 20H14.6667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
