import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { ActionType, useSupportSettingsDispatch, useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { Section, SectionHeader } from '../styles'
import RdnaDropdown from '@ringdna/common/src/components/RdnaForm/RdnaDropdown'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { action } from '@ringdna/common/src/utils/storeUtils'
import Task from '@ringdna/common/src/assets/icons/svg/Task'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'

export default Tasks
function Tasks() {
  const state = useSupportSettingsState()
  const dispatch = useSupportSettingsDispatch()
  const sfdcCreateTasksEarly = 'settings.sfdcCreateTasksEarly'

  const handleTaskCreationDelayOptions = (e: any) => {
    dispatch(action(ActionType.CHANGE_ACCOUNT_SETTINGS, { 'settings.sfdcCreateTasksDelay': e.target.value }))
  }

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={Task} iconSize={14} variant="filled" />
        <RdnaText className={'rdna-text'}>Tasks</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Create Tasks Early'}
        description={
          'Create a call task at the beginning of a call, then update the task at the end. If disabled, the call task is created at the end of the call.'
        }
        isChecked={state.accountSettings[sfdcCreateTasksEarly]}
        setting={sfdcCreateTasksEarly}
      />
      <RdnaSettingRow
        heading={'Task Creation Delay'}
        description={
          "This setting toggles the delay time for task creation in Salesforce for an Account. The default selection of 15 seconds should remain unless an Account's workflow demands shorter delay times."
        }
      >
        <RdnaDropdown
          value={state.accountSettings['settings.sfdcCreateTasksDelay'].toString()}
          inputData={taskCreationDelayInputData}
          onChange={handleTaskCreationDelayOptions}
          onBlur={() => {}}
        />
      </RdnaSettingRow>
    </Section>
  )
}

const taskCreationDelayOptions = [
  { label: '15', value: '15' },
  { label: '10', value: '10' },
  { label: '5', value: '5' }
]

const taskCreationDelayInputData = {
  type: InputTypes.DROPDOWN,
  name: 'task-creation-delay',
  options: taskCreationDelayOptions
}
