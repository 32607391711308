import React, { useCallback, useMemo, useState } from 'react'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import {customLinksColumns, CUSTOM_LINKS_STR, NO_RESULT_STATE, initCustomLink} from './const'
import {useCallCustomLinks} from '../../api'
import {CallCustomLink, MainProps} from '../../types'
import CallCustomLinkModal from './CallCustomLinkModal'
import ConfirmCustomLinkDeleteModal from './ConfirmCustomLinkDeleteModal'

export default function CustomLinksTab({ accountId }: MainProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [customLink, setCustomLink] = useState<CallCustomLink>(initCustomLink)
  const [payload, , loading, refetch] = useCallCustomLinks({meta:{accountId: accountId.toString()}})

  const handleActionMenu = useCallback((action: string, link: CallCustomLink) => {
    setCustomLink(link)
    if (action === CUSTOM_LINKS_STR.delete) {
      setIsDeleteModalOpen(true)
    } else if (action === CUSTOM_LINKS_STR.edit) {
      setIsModalOpen(true)
    }
  }, [])

  const memoPayload = useMemo(() => {
    return payload?.map((template: CallCustomLink) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={[{ text: CUSTOM_LINKS_STR.edit }, { text: CUSTOM_LINKS_STR.delete }]} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  async function closeModals(isFetch: boolean) {
    setIsModalOpen(false)
    setIsDeleteModalOpen(false)
    if (isFetch) {
      refetch()
    }
  }

  return (
    <>
      <SectionHeader
        descriptionText={CUSTOM_LINKS_STR.header}
        rightColumn={ <RdnaButton
          style={{ width: 'fit-content' }}
          disabled={!!loading}
          text={'Add Custom Link'}
          name="add-custom-link"
          onClick={() => {
            setCustomLink(initCustomLink)
            setIsModalOpen(true)
          }}
        />}
      />
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={customLinksColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        isFetching={!!loading}
        paginationSize={10}>
      </RdnaSmartTable>
      <CallCustomLinkModal
        open={isModalOpen}
        onClose={closeModals}
        accountId={accountId}
        customLink={customLink}
        setCustomLink={setCustomLink}
      />
      <ConfirmCustomLinkDeleteModal
        open={isDeleteModalOpen}
        onClose={closeModals}
        id={customLink.id}
      />
    </>
  )
}
