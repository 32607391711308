import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import React, { useMemo } from 'react'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE } from '../const'
import { Role, User } from '../types'
import { assignedUsersColumns } from '../columns'

export type SuccessRoleAssignmentPanelProps = {
  roles: Role[]
  listUser: User[]
}

export default function SuccessRoleAssignmentPanel({ listUser, roles }: SuccessRoleAssignmentPanelProps) {
  const tableData = useMemo(() => {
    return listUser.map((user: User) => ({
      ...user,
      roleName: roles.find(role => role.id === user.roleId)?.name
    }))
  }, [listUser, roles])

  return (
    <RdnaSmartTable
      // @ts-ignore
      columns={assignedUsersColumns(3)}
      data={tableData}
      noResultState={NO_RESULT_STATE}
      paginationSize={DEFAULT_PAGE_SIZE}
    />
  )
}
