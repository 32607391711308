import { HttpMethods } from '@ringdna/client/src/constants'
import { RdnaAccount } from '@ringdna/common/src/types/accounts'
import { AccountSettings, LockedSettings } from '../../components/call-recording-tab/utils/types'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import { AccountDialerSettings } from '../../components/support-settings/utils/types'

const API_BASE = '/api/v2/app/accounts'
const DIALER_PATH = 'dialerSettings'

export type PicklistAccount = {
  text: string
  value: number
}

type UseAccountsParams = {
  meta: {
    id: string
  }
}

export const useAccount = createUseFetch<RdnaAccount, UseAccountsParams>({
  key: 'use-account',
  path: ({ meta }) => `${API_BASE}/${meta.id}`,
  independent: true
})

export type PutAccountParams = {
  body: AccountSettings
}

export const useUpdateAccount = createUseFetchAction<RdnaAccount, PutAccountParams>({
  key: 'put-account',
  path: ({ body }: PutAccountParams) => `${API_BASE}/${body['account.id']}`,
  method: HttpMethods.Put
})

type putAccountLockedSettingsParams = {
  body: LockedSettings
}

export const usePutAccountLockedSettings = createUseFetchAction<RdnaAccount, putAccountLockedSettingsParams>({
  key: 'put-account-locked-settings',
  path: ({ body }: putAccountLockedSettingsParams) => `${API_BASE}/${body['lockedSetting.accountId']}/lockedSettings`,
  method: HttpMethods.Put
})

export const useAccountDialerSettings = createUseFetch<DialerSettings, UseAccountsParams>({
  key: 'use-account-dialer-settings',
  path: ({ meta }) => `${API_BASE}/${meta.id}/${DIALER_PATH}`,
  independent: true
})

type putAccountDialerSettings = {
  body: AccountDialerSettings
}

export const useUpdateAccountDialerSettings = createUseFetchAction<DialerSettings, putAccountDialerSettings>({
  key: 'put-account-dialer-settings',
  path: ({ body }: putAccountDialerSettings) => `${API_BASE}/${body['account.id']}/${DIALER_PATH}`,
  method: HttpMethods.Put
})
