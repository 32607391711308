Handlebars = require('handlebars/runtime').default
BaseCollection     = require('base/collection')
BaseGrid           = require('base/grid')
BaseView           = require('base/view')
VoicemailModal     = require('widgets/voicemail-modals/main-view')
VoicemailDropModel = require('widgets/voicemail-modals/model')

class VoicemailDropsView extends BaseView
  template: require('./template')
  className: 'voicemail-drops'
  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add-voicemail-drop': 'openVoicemailDropModal'

  initialize: (options) ->
    { @model, @collection } = options
    @modal = @getRegion('modal')

  onRender: =>
    @showChildView('grid', @buildGrid())

  buildGrid: =>
    editAudioCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        html = ''
        if not @model.get('deletable')
          html = """<img src="#{Handlebars.helpers.imageUrl('icon-group.svg')}"
            data-name='group' alt='Group' title='Group'>""" if not @model.get('isGlobal') and not @model.get('owner')?
          html = """<img src="#{Handlebars.helpers.imageUrl('icon-globe.svg')}"
            data-name='global' alt='Global' title='Global'>""" if @model.get('isGlobal')
        else
          html = """<button class='btn btn-primary update-voicemail' data-action='update' data-toggle='modal'
            data-target="#{@column.get('name')}">Update</button>""" if not @model.get('isDefault')
        $(@el).html(html)
        @

    columns = [
      { name: 'order',                label: 'Order',  cell: BaseGrid.OrderCell },
      { name: 'name',                 label: 'Label',  cell: 'string' },
      { name: 'url',                  label: 'URL',    cell: 'uri' },
      { name: 'id',                   label: 'ID',     cell: 'string' },
      { name: '',                     label: 'Listen', cell: BaseGrid.ListenAudioCell },
      { name: 'voicemail-drop-modal', label: 'Update', cell: editAudioCell },
      { name: '',                     label: 'Delete', cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No voicemail drops found.'
      onEdit     : @openVoicemailDropModal
      onRemove   : @updatedOrderIds
      onMoveOne  : @moveOne
      onMoveBound: @moveBound
    )

  openVoicemailDropModal: (options) =>
    model = options.model?.clone()
    model ?= new VoicemailDropModel()
    @modal.show(new VoicemailModal(
      userId      : @model.get('id')
      collection  : @collection
      model       : model
      modalTitle  : 'Voicemail Drop'
    ))

  updatedOrderIds: =>
    @model.set('orderIdsVoicemailDrops', @collection.pluck('id').join(','))

  moveOne: (options) =>
    model = options.model
    index = @collection.indexOf(model)
    if index isnt -1
      @collection.remove(model)
      @collection.add(model, {at: if options.move then index - 1 else index + 1})
      @save()

  moveBound: (options) =>
    model = options.model
    index = @collection.indexOf(model)
    if index isnt -1
      @collection.remove(model)
      if options.move then @collection.unshift(model) else @collection.push(model)
      @save()

  save: =>
    @updatedOrderIds()
    App.api.put(
      path    : 'voicemailDrops/reorder'
      data    :  userId: @model.get('id'), vmDropsIds: @model.get('orderIdsVoicemailDrops'))

module.exports = VoicemailDropsView
