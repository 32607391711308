import { FormSelectOption } from '../../types/form-inputs'

export const getFormOptionFromStrings = (options?: (string | FormSelectOption)[]): FormSelectOption[] => {
  if (!options) return [{ value: '', label: '' }]
  return options.map(option => {
    if (typeof option === 'string') {
      return { value: option, label: option }
    } else {
      return option
    }
  })
}
