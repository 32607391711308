import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function ThreeArrowsIcon({ size = 60, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="three-arrows-icon">
      <g clipPath="url(#clip0_9043_75761)">
        <g clipPath="url(#clip1_9043_75761)">
          <path d="M4.1665 14.5714H25.8332" stroke={color} strokeLinecap="round" />
          <path d="M19.1665 7.71436L25.8332 14.5715" stroke={color} strokeLinecap="round" />
          <path d="M19.3391 21.2511L25.8333 14.5714" stroke={color} strokeLinecap="round" />
        </g>
        <g clipPath="url(#clip2_9043_75761)">
          <path d="M4.1665 32.1428H40.8332" stroke={color} strokeLinecap="round" />
          <path d="M34.1665 25.2856L40.8332 32.1428" stroke={color} strokeLinecap="round" />
          <path d="M34.3391 38.8225L40.8333 32.1428" stroke={color} strokeLinecap="round" />
        </g>
        <g clipPath="url(#clip3_9043_75761)">
          <path d="M4.1665 48.75H55.8332" stroke={color} strokeLinecap="round" />
          <path d="M49.1665 42.0834L55.8332 48.75" stroke={color} strokeLinecap="round" />
          <path d="M49.3391 55.2441L55.8333 48.75" stroke={color} strokeLinecap="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9043_75761">
          <rect width={size} height={size} fill={color} />
        </clipPath>
        <clipPath id="clip1_9043_75761">
          <rect width={23.3333} height={15.4286} fill={color} transform="translate(3.33325 6.85718)" />
        </clipPath>
        <clipPath id="clip2_9043_75761">
          <rect width={38.3333} height={17.1429} fill={color} transform="translate(3.33325 23.5714)" />
        </clipPath>
        <clipPath id="clip3_9043_75761">
          <rect width={53.3333} height={16.6667} fill={color} transform="translate(3.33325 40)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ThreeArrowsIcon
