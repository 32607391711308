import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <rect id="Rectangle-8" stroke="#FE3202" strokeWidth="2" x="1" y="1" width="48" height="48" rx="12"></rect>
          <path
            d="M36.4127547,23.2659829 C29.6426402,23.2686607 22.8721351,23.267513 16.1020206,23.267513 C11.7060554,23.267513 11.7060554,23.267513 11.7060554,18.9199186 L11.7060554,13.0172892 C12.3755671,12.6817995 12.7290724,13.1967016 13.133748,13.4342604 C16.59185,15.4663257 20.0624516,17.480794 23.4658677,19.5970186 C24.5310709,20.2591995 25.3056577,20.3380033 26.4157814,19.6360379 C29.69342,17.5638057 33.0573841,15.622403 36.4049424,13.6584303 C36.9346144,13.3478058 37.3924135,12.8248704 38.2939497,12.8853121 C38.2939497,15.9380005 38.2771533,18.9719443 38.3013713,22.0055056 C38.3123085,23.4014029 37.3275717,23.2656003 36.4127547,23.2659829 Z M36.1459656,11.8291125 C32.4195121,14.0505362 29.0469545,16.0474076 25.6927557,18.0741173 C25.0962888,18.4344722 24.6466926,18.315119 24.0990523,17.9849849 C20.7866492,15.9892612 17.4570591,14.0218456 13.7688857,11.8291125 L36.1459656,11.8291125 Z M39.9802285,13.223097 C39.9622602,10.7824764 39.1990013,10.0273377 36.7123553,10.0131837 C32.807782,9.99137875 28.9028181,10.0074455 24.9978542,10.0078281 C21.0280484,10.0082106 17.0582426,9.98487553 13.0888275,10.0170091 C10.853346,10.0353711 10.0490726,10.8375626 10.0252452,13.0375639 C9.99321489,16.032871 9.98891814,19.0289431 10.0283701,22.0238677 C10.0553224,24.0785029 10.9193597,24.967914 12.9970333,24.97633 C21.0014867,25.0076984 29.0063307,25.008081 37.0107841,24.97633 C39.0857234,24.9675315 39.9466357,24.0758251 39.9739786,22.0181295 C40.0134306,19.0870896 40.0017122,16.1545195 39.9802285,13.223097 Z"
            id="Fill-1"
            fill="#FE3202"
          ></path>
          <text id="IMAP" fontFamily="Logical-Bold, Logical" fontSize="12" fontWeight="bold" fill="#FE3202">
            <tspan x="11" y="37">
              IMAP
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
