BaseModel = require('base/model')

class UserOverviewModel extends BaseModel
  initialize: (options) =>
    @userId = options.userId

  urlRoot: =>
    "users/#{@userId}/overview"

  parse: (response) =>
    response.user.userPresence = response.userPresence
    delete response.userPresence
    response

  user: =>
    new Backbone.Model(@get('user'))

module.exports = UserOverviewModel
