BaseView          = require('base/view')
CallRouting       = require('src/components/call-routing').default
ProductConstant   = require('@ringdna/common/src/constants/products')
ReactComponent    = require('src/ReactComponent').default

class CallRoutingView extends BaseView
  template: require('./template')
  className: 'call-routing-view'

  regions:
    container  : '.call-routing'

  initialize: (options) =>
    { @accountId, @qs, @tab } = options

  onRender: =>
    @showChildView('container',
      new ReactComponent({
        component: CallRouting,
        theme: ProductConstant.Products.ADMIN,
        props: { accountId: @accountId, tab: @tab, callFlowEdit: @user().can('call-flows:edit:me') }
      })
    )

module.exports = CallRoutingView
