import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceFavoriteIcon({ size = 14, color }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 13" fill="none" data-testid={'salesforce-favorite-icon'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10l-3.078 1.618a.25.25 0 01-.363-.263l.588-3.428L.657 5.5a.25.25 0 01.138-.426l3.442-.5 1.539-3.119a.25.25 0 01.448 0l1.54 3.119 3.441.5a.25.25 0 01.139.426l-2.49 2.428.587 3.428a.25.25 0 01-.363.263L6 10z"
        fill={color || Colors.SALESFORCE_FAVORITE}
        stroke={color || Colors.SALESFORCE_FAVORITE}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SalesforceFavoriteIcon
