import React, { useMemo, useState } from 'react'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { formatDateTime } from '@ringdna/common/src/utils/formatter'
import { NylasUser, NylasUserDetails } from '../model'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { Column } from 'react-table'
import DeleteIcon from '@ringdna/common/src/assets/icons/svg/Delete'
import { IconButton } from '@mui/material'
import { providerOptions, Provider } from '../constants'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { integrationItems } from '../../../integrations/models'
import styled from 'styled-components'

type Props = {
  nylasUsers: NylasUser[]
  removeUser: (user: NylasUser) => void
  isSupport: boolean
  provider: Provider
  handleProviderChange: (providerValue: Provider) => void
}

export default function NylasUsersTable({ nylasUsers, removeUser, isSupport, provider, handleProviderChange }: Props) {
  const [userToDelete, setUserToDelete] = useState<NylasUser>()
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false)

  function openConfirmDialog(user: NylasUser) {
    setUserToDelete(user)
    setConfirmModalIsOpen(true)
  }

  const onConfirmModalClose = (userConfirmed: boolean) => {
    if (userConfirmed && userToDelete) {
      removeUser(userToDelete)
    }
    setConfirmModalIsOpen(false)
  }

  const columns = useMemo<Column<NylasUser>[]>(
    () =>
      [
        isSupport
          ? { Header: 'Nylas ID', accessor: 'nylasId', className: 'nylas-column' }
          : { id: 'hidden-nylasId', width: 0 },
        {
          Header: 'User',
          accessor: 'user',
          Cell: function createdCell(data: { value: NylasUserDetails }) {
            return <a href={`#users/${data.value.id}`}>{data.value.displayName}</a>
          }
        },
        { Header: 'Email Address', accessor: 'email', className: 'nylas-column' },
        {
          Header: 'Provider',
          accessor: 'provider',
          disableFilters: false,
          Cell: function createdCell(data: { value: IntegrationProviderTypes }) {
            return integrationItems[data.value].label
          },
          Filter: function fn() {
            return (
              <RdnaSelect
                name={'headerText'}
                label={'Provider'}
                options={providerOptions}
                value={provider}
                onChange={(event: any) => {
                  return handleProviderChange(event.target.value)
                }}
              />
            )
          },
          width: 70
        },
        { Header: 'Sync State', accessor: 'status' },
        isSupport
          ? {
              Header: 'Linked At',
              accessor: 'linkedAt',
              Cell: function createdCell(data: { value: number }) {
                return formatDateTime(data.value)
              }
            }
          : { id: 'hidden-linkedAt', width: 0 },
        {
          Header: 'Remove',
          accessor: user => user,
          width: 50,
          Cell: function createdCell(data: { value: NylasUser }) {
            return (
              <IconButton aria-label="remove-user" onClick={() => openConfirmDialog(data.value)}>
                <DeleteIcon title={'Remove'} />
              </IconButton>
            )
          }
        }
      ] as Column<NylasUser>[],
    [isSupport, provider, handleProviderChange]
  )

  return (
    <Content>
      <RdnaModal
        open={confirmModalIsOpen}
        heading="Warning"
        onClose={() => onConfirmModalClose(false)}
        confirmButtonText="Delete"
        onConfirm={() => onConfirmModalClose(true)}
      >
        {userToDelete && (
          <RdnaText>{`You are about to remove the ${userToDelete.name} account from ringDNA?`}</RdnaText>
        )}
      </RdnaModal>
      <RdnaSmartTable
        columns={columns}
        data={nylasUsers}
        paginationSize={10}
        globalSearchLabel="Search"
        noResultState={{ title: 'No Connected Users Found.', subtitle: '' }}
      />
    </Content>
  )
}

const Content = styled.div`
  .nylas-column {
    margin-right: ${({ theme }) => 2 * theme.spacing}px;
    word-break: break-word;
  }
`
