BaseGrid = require('base/grid')
BaseView = require('base/view')
UserTeamModal = require('./editor/view')
UserTeamCollection = require('./collection')
BackgridCell      = require('helpers/backgrid-cell')

class UserTeamView extends BaseView

  template: require('./template')

  className: 'userTeam'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.edit-user-team': 'showEditor'

  bindings:
    '.edit-user-team .glyphicon-plus-sign' : 'toggle:any(isSupport,isAdmin)'

  computeds:
    isAdmin:
      get: -> App.session.user.isAdmin()
    isSupport:
      get: -> App.session.user.isSupport()
    isAgent:
      get: -> App.session.user.isAgent()

  initialize: (options) ->
    { @user } = options
    @collection = new UserTeamCollection(@user.userTeams.filter((ut) -> not ut.team.isDeleted))

  onRender: ->
    @showChildView('grid', @buildGrid())

  buildGrid: =>
    TypeCell = class extends Backgrid.StringCell
      render: ->
        $(@el).empty()
        type = if @model.get('type') is 'Supervisor' then 'Supervisor' else 'Member'
        $(@el).text(type)
        @delegateEvents()
        @

    linkToTeamCell = if @getBinding('isAgent') then 'String' else BackgridCell.href(
      url   : '#teams/:team.id',
      label : ':team.name',
      attrs : ['team.id', 'team.name'])

    columns = [
      { name: 'team.name',                label: 'Name',         cell: linkToTeamCell },
      { name: 'team.description',         label: 'Description',  cell: 'String' },
      { name: 'team.settings.openBarge',  label: 'Open Barge',   cell: 'String' },
      { name: 'type',                     label: 'Role',         cell: TypeCell },
      { name: '',                         label: 'Delete',       cell: BaseGrid.DeleteCell, renderable: @getBinding('isSupport') or @getBinding('isAdmin') }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No User Teams Found.'
      onEdit     : @showEditor
    )

  showEditor: =>
    @showChildView('modal', new UserTeamModal(
      userTeamCollection: @collection
      user: @user
    ))

module.exports = UserTeamView
