AddressCollection  = require('widgets/smart-numbers-widget/address-collection')
AddressStepModal   = require('features/call-tracking/address-step/address-step-view')
AdvancedModal       = require('./editor/view')
AdvancedCallTrackingСollection = require('./collection')
AdvancedCallTrackingModel      = require('./model')
BackgridCell       = require('helpers/backgrid-cell')
BackgridRow        = require('helpers/backgrid-row')
BaseView           = require('base/view')
BaseCollection     = require('base/collection')
CallflowPickerView = require('widgets/callflow-picker/view')
CallTrackingHelper = require('features/call-tracking/helper')
PagedCollection    = require('base/paged-collection')
PagerView          = require('widgets/pager/view')

class AdvancedPagedCollection extends PagedCollection
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    for advance in response.results
      advance['labelTracking'] = advance.label
      result.push(advance)
    result

class AdvancedView extends BaseView
  template: require('./template')

  regions:
    grid    : 'div.grid'
    pager   : 'span.pager'
    modal   : 'div.modal-container'
    callFlowPicker  : '.call-flow-picker'
    addressStepModal: 'div.address-step-container'

  ui:
    search  : 'button.search'
    searchLabel  : 'input.searchLabel'

  events:
    'click @ui.search' : 'search'
    'click a.add' : 'create'

  bindings:
    '.add': "classes:{'disabled':addButtonDisabled}"

  computeds:
    isSupport:
      get: -> @user().isSupport()

  initialize: (options) =>
    { @qs, @accountId} = options
    @qs.setFragment('call-tracking/advanced')
    @viewModel = new Backbone.Model(
      addButtonDisabled: false
    )

  onRender: =>
    @advancedCollection = new AdvancedCallTrackingСollection()
    @callFlowPickerView = new CallflowPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      label: 'none'
    )
    @showChildView('callFlowPicker', @callFlowPickerView)
    @viewModel.set('addButtonDisabled', @user().getSelectedSupportAccountId()?.toString() isnt @accountId.toString())
    @qs.bind(
      searchLabel: @ui.searchLabel
      callFlowId: @callFlowPickerView
    )
    @qs.restore()
    @search()

  onDestroy: =>
    @stopListening()

  search: =>
    @collection = new AdvancedPagedCollection(
      page: @qs.get('page')
      queryParams:
        searchLabel: @ui.searchLabel.val().trim()
        callFlowId: @callFlowPickerView.val()
        accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      url: 'advanced'
    )

    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, qs: @qs))
    @collection.fetch(reset: true)

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:smartNumber.id',
      label : ':smartNumber.number',
      attrs : ['smartNumber.id', 'smartNumber.number'])

    customCell = class extends Backgrid.Cell
      render: ->
        extraInfo = if @model.get('extraInfo') then @model.get('extraInfo') else '{}'
        info = JSON.parse(extraInfo)
        $(@el).html(info?.label)
        @

    customHeaderCell = class extends Backgrid.HeaderCell
      onClick: ->
        return
      render: ->
        $(@el).html('<a>Custom Parameter Value</a>')
        @

    flowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    columns = [
      {label: 'Account',                name: 'account.name',   cell: 'string', renderable: @getBinding('isSupport')}
      {label: 'Name',                   name: 'labelTracking',  cell: 'string'}
      {label: 'Custom Parameter Value', name: 'extraInfo',      cell: customCell, headerCell: customHeaderCell}
      {label: 'Call Flow',              name: 'callFlow',       cell: flowCell}
      {label: 'Number',                 name: 'number',         cell: numbCell}
      {label: 'Campaign',               name: 'campaign',       cell: 'string'}
      {label: 'Date Created',           name: 'createdDate',    cell: datetime}
      {label: 'Date Last Used',         name: 'smartNumber.dateLastUsed', cell: datetime}
    ]

    new Backgrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No advanced call tracking found.'
    )

  initModal: =>
    @advancedCollection.reset([new AdvancedCallTrackingModel()])
    @advancedModal = new AdvancedModal(
      accountId: @accountId
      collection: @advancedCollection
    )
    @listenTo(@advancedCollection, 'newCallTracking', (advanced) =>
      advanced['name'] = advanced.labelTracking
      @collection?.unshift(advanced)
      @collection?.pop() if @collection.length > 30
      @collection.state.totalRecords += 1
      @collection.trigger('pager:state:changed', @collection.state)
      toastr.info('Advanced Call Tracking saved.')
      @search() if @collection.length > 30
    ) if not @advancedCollection?._events?.newCallTracking
    @listenTo(@advancedModal, 'openAddressStepModal', => @initAddressStepModal())

  create: =>
    @initModal()
    @showChildView('modal', @advancedModal)

  initAddressStepModal: =>
    @addressCollection = new AddressCollection()
    addressStepModal = new AddressStepModal(
      accountId: @accountId
      addressCollection: @addressCollection
      title: 'Add Advanced Call Tracking'
      collection: @advancedCollection
    )
    @showChildView('addressStepModal', addressStepModal)
    $('#address-step-modal').modal()
    @listenTo(addressStepModal, 'repeatCreateCallTrackings', (ladda, notCreatedAddresses) =>
      notAddressesNumbers =
        CallTrackingHelper.filterRepeatCollection(notCreatedAddresses, @advancedCollection) if notCreatedAddresses.length isnt 0
      @advancedCollection.createAdvances(@accountId, notAddressesNumbers, =>
        ladda.stop()
        createdAddresses = @addressCollection.where(created: true)
        @addressCollection.remove(createdAddresses) if createdAddresses.length isnt 0
        $('#address-step-modal').modal('hide') if not notAddressesNumbers or notAddressesNumbers.length is 0
      ))

module.exports = AdvancedView
