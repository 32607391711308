import React, {useState} from 'react'
import styled from 'styled-components'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import {CallNoteTemplateForm, OptionTeam} from '../../types'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import {useCallNoteTemplatesCreate, useCallNoteTemplatesUpdate} from '../../api'
import {renderTeamOption} from '../../utils/utils'

type CallNoteTemplateProps = {
  open: boolean
  onClose: (isFetch: boolean) => void
  accountId: number
  callNotesTemplate: CallNoteTemplateForm
  setCallNotesTemplate: (value: CallNoteTemplateForm) => void
  teams: OptionTeam[]
}

export default function CallNotesTemplatesModal({ open, onClose, accountId, callNotesTemplate, setCallNotesTemplate, teams }: CallNoteTemplateProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error } = useRdnaNotification()
  const [placeholder, setPlaceholder] = useState(callNotesTemplate.teamIds.length > 0 ? undefined : 'All Teams')
  const createCallNoteTemplate = useCallNoteTemplatesCreate()
  const updateCallNoteTemplate = useCallNoteTemplatesUpdate()

  async function handleConfirm() {
    setProcessing(true)
    try {
      if (callNotesTemplate.id) {
        await updateCallNoteTemplate({
          meta: { id: callNotesTemplate.id },
          body: {
            accountId: accountId,
            name: callNotesTemplate.name,
            template: callNotesTemplate.template,
            enabled: callNotesTemplate.enabled,
            teamIds: callNotesTemplate.teamIds
          }
        })
      } else {
        await createCallNoteTemplate({
          body: {
            accountId: accountId,
            name: callNotesTemplate.name,
            template: callNotesTemplate.template,
            enabled: callNotesTemplate.enabled,
            teamIds: callNotesTemplate.teamIds
          }
        })
      }
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
    onClose(true)
  }

  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Save'}
      heading={!callNotesTemplate?.id ? 'Add Call Notes Template' : 'Edit Call Notes Template'}
      onClose={() => onClose(false)}
      onConfirm={handleConfirm}
      confirmButtonDisabled={!callNotesTemplate?.name || !callNotesTemplate.template}
      confirmButtonProcessing={processing}
    >
      <StyledSection>
        <RdnaTextInput
          name={'call-notes-template-name'}
          label={'Name'}
          placeholder={'Template Name'}
          required
          onChange={e => {
            setCallNotesTemplate({ ...callNotesTemplate, name: e.target.value })
          }}
          value={callNotesTemplate?.name}
          style={{marginBottom: 30}}
        />
        <RdnaTextInput
          name={'call-notes-template-template'}
          label={'Template'}
          placeholder={'Template copy'}
          required
          rows={3}
          multiline
          onChange={e => {
            setCallNotesTemplate({ ...callNotesTemplate, template: e.target.value })
          }}
          value={callNotesTemplate?.template}
          style={{marginBottom: 30}}
        />
        <RdnaAutocomplete
          label={'Teams'}
          multiple
          placeholder={placeholder}
          isOptionEqualToValue={(option: OptionTeam, value: OptionTeam) => {
            return option.value === value.value
          }}
          options={teams}
          value={teams.filter(option => callNotesTemplate.teamIds.indexOf(option.value) > -1)}
          onChange={(event, values) => {
            const newData: CallNoteTemplateForm = { ...callNotesTemplate, teamIds: values.map(value => value.value) }
            setCallNotesTemplate(newData)
            setPlaceholder(newData.teamIds.length > 0 ? undefined : 'All Teams')
          }}
          showOnlyMultipleCount = {true}
          showChips = {true}
          onChipRemove={value => {
            const newData: CallNoteTemplateForm = {...callNotesTemplate, teamIds: callNotesTemplate.teamIds.filter(id => id != value.value)}
            setCallNotesTemplate(newData)
            setPlaceholder(newData.teamIds.length > 0 ? undefined : 'All Teams')
          }}
          getOptionLabel={option => option.text}
          renderOption={renderTeamOption}
        />
      </StyledSection>
    </RdnaModal>
  )
}

const StyledSection = styled.section`
  min-width: 300px;
`
