import React from 'react'
import ReportIframe from '../common/reports/ReportIframe'
import { fetchReportUrl } from './smart-numbers-api'

type Props = {
  accountId: number
  smartNumberId: number
}

export default function SmartNumberMetrics({ accountId, smartNumberId }: Props) {
  const [data] = fetchReportUrl({
    query: { name: 'smart-number-embedded-analytics', accountId, smartNumberId, dateFilter: 'past7days~' }
  })

  return <>{data && <ReportIframe url={data.iframeUrl} />}</>
}
