import React, { useEffect, useMemo, useState } from 'react'

import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import RdnaHorizontalTab from '@ringdna/common/src/components/RdnaHorizontalTab'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import { Container } from './styles'
import { useAccountDialerSettings, useUpdateAccount } from '../../../store/api/accounts-api'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import GeneralSettings from './tabs/general-settings/GeneralSettings'
import RelatedRecords from './tabs/related-records/RelatedRecords'
import Support from './tabs/support/Support'
import { useUpdateInvalidNotifyUser } from '../../../store/api/salesforceInspector-api'
import { RdnaAccount } from '@ringdna/common/src/types/accounts'
import { useUser } from '../../common/services/UserContext'
import Fields from './tabs/salesforce-fields/Fields'
import { useSalesforceAvailableFieldsPreload } from '../../../store/api/salesforce-fields-api'
import useToast from '../../common/hooks/useToast'

type SalesforceTabsProps = {
  accountId: number
}

export default SalesforceTabs
function SalesforceTabs({ accountId }: SalesforceTabsProps) {
  const { isSupport } = useUser()
  const toastr = useToast()
  const [dialerSettings, error, loading] = useAccountDialerSettings({ meta: { id: accountId.toString() } })
  const updateAccount = useUpdateAccount()
  const updateInvalidNotifyUser = useUpdateInvalidNotifyUser()
  const [isLoading, setIsLoading] = useState(false)
  const [settings, setSettings] = useState<{ [key: string]: any }>({})
  useSalesforceAvailableFieldsPreload({ meta: { id: accountId } })

  useEffect(() => {
    error && toastr.warning(error.message)
  }, [error, toastr])

  const tabs = useMemo(() => {
    const arrayTabs = [
      {
        id: 'general-settings',
        title: 'General Settings',
        Content: (
          <>
            {dialerSettings && (
              <GeneralSettings dialerSettings={dialerSettings} setSettings={setSettings} isSupport={isSupport} />
            )}
          </>
        )
      },
      {
        id: 'fields',
        title: 'Fields',
        Content: <>{dialerSettings && <Fields accountId={dialerSettings.account.id} />}</>
      },
      {
        id: 'related-records',
        title: 'Related Records',
        Content: <>{dialerSettings && <RelatedRecords dialerSettings={dialerSettings} setSettings={setSettings} />}</>
      }
    ]
    if (isSupport) {
      arrayTabs.push({
        id: 'support',
        title: 'Support',
        Content: <>{dialerSettings && <Support dialerSettings={dialerSettings} setSettings={setSettings} />}</>
      })
    }
    return arrayTabs
  }, [dialerSettings, isSupport])

  const onSave = async () => {
    try {
      setIsLoading(true)
      const promises = [] as any
      if (dialerSettings && Object.keys(settings).length > 0) {
        const accountSettings = { 'account.id': dialerSettings.account.id }
        Object.keys(dialerSettings?.account.settings).forEach(key => {
          // @ts-ignore
          accountSettings[`settings.${key}`] = dialerSettings?.account.settings[key]
        })
        Object.keys(settings).forEach(key => {
          // @ts-ignore
          accountSettings[key] = settings[key]
        })
        // @ts-ignore
        promises.push(updateAccount({ body: accountSettings } as PutAccountParams))
        const adminNotifications = settings['adminNotifications']
        if (adminNotifications) {
          Object.keys(adminNotifications).forEach(key => {
            promises.push(
              updateInvalidNotifyUser({
                body: {
                  accountId: dialerSettings.account.id,
                  userId: key,
                  sendInvalidNotification: adminNotifications[key]
                }
              })
            )
          })
        }
      }
      await Promise.all(promises).then(values => {
        const payload = values[0]?.success?.payload as RdnaAccount
        if (payload && dialerSettings) {
          dialerSettings.account = payload
        }
      })
      toastr.success('Settings Saved')
      setIsLoading(false)
    } catch (error) {
      toastr.error(`An error has occurred, ${error}`)
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <SectionHeader
        headingText="Integrations: Salesforce"
        rightColumn={
          <RdnaButton
            disabled={isLoading}
            text={isLoading ? 'Saving...' : 'Save'}
            name="save-salesforce-settings"
            onClick={onSave}
          />
        }
      />
      <RdnaLoader
        data={dialerSettings}
        noResultsMsg={<NoResultsMsg title="Something went wrong, try refreshing!" Icon={<BinocularsIconLeaves />} />}
        loading={!!loading}
        top="150px"
      >
        <RdnaHorizontalTab renderIndividually tabs={tabs} />
      </RdnaLoader>
    </Container>
  )
}
