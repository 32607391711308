StepModel = require('../../model')

class TimeStepModel extends StepModel


  defaults:
    args: {}
    type: 'Time'
    info: 'Route calls based on your business hours by setting your business hours below. Use 12-hour or 24-hour time format. For example: 3:00 PM or 15:00.'
    fixedPlaceholders: ['Open Step', 'Closed Step', 'Holiday Schedule']
    days: [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ]
    leaf: false

  hoursId: ->
    @args()['hoursId']

  hoursType: ->
    @args()['hoursType']

  orderChildren: (children) ->
    result = []
    for placeholder in _.union(@fixedPlaceholders())
      childId = @args()[placeholder]
      if childId
        result.push(child if parseInt(child.get('id')) == parseInt(childId)) for child in children
    result

  formatTime: (val) ->
    hrs = val.getHours()
    mns = val.getMinutes()

    indicator = 'AM'
    if hrs > 11
      indicator = 'PM'
      if hrs > 12
        hrs -= 12
      else
        hrs = 12
    else if hrs == 0
      hrs = 12
    if mns < 10
      mns = '0' + mns

    "#{hrs}:#{mns} #{indicator}"


  errors: ->
    messages = []
    selectors = []
    if not @args()['timezone']
      messages.push('Timezone is required')
      selectors.push('.timezoneSelector')
    for day in @get('days')
      fromVal = @args()["#{day}-from"]
      toVal = @args()["#{day}-to"]
      if (toVal and not fromVal) or (fromVal and not toVal)
        messages.push('Missing start and/or end time')
        selectors.push(".#{day}")
      else if fromVal and toVal
        from = @parseTime(fromVal)
        to = @parseTime(toVal)
        if not from or not to
          messages.push('Use hh:mm am/pm format')
          selectors.push(".#{day}")
        else
          @args()["#{day}-from"] = @formatTime(from)
          @args()["#{day}-to"] = @formatTime(to)
          if to <= from
            messages.push('End time must be greater than start time')
            selectors.push(".#{day}")

    _.zip(messages, selectors)

  parseTime: (val) ->
    d = new Date()
    time = val.match(/(\d+)(:(\d\d))?\s*([pP]?)/i)
    return null if not time or time.length == 0
    h = parseInt(time[1], 10)
    if time[4]
      if h < 12
        h += 12
    else if h is 12
      h = 0
    d.setHours(h)
    d.setMinutes(parseInt(time[3], 10) || 0)
    d.setSeconds(0, 0)
    d


module.exports = TimeStepModel