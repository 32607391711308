import React from 'react'
import { SnackbarProvider } from 'notistack'
import { useFetchTeamsScript } from './api'

import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import VideoUsersContainer from './VideoUsersContainer'
import TeamsEnablement from './TeamsEnablement'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import styled from 'styled-components'

type IntegrationProps = {
  accountId: string
}

export default function VideoUsers({ accountId }: IntegrationProps) {
  const query = { query: { accountId: accountId } }
  const [payload, , loading, refetchScriptEnabled] = useFetchTeamsScript(query)
  const teamsEnabled = payload?.isEnabled
  return (
    <SnackbarProvider maxSnack={1}>
      <Container>
        <SectionHeader headingText={'Integrations: Microsoft Teams'} />
        <RdnaLoader data={payload} loading={!!loading}>
          {!loading && !teamsEnabled && (
            <TeamsEnablement accountId={accountId} refetchScriptEnabled={refetchScriptEnabled} />
          )}
          {teamsEnabled && <VideoUsersContainer query={query} refetchScriptEnabled={refetchScriptEnabled} />}
        </RdnaLoader>
      </Container>
    </SnackbarProvider>
  )
}

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
`
