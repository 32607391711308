import React from 'react'
import { InputComponentProps } from '../../types/form-inputs'

const HiddenInput = ({ inputData, value }: InputComponentProps) => {
  return (
    <input
      type="hidden"
      key={inputData.name}
      id={inputData.name}
      name={inputData.name}
      value={value as string}
      data-testid={`${inputData.name}-input`}
    />
  )
}

export default HiddenInput
