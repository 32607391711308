StepModel = require('../../model')

class LoopStepModel extends StepModel

  defaults:
    args: {}
    type: 'Loop'
    info: 'Iterate over an array of values'
    fixedPlaceholders: [ 'For Each Value', 'Loop Completed' ]
    leaf: false

  path: ->
    @args()['path']

  orderChildren: (children) ->
    result = []
    result.push(@findChildById(children, parseInt(@args()['For Each Value'])))
    result.push(@findChildById(children, parseInt(@args()['Loop Completed'])))
    result

module.exports = LoopStepModel