BaseView = require('base/view')
BaseGrid = require('base/grid')

class StepProvisionModal extends BaseView
  template: require('./step-provision-template')

  bindings:
    '.done' : 'disabled:disabledDone'

  computeds:
    disabledDone:
      deps: ['selectedNumbers']
      get: (selectedNumbers) ->
        if selectedNumbers.findWhere(type: 'CentralNumber')
          emptyLabel = (number for number in selectedNumbers.models when not number.get('label'))
          return emptyLabel.length
        not selectedNumbers.length or selectedNumbers.findWhere(type: '')

  regions:
    grid: '.number-types-list'

  ui:
    done: '.done'

  onRender: ->
    @ui.done.text('Retry') if @model.get('retry')
    selectedNumbers = @model.get('selectedNumbers')
    selectedNumbers.each((model) =>
      model.set(addressSid: @model.getAddressSid(model))
    )
    @showChildView('grid', @buildGrid(selectedNumbers))

  buildGrid: (collection) =>
    mainClass = @
    removeCell = class extends Backgrid.Cell
      className: 'remove-cell'
      events:
        'click a.remove': 'removeFromSelected'
      removeFromSelected: ->
        collection = @model.collection
        collection.remove(@model)
        mainClass.applyBindings()
      render: ->
        $(@el).html('''
          <a class="remove">
            <span class="glyphicon glyphicon-remove-sign"></span>
          </a>
        ''')
        @

    selectCell = class extends Backgrid.Cell
      className: 'select-cell'
      events:
        'change select.number-type-select': 'changeType'
      changeType: (e) ->
        type = e.currentTarget.value
        if type is 'Default'
          toastr.warning('Selecting a new Default Smart Number will replace your existing Default Smart Number, if present.')
          selected.value = '' for selected in $('.number-type-select') when selected.value is 'Default'
          @$('.number-type-select').val(type)
          @model.collection.findWhere('type': 'Default')?.set(type: '')
        @model.set(type: type)
        mainClass.applyBindings()
      render: ->
        $(@el).html('''
          <select class="form-control number-type-select">
            <option value="" disabled class="hidden"></option>
            <option value="Additional">Additional</option>
            <option value="Default">Default</option>
          </select>
        ''')
        @$('.number-type-select').val(@model.get('type'))
        @

    sourceCell = class extends Backgrid.Cell
      className: 'source-cell'
      events:
        'keyup input.number-source': 'changeSource'
      changeSource: (e) =>
        @model.set('source', $(e.target).val().trim())
        mainClass.applyBindings()
      render: ->
        $(@el).html('''
          <input class="form-control number-source" placeholder="ringDNA" maxlength="255"/>
        ''')
        @$('.number-source').val(@model.get('source'))
        @

    labelCell = class extends Backgrid.Cell
      className: 'label-cell'
      events:
        'keyup input.number-label': 'changeLabel'
      changeLabel: (e) =>
        @model.set('label', $(e.target).val().trim())
        mainClass.applyBindings()
      render: ->
        $(@el).html('''
          <input class="form-control number-label" maxlength="255">
        ''')
        @$('.number-label').val(@model.get('label'))
        @

    statusCell = class extends Backgrid.Cell
      className: 'status-cell'
      render: ->
        isFailed = @model.get('status') is 'Failed'
        statusImg = if isFailed then 'glyphicon-exclamation-sign' else 'glyphicon-ok-sign'
        statusColor = if isFailed then '#cc0000' else '#76b027'
        $(@el).html("""
          <div style='color: #{statusColor}'>
            <span class='glyphicon #{statusImg} '></span>&nbsp;
            <span>#{if isFailed then @model.get('message') else ''}</span>
          </div>
        """)
        @

    cols = [
      {label: 'Number',  name: 'number',  cell: 'string'}
      {label: 'Type',    name: '',        cell: selectCell, renderable: @model.get('type') is 'user'}
      {label: 'Source',  name: '',        cell: sourceCell, renderable: @model.get('type') is 'call-flow'}
      {label: 'Label',   name: '',        cell: labelCell}
      {label: 'Status',  name: 'message', cell: statusCell, renderable: @model.get('retry')}
      {label: '',        name: '',        cell: removeCell}
    ]

    new BaseGrid.Grid(
      collection : collection
      columns    : cols
      emptyText  : 'No smart numbers selected.'
    )

module.exports = StepProvisionModal
