import React, { useCallback, useMemo, useState } from 'react'
import { useUserSkillCreate, useUserSkillDelete, useUserSkills, useUserSkillUpdate } from '../../api'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import { CreateSkillsButton, FormLayout, HeaderTitle, SectionHeader } from '../../styles'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import { HEADER_TEXT, initSkill, userSkillsColumns, userSkillsSetting } from './const'
import { DeleteModalProps, UserSkillsProps } from '../../types'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { DELETE, EDIT, NO_RESULT_STATE, USER_SKILLS } from '../../const'
import useToast from '../../../common/hooks/useToast'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import UserSkillsIcon from '@ringdna/common/src/assets/icons/svg/UserSkills'
import UserSkillsModal from './UserSkillsModal'
import styled from 'styled-components'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'

type tabProps = {
  accountSettings: { [key: string]: any }
  setAccountSetting: (setting: { [key: string]: any }) => void
}

export default function UserSkillsTab({ accountSettings, setAccountSetting }: tabProps) {
  const toastr = useToast()
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false)
  const [isSkillDeleteModalOpen, setIsSkillDeleteModalOpen] = useState(false)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [userSkill, setUserSkill] = useState<UserSkillsProps>(initSkill)
  const deleteUserSkill = useUserSkillDelete()
  const createUserSkill = useUserSkillCreate()
  const updateUserSkill = useUserSkillUpdate()
  const [payload, , loading, refetch] = useUserSkills({
    meta: {
      accountId: accountSettings['account.id']
    }
  })

  const menuOptions = (userSkill: UserSkillsProps) => {
    const options = [{ text: EDIT }]
    if (userSkill.callFlowSteps.length === 0) {
      options.push({ text: DELETE })
    }
    return options
  }

  const handleToggle = (setting: string) => (value: boolean) => {
    setAccountSetting({ ...accountSettings, [setting]: value })
  }

  const handleActionMenu = useCallback((action: string, userSkill: UserSkillsProps) => {
    setUserSkill(userSkill)
    if (action === DELETE) {
      setIsSkillDeleteModalOpen(true)
    } else if (action === EDIT) {
      setIsSkillModalOpen(true)
    }
  }, [])

  const closeSkillModal = () => {
    setUserSkill(initSkill)
    setIsSkillModalOpen(false)
  }

  const memoPayload = useMemo(() => {
    return payload?.map((template: UserSkillsProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={menuOptions(template)} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  async function handleUpdate() {
    setIsLoadingUpdate(true)
    try {
      await updateUserSkill({ meta: { id: userSkill.id }, body: { name: userSkill.name } })
      closeSkillModal()
      refetch()
    } catch (e) {
      closeSkillModal()
      toastr.error(getErrorMessage(e))
    }
    setIsLoadingUpdate(false)
  }

  async function handleSkillSave() {
    setIsLoadingUpdate(true)
    try {
      await createUserSkill({ body: { accountId: accountSettings['account.id'], name: userSkill.name } })
      closeSkillModal()
      refetch()
    } catch (e) {
      closeSkillModal()
      toastr.error(getErrorMessage(e))
    }
    setIsLoadingUpdate(false)
  }

  async function handleDelete() {
    setIsLoadingDelete(true)
    try {
      await deleteUserSkill({ meta: { id: userSkill?.id } })
      setIsSkillDeleteModalOpen(false)
      refetch()
    } catch (e) {
      toastr.error(getErrorMessage(e))
    }
    setIsLoadingDelete(false)
  }

  return (
    <RdnaFormLayout container>
      <FormLayout>
        <HeaderSkill
          rightColumn={
            <RdnaToggleSwitch
              controlledState={{
                isChecked: accountSettings[userSkillsSetting],
                setIsChecked: handleToggle(userSkillsSetting)
              }}
            />
          }
        >
          <IconHelper icon={UserSkillsIcon} variant="filled" />
          <HeaderTitle>{USER_SKILLS.title}</HeaderTitle>
        </HeaderSkill>
      </FormLayout>
      {accountSettings[userSkillsSetting] && (
        <RdnaFormLayout>
          <RdnaHeaderSection descriptionText={HEADER_TEXT} rightColumn={CreateUserSkillsButton(setIsSkillModalOpen)} />
          <RdnaSmartTable
            searchPlaceholder="Search"
            // @ts-ignore
            columns={userSkillsColumns}
            data={memoPayload || []}
            noResultState={NO_RESULT_STATE}
            isFetching={!!loading}
            paginationSize={10}
          />
          <UserSkillsModal
            open={isSkillModalOpen}
            onClose={closeSkillModal}
            onConfirm={!userSkill?.id ? handleSkillSave : handleUpdate}
            setFormData={setUserSkill}
            formData={userSkill}
            isLoading={isLoadingUpdate}
          />
          <ConfirmSkillDeleteModal
            open={isSkillDeleteModalOpen}
            onClose={setIsSkillDeleteModalOpen}
            onConfirm={handleDelete}
            isLoadingDelete={isLoadingDelete}
          />
        </RdnaFormLayout>
      )}
    </RdnaFormLayout>
  )
}

const CreateUserSkillsButton = (setIsSkillModalOpen: any) => {
  return (
    <CreateSkillsButton
      data-analyticsid="add-user-skills"
      text={'Add Skill'}
      onClick={() => {
        setIsSkillModalOpen(true)
      }}
    />
  )
}

const ConfirmSkillDeleteModal = ({ open, onClose, onConfirm, isLoadingDelete }: DeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Skill`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`Are you sure you want to delete this skill. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}

const HeaderSkill = styled(SectionHeader)`
  div {
    padding-bottom: 0;
  }
`
