import React, { useRef, useState } from 'react'
import { useHTMLElementDimensions } from '../../hooks/useHTMLElementDimensions'
import { useTheme } from '@mui/material'
import RdnaText from '../RdnaText'
import styled, { css } from 'styled-components'
import Caret from '../../assets/icons/svg/Caret'
import { Direction } from '../../assets/icons/types'

type RdnaAccordionRowProps = {
  isOpen?: boolean
  children?: React.ReactNode
  headContent: React.ReactNode | string
  hideCaret?: boolean
  divider?: boolean
  maxHeight?: number
  toggled?: (isOpen: boolean) => void
}

const DEFAULT_ANIMATION_HEIGHT = 9999

const RdnaAccordionRow = React.memo(
  ({ isOpen, children, headContent, hideCaret, divider = false, maxHeight, toggled }: RdnaAccordionRowProps) => {
    const [open, toggleOpen] = useState(isOpen)
    const [overflowContent, toggleOverflowContent] = useState(isOpen)
    const [toggleRowDisabled, setToggleRowDisabled] = useState(false)
    const bodyRef = useRef(null)
    const elementHeight = useHTMLElementDimensions(bodyRef)
    const { height } = maxHeight ? { height: maxHeight } : elementHeight
    const isExpandable = !!children
    const theme = useTheme()

    const toggleRow = () => {
      if (toggleRowDisabled) return
      toggleOpen(!open)
      if (toggled) toggled(!open)
      setToggleRowDisabled(true)
      setTimeout(
        () => {
          setToggleRowDisabled(false)
          toggleOverflowContent(!open)
        },
        open ? 0 : theme.transitions.duration.standard
      )
    }

    return (
      <div className={`rdna-accordion-row ${open ? 'open' : ''} ${overflowContent ? 'overflow' : ''}`}>
        <StyledRdnaAccordionHeading
          className={`rdna-accordion-heading ${isExpandable ? 'is-expandable' : ''}`}
          onClick={toggleRow}
          data-testid={`accordion-row.heading-${headContent}`}
        >
          {typeof headContent === 'string' ? <RdnaText variant="h6">{headContent}</RdnaText> : headContent}
          {!hideCaret && isExpandable && (
            <div className="rdna-accordion-toggle">
              <div className="rdna-accordion-toggle-caret">
                <Caret direction={Direction.UP} />
              </div>
            </div>
          )}
        </StyledRdnaAccordionHeading>
        <StyledRdnaAccordionBody ref={bodyRef} $animationHeight={height || DEFAULT_ANIMATION_HEIGHT} $divider={divider}>
          {children}
        </StyledRdnaAccordionBody>
      </div>
    )
  }
)

RdnaAccordionRow.displayName = 'RdnaAccordionRow'

export default RdnaAccordionRow

type StyledRdnaAccordionBodyProps = {
  $animationHeight: number
  $divider: boolean
}

const StyledRdnaAccordionHeading = styled.div`
  &.is-expandable {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing * 4}px 0;
  & .rdna-accordion-toggle {
    margin-left: auto;
  }
  .rdna-accordion-toggle-caret {
    height: 24px;
    display: inline-block;
    transition-property: transform;
    transition-duration: ${props => props.theme.transitions.duration.standard}ms;
    transition-timing-function: ${props => props.theme.transitions.easing.easeInOut};
    transform: rotate(180deg);
  }
  .rdna-accordion-row.open & .rdna-accordion-toggle-caret {
    transform: rotate(0deg);
  }
`

const StyledRdnaAccordionBody = styled.div<StyledRdnaAccordionBodyProps>`
  overflow: hidden;
  max-height: 0;
  transition-property: max-height, padding-bottom;
  transition-duration: ${props => props.theme.transitions.duration.standard}ms;
  transition-timing-function: ${props => props.theme.transitions.easing.easeInOut};
  padding-bottom: 0;
  > :first-child {
    ${props =>
      props.$divider &&
      css`
        border-top: 1px solid ${props => props.theme.palette.border};
        padding-top: ${props => props.theme.spacing * 3}px;
      `}
  }
  .rdna-accordion-row.open && {
    max-height: ${props => props.$animationHeight}px;
  }
  .rdna-accordion-row.overflow & {
    overflow: visible;
  }
`
