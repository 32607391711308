import { compose, applyMiddleware, StoreEnhancer } from 'redux'
import apiMiddleware from './apiMiddleware'

const composeEnhancers =
  // @ts-ignore ts doesnt know about redux-devtools
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const enhancer: StoreEnhancer<any> = composeEnhancers(applyMiddleware(apiMiddleware))

export default enhancer
