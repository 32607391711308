BaseCollection = require('base/collection')
CallTrackingHelper = require('features/call-tracking/helper')
Model = require('./model')

class BasicCallTrackingCollection extends BaseCollection
  url: -> 'basic'

  toJSON: =>
    fields = @toPlayJSON('basicCallTracking')
    fields['accountId'] = @accountId
    fields['extraInfo'] = JSON.stringify(@extraInfo)
    fields

  model: Model

  createBasics: (accountId, domain, notAddressesCollection, callback) =>
    @accountId = accountId
    @extraInfo = { domain: domain }
    @create().done((basics) =>
      for basic in basics
        basic['account'] = { name: basic.accountName }
      needAddresses = CallTrackingHelper.filterCallTracking(@, basics)
      @reset(needAddresses) if needAddresses.length isnt 0
      @reset(notAddressesCollection.models) if notAddressesCollection and notAddressesCollection.length isnt 0
      callback(needAddresses.length isnt 0) if callback
    )

module.exports = BasicCallTrackingCollection