BaseView = require('base/view')
Handlebars = require('handlebars/runtime').default
SALES_SUPPORT_FILTERED_ROUTE_GROUPS = require('@ringdna/common/src/utils/accountUtils').SALES_SUPPORT_FILTERED_ROUTE_GROUPS
SALES_SUPPORT_FILTERED_ROUTE_PATHS = require('@ringdna/common/src/utils/accountUtils').SALES_SUPPORT_FILTERED_ROUTE_PATHS

class MenuItemView extends BaseView
  template: require('./item-template')
  tagName: 'li'
  bindings:
    '.menu-badge': 'toggle:showMenuBadge'
  initialize: ->
    @viewModel = new Backbone.Model(showMenuBadge: false)
    badgeAttr = @model.get('item')?.badge
    if badgeAttr
      @viewModel.set(showMenuBadge: @user().get(badgeAttr))
      @listenTo(@user(), "change:#{badgeAttr}", =>
        @viewModel.set(showMenuBadge: @user().get(badgeAttr))
      )

class MenuGroupView extends Marionette.View
  template: require('./group-template')
  tagName: 'li'

class MenuTitleView extends Marionette.View
  template: require('./title-template')
  className: 'menu-title'
  tagName: 'div'

class MenuView extends Marionette.CollectionView
  className: 'nav side-menu'
  tagName: 'ul'

  events:
    'click a[href="#accounts"]': 'updateAccountPicker'

  childView: (route) ->
    if route.get('isGroup')
      MenuGroupView
    else if route.get('isTitle')
      MenuTitleView
    else
      MenuItemView

  updateAccountPicker: ->
    if App.session.user.accountPickerRenderable() and not App.session.user.getSelectedSupportAccountId()
      App.session.user.updateAccountPicker()
      Backbone.history.loadUrl() if '#accounts' is window.location.hash

  initialize: (options) ->
    urlHash = window.location.hash

    ###
      Any route within routes.json that will have a React route,
      will need the parent BackBone route responsible for hosting the ReactComponent class.
      e.g. /#users/1/calendar/<react-routes>, in routes.json would be 'react : calendar'
    ###
    routesWithReact = _.chain(require('@ringdna/common/src/constants/routes.json')).filter((route) -> route.react)._wrapped
    pathArr = []
    reactPath = ''

    ###
      If the urlHash includes the react property in routes.json, split the string into an array so we can
      check to see if we're in an existing React route, e.g. if our BackBone parent route is 'calendar,'
      removing 'calendar' from the URL '/#users/1/calendar/<react-route>' will give us an array, and if the second
      item in the array exists, that means we're in a React route. We remove the React route string because BackBone
      doesn't know about it, and change the window location back to the parent route hosting the ReactComponent class.
    ###
    routesWithReact.map (route) ->
      if urlHash.includes(route.react)
        pathArr = urlHash.split(route.react + '/')
        reactPath = route.react
        return

    if pathArr and !!pathArr[1]
      url = window.location.origin + '/' + pathArr[0] + reactPath
      window.location.href = url

    @populate(options.user)

  filterRoute: (isSalesSupport, route) ->
    if isSalesSupport
      if 'messages' is route.path
        route.iconName = 'Insights'
        route.icon = 'chart.svg'
      if route.ability is 'system:manage'
        return false

      return !(
        SALES_SUPPORT_FILTERED_ROUTE_PATHS.includes(route.path) or
        (route.group isnt undefined && SALES_SUPPORT_FILTERED_ROUTE_GROUPS.includes(route.group))
      )
    return true

  populate: (user) ->
    routes = _
      .chain(
        require('@ringdna/common/src/constants/routes.json')
      )
      .filter((route) ->
        route.label?
      )
      .filter((route) ->
        if route.path == 'call-actions'
          App.featureFlag.isFlagged(App.featureFlag.flags.CALL_ACTIONS_REACT, false)
        else if route.path == 'tcpa'
          App.featureFlag.isFlagged(App.featureFlag.flags.v294_ID_3029, false)
        else if route.group is 'RingDNA'
          route.clickableLink = not user.allowedDialer() and not user.isAgent()
          if route.path == 'call-routing/:tab'
            if user.can('call-flows:search:*') || user.can('call-flows:search:me')
              route.path = 'call-routing/call-flows'
            else if user.can('call-queues:search:me') || user.can('call-queues:search:*')
              route.path = 'call-routing/call-queues'
            else
              route.path = 'call-routing/holiday-calendar'
          true
        else if route.ability.includes('analytics:view')
          route.altpath = require('../../../src/config').default[route.configDomain]
        else if route.configDomain and route.ability.includes('dashboard') # CALL-DASHBOARD
          if user.isSupervisor()
            route.alt = true
            if App.featureFlag.isFlagged(App.featureFlag.flags.v291_REV_1806)
              # when removing FF simply update routes.json dashboards configDomain and path to point to caiDomain and live
              route.altpath = require('../../../src/config').default['caiDomain'] + "/live"
            else
              route.altpath = require('../../../src/config').default[route.configDomain] + "/" + route.path
            true
          else
            false
        else if route.path == 'roles(/)(/:id)'
          route.path = 'roles'
          App.featureFlag.isFlagged(App.featureFlag.flags.ROLES)
        else if route.path == 'sales-methodology'
          App.featureFlag.isFlagged(App.featureFlag.flags.SALES_METHODOLOGY)
        else if route.configDomain and route.ability.includes('guidedSelling') # GUIDED SELLING
          salesforceId = user.get('salesforceUrl')?.split('//')[1].split('.')[0]
          route.clickableLink = not user.allowedGuidedSelling()
          route.alt = true
          route.altpath = "https://#{salesforceId}.lightning.force.com/#{route.path}"
          return true
        else if route.ability.includes('calendar')
          route.clickableLink = not user.allowedCalendar()
          true
        else
          true
      )
      .filter((route) ->
        if route.ability.includes('smart-recordings') or route.ability.includes('analytics')
          return true
        else
          return true for ability in route.ability.split(',') when user.can(ability)
      )
      .filter((route) ->
        if route.path == 'accounts/me' and user.can('accounts:search')
          return !user.hasUserAccountAuthorizations()
        if route.path == 'accounts' and user.can('accounts:search')
          return user.isSupport() || user.hasUserAccountAuthorizations()
        true
      )
      .filter((route) =>
        return @filterRoute(user.isSalesSupport(), route)
      )
      .groupBy((route) ->
        route.group ? route.label
      )
      .map((routes, name, icon) ->
        route = routes[0]
        iconRoute = if route?.icon then Handlebars.helpers.imageUrl(route.icon) else null
        routes.map((route) ->
          if route.icon
            route.icon = Handlebars.helpers.imageUrl(route.icon)
        )
        if route.group?
          isGroup: true, name: name, items: routes, icon: iconRoute, isTitle: route.path is "title", clickableLink: route.clickableLink
        else
          isGroup: false, name: name, item: route, icon: iconRoute, isTitle: route.path is "title", clickableLink: route.clickableLink
      )
      .value()
    @collection = new Backbone.Collection(routes)

module.exports = MenuView
