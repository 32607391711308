import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceEventIcon({ size = 17, color = Colors.SALESFORCE_EVENT }: BaseIconProps) {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 14 16">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.15 6.2H.85c-.24 0-.45.21-.45.45V14c0 .66.54 1.2 1.2 1.2h10.8c.66 0 1.2-.54 1.2-1.2V6.65c0-.24-.21-.45-.45-.45ZM4.9 12.8c0 .18-.12.3-.3.3H3.4c-.18 0-.3-.12-.3-.3v-1.2c0-.18.12-.3.3-.3h1.2c.18 0 .3.12.3.3v1.2Zm-.3-2.7c.18 0 .3-.12.3-.3V8.6c0-.18-.12-.3-.3-.3H3.4c-.18 0-.3.12-.3.3v1.2c0 .18.12.3.3.3h1.2Zm3.3 2.7c0 .18-.12.3-.3.3H6.4c-.18 0-.3-.12-.3-.3v-1.2c0-.18.12-.3.3-.3h1.2c.18 0 .3.12.3.3v1.2Zm-.3-2.7c.18 0 .3-.12.3-.3V8.6c0-.18-.12-.3-.3-.3H6.4c-.18 0-.3.12-.3.3v1.2c0 .18.12.3.3.3h1.2Zm3.3 2.7c0 .18-.12.3-.3.3H9.4c-.18 0-.3-.12-.3-.3v-1.2c0-.18.12-.3.3-.3h1.2c.18 0 .3.12.3.3v1.2Zm-.3-2.7c.18 0 .3-.12.3-.3V8.6c0-.18-.12-.3-.3-.3H9.4c-.18 0-.3.12-.3.3v1.2c0 .18.12.3.3.3h1.2Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="M12.4 2.3h-1.5v-.6c0-.48-.39-.9-.9-.9-.48 0-.9.39-.9.9v.6H4.9v-.6c0-.48-.39-.9-.9-.9-.48 0-.9.39-.9.9v.6H1.6C.94 2.3.4 2.84.4 3.5v.75c0 .24.21.45.45.45h12.3c.24 0 .45-.21.45-.45V3.5c0-.66-.54-1.2-1.2-1.2Z"
      />
    </svg>
  )
}

export default SalesforceEventIcon
