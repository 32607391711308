BaseCallTrackingModel = require('../__components/base-call-tracking-model')

class BasicCallTrackingModel extends BaseCallTrackingModel
  defaults: ->
    newDefaults =
      source: 'Basic'
      type: 'Default'

    _.extend(BaseCallTrackingModel.prototype.defaults(), newDefaults)


module.exports = BasicCallTrackingModel
