BaseView           = require('base/view')
BaseGrid = require('base/grid')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')

class CollectionGrid extends BaseView
  template: require('./template')

  regions:
    grid: 'div.grid'

  initialize: (options) ->
    { @campaigns, @callflows, @countries, @collection } = options

  onRender: =>
    @showChildView('grid', @buildGrid(@campaigns, @callflows, @countries)) if @getRegion('grid')
    $('[data-toggle="tooltip"]').tooltip()

  buildGrid: (campaigns, callflows, countries) =>
    mainClass = @

    labelCell = class extends Backgrid.Cell
      events:
        'change .label-tracking-cell': 'labelChanged'

      labelChanged: (e) =>
        @model.set('labelTracking', $(e.target).val())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:labelTracking', =>
          @showHtml(not @model.get('labelTracking'))
        )
        @listenTo(@model, 'checkLabel', =>
          @showHtml(true)
        )

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control label-tracking-cell' maxlength='255' value=\"#{@model.get('labelTracking')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    originatingURLCell = class extends Backgrid.Cell
      events:
        'change .origin-url-cell': 'originURLChanged'

      originURLChanged: (e) =>
        if not validator.isURL($(e.target).val().trim(), { require_protocol: false, require_host: true })
          toastr.warning("#{ $(e.target).val().trim() } is invalid. Please enter a valid url.")
        else
          @model.set('channel', $(e.target).val().trim())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:channel', =>
          @showHtml(not @model.get('channel'))
        )
        @listenTo(@model, 'checkOriginURL', =>
          @showHtml(true)
        )

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control origin-url-cell' maxlength='255' value=\"#{@model.get('channel')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    destinationURLCell = class extends Backgrid.Cell
      events:
        'change .destination-url-cell': 'destinationURLChanged'

      destinationURLChanged: (e) =>
        if not validator.isURL($(e.target).val().trim(), { require_protocol: false, require_host: true })
          toastr.warning("#{ $(e.target).val().trim() } is invalid. Please enter a valid url.")
        else
          @model.set('destinationURL', $(e.target).val().trim())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:destinationURL', =>
          @showHtml(not @model.get('destinationURL'))
        )
        @listenTo(@model, 'checkDestinationURL', =>
          @showHtml(true)
        )

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control destination-url-cell' maxlength='255' value=\"#{@model.get('destinationURL')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    originURLHeaderCell = class extends Backgrid.HeaderCell
      render: ->
        $(@el).html('<label>Originating URL <span class="glyphicon glyphicon-question-sign blue"
                    data-toggle="tooltip" data-placement="top"
                    data-original-title="URL of the web page the user was on prior to reaching your landing page"></span></label>')
        @

    destinationURLHeaderCell = class extends Backgrid.HeaderCell
      render: ->
        $(@el).html('<label>Destination URL <span class="glyphicon glyphicon-question-sign blue"
                    data-toggle="tooltip" data-placement="top"
                    data-original-title="URL of the landing page that will display the Smart Number"></span></label>')
        @

    deleteCell = class extends Backgrid.Cell
      events:
        'click a.delete': 'delete'

      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          @model.destroy(data: accountId: @model.get('accountId')) if ok
          true
        )
      render: =>
        @showHtml()
        @listenTo(mainClass.collection, 'update', =>
          @showHtml()
        )

      showHtml: =>
        html = """<a class="delete"><span class='glyphicon glyphicon-remove-sign'></span></a>""" if mainClass.collection.length > 1
        $(@el).html(if html then html else '')
        @

    columns = [
      {label: 'Name',          name: 'labelTracking', cell: labelCell,                                            sortable: false}
      {label: 'Originating URL', name: 'orURL',  cell: originatingURLCell, headerCell: originURLHeaderCell,       sortable: false}
      {label: 'Destination URL', name: 'desURL', cell: destinationURLCell, headerCell: destinationURLHeaderCell,  sortable: false}
      {label: 'Country',       name: 'country',  cell: CallTrackingWidgets.countryCell(countries),                sortable: false}
      {label: 'Smart Number',  name: 'type',     cell: CallTrackingWidgets.typeSmartNumberCell(),                 sortable: false}
      {label: '',              name: 'smartNumberId', cell: CallTrackingWidgets.typeSmartNumberValueCell(),       sortable: false}
      {label: 'Area Code',     name: 'code',     cell: CallTrackingWidgets.areaCodeCell(),                        sortable: false}
      {label: 'Call Flow',     name: 'callFlow', cell: CallTrackingWidgets.callFlowCell(callflows),               sortable: false}
      {label: 'SFDC Campaign', name: 'campaign', cell: CallTrackingWidgets.campaignCell(campaigns),               sortable: false}
      {label: '',              name: '',         cell: deleteCell,                                                sortable: false}
    ]

    new BaseGrid.Grid(
      collection : @collection,
      columns    : columns
    )

module.exports = CollectionGrid
