AccountModel = require('features/accounts/model')
AnalyticsUtils = require('@ringdna/common/src/utils/analyticsUtils').default

class TrackerService
  constructor: (@app) ->
    @radio   = @app.radio
    @radio.on('session:ended', @shutdown)
    @radio.on('session:started', @boot)
    @radio.reply('track', @track)

  boot: (sessionId, user) =>
    userId      = user.get('userId')
    accountId   = user.get('accountId')
    accountName = user.get('accountName')
    email       = user.get('userEmail')
    name        = user.get('userName')

    isDeleted          = user.get('isDeleted')
    accountIsDeleted   = user.get('accountIsDeleted')
    accountType        = user.get('accountType')
    sendFeedback       = user.get('sendFeedback')

    @app.analyticsHelper = new AnalyticsUtils(user)

    # conditionally load fullstory based on whether account has sendFeedback Enabled
    # TODO build real feature flag service
    if window.rdnaEnableFullstory and sendFeedback
      fsUserData = {
        displayName: '' + name,
        email: '' + email,
        # TODO: Add your own custom user variables here, details at
        # http://help.fullstory.com/develop-js/setuservars
        accountId_int: accountId,
        accountName_str: '' + accountName,
        accountType_str: '' + accountType
        isDeleted_bool: isDeleted
        accountIsDeleted_bool: accountIsDeleted
        version_str: '' + @version
      }
      FS.identify('' + userId, fsUserData)
      window.FS.load()

  track: (event, data = {}) ->
    @app.analyticsHelper.trackEvent(event, data)

  shutdown: ->
    FS.shutdown()

module.exports = TrackerService
