BaseView = require('base/view')

class SipRoutingPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  bindings:
    '.selector': 'value:sipRoutingId'

  onRender: =>
    @ui.picker.selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      load: (query, callback) =>
        App.api.get(
          path: 'sipRouting/list'
          success: (response) =>
            callback(response)
            selectize = @ui.picker[0].selectize
            return unless selectize
            selectize.addOption(text: '(none)', value: '')
            selectize.addItem('', true)
            if @model.get('sipRouting')?
              selectize.setValue(@model.get('sipRouting').id, true)
            else
              selectize.setValue('', true)
        )
    )

  computeds:
    sipRoutingId:
      deps: ['sipRouting']
      get: (sipRouting) ->
        sipRouting?.id
      set: (value) ->
        if @model.get('sipRouting')?
          if not value
            @model.set('sipRouting', null)
          else
            @model.get('sipRouting').id = value
        else
          @model.set('sipRouting', {id:value})

module.exports = SipRoutingPickerView
