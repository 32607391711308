BaseCollection  = require('base/collection')
PlaceholderStep = require('./step-models/Placeholder/model')

class StepCollection extends BaseCollection

  comparator: 'parentId'
  model: (attrs, options) ->
    newModel = require("./step-models/#{attrs.type}/model")
    new newModel(attrs, options)

  asTree: =>
    tree = (for value in @models
      id: String(value.get('id'))
      parent: if value.get('parentId') != 0 then String(value.get('parentId')) else '#'
      assignment: value.assignment()
      text: value.label()
      icon: value.icon())
    @sortTree(tree)

  sortTree: (tree) ->
    fixedPlaceholdersSteps = []
    valuesSteps = []
    for step in tree
      fixedPlaceholders = @get(step.parent)?.get('fixedPlaceholders')
      if fixedPlaceholders and step.assignment in (fixedPlaceholders)
        fixedPlaceholdersSteps.push(step)
      else
        valuesSteps.push(step)
    valuesSteps = _.sortBy(valuesSteps, 'assignment')
    fixedPlaceholdersSteps = _.sortBy(fixedPlaceholdersSteps, (step) =>
      fixedPlaceholders = @get(step.parent)?.get('fixedPlaceholders')
      if fixedPlaceholders and @get(step.parent)?.get('type') is 'Time'
        return index for value, index in fixedPlaceholders when step.assignment is value
      return step.assignment
    )
    sortedTree = valuesSteps.concat(fixedPlaceholdersSteps)
    sortedTree

  checkMissingFixedSteps: (step, success) ->
    return unless step.get('fixedPlaceholders')
    missingFixedSteps = (value for value in step.get('fixedPlaceholders') when value not in (key for key of step.args()))
    @createMissingPlaceholders(step, missingFixedSteps, success) if missingFixedSteps.length

  confirmReplaceStep: (step, callback) =>
    includedSteps = @findWhere((model) -> model?.get('parentId') is step.id and not model.isPlaceholder())
    if includedSteps
      bootbox.confirm('Are you sure you want to delete this step and all steps nested within it?', (result) =>
        callback(result)
      )
    else
      callback(true)

  createFixedPlaceholders: (step) =>
    @createPlaceholders(step, value) for value in step.get('fixedPlaceholders') if step.get('fixedPlaceholders')

  createMissingPlaceholders: (parentStep, missingSteps, success) =>
    @сreatedSteps = []
    @assignmentSteps = []
    @createPlaceholders(parentStep, value) for value in missingSteps
    parentStep.createStepsCollection(@сreatedSteps, @assignmentSteps, =>
      parentStep.parent().saveStep() if parentStep.parent()
      parentStep.saveStep(=>
        success()
      )
    )

  createPlaceholders: (parentStep, assignment) =>
    if assignment
      @сreatedSteps.push(new PlaceholderStep(parentId: parentStep.id, callflowId: parentStep.get('callflowId')))
      @assignmentSteps.push(
        name  : assignment
        id    : parentStep.id
      )

  createStepsCascade: (newStep, success) ->
    newStep.saveStep((step) =>
      @add(step)

      @createPlaceholders(step, step.nextPlaceholder())
      @createPlaceholders(step.parent(), step.parent().nextPlaceholder()) if step.parent()
      @createFixedPlaceholders(step)

      step.createStepsCollection(@сreatedSteps, @assignmentSteps, =>
        step.parent().saveStep() if step.parent()
        step.saveStep(=>
          success(true)
        )
      )
    )

  replaceStep: (oldStep, type, replacedStep, callback) =>
    @сreatedSteps = []
    @assignmentSteps = []
    @confirmReplaceStep(oldStep, (confirm) =>
      return callback(false) unless confirm
      unless replacedStep
        newModel = require("./step-models/#{type}/model")
        newStep = new newModel(name: '', parentId: oldStep.get('parentId'), id: oldStep.id, callflowId: oldStep.get('callflowId'))
        @removeStepsCascade(oldStep, newStep.isPlaceholder())
        @createStepsCascade(newStep, callback)
      else
        oldStepParent = oldStep.parent()
        replacedStepParent = replacedStep.parent()
        oldStepAssignment = oldStep.assignment()
        replacedStepAssignment = replacedStep.assignment()
        oldStepParent.updateAssignment(replacedStep.id, oldStepAssignment) if oldStepParent
        replacedStepParent.updateAssignment(oldStep.id, replacedStepAssignment)
        if oldStepParent
          replacedStep.set('parentId': oldStepParent.id)
        else
          replacedStep.set('parentId': null)
        replacedStep.saveStep()
        if replacedStepParent.get('type') is 'Menu'
          replacedStepParent.deleteAssignment(replacedStepAssignment)
        oldStepParent.saveStep() if oldStepParent
        replacedStepParent.saveStep( =>
          @removeStepsCascade(oldStep, replacedStep.isPlaceholder(), true)
          if replacedStepParent.get('type') is 'Menu' and replacedStepParent.nextPlaceholder()
            @createMissingPlaceholders(replacedStepParent, @missingMenuPlaceholderSteps(replacedStepParent), => callback(true))
          else callback(true)
        )
    )

  missingMenuPlaceholderSteps: (parent) =>
    hasFixedPlaceholderForParentMenu = false
    for model in @models
      if model.parent()?.get('type') is 'Menu' and model.isFixedPlaceholder()
        hasFixedPlaceholderForParentMenu = true
    missingSteps = [parent.nextPlaceholder()]
    missingSteps = missingSteps.concat(parent.get('fixedPlaceholders')) unless hasFixedPlaceholderForParentMenu
    missingSteps

  removeStepsCascade: (step, setPlaceholderStep, deleteItself = false) ->
    arrayRemoveSteps = []
    idsToExamine = []
    additionallyRemovableIds = if setPlaceholderStep then @removeUnusePlaceholders(step, arrayRemoveSteps) else []
    additionallyRemovableIds.push(step.id) if deleteItself
    step.deleteStep(additionallyRemovableIds)
    arrayRemoveSteps.push(step.id)
    idsToExamine.push(step.id)
    while idsToExamine.length > 0
      idToExamine = idsToExamine.pop()
      for model in @models
        if model.get('parentId') is idToExamine
          arrayRemoveSteps.push(model)
          idsToExamine.push(model.id)
    @remove(arrayRemoveSteps)
    @remove(step) if deleteItself

  removeUnusePlaceholders: (step, arrayRemoveSteps) ->
    parentStep = step.parent()
    return unless parentStep
    nonFixedPlaceholder = parentStep.nonFixedPlaceholder()
    for placeholder in nonFixedPlaceholder
      arrayRemoveSteps.push(placeholder)
      for key, value of parentStep.args() when value is placeholder.id.toString()
        delete parentStep.args()[key]
    _.pluck(arrayRemoveSteps, 'id')

module.exports = StepCollection
