/*
USAGE:
import { useAnalytics } from '../contexts/AnalyticsContext'

// analytics provides the tracking event, revenueEvents provides event names for the event
const { analytics, revenueEvents } = useAnalytics()

// fires track event, example includes an optional object to pass additional values
analytics.trackEvent(revenueEvents.inboxEvents.USER_FILTERED, { data: 'value'})
*/
import React, { createContext, useContext, useState, useEffect } from 'react'
import AnalyticsUtils, { TrackEvent } from '@ringdna/common/src/utils/analyticsUtils'
import { userSettingsAtom } from '../../store/api/user-settings'
import { useAtomValue } from 'jotai'
import { revenueEvents } from '@ringdna/common/src/constants/pendoEvents/revenue'
import config, { Env } from '../../config'
import { initPendo } from '@ringdna/common/src/utils/pendoUtils'

import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'

if (config.rdEnv !== Env.Local) {
  /* istanbul ignore next */
  initPendo(config.pendoKey)
}

type ContextType = {
  analytics: { trackEvent: TrackEvent }
  revenueEvents: typeof revenueEvents
}

const AnalyticsContext = createContext<ContextType | undefined>(undefined)

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)
  if (context === undefined) {
    /* istanbul ignore next */
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}

type Props = {
  children: React.ReactNode
}

export const AnalyticsProvider = ({ children }: Props) => {
  const [analytics, setAnalytics] = useState<AnalyticsUtils | undefined>()
  const user = useAtomValue(userSettingsAtom)

  useEffect(() => {
    if (!analytics) setAnalytics(new AnalyticsUtils(user))
  }, [user, analytics, setAnalytics])

  return (
    <RdnaLoader data="data" loading={!analytics} top="130px">
      {analytics ? (
        <AnalyticsContext.Provider value={{ analytics, revenueEvents }}>{children}</AnalyticsContext.Provider>
      ) : null}
    </RdnaLoader>
  )
}
