import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function ThoughtBubbleIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        clipRule="evenodd"
        transform="rotate(45 12.2699 13.4572)"
        d="m18.81369,17.80257c0.08599,-0.17207 0.05229,-0.37989 -0.08376,-0.51594l-1.73768,-1.73768c-0.14499,-0.14498 -0.37015,-0.17269 -0.54597,-0.06721l-1.1328,0.6797c-0.09529,0.05721 -0.20809,0.07678 -0.31455,0.04478c-0.45328,-0.13596 -1.77853,-0.6367 -3.30991,-2.16808c-1.5314,-1.53147 -2.03214,-2.85668 -2.16813,-3.30993c-0.03196,-0.10648 -0.0124,-0.21927 0.0448,-0.31461l0.67964,-1.13275c0.10557,-0.17586 0.07786,-0.40096 -0.06719,-0.54597l-1.73768,-1.73768c-0.13603,-0.13603 -0.34384,-0.16976 -0.51591,-0.08372l-2.01378,1.00689c-0.14042,0.07021 -0.23305,0.20687 -0.23179,0.36386c0.00763,0.95082 0.29018,4.45844 3.79782,7.96607c3.50762,3.50763 7.01525,3.79019 7.96606,3.79787c0.15705,0.00125 0.29373,-0.09135 0.36389,-0.23178l1.00694,-2.0138z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path d="M12 2v2M18.25 3.675l-1 1.732M5.75 3.675l1 1.732" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default ThoughtBubbleIcon
