import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#6442D9" offset="0%"></stop>
          <stop stopColor="#37C6FD" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="01-admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01b_account_connected_accounts-(enabled)" transform="translate(-432.000000, -1133.000000)">
          <g id="iCloud" transform="translate(276.000000, 1093.000000)">
            <g transform="translate(156.000000, 40.000000)">
              <rect id="Rectangle-8" fill="url(#linearGradient-1)" x="0" y="0" width="50" height="50" rx="12"></rect>
              <path
                d="M36.7998158,23.4649992 C40.1751412,23.7193917 42.8333333,26.555726 42.8333333,30.014881 C42.8333333,33.6413758 39.9117353,36.5833333 36.3051471,36.5833333 L14.1973039,36.5833333 C10.3132196,36.5833333 7.16666667,33.4148532 7.16666667,29.5089286 C7.16666667,26.5351868 9.00629479,23.9236072 11.6972774,22.8948759 C11.8461364,20.3811034 13.9197486,18.3869048 16.4583333,18.3869048 C17.4193288,18.3869048 18.3351419,18.6747765 19.1065882,19.1936088 C20.6161529,15.9732195 23.8599272,13.8333333 27.5122549,13.8333333 C32.6450713,13.8333333 36.8039216,18.0211651 36.8039216,23.1845238 C36.8039216,23.2781821 36.8025511,23.3716809 36.7998158,23.4649992 Z"
                id="Oval-1"
                stroke="#FFFFFF"
                strokeWidth="1.5"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
