CookieHelper    = require('@ringdna/common/app/helpers/cookie-helper')
config = require('../../src/config').default
cookieConfig = require('../../src/config/legacy').cookieConfig

class ApiService

  constructor: (app) ->

    @cookies = new CookieHelper(cookieConfig)

    @setupBackbone()
    app.radio.on('session:ended',   (sessionId) => @sessionId = null)
    app.radio.on('session:started', (sessionId) => @sessionId = sessionId)

  delete: (options) =>
    @exec('DELETE', options)

  get: (options)  =>
    @exec('GET', options)

  post: (options) =>
    @exec('POST', options)

  put: (options) =>
    @exec('PUT', options)

  exec: (verb, options) =>
    {
      anon        = false,
      data        = {},
      dataType    = 'json',
      ladda,
      path,
      sessionId = @sessionId,
      upload    = false
      success,
      failure,
      complete,
      suppressDefaultErrorHandler = false
    } = options

    if not anon and not sessionId
      console.error('No session established to ringDNA server')
      return

    path = "/api/v2/app/#{path}" unless path.indexOf('/api/v2/') == 0 or path.indexOf('/salesforceoauth') == 0
    url = config.apiDomain + path
    console.debug("REQUEST - #{verb} : #{url}")

    if ladda
      ladda = Ladda.create(ladda)
      ladda.start()

    ajax =
      cache       : false
      data        : data
      dataType    : dataType
      headers     : if anon then {} else { 'sessionId': sessionId }
      type        : verb
      url         : url
      success: (response, status, xhr) =>
        success?(response, status, xhr)
        ladda?.stop()
      error: (xhr, status, error) =>
        @handleError(xhr, status, error) if not suppressDefaultErrorHandler
        failure?(xhr, status, error)
        ladda?.stop()
      complete: (status) =>
        complete?(status)

    if (upload)
      form = new FormData()
      _.chain(data)
      .pairs()
      .each((item) -> form.append(item[0], item[1]))

      ajax.data = form
      ajax.contentType = false
      ajax.processData = false

    $.ajax(ajax)

  handleError: (xhr, status, error) =>
    if xhr.status is 401
      App.session.login()
      return

    if xhr.responseJSON
      message = xhr.responseJSON.message
      title   = xhr.responseJSON.code
    else
      message = "An error occurred. &nbsp; [status=#{xhr.status}, message=#{error}]"
      title   = 'Server Error'

    if xhr.status is 500
      console.error(message)
      toastr.error(message, title, {closeButton: true, extendedTimeOut: 0, timeOut: 0})
      App.sentryHelper.module('api-service').notify(new Error(message))
    else if xhr.status isnt 0
      console.warn(message)
      toastr.warning(_.escape(message), _.escape(title))

  setupBackbone: =>
    Backbone.ajax = =>
      if arguments.length isnt 1
        console.error("Unsupported ajax call. [args.length=#{arguments.length}]")

      args = arguments[0]
      verb = args.type

      options =
        anon        : args.anon
        data        : args.data
        upload      : args.upload
        path        : args.url
        sessionId   : args.sessionId
        ladda       : args.ladda
        success     : args.success
        failure     : args.failure ? args.error
        complete    : args.complete

      @exec(verb, options)

module.exports = ApiService
