var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content step\" data-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":42}}}) : helper)))
    + "\">\n  <input class=\"title input\" type=\"text\" data-key=\"name\" maxlength=\"24\" />\n  <div class=\"info\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"info") || (depth0 != null ? lookupProperty(depth0,"info") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"info","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":28}}}) : helper)))
    + "</div>\n  <div class=\"tip\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tip") || (depth0 != null ? lookupProperty(depth0,"tip") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tip","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":26}}}) : helper)))
    + "</div>\n  <div class=\"menuAssignment\">\n    <div class=\"subtitle\">Menu Assignment</div>\n    <span class=\"label\">Activate this function when the caller presses:</span>\n    <select class=\"assignment\"></select>\n  </div>\n  <div class=\"promptAssignment\">\n    <div class=\"subtitle\">Prompt Assignment</div>\n    <span class=\"label\">Activate this function when the caller enters: </span>\n    <input class=\"assignment\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxDigits") || (depth0 != null ? lookupProperty(depth0,"maxDigits") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxDigits","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":54}}}) : helper)))
    + "\" size=\"40\" />\n    <div class=\"speech\">\n      <span class=\"label\">Or when the caller says any of the following words or phrases, separated by commas:</span>\n      <input class=\"speechAssignment\" maxlength=\"1000\" size=\"40\" />\n    </div>\n  </div>\n  <div class=\"branchAssignment\">\n    <div class=\"subtitle\">Branch Assignment</div>\n    <span class=\"label\">Activate this function when the branch evaluates to:</span>\n    <input class=\"assignment\" maxlength=\"255\" size=\"80\" />\n  </div>\n  <div id=\"more-info\"></div>\n</div>\n";
},"useData":true});