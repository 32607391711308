import React from 'react'

// utils
import { CallRecordingSetting, UnitedStatesCallRecordingSetting } from '@ringdna/common/src/types/call-recording'
import { formatDateTime } from '@ringdna/common/src/utils/formatter'

// components
import { Tooltip } from '@mui/material'
import { RdnaTableCell } from '@ringdna/common/src/components/RdnaTable'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { PlayOutboundRecordingToggle, RecordCallsOption } from './UnitedStatesToggles'
import { StyledRow } from '../styles'

type UnitedStatesTableRowsProps = {
  stateSetting: UnitedStatesCallRecordingSetting | CallRecordingSetting
}

export default UnitedStatesTableRows
function UnitedStatesTableRows({
  // @ts-ignore
  stateSetting: { areaCodes, name, requiresConsent, dateConsentAdded, setting }
}: UnitedStatesTableRowsProps) {
  // @ts-ignore
  const playIndex = setting.stateId - 1
  const recordTypeKey = `items[${playIndex}].recordType`
  const outboundAnnouncementKey = `items[${playIndex}].outboundAnnouncement`

  return (
    <StyledRow>
      <RdnaTableCell>
        <RecordCallsOption recordTypeKey={recordTypeKey} requiresConsent={requiresConsent} name={name} />
      </RdnaTableCell>
      <RdnaTableCell>
        <PlayOutboundRecordingToggle outboundAnnouncementKey={outboundAnnouncementKey} />
      </RdnaTableCell>
      <RdnaTableCell>{name}</RdnaTableCell>
      <RdnaTableCell>
        {requiresConsent && (
          <Tooltip title={`${name} is an all-party consent to record call as of ${formatDateTime(dateConsentAdded)}`}>
            <RdnaText variant="h5" color="link">
              All Party
            </RdnaText>
          </Tooltip>
        )}
      </RdnaTableCell>
      <RdnaTableCell>{areaCodes.join(', ')}</RdnaTableCell>
    </StyledRow>
  )
}
