import React, { useEffect, useState } from 'react'

import { RdnaCollapseEnhancer } from '@ringdna/common/src/components/RdnaCollapse'
import styled from 'styled-components'

type Props = {
  value: React.ReactNode
  opened: boolean
}

export default function ExpandedCell({ value, opened }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsOpen(opened)
  }, [opened])

  return (
    <Container>
      <RdnaCollapseEnhancer
        className={'collapse-enhancer'}
        isExpanded={isOpen}
        setIsExpanded={setIsOpen}
        details={<div className={'expanded-cell'}>{isOpen ? value : ''}</div>}
      >
        <EllipsisStyle>{isOpen ? '' : value}</EllipsisStyle>
      </RdnaCollapseEnhancer>
    </Container>
  )
}

const Container = styled.div`
  padding-right: ${({ theme }) => 2 * theme.spacing}px;

  .collapse-enhancer {
    margin: 0;
    box-shadow: none;
  }
  .expanded-cell {
    text-wrap: wrap;
  }
`

const EllipsisStyle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`
