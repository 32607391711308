export const baseBreakpoints = {
  xs: {
    min: 0,
    max: 599,
    mediaQuery: '@media (max-width: 599px)'
  },
  sm: {
    min: 600,
    max: 959,
    mediaQuery: '@media (max-width: 959px)'
  },
  md: {
    min: 960,
    max: 1279,
    mediaQuery: '@media (max-width: 1279px)'
  },
  lg: {
    min: 1280,
    max: 1919,
    mediaQuery: '@media (max-width: 1919px)'
  },
  xl: {
    min: 1920,
    mediaQuery: '@media (min-width: 1920px)'
  }
}

export default baseBreakpoints
