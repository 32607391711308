var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block loop\">\n  <div class=\"sub-subtitle\">List of Values</div>\n  <div>Provide a variable expression or pipe-delimited list of values.</div>\n  <input type=\"text\" class=\"input\" data-key=\"loopList\" maxlength=\"80\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"loopList") : stack1), depth0))
    + "\" />\n  <div class=\"sub-subtitle\">Current Item Name</div>\n  <div>What key should contain the current list item?</div>\n  <input type=\"text\" class=\"input\" data-key=\"loopItemKey\" maxlength=\"80\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"loopItemKey") : stack1), depth0))
    + " \"/>\n</div>";
},"useData":true});