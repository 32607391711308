import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function AttachmentIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M16 6V16.5C16 18.9853 13.9853 21 11.5 21V21C9.01472 21 7 18.9853 7 16.5V7C7 5.34315 8.34315 4 10 4V4C11.6569 4 13 5.34315 13 7V16.5C13 17.3284 12.3284 18 11.5 18V18C10.6716 18 10 17.3284 10 16.5V10"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default AttachmentIcon
