import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'
import {
  UserIdParam,
  SchedulingPagesPayload,
  PostSchedulingPageParam
} from '../../components/users/calendar/SchedulingPages/types'
import { CalendarSettings, PutWelcomeMessageParam } from '@ringdna/common/src/api/calendar/calendar-model'

const API_BASE = '/api/v2/app/calendars'

export const useSchedulingPages = createUseFetch<SchedulingPagesPayload, UserIdParam>({
  key: 'get-scheduling-pages',
  path: `${API_BASE}/schedulingPages`
})

export const useDeleteSchedulingPage = createUseFetchAction<any, { id: number; body: any }>({
  key: 'delete-scheduling-page',
  path: params => `${API_BASE}/schedulingPages/${params.id}`,
  method: HttpMethods.Delete
})

export const usePostSchedulingPage = createUseFetchAction<any, PostSchedulingPageParam>({
  key: 'post-scheduling-page',
  path: `${API_BASE}/schedulingPages`,
  method: HttpMethods.Post
})

export const useCalendarSettings = createUseFetch<CalendarSettings, { meta: { id: number } }>({
  key: 'get-calendar-settings',
  path: params => `${API_BASE}/settings/${params.meta.id}`
})

export const usePutCalendarWelcomeMessage = createUseFetchAction<any, PutWelcomeMessageParam>({
  key: 'put-calendar-welcome-message',
  path: params => `${API_BASE}/settings/${params.id}/personalize`,
  method: HttpMethods.Put
})
