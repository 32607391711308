BaseView            = require('base/view')
Columns             = require('./columns')
BackgridRow         = require('helpers/backgrid-row')
StateCollection     = require('./state-collection')
StateModel          = require('./state-model')
NumberCollection    = require('./number-collection')
ProvisionCollection = require('./provision-collection')
ReportHelper        = require('helpers/report-helper')
WidgetModel         = require('widgets/smart-numbers-widget/model')
SmartNumberWidget   = require('widgets/smart-numbers-widget/edit/main-view')

module.exports = class LocalPresenceMainView extends BaseView

  template: require('./main-template')
  className: 'local-presence'

  bindings:
    '.localPresence'                : 'checked:localPresence'
    '.localPresenceVerifiedNumbers' : 'checked:localPresenceUseVerifiedNumbers'
    '.countries'                    : 'options:countryList'
    '.fallbackPhoneNumber'          : 'value:defaultPhoneNumber'
    '.saveLocalPresenceSettings'    : 'disabled: provisionButton'
    '.provisionNA'                  : 'disabled: provisionButton'
    '.provisionIntl'                : 'disabled: provisionButton'
    '.addNumber'                    : 'disabled: provisionButton'

  ui:
    download            : '.download'
    save                : 'button.saveLocalPresenceSettings'
    allUS               : '.enable-all-us'
    allCA               : '.enable-all-ca'
    provisionButtonNA   : 'button.provisionNA'
    deleteButton        : '.delete-numbers'
    countrySelector     : '.countries'
    prefix              : '.prefix'
    findButton          : 'button.find'
    provisionButtonIntl : 'button.provisionIntl'

  events:
    'click button.export'           : 'export'
    'click @ui.save'                : '_saveLocalPresenceSettings'
    'change @ui.allUS'              : '_enableAllUs'
    'change @ui.allCA'              : '_enableAllCanada'
    'click @ui.provisionButtonNA'   : '_provisionNA'
    'click @ui.deleteButton'        : '_delete'
    'click @ui.findButton'          : '_findAvailableNumbers'
    'click @ui.provisionButtonIntl' : '_provisionIntl'
    'click button.addNumber'        : 'manualAddNumber'

  regions:
    usView : '.us-list'
    caView : '.ca-list'
    availableNumbersView : '.available-numbers-list'
    modal: 'div.modal-container'

  initialize: (options) ->
    @accountId = options.model.id
    @model = options.model
    @model.set(
      'countryList': [],
      'provisionButton': null
    )
    @usCollection = new StateCollection()
    @canadaCollection = new StateCollection()
    @availableNumberCollection = new NumberCollection()
    @provisionSmartNumber = new ProvisionCollection(null, {accountId: @accountId})
    App.pubsub.bind('LocalPresenceStatus', @statusProvision)

  onDestroy: =>
    App.pubsub.unbind('LocalPresenceStatus')

  onAttach: =>
    $('input[type=checkbox]').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onRender: =>
    @_loadAreaCodes()
    @_loadCountries()
    usView = new Backgrid.Grid(
      row: BackgridRow.error('errorDesc', 'error-row'),
      columns: Columns.State,
      collection: @usCollection,
      emptyText: 'No Data'
    )
    @showChildView('usView', usView)
    caView = new Backgrid.Grid(
      row: BackgridRow.error('errorDesc', 'error-row'),
      columns: Columns.State,
      collection: @canadaCollection,
      emptyText: 'No Data'
    )
    @showChildView('caView', caView)
    availableNumbersView = new Backgrid.Grid(
      row: BackgridRow.error('errorDesc', 'error-row'),
      columns: Columns.AvailableNumbers,
      collection: @availableNumberCollection,
      emptyText: 'No Data'
    )
    @showChildView('availableNumbersView', availableNumbersView)

  _saveLocalPresenceSettings: =>
    @model.persist(
      ladda:  @ui.save[0]
      success: ->
        toastr.info('Local presence settings saved.')
    )

  export: =>
    App.api.get(
      path: 'localPresence/export'
      data: accountId: @accountId
      dataType: 'text'
      success: (response, status, xhr) =>
        data = new ReportHelper().parseExportData(response, xhr)
        @ui.download.attr(
          href: data.encodedUri
          download: data.fileName
        )
        @ui.download[0].click()
    )

  _loadAreaCodes: =>
    @_spin(true)
    NASmartNumbers = new StateCollection()
    NASmartNumbers.fetch(
      data: accountId: @accountId
    ).done((response) =>
      @_spin(false)
      @usCollection.add(new StateModel(key, value)) for key, value of response.us
      @canadaCollection.add(new StateModel(key, value)) for key, value of response.canada
    )

  _provisionNumbersNA: (collection, country, alreadyProvidedCodes) =>
    for model in collection.where({'needToProvide': true})
      for code in model.get('areaCodes')
        for key, value of code
          if not value
            @provisionSmartNumber.add(
              country       : country
              pattern       : key
            )
          else
            alreadyProvidedCodes.push(key)
    @provisionSmartNumber.setParams(international: false)

  _provisionNA: =>
    @provisionSmartNumber.reset()
    alreadyProvidedCodes = []
    @_provisionNumbersNA(@usCollection, 'US', alreadyProvidedCodes)
    @_provisionNumbersNA(@canadaCollection, 'CA', alreadyProvidedCodes)
    @_resetErrors('US')
    @_resetErrors('CA')
    if alreadyProvidedCodes.length
      message = 'The area code that you have selected, is already provided. ' + alreadyProvidedCodes.toString() + '. '
    else if not @provisionSmartNumber.length
      message = 'Nothing to provisioned. '
    toastr.warning(message, 'Numbers provision') if message
    if @provisionSmartNumber.length
      @_provisionNumber(@ui.provisionButtonNA)

  _deleteNumbers: (collection, deleteAreaCodes) =>
    for model in collection.where({'needToDelete': true})
      for code in model.get('areaCodes')
        deleteAreaCodes.push(key) for key, value of code

  _delete: =>
    deleteAreaCodes = []
    @_deleteNumbers(@usCollection, deleteAreaCodes)
    @_deleteNumbers(@canadaCollection, deleteAreaCodes)
    @_resetErrors('US')
    @_resetErrors('CA')
    return toastr.info('Please, select area codes to delete.') unless deleteAreaCodes.length

    @disableProvisionButtons(@ui.deleteButton, true)
    App.api.delete(
      path    : 'localPresence/bulkDelete'
      data    :
        accountId: @accountId
        areaCodes: deleteAreaCodes
      success : =>
        @updatedStatusProvide(@usCollection, false, {'needToDelete': true})
        @updatedStatusProvide(@canadaCollection, false, {'needToDelete': true})
        toastr.info('Deletion is done')
      complete: => @disableProvisionButtons(@ui.deleteButton, false)
    )

  _enableAllUs: (e) =>
    @usCollection.invoke('set', {'needToProvide': e.target.checked})
    @usCollection.invoke('set', {'needToDelete': e.target.checked})

  _enableAllCanada: (e) =>
    @canadaCollection.invoke('set', {'needToProvide': e.target.checked})
    @canadaCollection.invoke('set', {'needToDelete': e.target.checked})

  _loadCountries: =>
    @_spin(true)
    App.api.get(
      path    : 'localPresence/countryCodes'
      data    : accountId: @accountId
      success :
        (response) =>
          countries = ({label: country.countryName, value: country.countryCode} for country in response)
          @model.set('countryList', _.sortBy(countries, 'label'))
      complete: =>
        @_spin(false)
    )

  _findAvailableNumbers: =>
    @provisionSmartNumber.reset()
    @availableNumberCollection.fetch(
      ladda: @ui.findButton[0]
      data:
        accountId   : @accountId
        country     : @ui.countrySelector.val()
        searchTerm  : @ui.prefix.val()
    )

  _provisionIntl: =>
    @provisionSmartNumber.reset()
    for model in @availableNumberCollection.models when model.get('selected')
      @provisionSmartNumber.add(
        country       : model.get('country')
        pattern       : model.get('number')
        type          : model.get('type')
      )
    @_resetErrors('intl')
    unless @provisionSmartNumber.length
      toastr.warning('Nothing to provisioned.', 'Numbers provision')
      return
    @provisionSmartNumber.setParams(international: true)
    @_provisionNumber(@ui.provisionButtonIntl)

  _provisionNumber: (button) ->
    @disableProvisionButtons(button, true)
    @provisionSmartNumber.create()

  manualAddNumber: ->
    model = new WidgetModel(
      accountId: @accountId,
      type: 'local-presence'
    )
    view = new SmartNumberWidget(model: model)
    @showChildView('modal', view)
    @listenTo(view, 'assigned', (smartNumbers) =>
      for number in smartNumbers.models when number.get('number').indexOf('+1') > -1
        switch number.get('country')
          when 'US'
            collection = @usCollection
          when 'CA'
            collection = @canadaCollection
      if collection
        areaCode = number.get('number').slice(2, 5)
        for value in collection.models when value.get('areaCodesDesc').indexOf(areaCode) > -1
          value.get('areaCodesDesc').replace(areaCode, areaCode + '(provided)')
        @render()
      smartNumbers.reset()
    )

  updatedStatusProvide: (collection, status, sort) =>
    for model in collection.where(sort)
      unless model.get('errorDesc')
        for code in model.get('areaCodes')
          for key, value of code
            code[key] = status
        model.set('areaCodesDesc', model._getAreaDesc(model.get('areaCodes')))

  statusProvision: (data) =>
    if data.message is 'Provisioning is complete'
      @updatedStatusProvide(@usCollection, true, {'needToProvide': true})
      @updatedStatusProvide(@canadaCollection, true, {'needToProvide': true})
      @disableProvisionButtons(@model.get('provisionButton'), false)
      toastr.info('Provision is done')
    else
      switch data.country
        when 'US'
          collection = @usCollection
          numberField = 'areaCodesDesc'
        when 'CA'
          collection = @canadaCollection
          numberField = 'areaCodesDesc'
        else
          collection = @availableNumberCollection
          numberField = 'number'
      model = (value for value in collection.models when value.get(numberField).indexOf(data.areaCode) > -1)?[0]
      return unless model
      model.set('errorDesc', data.message)
      @_refreshRow(model, collection)

  disableProvisionButtons: (button, disabled) ->
    @model.set('provisionButton', if disabled then button else null)
    return unless button
    button = button.ladda()
    if disabled then button.ladda('start') else button.ladda('stop')

  _resetErrors: (country) =>
    models = []
    switch country
      when 'US' then collection = @usCollection
      when 'CA' then collection = @canadaCollection
      when 'intl' then collection = @availableNumberCollection
      else return

    for model in collection.models
      model.set('errorDesc', '') unless model.get('selected')
      models.push(model)
    collection.reset(models)

  _refreshRow: (model, collection) =>
    if collection.remove(model)
      collection.add(model)

  _spin: (enabled) =>
    if enabled
      @showSpinner($(@el), left: '45%')
    else
      @hideSpinner($(@el))
