import { createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '../../../../common/src/client'

type BillingReportRequest = {
  body: {
    accountId: string
    month: number
    year: number
    email: string
    international: boolean
    fromTwilio: boolean
  }
}
export const useBillingReport = createUseFetchAction<void, BillingReportRequest>({
  key: 'billing-report',
  path: '/api/v2/app/reports/billing',
  method: HttpMethods.Post
})
