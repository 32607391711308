import { SalesforceRecord } from '../../types/salesforce'
import SalesforceLeadIcon from '../../assets/icons/salesforce/SalesforceLead'
import SalesforceContactIcon from '../../assets/icons/salesforce/SalesforceContact'
import SalesforceMultimatchIcon from '../../assets/icons/salesforce/SalesforceMultimatch'
import NoSymbol from '../../assets/icons/svg/NoSymbol'
import SalesforceCampaignIcon from '../../assets/icons/salesforce/SalesforceCampaign'
import SalesforceAccountIcon from '../../assets/icons/salesforce/SalesforceAccount'
import SalesforceCaseIcon from '../../assets/icons/salesforce/SalesforceCase'
import SalesforceOpportunityIcon from '../../assets/icons/salesforce/SalesforceOpportunity'
import SalesforceFavoriteIcon from '../../assets/icons/salesforce/SalesforceFavorite'
import { Colors } from '../../constants/colors'
import SalesforceEventIcon from '../../assets/icons/salesforce/SalesforceEvent'
import SalesforceCustomIcon from '../../assets/icons/salesforce/SalesforceCustom'
import SalesforceTaskIcon from '../../assets/icons/salesforce/SalesforceTask'

/**
 * Component for rendering the appropriate icon for the type of record in Salesforce.
 */

const getSalesforceRecordIcon = (
  type?: SalesforceRecord
): [({ color }: { color?: Colors; size?: number; title?: string }) => JSX.Element, Colors] => {
  switch (type) {
    case SalesforceRecord.ACCOUNT:
      return [SalesforceAccountIcon, Colors.SALESFORCE_ACCOUNT]
    case SalesforceRecord.CASE:
      return [SalesforceCaseIcon, Colors.SALESFORCE_CASE]
    case SalesforceRecord.CONTACT:
      return [SalesforceContactIcon, Colors.SALESFORCE_CONTACT]
    case SalesforceRecord.CAMPAIGN:
      return [SalesforceCampaignIcon, Colors.SALESFORCE_CAMPAIGN]
    case SalesforceRecord.CUSTOM:
      return [SalesforceCustomIcon, Colors.SALESFORCE_CUSTOM]
    case SalesforceRecord.FAVORITE:
      return [SalesforceFavoriteIcon, Colors.SALESFORCE_FAVORITE]
    case SalesforceRecord.LEAD:
      return [SalesforceLeadIcon, Colors.SALESFORCE_LEAD]
    case SalesforceRecord.MULTIMATCH:
      return [SalesforceMultimatchIcon, Colors.SALESFORCE_MULTIMATCH]
    case SalesforceRecord.NONE:
      return [NoSymbol, Colors.F50]
    case SalesforceRecord.OPPORTUNITY:
      return [SalesforceOpportunityIcon, Colors.SALESFORCE_OPPORTUNITY]
    case SalesforceRecord.TASK:
      return [SalesforceTaskIcon, Colors.SALESFORCE_TASK]
    case SalesforceRecord.EVENT:
      return [SalesforceEventIcon, Colors.SALESFORCE_EVENT]
    default:
      return [SalesforceContactIcon, Colors.SALESFORCE_CONTACT]
  }
}

export default getSalesforceRecordIcon
