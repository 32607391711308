BaseCollection = require('base/collection')
Model = require('./salesforce-model')

class SalesforceUsersCollection extends BaseCollection

  url: -> 'users/salesforce/batch'

  setParams: (options) ->
    { @accountId, @areaCode, @extension, @location } = options

  toJSON: =>
    fields = @toPlayJSON('salesforceUsers')
    fields['accountId'] = @accountId
    fields['areaCode'] = @areaCode
    fields['extension'] = @extension
    fields['locationId'] = @location
    fields

  model: Model

module.exports = SalesforceUsersCollection
