import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const CalendarIcon = ({ color = Colors.N100, size = 24, strokeWidth = 1 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="calendar-icon">
    <path
      d="M3.3 4H17.625H20.7C20.8657 4 21 4.13431 21 4.3V20.7C21 20.8657 20.8657 21 20.7 21H3.3C3.13431 21 3 20.8657 3 20.7V4.3C3 4.13431 3.13431 4 3.3 4Z"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
    <path d="M7 3V5" stroke={color} strokeLinecap="round" strokeWidth={strokeWidth} />
    <path d="M17 3V5" stroke={color} strokeLinecap="round" strokeWidth={strokeWidth} />
    <path d="M3 8H21" stroke={color} strokeWidth={strokeWidth} />
  </svg>
)

export default CalendarIcon
