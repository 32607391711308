import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceContactIcon({ size = 17, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 12" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.29 4.389a.578.578 0 01-.562.56H9.636a.556.556 0 01-.56-.56v-.825c0-.297.263-.561.56-.561h4.125c.297 0 .561.23.561.56v.826h-.033zm0 3.003a.578.578 0 01-.562.561h-2.475a.557.557 0 01-.56-.561v-.825c0-.297.264-.561.56-.561h2.475c.297 0 .561.231.561.561v.825zM7.853 9.306H3.102c-.528 0-.923-.561-.923-1.122.032-.825.89-1.32 1.782-1.716.626-.264.725-.528.725-.792s-.164-.528-.396-.726c-.362-.33-.561-.825-.561-1.353 0-1.056.627-1.914 1.716-1.914s1.716.89 1.716 1.914c0 .56-.198 1.056-.561 1.353-.231.198-.395.429-.395.726 0 .264.066.528.725.759.891.396 1.75.924 1.782 1.749.099.56-.33 1.122-.858 1.122zM14.85 0H1.65C.759 0 0 .726 0 1.65v7.92c0 .891.759 1.65 1.65 1.65h13.2c.924 0 1.65-.726 1.65-1.65V1.65C16.5.726 15.774 0 14.85 0z"
        fill={color || Colors.SALESFORCE_CONTACT}
      />
    </svg>
  )
}

export default SalesforceContactIcon
