import React, { useState } from 'react'

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { Action, useUpdateSalesforceInspector } from '../../../../store/api/salesforceInspector-api'
import { useSupportSettingsState } from '../../utils/contextReducer'

export default React.memo(SalesforceClearCacheButton)
function SalesforceClearCacheButton() {
  const state = useSupportSettingsState()
  const [isLoading, setIsLoading] = useState(false)
  const updateSalesforceInspector = useUpdateSalesforceInspector()
  const onClear = async () => {
    try {
      setIsLoading(true)
      await updateSalesforceInspector({
        body: {
          action: Action.Cache,
          accountId: state.accountSettings['account.id']
        }
      })
      setIsLoading(false)
    } catch (error) {
      toastr.error(`An error has occurred, ${error}`)
      setIsLoading(false)
    }
  }

  return (
    <RdnaButton
      variant="contained"
      text={isLoading ? 'Clearing...' : 'Clear'}
      color="action"
      onClick={onClear}
      disabled={isLoading}
    />
  )
}
