AddCallQueueMembersModal         = require('../members/modals/view')
BackgridCell                     = require('helpers/backgrid-cell')
BackgridRow                      = require('helpers/backgrid-row')
BaseGrid                         = require('base/grid')
BaseView                         = require('base/view')
BaseModel                        = require('base/model')
CallQueueModel                   = require('./model')
MemberCollection                 = require('../members/collection')
MemberModel                      = require('../members/model')
NumberCollection                 = require('../numbers/collection')
NumberModel                      = require('../numbers/model')
SmartNumberWidget                = require('widgets/smart-numbers-widget/main-view')

class CallQueueView extends BaseView

  template: require('./template')
  className: 'call-queue-overview'

  bindings:
    'h1.name'                     : 'text:title'
    'input.name'                  : 'value:name,disabled:isDeleted'
    'input.description'           : 'value:description,disabled:isDeleted'
    'select.status'               : 'value:status,disabled:isDeleted'
    'button.delete'               : 'toggle:not(any(isNew,isDeleted))'
    'button.restore'              : 'toggle:all(not(isNew),isDeleted)'
    'button.save-call-queue'      : 'toggle:not(isDeleted)'
    'a.assign-call-queue-number'  : 'toggle:not(showAssignNumber)'
    '.acd'                        : 'toggle:isAcd'
    '.lwacp'                      : 'toggle:isLwacp'
    '.acd-lwacp'                  : 'toggle:any(isAcd,isLwacp)'
    '.lwa-lwacp'                  : 'toggle:any(isLwa,isLwacp)'
    '.call-queue-type'            : 'value:type,disabled:isDeleted'
    '.dial-timeout'               : 'value:dialTimeout,disabled:isDeleted'
    '.maximum-dials'              : 'value:maximumDials,disabled:isDeleted'
    '.maximum-hold-time'          : 'value:maximumHoldTime,disabled:isDeleted'
    '.next-loop-timeout'          : 'value:nextLoopTimeout,disabled:isDeleted'
    '.allow-wrap-up-time'         : 'checked:allowWrapUpTime'
    '.wrap-up-time-limit'         : 'value:wrapUpTimeLimit,disabled:any(not(allowWrapUpTime),isDeleted)'
    '.allow-reject'               : 'checked:allowReject'
    '.allow-unsubscribe'          : 'checked:allowUnsubscribe'
    '.allow-delete-voicemail'     : 'checked:allowDeleteVoicemail'
    '.glyphicon-plus-sign'        : 'toggle:not(isDeleted)'
    '.additional-actions'         : 'toggle:not(any(isNew,isDeleted))'
    '.handle-missed-call-as-answered': 'checked:handleMissedCallAsAnswered'

  computeds:
    title:
      deps: ['name', 'id']
      get: (name, id) ->
        if id and name
          'Call Queue: ' + name
        else
          'New Call Queue'
    status:
      deps: ['inactive']
      get: (inactive) ->
        if inactive then 'paused' else 'active'
      set: (value) ->
        if value is 'paused'
          @model.set('inactive', true)
        else if value is 'active'
          @model.set('inactive', false)
    isNew:
      get: ->
        @addNew
    showAssignNumber:
      deps: ['smartNumberCollection']
      get: (smartNumberCollection) ->
        smartNumberCollection and smartNumberCollection.models.length > 0
    isAcd:
      deps: ['type']
      get: (type) ->
        type is 'LongestWaitingAgent' or type is 'SequentialDial'
    isLwa:
      deps: ['type']
      get: (type) ->
        type is 'LongestWaitingAgent'
    isLwacp:
      deps: ['type']
      get: (type) ->
        type is 'LongestWaitingAgentCallParking'

  regions:
    modal               : 'div.modal-container'
    smartNumbers        : 'div.smart-numbers'
    members             : 'div.members'

  ui:
    deleteButton  : 'button.delete'
    saveButton    : 'button.save-call-queue'
    restoreButton : 'button.restore'

  events:
    'click a.assign-call-queue-number'  : 'assignNumber'
    'click a.add-members'               : 'addMembers'
    'click button.delete'               : 'delete'
    'click button.save-call-queue'      : 'save'
    'click button.restore'              : 'restore'
    'click a.clone-call-queue'          : 'cloneCallQueue'
    'click a.create-team'               : 'createTeam'
    'change .call-queue-type'           : 'updateDistStrategyTable'

  initialize: (options) =>
    @addNew = options.addNew
    if @addNew
      account =
        id: @user().get('accountId')
      if @user().accountPickerRenderable() and @user().getSelectedSupportAccountId(true)?.toString() isnt @user().get('accountId')?.toString()
        account =
          id: @user().getSelectedSupportAccountId(true)
      @model = new CallQueueModel(account: account)
    else
      @model = new CallQueueModel(id: options.queueId)

  updateDistStrategyTable: (event) =>
    strategy = event.target.value
    if strategy is 'LongestWaitingAgent' or strategy is 'SequentialDial'
      @model.set(maximumDials: @model.get('maximumDials') || 10)
      @model.set(dialTimeout:  @model.get('dialTimeout') || 10)
    if strategy is 'LongestWaitingAgentCallParking'
      @model.set(dialTimeout:  @model.get('dialTimeout') || 10)
      @model.set(maximumHoldTime:  @model.get('maximumHoldTime') || 300)
      @model.set(nextLoopTimeout:  @model.get('nextLoopTimeout') || 10)
    @showStrategyMessage(strategy)

  showStrategyMessage: (strategy) =>
    @$('.strategy-message').hide()
    @$("tr[data-strategy='#{strategy}']").show()

  onRender: =>
    unless @addNew
      @model.fetch().done(=>
        @numberCollection = new NumberCollection()
        @memberCollection = new MemberCollection([], queueId: @model.get('id'), type: 'Regular')
        @numberCollection.reset(@model.get('smartNumbers'))
        @model.set('smartNumberCollection', @numberCollection)
        @model.unset('smartNumbers', silent: true)
        for value in @model.get('userCallQueues')
          if value.rememberSubscription then value.user['rememberSubscription'] = 'Yes' else value.user['rememberSubscription'] = 'No'
          value.user['queueId'] = @model.get('id')
          value.user['memberOrder'] = value.memberOrder
          if value.type is 'Regular'
            @memberCollection.add(new MemberModel(value.user))
        @model.set('memberCollection', @memberCollection)
        @model.unset('userCallQueues', silent: true)
        @showStrategyMessage(@model.get('type'))
        @initializeChildViews()
        @applyBindings()
        @setToggleState()
      )

  onAttach: =>
    @$('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onDomRefresh: =>
    @$('[data-toggle="tooltip"]').tooltip()

  initializeChildViews: =>
    return unless @getRegion('smartNumbers')
    if @numberCollection and @numberCollection.models.length and not @numberView
      @numberView = @buildNumbersGrid(@numberCollection)
      @showChildView('smartNumbers', @numberView)
      @listenTo(@numberCollection, 'remove', @onUpdateNumber)
    if @memberCollection and @memberCollection.models.length and not @membersView
      @membersView = @buildMembersGrid(@memberCollection)
      @showChildView('members', @membersView)
      @listenTo(@memberCollection, 'remove', @onUpdateMembers)

  buildNumbersGrid: (collection) ->
    numberCell = BackgridCell.href(
      url   : '#smart-numbers/:id',
      label : ':number',
      attrs : ['id', 'number'])

    datetime = BackgridCell.dateTime()

    cols = [
      {label: 'Number',         name: 'number',       cell: numberCell,          renderable: @user().can('smart-numbers:view:*')}
      {label: 'Number',         name: 'number',       cell: 'string',            renderable: not @user().can('smart-numbers:view:*')}
      {label: 'Type',           name: 'type',         cell: 'string'}
      {label: 'Date Created',   name: 'dateCreated',  cell: datetime}
      {label: 'Date Last Used', name: 'dateLastUsed', cell: datetime}
      {label: 'ID',             name: 'id',           cell: 'string'}
      {label: 'Remove',         name: '',             cell: BaseGrid.DeleteCell, renderable: not @model.get('isDeleted') }
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart number associated.'
    )

  buildMembersGrid: (collection) ->
    userCell = BackgridCell.usersCell('id', 'displayName')

    deleteCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a.delete': 'delete'
      delete: =>
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          if ok
            collection = @model.collection
            @model.destroy().done(=>
              order = 1
              for member in collection.models
                if member.get('memberOrder') isnt order
                  member.set('memberOrder', order)
                order++
            )

          true
        )
      render: ->
        if @model.get('deletable')
          $(@el).html('''
            <a class="delete">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          ''')
        @

    cols = [
      {label: '',         name: 'memberOrder',    cell: BaseGrid.OrderCell, sortable: false}
      {label: 'Order',    name: 'memberOrder',    cell: 'string', sortable: false}
      {label: 'Name',     name: 'displayName',    cell: userCell, renderable: @user().can('users:edit:*'), sortable: false}
      {label: 'Name',     name: 'displayName',    cell: 'string', renderable: not @user().can('users:edit:*'), sortable: false}
      {label: 'Subscribed', name: 'rememberSubscription', cell: 'string', sortable: false}
      {label: 'Title',    name: 'title',          cell: 'string', sortable: false}
      {label: 'Location', name: 'location.name',  cell: 'string', sortable: false}
      {label: 'Queues',   name: 'callQueueCount', cell: 'string', sortable: false}
      {label: 'Remove',   name: '',               cell: deleteCell, renderable: not @model.get('isDeleted'), sortable: false}
    ]

    new BaseGrid.Grid(
      collection : collection
      columns    : cols
      emptyText  : 'No members associated.'
      onMoveOne  : @moveOne
      onMoveBound: @moveBound
    )

  moveOne: (options) =>
    model = options.model
    index = @memberCollection.indexOf(model)
    if index isnt -1
      @memberCollection.remove(model)
      @memberCollection.add(model, {at: if options.move then index - 1 else index + 1})
      @reorderMembers()

  moveBound: (options) =>
    model = options.model
    index = @memberCollection.indexOf(model)
    if index isnt -1
      @memberCollection.remove(model)
      if options.move then @memberCollection.unshift(model) else @memberCollection.push(model)
      @reorderMembers()

  assignNumber: =>
    return unless @validateModel()
    view = new SmartNumberWidget(
      accountId : @model.get('account').id,
      objectId  : @model.get('id'),
      type      : 'queue'
    )
    @showChildView('modal', view)
    @listenTo(view, 'assigned', @onAssignQueueNumber)

  addMembers: =>
    return unless @validateModel()
    view = new AddCallQueueMembersModal(
      type: 'Regular'
      accountId: @model.get('account')?.id
      queueId: @model.get('id')
    )
    @showChildView('modal', view)
    @listenTo(view, 'save', @onAddMembers)

  onAssignQueueNumber: (numbers) =>
    @numberCollection.add(numbers.at(0).get('smartNumber')) if numbers.length
    @initializeChildViews()
    @applyBindings()

  onAddMembers: (members) =>
    @addUsersToQueue(members, 'Regular', @onUpdateMembers)

  addUsersToQueue: (collection, type, callback) =>
    collection.create().done?(=>
      for model in collection.models
        model.set('queueId', @model.get('id'))
        if type is 'Regular'
          member = new MemberModel(model.attributes)
          member.set(rememberSubscription: 'Yes')
          @memberCollection.add(member)
      @initializeChildViews()
      callback?()
    )

  onUpdateMembers: =>
    return unless @memberCollection?.models
    if @memberCollection.models.length <= 1
      model.set('deletable', false) for model in @memberCollection.models
    if @memberCollection.models.length > 1
      model.set('deletable', true) for model in @memberCollection.models

  onUpdateNumber: =>
    @applyBindings()
    return unless @numberView and @numberCollection
    if @numberCollection.models.length < 1
      @showChildView('smartNumbers', new BaseView({
        template: _.constant('<p class="italic">No smart number associated.</p>')
      }))
      @numberView = null

  delete: =>
    return unless @validateModel()
    bootbox.dialog(
      title: 'Delete Queue'
      message: 'You are about to delete this queue. Deleted queues may be recovered for up to 30 days from the original delete date.',
      buttons:
        ok:
          label: 'Delete'
          className: 'btn-danger'
          callback: =>
            @model.delete(ladda: @ui.deleteButton[0]).done(=> @refresh(true))
    )

  restore: =>
    return unless @validateModel()
    ladda = Ladda.create(@ui.restoreButton[0])
    ladda.start()
    App.api.put(
      path: '/api/v2/app/callQueues/restore/' + @model.get('id')
      success : => @refresh(false)
      complete: => ladda.stop()
    )

  refresh: (isDeleted) =>
    @model.set(isDeleted: isDeleted)
    @numberView = null
    @membersView = null
    @getRegion('members').destroy()
    @$('.allow-wrap-up-time').prop('disabled', @model.get('isDeleted'))
    @$('.allow-reject').prop('disabled', @model.get('isDeleted'))
    @initializeChildViews()
    @applyBindings()

  cloneCallQueue: =>
    App.api.post(
      path    : "callQueues/#{@model.get('id')}/clone"
      success : (response) =>
        App.router.navigate("#call-queues/#{response}", {trigger: true})
        toastr.info('Call Queue copied.')
    )

  createTeam: ->
    App.api.post(
      path    : "callQueues/#{@model.get('id')}/createTeam"
      success : (response) =>
        App.router.navigate("#teams/#{response}", {trigger: true})
    )

  save: =>
    return unless @validate()
    @model.persist(ladda: @ui.saveButton[0]).done?(@savedActions)

  savedActions: =>
    toastr.info('Queue info saved.')
    if @model.get('id')
      @addNew = false
      App.router.navigate('#call-queues/' + @model.get('id'), {trigger: true})

  setToggleState: =>
    $(element).bootstrapToggle(if @model.get($(element).data('name')) then 'on' else 'off') for element in @$('.toggle-switch')
    @$('.toggle-switch').prop('disabled', @model.get('isDeleted'))

  validateModel: =>
    if @model and @model.get('id') and not @addNew
      return true
    toastr.warning('Please save queue before current action.')
    false

  validate: =>
    if not @model.get('name') or not @model.get('name').trim()
      toastr.warning('Please enter a Name.')
      return false
    if @model.get('dialTimeout') and parseInt(@model.get('dialTimeout')) < 5
      toastr.warning('Dial Timeout must be greater than or equal to 5 seconds')
      return false
    if @getBinding('isLwacp') and (not @model.get('nextLoopTimeout') or parseInt(@model.get('nextLoopTimeout')) < 10 or parseInt(@model.get('nextLoopTimeout')) > 9999)
      toastr.warning('Please enter a value between 10 and 9999')
      return false
    if @getBinding('isLwacp') and (not @model.get('maximumHoldTime') or parseInt(@model.get('maximumHoldTime')) < 10 or parseInt(@model.get('maximumHoldTime')) > 9999)
      toastr.warning('Please enter a value between 10 and 9999')
      return false
    true

  reorderMembers: =>
    newOrders = {}
    order = 1
    for member in @memberCollection.models
      if member.get('memberOrder') isnt order
        member.set('memberOrder', order)
        newOrders[member.get('id')] = order
      order++

    if not _.isEmpty(newOrders)
      App.api.put(
        path: "callQueues/#{@model.get('id')}/reorder"
        data: newOrders: newOrders
      )

module.exports = CallQueueView
