import { ResourceGeneric, CacheType, Storage } from '../constants'

type Subscriber = () => void

// @ts-ignore
const isBuild = import.meta.env.PROD

class ClientCache {
  isReady = false
  readyPromise: Promise<void>
  initSize = -1
  listeners: Set<Subscriber> = new Set()
  data: Array<ResourceGeneric> = []
  map: Map<string, ResourceGeneric> = new Map()
  renewTime = Date.now()

  // @TODO add default cache timeout
  constructor(
    public storageKey: string,
    public storage: Storage
  ) {
    this.readyPromise = new Promise(resolve => this.rehydrate(resolve))
  }

  renew() {
    this.renewTime = Date.now()
    this.notify()
  }

  notify() {
    // @TODO debounce / batch
    this.listeners.forEach(sub => sub())
  }
  subscribe(subscriber: Subscriber) {
    this.listeners.add(subscriber)
    return () => {
      this.listeners.delete(subscriber)
    }
  }

  persist() {
    // only persist keys which start with CacheType.Disk
    // @TODO there will be perf issues here with large caches. Consider using async serialize, and persisting the persistData object in memory. Also requestIdleCallback.
    const persistData = this.data.filter(v => {
      return v.cacheKey.indexOf(CacheType.Disk) === 0
    })
    this.storage.setItem(this.storageKey, JSON.stringify(persistData)).catch(err => {
      // if we hit a storage quota limit, prune off the front of the cache and try again
      if (err.message.toUpperCase().includes('QUOTA')) {
        console.error('## client: cache exceeded storage limits, pruning. Current resource count is ', this.data.length)
        this.data.shift()
        this.persist()
      }
    })
  }
  purge() {
    this.data = []
    this.map = new Map()
    this.notify()
    return this.persist()
  }


  async rehydrate(onComplete: () => void) {
    const serialData = await this.storage.getItem(this.storageKey)
    if (serialData) {
      this.data = JSON.parse(serialData)
      this.data.forEach(v => {
        this.map.set(v.cacheKey, v)
      })
      this.initSize = serialData.length
    }
    this.isReady = true
    onComplete()
    this.notify()
  }

  async set(key: string, value: ResourceGeneric) {
    if (!isBuild && !this.isReady)
      console.error(
        'WARNING: setting the ClientCache before it is ready can lead to unexpected results. Usually waiting for ready is handled by the createUseFetch* implementation.'
      )

    // remove the existing version of this resource from the cache
    const existingResource = this.map.get(key)
    // @TODO there is probably a faster data structure for doing this, like a linked list
    existingResource && this.data.splice(this.data.indexOf(existingResource), 1)

    this.map.set(key, value)
    this.data.push(value)

    this.persist()
    this.notify()
  }

  // get cannot be async as it is used in hooks
  get = (k: string) => this.map.get(k)
}

export { ClientCache }
