AddCallFlowModal  = require('./modals/view')
BackgridCell      = require('helpers/backgrid-cell')
BaseGrid          = require('base/grid')
BaseView          = require('base/view')

class CallFlowView extends BaseView

  template: require('./template')
  className: 'call-flow-view'

  regions:
    callFlow: 'div.call-flow'
    modal: 'div.modal-container'

  ui:
    addCallFlow: 'a.add-call-flow'

  bindings:
    'a.add-call-flow': "classes:{'disabled':hasCallFlow}"

  events:
    'click @ui.addCallFlow': 'addCallFlow'

  computeds:
    hasCallFlow:
      get: ->
        @callFlowCollection.length isnt 0


  initialize: (options) ->
    @callFlowCollection = options.callFlowCollection
    @listenTo(@callFlowCollection, 'add remove reset', ->
      @applyBindings()
    )
    @accountId = options.accountId

  onRender: =>
    @callFlowView = @buildCallFlowGrid(@callFlowCollection)
    @showChildView('callFlow', @callFlowView)

  addCallFlow: =>
    view = new AddCallFlowModal(accountId: @accountId)
    @showChildView('modal', view)
    @listenTo(view, 'save', (callFlowId) =>
      @trigger('addCallFlow', callFlowId)
    )

  buildCallFlowGrid: (collection) ->
    СallFlowDeleteCell = class extends BaseGrid.DeleteCell
      delete: ->
        bootbox.confirm('Are you sure you want to delete call flow from this smart number?', (ok) =>
          @model.destroy() if ok
          true
        )
    CallFlowCell = BackgridCell.href(
      url   : '#call-flows/:id',
      label : ':name',
      attrs : ['id', 'name'])

    СallFlowStatusCell = class extends Backgrid.StringCell
      render: ->
        $(@el).empty()
        status = if @model.get('paused') then 'Inactive' else 'Active'
        $(@el).text(status)
        @delegateEvents()
        @

    cols = [
      {label: 'Name',         name: 'name',         cell: CallFlowCell}
      {label: 'Description',  name: 'description',  cell: 'string'}
      {label: 'Status',       name: 'paused',       cell: СallFlowStatusCell}
      {label: 'Delete',       name: '',             cell: СallFlowDeleteCell }
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'There are no Call Flows assigned.'
    )


module.exports = CallFlowView