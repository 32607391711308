export const getInitials = (name?: string): string => {
  if (!name) return '?'
  const initials = name.match(/\b\w/g) as string[]
  if (!initials) return '?'
  if (name.indexOf(',') > -1) {
    initials.reverse()
  }

  // if phone number user avatar name should display as #
  if (/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(name)) return '#'

  // TODO: Handle Jr, Sr, Dr, MD etc.
  return (initials.shift() + (initials.pop() || '')).toUpperCase()
}
