import * as React from 'react'
import { BaseIconProps } from '../types'

function SalesforceOpportunityIcon({ size = 14, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.687 2.392c-.729 0-1.313.583-1.313 1.313 0 .408.175.758.468.991a2.348 2.348 0 01-2.217 1.4 2.275 2.275 0 01-2.158-2.158c0-.205 0-.38.03-.555.494-.204.845-.641.845-1.225A1.346 1.346 0 007 .876c-.73 0-1.312.582-1.312 1.312 0 .554.35 1.02.816 1.225.059.175.059.35.059.554a2.276 2.276 0 01-2.159 2.158c-.991.058-1.866-.554-2.245-1.4a1.306 1.306 0 00-.846-2.304c-.73 0-1.312.583-1.312 1.312 0 .73.583 1.313 1.312 1.313l.612 4.666c.03.204.204.35.409.35h9.333a.408.408 0 00.407-.35l.613-4.666c.73 0 1.313-.583 1.313-1.313 0-.729-.584-1.341-1.313-1.341zm-1.08 8.983H2.392a.417.417 0 00-.407.408v.03c0 .728.583 1.312 1.312 1.312h7.437c.73 0 1.312-.584 1.312-1.313v-.03c-.03-.203-.204-.407-.437-.407z"
        fill={color || '#F3BA6B'}
      />
    </svg>
  )
}

export default SalesforceOpportunityIcon
