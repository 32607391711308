BaseModel = require('base/model')

class LicenseRequestModel extends BaseModel
  url: 'licenses/addRequest'

  defaults:
    smartNumbers: 0
    minutes: 0
    adminUsers: 0
    dialerUsers: 0
    caiUsers: 0
    emailCalendarUser: 0
    sequenceUsers: 0
    effectiveDate: null
    details: ''

module.exports = LicenseRequestModel
