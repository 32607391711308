import * as React from 'react'

import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function StarIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M8.18812 18L11.9769 12.9692L15.8581 18L17.0594 17.1541L13.2244 12.1678L19 10.387L18.538 9.00685L12.7624 10.8322V5H11.2838V10.8322L5.46205 9.00685L5 10.387L10.8218 12.1678L6.9868 17.1541L8.18812 18Z"
        fill={color}
      />
    </svg>
  )
}

export default StarIcon
