import React from 'react'
import { Role } from '../types'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE } from '../const'
import { RoleDrawer } from '../styles'
import { roleDefinitionsColumns } from "../columns";

export type RoleDefinitionsPanelProps = {
  open: boolean
  onClose: () => void
  roleDefinitions: Role[]
}

export default function RoleDefinitionsPanel({ open, onClose, roleDefinitions }: RoleDefinitionsPanelProps) {
  return (
    <RoleDrawer open={open} heading={'Role Definitions'} onClose={onClose} onBack={onClose}>
      <RdnaSmartTable
        searchPlaceholder="Search"
        //@ts-ignore
        columns={roleDefinitionsColumns}
        data={roleDefinitions}
        noResultState={NO_RESULT_STATE}
        paginationSize={DEFAULT_PAGE_SIZE}
        rowHeight={'100%'}
      />
    </RoleDrawer>
  )
}
