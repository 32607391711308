import styled from 'styled-components'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'

export const Section = styled(RdnaCard)`
  width: auto;
  .rdna-text {
    margin: 0 ${({ theme }) => theme.spacing * 2}px;
    display: flex;
    align-items: center;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
`

export const SectionSubHeader = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacing * 3}px;
`

export const Body = styled.div`
  padding-top: ${({ theme }) => theme.spacing * 3}px;
`

export const SettingSelect = styled(RdnaSelect)`
  width: ${props => props.theme.spacing * 45}px;
  margin-left: ${props => props.theme.spacing * 2}px;
`

