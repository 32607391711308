Handlebars = require('handlebars/runtime').default
BaseModel = require('base/model')
AbilityService = require('./ability-service')
config = require('../../src/config').default
subDomain = require('../../src/config/legacy').subDomain

class UserModel extends BaseModel

  defaults:
    sandbox: false
    customUrl: null

  initialize: ->
    @ability = new AbilityService()
    @debounceUpdateAccountPicker = _.debounce(@updateAccountPicker, 1000)

  login: ->
    @persist(
      url  : '/api/v2/session/authenticate'
      data : { device: 'webapp', type: 'salesforce', uiEnv: subDomain }
      anon : true
    )

  logout: ->
    @persist(
      url: '/api/v2/session/logout'
    )

  setup: (unverifiedSessionId) ->
    @persist(
      url       : '/api/v2/session/setup'
      data      : { device: 'webapp' }
      sessionId : unverifiedSessionId
    )

  parse: (response) ->
    isAdmin = response.licenses?.admin
    isSupport = response.licenses?.support

    @ability.setup(response.licenses,
      'activity-feed:view'       : response.caiManager
      'cai-settings:view'        : response.caiManager
      'call-flows:callouts:edit' : response.dynamicCallFlows
      'call-flows:edit:me'       : if isSupport then true else (isAdmin and response.callFlowEditor)
      'call-tracking:view'       : if isSupport then true else (isAdmin and response.callTrackingEnabled)
      'cdm:search'               : response.settings.cdm and (isAdmin or isSupport)
      'cdm:view'                 : response.settings.cdm and (isAdmin or isSupport)
      'smart-recordings:view'    : response.licenses?.conversationAI
      'sfdc-users:location:edit' : response.settings.locationRequired
      'video:edit'               : response.videoEnabled
    ) if response.licenses

    {
      acceptedTerms: response.acceptedTerms
      accountAdvancedSearch: response.accountAdvancedSearch
      accountAgentStatusCallout: response.accountAgentStatusCallout
      accountAgentStatusCalloutEndpoint: response.accountAgentStatusCalloutEndpoint
      accountAgentUnavailableFlowId: response.accountAgentUnavailableFlowId
      accountAllowBlockCall: response.accountAllowBlockCall
      accountCallDispositionPrompt: response.accountCallDispositionPrompt
      accountCallDispositionRequiredDate: response.accountCallDispositionRequiredDate
      accountCallForwarding: response.accountCallForwarding
      accountCallForwardingPrompt: response.accountCallForwardingPrompt
      accountCallNoteTemplates: response.accountCallNoteTemplates
      accountCallNotification: response.accountCallNotification
      accountCallQueueCheckEnabled: response.accountCallQueueCheckEnabled
      accountCallRecordingByCountry: response.accountCallRecordingByCountry
      accountCallRecordingByState: response.accountCallRecordingByState
      accountCallRecordingLocked: response.accountCallRecordingLocked
      accountCallRecordingOption: response.accountCallRecordingOption
      accountCallRecordingOverride: response.accountCallRecordingOverride
      accountCallRecordingOverrideOption: response.accountCallRecordingOverrideOption
      accountCallerIdEnabled: response.accountCallerIdEnabled
      accountClickToVoicemail: response.accountClickToVoicemail
      accountConference: response.accountConference
      accountContinuousBridge: response.accountContinuousBridge
      accountContinuousChime: response.accountContinuousChime
      accountCreatedByUserId: response.accountCreatedByUserId
      accountCreatedDate: response.accountCreatedDate
      accountCreatedVia: response.accountCreatedVia
      accountDateDeleted: response.accountDateDeleted
      accountDirectConnect: response.accountDirectConnect
      accountDualChannelRecording: response.accountDualChannelRecording
      accountDynamicCallFlows: response.accountDynamicCallFlows
      accountEmail: response.accountEmail
      accountEmailSendViaSalesforce: response.accountEmailSendViaSalesforce
      accountExtensionNonPhoneFilter: response.accountExtensionNonPhoneFilter
      accountHideGroupCalls: response.accountHideGroupCalls
      accountId: response.accountId
      accountInboundCallRecordingAnnouncement: response.accountInboundCallRecordingAnnouncement
      accountInboundCallTone: response.accountInboundCallTone
      accountIsDeleted: response.accountIsDeleted
      accountIsSfSandbox: response.accountIsSfSandbox
      accountLicenseId: response.accountLicenseId
      accountLightningEmailTemplates: response.accountLightningEmailTemplates
      accountLocalPresence: response.accountLocalPresence
      accountLocalPresenceUseVerifiedNumbers: response.accountLocalPresenceUseVerifiedNumbers
      accountLocationRequired: response.accountLocationRequired
      accountManageCallDispositionRequiredByTeam: response.accountManageCallDispositionRequiredByTeam
      accountManageCallDispositions: response.accountManageCallDispositions
      accountMessaging: response.accountMessaging
      accountMessagingFeaturesEnabled: response.accountMessagingFeaturesEnabled
      accountMultiMatchRequiredDate: response.accountMultiMatchRequiredDate
      accountName: response.accountName
      accountName: response.accountName
      accountOutboundCallRecordingAnnouncement: response.accountOutboundCallRecordingAnnouncement
      accountRecordingPauseEnabled: response.accountRecordingPauseEnabled
      accountRecordingStatusEnabled: response.accountRecordingStatusEnabled
      accountRelatedRecords: response.accountRelatedRecords
      accountSfOrgId: response.accountSfOrgId
      accountSfdcCreateLeadOnMultiMatch: response.accountSfdcCreateLeadOnMultiMatch
      accountSfdcCreateLeadOnSfdcCampaign: response.accountSfdcCreateLeadOnSfdcCampaign
      accountSfdcCreateLeadOnUnansweredCall: response.accountSfdcCreateLeadOnUnansweredCall
      accountSfdcCreateSmartNumberCampaigns: response.accountSfdcCreateSmartNumberCampaigns
      accountSfdcCreateTasksEarly: response.accountSfdcCreateTasksEarly
      accountSfdcCustomCallObject: response.accountSfdcCustomCallObject
      accountSfdcDefaultMatchType: response.accountSfdcDefaultMatchType
      accountSfdcDisableLeadCreation: response.accountSfdcDisableLeadCreation
      accountSfdcEnhancedAnalytics: response.accountSfdcEnhancedAnalytics
      accountSfdcHonorDoNotCallOrEmail: response.accountSfdcHonorDoNotCallOrEmail
      accountSfdcIgnoreIntegrityErrors: response.accountSfdcIgnoreIntegrityErrors
      accountSfdcMatchAccounts: response.accountSfdcMatchAccounts
      accountSfdcMatchContacts: response.accountSfdcMatchContacts
      accountSfdcMatchLeads: response.accountSfdcMatchLeads
      accountSfdcMatchOnExternal: response.accountSfdcMatchOnExternal
      accountSfdcMatchOpportunities: response.accountSfdcMatchOpportunities
      accountSfdcMultiMatchSetting: response.accountSfdcMultiMatchSetting
      accountSfdcMultipleMatchAccountField: response.accountSfdcMultipleMatchAccountField
      accountSfdcMultipleMatchContactField: response.accountSfdcMultipleMatchContactField
      accountSfdcMultipleMatchLeadField: response.accountSfdcMultipleMatchLeadField
      accountSfdcOpenInSalesConsole: response.accountSfdcOpenInSalesConsole
      accountSfdcReuseTask: response.accountSfdcReuseTask
      accountSfdcSoslSearch: response.accountSfdcSoslSearch
      accountSfdcSubjectRequired: response.accountSfdcSubjectRequired
      accountSipClient: response.accountSipClient
      accountSipForwarding: response.accountSipForwarding
      accountSipRoutingId: response.accountSipRoutingId
      accountSmsTemplates: response.accountSmsTemplates
      accountSoundDisconnect: response.accountSoundDisconnect
      accountSoundIncoming: response.accountSoundIncoming
      accountSoundOutgoing: response.accountSoundOutgoing
      accountSsoUrl: response.accountSsoUrl
      accountType: response.accountType
      accountUndeliverableSmsOwnerId: response.accountUndeliverableSmsOwnerId
      accountUserSkills: response.accountUserSkills
      accountUpdatedDate: response.accountUpdatedDate
      accountVoicebaseProcessingSpeed: response.accountVoicebaseProcessingSpeed
      accountVoicemailEnabled: response.accountVoicemailEnabled
      accountVoicemailNotifyGroups: response.accountVoicemailNotifyGroups
      accountVoicemailTimeout: response.accountVoicemailTimeout
      accountVoicemailTranscriptionByCountry: response.accountVoicemailTranscriptionByCountry
      accountVoicemailTranscriptionEnabled: response.accountVoicemailTranscriptionEnabled
      accountVoicemailtranscriptionBlacklist: response.accountVoicemailtranscriptionBlacklist
      accountWaitonConferenCeenter: response.accountWaitonConferenCeenter
      accountWebRtcEnabled: response.accountWebRtcEnabled
      address                     : response.address
      alerts                      : response.alerts
      caiManager                  : response.caiManager
      calendarBooking             : response.calendarBooking
      channel                     : response.channel
      city                        : response.city
      continuousBridgeEnabled     : response.continuousBridgeEnabled
      conversationAiLoc           : response.settings?.conversationAiLocRestricted
      country                     : response.country
      countryCode                 : response.countryCode
      createdDate                 : response.createdDate
      customUserStatusAutoSwitch  : response.customUserStatusAutoSwitch
      customUserStatusEnabled     : response.customUserStatusEnabled
      customUserStatusTimeOverride: response.customUserStatusTimeOverride
      deletedDate                 : response.deletedDate
      displayName                 : response.userName
      extension                   : response.extension
      firstName                   : response.firstName
      hasAccessProfile            : response.hasAccessProfile
      hasNewProfiles              : response.hasNewProfiles
      isDeleted                   : response.isDeleted
      lastName                    : response.lastName
      licenses                    : response.licenses
      locationId                  : response.locationId
      loginDate                   : response.loginDate
      outboundCallerId            : response.outboundCallerId
      salesforceAccessToken       : response.salesforceAccessToken
      salesforceSandboxUrl        : response.salesforceSandboxUrl ? response.sandboxUrl
      salesforceUrl               : response.salesforceUrl ? response.url
      salesforceUserId            : response.salesforceUserId
      sendFeedback                : response.settings?.sendFeedback
      settings                    : response.settings
      sfAccountId                 : response.sfAccountId
      sfAlias                     : response.sfAlias
      sfCompany                   : response.sfCompany
      sfDepartment                : response.sfDepartment
      sfDivision                  : response.sfDivision
      sfLanguage                  : response.sfLanguage
      sfLocale                    : response.sfLocale
      sfManager                   : response.sfManager
      sfNickname                  : response.sfNickname
      sfProfile                   : response.sfProfile
      sfRole                      : response.sfRole
      sfRunAsUser                 : response.sfRunAsUser
      sfUserId                    : response.sfUserId
      state                       : response.state
      userAccountAuthorizations   : response.userAccountAuthorizations
      userEmail                   : response.userEmail
      userId                      : response.userId
      userLocationName            : response.userLocationName
      userName                    : response.userName
      userPicture                 : response.pictureUrl
      userUserName                : response.userUserName
      zip                         : response.zip
      zoomIntegrationEnabled      : response.zoomIntegrationEnabled
      googleIntegrationEnabled    : response.googleIntegrationEnabled
      googleIntegrationStatus     : response.googleIntegrationStatus
      exchangeIntegrationEnabled  : response.exchangeIntegrationEnabled
      exchangeIntegrationStatus   : response.exchangeIntegrationStatus
      advancedSolutionPackageVersion: response.advancedSolutionPackageVersion
      basePackageVersion          : response.basePackageVersion
      cadencePackageVersion       : response.cadencePackageVersion
      usedDialerLicenses          : response.usedDialerLicenses
      allowedDialerLicenses       : response.allowedDialerLicenses
      usedConversationAILicenses  : response.usedConversationAILicenses
      allowedConversationAILicenses: response.allowedConversationAILicenses
      usedAdminLicenses           : response.usedAdminLicenses
      allowedAdminLicenses        : response.allowedAdminLicenses
      usedCalendarLicenses        : response.usedCalendarLicenses
      allowedCalendarLicenses     : response.allowedCalendarLicenses
      usedGuidedSellingLicenses   : response.usedGuidedSellingLicenses
      allowedGuidedSellingLicenses: response.allowedGuidedSellingLicenses
      usedMomentsLicenses         : response.usedMomentsLicenses
      allowedMomentsLicenses      : response.allowedMomentsLicenses
    }

  can: (action) ->
    @ability.can(action)

  canByUserRole: (action) ->
    !!(@isSupport() and @can(action)) or (@isAdmin() and @hasUserAccountAuthorizations())

  photoUrl: ->
    if @get('userPicture')
      "#{@get('userPicture')}?oauth_token=#{@get('salesforceAccessToken')}"
    else
      Handlebars.helpers.imageUrl('sfdc-default-chatter-icon.png')

  profileUrl: ->
    @salesforceUrl(@get('salesforceUserId'))

  salesforceUrl: (id) ->
    "#{@get('salesforceUrl')}/secur/frontdoor.jsp?sid=#{@get('salesforceAccessToken')}&retURL=#{id}"

  salesforceLoginUrl: ->
    if @attributes.sandbox
      @attributes.salesforceSandboxUrl
    else if @attributes.customUrl
      @attributes.salesforceUrl = @attributes.salesforceUrl.replace('login.salesforce.com', @attributes.customUrl.replace(/^https?:\/\//,''))
    else
      @attributes.salesforceUrl

  hasUserAccountAuthorizations: =>
    @isAdmin() and @get('userAccountAuthorizations').length > 0

  isSupport: -> @get('licenses')?.support

  isSalesSupport: -> @get('licenses')?.salesSupport

  isAdmin: -> @get('licenses')?.admin

  isAgent: -> not (@isSupport() or @isAdmin())

  isSupervisor: -> @get('licenses')?.supervisor

  allowedDialer: -> @get('allowedAdminLicenses') > 0 and @get('licenses')?.admin or @isSupport()

  allowedMoments: -> @get('allowedMomentsLicenses') > 0 and @get('licenses')?.moments or @isSupport()

  allowedGuidedSelling: -> @get('allowedGuidedSellingLicenses') > 0 and @get('licenses')?.guidedSelling or @isSupport()

  allowedCalendar: -> @get('allowedCalendarLicenses') > 0 and @get('licenses')?.calendar or @isSupport()

  allowedConversationAI: -> @get('allowedConversationAILicenses') > 0 and @get('licenses')?.conversationAI or @isSupport()

  accountPickerRenderable: =>
    @can('accounts:edit:*') || @hasUserAccountAuthorizations()

  saveSelectedSupportAccountId: (id, text) ->
    sessionStorage.setItem('support-accountId', id)
    sessionStorage.setItem('support-accountName', text)

  getSelectedSupportAccountName: ->
    return @get('accountName') unless @accountPickerRenderable()
    accountName = sessionStorage.getItem('support-accountName')
    accountName = @get('accountName') unless accountName
    return accountName

  getSelectedSupportAccountId: (required = false) ->
    return @get('accountId') unless @accountPickerRenderable()
    accountId = sessionStorage.getItem('support-accountId')
    if accountId is 'ALL'
      if required
        @debounceUpdateAccountPicker()
        return @get('accountId')
      else
        return null
    unless accountId
      @saveSelectedSupportAccountId(@get('accountId'), @get('accountName'))
      accountId = @get('accountId')
    return accountId

  updateAccountPicker: ->
    App.view?.header?.currentView?.updateAccountPicker(@get('accountId'), @get('accountName'))

module.exports = UserModel
