ReactComponent = require('src/ReactComponent').default
ServiceUserTable    = require('src/components/service-user/ServiceUserTable').default

class ServiceUserView extends Marionette.View
  template: => '<div class="react"></div>'
  regions:
    react           : 'div.react'

  onRender: =>
    @showChildView('react', new ReactComponent({ component: ServiceUserTable }))

module.exports = ServiceUserView
