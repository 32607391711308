import { isEqual } from 'lodash-es'

/**
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the initial value of the result.
 *
 * @param obj1 - First object to compare
 * @param obj2 - Second object to compare
 * @returns string[] array of keys that have changed between two objects.
 */
export function getObjectDiff(obj1: any, obj2: any): string[] {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))

  return diff
}

export function copyJsonData(data: any): any {
  return JSON.parse(JSON.stringify(data))
}
