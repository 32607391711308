BaseModel                = require('base/model')
BaseView                 = require('base/view')
CallflowPickerView       = require('widgets/callflow-picker/view')
SipRoutingPickerView     = require('widgets/sip-routing-picker/view')
OutboundNumbersView      = require('./outbound-numbers/view')
VoicemailGreetingView    = require('./voicemail-greeting/view')
VoicemailDropsView       = require('./voicemail-drop/view')
UserQueueView            = require('./user-queue/view')
SmartNumberWidget             = require('widgets/smart-numbers-widget/main-view')
SmartNumberModel              = require('../../../smart-numbers/overview/model')
SmartNumberRowView            = require('./smart-numbers/smart-number-row-view')
SmartNumberTableView          = require('./smart-numbers/smart-number-table-view')
UserSkillView                 = require('./user-skill/view')
VoicemailDropCollection  = require('widgets/voicemail-modals/collection')
adminTemplate = require('./template-admin.hbs')
agentTemplate = require('./template-agent.hbs')
supportTemplate = require('./template-support.hbs')
isEmail = require('@ringdna/common/src/utils/validationUtils').default.isEmail

class UserSettingsView extends BaseView
  className: 'user-settings'

  regions:
    sipRoutingPicker: '.sipRoutingPicker'
    callFlowPicker: '.callFlowPicker'
    voicemailDrops: 'div.voicemail-drops'
    outboundNumbers: 'div.outbound-numbers'
    voicemailGreeting: 'div.voicemail-greeting'
    userSkill: 'div.user-skill-container'
    modal: 'div.modal-container'
    userQueue: 'div.user-queue-container'
    smartNumbers: '#smart-number-container'

  events:
    'click a.delete-voicemail-drops'      : 'deleteVoicemailDrop'
    'change input.callForwarding'         : 'refreshToggle'
    'change .callRecordingOption'         : 'changeCallRecordingOption'
    'click a.add-smart-number'            : 'addSmartNumber'
    'click .remove-smart-number'          : 'removeSmartNumber'
    'click a.evict-bridge'                : 'evictBridgeUser'
    'click a.evict-call'                  : 'evictLiveCall'
    'click .logout-user'                 : 'logoutUser'

  bindings:
    '.audioAutoGainControl'       : 'checked:audioAutoGainControl'
    '.audioConstraintsEnabled'    : 'checked:audioConstraintsEnabled'
    '.audioEchoCancellation'      : 'checked:audioEchoCancellation'
    '.audioHighpassFilter'        : 'checked:audioHighpassFilter'
    '.audioNoiseSuppression'      : 'checked:audioNoiseSuppression'
    '.calendar'                   : 'toggle:calendarEnabled'
    '.callDesktopNotification'    : 'checked:callDesktopNotification'
    '.callFlowEditor'             : 'checked:callFlowEditor'
    '.callForwarding'             : 'checked:callForwarding, disabled:callForwardingLocked'
    '.callForwardingNumber'       : 'value:forwardingNumber,disabled:any(not(isAdminOrSupport),callForwardingLocked,not(callForwarding))'
    '.callForwardingLockedBy'     : 'toggle:callForwardingLocked'
    '.callForwardingPrompt'       : 'checked:callForwardingPrompt, disabled:callForwardingPromptLocked'
    '.callForwardingPromptLockedBy' : 'toggle:callForwardingPromptLocked'
    '.callForwardingTimeout'        : 'value:callForwardingTimeout, disabled:callForwardingTimeoutLocked'
    '.callForwardingDisabledOffline': 'checked:disabledOffline, disabled:any(callForwardingDisabledOfflineLocked,not(callForwarding))'
    '.callForwardingDisabledOfflineLockedBy': 'toggle:callForwardingDisabledOfflineLocked'
    '.callForwardingTimeoutLockedBy': 'toggle:callForwardingTimeoutLocked'
    '.callForwardingUseSmartNumber' : 'checked:callForwardingUseSmartNumber, disabled:not(callForwarding)'
    '.callNotification'            : 'checked:callNotification'
    '.callNotificationNumbers'     : 'value:callNotificationNumbers, disabled:not(callNotification)'
    '.callRecordingOption'        : 'value:recordingOption,disabled:recordingLocked'
    '.callRecordingLockedBy'      : 'toggle:recordingLocked,text:recordingLockedBy'
    '.clickToCallDirect'          : 'checked:clickToCallDirect'
    '.sendFeedback'               : 'checked:sendFeedback'
    '.send-feedback-group'        : 'toggle:sendFeedbackAllowed'
    '.continuousBridge'           : 'checked:continuousBridge'
    '.countryCode'                : 'value:countryCode'
    '.dialNextPreview'            : 'checked:dialNextPreview'
    '.email2sfdc'                 : 'checked:email2sfdc'
    '.email2sfdcAddress'          : 'value:email2sfdcAddress, disabled:not(email2sfdc)'
    '.emailSendViaSalesforce'     : 'checked:emailSendViaSalesforce'
    '.firstName'                   : 'text:firstName'
    '.forwardCallsWhenInactive'   : 'checked:forwardCallsWhenInactive'
    '.forwardCallsWhenInactiveTo' : 'value:forwardCallsWhenInactiveTo'
    '.localPresence'              : 'checked:localPresence'
    '.local-presence-sms'         : 'toggle:messagingActive,classes:{"disabled": not (localPresence)}'
    '.local-presence-sms-checkbox': 'checked:localPresenceSMS'
    '.logout-user'                : 'enabled:canLogoutUser'
    '.messageDesktopNotification'  : 'checked:messageDesktopNotification'
    '.muteAudioNotifications'      : 'checked:muteAudioNotifications'
    '.nextCaller'                 : 'checked:nextCaller'
    '.noAnswer'                   : 'checked:noAnswer'
    '.noAnswerNumber'             : 'value:noAnswerNumber, disabled:not(noAnswer)'
    '.role'                       : 'value:role'
    '.salesforceId'               : 'value:sfUserId'
    '.sipClient'                  : 'checked:sipClient'
    '.sipClientId'                : 'value:sipClientId, disabled:not(sipClient)'
    '.sipClientPassword'          : 'value:sipClientPassword, disabled:not(sipClient)'
    '.sipClientUsername'          : 'value:sipClientUsername, disabled:not(sipClient)'
    '.softphoneTrace'             : 'checked:softphoneTrace'
    '.twilioClientDebug'          : 'checked:twilioClientDebug'
    '.twilioClientDSCP'           : 'checked:twilioClientDSCP'
    '.twilioClientEdge'           : 'value:twilioClientEdge'
    '.twilioClientVersion'        : 'value:twilioClientVersion,disabled:twilioVersionDisabled'
    '.unavailableFlowLockedBy'    : 'toggle:unavailableFlowLocked'
    '.userPresence'               : 'text:userPresence'
    '.user-skill-container'       : 'toggle:userSkillsEnabled'
    '.webLeads'                   : 'checked:webLeadsEnabled, disabled:webLeadsLocked'
    '.webLeadsLockedBy'           : 'toggle:all(webLeadsEnabled,webLeadsLocked)'
    '.container-web-leads'        : 'toggle:showWebLeads'
    '.webRTC'                     : 'checked:webRTCEnabled'
    '.userExtension'              : 'value:extension'

  computeds:
    showWebLeads:
      deps: ['featureVisibilitySettings']
      get: (featureVisibilitySettings) ->
        feature = @model?.featureVisibilitySettings?.findWhere(feature: 'WebLeads')
        feature?.get('visible') ? true

    twilioVersionDisabled:
      deps: ['twilioClientVersion']
      get: (twilioClientVersion) -> twilioClientVersion[0] is '2'

    isAdminOrSupport:
      get: -> @user().isAdmin() or @user().isSupport()

    userPresence:
      get: -> @model.get('userPresence')?.presence

    canLogoutUser:
      get: ->
        presence = @model.get('userPresence')?.presence?.toLowerCase()
        presence isnt 'offline' and presence isnt 'busy' and presence isnt 'oncall' and presence isnt 'connecting'

    userSkillsEnabled:
      deps: ['account']
      get: (account) ->
        account.settings.userSkills

  getTemplate: ->
    if @user().isSupport() and !@user().isSalesSupport()
      supportTemplate
    else if @user().isAdmin()
      adminTemplate
    else
      agentTemplate

  initialize: (options) ->
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @modal = @getRegion('modal')
    @vmDropCollection = new VoicemailDropCollection()
    @viewModel = new Backbone.Model(
      sendFeedbackAllowed: window.rdnaEnableFullstory and @model?.get('account')?.settings?.sendFeedback
    )
    @initCallRecordingAccess()
    @setSmartNumberCollection()
    @smartNumberTableView = new SmartNumberTableView(collection: @smartNumberCollection)

  childViewEvents:
    reload: -> @render()

  onDomRefresh: =>
    $('input[type=text], select').addClass('form-control input-sm')
    $('[data-toggle="tooltip"]').tooltip()

    @refreshToggle()
    @listenSipAndCallForwarding()

  refreshToggle: ->
    $('.toggle-switch').bootstrapToggle('destroy')
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onRender: =>
    @showChildView('outboundNumbers', new OutboundNumbersView(userModel: @model))
    @showChildView('voicemailGreeting', new VoicemailGreetingView(user: @model, collection: @model.voicemailGreetings))
    @showChildView('userQueue', new UserQueueView(user: @model))
    @showChildView('smartNumbers', new SmartNumberTableView(collection: @smartNumberCollection))
    @showChildView('userSkill', new UserSkillView(user: @model))
    @vmDropCollection.reset()
    @vmDropCollection.fetch(data: { accountId: null, teamId: null, userId: @model.get('id') }).done(=>
      return unless @getRegion('voicemailDrops')
      for voicemail in @vmDropCollection.models
        voicemail.set(
          deletable: not (voicemail.get('isGlobal') or (not voicemail.get('isGlobal') and not voicemail.get('owner')?))
        )
      @orderVMDrops(@vmDropCollection)
      @showChildView('voicemailDrops', new VoicemailDropsView(model: @model, collection: @vmDropCollection))
    )
    @callFlowPicker = new CallflowPickerView(
      accountId: @model.get('account').id
      allowEmptyOption: true
      selectedFlowId: @model.get('agentUnavailableFlow')?.id
    )
    @showChildView('callFlowPicker', @callFlowPicker)
    @listenTo(@callFlowPicker, 'change', (value) => @model.set(agentUnavailableFlow: if value then {id:value} else null))
    @callFlowPicker.disabled() if @model.get('unavailableFlowLocked')
    if @user().can('system:manage')
      @showChildView('sipRoutingPicker', new SipRoutingPickerView(model: @model))

  initCallRecordingAccess: =>
    accountSettings = @model.get('account')?.settings
    lockedTeam = @model.get('lockedCallRecordingTeam')
    if @model.get('callRecordingLocked') and accountSettings
      recordingOption = accountSettings.callRecordingOverrideOption
      recordingLocked = true
      recordingLockedBy = 'Locked by Account Admin'
    else if lockedTeam and lockedTeam.settings
      recordingOption = lockedTeam.settings.callRecordingOption
      recordingLocked = true
      recordingLockedBy = "Locked by Team: #{lockedTeam.name}"
    @viewModel.set(
      recordingOption: recordingOption ? @model.get('callRecordingOption')
      recordingLocked: recordingLocked
      recordingLockedBy: recordingLockedBy ? ''
    )

  changeCallRecordingOption: =>
    @model.set(callRecordingOption: @viewModel.get('recordingOption'))

  onSaveClicked: (button) =>
    return if not @validate()
    @model
      .persist(ladda: button)
      .done?(-> toastr.info('User saved.'))

  addOutboundNumber: =>
    @modal.show(new OutboundNumberModal(
      userId: @model.id
      outboundNumbers: @model.get('userOutboundNumbers')
    ))

  deleteOutboundNumber: (e) =>
    bootbox.confirm('Are you sure you\'d like to delete this?', (result) =>
      return unless result
      id = $(e.currentTarget).data('id')
      outboundNumber = new OutboundNumberModel(
        userId: @model.id
        id: id
      )
      outboundNumber
        .delete(data: userId: outboundNumber.get('userId'))
        .done(=>
          outboundNumbers = @model.get('userOutboundNumbers')
          outboundNumbers.splice(index for number, index in outboundNumbers when number.id is id, 1)
          toastr.info('Outbound number deleted.')
          @render()
        )
    )

  listenSipAndCallForwarding: =>
    @listenTo(@model, 'change:sipClient', =>
      @$('.callForwarding').bootstrapToggle('off') if @model.get('sipClient')
    )
    @listenTo(@model, 'change:callForwarding', =>
      @$('.sipClient').bootstrapToggle('off') if @model.get('callForwarding')
    )

  orderVMDrops: (vmDrops) =>
    models = _.clone(vmDrops.models)
    dbOrderIDs = @hasNotExistIds(@model.get('orderIdsVoicemailDrops'), models)
    dbOrderIDs = _.union(dbOrderIDs, vmDrops.pluck('id'))
    _.each(models, (model, item) ->
      index = dbOrderIDs.indexOf(model.get('id'))
      vmDrops.models.splice(index, 1, models[item]) if index isnt -1
    )

  hasNotExistIds: (order, models) ->
    order = order.split(',').map(Number) if typeof order is 'string'
    order = [] if not order or typeof order isnt 'object'
    notExistIds = order.filter((id) -> id if _.findIndex(models, id: id) is -1)
    _.each(notExistIds, (id) -> order.splice(order.indexOf(id), 1) if order.indexOf(id) isnt -1)
    return order

  addSmartNumber: =>
    view = new SmartNumberWidget(
      accountId : @model.get('account').id,
      objectId  : @model.get('id'),
      type      : 'user',
      country   : @model.get('countryCode')
    )
    @showChildView('modal', view)
    @listenTo(view, 'assigned', (smartNumbers) ->
      userSmartNumbers = @model.get('smartNumbers')
      if smartNumbers.findWhere({'type': 'Default'})
        userSmartNumbers = (model for model in @model.get('smartNumbers') when model.type isnt 'Default')
      smartNumbers.add(userSmartNumbers)
      @model.set('smartNumbers', (model.get('smartNumber') ? model.attributes for model in smartNumbers.models))
      @setSmartNumberCollection()
      @render()
    )

  reassignAdditionalNumber: (e) =>
    @modal.show(new ReassignAdditionalNumberModal(
      user: @model.get('user')
      numberId: $(e.currentTarget).data('id')
    ))

  setSmartNumberCollection: =>
    smartNumberModels = []
    SmartNumberCollection = Backbone.Collection.extend(model: SmartNumberModel)

    for smartNumber in @model.get('smartNumbers')
      smartNumber.isAdditionalNotDeleted = smartNumber.type is 'Additional' and not smartNumber.isDeleted
      smartNumber.isDefault = smartNumber.type is 'Default'
      smartNumberModel = new SmartNumberModel(smartNumber)
      smartNumberModels.push(smartNumberModel)

    @smartNumberCollection = new SmartNumberCollection(smartNumberModels)

  removeSmartNumber: (e) ->
    id = $(e.currentTarget).data('id')
    return unless (smartNumber = _.find(@model.get('smartNumbers'), (smartNumber) ->
      smartNumber if smartNumber.id == id
    ))

    number = smartNumber.number
    title = 'Delete Smart Number'
    message = "Are you sure you want to delete #{number}"

    smartNumberModel = @smartNumberCollection.models.find((model) -> model.id == id)

    bootbox.confirm(
      title: title
      message: message
      callback: (ok) =>
        return unless ok

        smartNumberModel.destroy(
          success: (model, response) =>
            toastr.info("#{number} was successfully deleted.")
            @model.fetch()

            # SmartNumbers are soft-deleted so this is how we re-render for Support
            smartNumberModel = new SmartNumberModel(response)
            @smartNumberCollection.push(smartNumberModel)
          error: ->
            toastr.error("#{number} could not be deleted")
        )
    )

  evictBridgeUser: =>
    bootbox.confirm('Kick off bridge timeout job for this user?', (result) =>
      return unless result

      App.api.delete(
        path: 'calls/bridge'
        data: userId: @model.get('id')
        success: ->
          toastr.info('Refresh this page in a minute or two.', 'Job Started')
      )
    )

  evictLiveCall: (e) =>
    callKey = $(e.currentTarget).data('key')
    bootbox.confirm('Are you sure you want to evict this call?', (result) =>
      return unless result

      App.api.delete(
        path: 'calls/live'
        data:
          userId  : @model.get('id'),
          callKey : callKey
        success: (response) =>
          toastr.info(response.message)
          @model.fetch()
      )
  )

  logoutUser: ->
    userName = @model.get('displayName')
    dialog = bootbox.dialog(
      title: 'Logout User'
      closeButton: true
      message: "You are about to log out #{userName}. Are you sure you want to log this user out of ringDNA?"
      onEscape: -> true
      buttons:
        cancel:
          label: 'Cancel'
          className: 'btn-cancel-logout btn-default pull-left'
        logout:
          label: 'Yes'
          className: 'btn-success'
          callback: =>
            App.api.put(
              path: '/api/v2/app/users/' + @model.get('id') + '/logout'
              success: =>
                toastr.info('User logged out successfully.')
                setTimeout(=>
                  @model.fetch()
                , 1000)
              suppressDefaultErrorHandler: true
              failure: (xhr, status, error) =>
                responseMessage = xhr.responseJSON.message
                if responseMessage.includes('=oncall')
                  message = "#{userName} is currently on an active call and cannot be logged out. Try again later."
                else if responseMessage.includes('=busy')
                  message = "#{userName} is currently busy and cannot be logged out. Try again later."
                else if responseMessage.includes('=offline')
                  message = "#{userName} is already logged out."
                else
                  message = "Unable to log out #{userName}. Try again later."
                bootbox.dialog(
                  title: 'Logout User'
                  closeButton: true
                  message: message
                  onEscape: -> true
                  buttons:
                    cancel:
                      label: 'Cancel'
                      className: 'btn-default'
                )
            )
    )

  validate: ->
    salesforceEmailBegin = 'emailtosalesforce@'
    email = @model.get('email2sfdcAddress')
    if @model.get('email2sfdc') and (not @validateEmailAddressFormat(email) or not email.startsWith(salesforceEmailBegin))
      errorMessage = 'Please enter a valid Salesforce Email Address in the ' +
        '&quot;<b>Log Emails to Salesforce if sent via RingDNA</b>&quot; setting.<br>' +
        'Valid Salesforce Email Addresses begin with <b>' + salesforceEmailBegin + '</b><br>' +
        'Click <b><a href="https://support.revenue.io/hc/en-us/articles/8984522729627" target="_blank" ' +
        'rel="noopener noreferrer">here</a></b> for help documentation.'
      toastr.error(errorMessage)
      false
    else
      true

  validateEmailAddressFormat: (email) ->
    return if email then isEmail(email) else false

module.exports = UserSettingsView
