var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-title\">\n  <div class=\"title-left\">\n    <h1>Smart Number <small>"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":38}}}) : helper)))
    + "</small></h1>\n  </div>\n  <div class=\"navbar-right\">\n    <button class=\"ladda-button btn btn-success save-number\">Save</button>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n\n<div class=\"x-panel\">\n  <div class=\"x-title\">\n    <h2>Details</h2>\n    <div class=\"navbar-right\">\n      <button class=\"ladda-button btn-sm btn-danger delete-number\">Delete</button>\n      <button class=\"ladda-button btn-sm btn-success undelete-number\">Undelete</button>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Number</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":23,"column":29},"end":{"line":23,"column":39}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group admin number-sid\">\n      <label class=\"col-md-4\">SID</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sid") || (depth0 != null ? lookupProperty(depth0,"sid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sid","hash":{},"data":data,"loc":{"start":{"line":27,"column":29},"end":{"line":27,"column":36}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Type</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":31,"column":29},"end":{"line":31,"column":37}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Description</label>\n      <div class=\"col-md-8\">\n        <textarea class=\"form-control number-description\" rows=\"3\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group status-container\">\n      <label class=\"col-md-4\">Status</label>\n      <div class=\"col-md-8\">\n        <select class=\"form-control number-status\">\n          <option value=\"Active\">Active</option>\n          <option value=\"Inactive\">Inactive</option>\n        </select>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Tags</label>\n      <span class=\"tags col-md-8\"></span>\n    </div>\n    <div class=\"form-group custom-parameter-container\">\n      <label class=\"col-md-4\">Insertion Value</label>\n      <div class=\"col-md-8\">\n        <input type=\"text\" class=\"form-control custom-parameter\"/>\n      </div>\n    </div>\n    <div class=\"form-group extra-info-container\">\n      <label class=\"col-md-4\">Extra Info</label>\n      <div class=\"col-md-8\">\n        <textarea class=\"form-control entity-extra-info\" rows=\"3\" readonly></textarea>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Date Created</label>\n      <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"dateCreated") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":68,"column":29},"end":{"line":68,"column":59}}}))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Date Last Used</label>\n      <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"dateLastUsed") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":72,"column":29},"end":{"line":72,"column":60}}}))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Date Deleted</label>\n      <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"dateDeleted") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":76,"column":29},"end":{"line":76,"column":59}}}))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Is Deleted</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"isDeleted") || (depth0 != null ? lookupProperty(depth0,"isDeleted") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"isDeleted","hash":{},"data":data,"loc":{"start":{"line":80,"column":29},"end":{"line":80,"column":42}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group admin number-released-twilio\">\n      <label class=\"col-md-4\">Released from Twilio?</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"isReleasedFromTwilio") || (depth0 != null ? lookupProperty(depth0,"isReleasedFromTwilio") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"isReleasedFromTwilio","hash":{},"data":data,"loc":{"start":{"line":84,"column":29},"end":{"line":84,"column":53}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Label</label>\n      <div class=\"col-md-8\">\n        <input type=\"text\" class=\"form-control number-label\"/>\n      </div>\n    </div>\n  </div>\n  <div class=\"clearfix\"></div>\n\n  <div class=\"x-title\">\n    <h2>Ownership</h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Account</label>\n      <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"linkTo")||(depth0 && lookupProperty(depth0,"linkTo"))||alias2).call(alias1,"accounts",((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"linkTo","hash":{},"data":data,"loc":{"start":{"line":102,"column":29},"end":{"line":102,"column":74}}}))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Name</label>\n      <span class=\"col-md-8\">"
    + alias4((lookupProperty(helpers,"linkTo")||(depth0 && lookupProperty(depth0,"linkTo"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? lookupProperty(stack1,"label") : stack1),{"name":"linkTo","hash":{},"data":data,"loc":{"start":{"line":106,"column":29},"end":{"line":106,"column":68}}}))
    + "</span>\n    </div>\n    <div class=\"form-group source\">\n      <label class=\"col-md-4\">Source</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"source") || (depth0 != null ? lookupProperty(depth0,"source") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"source","hash":{},"data":data,"loc":{"start":{"line":110,"column":29},"end":{"line":110,"column":39}}}) : helper)))
    + "</span>\n      <div class=\"col-md-8\">\n        <input type=\"text\" class=\"form-control\"/>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-md-6 tracking-container\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Tracking Type</label>\n      <span class=\"col-md-8 tracking-type\"></span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Domain</label>\n      <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"domain") || (depth0 != null ? lookupProperty(depth0,"domain") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"domain","hash":{},"data":data,"loc":{"start":{"line":123,"column":29},"end":{"line":123,"column":39}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Google Tracking ID</label>\n      <div class=\"col-md-8\">\n          <label class=\"col-md-2\">UA -</label>\n          <input type=\"text\" class=\"google-tracking-id col-md-10\"/>\n      </div>\n    </div>\n    <div class=\"form-group admin entity-id\">\n      <label class=\"col-md-4\">Entity ID</label>\n      <span class=\"col-md-8 entity-id\"></span>\n    </div>\n  </div>\n  <div class=\"clearfix\"></div>\n\n  <div class=\"call-flow-container\"></div>\n  <br/>\n\n  <div class=\"campaign-container\"></div>\n  <br/>\n\n  <div class=\"call-script-container\"></div>\n  <br/>\n\n  <div class=\"modal-container\"></div>\n\n  <div class=\"x-title smart-number-analytics-report\">\n    <h2>Analytics</h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"smart-number-analytics\"></div>\n\n</div>\n";
},"useData":true});