BaseView = require('base/view')
Details = require('./details')
Handlebars = require('handlebars/runtime').default

class LockedSettingView extends BaseView
  template: require('./template')

  ui:
    changed : 'a.changed-setting'

  bindings:
    'a.changed-setting'     : 'html:url'

  computeds:
    tooltipText:
      deps: ['locked', 'disabled']
      get: (locked, disabled) -> Details.tooltip(@setting, locked, disabled)
    url:
      deps: ['locked', 'disabled', 'tooltipText']
      get: (locked, disabled, tooltipText) ->
        title = if not locked then 'Unlock' else 'Lock'
        disabledIcon = if disabled then 'disabled' else 'active'
        icon = Handlebars.helpers.imageUrl("locked-setting/#{title}-#{disabledIcon}.svg").toLowerCase()
        "<img src=#{icon} data-toggle='tooltip' data-original-title='#{tooltipText}'>"

  events:
    'click @ui.changed'   : 'changed'

  initialize: (options) =>
    { @setting, @basicSettings } = options
    @viewModel = Details.selectLockedSetting(@model.get(@setting), @setting, @basicSettings)

  onRender: =>
    @listenTo(@basicSettings, 'change', =>
      $('[data-toggle="tooltip"]').tooltip()
      Details.changeLockedSetting(@viewModel, @setting, @basicSettings)
    )
    @listenTo(@basicSettings, 'disagree', =>
      @viewModel.set(locked: true)
    )
    @listenTo(@viewModel, 'change:locked', =>
      @model.set(@setting, @viewModel.get('locked'))
    )

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  changed: =>
    return if @viewModel.get('disabled')
    title = if @viewModel.get('locked') then 'Unlock' else 'Lock'
    additionalInfo = _.escape(@viewModel.get('additionalInfo'))
    message = Details.addTextToModal(@setting, !@viewModel.get('locked'), additionalInfo, @basicSettings)

    dialog = bootbox.dialog(
      closeButton: false
      title: "#{title} #{@viewModel.get('header')} Setting"
      message: message
      buttons:
        cancel:
          label: 'Cancel'
          className: 'btn btn-default'
          callback: => true
        ok:
          label: title
          className: 'btn btn-info locked'
          callback: =>
            @viewModel.set('locked', not @viewModel.get('locked'))
            dialog.modal('hide')
            $('[data-toggle="tooltip"]').tooltip()
            false)

module.exports = LockedSettingView
