class ReportHelper
  SQL_WILDCARD_CHAR = '%'
  REPORT_NAME_DELIMITER = '-'

  isEmptyOrWildcard: (text) ->
    !text || text && (text == '' || text == SQL_WILDCARD_CHAR ||
      (text.length == 1 && text[0] == SQL_WILDCARD_CHAR))

  isNotEmptyOrWildcard: (text) ->
    not @isEmptyOrWildcard(text)

  # TODO:  These could live in a more generic helper
  camelize: (text) ->
    text.replace(/-([a-z])/g, (g) -> g[1].toUpperCase())

  capitalize: (text) ->
    "#{text.slice(0, 1).toUpperCase()}#{text.slice(1).toLowerCase()}"

  parseExportData: (blob, xhr) ->
    fileName: xhr.getResponseHeader('Content-Disposition').split("filename=")[1]
    encodedUri: 'data:application/csv;charset=utf-8,' + encodeURIComponent(blob)

module.exports = ReportHelper
