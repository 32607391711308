var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">\n      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":15}}}) : helper)))
    + "\n    </label>\n    <span class=\"col-md-8\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fragment") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "    </span>\n  </div>\n</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"fragment") || (depth0 != null ? lookupProperty(depth0,"fragment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fragment","hash":{},"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":30}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"pk") || (depth0 != null ? lookupProperty(depth0,"pk") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pk","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":48}}}) : helper)))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":17}}}) : helper)))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":25,"column":9}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"salesforceId") : depth0),"label":"SalesforceId"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"type") : depth0),"label":"Type"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"isPersonAccount") : depth0),"label":"Person Account"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":2},"end":{"line":38,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"customCondition")||(depth0 && lookupProperty(depth0,"customCondition"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"key")),"additionalSfdcFields",{"name":"customCondition","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":37,"column":24}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression((lookupProperty(helpers,"additionalSfdcFields")||(depth0 && lookupProperty(depth0,"additionalSfdcFields"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"additionalSfdcFields","hash":{},"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":34,"column":35}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":depth0,"label":(data && lookupProperty(data,"key"))},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"sid") : depth0),"label":"Sid"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"duration") : depth0),"label":"Duration"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"notified") : depth0),"label":"Notification Sent"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"status") : depth0),"label":"Status"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"transcription") : depth0),"label":"Transcription"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"col-md-6 recording-url-section\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Recording URL</label>\n    <span class=\"col-md-8 recording\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUrl") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":52,"column":6},"end":{"line":56,"column":13}}})) != null ? stack1 : "")
    + "    </span>\n  </div>\n</div>\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Player URL</label>\n    <span class=\"col-md-8 recording\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUrl") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":64,"column":6},"end":{"line":68,"column":13}}})) != null ? stack1 : "")
    + "    </span>\n  </div>\n</div>\n<div class=\"clearfix\"></div>\n\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"url","hash":{},"data":data,"loc":{"start":{"line":53,"column":17},"end":{"line":53,"column":24}}}) : helper)))
    + "\">Open</a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"not-found\">Restricted</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"playerUrl") || (depth0 != null ? lookupProperty(depth0,"playerUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"playerUrl","hash":{},"data":data,"loc":{"start":{"line":65,"column":17},"end":{"line":65,"column":30}}}) : helper)))
    + "\">Open</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Queue</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"pk":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callQueueId") : stack1),"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callQueue") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),"label":"Queue"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"enteredQueue") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":141,"column":52},"end":{"line":141,"column":86}}}),"label":"Entered Queue"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"queueHoldTime") : stack1),"label":"Queue Hold Time"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Primary Match</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"sfdcRecord"),((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcPrimaryMatch") : stack1),{"name":"sfdcRecord","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Secondary Match</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"sfdcRecord"),((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcSecondaryMatch") : stack1),{"name":"sfdcRecord","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Voicemail</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"callRecording"),((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"voicemail") : stack1),{"name":"callRecording","hash":{"showUrl":(depth0 != null ? lookupProperty(depth0,"recordingUrls") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Call Recording</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"callRecording"),depth0,{"name":"callRecording","hash":{"showUrl":(depths[1] != null ? lookupProperty(depths[1],"recordingUrls") : depths[1])},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-md-12\">\n  <div class=\"x-title\">\n    <h3>Joined Recording</h3>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"joinedRecording") : stack1)) != null ? lookupProperty(stack1,"jobId") : stack1),"label":"Job Id"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"joinedRecording") : stack1)) != null ? lookupProperty(stack1,"duration") : stack1),"label":"Duration"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"col-md-4\">\n          URL\n        </label>\n        <span class=\"col-md-8 word-break\">\n            <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"joinedRecording") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"joinedRecording") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "</a>\n        </span>\n      </div>\n    </div>\n  </div>\n\n</div>\n<div class=\"clearfix\"></div>\n<br />\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-title\">\n  <h2>Conversation AI</h2>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"voicebase") : depth0),{"name":"with","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":2},"end":{"line":281,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"id") : depth0),"label":"ID"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"mediaIdAsString") : depth0),"label":"Media ID"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"status") : depth0),"label":"Status"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"fragment":"smart-recordings","pk":(depth0 != null ? lookupProperty(depth0,"callKey") : depth0),"value":"Open","label":"Player"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div class=\"clearfix\"></div>\n\n  <div class=\"form-group\">\n    <label class=\"col-md-2\">\n      Raw Data\n    </label>\n    <span class=\"col-md-10\">\n      <button data-toggle=\"collapse\" data-target=\"#voicebase-callback\">View</button>\n      <p>\n        <pre id=\"voicebase-callback\" class=\"collapse\">"
    + container.escapeExpression((lookupProperty(helpers,"formatJson")||(depth0 && lookupProperty(depth0,"formatJson"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"callbackJson") : depth0),{"name":"formatJson","hash":{},"data":data,"loc":{"start":{"line":276,"column":54},"end":{"line":276,"column":81}}}))
    + "</pre>\n      </p>\n    </span>\n  </div>\n\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"x-title\">\n  <h2>Salesforce Errors</h2>\n<div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"errors") : depth0),{"name":"each","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":293,"column":0},"end":{"line":306,"column":9}}})) != null ? stack1 : "")
    + "</div class=\"clearfix\"></div>\n</div>\n\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Error "
    + alias3((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":296,"column":36},"end":{"line":296,"column":54}}}))
    + "</label>\n      <span class=\"timestamp col-md-8\">\n        "
    + alias3((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timestamp") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":298,"column":8},"end":{"line":298,"column":36}}}))
    + "\n      </span>\n      <div class=\"clearfix\"></div>\n      <label class=\"problem col-md-4\">Message</label>\n      <span class=\"col-md-8\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":302,"column":29},"end":{"line":302,"column":40}}}) : helper)))
    + "</span>\n    <div class=\"clearfix\"></div>\n    </div>\n  </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"x-title\">\n  <h2>Call Events</h2>\n<div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-12\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"events") : depth0),{"name":"each","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":322,"column":2},"end":{"line":334,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Call Event "
    + alias3((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":325,"column":19},"end":{"line":325,"column":37}}}))
    + " <small class=\"timestamp\">"
    + alias3((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timestamp") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":325,"column":63},"end":{"line":325,"column":91}}}))
    + "</small></h3>\n  <div class=\"clearfix\"></div>\n  </div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"type") : depth0),"label":"Type"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"data") : depth0),"label":"Message"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"clearfix\"></div>\n\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>Leg "
    + container.escapeExpression((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":351,"column":12},"end":{"line":351,"column":30}}}))
    + "</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"role") : depth0),"label":"Role"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"callSid") : depth0),"label":"CallSid"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatBoolean")||(depth0 && lookupProperty(depth0,"formatBoolean"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"formatBoolean","hash":{},"data":data,"loc":{"start":{"line":357,"column":38},"end":{"line":357,"column":60}}}),"label":"Active"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatBoolean")||(depth0 && lookupProperty(depth0,"formatBoolean"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"completed") : depth0),{"name":"formatBoolean","hash":{},"data":data,"loc":{"start":{"line":358,"column":38},"end":{"line":358,"column":63}}}),"label":"Completed"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"duration") : depth0),"label":"Duration"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"clearfix\"></div>\n\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"x-title\">\n    <h3>"
    + container.escapeExpression((lookupProperty(helpers,"formatSeconds")||(depth0 && lookupProperty(depth0,"formatSeconds"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"startTime") : depth0),{"name":"formatSeconds","hash":{},"data":data,"loc":{"start":{"line":378,"column":8},"end":{"line":378,"column":35}}}))
    + "</h3>\n    <div class=\"clearfix\"></div>\n  </div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(depth0 != null ? lookupProperty(depth0,"transcription") : depth0),"label":"Transcription"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatPercent")||(depth0 && lookupProperty(depth0,"formatPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"confidence") : depth0),{"name":"formatPercent","hash":{},"data":data,"loc":{"start":{"line":383,"column":42},"end":{"line":383,"column":68}}}),"label":"Confidence"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatBoolean")||(depth0 && lookupProperty(depth0,"formatBoolean"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAgent") : depth0),{"name":"formatBoolean","hash":{},"data":data,"loc":{"start":{"line":384,"column":38},"end":{"line":384,"column":61}}}),"label":"Is Agent?"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"clearfix\"></div>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"clearfix\"></div>\n\n\n\n\n<!-- call data -->\n\n<div class=\"x-title\">\n  <h2>Call Data</h2>\n  <div class=\"clearfix\"></div>\n</div>\n\n<div class=\"col-md-12\">\n  <div class=\"x-title\">\n    <h3>Summary</h3>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callKey") : stack1),"label":"CallKey"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callSid") : stack1),"label":"CallSid"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"fragment":"accounts","pk":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),"label":"Account"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"fragment":"users","pk":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"ownerId") : stack1),"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"owner") : stack1)) != null ? lookupProperty(stack1,"email") : stack1),"label":"Owner"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"direction") : stack1),"label":"Direction"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"label":"Call Status"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"toNumber") : stack1),"label":"To Number"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"fromNumber") : stack1),"label":"From Number"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"forwardingNumber") : stack1),"label":"Forwarding Number"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"startTime") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":99,"column":52},"end":{"line":99,"column":83}}}),"label":"Start Time"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"endTime") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":100,"column":52},"end":{"line":100,"column":81}}}),"label":"End Time"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),"label":"Duration"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n  <div class=\"x-title\">\n    <h3>Details</h3>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"automatedVoicemailId") : stack1),"label":"Automated VoicemailId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"fragment":"call-flows","pk":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callFlowId") : stack1),"value":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callFlow") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),"label":"Call Flow"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callFlowStepId") : stack1),"label":"Call Flow Step Id"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"deliveryStatus") : stack1),"label":"Delivery Status"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"directConnect") : stack1),"label":"Direct Connect"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"legacyCallId") : stack1),"label":"Legacy CallId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatBoolean")||(depth0 && lookupProperty(depth0,"formatBoolean"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"localPresence") : stack1),{"name":"formatBoolean","hash":{},"data":data,"loc":{"start":{"line":119,"column":53},"end":{"line":119,"column":87}}}),"label":"Local Presence"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"mergedFromCallKey") : stack1),"label":"Merged From CallKey"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"mergedToCallKey") : stack1),"label":"Merged To CallKey"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"noAnswerNumber") : stack1),"label":"No Answer Number"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatBoolean")||(depth0 && lookupProperty(depth0,"formatBoolean"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"onHold") : stack1),{"name":"formatBoolean","hash":{},"data":data,"loc":{"start":{"line":123,"column":53},"end":{"line":123,"column":80}}}),"label":"On Hold"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":(lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"enteredHold") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":124,"column":53},"end":{"line":124,"column":86}}}),"label":"Entered Hold"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"storageBackend") : stack1),"label":"Storage Backend"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"toSmartNumberId") : stack1),"label":"To SmartNumberId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"transferredToNumber") : stack1),"label":"Transferred To Number"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callQueue") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":2},"end":{"line":148,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"x-title\">\n    <h3>Salesforce</h3>\n  <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-12\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcPrimaryMatchType") : stack1),"label":"Match Type"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcTaskId") : stack1),"label":"TaskId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcCampaignMemberId") : stack1),"label":"CampaignMemberId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcInitiatingRecordId") : stack1),"label":"Initiating RecordId"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"disposition") : stack1),"label":"Disposition"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1),"label":"Rating"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"notes") : stack1),"label":"Notes"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1),"label":"Subject"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"row"),depth0,{"name":"row","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"supervisorNotes") : stack1),"label":"Supervisor Notes"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"clearfix\"></div>\n  <br />\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcPrimaryMatch") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":2},"end":{"line":182,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"sfdcSecondaryMatch") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":2},"end":{"line":196,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"voicemail") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":2},"end":{"line":210,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"recordings") : stack1),{"name":"each","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":2},"end":{"line":224,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"joinedRecording") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":2},"end":{"line":252,"column":9}}})) != null ? stack1 : "")
    + "\n<!-- voicebase callbacks -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"voicebase") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":255,"column":0},"end":{"line":283,"column":7}}})) != null ? stack1 : "")
    + "\n<!-- salesforce errors -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errors") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":286,"column":0},"end":{"line":310,"column":7}}})) != null ? stack1 : "")
    + "\n\n<!-- call events -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"events") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":314,"column":0},"end":{"line":338,"column":7}}})) != null ? stack1 : "")
    + "\n<!-- call legs -->\n\n<div class=\"x-title\">\n  <h2>Call Legs</h2>\n<div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-12\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"call") : depth0)) != null ? lookupProperty(stack1,"callLegs") : stack1),{"name":"each","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":348,"column":2},"end":{"line":363,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n\n<!-- realtime transcription (yoda) -->\n\n<div class=\"x-title\">\n  <h2>Realtime Transcription</h2>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-12\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"realtimeTranscription") : depth0),{"name":"each","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":375,"column":2},"end":{"line":388,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n<!-- twilio callbacks -->\n<div class=\"twilio-callbacks-container\" ></div>\n";
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["row"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":18,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["opt"],"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":26,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"args":["sfdcRecord"],"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":39,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"args":["callRecording",(depth0 != null ? lookupProperty(depth0,"showUrl") : depth0)],"data":data,"loc":{"start":{"line":41,"column":0},"end":{"line":74,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});