import { KeyTabProps, SettingProps } from './types'

export enum TabKeys {
  MANAGE_CALL_DISPOSITIONS = 'manage-call-dispositions',
  CALL_NOTES_TEMPLATES = 'call-notes-templates',
  CUSTOM_LINKS = 'custom-links',
  CALL_TOOLS = 'call-tools',
  CALL_SCRIPTS = 'call-scripts',
  POSTCALL_PROMPT = 'postcall_prompt'
}

export const TABS: KeyTabProps[] = [
  {
    title: 'Manage Call Dispositions',
    key: TabKeys.MANAGE_CALL_DISPOSITIONS
  },
  {
    title: 'Call Notes Templates',
    key: TabKeys.CALL_NOTES_TEMPLATES
  },
  {
    title: 'Custom Links',
    key: TabKeys.CUSTOM_LINKS
  },
  {
    title: 'Call Tools',
    key: TabKeys.CALL_TOOLS
  },
  {
    title: 'Call Scripts',
    key: TabKeys.CALL_SCRIPTS
  },
  {
    title: 'Post-Call Prompt',
    key: TabKeys.POSTCALL_PROMPT
  }
]

export const DispositionPrompt: SettingProps = {
  title: 'Disposition Prompt',
  description: 'Show a prompt for agents to set a call disposition after they complete a call.'
}

export const DispositionsRequired: SettingProps = {
  title: 'Dispositions Required',
  description: 'Prevent agents from making a new call if they have not yet dispositioned all calls.'
}

export const ManageByTeam: SettingProps = {
  title: 'Manage By Team',
  description: 'When enabled, Teams can set their own preferences for required dispositions.'
}

export const VoicemailDrop: SettingProps = {
  title: 'Voicemail Drop',
  description: 'The Call Disposition selected in the dropdown will be automatically applied an agent leaves a Voicemail Drop.'
}

export const EnableCallTolls: SettingProps = {
  title: 'Enable Call Tools',
  description: 'Merge Ratings, Disposition, Call Notes, and Call Notes Template in RingDNA into Call Tools.'
}

export const EnableChatter: SettingProps = {
  title: 'Enable Chatter for Call Notes',
  description: 'Allow Call Notes to be posted into Salesforce Chatter.'
}

export const EnableCallRating: SettingProps = {
  title: 'Enable Call Rating',
  description: 'Enable users to rate a call.'
}

export const AppendTransferInfo: SettingProps = {
  title: 'Append Transfer Info to Cold Transfer Target Calls',
  description: 'Add the callkey of the call where a cold transfer was initiated to the comments field for the target call of the transfer.'
}

export const callDispositionPromptSettings = 'settings.callDispositionPrompt'
export const manageCallDispositionRequiredByTeamSettings = 'settings.manageCallDispositionRequiredByTeam'
export const manageCallDispositionsSettings = 'settings.manageCallDispositions'
export const callDispositionRequiredStateSettings = 'settings.callDispositionRequiredState'
export const voicemailDropDispositionSettings = 'settings.voicemailDropDisposition'
export const callNoteTemplatesSettings = 'settings.callNoteTemplates'
export const postCallPromptSettings = 'settings.postCallPrompt'
export const relatedRecordsSettings = 'settings.relatedRecords'
export const appendTransferInfoSettings = 'settings.appendTransferInfo'
