AppView        = require('./view')
ErrorView      = require('./error/view')
AppRouter      = require('./nav/router')
ApiService     = require('services/api-service')
PubSubService  = require('services/pubsub-service')
SentryHelper   = require('@ringdna/common/app/helpers/sentry')
SessionService = require('services/session-service')
TrackerService = require('services/tracker-service')
FeatureFlagService = require('@ringdna/common/src/utils/feature-flag/featureFlag')
config = require('../../src/config').default

class App extends Marionette.Application

  initialize: (options) ->
    @_initEnv()

    @api     = new ApiService(@)
    @router  = new AppRouter(app: @)
    @session = new SessionService(@)
    @pubsub  = new PubSubService(@)
    @tracker = new TrackerService(@)
    @featureFlag = FeatureFlagService
    @currentFragment = null
    @previousFragment = null
    @listenTo(@router, 'route', =>
      @previousFragment = @currentFragment
      @currentFragment = Backbone.history.fragment
    )

  onStart: (options) ->
    @_configureJqueryPlugins()
    @_registerEpoxyHelpers()

    @session.start()

    @view = new AppView(app: @)
    @view.render()
    @view.workspaceBlocked()

  _initEnv: =>
    @sentryHelper = new SentryHelper('webapp')
    # only ENV vars with REACT_APP prefixed are imported
    # this remaps them
    @config = {
      calendarServer: config.calendarServer
      launchDarklyKey: config.launchDarklyKey
    }

    console.info("#{@config.name} #{@config.version} #{@config.build} #{config.appDomain}")

    Backbone.Radio.DEBUG = true
    Backbone.Radio.log = (channelName, eventName) ->
      args = (item for item in arguments).slice(2)
      console.info("(Radio) #{channelName} -> #{eventName}", args)

    Backbone.Radio.tuneIn('global') if process.env.NODE_ENV is 'development'
    @radio = Backbone.Radio.channel('global')
    @_startErrorHandlers()

  _configureJqueryPlugins: ->
    toastr.options.closeButton       = false
    toastr.options.positionClass     = 'toast-top-center'
    toastr.options.preventDuplicates = true
    toastr.options.timeOut           = 4000

    Backgrid.Column.prototype.defaults.editable = false

  _registerEpoxyHelpers: ->
    Backbone.Epoxy.binding.addFilter('unixTime', (timestamp) ->
      moment(timestamp).format('MM/DD/YYYY hh:mm a') if timestamp
    )
    Backbone.Epoxy.binding.addFilter('salesforceUrl', (id) =>
      @session.user.salesforceUrl(id) if id
    )

  _startErrorHandlers: ->
    window.onerror = (msg, url, line, col, error)  =>
      @sentryHelper.module('application').notify(error) if error
      false

  navigate: (fragment, options) =>
    @router.navigate(fragment, options)

  navigatePrevious: =>
    if @previousFragment
      @router.navigate("##{@previousFragment}", trigger: true)
      @previousFragment = null

  error: (msg) =>
    console.error(msg)
    v = new ErrorView(msg: msg)
    @view.error.show(v)
    @listenTo(v, 'logout', => @radio.trigger('user:logout'))

module.exports = new App()
