import React from 'react'
import styled, { css, useTheme } from 'styled-components'

import { Colors } from '../../constants/colors'
import { Direction } from './types'

import { IconButton, IconButtonProps } from '@mui/material'
import ConditionalWrapper from '../../utils/conditionalWrapper'

export { Direction }

export type IconBgShape = 'square' | 'circle'
export type IconVariant = 'outlined' | 'filled' | 'none'
export type IconHelperProps = {
  icon?: ({
    color
  }: {
    color?: Colors
    size?: number
    direction?: Direction
    title?: string
    strokeWidth?: number
  }) => JSX.Element
  color?: string
  size?: number
  strokeWidth?: number
  direction?: Direction
  iconSize?: number
  testId?: string
  className?: string
  title?: string
  shape?: IconBgShape
  variant?: IconVariant
  onClick?: IconButtonProps['onChange']
}

const Background = styled.div<{
  shape: IconBgShape
  variant: IconVariant
  size: number
  color?: string
}>`
  ${({ shape, variant, size, color, theme }) => css`
    border-radius: ${shape === 'square' ? `4px` : '100%'};
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    height: ${size}px;
    width: ${size}px;
    display: flex;
    background: ${variant === 'filled' ? color || theme.palette.icon.link : null};
    border: ${variant === 'outlined' ? `${size / (theme.spacing * 4)}px solid ${color}` : null};
  `}
`

const Button = styled(IconButton)`
  padding: 0 !important;
`

export default function RdnaIconHelper({
  icon,
  color,
  size = 24,
  strokeWidth,
  direction,
  iconSize = 16,
  shape = 'circle',
  variant = 'none',
  onClick,
  title,
  className,
  testId,
  ...restOfIconButtonProps
}: IconHelperProps): JSX.Element {
  const Icon = icon
  const { palette } = useTheme()
  color = color || palette.icon.link
  const iconColor = variant === 'filled' ? palette.icon.contrast : color
  return (
    <ConditionalWrapper
      condition={!!onClick}
      wrapper={children => (
        <Button
          size="small"
          onClick={onClick}
          disabled={!onClick}
          data-testid={testId}
          className={className}
          {...restOfIconButtonProps}
        >
          {children}
        </Button>
      )}
    >
      <Background shape={shape} variant={variant} size={size} data-testid={!onClick ? testId : undefined} color={color}>
        {Icon && (
          <Icon color={iconColor} size={iconSize} direction={direction} title={title} strokeWidth={strokeWidth} />
        )}
      </Background>
    </ConditionalWrapper>
  )
}
