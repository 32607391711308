class PagedCollection extends Backbone.PageableCollection

  initialize: (options) ->
    @initAdditionalParameters()
    @url ?= options.url
    @state.currentPage = parseInt(options.page ? 1)
    _.extend(@queryParams, options?.queryParams)
    @listenTo( @, 'backgrid:sort', (sort) ->
      sort.collection.chain()
      .filter(( model ) ->
        return model.cid isnt sort.cid
      ).each(( model ) ->
        model.set( 'direction', null )
      )
    )

  parseState: (response, queryParams, state, options) ->
    totalRecords: response.total

  parseRecords: (response, options) =>
    # HACK: Backbone.Paginator is supposed to fire this event as 'pageable:state:change', but I can't get it to work.
    @trigger('pager:state:changed', @state)
    response.results

  args: =>
    _.pick(@queryParams, (value, key) ->
      if key is 'directions'
        false
      else if typeof value is 'string'
        not value.includes('pager.')
      else
        true
    )

  initAdditionalParameters: =>
    @state = {
      pageSize: 30
      sortKey: 'id'
      order: 'desc'
    }

    @queryParams = {
      currentPage: 'pager.currentPage'
      pageSize: 'pager.pageSize'
      totalPages: 'pager.totalPages'
      totalRecords: 'pager.totalRecords'
      sortKey: 'pager.sortBy'
      order: 'pager.sortDirection'
    }

module.exports = PagedCollection
