BaseModel = require('base/model')
BaseCollection = require('base/collection')

class CallNoteTemplatesModel extends BaseModel
  urlRoot: 'callNoteTemplates'

  defaults:
    name: ''
    template: ''
    enabled: true
    teamNames: ''
    teams: new BaseCollection()

  validate: (attrs) ->
    if not attrs.name.trim()
      return 'Please enter a template name.'
    if not attrs.template.trim()
      return 'Please enter a template body.'
    return

  parse: (response) =>
    teamNames = 'All'
    teams = new BaseCollection()
    if response.teams.length isnt 0
      teamsList = []
      for index in response.teams
        teamsList.push(index.team.name)
        teams.add(index.team)
      teamNames = teamsList.join(', ')
    response['teamNames'] = teamNames
    response['teams'] = teams
    response

module.exports = CallNoteTemplatesModel