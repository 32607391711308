BaseView = require('base/view')
HeaderModel = require('./model')
AccountPickerView = require('widgets/account-picker/view')
config = require('../../../src/config').default
ReactComponent  = require('src/ReactComponent').default
RdnaSettingsAvatar = require('@ringdna/common/src/components/RdnaSettingsAvatar/index').default
RdnaGenAi = require('@ringdna/common/src/components/RdnaGenAi/index').default
class HeaderView extends BaseView

  template: require('./template')

  regions:
    accountPicker : 'div.support-account-picker'
    'user-profile-dropdown-react' : 'div.user-profile-dropdown-react'
    'gen-ai-react' : 'div.gen-ai-react'


  computeds:
    cai:
      get: -> @user().can('smart-recordings:view')
    caiSettings:
      get: -> @user().can('smart-recordings:view') && @user().can('cai-settings:view')
    calendarTabEnabled:
      get: -> @user().get('settings.calendarEnabled')
    genAiEnabled:
      get: -> App.featureFlag.isFlagged(App.featureFlag.flags.MOMENTS_GENERATIVE_AI)

  bindings:
    '.conversation-ai-setup': 'toggle:caiSettings'
    '.conversation-ai-notifications': 'toggle:cai'
    '.user-calendar-url': 'toggle:calendarTabEnabled'
    '.gen-ai-react': 'toggle:genAiEnabled'

  events:
    'click .product-portal-link': -> App.router.navigate("#product-portal", trigger: true)
    'click a[data-action="logout"]': -> @app.radio.trigger('user:logout')

  initialize: (options) ->
    @app = options.app
    @model = new HeaderModel()
    @model.set('caiSetupHref', "#{config.caiDomain}/settings")
    @model.set('caiNotificationHref', "#{config.caiDomain}/notifications")
    @ldConfig = Object.assign({}, config)
    if App.featureFlag.isFlagged(App.featureFlag.flags.v291_REV_1806)
      @ldConfig.dashboardDomain = @ldConfig.caiDomain

  onRender: =>
    if @user().accountPickerRenderable()
      @accountPicker = @getRegion('accountPicker')
      @accountView = new AccountPickerView(dropdownParent:'body')
      @accountPicker.show(@accountView)
      @accountView.val(@user().getSelectedSupportAccountId()) if @user().getSelectedSupportAccountId()
      @listenTo(@accountView, 'selected', (val) =>
        @user().saveSelectedSupportAccountId(val, @accountView.text())
        path = window.location.hash
        routeArray = path?.split("/");
        isUserAccountSelected = val is @user().get('accountId')?.toString()
        if routeArray?.length is 3 and routeArray[0] is '#accounts'
          App.router.navigate(routeArray[0] + '/' + val + '/' + routeArray[2], trigger: true)
        else if path is '#user/salesforce-users' and not isUserAccountSelected
          App.router.navigate('#users', trigger: true)
        else
          Backbone.history.loadUrl()
      )
      @listenTo(@accountView, 'blur', =>
        setTimeout(=>
          unless @accountView.val()
            @user().saveSelectedSupportAccountId('ALL', '')
            Backbone.history.loadUrl()
        , 300)
      )

    @showChildView('user-profile-dropdown-react', new ReactComponent({component: RdnaSettingsAvatar, props: { pictureUrl: @user().get('userPicture'), userName: @user().get('userName'), userId: @user().get('userId'), showCalendar: @user().get('licenses').calendar, config: @ldConfig, logout: @doLogout, isSupport: @user().get('licenses').support, licenses: @user().get('licenses') } }))
    @showChildView('gen-ai-react', new ReactComponent({component: RdnaGenAi, props: { userName: @user().get('userName'), playBasePath: config.apiDomain, analytics: { trackEvent: (event, data) => @app.tracker.track(event, data) } }}))

  doLogout: =>
    @app.radio.trigger('user:logout')

  updateAccountPicker: (accountId, accountName) =>
    if @accountView and accountId
      @accountView.val(accountId)
      @user().saveSelectedSupportAccountId(accountId, accountName)

module.exports = HeaderView
