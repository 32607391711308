import {
  localProductDomains,
  devProductDomains,
  qaProductDomains,
  prodProductDomains,
  EnvDomains
} from '@ringdna/common/src/constants/productDomains'

export enum Env {
  Local = 'local',
  Dev = 'dev',
  Qa = 'qa',
  Prod = 'prod'
}

type EnvConfig = EnvDomains & {
  amplitudeKey: string
  launchDarklyKey: string
  appDomain: string
  rootDomain: string
  pubnubKey: string
  deployTarget: string
  salesforceId: string
  rdEnv: Env
  mlFeedbackDomain: string
}
export type Config = EnvConfig & {
  sentryDsn: string
  debugApiClient: boolean
  pendoId: string
  apiLabs: string
  apiGpu: string
}

if (process.env.NODE_ENV !== 'test') {
  console.log(`## RD_ENV ${process.env.RD_ENV || 'local'}`)
}

let envDefaults: EnvConfig
if (process.env.RD_ENV === 'local' || !process.env.RD_ENV) {
  envDefaults = {
    ...localProductDomains,
    appDomain: 'localhost',
    amplitudeKey: 'f4959f66a242577576bc485278c65bb8',
    launchDarklyKey: '5de5ad7ecd152607f833aee4',
    rootDomain: 'localhost',
    pubnubKey: 'sub-c-4ed829d2-7621-11e9-90ac-5a6b801e0231',
    deployTarget: 'local',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Local,
    mlFeedbackDomain: 'http://localhost:3000'
  }
} else if (process.env.RD_ENV === 'dev') {
  envDefaults = {
    ...devProductDomains,
    amplitudeKey: 'b7283c6c0675e28775bf732b1bf77a1e',
    launchDarklyKey: '60a2d7d682a78e0e8dc64e01',
    appDomain: 'app-dev.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-51d5713a-7621-11e9-bb2b-0e7d3ff647bd',
    deployTarget: 'dev',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Dev,
    mlFeedbackDomain: 'https://ml-feedback-dev.ringdna.net'
  }
} else if (process.env.RD_ENV === 'qa') {
  envDefaults = {
    ...qaProductDomains,
    amplitudeKey: 'b7283c6c0675e28775bf732b1bf77a1e',
    launchDarklyKey: '5de5c3c74d6d4808450f184a',
    appDomain: 'app-qa.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-51d5713a-7621-11e9-bb2b-0e7d3ff647bd',
    deployTarget: 'qa',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Qa,
    mlFeedbackDomain: 'https://ml-feedback-qa.ringdna.net'
  }
} else if (process.env.RD_ENV === 'prod') {
  envDefaults = {
    ...prodProductDomains,
    amplitudeKey: '19a22afdee26365840631209759fdf3f',
    launchDarklyKey: '5de5ad7ecd152607f833aee5',
    appDomain: 'app.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-55a444b2-7621-11e9-81d5-56c3556875f9',
    deployTarget: 'prod',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Prod,
    mlFeedbackDomain: 'https://ml-feedback.ringdna.net'
  }
} else {
  throw new Error(`Invalid RD_ENV value ${process.env.RD_ENV}`)
}

const defaultConfig: Config = {
  ...envDefaults,
  debugApiClient: process.env.NODE_ENV !== 'production',
  sentryDsn: 'https://1f1648d8ce954b4aac5c93ba81447210@o366070.ingest.sentry.io/5248208',
  pendoId: '1e0b9cf8-26ce-4c69-48dd-20a58e1c9154',
  apiLabs: 'https://lab.ringdna.net',
  apiGpu: 'https://gpu.ringdna.net'
}

export default defaultConfig
