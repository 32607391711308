BaseModel           = require('base/model')
BackgridCell        = require('helpers/backgrid-cell')
BaseGrid            = require('base/grid')
BaseView            = require('base/view')
PagedCollection     = require('base/paged-collection')
PagerView           = require('widgets/pager/view')
LocationCollection  = require('widgets/location-picker/collection')
LocationPickerView  = require('widgets/location-picker/view')
EmergencyCollection = require('./collection')
EmergencyModel      = require('./model')
EmergencyEditorView = require('./editor/view')

class EmergencyStatusModel extends BaseModel
  url: -> "emergency/#{@get('id')}/status"

module.exports = EmergencyModel

class EmergencyView extends BaseView
  template: require('./template')
  className: 'emergency'

  regions:
    grid           : 'div.grid'
    pager          : 'span.pager'
    locationPicker : 'span.location-picker'
    modal          : 'div.modal-container'

  ui:
    search       : 'button.search'
    addEmergency : 'a.add-emergency'

  events:
    'click @ui.search'       : 'search'
    'click @ui.addEmergency' : 'showEditor'

  initialize: (options) ->
    @qs = options.qs
    @accountId = options.accountId
    @locations = new LocationCollection()

  onRender: =>
    @collection = new EmergencyCollection(
      queryParams:
        accountId: @accountId
        locationId: null
      url: 'emergency'
    )
    @listenTo(@collection, 'change destroy', => @search())
    @listenTo(@collection, 'reset', => @initBootstrapToggle())
    @locations
      .fetch(data: accountId: @accountId)
      .done(=>
        return unless @getRegion('locationPicker')
        @locationPicker = new LocationPickerView(
          accountId: @accountId
          fullList: true
          locations: @locations
          emptyOption: 'All'
        )
        @listenTo(@locationPicker, 'select', (val) =>
          @collection.queryParams.locationId = if val then val else 0
        )
        @listenTo(@locationPicker, 'deselect', =>
          @collection.queryParams.locationId = null
        )
        @listenTo(@collection, 'add', =>
          @locationPicker.clear()
          @search()
        )
        @showChildView('locationPicker', @locationPicker)
        @search()
      )

  search: =>
    @showChildView('pager', new PagerView(collection: @collection))
    @collection
      .fetch(reset: true)
      .done(=>
        return unless @getRegion('grid')
        @detachChildView('grid')
        @grid = @buildGrid()
        @showChildView('grid', @grid)
      )

  buildGrid:() ->
    statusCell = class extends Backgrid.Cell
      events:
        'click .toggle' : 'statusChanged'

      statusChanged: =>
        if @model.get('enabled')
          msg = 'Disabling this status will restore all ringDNA user\'s smart numbers call forwarding status
            to their original settings. Do you want to proceed?'
        else
          msg = 'Enabling this status will forward all ringDNA user\'s smart numbers to
            an Emergency Routing Number. Do you want to proceed?'
        checkbox = @$("#status-#{@model.get('id')}")
        btn = @$('.toggle')
        inProgress = false
        dialog = bootbox.dialog(
          title: 'WARNING'
          closeButton: false
          className: 'proceed-emergency-status'
          message: msg
          onEscape: =>
            return false if inProgress
            checkbox.bootstrapToggle('toggle')
          buttons:
            cancel:
              label: 'Cancel'
              className: 'btn btn-default status-cancel'
              callback: =>
                return false if inProgress
                checkbox.bootstrapToggle('toggle')
            proceed:
              label: 'Proceed'
              className: 'ladda-button btn btn-danger proceed-status'
              callback: =>
                checked = checkbox.prop('checked')
                statusModel = new EmergencyStatusModel(
                  id: @model.get('id')
                  enabled: checked
                )
                inProgress = true
                statusModel
                  .persist(ladda: $('.proceed-status')[0])
                  .done( =>
                    @model.set(enabled: checked, {silent: true})
                    @model.trigger('statusUpdated')
                    dialog.modal('hide')
                  )
                  .fail( =>
                    checkbox.bootstrapToggle('toggle')
                    dialog.modal('hide')
                  )
                return false
        )

      render: =>
        checked = if @model.get('enabled') then 'checked' else ''
        id = "status-#{@model.get('id')}"
        html = "<div class='form-group status-cell'><input type='checkbox' class='status-checkbox' id='#{id}' #{checked}></div>"
        $(@el).html(html)
        @delegateEvents()
        @

    callFlowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    smartNumbersCell = class extends Backgrid.Cell
      render: =>
        entities = @model.get('callFlow')?.entities
        if entities
          html = ''
          sortedEntities = _.sortBy(entities, (entity) => entity.smartNumber.number)
          for entity in sortedEntities
            href = '#smart-numbers/' + entity.smartNumber.id
            html += "<div><a href='#{href}'>#{entity.smartNumber.number}</a></div>"
        $(@el).html(html)
        @

    datetimeCell = BackgridCell.dateTime()

    headerCellWithoutSorting = class extends Backgrid.HeaderCell
      onClick: ->
        return
      render: ->
        $(@el).html("<a>#{@column.get('label')}</a>")
        @

    editCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#emergency-editor-modal">
            Update
          </button>
        ''')
        @

    deleteCell = class extends Backgrid.Cell
      className: 'delete-cell'
      events:
        'click a.delete': 'delete'

      delete: ->
        inProgress = false
        dialog = bootbox.dialog(
          closeButton: false
          message: 'Are you sure you want to delete this?'
          onEscape: =>
            return false if inProgress
            true
          buttons:
            cancel:
              label: 'Cancel'
              className: 'btn btn-default delete-cancel'
              callback: =>
                return false if inProgress
                true
            ok:
              label: 'Ok'
              className: 'btn btn-default delete-ok'
              callback: =>
                inProgress = true
                @model
                  .delete(ladda: $('.delete-ok')[0], wait: true)
                  .done( => dialog.modal('hide'))
                  .fail( => dialog.modal('hide'))
                false
        )

      render: ->
        unless @model.get('enabled')
          html = '''
            <a class="delete">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          '''
        else
          html = ''
        $(@el).html(html)
        @listenTo(@model, 'statusUpdated', => @render())
        @

    columns = [
      { name: 'enabled',   label: 'Status',       cell: statusCell },
      { name: '',          label: 'Location',     cell: @locationsCell(),    headerCell: headerCellWithoutSorting },
      { name: 'callFlow',  label: 'Call Flow',    cell: callFlowCell },
      { name: 'callFlow',  label: 'Phone Number', cell: smartNumbersCell,    headerCell: headerCellWithoutSorting },
      { name: 'createdAt', label: 'Date created', cell: datetimeCell },
      { name: 'updatedAt', label: 'Date updated', cell: datetimeCell },
      { name: '',          label: 'Update',       cell: editCell,            headerCell: headerCellWithoutSorting },
      { name: '',          label: 'Delete',       cell: deleteCell,          headerCell: headerCellWithoutSorting },
    ]

    new BaseGrid.Grid(
      collection : @collection,
      columns    : columns,
      onEdit     : @showEditor
      emptyText  : 'No Emergency Routes found.'
    )

  locationsCell: =>
    mainClass = @
    locationsCell = class extends Backgrid.Cell
      render: =>
        emergencyRouteLocations = @model.get('emergencyRouteLocations')
        if emergencyRouteLocations
          if mainClass.locations.length is emergencyRouteLocations.length and emergencyRouteLocations.length isnt 0
            locations = 'All'
          else
            locationNames = []
            for emergencyRouteLocation in emergencyRouteLocations
              locationNames.push(emergencyRouteLocation.location.name)
            locations = locationNames.sort().join(', ')
          html = "<div>#{_.escape(locations)}</div>"
          $(@el).html(html)
        @

  showEditor: (options) =>
    model = options.model?.clone()
    model ?= new EmergencyModel(
      accountId: @accountId
      callFlow: null
      emergencyRouteLocations: []
    )
    @showChildView('modal', new EmergencyEditorView(
      accountId   : @accountId
      model       : model
      locations   : @locations
      collection  : @collection
    ))

  initBootstrapToggle: =>
    _.defer(=>
      $('input[type=checkbox]').bootstrapToggle(
        offstyle : 'default'
        onstyle  : 'info'
        size     : 'small'
        width    : '60px'
      )
    )

module.exports = EmergencyView
