import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import styled from 'styled-components'
import { SMS_TEMPLATE_STR } from '../strings'
import FileLoader from './FileLoader'
import { Attachment } from '../types'
import { SmsTemplateMedia } from '@ringdna/common/src/types/sms-template'
import { ACCEPTED_TWILIO_CONTENT_TYPES_MMS, loadFile } from '@ringdna/common/src/utils/fileUtils'

type Props = {
  onChange: (files: SmsTemplateMedia[]) => void
  name: string
  onError: (error: string) => void
  existingFiles?: SmsTemplateMedia[]
}

const mbFileSizeLimit = 5

export default function FilePicker({ onChange, name, existingFiles, onError }: Props) {
  const inputEl = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<Attachment[]>([])

  useEffect(() => {
    const newFiles: Attachment[] = []
    existingFiles &&
      existingFiles.map(media => {
        media.id &&
          media.signedUrl &&
          loadFile(media.signedUrl).then(file => {
            if (file) {
              const attach = file as Attachment
              attach.id = media.id!
              attach.url = media.url
              attach.signedUrl = media.signedUrl!
              attach.loaded = true
              newFiles.push(attach)
              setFiles([...newFiles])
            }
          })
      })
  }, [existingFiles])

  function isFileSizeTooLarge(fileSize: number): boolean {
    const megaByte = 1000000
    let fileTooLarge = false
    const megaByteFileSize = fileSize / megaByte
    if (megaByteFileSize > mbFileSizeLimit) {
      fileTooLarge = true
    }
    return fileTooLarge
  }

  const onFileUpload = () => {
    onChange(
      files.map(f => {
        return { id: f.id, url: f.url }
      })
    )
  }

  const onFileDelete = (file: Attachment) => {
    setFiles(files.filter(f => f.url !== file.url))
    onChange(
      files
        .filter(f => f.url !== file.url)
        .map(f => {
          return { id: f.id, url: f.url }
        })
    )
  }

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return null
    const newFiles = []
    if (files.length + event.target.files.length > 3) return onError('A limit of 3 files can be sent at one time.')
    for (const file of event.target.files) {
      if (ACCEPTED_TWILIO_CONTENT_TYPES_MMS.includes(file.type)) {
        if (isFileSizeTooLarge(file.size)) {
          onError(`File size must not exceed ${mbFileSizeLimit}mb.`)
        } else {
          newFiles.push(file as Attachment)
        }
      } else {
        onError(`Not valid file type: ${file.type}`)
      }
    }
    setFiles([...files, ...newFiles])
    event.target.value = ''
  }

  return (
    <div>
      <StyledButton
        text={SMS_TEMPLATE_STR.addFileImage}
        onClick={() => {
          inputEl.current && inputEl.current.click()
        }}
      />
      <input
        type="file"
        multiple={true}
        name={name}
        accept={ACCEPTED_TWILIO_CONTENT_TYPES_MMS.join(',')}
        ref={inputEl}
        data-testid={'file-upload'}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <Container>
        {files.map((file, index) => (
          <FileLoader
            key={`${file.name}-${index}`}
            file={file}
            onFileUpload={onFileUpload}
            onFileDelete={onFileDelete}
          ></FileLoader>
        ))}
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${props => props.theme.spacing * 3}px;
  .full-width {
    width: 100%;
  }
`

const StyledButton = styled(RdnaButton)`
  margin-top: ${props => props.theme.spacing * 2}px;
  margin-bottom: ${props => props.theme.spacing * 3}px;
`
