import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function BellIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M5.778 10.222v6.048c0 .05-.029.097-.074.12l-1.149.57a1 1 0 00-.555.895V18a1 1 0 001 1h14a1 1 0 001-1v-.145a1 1 0 00-.555-.895l-1.149-.57a.133.133 0 01-.074-.12v-6.048a6.222 6.222 0 00-12.444 0z"
        stroke={color}
      />
      <rect x={10} y={21} width={4} height={1} rx={0.5} fill={color} />
    </svg>
  )
}

export default BellIcon
