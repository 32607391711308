BaseView = require('base/view')

class AddressItemView extends BaseView
  template: require('./address-item-template')

  className: 'panel panel-default'

  bindings:
    '.friendly-name': 'value:friendlyName'
    '.business-name': 'value:businessName,classes:{"has-error":businessNameError}'
    '.country-container': 'classes:{"has-error":addressCountryError}'
    '.country-select': 'toggle:not(isLocal),'
    '.country-input': 'toggle:isLocal'
    '.street': 'value:street,classes:{"has-error":streetError}'
    '.city': 'value:city,classes:{"has-error":cityError}'
    '.region': 'value:region,classes:{"has-error":regionError}'
    '.postal-code': 'value:postalCode,classes:{"has-error":postalCodeError}'
    '.created-error': 'toggle:createdError'

  ui:
    country: '.country-select'

  computeds:
    isLocal:
      deps: ['addressRequirements']
      get: (addressRequirements) ->
        addressRequirements is 'local'

  initialize: (options) =>
    { @countries } = options
    @viewModel = new Backbone.Model(
      businessNameError: false
      addressCountryError: false
      streetError: false
      cityError: false
      regionError: false
      postalCodeError: false
      createdError: false
    )

  onRender: =>
    @initCountrySelectize()
    @setupListeners()

  initCountrySelectize: =>
    unless @getBinding('isLocal')
      @ui.country.selectize(
        allowEmptyOption: true
        selectOnTab: true
        maxItems: 1
        placeholder: 'Country'
        onInitialize: =>
          selectize = @ui.country[0].selectize
          return unless selectize
          for country in @countries.models
            if @model.get('addressRequirements') isnt 'foreign' or country.get('countryCode') isnt @model.get('countryCode')
              selectize.addOption(value: country.get('countryCode'), text: country.get('countryName'))
          selectize.addItem('', true)
        onChange: (value) =>
          @model.set(addressCountry: value)
      )
    else
      @model.set(addressCountry: @model.get('countryCode'))

  setupListeners: =>
    @listenTo(@model, 'change:businessName', => @viewModel.set(businessNameError: false) if @model.get('businessName'))
    @listenTo(@model, 'change:addressCountry', => @viewModel.set(addressCountryError: false) if @model.get('addressCountry'))
    @listenTo(@model, 'change:city', => @viewModel.set(cityError: false) if @model.get('city'))
    @listenTo(@model, 'change:postalCode', => @viewModel.set(postalCodeError: false) if @model.get('postalCode'))
    @listenTo(@model, 'change:region', => @viewModel.set(regionError: false) if @model.get('region'))
    @listenTo(@model, 'change:street', => @viewModel.set(streetError: false) if @model.get('street'))
    @listenTo(@model, 'checkErrors', => @checkErrors())
    @listenTo(@model, 'createdError', => @viewModel.set(createdError: true))

  checkErrors: =>
    @viewModel.set(businessNameError: not @model.get('businessName'))
    @viewModel.set(addressCountryError: not @model.get('addressCountry'))
    @viewModel.set(cityError: not @model.get('city'))
    @viewModel.set(postalCodeError: not @model.get('postalCode'))
    @viewModel.set(regionError: not @model.get('region'))
    @viewModel.set(streetError: not @model.get('street'))

module.exports = AddressItemView
