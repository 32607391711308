BaseGrid             = require('base/grid')
BaseView             = require('base/view')
EditorView           = require('./editor/view')
BackgridCell         = require('helpers/backgrid-cell')
SalesforceField      = require('./model')
SalesforceFieldСells = require('./cells')

class SalesforceFieldsView extends BaseView
  template: require('./template')

  regions:
    fieldsGrid: 'div.salesforce-fields-backgrid-container'
    modal     : 'div.modal-container'

  bindings:
    '.fields-title': 'text:title'
    '.add'         : 'toggle:enableAdd'
    '.no-add'      : 'toggle:not(enableAdd)'

  computeds:
    title:
      get: -> "Manage #{@type} Fields"

  events:
    'click a.add': 'addNewAdditionalField'

  initialize: (options) ->
    { @profileId, @type, @accountId } = options
    @viewModel = new Backbone.Model(enableAdd: false)
    @gridModel = new SalesforceField.GridModel()
    @listenTo(@gridModel, 'change:fields', @updateEnableAdd)

  onRender: =>
    @fetchData()

  fetchData: =>
    @viewModel.set(enableAdd: false)
    @gridModel
      .fetch(data: { id: @profileId, type: @type})
      .done(@renderGrid)

  addNewAdditionalField: =>
    view = new EditorView(
      accountId: @accountId
      profileId: @profileId
      sortOrder: @gridModel.get('fields').where(isDefault: false).length + 1
      type: @type
      availableFields: @gridModel.get('availableFields')
    )
    @listenTo(view, 'add:setting', => @fetchData())
    @showChildView('modal', view)

  renderGrid: =>
    return if not @getRegion('fieldsGrid')
    @detachChildView('fieldsGrid')
    @showChildView('fieldsGrid', @buildGrid(@gridModel.get('fields')))

  updateEnableAdd: =>
    @viewModel.set('enableAdd', @gridModel.get('fields').where(isDefault: false, view: true).length < 30)

  buildGrid: (collection) =>
    @listenToOnce(collection, 'change', => @trigger('enableSave'))
    datetime = BackgridCell.dateTime()
    viewCell = SalesforceFieldСells.ViewCell
    viewHeaderCell = SalesforceFieldСells.ViewHeaderCell
    visibleCell = SalesforceFieldСells.AlwaysVisibleCell
    visibleHeaderCell = SalesforceFieldСells.AlwaysVisibleHeaderCell
    editableСell = SalesforceFieldСells.EditableCell
    editableHeaderCell = SalesforceFieldСells.EditableHeaderCell
    requiredCell = SalesforceFieldСells.RequiredCell
    requiredHeaderCell = SalesforceFieldСells.RequiredHeaderCell
    deleteCell = SalesforceFieldСells.DeleteCell
    dynamicCell = (options) =>
      return new (options.column.get('cellType')(options.model))(options)
    getCellTypeByIsDefaultValue = (NotIsDefaultCell) => (model) =>
      if not model.get('isDefault') then NotIsDefaultCell else Backgrid.Cell

    columns = [
      {
        name: 'order',
        label: 'Order',
        sortable: false,
        cell: dynamicCell,
        cellType: getCellTypeByIsDefaultValue(BaseGrid.OrderCell),
        bounds: [collection.where({isDefault: true}).length, collection.length - 1]
      }
      { name: 'salesforceField.name',     label: 'Name',                  sortable: false, cell: 'string' }
      { name: 'salesforceField.sfdcName', label: 'Salesforce Field Name', sortable: false, cell: 'string' }
      { name: 'salesforceField.dataType', label: 'Type',                  sortable: false, cell: 'string' }
      { name: 'updatedAt',                label: 'Last Updated',          sortable: false, cell: datetime }
      { name: 'view',                     label: 'View',                  sortable: false, cell: viewCell,     headerCell: viewHeaderCell }
      { name: 'visible',                  label: 'Always Visible',        sortable: false, cell: visibleCell,  headerCell:  visibleHeaderCell }
      { name: 'editable',                 label: 'Editable',              sortable: false, cell: editableСell, headerCell: editableHeaderCell }
      { name: 'required',                 label: 'Required',              sortable: false, cell: requiredCell, headerCell: requiredHeaderCell }
      { name: '',                         label: 'Delete',                sortable: false, cell: dynamicCell,  cellType: getCellTypeByIsDefaultValue(deleteCell) }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : collection
      emptyText  : "No #{@type} fields have been found."
      onMoveOne  : @moveOne
      onMoveBound: @moveBound
      onRemove   : @fetchData
    )

  moveOne: (options) =>
    model = options.model
    collection = @gridModel.get('fields')
    index = collection.indexOf(model)
    if index isnt -1
      collection.remove(model)
      collection.add(model, {at: if options.move then index - 1 else index + 1})
      @trigger('enableSave')

  moveBound: (options) =>
    model = options.model
    collection = @gridModel.get('fields')
    index = collection.indexOf(model)
    if index isnt -1
      collection.remove(model)
      if options.move
        defaultCollection = collection.where(isDefault: true)
        additionalCollection = collection.where(isDefault: false)
        additionalCollection.unshift(model)
        collection.set(defaultCollection.concat(additionalCollection))
      else
        collection.push(model)
      @trigger('enableSave')

  onSaveClicked: (button) =>
    collection = new SalesforceField.SettingCollection(@gridModel.get('fields').models)
    collection.models.forEach((model, index) =>
      model.set(sortOrder: index - 1)
      model.get('salesforceField')?.isDefault = model.get('isDefault')
    )
    collection
      .persist(ladda: button)
      .done( =>
        @updateEnableAdd()
        toastr.info("Your changes has been saved.")
    )

module.exports = SalesforceFieldsView
