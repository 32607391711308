/**
 * @deprecated for `RdnaFormElements/RdnaRadioGroup`.
 */
import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { FormSelectOption, InputComponentProps } from '../../../types/form-inputs'
import styled from 'styled-components'
import { getFormOptionFromStrings } from '../FormOptionUtils'
import { Colors } from '../../../constants/colors'

const CUSTOM_INPUT = 'custom'

const CustomInput = styled(TextField)`
  float: left;
  max-width: 100px;
  top: ${({ theme }) => theme.spacing * -8}px;
  left: ${({ theme }) => theme.spacing * 15}px;
  margin-bottom: ${({ theme }) => theme.spacing * -8}px !important;
`

const StyledRadio = styled(Radio)`
  &.Mui-checked:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

const RdnaRadio = ({ inputData, value, isError, errorMessage, onChange }: InputComponentProps) => {
  const options = getFormOptionFromStrings(inputData.options || [])
  const [customValue, setCustomValue] = useState('')
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    const nonCustomValue = options.some(option => option.value === value)
    if (!nonCustomValue) {
      setCustomValue(value as string)
      setInputValue(CUSTOM_INPUT)
    }
  }, [options, setCustomValue, value])

  const customInput = useRef<HTMLInputElement>()

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      event.target.value === CUSTOM_INPUT && customInput.current ? customInput.current.value : event.target.value
    setInputValue(event.target.value)
    onChange(inputData.name, newValue)
  }

  const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValue(event.target.value)
    onChange(inputData.name, event.target.value)
  }

  return (
    <FormControl component="fieldset" className={inputData.className}>
      {inputData.label && <FormLabel component="legend">{inputData.label}</FormLabel>}
      <RadioGroup
        aria-label={inputData.name}
        data-testid={`radio-${inputData.name}`}
        name={inputData.name}
        style={{ width: 'fit-content', color: Colors.BLACK }}
        row={inputData.isRadioRow}
        value={inputValue}
        onChange={handleEventChange}
      >
        {options &&
          options.map((optionItem: FormSelectOption) => (
            <React.Fragment key={optionItem.value}>
              <FormControlLabel
                value={optionItem.value}
                control={
                  <StyledRadio
                    // @ts-ignore
                    inputProps={{ 'data-testid': `radio-option-${optionItem.value}` }}
                    color={'primary'}
                  />
                }
                label={optionItem.label}
              />
              {optionItem.value === CUSTOM_INPUT ? (
                <CustomInput
                  onChange={handleCustomInputChange}
                  inputProps={{ ref: customInput, 'data-testid': 'custom-radio-value-input' }}
                  placeholder={inputData.placeholder}
                  margin="dense"
                  variant={'outlined'}
                  value={customValue}
                />
              ) : null}
            </React.Fragment>
          ))}
      </RadioGroup>
      <FormHelperText>{isError ? errorMessage : inputData.helperText}</FormHelperText>
    </FormControl>
  )
}

export default RdnaRadio
