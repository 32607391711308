/**
 * @deprecated for `RdnaPagination2`.
 */
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Direction } from '../../../src/assets/icons/types'
import Caret from '../../../src/assets/icons/svg/Caret'
import styled from 'styled-components'
import RdnaText from '../RdnaText'

const PaginationStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;

  & > span {
    margin-right: ${props => props.theme.spacing * 3}px;
    width: max-content;
  }

  button {
    min-width: ${props => props.theme.spacing * 8}px;
    height: ${props => props.theme.spacing * 6}px;
  }

  .right-btn {
    margin-right: 11px;
  }

  .pagination-btn-active {
    border: 1px solid ${props => props.theme.palette.meta};

    .pagination-icon {
      color: ${props => props.theme.palette.meta};
    }

    &:hover {
      border: 1px solid ${props => props.theme.palette.primary.dark};
      background-color: ${props => props.theme.palette.primary.main};
      .pagination-icon {
        color: ${props => props.theme.palette.text.contrast};
      }
    }
  }

  .pagination-btn-inactive {
    border: 1px solid ${props => props.theme.palette.inactive};

    .pagination-icon {
      color: ${props => props.theme.palette.inactive};
    }
  }
`

type PaginationProps = {
  listLength: number
  itemsPerPage: number
  onClick: ({ page }: { page: number }) => any
  page?: number
  defaultReset?: boolean
}

const defaultPage = 1

export default function RdnaPagination({
  onClick,
  page = defaultPage,
  itemsPerPage,
  listLength,
  defaultReset = false
}: PaginationProps) {
  // tracks internal page state
  const [internalPage, setInternalPage] = useState<number>(page)

  useEffect(() => {
    page !== internalPage && setInternalPage(page)
    // tracking internalPage changes will cause a bug that was fixed in ADMIN-1396
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    defaultReset && setInternalPage(defaultPage)
  }, [listLength, defaultReset])

  const maxPages = Math.ceil(listLength / itemsPerPage)
  const pageChanger = (delta: number) => () => {
    const newPage = internalPage + delta
    if (newPage > 0 && newPage <= maxPages) {
      setInternalPage(newPage)
      // emit new page state
      onClick({ page: newPage })
    }
  }

  if (maxPages < 2) return null

  return (
    <PaginationStyle>
      <RdnaText>
        Page {internalPage > maxPages ? maxPages : internalPage} of {maxPages}
      </RdnaText>
      <Button
        className={`right-btn pagination-btn-${internalPage === 1 ? 'inactive' : 'active'}`}
        onClick={pageChanger(-1)}
        disabled={internalPage === 1}
        title={'Previous Page'}
        data-testid="previous-page"
      >
        <div className="pagination-icon">
          <Caret size={16} direction={Direction.LEFT} aria-hidden />
        </div>
      </Button>
      <Button
        className={`pagination-btn-${internalPage === maxPages ? 'inactive' : 'active'}`}
        onClick={pageChanger(1)}
        disabled={internalPage === maxPages}
        title={'Next Page'}
        data-testid="next-page"
      >
        <div className="pagination-icon">
          <Caret size={16} direction={Direction.RIGHT} aria-hidden />
        </div>
      </Button>
    </PaginationStyle>
  )
}
