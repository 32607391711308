BaseGrid = require('base/grid')
BaseView = require('base/view')
CallScriptModel = require('./model')
CallScriptCollection = require('./collection')
ModalView = require('./modals/main-view')
EntityCollection = require('./modals/entity-collection')

class CallScriptsView extends BaseView

  template: require('./template')

  className: 'call-scripts'

  regions:
    grid: 'div.grid'
    wizard: 'div.wizard'

  events:
    'click a.add': 'createCallScript'

  initialize: (options) ->
    { @accountId } = options
    @collection = new CallScriptCollection()

  onRender: =>
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'name',        label: 'Name',        cell: 'string' },
      { name: 'description', label: 'Description', cell: 'string' },
      { name: '',            label: 'Update',      cell: actionCell },
      { name: '',            label: 'Delete',      cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No call scripts found.'
      onEdit     : @showEditor
    )

  createCallScript: =>
    model = new CallScriptModel()
    @showChildView('wizard',
      new ModalView(
        model: model
        collection: @collection
        entityCollection: new EntityCollection()
        accountId: @accountId
      )
    )

  showEditor: (options) =>
    model = options.model.clone()
    @showChildView('wizard',
      new ModalView(
        model: model
        collection: @collection
        entityCollection: new EntityCollection()
        accountId: @accountId
        scriptId: model.get('id')
      )
    )

module.exports = CallScriptsView
