import React, { useContext, useState } from 'react'
import styled from 'styled-components'

// constants
import { connectZoomUserInputs, IntegrationsActions } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'

// types
import { InitIntegrationsContext } from '../../model'

// utils
import useToast from '../../../../common/hooks/useToast'
import { IntegrationsContext } from '../../store/IntegrationsProvider'

// styled
import { Box } from '@mui/material'
import RdnaForm from '@ringdna/common/src/components/RdnaForm'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { usePostCreateZoomUser } from '../../../../../store/api/user-integrations-api'

const StyledContainer = styled.div`
  text-align: center;
  width: 480px;
  overflow-x: hidden;
`

export default function ConnectNewZoomUserModal() {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [loading, setLoading] = useState(false)
  const postCreateZoomUser = usePostCreateZoomUser()

  const formSubmit = async (values: any) => {
    setLoading(true)
    const data: any = { accountId: activeUser.accountId, ...values }
    try {
      const res = await postCreateZoomUser({ body: data })
      setLoading(false)
      if (res?.success?.payload) {
        dispatch({ type: IntegrationsActions.SET_CONNECTED_INTEGRATION, payload: IntegrationProviderTypes.ZOOM })
      }
    } catch (error) {
      toastr.error(JSON.parse(error.message).message)
      setLoading(false)
    }
  }

  return (
    <StyledContainer>
      <Box pt={2}>
        <RdnaText variant="h2">Create a New Zoom Users</RdnaText>
      </Box>
      <Box py={2}>
        <RdnaText>Enter in the following parameters to create a new user for account ringDNA</RdnaText>
      </Box>
      <RdnaForm
        submitText="Connect Your Account"
        onSubmit={formSubmit}
        inputFields={connectZoomUserInputs}
        isLoading={loading}
      />
    </StyledContainer>
  )
}
