import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import React, { useState } from 'react'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../const'
import { rolesColumns } from '../columns'
import { Role } from '../types'

export type RolesTableProps = {
  payload: Role[]
  loading: boolean
}

export default function RolesTable({ payload, loading }: RolesTableProps) {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  return (
    <RdnaSmartTable
      searchPlaceholder="Search"
      // @ts-ignore
      columns={rolesColumns}
      data={payload}
      noResultState={NO_RESULT_STATE}
      isFetching={loading}
      paginationSize={pageSize}
      paginationSizes={PAGE_SIZES}
      onPagination={setPageSize}
    />
  )
}
