BaseView = require('base/view')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')
SalesforceFieldsView = require('./fields-grid/view')

class ProfileFieldsView extends BaseView

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  className: 'salesforce-profile-fields'

  regions:
    tab: '.sfdc-tabs'

  ui:
    save: '.save'

  bindings:
    '.not-viewed-alert'  : 'toggle:not(isViewed)'
    '.profile-name-title': 'text:name'
    '.type-route'        : 'text:typeRouteText'
    '.account'           : 'text:accountName'
    '.profile-name'      : 'value:name'
    '.profile-id'        : 'value:id'
    '.user-count'        : 'text:userCount'
    '.accountFields'     : 'classes:{"active btn-primary":AccountTabSelected}'
    '.campaignFields'    : 'classes:{"active btn-primary":CampaignTabSelected}'
    '.caseFields'        : 'classes:{"active btn-primary":CaseTabSelected}'
    '.contactFields'     : 'classes:{"active btn-primary":ContactTabSelected}'
    '.leadFields'        : 'classes:{"active btn-primary":LeadTabSelected}'
    '.opportunityFields' : 'classes:{"active btn-primary":OpportunityTabSelected}'
    '.taskFields'        : 'classes:{"active btn-primary":TaskTabSelected}'
    '.eventFields'       : 'classes:{"active btn-primary":EventTabSelected}'
    '.save'              : 'enabled:enableSave'

  events:
    'click .dismiss-alert': 'dismissAlert'
    'click .save': 'onSaveClicked'
    'click .sfdc-field-tabs button': 'tabClicked'
    'click .account': 'goToAccountView'
    'click .user-count': 'goToUsersView'

  computeds:
    accountName:
      deps: ['account']
      get: (account) ->
        account.name
    accountId:
      deps: ['account']
      get: (account) ->
        account.id
    typeRouteText:
      deps: ['currentTab']
      get: (currentTab) ->
        "#{currentTab[0].toUpperCase()}#{currentTab.slice(1)} Fields"

  initialize: (options) =>
    @viewModel = new Backbone.Model(
      AccountTabSelected: true
      CampaignTabSelected: false
      CaseTabSelected: false
      LeadTabSelected: false
      ContactTabSelected: false
      OpportunityTabSelected: false
      TaskTabSelected: false
      EventTabSelected: false
      currentTab: 'Account'
      enableSave: false
    )

    @tab = @getRegion('tab')

  onRender: =>
    @selectTab(@viewModel.get('currentTab'))

  dismissAlert: =>
    @model.set(isViewed: true)

  goToAccountView: =>
    App.navigate("#accounts/#{@model.get('account').id}", {trigger: true})

  goToUsersView: =>
    App.navigate("#salesforce-profiles/#{@model.get('id')}/users", {trigger: true})

  onSaveClicked: =>
    @tab.currentView.triggerMethod('save:clicked', @ui.save[0])

  tabClicked: (e) =>
    tab = $(e.target).attr('data-tab')
    @selectTab(tab)

  selectTab: (tabName) ->
    @viewModel.set(
      AccountTabSelected: false
      CampaignTabSelected: false
      CaseTabSelected: false
      ContactTabSelected: false
      LeadTabSelected: false
      OpportunityTabSelected: false
      TaskTabSelected: false
      EventTabSelected: false
      SettingsTabSelected: false
      enableSave: false
    )

    @viewModel.set("#{tabName}TabSelected", true)
    @viewModel.set(currentTab: tabName)
    profileId = @model.get('id')
    accountId = @getBinding('accountId')
    view = new SalesforceFieldsView(accountId: accountId, profileId: profileId, type: tabName)
    @listenTo(view, 'enableSave', => @viewModel.set('enableSave', true))
    @tab.show(view)


module.exports = ProfileFieldsView
