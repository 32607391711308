BaseView = require('base/view')
PhoneNumberCollectionView = require('./collection-view')

class PhoneNumberImportLayoutView extends BaseView

  template: require('./main-template')
  className: 'number-import'

  regions:
    results: '.results'

  ui:
    container : '.file-container'
    file      : '.file'
    header    : '.header'
    upload    : 'button.upload'

  events:
    'click @ui.upload': 'upload'

  bindings:
    '.header': 'toggle:length($collection)'

  initialize: ->
    @collection = new Backbone.Collection()
    App.pubsub.bind('PhoneNumberImportStatus', @onProgress)
    App.pubsub.bind('PhoneNumberImportFinished', @onFinish)

  onRender: =>
    @ui.file.filestyle(
      buttonBefore: true,
      buttonText: '&nbsp; Choose file'
    )

  upload: =>
    if not @ui.file.val()
      toastr.warning('Please choose a file.')
      return

    @ui.file.filestyle('disabled', true)

    App.api.post(
      path   : 'smartNumbers/import'
      data   : file: @ui.file[0].files[0]
      ladda  : @ui.upload[0]
      upload : true
      success: =>
        toastr.info('The importing process has begun.')
        @collection.reset()
        @showChildView('results', new PhoneNumberCollectionView(collection: @collection)) if @getRegion('results')
      complete: =>
        @ui.file.filestyle('disabled', false)
    )

  onProgress: (data) =>
    @collection?.add(new Backbone.Model(data?.message))

  onFinish: (data) =>
    toastr.info('The import has completed.')
    @ui.file.show()
    @ui.file.val('')

module.exports = PhoneNumberImportLayoutView
