var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block voicemail\">\n  <div class=\"sub-subtitle\">Audio</div>\n  <div>Play a message before the caller leaves a message</div>\n  <div class=\"audio-view\" data-prefix=\"audio\"></div>\n  <div class=\"center\">\n    <span class=\"error-step audio-input\"></span>\n  </div>\n\n  <div class=\"sub-subtitle\">Voicemail</div>\n  <div>Select a user or call queue to receive the voicemail</div>\n  <div class=\"picker\">\n    <div class=\"uqv-view\"></div>\n    <span class=\"error-step ugn\"></span>\n  </div>\n  <div class=\"voicemail-assignment\">\n    <div class=\"sub-subtitle\">Voicemail assignment</div>\n    <div>Select a user to have the voicemail assigned to</div>\n    <div class=\"picker\">\n      <div class=\"uqv-view vm-user-assignment\"></div>\n      <span class=\"error-step ugn\"></span>\n    </div>\n  </div>\n  <div class=\"append-text\">\n    <div class=\"sub-subtitle\">What text would you like to append to the Call Log activity?</div>\n    <b>Subject</b><br />\n    <input type=\"text\" class=\"input\" data-key=\"calllog-subject\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"calllog-subject") : stack1), depth0))
    + "\" /><br />\n    <b>Comments</b><br />\n    <textarea cols=\"80\" rows=\"10\" data-key=\"calllog-comments\" class=\"input\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"calllog-comments") : stack1), depth0))
    + "</textarea>\n  </div>\n</div>\n";
},"useData":true});