import React from 'react'
import {CallNotesTemplatesProps} from '../../types'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { Section, SectionRow, SectionHeader} from '../../styles'
import CallNotesIcon from '@ringdna/common/src/assets/icons/svg/CallNotes'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import InfoIcon from '@ringdna/common/src/assets/icons/svg/Info'
import RdnaTooltip from '@ringdna/common/src/components/RdnaTooltip'
import SettingsToggle from '../SettingsToggleSwitch'
import {callNoteTemplatesSettings} from '../../const'
import CallNotesTemplatesTable from './CallNotesTemplatesTable'


export default function CallNotesTemplatesTab({ accountId, accountSettings, setAccountSettings, teams }: CallNotesTemplatesProps) {
  return (
    <div data-testid='call-notes-templates-tab-section'>
      <Section>
        <SectionRow>
          <SectionHeader>
            <IconHelper icon={CallNotesIcon} iconSize={12} variant="filled" />
            <RdnaText className={'rdna-text'}>Call Notes Templates</RdnaText>
            <RdnaTooltip title="Allow users to use preconfigured Call Notes Templates.">
              <div>
                <IconHelper icon={InfoIcon} />
              </div>
            </RdnaTooltip>
          </SectionHeader>
          <SettingsToggle
            isChecked={accountSettings[callNoteTemplatesSettings]}
            setting={callNoteTemplatesSettings}
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
          />
        </SectionRow>
      </Section>
      {accountSettings[callNoteTemplatesSettings] && (<CallNotesTemplatesTable
        accountId={accountId}
        accountSettings={accountSettings}
        setAccountSettings={setAccountSettings}
        teams={teams}
      />)}
    </div>
  )
}
