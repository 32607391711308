import React, { useEffect, useState } from 'react'

// utils
import { useUser } from '../../common/services/UserContext'
import {
  hasAccountSettingsChangedSelector,
  hasAudioUploadSettingsChangedSelector,
  hasContinentRecordingSettingsChangedSelector,
  hasLockedSettingsChangedSelector,
  hasUSRecordingSettingsChangedSelector,
  useCallRecordingState
} from '../utils/contextReducer'
import { usePutAccountLockedSettings, useUpdateAccount } from '../../../store/api/accounts-api'
import { usePutCallRecordingSettings } from '../../../store/api/call-recording-api'
import { useUploadFile } from '../../../store/api/file-upload'
import { useCallRecordingDispatch } from '../utils/contextReducer'

// components
import RdnaButton from '@ringdna/common/src/components/RdnaButton'

type SaveAllSettingsButtonProps = {
  onSaveProp?: (callRecordingOverrideOption: string, accountCallRecordingLocked: boolean) => void
}

const prepareAudioData = (data: any[]) =>
  Object.assign(
    {},
    ...data.map((item: any, i: number) => ({
      [`files[${i}].file`]: item.file,
      [`files[${i}].fileName`]: item.fileName,
      [`files[${i}].setting`]: item.setting
    }))
  )

export default React.memo(SaveAllSettingsButton)
function SaveAllSettingsButton({ onSaveProp }: SaveAllSettingsButtonProps) {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const {
    accountSettings,
    unitedStatesRecordingSettings,
    continentRecordingSettings,
    lockedSettings,
    audioUploadSettings
  } = state
  const hasAccountSettingsChanged = hasAccountSettingsChangedSelector(state)
  const hasAudioUploadSettingsChanged = hasAudioUploadSettingsChangedSelector(state)
  const hasLockedSettingsChanged = hasLockedSettingsChangedSelector(state)
  const hasUSRecordingSettingsChanged = hasUSRecordingSettingsChangedSelector(state)
  const hasContinentRecordingSettingsChanged = hasContinentRecordingSettingsChangedSelector(state)
  const hasAnySettingChanged =
    hasAccountSettingsChanged ||
    hasAudioUploadSettingsChanged ||
    hasLockedSettingsChanged ||
    hasUSRecordingSettingsChanged ||
    hasContinentRecordingSettingsChanged

  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = useUploadFile()
  const putCallRecordingSettings = usePutCallRecordingSettings()
  const updateAccount = useUpdateAccount()
  const putAccountLockedSettings = usePutAccountLockedSettings()

  useEffect(() => {
    return () => dispatch({ type: 'RESET_TO_ORIGINAL_SETTINGS', payload: 'All' })
  }, [dispatch])

  const onSave = async () => {
    try {
      setIsLoading(true)

      const promises = [] as any

      if (hasAccountSettingsChanged) {
        promises.push(updateAccount({ body: accountSettings }))
      }

      if (accountSettings['settings.callRecordingByState'] && hasUSRecordingSettingsChanged) {
        promises.push(putCallRecordingSettings({ body: unitedStatesRecordingSettings }))
      }

      if (accountSettings['settings.callRecordingByCountry'] && hasContinentRecordingSettingsChanged) {
        promises.push(putCallRecordingSettings({ body: continentRecordingSettings }))
      }

      if (audioUploadSettings.length) {
        promises.push(
          uploadFile({
            accountId: accountSettings['account.id'],
            body: prepareAudioData(audioUploadSettings)
          }).then(response => {
            for (const file of response?.success?.payload) {
              dispatch({ type: 'CHANGE_ACCOUNT_SETTINGS', payload: { ['settings.' + file.setting]: file.url } })
            }
          })
        )
      }

      await Promise.all(promises)

      if (hasLockedSettingsChanged) {
        await putAccountLockedSettings({ body: lockedSettings })
      }

      onSaveProp &&
        onSaveProp(
          accountSettings['settings.callRecordingOverrideOption'],
          lockedSettings['lockedSetting.callRecording']
        )

      toastr.success('Call Recording Settings Saved')
      dispatch({ type: 'UPDATE_ORIGINAL_SETTINGS' })
      setIsLoading(false)
    } catch (error) {
      toastr.error(`An error has occurred, ${error}`)
      setIsLoading(false)
    }
  }

  return (
    <RdnaButton
      style={{ width: 'fit-content' }}
      disabled={!isEditingAllowed || isLoading || !hasAnySettingChanged}
      text={isLoading ? 'Saving...' : 'Save'}
      name="save-call-recording"
      onClick={onSave}
    />
  )
}
