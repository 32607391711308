BaseModel                 = require('base/model')
TwilioCallbacksCollection = require('./twilio-callbacks/collection')

config = require('../../../../src/config').default

class CallInspectorModel extends BaseModel

  urlRoot: 'calls'

  parse: (response) ->
    attrs = _.clone(response)
    @injectPapertrailUrl(attrs)
    @injectPlayerUrls(attrs)
    @twilioCallbacks = new TwilioCallbacksCollection(response.call.callbacks)
    delete response.call.callbacks
    attrs

  injectPapertrailUrl: (attrs) ->
    call = attrs.call
    url = "https://papertrailapp.com/events?q=#{call.callKey}+OR+#{call.callSid}"

    if call.ownerId
      url += escape('+OR+"userId=' + call.ownerId + ']"')

    if call.startTime
      timestamp = Math.floor(call.startTime / 1000)
      url += "&time=#{timestamp}"

    attrs.papertrailUrl = url

  injectPlayerUrls: (attrs) ->
    call = attrs.call
    baseUrl = "#{config.caiDomain}/conversations"
    rec.playerUrl = "#{baseUrl}/#{call.callKey}" for rec in call.recordings

    if call.voicemail
      call.voicemail.playerUrl = "#{baseUrl}/#{call.callKey}"

module.exports = CallInspectorModel
