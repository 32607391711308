import MeetingCard from './MeetingCard'
import CirclePlus from '@ringdna/common/src/assets/icons/svg/CirclePlus'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import styled from 'styled-components'
import React from 'react'
import { CREATE_SCHEDULING_PAGE_CTA } from './constants'

type Props = {
  onAddSchedulingPage: () => void
}

export default function AddSchedulingPageCard({ onAddSchedulingPage }: Props) {
  return (
    <MeetingCard>
      <StyledAddSchedulingPageCard onClick={onAddSchedulingPage}>
        <CirclePlus />
        <RdnaText variant="body2" component="p">
          {CREATE_SCHEDULING_PAGE_CTA}
        </RdnaText>
      </StyledAddSchedulingPageCard>
    </MeetingCard>
  )
}

const StyledAddSchedulingPageCard = styled.div`
  cursor: pointer;
  padding: ${props => props.theme.spacing * 4}px 0;
`
