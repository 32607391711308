import React from 'react'
import { Box } from '@mui/material'

/**
 * This provides a simple box to center content on the screen. This is good
 * for things like spinners and important page content.
 */

type Props = {
  children: React.ReactChild
  minHeight?: string
}

export default function UiCenteredBox({ children, ...props }: Props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...props}>
      <Box display="block">{children}</Box>
    </Box>
  )
}
