BaseGrid                  = require('base/grid')
BaseView                  = require('base/view')
BaseModel                 = require('base/model')
ListSelectedSfdcUsersView = require('features/users/salesforce/select/view')
ListSalesforceUsersView   = require('features/users/salesforce/show/view')
SfdcUsersCollection       = require('features/users/salesforce/salesforce-collection')
ResultSfdcUsersCollection = require('features/users/salesforce/result-collection')
ListNewUsersView          = require('features/users/salesforce/result/view')

class SalesforceUsersView extends BaseView
  template: require('./template')

  regions:
    content: '.content'

  ui:
    next: 'button.next'
    back: 'button.back'
    content: '.content'

  bindings:
    'button.next'     : 'classes:{"btn-success": enableNextStep}, disabled:not(enableNextStep), toggle:hideNextStep'
    'button.back'     : 'toggle:enableBackStep'

  events:
    'click @ui.next' : 'next'
    'click @ui.back' : 'back'

  initialize: (options) =>
    { @accountId, @qs } = options
    @salesforceUsers = new SfdcUsersCollection()
    @results = new ResultSfdcUsersCollection()
    @listSalesforceUsers = new ListSalesforceUsersView(qs: @qs, accountId: @accountId, salesforceUsers: @salesforceUsers)
    @viewModel = new Backbone.Model(
      enableNextStep: false
      enableBackStep: false
      hideNextStep: true
      locationRequired: @user().can('sfdc-users:location:edit')
    )

  onRender: =>
    @content = @getRegion('content')
    @content.show(@listSalesforceUsers)
    @search()

  search: =>
    @listenTo(@listSalesforceUsers, 'selected', (checked) =>
      @viewModel.set('enableNextStep', checked)
    )

  next: =>
    if not @viewModel.get('enableBackStep')
      @viewModel.set('enableBackStep', true)
      @listSelectedSfdcUsers = new ListSelectedSfdcUsersView(collection: @salesforceUsers, accountId: @accountId)
      @listenTo(@listSelectedSfdcUsers, 'selected', (checked) =>
        @viewModel.set('enableNextStep', checked)
      )
      @showView(@listSelectedSfdcUsers)
    else
      if @checkLicenses()
        toastr.warning('Please select licenses of the Imported Users')
        return
      if @checkLocation()
        toastr.warning('Please select location of the Imported Users')
        return
      @saveSelectedUsers()
      @viewModel.set(
        enableBackStep: false
        hideNextStep: false
      )
      @listNewUsers = new ListNewUsersView(collection: @results, accountId: @accountId)
      @showView(@listNewUsers)

  back: =>
    @showView(@listSalesforceUsers)
    @search()
    @viewModel.set('enableBackStep', false)

  checkLocation: =>
    hasLocation = false
    if @viewModel.get('locationRequired') and not @listSelectedSfdcUsers.viewModel.get('assignLocation')
      @salesforceUsers.each((user) => hasLocation = true if not user.get('locationId'))
    hasLocation

  checkLicenses: =>
    noLicense = false
    @salesforceUsers.each((user) =>
      hasLicense = false
      _.each(ListSelectedSfdcUsersView.getLicenses(), (license) =>
        hasLicense = true if user.get(license.value)
      )
      noLicense = true unless hasLicense
    )
    noLicense

  saveSelectedUsers: =>
    model = @listSelectedSfdcUsers.viewModel
    @salesforceUsers.setParams(
      accountId: @accountId
      areaCode: if model.get('assignAreaCode') then model.get('areaCode') else null
      extension: if model.get('assignExtension') then model.get('extension') else null
      location: if model.get('assignLocation') then model.get('location') else null
    )
    @salesforceUsers.create()

  showView: (view) =>
    @detachChildView('content')
    @content.show(view)

module.exports = SalesforceUsersView
