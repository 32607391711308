class MessageModel extends Backbone.Model
  urlRoot: 'messages'

class MessageInspectorView extends Marionette.View
  template: require('./template')
  className: 'message-inspector'

  initialize: (options) ->
    @model = new MessageModel(id: options.messageId)
    @listenTo(@model, 'change', -> @render())
    @model.fetch()

module.exports = MessageInspectorView
