import { RequestFail } from './Classes'
import { Store } from 'redux'
import { RehydrateAction } from 'redux-persist'
import { MutableRefObject } from 'react'
import { GenericObject } from '../../types/generic'

export const DefaultApi = Symbol('DefaultApi')

// persist -> survives process restarts, process -> in memory just for the current process lifetime
export enum CacheType {
  Persist = 'persist',
  Process = 'process',
  Off = 'off'
}

export enum HttpMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH'
}

export type ClientStateQueues = {
  active: Array<RequestAction>
  latent: Array<RequestAction>
  fail: Array<ErrorAction>
  success: Array<SuccessAction<any>>
}

export type ClientState = {
  process: { [key: string]: CachedResource<any> }
  persist: { [key: string]: CachedResource<any> }
  off: { [key: string]: CachedResource<any> }
  queues: ClientStateQueues
}

export type ApiConfig = {
  getSessionId?: () => Promise<string | void | null> | string | void
  basePath: string
  onRequestFail: (error: RequestFail, action: ErrorAction) => void
  retryThreshold?: (retryCount: number) => number
  debug?: boolean
}

export type CachePolicy = {
  // sizeLimit: number,
  // sizeOfConsideration: number,
  channelLimits: { [key: string]: number }
}
type FormDataValue = boolean | number | string | File | undefined

export type ParamsGeneric = {
  id?: string | number // @TODO consider removing this special param and force it to go in meta
  formData?: { [index: string]: FormDataValue | Array<FormDataValue> | null }
  urlSearchParams?: { [index: string]: any }
  meta?: { [index: string]: any }
  urlSearchParamsKeyPrefix?: string
} | void

type RetryState = {
  count: number
  maxCount: number
}
export enum ActionTypes {
  ApiConfig = 'apiConfig',
  Request = 'apiRequest',
  Started = 'apiRequestStarted',
  Loading = 'apiRequestLoading',
  Error = 'apiRequestError',
  Success = 'apiRequestSuccess',
  ProcessLatent = 'processLatent',
  Reset = 'reset'
}
export type ApiConfigAction = {
  type: ActionTypes.ApiConfig
  api: any
  apiConfig: ApiConfig
}

export type ProcessLatentAction = {
  type: ActionTypes.ProcessLatent
}

export type ApiActionBase = {
  requestId: string
  key: string
  path: string
  resourceId: string
  timestamp: number
  cache: CacheType
  cursor?: any
}
export type RequestAction = ApiActionBase & {
  type: ActionTypes.Request
  method: HttpMethods
  params: ParamsGeneric
  dependency?: { requestId: string }
  dependentActions: Array<RequestAction>
  api?: any
  retry?: RetryState
}

type ResultActionBase = ApiActionBase & {
  response: Response | undefined
  status: number
  request: RequestAction
  cursorTerminal?: boolean
}
export type SuccessAction<Payload> = ResultActionBase & {
  type: ActionTypes.Success
  payload: Payload
}
export type ErrorAction = ResultActionBase & {
  type: ActionTypes.Error
  error: RequestFail
  terminal: boolean
}
export type ResultAction = SuccessAction<any> | ErrorAction
export type ApiAction = RequestAction | ResultAction
export type ResetAction = {
  type: ActionTypes.Reset
}

export type Refetch<Payload> = () => Promise<Payload>
export type ClientAction = ApiAction | ApiConfigAction | ProcessLatentAction | RehydrateAction | ResetAction

export type ReturnTuple<Payload> = [
  Payload | undefined,
  ErrorMeta | undefined,
  Promise<Payload> | undefined,
  CachedResource<Payload> | undefined,
  Refetch<Payload>
]
export type PagerControl<Payload> = {
  cursorRef: MutableRefObject<any>
  next: () => Promise<Payload>
  reset: () => Promise<Payload>
}
export type ReturnTuplePaged<Payload> = [
  Payload | undefined,
  ErrorMeta | undefined,
  Promise<Payload> | undefined,
  CachedResource<Payload> | undefined,
  PagerControl<Payload>
]

export interface ClientListener {
  (state: ClientState): void
}

export type ClientStore = Omit<Store<ClientState>, 'dispatch'> & {
  dispatch: <A extends ClientAction>(action: A) => A extends RequestAction ? Promise<any> : A
  [key: string]: any // @NOTE added to support test/dev only store extensions
}

export type ErrorMeta = {
  message: string
  status: number
  timestamp: number
}

// @TODO make CachedResource and CachedError more specific, we know exactly which properties need to be omit
export type CachedResource<Payload> = Partial<SuccessAction<Payload>> & {
  resourceId: string
  lastErrorMeta: ErrorMeta
}

export type CachedError = Partial<ErrorAction>

export type CacheClassifier = [string, number] // [bucket, priority]

export const NoBaseResourceSymbol = Symbol('NoBaseResource')

export type PagerConfig<Cursor, Payload = any> = {
  nextCursor(cursor: Cursor, payload?: Payload): Cursor
  applyCursor(cursor: Cursor): { urlSearchParams: GenericObject }
  isTerminal(cursor: Cursor, payload?: Payload): boolean
}

export type MethodOptionsBase<Payload, Params> = {
  path: string | ((params: Params) => string)
  key: string
  method?: HttpMethods
  retryCount?: number
  cache?: CacheType
  cacheClassifier?: CacheClassifier | ((request: CachedResource<any>) => CacheClassifier)
  localActionFilter?: (action: ApiAction) => boolean
  // @NOTE localActionMerge and onDependencySuccess must mutate, and return void. This is done to encourage good performance. Immutability is handled under the hood with immer.
  localActionMerge?: (state: Payload, action: ApiAction) => void
  onDependencySuccess?: (action: RequestAction, dependency: SuccessAction<Payload>) => void
  headers?: { [key: string]: string }
  pager?: undefined
}

export type MethodOptionsPaged<Payload, Params> = Omit<MethodOptionsBase<Payload, Params>, 'pager'> & {
  pager: PagerConfig<any, Payload>
}

export type MethodOptions<Payload, Params> = MethodOptionsBase<Payload, Params> | MethodOptionsPaged<Payload, Params>
