Handlebars     = require('handlebars/runtime').default
BaseView       = require('base/view')

ReactComponent  = require('src/ReactComponent').default
Integrations = require('src/components/users/integrations').default

class IntegrationsReactView extends BaseView
  template: => '<div class="integrations-react"></div>'

  regions:
    'integrations-react' : 'div.integrations-react'

  onRender: ->
    @showChildView('integrations-react', new ReactComponent({ component: Integrations }))

module.exports = IntegrationsReactView
