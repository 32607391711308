import { Feature, DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import { Refetch } from '@ringdna/client/src/constants'

export type KeyTabProps = {
  title: string
  key: string
}

export type SettingsToggleProps = {
  setting: string
  disabled?: boolean
  isChecked: boolean
  accountSettings?: { [key: string]: any }
  setAccountSettings?: (setting: { [key: string]: any }) => void
  featureVisibilities?: Feature[]
  setFeatureVisibilities?: (feature: Feature[]) => void
}

export type SaveProps = {
  accountSettings: { [key: string]: any }
  featureVisibilities: Feature[]
  refetchSettings: Refetch<DialerSettings, { meta: { id: string } }>
  activeTab: string
  dialerSettings: DialerSettings | undefined
}

export type MainProps = {
  accountId: number
}

export type TabProps = {
  accountSettings: { [key: string]: any }
  setAccountSettings: (setting: { [key: string]: any }) => void
} & MainProps

export type DispositionProps = {
  dispositions: Disposition[]
  refetchDispositions: Refetch<Disposition[], { meta: { accountId: string } }>
} & TabProps

export type VisibilitiesProps = {
  featureVisibilities: Feature[]
  setFeatureVisibilities: (feature: Feature[]) => void
} & TabProps

export type CallNotesTemplatesProps = {
  teams: Team[]
} & TabProps

export type SettingProps = {
  title: string
  description: string
}

export type DeleteModalProps = {
  open: boolean
  onClose: (isFetch: boolean) => void
  id: number
}

export type CallCustomLink = {
  label: string
  url: string
  id: number
}

export type CallNoteTemplate = {
  id: number
  name: string
  template: string
  enabled: boolean
  teams: CallNoteTeamTemplate[]
}

export type CallNoteTemplateForm = {
  id: number
  name: string
  template: string
  enabled: boolean
  teamIds: number[]
}

export type Team = {
  id: number
  name: string | null
}

export type OptionTeam = {
  value: number
  text: string
}

export type CallNoteTeamTemplate = {
  id: number
  team: Team
}

export enum TeamDefaultFilterType {
  ALL = 0
}

export type CallScript = {
  id: number
  name: string
  description: string
  data: string
  entityIdsAdd: number[]
  entityIdsDelete: number[]
}

export type CallScriptEntity = {
  id: number
  name: string
  source: string
  enabled: boolean
  originalEnabled: boolean
  number: string
  smartNumber: {
    number: string
  }
  resources: [{
    id: number
    type: string
  }]
}

export type Disposition = {
  id: number
  disposition: string
  outcomeCategory: string | null
  inbound: number
  outbound: number
  teams: CallDispositionTeam[]
}

export type OptionCategory = {
  value: string
  label: string
}

export type CallDispositionTeam = {
  id: number
  name: string
  callDispositionTeamId: number | undefined
  inbound: boolean
  outbound: boolean
}

export type DispositionSetting = {
  id: number | undefined
  team: {
    id: number
  }
  inbound: boolean
  outbound: boolean
}
