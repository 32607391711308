import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function CallFlowsIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="call-flow-icon">
      <path d="M9.55469 12H14.3186" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.51157 12.7614C7.93218 12.7614 8.27315 12.4205 8.27315 11.9999C8.27315 11.5792 7.93218 11.2383 7.51157 11.2383C7.09097 11.2383 6.75 11.5792 6.75 11.9999C6.75 12.4205 7.09097 12.7614 7.51157 12.7614Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4217 12.7614C16.8423 12.7614 17.1833 12.4205 17.1833 11.9999C17.1833 11.5792 16.8423 11.2383 16.4217 11.2383C16.0011 11.2383 15.6602 11.5792 15.6602 11.9999C15.6602 12.4205 16.0011 12.7614 16.4217 12.7614Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4217 7.68916C16.8423 7.68916 17.1833 7.3482 17.1833 6.92759C17.1833 6.50698 16.8423 6.16602 16.4217 6.16602C16.0011 6.16602 15.6602 6.50698 15.6602 6.92759C15.6602 7.3482 16.0011 7.68916 16.4217 7.68916Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4217 17.8317C16.8423 17.8317 17.1833 17.4908 17.1833 17.0702C17.1833 16.6496 16.8423 16.3086 16.4217 16.3086C16.0011 16.3086 15.6602 16.6496 15.6602 17.0702C15.6602 17.4908 16.0011 17.8317 16.4217 17.8317Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.54297 9.74718C7.54297 8.9694 7.86704 8.27264 8.36936 7.75412C8.87167 7.2356 9.58464 6.92773 10.3624 6.92773H14.2513"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2675 17.0714H10.3786C9.60084 17.0714 8.90408 16.7473 8.38556 16.245C7.86704 15.7265 7.54297 15.0297 7.54297 14.252"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5234 7.6569L14.2688 6.92773"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2688 6.92701L13.5234 6.18164"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5703 12.7272L14.3157 11.998"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3157 11.9987L13.5703 11.2695"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5234 17.8157L14.2688 17.0703"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2688 17.071L13.5234 16.3418"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
