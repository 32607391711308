BaseModel = require('base/model')

module.exports = class CsvUserModel extends BaseModel

  defaults:
    accountId             : 0
    assignNumber          : false
    email                 : ''
    errorDesc             : null
    isoCountry            : ''
    pattern               : ''
    dialerLicense         : false
    adminLicense          : false
    conversationAILicense : false
    guidedSellingLicense  : false
    yodaAILicense         : false
    calendarBookingLicense: false
    sfUserId              : ''
    location	            : null
