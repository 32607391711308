AbilityUtils = require('../../../common/src/utils/abilityUtils')

# This class is a temporary shim to store the permissions per role. Once we have RBAC implemented on the server we
# should pull the list of abilities straight from the server and ditch role entirely.
class AbilityService
  constructor: ->
    @abilities = AbilityUtils.getAbilitiesForRole({
      admin: false,
      calendar: false,
      intelligentDialer: false,
      conversationAI: false,
      guidedSelling: false,
      moments: false,
      supervisor: false,
      support: false
    })

  setup: (roles, abilities) ->
    @abilities = AbilityUtils.getAbilitiesForRole(roles, abilities)

  can: (action) ->
    @abilities[action] or false

module.exports = AbilityService
