BaseView = require('base/view')

class CalloutStepView extends BaseView

  template: require('./template')

  events:
    'click a[data-action="add-callout-input"]'     : 'addApexInput'
    'click a[data-action="remove-callout-input"]'  : 'removeApexInput'

  initialize: (options) ->
    @updateViewData(@getApexInputs())

  addApexInput: ->
    calloutList = @getApexInputs()
    calloutList.push(
      {
        input: ''
        value: ''
      }
    )
    @updateViewData(calloutList)
    @render()

  getApexInputs: ->
    calloutInputsList = @model.args().calloutInputs?.split(',')
    calloutValuesList = @model.args().calloutValues?.split(',')
    for key, value of calloutInputsList
      {
      input: value
      value: calloutValuesList[key]
      }

  updateViewData: (calloutList) ->
    @model.set(
      firstCallout: calloutList.shift()
      calloutList: calloutList
    ) if calloutList.length > 0

  removeApexInput: (e) ->
    value = $(e.target).parent().parent().find('.calloutInputs').val()
    listApex = (apex for apex in @getApexInputs() when apex.input isnt value)
    @model.args().calloutInputs = (apex.input for apex in listApex).join()
    @model.args().calloutValues = (apex.value for apex in listApex).join()
    @updateViewData(listApex)
    @render()

module.exports = CalloutStepView