import React from 'react'
import { StyledTableCell, ArrowUp, ArrowDown } from './styles'

export type OrderProps = 'asc' | 'desc'

export type OrderByProps = {
  key: string
  order: OrderProps
}

export type TableCellProps = {
  children?: React.ReactNode
  setSortBy?: React.Dispatch<React.SetStateAction<OrderByProps | undefined>>
  sortKey?: string
  sortBy?: OrderByProps
  width?: string
  flex?: number
  className?: string
  isCentered?: boolean
}

export const RdnaTableCell = ({
  children,
  width,
  flex = 1,
  isCentered,
  sortKey,
  setSortBy,
  sortBy,
  className
}: TableCellProps) => {
  const onSort = () => {
    if (setSortBy && sortKey) {
      let order: OrderProps = 'asc'
      if (sortBy && sortBy.key === sortKey && sortBy.order === 'asc') order = 'desc'
      setSortBy({ key: sortKey, order })
    }
  }

  const displayArrow = () =>
    sortBy && sortBy.key === sortKey ? sortBy.order === 'desc' ? <ArrowDown /> : <ArrowUp /> : null

  const sortProps = setSortBy ? { onClick: onSort } : {}

  return (
    <StyledTableCell
      className={`ringdna-table-cell ${className || ''} ${isCentered ? 'centered' : ''}`}
      {...sortProps}
      style={width ? { width } : { flex }}
    >
      {children}
      {displayArrow()}
    </StyledTableCell>
  )
}
