import React, { useEffect, useState } from 'react'

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { Action, PackageCheck, useUpdateSalesforceInspector } from '../../../../store/api/salesforceInspector-api'
import { ActionType, useSupportSettingsDispatch, useSupportSettingsState } from '../../utils/contextReducer'
import { usePubNub } from '../../../contexts/PubNubContext'
import { PubNubChannels, MessageEvent } from '../../../contexts/PubNubContext'

export default React.memo(SalesforcePackageCheckButton)
function SalesforcePackageCheckButton() {
  const state = useSupportSettingsState()
  const [isLoading, setIsLoading] = useState(false)
  const updateSalesforceInspector = useUpdateSalesforceInspector()
  const dispatch = useSupportSettingsDispatch()
  const { pubnub } = usePubNub()

  useEffect(() => {
    const listener = {
      message: (e: MessageEvent) => {
        if (e.userMetadata === PubNubChannels.PACKAGE_UPDATE) {
          const {
            accountId,
            advancedSolutionPackageVersion,
            cadencePackageVersion,
            hotLeadsPackageVersion,
            message,
            packageVersion
          } = e.message as PackageCheck
          if (accountId === state.accountSettings['account.id']) {
            dispatch({
              type: ActionType.CHANGE_ACCOUNT_SETTINGS,
              payload: {
                'account.advancedSolutionPackageVersion': advancedSolutionPackageVersion,
                'account.cadencePackageVersion': cadencePackageVersion,
                'account.hotLeadsPackageVersion': hotLeadsPackageVersion,
                'account.packageVersion': packageVersion
              }
            })
          }
          toastr.info(message, 'Salesforce Inspector')
        }
      }
    }
    pubnub.addListener(listener)
    return () => {
      pubnub.removeListener(listener)
    }
  }, [dispatch, pubnub, state.accountSettings])

  const onRun = async () => {
    try {
      setIsLoading(true)
      await updateSalesforceInspector({
        body: {
          action: Action.Package,
          accountId: state.accountSettings['account.id']
        }
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <RdnaButton
      variant="contained"
      text={isLoading ? 'Running...' : 'Run'}
      color="action"
      onClick={onRun}
      disabled={isLoading}
    />
  )
}
