type FormDataValue = boolean | number | string | File | undefined
type JSObjectFormData = { [index: string]: FormDataValue | Array<FormDataValue> | null }

export default function prepareFormData(body: JSObjectFormData): FormData {
  const fd = new FormData()

  Object.entries(body).map(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(v => fd.append(`${key}[]`, String(v)))
    } else if (value instanceof File) {
      fd.append(key, value)
    } else if (value == null) {
      fd.append(key, '')
    } else {
      fd.append(key, String(value))
    }
  })
  return fd
}
