BaseView = require('base/view')

class BlockedNumberItemView extends BaseView

  template: require('./item-template')

  bindings:
    '.account-id'      : 'text:accountId'
    '.blocked-number'  : 'text:number'
    '.message'         : 'text:message'
    '.status'          : 'text:status'
    '.type'            : 'text:type'

  className: =>
    if @model.get('status') is 'Failed'
      'row number-item failed'
    else
      'row number-item'

module.exports = BlockedNumberItemView
