import React, { useEffect, useState, useMemo } from 'react'
import RdnaForm from '../RdnaFormElements'
import RdnaButton from '../RdnaButton'
import { useAtom, useAtomValue } from 'jotai'
import { filtersAtom, userDataAtom } from './index'
import getInputByName from './FilterInputs'
import RdnaGrid from '../RdnaGrid'
import {
  agentHiddenFilters,
  caiRecordingVisibilityHiddenFilters,
  getBlankValues,
  getPersistedFilters,
  mapFilterName,
  setPersistedFilters
} from './utils/inputUtils'
import { FilterFormValues, ReportFilterType } from './types'
import { partition } from 'lodash-es'
import styled from 'styled-components'
import DrawerFilters from './DrawerFilters'

type Props = {
  filters: ReportFilterType[]
  reportName?: string
  maxFiltersShow?: number
  defaultFilterValues?: FilterFormValues
}

type AdditionalFiltersProps = {
  numAppliedFilters: number
  onClick: () => void
}

const AdditionalFiltersButton = ({ numAppliedFilters, onClick }: AdditionalFiltersProps) => {
  return (
    <TriggerButton
      text={`+${numAppliedFilters === 0 ? '' : numAppliedFilters} Additional Filters`}
      onClick={(event: MouseEvent) => {
        event.preventDefault()
        onClick()
      }}
      variant={'outlined'}
      color={'neutral'}
    />
  )
}

const ReportFilters = ({ filters, reportName, maxFiltersShow, defaultFilterValues }: Props) => {
  const [filterValues, setFilters] = useAtom(filtersAtom)
  const userData = useAtomValue(userDataAtom)
  const accountId = userData?.accountId
  const [numAdditionalFiltersApplied, setNumAdditionalFiltersApplied] = useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)
  let isOnlyAgent = !userData?.licenses.admin && !userData?.licenses.support && !userData?.licenses.supervisor
  if (isOnlyAgent && userData?.caiRecordingVisibility && userData.caiRecordingVisibility !== 'User') {
    isOnlyAgent = false
  }
  const isNotCaiRecordingVisibilityAll = userData?.caiRecordingVisibility && userData.caiRecordingVisibility !== 'All'
  const persistedValues: FilterFormValues = getPersistedFilters(filters)
  const blankValues: FilterFormValues = getBlankValues(filters, isOnlyAgent, userData)
  const defaultValues = { ...blankValues, ...persistedValues, ...defaultFilterValues, name: reportName }

  const onSubmit = (data: FilterFormValues) => {
    setPersistedFilters(data)
    setFilters(data)
  }

  useEffect(() => {
    if (userData) {
      setFilters(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, reportName])

  // not much gating logic but if more than 3 should make a util method for hidden filters
  const [hiddenFilters, shownFilters] = partition(
    filters,
    filter =>
      (agentHiddenFilters.includes(filter) && isOnlyAgent) ||
      (caiRecordingVisibilityHiddenFilters.includes(filter) && isNotCaiRecordingVisibilityAll)
  )

  const [pageFilters, drawerFilters] = useMemo(
    () =>
      maxFiltersShow ? [shownFilters.slice(0, maxFiltersShow), shownFilters.slice(maxFiltersShow)] : [shownFilters, []],
    [shownFilters, maxFiltersShow]
  )

  const additionalButtons = drawerFilters.length
    ? [
        <AdditionalFiltersButton
          key={numAdditionalFiltersApplied}
          numAppliedFilters={numAdditionalFiltersApplied}
          onClick={() => setDrawerOpen(true)}
        />
      ]
    : []

  useEffect(() => {
    let nonBlankFilters = 0
    for (const filterName of drawerFilters) {
      const mappedName = mapFilterName(filterName)
      // @ts-ignore
      if (filterValues[mappedName] !== blankValues[mappedName] && filterValues[mappedName]?.length) {
        nonBlankFilters++
      }
      setNumAdditionalFiltersApplied(nonBlankFilters)
    }
  }, [filterValues, blankValues, drawerFilters])

  return (
    <>
      {userData && (
        <RdnaForm mode="all" defaultValues={defaultValues} onValid={onSubmit}>
          {({ handleSubmit, getValues, setValue, reset }, { RdnaFormController, getMuiProps }) => {
            const resetForm = () => {
              reset(blankValues)
              setFilters(blankValues)
              setPersistedFilters(blankValues)
              setDrawerOpen(false)
            }
            return (
              <>
                <RdnaGrid
                  columns={drawerFilters.length ? 4 : 3}
                  items={[
                    ...pageFilters.map(filter => {
                      return (
                        <div key={filter}>
                          <RdnaFormController
                            name={mapFilterName(filter)}
                            render={props =>
                              getInputByName(filter, getMuiProps(props).value, getMuiProps(props).onChange, setValue)
                            }
                          />
                        </div>
                      )
                    }),
                    ...additionalButtons
                  ]}
                />
                {!!drawerFilters.length && (
                  <DrawerFilters
                    filters={drawerFilters}
                    open={drawerOpen}
                    blankValues={blankValues}
                    onClose={() => setDrawerOpen(false)}
                    onReset={resetForm}
                    onSubmit={data => {
                      setDrawerOpen(false)
                      for (const field in data) {
                        // @ts-ignore
                        setValue(field as ReportFilterType, data[field])
                      }
                      const newData = { ...filterValues, ...data }
                      onSubmit(newData)
                    }}
                    defaultValues={getValues()}
                  />
                )}
                {[...hiddenFilters, ...drawerFilters].map(filter => {
                  return (
                    <div key={filter}>
                      <RdnaFormController
                        name={mapFilterName(filter)}
                        render={props => <input type={'hidden'} name={filter} value={getMuiProps(props).value} />}
                      />
                    </div>
                  )
                })}
                <FormButtons>
                  <RdnaButton
                    type="submit"
                    text="Refresh"
                    data-testid={'submit-report-filters'}
                    onClick={handleSubmit}
                  />
                  <RdnaButton
                    type="button"
                    text="Clear All"
                    data-testid={'clear-report-filters'}
                    onClick={resetForm}
                    variant={'outlined'}
                  />
                </FormButtons>
              </>
            )
          }}
        </RdnaForm>
      )}
    </>
  )
}

export default ReportFilters

const FormButtons = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
  gap: ${({ theme }) => theme.spacing * 2}px;
  display: flex;
`

const TriggerButton = styled(RdnaButton)`
  margin-top: ${props => props.theme.spacing}px;
`

export const ButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`
