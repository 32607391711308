/**
 * @deprecated for `RdnaFormElements/RdnaDatePicker`.
 */
import React from 'react'
import { LocalizationProvider, MobileDatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { InputComponentProps } from '../../../types/form-inputs'
import { isDate } from 'date-fns'
import styled from 'styled-components'
import RdnaTextInput from '../../RdnaFormElements/RdnaTextInput'

type Props = InputComponentProps & Pick<DatePickerProps<string, Date>, 'minDate' | 'maxDate' | 'value' | 'onChange'>

const RdnaDatePicker = ({
  inputData,
  value,
  isError,
  errorMessage,
  onChange,
  minDate,
  maxDate
}: Props): JSX.Element => {
  const parsedValue = inputData.placeholder || isDate(value) ? value : new Date(value as string)
  return (
    <div className={inputData.className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDatePicker
          inputFormat="MM/dd/yyyy"
          label={inputData.label}
          value={parsedValue}
          renderInput={props => (
            <RdnaTextInput
              {...props}
              variant="outlined"
              margin="dense"
              id={inputData.name}
              required={inputData.required}
              name={inputData.name}
              placeholder={inputData.placeholder}
              helperText={isError ? errorMessage : inputData.helperText}
              error={isError}
              data-testid={`${inputData.name}-input`}
            />
          )}
          onChange={value => {
            onChange(inputData.name, value)
          }}
          key={inputData.name}
          minDate={minDate}
          maxDate={inputData.disableFuture ? new Date() : maxDate}
        />
      </LocalizationProvider>
    </div>
  )
}

export default RdnaDatePicker
const StyledDatePicker = styled(MobileDatePicker)`
  .MuiInputBase-input {
    color: ${props => (props.value === null ? props.theme.palette.neutral.main : 'currentColor')};
  }
`
