import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function CallForwarding({ size = 24, color = Colors.N100, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="call-forwarding-icon">
      <path
        d="M13.018 8.5c.189-.53.38-.89.493-1.08a.438.438 0 01.283-.199l1.725-.395a.5.5 0 00.389-.488V3.423a.5.5 0 00-.354-.479l-2.841-.868a.457.457 0 00-.446.095c-.556.51-2.16 2.17-3.105 5.092-.126.39-.24.802-.338 1.237m-.057 6.738c.11.532.244 1.032.395 1.499.944 2.922 2.549 4.582 3.105 5.092.12.11.29.142.446.095l2.84-.869a.5.5 0 00.355-.478v-2.916a.5.5 0 00-.389-.487l-1.725-.395a.438.438 0 01-.283-.2 6.52 6.52 0 01-.58-1.341"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M3 11.821h17.778M19.074 10.056L21 11.82M21 11.821l-1.926 1.766"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default CallForwarding
