BaseModel = require('base/model')

class SalesforceUserModel extends BaseModel
  defaults:
    adminLicense: false
    dialerLicense: false
    calendarLicense: false
    gsLicense: false
    caiLicense: false
    momentsLicense: false
    areaCode: ''
    city: ''
    country: ''
    email: ''
    extensionCode: ''
    locationId: ''
    name: ''
    number: ''
    profile: ''
    salesforceId: ''
    title: ''
    userId: ''

module.exports = SalesforceUserModel
