import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const SvgPause = ({ size = 48, color = Colors.P50, title = 'pause-button' }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <title>{title}</title>
    <path
      d="M24 47.125C11.248 47.125.875 36.752.875 24S11.248.875 24 .875 47.125 11.248 47.125 24 36.752 47.125 24 47.125z"
      stroke={color}
    />
    <rect x={14.375} y={16.063} width={5.75} height={15.875} rx={2.875} fill={color} stroke={color} />
    <rect x={27.875} y={16.063} width={5.75} height={15.875} rx={2.875} fill={color} stroke={color} />
  </svg>
)

export default SvgPause
