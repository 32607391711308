BaseView          = require('base/view')
ProductConstant   = require('@ringdna/common/src/constants/products')
ReactComponent    = require('src/ReactComponent').default
TCPA              = require('src/components/tcpa').default

class TCPAView extends BaseView
  template: require('./template')
  className: 'tcpa-view'

  regions:
    container  : '.tcpa'

  initialize: (options) =>
    { @accountId, @qs } = options

  onRender: =>
    return unless App.featureFlag.isFlagged(App.featureFlag.flags.v294_ID_3029, false)
    @showChildView('container',
      new ReactComponent({
        component: TCPA,
        theme: ProductConstant.Products.ADMIN,
        props: { selectedAccountId: @accountId }
      })
    )

module.exports = TCPAView
