import React, { useCallback } from 'react'

// utils
import { CallRecordingSetting } from '@ringdna/common/src/types/call-recording'
import { useUser } from '../../../common/services/UserContext'
import {
  isCallRecordingDisabledSelector,
  isOutboundCallRecordingAnnouncementDisabledSelector,
  recordCallOptions,
  useCallRecordingDispatch,
  useCallRecordingState
} from '../../utils/contextReducer'

// components
import { RdnaTableCell } from '@ringdna/common/src/components/RdnaTable'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { StyledRow } from '../styles'

type InternationalTableRowsProps = {
  country: CallRecordingSetting
}

export default React.memo(InternationalTableRows)
export function InternationalTableRows({ country: { setting, name } }: InternationalTableRowsProps) {
  const recordTypeKey = `items[${setting.countryId - 1}].recordType`
  const outboundAnnouncementKey = `items[${setting.countryId - 1}].outboundAnnouncement`

  return (
    <StyledRow>
      <RdnaTableCell>
        <RecordCallsOption recordTypeKey={recordTypeKey} />
      </RdnaTableCell>
      <RdnaTableCell>
        <PlayOutboundRecordingToggle outboundAnnouncementKey={outboundAnnouncementKey} />
      </RdnaTableCell>
      <RdnaTableCell>{name}</RdnaTableCell>
    </StyledRow>
  )
}

type RecordCallsToggleProps = {
  recordTypeKey: string
}

const RecordCallsOption = React.memo(function Component({ recordTypeKey }: RecordCallsToggleProps) {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()

  return (
    <RdnaSelect
      options={recordCallOptions}
      value={state.continentRecordingSettings[recordTypeKey]}
      disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
      onChange={useCallback(
        // @ts-ignore
        event =>
          dispatch({
            type: 'CHANGE_CONTINENT_RECORDING_SETTINGS',
            payload: { [recordTypeKey]: event.target.value as string }
          }),
        [dispatch, recordTypeKey]
      )}
    />
  )
})

type PlayOutboundRecordingToggleProps = {
  outboundAnnouncementKey: string
}

const PlayOutboundRecordingToggle = React.memo(function Component({
  outboundAnnouncementKey
}: PlayOutboundRecordingToggleProps) {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()

  return (
    <RdnaToggleSwitch
      disabled={!isEditingAllowed || isOutboundCallRecordingAnnouncementDisabledSelector(state)}
      value={state.continentRecordingSettings[outboundAnnouncementKey]}
      onChange={useCallback(
        (checked: boolean) =>
          dispatch({
            type: 'CHANGE_CONTINENT_RECORDING_SETTINGS',
            payload: {
              [outboundAnnouncementKey]: checked
            }
          }),
        [dispatch, outboundAnnouncementKey]
      )}
      margin={'none'}
    />
  )
})
