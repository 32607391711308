import React, { useState, useCallback, useEffect, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { Colors } from '../constants/colors'

type Props = {
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  height?: number
  color?: Colors
  disabled?: boolean
  trigger?: any
  position?: 'top' | 'bottom'
}

export default function OverflowFade({
  height = 50,
  color,
  disabled,
  containerRef,
  position = 'bottom',
  trigger
}: Props) {
  const [maskOpacity, setMaskOpacity] = useState(0)
  const { palette } = useTheme()
  const maskColor = color || palette.white

  const onContainerChange = useCallback(() => {
    if (!containerRef.current || disabled) return
    const { clientHeight, scrollTop, scrollHeight } = containerRef.current
    const distFromBottom = scrollHeight - scrollTop - clientHeight
    const distFromEdge = position === 'bottom' ? distFromBottom : scrollTop
    // Scroll distance over which fade applies -- this feels like a good default
    const distanceThreshold = height / 4
    const newMaskOpacity = Math.min(1, distFromEdge / distanceThreshold)
    setMaskOpacity(newMaskOpacity)
  }, [disabled, height, containerRef, position])

  useEffect(() => {
    const ref = containerRef.current
    if (!ref) return
    ref.addEventListener('scroll', onContainerChange)
    return () => {
      ref.removeEventListener('scroll', onContainerChange)
    }
  }, [onContainerChange, containerRef])

  useEffect(() => {
    onContainerChange()
  }, [trigger, onContainerChange])

  return (
    <Fade
      color={maskColor}
      $position={position}
      style={{
        opacity: maskOpacity,
        height
      }}
    />
  )
}

const Fade = styled.div<{ color: Colors; $position: 'top' | 'bottom' }>`
  position: absolute;
  pointer-events: none;
  right: 0;
  left: 0;
  ${({ $position, color }) => {
    const stops =
      $position === 'bottom' ? `rgba(255, 255, 255, 0.001), ${color}` : `${color}, rgba(255, 255, 255, 0.001)`
    return `
    background-image: linear-gradient(${stops});
    ${$position}: 0;
  `
  }}
`
