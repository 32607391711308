import React from 'react'
import RdnaModal, { RdnaModalProps } from '@ringdna/common/src/components/RdnaModal'

const MODAL_CONTENT = {
  heading: 'Activate country by country international call recording controls',
  content:
    'International Call Recording Features for this account are currently disabled. To activate country by country international call recording controls, select "Accept."'
}

const InternationalConfirmModal = (props: Omit<RdnaModalProps, 'children'>) => {
  return (
    <RdnaModal {...props} heading={MODAL_CONTENT.heading} confirmButtonText="Accept">
      {MODAL_CONTENT.content}
    </RdnaModal>
  )
}

export default InternationalConfirmModal
