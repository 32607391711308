/**
 * @deprecated for `RdnaFormElements/RdnaSlider`.
 */
import React, { SyntheticEvent, useEffect, useState } from 'react'
import Slider from '@mui/material/Slider'
import { InputComponentProps, InputField } from '../../../types/form-inputs'
import { FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material'

interface Mark {
  value: number
  label?: React.ReactNode
}

const createMarks = (inputData: InputField, values: number | number[]): Mark[] => {
  const marks: Mark[] = []
  if (Array.isArray(values) && values.length > 1) {
    if (inputData.minValue !== undefined) {
      marks.push({
        value: inputData.minValue.value,
        label: values[0] === inputData.minValue.value && inputData.minValue.label ? inputData.minValue.label : values[0]
      })
    }

    if (inputData.maxValue !== undefined) {
      marks.push({
        value: inputData.maxValue.value,
        label: values[1] === inputData.maxValue.value && inputData.maxValue.label ? inputData.maxValue.label : values[1]
      })
    }
  }
  return marks
}

const RdnaSlider = ({ inputData, value, isError, errorMessage, onChange }: InputComponentProps) => {
  const [range, setRange] = useState<number[]>((value as number[]) || 0)
  const [marks, setMarks] = useState<Mark[]>(createMarks(inputData, value as number[]))
  const updateInput = (newValue: number | number[]) => {
    setRange(newValue as number[])
    setMarks(createMarks(inputData, newValue))
  }

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    updateInput(newValue)
  }

  useEffect(() => {
    if (value !== range) {
      updateInput(value as number[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleRangeChangeCommit = (event: Event | SyntheticEvent, newValue: number | number[]) => {
    onChange(inputData.name, newValue as number[])
  }

  const getAriaLabel = (value: number) => {
    return `range-slider-${value}`
  }

  return (
    <FormControl
      component="fieldset"
      className={inputData.className}
      data-testid={`range-slider.container-${inputData.name}`}
    >
      {inputData.label && (
        <FormLabel component="legend">
          {inputData.label}
          {inputData.required ? '*' : ''}
        </FormLabel>
      )}
      <FormGroup>
        <Slider
          value={range || 0}
          onChange={handleRangeChange}
          min={inputData.minValue && inputData.minValue.value}
          max={inputData.maxValue && inputData.maxValue.value}
          onChangeCommitted={handleRangeChangeCommit}
          marks={marks}
          getAriaLabel={getAriaLabel}
          disabled={inputData.disabled}
        />
      </FormGroup>
      <FormHelperText>{isError ? errorMessage : inputData.helperText}</FormHelperText>
    </FormControl>
  )
}

export default RdnaSlider
