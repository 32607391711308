BaseView          = require('base/view')
ProductConstant   = require('@ringdna/common/src/constants/products')
ReactComponent    = require('src/ReactComponent').default
CallActions       = require('src/components/call-actions').default

class CallActionsView extends BaseView
  template: require('./template')
  className: 'call-actions-view'

  regions:
    container  : '.call-actions'

  initialize: (options) =>
    { @accountId, @qs } = options

  onRender: =>
    return unless App.featureFlag.isFlagged(App.featureFlag.flags.CALL_ACTIONS_REACT, false)
    @showChildView('container',
      new ReactComponent({
        component: CallActions,
        theme: ProductConstant.Products.ADMIN,
        props: { accountId: @accountId }
      })
    )

module.exports = CallActionsView
