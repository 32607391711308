var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-title\">\n  <div class=\"title-left\">\n    <h1>Account : <span style=\"color: #777\" class=\"account-name\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":65},"end":{"line":3,"column":73}}}) : helper)))
    + "</span></h1>\n  </div>\n  <div class=\"navbar-right\">\n    <button class=\"ladda-button btn btn-success save\">Save</button>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n\n<ul class=\"nav nav-tabs\">\n  <li><a data-tab=\"overview\">Overview</a></li>\n  <li><a data-tab=\"licensing\">Licensing</a></li>\n  <li><a data-tab=\"logs\">Logs</a></li>\n</ul>\n\n<div class=\"x-panel\">\n  <div class=\"content-tabs\"></div>\n</div>\n";
},"useData":true});