BackgridCell = require('helpers/backgrid-cell')
BaseGrid = require('base/grid')
BaseView = require('base/view')

class CallArchiverCollection extends Backbone.Collection
  url: 'archiver'

class CallArchiverView extends BaseView
  template: require('./template')
  className: 'call-archiver-list'

  regions:
    grid: 'div.grid'

  onBeforeRender: =>
    @collection = new CallArchiverCollection()
    @collection.fetch(data: {accountId: @getAccountORNull()}).done(=> @showGrid())

  showGrid: =>
    callCell = BackgridCell.href(
      url   : '#calls/:callKey',
      label : ':callKey',
      attrs : ['callKey'])

    datetime = BackgridCell.dateTime()

    @cols = [
      { label: 'ID',         name: 'id',         cell: 'string' }
      { label: 'Call Key',   name: 'callKey',    cell: callCell }
      { label: 'Exception',  name: 'exception',  cell: 'string' }
      { label: 'Message',    name: 'message',    cell: 'string' }
      { label: 'Timestamp',  name: 'createdAt',  cell: datetime }
      { label: 'Resolved',   name: 'resolved',   cell: 'boolean' }
      { label: '',           name: '',           cell: BaseGrid.EditCell }
    ]

    @showChildView('grid', new BaseGrid.Grid(
      collection : @collection
      columns    : @cols
      emptyText  : 'No call archiver errors found.'
      onEdit     : @runArchiver
    ))

  runArchiver: (options) =>
    {model} = options
    model.save()

module.exports = CallArchiverView
