class CreatingSmartNumberModal extends Backbone.Modal
  submitEl: '.done'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1-view')
    'click #step2':
      view: require('./step-2-view')
    'click #step3':
      view: require('./step-3-view')
    'click #step4':
      view: require('../step-provision-view')

  events:
    'keyup .stepNext': 'keyPressedNext'
    'keyup .stepPrevious': 'keyPressedPrevious'
    'click .previous': 'previousStep'
    'click .next': 'nextStep'

  initialize: (options) ->
    { @accountId, @mainModal } = options
    @checkKey = (e) =>
      if @active
        switch e.keyCode
          when 27
            @triggerCancel(e)

  keyPressedPrevious: (e) =>
    @previousStep(e) if e.keyCode is 13

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  previousStep: (e) =>
    e.preventDefault()
    if @currentIndex is 0
      @mainModal.showFirstModal()
    else if @currentIndex is 3
      @openAt(1)
    else
      @previous()

  nextStep: (e) ->
    e.preventDefault()
    if @currentIndex is 0 and @model.get('searchTerm')
        if @model.get('searchTerm').length < 2
          toastr.warning('Digits you want must have at least two characters.')
        else if @model.get('searchTerm').startsWith('*')
          toastr.warning("Digits you want can't starts with *.")
        else @next()
    else if @currentIndex is 1 and not (@model.get('addressLocalRequired') or @model.get('addressForeignRequired') or @model.get('addressAnyRequired'))
      @openAt(3)
    else if @currentIndex is 2
      @currentView.saveAddress( =>
        @currentIndex = 1
        @nextStep(e)
      )
    else
      @next()

  beforeSubmit: =>
    selectedSmartNumbers = @model.get('selectedNumbers')
    if selectedSmartNumbers.unprovisionedNumbers().length < 1
      @trigger('assigned', selectedSmartNumbers.provisionedNumbers())
      @destroy()
      return false
    selectedSmartNumbers.setParams(
      accountId : @model.get('accountId'),
      country   : @model.get('country'),
      objectId  : @model.get('objectId'),
      type      : @model.get('type')
    )
    selectedSmartNumbers
      .create(ladda: @$('.done')[0])
      .done((response) =>
        if (number for number in response when number.status is 'Failed').length
          @model.set('retry': true)
          @model.get('selectedNumbers').reset(response)
          @openAt(3)
          return
        @trigger('assigned', @model.get('selectedNumbers'))
        @destroy()
      )
    false

module.exports = CreatingSmartNumberModal
