import React from 'react'
import { Methodology } from '../types'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE } from '../const'
import styled from 'styled-components'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { methodologiesColumns } from "../columns";

export type MethodologiesPanelProps = {
  open: boolean
  onClose: () => void
  methodologies: Methodology[]
}

export default function MethodologiesPanel({ open, onClose, methodologies }: MethodologiesPanelProps) {
  const requestMethodology = async () => {
    window.location.href = '#product-portal'
  }

  const HeaderDrawer = React.memo(() => {
    return (
      <RdnaHeaderSection
        headingText={`Methodologies`}
        rightColumn={
          <RdnaButton text={'Request New Methodology'} variant="outlined" onClick={requestMethodology}></RdnaButton>
        }
      />
    )
  })

  return (
    <RoleDrawer open={open} heading={<HeaderDrawer />} onClose={onClose} onBack={onClose}>
      <RdnaSmartTable
        searchPlaceholder="Search"
        //@ts-ignore
        columns={methodologiesColumns}
        data={methodologies}
        noResultState={NO_RESULT_STATE}
        paginationSize={DEFAULT_PAGE_SIZE}
        rowHeight={'100%'}
      />
    </RoleDrawer>
  )
}

const RoleDrawer = styled(RdnaDrawer)`
  > div {
    width: 70%;
  }
  h4 {
    display: block;
  }
`
