import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function CallEfficiencyIcon({ color = Colors.N100, size = 60 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="call-efficiency-icon">
      <path d="M30.5769 9.92308V49.1539" stroke={color} strokeLinecap="round" />
      <path d="M49.0385 9.92308V49.1539" stroke={color} strokeLinecap="round" />
      <path d="M12.1154 9.92308V49.1539" stroke={color} strokeLinecap="round" />
      <circle cx={12.1154} cy={20.3077} r={5.26923} fill={Colors.WHITE} stroke={color} />
      <circle cx={49.0385} cy={20.3077} r={5.26923} fill={Colors.WHITE} stroke={color} />
      <circle cx={30.577} cy={38.7692} r={5.26923} fill={Colors.WHITE} stroke={color} />
    </svg>
  )
}
