StepModel = require('../../model')

class ConferenceStepModel extends StepModel

  defaults:
    args: {}
    type: 'Conference'
    info: 'Start a conference call with two or more callers (max 40)'
    leaf: true

  holdMusicType: ->
    @args()['holdMusicType']

  moderatorId: ->
    @args()['moderatorId']

  moderatorType: ->
    @args()['moderatorType']

  errors: ->
    messages = []
    selectors = []

    audioErrors = @audioErrors(['audio']) if not @holdMusicType() or @holdMusicType() == ''
    errors = _.zip(messages, selectors)
    if audioErrors?.length > 0
      errors = _.union(errors, audioErrors)

    errors

module.exports = ConferenceStepModel