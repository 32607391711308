import { RdnaAccount } from '@ringdna/common/src/types/accounts'
import { AccountDialerSettings } from '../components/support-settings/utils/types'

export function parseAccountSettings(account: RdnaAccount): AccountDialerSettings {
  const accountSettings = {
    'account.id': account.id,
    'account.defaultPhoneNumber': account.defaultPhoneNumber,
    'account.name': account.name,
    'account.alias': account.alias,
    'account.packageName': account.packageName,
    'account.packageVersion': account.packageVersion,
    'account.primaryEmail': account.primaryEmail,
    'account.sfAccountId': account.sfAccountId,
    'account.sfOrgId': account.sfOrgId,
    'account.type': account.type
  }

  Object.keys(account.settings).forEach((key) => {
    const playKey = `settings.${key}`
    // @ts-ignore
    accountSettings[playKey] = account.settings[key]
  })

  // @ts-ignore
  return accountSettings
}
