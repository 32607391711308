Handlebars = require('handlebars/runtime').default
require('helpers/url-helper')
BaseView = require('base/view')
BaseModel = require('base/model')

class VoicemailAssignmentModel extends BaseModel
  urlRoot: -> "callFlows/voicemailAssignment/#{@get('queueId')}"

  parse: (response) =>
    users = (for model in response
      {
        type: 'user'
        text: model.displayName
        value: 'user:' + model.id
        id: "#{model.id}"
        idDefault: model.sfRunAsUser
      }
    )
    {options: users}

class UQModel extends BaseModel
  urlRoot: 'callFlows/usersAndQueues'

  parse: (response) =>
    restriction = @get('restriction')
    users = (for model in response.users
      {
      type: 'user'
      text: model.displayName
      value: 'user:' + model.id
      }
    ) if not restriction or restriction is 'users'
    queues = (for model in response.queues
      {
      type: 'group'
      text: model.name
      value: 'queue:' + model.id
      isDeleted: model.isDeleted
      queueType: model.type
      dialTimeout: model.dialTimeout
      }
    ) if not restriction or restriction  is 'queues'

    {options: _.union(users, queues)}

class UQView extends BaseView

  template: require('./template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    { @accountId, @restriction, @step, @type, @prefix, @readOnly } = options
    array = switch @type
      when 'Dial'
        [ 'dialId', 'dialType' ]
      when 'Conference'
        [ 'moderatorId', 'moderatorType' ]
      when 'Voicemail'
        [ 'recipientId', 'recipientType' ]
      when 'VoicemailAssignment'
        [ 'recipientAssignmentId' ]
      when 'Audio'
        [ "#{@prefix}UserId" ]
    placeholder = 'Start typing a user'
    placeholder += ' or call queue' if not @restriction or @restriction  is 'queues'
    @model = new Backbone.Model(
      placeholder : placeholder
      nameId      : array[0]
      nameType    : array[1]
      dialId      : @step.args()[array[0]]
      dialType    : if @type isnt 'Audio' and @type isnt 'VoicemailAssignment' then @step.args()[array[1]] else 'user'
    )

  onRender: (view) =>
    @ui.picker.selectize(
      preload: true
      load: (query, callback) ->
        @settings.load = null
        if view.type is 'VoicemailAssignment'
          picklist = new VoicemailAssignmentModel(queueId: view.step.args()['recipientId'])
        else
          picklist = new UQModel(restriction: view.restriction)
        picklist
          .fetch(data: accountId: view.accountId)
          .done(=>
            callback(picklist.get('options'))
            type = if view.model.get('dialType') is 'group' then 'queue' else view.model.get('dialType')
            dialId = view.model.get('dialId')
            if view.type is 'VoicemailAssignment'
              defaultValue = _.findWhere(picklist.get('options'), idDefault: true)
              if not dialId
                dialId = defaultValue.id if defaultValue
              else
                existValue = _.findWhere(picklist.get('options'), id: dialId)
                dialId = defaultValue.id if defaultValue and not existValue
            value = type + ':' + dialId
            if dialId?.indexOf('{{') > -1
              @addOption([value: value, text: dialId, type: view.model.get('dialType')])
            @setValue(value)
          )
      score: (search) ->
        score = @getScoreFunction(search)
        if search.indexOf('{{') > -1 and search.indexOf('}}') > -1
          @addOption([value: search, text: search, type: 'user'])
        (item) ->
          score(item)
      render:
        item: (item, escape) =>
          image = @imageUsersQueues(escape(item.type), escape(item.text))
          "<div data-type=\"{{escape(item.type)}}\">#{escape(item.text)}#{image}</div>"
        option: (item, escape) =>
          image = @imageUsersQueues(escape(item.type), escape(item.text))
          """<div>#{escape(item.text)}
               #{image}
                <span class='picker-value'>#{escape(item.value.replace(/user:|queue:/gi, ''))}</span>
             </div>"""
      onChange: (value) =>
        if @ui.picker[0]?.selectize
          selected = @ui.picker[0].selectize.options[value]
          @step.trigger('selectedUQ', selected) if @type is 'Voicemail'
    )
    @$('.selector')[0].selectize.disable() if @readOnly

  imageUsersQueues: (type, text) ->
    if type isnt 'undefined'
      type = 'queue' if type is 'group'
      urlSrc = Handlebars.helpers.imageUrl("call-flow/#{type}.png")
      "<img src='#{urlSrc}' title='#{text}' class='avatar' />"
    else
      ''

module.exports = UQView
