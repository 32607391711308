import React from 'react'

import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import { FeatureType, Feature } from '@ringdna/common/src/types/dialer-settings'
import {SettingsToggleProps} from '../types'

export default function SettingsToggle({ isChecked, setting, disabled = false, accountSettings, setAccountSettings, featureVisibilities, setFeatureVisibilities}: SettingsToggleProps) {
  const handleChange = (value: boolean) => {
    if (accountSettings && setAccountSettings && setting.startsWith('settings.')) {
      setAccountSettings({ ...accountSettings, [setting]: value })
    }
    if (featureVisibilities && setFeatureVisibilities && (setting in FeatureType)) {
      setFeatureVisibilities(featureVisibilities.map((visibility: Feature) => {
        if (visibility.feature == setting) {
          visibility.visible = value
          return visibility
        } else {
          return visibility
        }
      }))
    }
  }
  return (
    <RdnaToggleSwitch disabled={disabled} controlledState={{ isChecked, setIsChecked: handleChange }} />
  )
}
