import React, { useEffect, useMemo, useState } from 'react'

import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import CallFlowsTab from './components/CallFlowsTab/_CallFlowsTab'
import CallQueuesTab from './components/CallQueuesTab/_CallQueuesTab'
import HolidayCalendarTab from './components/HolidayCalendarTab/_HolidayCalendarTab'
import UserSkillsTab from './components/UserSkillsTab/_UserSkillsTab'
import { CALL_FLOWS, CALL_QUEUES, HOLIDAY_CALENDAR, USER_SKILLS } from './const'
import RdnaTabRouter from '@ringdna/common/src/components/RdnaTabRouter'
import { useAccount } from '../../store/api/accounts-api'
import { RdnaAccount } from '@ringdna/common/src/types/accounts'
import SaveButton from './components/SaveButton'
import { getAbilitiesForRole } from '@ringdna/common/src/utils/abilityUtils'
import { useUser } from '../common/services/UserContext'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'

export type CallRoutingProps = {
  accountId: number
  tab: string
  callFlowEdit: boolean
}

type RouteTypes = {
  path: string
  label: string
  render: any
}

export default function CallRouting({ accountId, tab, callFlowEdit }: CallRoutingProps) {
  const { licenses } = useUser()
  const [account] = useAccount({ meta: { id: accountId?.toString() } })
  const [loadingSettings, setLoadingSettings] = useState<boolean>(true)
  const [tabTitle, setTabTitle] = useState<string | undefined>()
  const [accountSettings, setAccountSettings] = useState<{ [key: string]: any }>({})
  const [lockedSettings, setLockedSettings] = useState<{ [key: string]: any }>({})

  const abilities = getAbilitiesForRole(licenses, {
    'call-flows:edit:me': callFlowEdit
  })

  useEffect(() => {
    if (account) {
      storeSettings(account)
    }
  }, [account])

  const onTabChange = (selectedTab: string) => {
    const routeTabTitle = ROUTES.find(tab => tab.path === selectedTab)?.label
    if (!tabTitle) {
      setTabTitle(routeTabTitle)
    } else if (tabTitle !== routeTabTitle) {
      setTabTitle(routeTabTitle)
    }
  }

  const ROUTES: RouteTypes[] = useMemo(() => {
    const routes = []
    if (abilities['call-flows:search:*'] || abilities['call-flows:search:me']) {
      routes.push({
        path: CALL_FLOWS.path,
        label: CALL_FLOWS.title,
        render: function fn() {
          return (
            <CallFlowsTab
              accountSettings={accountSettings}
              setAccountSetting={setAccountSettings}
              lockedSettings={lockedSettings}
              setLockedSetting={setLockedSettings}
              abilities={abilities}
            />
          )
        }
      })
    }
    if (abilities['call-queues:search:me'] || abilities['call-queues:search:*']) {
      routes.push({
        path: CALL_QUEUES.path,
        label: CALL_QUEUES.title,
        render: function fn() {
          return (
            <CallQueuesTab
              accountSettings={accountSettings}
              setAccountSetting={setAccountSettings}
              abilities={abilities}
            />
          )
        }
      })
    }

    routes.push({
      path: HOLIDAY_CALENDAR.path,
      label: HOLIDAY_CALENDAR.title,
      render: function fn() {
        return <HolidayCalendarTab accountId={accountSettings['account.id']} />
      }
    })

    if (abilities['accounts:edit:*'] || abilities['accounts:edit:me']) {
      routes.push({
        path: USER_SKILLS.path,
        label: USER_SKILLS.title,
        render: function fn() {
          return <UserSkillsTab accountSettings={accountSettings} setAccountSetting={setAccountSettings} />
        }
      })
    }
    return routes
  }, [abilities, accountSettings, lockedSettings])

  const redirectRoute = tab && tab !== ':tab' ? `/${tab}` : ROUTES[0].path

  const storeSettings = (account: RdnaAccount) => {
    setLoadingSettings(true)
    const accountSettings = { 'account.id': account.id }
    Object.keys(account.settings).forEach(key => {
      const playKey = `settings.${key}`
      //@ts-ignore
      if (account.settings[key] instanceof Object) {
        //@ts-ignore
        Object.keys(account.settings[key]).forEach(innerKey => {
          //@ts-ignore
          const setting = account.settings[key]
          //@ts-ignore
          accountSettings[`${playKey}.${innerKey}`] = setting[innerKey]
        })
      } else {
        //@ts-ignore
        accountSettings[playKey] = account.settings[key]
      }
    })
    setAccountSettings(accountSettings)

    // locked settings
    const lockedSettings = { 'lockedSetting.accountId': account.id }

    Object.keys(account.lockedSetting).forEach(key => {
      const playKey = `lockedSetting.${key}`
      //@ts-ignore
      lockedSettings[playKey] = account.lockedSetting[key]
    })
    setLockedSettings(lockedSettings)
    setLoadingSettings(false)
  }

  return (
    <RdnaLoader loading={loadingSettings} top="150px" data="data">
      <SectionHeader
        headingText={`RingDNA: ${tabTitle}`}
        rightColumn={
          tabTitle !== HOLIDAY_CALENDAR.title &&
          tabTitle !== CALL_QUEUES.title && (
            <SaveButton accountSettings={accountSettings} lockedSettings={lockedSettings} />
          )
        }
      />
      <RdnaTabRouter
        tabWidth="450px"
        redirectRoute={redirectRoute}
        baseRoute={'/call-routing/'}
        routes={ROUTES}
        onTabChange={onTabChange}
      />
    </RdnaLoader>
  )
}
