import { Colors } from '../colors'

const textColors = {
  primary: Colors.N100,
  neutral: Colors.N80,
  disabled: Colors.N40,
  link: Colors.C50,
  alert: Colors.F50,
  contrast: Colors.N0,
  info: Colors.C50,
  action: Colors.V50,
  warning: Colors.PY50,
  success: Colors.V50
}

const basePalette = {
  primary: {
    contrastText: Colors.N0,
    main: Colors.C30,
    dark: Colors.C50,
    light: Colors.N30
  },
  neutral: {
    contrastText: Colors.N0,
    main: Colors.N50,
    dark: Colors.N80,
    light: Colors.N30
  },
  action: {
    contrastText: Colors.N0,
    main: Colors.V30,
    dark: Colors.V50,
    light: Colors.N30
  },
  alert: {
    contrastText: Colors.N0,
    main: Colors.F30,
    dark: Colors.F50,
    light: Colors.N30
  },
  info: {
    contrastText: Colors.N0,
    main: Colors.C30,
    dark: Colors.C50,
    light: Colors.N30
  },
  success: {
    contrastText: Colors.N0,
    main: Colors.V30,
    dark: Colors.V50,
    light: Colors.N30
  },
  warning: {
    contrastText: Colors.N0,
    main: Colors.PY30,
    dark: Colors.PY50,
    light: Colors.N30
  },
  salesforceAccount: {
    contrastText: Colors.N0,
    main: Colors.P30,
    dark: Colors.SALESFORCE_ACCOUNT,
    light: Colors.N30
  },
  salesforceCampaign: {
    contrastText: Colors.N0,
    main: Colors.PY30,
    dark: Colors.SALESFORCE_CAMPAIGN,
    light: Colors.N30
  },
  salesforceCase: {
    contrastText: Colors.N0,
    main: Colors.S30,
    dark: Colors.SALESFORCE_CASE,
    light: Colors.N30
  },
  salesforceContact: {
    contrastText: Colors.N0,
    main: Colors.P30,
    dark: Colors.SALESFORCE_CONTACT,
    light: Colors.N30
  },
  salesforceEvent: {
    contrastText: Colors.N0,
    main: Colors.F30,
    dark: Colors.SALESFORCE_EVENT,
    light: Colors.N30
  },
  salesforceLead: {
    contrastText: Colors.N0,
    main: Colors.PY30,
    dark: Colors.SALESFORCE_LEAD,
    light: Colors.N30
  },
  salesforceOpportunity: {
    contrastText: Colors.N0,
    main: Colors.PY30,
    dark: Colors.SALESFORCE_OPPORTUNITY,
    light: Colors.N30
  },
  contrast: {
    contrastText: Colors.N80,
    main: Colors.N20,
    dark: Colors.N0,
    light: Colors.N0
  },
  text: {
    ...textColors
  },
  icon: {
    ...textColors,
    warning: Colors.PY50
  },
  illustrations: {
    20: Colors.C20,
    30: Colors.C30,
    40: Colors.C40,
    50: Colors.C50,
    60: Colors.C60,
    70: Colors.C70,
    80: Colors.C80
  },
  background: {
    main: Colors.N10,
    light: Colors.N0
  },
  boxShadow: {
    light: '0px 0px 8px rgba(0, 0, 0, 0.15);',
    primary: '0 2px 9px rgba(0, 0, 0, 0.03)',
    hover: '3px 3px 5px 0 rgba(0, 0, 0, 0.25)'
  },
  white: Colors.N0,
  border: Colors.N30,
  disabled: Colors.N40,
  inactive: Colors.N50,
  meta: Colors.N80
}

export default basePalette
