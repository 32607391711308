export enum ProductDomains {
  CAI = 'caiDomain',
  LIVE = 'dashboardDomain',
  MOMENTS_ADMIN = 'momentsAdminDomain',
  MOMENTS = 'moments',
  DIALER = 'softphone',
  WEB_ADMIN = 'webAdmin',
  CALENDAR = 'calendarServer',
  API = 'apiDomain'
}

export type EnvDomains = {
  [key in ProductDomains]: string
}

export const momentsAdminPath = '/moments/notifications'

export const localProductDomains = {
  [ProductDomains.CALENDAR]: 'http://localhost:3000',
  [ProductDomains.CAI]: 'http://localhost:3100',
  [ProductDomains.MOMENTS_ADMIN]: `http://localhost:3100${momentsAdminPath}`,
  [ProductDomains.MOMENTS]: 'http://localhost:3300',
  [ProductDomains.LIVE]: 'http://localhost:3000',
  [ProductDomains.DIALER]: 'http://localhost:8082',
  [ProductDomains.WEB_ADMIN]: 'http://localhost:3333',
  [ProductDomains.API]: 'http://localhost:8080'
}

export const devProductDomains = {
  [ProductDomains.CALENDAR]: 'https://my-dev.ringdna.net',
  [ProductDomains.CAI]: 'https://analytics-dev.revenue.io',
  [ProductDomains.MOMENTS_ADMIN]: `https://analytics-dev.revenue.io${momentsAdminPath}`,
  [ProductDomains.MOMENTS]: 'https://moments-dev.revenue.io',
  [ProductDomains.LIVE]: 'https://dashboards-dev.ringdna.net',
  [ProductDomains.DIALER]: 'https://softphone-dev.revenue.io',
  [ProductDomains.WEB_ADMIN]: 'https://app-dev.revenue.io',
  [ProductDomains.API]: 'https://dev.ringdna.com'
}

export const qaProductDomains = {
  [ProductDomains.CALENDAR]: 'https://my-qa.ringdna.net',
  [ProductDomains.CAI]: 'https://analytics-qa.revenue.io',
  [ProductDomains.MOMENTS_ADMIN]: `https://analytics-qa.revenue.io${momentsAdminPath}`,
  [ProductDomains.MOMENTS]: 'https://moments-qa.revenue.io',
  [ProductDomains.LIVE]: 'https://dashboards-qa.revenue.io',
  [ProductDomains.DIALER]: 'https://softphone-qa.revenue.io',
  [ProductDomains.WEB_ADMIN]: 'https://app-qa.revenue.io',
  [ProductDomains.API]: 'https://qa.ringdna.com'
}

export const prodProductDomains = {
  [ProductDomains.CALENDAR]: 'https://my.ringdna.net',
  [ProductDomains.CAI]: 'https://analytics.revenue.io',
  [ProductDomains.MOMENTS_ADMIN]: `https://analytics.revenue.io${momentsAdminPath}`,
  [ProductDomains.MOMENTS]: 'https://moments.revenue.io',
  [ProductDomains.LIVE]: 'https://dashboards.revenue.io',
  [ProductDomains.DIALER]: 'https://softphone.revenue.io',
  [ProductDomains.WEB_ADMIN]: 'https://app.revenue.io',
  [ProductDomains.API]: 'https://app.ringdna.com'
}
