module.exports = class

  # FIXME: wtf? these things can take args? do I even need Radio in Grid?
  @deleted: (field = 'isDeleted') ->
    @error(field, 'deleted')

  @error: (field, className) ->
    class extends Backgrid.Row
      render: ->
        super()
        @el.classList.add(className) if @model.get(field)
        @
