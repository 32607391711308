BaseCollection = require('base/collection')
CallTrackingHelper = require('features/call-tracking/helper')
Model = require('./model')

class OfflineCallTrackingCollection extends BaseCollection
  url: -> 'offline'

  toJSON: =>
    fields = @toPlayJSON('offlineCallTracking')
    fields['accountId'] = @accountId
    fields

  model: Model

  createOfflines: (accountId, notAddressesCollection, callback) =>
    @accountId = accountId
    @create().done((offlines) =>
      for offline in offlines
        offline['account'] = { name: offline.accountName }
      needAddresses = CallTrackingHelper.filterCallTracking(@, offlines)
      @reset(needAddresses) if needAddresses.length isnt 0
      @reset(notAddressesCollection.models) if notAddressesCollection and notAddressesCollection.length isnt 0
      callback(needAddresses.length isnt 0) if callback
    )

module.exports = OfflineCallTrackingCollection