import React from 'react'
import { AccountIntegration } from '../../model'
import styled from 'styled-components'

import { integrationItems, integrationDisconnectedFlags } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'

import { RdnaTableRow, RdnaTableCell } from '@ringdna/common/src/components/RdnaTable'
import { Grid, Box } from '@mui/material'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

type Props = {
  integration: AccountIntegration
  removeIntegration?: (integration: AccountIntegration) => void
  reconnectIntegration?: (integration: AccountIntegration) => void
  manageZoomIntegration?: () => void
  areButtonsDisabled: boolean
}

export default function ActiveIntegrationsItem({
  integration,
  removeIntegration,
  reconnectIntegration,
  areButtonsDisabled
}: Props) {
  const integrationAttributes = integrationItems[integration.provider]
  const Icon = integrationAttributes.icon
  const isZoomIntegration = integration.provider === IntegrationProviderTypes.ZOOM
  const isSalesForceIntegration = integration.provider === IntegrationProviderTypes.SALESFORCE
  const isIntegrationDisconnected = integration.status && integrationDisconnectedFlags.includes(integration.status)

  const colorPalette = isIntegrationDisconnected ? 'alert' : 'primary'

  const removeIntegrationHandler = () => {
    if (isZoomIntegration) {
      window.location.href = 'https://marketplace.zoom.us/user/installed'
    } else if (removeIntegration) {
      removeIntegration(integration)
    }
  }

  const reconnectIntegrationHandler = () => {
    if (reconnectIntegration) reconnectIntegration(integration)
  }

  const zoomButtons = () => {
    return (
      <ZoomDetailsButton
        data-testid="manage-zoom-integration"
        name="manage-zoom-integration"
        text="View Settings"
        variant="outlined"
        href="https://zoom.us/profile/setting"
        target="blank"
      />
    )
  }

  return (
    <RdnaTableRow>
      <RdnaTableCell width="90px">
        <Grid container justifyContent="center">
          <Icon />
        </Grid>
      </RdnaTableCell>
      <RdnaTableCell width="130px">
        <strong>{integrationAttributes.label}</strong>
      </RdnaTableCell>
      <RdnaTableCell>
        <RdnaText color={colorPalette}>{integration.email}</RdnaText>
      </RdnaTableCell>
      <RdnaTableCell>
        {isZoomIntegration ? zoomButtons() : <RdnaText color={colorPalette}>{integration.status || ''}</RdnaText>}
      </RdnaTableCell>
      <RdnaTableCell>
        {!isSalesForceIntegration && !isZoomIntegration && !isFlagged(flags.NYLAS_API_V3) && (
          <Box display="inline" mr={2}>
            <RdnaButton
              name="reconnect-integration"
              text="Reconnect"
              disabled={areButtonsDisabled}
              onClick={reconnectIntegrationHandler}
            />
          </Box>
        )}
        {!isSalesForceIntegration && (
          <RdnaButton
            name="remove-integration"
            text="Remove"
            color="alert"
            disabled={areButtonsDisabled}
            onClick={removeIntegrationHandler}
          />
        )}
      </RdnaTableCell>
    </RdnaTableRow>
  )
}

const ZoomDetailsButton = styled(RdnaButton)`
  text-decoration: none;
  bottom: 12px;
`
