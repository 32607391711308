import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ExplainationMark = ({ color = Colors.N50, size = 14 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6346 13.6711C12.6074 13.8687 12.4378 14.0159 12.2386 14.0159H11.1786C10.9594 14.0159 10.781 13.8391 10.7786 13.6207L10.6922 6.40474C10.6906 6.29834 10.733 6.19514 10.8082 6.11914C10.8834 6.04314 10.985 5.99994 11.0922 5.99994H13.2506C13.3658 5.99994 13.4762 6.05034 13.5514 6.13754C13.6282 6.22474 13.6626 6.34074 13.6466 6.45514L12.6346 13.6711Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6995 17.9999C10.9179 17.9999 10.3499 17.4495 10.3499 16.6903C10.3499 15.9199 10.9179 15.3607 11.6995 15.3607C12.4923 15.3607 13.0467 15.9071 13.0467 16.6903C13.0467 17.4615 12.4923 17.9999 11.6995 17.9999Z"
      fill={color}
    />
  </svg>
)

export default ExplainationMark
