BaseView = require('base/view')
UserModel = require('./create-user-model')
LicenseModel = require('features/accounts/show/licenses/model')
LocationPickerView = require('widgets/location-picker/view')

class CreateUserModal extends BaseView

  template: require('./create-user-template')
  className: 'create-user-modal'

  regions:
    locations    : 'div.locations'

  ui:
    email        : '.email'
    firstName    : '.first-name'
    lastName     : '.last-name'
    role         : '.role'
    picker       : '.locationList'
    salesforceId : '.salesforce-id'
    go           : 'button.create'
    modal        : '#create-user-modal'

  bindings:
    '.adminUsers'         : 'checked:adminUsers'
    '.caiUsers'           : 'checked:caiUsers'
    '.dialerUsers'        : 'checked:dialerUsers'
    '.nylasUser'          : 'checked:nylasUser'
    '.guidedSellingUsers' : 'checked:guidedSellingUsers'
    '.momentsUsers'       : 'checked:momentsUsers'

  events:
    'click @ui.go' : 'go'
    'change .toggle-switch' : 'toggleSwitchChanged'

  onDomRefresh: =>
    @refreshToggle()

  refreshToggle: ->
    $('.toggle-switch').bootstrapToggle('destroy')
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  initialize: (options) =>
    @accountId = options.accountId
    @locations = options.locationList
    @locationRequired = options.locationRequired
    @licenseModel = new LicenseModel()
    @viewModel = new Backbone.Model(
      adminUsers: false
      caiUsers: false
      dialerUsers: false
      nylasUser: false
      guidedSellingUsers: false
      momentsUsers: false
    )

  onRender: =>
    @licenseModel.fetch(data: { accountId: @accountId })
    @locationPicker = new LocationPickerView(accountId: @accountId, locations: @locations, fullList: true)
    @showChildView('locations', @locationPicker)

  toggleSwitchChanged: (e) =>
    selectedClass = e.target.classList[0]
    unless @licenseModel.get(selectedClass + 'Remaining') > 0
      errorMessage = 'You do not have the available licenses to provision to this user. Please request additional licenses and try again.'
      toastr.warning(errorMessage)
      @viewModel.set("#{selectedClass}": false)
      setTimeout(=>
        @$(".#{selectedClass}").data('bs.toggle').off(true)
      , 500)

  go: =>
    @model = new UserModel(
      accountId    : @accountId
      email        : @ui.email.val()
      firstName    : @ui.firstName.val()
      lastName     : @ui.lastName.val()
      salesforceId : @ui.salesforceId.val()
      locationId   : @locationPicker.val()
      'licenses.admin' : @viewModel.get('adminUsers')
      'licenses.intelligentDialer' : @viewModel.get('dialerUsers')
      'licenses.calendar' : @viewModel.get('nylasUser')
      'licenses.conversationAI' : @viewModel.get('caiUsers')
      'licenses.guidedSelling' : @viewModel.get('guidedSellingUsers')
      'licenses.moments' : @viewModel.get('momentsUsers')
    )

    if not @model.isValid()
      toastr.warning(@model.validationError)
      return false

    if @locationRequired and @locationPicker.val().length is 0
      toastr.warning('Please select location of the Imported Users')
      return false

    @model
      .persist(ladda: @ui.go[0])
      .done(=>
        @ui.modal.modal('hide')
        toastr.info('User created.')
      )

module.exports = CreateUserModal
