AccountModel              = require('features/accounts/model')
BaseView                  = require('base/view')
BlockedNumbersView        = require('./blocked-numbers/view')
DialerSettingsModel       = require('./intelligent-dialer/model')
IntelligentDialerView     = require('./intelligent-dialer/view')
SkillsView                = require('./skills/view')

CallRecordingTab          = require('src/components/call-recording-tab').default
ProductConstant           = require('@ringdna/common/src/constants/products')
ReactComponent            = require('src/ReactComponent').default

class DialerMenuView extends BaseView
  template: require('./tabs-separate-template')
  className: 'dialer-menu-view'

  regions:
    content: '.content'

  ui:
    contentTitle  : '.content-title'
    saveButton    : '.save'

  events:
    'click .save' : 'saveClicked'

  initialize: =>
    @content    = @getRegion('content')
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    [ @path ]   = window.location.hash.split('?')

  onAttach: =>
    @showTab()

  saveClicked: =>
    @content.currentView.triggerMethod('save:clicked', @ui.saveButton[0])

  showTab: =>
    @ui.saveButton.hide()
    switch @path
      when '#dialer/settings'
        @ui.contentTitle.text('RingDNA Settings')
        @ui.saveButton.show() if typeof @ui.saveButton is 'object'
        model = new DialerSettingsModel(accountId: @accountId)
        model.fetch().done(=>
          @content.show(new IntelligentDialerView(accountId: @accountId, model: model))
        )
      when '#dialer/call-recording'
        @ui.contentTitle.text('Call Recording')
        component = CallRecordingTab
        #     TODO: Remove onSave logic once we reactify Team Details Page.
        onSave = (callRecordingOverrideOption, accountCallRecordingLocked) =>
          @user().set(
            accountCallRecordingLocked: accountCallRecordingLocked
            accountCallRecordingOption: callRecordingOverrideOption
          ) if @user().isAdmin()
        @content.show(new ReactComponent({component: component, theme: ProductConstant.Products.ADMIN, props: { accountId: @accountId, onSave: onSave }}))
      when '#dialer/skills'
        @ui.contentTitle.text('Skills')
        model = new AccountModel(id: @accountId)
        model.fetch().done(=> @content.show(new SkillsView(accountId: @accountId)))
      when '#dialer/blocked-numbers'
        @ui.contentTitle.text('Blocked Numbers')
        model = new AccountModel(id: @accountId)
        model.fetch().done(=> @content.show(new BlockedNumbersView(accountId: @accountId, model: model)))
      else
        throw new Error("Unknown tab: #{@path}")

module.exports = DialerMenuView
