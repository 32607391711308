BaseCollection = require('base/collection')
DomainBlacklistModel = require('./model')

class DomainBlacklistCollection extends BaseCollection

  model: DomainBlacklistModel
  url: 'domainBlacklist'

  comparator: (model) ->
    model.get('name')

module.exports = DomainBlacklistCollection
