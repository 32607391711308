import React from 'react'
import styled from 'styled-components'
import Grid, { GridProps } from '@mui/material/Grid'
import { LARGE_MARGIN } from './const'

const StyledGrid = styled<any>(Grid)`
  .item {
    margin-bottom: ${props => props.theme.spacing * 6}px !important;
  }

  .modalSubmit {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: -${LARGE_MARGIN};
  }

  .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    display: none;
  }

  .MuiFormControl-root {
    margin-top: 0px;
  }

  .MuiFormControl-marginDense {
    margin-bottom: 0px;
  }

  .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .link {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  /* margin-bottom in figma calls for 3px, the height of the label text is 10.5px = 13.5px */
  .label {
    margin-bottom: 13.5px;
  }

  .submit {
    margin-bottom: 0px;
  }
`

type Props = {
  children: React.ReactNode
  container?: boolean
  label?: boolean
  link?: boolean
  submit?: boolean
  modalSubmit?: boolean
} & GridProps

/**
 * Layout component for forms
 *
 * @param xs - extra small resolution
 * @param children - form components
 * @param container - form container
 * @param gridProps - GridProps from @mui/material/Grid
 * @param label - if the layout is a label type
 * @param link - if the layout is a link type
 * @param submit - submit button layout
 * @param modalSubmit - modal submit button layout
 * @constructor
 */
export default function RdnaFormLayout({
  xs = 12,
  children,
  container,
  label = false,
  link = false,
  submit = false,
  modalSubmit = false,
  ...gridProps
}: Props) {
  return (
    <StyledGrid
      className={
        (modalSubmit && 'modalSubmit') ||
        (submit && 'submit') ||
        (link && 'link item') ||
        (label && 'label') ||
        (!container && !modalSubmit && !submit && !label && 'item')
      }
      container={container}
      item={!container}
      xs={!container ? xs : undefined}
      {...gridProps}
    >
      {children}
    </StyledGrid>
  )
}
