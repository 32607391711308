BaseModel = require('base/model')

class LicenseModel extends BaseModel
  url: -> 'licenses'

  parse: (response) ->
    attrs = _.clone(response)
    (for license in attrs.licenses
      switch license.type
        when 'MaxAdmins'
          license.type = 'Admin Users'
        when 'MaxDialerUsers'
          license.type = 'RingDNA Users'
        when 'MaxConversationAiUsers'
          license.type = 'Conversation AI Users'
        when 'MaxSmartNumbers'
          license.type = 'Smart Numbers'
        when 'MaxNylasUsers'
          license.type = 'Calendar Booking Users'
        when 'MaxGuidedSellingUsers'
          license.type = 'Guided Selling Users'
        when 'MaxMomentsUsers'
          license.type = 'Moments™ Users'

    ) if attrs.licenses
    attrs['smartNumberRemaining'] = response.smartNumberLimit - response.smartNumberCount
    attrs['adminUsersRemaining'] = response.adminUserLimit - response.adminUserCount
    attrs['dialerUsersRemaining'] = response.dialerUserLimit - response.dialerUserCount
    attrs['nylasUserRemaining'] = response.nylasUserLimit - response.nylasUserCount
    attrs['caiUsersRemaining'] = response.caiUserLimit - response.caiUserCount
    attrs['guidedSellingUsersRemaining'] = response.guidedSellingUserLimit - response.guidedSellingUserCount
    attrs['momentsUsersRemaining'] = response.momentsUserLimit - response.momentsUserCount
    attrs

  validate: (attrs) ->
    if attrs.amount?.length is 0
      return 'Please enter an amount.'
    false

module.exports = LicenseModel
