import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const SvgPlay = ({ size = 48, color = Colors.P50, title = 'play-button' }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <title>{title}</title>
    <path
      d="M24 47.125C11.248 47.125.875 36.752.875 24S11.248.875 24 .875 47.125 11.248 47.125 24 36.752 47.125 24 47.125z"
      stroke={color}
    />
    <path d="M20.625 32.438V15.561L32.438 24l-11.813 8.438z" fill={color} stroke={color} strokeLinejoin="round" />
  </svg>
)

export default SvgPlay
