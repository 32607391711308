import React, { useEffect, useState } from 'react'
import { Refetch } from '@ringdna/client/src/constants'
import { useUpdateTeamsSettings, TeamSettings, AccountIdQuery } from './api'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'
import useToast from '../../common/hooks/useToast'

import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import { Section, SectionHeader } from './styles'
import TeamsIcon from '@ringdna/common/src/assets/icons/svg/Teams'

export const RECORD_TITLE = 'Automatically record Microsoft Teams'
export const RECORD_SUBTITLE =
  'When enabled, all Microsoft Teams meetings will automatically be set to record. Users can still stop the recording of specific meetings.'
export const RECORD_USER_TITLE = 'User Level Automatic Microsoft Teams Recording'
export const RECORD_USER_SUBTITLE =
  'Allow individual users to enable or disable the automatic recording of Microsoft Teams meetings through Bot Chat by typing "Settings".'

type Props = {
  settings: TeamSettings
  refetchSettings: Refetch<TeamSettings, AccountIdQuery>
}

export default function TeamsGlobalSettings({ settings, refetchSettings }: Props) {
  const toastr = useToast()
  const [currentSettings, setCurrentSettings] = useState(settings)
  const updateSettings = useUpdateTeamsSettings()

  useEffect(() => {
    const postSettings = async () => {
      try {
        await updateSettings({
          json: currentSettings
        })
        refetchSettings()
      } catch (err) {
        /* istanbul ignore next */
        toastr.error(getErrorMessage(err))
      }
    }
    postSettings()
  }, [currentSettings, toastr, updateSettings, refetchSettings])

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={TeamsIcon} iconSize={24} variant="filled" />
        <RdnaText bold className={'rdna-text'}>
          Microsoft Teams
        </RdnaText>
      </SectionHeader>
      <RdnaSettingRow heading={RECORD_TITLE} description={RECORD_SUBTITLE}>
        <RdnaToggleSwitch
          value={currentSettings.autoRecord}
          margin={'none'}
          onChange={autoRecord => setCurrentSettings({ ...currentSettings, autoRecord })}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={RECORD_USER_TITLE} description={RECORD_USER_SUBTITLE}>
        <RdnaToggleSwitch
          value={currentSettings.userOverride}
          margin={'none'}
          onChange={userOverride => setCurrentSettings({ ...currentSettings, userOverride })}
        />
      </RdnaSettingRow>
    </Section>
  )
}
