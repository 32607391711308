BaseView        = require('base/view')
StepDetailsView = require('./step-details-view')

class CallFlowPaletteView extends BaseView
  template: require('./palette-template')

  initialize: ->
    @changedStep = null
    @model = new Backbone.Model(
      dynamicCallFlows: @user().can('call-flows:callouts:edit')
    )

class CallFlowStepView extends BaseView

  template: require('./template')

  regions:
    picker    : 'div.picker'
    palette   : '#palette-container'
    stepDetail: 'div.step-detail'

  bindings:
    '.name'                   : 'text:name'
    'input:not(.assignment):not(.record-call-flow)'  : 'disabled:readOnly'
    'textarea'                : 'disabled:readOnly'
    'select'                  : 'disabled:readOnly'
    '.addRemoveNumber'        : 'disabled:readOnly'

  computeds:
    name:
      get: -> @callflow.get('name')
    readOnly:
      get: ->
        @callflow.get('readOnly')

  events:
    'change .input'            : 'handleInput'
    'change.input'             : 'handleInput'
    'change select.assignment' : 'updateMenuAssignment'
    'keyup.input'              : 'handleInput'
    'keypress .max-digits'     : 'handleMaxDigits'
    'keyup .max-digits'        : 'handleChangeDigits'
    'keypress input.speechAssignment': 'enforceAlpha'

  initialize: (options) =>
    @callflow = options.callflow
    @listenTo(@model, 'updateTree', @updateTree)
    @refreshErrors()
    @callflow.saveCallFlow() if @callflow.changed?.valid != undefined

  onRender: =>
    @showChildView('palette', new CallFlowPaletteView())
    @selected(@model.models[0].id) unless @callflow.isNew() and @model.models.length > 0

  onAttach: =>
    $('.tree').jstree(
      core:
        animation: 0
        check_callback : (operation, node, parent, position, more) =>
          return false if @callflow.get('readOnly')
          step = @model.get(node.id)
          if step?.isPlaceholder() or (more?.pos is 'b' or more?.pos is 'a')
            return false
          true
        data: @model.asTree()
      plugins: ['dnd']
    ).on('loaded.jstree', (event, data) -> data.instance.open_all()
    ).on('refresh.jstree', (event, data) =>
      data.instance.open_all()
      _.defer(=>
        $('.tree-overview').scrollTop(@treeScroll.top)
        $('.tree-overview').scrollLeft(@treeScroll.left)
      ) if @treeScroll
    ).on('select_node.jstree', (event, data) =>
      @selected(data.selected[0])
    ).on('copy_node.jstree', (event, data) =>
      return if @callflow.get('readOnly')
      # add new node
      newStep = data.node
      @replaceStep(newStep.parent, newStep.text)
    ).on('move_node.jstree', (event, data) =>
        # node to node
      return if @callflow.get('readOnly')
      moveStep = data.node
      model = @model.get(moveStep.id)
      @replaceStep(moveStep.parent, model.get('type'), model)
    )
    $(document).on('dnd_move.vakata', @moveDNDNode).on('dnd_stop.vakata', @stopDNDNode)

    $('.draggable').on('mousedown', (e) ->
      type = $(@).find('img').attr('type')
      $.vakata.dnd.start(e, {
        'jstree': true
        'obj': $(@)
        'nodes': [ {
          id: true
          text: type
        } ]
      }, '<div id="jstree-dnd" class="jstree-default"><i class="jstree-icon jstree-er"></i></div>')
    )

  onDestroy: ->
    $(document).off('dnd_move.vakata', @moveDNDNode).off('dnd_stop.vakata', @stopDNDNode)

  moveDNDNode: (e, data) =>
    t = $(data.event.target)
    if !t.closest('.tree').length
      step = @model.get(data.data.nodes[0])
      if t.closest('#palette-container').length and not step?.isPlaceholder()
        data.helper.find('.jstree-icon').removeClass('jstree-er').addClass('jstree-ok')
      else
        data.helper.find('.jstree-icon').removeClass('jstree-ok').addClass('jstree-er')

  stopDNDNode: (e, data) =>
    t = $(data.event.target)
    if !t.closest('.jstree').length and t.closest('#palette-container').length
      return if @callflow.get('readOnly')
      # node to offline
      step = @model.get(data.data.nodes[0])
      @replaceStep(data.data.nodes[0], 'Placeholder') unless step?.isPlaceholder()
    @treeScroll =
      top: $('.tree-overview').scrollTop()
      left: $('.tree-overview').scrollLeft()

  handleMaxDigits: (e) ->
    return false if e.keyCode > 31 and (e.keyCode < 48 or e.keyCode > 57)

  handleChangeDigits: (e) ->
    value = parseInt(@$('.max-digits').val())
    if value > 20
      $(e.target).val('20')
    else if value is 0
      $(e.target).val('1')

  enforceAlpha: (e) ->
    regex = new RegExp('^[a-zA-Z,\b\\s]+$')
    key = String.fromCharCode(if not event.charCode then event.which else event.charCode)
    if !regex.test(key)
      event.preventDefault()
      return false

  handleInput: (e) ->
    _.debounce( @_handleInput(e)
    , 200)

  _handleInput: (e) ->
    target = $(e.target)
    stepId = target.closest('.step').attr('data-key')
    step = @model.get(stepId)
    value = target.val()
    if target.attr('type') is 'checkbox'
      value = if target.is(':checked') then 'true' else 'false'
    if step
      @changedStep = step
      @callflow.set('changedStep', step)
      if target.hasClass('assignment')
        parentStep = step.parent()
        if parentStep and not target.is(':focus')
          step.replaceAssignment(step, value)
          parentStep.saveStep()
        return
      else if target.hasClass('speechAssignment')
        parentStep = step.parent()
        if parentStep and not target.is(':focus')
          step.updateSpeechAssignment(stepId, value)
          parentStep.saveStep()
        return
      else if target.hasClass('title')
        step.set(name: value)
        @updateTreeLabel(step)
        return
      else if target.hasClass('smsNumbers')
        values = (element.value for element in $('.smsNumbers'))
        value = values.join()
      else if target.hasClass('smsType')
        value = if target.is(':checked') then 'nonAgent' else ''
      else if target.hasClass('calloutInputs') or target.hasClass('calloutValues')
        values = (element.value for element in $(".#{target.attr('data-key')}"))
        value = values.join()
      else if target.hasClass('ugv')
        if target.attr('data-type')
          selectedType = if value.length > 0 then target[0].selectize.options[value]?.type else 'user'
          step.args()[target.attr('data-type')] = selectedType
        value = value.replace(/user:|queue:/gi, '')
      else if target.hasClass('ugs-id') or target.hasClass('ugs-type')
        radioType = $('input[name="dialType"]:checked').val()
        return if radioType is 'multiNumber'
      step.args()[target.attr('data-key')] = value if target.attr('data-key')
      @refreshErrors(step)

  refreshErrors: (step) ->
    @$('.error-step').text('')
    errors = @callflow.errors()
    for stepId, error  of errors
      stepLabel = @$("##{stepId}").find('.step-label:first')
      if error?.length > 0
        @$(msg[1]).text(msg[0]) for msg in error if step?.id.toString() is stepId
        stepLabel.addClass('step-error')
      else
        stepLabel.removeClass('step-error')
    if step and not errors[step.id]
      stepLabel = @$("##{step.id}").find('.step-label:first')
      stepLabel.removeClass('step-error')

  replaceStep: (stepId, typeNewStep, replacedStep) =>
    if @callflow.isNew()
      @callflow.saveCallFlow(null, true, typeNewStep)
      return
    return unless @model.get(stepId)
    @showSpinner($('.tree-container'))
    @model.replaceStep(@model.get(stepId), typeNewStep, replacedStep, (replaced) =>
      @hideSpinner($('.tree-container'))
      return unless replaced
      @updateTree()
      if typeNewStep is 'Placeholder'
        @changedStep = null
        @callflow.set('changedStep', null)
      @selected(stepId)
    )

  selected: (stepId) =>
    @changedStep.saveStep() if @changedStep
    @changedStep = null
    step = @model.get(stepId)
    return unless step
    if step.isPlaceholder() or step.get('type') is 'Hangup'
      @getChildView('stepDetail')?.destroy() if @getRegion('stepDetail')
      @refreshErrors(step)
      return
    @model.checkMissingFixedSteps(step, => @updateTree())
    @showChildView('stepDetail', new StepDetailsView(model: step, callflow: @callflow))
    @listenTo(step, 'updateTreeLabel', @updateTreeLabel)
    @listenTo(step, 'refreshErrors', @refreshErrors)
    @refreshErrors(step)
    @applyBindings()

  updateMenuAssignment: (e) ->
    target = $(e.target)
    stepId = target.closest('.step').attr('data-key')
    step = @model.get(stepId)
    oldAssignment = step.assignment()
    newAssignment = target.val()
    return if newAssignment is oldAssignment

    targetId = step.parent().args()[newAssignment]
    if not targetId
      foundPlaceholder = @model.find((anyStep) =>
        return anyStep.get('parentId') is step.get('parentId') and anyStep.isPlaceholder() and not anyStep.isFixedPlaceholder()
      )
      targetId = foundPlaceholder.id

    parent = step.parent()
    if parent
      parent.swapAssignment(newAssignment, oldAssignment)
      parent.saveStep()
    @updateTreeLabel(step)
    @updateTreeLabel(@model.get(targetId)) if targetId
    @refreshErrors(step)

  updateTree: ->
    @trigger('updateTree')
    instance = $('.tree').jstree(true)
    instance.settings.core.data = @model.asTree()
    instance.refresh()
    instance.open_all()

  updateTreeLabel: (step) ->
    $('.tree').jstree('set_text', $('.tree').find("li##{step.id}"), step.label())

module.exports = CallFlowStepView
