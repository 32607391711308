BaseCollection  = require('base/collection')
MemberModel     = require('./model')

class MemberCollection extends BaseCollection
  model: MemberModel

  initialize: (models, options) =>
    {@teamId, @type} = options

  url: =>
    "teams/#{@teamId}/members"

  toJSON: =>
    fields = {}
    @each((member, index) =>
      fields["users[#{index}]"] = member.get('id')
    )
    fields['type'] = @type
    fields

module.exports = MemberCollection