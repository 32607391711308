BaseCollection         = require('base/collection')
VoicemailGreetingModel = require('./model')

module.exports = class VoicemailGreetingCollection extends BaseCollection

  model: VoicemailGreetingModel

  initialize: (options) =>
    _.defer( =>
      @insertDefault())

  insertDefault: ->
    selected = true
    for model in @models
      if model.get('selected') is true
        selected = false
        break
    @.add(new VoicemailGreetingModel().defaultGreeting(selected), {at: 0})

