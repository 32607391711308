import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function LinkIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M19.0668 14.6243V17.0827V19.541V21.9993H17.864H15.5871H13.3103H11.0334H8.75656H6.47971H4.20286H3V19.541V17.0827V14.6243V12.1243V9.66602V7.20768V6.99935V4.54102H4.20286H6.47971H8.75656"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 2H21.0024" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 2V8.79167" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.0017 2L11.0352 12"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
