import React from 'react'
import CallRecordingTab from './CallRecordingTab'
import {
  CallRecordingStateContext,
  CallRecordingDispatchContext,
  callRecordingReducer,
  initialState
} from './utils/contextReducer'

type AccountProviderProps = {
  accountId: string
  onSave: (callRecordingOverrideOption: string, accountCallRecordingLocked: boolean) => void
}

export default React.memo(CallRecordingTabProvider)
export function CallRecordingTabProvider({ accountId, onSave }: AccountProviderProps) {
  const [state, dispatch] = React.useReducer(callRecordingReducer, initialState)

  return (
    <CallRecordingStateContext.Provider value={state}>
      <CallRecordingDispatchContext.Provider value={dispatch}>
        <CallRecordingTab accountId={accountId} onSave={onSave} />
      </CallRecordingDispatchContext.Provider>
    </CallRecordingStateContext.Provider>
  )
}
