import { Colors } from '../../constants/colors'

export type BaseIconProps = {
  size?: number
  strokeWidth?: number
  color?: Colors | 'currentColor'
  title?: string
  innerFill?: string
}

export enum Direction {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right'
}
