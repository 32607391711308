import { createTheme } from '@mui/material/styles'
import overrides from '../material-ui-theme/vendor/overrides'
import {
  themeRevenue,
  themeAdmin,
  themeCalendar,
  themeGuidedSelling,
  themeIntelligentDialer,
  themeMoments
} from './theme'
import { Products } from '../products'

const getBaseTheme = (theme: Products) => {
  switch (theme) {
    case Products.CALENDAR:
      return themeCalendar
    case Products.REVENUE:
      return themeRevenue
    case Products.GUIDED_SELLING:
      return themeGuidedSelling
    case Products.INTELLIGENT_DIALER:
      return themeIntelligentDialer
    case Products.MOMENTS:
      return themeMoments
    default:
      return themeAdmin
  }
}

export const getThemes = (theme: Products) => {
  const baseTheme = getBaseTheme(theme)
  // @ts-ignore
  const muiTheme = createTheme({
    ...baseTheme,
    components: overrides
  })
  return { muiTheme, scTheme: baseTheme }
}

export interface RdnaTheme {
  palette: any
}
