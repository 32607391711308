BackgridCell      = require('helpers/backgrid-cell')
BackgridRow       = require('helpers/backgrid-row')
BaseView          = require('base/view')
PagedCollection   = require('base/paged-collection')
PagerView         = require('widgets/pager/view')
NumbersCollection = require('./collection')

class UnassignedNumbersSearch extends BaseView

  template: require('./template')
  className: 'unassigned-number-search'

  regions:
    grid          : 'div.grid'
    pager         : 'span.pager'

  ui:
    phoneNumber : 'input.phone-number'

  events:
    'click button.search' : 'search'
    'keyup input:text' : (e) -> @search() if e.keyCode is 13

  initialize: (options) ->
    { @accountId, @changedModel } = options
    @listenTo(@changedModel, 'destroy', => @destroy())

  onRender: =>
    @search()

  search: =>
    return unless @validate()

    collection = new NumbersCollection(
      queryParams:
        number    : @ui.phoneNumber.val()
        accountId : @accountId
        reassigningNumbers  : false
        filterType: 'Unassigned'
    )
    @listenTo(collection, 'reassignNumber', (model) => @reassignNumber(model))

    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, disableDownload: true))

    collection.fetch(reset: true)

  buildGrid: (collection) =>
    numberCell = BackgridCell.href(
      url   : '#smart-numbers/:id',
      label : ':number',
      attrs : ['id', 'number'])

    cols = [
      {label: 'Number',     name: 'number',       cell: numberCell}
      {label: 'Type',       name: 'type',         cell: 'string'}
      {label: 'Label',      name: 'labelColumn',  cell: 'string'}
      {label: '',           name: '',             cell: @reassignExistingCell()}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart numbers found.'
    )

  reassignExistingCell: =>
    trackingCollection = @changedModel.collection
    class extends Backgrid.Cell
      className: 'reassign-cell'
      events:
        'click .reassign': -> @model.collection.trigger('reassignNumber', @model)

      render: ->
        @showHtml()
        @listenTo(trackingCollection, 'change:number', => @showHtml())

      showHtml: =>
        disabled = if trackingCollection.findWhere(number: @model.get('number')) then 'disabled' else ''
        $(@el).html("""
          <button class="btn btn-xs btn-primary reassign" #{disabled}>Reassign</button>
        """)
        @

  reassignNumber: (numberModel) =>
    @changedModel.addNumber(numberModel.get('id'), numberModel.get('number'))
    @destroy()

  validate: =>
    if @ui.phoneNumber.val() and @ui.phoneNumber.val().length < 3
      toastr.warning('Phone number must be at least three characters.')
      return false

    true

module.exports = UnassignedNumbersSearch
