BaseModel = require('base/model')
BaseCollection  = require('base/collection')

class SelectedNumbersCollection extends BaseCollection
  url: -> 'smartNumbers/batch'
  setParams: (options) ->
    {@objectId, @accountId, @country, @type} = options
  toJSON: =>
    fields = @_toPlayJSON('numbers', @unprovisionedNumbers())
    fields['objectId'] = @objectId if @type isnt 'account'
    fields['accountId'] = @accountId
    fields['typeObject'] = @type
    fields['country'] = @country
    fields

  unprovisionedNumbers: =>
    @models.filter((number) => !number.get('status') || number.get('status') is 'Failed')

  provisionedNumbers: =>
    @models.filter((number) => number.get('status') && number.get('status') is 'Provisioned')

class Model extends BaseModel
  defaults:
    areaCode    : null
    availableNewNumbers: []
    dualCapability: true
    capability  : 'any'
    capabilityOptions: []
    country     : 'US'
    countryList : []
    isCreatingNumber: false
    retry       : false
    searchTerm  : ''
    selectedNumbers: new SelectedNumbersCollection()
    showLPNumbers: true
    smsOnly: false
    voiceOnly: false

  getAddressSid: (number) =>
    providedAddresses = @get('providedAddresses')
    switch number.get('addressRequirements')
      when 'local'
        address = providedAddresses.findWhere(countryCode: number.get('country'))
        sid = address.get('sid') if address
      when 'foreign'
        foreignAddresses = providedAddresses.filter((address) => address.get('countryCode') isnt number.get('country'))
        sid = foreignAddresses[0].get('sid') if foreignAddresses?.length
      when 'any'
        sid = providedAddresses.at(0).get('sid') if providedAddresses.length > 0

    sid

  module.exports = Model
