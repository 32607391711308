BackgridCell = require('helpers/backgrid-cell')
BaseGrid = require('base/grid')
BaseView = require('base/view')
CallNoteTemplatesModel = require('./model')
CallNoteTemplatesCollection = require('./collection')
EditCallNoteTemplatesModal = require('./modals/editor-view')

class CallNoteTemplatesView extends BaseView

  template: require('./template')

  className: 'callNoteTemplates'

  regions:
    grid: 'div.note-grid'
    modal: 'div.note-modal-container'

  events:
    'click a.add': 'addCallNoteTemplate'

  initialize: (options) ->
    { @accountId, @teams } = options
    @collection = new CallNoteTemplatesCollection()

  onRender: =>
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#edit-call-note-template-modal">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'enabled',    label: 'Enabled', cell: BackgridCell.checkbox(), editable:true },
      { name: 'name',       label: 'Name',    cell: 'string' },
      { name: 'teamNames',  label: 'Teams',   cell: 'string' },
      { name: '',           label: 'Update',  cell: actionCell },
      { name: '',           label: 'Delete',  cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No templates found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    if options.model.changed?.enabled isnt undefined
      data = options.model.toJSON()
      data['accountId'] = @accountId
      data['teamsIds'] = options?.model?.get('teams').pluck('id')
      data['teams'] = @model?.get('teams').toJSON()
      options.model.persist(data: data)
    else
      model = options.model.clone()
      @showChildView('modal', new EditCallNoteTemplatesModal(
        accountId: @accountId
        model: model
        collection: @collection
        existingTeams: @teams
      ))

  addCallNoteTemplate: =>
    @showChildView('modal', new EditCallNoteTemplatesModal(
      accountId: @accountId
      model: new CallNoteTemplatesModel()
      collection: @collection
      existingTeams: @teams
    ))

module.exports = CallNoteTemplatesView