import React, { useEffect, useState } from 'react'

import { useUpdateAccountDialerSettings } from '../../../store/api/accounts-api'
import { ActionType, useSupportSettingsDispatch, useSupportSettingsState } from '../utils/contextReducer'

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useUploadFile } from '../../../store/api/file-upload'

const prepareAudioData = (data: any[]) =>
  Object.assign(
    {},
    ...data.map((item: any, i: number) => ({
      [`files[${i}].file`]: item.file,
      [`files[${i}].fileName`]: item.fileName,
      [`files[${i}].setting`]: item.setting
    }))
  )

export default React.memo(SaveAllSettingsButton)
function SaveAllSettingsButton() {
  const state = useSupportSettingsState()
  const dispatch = useSupportSettingsDispatch()
  const { featureVisibilities, s3RecordingBucketConfirmation, audioUploadSettings } = state
  let { accountSettings } = state

  const [isLoading, setIsLoading] = useState(false)
  const uploadFile = useUploadFile()
  const updateAccountDialerSettings = useUpdateAccountDialerSettings()

  useEffect(() => {
    return () => dispatch({ type: ActionType.RESET_TO_ORIGINAL_SETTINGS, payload: 'All' })
  }, [dispatch])

  const onSave = async () => {
    try {
      setIsLoading(true)

      const promises = [] as any

      if (s3RecordingBucketConfirmation) {
        dispatch({ type: ActionType.RESET_S3_RECORDING_BUCKET_CONFIRM })
      } else {
        const settingsName = 'settings.s3RecordingBucket'
        dispatch({ type: ActionType.RESET_TO_ORIGINAL_SETTINGS, payload: settingsName })
        accountSettings = {
          ...state.accountSettings,
          [settingsName]: state.originalSettings.accountSettings[settingsName]
        }
      }

      const playModel = {
        'settings.sipRouting[id]': state.accountSettings['settings.sipRouting']?.id
      }

      const visibilities = {}
      featureVisibilities.forEach((visibility, i) => {
        // @ts-ignore
        visibilities[`featureVisibilities[${i}].feature`] = visibility.feature
        // @ts-ignore
        visibilities[`featureVisibilities[${i}].visible`] = visibility.visible
      })
      promises.push(updateAccountDialerSettings({ body: { ...accountSettings, ...visibilities, ...playModel } }))

      if (audioUploadSettings.length) {
        promises.push(
          uploadFile({
            accountId: accountSettings['account.id'],
            body: prepareAudioData(audioUploadSettings)
          }).then(response => {
            for (const file of response?.success?.payload) {
              dispatch({
                type: ActionType.CHANGE_ACCOUNT_SETTINGS,
                payload: { ['settings.' + file.setting]: file.url }
              })
            }
          })
        )
      }

      await Promise.all(promises)

      toastr.success('Support Settings Saved')
      dispatch({ type: ActionType.UPDATE_ORIGINAL_SETTINGS })
      setIsLoading(false)
    } catch (error) {
      toastr.error(`An error has occurred, ${error}`)
      setIsLoading(false)
    }
  }

  return (
    <RdnaButton
      style={{ width: 'fit-content' }}
      disabled={isLoading}
      text={isLoading ? 'Saving...' : 'Save'}
      name="save-support-settings"
      onClick={onSave}
    />
  )
}
