import React, { useRef, useState } from 'react'
import { debounce } from 'lodash-es'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'

type Props = {
  onUpdate: (a: any) => void
}

export default function NameFilter({ onUpdate }: Props) {
  const [input, setInput] = useState('')
  const debouncedChange = useRef(debounce((value: string) => onUpdate(value), 400)).current

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInput(e.target.value)
    debouncedChange(e.target.value)
  }

  return (
    <RdnaTextInput
      name={'Search filter name'}
      placeholder={'Search Template Name'}
      className={'search-filter-input'}
      value={input}
      onChange={handleChange}
    />
  )
}
