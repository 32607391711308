BaseView = require('base/view')
BaseModel = require('base/model')
BaseGrid = require('base/grid')
AddressModel = require('./../address-model')
AddressCollection = require('./../address-collection')

class Step3CreatingModal extends BaseView
  template: require('./step-3-template')

  bindings:
    '.provisioning-numbers' : 'text: provisioningNumbers'
    '.type-country'         : 'text: type'
    '.address-country'      : 'options:countries, optionsDefault:selectedCountry, disabled: isLocal'

  computeds:
    provisioningNumbers:
      deps:['numbers']
      get: (numbers) -> value.get('number') for value in numbers if numbers
    countries:
      deps:['countryList', 'country', 'type']
      get: (countryList, country, type) ->
        if type is 'foreign'
          (countries for countries in countryList when countries.value isnt country)
        else
          countryList
    selectedCountry:
      deps: ['country', 'countryList', 'type']
      get: (country, countryList, type) ->
        for value in countryList when value.value is country
          selectedValue = value
        if type is 'foreign' then null else selectedValue

  ui:
    addressCountry  : '.address-country'
    region          : '.address-region'
    city            : '.address-city'
    street          : '.address-street'
    postalCode      : '.address-postal-code'
    businessName    : '.address-business'
    friendlyName    : '.address-friendly'

  initialize: ->
    @viewModel = new Backbone.Model()
    selectedNumbers = @model.get('selectedNumbers')
    if @model.get('addressLocalRequired')
      @viewModel.set(
        numbers: selectedNumbers.where(addressRequirements: 'local'),
        type: 'local'
        nameRequired: 'addressLocalRequired'
        isLocal: true
      )
    else if @model.get('addressForeignRequired')
      @viewModel.set(
        numbers: selectedNumbers.where(addressRequirements: 'foreign'),
        type: 'foreign'
        nameRequired: 'addressForeignRequired'
        isLocal: false
      )
    else if @model.get('addressAnyRequired')
      @viewModel.set(
        numbers: selectedNumbers.where(addressRequirements: 'any'),
        type: 'any'
        nameRequired: 'addressAnyRequired'
        isLocal: false
      )

  saveAddress: (callback) =>
    addresses = new AddressCollection()
    address = new AddressModel(
      addressCountry: @ui.addressCountry.val()
      region: @ui.region.val()
      city: @ui.city.val()
      street: @ui.street.val()
      postalCode: @ui.postalCode.val()
      businessName: @ui.businessName.val()
      friendlyName: @ui.friendlyName.val()
    )
    addresses.add(address)
    addresses
      .setParams(accountId: @model.get('accountId'))
      .create(ladda: @$('.next')[0])
      .done(=>
        if addresses.at(0).get('created')
          @model.set(@viewModel.get('nameRequired'), false)
          callback()
        else
          toastr.warning('Cannot create address. The address you have provided cannot be validated')
      )

module.exports = Step3CreatingModal
