import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceCaseIcon({ size = 16, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 14" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.943 3.863H2.056c-.654 0-1.189.535-1.189 1.189v6.835c0 .654.535 1.189 1.19 1.189h11.886c.655 0 1.189-.535 1.189-1.189V5.052c0-.654-.534-1.189-1.189-1.189zM4.731 2.675H5.92c.178 0 .296-.12.296-.298v-.594h3.567v.594c0 .178.119.298.297.298h1.189c.178 0 .297-.12.297-.298v-.743C11.566.744 10.823 0 9.932 0H6.039c-.892 0-1.606.713-1.606 1.605v.772c0 .178.12.298.298.298z"
        fill={color || Colors.SALESFORCE_CASE}
      />
    </svg>
  )
}

export default SalesforceCaseIcon
