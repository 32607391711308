import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import RdnaSection from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import { DeleteModalProps, EventProps, HolidayProps } from '../../types'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import { EventColumns, initEvent } from './const'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useHolidayEventDelete, useHolidayEvents } from '../../api'
import { DELETE, EDIT } from '../../const'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import useToast from '../../../common/hooks/useToast'

type Props = {
  setPage: any
  holiday: HolidayProps
  setHoliday: any
  event: EventProps
  setEvent: any
}

export default function EditHolidayContent({ setPage, holiday, setHoliday, event, setEvent }: Props) {
  const toastr = useToast()
  const [payload, , loading, refetch] = useHolidayEvents({ query: { holidayScheduleId: holiday.id } })
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isEventDeleteOpen, setIsEventDeleteOpen] = useState(false)
  const deleteHolidayEvent = useHolidayEventDelete()

  const handleActionMenu = useCallback(
    (action: string, event: EventProps) => {
      setEvent(event)
      if (action === DELETE) {
        setIsEventDeleteOpen(true)
      } else if (action === EDIT) {
        setPage(2)
      }
    },
    [setEvent, setPage]
  )

  async function handleDelete() {
    setIsLoadingDelete(true)
    try {
      await deleteHolidayEvent({ meta: { id: event.id } })
      setIsEventDeleteOpen(false)
      refetch()
    } catch (e) {
      toastr.error(e)
    }
    setIsLoadingDelete(false)
  }

  const memoPayload = useMemo(() => {
    return payload?.map((template: EventProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={[{ text: EDIT }, { text: DELETE }]} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  return (
    <>
      <FormSection>
        <RdnaTextInput
          name={'holidayName'}
          label={'Name'}
          placeholder={'Name'}
          value={holiday.name}
          onChange={e => {
            setHoliday({ ...holiday, name: e.target.value })
          }}
        />
      </FormSection>
      <FormSection>
        <RdnaTextInput
          name={'holidayDescription'}
          label={'Description'}
          placeholder={'Description'}
          value={holiday.description}
          onChange={e => {
            setHoliday({ ...holiday, description: e.target.value })
          }}
        />
      </FormSection>
      <RdnaFormLayout>
        <RdnaHeaderSection
          headingText={'Holiday Schedule'}
          rightColumn={
            <RdnaButton
              text={'Add Event'}
              disabled={!holiday.name}
              onClick={() => {
                setEvent(initEvent)
                setPage(2)
              }}
            />
          }
        />
        <RdnaSmartTable
          // @ts-ignore
          columns={EventColumns}
          data={memoPayload || []}
          noResultState={{ title: 'No Holiday Events Found.', subtitle: '' }}
          isFetching={!!loading}
        />
      </RdnaFormLayout>
      <ConfirmEventDeleteModal
        open={isEventDeleteOpen}
        onClose={setIsEventDeleteOpen}
        onConfirm={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  )
}

const ConfirmEventDeleteModal = ({ open, onClose, onConfirm, isLoadingDelete }: DeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Holiday Event`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`Are you sure you want to delete this holiday event. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}

export const FormSection = styled(RdnaSection)`
  > div {
    margin-bottom: 0;
  }
`

export const FormDateRange = styled(RdnaFormLayout)`
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important;

  & > div:first-child {
    margin-right: 10px;
  }
  & > div {
    width: 49%;
  }
`
