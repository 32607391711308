import Cookies from 'js-cookie'

const DEFAULT_EXPIRATION = 30
const LOCAL_DOMAIN = 'localhost'
const PATH = ''

// @ts-ignore
const envName = process.env.RD_ENV || import.meta.env.MODE

export const SESSION_ID_KEY = 'session-id'
// webapp & softphone do not have a REACT_APP_RD_ENV
const getKey = (key: string) => `rdna-${key}-${envName}`
export const getDomain = () => {
  if (window.location.hostname === LOCAL_DOMAIN) {
    return LOCAL_DOMAIN
  } else {
    return window.location.hostname.split('.').slice(1).join('.')
  }
}
type overrideType = {
  secure?: boolean
  sameSite?: 'Strict' | 'Lax' | 'None'
  path: string
  partitioned?: boolean
}

const optionOverrides: overrideType =
  envName === 'test' ? { secure: false, path: PATH } : { secure: true, sameSite: 'None', path: PATH }
export const cookieUtils = {
  getCookie: (key: string): string | undefined => Cookies.get(getKey(key)),
  removeCookie: (key: string, options?: { domain?: string }) => {
    const removeOptions = {
      domain: options?.domain || getDomain(),
      ...optionOverrides
    }
    Cookies.remove(getKey(key), removeOptions)
    Cookies.remove(getKey(key), {
      ...removeOptions,
      partitioned: true
    })
  },
  setCookie: (key: string, val: string, options?: { expires?: number; domain?: string }) => {
    const setOptions = {
      domain: options?.domain || getDomain(),
      expires: options?.expires || DEFAULT_EXPIRATION,
      ...optionOverrides
    }
    return Cookies.set(getKey(key), val, setOptions)
  }
}

export async function handleCookieAccessInit() {
  if (!document.hasStorageAccess) {
    // Storage Access API is not supported so best we can do is
    // hope it's an older browser that doesn't block 3P cookies.
    return true
  } else {
    // Check whether access has been granted via the Storage Access API.
    const hasAccess = await document.hasStorageAccess()
    if (!hasAccess) {
      try {
        await document.requestStorageAccess()
        return true
      } catch (err) {
        // Access was not granted and it may be gated behind an interaction
        return false
      }
    } else {
      // Access was already granted
      return true
    }
  }
}
