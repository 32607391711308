import React, { useState } from 'react'
import styled from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'

import { useUser } from '../../../../common/services/UserContext'
import {
  useCallRecordingState,
  isCallRecordingLockedSelector,
  isCallRecordingOverrideOptionNoneSelector
} from '../../../utils/contextReducer'

export default React.memo(CallRecordingWarningBox)
function CallRecordingWarningBox() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const [isCloseWarningBoxOpen, setCloseWarningBoxOpen] = useState(true)
  const showCallRecordingWarning =
    isCallRecordingLockedSelector(state) &&
    !isEditingAllowed &&
    isCloseWarningBoxOpen &&
    isCallRecordingOverrideOptionNoneSelector(state)

  return (
    <>
      {showCallRecordingWarning && (
        <CallRecordingWarning>
          <RdnaText variant="h6">
            Call Recording Features for this account are currently disabled. To activate call recordings, select a call
            recording direction (Inbound, Outbound, or All) from the &quot;Call Recording&quot; options and agree to the
            User Agreement.
          </RdnaText>
          <ClickableText
            onClick={() => {
              setCloseWarningBoxOpen(false)
            }}
          >
            Dismiss this warning permanently
          </ClickableText>
        </CallRecordingWarning>
      )}
    </>
  )
}

const ClickableText = React.memo(styled(RdnaText)`
  margin: 20px 0 0;
  display: block;
  textdecorationline: underline;
`)

const CallRecordingWarning = styled.div`
  background: #fdd835;
  padding: 20px;
  margin: 0 0 20px;
  border-radius: 18px;
`
