import styled from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'

export const FormLayout = styled(RdnaFormLayout)`
  background-color: rgb(255, 255, 255);
  border-radius: ${props => props.theme.spacing}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  margin-bottom: ${props => props.theme.spacing * 4}px !important;
  padding: ${props => props.theme.spacing * 3}px;

  .locked-content > button {
    margin: 0;
  }
`

export const SettingSelect = styled(RdnaAutocomplete)`
  width: ${props => props.theme.spacing * 45}px;
  margin-left: ${props => props.theme.spacing * 2}px;
`

export const HeaderTitle = styled(RdnaText)`
  line-height: ${props => props.theme.spacing * 4}px;
  margin-left: ${props => props.theme.spacing * 2}px;
`

export const SectionHeader = styled(RdnaHeaderSection)`
  div {
    display: flex;
  }
`

export const CreateSkillsButton = styled(RdnaButton)`
  margin-left: ${props => props.theme.spacing * 2}px;
  width: ${props => props.theme.spacing * 14}px;
`

export const CellText = styled(RdnaText)`
  color: black;
  padding: 0 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial !important;
  &.deleted {
    color: grey;
  }
`
