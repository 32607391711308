import React from 'react'

// utils
import { useCallRecordingDispatch } from '../../../utils/contextReducer'

// components
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'

type CallRecordingToggleProps = {
  isDisabled: boolean
  isChecked: boolean
  accountSetting: string
}

const CallRecordingToggle = ({ isDisabled, isChecked, accountSetting }: CallRecordingToggleProps) => {
  const dispatch = useCallRecordingDispatch()
  const setting = 'settings.' + accountSetting

  const handleChange = (value: boolean) => {
    dispatch({ type: 'CHANGE_ACCOUNT_SETTINGS', payload: { [setting]: value } })
  }

  return <RdnaToggleSwitch disabled={isDisabled} value={isChecked} onChange={handleChange} margin={'none'} />
}

export default React.memo(CallRecordingToggle)
