StepModel = require('../../model')

class PromptStepModel extends StepModel

  defaults: ->
    result =
      type: 'Prompt'
      info: '''Specify a maximum digit code the caller must enter in order to be routed to a specific path within your call flow.
                The caller must press the pound (#) key after entering the maximum digit code to proceed to the next step.'''
      fixedPlaceholders: [ 'Any Response', 'No Response' ]
      leaf: false
      args:
        repeatPrompt: '0'
        spacing: '10'
        maxDigits: '20'
      dynamicCallFlows: App.session.user.can('call-flows:callouts:edit')

    result['fixedPlaceholders'].shift() if not App.session.user.can('call-flows:callouts:edit')
    result

  placeholders: ->
    for i in [1..9999] by 1
      nextPlaceholder = @lpad(i.toString(), 1)
      break if not @args()[nextPlaceholder]
    [ nextPlaceholder ]

  orderChildren: (children) ->
    result = []
    keys = (key for key of @args())
    for k in keys.sort()
      assignedChildId = parseInt(@args()[k])
      if assignedChildId > 0
        result.push(child if parseInt(child.get('id'))) is assignedChildId for child in children
    result

  getIdArgsStorage: (storage) ->
    keys = []
    keys.push(key) for key in Object.keys(storage) when not isNaN(key)
    return keys

  errors: ->
    messages = []
    selectors = []

    ids = @getIdArgsStorage(@args())
    spacing = @args()['spacing']
    repeatPrompt = @args()['repeatPrompt']
    maxDigits = @args()['maxDigits']
    for id in ids when id.length > parseInt(maxDigits)
      messages.push('Length of entered number must be less max digits in Prompt step')
      selectors.push('.maxDigits')
    if spacing isnt '' and not @isInteger(spacing)
      messages.push('Must be a positive integer or 0')
      selectors.push('.spacing')
    if repeatPrompt isnt '' and not @isInteger(repeatPrompt)
      messages.push('Must be a positive integer or 0')
      selectors.push('.repeatPrompt')
    if maxDigits is '' or (not @isInteger(maxDigits) or parseInt(maxDigits) is 0)
      messages.push('Must be a positive integer from 1 to 20')
      selectors.push('.maxDigits')

    audioErrors = @audioErrors(['audio', 'invalidAudio'])
    errors = _.zip(messages, selectors)
    if audioErrors.length > 0
      errors = _.union(errors, audioErrors)

    errors

  lpad: (str, length) ->
    return str if str.length is length
    while (str.length < length)
      str = '0' + str
    str

module.exports = PromptStepModel
