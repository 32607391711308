import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  UIEvent,
  SetStateAction,
  Dispatch
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import { UnstyledButton } from '../Button'
import RdnaText from '../RdnaText'
import OverflowFade from '../OverflowFade'
import { Colors } from '../../constants/colors'

const TAB_TRANSITION_TIME = '0.25s'

type TabConfigItem<T extends string> = {
  id: T
  title?: string
  Content: React.ReactNode
  Icon?: React.ReactNode | (({ size, color }: { size?: number; color?: Colors }) => React.ReactNode)
  disabled?: boolean
}
export type HorizontalTabProps<T extends string> = {
  tabs: TabConfigItem<T>[]
  onTabChange?: (activeTabKey: T) => void
  tabContentStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  headerTitle?: string
  Right?: React.ReactNode
  height?: number
  activeTab?: T | null
  renderIndividually?: boolean
  showHorizontalLine?: boolean
  separator?: boolean
  className?: string
  tabLabel?: string
}
export default function RdnaHorizontalTab<T extends string>({
  tabs,
  onTabChange,
  tabContentStyle,
  headerStyle,
  headerTitle,
  Right,
  height: overrideHeight,
  activeTab: programmaticActiveTab,
  renderIndividually,
  showHorizontalLine = true,
  separator = false,
  className,
  tabLabel
}: HorizontalTabProps<T>) {
  const { palette } = useTheme();
  const [activeLayout, setActiveLayout] = useState({ width: 0, left: 0 })
  const [navigatorHeight, setNavigatorHeight] = useState<number | undefined>(undefined)
  const tabKeys = useMemo(() => tabs.map(t => t.id), [tabs]) as T[]
  const refs = useMemo(
    () =>
      tabKeys.reduce((acc, key) => {
        acc.set(key, React.createRef())
        return acc
      }, new Map<T, React.RefObject<HTMLDivElement>>()),
    [tabKeys]
  )

  const [activeTab, setActiveTab] = useState<T>(programmaticActiveTab || tabs[0].id)
  const [activeIndex, setActiveIndex] = useState(tabKeys.indexOf(activeTab))

  useEffect(() => {
    if (programmaticActiveTab) setActiveTab(programmaticActiveTab)
  }, [programmaticActiveTab])

  useEffect(() => {
    const activeRef = refs.get(activeTab)
    if (activeRef && activeRef.current) {
      const { clientWidth, offsetLeft } = activeRef.current
      setActiveLayout({ width: clientWidth, left: offsetLeft })
    }
    setActiveIndex(tabKeys.indexOf(activeTab))
    if (onTabChange) onTabChange(activeTab)
  }, [activeTab, onTabChange, refs, tabKeys])

  return (
    <div className={className} style={{ overflowX: 'hidden' }}>
      <TabContainer data-testid="horizontal-tab-header" style={headerStyle}>
        {!!headerTitle && (
          <TabHeader>
            <RdnaText variant="h4">{headerTitle}</RdnaText>
          </TabHeader>
        )}

        <TabListContainer>
          <TabList role="tablist" $isLabeled={!!tabLabel} $hasHeaderTitle={!!headerTitle}>
            {tabLabel && <RdnaText style={{ marginRight: '-6px' }}>{tabLabel}</RdnaText>}

            {tabs.map(({ Icon, title, id, disabled = false }, index) => (
              <TabButton
                key={id}
                role="tab"
                className={`tab-container-${id}`}
                data-testid={`${id.toLowerCase()}-tab`}
                onClick={() => setActiveTab(id)}
                disabled={disabled}
                $separator={separator}
                $isActive={activeTab === id}
                $isLast={index === tabs.length - 1}
              >
                <RdnaText
                  variant="h6"
                  color={activeTab === id ? 'link' : disabled ? 'disabled' : 'neutral'}
                  ref={refs.get(id)}
                >
                  {title}
                </RdnaText>

                {!!Icon
                  ? typeof Icon === 'function'
                    ? Icon({ color: activeTab === id ? palette.primary.dark : Colors.TRANSPARENT })
                    : Icon
                  : null}
              </TabButton>
            ))}
          </TabList>

          {showHorizontalLine ? (
            <>
              <HorizontalLine />
              <ActiveHorizontalLine style={{ left: activeLayout.left, width: activeLayout.width }} />
            </>
          ) : null}
        </TabListContainer>

        {!!Right && <RightContainer>{Right}</RightContainer>}
      </TabContainer>

      <TabContentContainer
        data-testid={'horizontal-tab-content-container'}
        className={'horizontal-tab-content-container'}
        role="tabpanel"
        style={{
          height: overrideHeight || navigatorHeight,
          minHeight: overrideHeight || navigatorHeight,
          maxHeight: overrideHeight
        }}
        onTransitionEnd={() => {
          if (navigatorHeight !== undefined) {
            setNavigatorHeight(undefined)
          }
        }}
      >
        {renderIndividually ? (
          <TabContent<T>
            key={`horizontal-tab-content-${tabs[activeIndex].id}`}
            disableAnimation
            index={activeIndex}
            Content={tabs[activeIndex].Content}
            activeIndex={activeIndex}
            style={tabContentStyle}
            setActiveTab={setActiveTab}
            hasHeightOverride={!!overrideHeight}
            setNavigatorHeight={setNavigatorHeight}
          />
        ) : (
          tabs.map(({ Content, id }, i) => (
            <TabContent<T>
              key={`horizontal-tab-content-${id}`}
              index={i}
              Content={Content}
              activeIndex={activeIndex}
              style={tabContentStyle}
              setActiveTab={setActiveTab}
              hasHeightOverride={!!overrideHeight}
              setNavigatorHeight={setNavigatorHeight}
            />
          ))
        )}
      </TabContentContainer>
    </div>
  )
}

type TabProps<T> = {
  index: number
  activeIndex: number
  Content: React.ReactNode
  style?: React.CSSProperties
  setActiveTab: Dispatch<SetStateAction<T>>
  setNavigatorHeight: (height: number) => void
  hasHeightOverride: boolean
  disableAnimation?: boolean
}

const TabContent = <T,>({
  index,
  activeIndex,
  Content,
  style,
  setActiveTab,
  setNavigatorHeight,
  hasHeightOverride,
  disableAnimation = false
}: TabProps<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isActive = activeIndex === index
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const isActiveRef = useRef(isActive)
  const scrollHeightRef = useRef<number | null>(null)
  scrollHeightRef.current = containerRef.current && containerRef.current.scrollHeight

  // In order to animate the changing navigator height, we need to set
  // explicit "from" and "to" heights.
  useLayoutEffect(() => {
    // Only hard-set heights on tab change
    if (!hasHeightOverride && isActiveRef.current !== isActive) {
      // When this tab is becoming inactive set "from" height on the navigator.
      if (!isActive && scrollHeightRef.current !== null) {
        setNavigatorHeight(scrollHeightRef.current)
      }

      // When this tab is becoming active, set "to" height on the navigator
      // once "from" height has already been set.
      const timeoutId = setTimeout(() => {
        if (isActive && scrollHeightRef.current !== null) {
          setNavigatorHeight(scrollHeightRef.current)
        }
      })

      return () => clearTimeout(timeoutId)
    }
    isActiveRef.current = isActive
  }, [isActive, setNavigatorHeight, Content, hasHeightOverride])

  const providerValue = useMemo(
    () => ({
      active: isActive,
      containerRef,
      isScrolledToBottom,
      setActiveTab
    }),
    [isActive, isScrolledToBottom]
  )

  const additionalStyle = useMemo(
    () =>
      hasHeightOverride
        ? {
            display: 'flex' as const,
            flex: 1
          }
        : {},
    [hasHeightOverride]
  )

  // We only show the fadeout for nav content with a height override.
  // otherwise content expands to its own height.
  const fadeDisabled = !hasHeightOverride
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const isAtBottom =
      Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === e.currentTarget.clientHeight
    setIsScrolledToBottom(isAtBottom)
  }

  return (
    <>
      <ViewContainer
        ref={containerRef}
        data-testid={`tab-content-container${index}`}
        index={index}
        $active={index === activeIndex}
        $activeIndex={activeIndex}
        $disableAnimation={disableAnimation}
        onScroll={handleScroll}
        style={{ ...style, ...additionalStyle }}
      >
        <HorizontalTabContext.Provider value={providerValue}>{Content}</HorizontalTabContext.Provider>
      </ViewContainer>
      <OverflowFade disabled={fadeDisabled} trigger={Content} containerRef={containerRef} />
    </>
  )
}

type HorizontalTabState = {
  active: boolean
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  isScrolledToBottom: boolean
  setActiveTab: Dispatch<SetStateAction<any>>
}

export const HorizontalTabContext = React.createContext<HorizontalTabState | undefined>(undefined)
export const useHorizontalTabState = () => {
  const value = useContext(HorizontalTabContext)
  /* istanbul ignore if */
  if (!value) {
    throw new Error('useHorizontalTabState must be called from within HorizontalTabContext Provider')
  }
  return value
}

export const createFilterTabs = <T extends string>(
  options: readonly T[],
  iconByOption?: Partial<Record<T, TabConfigItem<T>['Icon']>>
) =>
  options.map(option => ({
    id: option,
    title: option,
    Icon: iconByOption?.[option],
    Content: null
  }))

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
`

const TabList = styled.div<{ $isLabeled: boolean; $hasHeaderTitle: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${({ $hasHeaderTitle }) => ($hasHeaderTitle ? 'end' : undefined)};
  gap: ${({ theme, $isLabeled }) => ($isLabeled ? theme.spacing * 3.5 : theme.spacing * 5)}px;
`

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 4;
`
const TabListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const TabButton = styled(UnstyledButton)<{ $separator: boolean; $isActive: boolean; $isLast: boolean }>`
  display: flex;
  align-items: center;
  transition: 0.1s;
  gap: 0 ${({ theme }) => theme.spacing / 2}px;
  padding: ${({ theme }) => theme.spacing}px 0;
  color: ${({ theme, $isActive }) => ($isActive ? theme.palette.primary.dark : undefined)};

  ${({ $separator, $isLast }) =>
    $separator &&
    !$isLast &&
    css`
      &:after {
        content: '|';
        position: relative;
        right: -16px;
        font-weight: bold;
        color: black;
      }
    `}
`

const HorizontalLine = styled.div`
  background-color: ${props => props.theme.palette.primary.light};
  border-radius: 3px;
  height: 2px;
`

const ActiveHorizontalLine = styled(HorizontalLine)`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.palette.primary.dark};
  transition: ${TAB_TRANSITION_TIME};
`

const RightContainer = styled.div`
  display: flex;
  flex: 0;
  margin-left: ${({ theme }) => theme.spacing}px;
`

const TabContentContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0;
  flex-direction: column;
  transition: ${TAB_TRANSITION_TIME};
`

const ViewContainer = styled.div<{ $active: boolean; index: number; $activeIndex: number; $disableAnimation?: boolean }>`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  pointer-events: ${({ $active }) => ($active ? 'inherit' : 'none')};
  overflow: auto;
  position: ${({ $active }) => ($active ? 'relative' : 'absolute')};
  padding-top: ${({ theme }) => theme.spacing * 4}px;
  ${({ $disableAnimation, $activeIndex, index, $active }) =>
    !$disableAnimation &&
    css`
      transform: translate(${(index - $activeIndex) * 100}%, 0);
      transition: ${$active ? TAB_TRANSITION_TIME : 0};
      scroll-behavior: smooth;
    `}
`
