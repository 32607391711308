import React, { useEffect, useMemo, useState } from 'react'

import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import BlockHeader from './board/BlockHeader'
import { BlockBody, SectionSearch } from './board/Containers'
import Card from './board/Card'
import { getReportGroups, ReportGroup } from './board/Models'
import { useUser } from '../contexts/UserContext'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'

export default React.memo(Reports)
function Reports() {
  const userSettings = useUser()
  const [searchValue, setSearchValue] = useState<string>('')
  const reportGroups: ReportGroup[] = useMemo(() => {
    if (userSettings.licenses.salesSupport) return getReportGroups('salesSupport')
    else if (userSettings.licenses.support) return getReportGroups('support')
    else if (userSettings.licenses.admin) return getReportGroups('admin')
    else if (userSettings.licenses.supervisor) return getReportGroups('supervisor')
    else if (userSettings.licenses.intelligentDialer) return getReportGroups('intelligentDialer')
    else return []
  }, [userSettings.licenses])
  const [filteredReports, setFilteredReports] = useState<ReportGroup[]>(reportGroups)

  useEffect(() => {
    const filtered = reportGroups.map(group => {
      return {
        title: group.title,
        reports: group.reports.filter(
          report =>
            report.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            report.description.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
    })
    setFilteredReports(filtered)
  }, [reportGroups, searchValue])

  return (
    <>
      <SectionHeader
        headingText="Reports"
        descriptionText="All reports and dashboards related to the RingDNA Communications Hub."
      />
      <SectionSearch>
        <RdnaTextInput
          search
          name="search"
          value={searchValue}
          placeholder={'Search Reports'}
          onChange={e => setSearchValue(e.target.value)}
        />
      </SectionSearch>
      <div>
        {filteredReports.map(group => (
          <div key={group.title}>
            {group.reports.length ? (
              <>
                <BlockHeader title={group.title} />
                <BlockBody>
                  {group.reports.map(report => (
                    <Card key={report.path} {...report} />
                  ))}
                </BlockBody>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
