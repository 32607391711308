import styled from 'styled-components'
export const IconSize = 24

export const Section = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: ${({ theme }) => theme.spacing}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  margin: ${({ theme }) => theme.spacing * 2}px;
  padding: ${({ theme }) => theme.spacing * 3}px;

  .rdna-text {
    margin: 0 ${({ theme }) => theme.spacing * 2}px;
  }

  .support-input {
    width: ${({ theme }) => theme.spacing * 50}px;
    margin: 0;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`

export const SectionSubHeader = styled.div`
  margin: ${({ theme }) => theme.spacing * 3}px;
`

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacing * 3}px;
`

export const PackageCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .rdna-button {
    margin: ${({ theme }) => theme.spacing * 2}px 0;
  }
`

export const Settings = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
