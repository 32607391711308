import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function CallLogIcon({ color = Colors.N100, size = 60 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="call-log-icon">
      <path
        d="M11.1097 5.5L39.6673 5.5L44.3982 10.3492L49.1341 15.2035V54.5H11.1097L11.1097 5.5Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1219 40C35.5101 40 39.878 35.5228 39.878 30C39.878 24.4772
      35.5101 20 30.1219 20C24.7338 20 20.3658 24.4772 20.3658 30C20.3658 35.5228 24.7338 40 30.1219 40Z"
        stroke={color}
      />
      <path
        d="M26.7334 27.8725L30.122 31.4692L34.5082 27.1276"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
