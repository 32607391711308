BaseView          = require('base/view')
BaseGrid          = require('base/grid')
BaseCollection    = require('base/collection')
BackgridCell      = require('helpers/backgrid-cell')
NumberCollection  = require('./collection')
SmartNumberWidget = require('widgets/smart-numbers-widget/main-view')
ReactComponent = require('src/ReactComponent').default
SmartNumbersTable      = require('src/components/smart-numbers-table').default
ProductConstant = require('@ringdna/common/src/constants/products')

class SmartNumbersView extends BaseView

  template: require('./template')

  bindings:
    'a.assign-smart-number' : 'toggle: not(readOnly)'
    '.remove'               : 'disabled: readOnly'

  regions:
    modal                   : 'div.modal-container'
    smartNumbersTableReact  : 'div.smart-numbers-react'

  events:
    'click button.assign-smart-number' : 'addSmartNumbers'

  onRender: ->
    @showChildView('smartNumbersTableReact', new ReactComponent({ component: SmartNumbersTable, theme: ProductConstant.Products.ADMIN, props: { callflowId: @model.get('id') }}))

  addSmartNumbers: =>
    if @model.isNew()
      toastr.warning('Please save call flow before assign smart numbers.')
      return
    view = new SmartNumberWidget(
      accountId: @model.get('account').id,
      objectId: @model.id
      type: 'call-flow'
    )
    @showChildView('modal', view)
    @listenTo(view, 'assigned', @render)

module.exports = SmartNumbersView
