import * as React from 'react'

import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function CloudIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M10.6839 7.55799C11.2019 7.01688 11.923 6.6813 12.7206 6.6813C13.7809 6.6813 14.7059 7.27402 15.1985 8.15393C15.6266 7.96216 16.1004 7.8555 16.599 7.8555C18.5113 7.8555 20.0616 9.42332 20.0616 11.3572C20.0616 13.2914 18.5113 14.8592 16.599 14.8592C16.3656 14.8592 16.1375 14.8358 15.9169 14.7911C15.4831 15.5668 14.6565 16.091 13.7077 16.091C13.3105 16.091 12.9348 15.999 12.6003 15.8354C12.1605 16.8725 11.136 17.5996 9.94188 17.5996C8.69836 17.5996 7.63857 16.8108 7.23177 15.7045C7.05399 15.7423 6.86981 15.762 6.68082 15.762C5.20027 15.762 4 14.5463 4 13.0464C4 12.0412 4.53928 11.1636 5.34053 10.6941C5.17556 10.3135 5.08381 9.89355 5.08381 9.45199C5.08381 7.72705 6.48062 6.32874 8.20347 6.32874C9.21498 6.32874 10.1139 6.8109 10.6839 7.55799Z"
        stroke={color}
        strokeWidth={0.75}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloudIcon
