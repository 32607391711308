BaseModel = require('base/model')

module.exports = class ResultSalesforceUserModel extends BaseModel

  defaults:
    salesforceId      : ''
    userId            : ''
    name              : ''
    userDisplayName   : ''
    email             : ''
    title             : ''
    locationName      : ''
    extensionCode     : ''
    numberCreated     : false
    numberDescription : ''
    userCreated       : false
    userDescription   : ''
    number            : ''
    numberId          : ''
