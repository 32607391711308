BaseModel = require('base/model')

class DialerSettingsModel extends BaseModel

  initialize: (options) =>
    { @accountId } = options
    @settings = [ 'callDispositionPrompt', 'callDispositionRequiredDate', 'multiMatchRequiredDate', 'callNoteTemplates',
                  'hideQueueCalls', 'inboundCallTone', 'manageCallDispositions', 'messaging', 'messagingFeaturesEnabled',
                  'undeliverableSMSOwner', 'relatedRecords', 'sfdcMultipleMatchAccountField',
                  'sfdcMultipleMatchContactField', 'sfdcMultipleMatchLeadField', 'sfdcCreateTasksDelay',
                  'localPresenceUseVerifiedNumbers', 'smsTemplates', 'voicemailTranscriptionEnabled',
                  'voicemailTranscriptionByCountry', 'voicemailDropDisposition', 'realtimeAlerts',
                  'appendTransferInfo', 'complianceHours', 'manageCallDispositionRequiredByTeam',
                  'callDispositionRequiredState', 'webLeadsRecordVisibility', 'customUserStatusEnabled',
                  'customUserStatusAutoSwitch', 'customUserStatusTimeOverride', 'sfdcMultiMatchSetting',
                  'soundIncoming', 'soundOutgoing', 'soundDisconnect', 'sipForwarding','sipForwardingId',
                  'sipForwardingPassword','sipForwardingUsername', 'sipRouting', 'voicemailEnabled', 'voicemailIncludeLink',
                  'voicemailNotifyQueues', 'voicemailTimeout', 'voicemailTranscriptionBlacklist', 'callNotification', 'continuousChime',
                  'callForwarding', 'callForwardingPrompt', 'callForwardingTimeout', 'callForwardingDisabledOffline', 'webRTCEnabled',
                  'callerIdEnabled', 'callQueueCheckEnabled', 'callQueueCheckTimeout', 'callTimeout', 'maxHoldTime', 'localPresenceFallbackFlow',
                  'agentUnavailableFlow', 'advancedSearch', 'conference', 'clickToVoicemail', 'directConnect', 'dualChannelRecording',
                  'dynamicCallFlows', 'extensionNonPhoneFilter', 'clickToCallDomainBlacklist', 'continuousBridge', 'continuousBridgeTimeout',
                  'waitUrlForHoldMusic', 'waitUrlForQueues', 'emailSendViaSalesforce', 'lightningEmailTemplates', 'sipClient',
                  's3RecordingBucket', 's3RecordingRole', 's3RecordingBucketConfirmation', 'waitOnConferenceEnter',
                  'agentStatusCallout', 'agentStatusCalloutEndpoint', 'sfdcDisableLeadCreation', 'sfdcHonorDoNotCallOrEmail', 'sfdcCreateLeadOnMultiMatch',
                  'sfdcCreateLeadOnSFDCCampaign', 'sfdcCreateLeadOnUnansweredCall', 'sfdcCreateTasksEarly', 'sfdcCustomCallObject', 'sfdcDefaultMatchType',
                  'sfdcIgnoreIntegrityErrors', 'sfdcMatchAccounts', 'sfdcMatchContacts', 'sfdcMatchLeads', 'sfdcMatchOnExternal', 'sfdcMatchOpportunities',
                  'sfdcOpenInSalesConsole', 'sfdcSoslSearch', 'sfdcClickToCallReuseTask', 'sfdcClickToCallTaskSubjectState', 'validateSMSNumber'
    ]
    @featureVisibilities = [ 'CallQueues', 'CallRating', 'CallToolsMenu', 'Chatter', 'GeneralSettings',
                             'LeadStatus', 'NewTask', 'EmailButton', 'TypeTool', 'PriorityTool',
                             'DueDateTool', 'ReminderTool', 'RelatedRecordsTool', 'WebLeads' ]

    @accountProps = ['packageName', 'packageVersion']

  url: =>
    "accounts/#{@accountId}/dialerSettings"

  parse: (response) ->
    attrs = _.clone(response)
    attrs[feature.feature] = feature.visible for feature in attrs.features
    delete attrs.features
    attrs[setting] = attrs.account.settings[setting] for setting in @settings
    attrs[setting] = attrs.account[setting] for setting in @accountProps
    @settingsId = attrs.account.settings.id
    attrs.id = attrs.account.id
    attrs.name = attrs.account.name
    delete attrs.account
    attrs

  toJSON: =>
    fields = {}
    fields.id = @accountId
    fields['account.id'] = @accountId
    fields['settings.id'] = @settingsId
    for key in @settings when key isnt 'messaging' and key isnt 'messagingFeaturesEnabled'
      fields["settings.#{key}"] = @get(key)
    features = for key in @featureVisibilities
      feature: key, visible: @get(key)
    fields['featureVisibilities'] = features
    for key in @accountProps
      fields["account.#{key}"] = @get(key)
    fields

module.exports = DialerSettingsModel
