BaseView  = require('base/view')
BaseModel = require('base/model')
FilePickerView = require('widgets/file-picker/view')

class VoicemailUploadModal extends BaseView
  template: require('./upload-step-template')

  regions:
    filePicker: '.file-picker-container'

  ui:
    go    : 'button.save-record'
    file  : 'input.file'
    label : 'input.file-label'
    outOfOffice: 'input.out-of-office'

  bindings:
    '.file-label'            : 'value:name'
    '.modal-title'           : 'text:modalTitle'
    '.out-of-office-section' : 'toggle:showOutOfOfficeCheckbox'
    '.out-of-office'         : 'checked:outOfOfficeValue'

  computeds:
    modalTitle:
      get: -> @modalTitle
    showOutOfOfficeCheckbox:
      get: -> @outOfOfficeCheckbox
    outOfOfficeValue:
      deps: ['outOfOffice']
      get: (outOfOffice) ->
        if outOfOffice then outOfOffice else false

  events:
    'click @ui.go' : 'go'

  initialize: (options) ->
    { @accountId, @modalTitle, @userId, @teamId, @outOfOfficeCheckbox } = options
    @reloadOnClose = false

  onRender: =>
    @filePicker = new FilePickerView(src: @model.get('url'), accept: '.wav')
    @showChildView('filePicker', @filePicker)

  go: =>
    return unless @validate()
    @filePicker.disable()
    @model
      .upload(
        data:
          file        : @filePicker.val()
          label       : @ui.label.val()
          accountId   : @accountId
          userId      : @userId
          teamId      : @teamId
          outOfOffice : @ui.outOfOffice.prop('checked')
        ladda: @ui.go[0]
      )
      .done((response) =>
        response.name = response.label unless response.name
        response.accountId = @accountId
        response.deletable = true
        @collection.set(response, {remove: false})
        toastr.info("#{@modalTitle} uploaded.")
        @model.trigger('saveVoicemail')
      )
      .fail(@filePicker.enable())

  validate: =>
    if not @ui.label.val()
      toastr.error('Please enter a label.')
      return false

    if not @filePicker.val() and @model.isNew()
      toastr.error('Please choose a file.')
      return false

    if @filePicker.val() and @filePicker.val()?.type isnt 'audio/wav'
      toastr.error('Only .wav files supported')
      return false

    true

module.exports = VoicemailUploadModal
