BaseModel = require('base/model')

class CustomUserStatusModel extends BaseModel

  urlRoot: 'userStatuses'

  defaults:
    name: ''
    description: ''
    busy: 'true'
    teams: new Backbone.Collection()
    defaultExpiration: '15'

  toJSON: =>
    fields = @attributes
    @unset('teams')
    fields['description'] = fields['description'].trim()
    fields

  parse: (response) =>
    teams = new Backbone.Collection()
    if response.teams.length isnt 0
      for team in response.teams
        teams.add(team.team)
    response['teams'] = teams
    response['busy'] = if response.busy then 'true' else 'false'
    response

  validate: (attrs) ->
    if not attrs.name.trim()
      return 'Please enter status name.'
    return

module.exports = CustomUserStatusModel