import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceTaskIcon({ size = 16, color }: BaseIconProps) {
  return (
    <svg width={size} height={size} fill="none">
      <defs>
        <clipPath id="clip0_8933_70815">
          <rect fill="white" height="24" width="24" />
        </clipPath>
        <clipPath id="clip1_8933_70815">
          <rect x="5.13281" y="5.00977" fill="white" height="14" width="14" />
        </clipPath>
      </defs>
      <g>
        <mask height="24" width="24" y="0" x="0" maskUnits="userSpaceOnUse" id="mask0_8933_70815">
          <rect fill="white" rx="12" height="24" width="24" />
        </mask>
        <g>
          <g mask="url(#mask0_8933_70815)" />
          <g>
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m7.17583,2.38228l-0.4951,-0.49504c-0.1456,-0.1456 -0.3494,-0.1456 -0.495,0l-3.08684,3.05764l-1.25221,-1.22306c-0.14561,-0.1456 -0.34946,-0.1456 -0.49507,0l-0.49506,0.49505c-0.14561,0.1456 -0.14561,0.34945 0,0.49505l1.71816,1.71815c0.1456,0.1456 0.32033,0.2038 0.49506,0.2038c0.17473,0 0.34946,-0.0582 0.49507,-0.2038l3.61099,-3.55274c0.1165,-0.11648 0.1165,-0.34944 0,-0.49505z"
            />
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m14.28405,5.70229l-6.08641,0c-0.2621,0 -0.46591,-0.20384 -0.46591,-0.46593l0,-0.93185c0,-0.26208 0.20381,-0.46593 0.46591,-0.46593l6.08641,0c0.26201,0 0.46591,0.20385 0.46591,0.46593l0,0.93185c0,0.26209 -0.2039,0.46593 -0.46591,0.46593z"
            />
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m14.28335,9.86637l-7.48412,0c-0.2621,0 -0.4659,-0.2039 -0.4659,-0.4659l0,-0.9319c0,-0.2621 0.2038,-0.4659 0.4659,-0.4659l7.48412,0c0.2621,0 0.466,0.2038 0.466,0.4659l0,0.9319c0,0.262 -0.2039,0.4659 -0.466,0.4659z"
            />
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m3.97329,9.86637l-0.93187,0c-0.26209,0 -0.46594,-0.2039 -0.46594,-0.4659l0,-0.9319c0,-0.2621 0.20386,-0.4659 0.46594,-0.4659l0.93187,0c0.2621,0 0.46594,0.2038 0.46594,0.4659l0,0.9319c0.02911,0.262 -0.20384,0.4659 -0.46594,0.4659z"
            />
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m3.97329,14.03037l-0.93187,0c-0.26209,0 -0.46594,-0.2038 -0.46594,-0.4659l0,-0.9318c0,-0.2621 0.20386,-0.466 0.46594,-0.466l0.93187,0c0.2621,0 0.46594,0.2039 0.46594,0.466l0,0.9318c0.02911,0.2621 -0.20384,0.4659 -0.46594,0.4659z"
            />
            <path
              fill={color || Colors.SALESFORCE_TASK}
              d="m14.28335,14.03037l-7.48412,0c-0.2621,0 -0.4659,-0.2038 -0.4659,-0.4659l0,-0.9318c0,-0.2621 0.2038,-0.466 0.4659,-0.466l7.48412,0c0.2621,0 0.466,0.2039 0.466,0.466l0,0.9318c0,0.2621 -0.2039,0.4659 -0.466,0.4659z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SalesforceTaskIcon
