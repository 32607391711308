BaseView = require('base/view')
CreateLicenseModal = require('./modals/create-license-view')
LicenseRequestModal = require('./modals/license-request-view')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')

class LicensesView extends BaseView

  className: 'account-licenses'

  regions:
    modal: 'div.modal-container'

  events:
    'click button.create-license' : 'createLicense'
    'click button.add-request' : 'licenseRequest'

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  initialize: (options) ->
    { @accountId } = options
    @model.set('accountId', @accountId)
    @listenTo(@model, 'change', => @render())

  createLicense: =>
    @showChildView('modal', new CreateLicenseModal(accountId: @accountId, model: @model))

  licenseRequest: =>
    @showChildView('modal', new LicenseRequestModal(accountId: @accountId))

module.exports = LicensesView
