import React, { useState } from 'react'

/*
Purpose:

// The Purpose of this context is to ensure that there is only a single audio file playing at the same time on the page.
This keeps state of which audio file is currently playing on the page and is up to each audio player component to listen
to state change to pause its audio if another audio file starts playing. Ex. RdnaFilePicker

*/

type Props = {
  children: React.ReactNode
}

type ContextType = {
  currentAudioPlaying: string | undefined
  setCurrentAudioPlaying: (audioFile: string) => void
}

const PageAudioContext = React.createContext<ContextType>({
  currentAudioPlaying: undefined,
  setCurrentAudioPlaying: audioFile => {
    /* istanbul ignore next */
    console.error('PageAudioContext.setCurrentAudioPlaying called before init', audioFile)
  }
})

const PageAudioProvider = ({ children }: Props) => {
  const [currentAudioPlaying, setCurrentAudioPlaying] = useState('')
  return (
    <PageAudioContext.Provider value={{ currentAudioPlaying, setCurrentAudioPlaying }}>
      {children}
    </PageAudioContext.Provider>
  )
}

export { PageAudioContext, PageAudioProvider }
