import React from 'react'
import { ButtonsContainer } from './ReportFilters'
import { mapFilterName } from './utils/inputUtils'
import getInputByName from './FilterInputs'
import RdnaButton from '../RdnaButton'
import RdnaDrawer from '../RdnaDrawer'
import RdnaForm from '../RdnaFormElements'
import styled from 'styled-components'
import { FilterFormValues, ReportFilterType } from './types'

type Props = {
  filters: ReportFilterType[]
  open: boolean
  onClose: () => void
  onReset: () => void
  onSubmit: (arg0: FilterFormValues) => void
  defaultValues: FilterFormValues
  blankValues: FilterFormValues
}

const DrawerFilters = ({ filters, onClose, open, onReset, onSubmit, defaultValues, blankValues }: Props) => {
  return (
    <RdnaDrawer open={open} onClose={onClose} heading={'Additional Filters'}>
      <RdnaForm mode="all" defaultValues={defaultValues} onValid={onSubmit}>
        {({ setValue, getValues, reset }, { RdnaFormController, getMuiProps }) => {
          return (
            <>
              {filters.map(filter => {
                return (
                  <DrawerFilter key={filter}>
                    <RdnaFormController
                      name={mapFilterName(filter)}
                      render={props =>
                        getInputByName(filter, getMuiProps(props).value, getMuiProps(props).onChange, setValue)
                      }
                    />
                  </DrawerFilter>
                )
              })}
              <ButtonsContainer>
                <RdnaButton
                  text={'Reset'}
                  color="neutral"
                  type={'button'}
                  variant="text"
                  onClick={() => {
                    reset(blankValues)
                    onReset()
                  }}
                  data-testid={`reset-filters-drawer-btn`}
                />
                <RdnaButton
                  type={'button'}
                  text={'Apply'}
                  onClick={() => onSubmit(getValues())}
                  data-testid={`submit-filters-drawer-btn`}
                />
              </ButtonsContainer>{' '}
            </>
          )
        }}
      </RdnaForm>
    </RdnaDrawer>
  )
}

export default DrawerFilters

const DrawerFilter = styled.div`
  min-height: ${({ theme }) => theme.spacing * 12}px;
  width: 33vw;
`
