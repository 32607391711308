import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const VideoIcon = ({ color = Colors.WHITE, size = 24 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M4 8V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V14.4415C16 14.2472 16.212 14.1272 16.3786 14.2272L20.2428 16.5457C20.576 16.7456 21 16.5056 21 16.1169V7.8831C21 7.49445 20.576 7.25439 20.2428 7.45435L16.3786 9.77283C16.212 9.8728 16 9.75278 16 9.55845V8C16 6.89543 15.1046 6 14 6H6C4.89543 6 4 6.89543 4 8Z"
      stroke={color}
    />
    <path
      d="M8.29175 14.1071V9.89292C8.29175 9.51124 8.71713 9.28359 9.03471 9.4953L12.1953 11.6024C12.4791 11.7915 12.4791 12.2085 12.1953 12.3976L9.03471 14.5047C8.71713 14.7164 8.29175 14.4888 8.29175 14.1071Z"
      fill={color}
    />
  </svg>
)

export default VideoIcon
