BaseView = require('base/view')

class EditLocationModal extends BaseView

  template: require('./template')
  className: 'location-editor'

  ui:
    modal        : '#edit-location-modal'
    go           : 'button.persist'

  events:
    'click @ui.go' : 'save'

  bindings:
    '.location'    : 'value:name'
    '.description' : 'value:description'
    '.address'     : 'value:address'

  initialize: (options) =>
    { @accountId } = options
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  save: =>
    if @model.hasChanged()
      @model.set('accountId', @accountId)
      @model
        .persist(ladda: @ui.go[0])
        .done?(=>
          @ui.modal.modal('hide')
          @collection.add(@model, { at: 0, merge: true })
          toastr.info('Location saved.')
        )
    else
      @ui.modal.modal('hide')

module.exports = EditLocationModal
