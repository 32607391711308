import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react'
import RdnaNotification, { NotificationType } from './index'

import styled from 'styled-components'

export const NOTIFICATION_SHOW_TIME = 5000
const StyledNotification = styled(RdnaNotification)`
  position: fixed;
  top: 80px;
  left: 50%;
  width: 90vw;
  max-width: ${({ theme }) => theme.spacing * 110}px;
  transform: translateX(-50%);
`

type MessageData = (message: string, options?: MessageOptions) => void

type ContextType = {
  success: MessageData
  error: MessageData
  warning: MessageData
  clear: () => void
}

type MessageOptions = {
  zIndex?: number
  autoHide?: boolean
  onlyShowOnce?: boolean
}

export const RdnaNotificationContext = createContext<ContextType | undefined>(undefined)

export function RdnaNotificationProvider({
  children,
  globalZIndex = 1,
  globalAutoHide
}: {
  children: React.ReactNode
  globalZIndex?: number
  globalAutoHide?: boolean
}) {
  const [notification, setNotification] = useState<{
    message: string
    type: NotificationType
    zIndex: number
    autoHide?: boolean
  }>({
    message: '',
    type: 'success',
    zIndex: globalZIndex,
    autoHide: false
  })
  const [shownMessages, setShownMessages] = useState<string[]>([])
  const { message, type, zIndex, autoHide } = notification

  const hasMessageBeenShown = useCallback(
    (message: string): boolean => {
      if (shownMessages.includes(message)) {
        return true
      } else {
        setShownMessages(existingMessages => [...existingMessages, message])
        return false
      }
    },
    [setShownMessages, shownMessages]
  )

  const createMessage = useCallback(
    (type: NotificationType, message: string, options?: MessageOptions) => {
      if (options?.onlyShowOnce) {
        const duplicate = hasMessageBeenShown(message)
        if (duplicate) return false
      }
      setNotification({
        message,
        type,
        zIndex: options?.zIndex || globalZIndex,
        autoHide: options?.autoHide || globalAutoHide
      })
    },
    [setNotification, hasMessageBeenShown, globalAutoHide, globalZIndex]
  )

  const value = useMemo(
    () => ({
      clear: () => setNotification({ message: '', type: 'warning', zIndex: globalZIndex, autoHide: false }),
      success: (message: string, options?: MessageOptions) => {
        createMessage('success', message, options)
      },
      error: (message: string, options?: MessageOptions) => {
        createMessage('error', message, options)
      },
      warning: (message: string, options?: MessageOptions) => {
        createMessage('warning', message, options)
      }
    }),
    [globalZIndex, createMessage]
  )

  useEffect(() => {
    if (autoHide && message) {
      setTimeout(() => {
        value.clear()
      }, NOTIFICATION_SHOW_TIME)
    }
  }, [value, autoHide, message])

  return (
    <RdnaNotificationContext.Provider value={value}>
      {message && (
        <StyledNotification
          onClose={() => setNotification({ ...notification, message: '' })}
          type={type}
          message={message}
          style={{ zIndex: zIndex }}
        />
      )}
      {children}
    </RdnaNotificationContext.Provider>
  )
}

export function useRdnaNotification() {
  const context = useContext(RdnaNotificationContext)
  /* istanbul ignore next */
  if (context === undefined) {
    /* istanbul ignore next */
    throw new Error('useRdnaNotification must be used within a RdnaNotificationProvider')
  }

  return context
}
