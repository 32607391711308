import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function RecordingIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M7.14 9.3C7.55367 9.3 7.91633 9.37933 8.228 9.538C8.53967 9.691 8.7805 9.912 8.9505 10.201C9.12617 10.4843 9.214 10.8158 9.214 11.1955C9.214 11.5922 9.10917 11.9407 8.8995 12.241C8.68983 12.5413 8.40933 12.7538 8.058 12.8785L9.316 15.25H8.228L7.0975 13.091H5.7715V15.25H4.794V9.3H7.14ZM5.7715 12.241H7.0635C7.41483 12.241 7.6925 12.1475 7.8965 11.9605C8.10617 11.7678 8.211 11.5128 8.211 11.1955C8.211 10.8782 8.10617 10.626 7.8965 10.439C7.6925 10.2463 7.41483 10.15 7.0635 10.15H5.7715V12.241ZM10.1065 9.3H13.991V10.167H11.084V11.816H13.481V12.6575H11.084V14.383H14.093V15.25H10.1065V9.3ZM14.4839 12.275C14.4839 11.6743 14.6001 11.1473 14.8324 10.694C15.0704 10.235 15.4048 9.88083 15.8354 9.6315C16.2718 9.3765 16.7789 9.249 17.3569 9.249C17.8046 9.249 18.2069 9.334 18.5639 9.504C18.9266 9.674 19.2213 9.91767 19.4479 10.235C19.6746 10.5467 19.8191 10.9093 19.8814 11.323H18.9039C18.8076 10.9433 18.6206 10.6458 18.3429 10.4305C18.0653 10.2152 17.7224 10.1075 17.3144 10.1075C16.9461 10.1075 16.6231 10.201 16.3454 10.388C16.0678 10.5693 15.8553 10.8243 15.7079 11.153C15.5606 11.476 15.4869 11.85 15.4869 12.275C15.4869 12.6943 15.5606 13.0683 15.7079 13.397C15.8553 13.7257 16.0678 13.9835 16.3454 14.1705C16.6231 14.3518 16.9461 14.4425 17.3144 14.4425C17.7224 14.4425 18.0624 14.3348 18.3344 14.1195C18.6121 13.9042 18.7991 13.6067 18.8954 13.227H19.8729C19.8106 13.6463 19.6661 14.0118 19.4394 14.3235C19.2184 14.6352 18.9294 14.876 18.5724 15.046C18.2154 15.216 17.8103 15.301 17.3569 15.301C16.7789 15.301 16.2718 15.1763 15.8354 14.927C15.4048 14.6777 15.0704 14.3263 14.8324 13.873C14.6001 13.414 14.4839 12.8813 14.4839 12.275Z"
        fill={color}
      />
    </svg>
  )
}

export default RecordingIcon
