import React from 'react'
import {VisibilitiesProps} from '../../types'
import {Section, SectionHeader} from '../../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import CallToolsIcon from '@ringdna/common/src/assets/icons/svg/CallTools'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import { FeatureType } from '@ringdna/common/src/types/dialer-settings'
import {
  AppendTransferInfo,
  EnableCallRating,
  EnableCallTolls,
  EnableChatter,
  appendTransferInfoSettings
} from '../../const'
import {isFeatureVisibilitiesSelector} from '../../utils/utils'
import SettingsToggle from '../SettingsToggleSwitch'

export default function CallToolsTab({ accountId, accountSettings, setAccountSettings, featureVisibilities, setFeatureVisibilities }: VisibilitiesProps) {
  return (
    <Section>
      <SectionHeader style={{marginBottom: 20}}>
        <IconHelper icon={CallToolsIcon} variant="filled" />
        <RdnaText className={'rdna-text'}>Call Tools</RdnaText>
      </SectionHeader>
      <RdnaSettingRow heading={EnableCallTolls.title} description={EnableCallTolls.description}>
        <SettingsToggle
          isChecked={isFeatureVisibilitiesSelector(featureVisibilities, FeatureType.CallToolsMenu)}
          setting={FeatureType.CallToolsMenu}
          featureVisibilities={featureVisibilities}
          setFeatureVisibilities={setFeatureVisibilities}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={EnableChatter.title} description={EnableChatter.description}>
        <SettingsToggle
          isChecked={isFeatureVisibilitiesSelector(featureVisibilities, FeatureType.Chatter)}
          setting={FeatureType.Chatter}
          featureVisibilities={featureVisibilities}
          setFeatureVisibilities={setFeatureVisibilities}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={EnableCallRating.title} description={EnableCallRating.description}>
        <SettingsToggle
          isChecked={isFeatureVisibilitiesSelector(featureVisibilities, FeatureType.CallRating)}
          setting={FeatureType.CallRating}
          featureVisibilities={featureVisibilities}
          setFeatureVisibilities={setFeatureVisibilities}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={AppendTransferInfo.title} description={AppendTransferInfo.description}>
        <SettingsToggle
          isChecked={accountSettings[appendTransferInfoSettings]}
          setting={appendTransferInfoSettings}
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
        />
      </RdnaSettingRow>
    </Section>
  )
}
