class BaseCollection extends Backbone.Collection

  persist: (options) =>
    defaults =
      data        : @toJSON()
      emulateJSON : true
      url         : @url()

    options = _.extend(defaults, options)
    Backbone
      .sync('update', @, options)
      .done((response) => @set(response))

  create: (options) =>
    defaults =
      data        : @toJSON()
      emulateJSON : true
      url         : @url()

    options = _.extend(defaults, options)
    Backbone
      .sync('create', @, options)
      .done((response) => @set(response))

  upload: (options) =>
    defaults =
      data        : @toJSON()
      emulateJSON : true
      url         : @url()
      upload      : true

    options = _.extend(defaults, options)
    Backbone
    .sync('create', @, options)
    .done((response) => @set(response))

  _toPlayJSON: (prefix, models) =>
    target = {}
    _.each(models, (model, index) =>
      target["#{prefix}[#{index}].#{key}"] = value for key, value of model.attributes
    )
    target

  # Prefix attribute names as expected by the Play framework.
  toPlayJSON: (prefix) =>
    @_toPlayJSON(prefix, @models)

module.exports = BaseCollection
