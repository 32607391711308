BaseView        = require('base/view')
ReactComponent  = require('src/ReactComponent').default
Roles           = require('src/components/roles').default
ProductConstant = require('@ringdna/common/src/constants/products')

class RolesView extends BaseView
  template: _.template('<div class="roles"></div>')
  className: 'roles-view'

  regions:
    container  : '.roles'

  initialize: (options) =>
    { @qs } = options

  onRender: =>
    return unless App.featureFlag.isFlagged(App.featureFlag.flags.ROLES)
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @showChildView('container', new ReactComponent({
      component: Roles,
      theme: ProductConstant.Products.ADMIN,
      props: { accountId: @accountId }
    }))

module.exports = RolesView
