import React, { ChangeEvent, KeyboardEvent, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import RdnaTextInput from '../../RdnaFormElements/RdnaTextInput'
import { useAtom } from 'jotai'
import { ApiBase, chatAtom, ChatMessage, contentWidthMultiplier, GenAiProps } from '../index'
import ChatBubble from './ChatBubble'
import { useSendMessageLab } from './api'
import TypingIndicator from './TypingIndicator'
import StreamMessage from './StreamMessage'
import RdnaText from '../../RdnaText'
import { useHTMLElementDimensions } from '../../../hooks/useHTMLElementDimensions'
import RdnaButton from '../../RdnaButton'
import SendMessageIcon from '../../../assets/icons/svg/SendMessage'
import EmptyChat from './EmptyChat'
import { cookieUtils, SESSION_ID_KEY } from '../../../utils/cookieUtils'
import { GenAiEvents } from '../config'
import PlusIcon from '../../../assets/icons/svg/Plus'
import { Colors } from '../../../constants/colors'
import StarterQuestions from './StarterQuestions'
import RdnaIconHelper from '../../../assets/icons/IconHelper'

export const serverIdentity = {
  owner: 'R',
  isAgent: false
}

export type ChatProps = Pick<GenAiProps, 'userName' | 'playBasePath' | 'analytics'> & {
  apiPath: string
  apiBase: ApiBase
  id: string
  queryParams?: { [key: string]: string }
  starterQuestions?: string[]
  contextLabel?: string
  isActive: boolean
}
export default function Chat({
  apiPath,
  apiBase,
  userName,
  queryParams,
  id,
  starterQuestions,
  contextLabel,
  playBasePath,
  analytics,
  isActive
}: ChatProps) {
  const [chatMessages, setChatMessages] = useAtom(chatAtom)
  const [textInputValue, setTextInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const sendMessageLab = useSendMessageLab()
  const heightRef = useRef(null)
  const { height } = useHTMLElementDimensions(heightRef, textInputValue)
  const normalizedHeight = Math.max(height - 2, 16)
  const roomMessages = chatMessages.filter(value => value.id === id)
  const [showStarterQuestions, setShowStarterQuestions] = useState<boolean>(
    roomMessages.length === 0 && !!starterQuestions?.length
  )

  useEffect(() => {
    if (!contextLabel || !isActive) return
    const newContextMessage = `Context: ${contextLabel}`
    const lastContext = roomMessages.find(msg => (msg.message || '').toString().includes('Context:'))
    const isNewContext = (lastContext?.message as string) !== newContextMessage
    isNewContext && setChatMessages(prev => [{ message: newContextMessage, id, ...serverIdentity }, ...prev])
  }, [contextLabel, id, roomMessages, setChatMessages, isActive])

  const loadMessage: ChatMessage = {
    message: <TypingIndicator />,
    owner: 'R',
    isAgent: false,
    id
  }
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '\n') {
      setTextInputValue(e.target.value)
    }
  }

  const onSendButtonClick = () => {
    handleEnter(textInputValue)
  }

  const selectStarterQuestion = (question: string) => {
    setTextInputValue(question)
    setShowStarterQuestions(false)
  }

  const toggleStarterQuestions = () => {
    setShowStarterQuestions(prev => !prev)
  }

  const handleEnter = async (value: string) => {
    setShowStarterQuestions(false)
    analytics.trackEvent(GenAiEvents.SEND_MESSAGE, { message: value, chat: id })
    const encodedValue = encodeURIComponent(value)
    setTextInputValue('')
    setLoading(true)
    setChatMessages(prev => [{ message: value, owner: userName, isAgent: true, id }, ...prev])
    if (apiBase === 'apiGpu' || apiBase === 'apiPlay') {
      const searchParams = new URLSearchParams({ ...queryParams, input: value })
      const requestUrl =
        apiBase === 'apiGpu'
          ? `https://gpu.ringdna.net${apiPath}?${searchParams.toString()}`
          : `${playBasePath}${apiPath}?${searchParams.toString()}`
      fetch(requestUrl, {
        headers: {
          sessionId: cookieUtils.getCookie(SESSION_ID_KEY) as string
        }
      }).then(response => {
        if (response.ok) {
          setChatMessages(prev => [
            {
              message: (
                <StreamMessage
                  response={response}
                  key={encodedValue}
                  name={encodedValue}
                  input={value}
                  apiPath={apiPath}
                  apiBase={apiBase}
                  queryParams={queryParams}
                />
              ),
              id,
              ...serverIdentity
            },
            ...prev
          ])
        } else {
          setChatMessages(prev => [{ message: 'This request failed', id, ...serverIdentity }, ...prev])
        }

        setLoading(false)
      })
    } else {
      try {
        const response = await sendMessageLab({
          query: { input: encodedValue, ...queryParams },
          meta: { apiPath }
        })
        setChatMessages(prev => [{ message: response.success?.payload as string, id, ...serverIdentity }, ...prev])
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setChatMessages(prev => [{ message: 'This request failed', id, ...serverIdentity }, ...prev])
      }
    }
  }

  return (
    <Container className={'chat-room'}>
      <Messages>
        {showStarterQuestions && (
          <StarterQuestions starterQuestions={starterQuestions} onSelect={selectStarterQuestion} />
        )}
        {loading && <ChatBubble message={loadMessage} loading={true} />}
        {roomMessages.map((message, index) => (
          <ChatBubble key={index} message={message} />
        ))}
        {roomMessages.length === 0 && !starterQuestions && <EmptyChat />}
      </Messages>
      <HeightCalculator ref={heightRef}>{textInputValue}</HeightCalculator>
      <ChatInput>
        <RdnaTextInput
          value={textInputValue}
          onChange={onChange}
          multiline
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              const input = e.target as HTMLInputElement
              handleEnter(input.value)
            }
          }}
          name={'prompt-input'}
          rows={1}
          placeholder={'Ask Revenue AI'}
          inputProps={{ style: { height: normalizedHeight } }}
          InputProps={{
            endAdornment: starterQuestions?.length ? (
              <RdnaIconHelper
                icon={PlusIcon}
                size={16}
                onClick={toggleStarterQuestions}
                testId={'starter-questions-toggle'}
                color={Colors.BLACK}
              />
            ) : undefined
          }}
        />
        <SendButton
          onClick={onSendButtonClick}
          disabled={textInputValue === ''}
          Icon={SendMessageIcon}
          data-testid={'send-message-btn'}
        />
      </ChatInput>
      <Disclaimer variant={'meta'} color={'neutral'}>
        Revenue AI can make mistakes. Consider checking important information.
      </Disclaimer>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.spacing * contentWidthMultiplier}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing * 2}px;
  padding: ${({ theme }) => theme.spacing * 2}px 0;
  overflow: auto;
  height: calc(100% - 40px);
`

const HeightCalculator = styled(RdnaText)`
  width: ${({ theme }) => theme.spacing * contentWidthMultiplier}px;
  white-space: pre-wrap;
  position: absolute;
  visibility: hidden;
  padding: 0 14px;
`

const ChatInput = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing}px;
  align-items: flex-end;
  > div {
    margin: 0;
  }
`

const SendButton = styled(RdnaButton)`
  padding: ${({ theme }) => theme.spacing}px;
  .icon-wrapper.before {
    margin: 0;
  }
`

const Disclaimer = styled(RdnaText)`
  margin-top: ${({ theme }) => theme.spacing}px;
`
