import React from 'react'

import { Colors } from '../../../constants/colors'

const TableIcon = ({ size = 24, color = Colors.N100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid={`table-icon`}>
      <path
        d="M2.5 5.5H21.5V20.5C21.5 21.0523 21.0523 21.5 20.5 21.5H3.5C2.94772 21.5 2.5 21.0523 2.5 20.5V5.5Z"
        fill={color}
        stroke="black"
        strokeLinejoin="round"
        opacity={0.7}
      />
      <path
        d="M2.5 3.5C2.5 2.94772 2.94772 2.5 3.5 2.5H20.5C21.0523 2.5 21.5 2.94772 21.5 3.5V5.5H2.5V3.5Z"
        fill={color}
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TableIcon
