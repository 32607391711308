BaseView = require('base/view')
BaseGrid = require('base/grid')
BaseCollection = require('base/collection')

class Step3Modal extends BaseView
  template: require('./step-3-template')

  regions:
    grid: 'div.call-scripts-selected-numbers'
    pager: 'div.pager'

  ui:
    go: 'a.persist'

  events:
    'click @ui.go': 'save'

  initialize: (options) ->
    { @entityCollection, @accountId, @scriptId } = options

  onRender: =>
    entities = @entityCollection.shadowCollection?.models ? @entityCollection.fullCollection.models
    models = (entity for entity in entities when entity.get('selected'))
    @selectedEntityCollection = new BaseCollection(models)
    @showChildView('grid', @buildGrid())

  buildGrid : =>
    removeSelectedCell = @removeSelectedCell(@selectedEntityCollection)
    columns = [
      { name: 'name',               label: 'Name',   cell: 'string' },
      { name: 'source',             label: 'Source', cell: 'string' },
      { name: 'smartNumber.number', label: 'Number', cell: 'string' },
      { name: 'tags',               label: 'Tags',   cell: 'string' },
      { name: '',                   label: 'Remove', cell: removeSelectedCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @selectedEntityCollection
      emptyText  : 'No entities selected.'
    )

  removeSelectedCell: (collection) =>
    class extends Backgrid.Cell
      className: 'remove-selected-cell'
      events:
        'click a.remove': 'removeSelected'
      removeSelected: ->
        @model.set(selected: false)
        @collection.remove(@model)
      render: ->
        @collection = collection
        $(@el).html('''
            <a class="remove">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          ''')
        @

  save: =>
    @model.set('accountId': @accountId) if not @model.get('id')
    @model.set('newSelectedEntities', [])
    @model.get('newSelectedEntities').push(entity.get('id')) for entity in @entityCollection.fullCollection.models when entity.get('selected')
    @model.set('entityIdsAdd', (value for value in @model.get('newSelectedEntities') when value not in @model.get('oldSelectedEntities')))
    @model.set('entityIdsDelete', (value for value in @model.get('oldSelectedEntities') when value not in @model.get('newSelectedEntities')))
    @model.unset('oldSelectedEntities')
    @model.unset('newSelectedEntities')
    @model
      .persist(ladda: @ui.go[0])
      .done( =>
        @collection.add(@model, { at: 0, merge: true })
        toastr.info('Call script saved.')
      )

module.exports = Step3Modal
