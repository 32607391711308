BaseView = require('base/view')

class ZoomView extends BaseView

  render: =>
    user = @user()
    userId = @user().get('userId')
    App.navigate("#users/#{userId}/integrations", true)

module.exports = ZoomView
