// TEMPORARY: ONLY USE FOR CAI - this matches new stand alone CAI context location and values
import { UserSettings } from '@ringdna/common/src/types/user-settings'
import UserService from '@ringdna/common/services/user.service'

const userService = new UserService(App)
const user = userService.getAll()
const userSettings: UserSettings = {
  ...user,
  isSupport: user.licenses?.support,
  isAdmin: user.licenses?.admin,
  isAuthorizedAdmin: userService.isAuthorizedAdmin,
  caiLocationEnabled: user.settings?.conversationAiLocRestricted
}

export const useUser = () => userSettings
