BaseGrid = require('base/grid')
BaseView = require('base/view')
CallCustomLinksModal = require('./editor/view')
CallCustomLinksModel = require('./model')
CallCustomLinksCollection = require('./collection')

class CallCustomLinksView extends BaseView

  template: require('./template')

  className: 'callCustomLinks'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add': 'addCallCustomLink'

  initialize: (options) ->
    { @accountId } = options
    @collection = new CallCustomLinksCollection()
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'
      edit: =>
        @column.channel.trigger('edit', { model: @model })
      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#edit-call-custom-links-modal">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'name',   label: 'Label',   cell: 'string' },
      { name: 'url',     label: 'URL',     cell: 'uri' },
      { name: '',        label: 'Update',  cell: actionCell },
      { name: '',        label: 'Delete',  cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No Call Custom Links Found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    model = options.model.clone()
    @showChildView('modal', new CallCustomLinksModal(
      accountId: @accountId
      model: model
      collection: @collection
    ))

  addCallCustomLink: =>
    @showChildView('modal', new CallCustomLinksModal(
      accountId: @accountId
      model: new CallCustomLinksModel()
      collection: @collection
    ))

module.exports = CallCustomLinksView