import React, { useMemo, useState } from 'react'
import RdnaSelect, { RdnaSelectOptions } from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { usePlayPicklist } from '@ringdna/common/src/components/RdnaReports/api'

type Props = {
  accountId: string
  onChoose: (a: string) => void
}

export default function TeamsPicklist({ accountId, onChoose }: Props) {
  const [value, setValue] = useState('')
  const [teamsPayload, , loading] = usePlayPicklist({
    meta: { accountId: parseInt(accountId), name: '', type: 'teams', pageSize: 1000 }
  })

  const options: RdnaSelectOptions<string>[] = useMemo(() => {
    let options = [{ label: 'All', value: '' }]
    if (teamsPayload) {
      options = options.concat(teamsPayload.map(val => ({ label: val.text, value: val.value })))
    }
    return options
  }, [teamsPayload])

  return (
    <>
      {!loading && (
        <RdnaSelect
          onChange={e => {
            setValue(e.target.value)
            onChoose(e.target.value)
          }}
          name={'teams-filter'}
          label={'Teams Filter'}
          options={options}
          value={value || ''}
          numOfRows={6}
        />
      )}
    </>
  )
}
