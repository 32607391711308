BaseView = require('base/view')
BaseCollection = require('base/collection')
TeamsPicker = require('widgets/teams-picker/view')

class EditCallNoteTemplatesModal extends BaseView

  template: require('./editor-template')
  className: 'call-note-templates-editor'

  regions:
    teamPicker : 'span.team-picker'

  ui:
    modal : '#edit-call-note-template-modal'
    go    : 'button.persist'

  events:
    'click @ui.go': 'save'

  bindings:
    '.note-name': 'value:name'
    '.note-template': 'value:template'

  initialize: (options) =>
    { @accountId, @existingTeams } = options
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @addedTeams = new BaseCollection(@model?.get('teams').slice(0))

  onRender: =>
    @userTeamPicker = new TeamsPicker(
      list: @existingTeams
      addedTeams: @addedTeams
      placeholder: 'Default (All Teams)'
    )
    @listenTo(@userTeamPicker, 'select', (value) =>
      model = @existingTeams.findWhere(id: parseInt(value)) if value
      @addedTeams.add(model) if model
    )
    @listenTo(@userTeamPicker, 'deselect', (value) =>
      @addedTeams.remove(parseInt(value)) if value
    )
    @showChildView('teamPicker', @userTeamPicker)

  save: =>
    data = @model.toJSON()
    data['accountId'] = @accountId
    data['teamIds'] = @addedTeams.pluck('id')
    data['teams'] = @model?.get('teams').toJSON()
    @model
      .persist(
        data: data
        ladda: @ui.go[0]
      ).done?(=>
        @ui.modal.modal('hide')
        names = @addedTeams.pluck('name')
        @model.set(
          teamNames: if names.length isnt 0 then names.join(', ') else 'All'
          teams: @addedTeams
        )
        @collection.add(@model, { at: 0, merge: true })
        toastr.info('Template saved.')
      )

module.exports = EditCallNoteTemplatesModal