BaseView = require('base/view')
ReportHelper  = require('helpers/report-helper')
config   = require('src/config').default

ReactComponent  = require('src/ReactComponent').default
ExportUsersButton = require('src/components/users/search/export/ExportUsersButton').default

class PagerView extends BaseView
  template: require('./template')

  regions:
    paginator: '.backgrid-paginator'
    reactExportButton: '.react-export-button'

  ui:
    container    : '.pager-container'
    download     : 'a.download'
    startRecord  : 'span.start-record'
    endRecord    : 'span.end-record'
    totalRecords : 'span.total-records'
    pageNumber   : 'input.page-number'

  events:
    'click a.download': 'export'

  bindings:
    '.react-export-button': 'toggle:reactExport,css:marginRight'
    'a.download'          : 'toggle:not(reactExport)'
    '.pager-container'    : 'css:pagerFlex'

  computeds:
    pagerFlex:
      get: => {'display': 'flex', alignItems: 'center' }
    marginRight:
      get: => {'margin-right': '12px' }

  initialize: (options) ->
    { @qs, @disableDownload } = options
    @viewModel = new Backbone.Model(
      reactExport: options.reactExport || false
    )

  onRender: =>
    @qs?.bind(page: @ui.pageNumber)
    $(@ui.download).hide() if @disableDownload

    @listenTo(@collection, 'pager:state:changed', (pager) =>
      @bindPaginator(pager)

      if @viewModel.get('reactExport')
        @showReactExport()
    )

    controls =
      rewind: null
      fastForward: null
      back:
        label: '<span class="glyphicon glyphicon-chevron-left"></span>'
        title: 'Previous'
      forward:
        label: '<span class="glyphicon glyphicon-chevron-right"></span>'
        title: 'Next'

    @showChildView('paginator',
      new Backgrid.Extension.Paginator(
        collection               : @collection
        controls                 : controls
        renderIndexedPageHandles : false
        windowSize               : 99999
      )
    )

  bindPaginator: (pager) =>
    @ui.pageNumber.val(if pager.currentPage is 1 then null else pager.currentPage)
    @qs?.navigate()

    if pager.totalRecords is 0
      @ui.container.hide()
      return

    start = (pager.currentPage - 1) * pager.pageSize + 1
    end = start + pager.pageSize - 1
    end = pager.totalRecords if end > pager.totalRecords

    @ui.startRecord.text(start)
    @ui.endRecord.text(end)
    @ui.totalRecords.text(pager.totalRecords)
    @ui.container.show()

  export: =>
    args = []
    for key, value of @collection.args()
      if key is 'locationIds'
        if value and value instanceof Array and value.length
          for test in value
            args.push("#{key}[]=#{test}")
      else
        args.push("#{key}=#{encodeURIComponent(value)}")

    App.api.get(
      path: "#{@collection.url}/export?" + args.join('&')
      data: accountId: @accountId
      dataType: 'text'
      success: (response, status, xhr) =>
        data = new ReportHelper().parseExportData(response, xhr)
        el = document.createElement('a')
        el.setAttribute('href', data.encodedUri)
        el.setAttribute('download', data.fileName)
        el.click()
    )

  showReactExport: =>
    return if @getRegion('reactExportButton')?.hasView()
    { name, email, salesforceId, userStatus, accountId } = @collection.args()
    @showChildView('reactExportButton', new ReactComponent({
      component: ExportUsersButton,
      props: {
        accountId,
        userName: name,
        userEmail: email,
        salesforceId,
        userStatus
      }
    }))

module.exports = PagerView
