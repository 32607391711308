BaseView = require('base/view')
CallInspectorModel            = require('./model')
TwilioCallbacksMainLayoutView = require('./twilio-callbacks/main-layout-view')

class CallView extends BaseView
  template: require('./call-template')

  bindings:
    '.recording-url-section': 'toggle:showCallRecording'

class ContainerView extends Marionette.View
  template: require('./container-template')
  className: 'call-inspector'

  ui:
    callId     : '.callId'
    papertrail : '.papertrail'
    go         : '.go'
    fix        : '.fix'

  events:
    'click @ui.go'  : 'go'
    'keyup .callId' : 'keyPressed'
    'click @ui.fix' : 'fix'

  regions:
    content         : '.content'
    twilioCallbacks : 'div.twilio-callbacks-container'

  initialize: (options) =>
    @callId = options.callId

  onRender: =>
    if @callId
      @ui.callId.val(@callId)
      @load(@callId)

  keyPressed: (e) =>
    @go() if e.keyCode is 13

  go: =>
    @load(_.escape(@ui.callId.val()))

  load: (callId) =>
    if callId.length < 3
      toastr.warning('Please enter a callKey or callSid')
      return

    @callId = callId
    App.navigate("#calls/#{callId}")

    @ui.fix.addClass('hidden')
    @model = new CallInspectorModel(id: callId)
    @model.fetch().done(=>
      return unless @getRegion('content')
      @showChildView('content', new CallView(model: @model))
      @showChildView('twilioCallbacks', new TwilioCallbacksMainLayoutView(callModel: @model.get('call'), collection: @model.twilioCallbacks))
      @ui.papertrail.attr('href', @model.get('papertrailUrl'))
      @ui.papertrail.removeClass('hidden')
      @ui.fix.removeClass('hidden') unless @model.get('call').endTime
    )

  fix: =>
    App.api.put(
      path: '/api/v2/app/calls/' + @model.get('call').callKey + '/fix'
      success : (response) =>
        @load(@ui.callId.val())
    )

module.exports = ContainerView
