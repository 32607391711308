import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { ActionType, useSupportSettingsDispatch, useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionRow, Settings } from '../styles'
import WebRTCIcon from '@ringdna/common/src/assets/icons/svg/WebRTC'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'

export default WebRTC
function WebRTC() {
  const state = useSupportSettingsState()
  const dispatch = useSupportSettingsDispatch()
  const isChecked = state.accountSettings['settings.webRTCEnabled']

  const handleChange = (webRTC: React.SetStateAction<boolean>) => {
    dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { 'settings.webRTCEnabled': webRTC } })
    if (!webRTC) {
      dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { 'settings.callForwarding': true } })
    }
  }

  return (
    <Section>
      <SectionRow>
        <Settings>
          <IconHelper icon={WebRTCIcon} iconSize={IconSize} variant="filled" />
          <RdnaText className={'rdna-text'}>WebRTC</RdnaText>
        </Settings>
        <RdnaToggleSwitch controlledState={{ isChecked, setIsChecked: handleChange }} />
      </SectionRow>
    </Section>
  )
}
