export const NOTES_TEMPLATES_STR = {
  delete: 'Delete',
  edit: 'Edit',
  headerOfTable: 'Create preconfigured Call Notes Templates.',
  name: 'name',
  teams: 'teams',
  enabled: 'enabled'
}

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }

export const initCallNotesTemplate = {
  id: 0,
  name: '',
  template: '',
  enabled: true,
  teamIds: []
}
