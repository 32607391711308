module.exports = class Columns

  @CSV = [
    {name: 'accountId',             label: 'Account ID',              cell: 'string'},
    {name: 'sfUserId',              label: 'SF User ID',              cell: 'string'},
    {name: 'email',                 label: 'Email',                   cell: 'string'},
    {name: 'dialerLicense',         label: 'Dialer License',          cell: 'boolean'},
    {name: 'adminLicense',          label: 'Admin License',           cell: 'boolean'},
    {name: 'conversationAILicense', label: 'Conversation AI License', cell: 'boolean'},
    {name: 'guidedSellingLicense',  label: 'Guided Selling License',  cell: 'boolean'},
    {name: 'yodaAILicense',         label: 'Moments™ License',         cell: 'boolean'},
    {name: 'calendarBookingLicense',label: 'Calendar Booking License', cell: 'boolean'},
    {name: 'assignNumber',          label: 'Assign Number',           cell: 'boolean'},
    {name: 'isoCountry',            label: 'Country Code',            cell: 'string'},
    {name: 'pattern',               label: 'Pattern',                 cell: 'string'},
    {name: 'errorDesc',             label: 'Error Description',       cell: 'string'},
    {name: 'location',              label: 'Location',                cell: 'string'}
  ]

  @RESULT = [
    {name: 'sfUserId',              label: 'SF User ID',              cell: 'string'},
    {name: 'userCreated',           label: 'User was created',        cell: 'boolean'},
    {name: 'userDescription',       label: 'User Description',        cell: 'string'},
    {name: 'userName',              label: 'User Name',               cell: 'string'},
    {name: 'userDisplayName',       label: 'Display Name',            cell: 'string'},
    {name: 'email',                 label: 'Email',                   cell: 'string'},
    {name: 'userId',                label: 'RD User Id',              cell: 'string'},
    {name: 'numberCreated',         label: 'Number was created',      cell: 'boolean'},
    {name: 'numberDescription',     label: 'Number Description',      cell: 'string'},
    {name: 'number',                label: 'Number',                  cell: 'string'},
    {name: 'numberId',              label: 'RD Number Id',            cell: 'string'},
    {name: 'location',              label: 'Location',                cell: 'string'},
    {name: 'dialerLicense',         label: 'Dialer License',          cell: 'boolean'},
    {name: 'adminLicense',          label: 'Admin License',           cell: 'boolean'},
    {name: 'conversationAILicense', label: 'Conversation AI License', cell: 'boolean'},
    {name: 'guidedSellingLicense',  label: 'Guided Selling License',  cell: 'boolean'},
    {name: 'yodaAILicense',         label: 'Moments™ License',         cell: 'boolean'},
    {name: 'calendarBookingLicense',label: 'Calendar Booking License', cell: 'boolean'}
  ]
