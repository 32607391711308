import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function SalesforceLeadIcon({ size = 16, color, title }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 15" fill="none">
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 .5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM14.998 6.75H1.002c-.5 0-.687.521-.28.755l3.654 1.953c.188.104.281.286.188.468L3.22 13.727c-.157.417.5.703.874.39l3.56-3.123c.189-.183.564-.183.751 0l3.562 3.124c.343.312.999.026.874-.39L11.436 9.9c-.062-.156.031-.364.188-.469l3.655-1.952c.406-.208.218-.729-.281-.729z"
        fill={color || Colors.SALESFORCE_LEAD}
      />
    </svg>
  )
}

export default SalesforceLeadIcon
