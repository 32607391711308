export const SMART_NUMBER_TABLE = {
  AreYouSureYouWantToDelete: 'Are you sure you want to delete this Smart Number?',
  cancel: 'Cancel',
  delete: 'Delete',
  deleteSmartNumber: 'Delete Smart Number',
  id: 'ID',
  name: 'Name',
  campaign: 'Campaign',
  campaignStatus: 'Campaign Status',
  CampaignSalesforceId: 'Campaign Salesforce ID',
  extraInfo: 'Extra Info',
  number: 'Number',
  remove: 'Remove',
  noResultsToDisplay: 'No results to display'
}
