import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../styles'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import StarIcon from '@ringdna/common/src/assets/icons/svg/Star'

export default ExtensionNonPhoneFilter
function ExtensionNonPhoneFilter() {
  const state = useSupportSettingsState()
  const extensionNonPhoneFilter = 'settings.extensionNonPhoneFilter'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={StarIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>Extension Non-Phone Filter</RdnaText>
      </SectionHeader>
      <SupportSettingInputRow
        title={'Extension Non-Phone Filter'}
        value={state.accountSettings['settings.extensionNonPhoneFilter']}
        setting={extensionNonPhoneFilter}
      />
    </Section>
  )
}
