var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-title\">\n  <h2>Account Details</h2>\n  <div class=\"navbar-right admin\">\n    <button class=\"ladda-button btn btn-info create-user\" data-toggle=\"modal\" data-target=\"#create-user-modal\">Add User</button>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-6 overview\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Account Name</label>\n    <div class=\"col-md-8\"><input type=\"text\" class=\"name\" disabled></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Account ID</label>\n    <div class=\"col-md-8\"><input type=\"text\" class=\"accountId\" disabled></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Account Alias</label>\n    <div class=\"col-md-8\"><input type=\"text\" class=\"accountAlias\" maxlength=\"250\"></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Primary Email</label>\n    <div class=\"col-md-8\"><input type=\"text\" class=\"primaryEmail\"></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Account Activated</label>\n    <span class=\"col-md-8\">"
    + container.escapeExpression((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"createdDate") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":27,"column":27},"end":{"line":27,"column":57}}}))
    + "</span>\n  </div>\n</div>\n<div class=\"clearfix\"></div>\n<br />\n\n<div class=\"locations-container\"></div>\n<div class=\"clearfix\"></div>\n<br />\n\n<div class=\"x-title\">\n  <h2>General Settings</h2>\n  <div class=\"clearfix\"></div>\n</div>\n\n<div class=\"col-md-6 general\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Manage Customer Data <span class=\"glyphicon glyphicon-question-sign blue\" data-toggle=\"tooltip\" data-original-title=\"Allows administrators to remove call and messaging data associated with a Salesforce Record.\"></span></label>\n    <div class=\"col-md-8\"><input type=\"checkbox\" class=\"cdm-account toggle-switch\"></div>\n  </div>\n  <div class=\"form-group send-feedback-group\">\n    <label class=\"col-md-4\" for=\"sendFeedback\">Send feedback to ringDNA <span class=\"glyphicon glyphicon-question-sign blue\" data-toggle=\"tooltip\" data-original-title=\"Send feedback to ringDNA.\"></span></label>\n    <div class=\"col-md-8\"><input id=\"sendFeedback\" class=\"sendFeedback toggle-switch\" type=\"checkbox\"></div>\n  </div>\n</div>\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Location Required for New Users <span class=\"glyphicon glyphicon-question-sign blue\" data-toggle=\"tooltip\" data-original-title=\"Enable to require location information for new users.\"></span></label>\n    <div class=\"col-md-8\"><input class=\"locationRequired toggle-switch\" type=\"checkbox\"></div>\n  </div>\n</div>\n<div class=\"clearfix\"></div>\n<div class=\"modal-container\"></div>\n";
},"useData":true});