import React from 'react'
import styled from 'styled-components'
import { useProductPortalToken } from '../../store/api/product-portal-api'

type ProductPortalProps = {
  minHeight: number
}

export default function ProductPortal({ minHeight }: ProductPortalProps) {
  const [payload] = useProductPortalToken()
  const iframeSrc = `https://portal.productboard.com/hbsagbb43qofwzsbqgxqmo28?token=${
    payload && (payload as { token: string }).token
  }`
  return (
    <>
      {payload && (
        <ProductPortalFrame
          title="product-portal"
          className="product-portal-iframe"
          src={iframeSrc}
          minHeight={minHeight}
        />
      )}
    </>
  )
}

const ProductPortalFrame = styled.iframe<{ minHeight: number }>`
  height: calc(100vh - 150px);
  min-height: ${({ minHeight }) => minHeight - 100}px;
  width: 100%;
  border: 0;
`
