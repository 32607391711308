BaseView = require('base/view')
BaseGrid = require('base/grid')
BackgridCell = require('helpers/backgrid-cell')
EntityCollection = require('./entity-collection')

class Step2Modal extends BaseView
  template: require('./step-2-template')

  regions:
    grid: 'div.grid'
    pager: 'div.pager'
    filter: 'span.filter'

  events:
    'click .assign-all': 'assignAll'

  initialize: (options) ->
    { @entityCollection, @accountId, @scriptId } = options

  onRender: =>
    if not @entityCollection.length
      @entityCollection
      .fetch(data:
        accountId : @accountId
        scriptId  : @scriptId
      )
      .done(=>
        @model.set('oldSelectedEntities', [])
        for entity in @entityCollection.fullCollection.models
          resources = entity.get('resources')
          selected = typeof (resource for resource in resources when resource.type is 'Script')?[0] isnt 'undefined'
          entity.set('selected', selected)
          @model.get('oldSelectedEntities').push(entity.get('id')) if selected
          entity.set('tags', @getTags(resources))
        if not @isDestroyed()
          @showChildView('grid', @buildGrid())
          @showChildView('pager', new Backgrid.Extension.Paginator(collection: @entityCollection))
          @showChildView('filter', @getClientSideFilter())
      )
    else
      @showChildView('grid', @buildGrid())
      @showChildView('pager', new Backgrid.Extension.Paginator(collection: @entityCollection))
      @showChildView('filter', @getClientSideFilter())


  onDestroy: ->
    @filterTable.clear() if @filterTable

  getTags: (resources) ->
    tagResources = (resource for resource in resources when resource.type is 'Tag')
    if tagResources
      tags = []
      tags = (tagResource.data for tagResource in tagResources)
      tags.join(', ')
    else
      ''

  getClientSideFilter: ->
    @filterTable = new Backgrid.Extension.ClientSideFilter(
      collection: @entityCollection
      placeholder: 'Search'
      fields: ['name', 'smartNumber.number']
      wait: 150
    )
    @filterTable.query = ->
      valueSearch = @searchBox().val()
      valueSearch = valueSearch.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
      @value = valueSearch

    @entityCollection.shadowCollection = @filterTable.shadowCollection
    @filterTable

  buildGrid: =>
    columns = [
      { name: 'selected',           label: 'Select', cell: BackgridCell.checkbox(), editable: true },
      { name: 'name',               label: 'Name',   cell: 'string' },
      { name: 'source',             label: 'Source', cell: 'string' },
      { name: 'smartNumber.number', label: 'Number', cell: 'string' },
      { name: 'tags',               label: 'Tags',   cell: 'string' }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @entityCollection
      emptyText  : 'No entities found.'
    )

  assignAll: (e) ->
    entity.set('selected', e.target.checked) for entity in @entityCollection.fullCollection.models

module.exports = Step2Modal
