import React, { useState } from 'react'
import styled from 'styled-components'

import RdnaTooltip from '../RdnaTooltip'
import RdnaText from '../RdnaText'
import LockIcon from '../../assets/icons/svg/LockIcon'
import RdnaModal from '../RdnaModal'

type RdnaLockedSettingProps = {
  locked: boolean
  isDisabled?: boolean
  saveSetting: any
  content: any
}

const RdnaLockedSetting = React.memo(({ locked, isDisabled = false, saveSetting, content }: RdnaLockedSettingProps) => {
  const { title, tooltip, modalText } = content
  const lockState = !locked ? 'unlock' : 'lock'
  const lockText = locked ? 'Unlock' : 'Lock'
  const modalTitle = `${lockText} ${title}`

  const [isLockModalOpen, setLockModalOpen] = useState(false)

  const openLockModal = () => {
    setLockModalOpen(true)
  }
  const closeLockModal = () => {
    setLockModalOpen(false)
  }
  const handleClick = () => {
    closeLockModal()
    saveSetting()
  }

  return (
    <>
      <LockButton disabled={isDisabled}>
        <RdnaTooltip title={tooltip[lockState]} aria-label={tooltip[lockState]}>
          <TooltipRefContainer onClick={openLockModal} data-testid={'lockTooltipRef'}>
            <LockIcon isDisabled={isDisabled} isLocked={locked} />
          </TooltipRefContainer>
        </RdnaTooltip>
      </LockButton>
      <ConfirmationModal
        isLockModalOpen={isLockModalOpen}
        modalTitle={modalTitle}
        modalText={modalText[lockState]}
        saveButtonText={lockText}
        closeModal={closeLockModal}
        handleClick={handleClick}
      />
    </>
  )
})

type ConfirmModalProps = {
  isLockModalOpen: boolean
  modalTitle: string
  modalText: string
  closeModal: any
  saveButtonText: any
  handleClick: any
}

const ConfirmationModal = React.memo(
  ({ isLockModalOpen, modalTitle, modalText, closeModal, saveButtonText, handleClick }: ConfirmModalProps) => {
    return (
      <RdnaModal
        open={isLockModalOpen}
        heading={modalTitle}
        onClose={closeModal}
        onConfirm={handleClick}
        confirmButtonText={saveButtonText}
      >
        <RdnaText>{modalText}</RdnaText>
      </RdnaModal>
    )
  }
)

const TooltipRefContainer = styled.div`
  padding: 0;
`

const LockButton = styled.button`
  display: inline;
  height: 24px;
  margin: 15px 0 0 15px;
  border: 0;
  padding: 0;
`

export default RdnaLockedSetting
