BaseView = require('base/view')

class Step1 extends BaseView

  template: require('./step-1-template')

  className: 'call-disposition-step-1-editor'

  events:
    'click .next': 'nextStep'
    'keyup input.disposition': 'changeDisposition'

  bindings:
    '.disposition': 'value:disposition,disabled:isEdit'
    '.disposition-outcomeCategory': 'value:outcomeCategory'
    '.next': "classes:{'disabled': nextButtonDisabled}"

  computeds:
    isEdit:
      get: -> @isEdit

  initialize: (options) =>
    { @accountId, @isEdit } = options
    @viewModel = new Backbone.Model(nextButtonDisabled: not @model.get('disposition')?.trim())
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  changeDisposition: (e) =>
    @viewModel.set(nextButtonDisabled: not $(e.target).val()?.trim())

module.exports = Step1
