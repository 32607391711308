import React, { useCallback, useEffect, useMemo, useState } from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { Link, useParams } from 'react-router-dom'
import { useMethodologies, useRoleDetails, useRoleMethodologyUpdate } from '../api'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import styled from 'styled-components'
import { Direction } from '@ringdna/common/src/assets/icons/types'
import CaretIcon from '@ringdna/common/src/assets/icons/svg/Caret'
import { Colors } from '@ringdna/common/src/constants/colors'
import { RolesProps } from './main'
import UsersTable from './UsersTable'
import RdnaSection from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { Methodology, SelectEvent } from '../types'
import MethodologiesPanel from './MethodologiesPanel'
import { SelectContainer, StyledContainer } from '../styles'
import AssignmentUserRoleDrawer from './AssignmentUserRoleDrower'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'

export default function RoleDetail({ accountId, roleDefinitions }: RolesProps) {
  const { id: routeRoleId } = useParams<{ id: string }>()
  const { error } = useRdnaNotification()
  const [methodology, setMethodology] = useState<Methodology>()
  const [isMethodologiesDrawerOpen, setIsMethodologiesDrawerOpen] = useState(false)
  const [isAssignUsersDrawerOpen, setIsAssignUsersDrawerOpen] = useState(false)
  const [payload, , loading, refetch] = useRoleDetails({
    meta: { accountId, roleId: Number(routeRoleId) }
  })
  const [methodologies] = useMethodologies()
  const updateRoleMethodology = useRoleMethodologyUpdate()

  const updateMethodology = useCallback(
    (methodologyId: number) => {
      const selectedMethodology = methodologies?.find(methodology => methodology.id === methodologyId)
      setMethodology(selectedMethodology)
    },
    [methodologies]
  )

  const closeDrawer = () => {
    setIsMethodologiesDrawerOpen(false)
    setIsAssignUsersDrawerOpen(false)
  }

  const onAssignUsers = async () => {
    setIsAssignUsersDrawerOpen(true)
  }

  const onViewMethodologies = () => {
    setIsMethodologiesDrawerOpen(true)
  }

  useEffect(() => {
    if (payload?.methodology?.id) {
      updateMethodology(payload.methodology.id)
    }
  }, [payload, updateMethodology])

  const getMethodologiesOptions = useMemo(() => {
    const options =
      methodologies !== undefined
        ? methodologies?.map(methodology => ({ label: methodology.name, value: methodology.id }))
        : []
    options.unshift({ label: 'None', value: -1 })
    return options
  }, [methodologies])

  const onMethodologyChange = async (event: SelectEvent) => {
    try {
      await updateRoleMethodology({
        meta: { roleId: routeRoleId },
        json: { methodologyId: event.target.value, accountId }
      })
      updateMethodology(event.target.value)
    } catch (e) {
      error(getErrorMessage(e))
    }
  }

  return (
    <RdnaLoader loading={!!loading} top="150px" data="data">
      <StyledContainer>
        <BackButton to={''}>
          <CaretIcon direction={Direction.LEFT} color={Colors.M50} />
          <RdnaText variant="h4" color="link">
            &nbsp; Back to Roles
          </RdnaText>
        </BackButton>
        <RdnaButton text={'Assign Users'} onClick={onAssignUsers}></RdnaButton>
      </StyledContainer>
      <RdnaSection formTitle={payload?.name}>
        <RdnaText bold>{`${payload?.name}: `}</RdnaText>
        <RdnaText>{`${payload?.description}`}</RdnaText>
      </RdnaSection>
      <StyledContainer style={{ width: 'fit-content' }}>
        <RdnaText bold>Methodology:</RdnaText>
        <SelectContainer>
          <RdnaSelect
            name={'methodology'}
            options={getMethodologiesOptions}
            value={methodology?.id || -1}
            onChange={e => onMethodologyChange(e as SelectEvent)}
          ></RdnaSelect>
        </SelectContainer>
        <RdnaButton text={'View Available Methodologies'} variant="outlined" onClick={onViewMethodologies}></RdnaButton>
      </StyledContainer>
      <RdnaSection subtitle={methodology?.description}></RdnaSection>
      <UsersTable
        payload={payload}
        loading={!!loading}
        refetch={refetch}
        roleId={parseInt(routeRoleId)}
        roleDefinitions={roleDefinitions}
      ></UsersTable>
      <MethodologiesPanel open={isMethodologiesDrawerOpen} onClose={closeDrawer} methodologies={methodologies || []} />
      <AssignmentUserRoleDrawer
        isOpen={isAssignUsersDrawerOpen}
        onClose={closeDrawer}
        roleDefinitions={roleDefinitions}
        listUnassignedUsers={payload?.listUnassignedUsers || []}
        accountId={accountId}
        roleId={parseInt(routeRoleId)}
        refetch={refetch}
      />
    </RdnaLoader>
  )
}

const BackButton = styled(Link)`
  display: inline-flex;
  text-decoration: none !important;
`
