BaseView                          = require('base/view')
TwilioCallbacksMainLayoutTemplate = require('./main-layout-template')
TwilioCallbacksCollectionView     = require('./collection-view')

module.exports = class TwilioCallbacksMainLayoutView extends BaseView

  template: TwilioCallbacksMainLayoutTemplate

  id: 'twilioCallbacks'

  regions:
    twilioCallbacks: '#twilioCallbacks'

  initialize: (options) ->
    {@callModel} = options

  render: =>
    super
    @showChildView('twilioCallbacks', new TwilioCallbacksCollectionView(callModel: @callModel, collection: @collection))