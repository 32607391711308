class BaseView extends Marionette.View
  constructor: ->
    super
    @epoxify()

  epoxify: ->
    Backbone.Epoxy.View.mixin(@)
    @listenTo(@, 'ui:bind', @applyBindings)
    @listenTo(@, 'before:destroy', @removeBindings)

  bindUIElements: ->
    @trigger('ui:bind')
    super

  showSpinner: (e, options = {}) ->
    options['color'] = '#666'
    spinner = new Spinner(options).spin()
    e.prepend(spinner.el)

  hideSpinner: (e) ->
    e.find('.spinner').remove()

  track: (event, data) ->
    App.tracker.track(event, data)

  user: ->
    App.session.user

  getAccountORNull: =>
    if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId() else null

module.exports = BaseView
