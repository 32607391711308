import React, { forwardRef } from 'react'
import { Alert } from '@mui/material'
import { AlertProps } from '@mui/lab'
import RdnaButton from '../RdnaButton'
import RdnaText, { colorpalettes } from '../RdnaText'
import styled from 'styled-components'

export type NotificationType = 'error' | 'success' | 'warning' | 'info'

type Props = {
  /** Content to go within the card */
  message: React.ReactNode
  rightSideContent?: React.ReactNode
  /** Type of notification which determines color.
   * Error = red, warning = orange, success = green, info = gray
   * */
  type?: NotificationType
  /** Text to display within action button */
  buttonText?: string
  /** Event fired when user clicks on X close button. If no method passed. The x button will not show */
  onClose?: () => void
  /** Event fired when user clicks on action button. If no method passed. The button will not show */
  onButtonClick?: () => void
  /** Additional classname to card to enabling styling from parent */
  className?: string
} & AlertProps

const getThemeColor = (type: NotificationType): keyof typeof colorpalettes => {
  switch (type) {
    case 'error':
      return 'alert'
    default:
      return type
  }
}

const RdnaNotification = forwardRef(
  (
    {
      message,
      type = 'error',
      buttonText = 'Done',
      onButtonClick,
      onClose,
      className = '',
      rightSideContent,
      ...props
    }: Props,
    ref
  ) => {
    const themeColor = getThemeColor(type)

    const action =
      rightSideContent || onButtonClick ? (
        <ActionContainer>
          {rightSideContent}
          {onButtonClick ? <RdnaButton color={themeColor} text={buttonText} onClick={onButtonClick} /> : null}
        </ActionContainer>
      ) : null
    return (
      <Alert
        variant="filled"
        severity={type}
        className={className}
        action={action}
        onClose={onClose}
        ref={ref as any}
        {...props}
      >
        <StyledText data-testid="notification-message" color={themeColor}>
          {message}
        </StyledText>
      </Alert>
    )
  }
)

export default RdnaNotification

const StyledText = styled(RdnaText)`
  white-space: pre-line;
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing * 2}px;
`
