BaseView = require('base/view')
LicenseRequestModel = require('./license-request-model')

class LicenseRequestModal extends BaseView

  template: require('./license-request-template')
  className: 'license-request-modal'

  ui:
    addAdminUsers         : 'input.addAdminUsers'
    addDialerUsers         : 'input.addDialerUsers'
    addNylasUsers         : 'input.addNylasUsers'
    addCaiUsers           : 'input.addCaiUsers'
    addSequenceUsers      : 'input.addSequenceUsers'
    addYodaAIUsers        : 'input.addYodaAIUsers'
    datePicker            : '.date-picker'
    enableEffectiveDate   : '.enableEffectiveDate'
    go                    : 'button.request'
    modal                 : '#license-request-modal'

  bindings:
    '.addSmartNumbers'            : 'checked:addSmartNumbers'
    '.addMinutes'                 : 'checked:addMinutes'
    '.addAdminUsers'              : 'checked:addAdminUsers'
    '.addDialerUsers'              : 'checked:addDialerUsers'
    '.addNylasUsers'              : 'checked:addNylasUsers'
    '.addCaiUsers'                : 'checked:addCaiUsers'
    '.addSequenceUsers'           : 'checked:addSequenceUsers'
    '.addYodaAIUsers'             : 'checked:addYodaAIUsers'
    '.smartNumbersQuantity'       : 'disabled:not(addSmartNumbers),value:smartNumbersQuantity'
    '.minutesQuantity'            : 'disabled:not(addMinutes),value:minutesQuantity'
    '.adminUsersQuantity'         : 'disabled:not(addAdminUsers),value:adminUsersQuantity'
    '.dialerUsersQuantity'         : 'disabled:not(addDialerUsers),value:dialerUsersQuantity'
    '.nylasUsersQuantity'         : 'disabled:not(addNylasUsers),value:nylasUsersQuantity'
    '.caiUsersQuantity'           : 'disabled:not(addCaiUsers),value:caiUsersQuantity'
    '.sequenceUsersQuantity'      : 'disabled:not(addSequenceUsers),value:sequenceUsersQuantity'
    '.yodaAIUsersQuantity'        : 'disabled:not(addYodaAIUsers),value:yodaAIUsersQuantity'
    '.effectiveDate'              : 'value:effectiveDate'
    '.details'                    : 'value:details'

  events:
    'click @ui.go'               : 'go'
    'click @ui.addAdminUsers'    : 'checkShowEnableEffectiveDate'
    'click @ui.addDialerUsers'    : 'checkShowEnableEffectiveDate'
    'click @ui.addNylasUsers'    : 'checkShowEnableEffectiveDate'
    'click @ui.addCaiUsers'      : 'checkShowEnableEffectiveDate'
    'click @ui.addSequenceUsers' : 'checkShowEnableEffectiveDate'
    'click @ui.addYodaAIUsers'   : 'checkShowEnableEffectiveDate'

  checkShowEnableEffectiveDate: =>
    @ui.enableEffectiveDate.toggle(@ui.addAdminUsers[0].checked || @ui.addDialerUsers[0].checked || @ui.addNylasUsers[0].checked || @ui.addSequenceUsers[0].checked || @ui.addYodaAIUsers[0].checked)

  initialize: (options) =>
    { @accountId } = options
    @viewModel = new Backbone.Model(
      addSmartNumbers: false
      addMinutes: false
      addAdminUsers: false
      addDialerUsers: false
      addNylasUsers: false
      addCaiUsers: false
      addSequenceUsers: false
      addYodaAIUsers: false
      smartNumbersQuantity: null
      minutesQuantity: null
      adminUsersQuantity: null
      dialerUsersQuantity: null
      nylasUsersQuantity: null
      caiUsersQuantity: null
      sequenceUsersQuantity: null
      yodaAIUsersQuantity: null
      effectiveDate: null
      details: ''
    )

  onRender: =>
    @ui.datePicker.datepicker(
      autoclose   : true
      dateFormat  : 'mm/dd/yyyy'
      minViewMode : 'days'
      maxViewMode : 'days'
      orientation : 'bottom'
      startDate   : new Date()
    )
    @ui.enableEffectiveDate.hide()

  validate: =>
    addSmartNumbers = @viewModel.get('addSmartNumbers')
    smartNumbersQuantity = @viewModel.get('smartNumbersQuantity')
    smartNumbers = not addSmartNumbers or addSmartNumbers and smartNumbersQuantity and parseInt(smartNumbersQuantity) > 0

    addMinutes = @viewModel.get('addMinutes')
    minutesQuantity = @viewModel.get('minutesQuantity')
    minutes = not addMinutes or addMinutes and minutesQuantity and parseInt(minutesQuantity) > 0

    addAdminUsers = @viewModel.get('addAdminUsers')
    adminUsersQuantity = @viewModel.get('adminUsersQuantity')
    adminUsers = not addAdminUsers or addAdminUsers and adminUsersQuantity and parseInt(adminUsersQuantity) > 0

    addDialerUsers = @viewModel.get('addDialerUsers')
    dialerUsersQuantity = @viewModel.get('dialerUsersQuantity')
    dialerUsers = not addDialerUsers or addDialerUsers and dialerUsersQuantity and parseInt(dialerUsersQuantity) > 0

    addNylasUsers = @viewModel.get('addNylasUsers')
    nylasUsersQuantity = @viewModel.get('nylasUsersQuantity')
    nylasUsers = not addNylasUsers or addNylasUsers and nylasUsersQuantity and parseInt(nylasUsersQuantity) > 0

    addCaiUsers = @viewModel.get('addCaiUsers')
    caiUsersQuantity = @viewModel.get('caiUsersQuantity')
    caiUsers = not addCaiUsers or addCaiUsers and caiUsersQuantity and parseInt(caiUsersQuantity) > 0

    addSequenceUsers = @viewModel.get('addSequenceUsers')
    sequenceUsersQuantity = @viewModel.get('sequenceUsersQuantity')
    sequenceUsers = not addSequenceUsers or addSequenceUsers and sequenceUsersQuantity and parseInt(sequenceUsersQuantity) > 0

    addYodaAIUsers = @viewModel.get('addYodaAIUsers')
    yodaAIUsersQuantity = @viewModel.get('yodaAIUsersQuantity')
    momentsUsers = not addYodaAIUsers or addYodaAIUsers and yodaAIUsersQuantity and parseInt(yodaAIUsersQuantity) > 0

    anySelected = addSmartNumbers or addMinutes or addAdminUsers or addDialerUsers or addNylasUsers or caiUsers or addSequenceUsers or addYodaAIUsers

    if smartNumbers and minutes and adminUsers and dialerUsers and nylasUsers and anySelected and caiUsers and sequenceUsers and momentsUsers
      true
    else
      toastr.warning("Please select at least one feature you\'d like to add and enter correct quantity value.")
      false

  go: =>
    return unless @validate()

    model = new LicenseRequestModel(
      accountId: @accountId
      smartNumbers: if @viewModel.get('addSmartNumbers') then @viewModel.get('smartNumbersQuantity') else 0
      minutes: if @viewModel.get('addMinutes') then @viewModel.get('minutesQuantity') else 0
      adminUsers: if @viewModel.get('addAdminUsers') then @viewModel.get('adminUsersQuantity') else 0
      dialerUsers: if @viewModel.get('addDialerUsers') then @viewModel.get('dialerUsersQuantity') else 0
      nylasUsers: if @viewModel.get('addNylasUsers') then @viewModel.get('nylasUsersQuantity') else 0
      caiUsers: if @viewModel.get('addCaiUsers') then @viewModel.get('caiUsersQuantity') else 0
      sequenceUsers: if @viewModel.get('addSequenceUsers') then @viewModel.get('sequenceUsersQuantity') else 0
      momentsUsers: if @viewModel.get('addYodaAIUsers') then @viewModel.get('yodaAIUsersQuantity') else 0
      effectiveDate: @viewModel.get('effectiveDate')
      details: @viewModel.get('details')
    )
    model
      .persist(ladda: @ui.go[0])
      .done?(=>
        @ui.modal.modal('hide')
        toastr.info('Your request was submitted.')
      )

module.exports = LicenseRequestModal
