import { createUseFetch } from '@ringdna/client/src/react/createUseFetch'

const API_BASE = '/api/v2/app/reports/urlForDashboard'

type ReportUrlPayload = {
  iframeUrl: string
}

type FetchReportUrlParams = {
  query: {
    name: string
    accountId: number
    smartNumberId: number
    dateFilter: string
  }
}

export const fetchReportUrl = createUseFetch<ReportUrlPayload, FetchReportUrlParams>({
  key: 'smart-number-embedded-analytics',
  path: `${API_BASE}`
})
