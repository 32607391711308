BaseModel           = require('base/model')
BackgridCell        = require('helpers/backgrid-cell')
BaseGrid            = require('base/grid')
BaseView            = require('base/view')
SkillCollection     = require('./collection')
SkillModel          = require('./model')

class SkillsView extends BaseView
  template: require('./template')
  className: 'skills'

  regions:
    grid  : 'div.grid'

  ui:
    addSkill: '.add-skill'

  events:
    'click .add-skill' : 'addSkill'

  bindings:
    '.skill-name' : 'value:skillName'

  initialize: (options) ->
    { @accountId } = options
    @collection = new SkillCollection()
    @viewModel = new Backbone.Model(
      skillName: null
    )

  onRender: =>
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        return unless @getRegion('grid')
        @showChildView('grid', @buildGrid())
      )

  buildGrid: =>
    skillNameCell = class extends Backgrid.Cell
      events:
        'change .skill-name-input' : (e) -> @model.set(name: e.target.value)
      render: ->
        if @model.get('onEdit')
          html = "<input class='form-control skill-name-input' value='#{@model.get('name')}'>"
        else
          html = "<span>#{_.escape(@model.get('name'))}</span>"
        @listenTo(@model, 'change:onEdit', @render)
        $(@el).html(html)
        @

    callFlowsCell = class extends Backgrid.Cell
      render: ->
        callFlowSteps = @model.get('callFlowSteps')
        if callFlowSteps.length
          uniqCallFlowSteps = _.uniq(callFlowSteps, false, (step) =>
            step.callFlow.id
          )
          sortedUniqCallFlowSteps = _.sortBy(uniqCallFlowSteps, (step) =>
            step.callFlow.name
          )
          html = ''
          for step in sortedUniqCallFlowSteps
            href = '#call-flows/' + step.callFlow.id
            html += "<div><a href='#{href}'>#{step.callFlow.name}</a></div>"
        else
          html = '<span>none</span>'
        $(@el).html(html)
        @

    updateSaveCell = class extends Backgrid.Cell
      events:
        'click button[data-action="edit"]': 'edit'
        'click button[data-action="save"]': 'save'
      edit: =>
        @model.set(onEdit: true)
        @render()
      save: =>
        @model
          .persist(ladda: @$('.save-skill')[0])
          .done(=>
            @model.set(onEdit: false)
            @render()
          )
      render: ->
        if @model.get('onEdit')
          html = '''<button class="btn btn-success save-skill" data-action="save">Save</button>'''
        else
          html = '''<button class="btn btn-primary" data-action="edit">Update</button>'''
        $(@el).html(html)
        @

    deleteCell = class extends Backgrid.Cell
      events:
        'click a.delete': 'delete'
      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          if ok
            @model.destroy()
            @column.channel?.trigger('remove')
          true
        )
      render: ->
        html = ''
        unless @model.get('callFlowSteps').length
          html = '<a class="delete"><span class="glyphicon glyphicon-remove-sign"></span></a>'
        $(@el).html(html)
        @


    columns = [
      { name: 'name',              label: 'Skill Name',      cell: skillNameCell,       sortable: true  },
      { name: 'userSkills.length', label: 'Number of Users', cell: 'integer',           sortable: true },
      { name: '',                  label: 'Call Flows',      cell: callFlowsCell,       sortable: false },
      { name: '',                  label: 'Update',          cell: updateSaveCell,      sortable: false },
      { name: '',                  label: 'Delete',          cell: deleteCell,          sortable: false }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No skills found.'
    )

  addSkill: =>
    skillName = @viewModel.get('skillName')
    unless skillName
      toastr.warning('Please enter skill name.')
      return
    skill = new SkillModel(
      name: skillName
      accountId: @accountId
    )
    skill
      .persist(ladda: @ui.addSkill[0])
      .done( =>
        @collection.add(skill)
        @viewModel.set(skillName: null)
      )

module.exports = SkillsView
