import React from 'react'
import styled from 'styled-components'

import { CircularProgress } from '@mui/material'
import RdnaText from '../RdnaText'
import NoResults from '../../assets/illustrations/NoResults'

export type LoaderProps<Data> = {
  data: Data | undefined
  loading: boolean
  children: React.ReactNode
  noResultsMsg?: React.ReactElement | string
  top?: string
  LoadingIcon?: ({ size }: { size: number }) => JSX.Element
  size?: number
  CustomLoader?: React.ComponentType
  testId?: string
}

const ProgressDiv = styled.div<{ $top: string }>`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.$top};
`

export default function RdnaLoader<Data>({
  data,
  loading,
  children,
  top = '0px',
  size = 50,
  LoadingIcon,
  noResultsMsg,
  CustomLoader,
  testId = 'loader'
}: LoaderProps<Data>) {
  const NoResults =
    typeof noResultsMsg === 'object' ? noResultsMsg : <NoResultsMsg title={noResultsMsg || 'No results to display'} />
  const dataExists = data && (Array.isArray(data) ? data.length > 0 : Object.entries(data).length > 0)
  const dataDoesNotExist = data && (Array.isArray(data) ? data.length === 0 : Object.entries(data).length === 0)
  return (
    <>
      {loading || !data ? (
        !!CustomLoader ? (
          <CustomLoader />
        ) : (
          <ProgressDiv $top={top} data-testid={testId}>
            {LoadingIcon ? <LoadingIcon size={size} /> : <CircularProgress size={size} />}
          </ProgressDiv>
        )
      ) : null}
      {!loading && dataExists && children}
      {!loading && dataDoesNotExist && NoResults}
    </>
  )
}

const StyledNoResultsMsg = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing * 12}px 0;
  svg,
  div {
    display: block;
    margin: 0 auto;
  }
  .has-icon {
    padding-top: ${props => props.theme.spacing * 3}px;
  }
`

type NoResultsMsgProps = {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  Icon?: React.ReactNode
}

export function NoResultsMsg({ title, subtitle, Icon }: NoResultsMsgProps) {
  return (
    <StyledNoResultsMsg className="no-search-results" data-testid={'no-search-results'}>
      {Icon && Icon}
      {title && (
        <RdnaText variant={subtitle ? 'h2' : 'h3'} className={Icon ? 'has-icon' : ''}>
          {title}
        </RdnaText>
      )}
      {subtitle && <RdnaText variant="body3">{subtitle}</RdnaText>}
    </StyledNoResultsMsg>
  )
}
