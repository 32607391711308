StepModel = require('../../model')

class SMSStepModel extends StepModel

  defaults:
    type: 'SMS'
    info: '''Send a text message to the caller. If you would like to specify the recipient of the SMS message (other than the caller),
            please enter the phone number where you would like the SMS to be sent when this call flow is triggered.'''
    fixedPlaceholders: [ '_' ]
    args:
      smsNumbers: ''
    leaf: false

  nextStep: ->
    @get('nextStep')

  smsNumbers: ->
    @args()['smsNumbers']

  smsType: ->
    @args()['smsType']

  text: ->
    @args()['text']

  errors: ->
    messages = []
    selectors = []
    if not @text()?.trim()
      messages.push('Message is required')
      selectors.push('.text')
    if @smsType() is 'nonAgent'
      numbers = @smsNumbers().split(',')
      if (number for number in numbers when number).length isnt numbers.length
        messages.push('Phone numbers are required')
        selectors.push('.numbers-error')
      else
        for n in numbers when n.trim()
          p = n.replace(/\s|\-|\(|\)/ig, '')
          intRegex = /^\d+$/
          if not intRegex.test(p)
            messages.push('Phone number must be numeric')
            selectors.push('.numbers-error')

    _.zip(messages, selectors)

module.exports = SMSStepModel