import React from 'react'
import { SnackbarProvider } from 'notistack'
import { IntegrationsProvider } from './store/IntegrationsProvider'
import ActiveUserContainer from './ActiveUserContainer'

export default function Settings() {
  return (
    <SnackbarProvider maxSnack={1}>
      <IntegrationsProvider>
        <ActiveUserContainer />
      </IntegrationsProvider>
    </SnackbarProvider>
  )
}
