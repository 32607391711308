AdwordsCallTrackingСollection = require('./../adwords-collection')
BaseView = require('base/view')
BaseGrid = require('base/grid')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')
UnassignedNumbersGrid = require('../../__components/unassigned-numbers-grid/view')

class Step2Modal extends BaseView
  template: require('./step-2-template')

  regions:
    grid: 'div.grid'
    numbersGrid: 'div.numbers-grid'

  bindings:
    '.stepNext': "classes:{'disabled': finishEnable}"
    '.add-call-tracking-grid': 'classes:{"unset-main-grid":hasUnset}'

  events:
    'click .btn-success': 'finish'

  initialize: (options) =>
    { @accountId, @viewModel } = options
    @viewModel.set(hasUnset: @collection.length <= 3)
    @newCollection = new AdwordsCallTrackingСollection()
    @listenTo(@newCollection, 'change:typeNumber', (model) => @checkChangedTypeNumber(model))
    @activeModelForUnassignedNumber = null

  onRender: =>
    @showSpinner($(@el), left: '45%')
    CallTrackingWidgets.getInfo(@accountId, (campaigns, callflows, countries) =>
      @hideSpinner($(@el))
      @newCollection.reset(@collection.models)
      @showChildView('grid', @buildGrid(campaigns, callflows, countries)) if @getRegion('grid')
      $('[data-toggle="tooltip"]').tooltip()
    )

  checkChangedTypeNumber: (changedModel) =>
    if changedModel.get('typeNumber') is 'Existing'
      @activeModelForUnassignedNumber = changedModel
      @showChildView('numbersGrid', new UnassignedNumbersGrid(accountId: @accountId, changedModel: changedModel))
    else
      if @activeModelForUnassignedNumber?.cid == changedModel.cid
        @getRegion('numbersGrid').destroy() if @getRegion('numbersGrid')

  buildGrid: (campaigns, callflows, countries) =>
    mainClass = @

    labelCell = class extends Backgrid.Cell
      events:
        'change .label-tracking-cell': 'labelChanged'

      labelChanged: (e) =>
        @model.set('labelTracking', $(e.target).val())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:labelTracking', => @showHtml(not @model.get('labelTracking')))
        @listenTo(@model, 'checkLabel', => @showHtml(true))

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control label-tracking-cell' maxlength='255' value=\"#{@model.get('labelTracking')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    deleteCell = class extends Backgrid.Cell
      events:
        'click a.delete': 'delete'

      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          @model.destroy(data: accountId: @model.get('accountId')) if ok
          true
        )
      render: =>
        @showHtml()
        @listenTo(mainClass.collection, 'update', => @showHtml())

      showHtml: =>
        html = """<a class="delete"><span class='glyphicon glyphicon-remove-sign'></span></a>""" if mainClass.collection.length > 1
        $(@el).html(if html then html else '')
        @

    columns = [
      {label: 'Name',          name: 'labelTracking', cell: labelCell,                                      sortable: false}
      {label: 'Tracking Type', name: 'type',          cell: 'string',                                       sortable: false}
      {label: 'Additional',    name: 'channel',       cell: 'string',                                       sortable: false}
      {label: 'Country',       name: 'country',       cell: CallTrackingWidgets.countryCell(countries),     sortable: false}
      {label: 'Smart Number',  name: 'type',     cell: CallTrackingWidgets.typeSmartNumberCell(),           sortable: false}
      {label: '',              name: 'smartNumberId', cell: CallTrackingWidgets.typeSmartNumberValueCell(), sortable: false}
      {label: 'Area Code',     name: 'code',     cell: CallTrackingWidgets.areaCodeCell(),                  sortable: false}
      {label: 'Call Flow',     name: 'callFlow', cell: CallTrackingWidgets.callFlowCell(callflows),         sortable: false}
      {label: 'SFDC Campaign', name: 'campaign', cell: CallTrackingWidgets.campaignCell(campaigns),         sortable: false}
      {label: '',              name: '',         cell: deleteCell}
    ]

    new BaseGrid.Grid(
      collection : @newCollection,
      columns    : columns
      emptyText  : 'No tracks found.'
    )

  finish: =>
    @collection.reset(@newCollection.models)

module.exports = Step2Modal
