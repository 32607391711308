OutboundNumberModel = require('./../model')
BaseView = require('base/view')

class OutboundNumberModal extends BaseView
  template: require('./template')
  className: 'outbound-number-create'

  ui:
    add    : 'button.add'
    number : 'input.phone-number'
    label  : 'input.phone-label'
    modal  : '#outbound-number-modal'

  events:
    'click @ui.add' : 'add'

  initialize: (options) ->
    {@userId, @outboundNumbers} = options
    @model = new OutboundNumberModel()

  onAttach: =>
    @ui.modal.on('hide.bs.modal', (e) =>
      @triggerMethod('reload') if @reloadOnClose
    )

  add: =>
    return unless @validate()

    @model = new OutboundNumberModel(
      userId : @userId
      number : @ui.number.val()
      label  : @ui.label.val()
    )

    @model
      .persist(ladda: @ui.add[0])
      .done( =>
        @model.set(name: @model.get('label'))
        @outboundNumbers.add(@model)
        @ui.modal.modal('hide')
        toastr.info('Outbound number created.')
      )
      .fail(@ui.modal.modal('hide'))

  validate: =>
    if not @ui.number.val()
      toastr.warning('Please enter a phone number.')
      return false

    true

module.exports = OutboundNumberModal
