BaseView = require('base/view')
LicenseModel = require('../model')

class CreateLicenseModal extends BaseView

  template: require('./create-license-template')
  className: 'create-license-modal'

  ui:
    type   : '.type'
    amount : '.amount'
    reason : '.reason'
    notes  : '.notes'
    go     : 'button.create'
    modal  : '#create-license-modal'

  events:
    'click @ui.go' : 'go'

  initialize: (options) =>
    { @accountId } = options

  go: =>
    license = new LicenseModel(
      accountId : @accountId
      type      : @ui.type.val()
      amount    : @ui.amount.val()
      reason    : @ui.reason.val()
      notes     : @ui.notes.val()
    )

    if not license.isValid()
      toastr.warning(license.validationError)
      return false

    license
      .persist(ladda: @ui.go[0])
      .done(=>
        @ui.modal.modal('hide')
        toastr.info('License added.')
        @model.fetch(data: { accountId: @accountId })
        # hack to make backdrop disappear and window scrollable
        $('.modal-backdrop').remove()
        $('body').removeClass('modal-open')
      )

module.exports = CreateLicenseModal
