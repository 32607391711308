import React from 'react'
import styled from 'styled-components'
import RdnaText from '../RdnaText'

export interface CountBubbleProps {
  /** Count to display */
  count: React.ReactNode
  /** Diameter of bubble */
  size?: number
  /** Color of bubble. */
  color?: 'primary' | 'neutral' | 'link' | 'alert' | 'action' | 'contrast'
  noZero?: boolean
  style?: React.CSSProperties
}

const RdnaCountBubbleContainer = styled.div<{ size: number; color: string }>`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme, color }) => theme.palette.icon[color]};
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => (size * 2) / 3}px;
  border-radius: ${({ size }) => size}px;
  & span {
    flex: 1;
    margin: 0 ${({ theme }) => theme.spacing / 2}px;
    line-height: ${({ size }) => (size * 2) / 3}px;
    text-align: center;
  }
`

// not using DEFAULT_ICON_SIZE below because storybook will not display it
export default function RdnaCountBubble({
  count,
  size = 24,
  color = 'neutral',
  noZero = false,
  style
}: CountBubbleProps) {
  if (noZero && !count) return null
  return (
    <RdnaCountBubbleContainer color={color} size={size} style={style}>
      <RdnaText variant={'meta'} color={color === 'contrast' ? 'neutral' : 'contrast'}>
        {count}
      </RdnaText>
    </RdnaCountBubbleContainer>
  )
}
