import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import React from 'react'
import { useUser } from '../../../common/services/UserContext'
import styled from "styled-components";

interface Props {
  agreementCheck: boolean
  setAgreementCheck: (value: boolean) => void
  disabled: boolean
}

export default function AgreementCheck({ agreementCheck, setAgreementCheck, disabled }: Props) {
  return (
    <Checkbox
      data-testid={'agreement-check'}
      value={agreementCheck}
      disabled={disabled}
      onChange={value => setAgreementCheck(value)}
      label={
        <>
          By checking this box, you agree to grant Revenue access to your recordings per our{' '}
          <a href={'https://www.revenue.io/privacy-policy'} target={'_blank'} rel="noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href={'https://www.revenue.io/msa'} target={'_blank'} rel="noreferrer">
            Master Service Agreement
          </a>
          .
        </>
      }
    />
  )
}

const Checkbox = styled(RdnaCheckbox)`
  .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
