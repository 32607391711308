BaseView        = require('base/view')
BaseModel       = require('base/model')
AudioPlayerView = require('./audio-player-view')
UQView          = require('../usersAndQueues/view')

class AudioModel extends BaseModel
  urlRoot: 'callFlows/audio'

class AudioView extends BaseView
  template: require('./template')

  ui:
    file: 'input.fileupload'

  regions:
    uqv: 'div.uq-view'

  bindings:
    'select.audioType'  : 'value:audioType'
    '.audioVoice' : 'checked:audioVoice'
    '#audio-language' : 'value:audioLanguage'
    '#audio-text' : 'value:audioInput'
    '.call-and-record-number' : 'value:recordCallNumber'
    '.audio-record-1' : 'toggle: not(any(audioRecording,audioRecorded))'
    '.audio-record-2' : 'toggle: audioRecording'
    '.audio-record-3' : 'toggle: audioRecorded'
    '.library-title'  : 'value: libraryTitle'
    '#library-2'      : 'checked: library2'
    '.spinner'        : 'toggle: spin'
    '.selected-file'  : 'text: selectedFile'
    '.fileupload'     : 'toggle: not(spin)'
    '.audio-upload-1' : 'toggle: not(audioUploaded)'
    '.audio-upload-2' : 'toggle: audioUploaded'
    '#library-3'      : 'checked : library3'

  computeds:
    audioType:
      get: ->
        @model.audioType(@prefix) ? '0'
    audioVoice:
      get: ->
        @model.args()["#{@prefix}Voice"] ? 'man'
    audioLanguage:
      get: ->
        @model.args()["#{@prefix}Language"] ? 'en'
    audioInput:
      get: ->
        @model.args()["#{@prefix}Text"] ? ''

  events:
    'change .audioType': 'handleType'
    'keyup .audioInput': 'handleAudioInput'
    'change .audioInput': 'handleAudioInput'
    'change .audioVoice': 'handleAudioInput'
    'click [data-action="call-and-record"]': 'handleCallAndRecord'
    'click [data-action="change-call-and-record"]': 'handleRetryCallAndRecord'
    'click [data-action="retry-call-and-record"]': 'handleRetryCallAndRecord'
    'click [data-action="change-mp3"]': 'handleChangeMP3'
    'click [data-action="library-2"]': 'handleLibrary'
    'click [data-action="library-3"]': 'handleLibrary'
    'change .library-file': 'handleChangeLibraryFile'
    'change input.fileupload': 'uploadFile'

  initialize: (opts) ->
    { @prefix, @callflow, @readOnlyMode }  = opts
    @model.set(
      prefix  : opts.prefix
      full    : @model.get('type') isnt 'Conference'
    )
    @viewModel = new Backbone.Model(
      audioRecording: false
      audioRecorded: false
      audioUploaded: false
      libraryTitle: ''
      library2: false
      library3: false
      recordCallNumber: ''
      recordCallSid: ''
      selectedFile: ''
      spin: false
    )

  onAttach: ->
    @refreshTypePanel(null, @model.audioType(@prefix))

  onDestroy: ->
    App.pubsub.unbind('FinishedRecordingCallFlow', @handleCallAndRecordUpdate)

  uploadFile: (e) =>
    return unless @ui.file.val()

    @viewModel.set(spin: true)
    new AudioModel().persist(
      data:
        accountId : @callflow.get('account')?.id
        audioFile : @ui.file[0].files[0]
      upload: true
    ).done((response) =>
      base = $(e.target).closest('.audioTypePanel')
      @model.args()["#{@prefix}Id"] = response.id
      @createAudio(response.id, base)
      @viewModel.set(
        audioUploaded: true
        selectedFile: @ui.file.val()
      )
      @model.trigger('refreshErrors', @model)
    ).always( =>
      @viewModel.set(spin: false)
    )

  handleType: (e) =>
    audioType = $(e.target).val()
    @refreshTypePanel(e, audioType)

  refreshTypePanel: (e, audioType) ->
    if not e
      e = base = @$('.audio')
    else
      base = $(e.target).closest('.audio')
    base.find('.audioTypePanel').addClass('hidden')
    base.find(".audio-#{audioType}").removeClass('hidden')

    @model.args()["#{@prefix}Id"] = null if audioType isnt @model.audioType(@prefix)

    if audioType is '2'
      if @model.audioId(@prefix)
        @createAudio(@model.audioId(@prefix), base, (model) =>
          @viewModel.set(
            audioRecording: false
            audioRecorded: true
            libraryTitle: model.get('filename')
            library2: model.get('library')
          )
        )
      else
        @viewModel.set(
          audioRecording: false
          audioRecorded: false
        )
    else if audioType is '3'
      if @model.audioId(@prefix)
        @createAudio(@model.audioId(@prefix), base, (model) =>
          @viewModel.set(
            audioUploaded: true
            selectedFile: model.get('filename')
            library3: model.get('library')
          )
        )
      else
        @viewModel.set(audioUploaded: false)
    else if audioType is '4'
      base.find('.library-file').html('<option value="">-- Select One --</option>')
      new AudioModel().fetch(
        data  : accountId : @callflow.get('account')?.id
      ).done((response) =>
        for file in response
          tag1 = "<option value=\"#{file.id}\""
          tag2 = "#{file.filename}</option>"
          html = tag1
          if @model.args()["#{@prefix}Id"] is String(file.id)
            html = "#{tag1} selected='selected'>#{tag2}"
            @createAudio(file.id, base)
          else
            html = "#{tag1}>#{tag2}"
          base.find('.library-file').append(html)
        )
    else if audioType is '5'
      @showChildView('uqv', new UQView(
        accountId   : @callflow.get('account')?.id
        prefix      : @prefix
        readOnly    : @callflow.get('readOnly')
        restriction : 'users'
        step        : @model
        type        : 'Audio'
        )
      )

  createAudio: (id, base, callback) =>
    accountId = @callflow.get('account')?.id
    @audioModel = new AudioModel(id: id)
    @audioModel
      .fetch(data: accountId: accountId)
      .done(=>
        callback(@audioModel) if callback
        @showAudioPlayer(base, @audioModel)
    )

  showAudioPlayer: (base, data) =>
    key = "#{@prefix}-#{@model.audioType(@prefix)}"
    base.find('.audio-player-view').each((idx, e) =>
      found = $(e).data()['instance'].toString()
      if key is found
        @getRegion('audio').destroy() if @getRegion('audio')
        @addRegion('audio', "div[data-instance=#{found}]")
        @showChildView('audio', new AudioPlayerView(
          prefix: @prefix
          audioModel: data
          instance: @model.audioType(@prefix)
          )
        )
    )

  handleChangeMP3: =>
    @viewModel.set(audioUploaded: false)

  handleCallAndRecord: (e) =>
    number = @getBinding('recordCallNumber')
    if number is '' or $.trim(number).length is 0
      alert('Phone Number is required')
    else
      App.pubsub.bind('FinishedRecordingCallFlow', @handleCallAndRecordUpdate)
      @clickedPrefix = @prefix
      @callAndRecordBase = $(e.target).closest('.audioTypePanel')
      @viewModel.set(
        audioRecording: true
        audioRecorded: false
      )
      App.api.get(
        path    : "callFlows/#{@callflow.id}/#{@model.id}/callAndRecord/#{number}"
        success : (response) => @viewModel.set('recordCallSid', response.callSid)
      )

  handleCallAndRecordUpdate: (data) =>
    return if @prefix isnt @clickedPrefix or String(@callflow.id) isnt data.callFlowId
    @clickedPrefix = null
    @viewModel.set(
      audioRecording: false
      audioRecorded: true
      recordCallSid: ''
    )
    @model.args()["#{@prefix}Id"] = data.fileId
    @createAudio(data.fileId, @callAndRecordBase)
    @model.trigger('refreshErrors', @model)

  handleRetryCallAndRecord: =>
    @viewModel.set(
      audioRecording: false
      audioRecorded: false
    )
    recordCallSid = @viewModel.get('recordCallSid')
    if recordCallSid
      App.api.get(
        path    : "callFlows/cancelCall/#{recordCallSid}"
      )

  handleLibrary: (e) =>
    id = @model.audioId(@prefix)
    type = @model.audioType(@prefix)
    if type is '2'
      title = @viewModel.get('libraryTitle')
    else if type is '3'
      title = @viewModel.get('selectedFile')
    if id
      @audioModel.persist(
        data  :
          'audioFile.id'        : @audioModel.id
          'audioFile.filename'  : title
          'audioFile.library'   : $(e.target).is(':checked')
      )
    else
      $(e.target).prop('checked', !$(e.target).prop('checked'))
      toastr.error('Failed to update metadata: not found audio file')

  handleChangeLibraryFile: (e) =>
    base = $(e.target).closest('.audioTypePanel')
    @createAudio($(e.target).val(), base)

module.exports = AudioView
