BaseView = require('base/view')

class OverviewView extends BaseView

  template: require('./template')
  className: 'recordings-account-overview'

  ui:
    cleanup : 'button.cleanup'

  events:
    'click @ui.cleanup'    : 'cleanup'

  cleanup: =>
    @trigger('cleanup', @model.get('account').id)


module.exports = OverviewView