import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function SpeakerIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg viewBox="0 0 36 36" width={size} height={size} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        d="M23.5 14s1.5 1.48 1.5 3.5-1.5 3.5-1.5 3.5M27 13s2 1.5 2 4.5-2 4.5-2 4.5"
      />
      <path
        stroke={color}
        d="m18.526 7.794-11 5.923a1 1 0 0 0-.526.88v5.806a1 1 0 0 0 .526.88l11 5.923a1 1 0 0 0 1.474-.88V8.674a1 1 0 0 0-1.474-.88Z"
      />
    </svg>
  )
}

export default SpeakerIcon
