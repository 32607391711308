class CampaignsModalView extends Backbone.Modal
  template: _.template('<div class="modals-container"></div>')

  submitEl: '.save'

  cancelEl: '.close'

  viewContainer: '.modals-container'

  views:
    'click #tab1':
      view: require('./add-step-view')
    'click #tab2':
      view: require('./create-step-view')

  initialize: (options) =>
    { @accountId, @viewModel, @model, @smartNumberId} = options

  beforeSubmit: =>
    if @currentIndex is 0 and @currentView.viewModel.get('campaignId')
      @trigger('save', @currentView.viewModel.get('campaignId'))
      @destroy()
    else if @currentIndex is 1
      if not @model.get('name')
        toastr.warning('Please enter a campaign name.')
      else
        @save()
    false

  onDestroy: =>
    $('body').css('overflow-y', 'auto')

  save: =>
    data = @model.toPlayJSON('campaign')
    data['smartNumberId'] = @smartNumberId
    @model.persist( data: data ).done?((campaign) =>
      toastr.info('Campaign saved.')
      @trigger('save', campaign.salesforceId)
      @destroy()
    )

module.exports = CampaignsModalView
