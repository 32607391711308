import { IntegrationsActionTypes, IntegrationsState } from '../model'
import { integrationDisconnectedFlags, IntegrationsActions } from '../constants'
import { getAllIntegrations } from './integrationsSelectors'

export default function integrationsReducer(
  state: IntegrationsState,
  action: IntegrationsActionTypes
): IntegrationsState {
  switch (action.type) {
    case IntegrationsActions.SET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload
      }
    case IntegrationsActions.SET_INTEGRATIONS:
      const allIntegrations = getAllIntegrations({ payload: action.payload, activeUser: state.activeUser })
      const activeIntegrations = allIntegrations.filter(integration => integration.connected)
      const primaryIntegration = allIntegrations.filter(
        integration => integration.primary && !integrationDisconnectedFlags.includes(integration.status)
      )[0]
      return {
        ...state,
        allIntegrations,
        activeIntegrations,
        zoomMetaModel: action.payload.zoomMetaModel,
        primaryIntegration: primaryIntegration ? primaryIntegration.provider : null,
        connectedIntegration: undefined,
        deletedIntegration: undefined
      }
    case IntegrationsActions.SET_GMAIL_AUTH_URL:
      return {
        ...state,
        gmailAuthUrl: action.payload
      }
    case IntegrationsActions.SET_SELECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: action.payload,
        connectedIntegration: undefined
      }
    case IntegrationsActions.SET_CONNECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: undefined,
        connectedIntegration: action.payload
      }
    case IntegrationsActions.SET_PRIMARY_INTEGRATION:
      return {
        ...state,
        primaryIntegration: action.payload
      }
    case IntegrationsActions.DELETE_INTEGRATION:
      return {
        ...state,
        deletedIntegration: action.payload,
        displayZoomManager: false
      }
    case IntegrationsActions.DISPLAY_ZOOM_USER_MANAGER:
      return {
        ...state,
        displayZoomManager: action.payload
      }
    default:
      return state
  }
}
