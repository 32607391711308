BackgridCell = require('helpers/backgrid-cell')
BaseModel = require('base/model')
BaseView  = require('base/view')
CdmDeleteConfirmationModal = require('../modal/delete/view')
CdmReportConfirmationModal = require('../modal/report/view')

class CDMView extends BaseView
  template: require('./template')
  className: 'customer-data-view'

  regions:
    callsGrid           : 'div.calls-container'
    messagesGrid        : 'div.messages-container'
    favoritesGrid       : 'div.favorites-container'
    teleconferenceGrid  : 'div.teleconference-container'
    modal               : 'div.modal-container'

  bindings:
    'h1.custom-name' : 'text:customerName'
    'span.id' : 'text:recordId'
    'span.salesforce-id' : 'text:salesforceId'
    'span.type' : 'text:type'
    'div.pending-delete-status' : 'toggle:showPendingDeleteAlert'
    'div.in-progress-delete-status' : 'toggle:showInprogressDeleteAlert'
    'div.deleted-status' : 'toggle:showDeletedAlert'
    'button.delete' : 'toggle:not(any(showInprogressDeleteAlert, showPendingDeleteAlert))'
    'button.undelete' : 'toggle:showPendingDeleteAlert'
    '.call-data-removed' : 'toggle:callDataRemoved'
    '.message-data-removed' : 'toggle:messageDataRemoved'
    '.favorite-data-removed' : 'toggle:favoriteDataRemoved'
    '.teleconference-data-removed' : 'toggle:teleconferenceDataRemoved'
    'div.in-progress-report-status' : 'toggle:showInprogressReportAlert'
    'div.completed-report-status' : 'toggle:showCompleteReportAlert'
    'button.run-report' : 'toggle:not(showInprogressReportAlert)'
    'a.download-report': 'attr:{href:reportUrl}'

  events:
    'click button.run-report' : 'runReport'
    'click button.delete' : 'deleteData'
    'click button.undelete' : 'undelete'
    'click span.remove-delete-alert' : 'removeDeleteAlert'
    'click span.remove-report-alert' : 'removeReportAlert'

  computeds:
    showPendingDeleteAlert:
      deps: ['deleteStatus']
      get: (deleteStatus) ->
        deleteStatus is 'Pending'
    showInprogressDeleteAlert:
      deps: ['deleteStatus']
      get: (deleteStatus) ->
        deleteStatus is 'Inprogress'
    showDeletedAlert:
      deps: ['deleteStatus']
      get: (deleteStatus) ->
        deleteStatus is 'Completed'
    showInprogressReportAlert:
      deps: ['reportStatus']
      get: (reportStatus) ->
        reportStatus is 'Inprogress'
    showCompleteReportAlert:
      deps: ['reportStatus']
      get: (reportStatus) ->
        reportStatus is 'Completed'

  initialize: (options) =>
    @viewModel = new BaseModel(
      recordId: options.recordId
      customerName: ''
      salesforceId: ''
      type: ''
      deleteStatus: ''
      reportStatus: ''
      reportUrl: ''
      callDataRemoved: false
      messageDataRemoved: false
      favoriteDataRemoved: false
      teleconferenceDataRemoved: false
    )

    App.api.get(
      path : 'cdm'
      data :
        recordId: options.recordId
      success : (response) =>
        calls = new Backbone.Collection(response.calls)
        messages = new Backbone.Collection(response.messages)
        favorites = new Backbone.Collection(response.favorites)
        teleconferences = new Backbone.Collection(response.teleconferences)
        @viewModel.set('customerName', response.customerName)
        @viewModel.set('salesforceId', response.salesforceId)
        @viewModel.set('type', response.type)
        @viewModel.set('deleteStatus', response.deleteStatus)
        @viewModel.set('reportStatus', response.reportStatus)
        @viewModel.set('reportUrl', response.reportUrl) if response.reportUrl

        if response.deleteStatus is 'Completed' and not calls.length
          @viewModel.set('callDataRemoved', true)
        else
          @viewModel.set('callDataRemoved', false)
          @showChildView('callsGrid', @buildCallsGrid(calls)) if @getRegion('callsGrid')

        if response.deleteStatus is 'Completed' and not messages.length
          @viewModel.set('messageDataRemoved', true)
        else
          @viewModel.set('messageDataRemoved', false)
          @showChildView('messagesGrid', @buildMessagesGrid(messages)) if @getRegion('messagesGrid')

        if response.deleteStatus is 'Completed' and not favorites.length
          @viewModel.set('favoriteDataRemoved', true)
        else
          @viewModel.set('favoriteDataRemoved', false)
          @showChildView('favoritesGrid', @buildFavoritesGrid(favorites)) if @getRegion('favoritesGrid')

        if response.deleteStatus is 'Completed' and not teleconferences.length
          @viewModel.set('teleconferenceDataRemoved', true)
        else
          @viewModel.set('teleconferenceDataRemoved', false)
          @showChildView('teleconferenceGrid', @buildTeleconferenceGrid(teleconferences)) if @getRegion('teleconferenceGrid')

    )
    App.pubsub.bind('CDMStatus', @onStatusUpdate)

  buildCallsGrid: (collection) ->
    recordingCell = BackgridCell.href(
      url   : ':data.recording',
      label : ':data.recording',
      attrs : ['data.recording'])

    datetimeCell = BackgridCell.dateTime()

    cols = [
      {label: 'Call Key',       name: 'data.callKey',   cell: 'string'}
      {label: 'Call SID',       name: 'data.callSid',   cell: 'string'}
      {label: 'ID',             name: 'id',             cell: 'string'}
      {label: 'Direction',      name: 'data.direction', cell: 'string'}
      {label: 'End Time',       name: 'data.endTime',   cell: datetimeCell}
      {label: 'Recording Link', name: 'data.recording', cell: recordingCell}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No calls found.'
    )

  buildMessagesGrid: (collection) ->
    datetimeCell = BackgridCell.dateTime()

    cols = [
      {label: 'Message ID',   name: 'data.sid',         cell: 'string'}
      {label: 'ID',           name: 'id',               cell: 'string'}
      {label: 'To Number',    name: 'data.toNumber',    cell: 'string'}
      {label: 'From Number',  name: 'data.fromNumber',  cell: 'string'}
      {label: 'End Time',     name: 'data.createdAt',   cell: datetimeCell}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No messages found.'
    )

  buildFavoritesGrid: (collection) ->
    cols = [
      {label: 'ID',           name: 'id',         cell: 'string'}
      {label: 'Type',         name: 'data.type',  cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No favorites found.'
    )

  buildTeleconferenceGrid: (collection) ->
    cols = [
      {label: 'Recording Key',  name: 'data.meetingId', cell: 'string'}
      {label: 'ID',             name: 'id',             cell: 'string'}
      {label: 'Source',         name: 'source',         cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No teleconference found.'
    )

  runReport: =>
    v = new CdmReportConfirmationModal(
      salesforceId: @viewModel.get('salesforceId')
      customerName: @viewModel.get('customerName')
    )
    @showChildView('modal', v)

  deleteData: =>
    v = new CdmDeleteConfirmationModal(salesforceId: @viewModel.get('salesforceId'))
    @showChildView('modal', v)

  undelete: ->
    App.api.post(
      path : 'cdm/cancel'
      data :
        salesforceId: @viewModel.get('salesforceId')
    )

  removeDeleteAlert: =>
    App.api.delete(
      path: 'cdm/alert'
      data:
        salesforceId: @viewModel.get('salesforceId')
        type: 'DeletingComplete'
      success: =>
        @viewModel.set('deleteStatus', 'Blank')
    )

  removeReportAlert: =>
    App.api.delete(
      path: 'cdm/alert'
      data:
        salesforceId: @viewModel.get('salesforceId')
        type: 'ReportComplete'
      success: =>
        @viewModel.set('reportStatus', 'Blank')
    )

  onStatusUpdate: (data) =>
    return if data.salesforceId isnt @viewModel.get('salesforceId')
    if data.deleteStatus
      @viewModel.set('deleteStatus', data.deleteStatus)
      if data.deleteStatus is 'Completed'
        @detachChildView('callsGrid') if @getRegion('callsGrid')
        @detachChildView('messagesGrid') if @getRegion('messagesGrid')
        @detachChildView('favoritesGrid') if @getRegion('favoritesGrid')
        @detachChildView('teleconferenceGrid') if @getRegion('teleconferenceGrid')
        @viewModel.set('callDataRemoved', true)
        @viewModel.set('messageDataRemoved', true)
        @viewModel.set('favoriteDataRemoved', true)
        @viewModel.set('teleconferenceDataRemoved', true)
        @$('button.delete').hide()
    if data.reportStatus
      @viewModel.set('reportStatus', data.reportStatus)
      if data.reportStatus is 'Completed'
        @viewModel.set('reportUrl', data.reportUrl)
      if data.reportStatus is 'Failed'
        toastr.error('Report failed.', 'Server Error', {closeButton: true, extendedTimeOut: 0, timeOut: 0})

module.exports = CDMView
