BaseView = require('base/view')
SkillCollection = require('features/accounts/show/skills/collection')
UserSkillModel = require('./../model')

class UserSkillModal extends BaseView

  template: require('./template')
  className: 'user-skill-modal'

  regions:
    userSkillPicker : 'span.group-picker'

  ui:
    modal       : '#user-skill-modal'
    save        : 'button.save-user-skill'
    skillPicker : '.skill-picker'
    levelPicker : '.level-picker'

  bindings:
    '.modal-title': 'text:title'

  computeds:
    title:
      get: ->
        if @model.get('id')
          'Update User Skill'
        else
          'Add Skill to User'

  events:
    'click @ui.save': 'save'

  initialize: (options) =>
    { @accountId, @userSkillCollection } = options
    @collection = new SkillCollection()

  onRender: =>
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @initSkillSelectize()
        @initLevelSelectize()
      )

  initSkillSelectize: =>
    skills = []
    for model in @collection.models
      skills.push(text: model.get('name'), value: model.get('id'))
    skills = _.sortBy(skills, 'name')
    @ui.skillPicker.selectize(
      placeholder: 'Skill'
      preload: true
      options: skills
      items: if @model.get('skillId') then [@model.get('skillId')] else []
      onChange: (skillId) =>
        @model.set(skillId: if skillId then parseInt(skillId) else null)
    )

    @ui.skillPicker[0].selectize.disable() if @model.get('id')

  initLevelSelectize: =>
    @ui.levelPicker.selectize(
      placeholder: 'Skill level'
      preload: true
      options: [
        {text: 'Level 5 (Highest)', value: 5},
        {text: 'Level 4', value: 4},
        {text: 'Level 3', value: 3},
        {text: 'Level 2', value: 2},
        {text: 'Level 1 (Lowest)', value: 1}
      ]
      items: if @model.get('level') then [@model.get('level')] else []
      onChange: (level) =>
        @model.set(level: if level then parseInt(level) else null)
    )

  save: =>
    return unless @validate()
    @model
      .persist(ladda: @ui.save[0])
      .done((response) =>
        userSkillModel = @userSkillCollection.findWhere(id: response.id)
        if userSkillModel
          userSkillModel.set(level: response.level)
        else
          userSkillModel = new UserSkillModel(response)
          @userSkillCollection.add(userSkillModel)
        @ui.modal.modal('hide')
      )

  validate: =>
    if not @model.get('skillId') or not @model.get('level')
      toastr.warning('Please select skill and level')
      return false
    true

module.exports = UserSkillModal
