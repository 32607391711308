import React, { useEffect } from 'react'

import { Methodology } from './Models'
import { useMethodologyTopics } from '../../store/api/salesmethodology-api'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import useToast from '../common/hooks/useToast'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import DrawerPage from './DrawerPage'

type Props = {
  methodology: Methodology
  accountId: number
  onSave: (reload: boolean) => void
}

export default function Drawer({ methodology, accountId, onSave }: Props) {
  const toastr = useToast()
  const [methodologyDetails, error, loading] = useMethodologyTopics({
    meta: { accountId: accountId, id: methodology.id }
  })

  useEffect(() => {
    error && toastr.error(getErrorMessage(error))
  }, [error, toastr])

  return (
    <RdnaLoader data={methodologyDetails} loading={!!loading}>
      <DrawerPage
        methodologyId={methodology.id}
        methodologyDetails={methodologyDetails || { topics: [], objectFields: [], configurations: [] }}
        accountId={accountId}
        onSave={onSave}
      ></DrawerPage>
    </RdnaLoader>
  )
}
