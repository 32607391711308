BaseGrid         = require('base/grid')
BaseView         = require('base/view')
BaseModel        = require('base/model')
EditorView       = require('./editor/view')
NumberCollection = require('./collection')
NumberModel      = require('./model')
BackgridCell     = require('helpers/backgrid-cell')
UserPickerView   = require('widgets/user-picker/view')
PagerView        = require('widgets/pager/view')

class BlockedNumbersView extends BaseView
  template: require('./template')
  className: 'blocked-numbers'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'
    userPicker: 'span.user-picker'
    pager: 'span.pager'

  ui:
    number : 'input.phone-number'
    search : 'button.search'
    type   : '.number-type'

  bindings:
    '.allow-block-call' : 'checked:allowBlockCall'

  events:
    'click button.search' : 'search'
    'change .allow-block-call' : 'allowBlockCallChanged'

  childViewEvents:
    reload: -> @search()

  initialize: (options) ->
    @accountId = options.accountId

  onAttach: =>
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onRender: =>
    @userPicker = @getRegion('userPicker')
    @userPicker.show(new UserPickerView(accountId: @model.get('id')))
    @collection = new NumberCollection(
      queryParams:
        accountId : @accountId
    )
    @listenTo(@collection, 'reload', => @search())
    @detachChildView('grid')
    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection))
    @search()

  allowBlockCallChanged: =>
    @model.persist()

  search: =>
    data =
      number    : @ui.number.val()
      userId    : @getRegion('userPicker').currentView.val()
      type      : @ui.type.val()
    @collection.fetch({data: data, reset: true})

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    userCell = class extends Backgrid.BooleanCell
      render: ->
        user = @model.get('user')
        if user
          url = "#users/#{user.id}"
          label = "#{user.displayName}"
          html = "<a href='#{url}'>#{label}</a>"
        else
          label = 'All'
          html = '<span>All</span>'
        $(@el).html(_.template(html))
        @

    deleteCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a.delete': 'delete'
      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          if ok
            collection = @model.collection
            @model.destroy(data: accountId: @model.get('accountId')).done(=> collection.trigger('reload'))
          true
        )
      render: ->
        deletable = @model.get('deletable') ? true
        if deletable
          $(@el).html('''
            <a class="delete">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          ''')
        @

    columns = [
      { name: '',           label: '',             cell: BaseGrid.EditCell }
      { name: 'number',     label: 'Number',       cell: 'string' },
      { name: 'type',       label: 'Block Type',   cell: 'string' },
      { name: 'direction',  label: 'Direction',    cell: 'string' },
      { name: 'timestamp',  label: 'Date Created', cell: datetime },
      { name: 'user',       label: 'Access Block', cell: userCell}
      { name: 'id',         label: 'ID',           cell: 'string' },
      { name: '',           label: '',             cell: deleteCell, headerCell: BaseGrid.AddCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No blocked numbers found.'
      onAdd      : @showEditor
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    model = options.model?.clone()
    model ?=
      new NumberModel(
        number    : ''
        type      : 'Call'
        direction : 'Inbound'
      )
    model.set(accountId : @accountId)
    @showChildView('modal', new EditorView(model: model, collection: @collection, allowBlockCall: @model.get('allowBlockCall')))
    $('#edit-blocked-number-modal').modal('show') # FIXME: need better modals

module.exports = BlockedNumbersView
