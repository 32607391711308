import React from 'react'

import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import Delete from '@ringdna/common/src/assets/icons/svg/Delete'
import { SchedulingPage } from '../types'
import { useDeleteSchedulingPage } from '../../../../../store/api/personal-calendar-api'

type Props = {
  schedulingPage: SchedulingPage
  isModalOpen: boolean
  closeModal: () => void
}

export default function DeleteSchedulingPageModal({ schedulingPage, isModalOpen, closeModal }: Props) {
  const deleteSchedulingPage = useDeleteSchedulingPage()

  const onDelete = async () => {
    try {
      await deleteSchedulingPage({ id: schedulingPage.id, body: {} })
      closeModal()
    } catch (error) {
      toastr.error(error.message)
    }
  }

  const heading = `Deleting ${schedulingPage.name}`
  const body = `Are you sure you want to delete this?`

  return (
    <RdnaModal
      open={isModalOpen}
      onClose={closeModal}
      onConfirm={onDelete}
      confirmButtonText="Delete"
      heading={heading}
      headingIcon={<Delete />}
    >
      <RdnaText>{body}</RdnaText>
    </RdnaModal>
  )
}
