BaseView = require('base/view')
Picklist = require('./collection')

class CallflowPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    {@accountId, @allowEmptyOption, @selectedFlowId, @showNumbers, @placeholder, @data} = options
    @picklist = null

  onRender: =>
    @ui.picker.selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      placeholder: if @placeholder then @placeholder else 'Call Flow'
      load: (query, callback) =>
        return if @picklist
        @picklist = new Picklist()
        unless @data
          @picklist
            .fetch(data: {accountId: @accountId, showNumbers: @showNumbers})
            .done((response) =>
              callback(response)
              @initSelectize(@ui.picker[0].selectize)
            )
        else
          callback(@data)
          @initSelectize(@ui.picker[0].selectize)
      onChange: (value) =>
        @trigger('change', value, @ui.picker[0].selectize?.options[value]?.text)
    )

  val: (accountId) =>
    if accountId
      selectize = @ui.picker[0].selectize
      callflows = new Picklist()
      callflows.fetch(data:
        accountId: accountId
        showNumbers: @showNumbers)
        .done((response) =>
          selectize.clearOptions()
          selectize.addOption(text: callflow.text, value: callflow.value) for callflow in response
          selectize.addItem('', true)
        )
    else
      @ui.picker.val()

  initSelectize: (selectize) ->
    return unless selectize
    if @allowEmptyOption
      selectize.addOption(text: '(none)', value: '')
      selectize.addItem('', true)
    if @selectedFlowId
      selectize.setValue(@selectedFlowId, true)
    else
      selectize.setValue('', true)

  disabled: ->
    @ui.picker[0].selectize.disable()

module.exports = CallflowPickerView
