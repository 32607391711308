BaseModel = require('base/model')

class MemberModel extends BaseModel
  initialize: (options) =>
    @queueId = options.queueId

  urlRoot: =>
    "callQueues/#{@queueId}/members"

  defaults:
    displayName: ''
    callQueueCount: 0
    title: null
    location: null
    deletable: true

module.exports = MemberModel
