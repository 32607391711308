BaseCollection = require('base/collection')

module.exports = class ProvisionSmartNumbers extends BaseCollection
  url: -> 'localPresence/provision'

  initialize: (collection, options) ->
    @accountId = options.accountId

  setParams: (options) ->
    @international = options.international

  toJSON: =>
    fields = @toPlayJSON('numbers')
    fields['accountId'] = @accountId
    fields['international'] = @international
    fields
