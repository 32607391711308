BaseModel = require('base/model')
BaseView = require('base/view')
ReactComponent = require('src/ReactComponent').default
Reports = require('src/components/reports/BillingReports').default
ProductConstant = require('@ringdna/common/src/constants/products')

class BillingView extends BaseView
  template: _.template('<div class="react-billing"></div>')
  className: 'billing-export'

  regions:
    react: 'div.react-billing'

  onRender: =>
    @showChildView('react', new ReactComponent({ component: Reports, theme: ProductConstant.Products.ADMIN }))

module.exports = BillingView
