import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseTransitions from '../baseTransitions'
import baseBreakpoints from '../baseBreakpoints'
import { Colors } from '../../colors'

const palette = {
  ...basePalette,
  primary: {
    contrastText: Colors.N0,
    main: Colors.M30,
    dark: Colors.M50,
    light: Colors.N30
  },
  text: {
    ...basePalette.text,
    link: Colors.M50
  },
  icon: {
    ...basePalette.icon,
    link: Colors.M50
  },
  illustrations: {
    20: Colors.M20,
    30: Colors.M30,
    40: Colors.M40,
    50: Colors.M50,
    60: Colors.M60,
    70: Colors.M70,
    80: Colors.M80
  },
  background: {
    ...basePalette.background,
    dark: Colors.M60
  }
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const zIndex = {
  rdnaMenu: 100,
  appBar: 1200,
  drawer: 5555, // to go on top of pendo guide
  modal: 5556,
  tooltip: 5557
}

const breakpoints = {
  ...baseBreakpoints
}

const theme = {
  palette,
  typography,
  transitions,
  breakpoints,
  zIndex,
  spacing: 6
}

export default theme
