import { ApiAction } from './constants'

class RequestFail {
  error?: Error
  response?: Response
  constructor(
    public message: string,
    responseOrError: Response | Error
  ) {
    if (responseOrError instanceof Error) this.error = responseOrError
    else this.response = responseOrError
  }
}

class RequestPending {
  constructor(public dependingAction: ApiAction) {}
}
export { RequestFail, RequestPending }
