StepModel = require('../../model')
libphonenumber = require('libphonenumber-js/max')

class DialStepModel extends StepModel

  defaults:
    type: 'Dial'
    info: 'Transfer to another phone number, user, call queue or skill'
    fixedPlaceholders: [ 'No Answer' ]
    leaf: false
    args:
      dialType: ''

  noAnswer: ->
    @get('noAnswer')

  phoneNumbers: ->
    @args().phoneNumbers

  dialType: ->
    @args().dialType

  dialId: ->
    @args().dialId

  errors: ->
    messages = []
    selectors = []

    timeout = @args().timeout
    if timeout and timeout isnt '' and not @isInteger(timeout)
      messages.push('Must be a positive integer or 0')
      selectors.push('.dialTimeout')
    if @dialType() is 'multiNumber'
      if not @phoneNumbers()
        messages.push('Phone numbers are required')
        selectors.push('.phoneNumbers')
      else if not @phoneNumbers().startsWith('{{')
        numbers = @phoneNumbers().split('|')
        for n in numbers
          extVal = /\s*(x|ext|,)\s*\d+$/i.exec(n)
          n = n.replace(extVal[0], '') if extVal?.length
          p = n.replace(/[^\d]/g, '').trim()
          continue if n.startsWith('sip:') and n.length > 5
          if not n.startsWith('sip:') and p.length
            try
              number = libphonenumber.parsePhoneNumber(n, 'US')
              continue if number.isValid()
          messages.push('Phone number must be is valid or sip')
          selectors.push('.phoneNumbers')
    else if not @dialType() or (@dialType() isnt 'multiNumber' and @dialType() isnt 'recordOwner' and not @dialId())
      messages.push('User, call queue, skill or phone number is required')
      selectors.push('.ugn')

    _.zip(messages, selectors)

module.exports = DialStepModel
