var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block menu\">\n  <div class=\"sub-subtitle\">Audio</div>\n  <div>1. Play a message at the beginning of the menu</div>\n  <div class=\"audio-view\" data-prefix=\"audio\"></div>\n  <div class=\"center\">\n    <span class=\"error-step audio-input\"></span>\n  </div>\n  <div>2. Play a message for invalid responses</div>\n  <div class=\"invalid-audio-view\" data-prefix=\"invalidAudio\"></div>\n  <div class=\"center\">\n    <span class=\"error-step invalidAudio-input\"></span>\n  </div>\n  <div class=\"sub-subtitle\">Spacing</div>\n  <div>How long should ringDNA wait for a caller input before repeating the menu options?</div>\n  <div class=\"center\"><input type=\"text\" class=\"input\" data-key=\"spacing\" maxlength=\"2\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"spacing") : stack1), depth0))
    + "\"/>seconds\n    <span class=\"error-step spacing\"></span>\n  </div>\n  <div class=\"sub-subtitle\">Repeat Menu</div>\n  <div>How many times can this menu be repeated? (enter 0 for no repeat)</div>\n  <div class=\"center\"><input type=\"text\" class=\"input\" data-key=\"repeatMenu\" maxlength=\"2\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"repeatMenu") : stack1), depth0))
    + "\"/>times\n    <span class=\"error-step repeatMenu\"></span>\n  </div>\n</div>";
},"useData":true});