BaseGrid                = require('base/grid')
BaseView                = require('base/view')
BackgridCell            = require('helpers/backgrid-cell')
VoicemailGreetingModel  = require('./model')
VoicemailModal          = require('widgets/voicemail-modals/main-view')

class VoicemailGreetingView extends BaseView
  template: require('./template')
  className: 'voicemail-greeting'
  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add-voicemail-greeting' : 'showVoicemailGreetingEditor'

  initialize: (options) ->
    {@collection} = options
    @userModel = options.user
    @modal = @getRegion('modal')

  onRender: =>
    @showChildView('grid', @buildGrid())

  selectedCell: =>
    user = @userModel
    sessionUser = @user()
    class extends Backgrid.Cell
      events:
        'change .selected': 'selectGreeting'
      render: =>
        disabled = if user.get('id') isnt sessionUser.get('userId') then 'disabled' else ''
        сhecked = if @model.get('selected') then 'checked' else ''
        html = "<input class='selected' type='checkbox' #{сhecked} #{disabled}>"
        @listenTo(@model.collection, 'selected', => @render())
        @listenTo(@model.collection, 'remove', (model) =>
          if model.get('selected')
            @model.collection.at(0).set(selected: true)
            @model.collection.trigger('selected')
        )
        $(@el).html(html)
        @
      selectGreeting: (e) ->
        selected = e.target.checked
        greetingModel = new VoicemailGreetingModel(
          id: if @model.get('id') then @model.get('id') else -1
          selected: selected
          userId: user.get('id')
        )
        if not @model.get('id') and not selected
          @render()
          return
        greetingModel
          .persist()
          .done( =>
            @model.set(selected: selected)
            if selected
              for model in @model.collection.models
                model.set(selected: false) if model.get('id') isnt @model.get('id')
            else
              @model.collection.at(0).set(selected: true)
            @model.collection.trigger('selected')
          )
          .fail( =>
            @model.set(selected: not selected)
            @render()
          )

  buildGrid: =>
    columns = [
      { name: '',                         label: 'Selected',   cell: @selectedCell() }
      { name: 'name',                     label: 'Label',      cell: 'string' }
      { name: 'url',                      label: 'URL',        cell: 'uri' },
      { name: 'id',                       label: 'ID',         cell: 'string' },
      { name: 'outOfOffice',              label: 'OOO',        cell: 'string' },
      { name: '',                         label: 'Listen',     cell: BaseGrid.ListenAudioCell }
      { name: 'voicemail-greeting-modal', label: 'Update',     cell: BaseGrid.EditAudioCell},
      { name: '',                         label: 'Delete',     cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No custom greetings found.'
      onEdit     : @showVoicemailGreetingEditor
    )

  showVoicemailGreetingEditor: (options) =>
    model = options.model?.clone()
    model ?= new VoicemailGreetingModel()
    @modal.show(new VoicemailModal(
      userId              : @userModel.id
      collection          : @collection
      model               : model
      modalTitle          : 'Custom Greetings'
      outOfOfficeCheckbox : true
    ))

module.exports = VoicemailGreetingView
