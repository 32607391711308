StepModel = require('../../model')

class PlaceholderStepModel extends StepModel

  defaults:
    args: {}
    type: 'Placeholder'
    label: '_'
    leaf: false

  errors: ->
    messages = []
    selectors = []
    if @parent()?.get('type') is 'Prompt' and @isFixedPlaceholder()
      messages.push('Required fixed step')
      selectors.push('')

    errors = _.zip(messages, selectors)
    errors

module.exports = PlaceholderStepModel
