import React, { useContext, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'

// types
import { ZoomUsersPagedParam, ZoomUser, InitIntegrationsContext } from '../../model'
import { IntegrationsActions } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'

// utils
import { useZoomUsersPaged, usePostZoomIntegrationConnection } from '../../../../../store/api/user-integrations-api'
import { IntegrationsContext } from '../../store/IntegrationsProvider'
import useToast from '../../../../common/hooks/useToast'

// styled
import { Box, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { RdnaTable, RdnaTableHead, RdnaTableCell, RdnaTableRow } from '@ringdna/common/src/components/RdnaTable'
import Pagination from '@ringdna/common/src/components/RdnaPagination'
import UiCenteredBox from '@ringdna/common/src/components/layout/UiCenteredBox'
import RdnaText from '@ringdna/common/src/components/RdnaText'

const StyledZoomModal = styled.div<{ $listLength: number }>`
  position: relative;
  height: ${props => (props.$listLength > 4 ? '573.8px' : 'auto')};
  .MuiBox-root {
    text-align: center;
  }
  .ringdna-table {
    width: 680px;
    padding: 18px 0;
  }
  & > .MuiButton-root {
    position: absolute;
    bottom: 0;
  }
`
export const pageSize = 5

export default function ConnectZoomUserModal() {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [listLength, setListLength] = useState(0)
  const postZoomIntegrationConnection = usePostZoomIntegrationConnection()

  const zoomUsersPagedParam: ZoomUsersPagedParam = useMemo(
    () => ({
      query: {
        accountId: activeUser.accountId,
        'pager.pageSize': pageSize,
        'pager.currentPage': currentPage
      }
    }),
    [activeUser, currentPage]
  )
  const [zoomUsersPayload, , loading, ,] = useZoomUsersPaged(zoomUsersPagedParam)

  useEffect(() => {
    if (zoomUsersPayload && zoomUsersPayload.total) setListLength(zoomUsersPayload.total)
  }, [zoomUsersPayload])

  const onPaginate = ({ page }: { page: number }) => {
    setCurrentPage(page)
  }

  const selectZoomUser = async (user: ZoomUser) => {
    try {
      const res = await postZoomIntegrationConnection({
        body: {
          accountId: activeUser.accountId,
          email: user.email,
          rdnaUserId: activeUser.userId
        }
      })
      if (res.success) {
        dispatch({
          type: IntegrationsActions.SET_CONNECTED_INTEGRATION,
          payload: IntegrationProviderTypes.ZOOM
        })
      }
    } catch (error) {
      toastr.error(error.message)
    }
  }

  return (
    <StyledZoomModal $listLength={listLength}>
      <Box>
        <RdnaText variant="h2">Select a Zoom User</RdnaText>
      </Box>
      <Box pt={2} pb={4}>
        <RdnaText>Select a Zoom user to associate with this ringDNA user.</RdnaText>
      </Box>
      <Pagination onClick={onPaginate} itemsPerPage={pageSize} listLength={listLength} />
      <RdnaTable $shadedRows>
        <RdnaTableHead>
          <RdnaTableCell>Name</RdnaTableCell>
          <RdnaTableCell flex={2}>Email</RdnaTableCell>
          <RdnaTableCell>Department</RdnaTableCell>
          <RdnaTableCell width="100px">Actions</RdnaTableCell>
        </RdnaTableHead>
        {!loading &&
          zoomUsersPayload &&
          zoomUsersPayload.results.map(user => (
            <RdnaTableRow key={user.id}>
              <RdnaTableCell>{`${user.first_name} ${user.last_name}`}</RdnaTableCell>
              <RdnaTableCell flex={2}>{user.email}</RdnaTableCell>
              <RdnaTableCell>{user.dept}</RdnaTableCell>
              <RdnaTableCell width="100px">
                <Button
                  name="select-zoom-user"
                  disabled={!user.type || user.type < 2}
                  onClick={() => selectZoomUser(user)}
                  color="primary"
                  variant="contained"
                >
                  Select
                </Button>
              </RdnaTableCell>
            </RdnaTableRow>
          ))}
      </RdnaTable>
      {!!loading && (
        <UiCenteredBox minHeight="68px">
          <CircularProgress />
        </UiCenteredBox>
      )}
      {!loading && zoomUsersPayload && zoomUsersPayload.results.length === 0 && (
        <UiCenteredBox>
          <RdnaText>No users found.</RdnaText>
        </UiCenteredBox>
      )}
    </StyledZoomModal>
  )
}
