class VoicemailModalView extends Backbone.Modal
  template: _.template('<div class="voicemail-modals-container"></div>')

  cancelEl: '.close'

  events:
    'click .close': -> @memento.restore()

  viewContainer: '.voicemail-modals-container'

  views:
    'click #tab1':
      view: require('./upload-step-view')
    'click #tab2':
      view: require('./record-step-view')

  initialize: ->
    @memento = new Backbone.Memento(@model)
    @memento.store()
    audios = $('audio')
    for audio in audios
      audio.pause()
      audio.currentTime = 0
    @listenTo(@model, 'saveVoicemail', =>
      audio = $("#audio-#{@model.id}")
      audio[0].src = @model.get('url') if audio
      @memento.store()
      @destroy()
    )

  # TODO: We shouldn't need to do this. It looks like the Backbone.Modal plugin we're using is not compatible with
  # Marionette and does not cleanup views properly. Needs investigation.
  onDestroy: ->
    @memento.restore()
    @currentView.destroy()

module.exports = VoicemailModalView
