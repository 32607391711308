import React from 'react'
import SupportSettings from './SupportSettings'
import {
  SupportSettingsStateContext,
  SupportSettingsDispatchContext,
  supportSettingsReducer,
  initialState
} from './utils/contextReducer'
import { PubNubProvider } from '../contexts/PubNubContext'

type AccountProviderProps = {
  accountId: string
}

export default React.memo(SupportSettingsProvider)
export function SupportSettingsProvider({ accountId }: AccountProviderProps) {
  const [state, dispatch] = React.useReducer(supportSettingsReducer, initialState)

  return (
    <PubNubProvider>
      <SupportSettingsStateContext.Provider value={state}>
        <SupportSettingsDispatchContext.Provider value={dispatch}>
          <SupportSettings accountId={accountId} />
        </SupportSettingsDispatchContext.Provider>
      </SupportSettingsStateContext.Provider>
    </PubNubProvider>
  )
}
