import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { Methodology, Role, RoleDetail, RoleMapping, Roles } from '../types'
import { HttpMethods } from '@ringdna/client/src/constants'

type UserRoleUpdateParam = {
  roleId: number
}

type RoleMethodologyUpdateParam = {
  accountId: number
  methodologyId: number
}

export const useRoles = createUseFetch<Roles, { meta: { accountId: number } }>({
  key: 'get-roles',
  independent: true,
  path: params => `/api/v3/app/roles?accountId=${params.meta.accountId}`
})

export const useUserRolesBulkUpdate = createUseFetchAction<void, any>({
  key: 'update-user-roles-bulk',
  method: HttpMethods.Put,
  path: `/api/v3/app/roles/bulk`,
  headers: { 'Content-Type': 'application/json' }
})

export const useRoleDetails = createUseFetch<RoleDetail, { meta: { accountId: number; roleId: number } }>({
  key: 'get-roles-details',
  independent: true,
  path: params => `/api/v3/app/roles/${params.meta.roleId}?accountId=${params.meta.accountId}`
})

export const useRoleMethodologyUpdate = createUseFetchAction<
  { meta: { roleId: number }; json: RoleMethodologyUpdateParam },
  any
>({
  key: 'update-role-methodology',
  method: HttpMethods.Put,
  headers: { 'content-type': 'application/json' },
  path: params => `/api/v3/app/roles/${params.meta.roleId}`
})

export const useUserRoleUpdate = createUseFetch<{ meta: { userId: number }; json: UserRoleUpdateParam }, any>({
  key: 'update-user-role',
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' },
  path: params => `/api/v3/app/users/${params.meta.userId}/role`
})

export const useUserRoleDelete = createUseFetchAction<{ meta: { userId: number } }, any>({
  key: 'delete-user-role',
  method: HttpMethods.Delete,
  headers: { 'Content-Type': 'application/json' },
  path: params => `/api/v3/app/users/${params.meta.userId}/role`
})

export const useRoleDefinitions = createUseFetch<Role[], void>({
  key: 'get-role-definitions',
  independent: true,
  path: `/api/v3/app/roleDefinitions`
})

export const useMethodologies = createUseFetch<Methodology[], void>({
  key: 'get-methodologies',
  independent: true,
  path: '/api/v3/app/methodologies'
})

export const useRoleMapping = createUseFetch<RoleMapping[], { meta: { accountId: number; refresh: boolean } }>({
  key: 'get-sfdc-roles',
  independent: true,
  path: params => `/api/v3/app/roleMapping?accountId=${params.meta.accountId}&refresh=${params.meta.refresh}`
})

export const useRoleMappingUpdate = createUseFetchAction<RoleMapping[], any>({
  key: 'update-sfdc-roles',
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' },
  path: `/api/v3/app/roleMapping`
})
