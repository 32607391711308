import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'
import { EventProps, HolidayProps, UserSkillsProps } from '../types'

type GetPageParam = {
  query: {
    searchTerm: string
    'pager.pageSize': number
    'pager.currentPage': number
    'pager.sortBy'?: string | null
    'pager.sortDirection'?: string | null
    accountId: number
  }
}

type HolidayCalendarParam = {
  accountId: number
  name: string
  description: string
}

type HolidayEventParam = {
  accountId: number
  holidayScheduleId: number
  name: string
  fromDate: number
  toDate: number
}

export const useCallFlows = createUseFetch<any, GetPageParam>({
  key: 'get-call-flows',
  independent: true,
  path: `/api/v2/app/callFlows`
})

export const useCallFlowsPicklist = createUseFetch<any[], { meta: { id: number } }>({
  key: 'call-flows-picklist',
  independent: true,
  path: params => `/api/v2/app/callFlows/picklist?accountId=${params.meta.id}&showNumbers=false`
})

export const useCallFlowsDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-flow',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callFlows/${params.meta.id}`
})

export const useCallFlowsCopy = createUseFetchAction<any, { meta: { id: number } }>({
  key: 'copy-call-flow',
  method: HttpMethods.Post,
  path: params => `/api/v2/app/callFlows/copy/${params.meta.id}`
})

export const useCallQueues = createUseFetch<any, GetPageParam>({
  key: 'get-call-queues',
  independent: true,
  path: `/api/v2/app/callQueues`
})

export const useCallQueueDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-queue',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callQueues/${params.meta.id}`
})

export const useCallQueueRestore = createUseFetchAction<any, { meta: { id: number } }>({
  key: 'restore-call-queue',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/callQueues/restore/${params.meta.id}`
})

export const useCallQueuesLive = createUseFetch<any, { query: { accountId: number } }>({
  key: 'get-call-queues-live',
  independent: true,
  path: `/api/v2/app/callQueues/live`
})

export const useCallQueueLiveDelete = createUseFetchAction<{ meta: { sid: string, accountId: number } }, any>({
  key: 'delete-call-queue-live',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callQueues/live/${params.meta.sid}?accountId=${params.meta.accountId}`
})

export const useHolidayCalendars = createUseFetch<HolidayProps[], { query: { accountId: number } }>({
  key: 'get-holiday-calendars',
  independent: true,
  path: `/api/v2/app/holidaySchedule`
})

export const useHolidayCalendarDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-holiday-calendar',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/holidaySchedule/${params.meta.id}`
})

export const useHolidayCalendarCreate = createUseFetchAction<any, { body: HolidayCalendarParam }>({
  key: 'create-holiday-calendar',
  method: HttpMethods.Post,
  path: `/api/v2/app/holidaySchedule`
})

export const useHolidayCalendarUpdate = createUseFetchAction<{ meta: { id: number }; body: HolidayCalendarParam }, any>(
  {
    key: 'update-holiday-calendar',
    method: HttpMethods.Put,
    path: params => `/api/v2/app/holidaySchedule/${params.meta.id}`
  }
)

export const useHolidayEvents = createUseFetch<EventProps[], { query: { holidayScheduleId: number } }>({
  key: 'get-holiday-event',
  independent: true,
  path: `/api/v2/app/holidayEvent`
})

export const useHolidayEventDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-holiday-event',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/holidayEvent/${params.meta.id}`
})

export const useHolidayEventCreate = createUseFetchAction<{ body: HolidayEventParam }, any>({
  key: 'create-holiday-event',
  method: HttpMethods.Post,
  path: `/api/v2/app/holidayEvent`
})

export const useHolidayEventUpdate = createUseFetchAction<{ meta: { id: number }; body: HolidayEventParam }, any>({
  key: 'update-holiday-event',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/holidayEvent/${params.meta.id}`
})

export const useUserSkills = createUseFetch<UserSkillsProps[], { meta: { accountId: number } }>({
  key: 'get-user-skills',
  independent: true,
  path: params => `/api/v2/app/skills?accountId=${params.meta.accountId}`
})

export const useUserSkillDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-user-skill',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/skills/${params.meta.id}`
})

export const useUserSkillCreate = createUseFetchAction<any, { body: { accountId: number; name: string } }>({
  key: 'create-user-skill',
  method: HttpMethods.Post,
  path: `/api/v2/app/skills`
})

export const useUserSkillUpdate = createUseFetchAction<
  any,
  { meta: { id: number | undefined }; body: { name: string } }
>({
  key: 'update-user-skill',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/skills/${params.meta.id}`
})
