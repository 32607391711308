import React from 'react'
import { Colors } from '../../../constants/colors'

type Props = {
  size?: number
  color?: string
  isLocked?: boolean
  isDisabled?: boolean
}

const getLockShape = (isLocked: boolean) => {
  return isLocked
    ? 'M26.4,16.8c0,0,0-0.1,0-0.1v-2.5c0-0.8-0.2-1.6-0.5-2.4c-0.3-0.7-0.8-1.4-1.3-2c-0.6-0.6-1.3-1-2-1.3 C21.8,8.1,21.1,8,20.2,8h-0.5c-0.8,0-1.6,0.2-2.4,0.5c-0.7,0.3-1.4,0.8-2,1.3c-0.6,0.6-1,1.3-1.3,2c-0.3,0.8-0.5,1.6-0.5,2.4v2.5 c0,0,0,0.1,0,0.1c-1.5,0.4-2.5,1.7-2.5,3.3v9.2c0,0.4,0.3,0.7,0.7,0.7l0,0h16.6c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-9.2 C29,18.5,27.9,17.2,26.4,16.8z M15.9,16.7v-2.5c0-0.6,0.1-1.3,0.4-1.9c0.2-0.6,0.6-1.1,1-1.5c0.9-0.9,1.9-1,2.4-1h0.5 c0.5,0,1.5,0,2.4,1c0.4,0.4,0.8,1,1,1.5c0.3,0.6,0.4,1.2,0.4,1.9v2.5L15.9,16.7z'
    : 'M25.5,16.7h-9.6v-2.5c0.1-2.6,1.6-4.4,3.8-4.3l0.5,0c1.3,0,1.8,0.4,2.7,1.3c0.5,0.8,0.2,0.4,0.6,0.9c0.3,0.5,1,0.4,1.3,0.2c0.3-0.2,1-0.5,0.7-1.1c-0.6-1.5-1.4-2.1-2.9-2.7C21.9,8.1,21.1,8,20.2,8h-0.5c-0.8,0-1.6,0.2-2.4,0.5c-1.5,0.6-2.7,1.8-3.3,3.3c-0.3,0.8-0.5,1.6-0.5,2.4v2.5c0,0,0,0.1,0,0.1c-1.5,0.4-2.6,1.8-2.6,3.3v9.2c0,0.4,0.3,0.7,0.7,0.7l0,0h16.6c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-9.2C29,18.2,27.4,16.7,25.5,16.7z'
}

const LockIcon = ({ size = 24, color = Colors.M50, isLocked = true, isDisabled = false }: Props) => (
  <svg height={size} width={size} viewBox="0 0 40 40">
    <circle fill={!isDisabled ? color : Colors.MID_GREY} cx="20" cy="20" r="20" />
    <path fill={Colors.WHITE} d={getLockShape(isLocked)} />
  </svg>
)

export default LockIcon
