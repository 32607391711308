BaseModel = require('base/model')

class CallQueueModel extends BaseModel
  urlRoot: 'callQueues'

  defaults:
    name: ''
    inactive: false
    isDeleted: false
    description: ''
    type: 'Default'
    dialTimeout: '10'
    maximumDials: '10'
    maximumHoldTime: '300'
    nextLoopTimeout: '10'
    allowWrapUpTime: false
    wrapUpTimeLimit: '30'
    allowReject: true
    allowUnsubscribe: true
    allowDeleteVoicemail: false
    longestWaitingAgentInfo: false
    simulringInfo: true
    handleMissedCallAsAnswered: false

  initialize: =>
    @queue = [ 'id', 'name', 'description', 'inactive', 'isDeleted', 'type', 'account', 'dialTimeout',
      'maximumDials', 'allowWrapUpTime', 'wrapUpTimeLimit', 'allowReject', 'allowUnsubscribe', 'allowDeleteVoicemail',
      'maximumHoldTime', 'nextLoopTimeout', 'handleMissedCallAsAnswered' ]

  toJSON: =>
    @toPlayJSON('queue', _.pick(@attributes, @queue))

module.exports = CallQueueModel
