/*
 * https://www.twilio.com/docs/glossary/what-sms-character-limit
 */

const ASCII_LOWERCASE_Z = 122
const ASCII_LEFT_BRACKET = 91
const ASCII_CARET = 94
const UCS2_SMS_CHAR_LIMIT = 67
const STANDARD_SMS_CHAR_LIMIT = 153
const START_SMS_DIVISION_AT = 160

export const getUCS2SmsCount = (count: number) => Math.ceil(count / UCS2_SMS_CHAR_LIMIT)
export const getStandardSmsCount = (count: number) =>
  count > START_SMS_DIVISION_AT ? Math.ceil(count / STANDARD_SMS_CHAR_LIMIT) : 1

export const isCharUCS2Encoded = (char: string) => {
  const code = char.charCodeAt(0)
  return code > ASCII_LOWERCASE_Z || (code >= ASCII_LEFT_BRACKET && code <= ASCII_CARET)
}

export const getSmsCount = (str: string) => {
  for (const char of str) {
    if (isCharUCS2Encoded(char)) return getUCS2SmsCount(str.length)
  }
  return getStandardSmsCount(str.length)
}
