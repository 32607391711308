import React, {useState} from 'react'
import { Section, SectionHeader, SectionRow } from '../../../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import CallDispositionsIcon from '@ringdna/common/src/assets/icons/svg/CallDispositions'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import InfoIcon from '@ringdna/common/src/assets/icons/svg/Info'
import RdnaTooltip from '@ringdna/common/src/components/RdnaTooltip'
import {manageCallDispositionsSettings} from '../../../const'
import {DispositionProps} from '../../../types'
import ManageCallDispositionsTable from './ManageCallDispositionsTable'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import ConfirmEnableCallDispositionModal from './ConfirmEnableCallDispositionModal'


export default function ManageCallDispositions ({ accountId, accountSettings, setAccountSettings, dispositions, refetchDispositions }: DispositionProps) {
  const [isEnableModalOpen, setIsEnableModalOpen] = useState(false)
  const [isShowManageCallDispositions, setIsShowManageCallDispositions] = useState(accountSettings[manageCallDispositionsSettings])

  const handleDispositionChange = (value: boolean) => {
    if (accountSettings && setAccountSettings) {
      if (value) {
        setIsEnableModalOpen(true)
      } else {
        setIsShowManageCallDispositions(false)
      }
      setAccountSettings({ ...accountSettings, [manageCallDispositionsSettings]: value })
    }
  }

  const handleNotConfirmDispositionEnabled = () => {
    if (accountSettings && setAccountSettings) {
      setAccountSettings({ ...accountSettings, [manageCallDispositionsSettings]: false })
      setIsEnableModalOpen(false)
      setIsShowManageCallDispositions(false)
    }
  }

  const handleConfirmDispositionEnabled = () => {
    setIsEnableModalOpen(false)
    setIsShowManageCallDispositions(true)
    refetchDispositions()
  }

  return (
    <>
      <Section data-testid='manage-call-dispositions-section'>
        <SectionRow>
          <SectionHeader>
            <IconHelper icon={CallDispositionsIcon} variant="filled" />
            <RdnaText className={'rdna-text'}>Manage Call Dispositions</RdnaText>
            <RdnaTooltip title="Allow Revenue to manage call dispositions instead of Salesforce.">
              <div>
                <IconHelper icon={InfoIcon} />
              </div>
            </RdnaTooltip>
          </SectionHeader>
          <RdnaToggleSwitch
            controlledState={{ isChecked: accountSettings[manageCallDispositionsSettings], setIsChecked: handleDispositionChange }}
          />
          <ConfirmEnableCallDispositionModal
            open={isEnableModalOpen}
            onClose={handleNotConfirmDispositionEnabled}
            onConfirm={handleConfirmDispositionEnabled}
            accountId={accountId}
          />
        </SectionRow>
      </Section>
      {isShowManageCallDispositions && (<ManageCallDispositionsTable
        accountId={accountId}
        accountSettings={accountSettings}
        setAccountSettings={setAccountSettings}
        dispositions={dispositions}
        refetchDispositions={refetchDispositions}
      />)}
    </>
  )
}
