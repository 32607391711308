import React from 'react'
import { Grid, Box } from '@mui/material'
import RdnaText from '@ringdna/common/src/components/RdnaText'

type Props = {
  name: string
  children: JSX.Element | string
}

export default function ZoomAttribute({ name, children }: Props) {
  return (
    <Box pt={4}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <RdnaText color="neutral" align="right" style={{ display: 'block' }}>
            {name}
          </RdnaText>
        </Grid>
        <Grid item xs={6}>
          <Box pl={2}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  )
}
