import React from 'react'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export default function ConfirmDisableTeamDispositionModal({ open, onClose, onConfirm, dispositionState }: { open: boolean; onClose: any; onConfirm: any; dispositionState: string}) {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Disable Manage By Team'}
      heading={'Warning'}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <RdnaText>{`You are about to disable the ability for Teams to manage their preferences for Call Disposition Required. The account preference (Call Disposition Required: ${dispositionState}) will be applied to all teams.`}</RdnaText>
    </RdnaModal>
  )
}
