BaseView = require('base/view')

class CdmReportConfirmationModal extends BaseView
  template: require('./template')
  className: 'cdm-report-confirmation-modal'

  events:
    'click button.generate-report' : 'generateReport'

  initialize: (options) =>
    @model = new Backbone.Model(
      salesforceId: options.salesforceId
      customerName: options.customerName
      date: moment().format('YYYY-MM-DD HH:mm')
    )

  generateReport: ->
    App.api.get(
      path: 'cdm/report'
      data: salesforceId: @model.get('salesforceId')
      success: =>
        $('#cdm-report-confirmation-modal').modal('hide')
    )

module.exports = CdmReportConfirmationModal