class ExistingSmartNumberModal extends Backbone.Modal
  submitEl: '.done'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1-view')
    'click #step2':
      view: require('../step-provision-view')

  events:
    'keyup .stepNext': 'keyPressedNext'
    'keyup .stepPrevious': 'keyPressedPrevious'
    'click .previous': 'previousStep'
    'click .next': 'nextStep'

  initialize: (options) ->
    { @mainModal } = options
    @model.get('selectedNumbers').reset()
    @checkKey = (e) =>
      if @active
        switch e.keyCode
          when 27
            @triggerCancel(e)

  keyPressedPrevious: (e) =>
    @previousStep(e) if e.keyCode is 13

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  previousStep: (e) =>
    e.preventDefault()
    if @currentIndex is 0
      @mainModal.showFirstModal()
      return
    @previous()

  nextStep: (e) ->
    e.preventDefault()
    @next()

  beforeSubmit: =>
    selectedSmartNumbers = @model.get('selectedNumbers')
    selectedSmartNumbers.setParams(
      accountId : @model.get('accountId'),
      objectId  : @model.get('objectId'),
      type      : @model.get('type')
    )
    selectedSmartNumbers
      .persist(ladda: @$('.done')[0])
      .done((response) =>
        if (number for number in response when number.status is 'Failed').length
          @model.set('retry': true)
          @model.get('selectedNumbers').reset(response)
          @openAt(1)
          return
        @trigger('assigned', @model.get('selectedNumbers'))
        @destroy()
      )
    false

module.exports = ExistingSmartNumberModal
