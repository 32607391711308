BaseView = require('base/view')

class CallCustomLinksModal extends BaseView

  template: require('./template')
  className: 'call-custom-links-editor'

  ui:
    modal        : '#edit-call-custom-links-modal'
    go           : 'button.persist'

  events:
    'click @ui.go': 'save'

  bindings:
    '.name': 'value:name'
    '.url': 'value:url'

  initialize: (options) =>
    { @accountId } = options
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  save: =>
    @model.set(accountId: @accountId) if not @model.get('accountId')
    @model
      .persist(ladda: @ui.go[0])
      .done?(=>
        @ui.modal.modal('hide')
        @collection.add(@model, { at: 0, merge: true })
        toastr.info('Call Custom Link Saved.')
      )

module.exports = CallCustomLinksModal
