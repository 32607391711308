export const SMS_TEMPLATE_STR = {
  smsTemplates: 'SMS Templates',
  createPreconfiguredSmsTemplate: 'Create preconfigured SMS Templates.',
  noResultsToDisplay: 'No results to display',
  createTemplate: 'Create Template',
  deleteSmsTemplate: 'Delete SMS Template',
  delete: 'Delete',
  edit: 'Edit',
  cancel: 'Cancel',
  templateName: 'Template Name',
  enterTemplateName: 'Enter template name',
  submit: 'Submit',
  smsTemplate: 'SMS Template',
  nameFieldCannotBeBlank: 'the name field cannot be blank.',
  theSmsMessage: 'Enter the SMS message',
  enterTheSmsMessage: 'Enter the SMS message',
  theNotificationFieldCannotBeBlank: 'the notification text field cannot be blank.',
  selectWhichTeams: 'If no teams are selected, the SMS Template will be available to all teams.',
  teams: 'Teams',
  enterTeamsHere: 'Enter team names here',
  characters: 'Characters',
  smsCount: 'SMS Count',
  name: 'Name',
  enable: 'Enabled',
  addFileImage: 'Add File/Image'
}
