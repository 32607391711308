import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

type RdnaGridProps = {
  /** List of elements for the component to arrange */
  items: ReactNode[]
  /** Number of columns for grid view */
  columns?: number
  /** Spacing in pixels between rows & columns */
  spacing?: number
  /** Limit height (in px) of component and make scrollable if exceeds defined height */
  maxHeight?: number
  /** Minimum limit height (in px) of component */
  minHeight?: number
  /** The element would be render in case of empty items */
  emptyCard?: React.ReactNode
}

export default function RdnaGrid({ items, columns = 3, spacing = 12, maxHeight, minHeight, emptyCard }: RdnaGridProps) {
  return (
    <ListContainer
      spacing={spacing}
      columns={columns}
      maxHeight={maxHeight}
      minHeight={minHeight}
      data-testid={'grid-container'}
    >
      {items.length
        ? items.map((item, i) => (
            <ListItem columns={columns} key={i} spacing={spacing}>
              {item}
            </ListItem>
          ))
        : emptyCard || null}
    </ListContainer>
  )
}

type ContainerProps = {
  spacing: number
  columns: number
  maxHeight?: number
  minHeight?: number
}
const ListContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  margin: ${props => (props.columns > 1 ? `-${props.spacing}px 0 0 -${props.spacing}px` : `0`)};
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
      min-height: ${props.minHeight}px;
      overflow-y: auto;
    `}
`

const ListItem = styled.div<{ columns: number; spacing: number }>`
  width: ${props => 100 / props.columns}%;
  padding: ${props => (props.columns > 1 ? `${props.spacing}px 2px 0 ${props.spacing}px` : `0 2px ${props.spacing}px`)};
  box-sizing: border-box;

  &:first-of-type {
    padding-top: ${props => props.spacing}px;
  }
`
