export enum AlertType {
  INCLUDES = 'Includes',
  EXCLUDES = 'Excludes'
}

export enum TimerType {
  TIME = 'Timer'
}

export enum TriggeringScope {
  AGENT = 'Agent',
  BOTH = 'Both',
  PARTICIPANT = 'Participant'
}

export enum ConversationEtiquette {
  AGENT_TALK_RATE = 'TalkRate',
  AGENT_INTERRUPTIONS = 'Interruptions',
  AGENT_MONOLOGUES = 'Monologues'
}

export type NotificationTriggeringType = AlertType | ConversationEtiquette | TimerType

export enum CallDirection {
  ALL = 'All',
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound'
}

export enum AlertsTargetingType {
  TEAMS = 'Teams',
  ALL_USERS = 'AllUsers',
  INDIVIDUAL_USERS = 'IndividualUsers',
  SALESFORCE_ROLES = 'SalesforceRoles',
  SALESFORCE_PROFILES = 'SalesforceProfiles'
}

export enum NotifyType {
  ALERT = 'Alert',
  CHECKLIST = 'Checklist',
  INFORMATION = 'Info',
  WARNING = 'Warning'
}

export enum NotificationCategories {
  ANNOUNCEMENT = 'Announcement',
  BEHAVIORAL_ETIQUETTE = 'Behavioral Etiquette',
  BEST_PRACTICE = 'Best Practice',
  COMPETITION = 'Competition',
  ESCALATION = 'Escalation',
  NEXT_ACTION = 'Next Action',
  OBJECTION_HANDLING = 'Objection Handling',
  OTHER = 'Other',
  POLICY = 'Policy',
  POSITIONING = 'Positioning',
  RELEVANT_RESOURCE = 'Relevant Resource',
  SELF_COACHING = 'Self-Coaching'
}

export enum Notify {
  AGENT = 'Agent',
  SUPERVISORS = 'Supervisors',
  BOTH = 'Both'
}

export enum WidgetEvents {
  LISTEN_IN = 'Listen In'
}

export type WidgetEvent = ({ eventName, data }: { eventName: string; data: any }) => void
