/**
 * Sentry Service
 * Centralize where we have sentry code between packages.
 */
const filteredTwilioErrors = [31000, 31003, 31009]

/* eslint-disable */
module.exports = class SentryHelper {
    constructor(applicationName) {
        this.applicationName = applicationName

        this.moduleName = ''
    }

    get defaultErrorMsg() {
        return `an unknown ${this.applicationName} error has occured`
    }

    module(name) {
        this.moduleName = name
        return this
    }

    notify(error) {
        this._sendToSentry(this._sanitizeError(error))
    }

    /**
     *
     * Reference: https://ringdna.atlassian.net/browse/RDNA-20367
     * There are some errors codes we get back from Twilio that are transient
     * and are not an indication of something wrong with our application. We
     * don't need to report these issues to sentry.
     */
    _parseTwilioError(error) {
        if(filteredTwilioErrors.indexOf(error.code) === -1) {
            let errorDescription = (error.twilioError.causes && error.twilioError.causes.length) ?  error.twilioError.causes[0] : error.twilioError.description
            return new Error(`Twilio Error ${error.code}: ${error.message}. ${errorDescription}`)
        } else {
            return false
        }
    }

    /**
     * We might not actually be receiving an error object.
     */
    _sanitizeError(error) {
        if (_.isError(error)) {
            return error
        }

        if (error.twilioError) {
            return this._parseTwilioError(error)
        }

        this._log(error)

        const msg = this.moduleName ? this.defaultErrorMsg + ` within the ${this.moduleName}` : this.defaultErrorMsg
        let errorString = typeof error === 'object' ? JSON.stringify(error) : error.toString()
        return new Error(errorString || msg)
    }

    _sendToSentry(error) {
        if (this.moduleName) {
            sentryClient.setTag('module', this.moduleName)
        }
        if(error) {
            sentryClient.captureException(error)
        }
    }

    _log(error) {
        const msg = `SentryHelper failed to receive a JS Error obj and instead receieved type: ${typeof error}`
        console.error(`(SentryHelper) ${msg}`, JSON.stringify(error))
    }
}
