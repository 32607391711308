import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function ChatIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M10.4 14.4L7.93979 16.891C7.59663 17.2384 7.42504 17.4121 7.27756 17.4244C7.14961 17.435 7.02433 17.3836 6.94077 17.2861C6.84444 17.1738 6.84444 16.9296 6.84444 16.4412V14.4C6.43159 14.4 6.22516 14.4 6.05216 14.3747C5.00299 14.221 4.17902 13.397 4.02534 12.3478C4 12.1748 4 11.9684 4 11.5556V7.84C4 6.49587 4 5.82381 4.26158 5.31042C4.49168 4.85883 4.85883 4.49168 5.31042 4.26158C5.82381 4 6.49587 4 7.84 4H13.76C15.1041 4 15.7762 4 16.2896 4.26158C16.7412 4.49168 17.1083 4.85883 17.3384 5.31042C17.6 5.82381 17.6 6.49587 17.6 7.84V11.2M17.6 20L15.8589 18.7895C15.6141 18.6193 15.4917 18.5343 15.3586 18.4739C15.2404 18.4204 15.1161 18.3814 14.9885 18.3579C14.8447 18.3314 14.6956 18.3314 14.3975 18.3314H12.96C12.0639 18.3314 11.6159 18.3314 11.2736 18.157C10.9726 18.0036 10.7278 17.7589 10.5744 17.4578C10.4 17.1156 10.4 16.6675 10.4 15.7714V13.76C10.4 12.8639 10.4 12.4159 10.5744 12.0736C10.7278 11.7726 10.9726 11.5278 11.2736 11.3744C11.6159 11.2 12.0639 11.2 12.96 11.2H17.44C18.3361 11.2 18.7841 11.2 19.1264 11.3744C19.4274 11.5278 19.6722 11.7726 19.8256 12.0736C20 12.4159 20 12.8639 20 13.76V15.9314C20 16.6769 20 17.0497 19.8782 17.3437C19.7158 17.7358 19.4043 18.0472 19.0123 18.2096C18.7183 18.3314 18.3455 18.3314 17.6 18.3314V20Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
