import React, { useContext, useEffect, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { IntegrationsContext } from '../store/IntegrationsProvider'
import { useGmailAuthUrl, useIntegrations } from '../../../../store/api/user-integrations-api'
import { IntegrationsActions } from '../constants'
import AddIntegration from './AddIntegration'
import ActiveIntegrations from './ActiveIntegrations'
import ZoomUserManager from './ZoomUserManager'
import SetPrimaryAccount from './SetPrimaryAccount'
import { IntegrationProviderTypes, NylasUser } from '@ringdna/common/src/types/nylas'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

type gmailAuthParamsType = {
  reconnect?: boolean
  email?: string
  nylasId?: string
}

export default function IntegrationsContainer() {
  const {
    dispatch,
    integrationsState: { activeUser, connectedIntegration, deletedIntegration, displayZoomManager }
  } = useContext(IntegrationsContext)
  const [integrationsPayload = { nylasSettings: [], nylasUsers: [] }, , loading, refetch] = useIntegrations({
    meta: {
      id: activeUser.userId
    }
  })

  const gmailRef = useRef<NylasUser | undefined>()
  const isReconnectFlagEnabled = isFlagged(flags.NYLAS_RECONNECT, false)

  const gmailIntegration = integrationsPayload?.nylasUsers?.filter(
    integration => integration.provider === IntegrationProviderTypes.GMAIL
  )[0]

  const [gmailAuthParams, setGmailAuthParams] = useState<gmailAuthParamsType | undefined>()
  const [gmailAuthUrlPayload, , , refetchGoogleUrl] = useGmailAuthUrl({
    query: {
      userId: activeUser.userId,
      reconnect: gmailAuthParams?.reconnect,
      email: gmailAuthParams?.email,
      nylasId: gmailAuthParams?.nylasId
    }
  })

  useDeepCompareEffect(() => {
    if (integrationsPayload.nylasSettings.length)
      dispatch({
        type: IntegrationsActions.SET_INTEGRATIONS,
        payload: integrationsPayload
      })
  }, [integrationsPayload])

  useEffect(() => {
    if (gmailAuthUrlPayload && gmailAuthUrlPayload.url) {
      dispatch({
        type: IntegrationsActions.SET_GMAIL_AUTH_URL,
        payload: gmailAuthUrlPayload.url
      })
    }
  }, [dispatch, gmailAuthUrlPayload])

  useEffect(() => {
    if (connectedIntegration && refetch) refetch()
  }, [connectedIntegration, refetch])

  useEffect(() => {
    if (deletedIntegration && refetch) refetch()
  }, [deletedIntegration, refetch])

  useDeepCompareEffect(() => {
    if (isReconnectFlagEnabled) {
      if (gmailIntegration && gmailIntegration !== gmailRef.current) {
        gmailRef.current = gmailIntegration
        setGmailAuthParams({ ...gmailIntegration, reconnect: true })
        refetchGoogleUrl()
      } else if (typeof gmailIntegration === 'undefined') {
        setGmailAuthParams(gmailIntegration)
      }
    }
  }, [gmailIntegration, setGmailAuthParams, refetchGoogleUrl, isReconnectFlagEnabled])

  return (
    <>
      {!displayZoomManager ? (
        <>
          <AddIntegration />
          {!loading && <SetPrimaryAccount />}
          <ActiveIntegrations integrationsLoading={!!loading} />
        </>
      ) : (
        <ZoomUserManager />
      )}
    </>
  )
}
