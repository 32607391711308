BaseView = require('base/view')

hiddenTooltip = "The field cannot be added because Read Access has not been granted to this Profile in Salesforce.
        Please contact your Salesforce administrator if you believe this is in error."
limitTooltip = "The field cannot be added to this Profile because it has reached its maximum number of allowed fields."

class СheckboxHeaderBaseCell extends BaseView
  template: _.template("<input type='checkbox'>&NonBreakingSpace;<span></span>")
  tagName: 'th'
  className: 'renderable'

  bindings:
    'input': 'checked:checked,disabled:disabled'
    'span': 'text:label'

  initialize: (options) ->
    { @column } = options
    @viewModel = new Backbone.Model(
      label: @column.get('label')
      checked: false
      disabled: false
    )

class ViewHeaderCell extends СheckboxHeaderBaseCell
  events:
    'click input': 'onClick'

  initialize: (options) ->
    super(options)
    @setCheckboxValues()
    @listenTo(@collection, 'change:view', @setCheckboxValues)

  onClick: (e) =>
    checked = e.target.checked
    @collection.models.forEach((model) => model.set(view: checked) unless model.get('hidden') or model.get('profile')?.limit)

  setCheckboxValues: =>
    modelsCanBeChecked = @collection.models.filter((model) => not model.get('hidden') or model.get('profile')?.limit)
    checked = modelsCanBeChecked.every((model) => model.get('view'))
    disabled = @collection.models.length is 0
    @viewModel.set(
      checked: checked and not disabled
      disabled: disabled
    )

class AlwaysVisibleHeaderCell extends СheckboxHeaderBaseCell
  events:
    'click input': 'onClick'

  initialize: (options) ->
    super(options)
    @setCheckboxValues()
    @listenTo(@collection, 'change:view change:visible', @setCheckboxValues)

  onClick: (e) =>
    checked = e.target.checked
    @collection.models.forEach((model) => model.set(visible: checked) if model.get('view'))

  setCheckboxValues: =>
    modelsCanBeChecked = @collection.models.filter((model) => model.get('view'))
    checked = modelsCanBeChecked.every((model) => model.get('visible'))
    disabled = modelsCanBeChecked.length is 0
    @viewModel.set(
      checked: checked and not disabled
      disabled: disabled
    )

class EditableHeaderCell extends СheckboxHeaderBaseCell
  events:
    'click input': 'onClick'

  initialize: (options) ->
    super(options)
    @setCheckboxValues()
    @listenTo(@collection, 'change:view change:editable', @setCheckboxValues)

  onClick: (e) =>
    checked = e.target.checked
    @collection.models.forEach((model) => model.set(editable: checked) if model.get('updateable') and model.get('view'))

  setCheckboxValues: =>
    modelsCanBeChecked = @collection.models.filter((model) => model.get('updateable') and model.get('view'))
    checked = modelsCanBeChecked.every((model) => model.get('editable') and model.get('view'))
    disabled = modelsCanBeChecked.length is 0
    @viewModel.set(
      checked: checked and not disabled
      disabled: disabled
    )

class RequiredHeaderCell extends СheckboxHeaderBaseCell
  events:
    'click input': 'onClick'

  initialize: (options) ->
    super(options)
    @setCheckboxValues()
    @listenTo(@collection, 'change:view change:editable change:required', @setCheckboxValues)

  onClick: (e) =>
    checked = e.target.checked
    @collection.models.forEach((model) => model.set(required: checked) if model.get('updateable') and model.get('editable'))

  setCheckboxValues: =>
    modelsCanBeChecked = @collection.models.filter((model) => model.get('updateable') and model.get('editable') and model.get('view'))
    checked = modelsCanBeChecked.every((model) => model.get('required'))
    disabled = modelsCanBeChecked.length is 0
    @viewModel.set(
      checked: checked and not disabled
      disabled: disabled
    )

class СheckboxBaseCell extends BaseView
  template: _.template("<input type='checkbox'>")
  tagName: 'td'
  className: 'renderable checkbox-cell'

  initialize: (options) ->
    { @column } = options

  events:
    'click input': 'onClick'

  onClick: =>
    @model.collection.trigger("change:#{@column.get('name')}", @model)

class ViewCell extends СheckboxBaseCell
  template: _.template("
    <div class='tooltip-container'>
      <div class='checkbox-container'>
        <div class='checkbox-tooltip'></div>
        <input type='checkbox'>
      </div>
    </div>
  ")

  bindings:
    'input': 'checked:view,disabled:any(hidden,limit)'
    '.checkbox-tooltip': 'toggle:any(hidden,limit)'

  ui:
    checkboxTooltip: '.checkbox-tooltip'

  computeds:
    limit:
      deps: ['profile']
      get: (profile) -> profile?.limit

  onRender: ->
    @ui.checkboxTooltip.tooltip(
      title: if @getBinding('limit') then limitTooltip else hiddenTooltip
    )

class AlwaysVisibleCell extends СheckboxBaseCell
  bindings:
    'input': 'checked:visible,disabled:not(view)'

class EditableCell extends СheckboxBaseCell
  bindings:
    'input': 'checked:editable,disabled:any(not(view),not(updateable))'

class RequiredCell extends СheckboxBaseCell
  bindings:
    'input': 'checked:required,disabled:any(not(view),not(updateable),not(editable))'

  initialize: (options) ->
    super(options)
    @listenTo(@model, 'change:editable', =>
      @model.set(required: false)
      @model.collection.trigger("change:required", @model)
    )

class DeleteCell extends Backgrid.Cell
  className: 'action-cell'
  events:
    'click a.delete': 'delete'
  delete: ->
    bootbox.confirm('Are you sure you want to delete this field? This will also remove the field from each users softphone.', (ok) =>
      if ok
        @model.destroy().done(=> @column.channel?.trigger('remove'))
      true
    )
  render: ->
    $(@el).html('''
          <a class="delete">
            <span class="glyphicon glyphicon-remove-sign"></span>
          </a>
        ''')
    @

module.exports = class
  @ViewHeaderCell: ViewHeaderCell
  @AlwaysVisibleHeaderCell: AlwaysVisibleHeaderCell
  @EditableHeaderCell: EditableHeaderCell
  @RequiredHeaderCell: RequiredHeaderCell
  @ViewCell: ViewCell
  @AlwaysVisibleCell: AlwaysVisibleCell
  @EditableCell: EditableCell
  @RequiredCell: RequiredCell
  @DeleteCell: DeleteCell
