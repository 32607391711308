StepModel = require('../../model')

class VoicemailStepModel extends StepModel

  defaults:
    type: 'Voicemail'
    info: 'Transfer the caller to voicemail'
    leaf: true

  recipientId: ->
    @args()['recipientId']

  recipientType: ->
    @args()['recipientType']

  errors: ->
    messages = []
    selectors = []

    if not @recipientId()
      messages.push('User or call queue is required')
      selectors.push('.picker > .ugn')

    audioErrors = @audioErrors(['audio'])

    errors = _.zip(messages, selectors)
    if audioErrors.length > 0
      errors = _.union(errors, audioErrors)

    errors

module.exports = VoicemailStepModel