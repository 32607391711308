import styled from 'styled-components'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .rdna-text {
    margin: 0 ${({ theme }) => theme.spacing * 2}px;
    display: flex;
    align-items: center;
  }
`
export const SettingSelect = styled(RdnaAutocomplete)`
  width: ${props => props.theme.spacing * 45}px;
  margin-left: ${props => props.theme.spacing * 2}px;
`
