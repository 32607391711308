import * as React from 'react'
import { ExchangeIcon, Office365Icon } from './index'
import styled from 'styled-components'

type Props = {
  size?: number
}
const Holder = styled.div`
  display: inline-flex;
  align-items: center;
  svg:first-child {
    margin-right: ${props => props.theme.spacing}px;
  }
`

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <Holder>
      <Office365Icon size={size} />
      <ExchangeIcon size={size} />
    </Holder>
  )
}

export default SvgComponent
