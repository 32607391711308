import React from 'react'
import { Colors } from '../../../constants/colors'

const GraphIcon = ({ size = 24, color = Colors.N100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid={`graph-icon`}>
      <rect x="2.5" y="21.5" width="9" height="3" rx="1" transform="rotate(-90 2.5 21.5)" fill={color} stroke="black" />
      <rect
        x="10.5"
        y="21.5"
        width="13"
        height="3"
        rx="1"
        transform="rotate(-90 10.5 21.5)"
        fill={color}
        stroke="black"
      />
      <rect
        x="18.5"
        y="21.5"
        width="19"
        height="3"
        rx="1"
        transform="rotate(-90 18.5 21.5)"
        fill={color}
        stroke="black"
      />
    </svg>
  )
}

export default GraphIcon
