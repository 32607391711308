import React from 'react'
import styled from 'styled-components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
  DatePickerProps,
  MobileDateTimePicker
} from '@mui/x-date-pickers'
import RdnaTextInput from '../RdnaTextInput'
import useId from '../../../hooks/useId'
import { SalesforceDataType } from '../../RdnaMomentsDrawer/utils/types'

export type DateType = SalesforceDataType.DATE | SalesforceDataType.TIME | SalesforceDataType.DATE_TIME

export type RdnaDatePickerProps<T> = {
  name: string
  required?: boolean
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  dateType?: DateType
} & Pick<DatePickerProps<T, Date>, 'minDate' | 'maxDate' | 'value' | 'onChange'>

export const DATE_FORMAT = 'MM/dd/yyyy'
export const TIME_FORMAT = 'h:mmaaa'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export default function RdnaDatePicker<T>({
  // KeyboardDatePickerProps
  required, // overriding default 'required' prop to only add the asterisk. validate required in parent component
  label,
  placeholder,
  disabled = false,
  name,
  error,
  helperText,
  onChange,
  dateType = SalesforceDataType.DATE,
  value,
  ...restOfDatePickerProps
}: RdnaDatePickerProps<T>): JSX.Element {
  const _id = useId(name + '-datepicker_')

  const DateTypeComponent =
    dateType === SalesforceDataType.DATE
      ? MobileDatePicker
      : dateType === SalesforceDataType.TIME
      ? MobileTimePicker
      : MobileDateTimePicker

  const inputFormat =
    dateType === SalesforceDataType.DATE
      ? DATE_FORMAT
      : dateType === SalesforceDataType.TIME
      ? TIME_FORMAT
      : DATE_TIME_FORMAT

  // @ts-ignore
  return (
    <StyledContainer value={value}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTypeComponent
          inputFormat={inputFormat}
          toolbarFormat={inputFormat}
          renderInput={props => {
            return (
              <RdnaTextInput
                {...props}
                fullWidth
                disabled={disabled}
                placeholder={placeholder}
                variant="outlined"
                margin="dense"
                size="small"
                id={_id}
                name={name}
                label={label}
                required={required}
                helperText={helperText}
                error={error}
              />
            )
          }}
          data-testid={`${name}-input`}
          DialogProps={{
            className: 'date-picker',
            // @ts-ignore
            'data-testid': `date-picker-${name}`
          }}
          value={value}
          {...restOfDatePickerProps}
          onChange={value => {
            onChange(value as Date)
          }}
        />
      </LocalizationProvider>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ value: unknown }>`
  .MuiInputBase-input {
    color: ${props => (props.value === null ? props.theme.palette.neutral.main : 'currentColor')};
  }
`
