import 'isomorphic-fetch'
import React, { ChangeEvent, HTMLAttributes, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { AutoCompleteInputData, InputComponentProps } from '../../types/form-inputs'
import { DataListItem, useDataPicklistApiFactory } from '../../api/account-data-api'
import RdnaAutocomplete from './RdnaAutocomplete'
import { differenceBy, isEqual, uniqBy } from 'lodash-es'
import RdnaUserLabel from '../RdnaUserLabel'
import { DefaultApi } from '@ringdna/client/src/constants'
import { v3Picklist } from '../RdnaReports/api'

const INITIAL_MESSAGE = 'Start typing...'
const EMPTY_MESSAGE = 'No options'

const AutoCompleteInput = ({ inputData, isError, errorMessage, onChange, onBlur }: InputComponentProps) => {
  const [options, setOptions] = useState<DataListItem[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [inputValue, setInputValue] = useState<AutoCompleteInputData | AutoCompleteInputData[]>([])
  const [initializedAccountId, setInitializedAccountId] = useState<boolean>(false)
  const { values } = useFormikContext<any>()

  const updateForm = useCallback(
    (value?: DataListItem) => {
      let displayName = ''
      let changeValue: AutoCompleteInputData | AutoCompleteInputData[] = inputData.multipleValues
        ? []
        : { value: '', label: '' }
      let insertValue
      if (value) {
        insertValue = { value: value.value, label: value.text }
        if (inputData.multipleValues) {
          if (insertValue.value === '') {
            // "All" input clears previous selections
            changeValue = [insertValue]
          } else {
            changeValue = uniqBy([...(inputValue as AutoCompleteInputData[]), insertValue], 'value').filter(
              item => item.value !== ''
            )
          }
        } else if (inputData.searchType === 'accounts') {
          changeValue = insertValue
          displayName = (value as DataListItem).text
        } else {
          displayName = (value as DataListItem).text
          changeValue = insertValue
        }
      }
      if (!isEqual(inputValue, changeValue)) {
        setInputValue(changeValue)
        onChange(inputData.name, changeValue)
        setSearchTerm(displayName)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  )

  const clearData = function () {
    setOptions([])
    updateForm(undefined)
  }

  const handleChange = function (event: SyntheticEvent, value: DataListItem) {
    if (onBlur) {
      onBlur(inputData.name, true)
    }
    if (value) {
      updateForm(value)
    } else if (!inputData.multipleValues) {
      clearData()
    }
  }

  const updateSearch = function (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newTerm = event.currentTarget.value
    setSearchTerm(newTerm)
    if (!newTerm && !inputData.multipleValues) clearData()
  }
  let searchUrl = inputData.customApi ? '/' : `/api/v${v3Picklist.includes(inputData.searchType || '') ? 3 : 2}/app/`
  const apiBase = inputData.customApi || DefaultApi
  if (inputData.customEndpoint) {
    searchUrl += inputData.customEndpoint
  } else {
    switch (inputData.searchType) {
      case 'locations':
        searchUrl += 'locations'
        break
      case 'supervisor':
        searchUrl += 'users/supervisorPicklist'
        break
      case 'queues':
        searchUrl += 'callQueues'
        break
      default:
        searchUrl += `${inputData.searchType}/picklist`
    }
  }

  const queryObj = inputData.customApi
    ? { name: searchTerm, accountId: values?.accountId?.value || values.accountId }
    : {
        'pager.currentPage': 1,
        'pager.pageSize': 30,
        userStatus: 'all',
        name: searchTerm,
        criteria: searchTerm,
        field: inputData.customSearchParam,
        accountId: values?.accountId?.value || values.accountId
      }

  const [data, , loading, refetch] = useDataPicklistApiFactory(apiBase)(
    {
      meta: {
        searchUrl
      },
      query: queryObj
    },
    { paused: true }
  )
  const refetchRef = useRef(refetch)

  useEffect(() => {
    refetchRef.current = refetch
  }, [refetch])

  useEffect(() => {
    if (searchTerm.length > 1 && !inputData.autoload) {
      refetchRef.current()
    } else if (searchTerm.length === 0) {
      setOptions([])
    }
  }, [searchTerm, refetchRef, inputData.autoload])

  // When a form value is updated by deleting a tag or the clear button, need to update its value
  useEffect(() => {
    if (values[inputData.name] !== inputValue) {
      let updatedValue = values[inputData.name]
      if (updatedValue === '' && inputData.multipleValues) {
        updatedValue = []
        onChange(inputData.name, updatedValue)
      }
      setInputValue(updatedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[inputData.name]])

  // When account ID is changed, every other input must be reset to only search that account
  useEffect(() => {
    if (initializedAccountId && values.accountId !== null) {
      if (inputData.name !== 'accountId') {
        if (values.accountId?.value || values.accountId?.length) {
          if (inputData.defaultValue && !inputData.multipleValues) {
            setSearchTerm(inputData.defaultSearchTerm || inputData.defaultValue)
          } else if (inputData.autoload) {
            clearData()
            refetchRef.current()
          } else {
            clearData()
          }
        } else {
          clearData()
        }
      } else if (inputData.defaultSearchTerm && !searchTerm) {
        setSearchTerm(inputData.defaultSearchTerm)
      }
    } else {
      if (inputData.autoload) refetchRef.current()
      if (inputData.defaultSearchTerm && inputData.defaultValue) {
        const defaultOption: DataListItem = { text: inputData.defaultSearchTerm, value: inputData.defaultValue }
        updateForm(defaultOption)
      }
      setInitializedAccountId(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.accountId, inputData.autoload, inputData.name, refetchRef])

  useEffect(() => {
    if (data) {
      // dispositions and queues picklist endpoints return improperly formatted responses, this corrects them
      // @ts-ignore some picklist improperly using results key, we need to fix underlying endpoint
      let rawData: DataListItem[] = data.results || data
      if (rawData.length && !rawData[0].value) {
        rawData = rawData
          .map(entry =>
            entry.name && entry.id
              ? { text: entry.name, value: entry.id.toString() }
              : { text: entry.text, value: entry.text }
          )
          .filter(entry => entry.value !== 'Default')
      }
      setOptions(differenceBy(uniqBy(rawData, 'text'), inputValue as AutoCompleteInputData[], 'text'))
    }
  }, [data, inputData.searchType, updateForm, inputValue])

  const isInputEmpty = searchTerm.length < 1

  return (
    <RdnaAutocomplete
      className={inputData.className}
      id={inputData.name}
      label={inputData.label}
      disableCloseOnSelect={inputData.multipleValues}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: DataListItem) => (
        <li {...props}>
          <RdnaUserLabel
            name={option.text}
            email={option.email}
            account={inputData.searchType === 'accounts' ? option.value : undefined}
            showAvatar={inputData.searchType === 'usersShare'}
          />
        </li>
      )}
      options={options}
      getOptionLabel={option => (option as DataListItem).text}
      inputValue={searchTerm}
      loading={!!loading}
      onChange={handleChange}
      onInputChange={updateSearch}
      inputError={isError}
      inputHelperText={isError ? errorMessage : inputData.helperText}
      placeholder={inputData.placeholder}
      noOptionsText={isInputEmpty ? INITIAL_MESSAGE : EMPTY_MESSAGE}
    />
  )
}

export default AutoCompleteInput
