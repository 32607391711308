BaseModel = require('base/model')
isEmail = require('@ringdna/common/src/utils/validationUtils').default.isEmail

class CreateUserModel extends BaseModel
  urlRoot: 'users'

  validate: (attrs) ->
    if attrs.firstName.length < 2
      return 'Please enter a first name.'

    if attrs.lastName.length < 2
      return 'Please enter a last name.'

    if !isEmail(attrs.email || '')
      return 'Please enter a valid email.'

    if attrs.salesforceId.length < 15
      return 'Please enter a SalesforceID (15 or 18 characters).'

    return

module.exports = CreateUserModel
