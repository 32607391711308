import React from 'react'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { ReportDetail } from './Models'
import { CardDescription, StyledCard } from './Containers'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'

const Card = (report: ReportDetail) => {
  const iconStrokeWidth = 0.8
  const viewReportHandler = () => {
    window.location.href = `#${report.path}`
  }

  return (
    <StyledCard width="fill" data-testid={'card-' + report.title.split(' ').join('-').toLowerCase()}>
      <>
        <IconHelper
          icon={report.icon}
          size={60}
          iconSize={60}
          strokeWidth={report.thinner ? iconStrokeWidth / 2 : iconStrokeWidth}
        />
        <RdnaText style={{ paddingTop: 18 }} variant="h6">
          {report.title}
        </RdnaText>
        <CardDescription>
          <RdnaText>{report.description}</RdnaText>
        </CardDescription>
        <RdnaButton variant="outlined" text={'View Report'} onClick={viewReportHandler} />
      </>
    </StyledCard>
  )
}

export default Card
