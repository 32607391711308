import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

type Params = {
  meta: {
    apiPath: string
  }
  query?: {
    input: string
  }
}

export const useSendMessageLab = createUseFetchAction<string, Params>({
  key: 'send-chat-message-lab',
  api: 'apiLabs',
  path: params => params.meta.apiPath,
  headers: {
    credentials: 'include'
  }
})

type SourceParams = {
  meta: {
    apiPath: string
  }
  query?: {
    input: string
    [key: string]: string
  }
}

type SourceResponse = {
  sources: string[] | null
}

export const useGetSources = createUseFetch<SourceResponse, SourceParams>({
  key: 'get-gen-ai-sources',
  api: 'apiGpu',
  path: params => params.meta.apiPath,
  headers: {
    credentials: 'include'
  }
})
