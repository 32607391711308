BaseGrid = require('base/grid')
BaseModel = require('base/model')
BaseView = require('base/view')
EditCallDispositionModal = require('./modals/main-view')
CallDispositionModel = require('./model')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')

class SyncDispositionsModel extends BaseModel
  url: 'callDispositions/syncToSalesforce'

class CallDispositionsView extends BaseView

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  className: 'subjects'

  regions:
    grid  : 'div.grid'
    modal : 'div.modal-container'

  ui:
    sync: 'button.sync-call-dispositions'

  events:
    'click a.add': 'createDisposition'
    'click button.sync-call-dispositions': 'syncCallDispositionsToSalesforce'

  bindings:
    '.sync-call-dispositions': 'enabled: enabledActions'

  initialize: (options) =>
    { @accountId, @dispositions } = options
    @viewModel = new Backbone.Model(enabledActions: options.saved)

  onRender: =>
    @showChildView('grid', @buildGrid()) if @getRegion('grid')

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a[data-action="update"]': 'edit'
        'click a.delete': 'delete'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      delete: ->
        inProgress = false
        disposition = @model.get('disposition')

        dialog = bootbox.dialog(
          title: 'Delete Disposition'
          message: "Are you sure you want to delete the disposition, <b>#{_.escape(disposition)}</b>?"
          onEscape: =>
            return false if inProgress
            true
          buttons:
            cancel:
              label: 'Cancel'
              className: 'btn btn-default pull-left'
              callback: =>
                return false if inProgress
                true
            ok:
              label: 'Delete'
              className: 'btn btn-danger delete-ok'
              callback: =>
                inProgress = true
                @model
                  .delete(ladda: $('.delete-ok')[0], wait: true)
                  .done( => dialog.modal('hide'))
                  .fail( => dialog.modal('hide'))
                false
        )

      render: ->
        $(@el).html('''
          <a class="update" data-action="update" data-toggle="modal" data-target="#edit-call-disposition-modal">
            <span class="glyphicon glyphicon-pencil"></span>
          </a>
          <a class="delete">
            <span class="glyphicon glyphicon-remove-sign"></span>
          </a>
        ''')
        @

    columns = [
      { name: 'disposition', label: 'Disposition', cell: 'string' },
      { name: 'outcomeCategory', label: 'Outcome Category', cell: 'string' },
      { name: 'inbound', label: 'Assigned Teams (Inbound)', cell: 'string' },
      { name: 'outbound', label: 'Assigned Teams (Outbound)', cell: 'string'},
      { name: '',            label: 'Actions',     cell: actionCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @dispositions
      emptyText  : 'No dispositions found.'
      onEdit     : @editDisposition
    )

  createDisposition: =>
    @showChildView('modal', new EditCallDispositionModal(
      accountId: @accountId
      model: new CallDispositionModel()
      collection: @dispositions
      isEdit: false
    ))

  editDisposition: (options) =>
    model = options.model.clone()
    @showChildView('modal', new EditCallDispositionModal(
      accountId: @accountId
      model: model
      collection: @dispositions
      isEdit: true
    ))

  syncCallDispositionsToSalesforce: ->
    bootbox.confirm(
      title: 'Warning'
      message: 'It will overwrite your Salesforce call disposition picklist. Do you want to continue syncing?'
      callback: (ok) =>
        return unless ok
        syncModel = new SyncDispositionsModel(accountId: @accountId)
        syncModel.persist(ladda: @ui.sync[0]).done?( =>
          toastr.info('Call dispositions synced to Salesforce successfully.')
        )
        true
    )

  updateActions: (enabled) =>
    @viewModel.set('enabledActions', enabled)

module.exports = CallDispositionsView
