import { createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'

type UserExportParams = {
  query: {
    accountId: string
    email: string
    name: string | null
    salesforceId: string | null
    userStatus: string
    userEmail: string | null
  }
}

export const useUserExport = createUseFetchAction<void, UserExportParams>({
  key: 'user-export',
  path: '/api/v2/app/users/export',
  method: HttpMethods.Get
})
