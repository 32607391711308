import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function CloseIcon({ size = 24, color = Colors.N100, title = 'close-icon' }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <title>{title}</title>
      <path d="M19.071 4.929L4.93 19.07M19.071 19.071L4.93 4.93" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default CloseIcon
