BaseView        = require('base/view')
ReactComponent  = require('src/ReactComponent').default
SalesMethodology         = require('src/components/sales-methodology/SalesMethodology').default
ProductConstant = require('@ringdna/common/src/constants/products')

class SalesMethodologyView extends BaseView
  template: _.template('<div class="sales-methodology"></div>')
  className: 'sales-methodology-view'

  regions:
    container  : '.sales-methodology'

  onRender: =>
    return unless App.featureFlag.isFlagged(App.featureFlag.flags.SALES_METHODOLOGY)
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @showChildView('container', new ReactComponent({
      component: SalesMethodology
      theme: ProductConstant.Products.ADMIN
      props: { accountId: @accountId }
    }))

module.exports = SalesMethodologyView
