import React from 'react'

import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { ActionType, useSupportSettingsDispatch } from '../../../utils/contextReducer'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'

type SupportSettingToggleRowProps = {
  title: string
  value: any
  isDisabled?: boolean
  placeholder?: string
  setting: string
}

const SupportSettingInputRow = ({
  title,
  value,
  isDisabled = false,
  setting,
  placeholder
}: SupportSettingToggleRowProps) => {
  const dispatch = useSupportSettingsDispatch()

  function handleChange(value: string) {
    dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { [setting]: value } })
  }

  return (
    <RdnaSettingRow heading={title} description={''}>
      <RdnaTextInput
        isDisabled={isDisabled}
        inputData={{
          name: setting,
          type: InputTypes.TEXT,
          placeholder: placeholder,
          className: 'support-input'
        }}
        value={value == null ? '' : value}
        onChange={({ target: { value } }) => handleChange(value)}
      />
    </RdnaSettingRow>
  )
}
export default React.memo(SupportSettingInputRow)
