import React from 'react'

import Main from './components/main'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import InsightsRouter from '@ringdna/cai/src/components/insights'
import RoleDetail from './components/RoleDetail'
import { useRoleDefinitions } from './api'

type RolesProps = {
  accountId: number
}

type RouteTypes = {
  path: string
  label: string
  render: any
}

export default function Roles({ accountId }: RolesProps) {
  const [roleDefinitions] = useRoleDefinitions()
  const ROUTES: RouteTypes[] = [
    {
      path: '/',
      label: 'Main',
      render: function fn() {
        return <Main accountId={accountId} roleDefinitions={roleDefinitions || []}></Main>
      }
    },
    {
      path: '/:id',
      label: 'Role Detail',
      render: function fn() {
        return <RoleDetail accountId={accountId} roleDefinitions={roleDefinitions || []}></RoleDetail>
      }
    }
  ]

  return (
    <HashRouter hashType="noslash" basename={'roles'}>
      <Switch>
        {ROUTES.map(route => {
          return <Route key={route.label} path={route.path} exact={true} render={route.render} />
        })}
        <Redirect to={'roles'} />
        <InsightsRouter />
      </Switch>
    </HashRouter>
  )
}
