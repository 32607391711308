BaseView = require('base/view')
AccountModel = require('features/accounts/model')

class Picklist extends Backbone.Collection
  url: 'accounts/picklist'

class AccountPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  events:
    'change @ui.picker': 'go'
    'keydown input:text' : 'keyPressed'

  initialize: (options) =>
    @dropdownParent = if options.dropdownParent isnt undefined then options.dropdownParent else null

  onRender: =>
    @cleared = true
    @ui.picker.selectize(
      dropdownParent: @dropdownParent
      load: (query, callback) =>
        @cleared = false
        return if query?.length < 2

        picklist = new Picklist()
        picklist
          .fetch(data: name: query)
          .done((response) => callback(response))

      onDropdownClose: =>
        @trigger('dropdownClose')

      onBlur: =>
        @trigger('blur')

      render:
        option: (item, escape) ->
          "<div>#{escape(item.text)} <span class='picker-value'>#{escape(item.value)}</span></div>"
    )
    @selectize = @ui.picker[0].selectize

  go: =>
    if @ui.picker.val()
      @trigger('selected', @ui.picker.val())
    else
      @selectize.clearOptions()
      @cleared = true
      @trigger('deselected')

  keyPressed: (e) =>
    if e.keyCode is 13 and @cleared
      @selectize.clear()
      @selectize.blur()
    else if e.currentTarget?.value and e.keyCode isnt 8
      @cleared = false
    else if e.currentTarget?.value?.length < 2 and e.keyCode is 8
      @cleared = true
    true

  disable: =>
    @selectize.disable()

  enable: =>
    @selectize.enable()

  # HACK: This method does two different things. When passed no args, it'll return the selected accountId. When passed
  # an accountId, it'll look up the account name and populate the widget.  A little wonky, but it's convenient to mimic
  # the standard form element val() api.
  val: (accountId) =>
    if arguments.length is 0
      return @ui.picker.val?()
    else
      @cleared = false
      @selectize.addOption(text: '', value: accountId)
      @selectize.addItem(accountId, true)

      account = new AccountModel(id: accountId)
      account.fetch().done(=>
        label = if account.get('alias') then "#{account.get('name')}(#{account.get('alias')})" else account.get('name')
        @selectize.updateOption(accountId, {text: label, value: accountId})
        @selectize.onSearchChange(account.get('name'))
      )

  text: =>
    item = @selectize.getItem(@selectize.getValue())
    if item then item.text() else ''

module.exports = AccountPickerView
