import { Colors } from '../../colors'
import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseTransitions from '../baseTransitions'

const palette = {
  ...basePalette,
  primary: {
    contrastText: Colors.N0,
    main: Colors.C30,
    dark: Colors.C50,
    light: Colors.N30
  },
  text: {
    ...basePalette.text,
    link: Colors.C50
  },
  calendar: {
    GMAIL: {
      primary: Colors.P50,
      secondary: Colors.P05
    },
    EXCHANGE: {
      primary: Colors.V50,
      secondary: Colors.V05
    },
    SALESFORCE: {
      primary: Colors.C50,
      secondary: Colors.C05
    },
    CUSTOM: {
      primary: Colors.V50,
      secondary: Colors.V05
    }
  }
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const zIndex = {
  appBar: 1200,
  drawer: 1100
}

const shadow = {
  default: '0px 2px 9px rgba(0, 0, 0, 0.03)'
}

const theme = {
  palette,
  typography,
  transitions,
  zIndex,
  shadow,
  spacing: 6
}

export default theme
