import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function DeleteIcon({ size = 24, color = Colors.N100, title = 'trash-can' }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <title>{title}</title>
      <path d="M8 5V4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V5" stroke={color} strokeLinejoin="round" />
      <path
        d="M4.5 4.5H19.5V21C19.5 21.2761 19.2761 21.5 19 21.5H5C4.72386 21.5 4.5 21.2761 4.5 21V4.5Z"
        stroke={color}
      />
      <path d="M3.5 4.5H20.5" stroke={color} strokeLinecap="round" />
      <path d="M12 9.5V17.5" stroke={color} strokeLinecap="round" />
      <path d="M16 9.5V17.5" stroke={color} strokeLinecap="round" />
      <path d="M8 9.5V17.5" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default DeleteIcon
