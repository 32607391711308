import React, { useCallback, useMemo, useState } from 'react'
import {CallScript, MainProps} from '../../types'
import {useCallScripts} from '../../api'
import {initCallScripts, CALL_SCRIPTS_STR, NO_RESULT_STATE, callScriptsColumns} from './const'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import CallScriptModal from './CallScriptModal'
import ConfirmCallScriptDeleteModal from './ConfirmCallScriptDeleteModal'

export default function CallScriptsTab({ accountId }: MainProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [callScript, setCallScript] = useState<CallScript>(initCallScripts)
  const [payload, , loading, refetch] = useCallScripts({meta:{accountId: accountId.toString()}})

  const handleActionMenu = useCallback((action: string, script: CallScript) => {
    setCallScript({ ...script, entityIdsAdd: [], entityIdsDelete: [] })
    if (action === CALL_SCRIPTS_STR.delete) {
      setIsDeleteModalOpen(true)
    } else if (action === CALL_SCRIPTS_STR.edit) {
      setIsModalOpen(true)
    }
  }, [])

  const memoPayload = useMemo(() => {
    return payload?.map((script: CallScript) => ({
      ...script,
      menu: (
        <>
          <RdnaMenu options={[{ text: CALL_SCRIPTS_STR.edit }, { text: CALL_SCRIPTS_STR.delete }]} onSelect={item => handleActionMenu(item, script)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  async function closeModals(isFetch: boolean) {
    setIsModalOpen(false)
    setIsDeleteModalOpen(false)
    if (isFetch) {
      await refetch()
      setCallScript(initCallScripts)
    }
  }

  return (
    <>
      <SectionHeader
        descriptionText={CALL_SCRIPTS_STR.header}
        rightColumn={ <RdnaButton
          style={{ width: 'fit-content' }}
          disabled={!!loading}
          text={'Add Call Script'}
          name="add-call-script"
          onClick={() => {
            setCallScript(initCallScripts)
            setIsModalOpen(true)
          }}
        />}
      />
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={callScriptsColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        isFetching={!!loading}
        paginationSize={10}>
      </RdnaSmartTable>
      <ConfirmCallScriptDeleteModal
        open={isDeleteModalOpen}
        onClose={closeModals}
        id={callScript.id}
      />
      <CallScriptModal
        open={isModalOpen}
        accountId={accountId}
        setCallScript={setCallScript}
        callScript={callScript}
        onClose={closeModals}
      />
    </>
  )
}
