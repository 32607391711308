import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function SettingsIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path d="M10 13.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" stroke={color} />
      <path
        clipRule="evenodd"
        d="M17.16 12.237l.074-.234 2.104-.789a.25.25 0 00.162-.234V9.02a.25.25 0 00-.162-.234l-2.104-.79-.073-.233a7.445 7.445 0 00-.516-1.244l-.114-.217.93-2.046a.25.25 0 00-.05-.28l-1.387-1.387a.25.25 0 00-.28-.05l-2.046.93-.217-.114a7.445 7.445 0 00-1.244-.516l-.234-.073-.789-2.104A.25.25 0 0010.98.5H9.02a.25.25 0 00-.234.162l-.79 2.104-.233.073a7.45 7.45 0 00-1.244.516l-.217.114-2.046-.93a.25.25 0 00-.28.05L2.589 3.976a.25.25 0 00-.05.28l.93 2.046-.114.217a7.45 7.45 0 00-.516 1.244l-.073.234-2.104.789A.25.25 0 00.5 9.02v1.96a.25.25 0 00.162.234l2.104.79.073.233c.134.43.307.846.516 1.244l.114.217-.93 2.046a.25.25 0 00.05.28l1.387 1.387a.25.25 0 00.28.05l2.046-.93.217.114c.398.209.814.382 1.244.516l.234.073.789 2.104a.25.25 0 00.234.162h1.96a.25.25 0 00.234-.162l.79-2.104.233-.073a7.44 7.44 0 001.244-.516l.217-.114 2.046.93a.25.25 0 00.28-.05l1.387-1.387a.25.25 0 00.05-.28l-.93-2.046.114-.217a7.44 7.44 0 00.516-1.244z"
        stroke={color}
      />
    </svg>
  )
}

export default SettingsIcon
