Handlebars = require('handlebars/runtime').default
require('helpers/url-helper')
BaseView = require('base/view')
BaseModel = require('base/model')

class UserQueueSkillModel extends BaseModel
  urlRoot: 'callFlows/usersQueuesSkills'

  parse: (response) =>
    response.users = _.sortBy(response.users, 'displayName')
    for user in response.users
      user.type = 'user'
      user.value = user.id
      user.text = user.displayName
    response.queues = _.sortBy(response.queues, 'name')
    for queue in response.queues
      queue.queueType = queue.type
      queue.type = 'group'
      queue.value = queue.id
      queue.text = queue.name
    response.skills = _.sortBy(response.skills, 'name')
    for skill in response.skills
      skill.type = 'skill'
      skill.value = skill.id
      skill.text = skill.name

    response

class UsersQueuesSkillsView extends BaseView

  template: require('./template')

  ui:
    dialTypePicker: '.ugs-type'
    dialIdPicker: '.ugs-id'

  initialize: (options) ->
    { @step, @accountId, @readOnly, @skillsEnabled } = options
    @picklist = new UserQueueSkillModel()

  onRender: =>
    @picklist
      .fetch(data: accountId: @accountId)
      .done(=>
        return if @isDestroyed()
        @initTypeSelectize()
        @updateTypeSelectorState(@step.dialType())
      )

  updateTypeSelectorState: (type) =>
    switch type
      when 'user'
        placeholder = 'Start typing a user'
        options = @picklist.get('users')
      when 'group'
        placeholder = 'Start typing a call queue'
        options = @picklist.get('queues')
      when 'skill'
        placeholder = 'Start typing a skill'
        options = @picklist.get('skills')
      else
        placeholder = ' '
        options = []

    if @ui.dialIdPicker[0]?.selectize
      @updateIdSelectize(type, options, placeholder)
    else
      @initIdSelectize(type, options, placeholder)

  initTypeSelectize: =>
    options = [{text: 'User', value: 'user'}, {text: 'Call Queue', value: 'group'},
      {text: 'Skill', value: 'skill'}, {text: 'Record Owner', value: 'recordOwner'}]
    options.splice(2, 1) unless @skillsEnabled
    @ui.dialTypePicker.selectize(
      placeholder: 'Dial type'
      preload: true
      options: options
      items: if @step.dialType() and @step.dialType() isnt 'multiNumber' then [@step.dialType()] else []
      onChange: (type) =>
        @updateTypeSelectorState(type)
        @step.trigger('ugsTypeChanged', type)
    )
    @ui.dialTypePicker[0].selectize.disable() if @readOnly


  initIdSelectize: (type, options, placeholder) =>
    currentOptions = JSON.parse(JSON.stringify(options))
    if @step.dialId() and @step.dialId().indexOf('{{') > -1 and @step.dialId().indexOf('}}') > -1
      currentOptions.push(
        value: @step.dialId()
        text: @step.dialId()
        type: 'user'
      )

    @ui.dialIdPicker.selectize(
      placeholder: placeholder
      maxItems: 1
      preload: true
      options: currentOptions
      items: if @step.dialId() then [@step.dialId()] else []
      score: (search) =>
        score = @ui.dialIdPicker[0].selectize.getScoreFunction(search)
        if search.indexOf('{{') > -1 and search.indexOf('}}') > -1 and @step.dialType() is 'user'
          @ui.dialIdPicker[0].selectize.addOption([value: search, text: search, type: 'user'])
        (item) ->
          score(item)
      render:
        item: (item, escape) =>
          image = @imageUserQueueSkill(escape(item.type), escape(item.text))
          "<div data-type=\"{{escape(item.type)}}\">#{escape(item.text)}#{image}</div>"
        option: (item, escape) =>
          image = @imageUserQueueSkill(escape(item.type), escape(item.text))
          "<div>#{escape(item.text)} #{image} <span class='picker-value'>#{escape(item.value)}</span></div>"
      onChange: (value) =>
        if @ui.dialIdPicker[0]?.selectize
          selected = @ui.dialIdPicker[0].selectize.options[value]
          @step.trigger('ugsIdChanged', selected)
    )
    @ui.dialIdPicker[0].selectize.disable() if @readOnly or not type or type is 'multiNumber' or type is 'recordOwner'
    if @step.dialId() and @ui.dialIdPicker[0]?.selectize
      selected = @ui.dialIdPicker[0].selectize.options[@step.dialId()]
      @step.trigger('ugsIdChanged', selected)

  updateIdSelectize: (type, options, placeholder) =>
    selectize = @ui.dialIdPicker[0]?.selectize
    return unless selectize

    if type and type isnt 'recordOwner'
      selectize.enable()
    else
      selectize.disable()

    selectize.settings.placeholder = placeholder
    selectize.updatePlaceholder()
    selectize.clear()
    selectize.clearOptions()
    selectize.addOption(options)

  imageUserQueueSkill: (type, text) ->
    if type isnt 'skill'
      type = 'queue' if type is 'group'
      urlSrc = Handlebars.helpers.imageUrl("call-flow/#{type}.png")
      "<img src='#{urlSrc}' title='#{text}' class='avatar' />"
    else
      ''

module.exports = UsersQueuesSkillsView
