import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function FlowChartIcon({ size = 24, color = Colors.N100, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="flow-chart-icon">
      <path
        d="M5.744 10.939h8.167M2.245 12.244a1.306 1.306 0 100-2.61 1.306 1.306 0 000 2.61zM17.522 12.244a1.306 1.306 0 100-2.61 1.306 1.306 0 000 2.61zM17.522 3.55a1.306 1.306 0 100-2.611 1.306 1.306 0 000 2.61zM17.522 20.939a1.306 1.306 0 100-2.611 1.306 1.306 0 000 2.61zM2.3 7.078c0-1.334.556-2.528 1.417-3.417.862-.889 2.084-1.417 3.417-1.417h6.667M13.829 19.633H7.162c-1.333 0-2.528-.555-3.417-1.416-.889-.89-1.444-2.084-1.444-3.417M12.549 3.494l1.278-1.25M13.827 2.244L12.549.967M12.633 12.189l1.278-1.25M13.91 10.939l-1.277-1.25M12.549 20.911l1.278-1.278M13.827 19.633l-1.278-1.25"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default FlowChartIcon
