import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function CallVolumeByDayIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="call-volume-by-day-icon">
      <g clipPath="url(#clip0_9043_75687)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9611 52.0964C25.1143 52.0453 25.2175 51.902 25.2175
        51.7406V43.7439C25.2175 43.5718 25.1004 43.4218 24.9335 43.3801L20.4669 42.2634C20.3764 42.2408 20.2991
        42.1875 20.2513 42.1074C19.8405 41.4191 17.8806 37.791 17.8806 31.2228C17.8806 24.6547 19.8405 21.0265
        20.2513 20.3383C20.2991 20.2582 20.3764 20.2049 20.4669 20.1822L24.9335 19.0656C25.1004 19.0239 25.2175
        18.8739 25.2175 18.7018V10.7051C25.2175 10.5437 25.1143 10.4004 24.9611 10.3493L18.082 8.05629C17.957
        8.01463 17.8252 8.0368 17.7291 8.12685C16.5828 9.20056 9.3208 16.5807 9.3208 31.2228C9.3208 45.865 16.5828
        53.2451 17.7291 54.3188C17.8252 54.4089 17.957 54.4311 18.082 54.3894L24.9611 52.0964Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M26.8479 35.1983L34.4566 31.2285L42.6088 32.552L50.7609 21.3043"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9043_75687">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
