StepModel = require('../../model')

class GreetingStepModel extends StepModel

  defaults:
    args: {}
    type: 'Greeting'
    info: 'Select a sound file the caller will hear'
    fixedPlaceholders: [ '_' ]
    leaf: false

  nextStep: ->
    @get('nextStep')

  errors: ->
    @audioErrors(['audio'])

module.exports = GreetingStepModel

