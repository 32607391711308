BaseView = require('base/view')
BaseModel = require('base/model')
AccountModel = require('features/accounts/model')

class FilePickerView extends BaseView
  template: require('./template')
  className: 'file-picker'

  ui:
    audio: '.audio'
    file: '.file'
    playAudio: '.play-audio'
    stopAudio: '.stop-audio'
    deleteFile: '.delete-file'

  bindings:
    '.audio': 'attr:{src:src}'
    '.file' : 'attr:{accept:accept}'
    '.play-audio': 'disabled:disabled,toggle:all(src,not(playing)),classes:{"disabled":disabled}'
    '.stop-audio': 'disabled:disabled,toggle:all(src,playing),classes:{"disabled":disabled}'
    '.delete-file': 'disabled:disabled,toggle:src,classes:{"disabled":disabled}'

  events:
    'click @ui.playAudio' : 'playAudio'
    'click @ui.stopAudio' : 'stopAudio'
    'click @ui.deleteFile' : 'deleteFile'
    'change @ui.file' : 'changeFile'

  initialize: (options) =>
    @viewModel = new BaseModel(
      src: options.src
      accept: options.accept
      playing: false
      disabled: false
    )

  onRender: =>
    @ui.file.filestyle(
      buttonBefore: true
      buttonText: '&nbsp; Choose file'
    )
    filename = @viewModel.get('src')?.split('/').pop()
    @$('.bootstrap-filestyle').find(':text').val(filename) if @viewModel.get('src')
    @ui.file.on()
    @ui.audio.on('ended pause', => @stopAudio())
    @saveAudio =
      src: null
      fileName: null

  playAudio: =>
    @stopPlayers()
    @ui.audio[0].play()
    @viewModel.set(playing: true)

  stopAudio: =>
    @ui.audio[0].pause()
    @ui.audio[0].currentTime = 0
    @viewModel.set(playing: false)

  deleteFile: =>
    @viewModel.set('src', null)
    @ui.file[0].value = null
    @$('.bootstrap-filestyle').find(':text').val('')
    @trigger('deleteAudio')

  stopPlayers: ->
    audios = $('audio')
    for audio in audios
      audio.pause()
      audio.currentTime = 0

  changeFile: =>
    @stopAudio()
    if (val = @val()) and (src = URL.createObjectURL(val))
      @viewModel.set(src: src)
      @saveAudio =
        src: src
        fileName: @val()?.name
    else
      @viewModel.set(src: @saveAudio?.src)
      @ui.audio.src = @saveAudio?.src
      @$('.bootstrap-filestyle').find(':text').val(@saveAudio.fileName) if @saveAudio?.fileName

  disable: =>
    @ui.file.filestyle('disabled', true)
    @viewModel.set(disabled: true)

  enable: =>
    @ui.file.filestyle('disabled', false)
    @viewModel.set(disabled: false)

  val: (url, fileName) =>
    if arguments.length is 0
      return @ui.file[0]?.files[0]
    else
      @viewModel.set(src: url)
      @ui.audio.src = url
      @$('.bootstrap-filestyle').find(':text').val(fileName) if fileName


module.exports = FilePickerView
