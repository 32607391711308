import { useFormikContext } from 'formik'
import React from 'react'
import { Box, Grid } from '@mui/material'
import { flatten } from 'lodash-es'
import {
  AutoCompleteInputData,
  FormSelectOption,
  HandleBlur,
  InputChangeEvent,
  InputField
} from '../../types/form-inputs'
import RdnaChip from '../RdnaChip'

type ChipData = {
  key: string
  value: string | AutoCompleteInputData
  label: string
}

type ChipProps = {
  inputs: InputField[]
  onChange: InputChangeEvent
  onFieldTouched: HandleBlur
}

const SelectedChips = ({ inputs, onChange, onFieldTouched }: ChipProps) => {
  const { values } = useFormikContext<any>()

  const chipInputs: ChipData[] = flatten(
    inputs
      .filter((input: InputField) => {
        return input.multipleValues && values[input.name] && values[input.name] !== ''
      })
      .map((filteredInput: InputField) => {
        if (typeof values[filteredInput.name] === 'string' && !filteredInput.multipleValues) {
          const chipData: ChipData = {
            label: `${filteredInput.name}: ${values[filteredInput.name]}`,
            key: filteredInput.name,
            value: values[filteredInput.name]
          }
          return chipData
        } else {
          let inputValues = values[filteredInput.name]
          if (filteredInput.multipleValues && typeof inputValues === 'string') {
            inputValues = inputValues.split(',')
          }

          return inputValues.map((value: string | AutoCompleteInputData) => {
            let labelValue = (value as AutoCompleteInputData).label
            if (filteredInput.options) {
              for (const option of filteredInput.options as FormSelectOption[]) {
                if (option.value === value) {
                  labelValue = option.label
                }
              }
            }

            const chipLabel =
              filteredInput.chipLabel !== null ? `${filteredInput.chipLabel || filteredInput.label}:` : ''
            const chipData: ChipData = {
              label: `${chipLabel} ${labelValue}`,
              key: filteredInput.name,
              value: value
            }
            return chipData
          })
        }
      })
  )

  const handleDelete = (chipToDelete: ChipData) => () => {
    const newValue = values[chipToDelete.key].filter((value: string) => value !== chipToDelete.value)
    onFieldTouched(chipToDelete.key, true)
    onChange(chipToDelete.key, newValue)
  }

  return (
    <Grid item xs={12}>
      {chipInputs.map(chipData => {
        return (
          <Box key={chipData.label} mr={1} mb={1} display="inline-block" position="relative">
            <RdnaChip label={chipData.label} onDelete={handleDelete(chipData)} />
          </Box>
        )
      })}
    </Grid>
  )
}

export default SelectedChips
