var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"clear\" ></div>\n      <div class=\"value\">\n        <label>Apex variable name: </label> <input type=\"text\" data-key=\"calloutInputs\" class=\"input calloutInputs\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"input") || (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"input","hash":{},"data":data,"loc":{"start":{"line":16,"column":123},"end":{"line":16,"column":132}}}) : helper)))
    + "\"/>\n        <label>ringDNA variable or value: </label> <input type=\"text\" data-key=\"calloutValues\" class=\"input calloutValues\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":17,"column":130},"end":{"line":17,"column":139}}}) : helper)))
    + "\"/>\n        <a class=\"addRemoveInput\" data-action=\"remove-callout-input\" title=\"Remove Input\"><span class=\"glyphicon glyphicon-minus-sign\"></span></a>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block callout\">\n  <div class=\"sub-subtitle\">Path</div>\n  <div>What is the path to the Apex REST method?</div>\n  <input type=\"text\" class=\"input\" data-key=\"path\" maxlength=\"80\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\"/>\n  <div class=\"sub-subtitle\">Apex Inputs</div>\n  <div>What values should be provided to the Apex method?</div>\n  <div>\n    <div class=\"value\">\n      <label>Apex variable name: </label> <input type=\"text\" data-key=\"calloutInputs\" class=\"input calloutInputs\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstCallout") : depth0)) != null ? lookupProperty(stack1,"input") : stack1), depth0))
    + "\"/>\n      <label>ringDNA variable or value: </label> <input type=\"text\" data-key=\"calloutValues\" class=\"input calloutValues\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstCallout") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\"/>\n      <a class=\"addRemoveInput\" data-action=\"add-callout-input\" title=\"Add Input\"><span class=\"glyphicon glyphicon-plus-sign\"></span></a>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"calloutList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"sub-subtitle\">Save Output</div>\n  <div>What key should be used to save the callout output?</div>\n  <input type=\"text\" class=\"input\" data-key=\"storageKey\" maxlength=\"40\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"storageKey") : stack1), depth0))
    + "\"/>\n</div>";
},"useData":true});