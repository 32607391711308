StepModel = require('../../model')

class BrunchStepModel extends StepModel

  defaults:
    args: {}
    type: 'Branch'
    info: 'Conditionally execute a child step'
    fixedPlaceholders: [ 'No Value', 'Any Value' ]
    leaf: false

  placeholders: ->
    childCount = (key for key of @args()).length
    childCount = childCount - 2 if childCount > 0
    value = "Value #{childCount + 1}"
    keys = (key for key of @args())
    if value in keys
      arrayValues = (value for value in keys when value.includes('Value '))
      maxVal = (Number(value.slice(6)) for value in arrayValues).reduce((a, b) -> Math.max(a, b))
      [ "Value #{maxVal + 1}" ]
    else [ value ]

  path: ->
    @args()['path']

  orderChildren: (children) ->
    result = []
    keys = (key for key of @args())
    for k in keys.sort()
      return if k == 'No Value' or k == 'Any Value'
      child = @findChildById(children, parseInt(@args()[k]))
      result.push(child) if child
    result.push(@findChildById(children, parseInt(@args()['No Value'])))
    result.push(@findChildById(children, parseInt(@args()['Any Value'])))
    result

module.exports = BrunchStepModel