import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { SchedulingPage } from '../types'
import { copyToClipboard } from '@ringdna/common/src/utils/copyToClipboard'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaMenu, { MenuOption } from '@ringdna/common/src/components/RdnaMenu'
import SchedulingPageCard from './MeetingCard'
import DeleteSchedulingPageModal from './DeleteSchedulingPageModal'

type Props = {
  schedulingPages: SchedulingPage[]
  pageIndex: number
  onEditSchedulingPage: (schedulingPage: SchedulingPage) => void
  refetchSchedulingPages: () => void
}

export default function EditSchedulingCard({
  pageIndex,
  schedulingPages,
  onEditSchedulingPage,
  refetchSchedulingPages
}: Props) {
  const schedulingPage = schedulingPages[pageIndex]
  const calendarLink = schedulingPage.eventLinkName
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const onEdit = () => {
    onEditSchedulingPage(schedulingPage)
  }

  const onDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    refetchSchedulingPages()
  }

  const onCopy = () => {
    copyToClipboard(calendarLink)
    toastr.success('Link copied to clipboard')
  }

  const menuOptions: MenuOption[] = [{ text: 'Edit' }, { text: 'Delete' }, { text: 'Copy Link' }]

  const onMenuSelect = (option: string) => {
    switch (option) {
      case 'Edit':
        onEdit()
        break
      case 'Delete':
        onDelete()
        break
      case 'Copy Link':
        onCopy()
        break
    }
  }

  const schedulingPageString = useMemo(
    () => schedulingPage.type.match(/[A-Z][a-z]+/g)?.join('-'),
    [schedulingPage.type]
  )

  return (
    <SchedulingPageCard className="edit-scheduling-page-card">
      <DeleteSchedulingPageModal
        schedulingPage={schedulingPage}
        isModalOpen={isDeleteModalOpen}
        closeModal={onCloseDeleteModal}
      />
      <RdnaMenuContainer>
        <RdnaMenu options={menuOptions} onSelect={onMenuSelect} />
      </RdnaMenuContainer>
      <StyledSchedulingPageCardHeader variant="body3" component="p" className="scheduling-page-card-heading">
        {schedulingPage.name}
      </StyledSchedulingPageCardHeader>
      <RdnaText component="p">{`${schedulingPageString}, ${schedulingPage.duration} min`}</RdnaText>
      <StyledSchedulingPageLink href={calendarLink} target="_blank" rel="noopener noreferrer">
        {calendarLink}
      </StyledSchedulingPageLink>
    </SchedulingPageCard>
  )
}

const RdnaMenuContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing * 3}px;
  right: ${props => props.theme.spacing * 1.5}px;
`

const StyledSchedulingPageCardHeader = styled(RdnaText)`
  padding-top: ${props => props.theme.spacing * 2}px;
`

const StyledSchedulingPageLink = styled(RdnaText)`
  display: block;
  overflow: hidden;
  height: ${props => props.theme.spacing * 6}px;
`
