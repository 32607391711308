BaseModel             = require('base/model')
StepCollection        = require('../call-flow-steps/collection')
PlaceholderStepModel  = require('../call-flow-steps/step-models/Placeholder/model')

class CallFlowModel extends BaseModel
  urlRoot: 'callFlows'

  defaults: ->
    abandonedComments: null
    abandonedSubject: null
    createdDate: null
    description: ''
    finalStepDetails: false
    name: 'New Call Flow'
    paused: false
    statuses: ['Active', 'Paused']
    updatedBy: null
    updatedDate: null
    valid: true
    steps: new StepCollection(new PlaceholderStepModel(parentId: '#', id: 0))

  errors: ->
    result = {}
    hasErrors = false
    for step in @get('steps').models
      err = step.errors()
      if err?.length
        hasErrors = true
        result[step.id] = err
    @set(valid: !hasErrors)
    result

  steps: =>
    new StepCollection(@get('flowSteps'), {parse: true, callflowId: @.id}) if not @isNew()

  copyCallFlow: ->
    App.api.post(
      path    : "callFlows/copy/#{@id}"
      success : (response) =>
        App.router.navigate("#call-flows/#{response}", {trigger: true})
        toastr.info('Call Flow copied.')
    )

  saveCallFlow: (saveButton, addNew, typeNewStep, callback) ->
    if typeNewStep
      newModel = require("../call-flow-steps/step-models/#{typeNewStep}/model")
      newStep = new newModel(name: '')
      data = Object.assign({}, @.toJSON(), newStep.toJSON())
    else
      data = @.toJSON()

    if addNew and @get('account')
      data.accountId = @get('account').id

    @
    .persist(data: data, ladda: saveButton)
    .done?((response) =>
      if addNew
        App.router.navigate("#call-flows/#{@.id}", {trigger: true})
      toastr.info('Call Flow saved.')
      callback(response) if callback
    )

  toJSON: =>
    fields = {}
    apiAttrs = [ 'id', 'name', 'entities', 'paused', 'description', 'finalStepDetails', 'valid', 'connectUser', 'abandonedSubject', 'abandonedComments', 'postCallFlow' ]
    fields["callflow.#{key}"] = @get(key) for key in apiAttrs
    fields

  validate: (attrs) ->
    if attrs.name.length < 1
      return 'Please enter a Name.'
    false

module.exports = CallFlowModel
