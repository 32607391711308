import React, { createContext, useReducer } from 'react'
import { InitIntegrationsContext, ProviderParams } from '../model'
import { initIntegrationsContext, initIntegrationsState } from '../constants'
import integrationsReducer from './integrationsReducer'

const IntegrationsContext = createContext<InitIntegrationsContext>(initIntegrationsContext)

function IntegrationsProvider({ children }: ProviderParams) {
  const [integrationsState, dispatch] = useReducer(integrationsReducer, initIntegrationsState)

  return <IntegrationsContext.Provider value={{ integrationsState, dispatch }}>{children}</IntegrationsContext.Provider>
}

export { IntegrationsContext, IntegrationsProvider }
