import React, { useEffect, useState } from 'react'

export const typingSpeed = 350
export default function TypingIndicator() {
  const [numDots, setNumDots] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setNumDots(prev => (prev + 1) % 3)
    }, typingSpeed)
    return () => clearInterval(timer)
  }, [])

  const dotsString = Array.from({ length: numDots + 1 }, () => '.').join('')
  return <> is typing{dotsString}</>
}
