var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span>&times;</span></button>\n  <h4 class=\"modal-title\"></h4>\n</div>\n<div class=\"modal-body\">\n  <div>\n    <span>Create a recording or you can <a class=\"upload-voicemail\" id=\"tab1\">upload</a> a file.</span>\n  </div>\n  <br/>\n  <div class=\"grid\">\n    <table class=\"voicemail-recording-table\">\n      <tbody>\n        <tr>\n          <td vertical-align=\"middle\" class=\"col-md-2\">\n            <button type=\"button\" class=\"btn btn-success btn-xs play\" disabled>\n              <span class=\"glyphicon glyphicon-play\"></span>\n            </button>\n            <button type=\"button\" class=\"btn btn-danger btn-xs stop\">\n              <span class=\"glyphicon glyphicon-stop\"></span>\n            </button>\n            <audio id=\"recording-url\" preload=\"auto\">\n              <p>Your browser does not support the <code>audio</code> element.</p>\n            </audio>\n          </td>\n          <td>\n            <div class=\"voicemail-label\">\n              <input type=\"text\" class=\"form-control col-md-12 voicemail-name\" id=\"voicemailLabel\" placeholder=\"Label\" autofocus=\"autofocus\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":27,"column":148},"end":{"line":27,"column":156}}}) : helper)))
    + "\"/>\n            </div>\n          </td>\n          <td vertical-align=\"middle\" class=\"col-md-2\">\n            <button type=\"button\" class=\"btn btn-danger btn-xs record-voicemail\">\n              <span class=\"glyphicon glyphicon-record\"></span> Record\n            </button>\n            <button type=\"button\" class=\"btn btn-danger btn-xs stop-recording-voicemail\">\n              <span class=\"glyphicon glyphicon-stop\"></span> Stop\n            </button>\n          </td>\n        </tr>\n      </tbody>\n    </table>\n    <div class=\"clearfix\"></div>\n    <div class=\"form-group out-of-office-section\">\n      <label class=\"col-md-1\">\n        <input type=\"checkbox\" class=\"out-of-office\">\n      </label>\n      <span class=\"col-md-11\">Out of Office Greeting</span>\n    </div>\n  </div>\n</div>\n\n<div class=\"modal-footer\">\n  <p class=\"pull-left notification\"></p>\n  <button class=\"ladda-button btn btn-success save-record\" data-style=\"expand-left\">Save</button>\n</div>";
},"useData":true});