BaseView  = require('base/view')
BaseModel = require('base/model')

class VoicemailRecordingModal extends BaseView
  template: require('./record-step-template')

  ui:
    go           : 'button.save-record'
    recordingUrl : '#recording-url'
    label        : 'input.voicemail-name'
    outOfOffice  : 'input.out-of-office'

  bindings:
    '.notification'             : 'text:message'
    '.modal-title'              : 'text:modalTitle'
    '.record-voicemail'         : 'toggle:not(recording),disabled:not(allowRecord),attr:{title:recordVmError}'
    '.stop-recording-voicemail' : 'toggle:recording'
    '.play'                     : 'enabled:haveRecording,toggle:not(playing)'
    '.stop'                     : 'toggle:playing'
    '.out-of-office-section'    : 'toggle:showOutOfOfficeCheckbox'
    '.out-of-office'            : 'checked:outOfOfficeValue'

  computeds:
    modalTitle:
      get: -> @modalTitle
    allowRecord:
      deps: ['playing']
      get: (playing) ->
        if @audioRecorder
          not playing
        else
          false
    recordVmError:
      get: ->
        if @audioRecorder
          null
        else
          'This browser is unable to record your voice!'
    showOutOfOfficeCheckbox:
      get: ->
        @outOfOfficeCheckbox
    outOfOfficeValue:
      deps: ['outOfOffice']
      get: (outOfOffice) ->
        if outOfOffice then outOfOffice else false

  events:
    'click  @ui.go'                   : 'go'
    'click .record-voicemail'         : 'record'
    'click .stop-recording-voicemail' : 'stopRecording'
    'click .play'                     : 'play'
    'click .stop'                     : 'stopPlaying'

  initialize: (options) ->
    { @accountId, @modalTitle, @userId, @teamId, @outOfOfficeCheckbox } = options
    @reloadOnClose = false
    @viewModel = new Backbone.Model(
      audio         : null
      haveRecording : @model.has('url')
      message       : ''
      playing       : false
      recording     : false
    )

    navigator.mediaDevices
      .getUserMedia(audio: true)
      .then((stream) =>
        @audioContext = new AudioContext()
        audioSource = @audioContext.createMediaStreamSource(stream)
        @audioRecorder = new Recorder(audioSource)
        @applyBindings() # TODO: We shouldn't need to do this. Epoxy misconfiguration?
      )
      .catch((error) ->
        console.info('Audio initialization error', error)
        toastr.warning('Sorry, this browser is unable to record your voice.')
      )

  onDestroy: =>
    @audioContext?.close()

  onShow: ->
    @ui.recordingUrl[0].onpause = =>
      @viewModel.set(playing: false)
    @ui.recordingUrl[0].src = @model.get('url') if @model.has('url')

  go: =>
    return unless @validate()
    @model
      .upload(
        data:
          file        : @viewModel.get('audio')
          label       : @ui.label.val()
          accountId   : @accountId
          userId      : @userId
          teamId      : @teamId
          outOfOffice : @ui.outOfOffice.prop('checked')
        ladda: @ui.go[0]
      )
      .done((response) =>
        response.name = response.label unless response.name
        response.accountId = @accountId
        response.deletable = true
        @collection.set(response, {remove: false})
        toastr.info("#{@modalTitle} uploaded.")
        @model.trigger('saveVoicemail')
      )

  record: =>
    @viewModel.set(
      recording: true
      haveRecording: false
      message: 'Recording...'
    )
    @audioRecorder?.clear()
    @audioRecorder?.record()

  stopRecording: =>
    @audioRecorder.stop()
    @audioRecorder.exportWAV((audio) =>
      @viewModel.set(audio: audio)
      @ui.recordingUrl[0].src = URL.createObjectURL(audio)
    )
    @viewModel.set({
      recording: false
      haveRecording: true
      message: 'Voicemail recorded. Click Play to listen to it.'
    })

  play: =>
    @viewModel.set('playing', true)
    @ui.recordingUrl[0].play()

  stopPlaying: =>
    @ui.recordingUrl[0].pause()
    @ui.recordingUrl[0].currentTime = 0
    @viewModel.set('playing', false)

  validate: =>
    if not @ui.label.val()
      toastr.error('Please enter a label.')
      return false

    if not @viewModel.get('audio') and @model.isNew()
      toastr.error('Please create a recording.')
      return false

    true


module.exports = VoicemailRecordingModal
