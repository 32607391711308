BaseView = require('base/view')
BaseCollection = require('base/collection')
BaseModel = require('base/model')
CallQueueCollection = require('widgets/call-queues-picker/user-call-queues-collection')
CallQueuesPicker  = require('widgets/call-queues-picker/view')

class JoinedCallQueueCollection extends BaseCollection
  url: -> 'userQueues/batch'
  setParams: (options) ->
    { @userId } = options
  toJSON: =>
    fields = {}
    fields['userId'] = @userId
    @each((model, index) =>
      fields["queueIds[#{index}]"] = model.get('id')
    )
    fields

class UserCallQueueModal extends BaseView

  template: require('./template')
  className: 'user-call-queue-modal'

  regions:
    userCallQueuePicker : 'span.call-queue-picker'

  ui:
    modal  : '#user-call-queue-modal'
    save   : 'button.save-user-call-queues'

  events:
    'click @ui.save': 'save'

  initialize: (options) =>
    { @user, @userCallQueueCollection } = options
    @joinedCallQueueCollection = new JoinedCallQueueCollection()
    for model in @userCallQueueCollection.models
      @joinedCallQueueCollection.add(new BaseModel(model.get('queue'))) if model.get('type') is 'Regular'
    @joinedCallQueueCollection.setParams(userId: @user.id)
    @callQueueCollection = new CallQueueCollection()

  onRender: =>
    @callQueueCollection
      .fetch(
        data:
          userId : @user.id
      )
      .done( =>
        return unless @getRegion('userCallQueuePicker')
        @userCallQueuePicker = new CallQueuesPicker(
          list: @callQueueCollection
          placeholder: null
          addedCallQueues: @joinedCallQueueCollection
        )

        @listenTo(@userCallQueuePicker, 'select', (value) =>
          model = @callQueueCollection.findWhere(id: parseInt(value))
          @joinedCallQueueCollection.add(model) if model
        )
        @listenTo(@userCallQueuePicker, 'deselect', (value) =>
          @joinedCallQueueCollection.remove(parseInt(value))
        )
        @showChildView('userCallQueuePicker', @userCallQueuePicker)
      )

  save: =>
    @joinedCallQueueCollection
      .persist(ladda: @ui.save[0])
      .done((response) =>
        @userCallQueueCollection.reset(response)
        @ui.modal.modal('hide')
      )

module.exports = UserCallQueueModal
