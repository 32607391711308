import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ratio = 16 / 20

function RecordsIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size * ratio} viewBox="0 0 20 16" fill="none">
      <path
        d="M8.404 1H2.5A1.5 1.5 0 001 2.5v11A1.5 1.5 0 002.5 15h15a1.5 1.5 0 001.5-1.5V6.03a1.5 1.5 0 00-1.5-1.5h-4.904a1.5 1.5 0 01-1.19-.586L9.594 1.586A1.5 1.5 0 008.404 1z"
        stroke={color}
      />
    </svg>
  )
}

export default RecordsIcon
