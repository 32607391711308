import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function XmarkIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 8 8" fill="none">
      <path d="M5.819 2.182L2.182 5.818M5.818 5.818L2.182 2.182" stroke={color} strokeWidth={2} strokeLinecap="round" />
    </svg>
  )
}

export default XmarkIcon
