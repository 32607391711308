import React, { useCallback } from 'react'
import { TextField, CircularProgress, PopperProps, Popper } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import styled from 'styled-components'
import RdnaChip from '../../RdnaChip'
import { Box } from '@mui/material'
import { DropdownArrowIcon } from '../RdnaSelect'

export interface RdnaAutocompleteProps<T, Multiple extends boolean | undefined, FreeSolo extends boolean | undefined>
  extends Omit<AutocompleteProps<T, Multiple, true, FreeSolo>, 'onInputChange' | 'renderInput'> {
  label?: React.ReactNode
  inputError?: boolean
  inputHelperText?: React.ReactNode
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  optionHeight?: number
  startAdornment?: React.ReactNode
  showOnlyMultipleCount?: boolean
  showChips?: boolean
  placeholder?: string
  onChipRemove?: (value: T) => void
}

const RdnaAutocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  id,
  inputError,
  inputHelperText,
  onInputChange,
  label,
  loading,
  placeholder,
  startAdornment,
  optionHeight = 30,
  multiple,
  showOnlyMultipleCount = false,
  showChips = false,
  onChipRemove,
  value,
  onChange,
  ...rest
}: RdnaAutocompleteProps<T, Multiple, FreeSolo>) => {
  // @ts-ignore
  const valueLength = multiple && value && value?.length ? value?.length : 0
  const derivedPlaceholder = valueLength
    ? valueLength > 1 || showOnlyMultipleCount
      ? `Selected (${valueLength})`
      : // @ts-ignore
        (value as Array<string>)[0]
    : placeholder

  return (
    <>
      <Autocomplete
        id={id}
        data-testid={`autocomplete-${id}`}
        loading={loading}
        popupIcon={<DropdownArrowIcon />}
        renderInput={params => (
          <StyledTextField
            {...params}
            className={valueLength ? 'options-selected' : ''}
            data-testid={`autocomplete-${id}-input`}
            label={label}
            margin="dense"
            fullWidth
            placeholder={derivedPlaceholder}
            onChange={onInputChange}
            error={inputError}
            helperText={inputHelperText}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              startAdornment: startAdornment || params.InputProps.startAdornment,
              endAdornment: (
                // @ts-ignore
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        PopperComponent={CustomPopper}
        componentsProps={{
          paper: {
            sx: {
              marginTop: 1,
              maxHeight: `${optionHeight * MAX_ITEMS + MARGIN}px`,
              '> ul': {
                maxHeight: `${optionHeight * MAX_ITEMS + MARGIN}px`
              }
            }
          }
        }}
        renderTags={() => null}
        multiple={multiple}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {multiple &&
        showChips &&
        value &&
        Array.isArray(value) &&
        value.map(option => (
          <Box
            key={typeof option === 'string' ? option : option.value}
            mr={1}
            mt={1}
            display="inline-block"
            position="relative"
          >
            <RdnaChip
              label={typeof option === 'string' ? option : option.text}
              onDelete={() => onChipRemove && onChipRemove(option)}
            />
          </Box>
        ))}
    </>
  )
}

export default RdnaAutocomplete

const CustomPopper = (props: PopperProps) => {
  return <Popper {...props} placement="bottom-start" className={`${props.className} styled-scrollbar`} />
}

const MAX_ITEMS = 4
const MARGIN = 12

const StyledTextField = styled(TextField)`
  &.options-selected {
    input {
      &::placeholder {
        color: black;
      }
    }
`
