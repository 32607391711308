BaseView              = require('base/view')
CallFlowView          = require('./call-flow/view')
CallFlowCollection    = require('./call-flow/collection')
CallScriptView        = require('./call-script/view')
CallScriptCollection  = require('./call-script/collection')
CampaignView          = require('./campaign/view')
CampaignCollection    = require('./campaign/collection')
SmartNumberModel      = require('./model')
TagsView              = require('widgets/tags/view')
TagCollection         = require('widgets/tags/collection')
ReactComponent        = require('../../../../src/ReactComponent').default
SmartNumberMetrics    = require('../../../../src/components/smart-numbers/index').default


class SmartNumberOverviewView extends BaseView

  template: require('./template')
  className: 'smart-number-overview'

  regions:
    tags: 'span.tags'
    modal: 'div.modal-container'
    callFlow: 'div.call-flow-container'
    campaign: 'div.campaign-container'
    callScript: 'div.call-script-container'
    metrics: '.smart-number-analytics'

  ui:
    deleteNumber    : 'button.delete-number'
    undeleteNumber  : 'button.undelete-number'
    saveButton      : 'button.save-number'
    analyticsReport : '.smart-number-analytics-report'

  bindings:
    '.custom-parameter'           : 'value:customParameter'
    '.custom-parameter-container' : 'toggle:isAdvancedNumber'
    '.entity-extra-info'          : 'value:extraInfo'
    '.extra-info-container'       : 'toggle:extraInfo'
    'input.number-label'          : 'value:label'
    'textarea.number-description' : 'value:smartNumberDescription'
    '.delete-number'              : 'toggle:not(isDeleted), disabled:isSystemNumber'
    '.undelete-number'            : 'toggle:all(isDeleted, isSupport), disabled:isReleasedFromTwilio'
    '.number-sid'                 : 'toggle:isSupport'
    '.number-released-twilio'     : 'toggle:isSupport'
    '.status-container'           : 'toggle:showStatus'
    'select.number-status'        : 'value:status, disabled:all(isDeleted,showTracking)'
    '.tracking-container'         : 'toggle:showTracking'
    '.tracking-type'              : 'text:trackingType'
    '.google-tracking-id'         : 'value:googleId'
    'span.entity-id'              : 'text:entityId'
    'div.entity-id'               : 'toggle:isSupport'
    '.source span'                : 'toggle:not(hasOfflineTracking)'
    '.source div'                 : 'toggle:hasOfflineTracking'
    '.source input'               : 'value:offline'

  events:
    'click @ui.deleteNumber'    : 'deleteNumber'
    'click @ui.undeleteNumber'  : 'undeleteNumber'
    'click @ui.saveButton'      : 'save'

  computeds:
    isSupport:
      get: ->
        @user().can('smart-numbers:view:*')
    showStatus:
      deps: ['entity']
      get: (entity) ->
        if entity then true else false
    status:
      deps: ['entity']
      get: (entity) ->
        if entity
          if entity.inactive
            'Inactive'
          else
            'Active'
        else
          'Active'
      set: (value) ->
        return unless @model.get('entity')
        if value is 'Inactive'
          @model.get('entity').inactive = true
        else if value is 'Active'
          @model.get('entity').inactive = false
    showTracking:
      deps: ['entity', 'type']
      get: (entity, type) ->
        if entity and (type is 'CentralNumber' or type is 'Tracking') then true else false
    trackingType:
      deps: ['entity']
      get: (entity) ->
        if entity
          entity.type
        else
          ''
    googleId:
      deps: ['analytics']
      get: (analytics) ->
        if analytics
          analytics.trackingId
        else
          ''
      set: (value) ->
        if @model.get('analytics')
          @model.get('analytics').trackingId = value
        else
          @model.set('analytics', {trackingId:value})
    entityId:
      deps: ['entity']
      get: (entity) ->
        if entity
          entity.id
        else
          ''
    hasOfflineTracking:
      deps: ['entity']
      get: (entity) ->
        entity?.typeTracking is 'Offline'
    offline:
      deps: ['source']
      get: (source) -> source
      set: (value) ->
        if @model.get('entity')
          @model.get('entity')?.source = value
        else
          @model.set('entity', {source:value})
    isAdvancedNumber:
      deps: ['entity']
      get: (entity) ->
        entity?.typeTracking is 'Advanced'
    isSystemNumber:
      deps: ['type']
      get: (type) ->
        type is 'System'

  initialize: (options) =>
    @model = new SmartNumberModel(id: options.id)
    @tagsCollection = new TagCollection()
    @scriptCollection = new CallScriptCollection()
    @callFlowCollection = new CallFlowCollection()
    @campaignCollection = new CampaignCollection()
    @model.fetch().done(=>
      @render()
    )
    @viewModel = new Backbone.Model(
      customParameter: ''
      extraInfo: ''
      extraInfoObj: {}
    )

  onRender: =>
    @tagsCollection?.reset(@model.get('tags')) if @model.get('tags')
    @callFlowCollection?.reset(@model.get('callFlow')) if @model.get('callFlow')
    @callFlowCollection?.setEntity(@model.get('entity').id) if @model.get('entity')
    @scriptCollection?.reset(@model.get('scripts')) if @model.get('scripts')
    @scriptCollection?.setNumber(@model.get('id')) if @model.get('id')
    @campaignCollection?.reset(@model.get('campaign')) if @model.get('campaign')
    @parseExtraInfo()
    @showTags()
    @showCallFlow()
    @showCallScript()
    @showCampaign()
    @showMetrics()

  showTags: =>
    if @model.get('targetId') and @model.get('targetType')
      tagsView = new TagsView(accountId:@model.get('account').id, collection: @tagsCollection)
      @getRegion('tags').show(tagsView)
      @listenTo(tagsView, 'added', (tag) ->
        App.api.put(
          path: 'tags/legacy/' + tag
          data:
            targetType: @model.get('targetType')
            targetId: @model.get('targetId')
        )
      )
      @listenTo(tagsView, 'removed', (tag) ->
        App.api.delete(
          path: 'tags/legacy/' + tag
          data:
            targetType: @model.get('targetType')
            targetId: @model.get('targetId')
        )
      )

  showCallFlow: =>
    if @getBinding('showTracking')
      callFlowView = new CallFlowView(
        callFlowCollection: @callFlowCollection
        accountId:@model.get('account').id
      )
      @showChildView('callFlow', callFlowView)
      @listenTo(callFlowView, 'addCallFlow', (callFlowId) =>
        App.api.put(
          path: '/api/v2/app/smartNumbers/' + @model.get('id') + '/flow'
          data:
            flowId: callFlowId
            entityId: @model.get('entity').id
          success : (response) =>
            if response.callFlow
              @callFlowCollection.reset(response.callFlow)
              @callFlowCollection?.setEntity(@model.get('entity').id) if @model.get('entity')
        )
      )

  showCallScript: =>
    if @model.get('entity') and @model.get('type') is 'Tracking'
      callScriptView = new CallScriptView(
        callScriptCollection: @scriptCollection
        accountId: @model.get('account').id
      )
      @showChildView('callScript', callScriptView)
      @listenTo(callScriptView, 'addCallScript', (callScriptId) =>
        App.api.put(
          path: '/api/v2/app/smartNumbers/' + @model.get('id') + '/script/' + callScriptId
          success : (response) =>
            if response.scripts
              @scriptCollection.reset(response.scripts)
              @scriptCollection?.setNumber(@model.get('id')) if @model.get('id')
        )
      )

  showCampaign: =>
    if @model.get('entity') and @model.get('type') is 'Tracking'
      campaignView = new CampaignView(
        campaignCollection: @campaignCollection
        accountId: @model.get('account').id
        smartNumberId: @model.get('id')
      )
      @showChildView('campaign', campaignView)
      @listenTo(campaignView, 'addCampaign', (campaignId) =>
        App.api.put(
          path: '/api/v2/app/smartNumbers/' + @model.get('id') + '/campaign/' + campaignId
          success : (response) =>
            if response.campaign
              @campaignCollection.reset(response.campaign)
        )
      )

  showMetrics: =>
    return unless @model.get('account')?.id

    @showChildView('metrics', new ReactComponent({
      component: SmartNumberMetrics,
      props: {
        accountId: @model.get('account')?.id
        smartNumberId: @model.get('id')
      }
    }))

  deleteNumber: =>
    @model.delete(ladda: @ui.deleteNumber[0]).done?(=>
      @render()
    )

  undeleteNumber: =>
    @model.undelete(ladda: @ui.undeleteNumber[0]).done?(=>
      @render()
    )

  parseExtraInfo: =>
    json = @model.get('entity')?.extraInfo
    return unless json
    extraInfo = JSON.parse(json)
    @viewModel.set(extraInfoObj: extraInfo)
    if Object.keys(extraInfo).length isnt 0
      @viewModel.set(extraInfo: json)
      @viewModel.set(customParameter: extraInfo.label) if extraInfo.label

  setExtraInfo: =>
    extraInfoObj = @viewModel.get('extraInfoObj')
    extraInfoObj['label'] = @viewModel.get('customParameter')
    @model.get('entity')?.extraInfo = JSON.stringify(extraInfoObj)

  save: =>
    @setExtraInfo() if @getBinding('isAdvancedNumber')
    @model.persist(ladda: @ui.saveButton[0]).done?(=>
      @render()
      toastr.info('Number saved.')
    )

module.exports = SmartNumberOverviewView
