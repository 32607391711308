var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"draggable\">\n          <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"call-flow/fullsize-callout.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":65}}}))
    + "\" alt=\"Callout\" title=\"Callout\" type=\"Callout\" leaf=\"false\">\n          <div class=\"label\">Callout</div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"function-palette-container\">\n  <div class=\"function-palette\">\n    <div class=\"row\">\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-dial.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":60}}}))
    + "\" alt=\"Dial\" title=\"Dial\" type=\"Dial\" leaf=\"false\">\n        <div class=\"label\">Dial</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-greeting.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":64}}}))
    + "\" alt=\"Greeting\" title=\"Greeting\" type=\"Greeting\" leaf=\"false\">\n        <div class=\"label\">Greeting</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-menu.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":60}}}))
    + "\" alt=\"Menu\" title=\"Menu\" type=\"Menu\" leaf=\"false\">\n        <div class=\"label\">Menu</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-prompt.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":62}}}))
    + "\" alt=\"Prompt\" title=\"Prompt\" type=\"Prompt\" leaf=\"false\">\n        <div class=\"label\">Prompt</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-voicemail.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":21,"column":18},"end":{"line":21,"column":65}}}))
    + "\" alt=\"Voicemail\" title=\"Voicemail\" type=\"Voicemail\" leaf=\"true\">\n        <div class=\"label\">Voicemail</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-sms.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":18},"end":{"line":25,"column":59}}}))
    + "\" alt=\"Sms\" title=\"SMS\" type=\"SMS\" leaf=\"false\">\n        <div class=\"label\">SMS</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-time.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":29,"column":18},"end":{"line":29,"column":60}}}))
    + "\" alt=\"Menu\" title=\"Time\" type=\"Time\" leaf=\"false\">\n        <div class=\"label\">Time</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-conference.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":33,"column":18},"end":{"line":33,"column":66}}}))
    + "\" alt=\"Conference\" title=\"Conference\" type=\"Conference\" leaf=\"true\">\n        <div class=\"label\">Conference</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-hangup.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":62}}}))
    + "\" alt=\"HangUp\" title=\"Hangup\" type=\"Hangup\" leaf=\"true\">\n        <div class=\"label\">Hangup</div>\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dynamicCallFlows") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":6},"end":{"line":45,"column":13}}})) != null ? stack1 : "")
    + "      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-loop.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":47,"column":18},"end":{"line":47,"column":60}}}))
    + "\" alt=\"Loop\" title=\"Loop\" type=\"Loop\" leaf=\"false\">\n        <div class=\"label\">Loop</div>\n      </div>\n      <div class=\"draggable\">\n        <img src=\""
    + alias3((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||alias2).call(alias1,"call-flow/fullsize-branch.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":51,"column":18},"end":{"line":51,"column":62}}}))
    + "\" alt=\"Branch\" title=\"Branch\" type=\"Branch\" leaf=\"false\">\n        <div class=\"label\">Branch</div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});