/**
 * @deprecated for `RdnaFormElements/RdnaTextInput`.
 */
import React, { ChangeEvent, MouseEvent, useRef } from 'react'
import { useMemo } from 'react'
import IMask from 'imask'
import { InputAdornment, TextField, IconButton } from '@mui/material'

import { InputComponentProps, InputField } from '../../../types/form-inputs'
import { InputTypes } from '../../../constants/input-types'
import SearchIcon from '../../../assets/icons/svg/Search'
import CloseIcon from '../../../assets/icons/svg/Close'
import RdnaText from '../../RdnaText'

const phoneMask = {
  mask: '(000) 000-0000'
}

const maskField = (input: InputField, event: ChangeEvent<HTMLElement>, callBack: (arg0: any) => void) => {
  if (input.type === InputTypes.PHONE) {
    const element = event.target as HTMLInputElement
    IMask(element, phoneMask)
  }
  callBack(event)
}

const RdnaTextInput = ({
  inputData,
  value,
  isDisabled,
  isError,
  errorMessage,
  onChange,
  onKeyPress,
  onBlur
}: InputComponentProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const clearInput = (event: MouseEvent<HTMLElement>) => {
    if (inputRef.current) {
      inputRef.current.value = ''
      event.target = inputRef.current
      onChange(event)
    }
  }

  const startIcon =
    inputData.type === InputTypes.SEARCH ? (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ) : (
      false
    )

  const endIcon =
    inputData.type === InputTypes.SEARCH && !!value ? (
      <IconButton size="small" onClick={clearInput}>
        <CloseIcon size={16} />
      </IconButton>
    ) : (
      false
    )

  const formatChange = (event: any) => {
    maskField(inputData, event, onChange)
  }

  const renderCharacterCounter = useMemo(() => {
    if (typeof value !== 'string') return

    const maxLength = inputData?.maxTextLength || 255
    const showMessage = value.trim().length >= maxLength
    return (
      <div style={{ display: 'flex', justifyContent: showMessage ? 'space-between' : 'flex-end' }}>
        {showMessage ? (
          <RdnaText variant="meta" color="alert">
            You’ve reached the maximum number of characters.
          </RdnaText>
        ) : null}
        <RdnaText variant="meta" align="right" color="neutral">
          {maxLength - value.trim().length}
        </RdnaText>
      </div>
    )
  }, [inputData, value])

  return (
    <TextField
      className={inputData.className}
      key={inputData.name}
      inputRef={inputRef}
      id={inputData.name}
      name={inputData.name}
      label={inputData.label}
      type={inputData.type === InputTypes.SEARCH ? 'text' : inputData.type}
      required={inputData.required}
      disabled={inputData.disabled || isDisabled}
      value={value}
      error={isError}
      placeholder={inputData.placeholder}
      helperText={isError ? errorMessage : inputData.characterCounter ? renderCharacterCounter : inputData.helperText}
      fullWidth
      onKeyPress={onKeyPress}
      rows={inputData.rows || 10}
      variant="outlined"
      onChange={formatChange}
      multiline={inputData.type === InputTypes.TEXT_AREA}
      margin="dense"
      size="small"
      data-testid={`${inputData.name}-input`}
      inputProps={{
        maxLength: inputData.maxTextLength || 255,
        autoFocus: inputData.autoFocus || false,
        min: inputData?.minNumber,
        max: inputData?.maxNumber,
        style: {
          boxShadow: 'none',
          borderRadius: 0
        }
      }}
      InputProps={{
        startAdornment: startIcon,
        endAdornment: endIcon,
        onBlur: () => (onBlur ? onBlur(inputData.name, true) : null)
      }}
      InputLabelProps={{
        shrink: inputData?.placeholder ? !!inputData.placeholder : undefined
      }}
    />
  )
}

export default RdnaTextInput
