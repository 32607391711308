import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function MenuIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.53 7H4.47C4.21 7 4 6.776 4 6.5s.21-.5.47-.5h15.06c.26 0 .47.224.47.5s-.21.5-.47.5zM19.53 13H4.47c-.26 0-.47-.224-.47-.5s.21-.5.47-.5h15.06c.26 0 .47.224.47.5s-.21.5-.47.5zM4.47 19c-.26 0-.47-.224-.47-.5s.21-.5.47-.5h15.06c.26 0 .47.224.47.5s-.21.5-.47.5H4.47z"
        fill={color}
      />
    </svg>
  )
}

export default MenuIcon
