NavController = require('./controller')
QueryString   = require('helpers/query-string')

class AppRouter extends Marionette.AppRouter
  initialize: (options) ->
    {@app} = options
    @app.radio.on('session:ended',   (sessionId) => @sessionId = null)
    @app.radio.on('session:started', (sessionId) => @sessionId = sessionId)
    @controller = new NavController(app: @app)

    routes = require('@ringdna/common/src/constants/routes.json').filter((r) -> !r.configDomain)
    @appRoute(route.path, route.callback) for route in routes

  # TODO: Ability check here? Or just defer to the server?
  execute: (callback, args, name) ->
    return unless @sessionId
    qs = new QueryString(';', args.pop())
    args.push(qs)
    callback?.apply(@, args)

module.exports = AppRouter
