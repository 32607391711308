import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseTransitions from '../baseTransitions'
import baseBreakpoints from '../baseBreakpoints'
import { Colors } from '../../colors'

const palette = {
  ...basePalette,
  primary: {
    contrastText: Colors.N0,
    main: Colors.Y30,
    dark: Colors.Y50,
    light: Colors.N30
  },
  text: {
    ...basePalette.text,
    link: Colors.Y50
  },
  icon: {
    ...basePalette.icon,
    link: Colors.Y50
  },
  illustrations: {
    20: Colors.Y20,
    30: Colors.Y30,
    40: Colors.Y40,
    50: Colors.Y50,
    60: Colors.Y60,
    70: Colors.Y70,
    80: Colors.Y80
  },
  background: {
    ...basePalette.background,
    dark: Colors.Y60
  }
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const zIndex = {
  rdnaMenu: 100,
  appBar: 1200,
  drawer: 1100,
  modal: 10000
}

const breakpoints = {
  ...baseBreakpoints
}

const theme = {
  palette,
  typography,
  transitions,
  breakpoints,
  zIndex,
  spacing: 6
}

export default theme
