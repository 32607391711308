import React, { forwardRef, HTMLAttributes, useContext, useState } from 'react'
import styled, { ThemeContext, useTheme } from 'styled-components'
import { Colors } from '../../constants/colors'
import RevenueLogo from '../../assets/logos/Revenue'
import { colorpalettes } from '../RdnaText'

/*
USE:

// An small, outlined button with text of Click Me along with a caret icon placed after text
//Defaults: color = primary, size = medium, variant = contained, disabled = false, iconPlacement = before

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import CaretFilled from '@ringdna/common/src/assets/icons/svg/CaretFilled'

<RdnaButton
  variant="outlined"
  text="Click Me"
  Icon={CaretFilled}
  iconPlacement="after"
/>

// Async Example: resetPending true reenables the button after async onClick function resolves

<RdnaButton
  type="outlined"
  text="Click Me"
  onClick={async function}
  asyncButton
  resetPending
/>


*/

export const variants = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text'
}

export const iconplacements = {
  before: 'before',
  after: 'after'
}

const StyledButton = styled.button<{ color: keyof typeof colorpalettes }>`
  font-family: ${props => props.theme.typography.fontFamily};
  ${props => ({ ...props.theme.typography.button })};
  padding: ${props => `${1.5 * props.theme.spacing}px`} ${props => `${2 * props.theme.spacing}px`};
  border-radius: ${props => props.theme.spacing}px;
  display: inline-flex;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: 0.25s;
  align-items: center;
  user-select: none;
  text-decoration: none;
  height: ${props => props.theme.spacing * 6}px;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    pointer-events: none;
  }

  &.processing {
    pointer-events: none;
    .icon-wrapper {
      height: ${props => props.theme.spacing * 3}px;
      width: ${props => props.theme.spacing * 3}px;
    }
  }

  & .icon-wrapper {
    display: flex;

    &.before {
      margin-right: ${props => props.theme.spacing * 1.5}px;
    }

    &.after {
      margin-left: ${props => props.theme.spacing * 1.5}px;
    }
  }

  &.contained {
    color: ${props => props.theme.palette[props.color].contrastText};
    background-color: ${props => props.theme.palette[props.color].dark};

    &:hover {
      background-color: ${props => props.theme.palette[props.color].main};
    }

    &:disabled {
      background-color: ${props => props.theme.palette.disabled};
    }
  }

  &.outlined {
    border: 1px solid ${props => props.theme.palette[props.color].dark};
    color: ${props => props.theme.palette[props.color].dark};
    background-color: transparent;

    :hover {
      color: ${props => props.theme.palette[props.color].contrastText};
      background-color: ${props => props.theme.palette[props.color].main};
    }

    :disabled {
      border: 1px solid ${props => props.theme.palette.disabled};
      color: ${props => props.theme.palette.disabled};
      background-color: transparent;
    }
  }

  &.text {
    color: ${props => props.theme.palette[props.color].dark};
    background-color: transparent;
    font-weight: bold;

    :hover {
      color: ${props => props.theme.palette[props.color].main};
    }

    :disabled {
      color: ${props => props.theme.palette.disabled};
    }
  }
`

// @ts-ignore - TODO
export interface RdnaButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof variants
  color?: keyof typeof colorpalettes
  Icon?: ({ color }: { color?: Colors }) => JSX.Element
  iconPlacement?: keyof typeof iconplacements
  text?: React.ReactNode
  disabled?: boolean
  name?: string
  className?: string
  type?: 'submit' | 'reset' | 'button'
  href?: string | null
  target?: string | null
  rel?: string | null
  asyncButton?: boolean
  onClick?: any
  processing?: boolean
  resetPending?: boolean
}

const RdnaButton = forwardRef<HTMLButtonElement, RdnaButtonProps>(
  (
    {
      variant = 'contained',
      color = 'primary',
      Icon,
      iconPlacement = 'before',
      text,
      disabled,
      name,
      className,
      type,
      href,
      target,
      rel,
      asyncButton = false,
      onClick,
      resetPending,
      processing,
      ...rest
    },
    ref
  ) => {
    const [isHovering, setIsHovering] = useState(false)
    const [pending, setPending] = useState(false)
    const { palette } = useTheme();
    const theme = palette[color]

    const LogoIcon = ({ color }: { color?: Colors }) => {
      return <RevenueLogo size={18} color={color} iconOnly />
    }
    if (processing) {
      Icon = LogoIcon
      text = 'Saving...'
      className = `${className} processing`
    }

    const getIconContainer = () => {
      if (!Icon) return null
      let iconColor = variant === 'contained' ? theme.contrastText : theme.main
      if (variant === 'text' && isHovering) iconColor = theme.main
      if (variant === 'outlined' && isHovering) iconColor = theme.contrastText
      if (variant !== 'contained' && disabled) iconColor = theme.light
      return (
        <div className={`icon-wrapper ${iconPlacement}`}>
          <Icon color={iconColor} />
        </div>
      )
    }

    const onPendingClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setPending(true)
      try {
        await onClick(event)
        resetPending && setPending(false)
      } catch (err) {
        setPending(false)
      }
    }

    return (
      // @ts-ignore temporarily incompatible contentEditable types
      <StyledButton
        ref={ref}
        as={!disabled && href ? 'a' : 'button'}
        color={color}
        className={`rdna-button ${variant} ${className || ''}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        disabled={disabled || pending}
        {...(onClick ? { onClick: !asyncButton ? onClick : onPendingClick } : {})}
        {...(type ? { type } : {})}
        {...(name ? { name } : {})}
        {...(href ? { href } : {})}
        {...(target ? { target } : {})}
        {...(rel ? { rel } : {})}
        {...rest}
      >
        {iconPlacement === 'before' && getIconContainer()}
        {text}
        {iconPlacement === 'after' && getIconContainer()}
      </StyledButton>
    )
  }
)

export default RdnaButton
