BaseView = require('base/view')

DomainBlacklistCollection     = require('./collection')
DomainBlacklistCollectionView = require('./collection-view')
DomainBlacklistItem           = require('./model')
DomainBlacklistItemView       = require('./item-view')

class DomainBlacklistMainView extends BaseView

  template: require('./main-template')
  className: 'domain-blacklist'

  regions:
    blacklist: 'div.blacklist'
    createRegion: 'div.create'

  events:
    'click button.create': 'create'

  initialize: (options) ->
    @collection = new DomainBlacklistCollection()

  onRender: ->
    view = new DomainBlacklistCollectionView(collection: @collection)
    @showChildView('blacklist', view)
    @collection.fetch()

  create: =>
    view = new DomainBlacklistItemView(
      collection: @collection
      model: new DomainBlacklistItem(name: '', isNew: true)
    )
    @showChildView('createRegion', view)

module.exports = DomainBlacklistMainView
