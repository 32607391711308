import {
  GmailAuthUrlRequestParam,
  GmailAuthUrlRequestPayload,
  HooversAuthUrlRequestParam,
  IntegrationRequestParam,
  IntegrationsPayload,
  OauthRequestParam,
  OauthUrlRequestPayload,
  PrimaryIntegrationParam,
  UserPayload,
  ZoomIntegrationConnectionParam,
  ZoomOauthRequestParam,
  ZoomUsersPagedParam,
  ZoomUsersPagedPayload
} from '../../components/users/integrations/model'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'

type Id = string | number

type IdPayloadParam = {
  meta: {
    id: Id
  }
}
const API_BASE = '/api/v2/app/'
const API_BASE_V3 = '/api/v3/app/'

export const useFetchUser = createUseFetchAction<UserPayload, IdPayloadParam>({
  key: 'get-managed-user',
  path: params => `${API_BASE}users/${params.meta.id}`
})

export const useIntegrations = createUseFetch<IntegrationsPayload, IdPayloadParam>({
  key: 'get-integrations',
  path: params => `${API_BASE}users/${params.meta.id}/integrations?marketplace=true`
})

export const useGmailAuthUrl = createUseFetch<GmailAuthUrlRequestPayload, GmailAuthUrlRequestParam>({
  key: 'get-gmail-auth-url',
  path: `${API_BASE}googleoauth/nylas/connect`
})

export const useZoomOauthUrl = createUseFetchAction<OauthUrlRequestPayload, ZoomOauthRequestParam>({
  key: 'get-zoom-oauth-url',
  path: `${API_BASE}zoom/oauth-url`
})

export const useOauthUrl = createUseFetchAction<OauthUrlRequestPayload, OauthRequestParam>({
  key: 'get-oauth-url',
  path: `${API_BASE}oauth/nylas/oauth-url`
})

export const useOauthUrl2 = createUseFetchAction<OauthUrlRequestPayload, OauthRequestParam>({
  key: 'get-oauth-url2',
  path: `${API_BASE_V3}nylas/oauth-url`
})

export const useHooversOauthUrl = createUseFetchAction<OauthUrlRequestPayload, HooversAuthUrlRequestParam>({
  key: 'get-hoovers-oauth-url',
  path: `${API_BASE}hooversoauth/connect`
})

export const useIntegrationRequest = createUseFetchAction<any, IntegrationRequestParam>({
  key: 'post-integration-connection-request',
  path: `${API_BASE}users/integrations/request`,
  method: HttpMethods.Post
})

export const usePostIntegrationConnection = createUseFetchAction<any, any>({
  key: 'post-integration-connection',
  path: `${API_BASE}nylas/users`,
  method: HttpMethods.Post
})

export const useDeleteIntegrationRequest = createUseFetchAction<any, IdPayloadParam>({
  key: 'delete-integration',
  path: params => `${API_BASE}nylas/users/${params.meta.id}`,
  method: HttpMethods.Delete
})

export const usePostPrimaryIntegration = createUseFetchAction<any, PrimaryIntegrationParam>({
  key: 'post-primary-integration',
  path: `${API_BASE}nylas/defaultProvider`,
  method: HttpMethods.Put
})

export const usePostZoomIntegrationConnection = createUseFetchAction<any, ZoomIntegrationConnectionParam>({
  key: 'post-zoom-user-integration-connection',
  path: `${API_BASE}zoom/users/association`,
  method: HttpMethods.Post
})

export const useZoomUsersPaged = createUseFetch<ZoomUsersPagedPayload, ZoomUsersPagedParam>({
  key: 'get-zoom-users-paged',
  path: `${API_BASE}zoom/users`
})

export const useDeleteZoomIntegrationRequest = createUseFetchAction<any, IdPayloadParam>({
  key: 'delete-zoom-integration',
  path: params => `${API_BASE}zoom/users/${params.meta.id}`,
  method: HttpMethods.Delete
})

export const usePostCreateZoomUser = createUseFetchAction<any, any>({
  key: 'post-create-zoom-user',
  path: `${API_BASE}zoom/users`,
  method: HttpMethods.Post
})
