BaseView = require('base/view')
CreatingSmartNumberView = require('./new/main-view')
ExistingSmartNumberView = require('./edit/main-view')
WidgetModel = require('./model')

class SmartNumberWidget extends Backbone.Modal
  template: require('./main-template')

  cancelEl: '.close'

  events:
    'keyup .stepNext': 'keyPressedNext'
    'click .next': 'nextStep'
    'click input[name="availableSmartNumbers"]' : 'changeType'

  initialize: (options) ->
    { @mainModal } = options
    @model.set(showLPNumbers: @model.get('type') isnt 'call-flow')
    @checkKey = (e) =>
      if @active
        switch e.keyCode
          when 27
            @triggerCancel(e)

  onRender: ->
    @model.get('selectedNumbers').reset()
    typeSmartNumber = if @model.get('isCreatingNumber') then 'creating' else 'existing'
    @$("input[value='#{typeSmartNumber}']").attr('checked', true)

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  nextStep: (e) =>
    e.preventDefault()
    if @model.get('isCreatingNumber')
      view = new CreatingSmartNumberView(mainModal: @mainModal, model: @model)
    else
      view = new ExistingSmartNumberView(mainModal: @mainModal, model: @model)
    @mainModal.showChildView('modals', view)

  changeType: (e) =>
    @model.set('isCreatingNumber', @$(e.target).val() is 'creating')

module.exports = SmartNumberWidget

class ModalContainerView extends BaseView
  template: _.template('<div class="modal-container"></div>')

  childViewEvents:
    assigned: (numbers) -> @trigger('assigned', numbers)

  regions:
    modals: 'div.modal-container'

  initialize: (options) ->
    { @accountId, @country, @objectId, @type } = options

  onRender: ->
    @modalsRegion = @getRegion('modals')
    @model = new WidgetModel(
      accountId: @accountId,
      objectId: @objectId,
      type: @type
      country: @country.toUpperCase() if @country
    )
    @showFirstModal()

  showFirstModal: ->
    @showChildView('modals', new SmartNumberWidget(mainModal: @, model: @model))

module.exports = ModalContainerView
