import React, { useState, useContext, useEffect, useCallback } from 'react'

import { IntegrationsContext } from '../../store/IntegrationsProvider'
import { InitIntegrationsContext } from '../../model'
import { IntegrationsActions } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'

import { Grid, Box } from '@mui/material'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import SelectIntegrationsModal from './SelectIntegrationsModal'
import ConnectIntegrationModal from './ConnectIntegrationModal'
import ConnectZoomUser from './ConnectZoomUserModal'
import ConnectNewZoomUser from './ConnectNewZoomUserModal'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaModal, { ModalButtons } from '@ringdna/common/src/components/RdnaModal'
import OauthNotification from './OauthNotification'

export default function IntegrationsContainer() {
  const {
    dispatch,
    integrationsState: { selectedIntegration, connectedIntegration, gmailAuthUrl }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [selectModalOpen, setSelectModalOpen] = useState<boolean>(false)
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false)
  const [connectNewZoomUserModal, setConnectNewZoomUserModal] = useState<boolean>(false)
  const isZoom = selectedIntegration?.provider === IntegrationProviderTypes.ZOOM

  const openSelectModal = () => {
    setSelectModalOpen(true)
  }

  const closeSelectModal = () => {
    setSelectModalOpen(false)
  }

  const connectNewZoomUser = () => {
    setConnectNewZoomUserModal(true)
  }

  const closeConnectModal = useCallback(() => {
    setConnectNewZoomUserModal(false)
    setConnectModalOpen(false)
    dispatch({ type: IntegrationsActions.SET_SELECTED_INTEGRATION, payload: undefined })
  }, [dispatch])

  useEffect(() => {
    if (selectedIntegration) {
      setSelectModalOpen(false)
      if (selectedIntegration.provider !== IntegrationProviderTypes.GMAIL) {
        setConnectModalOpen(true)
      } else {
        window.location.href = gmailAuthUrl
      }
    }
  }, [selectedIntegration, gmailAuthUrl])

  useEffect(() => {
    if (connectedIntegration) closeConnectModal()
  }, [connectedIntegration, closeConnectModal])

  return (
    <>
      <RdnaModal open={selectModalOpen} maxWidth={false} heading="Add a Connection" onClose={closeSelectModal}>
        <SelectIntegrationsModal />
      </RdnaModal>
      {!isZoom && (
        <RdnaModal open={connectModalOpen} onClose={closeConnectModal}>
          <ConnectIntegrationModal />
        </RdnaModal>
      )}
      {isZoom && !connectNewZoomUserModal && (
        <RdnaModal open={connectModalOpen} maxWidth={false} onClose={closeConnectModal}>
          <ConnectZoomUser />
          <ModalButtons>
            <RdnaButton text="Cancel" color="neutral" variant="text" onClick={closeConnectModal} />
            <RdnaButton text="Create New Zoom User" onClick={connectNewZoomUser} />
          </ModalButtons>
        </RdnaModal>
      )}
      {isZoom && connectNewZoomUserModal && (
        <RdnaModal open={connectModalOpen} onClose={closeConnectModal}>
          <ConnectNewZoomUser />
        </RdnaModal>
      )}
      <OauthNotification />
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <SectionHeader
            headingText="Integrations"
            descriptionText="Manage third party services to connect to their accounts to sync information within the ringDNA Softphone. By default, Salesforce events will be displayed on your calendar."
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end">
            <RdnaButton name="add-integration" onClick={openSelectModal} text="Add Integration" />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
