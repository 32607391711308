BaseModel = require('base/model')

class TagModel extends BaseModel
  url: 'tags/legacy'

  defaults:
    id: null
    data: null
    type: 'Tag'
    account: null

module.exports = TagModel