import React, { useMemo, useState } from 'react'
import { Role, RoleMapping } from '../types'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import styled from 'styled-components'
import { REVENUE_ROLE_TEXT, SALESFORCE_ROLE_TEXT } from '../const'
import RdnaSection from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import { Direction } from '@ringdna/common/src/assets/icons/types'
import Arrow from '@ringdna/common/src/assets/icons/svg/Arrow'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { createRoleOptions } from '../utils'

export type RoleMappingCellProps = {
  salesforceRole: RoleMapping
  roleDefinitions: Role[]
  onChange: any
}

export default function RoleMappingCell({ salesforceRole, roleDefinitions, onChange }: RoleMappingCellProps) {
  const [roleId, setRoleId] = useState<number | null>(salesforceRole.roleId)
  const getRoleOptions = useMemo(() => {
    const options = createRoleOptions(roleDefinitions)
    return [{ groupId: ' ', label: 'Unassigned', value: undefined }, ...options]
  }, [roleDefinitions])

  const onChangeRole = (event: any) => {
    const selectValue = event.target.value
    salesforceRole.roleId = selectValue
    setRoleId(selectValue)
    onChange(salesforceRole)
  }

  return (
    <RdnaSection>
      <RoleLayout container spacing={3}>
        <RdnaFormLayout xs={5.5}>
          <RdnaTextInput
            placeholder={SALESFORCE_ROLE_TEXT}
            label={SALESFORCE_ROLE_TEXT}
            value={`${salesforceRole.salesforceRoleName}`}
            disabled
          />
        </RdnaFormLayout>
        <ArrowLayout xs={1}>
          <Arrow direction={Direction.RIGHT} />
        </ArrowLayout>
        <RdnaFormLayout xs={5.5}>
          <RdnaSelect
            name={'role-mapping'}
            label={REVENUE_ROLE_TEXT}
            options={getRoleOptions}
            placeholder={'Unassigned'}
            value={roleId == null ? '' : roleId}
            onChange={onChangeRole}
            showPlaceholderAsOption={false}
          />
        </RdnaFormLayout>
      </RoleLayout>
    </RdnaSection>
  )
}

const RoleLayout = styled(RdnaFormLayout)`
  margin-bottom: ${props => props.theme.spacing * -11}px;
`

const ArrowLayout = styled(RdnaFormLayout)`
  padding: 0 !important;
  text-align: center;
  align-self: center;
`
