export function logout() {
  App?.session?.login()
}

export function authenticator() {
  return {
    headers: {
      sessionId: App?.session?.sessionId
    }
  }
}
