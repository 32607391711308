import React, { useState } from 'react'
import styled from 'styled-components'

// utils
import { useUser } from '../../../../common/services/UserContext'
import {
  useCallRecordingState,
  useCallRecordingDispatch,
  isCallRecordingOverrideOptionNoneSelector,
  getCallRecordingOverrideOptionSelector,
  isCallRecordingLockedSelector
} from '../../../utils/contextReducer'

// components
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import Checkbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import CallRecordingTermsContent from './CallRecordingTermsContent'
import RdnaLockedSetting from '@ringdna/common/src/components/RdnaLockedSetting'
import { createSimpleOptions } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
const callOverRideDropDownOptions = createSimpleOptions(['All', 'Inbound', 'Outbound', 'None'])

const TERMS = 'I AGREE TO THESE TERMS'

const CallTypeDropdown = () => {
  const dispatch = useCallRecordingDispatch()
  const state = useCallRecordingState()
  const isCallRecordingLocked = isCallRecordingLockedSelector(state)
  const isCallRecordingOverrideOptionNone = isCallRecordingOverrideOptionNoneSelector(state)
  const CallRecordingOverrideOption = getCallRecordingOverrideOptionSelector(state)

  const [tempCallRecordingOption, setTempCallRecordingOption] = useState(CallRecordingOverrideOption)
  const [isAgreementSelected, setAgreementSelected] = useState(!isCallRecordingOverrideOptionNone)
  const [isAgreementModalOpen, setIsLockedSettingModalOpen] = useState(false)
  const [isUnlocking, setIsUnlocking] = useState(false)

  const handleDropDownChange = (e: { target: { value: string } }) => {
    setTempCallRecordingOption(e.target.value)
    if (
      e.target.value !== 'None' &&
      !isAgreementSelected &&
      isCallRecordingOverrideOptionNone &&
      isCallRecordingLocked
    ) {
      setIsLockedSettingModalOpen(true)
    } else {
      dispatchCallRecordingOverrideOptionChange(e.target.value)
    }
    if (e.target.value === 'None') {
      setAgreementSelected(false)
    }
  }
  const dispatchCallRecordingOverrideOptionChange = (value: string) => {
    dispatch({
      type: 'CHANGE_ACCOUNT_SETTINGS',
      payload: { 'settings.callRecordingOverrideOption': value }
    })
  }
  const handleAgreementCheckBox = (value: boolean) => {
    setAgreementSelected(value)
  }

  const cancelAgreement = () => {
    setIsUnlocking(false)
    setIsLockedSettingModalOpen(false)
    setAgreementSelected(false)
    setTempCallRecordingOption(CallRecordingOverrideOption)
  }

  const saveAgreement = () => {
    dispatchCallRecordingOverrideOptionChange(tempCallRecordingOption)
    setAgreementSelected(true)
    if (isUnlocking) {
      dispatch({
        type: 'CHANGE_LOCKED_SETTINGS',
        payload: { 'lockedSetting.callRecording': false }
      })
    }
    setIsLockedSettingModalOpen(false)
  }

  const handleCallRecordingLocked = () => {
    if (isCallRecordingLocked && isCallRecordingOverrideOptionNone && !isAgreementSelected) {
      setIsUnlocking(true)
      setIsLockedSettingModalOpen(true)
    } else {
      dispatch({
        type: 'CHANGE_LOCKED_SETTINGS',
        payload: { 'lockedSetting.callRecording': !isCallRecordingLocked }
      })
    }
  }
  return (
    <Container>
      <RdnaText style={{ padding: '20px 0 10px', display: 'block' }}>
        What types of calls would you like to be recorded?
      </RdnaText>
      <CallOverRideDropDown handleChange={handleDropDownChange} handleCallRecordingLocked={handleCallRecordingLocked} />
      <AgreementModal
        isAgreementModalOpen={isAgreementModalOpen}
        isAgreementSelected={isAgreementSelected}
        cancelAgreement={cancelAgreement}
        handleAgreementCheckBox={handleAgreementCheckBox}
        saveAgreement={saveAgreement}
      />
    </Container>
  )
}

type CallOverRideDropDownProps = {
  handleChange: any
  handleCallRecordingLocked: any
}

const getLockedSettingsContent = (str: string) => {
  const customStr = `${str?.toLowerCase() !== 'none' ? 'ON' : 'OFF'} for all ${str}`
  return {
    title: 'Call Recording Account Setting',
    tooltip: {
      lock: 'Click to unlock this setting and enable teams and users to set individual Call Recording preferences.',
      unlock:
        'Click to lock this setting and disable teams and users from setting individual Call Recording preferences.'
    },
    modalText: {
      lock: `Teams and users will be able to set their own call recording preferences. By default, all teams and users will have Call Recording turned ${customStr} calls, but teams and users can set new preferences as desired.`,
      unlock: `Call Recording will be turned ${customStr} calls and cannot be modified by teams or users.`
    }
  }
}

const CallOverRideDropDown = ({ handleChange, handleCallRecordingLocked }: CallOverRideDropDownProps) => {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const callRecordingOverrideOption = getCallRecordingOverrideOptionSelector(state)
  const isCallRecordingLocked = isCallRecordingLockedSelector(state)

  return (
    <CallOverRideContainer>
      <RdnaSelect
        value={callRecordingOverrideOption}
        name={'call-types'}
        options={callOverRideDropDownOptions}
        disabled={!isEditingAllowed}
        onChange={handleChange}
      />
      <RdnaLockedSetting
        content={getLockedSettingsContent(callRecordingOverrideOption)}
        locked={isCallRecordingLocked}
        isDisabled={!isEditingAllowed}
        saveSetting={handleCallRecordingLocked}
      />
    </CallOverRideContainer>
  )
}

type AgreementModalProps = {
  isAgreementModalOpen: boolean
  isAgreementSelected: boolean
  cancelAgreement: any
  handleAgreementCheckBox: any
  saveAgreement: any
}
const AgreementModal = React.memo(function Component({
  isAgreementModalOpen,
  isAgreementSelected,
  cancelAgreement,
  handleAgreementCheckBox,
  saveAgreement
}: AgreementModalProps) {
  return (
    <RdnaModal
      open={isAgreementModalOpen}
      heading="Call Recording Agreement"
      onClose={cancelAgreement}
      onConfirm={saveAgreement}
      confirmButtonText="Yes"
      confirmButtonDisabled={!isAgreementSelected}
    >
      <>
        <CallRecordingTermsContent />
        <Checkbox label={TERMS} value={isAgreementSelected} onChange={handleAgreementCheckBox} />
      </>
    </RdnaModal>
  )
})

const Container = styled.div`
  padding: 0;
  max-width: 400px;
`

const CallOverRideContainer = styled.div`
  display: flex;
`

export default React.memo(CallTypeDropdown)
