BaseGrid = require('base/grid')
BaseView = require('base/view')
ComplianceHoursCollection = require('./collection')
EditComplianceHoursModal = require('./modals/editor-view')

class ComplianceHoursView extends BaseView

  template: require('./template')

  className: 'compliance-hours-view'

  regions:
    grid  : '.compliance-hours-grid'
    modal : '.modal-container'

  bindings:
    '.compliance-hours-enabled': 'checked:complianceHours'
    '.compliance-hours-grid': 'toggle:complianceHours'

  initialize: (options) ->
    @accountId = options.accountId
    @collection = new ComplianceHoursCollection()
    @viewModel = new Backbone.Model(
      complianceHours: options.complianceHours
    )
    @listenTo(@viewModel, 'change:complianceHours', =>
      @trigger('complianceHoursChange', @viewModel.get('complianceHours'))
    )

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid())
      )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#edit-compliance-hours-modal">
            Update
          </button>
        ''')
        @

    hoursCell = class extends Backgrid.StringCell
      render: ->
        html = 'No Restrictions'
        if @model.get('allDay')
          html = 'All Outbound Calls Disabled'
        else if (@model.get('startTime12') isnt null and @model.get('endTime12') isnt null)
          html = "#{@model.get('startTime12')} - #{@model.get('endTime12')}"
        $(@el).html(_.template(html))
        @

    columns = [
      { name: 'dayOfWeek',  label: 'Day',     cell: 'string' },
      { name: '',           label: 'Hours',   cell: hoursCell },
      { name: '',           label: 'Update',  cell: actionCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No compliance hours found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    @showChildView('modal', new EditComplianceHoursModal(
      accountId: @accountId
      model: options.model.clone()
      collection: @collection
    ))

module.exports = ComplianceHoursView