import {
  ApiAction,
  ClientState,
  ClientStateQueues,
  ClientAction,
  ActionTypes,
  RequestAction,
  CacheType
} from '../constants'
import { REHYDRATE } from 'redux-persist'
import { requestDependencyMap } from '../caches'

let isRehydrated = false
const MIN_SUCCESS_TIME = 10000
const initialState: ClientState = {
  persist: {},
  process: {},
  off: {},
  queues: {
    // @TODO this might be more performant / flexible to move into a single queue
    active: [],
    latent: [],
    fail: [],
    success: []
  }
}

function reduceQueues(queues: ClientStateQueues, action: ApiAction) {
  // @TODO would it be better to: 1) throw 2) implement an action buffer 3) only warn on non-GET requests 4) do nothing its fine?
  if (process.env.NODE_ENV !== 'production' && typeof jest === 'undefined' && !isRehydrated)
    console.warn('triggering requests before client is rehydrated can lead to unexpected behavior')
  let { latent, active, fail, success } = queues
  const requestAction = action.type === ActionTypes.Request ? action : action.request
  const { requestId, retry } = requestAction

  // remove current request from active/latent queues as needed
  if (action.type === ActionTypes.Request) {
    const latentIndex = latent.findIndex(a => a.requestId === requestId)
    if (latentIndex >= 0) {
      latent = [...latent]
      latent.splice(latentIndex, 1)
    }
    active = [...active, action]
  } else {
    // remove from active
    const activeIndex = active.findIndex(a => a.requestId === requestId)
    if (activeIndex >= 0) {
      active = [...active]
      active.splice(activeIndex, 1)
    }

    // append to latent/failed queues as determined by retry logic (only for non-request actions)
    if (action.type === ActionTypes.Error) {
      if (retry && retry.count < retry.maxCount && !action.terminal) {
        const nextRequest: RequestAction = {
          ...requestAction,
          retry: {
            ...retry,
            count: retry.count + 1
          }
        }
        // append to latent if we need to try again
        latent = [...latent, nextRequest]
      }
      // append to fail if this was the final attempt or the error action was terminal
      else fail = [...fail, action]
    }
    if (action.type === ActionTypes.Success) {
      // filter any no longer depended on actions that are older than given timeout
      const MIN_AGE = Date.now() - MIN_SUCCESS_TIME
      success = success.filter(a => {
        return a.timestamp > MIN_AGE || !!requestDependencyMap.get(a.requestId)
      })
      // append current action to the success queue (this mutation is safe since we just created a new array copy in the step before)
      success.push(action)
    }
  }
  return {
    latent,
    active,
    fail,
    success
  }
}

const reducer = (state: ClientState = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    case ActionTypes.Reset:
      return initialState
    case REHYDRATE:
      isRehydrated = true
      // @ts-ignore limitation of redux-persist type defs
      const payload: ClientState | undefined | null = action.payload
      const newState = {
        ...state,
        ...payload,
        persist: {
          ...state.persist,
          ...(payload && payload.persist)
        },
        queues: {
          // concat any rehydrated active actions back to latent
          // @TODO is it ok to assume active requests should be retried? will the server safely handle cases where the request already succeeded and we are trying again unknowlingly?
          latent: state.queues.latent.concat(
            (payload && payload.queues.latent) || [],
            ((payload && payload.queues.active) || []).filter(a => !!a.retry)
          ),
          active: [],
          success: state.queues.success.concat((payload && payload.queues.success) || []),
          fail: state.queues.fail.concat((payload && payload.queues.fail) || [])
        }
      }
      return newState
    case ActionTypes.Success: {
      const resourceId = action.resourceId
      const resource = {
        payload: action.payload,
        response: action.response,
        status: action.status,
        timestamp: Date.now(),
        cursor: action.cursor,
        cursorTerminal: action.cursorTerminal
      }

      const dictionary: CacheType = action.cache
      if (action.cursor) {
        const existingResource = state[dictionary][resourceId]
        if (existingResource && existingResource.cursor < resource.cursor)
          resource.payload = [...existingResource.payload, ...resource.payload]
      }
      return {
        ...state,
        [dictionary]: {
          ...state[dictionary],
          [resourceId]: resource
        },
        queues: reduceQueues(state.queues, action)
      }
    }
    case ActionTypes.Error: {
      const resourceId = action.resourceId
      const dictionary: CacheType = action.cache
      const resource = {
        ...(state[dictionary][resourceId] || null),
        lastErrorMeta: {
          message: action.error.message,
          status: action.status,
          timestamp: Date.now()
        }
      }
      return {
        ...state,
        [dictionary]: {
          ...state[dictionary],
          [resourceId]: resource
        },
        queues: reduceQueues(state.queues, action)
      }
    }
    case ActionTypes.Request:
      return {
        ...state,
        queues: reduceQueues(state.queues, action)
      }
    default:
      return state
  }
}

export default reducer
