import React, { ReactNode, useEffect } from 'react'
import { atom, useSetAtom } from 'jotai'
import SectionHeader from '../RdnaHeaderSection'
import { UserLicenses, CaiRecordingVisibility } from '../../types/user-settings'
import { FilterFormValues } from './types'

export type UserData = {
  accountId: number
  supportAccountId?: number
  userName: string
  licenses: UserLicenses
  opportunityAmount?: string
  opportunityWinProbability?: string
  caiRecordingVisibility?: CaiRecordingVisibility
  currentFiscalQuarter?: string
  isAuthorizedAdmin?: boolean
}

type Props = {
  headline?: string
  subHeadline?: ReactNode
  children: React.ReactNode
  userData: UserData
  defaultFilterData?: FilterFormValues
}

export const filtersAtom = atom({})
export const userDataAtom = atom<UserData | undefined>(undefined)

const RdnaReports = ({ children, headline, subHeadline, userData, defaultFilterData }: Props) => {
  const setUser = useSetAtom(userDataAtom)
  const setFilters = useSetAtom(filtersAtom)

  useEffect(() => {
    if (userData.licenses.support || userData.isAuthorizedAdmin) {
      const supportAccountId = sessionStorage.getItem('support-accountId')
      userData.supportAccountId = supportAccountId ? parseInt(supportAccountId) : undefined
    }
    setUser(userData)
    if (defaultFilterData) {
      setFilters(defaultFilterData)
    }
  }, [userData, setUser, setFilters, defaultFilterData])
  return (
    <>
      {(headline || subHeadline) && <SectionHeader headingText={headline} descriptionText={subHeadline} />}
      {children}
    </>
  )
}

export default RdnaReports
