import React from 'react'
import { Types } from '../../../../../store/api/salesforce-fields-api'
import RdnaAccordion from '@ringdna/common/src/components/RdnaAccordion'
import RdnaAccordionRow from '@ringdna/common/src/components/RdnaAccordion/RdnaAccordionRow'
import Table from './Table'
import RdnaText from "@ringdna/common/src/components/RdnaText";
import IconHelper from "@ringdna/common/src/assets/icons/IconHelper";
import getSalesforceRecordIcon from "@ringdna/common/src/components/RdnaSalesforceRecordIcon";
import {SalesforceRecord} from "@ringdna/common/src/types/salesforce";

type Props = {
  accountId: number
}

export default Fields
function Fields({ accountId }: Props) {
  const headContent = (type: string) => {
    const [Icon] = getSalesforceRecordIcon(type.toLowerCase() as SalesforceRecord)
    return(
      <>
        <IconHelper icon={Icon} variant="filled" />
        <RdnaText variant="h6" style={{paddingLeft: 12}}>{type}</RdnaText>
      </>
    )
  }

  return (
    <RdnaAccordion border={'all'}>
      {Types.map(type => (
        <RdnaAccordionRow key={type} headContent={headContent(type)} maxHeight={1100}>
          <Table accountId={accountId} type={type} />
        </RdnaAccordionRow>
      ))}
    </RdnaAccordion>
  )
}
