import React from 'react'
import styled from 'styled-components'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import { UserSkillsProps } from '../../types'

type UserSkillModalProps = {
  open: boolean
  onClose: any
  onConfirm: any
  setFormData: any
  formData: UserSkillsProps | undefined
  isLoading: boolean
}

export default function UserSkillsModal({
  open,
  onClose,
  onConfirm,
  formData,
  setFormData,
  isLoading
}: UserSkillModalProps) {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Save'}
      heading={!formData?.id ? 'Add User Skill' : 'Edit User Skill'}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonDisabled={!formData?.name || isLoading}
    >
      <StyledSection>
        <RdnaTextInput
          name={'skill-name'}
          label={'Name'}
          placeholder={'Enter Skill Name'}
          required
          onChange={e => {
            setFormData({ ...formData, name: e.target.value })
          }}
          value={formData?.name}
        />
      </StyledSection>
    </RdnaModal>
  )
}

const StyledSection = styled.section`
  margin: 0 0 18px;
`
