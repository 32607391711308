import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { Collapse, CollapseProps } from '@mui/material'

/* --- STYLES --- */
type ContainerProps = {
  $expandable: boolean
  $hover: boolean
  $highlight?: boolean
}
const Container = styled.div<ContainerProps>`
  margin-bottom: ${props => `${2 * props.theme.spacing}px`};
  border-radius: 6px;
  cursor: ${props => (props.$expandable ? 'pointer' : 'default')};
  box-shadow: ${({ theme }) => theme.palette.boxShadow.main};
  background-color: ${({ theme }) => theme.palette.background.light};
  ${({ $hover, theme }) =>
    $hover &&
    css`
      &:hover {
        box-shadow: ${theme.palette.boxShadow.hover};
      }
    `}
  border: 1px solid ${({ $highlight, theme }) =>
    $highlight ? theme.palette.primary.dark : theme.palette.background.light};
`
const DetailsContainer = styled.div`
  cursor: default;
  padding: 0 18px 18px 18px;
`
const DefaultWrapper = styled.div`
  padding: 8px 18px;
  border-radius: 6px;
  background-color: ${props => props.theme.palette.background.light};
` /* --- end of STYLES --- */

type Props = {
  OriginalWrapper?: React.ReactNode
  wrapperProps?: any
  draggableRef?: any
  className?: string
  children: React.ReactNode
  expandable: boolean
  horizontalLine?: boolean
  details?: React.ReactNode
  highlight?: boolean
  controlledState?: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }
} & CollapseProps

export default React.memo(function RdnaRowCollapseEnhancer({
  // @ts-ignore
  OriginalWrapper = DefaultWrapper,
  wrapperProps = {},
  draggableRef,
  className = '',
  children,
  expandable,
  horizontalLine = false,
  details,
  highlight,
  controlledState,
  ...restOfCollapseProps
}: Props) {
  const [isDetailOpen, setDetailOpen] = useState(false)
  const toggleDetailOpen = useCallback(() => {
    controlledState ? controlledState.setIsOpen(!controlledState.isOpen) : setDetailOpen(isDetailOpen => !isDetailOpen)
  }, [controlledState])

  return (
    <Container
      ref={draggableRef}
      className={className}
      $expandable={expandable}
      $hover={!!wrapperProps.onClick}
      onClick={toggleDetailOpen}
      $highlight={highlight}
      data-testid="collapse-container"
    >
      {/* @ts-ignore */}
      <OriginalWrapper {...wrapperProps} key={wrapperProps.key}>
        {children}
      </OriginalWrapper>

      {expandable && details && (
        <Collapse in={controlledState ? controlledState.isOpen : isDetailOpen} timeout="auto" {...restOfCollapseProps}>
          <DetailsContainer onClick={e => e.stopPropagation()}>
            {horizontalLine && <hr />}
            {details}
          </DetailsContainer>
        </Collapse>
      )}
    </Container>
  )
})
