BaseView = require('base/view')
AudioView = require('../../widgets/audio/view')
UQView    = require('../../widgets/usersAndQueues/view')

class ConferenceStepView extends BaseView

  template: require('./template')

  bindings:
    'select.input'  : 'value:holdMusic'

  computeds:
    holdMusic:
      get: ->
        @model.args().holdMusicType

  regions:
    audio : '.audio-view'
    uqv   : 'div.uqv-view'

  initialize: (options) ->
    @callflow = options.callflow

  onRender: =>
    @showChildView('audio', new AudioView(model: @model, prefix: 'audio', callflow: @callflow))
    @showChildView('uqv', new UQView(
      step      : @model,
      type      : 'Conference'
      accountId : @callflow.get('account')?.id
      readOnly  : @callflow.get('readOnly'))
    )

module.exports = ConferenceStepView