import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import {
  ActionType,
  isSipRoutingSelector,
  useSupportSettingsDispatch,
  useSupportSettingsState
} from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader, SectionSubHeader } from '../styles'
import SipIcon from '@ringdna/common/src/assets/icons/svg/Sip'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import RdnaDropdown from '@ringdna/common/src/components/RdnaForm/RdnaDropdown'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { useSipEnabledRoutes } from '../../../../../store/api/sip-route-api'
import { action } from '@ringdna/common/src/utils/storeUtils'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'

export default Sip
function Sip() {
  const state = useSupportSettingsState()
  const dispatch = useSupportSettingsDispatch()
  const [enabledRoutes = []] = useSipEnabledRoutes()
  const enabledRoutesOptions = enabledRoutes?.map(route => ({ label: route.text, value: route.value })) || []
  enabledRoutesOptions.push({ label: 'None', value: '' })

  const sipClient = 'settings.sipClient'
  const sipForwarding = 'settings.sipForwarding'
  const sipForwardingId = 'settings.sipForwardingId'
  const sipForwardingUsername = 'settings.sipForwardingUsername'
  const sipForwardingPassword = 'settings.sipForwardingPassword'

  const handleEnabledRoutesOptions = (e: any) => {
    const value = e.target.value ? { id: e.target.value } : null
    dispatch(action(ActionType.CHANGE_ACCOUNT_SETTINGS, { 'settings.sipRouting': value }))
  }

  const enabledRoutesInputData = {
    type: InputTypes.DROPDOWN,
    name: 'enabled-routes',
    options: enabledRoutesOptions
  }

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={SipIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>SIP</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow title={'SIP Client'} isChecked={state.accountSettings[sipClient]} setting={sipClient} />
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          SIP Routing
        </RdnaText>
      </SectionSubHeader>
      <RdnaSettingRow heading={'Enabled Routes'} description={''}>
        <RdnaDropdown
          value={isSipRoutingSelector(state)}
          inputData={enabledRoutesInputData}
          onChange={handleEnabledRoutesOptions}
          onBlur={() => {}}
        />
      </RdnaSettingRow>
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          SIP Forwarding Settings
        </RdnaText>
      </SectionSubHeader>
      <SupportSettingToggleRow
        title={'Enable SIP Forwarding'}
        isChecked={state.accountSettings[sipForwarding]}
        setting={sipForwarding}
      />
      <SupportSettingInputRow
        title={'SIP ID'}
        value={state.accountSettings[sipForwardingId]}
        isDisabled={!state.accountSettings[sipForwarding]}
        setting={sipForwardingId}
      />
      <SupportSettingInputRow
        title={'SIP Username'}
        value={state.accountSettings[sipForwardingUsername]}
        isDisabled={!state.accountSettings[sipForwarding]}
        setting={sipForwardingUsername}
      />
      <SupportSettingInputRow
        title={'SIP Password'}
        value={state.accountSettings[sipForwardingPassword]}
        isDisabled={!state.accountSettings[sipForwarding]}
        setting={sipForwardingPassword}
      />
    </Section>
  )
}
