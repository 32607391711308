import React from 'react'
import { Chip, ChipProps, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled, { css } from 'styled-components'
import { Colors } from '../../constants/colors'

type RdnaChipProps = {
  /** Text displayed within chip */
  label: React.ReactNode
  /** event fired when click on tag */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  /** event fired when x icon clicked on chip */
  onDelete?: (event: any) => void
  /** Element displayed within chip */
  icon?: React.ReactElement
  /** Additional classname on chip */
  className?: string
  /** Disable default hover styles (color change on hover) */
  disableHover?: boolean
  color?: Colors
} & Omit<ChipProps, 'color'>

const StyleChip = styled(Chip)<{ $disableHover: string; $activeColor: string; $inactiveColor: string }>`
  button {
    margin-right: ${props => props.theme.spacing}px;
  }
  ${props =>
    props.$disableHover === 'false'
      ? css`
          &:hover {
            color: ${props.$activeColor};
            border-color: ${props.$activeColor};
          }

          &:hover svg {
            color: ${props.$activeColor};
          }

          &:hover svg > * {
            color: ${props.$activeColor};
            stroke: ${props.$activeColor};
          }

          &:hover span {
            color: ${props.$activeColor};
          }
        `
      : css`
          border-color: ${props.$inactiveColor} !important;
        `}
`

const RdnaChip = ({
  label,
  onClick,
  onDelete,
  icon,
  className,
  disableHover = false,
  color,
  ...rest
}: RdnaChipProps) => {
  const theme = useTheme()
  const activeColor = color ? color : theme.palette.primary.main
  const inactiveColor = color && disableHover ? color : theme.palette.grey.A400
  return (
    <StyleChip
      {...rest}
      $disableHover={disableHover.toString()}
      onClick={onClick}
      onDelete={onDelete}
      label={label}
      size="small"
      className={className}
      avatar={icon}
      deleteIcon={<CloseIcon />}
      variant="outlined"
      $activeColor={activeColor}
      $inactiveColor={inactiveColor}
    />
  )
}

export default RdnaChip
