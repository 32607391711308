BaseCollection         = require('base/collection')
OutboundNumberModel = require('./model')

module.exports = class OutboundNumberCollection extends BaseCollection

  url: 'outboundNumbers'
  model: OutboundNumberModel

  parse: (response) ->
    for outboundNumber in response
      outboundNumber.name = outboundNumber.label
    response

