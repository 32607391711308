import React, { useState } from 'react'
import { Column, ConnectedUsers, Container, Section, SectionRow } from './styles'

import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaChip from '@ringdna/common/src/components/RdnaChip'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import { useAccountIntegrations, usePutAccountIntegration } from '../../../store/api/account-integrations-api'
import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import { integrationItems, NylasSettingModel } from '../models'
import { Provider } from '../../accounts/nylas-users/constants'
import NylasUsersContainer from '../../accounts/nylas-users/NylasUsersContainer'
import { ProductDomains } from '@ringdna/common/src/constants/productDomains'
import config from '../../../config'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'

type IntegrationProps = {
  accountId: number
}

const HEAD = 'Integrations: Email & Calendars'
const SUBHEAD = 'Email & Calendar'
const DESCRIPTION =
  'Manage allowed third party email and calendar services to enable end users ' +
  'to connect to their accounts to sync information across Revenue applications.'

export default Integration
function Integration({ accountId }: IntegrationProps) {
  const [accountIntegrations, , loading, refetch] = useAccountIntegrations({ meta: { id: accountId } })
  const [provider, setProvider] = useState<Provider>(Provider.ALL)
  const [revokeAll, setRevokeAll] = useState<boolean>(false)
  const [settingToChange, setSettingToChange] = useState<NylasSettingModel>()
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false)
  const updateIntegration = usePutAccountIntegration()

  function userCount(countProvider: Provider) {
    return accountIntegrations?.nylasUserCountByProvider[countProvider]
      ? accountIntegrations.nylasUserCountByProvider[countProvider]
      : 0
  }

  function openConfirmDialog(setting: NylasSettingModel) {
    setSettingToChange(setting)
    if (setting.enabled) {
      setConfirmModalIsOpen(true)
    } else {
      toggleProvider(setting)
    }
  }

  const onConfirmModalClose = (confirmed: boolean) => {
    if (confirmed && settingToChange) {
      toggleProvider(settingToChange)
    } else {
      setConfirmModalIsOpen(false)
    }
  }

  const toggleProvider = async (settingToChange: NylasSettingModel) => {
    setRevokeAll(true)
    settingToChange.enabled = !settingToChange.enabled
    try {
      await updateIntegration({ body: settingToChange })
      !settingToChange.enabled && refetch()
    } catch (error) {
      toastr.error(error.message)
    } finally {
      setConfirmModalIsOpen(false)
      setRevokeAll(false)
    }
  }

  const onProviderChange = (selectedProvider: Provider) => {
    setProvider(selectedProvider)
  }

  const showLicenses = () => {
    window.location.assign(`${config[ProductDomains.WEB_ADMIN]}/#accounts/${accountId}/licensing`)
  }

  const ProviderSwitch = ({
    setting,
    onUpdate
  }: {
    setting: NylasSettingModel
    onUpdate: (setting: NylasSettingModel) => void
  }) => {
    const [value, setValue] = useState<boolean>(setting.enabled)
    const onChange = (checked: boolean) => {
      setValue(checked)
      onUpdate(setting)
    }
    return <RdnaToggleSwitch onChange={onChange} value={value} margin={'none'} />
  }

  return (
    <Container>
      <SectionHeader headingText={HEAD} />
      <RdnaLoader
        data={accountIntegrations && [accountIntegrations]}
        noResultsMsg={<NoResultsMsg title="Something went wrong, try refreshing!" Icon={<BinocularsIconLeaves />} />}
        loading={!!loading}
        top="150px"
      >
        <Section>
          <SectionRow>
            <Column>
              <RdnaText bold>{SUBHEAD}</RdnaText>
              <RdnaText>{DESCRIPTION}</RdnaText>
            </Column>
            {accountIntegrations && accountIntegrations.availableNylasLicenses >= 0 && (
              <RdnaChip
                label={`${
                  accountIntegrations.availableNylasLicenses > 0 ? accountIntegrations?.availableNylasLicenses : 'No'
                } License Available`}
                onClick={showLicenses}
                className={accountIntegrations.availableNylasLicenses > 0 ? 'availableLicenses' : 'noLicenses'}
              />
            )}
          </SectionRow>
          {accountIntegrations?.nylasSettings &&
            accountIntegrations?.nylasSettings
              .filter(setting => setting.provider === Provider.GMAIL || setting.provider === Provider.EXCHANGE)
              .map((setting: NylasSettingModel) => (
                <RdnaSettingRow
                  key={setting.provider}
                  icon={integrationItems[setting.provider].icon}
                  iconSize={48}
                  heading={integrationItems[setting.provider].label}
                  description={integrationItems[setting.provider].description}
                >
                  <RdnaText className={'count'} onClick={() => onProviderChange(setting.provider)}>{`${userCount(
                    setting.provider
                  )} users`}</RdnaText>
                  <ProviderSwitch setting={setting} onUpdate={setting => openConfirmDialog(setting)}></ProviderSwitch>
                </RdnaSettingRow>
              ))}
        </Section>
        {accountIntegrations?.nylasUserCountByProvider &&
          Object.keys(accountIntegrations.nylasUserCountByProvider).length > 0 &&
          (userCount(Provider.GMAIL) > 0 || userCount(Provider.EXCHANGE) > 0) && (
            <>
              <ConnectedUsers>
                <RdnaText bold>{'Connected Users'}</RdnaText>
              </ConnectedUsers>
              <NylasUsersContainer
                selectedProvider={provider}
                accountId={accountId}
                handleChange={onProviderChange}
                refresh={refetch}
              />
            </>
          )}
      </RdnaLoader>
      <RdnaModal
        open={confirmModalIsOpen}
        heading="Confirm Disabled Account"
        onClose={() => onConfirmModalClose(false)}
        confirmButtonText="Continue"
        confirmButtonDisabled={revokeAll}
        onConfirm={() => onConfirmModalClose(true)}
      >
        {settingToChange && (
          <RdnaText>
            {`
            There are ${userCount(settingToChange.provider)}
            users associated with this account type. By disabling this account they will all lose
            access to manage their accounts within Revenue products.`}
          </RdnaText>
        )}
      </RdnaModal>
    </Container>
  )
}
