BaseModel = require('base/model')

class GoogleAccountModel extends BaseModel
  defaults:
    childId: ''
    childName: ''
    parentId: ''
    parentName: ''

module.exports = GoogleAccountModel
