BaseModel = require('base/model')

class DomainBlacklistModel extends BaseModel

  defaults:
    name: ''
    isNew: false # FIXME: This conflicts with backbone's `isNew` attribute.  fix.

  urlRoot: 'domainBlacklist'

module.exports = DomainBlacklistModel
