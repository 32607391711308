import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50">
      <g id="01-admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01b_account_connected_accounts-(enabled)" transform="translate(-1236.000000, -1133.000000)">
          <g id="AOL" transform="translate(1074.000000, 1093.000000)">
            <g id="aol" transform="translate(162.000000, 40.000000)">
              <rect id="Rectangle-8" fill="#000000" x="0" y="0" width="50" height="50" rx="12"></rect>
              <g id="AOL_logo" transform="translate(5.000000, 17.000000)" fill="#FFFFFF">
                <path
                  d="M21.9684643,3.8797812 C18.4820337,3.8797812 15.8444731,6.58808095 15.8444731,9.94313885 C15.8444731,13.4800975 18.5931952,16.0064965 21.9684643,16.0064965 C25.3437334,16.0064965 28.0823499,13.4800975 28.0823499,9.94313885 C28.0823499,6.58808095 25.454895,3.8797812 21.9684643,3.8797812 Z M21.9684643,7.08325516 C23.4741981,7.07314956 24.7070809,8.35656027 24.7070809,9.94313885 C24.7070809,11.5196118 23.4741981,12.8030225 21.9684643,12.8030225 C20.4627305,12.8030225 19.2298478,11.5196118 19.2298478,9.94313885 C19.2298478,8.35656027 20.4627305,7.08325516 21.9684643,7.08325516 Z"
                  id="Shape"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M39.4137622,13.8839171 C39.4137622,15.0561662 38.4638362,16.0060923 37.291587,16.0060923 C36.1193379,16.0060923 35.1694118,15.0561662 35.1694118,13.8839171 C35.1694118,12.711668 36.1193379,11.7617419 37.291587,11.7617419 C38.4638362,11.7617419 39.4137622,12.711668 39.4137622,13.8839171 Z"
                  id="path"
                ></path>
                <polygon
                  id="path"
                  points="33.2362039 15.6022727 29.9013572 15.6022727 29.9013572 0.544934498 33.2362039 0.544934498"
                ></polygon>
                <path
                  d="M0.595128608,15.6022727 L6.65848625,0.544934498 L9.79122104,0.544934498 L15.7535227,15.6022727 L11.5091724,15.6022727 L10.8017806,13.4800975 L5.54687069,13.4800975 L4.83947896,15.6022727 L0.595128608,15.6022727 Z M9.79122104,10.3473627 L8.22485365,5.29456465 L6.65848625,10.3473627 L9.79122104,10.3473627 Z"
                  id="Shape"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
