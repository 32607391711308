import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'

const API_BASE = '/api/v2/app/salesforce'

export const Types = ['Account', 'Campaign', 'Case', 'Contact', 'Lead', 'Opportunity', 'Task', 'Event']

type UseParams = {
  meta: {
    id: number
    type: string
  }
}

export type Field = {
  id?: number
  dataType: string
  isDefault?: boolean
  name: string
  settingsCount?: number
  sfdcName: string
  updatedDate?: number
}

export type FieldSettings = {
  id: number
  editable: boolean
  hidden: boolean
  profile: {
    id: number
    name: string
    limit?: boolean
  }
  required: boolean
  updateable: boolean
  view: boolean
  visible: boolean
}

export const useSalesforceFields = createUseFetch<Field[], UseParams>({
  key: 'use-salesforce-fields',
  path: ({ meta }) => `${API_BASE}/fields?accountId=${meta.id}&type=${meta.type}`
})

export const useSalesforceFieldSettings = createUseFetchAction<FieldSettings[], { meta: { id: number } }>({
  key: 'use-salesforce-field-settings',
  path: ({ meta }) => `${API_BASE}/fields/${meta.id}`,
  independent: true
})

type UseNewSettingsParams = {
  meta: {
    type: string
    sfdcName: string
    accountId: number
  }
}

export const useNewSalesforceFieldSettings = createUseFetchAction<FieldSettings[], UseNewSettingsParams>({
  key: 'use-new-salesforce-field-settings',
  path: ({ meta }) => `${API_BASE}/field/name?accountId=${meta.accountId}&type=${meta.type}&sfdcName=${meta.sfdcName}`
})

type PutParams = {
  meta: {
    id: number
  }
  body: Record<string, any>
}

export const usePutSalesforceFieldSettings = createUseFetchAction<void, PutParams>({
  key: 'put-salesforce-field-settings',
  path: ({ meta }) => `${API_BASE}/fields/${meta.id}`,
  method: HttpMethods.Put
})

export const usePostSalesforceFieldSettings = createUseFetchAction<void, { body: Record<string, any> }>({
  key: 'post-salesforce-field-settings',
  path: `${API_BASE}/fields`,
  method: HttpMethods.Post
})

export type AvailableField = {
  name: string
  parentType: string
  sfdcName: string
  dataType: string
}

export const useSalesforceAvailableFields = createUseFetchAction<AvailableField[], UseParams>({
  key: 'use-salesforce-available-fields',
  path: ({ meta }) => `${API_BASE}/availableFields?accountId=${meta.id}&type=${meta.type}`
})

export const useSalesforceAvailableFieldsPreload = createUseFetch<void, { meta: { id: number } }>({
  key: 'use-salesforce-available-fields-preload',
  path: ({ meta }) => `${API_BASE}/availableFieldsPreload?accountId=${meta.id}`
})

export const useDeleteSalesforceField= createUseFetchAction<void, { meta: { id: number } }>({
  key: 'use-delete-salesforce-field',
  path: ({ meta }) => `${API_BASE}/fields/${meta.id}`,
  method: HttpMethods.Delete
})
