BaseView  = require('base/view')
BaseModel = require('base/model')
Picklist  = require('./collection')

class CreateCampaignModal extends BaseView
  template: require('./create-step-template')

  ui:
    startDatePicker : '.start-date-picker'
    endDatePicker   : '.end-date-picker'

  bindings:
    '.campaignName' : 'value:name'
    '.type'         : 'value:uiType'
    '.status'       : 'value:uiStatus'
    '.startDate'    : 'value:uiStartDate'
    '.endDate'      : 'value:uiEndDate'
    '.description'  : 'value:description'

  events:
    'click .active': 'active'

  computeds:
    uiCampaignName:
      deps: ['name']
      get: (name) -> name
      set: (value) ->
        @model.set(name: value.trim())

    uiStatus:
      get: -> 'none'
      set: (value) ->
        @model.set('status', if value is 'none' then null else value)

    uiType:
      get: -> 'none'
      set: (value) ->
        @model.set('type', if value is 'none' then null else value)

    uiStartDate:
      deps: ['startDate']
      get: (startDate) -> startDate
      set: (value) ->
        @ui.endDatePicker.datepicker('setStartDate', value)
        @model.set(startDate: value)

    uiEndDate:
      deps: ['endDate']
      get: (endDate) -> endDate
      set: (value) ->
        @model.set(startDate: value) if not @model.get('startDate')
        @model.set(endDate: value)

  initialize: (options) ->
    { @accountId, @viewModel, @model } = options

  onRender: ->
    pickerOption =
      autoclose   : true
      format      : 'mm/dd/yyyy'
      dateFormat  : 'mm/dd/yyyy'
      minViewMode : 'days'
      maxViewMode : 'days'
      orientation : 'bottom'
      startDate   : new Date()
    $('body').css('overflow-y', 'hidden')
    @ui.startDatePicker.datepicker(pickerOption)
    @ui.endDatePicker.datepicker(pickerOption)

  onDestroy: ->
    $('body').css('overflow-y', 'auto')

  active: (e) ->
    @model.set(active: e.target.checked)

module.exports = CreateCampaignModal