import React, { useState, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

// utils
import { useSmsTemplates, useSmsTemplateDelete, useSmsTemplatesUpdate, useSmsTemplatesPost } from './api'
import { formatTeamsStringForColumns } from '@ringdna/common/src/utils/teamUtils'
import { RdnaTeam } from '@ringdna/common/src/types/team'
import { SmsTemplatesProps, RtnPagination, SmsTemplateDeleteModalProps } from './types'
import { RdnaSmsTemplate } from '@ringdna/common/src/types/sms-template'
import { initFormData, smsTemplateColumns } from './const'
import { SMS_TEMPLATE_STR } from './strings'

// components
import RdnaSmartTable, {
  RdnaSmartTableRef,
  getActivePageForDelete
} from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import SmsTemplateModal from './components/SmsTemplateModal'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import TeamsPicklist from './components/TeamsPicklist'
import NameFilter from './components/NameFilter'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import AttachmentIcon from '@ringdna/common/src/assets/icons/svg/Attachment'

const SERVER_PAGE_OFFSET = 1

const SmsTemplates = ({ accountId, smsSettingsPage = false }: SmsTemplatesProps) => {
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false)
  const [pagination, setPagination] = useState<RtnPagination>({ pageIndex: 0 })
  const [isConfirmSmsDeleteModalOpen, setIsConfirmSmsDeleteModalOpen] = useState(false)
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)
  const [activeId, setActiveId] = useState<number | undefined>()
  const [formData, setFormData] = useState<RdnaSmsTemplate>(initFormData)
  const deleteSmsTemplate = useSmsTemplateDelete()
  const updateSmsTemplatesUpdate = useSmsTemplatesUpdate()
  const saveSmsTemplatesUpdate = useSmsTemplatesPost()
  const NO_RESULT_STATE = { title: SMS_TEMPLATE_STR.noResultsToDisplay, subtitle: '' }
  const smartTableRef = useRef<RdnaSmartTableRef | undefined>()
  const [name, setName] = useState<string>('')
  const [teamId, setTeamId] = useState<string>('')

  const [payload, , loading, refetch] = useSmsTemplates({
    meta: {
      page: pagination.pageIndex + SERVER_PAGE_OFFSET,
      limit: 10,
      name: name,
      teamId: teamId,
      accountId
    }
  })

  const totalRowsRef = useRef()
  // cache the total rows so we dont lose that info while fetching the next page
  if (payload?.total) totalRowsRef.current = payload?.total

  const handleActionMenu: any = useCallback(
    // @ts-ignore
    (action: string, templateId: number) => {
      setActiveId(templateId)
      if (action === SMS_TEMPLATE_STR.delete) {
        setIsConfirmSmsDeleteModalOpen(true)
      } else {
        const updateData: RdnaSmsTemplate = payload.results.filter(
          (template: { id: number }) => template.id === templateId
        )[0]
        updateData.teams = updateData.teams.map(team => {
          return { ...team, value: team.id.toString(), text: team.name }
        })
        setFormData(updateData)
        setIsSmsModalOpen(true)
        // trackEvent('admin-sms-template-edit')
      }
    },
    [payload]
  )

  const setQueryState = useCallback(
    // @ts-ignore
    ({ pageIndex, pageSize, sortBy }) => {
      setPagination({
        pageIndex,
        pageSize,
        sortBy
      })
    },
    [setPagination]
  )

  const closeSmsTemplateModal = () => {
    setFormData(initFormData)
    setActiveId(undefined)
    setIsSmsModalOpen(false)
  }

  const handleToggle: any = useCallback(
    // @ts-ignore
    async (id: number, toggle: boolean) => {
      try {
        await updateSmsTemplatesUpdate({ meta: { id: id }, json: { enabled: toggle, accountId } })
      } catch (e) {
        if (e.fail.payload.message) {
          toastr.error(e.fail.payload.message)
        }
      }
    },
    [updateSmsTemplatesUpdate, accountId]
  )

  async function handleUpdate() {
    setConfirmButtonDisabled(true)
    try {
      await updateSmsTemplatesUpdate({
        meta: { id: activeId },
        json: { ...formData, accountId, teams: formData.teams.map(e => ({ id: e.value })) }
      })
      closeSmsTemplateModal()
      refetch()
      // trackEvent('admin-sms-template-update')
    } catch (e) {
      toastr.error(getErrorMessage(e))
    } finally {
      setConfirmButtonDisabled(false)
    }
  }

  async function handleDelete() {
    if (!activeId) {
      setConfirmButtonDisabled(false)
      return
    }
    setConfirmButtonDisabled(true)
    try {
      await deleteSmsTemplate({ meta: { id: activeId } })
      setIsConfirmSmsDeleteModalOpen(false)
      setActiveId(undefined)
      const activePage = getActivePageForDelete(payload.total, pagination.pageIndex)
      smartTableRef.current?.gotoPage(activePage)
      refetch()
      // trackEvent('admin-sms-template-delete')
    } catch (e) {
      toastr.error(getErrorMessage(e))
    } finally {
      setConfirmButtonDisabled(false)
    }
  }

  async function handleSmsTemplateSave() {
    setConfirmButtonDisabled(true)
    try {
      await saveSmsTemplatesUpdate({
        json: { ...formData, accountId, teams: formData.teams.map(e => ({ id: e.value })) }
      })
      closeSmsTemplateModal()
      refetch()
    } catch (e) {
      if (e?.fail?.payload?.message) {
        toastr.error(e.fail.payload.message)
      }
    } finally {
      setConfirmButtonDisabled(false)
    }
  }

  const memoPayload = useMemo(() => {
    return payload?.results?.map(
      (template: { teams: RdnaTeam[]; id: string; enabled: boolean; files: string[]; name: string }) => ({
        ...template,
        fileAndName: (
          <NameContainer>
            {template.files.length > 0 && <AttachmentIcon />}
            <RdnaText>{template.name}</RdnaText>
          </NameContainer>
        ),
        teams: formatTeamsStringForColumns(template.teams, 2),
        ...(smsSettingsPage && {
          enabled: (
            <EnableToggle
              enabled={template.enabled}
              onToggle={(e: boolean) => handleToggle(template.id, e)}
              isCheckbox={smsSettingsPage}
            />
          )
        }),
        menu: (
          <>
            {!smsSettingsPage && (
              <EnableToggle
                enabled={template.enabled}
                onToggle={(e: boolean) => handleToggle(template.id, e)}
                isCheckbox={smsSettingsPage}
              ></EnableToggle>
            )}
            <RdnaMenu
              options={[{ text: SMS_TEMPLATE_STR.edit }, { text: SMS_TEMPLATE_STR.delete }]}
              onSelect={item => handleActionMenu(item, template.id)}
            />
          </>
        )
      })
    )
  }, [payload, handleToggle, handleActionMenu, smsSettingsPage])

  const reactSmsTemplateColumns = useMemo(() => {
    return [
      {
        Header: SMS_TEMPLATE_STR.enable,
        accessor: 'enabled',
        disableSortBy: true
      },
      {
        Header: SMS_TEMPLATE_STR.name,
        accessor: 'fileAndName',
        disableSortBy: true,
        canFilter: true,
        disableFilters: false,
        Filter: function () {
          return <NameFilter onUpdate={e => setName(e)} />
        }
      },
      {
        Header: SMS_TEMPLATE_STR.teams,
        accessor: 'teams',
        disableSortBy: true,
        canFilter: true,
        disableFilters: false,
        Filter: function () {
          return <TeamsPicklist accountId={accountId} onChoose={e => setTeamId(e)} />
        }
      },
      {
        Header: '',
        accessor: 'menu',
        disableSortBy: true,
        styles: {
          align: 'right'
        }
      }
    ]
  }, [accountId])

  return (
    <div>
      {!smsSettingsPage && <SectionHeader headingText={SMS_TEMPLATE_STR.smsTemplates} />}
      <Top>
        <RdnaText variant="body2">{SMS_TEMPLATE_STR.createPreconfiguredSmsTemplate}</RdnaText>
        <CreateButton
          data-analyticsid="admin-sms-templates-create"
          text={SMS_TEMPLATE_STR.createTemplate}
          onClick={() => setIsSmsModalOpen(true)}
        />
      </Top>
      <RdnaSmartTable
        ref={smartTableRef}
        columns={smsSettingsPage ? reactSmsTemplateColumns : smsTemplateColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        setQueryState={setQueryState}
        isFetching={!!loading}
        paginationSize={10}
        totalItems={totalRowsRef?.current || 1}
      />
      <SmsTemplateModal
        accountId={accountId}
        open={isSmsModalOpen}
        onClose={closeSmsTemplateModal}
        onConfirm={!activeId ? handleSmsTemplateSave : handleUpdate}
        setFormData={setFormData}
        formData={formData}
        confirmButtonDisabled={confirmButtonDisabled}
      />
      <ConfirmSmsDeleteModal
        open={isConfirmSmsDeleteModalOpen}
        onClose={setIsConfirmSmsDeleteModalOpen}
        onConfirm={handleDelete}
        confirmButtonDisabled={confirmButtonDisabled}
      />
    </div>
  )
}

const ConfirmSmsDeleteModal = ({ open, onClose, onConfirm, confirmButtonDisabled }: SmsTemplateDeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={SMS_TEMPLATE_STR.cancel}
      confirmButtonText={SMS_TEMPLATE_STR.delete}
      confirmButtonDisabled={confirmButtonDisabled}
      heading={SMS_TEMPLATE_STR.deleteSmsTemplate}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>Are you sure you want to delete this SMS Template?</RdnaText>
    </RdnaModal>
  )
}

const EnableToggle = ({
  enabled,
  onToggle,
  isCheckbox
}: {
  enabled: boolean
  onToggle: (e: boolean) => void
  isCheckbox: boolean // Part of the SMS Settings Page, old one must be ToggleSwitch
}) => {
  const [checked, setChecked] = useState(enabled)
  return isCheckbox ? (
    <RdnaCheckbox
      data-analyticsid="admin-sms-template-toggle"
      onChange={(value: boolean) => {
        onToggle(value)
        setChecked(value)
      }}
      value={checked}
      style={{ margin: '0!important' }}
    />
  ) : (
    <RdnaToggleSwitch
      data-analyticsid="admin-sms-template-toggle"
      onChange={(value: boolean) => {
        onToggle(value)
        setChecked(value)
      }}
      value={checked}
      margin={'none'}
    />
  )
}

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing}px;
  svg {
    flex-shrink: 0;
    transform: scale(-1, 1) rotate(145deg);
    margin-right: ${({ theme }) => theme.spacing}px;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const Top = styled.div`
  margin: ${({ theme }) => theme.spacing * 3}px 0 ${({ theme }) => theme.spacing}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CreateButton = styled(RdnaButton)`
  margin-right: 0;
`

export default React.memo(SmsTemplates)
