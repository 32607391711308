Uuid = require('uuid')
config = require('../../src/config').default
class PubSubService

  # FIXME: Need to unbind channel on logout
  constructor: (app) ->
    @app = app
    @_startPubNub()
    @eventBindings = {}

  _startPubNub: =>
    @pubnub = new PubNub(
      subscribeKey: config.pubnubKey
      ssl: true
      logVerbosity: process.env.NODE_ENV == 'development'
      uuid: Uuid.v4()

    )
    @pubnub.addListener(
      message: @onPubNubMessage
    )

  setup: (channelName) =>
    console.info("(PubSub) Subscribed to channel: #{channelName}")
    @pubnub.subscribe(channels: [ channelName ])
    @bind('FlashMessage', @flashMessage)
    @bind('PackageUpdate', @onPackageUpdate)

  bind: (event, callback) =>
    console.info("(PubSub) Bind #{event}")
    @eventBindings[event] = callback

  unbind: (event, handler, context) =>
    console.info("(PubSub) Unbind #{event}")
    delete @eventBindings[event]

  # FIXME: This tight binding to the UI layer is no bueno. Should move outside of PubSubService.
  flashMessage: (flash) ->
    if flash.level is 'error'
      options = {closeButton: true, extendedTimeOut: 0, timeOut: 0}
    else
      options = {}

    toastr[flash.level](flash.message, flash.title, options)

  onPackageUpdate: (data) =>
    toastr.info(data.message, data.title)

  onPubNubMessage: (msg) =>
    data = msg.message
    type = msg.userMetadata

    callback = @eventBindings[type]
    if callback
      callback(data)
    else
      console.log("(PubNub) No callback found for #{type}")

module.exports = PubSubService
