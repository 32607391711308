BaseModel = require('base/model')

class CustomUserStatusModel extends BaseModel
  urlRoot: 'userStatuses'

  defaults:
    name: ''
    description: ''
    busy: '1'
    defaultExpiration: '15'

module.exports = CustomUserStatusModel
