BackgridCell            = require('helpers/backgrid-cell')
BaseGrid                = require('base/grid')
BaseView                = require('base/view')
VoicemailDropCollection = require('widgets/voicemail-modals/collection')
VoicemailDropModel      = require('widgets/voicemail-modals/model')
VoicemailModal          = require('widgets/voicemail-modals/main-view')

class VoicemailDropGroupView extends BaseView

  template: require('./template')
  className: 'voicemail-team-group'

  regions:
    grid  : 'div.grid'
    modal : 'div.modal-voicemail-container'

  events:
    'click a.add-voicemail-drop'  : 'openVoicemailDropModal'

  initialize: (options) ->
    {@teamId, @parentView} = options

  onRender: =>
    @collection = new VoicemailDropCollection()
    @collection
      .fetch(
        data: accountId: null, teamId: @teamId, userId: null
      )
      .done?(=>
        @showChildView('grid', @buildGrid(@collection)) if @getRegion('grid')
      )

  buildGrid: (collection) ->
    cols = [
      { name: 'name',                 label: 'Label',  cell: 'string' },
      { name: 'url',                  label: 'URL',    cell: 'uri' },
      { name: 'id',                   label: 'ID',     cell: 'string' },
      { name: '',                     label: 'Listen', cell: BaseGrid.ListenAudioCell },
      { name: 'voicemail-drop-modal', label: 'Update', cell: BaseGrid.EditAudioCell },
      { name: '',                     label: 'Delete', cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : cols
      collection : collection
      emptyText  : 'No voicemail drops found.'
      onEdit     : @openVoicemailDropModal
    )

  openVoicemailDropModal: (options) =>
    return unless @parentView.validateModel()
    model = options.model?.clone()
    model ?= new VoicemailDropModel()
    view = new VoicemailModal(
      teamId     : @teamId
      collection  : @collection
      model       : model
      modalTitle  : 'Voicemail Drop'
    )
    @showChildView('modal', view)

module.exports = VoicemailDropGroupView