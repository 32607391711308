BaseModel = require('base/model')

class MemberModel extends BaseModel
  initialize: (options) =>
    @teamId = options.teamId

  urlRoot: =>
    "teams/#{@teamId}/members"

  defaults:
    displayName: ''
    teamCount: 0
    title: null
    location: null
    listenIn: false
    deletable: true

module.exports = MemberModel
