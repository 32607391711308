BaseModel = require('base/model')

class CallCustomLinksModel extends BaseModel
  urlRoot: 'callCustomLinks'

  defaults:
    name: ''
    url: ''

  parse: (response) ->
    attrs =
      id: response.id
      name: response.label
      url: response.url
    attrs

  validate: (attrs) ->
    if not attrs.name.trim() or not attrs.url.trim()
      return 'Label And URL Cannot Be Empty.'
    return

module.exports = CallCustomLinksModel