AddressCollection  = require('widgets/smart-numbers-widget/address-collection')
AddressStepModal   = require('features/call-tracking/address-step/address-step-view')
BackgridCell       = require('helpers/backgrid-cell')
BackgridRow        = require('helpers/backgrid-row')
BaseView           = require('base/view')
CallflowPickerView = require('widgets/callflow-picker/view')
CallTrackingHelper = require('features/call-tracking/helper')
CustomModal        = require('./editor/view')
CustomCallTrackingСollection = require('./collection')
CustomCallTrackingModel      = require('./model')
BaseCollection     = require('base/collection')
PagedCollection    = require('base/paged-collection')
PagerView          = require('widgets/pager/view')

class CustomPagedCollection extends PagedCollection
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    for custom in response.results
      custom['labelTracking'] = custom.label
      result.push(custom)
    result

class CustomView extends BaseView
  template: require('./template')

  regions:
    grid    : 'div.grid'
    pager   : 'span.pager'
    modal   : 'div.modal-container'
    addressStepModal   : 'div.address-step-container'
    callFlowPicker     : '.call-flow-picker'

  ui:
    search  : 'button.search'
    searchInfo  : 'input.searchInfo'

  events:
    'click @ui.search' : 'search'
    'click a.add' : 'create'

  bindings:
    '.add': "classes:{'disabled':addButtonDisabled}"

  computeds:
    isSupport:
      get: -> @user().isSupport()

  initialize: (options) =>
    { @qs, @accountId } = options
    @qs.setFragment('call-tracking/custom')
    @viewModel = new Backbone.Model(
      addButtonDisabled: false
    )

  onRender: =>
    @customCollection = new CustomCallTrackingСollection()
    @callFlowPickerView = new CallflowPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      label: 'none'
    )
    @viewModel.set('addButtonDisabled', @user().getSelectedSupportAccountId(true)?.toString() isnt @accountId.toString())
    @callFlowPicker = @getRegion('callFlowPicker')
    @callFlowPicker.show(@callFlowPickerView)
    @qs.bind(
      searchInfo: @ui.searchInfo
      callFlowId: @callFlowPicker.currentView
    )
    @qs.restore()
    @search()

  onDestroy: =>
    @stopListening()

  search: =>
    @collection = new CustomPagedCollection(
      page: @qs.get('page')
      queryParams:
        searchInfo: @ui.searchInfo.val().trim()
        callFlowId: @callFlowPicker.currentView.val()
        accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      url: 'custom'
    )
    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, qs: @qs))
    @collection.fetch(reset: true)

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    domainCell = class extends Backgrid.Cell
      render: ->
        info = JSON.parse(@model.get('extraInfo') ? '{}')
        $(@el).html(info?.domain)
        @

    domainHeaderCell = class extends Backgrid.HeaderCell
      onClick: ->
        return
      render: ->
        $(@el).html('<a>Destination URL</a>')
        @

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:smartNumber.id',
      label : ':smartNumber.number',
      attrs : ['smartNumber.id', 'smartNumber.number'])

    flowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    columns = [
      {label: 'Account',         name: 'account.name',   cell: 'string', renderable: @getBinding('isSupport')}
      {label: 'Name',            name: 'labelTracking',  cell: 'string'}
      {label: 'Originating URL', name: 'name',           cell: 'string'}
      {label: 'Destination URL', name: 'extraInfo',      cell: domainCell, headerCell: domainHeaderCell}
      {label: 'Call Flow',       name: 'callFlow',       cell: flowCell}
      {label: 'Number',          name: 'number',         cell: numbCell}
      {label: 'Campaign',        name: 'campaign',       cell: 'string'}
      {label: 'Date Created',    name: 'createdDate',    cell: datetime}
      {label: 'Date Last Used',  name: 'smartNumber.dateLastUsed', cell: datetime}
    ]

    new Backgrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No custom call tracking found.'
    )

  initModal: =>
    @customCollection.reset([new CustomCallTrackingModel()])
    @customModal = new CustomModal(
      accountId: @accountId
      collection: @customCollection
    )
    @listenTo(@customCollection, 'newCallTracking', (custom) =>
      custom['name'] = custom.channel
      @collection?.unshift(custom)
      @collection?.pop() if @collection.length > 30
      @collection.state.totalRecords += 1
      @collection.trigger('pager:state:changed', @collection.state)
      toastr.info('Custom Call Tracking saved.')
      @search() if @collection.length > 30
    ) if not @customCollection?._events?.newCallTracking
    @listenTo(@customModal, 'openAddressStepModal', => @initAddressStepModal())

  create: =>
    @initModal()
    @showChildView('modal', @customModal)

  initAddressStepModal: =>
    @addressCollection = new AddressCollection()
    addressStepModal = new AddressStepModal(
      accountId: @accountId
      addressCollection: @addressCollection
      title: 'Add Custom Call Tracking'
      collection: @customCollection
    )
    @showChildView('addressStepModal', addressStepModal)
    $('#address-step-modal').modal()
    @listenTo(addressStepModal, 'repeatCreateCallTrackings', (ladda, notCreatedAddresses) =>
      notAddressesNumbers = CallTrackingHelper.filterRepeatCollection(notCreatedAddresses, @customCollection) if notCreatedAddresses.length isnt 0
      @customCollection.createCustoms(@accountId, notAddressesNumbers, =>
        ladda.stop()
        createdAddresses = @addressCollection.where(created: true)
        @addressCollection.remove(createdAddresses) if createdAddresses.length isnt 0
        $('#address-step-modal').modal('hide') if not notAddressesNumbers or notAddressesNumbers.length is 0
      ))

module.exports = CustomView
