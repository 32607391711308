import React from 'react'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import RdnaNotification from '@ringdna/common/src/components/RdnaNotification'

/**
 * Custom hook that allows us to use a common interface for both the legacy toastr.js and
 * newer notistack material-ui snackbar lib and allows us to selectively turn the legacy lib on and off
 * per component.
 *
 * Once we have converted everything or no longer want to use toastr.js we can just flip
 * useLegacy to false.
 *
 * https://iamhosseindhv.com/notistack
 */

export enum VerticalPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum HorizontalPosition {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

type Options = {
  anchorOrigin: {
    vertical: VerticalPosition
    horizontal: HorizontalPosition
  }
}

const bottomLeft: Options = {
  anchorOrigin: { vertical: VerticalPosition.BOTTOM, horizontal: HorizontalPosition.LEFT }
}

const centered: Options = {
  anchorOrigin: { vertical: VerticalPosition.TOP, horizontal: HorizontalPosition.CENTER }
}

export const PredefinedOptions = {
  bottomLeft,
  centered
}

type VariantType = 'error' | 'success' | 'warning' | 'info'

export default function useToast(useLegacy = true) {
  const { enqueueSnackbar } = useSnackbar()
  const makeToastMessage = useCallback(
    (msg: string, type: VariantType, options: Options = PredefinedOptions.centered) => {
      function newMessage(msg: string, variant: VariantType, options: Options) {
        enqueueSnackbar(msg, {
          variant: variant,
          persist: false,
          content: function getContent(key) {
            // @ts-ignore
            return <RdnaNotification key={key} message={msg} type={variant} style={{ minWidth: '60vw' }} />
          },
          ...options
        })
      }
      function oldMessage(msg: string, variant: VariantType, options: Options) {
        toastr.options = {
          ...toastr.options,
          positionClass: `toast-${options.anchorOrigin.vertical}-${options.anchorOrigin.horizontal}`
        }
        toastr[variant](msg)
      }

      return useLegacy ? oldMessage(msg, type, options) : newMessage(msg, type, options)
    },
    [enqueueSnackbar, useLegacy]
  )

  const api = useMemo(
    () => ({
      info(msg: string, options?: Options) {
        makeToastMessage(msg, 'info', options)
      },

      error(msg: string, options?: Options) {
        makeToastMessage(msg, 'error', options)
      },

      success(msg: string, options?: Options) {
        makeToastMessage(msg, 'success', options)
      },

      warning(msg: string, options?: Options) {
        makeToastMessage(msg, 'warning', options)
      }
    }),
    [makeToastMessage]
  )

  return api
}
