BaseModel = require('base/model')

class CallDispositionModel extends BaseModel
  urlRoot: 'callDispositions'

  defaults:
    disposition: ''
    outcomeCategory: null

  validate: (attrs) ->
    if not attrs.disposition.trim()
      return 'Please enter disposition.'
    return

module.exports = CallDispositionModel
