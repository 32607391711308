import styled from 'styled-components'

export const Section = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: ${({ theme }) => theme.spacing}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  margin: ${({ theme }) => theme.spacing * 2}px 0;
  padding: ${({ theme }) => theme.spacing * 3}px;

  .rdna-text {
    margin: 0 ${({ theme }) => theme.spacing * 2}px;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
  align-items: center;
`

export const TeamsUsers = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 5}px;
`
