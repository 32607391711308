import React, { useMemo, useRef, useState, useCallback } from 'react'

// utils
import { SMART_NUMBER_COLUMNS } from './const'
import { SMART_NUMBER_TABLE } from './strings'
import { useSmartNumbers, useDeleteSmartNumber } from './api'
import { SmartNumberResultTypes, SmartNumberDeleteModalProps, Pagination } from './types'

// components
import RdnaSmartTable, {
  RdnaSmartTableRef,
  getActivePageForDelete
} from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'

const SERVER_PAGE_OFFSET = 1

const SmartNumbersTable = (props: { callflowId: string }) => {
  const { callflowId } = props
  const [deleteId, setDeleteId] = useState<number | null>()
  const deleteSmartNumber = useDeleteSmartNumber()
  const [pagination, setPagination] = useState<Pagination>({ pageIndex: 0 })
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const NO_RESULT_STATE = { title: SMART_NUMBER_TABLE.noResultsToDisplay, subtitle: '' }
  const smartTableRef = useRef<RdnaSmartTableRef | undefined>()

  const [payload, , loading, refetch] = useSmartNumbers({
    meta: { callflowId, page: pagination.pageIndex + SERVER_PAGE_OFFSET, limit: 10 }
  })

  const totalRowsRef = useRef()
  // cache the total rows so we dont lose that info while fetching the next page
  if (payload?.total) totalRowsRef.current = payload?.total

  const handleActionMenu: any = useCallback(
    // @ts-ignore
    (smartNumberId: number) => {
      setDeleteId(smartNumberId)
      setIsConfirmationModalOpen(true)
    },
    []
  )

  const setQueryState = useCallback(
    // @ts-ignore
    ({ pageIndex, pageSize, sortBy }) => {
      setPagination({
        pageIndex,
        pageSize,
        sortBy
      })
    },
    [setPagination]
  )

  async function handleDelete() {
    try {
      await deleteSmartNumber({ meta: { callflowId, id: deleteId } })
      setIsConfirmationModalOpen(false)
      const activePage = getActivePageForDelete(payload.total, pagination.pageIndex)
      smartTableRef.current?.gotoPage(activePage)
      refetch()
      // trackEvent('admin-smart-number-delete')
    } catch (e) {
      toastr.error(e)
    }
  }

  const memoPayload = useMemo(() => {
    return payload?.results?.map((result: SmartNumberResultTypes) => ({
      id: `${result.smartNumber.id}`,
      name: result.smartNumber.label || '',
      campaign: result.campaign || '',
      campaignStatus: result.campaignStatus || '',
      sfCampaignId: result.sfCampaignId ? `${result.sfCampaignId}` : '',
      extraInfo: result.extraInfo ? JSON.stringify(result.extraInfo).replace(/\\/g, '').replace(/"/g, '') : '',
      number: result.smartNumber.number || '',
      menu: (
        <RdnaMenu
          options={[{ text: SMART_NUMBER_TABLE.delete }]}
          onSelect={() => handleActionMenu(result.smartNumber.id)}
        />
      )
    }))
  }, [payload, handleActionMenu])

  return (
    <>
      <RdnaSmartTable
        ref={smartTableRef}
        columns={SMART_NUMBER_COLUMNS}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        setQueryState={setQueryState}
        isFetching={!!loading}
        paginationSize={10}
        totalItems={totalRowsRef.current || 1}
      />
      <ConfirmSmsDeleteModal
        open={isConfirmationModalOpen}
        onClose={setIsConfirmationModalOpen}
        onConfirm={handleDelete}
      />
    </>
  )
}

const ConfirmSmsDeleteModal = ({ open, onClose, onConfirm }: SmartNumberDeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={SMART_NUMBER_TABLE.cancel}
      confirmButtonText={SMART_NUMBER_TABLE.delete}
      heading={SMART_NUMBER_TABLE.deleteSmartNumber}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{SMART_NUMBER_TABLE.AreYouSureYouWantToDelete}</RdnaText>
    </RdnaModal>
  )
}

export default React.memo(SmartNumbersTable)
