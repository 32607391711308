import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { AccountIntegrations, NylasSettingModel } from '../../components/integrations/models'
import { HttpMethods } from '@ringdna/common/src/client/constants'

const API_BASE = '/api/v2/app'

type UseAccountsParams = {
  meta: {
    id: number
  }
}
export const useAccountIntegrations = createUseFetch<AccountIntegrations, UseAccountsParams>({
  key: 'use-account-integrations',
  path: ({ meta }) => `${API_BASE}/accounts/${meta.id}/integrations`,
  independent: true
})

type PutIntegrationParam = {
  body: NylasSettingModel
}

export const usePutAccountIntegration = createUseFetchAction<void, PutIntegrationParam>({
  key: 'put-account-integrations',
  path: params => `${API_BASE}/nylas/settings/${params.body.id}`,
  method: HttpMethods.Put
})

export enum RecordType {
  Account = 'Account',
  Contact = 'Contact',
  Lead = 'Lead'
}

export type AdditionalFilter = {
  id: number
  deleted: boolean
  query: string
  sObjectType: RecordType
}

export const useAdditionalFilters = createUseFetch<AdditionalFilter[], UseAccountsParams>({
  key: 'use-additional-filters',
  path: ({ meta }) => `${API_BASE}/salesforceFilters/${meta.id}`
})

type PutAdditionalFilters = {
  meta: {
    id: number
  }
  body: Record<string, any>
}

export const usePutAdditionalFilters = createUseFetchAction<void, PutAdditionalFilters>({
  key: 'put-additional-filters',
  path: params => `${API_BASE}/salesforceFilters/${params.meta.id}`,
  method: HttpMethods.Put
})
