var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<iframe src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"salesforceUrl") || (depth0 != null ? lookupProperty(depth0,"salesforceUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"salesforceUrl","hash":{},"data":data,"loc":{"start":{"line":15,"column":13},"end":{"line":15,"column":30}}}) : helper)))
    + "/secur/logout.jsp\" height=\"1\" width=\"1\" noborder=\"1\" style=\"display:none;\"></iframe>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + container.escapeExpression((lookupProperty(helpers,"imageUrl")||(depth0 && lookupProperty(depth0,"imageUrl"))||container.hooks.helperMissing).call(alias1,"revenue-lockup.png",{"name":"imageUrl","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":43}}}))
    + "\" class=\"rdna-logo\"/>\n<div class=\"welcome\">Welcome to revenue.io</div>\n<div class=\"signIn\">Sign into the revenue.io Console with your Salesforce username and password</div>\n<button data-action=\"salesforce-login\">Log in</button>\n<div class=\"sfdc-connection\">\n  <span>Select the connection:</span><br />\n  <select class=\"connection\">\n    <option value=\"production\">Production</option>\n    <option value=\"sandbox\">Sandbox</option>\n    <option value=\"custom\">Custom</option>\n  </select>\n  <input class=\"custom-url\" type=\"text\" placeholder=\"URL\">\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"salesforceLogout") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"flash\"></div>\n";
},"useData":true});