BaseView = require('base/view')

class DeletionPolicyView extends BaseView
  API_PATH = '/api/v2/app/deletion/policy'

  template: require('./template')

  className: 'deletion-policy'

  bindings:
    '.dataDeletionPolicy'       : 'checked:dataDeletionPolicyEnabled'
    '.dataDeletionPolicyLength' : 'value:dataDeletionPolicyLength, disabled:not(dataDeletionPolicyEnabled)'

  initialize: (options) ->
    { @accountId } = options
    @model = new Backbone.Model(
      dataDeletionPolicyEnabled: false
      dataDeletionPolicyLength: null
    )
    @originalLength = null
    App.api.get(
      path: API_PATH
      data    : accountId: @accountId
      success: (response) =>
        if response and response.length
          @model.set(
            dataDeletionPolicyEnabled: true
            dataDeletionPolicyLength: response.length
          )
          @originalLength = response.length
          @$('.dataDeletionPolicy').bootstrapToggle('on')
    )

  validate: =>
    @model.set('dataDeletionPolicyLength', null) unless @model.get('dataDeletionPolicyEnabled')
    deletionPolicy = @model.get('dataDeletionPolicyLength')
    if @model.get('dataDeletionPolicyEnabled') and (not deletionPolicy or deletionPolicy is 0 or deletionPolicy is '0')
      toastr.warning('Please enter a Data Deletion Storage.')
      return false
    true

  save: =>
    if @model.get('dataDeletionPolicyLength') and @originalLength and String(@model.get('dataDeletionPolicyLength')) is String(@originalLength)
      return
    if @model.get('dataDeletionPolicyEnabled') and @model.get('dataDeletionPolicyLength')
      App.api.post(
        path: API_PATH
        data:
          accountId : @accountId
          days : @model.get('dataDeletionPolicyLength')
        success: (response) =>
          if response and response.length
            @originalLength = response.length
      )
    else
      App.api.delete(
        path: API_PATH
        data: accountId : @accountId
        success: (response) =>
          @originalLength = null
      )


module.exports = DeletionPolicyView
