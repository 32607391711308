import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ratio = 17 / 15
const CallNotesIcon = ({ color = Colors.N100, size = 15 }: BaseIconProps) => (
  <svg width={size} height={size * ratio} viewBox="0 0 15 17" fill="none">
    <path
      d="M11 9v6.7a.3.3 0 01-.3.3H1.3a.3.3 0 01-.3-.3V2.3a.3.3 0 01.3-.3H6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.485.707l2.122 2.121-5.22 5.22-3.182 1.06 1.06-3.181 5.22-5.22z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path fill={color} d="M10.071 1.414L12.9 4.242l-.708.708L9.364 2.12z" />
  </svg>
)

export default CallNotesIcon
