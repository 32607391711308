import React from 'react'
import { AccountDialerSettings, AudioUploadSettings, FeatureVisibilities, OriginalSettings } from './types'
import { Feature, FeatureType } from '@ringdna/common/src/types/dialer-settings'

export enum ActionType {
  STORE_ACCOUNT_SETTINGS = 'STORE_ACCOUNT_SETTINGS',
  STORE_FEATURE_VISIBILITIES = 'STORE_FEATURE_VISIBILITIES',
  CHANGE_ACCOUNT_SETTINGS = 'CHANGE_ACCOUNT_SETTINGS',
  CHANGE_FEATURE_VISIBILITIES = 'CHANGE_FEATURE_VISIBILITIES',
  CHANGE_S3_RECORDING_BUCKET_CONFIRM = 'CHANGE_S3_RECORDING_BUCKET_CONFIRM',
  ADD_TO_AUDIO_UPLOAD_SETTINGS = 'ADD_TO_AUDIO_UPLOAD_SETTINGS',
  REMOVE_FROM_AUDIO_UPLOAD_SETTINGS = 'REMOVE_FROM_AUDIO_UPLOAD_SETTINGS',
  RESET_TO_ORIGINAL_SETTINGS = 'RESET_TO_ORIGINAL_SETTINGS',
  RESET_S3_RECORDING_BUCKET_CONFIRM = 'RESET_S3_RECORDING_BUCKET_CONFIRM',
  UPDATE_ORIGINAL_SETTINGS = 'UPDATE_ORIGINAL_SETTINGS'
}

type Action =
  | { type: ActionType.STORE_ACCOUNT_SETTINGS; payload: AccountDialerSettings }
  | { type: ActionType.STORE_FEATURE_VISIBILITIES; payload: Feature[] }
  | { type: ActionType.CHANGE_ACCOUNT_SETTINGS; payload: { [setting: keyof AccountDialerSettings | string]: any } }
  | {
      type: ActionType.CHANGE_FEATURE_VISIBILITIES
      payload: { featureType: FeatureType[keyof FeatureType]; value: boolean }
    }
  | { type: ActionType.CHANGE_S3_RECORDING_BUCKET_CONFIRM; payload: boolean }
  | { type: ActionType.ADD_TO_AUDIO_UPLOAD_SETTINGS; payload: AudioUploadSettings }
  | { type: ActionType.REMOVE_FROM_AUDIO_UPLOAD_SETTINGS; payload: keyof AccountDialerSettings | string }
  | { type: ActionType.RESET_TO_ORIGINAL_SETTINGS; payload: keyof AccountDialerSettings | 'All' }
  | { type: ActionType.RESET_S3_RECORDING_BUCKET_CONFIRM }
  | { type: ActionType.UPDATE_ORIGINAL_SETTINGS }

export type Dispatch = (action: Action) => void
export type SupportSettingsState = Readonly<{
  accountSettings: AccountDialerSettings
  featureVisibilities: FeatureVisibilities
  s3RecordingBucketConfirmation: boolean
  audioUploadSettings: AudioUploadSettings[]
  originalSettings: OriginalSettings
}>

// using `{} as any` allows us to start with an empty object instead of undefined or its initial type
export const initialState: SupportSettingsState = {
  accountSettings: {} as any as AccountDialerSettings,
  featureVisibilities: {} as any as FeatureVisibilities,
  s3RecordingBucketConfirmation: false,
  audioUploadSettings: [] as any as AudioUploadSettings[],
  originalSettings: {
    accountSettings: {},
    featureVisibilities: {},
    s3RecordingBucketConfirmation: false
  } as any as OriginalSettings
}

export function supportSettingsReducer(
  state: SupportSettingsState = initialState,
  action: Action
): SupportSettingsState {
  switch (action.type) {
    case ActionType.STORE_ACCOUNT_SETTINGS: {
      return {
        ...state,
        accountSettings: action.payload,
        originalSettings: {
          ...state.originalSettings,
          accountSettings: action.payload
        }
      }
    }
    case ActionType.STORE_FEATURE_VISIBILITIES: {
      return {
        ...state,
        featureVisibilities: action.payload,
        originalSettings: {
          ...state.originalSettings,
          featureVisibilities: action.payload
        }
      }
    }
    case ActionType.CHANGE_ACCOUNT_SETTINGS: {
      return {
        ...state,
        accountSettings: { ...state.accountSettings, ...action.payload }
      }
    }
    case ActionType.CHANGE_FEATURE_VISIBILITIES: {
      state.featureVisibilities
        .filter(visibility => visibility.feature === action.payload.featureType)
        .forEach(visibility => {
          visibility.visible = action.payload.value
        })
      return { ...state }
    }
    case ActionType.CHANGE_S3_RECORDING_BUCKET_CONFIRM: {
      return {
        ...state,
        s3RecordingBucketConfirmation: action.payload
      }
    }
    case ActionType.ADD_TO_AUDIO_UPLOAD_SETTINGS: {
      return { ...state, audioUploadSettings: [...state.audioUploadSettings, action.payload] }
    }
    case ActionType.REMOVE_FROM_AUDIO_UPLOAD_SETTINGS: {
      return {
        ...state,
        audioUploadSettings: state.audioUploadSettings.filter(
          (item: AudioUploadSettings) => item.setting !== action.payload
        )
      }
    }
    case ActionType.RESET_TO_ORIGINAL_SETTINGS: {
      const settingsName = action.payload
      if (settingsName === 'All') {
        return { ...state, ...state.originalSettings }
      }
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          [settingsName]: state.originalSettings.accountSettings[settingsName]
        }
      }
    }
    case ActionType.RESET_S3_RECORDING_BUCKET_CONFIRM: {
      return {
        ...state,
        s3RecordingBucketConfirmation: state.originalSettings.s3RecordingBucketConfirmation
      }
    }
    case ActionType.UPDATE_ORIGINAL_SETTINGS: {
      return {
        ...state,
        originalSettings: {
          accountSettings: state.accountSettings,
          featureVisibilities: state.featureVisibilities,
          s3RecordingBucketConfirmation: state.s3RecordingBucketConfirmation,
          audioUploadSettings: state.audioUploadSettings
        }
      }
    }
  }
}

// selectors
export const isFeatureVisibilitiesSelector = (state: SupportSettingsState, featureType: FeatureType): boolean => {
  const feature = state.featureVisibilities.find(feature => feature.feature === featureType)
  if (!feature) {
    throw new Error(`featureVisibilities not found for type: ${featureType}`)
  }
  return feature.visible
}

export const isSipRoutingSelector = (state: SupportSettingsState): string =>
  state.accountSettings['settings.sipRouting'] == null
    ? ''
    : state.accountSettings['settings.sipRouting']?.id.toString()

// context
export const SupportSettingsStateContext = React.createContext<SupportSettingsState | undefined>(undefined)
export const SupportSettingsDispatchContext = React.createContext<Dispatch | undefined>(undefined)

export function useSupportSettingsState() {
  const context = React.useContext(SupportSettingsStateContext)
  if (!context) {
    throw new Error('useSupportSettingsState must be used within a SupportSettingsProvider')
  }
  return context
}

export function useSupportSettingsDispatch() {
  const context = React.useContext(SupportSettingsDispatchContext)
  if (!context) {
    throw new Error('useSupportSettingsDispatch must be used within a SupportSettingsProvider')
  }
  return context
}
