BaseView = require('base/view')

class PhoneNumberItemView extends BaseView

  template: require('./item-template')

  bindings:
    '.account-id'      : 'text:accountId'
    '.user-id'         : 'text:userId'
    '.number-type'     : 'text:type'
    '.callflow-id'     : 'text:callFlowId'
    '.twilio-type'     : 'text:twilioType'
    '.number'          : 'text:number'
    '.message'         : 'text:message'
    '.status'          : 'text:status'

  className: =>
    if @model.get('status') is 'Failed'
      'row number-item failed'
    else
      'row number-item'

module.exports = PhoneNumberItemView
