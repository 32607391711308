import React, { useEffect, useMemo, useState } from 'react'

import { useSalesforceInspector, useUpdateSalesforceRunUsUser } from '../../../../../store/api/salesforceInspector-api'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaText from '@ringdna/common/src/components/RdnaText'

type Props = {
  accountId: number
}

type Option = {
  label: string
  value: string
  sfUserId: string
}

export default SalesforceConnect
function SalesforceConnect({ accountId }: Props) {
  const [salesforceInspector, , loading] = useSalesforceInspector({ meta: { id: accountId } })
  const updateSalesforceRunUsUser = useUpdateSalesforceRunUsUser()
  const [sfRunAsUserId, setSfRunAsUserId] = useState('')
  const [sfRunAsUserSfId, setSfRunAsUserSfId] = useState('')

  const listSfRunAsUsers: Option[] = useMemo(() => {
    let listUsers: Option[] = []
    if (salesforceInspector) {
      listUsers = salesforceInspector.listSfRunAsUsers.map(user => ({
        label: user.displayName,
        value: user.id.toString(),
        sfUserId: user.sfUserId
      }))
      const connectUserIncluded = listUsers.find(user => user.value === salesforceInspector?.connectUser.id.toString())
      if (salesforceInspector.connectUser && !connectUserIncluded) {
        listUsers.push({
          label: salesforceInspector.connectUser.displayName,
          value: salesforceInspector.connectUser.id.toString(),
          sfUserId: salesforceInspector.connectUser.sfUserId
        })
      }
    }
    return listUsers
  }, [salesforceInspector])

  useEffect(() => {
    if (salesforceInspector?.connectUser) {
      setSfRunAsUserId(salesforceInspector.connectUser.id.toString())
      setSfRunAsUserSfId(salesforceInspector.connectUser.sfUserId)
    }
  }, [salesforceInspector])

  const changeSfRunAsUser = async (e: any) => {
    const userId = e.target.value
    const user = listSfRunAsUsers.find(option => option.value === userId)
    if (user) {
      try {
        await updateSalesforceRunUsUser({ body: { accountId: accountId, userId: Number(userId) } })
        setSfRunAsUserId(user.value)
        setSfRunAsUserSfId(user.sfUserId)
      } catch (error) {
        toastr.error(error.message)
      }
    }
  }

  return (
    <>
      <RdnaSettingRow heading={'Salesforce Connect User'} description={''}>
        {(loading || listSfRunAsUsers.length > 0) && (
          <RdnaSelect
            onChange={changeSfRunAsUser}
            name="list-run-us-users"
            options={listSfRunAsUsers}
            value={sfRunAsUserId}
            onBlur={() => {}}
            showPlaceholderAsOption={false}
            placeholder="Loading..."
            className="rdna-select"
            isLoading={!!loading}
          />
        )}
        {!loading && !listSfRunAsUsers.length && <RdnaText>{'No run-as user found.'}</RdnaText>}
      </RdnaSettingRow>
      <RdnaSettingRow heading={'Salesforce ID'} description={''}>
        <RdnaTextInput disabled className="support-input" value={sfRunAsUserSfId} name={'sfId'} />
      </RdnaSettingRow>
    </>
  )
}
