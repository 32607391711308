BaseGrid        = require('base/grid')
BackgridRow     = require('helpers/backgrid-row')
BaseView        = require('base/view')
PagedCollection = require('base/paged-collection')
PagerView       = require('widgets/pager/view')

class ExistingNumbersCollection extends PagedCollection
  state:
    pageSize: 5
  url: 'smartNumbers/reassign'
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    results = []
    for number in response.results
      if number.user
        number['name'] = number.user.displayName
      else if number.queue
        number['name'] = number.queue.name
      else if number.entities.length
        entities = number.entities[0]
        number['name'] = if entities.callFlow then entities.callFlow.name else entities.name
      number['labelName'] = number.label
      results.push(number)
    results

class Step1ExistingModal extends BaseView
  template: require('./step-1-template')

  bindings:
    '.stepPrevious' : 'toggle: localPresenceType'
    '.next' : 'disabled: disabledNext'

  computeds:
    disabledNext:
      deps: ['selectedNumbers']
      get: (selectedNumbers) ->
        not selectedNumbers.length
    localPresenceType:
      deps: ['type']
      get: (type) ->
        type isnt 'local-presence'

  events:
    'click @ui.searchExistingNumbers' : 'search'
    'keyup input:text' : 'keyPressed'

  regions:
    grid: '.existing-numbers-list'
    pager: 'span.pager'

  ui:
    existingNumber : 'input.existing-number'
    ownerName      : 'input.owner-name'
    searchExistingNumbers : 'button.search-existing-numbers'
    typeValue      : '.type-of-number'

  onRender: ->
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: ->
    collection = new ExistingNumbersCollection(
      queryParams:
        number    : @ui.existingNumber.val()
        accountId : @model.get('accountId')
        owner     : @ui.ownerName.val()
        reassigningNumbers  : true
        showLPNumbers       : @model.get('showLPNumbers')
        filterType          : @ui.typeValue.val() if @ui.typeValue.val() isnt 'All'
    )
    @detachChildView('grid')
    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection))
    collection.fetch(reset: true)

  buildGrid: (collection) =>
    reassignCell = @reassignExistingCell(@model.get('selectedNumbers'))
    cols = [
      {label: 'Number',         name: 'number',           cell: 'string'}
      {label: 'Type',           name: 'type',             cell: 'string'}
      {label: 'Owner',          name: 'name',             cell: 'string'}
      {label: 'Label',          name: 'labelName',        cell: 'string'}
      {label: '',               name: '',                 cell: reassignCell}
    ]

    new BaseGrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart numbers found.'
    )

  reassignExistingCell: (reassignedNumbers) ->
    mainClass = @
    class extends Backgrid.Cell
      className: 'reassign-cell'
      events:
        'click .reassign': 'addToReassigned'
        'click .remove': 'removeFromReassigned'
      addToReassigned: ->
        @$('.reassign').hide()
        @$('.remove').show()
        switch mainClass.model.get('type')
          when 'account'
            type = 'CentralNumber'
          when 'call-flow'
            type = 'Tracking'
          when 'queue'
            $('.reassign').prop('disabled', true)
            type = 'Default'
          when 'local-presence'
            type = 'LocalPresence'
          else
            type = ''
        model = new Backbone.Model(
          id: @model.get('id')
          type: type
          number: @model.get('number')
          label : @model.get('label')
          source: @model.get('entities')[0]?.source
        )
        reassignedNumbers.add(model)
        mainClass.applyBindings()
      removeFromReassigned: ->
        @$('.remove').hide()
        @$('.reassign').show()
        if mainClass.model.get('type') is 'queue'
          $('.reassign').prop('disabled', false)
        else
          @$('.reassign').prop('disabled', false)
        reassignedNumbers.remove(@model.id)
        mainClass.applyBindings()
      render: ->
        switch mainClass.model.get('type')
          when 'account'
            disabled = @model.get('type') is 'CentralNumber'
          when 'call-flow'
            disabled = @model.get('entities')?[0]?.callFlow?.id is mainClass.model.get('objectId')
          when 'local-presence'
            disabled = @model.get('type') is 'LocalPresence'
          when 'user'
            disabled = @model.get('user')?.id is mainClass.model.get('objectId')

        title = ''
        if disabled
          title  = 'title="Number already assigned to this owner"'

        $(@el).html("""
          <button class="btn btn-xs btn-primary reassign" #{title}>Reassign</button>
          <button class="btn btn-xs btn-danger remove">Remove</button>
        """)
        if @model.get('type') is 'Tracking' and not @model.get('entities')[0]?.callFlow and mainClass.model.get('type') is 'call-flow'
          @$('.reassign')[0].textContent = 'Add'
          @$('.reassign').addClass('not-assigned')
        reassigned = reassignedNumbers.get(@model.get('id'))
        @$('.reassign').prop('disabled', mainClass.model.get('type') is 'queue' and mainClass.model.get('selectedNumbers').length or disabled)
        if reassigned then @$('.reassign').hide() else @$('.remove').hide()
        @

module.exports = Step1ExistingModal
