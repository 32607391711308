import React, {useMemo, useCallback, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import {
  CallNotesTemplatesProps,
  CallNoteTeamTemplate,
  CallNoteTemplate,
  CallNoteTemplateForm,
  OptionTeam,
  TeamDefaultFilterType
} from '../../types'
import {useCallNoteTemplates, useCallNoteTemplatesUpdate} from '../../api'
import {formatTeamsStringForColumns, getTeamOptions, renderTeamOption} from '../../utils/utils'
import {initCallNotesTemplate, NO_RESULT_STATE, NOTES_TEMPLATES_STR} from './const'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import {Body} from '../../styles'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import CallNotesTemplatesModal from './CallNotesTemplatesModal'
import ConfirmCallNotesTemplateDeleteModal from './ConfirmCallNotesTemplateDeleteModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import styled from 'styled-components'


export default function CallNotesTemplatesTable({ accountId, accountSettings, setAccountSettings, teams }: CallNotesTemplatesProps) {
  const { error } = useRdnaNotification()
  const updateCallNoteTemplate = useCallNoteTemplatesUpdate()
  const [payload, , loading, refetch] = useCallNoteTemplates({meta:{accountId: accountId.toString()}})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [callNotesTemplate, setCallNotesTemplate] = useState<CallNoteTemplateForm>(initCallNotesTemplate)

  const [selectedTeam, setSelectedTeam] = useState<OptionTeam | null>(null)
  const [filteredTemplates, setFilteredTemplates] = useState<CallNoteTemplate[]>([])
  const handleTeamChange = (option: OptionTeam | null) => {
    setSelectedTeam(option ? {text: option.text, value: option.value} : null)
  }

  const teamsOptions: OptionTeam[] = getTeamOptions(teams)
  const defaultOptions = [{ text: 'All', value: TeamDefaultFilterType.ALL }]

  useDeepCompareEffect(() => {
    if (!payload) {
      setFilteredTemplates([])
    } else if (selectedTeam == null) {
      setFilteredTemplates(payload)
    } else if (selectedTeam.value == TeamDefaultFilterType.ALL) {
      setFilteredTemplates(payload.filter(template => template.teams.length == 0))
    } else {
      setFilteredTemplates(payload.filter(template => template.teams.filter(teamTemplate => teamTemplate.team.id == selectedTeam.value).length > 0))
    }
  }, [payload, setFilteredTemplates, selectedTeam])

  const handleActionMenu = useCallback((action: string, template: CallNoteTemplate) => {
    setCallNotesTemplate({
      id: template.id,
      name: template.name,
      template: template.template,
      enabled: template.enabled,
      teamIds: getTeamIds(template.teams)
    })
    if (action === NOTES_TEMPLATES_STR.delete) {
      setIsDeleteModalOpen(true)
    } else if (action === NOTES_TEMPLATES_STR.edit) {
      setIsModalOpen(true)
    }
  }, [])

  const handleToggle = useCallback((template: CallNoteTemplate, toggle: boolean) => {
    toggleUpdate({
      id: template.id,
      name: template.name,
      template: template.template,
      enabled: toggle,
      teamIds: getTeamIds(template.teams)
    })
  }, [])

  const getTeamIds = (teams: CallNoteTeamTemplate[]) => {
    if (!teams) {
      return []
    }
    const ids = teams.map((team: CallNoteTeamTemplate) => {
      return team.team.id
    })
    return ids
  }

  async function toggleUpdate(template: CallNoteTemplateForm) {
    try {
      await updateCallNoteTemplate({
        meta: { id: template.id },
        body: {
          accountId: accountId,
          name: template.name,
          template: template.template,
          enabled: template.enabled,
          teamIds: template.teamIds
        }
      })
    } catch (e) {
      error(getErrorMessage(e))
    }
  }

  async function closeModals(isFetch: boolean) {
    setIsModalOpen(false)
    setIsDeleteModalOpen(false)
    if (isFetch) {
      refetch()
    }
  }

  const memoPayload = useMemo(() => {
    return filteredTemplates.map((template: CallNoteTemplate) => ({
      ...template,
      teams: formatTeamsStringForColumns(template.teams, 5),
      menu: (
        <RdnaMenu options={[{ text: NOTES_TEMPLATES_STR.edit }, { text: NOTES_TEMPLATES_STR.delete }]} onSelect={item => handleActionMenu(item, template)} />
      ),
      enabled: (
        <CallNotesTemplateEnableCheckbox
          enabled={template.enabled}
          onToggle={(e: boolean) => handleToggle(template, e)}
        ></CallNotesTemplateEnableCheckbox>
      )
    }))
  }, [filteredTemplates, handleActionMenu])

  const notesTemplatesColumns = [
    {
      Header: 'Enabled',
      accessor: NOTES_TEMPLATES_STR.enabled,
      disableSortBy: true,
      width: 30
    },
    {
      Header: 'Name',
      accessor: NOTES_TEMPLATES_STR.name
    },
    {
      Header: 'Teams',
      accessor: NOTES_TEMPLATES_STR.teams,
      disableFilters: false,
      Filter: function fn() {
        return (
          <RdnaAutocomplete
            id={'teams-filter'}
            options={[...defaultOptions, ...teamsOptions]}
            placeholder={'Filter by Team'}
            value={selectedTeam ? selectedTeam : undefined}
            isOptionEqualToValue={(option: OptionTeam, value: OptionTeam) => {
              return option.value === value.value
            }}
            onChange={(event: React.SyntheticEvent<Element, Event>, option: OptionTeam) => {
              return handleTeamChange(option ? option : null)
            }}
            getOptionLabel={option => option.text}
            renderOption={renderTeamOption}
          />
        )
      },
      Cell: function createdCell(data: { value: string }) {
        return <CellText>{data.value}</CellText>
      }
    },
    {
      Header: 'Actions',
      accessor: 'menu',
      disableSortBy: true,
      styles: {
        align: 'right'
      },
      width: 30
    }
  ]

  return (
    <Body>
      <RdnaHeaderSection
        descriptionText={NOTES_TEMPLATES_STR.headerOfTable}
        rightColumn={ <RdnaButton
          style={{ width: 'fit-content' }}
          disabled={!!loading}
          text={'Add Template'}
          name="add-template"
          onClick={() => {
            setCallNotesTemplate(initCallNotesTemplate)
            setIsModalOpen(true)
          }}
        />}
      />
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={notesTemplatesColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        isFetching={!!loading}
        paginationSize={10}>
      </RdnaSmartTable>
      <CallNotesTemplatesModal
        open={isModalOpen}
        onClose={closeModals}
        accountId={accountId}
        callNotesTemplate={callNotesTemplate}
        setCallNotesTemplate={setCallNotesTemplate}
        teams={teamsOptions}
      />
      <ConfirmCallNotesTemplateDeleteModal
        open={isDeleteModalOpen}
        onClose={closeModals}
        id={callNotesTemplate.id}
      />
    </Body>
  )
}

const CallNotesTemplateEnableCheckbox = ({ enabled, onToggle }: { enabled: boolean; onToggle: (e: boolean) => void }) => {
  const [checked, setChecked] = useState(enabled)
  return (
    <RdnaCheckbox
      value={checked}
      onChange={(value: boolean) => {
        onToggle(value)
        setChecked(value)
      }}
    />
  )
}

export const CellText = styled(RdnaText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial !important;
`
