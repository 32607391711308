export const HEADER_TEXT = 'Export Users'
export const CONFIRM_BUTTON_TEXT = 'Generate'
export const CANCEL_BUTTON_TEXT = 'Cancel'
export const DETAIL_STATUS_TEXT = 'Status'
export const DETAIL_USER_NAME_TEXT = 'User Name'
export const DETAIL_USER_EMAIL_TEXT = 'User Email'
export const DETAIL_SALESFORCE_ID_TEXT = 'Salesforce ID'
export const EMAIL_DESCRIPTION_TEXT =
  'Enter the email address that should receive the download link for the export when it is ready.'
export const DETAILS_HEADER_TEXT = 'Export Details'
export const EMAIL_INPUT_PLACEHOLDER = 'Email Address'
