require('./vendor.coffee')
require('./styles/application.sass')

config = require('../src/config').default

window.sentryClient = require('@sentry/browser')
window.sentryClient.init({
  dsn: config.sentryDsn,
  environment: config.rdEnv
})

window.App = require('core/app')
$(document).ready( ->
  App.start()
)
