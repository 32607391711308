BaseGrid = require('base/grid')
BaseView = require('base/view')
UserStatusModel = require('./model')
UserStatusCollection = require('./collection')

class CustomUserStatusView extends BaseView
  template: require('./template')

  className: 'custom-user-status'

  regions:
    grid: 'div.grid'

  initialize: (options) ->
    { @accountId, @teamId } = options
    @collection = new UserStatusCollection()

  onRender: ->
    @collection
      .fetch(data: 
        accountId: @accountId
        teamId: @teamId
      )
      .done(=>
        return if not @getRegion('grid')
        @showChildView('grid', @buildGrid())
    )

  buildGrid: =>
    busyCell = class extends Backgrid.Cell
      render: ->
        busyText = if @model.get('busy') then 'Yes' else 'No'
        $(@el).html(busyText)
        @

    defaultExpirationCell = class extends Backgrid.Cell
      render: ->
        defaultExpiration = @model.get('defaultExpiration')
        defaultExpiration = if defaultExpiration then defaultExpiration else 'No Expiration'
        $(@el).html(defaultExpiration)
        @

    columns = [
      { name: 'name',        label: 'Name',        cell: 'string' },
      { name: '',            label: 'Busy',        cell: busyCell },
      { name: '',            label: 'Time',        cell: defaultExpirationCell },
      { name: 'description', label: 'Description', cell: 'string' }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No user statuses found.'
    )

module.exports = CustomUserStatusView