import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { Section, SectionHeader } from '../styles'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import Records from '@ringdna/common/src/assets/icons/svg/Records'

export default MultiMatchScenarios
function MultiMatchScenarios() {
  const state = useSupportSettingsState()

  const sfdcMultipleMatchLeadField = 'settings.sfdcMultipleMatchLeadField'
  const sfdcMultipleMatchContactField = 'settings.sfdcMultipleMatchContactField'
  const sfdcMultipleMatchAccountField = 'settings.sfdcMultipleMatchAccountField'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={Records} iconSize={14} variant="filled" />
        <RdnaText className={'rdna-text'}>Multi-Match Scenarios</RdnaText>
      </SectionHeader>
      <SupportSettingInputRow
        title={'Multi-Match Lead Field'}
        value={state.accountSettings[sfdcMultipleMatchLeadField]}
        setting={sfdcMultipleMatchLeadField}
      />
      <SupportSettingInputRow
        title={'Multi-Match Contact Field'}
        value={state.accountSettings[sfdcMultipleMatchContactField]}
        setting={sfdcMultipleMatchContactField}
      />
      <SupportSettingInputRow
        title={'Multi-Match Account Field'}
        value={state.accountSettings[sfdcMultipleMatchAccountField]}
        setting={sfdcMultipleMatchAccountField}
      />
    </Section>
  )
}
