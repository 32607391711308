AdwordsCallTrackingСollection = require('./../adwords-collection')
CallTrackingHelper = require('features/call-tracking/helper')

class AdWordsCallTrackingModal extends Backbone.Modal
  template: _.template('<div class="modals-container"></div>')

  viewContainer: '.modals-container'

  submitEl: '.done'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1-view')
    'click #step2':
      view: require('./step-2-view')

  events:
    'keyup .stepPrevious': 'keyPressedPrevious'
    'keyup .stepNext': 'keyPressedNext'
    'click .stepPrevious': 'previousStep'
    'click .stepNext': 'nextStep'

  initialize: (options) =>
    { @accountId, @viewModel } = options

  onRender: =>
    @listenTo(@collection, 'add remove', =>
      @viewModel.set('finishEnable', @collection.length is 0))

  keyPressedPrevious: (e) =>
    @previousStep(e) if e.keyCode is 13

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  previousStep: (e) ->
    e.preventDefault()
    @previous()

  validate: =>
    error = null
    switch @currentIndex
      when 0
        error = 'Please add a tracks' if @collection.length is 0
      when 1
        error = 'Please correct the highlighted errors.' if @findErrorsAdWords()
    if error
      toastr.warning(error)
      return false
    true

  nextStep: (e) ->
    e.preventDefault()
    return unless @validate()
    switch @currentIndex
      when 0
        @next()
      when 1
        @createAdWordsCallTracking()

  createAdWordsCallTracking: =>
    ladda = Ladda.create(@$('.next')[0])
    ladda.start()
    @collection.createAdWords(null, (hasNeedAddress) =>
      ladda.stop()
      @trigger('openAddressStepModal') if hasNeedAddress
      @destroy())

  findErrorsAdWords: =>
    subjectError = CallTrackingHelper.findErrors(@collection)
    @collection.each((model) =>
      model.trigger('checkLabel') if not model.get('labelTracking')
      subjectError = true if not model.get('labelTracking'))
    subjectError

module.exports = AdWordsCallTrackingModal
