BackgridCell = require('helpers/backgrid-cell')

module.exports.AvailableNumbers =
  [
    {name: 'number',    label: 'Number',            editable: false, cell: 'string'},
    {name: 'country',   label: 'Country',           editable: false, cell: 'string'},
    {name: 'selected',  label: 'Selected',          editable: true,  cell: BackgridCell.checkbox()},
    {name: 'errorDesc', label: 'Error Description', editable: false, cell: 'string' }
  ]

module.exports.State =
  [
    {name: 'state',         label: 'State',             editable: false, cell: 'string'},
    {name: 'areaCodesDesc', label: 'Area Codes',        editable: false, cell: 'string'},
    {name: 'needToProvide', label: 'Provide',           editable: true,  cell: BackgridCell.checkbox()},
    {name: 'needToDelete',  label: 'Delete',            editable: true,  cell: BackgridCell.checkbox()},
    {name: 'errorDesc',     label: 'Error Description', editable: false, cell: 'string' }
  ]
