import { SMS_TEMPLATE_STR } from './strings'

export const smsTemplateColumns = [
  {
    Header: SMS_TEMPLATE_STR.name,
    accessor: 'name'
  },
  {
    Header: SMS_TEMPLATE_STR.teams,
    accessor: 'teams'
  },
  {
    Header: '',
    accessor: 'empty' // silly string to render empty column
  },
  {
    Header: SMS_TEMPLATE_STR.enable,
    accessor: 'menu',
    styles: {
      align: 'right'
    }
  }
]

export const initFormData = {
  name: '',
  teams: [],
  template: '',
  enabled: true,
  files: []
}
