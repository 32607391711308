/**
 * A hash of all the available Launch Darkly (LD) features flags available to the app.
 *
 * Adding A New Flag:
 *
 * - Create your feature flag using the LD dashboard.
 * - copy the kabob-case key from LD
 * - enter the key into the hash below using the format:
 *   { UPPER_CASE_STRING_THATS_EASY_TO_READ: launch-darkly-kabob-case-key }
 *
 * Note: flags removed from LD should also be removed from this file to
 * avoid a mess of old flags.
 */

export const availableFlags = {
  CAI_ANNOTATION_TAGS: 'cai-annotation-tags',
  DIALER_DISABLE_CLICK_TO_CALL: 'dialer-disable-click-to-call',
  DIALER_REACT_MESSAGES: 'v284_ID-2375',
  DIALER_REACT_CALL_QUEUES: 'v284_ID-2376',
  DISPLAY_SOFTPHONE_VOICEMAIL_DROP: 'display-softphone-voicemail-drop',
  HOOVERS_INTEGRATION: 'hoovers-integration',
  MOMENTS_GENERATIVE_AI: 'moments-generative-ai',
  NYLAS_API_V3: 'v282_sfdc-6389_nylas-api-v3',
  NYLAS_RECONNECT: 'nylas-reconnect',
  PROMPT_MODAL: 'suggested-related-record-prompt-modal',
  REACT_HISTORY: 'react-history',
  REALTIME_TRANSCRIPTION: 'real-time-transcription',
  ROLES: 'v290_ID-2499_user_role',
  SALES_METHODOLOGY: 'sales-methodology',
  TEAMS_ACCOUNT_FORM: 'teams-account-form',
  TWILIO_CLIENT_MIGRATION: 'v292_ID-2580_Twilio_SDK_2',
  WHISPER: 'whisper',
  v281_CAI_3379: 'v-281-cai-3379-non-recorded-conversations',
  V283_MO_2811: 'v283_mo-2811_participant-record-page',
  v284_MO_3036: 'v284_mo-3036_auto-resolve-primary-contact',
  v286_REV_833: 'v286_rev-833_moments-behavior-dashboard',
  v287_REV_1264: 'v287_rev-1264_recipes',
  CALL_ACTIONS_REACT: 'v290_ID-1660_call-actions',
  v288_REV_1410: 'v288_rev-1410_profile_section',
  v290_ID_2608: 'v290_ID-2608_MMS',
  v291_REV_1806: 'v291_REV-1806_live-section',
  v291_REV_1622: 'v291_REV-1622_processing-internal-ms-teams-meetings',
  v294_REV_2034: 'v294_REV-2034_Highcharts-POC',
  v294_ID_3029: 'v294_ID-3029_Designate_TCPA_Check_Fields_Escoffier',
  v294_REV_2026: 'v294_REV-2026_Supervisor-Status-Update',
  v295_REV_2204: 'v295_REV_2204_Ask_Revenue',
  v294_ID_2850: 'ID-2850_grant_call_recording_access',
  v294_ID_2076: 'v294_ID-2076_Activity_Items_CRUD',
  v296_REV_1215: 'v296_REV_1215_External_Share',
  v296_REV_2433: 'v296_REV-2433_Ask_Revenue_Opportunity',
  v296_REV_2479: 'v296_REV-2479_Edit_Opportunities',
  v296_REV_2608: 'v296_REV-2608_GAI-Opportunity-Summary'
}
