import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

// utils
import { useAccount } from '../../store/api/accounts-api'
import { useCallRecordingDispatch } from './utils/contextReducer'
import { RdnaAccount } from '@ringdna/common/src/types/accounts'
import { Dispatch } from './utils/contextReducer'

// components
import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import RdnaHorizontalTab from '@ringdna/common/src/components/RdnaHorizontalTab'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import OverviewSubTab from './components/OverViewSubTab/_OverviewSubTab'
import AdvancedSubTab from './components/AdvancedSubTab/_AdvancedSubTab'
import UnitedStatesSubTab from './components/UnitedStatesSubTab/_UnitedStatesSubTab'
import InternationalSubTab from './components/InternationalSubTab/_InternationalSubTab'
import { parseAccountSettings } from '../../utils/account'
import SupportAccessTab from './components/SupportAccessTab/_SupportAccessTab'

import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

type CallRecordingTabProps = {
  accountId: string
  onSave: (callRecordingOverrideOption: string, accountCallRecordingLocked: boolean) => void
}

export default React.memo(CallRecordingTab)
function CallRecordingTab({ accountId, onSave }: CallRecordingTabProps) {
  const [account, , , ,] = useAccount({ meta: { id: accountId } })
  const [haveData, setHaveData] = useState(false)

  const dispatch = useCallRecordingDispatch()

  useEffect(() => {
    if (account) {
      storeSettings(dispatch, account)
      setHaveData(true)
    }
  }, [account, dispatch, setHaveData])

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: 'overview',
        title: 'Overview',
        Content: <OverviewSubTab accountId={accountId} onSave={onSave} />
      },
      {
        id: 'advanced',
        title: 'Advanced',
        Content: <AdvancedSubTab accountId={accountId} />
      },
      {
        id: 'united-states',
        title: 'United States',
        Content: <UnitedStatesSubTab accountId={accountId} />
      },
      {
        id: 'international',
        title: 'International',
        Content: <InternationalSubTab accountId={accountId} />
      }
    ]

    if (isFlagged(flags.v294_ID_2850, false)) {
      tabs.push({
        id: 'support-access',
        title: 'Support Access',
        Content: <SupportAccessTab />
      })
    }
    return tabs
  }, [accountId, onSave])

  return (
    <Container>
      <RdnaLoader
        data={account && [account]}
        noResultsMsg={<NoResultsMsg title="Something went wrong, try refreshing!" Icon={<BinocularsIconLeaves />} />}
        loading={!haveData}
        top="150px"
      >
        <RdnaHorizontalTab renderIndividually tabs={tabs} />
      </RdnaLoader>
    </Container>
  )
}

const storeSettings = (dispatch: Dispatch, account: RdnaAccount) => {
  // account settings
  const accountSettings = parseAccountSettings(account)

  // locked settings
  const lockedSettings = { 'lockedSetting.accountId': account.id }

  Object.keys(account.lockedSetting).forEach(key => {
    const playKey = `lockedSetting.${key}`

    // @ts-ignore
    lockedSettings[playKey] = account.lockedSetting[key]
  })

  dispatch({ type: 'STORE_ACCOUNT_SETTINGS', payload: accountSettings })
  dispatch({ type: 'STORE_LOCKED_SETTINGS', payload: lockedSettings })
}

const Container = styled.div`
  padding: 0 ${({ theme }) => theme.spacing * 3}px;
  min-height: 100vh;
  max-height: 100%;

  .rdna-button {
    margin: ${({ theme }) => theme.spacing * 2}px 0;
  }
`
