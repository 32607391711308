import { RdnaSelectOptions } from '../../RdnaFormElements/RdnaSelect'
import { FilterFormValues, PersistedFilters, ReportFilterType } from '../types'
import { UserData } from '../'
import { format, subDays, startOfDay } from 'date-fns'
import { PicklistData } from '../api'
import { sortedUniqBy } from 'lodash-es'
import { localTimeZone } from './metabase'

export const WILDCARD = '%'
export const QUERY_STORAGE_FILTERS = 'reportFilters'
export const SESSION_STORAGE_FILTERS = 'session-storage-report-filters'

export const getQueryStoredFilters = (): string | undefined => {
  const hashQuerystring = window.location.hash.split('?')[1]
  // if hash url and has a querystring parse it to see if we have query stored filters
  if (hashQuerystring) {
    let queryStorageFilter
    hashQuerystring.split('&').every(part => {
      const [key, value] = part.split('=')
      if (key === QUERY_STORAGE_FILTERS) {
        queryStorageFilter = value
        return false
      }
    })
    // if we do, return them
    return queryStorageFilter
  }
  // otherwise assume this is not a hash route and if present return query stored filters
  return new URLSearchParams(window.location.search).get(QUERY_STORAGE_FILTERS) || undefined
}

export const getPersistedFilters = (filters: ReportFilterType[]): FilterFormValues => {
  const mappedFilterNames = filters.map(fiilterName => mapFilterName(fiilterName))
  const rawQueryStorageFilters = getQueryStoredFilters()
  let queryStorageFilters
  if (rawQueryStorageFilters) {
    try {
      queryStorageFilters = atob(rawQueryStorageFilters)
    } catch {
      queryStorageFilters = atob(decodeURIComponent(rawQueryStorageFilters))
    }
  }
  if (queryStorageFilters) return JSON.parse(queryStorageFilters)
  const persistedFiltersString = sessionStorage.getItem(SESSION_STORAGE_FILTERS)
  const parsedFiltersJson: FilterFormValues = persistedFiltersString ? JSON.parse(persistedFiltersString) : {}
  for (const key of Object.keys(parsedFiltersJson)) {
    const typedKey = mapFilterName(key as ReportFilterType)
    if (mappedFilterNames.indexOf(typedKey) < 0 || parsedFiltersJson[typedKey] === '') {
      delete parsedFiltersJson[typedKey]
    }
  }
  return parsedFiltersJson
}

export const setPersistedFilters = (filterData: FilterFormValues) => {
  sessionStorage.setItem(SESSION_STORAGE_FILTERS, JSON.stringify(filterData))
}

export const addAllOption = (exisitingData: RdnaSelectOptions<any>[], allLabel: string): RdnaSelectOptions<any>[] => {
  return [
    {
      label: allLabel,
      value: WILDCARD
    },
    ...exisitingData
  ]
}

export const createUrlSafeQueryFilters = (reportFilters: PersistedFilters | undefined): string | undefined =>
  reportFilters ? encodeURIComponent(btoa(JSON.stringify(reportFilters))) : undefined

export const mapFilterName = (name: ReportFilterType): ReportFilterType => {
  switch (name) {
    case ReportFilterType.MOMENTS_CATEGORY:
      return ReportFilterType.CATEGORY
    default:
      return name
  }
}

const START_DATE_DEFAULT = 30
const START_DATE_QUARTER_DEFAULT = 90
const DATE_FORMAT = 'yyyy-MM-dd'
export const getDateString = (date: Date) => startOfDay(date).toISOString()
export const getStartDate = (offset?: number) => getDateString(subDays(new Date(), offset || START_DATE_DEFAULT))
export const getEndDateDefault = () => getDateString(new Date())
export const dateFormat = (dateString: string) => format(new Date(dateString), DATE_FORMAT)

export const multiValueFilters = [
  ReportFilterType.AGENT_MULTIPLE,
  ReportFilterType.CALL_DISPOSITION_MULTIPLE,
  ReportFilterType.LOCATION,
  ReportFilterType.NOTIFICATION_NAMES,
  ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE,
  ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE,
  ReportFilterType.OPPORTUNITY_STAGE_MULTIPLE,
  ReportFilterType.OPPORTUNITY_TYPE_MULTIPLE,
  ReportFilterType.TEAM_MULTIPLE
]

export const autoCompleteFilters = [
  ReportFilterType.AGENT_MULTIPLE,
  ReportFilterType.CALL_DISPOSITION_MULTIPLE,
  ReportFilterType.NOTIFICATION_NAMES,
  ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE,
  ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE,
  ReportFilterType.OPPORTUNITY_STAGE_MULTIPLE,
  ReportFilterType.OPPORTUNITY_TYPE_MULTIPLE,
  ReportFilterType.TEAM_MULTIPLE
]

export const agentHiddenFilters = [
  ReportFilterType.AGENT,
  ReportFilterType.AGENT_MULTIPLE,
  ReportFilterType.TEAM,
  ReportFilterType.TEAM_MULTIPLE
]

export const caiRecordingVisibilityHiddenFilters = [ReportFilterType.LOCATION]

export const sliderFilters = [
  ReportFilterType.CALL_DURATION,
  ReportFilterType.OPPORTUNITY_AMOUNT,
  ReportFilterType.OPPORTUNITY_WIN_PROBABILITY
]

const sliderDefault = '0,100'

export const getBlankValues = (
  filters: ReportFilterType[],
  isOnlyAgent: boolean,
  userData: UserData | undefined
): FilterFormValues => {
  const values: FilterFormValues = {}

  for (const filter of filters) {
    const filterName = mapFilterName(filter)
    if (filterName === ReportFilterType.END_DATE) {
      values[filterName] = getEndDateDefault()
    } else if (filterName === ReportFilterType.START_DATE) {
      values[filterName] = getStartDate()
    } else if (filterName === ReportFilterType.START_DATE_QUARTER) {
      values[filterName] = getStartDate(START_DATE_QUARTER_DEFAULT)
    } else if (filterName === ReportFilterType.CALL_DURATION) {
      values[filterName] = '0,120'
    } else if (filterName === ReportFilterType.OPPORTUNITY_CLOSE_DATE) {
      values[filterName] = 'This Fiscal Quarter'
    } else if (filterName === ReportFilterType.COACHING_DIRECTION) {
      values[filterName] = 'coaching given'
    } else if (filterName === ReportFilterType.OPPORTUNITY_AMOUNT) {
      values[filterName] = userData?.opportunityAmount || sliderDefault
    } else if (filterName === ReportFilterType.OPPORTUNITY_STAGE_MULTIPLE) {
      values[filterName] = 'Open Opportunities'
    } else if (filterName === ReportFilterType.OPPORTUNITY_WIN_PROBABILITY) {
      values[filterName] = userData?.opportunityWinProbability || sliderDefault
    } else if (filterName === ReportFilterType.FISCAL_PERIOD) {
      values[filterName] = userData?.currentFiscalQuarter || ''
    } else if (filterName === ReportFilterType.AGENT_MULTIPLE && isOnlyAgent) {
      values[filterName] = userData?.userName || ''
    } else if (filterName === ReportFilterType.AGENT && isOnlyAgent) {
      values[filterName] = userData?.userName || ''
    } else if (filterName === ReportFilterType.TIMEZONE) {
      values[filterName] = localTimeZone
    } else if (filterName === ReportFilterType.VOICEMAIL) {
      values[filterName] = '0'
    } else if (multiValueFilters.indexOf(filterName) > -1) {
      values[filterName] = ''
    } else {
      values[filterName] = '%'
    }
  }

  return values
}

export const picklistDataToSelectOptions = (data: PicklistData, enabledValues: string[]): RdnaSelectOptions<any>[] => {
  const sortedData = data
    .map(item => {
      return { label: item.text, value: item.text }
    })
    .sort(
      (a, b) =>
        Number(enabledValues.indexOf(b.value) > -1) - Number(enabledValues.indexOf(a.value) > -1) ||
        a.label.localeCompare(b.label)
    )
  return sortedUniqBy(sortedData, e => e.label)
}
