import React from 'react'
import styled from 'styled-components'
import { UserLicenses } from '../../types/user-settings'

import RdnaText from '../RdnaText'
import RdnaMenu from '../RdnaMenu'
import RdnaAvatar from '../RdnaAvatar'
import CaretFilled from '../../assets/icons/svg/CaretFilled'
import Settings from '../../assets/icons/svg/Settings'
import Logout from '../../assets/icons/svg/Logout'

import CallTracking from '../../assets/icons/svg/CallTracking'
import Dashboard from '../../assets/icons/svg/Dashboard'
import ThoughtCall from '../../assets/icons/svg/ThoughtCall'
import Calendar from '../../assets/icons/svg/Calendar'
import { ProductDomains } from '../../constants/productDomains'
import RevenueLogo from '../../assets/logos/Revenue'
import { Colors } from '../../constants/colors'

enum OptionValues {
  ADMIN_SETTINGS = 'Admin Settings',
  MY_SETTINGS = 'My Settings',
  DIALER = 'RingDNA Dialer',
  REVENUE = 'Revenue',
  DASHBOARD = 'Live',
  CALL_TRACKING = 'Call Tracking',
  CALENDAR = 'My Calendar',
  LOGOUT = 'Logout'
}

type Props = {
  pictureUrl: string
  userName: string
  userId: number
  config: { [ProductDomains: string]: any }
  logout: () => void
  licenses: UserLicenses
}

const iconSize = 20

const menuOptions = (
  showCallTracking: boolean,
  config: { [ProductDomains: string]: any },
  userId: number,
  licenses: UserLicenses
) => {
  const app = window.location.origin
  const baseOptions = [
    {
      text: app === config[ProductDomains.WEB_ADMIN] ? OptionValues.MY_SETTINGS : OptionValues.ADMIN_SETTINGS,
      icon: <Settings size={iconSize} />,
      url: `${config[ProductDomains.WEB_ADMIN]}/#users/${userId}`
    },
    {
      text: OptionValues.DIALER,
      icon: <ThoughtCall size={iconSize} />
    }
  ]
  if (app !== config[ProductDomains.CAI])
    baseOptions.push({
      text: OptionValues.REVENUE,
      icon: <RevenueLogo iconOnly={true} size={iconSize} color={Colors.BLACK} />,
      url: `${config[ProductDomains.CAI]}`
    })

  if (app !== config[ProductDomains.LIVE])
    baseOptions.push({
      text: OptionValues.DASHBOARD,
      icon: <Dashboard size={iconSize} />,
      url: `${config[ProductDomains.LIVE]}/${
        config[ProductDomains.LIVE] === config[ProductDomains.CAI] ? 'live' : 'calls'
      }` // flag v291_REV_1806
    })

  if (showCallTracking)
    baseOptions.push({
      text: OptionValues.CALL_TRACKING,
      icon: <CallTracking size={iconSize} />,
      url: `${config[ProductDomains.WEB_ADMIN]}/#call-tracking/basic`
    })

  if (licenses.calendar) {
    baseOptions.push({
      text: OptionValues.CALENDAR,
      icon: <Calendar size={iconSize} />,
      url: `${config[ProductDomains.WEB_ADMIN]}/#users/${userId}/calendar`
    })
  } else {
    baseOptions.push({
      text: OptionValues.CALENDAR,
      icon: <Calendar size={iconSize} />,
      url: `https://www.revenue.io/revenue-calendar-for-sales`
    })
  }

  baseOptions.push({ text: OptionValues.LOGOUT, icon: <Logout size={iconSize} /> })

  return baseOptions
}

const RdnaSettingsAvatar = ({ pictureUrl, userName, userId, config, logout, licenses }: Props) => {
  const showCallTracking = !licenses.salesSupport
  const options = menuOptions(showCallTracking, config, userId, licenses)

  const onMenuSelect = (option: string) => {
    if (option === OptionValues.LOGOUT) logout()
    if (option === OptionValues.DIALER) {
      const isWindowsOS = navigator.appVersion.indexOf('Win') !== -1

      const height = isWindowsOS ? 720 : 670
      const width = isWindowsOS ? 540 : 490

      window.open(config[ProductDomains.DIALER], 'dialer', `width=${width},height=${height},popup=true,location=no`)
    }
  }

  return (
    <Container>
      <RdnaMenu options={options} onSelect={onMenuSelect} iconPlacement="after">
        <UserContainer>
          <RdnaAvatar size={36} name={userName} imageUrl={pictureUrl} />
          <RdnaText variant="h6" color="contrast">
            {userName}
          </RdnaText>
          <CaretFilled color="currentColor" />
        </UserContainer>
      </RdnaMenu>
    </Container>
  )
}

export default React.memo(RdnaSettingsAvatar)

const Container = styled.div`
  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacing * 3}px;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: ${({ theme }) => theme.spacing}px;
  }
  & .caret-filled {
    margin-left: ${({ theme }) => theme.spacing}px;
  }
`
