import React, { useState } from 'react'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import styled from 'styled-components'
import { AGREEMENT_MODAL_STR } from '../strings'

type Props = {
  open: boolean
  onClose: (e: boolean) => void
  onConfirm: () => void
}

export default function AgreementModal({ open, onClose, onConfirm }: Props) {
  const [check, setCheck] = useState(false)

  return (
    <StyledModal
      open={open}
      cancelButtonText={AGREEMENT_MODAL_STR.cancel}
      confirmButtonText={AGREEMENT_MODAL_STR.confirm}
      confirmButtonDisabled={!check}
      heading={AGREEMENT_MODAL_STR.header}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <Text className={'styled-scrollbar'}> {AGREEMENT_MODAL_STR.agreementText} </Text>
      <RdnaCheckbox
        data-testid={'agreement-checkbox'}
        value={check}
        label={AGREEMENT_MODAL_STR.agreementCheckboxLabel}
        onChange={() => setCheck(!check)}
      />
    </StyledModal>
  )
}

const StyledModal = styled(RdnaModal)`
  .MuiPaper-root {
    padding: 60px 40px;
  }

  [data-testid='modal.close'] {
    display: none;
  }
`

const Text = styled(RdnaText)`
  white-space: pre-line;
  width: 400px;
  height: 250px;
  overflow: scroll;
  overflow-x: hidden;
`
