import React from 'react'
import { isEqual } from 'lodash-es'
import {
  AccountSettings,
  LockedSettings,
  ContinentRecordingSettings,
  UnitedStatesRecordingSettings,
  AudioUploadSettings,
  OriginalSettings
} from './types'

type Action =
  | { type: 'STORE_ACCOUNT_SETTINGS'; payload: any }
  | { type: 'STORE_LOCKED_SETTINGS'; payload: any }
  | { type: 'STORE_US_RECORDING_SETTINGS'; payload: any }
  | { type: 'STORE_CONTINENT_RECORDING_SETTINGS'; payload: any }
  | { type: 'CHANGE_ACCOUNT_SETTINGS'; payload: any }
  | { type: 'CHANGE_LOCKED_SETTINGS'; payload: any }
  | { type: 'CHANGE_US_RECORDING_SETTINGS'; payload: any }
  | { type: 'CHANGE_CONTINENT_RECORDING_SETTINGS'; payload: any }
  | { type: 'ADD_TO_AUDIO_UPLOAD_SETTINGS'; payload: any }
  | { type: 'REMOVE_FROM_AUDIO_UPLOAD_SETTINGS'; payload: any }
  | { type: 'RESET_TO_ORIGINAL_SETTINGS'; payload: keyof OriginalSettings | 'All' }
  | { type: 'UPDATE_ORIGINAL_SETTINGS' }

export type Dispatch = (action: Action) => void
export type CallRecordingState = Readonly<{
  accountSettings: AccountSettings
  lockedSettings: LockedSettings
  unitedStatesRecordingSettings: UnitedStatesRecordingSettings
  continentRecordingSettings: ContinentRecordingSettings
  audioUploadSettings: AudioUploadSettings[]
  originalSettings: OriginalSettings
}>

// using `{} as any` allows us to start with an empty object instead of undefined or its initial type
export const initialState: CallRecordingState = {
  accountSettings: {} as any as AccountSettings,
  lockedSettings: {} as any as LockedSettings,
  unitedStatesRecordingSettings: {} as any as UnitedStatesRecordingSettings,
  continentRecordingSettings: {} as any as ContinentRecordingSettings,
  audioUploadSettings: [] as any as AudioUploadSettings[],
  originalSettings: {
    accountSettings: {},
    lockedSettings: {},
    unitedStatesRecordingSettings: {},
    continentRecordingSettings: {},
    audioUploadSettings: []
  } as any as OriginalSettings
}

export function callRecordingReducer(state: CallRecordingState = initialState, action: Action): CallRecordingState {
  switch (action.type) {
    case 'STORE_ACCOUNT_SETTINGS': {
      return {
        ...state,
        accountSettings: action.payload,
        originalSettings: {
          ...state.originalSettings,
          accountSettings: action.payload
        }
      }
    }
    case 'STORE_LOCKED_SETTINGS': {
      return {
        ...state,
        lockedSettings: action.payload,
        originalSettings: {
          ...state.originalSettings,
          lockedSettings: action.payload
        }
      }
    }
    case 'STORE_US_RECORDING_SETTINGS': {
      return {
        ...state,
        unitedStatesRecordingSettings: action.payload,
        originalSettings: {
          ...state.originalSettings,
          unitedStatesRecordingSettings: action.payload
        }
      }
    }
    case 'STORE_CONTINENT_RECORDING_SETTINGS': {
      return {
        ...state,
        continentRecordingSettings: action.payload,
        originalSettings: {
          ...state.originalSettings,
          continentRecordingSettings: action.payload
        }
      }
    }
    case 'CHANGE_ACCOUNT_SETTINGS': {
      return {
        ...state,
        accountSettings: { ...state.accountSettings, ...action.payload }
      }
    }
    case 'CHANGE_LOCKED_SETTINGS': {
      return {
        ...state,
        lockedSettings: { ...state.lockedSettings, ...action.payload }
      }
    }
    case 'CHANGE_US_RECORDING_SETTINGS': {
      return {
        ...state,
        unitedStatesRecordingSettings: { ...state.unitedStatesRecordingSettings, ...action.payload }
      }
    }
    case 'CHANGE_CONTINENT_RECORDING_SETTINGS': {
      return {
        ...state,
        continentRecordingSettings: {
          ...state.continentRecordingSettings,
          ...action.payload
        }
      }
    }
    case 'ADD_TO_AUDIO_UPLOAD_SETTINGS': {
      return { ...state, audioUploadSettings: [...state.audioUploadSettings, action.payload] }
    }
    case 'REMOVE_FROM_AUDIO_UPLOAD_SETTINGS': {
      return {
        ...state,
        audioUploadSettings: state.audioUploadSettings.filter(
          (item: AudioUploadSettings) => item.setting !== action.payload
        )
      }
    }
    case 'RESET_TO_ORIGINAL_SETTINGS': {
      const settingsName = action.payload
      if (settingsName === 'All') {
        return { ...state, ...state.originalSettings }
      }
      return { ...state, [settingsName]: state.originalSettings[settingsName] }
    }
    case 'UPDATE_ORIGINAL_SETTINGS': {
      return {
        ...state,
        originalSettings: {
          accountSettings: state.accountSettings,
          lockedSettings: state.lockedSettings,
          unitedStatesRecordingSettings: state.unitedStatesRecordingSettings,
          continentRecordingSettings: state.continentRecordingSettings,
          audioUploadSettings: state.audioUploadSettings
        }
      }
    }
    default: {
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export enum RecordTypes {
  NONE = 'NoRecording',
  ALL = 'AllParties',
  AGENT_ONLY = 'AgentOnly'
}

export const recordCallOptions = [
  {
    label: 'No Recording',
    value: RecordTypes.NONE
  },
  {
    label: 'Record All Parties',
    value: RecordTypes.ALL
  },
  {
    label: 'Record Agent Only',
    value: RecordTypes.AGENT_ONLY
  }
]

// selectors
export const isCallRecordingDisabledSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingOverrideOption'] === 'None' &&
  state.lockedSettings['lockedSetting.callRecording']

export const isCallRecordingByStateOnSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingByState']

export const isCallRecordingByCountryOnSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingByCountry']

export const isInboundCallRecordingAnnouncementSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.inboundCallRecordingAnnouncement']

export const isOutboundCallRecordingAnnouncementSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.outboundCallRecordingAnnouncement']

export const isOutboundCallRecordingAnnouncementAutomaticSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.outboundCallRecordingAnnouncementAutomatic']

export const recordingPauseTypeDropDownSelector = (state: CallRecordingState): string =>
  state.accountSettings['settings.recordingPauseEnabled']
    ? state.accountSettings['settings.recordingPauseType']
    : 'false'

export const isRecordingStatusEnabledSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.recordingStatusEnabled']

export const isRecordingVisibilitySelector = (state: CallRecordingState): string =>
  state.accountSettings['settings.recordingVisibility']

export const isCallRecordingDownloadForbiddenSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingDownloadForbidden']

export const isCallRecordingLockedSelector = (state: CallRecordingState): boolean =>
  state.lockedSettings['lockedSetting.callRecording']

export const isOutboundCallRecordingAnnouncementDisabledSelector = (state: CallRecordingState): boolean =>
  isCallRecordingDisabledSelector(state) || !state.accountSettings['settings.outboundCallRecordingAnnouncement']

export const isCallRecordingOverrideOptionNoneSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingOverrideOption'] === 'None'

export const getCallRecordingOverrideOptionSelector = (state: CallRecordingState): string =>
  state.accountSettings['settings.callRecordingOverrideOption']

export const inboundCallRecordingAnnouncementUrlSelector = (state: CallRecordingState): string =>
  state.accountSettings['settings.inboundCallRecordingAnnouncementUrl']

export const outboundCallRecordingAnnouncementUrlSelector = (state: CallRecordingState): string =>
  state.accountSettings['settings.outboundCallRecordingAnnouncementUrl']

export const callRecordingAgentOnlySelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingAgentOnly']

export const callRecordingExternalCallerSelector = (state: CallRecordingState): boolean =>
  state.accountSettings['settings.callRecordingExternalCaller']

export const hasAccountSettingsChangedSelector = (state: CallRecordingState): boolean =>
  !isEqual(state.accountSettings, state.originalSettings.accountSettings)

export const hasAudioUploadSettingsChangedSelector = (state: CallRecordingState): boolean =>
  !isEqual(state.audioUploadSettings, state.originalSettings.audioUploadSettings)

export const hasLockedSettingsChangedSelector = (state: CallRecordingState): boolean =>
  !isEqual(state.lockedSettings, state.originalSettings.lockedSettings)

export const hasUSRecordingSettingsChangedSelector = (state: CallRecordingState): boolean =>
  !isEqual(state.unitedStatesRecordingSettings, state.originalSettings.unitedStatesRecordingSettings)

export const hasContinentRecordingSettingsChangedSelector = (state: CallRecordingState): boolean =>
  !isEqual(state.continentRecordingSettings, state.originalSettings.continentRecordingSettings)

// context
export const CallRecordingStateContext = React.createContext<CallRecordingState | undefined>(undefined)
export const CallRecordingDispatchContext = React.createContext<Dispatch | undefined>(undefined)

export function useCallRecordingState() {
  const context = React.useContext(CallRecordingStateContext)
  if (!context) {
    throw new Error('useCallRecordingState must be used within a CallRecordingProvider')
  }
  return context
}

export function useCallRecordingDispatch() {
  const context = React.useContext(CallRecordingDispatchContext)
  if (!context) {
    throw new Error('useCallRecordingDispatch must be used within a CallRecordingProvider')
  }
  return context
}
