BaseView  = require('base/view')
BaseModel = require('base/model')

class UploadModel extends BaseModel
  url: 'smartRecordingsSystem/uploadUser'

class MainView extends BaseView
  template: require('./template')

  ui:
    days : 'input.days'
    go   : 'button.go'

  events:
    'click @ui.go' : 'go'

  initialize: (options) ->
    {@userId} = options

  go: =>
    days = @ui.days.val()
    return unless @validate(days)

    model = new UploadModel(
      userId  : @userId
      days    : days
    )

    model
      .persist()
      .done(=>
        toastr.info('Upload job has started. Check papertrail for status: ' +
        '<a target="_blank" rel="noopener noreferrer" href="https://papertrailapp.com/events?q=Voicebase">https://papertrailapp.com/events?q=Voicebase</a>')
      )

  validate: (days) ->
    if (days > 31)
      message = 'Days value must be 31 or less.'
      toastr.warning(message)
      return false
    true

module.exports = MainView
