import { HttpMethods } from '@ringdna/common/src/client'
import { CallRecordingSetting, UnitedStatesCallRecordingSetting } from '@ringdna/common/src/types/call-recording'
import {
  ContinentRecordingSettings,
  UnitedStatesRecordingSettings
} from '../../components/call-recording-tab/utils/types'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { RecordTypes } from '../../components/call-recording-tab/utils/contextReducer'

const API_BASE = '/api/v2/app'

type CallRecordingRegion = 'us' | 'international'

type useCallRecordingSettingsParams = {
  meta: {
    id: string
    isUS: boolean
  }
}

export type CallRecordingSettingsData = {
  id: number
  recordType: RecordTypes
  outboundAnnouncement: boolean
}

export const useCallRecordingSettings = createUseFetch<
  CallRecordingSetting[] | UnitedStatesCallRecordingSetting[],
  useCallRecordingSettingsParams
>({
  key: 'use-call-recording-settings',
  path: params => `${API_BASE}/callRecordingSettings?accountId=${params.meta.id}&isUS=${params.meta.isUS}`,
  method: HttpMethods.Get,
  independent: true
})

type putCallRecordingSettingsParams = {
  body: ContinentRecordingSettings | UnitedStatesRecordingSettings
}

export const usePutCallRecordingSettings = createUseFetchAction<{ status: string }, putCallRecordingSettingsParams>({
  key: 'put-call-recording-settings',
  path: `${API_BASE}/callRecordingSettings`,
  method: HttpMethods.Put
})

type UpdateRecordingSettingParam = {
  accountId: string
  region: CallRecordingRegion
  regionId: number
  json: {
    recordType: RecordTypes
    outboundAnnouncement: boolean
  }
}

export const useUpdateCallRecordingSettings = createUseFetchAction<void, UpdateRecordingSettingParam>({
  key: 'update-call-recording-settings',
  method: HttpMethods.Put,
  path: params =>
    `/api/v3/accounts/${params.accountId}/call-recording-settings/${params.region}/${params.regionId}?x-http-method-override=PATCH`,
  headers: { 'Content-Type': 'application/json' }
})

type UpdateAccountSettingParam = {
  accountId: string
  json: {
    callRecordingByCountry?: boolean
    callRecordingByState?: boolean
  }
}

export const useUpdateAccountSettings = createUseFetchAction<void, UpdateAccountSettingParam>({
  key: 'update-account-settings',
  method: HttpMethods.Put,
  path: params => `/api/v3/accounts/${params.accountId}/settings?x-http-method-override=PATCH`,
  headers: { 'Content-Type': 'application/json' }
})

type UpdateCallRecordingSupportAccess = {
  meta: {
    accountId: number
  }
  body: {
    timeStr: string | null
  }
}

export const useUpdateCallRecodingSupportAccess = createUseFetchAction<string | null, UpdateCallRecordingSupportAccess>({
  key: 'update-call-recording-support-access',
  method: HttpMethods.Post,
  path: params => `/api/v2/app/accounts/${params.meta.accountId}/callRecordingSupportAccess`
})
