import { FormValues, SchedulingPage } from '../types'
import { addHours, addMinutes, startOfHour, getTime } from 'date-fns'

export const serializeSchedulingPage = (schedulingPage: SchedulingPage) => {
  return (Object.keys(schedulingPage) as Array<keyof typeof schedulingPage>).reduce((acc: any, key) => {
    acc[`page.${key}`] = schedulingPage[key]
    return acc
  }, {})
}

export const formValuesToSchedulingPagePayload = (values: FormValues, userId: number) => {
  const duration = parseInt(values.duration)
  const serializedMeetingType = serializeSchedulingPage({
    ...nonFormRelatedValues(duration),
    ...values,
    duration
  })
  serializedMeetingType.userId = userId
  return serializedMeetingType
}

// do not think we should be sending these values as they not related to form or derivable from form values
const nonFormRelatedValues = (duration: number) => {
  const d = new Date()
  const startDateTime = addHours(startOfHour(d), 1)
  const endDateTime = addMinutes(startDateTime, duration)
  return {
    type: 'OneOnOne',
    confirmationRedirectUrl: '',
    timezone: '',
    maxAttendees: '',
    rrule: '',
    objectDate: 'TIMESPAN',
    startDateTime: getTime(startDateTime),
    endDateTime: getTime(endDateTime)
  }
}
