class NumberModel extends Backbone.Model
  url: 'numberInspector'

class NumberView extends Marionette.View
  template: require('./number-template')

class ContainerView extends Marionette.View
  template: require('./container-template')
  className: 'number-inspector'

  ui:
    phoneNumber: '.phone-number'
    go: '.go'

  events:
    'click @ui.go': 'go'
    'keyup .phone-number': 'keyPressed'

  regions:
    content: '.content'

  initialize: (options) =>
    @number = options.qs.params.number

  onRender: =>
    @restore()

  keyPressed: (e) =>
    @go() if e.keyCode is 13

  go: =>
    @load(@ui.phoneNumber.val())

  load: (number) =>
    App.navigate("#system/number-inspector?number=#{number}")

    @model = new NumberModel()
    @model
      .fetch(data: {number: number})
      .done(=> @showChildView('content', new NumberView(model: @model)) if @getRegion('content'))

  restore: =>
    return unless @number
    @ui.phoneNumber.val(@number)
    @load(@number)
    delete @number

module.exports = ContainerView
