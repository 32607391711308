import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function UserSkillsIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="user-skills-icon">
      <path
        d="M17.1048 13.2383L18.5758 15.7861L17.1048 18.334L14.1628 18.334L12.6918 15.7861L14.1628 13.2383L17.1048 13.2383Z"
        stroke={color}
        strokeWidth="0.75"
      />
      <path
        d="M10.875 8.25C10.875 9.69975 9.69975 10.875 8.25 10.875C6.80025 10.875 5.625 9.69975 5.625 8.25C5.625 6.80025 6.80025 5.625 8.25 5.625C9.69975 5.625 10.875 6.80025 10.875 8.25Z"
        stroke={color}
        strokeWidth="0.75"
      />
      <path d="M5.85676 18.375L8.25 13.5885L10.6432 18.375H5.85676Z" stroke={color} strokeWidth="0.75" />
      <mask id="path-4-inside-1_8450_59355" fill="white">
        <rect x="12.75" y="5.25" width="6" height="6" rx="0.225" />
      </mask>
      <rect
        x="12.75"
        y="5.25"
        width="6"
        height="6"
        rx="0.225"
        stroke={color}
        strokeWidth="1.5"
        mask="url(#path-4-inside-1_8450_59355)"
      />
    </svg>
  )
}
