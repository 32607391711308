BaseView        = require('base/view')
ReactComponent  = require('src/ReactComponent').default
Reports         = require('src/components/reports/Reports').default
ProductConstant = require('@ringdna/common/src/constants/products')

class ReportsView extends BaseView
  template: require('./template')
  className: 'reports-view'

  regions:
    container  : '.reports'

  onRender: =>
    @showChildView('container', new ReactComponent({component: Reports, theme: ProductConstant.Products.ADMIN}))

module.exports = ReportsView
