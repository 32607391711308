import React, { useEffect, useState } from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { Column, IconSize, Section, SectionHeader, SectionSubHeader } from '../../styles'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import CloudIcon from '@ringdna/common/src/assets/icons/svg/Cloud'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import SalesforceConnectTestButton from './SalesforceConnectTestButton'
import SalesforceConnect from './SalesforceConnect'
import Notifications from './Notifications'

type Props = {
  dialerSettings: DialerSettings
  setSettings: (settings: { [key: string]: any }) => void
  isSupport: boolean
}

const SF_CONNECT_SUB =
  'Primary Salesforce Connect user. Additionally, if call queues are set up with RingDNA, ' +
  'RingDNA will assign all abandoned queued calls to this user. If marketing campaigns are tracked, RingDNA will create ' +
  'a Lead for unknown callers and assign it to this user.'

export default GeneralSettings
function GeneralSettings({ dialerSettings, setSettings, isSupport }: Props) {
  const [local, setLocal] = useState<{ [key: string]: any }>({})
  const [sfOrgId, setSfOrgId] = useState(dialerSettings.account.sfOrgId)
  const [adminNotifications, setAdminNotifications] = useState<{ [userId: string]: boolean }>({})
  const [sfdcCreateLeadOnTrackingNumber, setSfdcCreateLeadOnTrackingNumber] = useState(
    dialerSettings.account.settings.sfdcCreateLeadOnTrackingNumber
  )
  const [sfdcCreateSmartNumberCampaigns, setSfdcCreateSmartNumberCampaigns] = useState(
    dialerSettings.account.settings.sfdcCreateSmartNumberCampaigns
  )

  const update = (key: string, value: any) => {
    const updated = { ...local, [key]: value }
    setLocal(updated)
    setSettings(updated)
  }

  useEffect(() => {
    if (Object.keys(adminNotifications).length > 0) {
      const updated: any = { ...local, adminNotifications: adminNotifications }
      updated['settings.sfdcInvalidNotificationsAll'] = Object.values(adminNotifications).every(value => value)
      setSettings(updated)
    }
  }, [local, setSettings, adminNotifications])

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={CloudIcon} iconSize={IconSize} variant="filled" />
        <RdnaText bold className={'rdna-text'}>
          Salesforce Settings
        </RdnaText>
      </SectionHeader>
      <RdnaSettingRow heading={'Organization ID'} description={''}>
        <RdnaTextInput
          inputData={{ name: 'sfOrgId', type: InputTypes.TEXT, className: 'support-input' }}
          value={sfOrgId}
          onChange={({ target: { value } }) => {
            setSfOrgId(value)
            update('account.sfOrgId', value)
          }}
          isDisabled={!isSupport}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={'Create Lead for every Inbound Call To a Call Tracking Number'} description={''}>
        <RdnaToggleSwitch
          value={sfdcCreateLeadOnTrackingNumber}
          onChange={(checked: boolean) => {
            setSfdcCreateLeadOnTrackingNumber(checked)
            update('settings.sfdcCreateLeadOnTrackingNumber', checked)
          }}
        />
      </RdnaSettingRow>
      <RdnaSettingRow
        heading={'Create Smart Number Campaigns'}
        description={'Create a new Salesforce campaign when provisioning a tracking Smart Number.'}
      >
        <RdnaToggleSwitch
          value={sfdcCreateSmartNumberCampaigns}
          onChange={(checked: boolean) => {
            setSfdcCreateSmartNumberCampaigns(checked)
            update('settings.sfdcCreateSmartNumberCampaigns', checked)
          }}
        />
      </RdnaSettingRow>
      <SectionSubHeader>
        <Column>
          <RdnaText bold>Salesforce Connect</RdnaText>
          <RdnaText>{SF_CONNECT_SUB}</RdnaText>
        </Column>
        <SalesforceConnectTestButton accountId={dialerSettings.account.id} />
      </SectionSubHeader>
      <SalesforceConnect accountId={dialerSettings.account.id} />
      <Notifications
        accountId={dialerSettings.account.id}
        setAdminNotifications={setAdminNotifications}
        sfdcInvalidNotificationsAll={dialerSettings.account.settings.sfdcInvalidNotificationsAll}
      />
    </Section>
  )
}
