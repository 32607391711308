export enum IntegrationProviderTypes {
  SALESFORCE = 'SALESFORCE',
  AOL = 'AOL',
  EXCHANGE = 'EXCHANGE',
  GMAIL = 'GMAIL',
  HOOVERS = 'HOOVERS',
  HOTMAIL = 'HOTMAIL',
  IMAP = 'IMAP',
  ICLOUD = 'ICLOUD',
  YAHOO = 'YAHOO',
  ZOOM = 'ZOOM'
}

export enum NylasStatusTypesDisabled {
  DEACTIVATED = 'DEACTIVATED',
  DISABLED = 'DISABLED',
  DISCONNECTION = 'DISCONNECTION',
  EXCEPTION = 'EXCEPTION',
  INVALID = 'INVALID',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  STOPPED = 'STOPPED',
  SYNC_ERROR = 'SYNC_ERROR'
}

export enum NylasStatusTypesEnabled {
  DOWNLOADING = 'DOWNLOADING',
  INITIALIZING = 'INITIALIZING',
  PARTIAL = 'PARTIAL',
  RUNNING = 'RUNNING'
}

export const NylasStatusTypes = { ...NylasStatusTypesDisabled, ...NylasStatusTypesEnabled }

export type NylasUser = {
  billingState: string
  email: string
  id: number
  linkedAt: number
  isPrimary: boolean
  name: string
  nylasId: string
  object: string
  provider: IntegrationProviderTypes
  status: keyof typeof NylasStatusTypes
}

export type NylasCalendarSettings = {
  calendarId: string
  enabled: boolean
  isDefault: boolean
  name: string
  readOnly: boolean
  settingId: number
}

export type NylasProvider = {
  email: string
  id: number
  nylasCalendars: NylasCalendarSettings[]
  provider: IntegrationProviderTypes
}
