import { Selector } from 'reselect'
import { deepEqualSelector } from '../../../../../src/utils/deepEqualSelector'
import { IntegrationsPayload, NylasSetting, AccountIntegration, ActiveUser } from '../model'
import { IntegrationProviderTypes, NylasUser } from '@ringdna/common/src/types/nylas'
import {flags, isFlagged} from "@ringdna/common/src/utils/feature-flag/featureFlag";

type AllIntegrationsParams = {
  payload: IntegrationsPayload
  activeUser: ActiveUser
}

const getNylasUsersLookup: Selector<AllIntegrationsParams, any> = deepEqualSelector(
  [params => params.payload.nylasUsers],
  nylasUsers => {
    return nylasUsers.reduce((acc: { [name: string]: NylasUser }, nylasUser: NylasUser) => {
      acc[nylasUser.provider] = nylasUser
      return acc
    }, {})
  }
)

const getZoomIntegration: Selector<AllIntegrationsParams, AccountIntegration> = deepEqualSelector(
  [params => params.payload],
  payload => {
    const zoomUser = payload.zoomMetaModel.zoomUser
    return {
      id: zoomUser ? zoomUser.rdnaZoomUserId : '',
      provider: IntegrationProviderTypes.ZOOM,
      email: zoomUser ? zoomUser.email : '',
      status: '',
      enabled: payload.zoomMetaModel.apiEnabled,
      connected: payload.zoomMetaModel.zoomToken ? payload.zoomMetaModel.zoomToken : payload.zoomMetaModel.enabled,
      primary: false
    }
  }
)

const getSalesforceIntegration: Selector<AllIntegrationsParams, AccountIntegration> = deepEqualSelector(
  [params => params.activeUser],
  activeUser => {
    return {
      id: activeUser.accountId,
      provider: IntegrationProviderTypes.SALESFORCE,
      email: activeUser.userEmail,
      status: 'DEFAULT',
      enabled: true,
      connected: true,
      primary: false
    }
  }
)

const getHooversIntegration: Selector<AllIntegrationsParams, AccountIntegration> = deepEqualSelector(
  [params => params.activeUser],
  activeUser => {
    return {
      id: activeUser.accountId,
      provider: IntegrationProviderTypes.HOOVERS,
      email: activeUser.userEmail,
      status: 'DEFAULT',
      enabled: true,
      connected: false,
      primary: false
    }
  }
)

export const getAllIntegrations: Selector<AllIntegrationsParams, AccountIntegration[]> = deepEqualSelector(
  [params => params.payload.nylasSettings, getSalesforceIntegration, getZoomIntegration, getNylasUsersLookup, getHooversIntegration],
  (nylasSettings, salesForceIntegration, zoomIntegration, nylasUsersLookup, hooversIntegration) => {
    const showHoovers = isFlagged(flags.HOOVERS_INTEGRATION)

    const allIntegrations: AccountIntegration[] = nylasSettings.map((integration: NylasSetting) => {
      const nylasUser = nylasUsersLookup[integration.provider]
      return {
        id: nylasUser ? nylasUser.id : integration.id,
        provider: integration.provider,
        email: nylasUser ? nylasUser.email : '',
        status: nylasUser ? nylasUser.status : '',
        nylasId: nylasUser ? nylasUser.nylasId : '',
        enabled: integration.enabled,
        connected: nylasUser !== undefined,
        primary: nylasUser ? nylasUser.isPrimary : false
      }
    })
    allIntegrations.unshift(salesForceIntegration, zoomIntegration)
    if (showHoovers)
      allIntegrations.push(hooversIntegration)
    return allIntegrations
  }
)
