import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function AccountIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M21.5 12a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0z" stroke="#000" />
      <path clipRule="evenodd" d="M12 11a2 2 0 100-4 2 2 0 000 4z" stroke={color} />
      <path clipRule="evenodd" d="M8 14s1.5-1.5 4-1.5 4 1.5 4 1.5v2H8v-2z" stroke={color} strokeLinejoin="round" />
    </svg>
  )
}

export default AccountIcon
