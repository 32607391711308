BaseView = require('base/view')
BulkNumberDeleterCollectionView = require('./collection-view')

class BulkNumberDeleterLayoutView extends BaseView

  template: require('./main-template')
  className: 'bulk-number-deleter'

  regions:
    results: '.results'

  ui:
    container  : '.file-container'
    file       : '.file'
    hardDelete : 'input[name=hardDelete]'
    header     : '.header'
    upload     : 'button.upload'

  events:
    'click @ui.upload': 'upload'

  bindings:
    '.header' : 'toggle:length($collection)'

  initialize: ->
    @collection = new Backbone.Collection()

  onAttach: =>
    $('form input[type=checkbox]').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onRender: =>
    @ui.file.filestyle(
      buttonBefore: true,
      buttonText: '&nbsp; Choose file'
    )

  upload: =>
    if not @ui.file.val()
      toastr.warning('Please choose a file.')
      return

    @ui.file.filestyle('disabled', true)
    hardDelete = @ui.hardDelete.is(':checked')

    App.api.post(
      path   : 'smartNumbers/bulkDelete'
      data   : { file: @ui.file[0].files[0], hardDelete: hardDelete }
      ladda  : @ui.upload[0]
      upload : true
      success: (response) =>
        toastr.info('Import completed')
        @collection.reset(response)
        @showChildView('results', new BulkNumberDeleterCollectionView(collection: @collection)) if @getRegion('results')
      complete: =>
        @ui.file.filestyle('disabled', false)
    )

module.exports = BulkNumberDeleterLayoutView
