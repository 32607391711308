BackgridCell = require('helpers/backgrid-cell')

class DeploymentCollection extends Backbone.Collection
  url: 'deployments'

class DeploymentsView extends Marionette.View
  template: require('./template')
  className: 'deployment-list'

  regions:
    grid: 'div.grid'

  onBeforeRender: =>
    @collection = new DeploymentCollection()
    @collection.fetch().done(=> @showGrid())

  showGrid: =>
    datetime = BackgridCell.dateTime()

    @cols = [
      {label: 'Project',       name: 'project',    cell: 'string'}
      {label: 'Build',         name: 'build',      cell: 'string'}
      {label: 'Git Reference', name: 'gitref',     cell: 'string'}
      {label: 'Github Link',   name: 'githubLink', cell: 'uri'}
      {label: 'Timestamp',     name: 'timestamp',  cell: datetime}
      {label: 'ID',            name: 'id',         cell: 'string'}
    ]

    @showChildView('grid', new Backgrid.Grid(
      collection : @collection
      columns    : @cols
      emptyText  : 'No deployments found.'
    ))

module.exports = DeploymentsView
