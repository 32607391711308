BackgridCell                = require('helpers/backgrid-cell')
BaseView                    = require('base/view')
DispositionTeamCollection  = require('./collection')

class DispositionTeamView extends BaseView

  template: require('./template')
  className: 'disposition-team'

  regions:
    dispositions  : 'div.call-dispositions'

  initialize: (options) ->
    @accountId = options.accountId
    @teamId = options.teamId

  onRender: =>
    @collection = new DispositionTeamCollection()
    @collection.fetch(data:
      accountId: @accountId
      teamId: @teamId
    ).done?(=> @showChildView('dispositions', @buildGrid(@collection)) if @getRegion('dispositions'))

  buildGrid: (collection) ->
    cols = [
      {label: 'Name',     name: 'disposition.disposition',     cell: 'string'}
      {label: 'Inbound',  name: 'inbound',  cell: BackgridCell.checkbox(),  editable: true}
      {label: 'Outbound', name: 'outbound', cell: BackgridCell.checkbox(),  editable: true}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No dispositions found.'
    )

module.exports = DispositionTeamView