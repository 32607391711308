import React from 'react'

import { FeatureType } from '@ringdna/common/src/types/dialer-settings'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import { ActionType, useSupportSettingsDispatch } from '../../../utils/contextReducer'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'

type SupportSettingToggleRowProps = {
  setting: string
  description?: string
  disabled?: boolean
  isChecked: boolean
  title: string
}

const SupportSettingToggleRow = ({
  title,
  description = '',
  isChecked,
  setting,
  disabled = false
}: SupportSettingToggleRowProps) => {
  const dispatch = useSupportSettingsDispatch()

  const handleChange = (value: boolean) => {
    if (setting.startsWith('settings.')) {
      dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { [setting]: value } })
    }
    if (setting in FeatureType) {
      dispatch({ type: ActionType.CHANGE_FEATURE_VISIBILITIES, payload: { featureType: setting, value: value } })
    }
    if (setting === 's3RecordingBucketConfirmation') {
      dispatch({ type: ActionType.CHANGE_S3_RECORDING_BUCKET_CONFIRM, payload: value })
    }
  }

  return (
    <RdnaSettingRow heading={title} description={description}>
      <RdnaToggleSwitch testId={`${setting}-toggle`} disabled={disabled} controlledState={{ isChecked, setIsChecked: handleChange }} />
    </RdnaSettingRow>
  )
}
export default React.memo(SupportSettingToggleRow)
