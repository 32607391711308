BaseView          = require('base/view')
BaseModel         = require('base/model')
BaseCollection    = require('base/collection')
isEmail = require('@ringdna/common/src/utils/validationUtils').default.isEmail

class AvailableUsersCollection extends BaseCollection
  setParams: (options) ->
    {@teamId} = options
  url: -> "teams/#{@teamId}/notifications/availableUsers"

class MailingPickerView extends BaseView
  template: require('./template')

  ui:
    picker:   '.selector'

  initialize: (options) =>
    { @type } = options

  onRender: =>
    if @type is 'users'
      @availableUsers = new AvailableUsersCollection()
      @availableUsers.setParams(teamId: @model.id)
      @usersCollection = new BaseCollection(@model.get('selectedUsersAndEmailsCollection').filter((model) => !!!model.get('email')))
      @initUsersSelectize()
    if @type is 'emails'
      @emailsCollection = new BaseCollection(@model.get('selectedUsersAndEmailsCollection').filter((model) => !!model.get('email')))
      @initEmailsSelectize()

  initUsersSelectize: =>
    @ui.picker.selectize(
      maxItems: null
      plugins: ['remove_button']
      onInitialize: =>
        selectize = @ui.picker[0].selectize
        @availableUsers.fetch(
          data:
            accountId: @model.get('account').id
        ).done( =>
          selectize.addOption(value: model.get('id'), text: model.get('displayName') for model in @availableUsers.models)
          selectize.addItem(model.get('id'), true) for model in @usersCollection.models
        )

      onItemAdd: (value, item) =>
        selectize = @ui.picker[0].selectize
        model = @availableUsers.findWhere(id: parseInt(value))
        if (model.get('emailInvalid'))
          selectize.removeItem(item)
          selectize.blur()
          toastr.warning('Cannot select user: invalid email')
          return
        @model.get('selectedUsersAndEmailsCollection').add(model) unless model.get('emailInvalid')

      onItemRemove: (value) =>
        @model.get('selectedUsersAndEmailsCollection').remove(parseInt(value))
    )
    @ui.picker[0].selectize.disable() if @model.get('isDeleted')

  initEmailsSelectize: =>
    @ui.picker.selectize(
      delimiter: ','
      maxItems: null
      plugins: ['remove_button']
      create: (value) =>
        return {
          value: value
          text: value
        }

      onInitialize: =>
        @ui.picker[0].selectize.addOption(value: model.get('email'), text: model.get('email') for model in @emailsCollection.models)
        @ui.picker[0].selectize.addItem(model.get('email'), true) for model in @emailsCollection.models

      onItemAdd: (value, item) =>
        unless isEmail(value)
          @ui.picker[0].selectize.removeItem(item)
          @ui.picker[0].selectize.removeOption(value)
          toastr.warning('Must enter a valid email.')
          return

        model = @emailsCollection.findWhere(email: value)
        if !!model
          @model.get('selectedUsersAndEmailsCollection').add(model)
        else
          @model.get('selectedUsersAndEmailsCollection').add(new BaseModel({email : value}))

      onItemRemove: (value) =>
        model = @model.get('selectedUsersAndEmailsCollection').findWhere(email: value)
        @model.get('selectedUsersAndEmailsCollection').remove(model)
    )
    @ui.picker[0].selectize.disable() if @model.get('isDeleted')

module.exports = MailingPickerView
