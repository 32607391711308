import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Refetch } from '@ringdna/client/src/constants'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'
import { getFormattedFullDate } from '@ringdna/common/src/utils/timeUtils'
import {
  useFetchTeamsUsers,
  AccountUser,
  useFetchTeamsSettings,
  AccountIdQuery,
  useDeleteMsTeamsAccountId,
  TeamsScriptResponse
} from './api'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import useToast from '../../common/hooks/useToast'

import TeamsGlobalSettings from './TeamsGlobalSettings'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import EditProviderModal from './EditProviderModal'
import RdnaMenu, { MenuOption } from '@ringdna/common/src/components/RdnaMenu'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { TeamsUsers } from './styles'

// will remain false until zoom BE endpoints ready
export const DISPLAY_ZOOM = false
const DELETE_TENANT_CTA = 'Delete Tenant Id'

enum UserMenuValues {
  EDIT_TEAMS = 'Edit'
}

export enum VideoAccountType {
  TEAMS = 'Teams',
  ZOOM = 'Zoom'
}

type TABLE_VALUES = {
  name: string
  email: string
  lastActive: string
  zoom?: string
  teams: string
  editMenu: LinkageValues
}

type LinkageValues = {
  setSelectedAccountType: React.Dispatch<React.SetStateAction<VideoAccountType | undefined>>
  setSelectedAccountUser: React.Dispatch<React.SetStateAction<AccountUser | undefined>>
  setIsConnectedUser: React.Dispatch<React.SetStateAction<boolean>>
  accountUser: AccountUser
}

const PAGINATION_SIZE = 10
const SEARCH_PLACEHOLDER = 'Search'
const MENU_OPTIONS: MenuOption[] = Object.values(UserMenuValues).map(val => ({ text: val }))
const truncatedValue = function fn({ value }: { value: string }) {
  return <Truncated>{value}</Truncated>
}
const TABLE_COLUMNS = [
  {
    Header: 'User',
    accessor: 'name' as const,
    Cell: truncatedValue
  },
  {
    Header: 'Email Address',
    accessor: 'email' as const,
    Cell: truncatedValue
  },
  {
    Header: 'Zoom Email',
    accessor: 'zoom' as const,
    Cell: truncatedValue
  },
  {
    Header: 'Microsoft Teams Email',
    accessor: 'teams' as const,
    Cell: truncatedValue
  },
  {
    Header: 'Last Active',
    accessor: 'lastActive' as const
  },
  {
    Header: '',
    accessor: 'editMenu' as const,
    disableSortBy: true,
    width: '30',
    styles: {
      align: 'right'
    },
    Cell: function fn({ value }: { value: LinkageValues }) {
      const { setSelectedAccountType, setSelectedAccountUser, setIsConnectedUser, accountUser } = value
      const onMenuSelect = (selectedOption: string) => {
        const isTeams = selectedOption === UserMenuValues.EDIT_TEAMS
        setSelectedAccountUser(accountUser)
        setIsConnectedUser(isTeams ? !!accountUser.msTeamsId : !!accountUser.zoomId)
        setSelectedAccountType(isTeams ? VideoAccountType.TEAMS : VideoAccountType.ZOOM)
      }
      return <RdnaMenu options={MENU_OPTIONS} onSelect={onMenuSelect} />
    }
  }
]

if (!DISPLAY_ZOOM) TABLE_COLUMNS.splice(2, 1)

type Props = {
  query: AccountIdQuery
  refetchScriptEnabled: Refetch<TeamsScriptResponse, AccountIdQuery>
}

export default function VideoUsersContainer({ query, refetchScriptEnabled }: Props) {
  const toastr = useToast()
  const [isConnectedUser, setIsConnectedUser] = useState<boolean>(true)
  const [selectedAccountType, setSelectedAccountType] = useState<VideoAccountType | undefined>()
  const [selectedAccountUser, setSelectedAccountUser] = useState<AccountUser>()

  const [teamsUsersPayload = [], error, loading, refetchTeamsUsers] = useFetchTeamsUsers(query)
  const [teamsInitialSettings, , , refetchTeamsSettings] = useFetchTeamsSettings(query)

  const deleteMsTeamsAccountId = useDeleteMsTeamsAccountId()

  const accountId = query.query.accountId

  const onDeleteMsTeamsAccountId = () => {
    const deleteId = async () => {
      try {
        await deleteMsTeamsAccountId({ meta: { accountId } })
        refetchScriptEnabled()
      } catch (err) {
        /* istanbul ignore next */
        toastr.error(getErrorMessage(err))
      }
    }
    deleteId()
  }

  useEffect(() => {
    if (error) toastr.error(getErrorMessage(error))
  }, [error, toastr])

  const tableData = useMemo(() => {
    const data = teamsUsersPayload.map((accountUser: AccountUser) => {
      const values: TABLE_VALUES = {
        name: accountUser.displayName,
        email: accountUser.email,
        zoom: accountUser.zoomEmail || '_',
        teams: accountUser.msTeamsEmail || '_',
        lastActive: accountUser.loginDate ? getFormattedFullDate(accountUser.loginDate) : 'N/A',
        editMenu: {
          setSelectedAccountType,
          setSelectedAccountUser,
          setIsConnectedUser,
          accountUser
        }
      }
      if (!DISPLAY_ZOOM) delete values.zoom
      return values
    })
    return data
  }, [teamsUsersPayload])

  const onCloseEditProviderModal = () => {
    setSelectedAccountType(undefined)
    setSelectedAccountUser(undefined)
  }

  return (
    <>
      {selectedAccountType && selectedAccountUser && (
        <EditProviderModal
          accountId={accountId}
          accountUser={selectedAccountUser}
          accountType={selectedAccountType}
          isConnectedUser={isConnectedUser}
          isOpen={!!selectedAccountType}
          onRequestClose={onCloseEditProviderModal}
          refetchAccountUsers={refetchTeamsUsers}
        />
      )}
      {isFlagged(flags.TEAMS_ACCOUNT_FORM) && (
        <RdnaButton text={DELETE_TENANT_CTA} color="alert" onClick={onDeleteMsTeamsAccountId} />
      )}
      {teamsInitialSettings !== undefined && (
        <TeamsGlobalSettings settings={teamsInitialSettings} refetchSettings={refetchTeamsSettings} />
      )}
      <TeamsUsers>
        <RdnaText bold>{'Teams Users'}</RdnaText>
      </TeamsUsers>
      <RdnaSmartTable
        // @ts-ignore - TODO
        columns={TABLE_COLUMNS}
        data={tableData}
        paginationSize={PAGINATION_SIZE}
        isFetching={!!loading}
        searchPlaceholder={SEARCH_PLACEHOLDER}
      />
    </>
  )
}

const Truncated = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
