BackgridCell    = require('helpers/backgrid-cell')
BaseView        = require('base/view')
BaseModel       = require('base/model')
PagedCollection = require('base/paged-collection')
PagerView       = require('widgets/pager/view')
UserPickerView  = require('widgets/user-picker/view')

class SfRetryErrorModel extends BaseModel
  url: 'salesforceInspector/retryError'

class LogsView extends BaseView
  template: require('./template')
  className: 'logs'

  regions:
    grid        : 'div.grid'
    pager       : 'span.pager'
    userPicker  : 'span.user-picker'

  ui:
    startDate : '.start-date'
    endDate   : '.end-date'
    search    : 'button.search'
    category  : 'select.category'
    range     : 'select.range'

  bindings:
    '.range': 'value:range'
    '.start-date': 'toggle:customRange'
    '.end-date': 'toggle:customRange'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  computeds:
    customRange:
      deps: ['range']
      get: (range) ->
        range is 'custom'

  initialize: (options) ->
    @qs = options.qs
    @accountId = options.accountId
    @viewModel = new Backbone.Model(range: 'today')

    App.pubsub.bind('SalesforceResynced', @onSalesforceResync)

  onRender: =>
    @userPicker = @getRegion('userPicker')
    @userPicker.show(new UserPickerView(accountId: @accountId))
    @setupDatepickers()

    if App.featureFlag.isFlagged(App.featureFlag.flags.v294_ID_2850)
      @ui.category.append('<option value="SupportRecordingAccess">Support Recording Access</option>')
    category = @ui.category.selectize(
      allowEmptyOption: true
      maxItems: 1
      placeholder: 'Category'
    )
    category[0].selectize.clear()
    @ui.range.selectize(
      allowEmptyOption: true
      maxItems: 1
      placeholder: 'Date range'
    )
    @search()

  onSalesforceResync: (options) =>
    callKey = options.callKey
    toastr.info("Salesforce Resync successful for #{callKey}")

    # TODO:  Use proper model bindings to avoid the aditional request
    @collection.fetch({reset: true})

  setupDatepickers: =>
    end = moment().hours(0).minutes(0).seconds(0).milliseconds(0)
    datePickerOpts =
      autoclose   : true
      format      : 'mm/dd/yyyy'
      minViewMode : 'days'
      maxViewMode : 'months'
      orientation : 'bottom'
      endDate     : end.toDate()

    @ui.startDate.datepicker(datePickerOpts)
    @ui.startDate.datepicker().on('changeDate', (e) =>
      start = e.date
      current = moment().hours(0).minutes(0).seconds(0).milliseconds(0)
      if @ui.endDate.datepicker('getDate')
        end = @ui.endDate.datepicker('getDate')
        duration = moment.duration(moment(end).diff(moment(start)))
        if duration.get('months') > 2 or (duration.get('months') is 2 and duration.get('days') > 0)
          end = moment(start).add(2, 'months')
          @ui.endDate.datepicker('update', if end.isAfter(current) then current.toDate() else end.toDate())
      else
        end = moment(start).add(2, 'months')
        @ui.endDate.datepicker('update', if end.isAfter(current) then current.toDate() else end.toDate())
    )

    @ui.endDate.datepicker(datePickerOpts)
    @ui.endDate.datepicker().on('changeDate', (e) =>
      end = e.date
      if @ui.startDate.datepicker('getDate')
        end = @ui.endDate.datepicker('getDate')
        duration = moment.duration(moment(end).diff(moment(start)))
        if duration.get('months') > 2 or (duration.get('months') is 2 and duration.get('days') > 0)
          start = moment(end).subtract(2, 'months')
          @ui.startDate.datepicker('update', start.toDate())
      else
        start = moment(end).subtract(2, 'months')
        @ui.startDate.datepicker('update', start.toDate())
    )

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    return unless @validate()

    startDate = @ui.startDate.datepicker('getDate')
    endDate = @ui.endDate.datepicker('getDate')
    @collection = new PagedCollection(
      queryParams:
        accountId: @accountId
        category: @ui.category.val()
        range: @ui.range.val()
        userId: @userPicker.currentView.val()
        startDate: if startDate then moment(startDate).format('MM/DD/YYYY') else ''
        endDate: if endDate then moment(endDate).format('MM/DD/YYYY') else ''
      url: 'auditLogs'
    )

    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection))

    @collection.fetch(reset: true)

  valueCell: ->
    mainClass = @
    class extends Backgrid.StringCell
      render: ->
        if mainClass.isSalesforceErrorView(@model) and mainClass.user().can('calls:view') and @model.get(@column.get('name'))
          url = '#calls/' + @model.get(@column.get('name'))
          label = @model.get(@column.get('name'))
          html = "<a href='#{url}'>#{_.escape(label)}</a>"

          $(@el).html(_.template(html))
        else
          value = @formatter.fromRaw(@model.get(@column.get('name')), @model)
          $(@el).empty()
          $(@el).text(value)
          @delegateEvents()

        @

  referenceCell: ->
    mainClass = @
    class extends Backgrid.StringCell
      render: =>
        if @model.get('type') is 'SalesforceError' and @model.get('recordId')
          $(@el).text(@model.get('recordId'))
          @
        else if (@model.get('type') is 'SmartRecordingPlayed' or @model.get('type') is 'SmartRecordingDeleted') and @model.get('callKey')
          # - Only support can view Call Inspector
          # - Zoom Video Call has unique ID and we can't show related call in call inspector
          if mainClass.user().can('calls:view') and @model.get('callKey').match(/^rc[0-9]/)
            url = '#calls/' + @model.get('callKey')
            label = @model.get('callKey')
            html = "<a href='#{url}'>#{_.escape(label)}</a>"
            $(@el).html(_.template(html))
            @
          else
            $(@el).text(@model.get('callKey'))
            @
        else
          @

  buildGrid:(collection) ->
    mainClass = @

    values = [
      ['Recording', 'SmartRecordingPlayed']
      ['Recording', 'SmartRecordingDeleted']
      ['Emergency Call Flow', 'EmergencyFlow']
      ['Blocked Numbers', 'BlockedNumber']
      ['GDPR', 'GDPR']
      ['Salesforce Error', 'SalesforceError']
      ['Data Deletion Policy', 'DataDeletionPolicy']
      ['Account Recording Settings', 'CallRecordingSettingChanged']
      ['Account Recording Settings (Country)', 'CallRecordingCountrySetting']
      ['Account Recording Settings (State)', 'CallRecordingStateSetting']
      ['User Recording Settings', 'UserCallRecordingSettingChanged']
    ]
    if App.featureFlag.isFlagged(App.featureFlag.flags.v294_ID_2850)
      values.push(['Support Recording Access', 'SupportRecordingAccess'])

    categoryCell = Backgrid.SelectCell.extend(
      optionValues: values
    )

    datetime = BackgridCell.dateTime()

    userCell = BackgridCell.usersCell('user.id', 'user.displayName')

    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="retry-error"]': 'retryError'

      retryError: =>
        errorId = @model.get('id')
        callKey = @model.get('data')
        model = new SfRetryErrorModel(salesforceErrorId: errorId)
        model
          .persist()
          .done(->
            toastr.info("Salesforce Resync for #{callKey} enqueued.")
          )

      render: ->
        $(@el).html('''
          <button class="btn btn-success glyphicon glyphicon-refresh" data-action="retry-error"></button>
        ''')

        # TODO:  Use proper element <-> model bindings
        mainClass.disableElementByModel(@el, @model)

        @

    columns = [
      { name: 'timestamp',          label: 'Date',                cell: datetime },
      { name: 'type',               label: 'Category',            cell: categoryCell },
      { name: 'additionalInfo',     label: 'Additional Details',  cell: 'string' },
      { name: 'user.displayName',   label: 'User Name',           cell: userCell },
      { name: 'previousData',       label: 'Old Value',           cell: @valueCell() },
      { name: 'data',               label: 'New Value',           cell: @valueCell() },
      { name: 'recordId',           label: 'Reference ID',        cell: @referenceCell() },
      { name: 'resolved',           label: 'Resolved',            cell: 'boolean' },
      { name: '',                   label: 'Retry',               cell: actionCell }
    ]

    @grid = new Backgrid.Grid(
      collection : collection,
      columns    : columns,
      emptyText  : 'No logs found.'
    )

  # TODO:  Tooltips explaining why these are disabled?
  disableElementByModel: (element, model) ->
    if !model.get('retryable') || model.get('resolved') ||
      !@isSalesforceErrorView(model)
        $(element).addClass('disabled')

  isSalesforceErrorView: (model) ->
    model?.get('type') is 'SalesforceError'

  validate: ->
    if not @ui.range.val()
      toastr.warning('Please select date range', 'Oops!')
      return false
    else if @ui.range.val() is 'custom' and (not @ui.startDate.datepicker('getDate') or not @ui.endDate.datepicker('getDate'))
      toastr.warning('Start or End date cannot be empty', 'Oops!')
      return false
    else if @ui.endDate.datepicker('getDate') < @ui.startDate.datepicker('getDate')
      toastr.warning('End date cannot be before start date', 'Oops!')
      return false
    true

module.exports = LogsView
