import styled from 'styled-components'
export const IconSize = 24

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
  .empty-salesforce-state {
    margin: 0 ${({ theme }) => theme.spacing * 0.5}px ${({ theme }) => theme.spacing}px
      ${({ theme }) => theme.spacing * 0.5}px;
    text-align: center;
  }
`

export const Section = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: ${({ theme }) => theme.spacing}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  margin: ${({ theme }) => theme.spacing * 2}px ${({ theme }) => theme.spacing * 0.5}px;
  padding: ${({ theme }) => theme.spacing * 3}px;

  .rdna-text {
    margin: 0 ${({ theme }) => theme.spacing * 2}px;
  }
  .rdna-select {
    min-width: ${({ theme }) => theme.spacing * 50}px;
  }
  .support-input {
    width: ${({ theme }) => theme.spacing * 50}px;
    margin: 0;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing * 10}px;
`

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
  align-items: center;
`

export const SectionSubHeader = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing * 4}px 0;
  align-items: center;
  justify-content: space-between;
`
