BackgridCell = require('helpers/backgrid-cell')
BaseGrid = require('base/grid')
BaseView = require('base/view')
CentralNumberCollection = require('./collection')
SmartNumberWidget       = require('widgets/smart-numbers-widget/main-view')

class CentralNumbersView extends BaseView

  template: require('./template')

  className: 'central-numbers'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add' : 'showEditor'

  initialize: (options) ->
    { @accountId } = options
    @collection = new CentralNumberCollection()

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>
    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:id',
      label : ':number',
      attrs : ['id', 'number'])

    columns = [
      { name: 'name',         label: 'Name',         cell: 'string' },
      { name: 'number',       label: 'Smart Number', cell: numbCell },
      { name: 'dateLastUsed', label: 'Last Used',    cell: 'datetime' },
      { name: 'id',           label: 'ID',           cell: 'string' },
      { name: '',             label: 'Delete',       cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No central numbers found.'
    )

  showEditor: =>
    view = new SmartNumberWidget(accountId: @accountId, type: 'account')
    @showChildView('modal', view)
    @listenTo(view, 'assigned', @render)

module.exports = CentralNumbersView