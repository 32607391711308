Handlebars = require('handlebars/runtime').default
Handlebars.registerHelper('imageUrl', (val, options) ->
  "images/#{val}"
)

Handlebars.registerHelper('linkTo', (entity, pk, label) ->
  if pk
    label = pk if typeof label is 'object'
    new Handlebars.SafeString("<a href='##{_.escape(entity)}/#{_.escape(pk)}'>#{_.escape(label)}</a>")
  else
    ''
)

Handlebars.registerHelper('salesforceUrl', (id) ->
  if id
    url = App.session.user.salesforceUrl(id)
    new Handlebars.SafeString("<a class='sf-link tooltips' href='#{url}' target='_new'><span>Link to Salesforce</span></a>")
  else
    ''
)

Handlebars.registerHelper('customCondition', (key, value, options) ->
  if key is value
    options.fn(@)
  else
    options.inverse(if _.isEmpty(@) then '' else @)
)

Handlebars.registerHelper('additionalSfdcFields', (fields) ->
  html = """ <div class='col-md-6'><div class='form-group'>
          <label class='col-md-4'>additionalSfdcFields</label><div class='col-md-4'>"""
  html += """ <span class='col-md-8'>#{field.name}: #{field.value}</span>""" for field in fields
  html += '</div></div></div>'
  new Handlebars.SafeString(html)
)
