import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const CallDispositionsIcon = ({ color = Colors.N100, size = 24}: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="call-dispositions-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17647 6H14.8235C17.6824 6 20 8.37123 20 11.2963C20 14.2214 17.6824 16.5926 14.8235 16.5926L12.4706 19V16.5926H9.17647C6.31758 16.5926 4 14.2214 4 11.2963C4 8.37123 6.31758 6 9.17647 6Z"
      stroke={color}
      strokeWidth="0.75"
      strokeLinejoin="round"
    />
    <rect
      x="8.25"
      y="11.25"
      width="7.5"
      height="0.5"
      rx="0.25"
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
)

export default CallDispositionsIcon