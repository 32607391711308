BaseCallTrackingModel = require('../__components/base-call-tracking-model')

class CustomCallTrackingModel extends BaseCallTrackingModel
  defaults: ->
    newDefaults =
      source: 'Custom'
      destinationURL: ''

    _.extend(BaseCallTrackingModel.prototype.defaults(), newDefaults)

module.exports = CustomCallTrackingModel
