import * as Sentry from '@sentry/react'

export async function captureException(
  err: Error,
  extra?: Record<string, any>,
  fingerprint?: string[]
): Promise<string | void> {
  return new Promise(resolve => {
    console.error('captureException:', err)
    /* istanbul ignore if */
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope: any) => {
        if (extra) scope.setExtras(extra)
        if (fingerprint) scope.setFingerprint(fingerprint)
        const eventId = Sentry.captureException(err)
        resolve(eventId)
      })
    } else {
      resolve(undefined)
    }
  })
}
