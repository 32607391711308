BaseModel = require('base/model')
BaseCollection = require('base/collection')
CallDispositionModel = require('../model')

class CallDispositionTeam extends BaseModel
  url: -> 'callDisposition/teams'

  defaults:
    accountId: null
    disposition: new CallDispositionModel()

  toJSON: (settings) =>
    fields = {}
    fields['disposition'] = @get('disposition').toJSON()
    fields['accountId'] = @get('accountId')
    callDispositionTeams = []
    settings.each((item, index) =>
      callDispositionTeams.push({
        id: item.get('callDispositionTeamId')
        team:
          id: item.get('id')
        inbound: item.get('inbound')
        outbound: item.get('outbound')
      })
    )
    fields['settings'] = JSON.stringify(callDispositionTeams)
    fields

  persist: (options) =>
    data = @toJSON(options)
    defaults =
      data        : data
      emulateJSON : true
      url         : @url()

    options = _.extend(defaults, options)
    Backbone.sync('update', @, options)

module.exports = class
  @Model: CallDispositionTeam
