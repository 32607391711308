import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function MessagesIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="messages-icon">
      <g clipPath="url(#clip0_9043_75722)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5917 10.5881H38.4083C46.9219 10.5881 53.8236 17.6696 53.8236 26.4051C53.8236 35.1406 46.9219
              42.2221 38.4083 42.2221L31.4014 49.4117V42.2221H21.5917C13.0781 42.2221 6.17651 35.1406 6.17651
              26.4051C6.17651 17.6696 13.0781 10.5881 21.5917 10.5881Z"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9043_75722">
          <rect width={60} height={60} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
