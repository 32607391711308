import { RelatedRecordsOrder, RelatedRecordsProps, RelatedRecordsQuery } from '../types'
import { standardSalesforceTypes } from '../const'
import React from 'react'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import styled from 'styled-components'

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const formatRulesDescriptions = (
  queries: string[],
  listAvailableRules: { [key: string]: RelatedRecordsQuery }
) => {
  const rules = Object.entries(listAvailableRules)
    .filter(([key]) => queries.includes(key))
    .map(([, value]) => value.description)
    .join(', ')
  return rules.length > 0 ? rules : 'None'
}

export const formatOrderDescriptions = (order: string, listAvailableOrders: { [key: string]: RelatedRecordsOrder }) =>
  Object.entries(listAvailableOrders)
    .filter(([key]) => order === key)
    .map(([, value]) => value.label)

export const createOptions = (options: { [key: string]: RelatedRecordsOrder | RelatedRecordsQuery }) =>
  Object.entries(options).map(([key, value]) => ({ label: value.description, value: key }))

export const createCustomObjectsOptions = (obj: { [key: string]: string }, existing: string[]) =>
  Object.entries(obj).map(([value, label]) => ({
    label,
    value,
    disabled: existing?.includes(value)
  }))

export const isCustomRecord = (record: RelatedRecordsProps) => !standardSalesforceTypes.includes(record.typeRecord)

export const hasNoOptions = (options: { [key: string]: RelatedRecordsQuery }) => Object.entries(options).length < 1

export const typeRecordCell = (value: string, record: RelatedRecordsProps) => {
  return (
    <StyledItem>
      <RdnaText> {value} </RdnaText>
      {isCustomRecord(record) ? (
        <RdnaText color={'neutral'} variant={'meta'}>
          {record.apiName}
        </RdnaText>
      ) : (
        <></>
      )}
    </StyledItem>
  )
}
