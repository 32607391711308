import React from 'react'
import styled from 'styled-components'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { Colors } from '@ringdna/common/src/constants/colors'
import PlusIcon from '@ringdna/common/src/assets/icons/svg/Plus'

const StyledContainer = styled.div`
  button {
    border: 2px solid ${Colors.M50};
    border-radius: 5px;
    color: ${Colors.M50} !important;
  }
`

type Props = {
  onClick: () => void
  text: string
}

const CreateBtn = ({ onClick, text = 'Create New' }: Props) => {
  return (
    <StyledContainer>
      <RdnaButton Icon={() => <PlusIcon color={Colors.M50} />} text={text} onClick={onClick} color="contrast" />
    </StyledContainer>
  )
}

export default CreateBtn
