import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useAccountDialerSettings } from '../../store/api/accounts-api'
import { ActionType, useSupportSettingsDispatch } from './utils/contextReducer'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import { Dispatch } from './utils/contextReducer'

import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import RdnaHorizontalTab from '@ringdna/common/src/components/RdnaHorizontalTab'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import SaveAllSettingsButton from './components/SaveAllSettingsButton'
import SalesforceRecordSettings from './components/SupportSettingsTab/sections/_SalesforceRecordSettings'
import SalesforcePackages from './components/SupportSettingsTab/sections/_SalesforcePackages'
import Tasks from './components/SupportSettingsTab/sections/_Tasks'
import AgentStatusSettings from './components/SupportSettingsTab/sections/_AgentStatusSettings'
import AdvancedCallRecordingSettings from './components/SupportSettingsTab/sections/_AdvancedCallRecordingSettings'
import MultiMatchScenarios from './components/SupportSettingsTab/sections/_MultiMatchScenarios'
import ExtensionNonPhoneFilter from './components/SupportSettingsTab/sections/_ExtensionNonPhoneFilter'
import CallsTelephonySettings from './components/SupportSettingsTab/sections/_CallsTelephonySettings'
import Sip from './components/SupportSettingsTab/sections/_Sip'
import WebRTC from './components/SupportSettingsTab/sections/_WebRTC'
import ReactToMarionette from './utils/ReactToMarionette'
import MarketingCallTracking from './components/SupportSettingsTab/sections/_MarketingCallTracking'
import SmsSettings from './components/SupportSettingsTab/sections/_SMS'

type SupportSettingsProps = {
  accountId: string
}

export default React.memo(SupportSettings)
function SupportSettings({ accountId }: SupportSettingsProps) {
  const [tabId, setTabId] = useState<string>()
  const [dialerSettings] = useAccountDialerSettings({ meta: { id: accountId } })
  const [haveData, setHaveData] = useState(false)

  const dispatch = useSupportSettingsDispatch()

  useEffect(() => {
    if (dialerSettings) {
      storeSettings(dispatch, dialerSettings)
      setHaveData(true)
    }
  }, [dialerSettings, dispatch, setHaveData])

  const tabs = useMemo(
    () => [
      {
        id: 'support-settings',
        title: 'Support Settings',
        Content: (
          <div>
            <SalesforceRecordSettings />
            <SalesforcePackages />
            <Tasks />
            <AgentStatusSettings />
            <AdvancedCallRecordingSettings />
            <MultiMatchScenarios />
            <ExtensionNonPhoneFilter />
            <CallsTelephonySettings />
            <SmsSettings />
            <Sip />
            <WebRTC />
            <MarketingCallTracking />
          </div>
        )
      },
      {
        id: 'local-presence',
        title: 'Local Presence',
        Content: <ReactToMarionette id={'local-presence'} />
      },
      {
        id: 'emergency',
        title: 'Emergency',
        Content: <ReactToMarionette id={'emergency'} />
      }
    ],
    []
  )

  const onTabChange = (id: string) => {
    setTabId(id)
  }

  return (
    <Container>
      <SectionHeader
        headingText="RingDNA Dialer: Support Settings"
        rightColumn={haveData && tabId === 'support-settings' && <SaveAllSettingsButton />}
      />
      <RdnaLoader
        data={dialerSettings && [dialerSettings]}
        noResultsMsg={<NoResultsMsg title="Something went wrong, try refreshing!" Icon={<BinocularsIconLeaves />} />}
        loading={!haveData}
        top="150px"
      >
        <RdnaHorizontalTab renderIndividually tabs={tabs} onTabChange={onTabChange} />
      </RdnaLoader>
    </Container>
  )
}

const storeSettings = (dispatch: Dispatch, dialerSettings: DialerSettings) => {
  const accountSettings = {
    'account.id': dialerSettings.account.id,
    'account.packageVersion': dialerSettings.account.packageVersion,
    'account.cadencePackageVersion': dialerSettings.account.cadencePackageVersion,
    'account.advancedSolutionPackageVersion': dialerSettings.account.advancedSolutionPackageVersion,
    'account.hotLeadsPackageVersion': dialerSettings.account.hotLeadsPackageVersion
  }
  Object.keys(dialerSettings.account.settings).forEach(key => {
    const playKey = `settings.${key}`
    // @ts-ignore
    accountSettings[playKey] = dialerSettings.account.settings[key]
  })
  // @ts-ignore
  dispatch({ type: ActionType.STORE_ACCOUNT_SETTINGS, payload: accountSettings })
  dispatch({ type: ActionType.STORE_FEATURE_VISIBILITIES, payload: dialerSettings.features })
}

const Container = styled.div`
  min-height: 100vh;
  max-height: 100%;

  .rdna-button {
    margin-right: 0;
  }
`
