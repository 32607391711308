AddCallScriptModal  = require('./modals/view')
BackgridCell        = require('helpers/backgrid-cell')
BaseGrid            = require('base/grid')
BaseView            = require('base/view')

class CallScriptView extends BaseView

  template: require('./template')
  className: 'call-script-view'

  regions:
    callScript: 'div.call-script'
    modal: 'div.modal-container'

  ui:
    addCallScript: 'a.add-call-script'

  bindings:
    'a.add-call-script': "classes:{'disabled':hasCallScript}"

  events:
    'click @ui.addCallScript': 'addCallScript'

  computeds:
    hasCallScript:
      get: ->
        @callScriptCollection.length isnt 0


  initialize: (options) ->
    @callScriptCollection = options.callScriptCollection
    @listenTo(@callScriptCollection, 'add remove reset', ->
      @applyBindings()
    )
    @accountId = options.accountId

  onRender: =>
    @callScriptView = @buildCallFlowGrid(@callScriptCollection)
    @showChildView('callScript', @callScriptView)

  addCallScript: =>
    view = new AddCallScriptModal(accountId: @accountId)
    @showChildView('modal', view)
    @listenTo(view, 'save', (callFlowId) =>
      @trigger('addCallScript', callFlowId)
    )

  buildCallFlowGrid: (collection) ->
    СallScriptDeleteCell = class extends BaseGrid.DeleteCell
      delete: ->
        bootbox.confirm('Are you sure you want to remove this call script from this smart number?', (ok) =>
          @model.destroy() if ok
          true
        )
    cols = [
      {label: 'Name',         name: 'name',         cell: 'string'}
      {label: 'Description',  name: 'description',  cell: 'string'}
      {label: 'Delete',       name: '',             cell: СallScriptDeleteCell}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'There are no Call Scripts assigned.'
    )


module.exports = CallScriptView