AccountModel                     = require('features/accounts/model')
AddTeamMembersModal              = require('../members/modals/view')
BaseCollection                   = require('base/collection')
BackgridCell                     = require('helpers/backgrid-cell')
BackgridRow                      = require('helpers/backgrid-row')
BaseGrid                         = require('base/grid')
BaseView                         = require('base/view')
BaseModel                        = require('base/model')
DispositionTeamView              = require('../dispositions/view')
LockedSettingView                = require('widgets/locked-setting/view')
LockedSettingModel               = require('widgets/locked-setting/model')
MemberCollection                 = require('../members/collection')
MemberModel                      = require('../members/model')
MailingPickerView                = require('./mailing-picker/view')
SelectedUsersAndEmailsCollection = require('./mailing-picker/collection')
TeamModel                        = require('./model')
TeamsCollection                        = require('./collection')
VoicemailDropTeamView            = require('../voicemail-drop/view')
UserStatusView                   = require('../user-status/view')

class TeamView extends BaseView

  template: require('./template')
  className: 'team-overview'

  bindings:
    'h1.name'                  : 'text:title'
    'input.name'               : 'value:name,disabled:isDeleted'
    'input.description'        : 'value:description,disabled:isDeleted'
    'select.open-barge'        : 'value:barge,disabled:isDeleted'
    '.call-disposition-required-state' : 'value:callDispositionRequiredState,disabled:isDeleted'
    '.call-disposition-required-container' : 'toggle:manageCallDispositionRequiredByTeam'
    'button.delete'            : 'toggle:not(any(isNew,isDeleted))'
    'button.restore'           : 'toggle:all(not(isNew),isDeleted)'
    'button.save-team'         : 'toggle:not(isDeleted)'
    '.callRecordingLocked'     : 'toggle:enableCallRecordingSettings'
    '.callRecordingLockedBy'   : 'toggle:not(any(isDeleted,enableCallRecordingSettings))'
    '.longest-waiting-agent'   : 'toggle:longestWaitingAgent'
    '.team-type'               : 'value:type,disabled:isDeleted'
    '.glyphicon-plus-sign'     : 'toggle:not(isDeleted)'
    '.call-recording-option'   : 'value:callRecordingOption, enabled:enableCallRecordingSettings'
    '.call-recording-container': 'toggle:all(settingsAvailable)'
    '.additional-actions'      : 'toggle:not(any(isNew,isDeleted))'

  computeds:
    title:
      deps: ['name', 'id']
      get: (name, id) ->
        if id and name
          'Team: ' + name
        else
          'New Team'
    isNew:
      get: ->
        @addNew
    barge:
      deps: ['settings']
      get: (settings) ->
        if settings then String(settings.openBarge) else 'false'
      set: (value) ->
        @model.get('settings').openBarge = value is 'true'
    longestWaitingAgent:
      deps: ['type']
      get: (type) ->
        type is 'LongestWaitingAgent'
    enableCallRecordingSettings:
      deps: ['isDeleted']
      get: (isDeleted) ->
        return false if isDeleted
        lockedSetting = @model.get('account')?.lockedSetting
        if lockedSetting and lockedSetting.callRecording
          return false
        true
    settingsAvailable:
      get: ->
        if @model.get('account')?.settings then true else false
    callDispositionRequiredState:
      get: ->
        if @model?.get('settings')?.callDispositionRequiredState then @model?.get('settings').callDispositionRequiredState else 'None'
      set: (value) ->
        @model.get('settings').callDispositionRequiredState = value
        @applyBindings()
    callRecordingOption:
      get: ->
        lockedSetting = @model.get('account')?.lockedSetting
        accountSettings = @model.get('account')?.settings
        if lockedSetting and lockedSetting.callRecording and accountSettings
          accountSettings.callRecordingOverrideOption
        else @model?.get('settings')?.callRecordingOption
      set: (value) ->
        @model.get('settings').callRecordingOption = value
        @model.trigger('change')
    manageCallDispositionRequiredByTeam:
      get: ->
        if @model?.get('account') then @model?.get('account').settings.manageCallDispositionRequiredByTeam else false

  regions:
    callRecordingLocked : '.callRecordingLocked'
    modal               : 'div.modal-container'
    members             : 'div.members'
    supervisors         : 'div.supervisors'
    dispositions        : 'div.disposition-team'
    voicemailDrop       : 'div.voicemail-drop-team'
    mailingUsersPicker  : 'div.mailing-users-picker'
    mailingEmailsPicker : 'div.mailing-emails-picker'
    userStatuses        : 'div.user-statuses'

  ui:
    deleteButton  : 'button.delete'
    saveButton    : 'button.save-team'
    restoreButton : 'button.restore'

  events:
    'click a.add-members'       : 'addMembers'
    'click a.add-supervisors'   : 'addSupervisors'
    'click button.delete'       : 'delete'
    'click button.save-team'    : 'save'
    'click button.restore'      : 'restore'
    'click a.clone-team'        : 'cloneTeam'
    'click a.create-call-queue' : 'createCallQueue'

  initialize: (options) =>
    @allTeams = new TeamsCollection()

    @addNew = options.addNew
    @showDisposition = false
    if @addNew
      accountId = @user().get('accountId')
      accountName = @user().get('accountName')
      if @user().accountPickerRenderable() and @user().getSelectedSupportAccountId(true)?.toString() isnt @user().get('accountId')?.toString()
        accountId = @user().getSelectedSupportAccountId(true)
        accountName = @user().getSelectedSupportAccountName()
      @model = new TeamModel(
        account:
          id: accountId
          name: accountName
          lockedSetting: { callRecording: @user().get('accountCallRecordingLocked') }
          settings: {
            'callRecordingOverrideOption' : @user().get('accountCallRecordingOption')
            'manageCallDispositionRequiredByTeam' : @user().get('accountManageCallDispositionRequiredByTeam')
          }
        settings:
          openBarge: false
          callRecordingLocked: false
          callDispositionRequiredState: 'None'
          callRecordingOption: @user().get('accountCallRecordingOption')
      )
    else
      @model = new TeamModel(id: options.teamId)
      @customUserStatusEnabled = @user().get('customUserStatusEnabled')

  onRender: =>
    if @addNew
      if @user().accountPickerRenderable() and @user().getSelectedSupportAccountId(true)?.toString() isnt @user().get('accountId')?.toString()
        accountModel = new AccountModel(id: @model.get('account').id)
        accountModel.fetch().done(=>
          @model.get('account').lockedSetting.callRecording = accountModel.get('lockedSetting').callRecording
          @model.get('account').settings.callRecordingOverrideOption = accountModel.get('callRecordingOverrideOption')
          @model.get('account').settings.manageCallDispositionRequiredByTeam = accountModel.get('manageCallDispositionRequiredByTeam')
          @model.get('settings').callRecordingOption = accountModel.get('callRecordingOverrideOption')
          @initLockedSettings()
          @applyBindings()
        )
      else
        @initLockedSettings()
    else
      @model.fetch().done(=>
        @initLockedSettings()
        @showDisposition = @model.get('account')?.settings?.manageCallDispositions
        @supervisorCollection = new MemberCollection([], teamId: @model.get('id'), type: 'Supervisor')
        @memberCollection = new MemberCollection([], teamId: @model.get('id'), type: 'Regular')
        @selectedUsersAndEmailsCollection = new SelectedUsersAndEmailsCollection([], teamId: @model.get('id'))
        for value in @model.get('userTeams')
          if value.rememberSubscription then value.user['rememberSubscription'] = 'Yes' else value.user['rememberSubscription'] = 'No'
          value.user['teamId'] = @model.get('id')
          value.user['listenIn'] = value.listenIn
          if value.type is 'Regular'
            @memberCollection.add(new MemberModel(value.user))
          else if value.type is 'Supervisor'
            @supervisorCollection.add(new MemberModel(value.user))
        for value in @model.get('reportNotificationsUsers')
          if !!value.email
            @selectedUsersAndEmailsCollection.add(new BaseModel({ email: value.email }))
          else
            @selectedUsersAndEmailsCollection.add(new BaseModel(value.user))

        @model.set('memberCollection', @memberCollection)
        @model.set('supervisorCollection', @supervisorCollection)
        @model.set('selectedUsersAndEmailsCollection', @selectedUsersAndEmailsCollection)
        @model.unset('userTeams', silent: true)
        @initializeChildViews()
        @applyBindings()
      )
  onAfterRender: =>
    console.log('render complete')

  onAttach: =>
    @$('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  onDomRefresh: =>
    @$('[data-toggle="tooltip"]').tooltip()

  initializeChildViews: =>
    if @memberCollection and @memberCollection.models.length and not @membersView
      @listenTo(@memberCollection, 'remove', @onUpdateMembers)
      @onUpdateMembers()
    if @supervisorCollection and @supervisorCollection.models.length and not @supervisorsView
      @supervisorsView = @buildMembersGrid(@supervisorCollection, 'supervisor')
      @showChildView('supervisors', @supervisorsView)
      @listenTo(@supervisorCollection, 'remove', @onUpdateSupervisors)
    if @showDisposition and not @dispositionTeamView
      @dispositionTeamView = new DispositionTeamView(
        accountId: @model.get('account').id
        teamId: @model.get('id')
      )
      @showChildView('dispositions', @dispositionTeamView)
    if @customUserStatusEnabled and not @userStatusView
      @userStatusView = new UserStatusView(
        accountId: @model.get('account').id
        teamId: @model.get('id')
      )
      @showChildView('userStatuses', @userStatusView)
    if not @voicemailDropView and not @model.get('isDeleted')
      @voicemailDropView = new VoicemailDropTeamView(
        teamId: @model.get('id')
        parentView: @
      )
      @showChildView('voicemailDrop', @voicemailDropView)
    @mailingUsersPickerView = new MailingPickerView(
      model: @model,
      type: 'users'
    )
    @showChildView('mailingUsersPicker', @mailingUsersPickerView)
    @mailingEmailsPickerView = new MailingPickerView(
      model: @model,
      type: 'emails'
    )
    @showChildView('mailingEmailsPicker', @mailingEmailsPickerView)

  initLockedSettings: =>
    @lockedSettingModel = new LockedSettingModel(
      accountId: @model.get('account')?.id
      callRecordingTeam: @model.get('settings').callRecordingLocked
    )
    @callRecordingLocked = new LockedSettingView(
      basicSettings: @model
      model: @lockedSettingModel
      setting: 'callRecordingTeam')
    @showChildView('callRecordingLocked', @callRecordingLocked) if @getRegion('callRecordingLocked')

  buildMembersGrid: (collection, type) ->
    userCell = BackgridCell.usersCell('id', 'displayName')

    checkboxCell = class extends Backgrid.BooleanCell
      events: ->
        'change input': (e) ->
          @model.set(@column.get('name'), e.target.checked)
          @model.persist()

    cols = [
      {label: 'Name',     name: 'displayName',    cell: userCell,            renderable: @user().can('users:edit:*')}
      {label: 'Name',     name: 'displayName',    cell: 'string',            renderable: not @user().can('users:edit:*')}
      {label: 'Title',    name: 'title',          cell: 'string'}
      {lable: 'Listen-In',name: 'listenIn',       cell: checkboxCell,        renderable: type isnt 'supervisor', editable: true, sortable: false}
      {label: 'Location', name: 'location.name',  cell: 'string'}
      {label: 'Teams',    name: 'teamCount',      cell: 'string'}
      {label: 'Remove',   name: '',               cell: BaseGrid.DeleteCell, renderable: not @model.get('isDeleted'), sortable: false}
    ]

    emptyText = if type is 'supervisor' then 'No supervisors associated.' else 'No members associated.'
    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : emptyText
    )

  addMembers: =>
    return unless @validateModel()
    view = new AddTeamMembersModal(
      type: 'Regular'
      accountId: @model.get('account')?.id
      teamId: @model.get('id')
    )
    @showChildView('modal', view)
    @listenTo(view, 'save', @onAddMembers)

  addSupervisors: =>
    return unless @validateModel()
    view = new AddTeamMembersModal(
      type: 'Supervisor'
      accountId: @model.get('account')?.id
      teamId: @model.get('id')
    )
    @showChildView('modal', view)
    @listenTo(view, 'save', @onAddSupervisors)

  cloneTeam: =>
    App.api.post(
      path    : "teams/#{@model.get('id')}/clone"
      success : (response) =>
        App.router.navigate("#teams/#{response}", {trigger: true})
        toastr.info('Team copied.')
    )

  createCallQueue: ->
    App.api.post(
      path    : "teams/#{@model.get('id')}/createCallQueue"
      success : (response) =>
        App.router.navigate("#call-queues/#{response}", {trigger: true})
    )

  onAddMembers: (members) =>
    @addUsersToTeam(members, 'Regular', @onUpdateMembers)

  onAddSupervisors: (supervisors) =>
    @addUsersToTeam(supervisors, 'Supervisor')

  addUsersToTeam: (collection, type, callback) =>
    collection.create().done?(=>
      for model in collection.models
        model.set('teamId', @model.get('id'))
        if type is 'Regular'
          @memberCollection.add(new MemberModel(model.attributes))
        else if type is 'Supervisor'
          @supervisorCollection.add(new MemberModel(model.attributes))
      @initializeChildViews()
      callback?()
    )

  onUpdateMembers: =>
    return unless @memberCollection?.models
    if @memberCollection.models.length <= 1
      model.set('deletable', false) for model in @memberCollection.models
    if @memberCollection.models.length > 1
      model.set('deletable', true) for model in @memberCollection.models
    @membersView = @buildMembersGrid(@memberCollection, 'member')
    @showChildView('members', @membersView)

  onUpdateSupervisors: =>
    return unless @supervisorsView and @supervisorCollection
    if @supervisorCollection.models.length < 1
      @showChildView('supervisors', new BaseView({
        template: _.constant('<p class="italic">No supervisors associated.</p>')
      }))
      @supervisorsView = null

  delete: =>
    return unless @validateModel()
    bootbox.dialog(
      title: 'Delete Team'
      message: "Are you sure you want to delete team: #{_.escape(@model.get('name'))}?",
      buttons:
        ok:
          label: 'Delete'
          className: 'btn-danger'
          callback: =>
            @model.delete(ladda: @ui.deleteButton[0]).done(=> @refresh(true))
    )

  restore: =>
    return unless @validateModel()
    ladda = Ladda.create(@ui.restoreButton[0])
    ladda.start()
    App.api.put(
      path: '/api/v2/app/teams/restore/' + @model.get('id')
      success : => @refresh(false)
      complete: => ladda.stop()
    )

  refresh: (isDeleted) =>
    @model.set(isDeleted: isDeleted)
    @membersView = null
    @supervisorsView = null
    @dispositionTeamView = null
    @customStatusView = null
    @voicemailDropView?.destroy()
    @voicemailDropView = null
    @initializeChildViews()
    @applyBindings()

  save: =>
    return unless @validate()
    @model.get('settings').callRecordingLocked = @lockedSettingModel.get('callRecordingTeam')
    if @showDisposition and @dispositionTeamView?.collection?.length
      ladda = Ladda.create(@ui.saveButton[0])
      ladda.start()
      $.when(@model.persist(), @dispositionTeamView.collection.persist(), @model.get('selectedUsersAndEmailsCollection').persist())
        .done(@savedActions).always(-> ladda.stop())
    else
      @model.persist(ladda: @ui.saveButton[0]).done?(@savedActions)
      @model.get('selectedUsersAndEmailsCollection').persist() unless @addNew

  savedActions: =>
    toastr.info('Team info saved.')
    if @model.get('id')
      @addNew = false
      App.router.navigate('#teams/' + @model.get('id'), {trigger: true})

  validateModel: =>
    if @model and @model.id and not @addNew
      return true
    toastr.warning('Please save team before current action.')
    false

  validate: =>
    if not @model.get('name') or not @model.get('name').trim()
      toastr.warning('Please enter a Name.')
      return false
    true

module.exports = TeamView
