import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ShareIcon = ({ color = Colors.N100, size = 24 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M5 8.5V16.375C5 17.8247 6.17525 19 7.625 19H16.375C17.8247 19 19 17.8247 19 16.375V14.625"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M8.5 15.5V12.875C8.5 9.97551 10.8505 7.625 13.75 7.625H18.125" stroke={color} strokeLinecap="round" />
    <path d="M15.5 5L18.125 7.625L15.5 10.25" stroke={color} strokeLinecap="round" />
  </svg>
)

export default ShareIcon
