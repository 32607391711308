import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function AmountIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="amount-icon">
      <path
        d="M15.9856 8.58482C14.9418 8.18269 13.7723 7.83082 12.6279 7.83082C11.4458 7.83082 10.6912 8.34605 10.6912 9.18802C10.6912 10.1431 11.4961 10.4949 13.0806 10.8971C15.6083 11.538 16.7779 12.669 16.7779 14.6168C16.7779 16.9165 15.0299 18.5376 12.4015 18.5376C10.49 18.5376 9.01864 17.9092 8 17.2432L8.64136 15.3708C9.67258 15.8483 10.905 16.351 12.1626 16.351C13.5459 16.351 14.3382 15.6473 14.3382 14.7927C14.3382 13.8125 13.307 13.4104 11.8733 13.0837C9.9744 12.6438 8.25152 11.6888 8.25152 9.35138C8.25152 7.16479 10.0373 5.6568 12.5399 5.6568C14.1747 5.6568 15.5832 6.1846 16.627 6.80036L15.9856 8.58482Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3653 18.7028V19.8603V21.0007H12.397H11.449V19.8603V18.7028"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4494 5.29791L11.4494 4.14045L11.4494 3.00002L12.4177 3.00002L13.3657 3.00002L13.3657 4.14045L13.3657 5.29791"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AmountIcon
