import { isSameDay, format, formatDistanceStrict, differenceInSeconds } from 'date-fns'
import padStart from 'lodash/padStart'

export const convertSecondsToHoursMinutesSeconds = (time: number) => {
  const timeStr = new Date(time * 1000).toISOString().substr(11, 8)

  const hours = parseInt(timeStr.slice(0, 2))
  const minutes = parseInt(timeStr.slice(3, 5))
  const seconds = parseInt(timeStr.slice(6))

  return { hours, minutes, seconds }
}

export const convertFormatedSecondsToSeconds = (time: string) => {
  if (!time) return 0
  const timeParts = time.split(':').map(val => parseInt(val || '0'))
  const len = timeParts.length
  return len < 2
    ? timeParts[0]
    : len === 2
    ? timeParts[0] * 60 + timeParts[1]
    : timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2]
}

export const formatSeconds = (time: number, { hoursStyle }: { hoursStyle?: 'one-digit' | 'two-digits' } = {}) => {
  const { hours, minutes, seconds } = convertSecondsToHoursMinutesSeconds(time)

  const hasHrs = Number(hours) > 0 || !!hoursStyle
  // by default if hours are less than 1, they wouldn't be rendered

  const formatHours = () => {
    if (hoursStyle === 'two-digits') {
      return Number(hours).toString().padStart(2, '0')
    } else {
      return `${Number(hours)}`
    }
  }

  const formatMinutes = () => {
    if (hoursStyle === 'two-digits' || hasHrs) {
      return Number(minutes).toString().padStart(2, '0')
    } else {
      return `${Number(minutes)}`
    }
  }

  const formatSeconds = () => {
    return Number(seconds).toString().padStart(2, '0')
  }
  return hasHrs ? `${formatHours()}:${formatMinutes()}:${formatSeconds()}` : `${formatMinutes()}:${formatSeconds()}`
}

export const formatSecondsToText = (time: number) => {
  const { hours, minutes, seconds } = convertSecondsToHoursMinutesSeconds(time)

  const dataSet = [
    { label: 'hr', value: hours },
    { label: 'min', value: minutes },
    { label: 'sec', value: seconds }
  ]
  if (hours === 0 && minutes === 0 && seconds === 0) return '0secs'
  return dataSet
    .map(({ label, value }) => {
      if (value === 0) return null
      return `${value}${label}${value > 1 ? 's' : ''}`
    })
    .join(' ')
    .trim()
}

export const timestampToMillis = (timestamp: string) => {
  const [hrs, mins, secsMillis] = timestamp.split(':')
  const [secs, millis] = secsMillis.split(',')
  return Number(millis) + Number(secs) * 1000 + Number(mins) * 60 * 1000 + Number(hrs) * 60 * 60 * 1000
}

export const getFormattedDateTime = (timestamp?: string | number) => {
  if (!timestamp) return ['n/a', '']

  const date = new Date(timestamp)

  // If call occurred within current day, display time of call
  // Otherwise display month and day

  const isToday = isSameDay(date, new Date())
  const month = format(date, 'MMM')
  const day = format(date, 'd')
  const time = format(date, 'h:mm')
  const ampm = format(date, 'aaa')

  return isToday ? [time, ampm] : [month, day]
}

export const getFormattedDate = (timestamp: string | number | null) => {
  if (!timestamp) return ['n/a', '']
  const date = new Date(timestamp)
  return [format(date, 'M/d/yy'), format(date, 'h:mm aaa')]
}

export const getFormattedFullDate = (timestamp: string | number | null, isDateOnly?: boolean) => {
  if (!timestamp) return 'n/a'
  const date = new Date(timestamp)
  return isDateOnly ? format(date, 'M/d/y') : format(date, 'M/d/y h:mmaaa')
}

export const playbackSpeedToText = (playbackSpeed: number) => {
  return `${Number.isInteger(playbackSpeed) ? playbackSpeed.toFixed(1) : playbackSpeed}x`
}

enum TimeInWords {
  MINUTE = 'a minute',
  HOUR = 'an hour',
  DAY = 'a day',
  MONTH = 'a month',
  YEAR = 'a year'
}

export const timeFromNow = (timestamp: number | Date, options?: { wordForOne?: boolean }) => {
  const timeDistance = formatDistanceStrict(timestamp, new Date())
  if (timeDistance.includes('seconds') || timeDistance.includes('second')) {
    if (options?.wordForOne) {
      return TimeInWords.MINUTE
    } else {
      return '1 minute'
    }
  } else {
    if (options?.wordForOne) {
      switch (timeDistance) {
        case '1 minute': {
          return TimeInWords.MINUTE
        }
        case '1 hour': {
          return TimeInWords.HOUR
        }
        case '1 day': {
          return TimeInWords.DAY
        }
        case '1 month': {
          return TimeInWords.MONTH
        }
        case '1 year': {
          return TimeInWords.YEAR
        }
        default: {
          return timeDistance
        }
      }
    } else {
      return timeDistance
    }
  }
}

export const getFormattedPendoDateTime = (timestamp: string | number) => {
  if (!timestamp) {
    return timestamp
  }
  const date = new Date(timestamp)
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

export const getFormattedInputDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

export const getLocalDateFromUTCString = (formattedDate: string) => {
  // input format: yyyy-MM-dd
  const originalDate = new Date(formattedDate)
  return format(new Date(originalDate.getUTCFullYear(), originalDate.getUTCMonth(), originalDate.getUTCDate()), 'M/d/y')
}

export const dateToTimestamp = (date: Date) => {
  if (!date) {
    date = new Date()
  }
  return parseInt(new Date(date).getTime().toFixed(0))
}

/**
 * Format the user's call duration into a readable format
 * @param duration
 */
export function formatCallDuration(duration: number) {
  const hours = Math.floor(duration / 3600)
  duration = duration - hours * 3600
  const min = Math.floor(duration / 60)
  const sec = Math.floor(duration % 60)

  return `${padZero(hours)}:${padZero(min)}:${padZero(sec)}`
}

/**
 * Flatten padding
 * @param num
 */
export function padZero(num: number) {
  return padStart(num.toString(), 2, '0')
}

export function differenceInSecondsFromNow(timestamp: string | number | null) {
  const now = new Date()
  const date = timestamp ? new Date(timestamp) : now
  return differenceInSeconds(now, date)
}
