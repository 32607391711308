/**
 * @deprecated for `RdnaFormElements/RdnaAutoComplete`.
 */
import React from 'react'
import { TextField, CircularProgress } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { DropdownArrowIcon } from '../RdnaFormElements/RdnaSelect'

export interface RdnaAutocompleteProps<T, Multiple extends boolean | undefined>
  extends Omit<AutocompleteProps<T, Multiple, true, false>, 'onInputChange' | 'renderInput'> {
  label?: React.ReactNode
  inputError?: boolean
  inputHelperText?: React.ReactNode
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  placeholder?: string
  startAdornment?: React.ReactNode
}

const RdnaAutocomplete = <T, Multiple extends boolean | undefined = false>({
  id,
  inputError,
  inputHelperText,
  onInputChange,
  label,
  loading,
  placeholder,
  startAdornment,
  ...rest
}: RdnaAutocompleteProps<T, Multiple>) => {
  return (
    <Autocomplete
      id={id}
      data-testid={`autocomplete-${id}`}
      loading={loading}
      popupIcon={<DropdownArrowIcon />}
      renderInput={params => (
        <TextField
          {...params}
          data-testid={`autocomplete-${id}-input`}
          label={label}
          margin="dense"
          fullWidth
          placeholder={placeholder}
          onChange={onInputChange}
          error={inputError}
          helperText={inputHelperText}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment || params.InputProps.startAdornment,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      renderTags={() => null}
      {...rest}
    />
  )
}

export default RdnaAutocomplete
