import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function KebabIcon({ size = 24, color = Colors.N80 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8a2 2 0 100-4 2 2 0 000 4zM10 18a2 2 0 104 0 2 2 0 00-4 0zM12 10a2 2 0 100 4 2 2 0 000-4z"
        fill={color}
      />
    </svg>
  )
}

export default KebabIcon
