import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { Configuration, Field, Methodology, Topic } from '../../components/sales-methodology/Models'
import { HttpMethods } from '@ringdna/client/src/constants'

const API_BASE = '/api/v2/app/methodology'

type ResetSalesMethodology = {
  body: {
    accountId: number
    methodologyId?: number
  }
}

export const useSalesMethodologies = createUseFetch<Methodology[], { meta: { accountId: number } }>({
  key: 'get-sales-methodology',
  path: params => `${API_BASE}/${params.meta.accountId}/list`,
  independent: true
})

export type MethodologyDetails = {
  topics: Topic[]
  objectFields: Field[]
  configurations: Configuration[]
}

export const useMethodologyTopics = createUseFetch<MethodologyDetails, { meta: { accountId: number; id: number } }>({
  key: 'get-methodology-topics',
  path: params => `${API_BASE}/${params.meta.accountId}/${params.meta.id}`,
  independent: true
})

export const useResetMethodology = createUseFetchAction<any, ResetSalesMethodology>({
  key: 'post-reset-methodology',
  path: `${API_BASE}`,
  method: HttpMethods.Post
})

export const useTopicConfiguration = createUseFetchAction<any, { body: Record<string, any> }>({
  key: 'post-topic-configuration',
  path: `${API_BASE}/topicConfiguration`,
  method: HttpMethods.Post
})
