import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { Section, SectionHeader } from '../styles'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import UsersIcon from '@ringdna/common/src/assets/icons/svg/Users'

export default AgentStatusSettings
function AgentStatusSettings() {
  const state = useSupportSettingsState()

  const agentStatusCallout = 'settings.agentStatusCallout'
  const agentStatusCalloutEndpoint = 'settings.agentStatusCalloutEndpoint'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={UsersIcon} iconSize={18} variant="filled" />
        <RdnaText className={'rdna-text'}>Agent Status Settings</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Agent Status Callout'}
        isChecked={state.accountSettings[agentStatusCallout]}
        setting={agentStatusCallout}
      />
      <SupportSettingInputRow
        title={'Agent Status Callout URL'}
        value={state.accountSettings[agentStatusCalloutEndpoint]}
        isDisabled={!state.accountSettings[agentStatusCallout]}
        setting={agentStatusCalloutEndpoint}
        placeholder={'Callout URL'}
      />
    </Section>
  )
}
