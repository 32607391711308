BaseModel = require('base/model')

module.exports = class StateModel extends BaseModel

  defaults:
    state         : ''
    areaCodes     : []
    areaCodesDesc : ''
    needToProvide : true
    needToDelete  : true
    errorDesc     : null

  initialize: (state, areaCodes) =>
    @set('state', state)
    @set('areaCodes', areaCodes)
    @set('areaCodesDesc', @_getAreaDesc(areaCodes))

  _getAreaDesc: (areaCodes) ->
    result = ''
    firstVal = true
    for code in areaCodes
      for key, value of code
        if firstVal
          firstVal = false
          result = key
        else
          result = result + ', ' + key
        if value
          result = result + '(provided)'
    result

