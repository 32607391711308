var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-panel\">\n  <div class=\"x-title\">\n    <div class=\"col-md-4\">\n      <h2>Account Details</h2>\n    </div>\n    <div class=\"navbar-right col-md-8\">\n      <span class=\"account-picker col-md-5\"></span>\n      <span class=\"col-md-5\">\n        <input type=\"text\" class=\"form-control sfOrgId\" placeholder=\"New sfOrgId (18 digit)\"/>\n      </span>\n      <span class=\"col-md-2\">\n        <button class=\"btn btn-info split\">Split/Move Data</button>\n      </span>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Id</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Name</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n    </div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">sfOrgId</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"sfOrgId") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Date Created</label>\n      <span class=\"col-md-8\">"
    + alias2((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"createdDate") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":34,"column":29},"end":{"line":34,"column":67}}}))
    + "</span>\n    </div>\n  </div>\n\n  <div class=\"x-title\">\n    <h2>Users <small>(please select users which you want move)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid users-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Call Queues <small>(please select call queues which you want copy)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid call-queues-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Teams <small>(please select teams which you want copy)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid teams-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Callflows <small>(please select callflows which you want move)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid callflows-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Skills <small>(please select skills which you want copy)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid skills-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Smart Numbers <small>(please select smart numbers which you want move)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid smartnumbers-grid scroll-x\"></div>\n\n  <div class=\"x-title\">\n    <h2>Holiday Schedules <small>(please select holiday schedules which you want copy)</small></h2>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"grid holidayschedule-grid scroll-x\"></div>\n\n  <pre class=\"results\" style=\"display: none; height: auto;\"></pre>\n  <div class=\"clearfix\"></div>\n</div>\n";
},"useData":true});