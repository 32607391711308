import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/common/src/client/constants'

export type AccountIdQuery = {
  query: {
    accountId: string
  }
}

export type AccountUser = {
  userId: number
  msTeamsId?: string | null
  msTeamsAccountId?: string
  zoomId?: string | null
  displayName: string
  loginDate: number
  email: string
  msTeamsEmail: string | null
  zoomEmail?: string | null
}

export const useFetchTeamsUsers = createUseFetch<AccountUser[], AccountIdQuery>({
  key: 'fetch-teams-users',
  path: '/api/v3/app/msteams/users'
})

export const useFetchZoomUsers = createUseFetch<AccountUser[], AccountIdQuery>({
  key: 'fetch-zoom-users',
  path: '/api/v3/app/zoom/users'
})

type UpdateUserParam = {
  meta: {
    userId: number
  }
  json: {
    msTeamsEmail?: string | null
    msTeamsAccountId?: string | null
    zoomEmail?: string | null
  }
}

export const useUpdateTeamsUser = createUseFetchAction<void, UpdateUserParam>({
  key: 'update-teams-user',
  path: params => `/api/v3/app/msteams/users/${params.meta.userId}?x-http-method-override=PATCH`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

export const useUpdateZoomUser = createUseFetchAction<void, UpdateUserParam>({
  key: 'update-zoom-user',
  path: params => `/api/v3/app/zoom/users/${params.meta.userId}?x-http-method-override=PATCH`,
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

export type TeamSettings = {
  accountId: string
  tenantId: string
  autoRecord: boolean
  userOverride: boolean
}

export const useFetchTeamsSettings = createUseFetch<TeamSettings, AccountIdQuery>({
  key: 'fetch-teams-settings',
  path: '/api/v3/app/msteams/settings'
})

type UpdateTeamsSettingsParam = {
  json: TeamSettings
}

export const useUpdateTeamsSettings = createUseFetchAction<void, UpdateTeamsSettingsParam>({
  key: 'update-teams-settings',
  path: '/api/v3/app/msteams/settings',
  method: HttpMethods.Put,
  headers: { 'Content-Type': 'application/json' }
})

export type TeamsScriptResponse = {
  isEnabled: boolean
}

export const useFetchTeamsScript = createUseFetch<TeamsScriptResponse, AccountIdQuery>({
  key: 'fetch-teams-script',
  path: '/api/v3/app/msteams/script',
  independent: true
})

type PostMsTeamsAccountIdParam = {
  json: {
    accountId: string
    msTeamsAccountId: string
  }
}

export const usePostMsTeamsAccountId = createUseFetchAction<void, PostMsTeamsAccountIdParam>({
  key: 'post-msteams-account-id',
  path: '/api/v3/app/msteams/msTeamsAccountId',
  method: HttpMethods.Post,
  headers: { 'Content-Type': 'application/json' }
})

type DeleteMsTeamsAccountIdParam = {
  meta: { accountId: string }
}

export const useDeleteMsTeamsAccountId = createUseFetchAction<void, DeleteMsTeamsAccountIdParam>({
  key: 'delete-msteams-account-id',
  path: ({ meta }) => `/api/v3/app/msteams/msTeamsAccountId/${meta.accountId}`,
  method: HttpMethods.Delete
})
