export const CALL_SCRIPTS_STR = {
  delete: 'Delete',
  edit: 'Edit',
  header: 'Create Call Scripts to display when callers call in to campaigns.',
  name: 'name',
  description: 'description',
  source: 'source',
  number: 'number',
  enabled: 'enabled'
}

export const callScriptsColumns = [
  {
    Header: 'Name',
    accessor: CALL_SCRIPTS_STR.name
  },
  {
    Header: 'Description',
    accessor: CALL_SCRIPTS_STR.description
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }

export const initCallScripts = {
  id: 0,
  name: '',
  description: '',
  data: '',
  entityIdsAdd: [],
  entityIdsDelete: []
}
