import React from 'react'
import { useEffect } from 'react'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactToMarionetteView = require('../../../../app/features/react-to-marionette/view')

export type Props = {
  id: string
}

export default function ReactToMarionette({ id }: Props) {
  useEffect(() => {
    const marionetteComponent = new ReactToMarionetteView({ tabId: id })
    marionetteComponent.render()
    document.getElementById(id)?.appendChild(marionetteComponent.el)
  }, [id])

  return <div id={id} />
}
