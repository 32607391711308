import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function BillingIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="billing-icon">
      <path
        d="M40.7936 33.8637V54.0933C40.7936 54.2175 40.6929 54.3183 40.5686 54.3183H12.4472C12.3229 54.3183 12.2222
      54.2175 12.2222 54.0933V42.63V18.2032V13.6342C12.2222 13.5099 12.3229 13.4092 12.4472 13.4092H26.5079"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8201 24.2001C37.0201 24.1734 36.2801 24.0468 35.6001 23.8201C34.9201 23.5934 34.3267 23.3201 33.8201
      23.0001L34.3001 21.7201C34.8201 21.9734 35.3734 22.1934 35.9601 22.3801C36.5467 22.5668 37.1667 22.6734 37.8201
      22.7001V17.8001C37.3134 17.6801 36.8334 17.5201 36.3801 17.3201C35.9401 17.1201 35.5534 16.8668 35.2201
      16.5601C34.8867 16.2534 34.6201 15.8934 34.4201 15.4801C34.2201 15.0534 34.1201 14.5601 34.1201 14.0001C34.1201
      13.0134 34.4467 12.1934 35.1001 11.5401C35.7534 10.8734 36.6601 10.4734 37.8201 10.3401V8.86011H39.3601V10.3201C40.0134
      10.3868 40.6267 10.5134 41.2001 10.7001C41.7867 10.8734 42.3134 11.0934 42.7801 11.3601L42.3601 12.6201C41.9334
      12.4468 41.4667 12.2868 40.9601 12.1401C40.4534 11.9934 39.9201 11.8934 39.3601 11.8401V16.5201C40.5067 16.8401
      41.4067 17.3001 42.0601 17.9001C42.7267 18.4868 43.0601 19.2868 43.0601 20.3001C43.0601 20.8601 42.9601 21.3601
      42.7601 21.8001C42.5734 22.2268 42.3134 22.6001 41.9801 22.9201C41.6467 23.2401 41.2534 23.5001 40.8001
      23.7001C40.3601 23.9001 39.8801 24.0401 39.3601 24.1201V25.8201H37.8201V24.2001ZM35.8001 13.9401C35.8001
      14.5268 35.9801 14.9868 36.3401 15.3201C36.7134 15.6401 37.2067 15.9001 37.8201 16.1001V11.8601C37.1801
      11.9668 36.6801 12.1934 36.3201 12.5401C35.9734 12.8868 35.8001 13.3534 35.8001 13.9401ZM41.3801 20.3801C41.3801
      19.8334 41.1934 19.3934 40.8201 19.0601C40.4467 18.7268 39.9601 18.4468 39.3601 18.2201V22.5601C39.9601 22.4134
      40.4467 22.1601 40.8201 21.8001C41.1934 21.4268 41.3801 20.9534 41.3801 20.3801Z"
        fill={color}
      />
    </svg>
  )
}
