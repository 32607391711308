BaseModel      = require('base/model')
BaseView       = require('base/view')
UserPickerView = require('widgets/user-picker/view')

class Model extends BaseModel
  url: => "smartNumbers/#{@id}/reassign"

class View extends BaseView
  template: require('./reassign-additional-number-template')
  className: 'reassign-additional-number'

  regions:
    userPicker : 'span.user-picker'

  ui:
    reassign : 'button.reassign'
    modal    : '#reassign-additional-number-modal'

  events:
    'click @ui.reassign' : 'reassign'

  initialize: (options) ->
    {@numberId} = options
    @userModel = options.user
    @reloadOnClose = false

  onRender: =>
    @userPicker = @getRegion('userPicker')
    @userPicker.show(new UserPickerView(accountId: @userModel.account.id))

  onAttach: =>
    @ui.modal.on('hide.bs.modal', (e) =>
      @triggerMethod('reload') if @reloadOnClose
    )

  reassign: =>
    return unless @validate()

    @model = new Model(
      id : @numberId
      userId : @userPicker.currentView.val()
    )

    @model
      .persist(ladda: @ui.reassign[0])
      .done(=>
        smartNumbers = @userModel.smartNumbers
        smartNumbers.splice(index for number, index in smartNumbers when number.id is @numberId, 1)
        @reloadOnClose = true
        @ui.modal.modal('hide')
        toastr.info('Additional number reassigned.')
      )
      .fail(@ui.modal.modal('hide'))

  validate: =>
    if not @userPicker.currentView.val()
      toastr.warning('Please select a user.')
      return false
    else if parseInt(@userPicker.currentView.val()) is @userModel.id
      toastr.warning('This number already assigned to selected user.')
      return false

    true

module.exports = View
