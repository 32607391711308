import React, { useRef, useState } from 'react'
import IMask from 'imask'
import styled from 'styled-components'

import { InputTypes } from '../../../constants/input-types'
import { Colors } from '../../../constants/colors'
import useId from '../../../hooks/useId'

import TextField, { TextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import RdnaText from '../../../components/RdnaText'
import SearchIcon from '../../../assets/icons/svg/Search'
import CloseIcon from '../../../assets/icons/svg/Close'
import IconHelper from '../../../assets/icons/IconHelper'

const phoneMask = { mask: '(000) 000-0000' }

const CharacterCounter = styled(RdnaText)`
  color ${Colors.N70}
`

const HelperText = styled.span`
  margin-top: ${props => props.theme.spacing}px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`

export type RdnaTextInputProps = { search?: boolean; counter?: boolean } & TextFieldProps

export default function RdnaTextInput({
  search = false,
  // TextFieldProps
  required, // overriding default 'required' prop to only add the asterisk. validate required in parent component
  value,
  type,
  inputProps,
  InputProps,
  InputLabelProps,
  onChange,
  name,
  counter,
  error,
  rows,
  helperText,
  label,
  placeholder,
  className,
  ...restOfTextFieldProps
}: RdnaTextInputProps) {
  const _id = useId(name + '-textinput_')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isLengthError, setIsLengthError] = useState(false)
  const [lengthErrorMessage, setLengthErrorMessage] = useState<string | undefined>(undefined)
  const helperTextString = helperText || lengthErrorMessage
  return (
    <>
      <TextField
        inputRef={inputRef}
        variant="outlined"
        fullWidth
        id={_id}
        name={name}
        margin={'dense'}
        size="small"
        type={type}
        value={value}
        label={label}
        placeholder={placeholder}
        error={error || isLengthError}
        helperText={
          (helperTextString || counter) && (
            <HelperText>
              <RdnaText
                variant={'meta'}
                color={error || isLengthError ? 'alert' : 'neutral'}
                className={'text-input-helper-text'}
              >
                {/*@ts-ignore*/}
                {helperTextString}
              </RdnaText>
              {counter && (
                <CharacterCounter variant="meta" className={'character-counter'}>{`${(value as string)?.length || 0}/${
                  inputProps?.maxLength || 1
                }`}</CharacterCounter>
              )}
            </HelperText>
          )
        }
        multiline={type === InputTypes.TEXT_AREA}
        rows={rows || 10}
        className={className}
        onChange={e => {
          if (inputProps?.maxLength) {
            if (e.target.value.length === inputProps.maxLength && !counter) {
              setIsLengthError(true)
              setLengthErrorMessage(`${label || placeholder} cannot exceed ${inputProps.maxLength} characters.`)
            } else {
              setIsLengthError(false)
              setLengthErrorMessage(undefined)
            }
          }
          type === InputTypes.PHONE && IMask(e.target, phoneMask)
          onChange && onChange(e)
        }}
        inputProps={{ ...inputProps, 'data-testid': `${name}-text-input` }}
        InputProps={{
          startAdornment: search ? (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ) : null,
          endAdornment:
            search && !!value ? (
              <IconHelper
                icon={CloseIcon}
                onClick={() => {
                  if (inputRef.current) inputRef.current.value = ''
                  onChange && onChange({ target: { value: '' } } as any)
                }}
              />
            ) : null,
          ...InputProps
        }}
        InputLabelProps={{ required, ...InputLabelProps, shrink: true }}
        {...restOfTextFieldProps}
      />
    </>
  )
}
