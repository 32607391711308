import React, { useEffect, useState } from 'react'

// utils
import { Dispatch, RecordTypes, useCallRecordingDispatch } from '../../utils/contextReducer'
import { UnitedStatesCallRecordingSetting } from '@ringdna/common/src/types/call-recording'
import { useCallRecordingSettings } from '../../../../store/api/call-recording-api'

// components
import { areEqual, ListChildComponentProps, VariableSizeList } from 'react-window'
import { RdnaTable, RdnaTableCell, RdnaTableHead } from '@ringdna/common/src/components/RdnaTable'
import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import UnitedStatesTableRows from './UnitedStatesTableRows'

type UnitedStatesTableProps = {
  accountId: string
}

export default React.memo(UnitedStatesTable)
function UnitedStatesTable({ accountId }: UnitedStatesTableProps) {
  const [usSettings, , , ,] = useCallRecordingSettings({ meta: { id: accountId, isUS: true } })
  const [haveData, setHaveData] = useState(false)

  const dispatch = useCallRecordingDispatch()

  useEffect(() => {
    if (usSettings) {
      storeUnitedStatesSettings(dispatch, usSettings as UnitedStatesCallRecordingSetting[], accountId)
      setHaveData(true)
    }
  }, [usSettings, dispatch, accountId])

  const getItemSize = (index: number) => {
    // @ts-ignore
    const numberOfLines = Math.ceil(usSettings[index].areaCodes.length / 5)
    const extraPaddingPerLine = numberOfLines * 15
    const basePadding = 40

    return extraPaddingPerLine + basePadding
  }

  return (
    <RdnaLoader
      data={usSettings}
      noResultsMsg={
        <NoResultsMsg
          title="Something went wrong, try refreshing!"
          subtitle="We couldn't find your call recording settings."
          Icon={<BinocularsIconLeaves />}
        />
      }
      loading={!haveData}
      top="100px"
    >
      <RdnaTable $shadedHead>
        <RdnaTableHead>
          <RdnaTableCell>Record Calls</RdnaTableCell>
          <RdnaTableCell>Play Outbound Recording</RdnaTableCell>
          <RdnaTableCell>State</RdnaTableCell>
          <RdnaTableCell>Special Consent</RdnaTableCell>
          <RdnaTableCell>Area Codes</RdnaTableCell>
        </RdnaTableHead>
        {usSettings && (
          <VariableSizeList
            height={520}
            width="100%"
            itemSize={getItemSize}
            itemCount={usSettings.length}
            className={'styled-scrollbar'}
          >
            {React.memo(function Rows({ index, style }: ListChildComponentProps) {
              return (
                // @ts-ignore
                <div style={style}>
                  <UnitedStatesTableRows stateSetting={usSettings[index]} />
                </div>
              )
            }, areEqual)}
          </VariableSizeList>
        )}
      </RdnaTable>
    </RdnaLoader>
  )
}

// helpers
const storeUnitedStatesSettings = (
  dispatch: Dispatch,
  usSettings: UnitedStatesCallRecordingSetting[],
  accountId: string
) => {
  const unitedStatesRecordingSettings = { accountId, isUS: true }

  usSettings.forEach((state, index) => {
    const {
      setting: { stateId, recordType, outboundAnnouncement }
    } = state

    // @ts-ignore
    unitedStatesRecordingSettings[`items[${index}].id`] = stateId
    // @ts-ignore
    unitedStatesRecordingSettings[`items[${index}].recordType`] = recordType
    // Temp fix since old field is still in DB to prevent downtime - https://ringdna.atlassian.net/browse/ADMIN-1311
    // @ts-ignore
    unitedStatesRecordingSettings[`items[${index}].enabled`] = recordType !== RecordTypes.NONE
    // @ts-ignore
    unitedStatesRecordingSettings[`items[${index}].outboundAnnouncement`] = outboundAnnouncement
  })

  dispatch({ type: 'STORE_US_RECORDING_SETTINGS', payload: unitedStatesRecordingSettings })
}
