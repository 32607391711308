import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, PackageCheck, Section, SectionRow, Settings } from '../styles'
import CloudIcon from '@ringdna/common/src/assets/icons/svg/Cloud'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import SalesforceClearCacheButton from '../SalesforceClearCacheButton'
import SalesforcePackageCheckButton from '../SalesforcePackageCheckButton'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'

export default SalesforcePackages
function SalesforcePackages() {
  const state = useSupportSettingsState()

  const packageVersion = 'account.packageVersion'
  const guidedSellingPackageVersion = 'account.cadencePackageVersion'
  const advancedSolutionPackageVersion = 'account.advancedSolutionPackageVersion'
  const hotLeadsPackageVersion = 'account.hotLeadsPackageVersion'

  return (
    <Section>
      <SectionRow>
        <Settings>
          <IconHelper icon={CloudIcon} iconSize={IconSize} variant="filled" />
          <RdnaText className={'rdna-text'}>Salesforce Packages</RdnaText>
        </Settings>
        <PackageCheck>
          <RdnaText bold={true} className={'rdna-text'}>
            Package Check
          </RdnaText>
          <SalesforcePackageCheckButton />
        </PackageCheck>
      </SectionRow>
      <SupportSettingInputRow
        title={'RingDNA for Salesforce'}
        value={state.accountSettings[packageVersion]}
        isDisabled={true}
        setting={packageVersion}
        placeholder={'Package Number'}
      />
      <SupportSettingInputRow
        title={'Guided Selling'}
        value={state.accountSettings[guidedSellingPackageVersion]}
        isDisabled={true}
        setting={guidedSellingPackageVersion}
        placeholder={'Package Number'}
      />
      <SupportSettingInputRow
        title={'Advanced Solutions Package'}
        value={state.accountSettings[advancedSolutionPackageVersion]}
        isDisabled={true}
        setting={advancedSolutionPackageVersion}
        placeholder={'Package Number'}
      />
      <SupportSettingInputRow
        title={'Hot Leads'}
        value={state.accountSettings[hotLeadsPackageVersion]}
        isDisabled={true}
        setting={hotLeadsPackageVersion}
        placeholder={'Package Number'}
      />
      <RdnaSettingRow
        heading={'Cache'}
        description={"Select 'Clear' to remove call dispositions and described SObjects from redis cache."}
      >
        <SalesforceClearCacheButton />
      </RdnaSettingRow>
    </Section>
  )
}
