import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../styles'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import CallTrackingIcon from '@ringdna/common/src/assets/icons/svg/CallTracking'

export default MarketingCallTracking
function MarketingCallTracking() {
  const state = useSupportSettingsState()

  const sfdcDisableLeadCreation = 'settings.sfdcDisableLeadCreation'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={CallTrackingIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>Marketing Call Tracking</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Disable Marketing Lead Creation'}
        isChecked={state.accountSettings[sfdcDisableLeadCreation]}
        setting={sfdcDisableLeadCreation}
      />
    </Section>
  )
}
