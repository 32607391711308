import React, { useState, useMemo } from 'react'
import { FormValues } from './model'
import { UserSettings } from '@ringdna/common/src/api/calendar/calendar-model'
import { getInputFields, defaultWelcomeMessage } from './constants'
import EditIcon from '@ringdna/common/src/assets/icons/svg/Edit'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaForm from '@ringdna/common/src/components/RdnaForm'
import { SettingsItem } from './SettingsItem'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'

export type Props = {
  userSettings: UserSettings
  welcomeMessage: string
  calendarBookingUrl: string
  isSaving: boolean
  submitForm: (values: FormValues) => void
}

export default function PersonalizeView({
  userSettings,
  welcomeMessage,
  calendarBookingUrl,
  isSaving,
  submitForm
}: Props) {
  const [isOpenModal, setOpenModal] = useState<boolean>(false)

  const personalLink = useMemo(() => {
    return (
      <a data-testid="settings-link" href={calendarBookingUrl} target="_blank" rel="noopener noreferrer">
        {calendarBookingUrl}
      </a>
    )
  }, [calendarBookingUrl])

  const toggleModalOpen = () => {
    setOpenModal(!isOpenModal)
  }

  const onSubmit = (values: FormValues) => {
    submitForm(values)
    setOpenModal(!isOpenModal)
  }

  return (
    <>
      <RdnaModal open={isOpenModal} heading="Your Revenue.io Calendar Welcome Message" onClose={toggleModalOpen}>
        <RdnaForm
          cancelText="Cancel"
          onCancel={toggleModalOpen}
          submitText="Save"
          onSubmit={onSubmit}
          inputFields={getInputFields(welcomeMessage)}
          alignElements="center"
          isLoading={isSaving}
        />
      </RdnaModal>
      <SettingsItem name="Name" value={userSettings.displayName} />
      <SettingsItem name="Country" value={userSettings.country} />
      <SettingsItem name="Timezone" value={userSettings.timeZoneSidKey.replace('_', ' ')} />
      <SettingsItem name="Your Revenue.io Calendar Link" boldHeading>
        {personalLink}
      </SettingsItem>
      <SettingsItem name="Your Revenue.io Calendar Welcome Message" boldHeading>
        <>
          <button data-testid="settings-message-btn" name="edit-personal-message" onClick={toggleModalOpen}>
            <EditIcon />
          </button>
          <RdnaText
            component="p"
            variant="body2"
            color={welcomeMessage !== defaultWelcomeMessage ? 'primary' : 'neutral'}
          >
            {welcomeMessage}
          </RdnaText>
        </>
      </SettingsItem>
    </>
  )
}
