import { getLocalDateFromUTCString } from '@ringdna/common/src/utils/timeUtils'
import {NameLinkCell} from "../../cells";

export const HEADER_TEXT = 'Allows you to define the holiday schedule for call flows.'

export const HolidayCalendarColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value, row }: { value: string; row: any }) => NameLinkCell(value, row)
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const EventColumns = [
  {
    Header: 'Event Name',
    accessor: 'name',
    Cell: ({ value, row }: { value: string; row: any }) => NameLinkCell(value, row)
  },
  {
    Header: 'Dates',
    accessor: 'fromDate',
    Cell: (data: { value: string; row: any }) =>
      `${getLocalDateFromUTCString(data.value)} - ${getLocalDateFromUTCString(data.row.original.toDate)}`
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const initHoliday = {
  id: 0,
  description: '',
  name: ''
}

export const initEvent = {
  id: 0,
  name: '',
  fromDate: '',
  toDate: ''
}
