BaseModel = require('base/model')

class TwilioCallbackModel extends BaseModel

  defaults:
    accountSid: ''
    callDuration: 0
    called: ''
    caller: ''
    callKey: ''
    callerName: ''
    callSid: ''
    callStatus: ''
    dialCallDuration: 0
    dialCallSid: ''
    dialCallStatus: ''
    digits: ''
    direction: ''
    forwardedFrom: ''
    from: ''
    id: 0
    inbound: false
    outbound: false
    queueTime: 0
    rdAction: ''
    rdCount: 0
    rdTimestamp: 0
    recordingDuration: 0
    recordingSid: ''
    recordingStatus: ''
    sequenceNumber: 0
    to: ''
    rdCallerId: ''
    rdCount: 0
    rdFromNumber: ''
    rdOrigin: ''
    rdToNumber: ''
    rdUserId: ''
    rdSfdcRecordId: ''

module.exports = TwilioCallbackModel
