BaseModel = require('base/model')

class SmartNumberModel extends BaseModel
  urlRoot: 'smartNumbers'

  defaults:
    isDeleted               : false
    isReleasedFromTwilio    : false
    group                   : null
    label                   : ''
    smartNumberDescription  : ''
    entities                : null

  initialize: (options) =>
    @number = [ 'id', 'number', 'sid', 'smartNumberDescription', 'label']
    @entity = ['id', 'inactive', 'name', 'source', 'type', 'typeTracking', 'extraInfo']
    @analytics = ['id', 'trackingId']
    @other = ['sid', 'accountId']

  toJSON: =>
    json = @toPlayJSON('number', _.pick(@attributes, @number))
    if @attributes.entity
      json = _.extend(json, @toPlayJSON('entity', _.pick(@attributes.entity, @entity)))
    if @attributes.analytics
      json = _.extend(json, @toPlayJSON('analytics', _.pick(@attributes.analytics, @analytics)))
    json = _.extend(json, _.pick(@attributes, @other))
    json

module.exports = SmartNumberModel
