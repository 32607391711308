import React, { useContext } from 'react'
import styled from 'styled-components'

import { InitIntegrationsContext } from '../../../model'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { IntegrationsContext } from '../../../store/IntegrationsProvider'

import SelectIntegrationItem from './SelectIntegrationItem'
import { isFlagged, flags } from '@ringdna/common/src/utils/feature-flag/featureFlag'

export default function SelectIntegrationsModal() {
  const {
    integrationsState: { activeUser, allIntegrations }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)

  return (
    <Container>
      {allIntegrations
        // TODO: remove once backend response gets filtered
        .filter(
          integration =>
            (integration.provider === IntegrationProviderTypes.GMAIL &&
              integration.enabled &&
              (activeUser.licenses?.calendar || activeUser.licenses?.guidedSelling)) ||
            (integration.provider === IntegrationProviderTypes.EXCHANGE &&
              integration.enabled &&
              (activeUser.licenses?.calendar || activeUser.licenses?.guidedSelling)) ||
            integration.provider === IntegrationProviderTypes.ZOOM ||
            integration.provider === IntegrationProviderTypes.HOOVERS
        )
        .map(integration => (
          <SelectIntegrationItem key={integration.provider} integration={integration} />
        ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`
