/**
 * @deprecated for `RdnaFormElements/RdnaSelect`.
 */
import React from 'react'
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { FormSelectOption, InputComponentProps } from '../../../types/form-inputs'
import styled from 'styled-components'
import RdnaText from '../../RdnaText'
import CaretIcon from '../../../assets/icons/svg/CaretFilled'
import RdnaIconHelper from '../../../assets/icons/IconHelper'
import { DropdownArrow } from '../../RdnaFormElements/RdnaSelect'

const Icon = styled.div`
  padding: 0;
  padding-right: 16px;
  display: flex;
`

const RdnaDropdown = ({
  inputData,
  value,
  isAutoWidth,
  isError,
  isDisabled,
  errorMessage,
  onChange
}: InputComponentProps): JSX.Element => {
  let options = inputData.options
  if (inputData && inputData.options && inputData.options.length) {
    if (typeof inputData.options[0] === 'string') {
      options = (inputData.options as Array<string>).map((name: string) => {
        return { value: name, label: name }
      })
    }
  }

  const displayValue = (options as Array<FormSelectOption>)
    .filter((optionItem: FormSelectOption) => {
      if (inputData.multipleValues) {
        return (value as string[]).indexOf(optionItem.value) > -1
      } else {
        return value === optionItem.value
      }
    })
    .map((optionItem: FormSelectOption) => {
      return optionItem.label
    })
    .join(', ')

  return (
    <FormControl className={inputData.className} variant="outlined" error={isError} disabled={isDisabled}>
      <Container staticLabel={inputData.staticLabel}>
        <InputLabel
          htmlFor={`${inputData.name}-select`}
          required={inputData.required}
          shrink={inputData?.placeholder ? !!inputData.placeholder : undefined}
        >
          {inputData.label}
        </InputLabel>
      </Container>
      <Select
        id="ringdna-select"
        // @ts-ignore
        value={value}
        {...(isAutoWidth ? { autoWidth: true } : { fullWidth: true })}
        multiple={inputData.multipleValues}
        onChange={onChange}
        // @ts-ignore
        defaultValue={value || ''}
        displayEmpty
        IconComponent={DropdownArrow}
        renderValue={value =>
          inputData.multipleValues
            // @ts-ignore
            ? (value as string[]).length < 3
              ? displayValue
              : 'Multiple items selected'
            : displayValue || <RdnaText color="neutral">{inputData.placeholder}</RdnaText>
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }}
        data-analyticsid={`${inputData.name}-input`}
        data-testid={`${inputData.name}-input`}
        inputProps={{
          name: `${inputData.name}`,
          id: `${inputData.name}-select`
        }}
      >
        {inputData?.placeholder && (
          <MenuItem value="" disabled>
            {inputData.placeholder}
          </MenuItem>
        )}
        {options &&
          options.length &&
          (options as Array<FormSelectOption>).map((optionItem: FormSelectOption) => (
            <MenuItem
              key={optionItem.value}
              value={optionItem.value}
              data-testid={`option-${optionItem.value}`}
              data-analyticsid={`option-${optionItem.value}`}
            >
              {inputData.multipleValues && !optionItem.icon && !optionItem.Icon && (
                <Checkbox color="primary" checked={(value as string[]).indexOf(optionItem.value) > -1} />
              )}
              {(optionItem.Icon || optionItem.icon) && (
                <Icon>
                  {optionItem.Icon ? <optionItem.Icon /> : <img src={optionItem.icon} alt={optionItem.value} />}
                </Icon>
              )}
              {optionItem.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{isError ? errorMessage : inputData.helperText}</FormHelperText>
    </FormControl>
  )
}

const Container = styled.div<{ staticLabel: boolean | undefined }>`
  ${props =>
    props.staticLabel
      ? `
  .MuiInputLabel-formControl {
    color: ` +
        props.theme.palette.neutral.main +
        `;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    opacity: 0;
  }
  .Mui-focused {
    opacity: 0;
  }`
      : ''}
`

export default RdnaDropdown
