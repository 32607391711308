BaseView = require('base/view')

class CdmDeleteConfirmationModal extends BaseView
  template: require('./template')
  className: 'cdm-delete-confirmation-modal'

  events:
    'click button.delete-confirm' : 'deleteData'

  initialize: (options) =>
    @model = new Backbone.Model(salesforceId: options.salesforceId)

  deleteData: ->
    App.api.delete(
      path: 'cdm'
      data: salesforceId: @model.get('salesforceId')
      success: =>
        $('#cdm-delete-confirmation-modal').modal('hide')
    )

module.exports = CdmDeleteConfirmationModal