BaseView               = require('base/view')
TwilioCallbackModel    = require('./model')
TwilioCallbackItemView = require('./item-template')

class TwilioCallbackView extends BaseView
  template: TwilioCallbackItemView
  tagName: 'li'
  className: ->
    ownerCallSid = (leg.callSid for leg in @options.callModel.callLegs when leg.role is 'Owner')?[0]
    'twilio-callback' + if @model.get('callSid') isnt ownerCallSid then ' timeline-inverted' else ''

  bindings:
    '.id'                        : 'text: index'
    '.timestamp'                 : 'text: timestampCalc'
    '.action'                    : 'text: action'
    '.actionContainer'           : 'toggle: rdAction'
    '.accountSid'                : 'text: accountSid'
    '.accountSidContainer'       : 'toggle: accountSid'
    '.callDuration'              : 'text: callDuration'
    '.callDurationContainer'     : 'toggle: callDuration'
    '.called'                    : 'text: called'
    '.calledContainer'           : 'toggle: called'
    '.caller'                    : 'text: caller'
    '.callerContainer'           : 'toggle: caller'
    '.callerName'                : 'text: callerName'
    '.callerNameContainer'       : 'toggle: callerName'
    '.callSid'                   : 'text: callSid'
    '.callSidContainer'          : 'toggle: callSid'
    '.callStatus'                : 'text: callStatus'
    '.callStatusContainer'       : 'toggle: callStatus'
    '.dialCallDuration'          : 'text: dialCallDuration'
    '.dialCallDurationContainer' : 'toggle: dialCallDuration'
    '.dialCallSid'               : 'text: dialCallSid'
    '.dialCallSidContainer'      : 'toggle: dialCallSid'
    '.dialCallStatus'            : 'text: dialCallStatus'
    '.dialCallStatusContainer'   : 'toggle: dialCallStatus'
    '.digits'                    : 'text: digits'
    '.digitsContainer'           : 'toggle: digits'
    '.direction'                 : 'text: direction'
    '.directionContainer'        : 'toggle: direction'
    '.forwardedFrom'             : 'text: forwardedFrom'
    '.forwardedFromContainer'    : 'toggle: forwardedFrom'
    '.from'                      : 'text: from'
    '.fromContainer'             : 'toggle: from'
    '.recordingDuration'         : 'text: recordingDuration'
    '.recordingDurationContainer': 'toggle: recordingDuration'
    '.recordingSid'              : 'text: recordingSid'
    '.recordingSidContainer'     : 'toggle: recordingSid'
    '.recordingStatus'           : 'text: recordingStatus'
    '.recordingStatusContainer'  : 'toggle: recordingStatus'
    '.sequenceNumber'            : 'text: sequenceNumber'
    '.sequenceNumberContainer'   : 'toggle: sequenceNumber'
    '.to'                        : 'text: to'
    '.toContainer'               : 'toggle: to'
    '.rdCallerId'                : 'text: rdCallerId'
    '.rdCallerIdContainer'       : 'toggle: rdCallerId'
    '.rdCount'                   : 'text: rdCount'
    '.rdCountContainer'          : 'toggle: rdCount'
    '.rdFromNumber'              : 'text: rdFromNumber'
    '.rdFromNumberContainer'     : 'toggle: rdFromNumber'
    '.rdOrigin'                  : 'text: rdOrigin'
    '.rdOriginContainer'         : 'toggle: rdOrigin'
    '.rdToNumber'                : 'text: rdToNumber'
    '.rdToNumberContainer'       : 'toggle: rdToNumber'
    '.rdUserId'                  : 'text: rdUserId'
    '.rdUserIdContainer'         : 'toggle: rdUserId'
    '.rdSfdcRecordId'            : 'text: rdSfdcRecordId'
    '.rdSfdcRecordIdContainer'   : 'toggle: rdSfdcRecordId'

  computeds:
    index:
      get: ->
        @_index + 1
    timestampCalc:
      deps: ['rdTimestamp']
      get: (rdTimestamp) ->
        new Date(rdTimestamp).toLocaleString('en-US', {hour12: true, timeZone: 'UTC'})
    action:
      deps: ['rdAction']
      get: (rdAction) ->
        actionSplit = rdAction.split('.')
        actionSplit[actionSplit.length - 1]

module.exports = TwilioCallbackView