var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":12}}}) : helper)))
    + "</td>\n<td><a href=\"#smart-numbers/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":34}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":46}}}) : helper)))
    + "</a></td>\n<td>"
    + alias4((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"dateLastUsed") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":35}}}))
    + "</td>\n<td class=\"is-deleted\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"isDeleted") || (depth0 != null ? lookupProperty(depth0,"isDeleted") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"isDeleted","hash":{},"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":36}}}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":10}}}) : helper)))
    + "</td>\n<td>\n  <a class=\"remove-smart-number\" data-id="
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":47}}}) : helper)))
    + " title=\"Delete\">\n    <span class=\"glyphicon glyphicon-remove-sign\" ></span>\n  </a>\n</td>\n";
},"useData":true});