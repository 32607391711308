import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { isFeatureVisibilitiesSelector, useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../styles'
import { FeatureType } from '@ringdna/common/src/types/dialer-settings'
import TableIcon from '@ringdna/common/src/assets/icons/svg/Table'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'

export default SalesforceRecordSettings
function SalesforceRecordSettings() {
  const state = useSupportSettingsState()

  const sfdcCustomCallObject = 'settings.sfdcCustomCallObject'
  const sfdcIgnoreIntegrityErrors = 'settings.sfdcIgnoreIntegrityErrors'
  const sfdcOpenInSalesConsole = 'settings.sfdcOpenInSalesConsole'
  const sfdcSoslSearch = 'settings.sfdcSoslSearch'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={TableIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>Salesforce Record Settings</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Allow Users To Create New Tasks & Events'}
        isChecked={isFeatureVisibilitiesSelector(state, FeatureType.NewTask)}
        setting={FeatureType.NewTask}
      />
      <SupportSettingToggleRow
        title={'Enable Email Button'}
        description={'Hide or show the email button in the RingDNA Dialer.'}
        isChecked={isFeatureVisibilitiesSelector(state, FeatureType.EmailButton)}
        setting={FeatureType.EmailButton}
      />
      <SupportSettingToggleRow
        title={'Ignore Integrity Errors'}
        isChecked={state.accountSettings[sfdcIgnoreIntegrityErrors]}
        setting={sfdcIgnoreIntegrityErrors}
      />
      <SupportSettingToggleRow
        title={'Custom Activity Object'}
        isChecked={state.accountSettings[sfdcCustomCallObject]}
        setting={sfdcCustomCallObject}
      />
      <SupportSettingToggleRow
        title={'Open records in Classic Sales Console'}
        description={"A lead/contact will open in Salesforce Classic's Console view, not Lightning Console."}
        isChecked={state.accountSettings[sfdcOpenInSalesConsole]}
        setting={sfdcOpenInSalesConsole}
      />
      <SupportSettingToggleRow
        title={'SOSL Search'}
        description={
          'Use the Salesforce Object Search Language (SOSL) to construct text-based search queries against the search index'
        }
        isChecked={state.accountSettings[sfdcSoslSearch]}
        setting={sfdcSoslSearch}
      />
    </Section>
  )
}
