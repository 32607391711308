# LEGACY
# Please avoid adding to this file
# require in node modules where needed in the future
# webpack will de-dupe
require('script-loader!../vendor/scripts/jquery-3.5.1.min.js')
# have to load jquery-ui before bootstrap to avoid conflicts
require('script-loader!../vendor/scripts/jquery-ui-1.12.1.min.js')
require('script-loader!jstree')
require('script-loader!../vendor/scripts/underscore.js')
require('script-loader!../vendor/scripts/backbone-1.3.3.js')
require('script-loader!../vendor/scripts/backbone.radio-2.0.0.js')
require('script-loader!../vendor/scripts/backbone.marionette-3.2.0.js')
require('script-loader!../vendor/scripts/backbone.paginator-2.0.5.js')
require('script-loader!../vendor/scripts/backgrid-0.3.7.js')
require('script-loader!../vendor/scripts/backgrid-paginator-0.3.9.js')
require('script-loader!../vendor/scripts/bootstrap-3.3.7')
require('script-loader!../vendor/scripts/spin.js')
require('script-loader!../vendor/scripts/ladda-1.0.0.js')
require('script-loader!../vendor/scripts/moment-2.10.6.js')
require('script-loader!../vendor/scripts/pace-config.js')
require('coffee-loader!script-loader!../vendor/scripts/pace-1.0.2.coffee')

require('script-loader!../vendor/scripts/backbone.dotattr.js')
require('script-loader!../vendor/scripts/backbone.epoxy-1.3.3.js')
require('script-loader!../vendor/scripts/backbone.memento-0.4.1a.js')
require('script-loader!../vendor/scripts/backbone.modal-1.1.5.js')
require('script-loader!../vendor/scripts/backgrid-filter.js')
require('script-loader!../vendor/scripts/bootbox-4.4.0.js')
require('script-loader!../vendor/scripts/bootstrap-datepicker-1.6.4.js')
require('script-loader!../vendor/scripts/bootstrap-datetimepicker.min.js')
require('script-loader!../vendor/scripts/bootstrap-filestyle-1.2.1.js')
require('script-loader!../vendor/scripts/bootstrap-slider-10.0.0.js')
require('script-loader!../vendor/scripts/bootstrap-toggle-2.2.0.js')
require('script-loader!../vendor/scripts/bootstrap-treeview-1.2.0.js')
require('script-loader!../vendor/scripts/iframeResizer.js')
require('script-loader!../vendor/scripts/jquery.toastr-2.1.3.js')
require('script-loader!../vendor/scripts/js.cookie-2.1.2.js')
require('script-loader!../vendor/scripts/ladda.jquery-1.0.0.js')
require('script-loader!../vendor/scripts/pubnub.4.24.3.min')
require('script-loader!../vendor/scripts/recorder.js')
require('script-loader!../vendor/scripts/selectize-0.12.4.js')
require('script-loader!../vendor/scripts/twilio.min.js')
require('script-loader!../vendor/scripts/underscore.js')
require('script-loader!../vendor/scripts/underscore.string-3.3.4.js')
require('script-loader!../vendor/scripts/validator.js')
require('script-loader!../vendor/scripts/video-js-5.19.2.js')

require('../vendor/styles/bootstrap.css')
require('../vendor/styles/backbone.modal.css')
require('../vendor/styles/backbone.modal.theme.css')
require('../vendor/styles/backgrid-filter.css')
require('../vendor/styles/backgrid-paginator.css')
require('../vendor/styles/backgrid.css')
require('../vendor/styles/bootstrap-datepicker3.css')
require('../vendor/styles/bootstrap-datetimepicker.css')
require('../vendor/styles/bootstrap-progressbar-3.3.4.css')
require('../vendor/styles/bootstrap-slider-10.0.0.css')
require('../vendor/styles/bootstrap-theme.css')
require('../vendor/styles/bootstrap-toggle.css')
require('../vendor/styles/bootstrap-treeview.css')
require('../vendor/styles/font-awesome.css')
require('../vendor/styles/jquery-jvectormap-2.0.3.css')
require('../vendor/styles/jquery-ui-1.12.1.css')
require('../vendor/styles/jquery.toastr.css')
require('jstree/dist/themes/default/style.css')
require('../vendor/styles/ladda.scss')
require('../vendor/styles/pace.css')
require('../vendor/styles/selectize.bootstrap3.css')
require('../vendor/styles/video-js.css')
