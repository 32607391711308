import RdnaIconHelper from '../../../assets/icons/IconHelper'
import ChatIcon from '../../../assets/icons/svg/Chat'
import RdnaText from '../../RdnaText'
import React from 'react'
import styled from 'styled-components'
import { DrawerHeaderBackground } from '../index'

export default function EmptyChat() {
  return (
    <Container>
      <RdnaIconHelper icon={ChatIcon} size={80} iconSize={60} variant={'filled'} color={DrawerHeaderBackground} />
      <RdnaText variant={'h2'} color={'link'}>
        How can I help you today?
      </RdnaText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing * 2}px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`
