import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { JsonObject, HttpMethods } from '@ringdna/client/src/constants'

export const useSmsTemplates = createUseFetch<
  any,
  { meta: { accountId: string | undefined; page: number; limit: number; name?: string; teamId?: string } }
>({
  key: 'get-sms-templates',
  independent: true,
  path: params =>
    `/api/v3/sms-templates?accountId=${params.meta.accountId}&name=${params.meta.name}${
      params.meta.teamId ? `&teamId=${params.meta.teamId}` : ''
    }&page=${params.meta.page}&limit=${params.meta.limit}`
})

export const useSmsTemplatesPost = createUseFetchAction<void, any>({
  key: 'post-sms-templates',
  method: HttpMethods.Post,
  path: '/api/v3/sms-templates',
  headers: { 'Content-Type': 'application/json' }
})

export const useSmsTemplatesUpdate = createUseFetchAction<any, { meta: { id: number | undefined }; json: JsonObject }>({
  key: 'update-sms-templates',
  method: HttpMethods.Put,
  path: params => `/api/v3/sms-templates/${params.meta.id}?x-http-method-override=PATCH`,
  headers: { 'Content-Type': 'application/json' }
})

export const useSmsTemplateDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-sms-template',
  method: HttpMethods.Delete,
  path: params => `/api/v3/sms-templates/${params.meta.id}`
})

export const useUploadMedia = createUseFetchAction<any, any>({
  key: 'post-upload-media',
  path: `/api/v2/messageMedia`,
  method: HttpMethods.Post
})

export const useDeleteMedia = createUseFetchAction<any, any>({
  key: 'delete-media',
  path: `/api/v2/messageMedia`,
  method: HttpMethods.Delete
})
