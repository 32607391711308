import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Colors } from '@ringdna/common/src/constants/colors'
import { useAtomValue } from 'jotai'
import { caiManagerAtom, userSettingsStaticAtom } from '../../store/api/user-settings'
import { useFeatureFlag } from '../contexts/FeatureFlagContext'
import config, { ProductDomains } from '../../config'
import { useAnalytics } from '../contexts/AnalyticsContext'
import { RevenueSections } from '../Revenue/GetRevenueIntelligence/useRevenueSection'

import InboxTabLabel from './InboxTabLabel'
import RdnaLeftNav, { NavigationItem } from '@ringdna/common/src/components/RdnaNav/RdnaLeftNav'
import Checklist from '@ringdna/common/src/assets/icons/svg/Checklist'
import CallList from '@ringdna/common/src/assets/icons/svg/CallList'
import Records from '@ringdna/common/src/assets/icons/svg/Records'
import RdnaText from '@ringdna/common/src/components/RdnaText'

import PhoneIcon from '@ringdna/common/src/assets/icons/svg/Phone'
import LibrariesIcon from '@ringdna/common/src/assets/icons/svg/Libraries'
import AmountIcon from '@ringdna/common/src/assets/icons/svg/Amount'
import LeaderboardIcon from '@ringdna/common/src/assets/icons/svg/Leaderboard'
import SystemCheckIcon from '@ringdna/common/src/assets/icons/svg/SystemCheck'
import SavedSearchIcon from '@ringdna/common/src/assets/icons/svg/SavedSearch'
import SettingsIcon from '@ringdna/common/src/assets/icons/svg/Settings'
import EtiquetteIcon from '@ringdna/common/src/assets/icons/svg/Etiquette'
import PhraseBubbleIcon from '@ringdna/common/src/assets/icons/svg/PhraseBubble'
import CoachingIcon from '@ringdna/common/src/assets/icons/svg/Coaching'
import DownloadIcon from '@ringdna/common/src/assets/icons/svg/Download'
import ActivityMetricsIcon from '@ringdna/common/src/assets/icons/svg/ActivityMetrics'
import Bell from '@ringdna/common/src/assets/icons/svg/Bell'
import ThoughtBubbleIcon from '@ringdna/common/src/assets/icons/svg/ThoughtBubble'
import LightBulbOnIcon from '@ringdna/common/src/assets/icons/svg/LightBulbOn'
import Ear from '@ringdna/common/src/assets/icons/svg/Ear'
import BookIcon from '@ringdna/common/src/assets/icons/svg/Book'
import BarChartIcon from '@ringdna/common/src/assets/icons/svg/BarChart'
import ProfileIcon from '@ringdna/common/src/assets/icons/svg/Account'
import LiveIcon from '@ringdna/common/src/assets/icons/svg/Dashboard'

export enum RoutePaths {
  ETIQUETTE_PHONE_ROUTE = '/insights/conversation-etiquette-phone',
  ETIQUETTE_VIDEO_ROUTE = '/insights/conversation-etiquette-video',
  GET_REVENUE = '/get-revenue',
  OVER_TIME_ROUTE = '/insights/over-time-keyword-groups',
  VIDEO_CONVO_ROUTE = '/insights/first-speaker-keyword-groups',
  OCCURRENCE_ROUTE = '/insights/keyword-group-heatmap',
  PRODUCT_UTILIZATION_ROUTE = '/insights/cai-coaching',
  DETAIL_ROUTE = '/insights/coaching-detail',
  CALLING_PRODUCITIVTY_ROUTE = '/insights/activity-calling-productivity',
  TALK_TIME_ROUTE = '/insights/activity-talk-time',
  REC_CALL_PERCENTAGE = '/insights/activity-record-percent',
  TIME_OF_DAY_ROUTE = '/insights/activity-time-day',
  DISPOSITIONS_ROUTE = '/insights/activity-dispositions',
  CALL_DIR_ROUTE = '/insights/activity-call-directions',
  LOCAL_PRESENCE_ROUTE = '/insights/activity-local-presence',
  QUARTERLY_ROUTE = '/insights/quarterly-forecast',
  CAI_NO_LICENSE = '/get-cai',
  MOMENTS_NO_LICENSE = '/get-moments',
  MOMENTS_BEHAVIOR = '/moments/behavior',
  MOMENTS_NOTIFICATIONS = '/moments/notifications',
  MOMENTS_CHECKLISTS = '/moments/checklists',
  MOMENTS_CONTENT = '/moments/content',
  MOMENTS_TEMPLATES = '/moments/templates',
  MOMENTS_BEHAVIORAL_INSIGHTS = '/insights/moments-behavioral-insights-v2',
  MOMENTS_CHECKLIST_ADHERENCE = '/insights/moments-checklist-adherence',
  MOMENTS_COMPLIANCE = '/insights/moments-compliance',
  MOMENTS_CONTENT_LINKS = '/insights/moments-content-links',
  MOMENTS_CONVERSATION_CONVERSION = '/insights/moments-conversation-conversion',
  MOMENTS_DELIVERY = '/insights/moments-delivery',
  MOMENTS_DISPOSITIONS = '/insights/moments-notifications-by-disposition',
  MOMENTS_FREQUENCY = '/insights/moments-frequency',
  MOMENTS_INTERACTIONS = '/insights/moments-interactions',
  MOMENTS_OUTCOMES = '/insights/moments-outcomes',
  ENGAGEMENT = '/engagement',
  DYNAMIC_LEADERBOARD_ROUTE = '/dynamic-leaderboards',
  ACCOUNT_MANAGEMENT = '/settings/account-management',
  CUSTOM_VOCABULARY = '/settings/custom-vocabulary',
  KEYWORD_GROUPS = '/settings/keyword-group',
  NOTIFICATIONS = '/settings/notifications',
  RECIPES = '/recipes',
  RECORDINGS = '/settings/recordings',
  SUPPORT = '/settings/ringdna-support',
  SCORECARDS = '/scorecards',
  TAGS = '/settings/tags',
  USERS = '/settings/users',
  USERPROFILE = '/users',
  ZOOM = '/settings/zoom',
  CONVERSATIONS = '/conversations',
  INBOX = '/inbox',
  LIBRARY = '/library',
  LIVE = '/live',
  MY_SAVED_SEARCHES = '/saved-search/my-saved-searches',
  PUBLIC_SAVED_SEARCHES = '/saved-search/public-saved-searches',
  SHARED_SAVED_SEARCHES = '/saved-search/shared-with-me',
  TEAMS = 'teams',
  OPPORTUNITIES = '/opportunities',
  PARTICIPANT = '/participant'
}

export const MOMENTS_SUBROUTES: NavigationItem[] = [
  {
    id: 'Moments',
    title: 'Moments™',
    sectionTitle: true
  },
  {
    title: 'Behavior Dashboard',
    id: RoutePaths.MOMENTS_BEHAVIOR,
    fullUrl: RoutePaths.MOMENTS_BEHAVIOR,
    Icon: Ear,
    appLicenses: ['moments'],
    userLicenses: ['admin', 'support'],
    ldFlag: 'v286_REV_833'
  },
  {
    title: 'Notifications',
    id: RoutePaths.MOMENTS_NOTIFICATIONS,
    fullUrl: RoutePaths.MOMENTS_NOTIFICATIONS,
    Icon: Bell,
    appLicenses: ['moments']
  },
  {
    title: 'Get Moments™',
    id: RoutePaths.MOMENTS_NO_LICENSE,
    fullUrl: RoutePaths.MOMENTS_NO_LICENSE,
    Icon: Bell,
    nonLicensed: 'moments'
  },
  {
    title: 'Checklists',
    id: RoutePaths.MOMENTS_CHECKLISTS,
    fullUrl: RoutePaths.MOMENTS_CHECKLISTS,
    Icon: Checklist,
    appLicenses: ['moments']
  },
  {
    title: 'Content',
    id: RoutePaths.MOMENTS_CONTENT,
    fullUrl: RoutePaths.MOMENTS_CONTENT,
    Icon: CallList,
    appLicenses: ['moments'],
    userLicenses: ['admin', 'support', 'supervisor']
  },
  {
    title: 'Templates',
    id: RoutePaths.MOMENTS_TEMPLATES,
    fullUrl: RoutePaths.MOMENTS_TEMPLATES,
    Icon: Records,
    appLicenses: ['moments'],
    userLicenses: ['admin', 'support']
  }
]

export const INSIGHT_SUBROUTES: NavigationItem[] = [
  {
    id: 'Insights',
    title: 'Insights',
    sectionTitle: true,
    appLicenses: ['moments', 'conversationAI']
  },
  {
    Icon: () => <EtiquetteIcon innerFill={Colors.WHITE} />,
    id: '/insights',
    title: 'Etiquette',
    appLicenses: ['moments', 'conversationAI'],
    subroutes: [
      {
        title: 'Audio Conversations',
        id: RoutePaths.ETIQUETTE_PHONE_ROUTE,
        fullUrl: RoutePaths.ETIQUETTE_PHONE_ROUTE,
        appLicenses: ['moments', 'conversationAI']
      },
      {
        title: 'Video Conversations',
        id: RoutePaths.ETIQUETTE_VIDEO_ROUTE,
        fullUrl: RoutePaths.ETIQUETTE_VIDEO_ROUTE,
        appLicenses: ['moments', 'conversationAI']
      }
    ]
  },
  {
    Icon: () => <PhraseBubbleIcon />,
    id: '/insights',
    title: 'Real-Time Coaching',
    appLicenses: ['moments'],
    subroutes: [
      {
        title: 'Delivery',
        subTitle: 'Understand how often notifications are triggered and by which reps.',
        id: RoutePaths.MOMENTS_DELIVERY,
        fullUrl: RoutePaths.MOMENTS_DELIVERY,
        appLicenses: ['moments']
      },
      {
        title: 'Notifications (by disposition)',
        subTitle:
          'Understand which notifications are triggered and how often they are triggered for individual Call Dispositions (expressed as a percentage).',
        id: RoutePaths.MOMENTS_DISPOSITIONS,
        fullUrl: RoutePaths.MOMENTS_DISPOSITIONS,
        appLicenses: ['moments']
      },
      {
        title: 'Compliance',
        subTitle: (
          <span>
            Understand how Agent behavior changes when receiving <RdnaText bold>&quot;Exclude&quot;</RdnaText>{' '}
            Notifications (notifications that trigger only when <RdnaText bold>phrases are NOT spoken</RdnaText>).
            <ul>
              <li>
                <RdnaText bold>Success</RdnaText> = Agent spoke the required phrase(s) before the Notification was
                triggered.{' '}
              </li>
              <li>
                <RdnaText bold>Coached to success</RdnaText> = Agent said the required phrase(s) after the Notification
                was triggered.
              </li>
              <li>
                <RdnaText bold>Unsuccessful</RdnaText> = Notification was triggered & Agent never completed required
                phrase(s).
              </li>
            </ul>
          </span>
        ),
        id: RoutePaths.MOMENTS_COMPLIANCE,
        fullUrl: RoutePaths.MOMENTS_COMPLIANCE,
        appLicenses: ['moments']
      },
      {
        title: 'Behavioral Insights',
        subTitle: (
          <span>
            Understand how Agent behaviors change when receiving Conversation Etiquette Notifications (real-time
            reminders that trigger when <RdnaText bold>suboptimal</RdnaText> behaviors are detected in conversation).
          </span>
        ),
        id: RoutePaths.MOMENTS_BEHAVIORAL_INSIGHTS,
        fullUrl: RoutePaths.MOMENTS_BEHAVIORAL_INSIGHTS,
        appLicenses: ['moments']
      },
      {
        title: 'Outcomes',
        subTitle: 'Understand how calls with triggered notifications impact resulting call outcomes.',
        id: RoutePaths.MOMENTS_OUTCOMES,
        fullUrl: RoutePaths.MOMENTS_OUTCOMES,
        appLicenses: ['moments']
      },
      {
        title: 'Conversation Conversion',
        subTitle:
          'Understand the key conversation conversion steps within your organization from the volume of dials that result in conversations and conversations that result in positive outcomes.',
        id: RoutePaths.MOMENTS_CONVERSATION_CONVERSION,
        fullUrl: RoutePaths.MOMENTS_CONVERSATION_CONVERSION,
        appLicenses: ['moments']
      },
      {
        title: 'Content Links',
        subTitle: 'Understand how often and what reference links users are accessing via notifications.',
        id: RoutePaths.MOMENTS_CONTENT_LINKS,
        fullUrl: RoutePaths.MOMENTS_CONTENT_LINKS,
        appLicenses: ['moments']
      },
      {
        title: 'Frequency',
        subTitle:
          'Understand how often Moments™ notifications are triggered based on the volume of conversations they are applicable for.',
        id: RoutePaths.MOMENTS_FREQUENCY,
        fullUrl: RoutePaths.MOMENTS_FREQUENCY,
        appLicenses: ['moments']
      },
      {
        title: 'Checklist Adherence',
        subTitle:
          'Understand how reps are engaging with checklists and the completion rates of checklists or individual checklist items.',
        id: RoutePaths.MOMENTS_CHECKLIST_ADHERENCE,
        fullUrl: RoutePaths.MOMENTS_CHECKLIST_ADHERENCE,
        appLicenses: ['moments']
      },
      {
        title: 'Interactions',
        subTitle: 'Understand how reps are using and interacting with Moments™ notifications.',
        id: RoutePaths.MOMENTS_INTERACTIONS,
        fullUrl: RoutePaths.MOMENTS_INTERACTIONS,
        appLicenses: ['moments']
      }
    ]
  },
  {
    Icon: () => <ThoughtBubbleIcon />,
    id: '/insights',
    title: 'Conversation Topics',
    appLicenses: ['conversationAI'],
    subroutes: [
      {
        title: 'Over Time',
        id: RoutePaths.OVER_TIME_ROUTE,
        fullUrl: RoutePaths.OVER_TIME_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Who Said It First',
        id: RoutePaths.VIDEO_CONVO_ROUTE,
        fullUrl: RoutePaths.VIDEO_CONVO_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Occurrence in Conversation',
        id: RoutePaths.OCCURRENCE_ROUTE,
        fullUrl: RoutePaths.OCCURRENCE_ROUTE,
        appLicenses: ['conversationAI']
      }
    ]
  },
  {
    Icon: () => <CoachingIcon />,
    id: '/insights',
    title: 'Coaching',
    appLicenses: ['conversationAI'],
    subroutes: [
      {
        title: 'Product Utilization',
        id: RoutePaths.PRODUCT_UTILIZATION_ROUTE,
        fullUrl: RoutePaths.PRODUCT_UTILIZATION_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Detailed',
        id: RoutePaths.DETAIL_ROUTE,
        fullUrl: RoutePaths.DETAIL_ROUTE,
        appLicenses: ['conversationAI']
      }
    ]
  },
  {
    Icon: () => <ActivityMetricsIcon />,
    id: '/insights',
    title: 'Activity Metrics',
    appLicenses: ['conversationAI'],
    subroutes: [
      {
        title: 'Calling Productivity',
        id: RoutePaths.CALLING_PRODUCITIVTY_ROUTE,
        fullUrl: RoutePaths.CALLING_PRODUCITIVTY_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Talk Time',
        id: RoutePaths.TALK_TIME_ROUTE,
        fullUrl: RoutePaths.TALK_TIME_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Recorded Call Percentage',
        id: RoutePaths.REC_CALL_PERCENTAGE,
        fullUrl: RoutePaths.REC_CALL_PERCENTAGE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Time of Day',
        id: RoutePaths.TIME_OF_DAY_ROUTE,
        fullUrl: RoutePaths.TIME_OF_DAY_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Dispositions',
        id: RoutePaths.DISPOSITIONS_ROUTE,
        fullUrl: RoutePaths.DISPOSITIONS_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Call Directions',
        id: RoutePaths.CALL_DIR_ROUTE,
        fullUrl: RoutePaths.CALL_DIR_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Local Presence',
        id: RoutePaths.LOCAL_PRESENCE_ROUTE,
        fullUrl: RoutePaths.LOCAL_PRESENCE_ROUTE,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Engagement',
        subTitle: '',
        id: RoutePaths.ENGAGEMENT,
        fullUrl: RoutePaths.ENGAGEMENT,
        appLicenses: ['conversationAI'],
        ldFlag: 'v294_REV_2034'
      }
    ]
  }
]

export const DYNAMIC_LEADERBOARD: NavigationItem = {
  title: 'X-Ray',
  Icon: () => <LeaderboardIcon />,
  id: RoutePaths.DYNAMIC_LEADERBOARD_ROUTE,
  fullUrl: RoutePaths.DYNAMIC_LEADERBOARD_ROUTE,
  appLicenses: ['conversationAI']
}

export const OPPORTUNTIES_LIST: NavigationItem = {
  title: 'Opportunities',
  id: RoutePaths.OPPORTUNITIES,
  Icon: () => <AmountIcon />,
  fullUrl: RoutePaths.OPPORTUNITIES,
  userLicenses: ['supervisor', 'admin', 'support'],
  appLicenses: ['conversationAI']
}

export const SCORECARDS: NavigationItem = {
  title: 'Scorecards',
  id: RoutePaths.SCORECARDS,
  Icon: () => <BarChartIcon />,
  fullUrl: RoutePaths.SCORECARDS,
  userLicenses: ['supervisor', 'admin', 'support'],
  appLicenses: ['conversationAI', 'moments'],
  ldFlag: 'v288_REV_1410'
}

export const ROUTES = (userId: number): NavigationItem[] => [
  {
    title: 'HOME',
    id: '/home',
    sectionTitle: true
  },
  {
    Icon: () => <ProfileIcon />,
    title: 'My Profile',
    id: RoutePaths.USERPROFILE,
    fullUrl: `${RoutePaths.USERPROFILE}/${userId}`,
    appLicenses: ['moments', 'conversationAI'],
    ldFlag: 'v288_REV_1410'
  },
  {
    Icon: () => <DownloadIcon />,
    title: 'Inbox',
    id: RoutePaths.INBOX,
    fullUrl: RoutePaths.INBOX,
    AfterLabelIcon: InboxTabLabel,
    appLicenses: ['conversationAI']
  },
  {
    Icon: BookIcon,
    title: 'Recipes',
    id: RoutePaths.RECIPES,
    ldFlag: 'v287_REV_1264',
    fullUrl: RoutePaths.RECIPES,
    appLicenses: ['moments', 'conversationAI'],
    userLicenses: ['admin', 'support']
  },
  {
    title: 'CONVERSATION AI',
    id: '/conversationai',
    sectionTitle: true
  },
  {
    Icon: () => <LiveIcon />,
    title: 'Live',
    id: RoutePaths.LIVE,
    fullUrl: RoutePaths.LIVE,
    userLicenses: ['supervisor'],
    appLicenses: ['intelligentDialer'],
    ldFlag: 'v291_REV_1806'
  },
  {
    Icon: () => <PhoneIcon />,
    title: 'Conversations',
    id: RoutePaths.CONVERSATIONS,
    fullUrl: RoutePaths.CONVERSATIONS,
    appLicenses: ['conversationAI']
  },
  SCORECARDS,
  {
    Icon: () => <PhoneIcon />,
    title: 'Get Conversation AI',
    id: RoutePaths.CAI_NO_LICENSE,
    fullUrl: RoutePaths.CAI_NO_LICENSE,
    nonLicensed: 'conversationAI'
  },
  {
    Icon: () => <LibrariesIcon innerFill={Colors.WHITE} />,
    title: 'Libraries',
    id: RoutePaths.LIBRARY,
    fullUrl: RoutePaths.LIBRARY,
    appLicenses: ['conversationAI']
  },
  {
    Icon: () => <SavedSearchIcon color={Colors.N100} />,
    id: 'Saved Search',
    title: 'Saved Search',
    appLicenses: ['conversationAI'],
    subroutes: [
      {
        title: 'My Saved Searches',
        id: `${RoutePaths.MY_SAVED_SEARCHES}/`,
        fullUrl: `${RoutePaths.MY_SAVED_SEARCHES}/`,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Public Saved Searches',
        id: `${RoutePaths.PUBLIC_SAVED_SEARCHES}/`,
        fullUrl: `${RoutePaths.PUBLIC_SAVED_SEARCHES}/`,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Shared With Me',
        id: `${RoutePaths.SHARED_SAVED_SEARCHES}/`,
        fullUrl: `${RoutePaths.SHARED_SAVED_SEARCHES}/`,
        appLicenses: ['conversationAI']
      }
    ]
  },
  {
    title: 'REVENUE INTELLIGENCE',
    id: '/revenue',
    sectionTitle: true,
    appLicenses: ['conversationAI']
  },
  OPPORTUNTIES_LIST,
  DYNAMIC_LEADERBOARD,
  ...MOMENTS_SUBROUTES,
  ...INSIGHT_SUBROUTES,
  {
    id: '/settings',
    title: 'Settings',
    sectionTitle: true,
    appLicenses: ['moments', 'conversationAI']
  },
  {
    Icon: () => <SettingsIcon />,
    id: '/settings',
    title: 'User Settings',
    appLicenses: ['conversationAI'],
    subroutes: [
      {
        title: 'Inbox Setup',
        id: RoutePaths.NOTIFICATIONS,
        fullUrl: RoutePaths.NOTIFICATIONS,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Recordings',
        id: RoutePaths.RECORDINGS,
        fullUrl: RoutePaths.RECORDINGS,
        appLicenses: ['conversationAI']
      },
      {
        id: RoutePaths.ZOOM,
        title: 'Zoom',
        fullUrl: RoutePaths.ZOOM,
        appLicenses: ['conversationAI']
      },
      {
        id: RoutePaths.SUPPORT,
        title: 'Revenue Support',
        fullUrl: RoutePaths.SUPPORT,
        userLicenses: ['support'],
        appLicenses: ['conversationAI']
      }
    ]
  },
  {
    Icon: () => <SystemCheckIcon />,
    id: '/settings',
    title: 'Account Settings',
    appLicenses: ['moments', 'conversationAI'],
    subroutes: [
      {
        title: 'Custom Vocabulary',
        id: RoutePaths.CUSTOM_VOCABULARY,
        fullUrl: RoutePaths.CUSTOM_VOCABULARY,
        caiManager: true,
        appLicenses: ['conversationAI'],
        userLicenses: ['admin']
      },
      {
        title: 'Keyword Groups',
        id: RoutePaths.KEYWORD_GROUPS,
        fullUrl: RoutePaths.KEYWORD_GROUPS,
        caiManager: true,
        appLicenses: ['conversationAI'],
        userLicenses: ['admin']
      },
      {
        title: 'Tag Management',
        id: RoutePaths.TAGS,
        fullUrl: RoutePaths.TAGS,
        ldFlag: 'CAI_ANNOTATION_TAGS',
        appLicenses: ['conversationAI']
      },
      {
        title: 'User Management',
        id: RoutePaths.USERS,
        fullUrl: RoutePaths.USERS,
        appLicenses: ['moments', 'conversationAI'],
        userLicenses: ['admin', 'support']
      },
      {
        title: 'Teams Integration',
        id: RoutePaths.TEAMS,
        appLicenses: ['conversationAI']
      },
      {
        title: 'Account Management',
        id: RoutePaths.ACCOUNT_MANAGEMENT,
        fullUrl: RoutePaths.ACCOUNT_MANAGEMENT,
        appLicenses: ['conversationAI'],
        userLicenses: ['support', 'admin']
      }
    ]
  }
]

const useFilteredRoutes = (): NavigationItem[] => {
  const { licenses, userId } = useAtomValue(userSettingsStaticAtom)
  const { caiManager } = useAtomValue(caiManagerAtom)
  const { isFlagged, flags } = useFeatureFlag()
  const filterGatedNavigation = (routes: NavigationItem[]) => {
    return routes.reduce((acc, route) => {
      if (route.nonLicensed && licenses[route.nonLicensed]) return acc
      if (route.appLicenses && !route.appLicenses.some(role => licenses[role])) return acc
      if (route.ldFlag) {
        const flagged = isFlagged(flags[route.ldFlag])
        if (route?.invertLdFlagValue) {
          if (flagged) return acc
        } else if (!flagged) return acc
      }
      if (route.caiManager && route.userLicenses) {
        if (!caiManager && !route.userLicenses.some(role => licenses[role])) return acc
      } else {
        if (route.caiManager && !caiManager) return acc
        if (route.userLicenses && !route.userLicenses.some(role => licenses[role])) return acc
      }
      acc.push(route)
      return acc
    }, [] as NavigationItem[])
  }

  const filterSubRoutes = filterGatedNavigation(
    ROUTES(userId).map(route => {
      if (route.subroutes) {
        const subroutes = filterGatedNavigation(route.subroutes)
        const filteredRoute = { ...route, subroutes }
        // if all route subroutes filtered out, remove route id so it too can be filtered out
        if (!subroutes.length) filteredRoute.id = ''
        return filteredRoute
      } else {
        return route
      }
    })
  ).filter(route => route.id)

  const filterUnusedSectionTitles = filterSubRoutes.filter(
    (route, idx) =>
      !(
        (route.sectionTitle && filterSubRoutes[idx + 1]?.sectionTitle) ||
        (route.sectionTitle && idx === filterSubRoutes.length - 1)
      )
  )

  return filterUnusedSectionTitles
}

export default function LeftNav() {
  const { pathname } = useLocation()
  const { userId } = useAtomValue(userSettingsStaticAtom)
  const [activeRouteId, setActiveRouteId] = useState<string | number>(pathname)
  const [activeSubRouteId, setActiveSubRouteId] = useState<string | number>(pathname)
  const filteredRoutes = useFilteredRoutes()
  const { analytics, revenueEvents } = useAnalytics()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const subRoute = pathname.split('/')[2]
    const route = pathname.split('/')[1]
    let routePath: string | number = 0
    if (RoutePaths.GET_REVENUE.includes(route))
      routePath =
        new URLSearchParams(window.location.search).get('ref') === (RevenueSections.OPPORTUNITIES as unknown as string)
          ? RoutePaths.OPPORTUNITIES
          : RoutePaths.DYNAMIC_LEADERBOARD_ROUTE
    if (RoutePaths.LIBRARY.includes(route)) routePath = RoutePaths.LIBRARY
    if (RoutePaths.CONVERSATIONS.includes(route)) routePath = RoutePaths.CONVERSATIONS
    if (RoutePaths.MY_SAVED_SEARCHES.includes(subRoute)) routePath = `${RoutePaths.MY_SAVED_SEARCHES}/`
    if (RoutePaths.PUBLIC_SAVED_SEARCHES.includes(subRoute)) routePath = `${RoutePaths.PUBLIC_SAVED_SEARCHES}/`
    if (RoutePaths.SHARED_SAVED_SEARCHES.includes(subRoute)) routePath = `${RoutePaths.SHARED_SAVED_SEARCHES}/`
    if (RoutePaths.MOMENTS_TEMPLATES.includes(subRoute)) routePath = `${RoutePaths.MOMENTS_TEMPLATES}`
    if (RoutePaths.USERPROFILE.includes(route)) {
      if (userId === parseInt(subRoute)) {
        routePath = `${RoutePaths.USERPROFILE}`
      } else {
        routePath = `${RoutePaths.USERS}`
      }
    }
    if (RoutePaths.RECIPES.includes(route)) routePath = `${RoutePaths.RECIPES}`
    if (RoutePaths.SCORECARDS.includes(route)) routePath = `${RoutePaths.SCORECARDS}`
    if (RoutePaths.OPPORTUNITIES.includes(route) && subRoute)
      routePath =
        new URLSearchParams(window.location.search).get('ref') === 'list'
          ? RoutePaths.OPPORTUNITIES
          : RoutePaths.QUARTERLY_ROUTE
    routePath = routePath || pathname
    if (filteredRoutes.find(route => route.id === routePath)) {
      setActiveRouteId(routePath)
      setActiveSubRouteId('')
    } else {
      setActiveRouteId('')
      setActiveSubRouteId(routePath)
    }
  }, [pathname, filteredRoutes])

  return (
    <RdnaLeftNav
      useTogglePropInsteadOfState={true}
      isReactBrowserRouter
      showVerticalLines={true}
      activeSubrouteId={activeSubRouteId}
      activeRouteId={activeRouteId}
      onRouteSelect={(id, title) => {
        analytics.trackEvent(revenueEvents.globalEvents.LEFT_NAV_CLICK, { value: title })
        setActiveRouteId(id)
        setActiveSubRouteId('')
      }}
      onSubrouteSelect={(id, title, parentTitle) => {
        if (id === RoutePaths.TEAMS) {
          analytics.trackEvent(revenueEvents.globalEvents.LEFT_NAV_CLICK, {
            value: 'Admin / Integrations / Teams Integration'
          })
          window.open(`${config[ProductDomains.WEB_ADMIN]}/#integrations/video`, '_blank')
        } else {
          analytics.trackEvent(revenueEvents.globalEvents.LEFT_NAV_CLICK, { value: `${parentTitle} / ${title}` })
          setActiveSubRouteId(id)
          setActiveRouteId('')
        }
      }}
      enableRouting
      routes={filteredRoutes}
    />
  )
}
