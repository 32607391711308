import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { FormSelectOption, InputField } from '@ringdna/common/src/types/form-inputs'
import { usePostSchedulingPage } from '../../../../../store/api/personal-calendar-api'
import { getSchedulerFormData } from './constants'
import { formValuesToSchedulingPagePayload } from './utils'
import { SchedulingPagesPayload, SchedulingPage, FieldValues, FieldErrors } from '../types'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import EditSchedulingCard from './EditSchedulingCard'
import AddSchedulingPageCard from './AddSchedulingPageCard'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import { Colors } from '@ringdna/common/src/constants/colors'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'

type Props = {
  userId: number
  schedulingPagesPayload: SchedulingPagesPayload
  refetchSchedulingPages: () => void
}

export default function SchedulingEditor({ userId, schedulingPagesPayload, refetchSchedulingPages }: Props) {
  const schedulingPages = useMemo(() => schedulingPagesPayload.schedulingPages, [schedulingPagesPayload])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const [inputFields, setInputFields] = useState<InputField[]>([])
  const [fieldValues, setFieldValues] = useState<FieldValues>({})
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})
  const [initialSchedulingPageValues, setInitialSchedulingPageValues] = useState<FieldValues | null>()
  const postSchedulingPage = usePostSchedulingPage()

  const onAddSchedulingPage = () => {
    setInitialSchedulingPageValues(null)
    buildForm({})
  }

  const onEditSchedulingPage = (editValues: SchedulingPage) => {
    // @ts-ignore
    nylas.scheduler
      .show({
        auth: {
          pageEditToken: editValues.editToken
        },
        style: {
          tintColor: Colors.BLUE,
          backgroundColor: Colors.WHITE
        }
      })
      .addEventListener('close', () => {
        setTimeout(refetchSchedulingPages, 1000) // one-second delay for make sure that data already in Nylas
      })
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const buildForm = (editValues: FieldValues) => {
    setFieldErrors({})
    const { formFields, formInitialValues } = getSchedulerFormData(editValues)
    setFieldValues(formInitialValues)
    setInputFields(formFields)
    setOpenModal(true)
  }

  const onSubmit = async () => {
    if (!fieldValues || !isValidForm(fieldValues)) return
    const data = formValuesToSchedulingPagePayload(fieldValues, userId)
    setIsSaving(true)
    try {
      if (!fieldValues?.id) {
        await postSchedulingPage({ body: data })
      }
      setIsSaving(false)
      closeModal()
      refetchSchedulingPages()
    } catch (error) {
      toastr.error(error.message)
      setIsSaving(false)
    }
  }

  const isValidForm = (values: FieldValues) => {
    const errors = inputFields.reduce((acc: FieldErrors, field) => {
      const value = values[field.name] as string
      switch (field.name) {
        case 'name':
          if (!value || value.length < 3) acc.name = 'Must be atleast 3 characters.'
          break
        default:
          if (field.required && !value) acc[field.name] = 'This field is required.'
      }
      return acc
    }, {})
    const isValid = Object.keys(errors).length === 0
    setFieldErrors(!isValid ? errors : {})
    return isValid
  }

  const onChange = (name: string, value: string) => {
    if (!fieldValues) return
    setFieldValues({ ...fieldValues, [name]: value })
  }

  const onBlur = async (fieldName: string) => {
    if (
      !fieldValues ||
      (initialSchedulingPageValues && initialSchedulingPageValues[fieldName] === fieldValues[fieldName])
    )
      return
    isValidForm(fieldValues)
  }

  return (
    <StyledSchedulingPageEditor>
      <RdnaModal
        open={isOpenModal}
        onClose={closeModal}
        heading={`${fieldValues.id ? 'Edit' : 'New'} Scheduling Page`}
        onConfirm={onSubmit}
        confirmButtonText={'Submit'}
        confirmButtonDisabled={isSaving}
      >
        {fieldValues &&
          inputFields &&
          inputFields
            .filter((field: InputField) => field.label !== 'Location')
            .map((field: InputField) => {
              const isEventLinkName = field.name === 'eventLinkName'
              const isError = typeof fieldErrors[field.name] !== 'undefined'
              return (
                <div key={field.name}>
                  <FieldContainer className={isEventLinkName ? 'event-link-name' : ''}>
                    <>
                      {field.type === InputTypes.DROPDOWN ? (
                        <RdnaSelect
                          label={field.label}
                          required={field.required}
                          name={field.name}
                          // @ts-ignore TODO
                          value={fieldValues[field.name] || ''}
                          disabled={field.disabled}
                          options={field.options as FormSelectOption[]}
                          error={isError}
                          helperText={isError ? fieldErrors[field.name] : ''}
                          onChange={e => onChange(e.target.name, e.target.value as string)}
                        />
                      ) : (
                        <RdnaTextInput
                          label={field.label}
                          name={field.name}
                          required={field.required}
                          disabled={field.disabled}
                          value={fieldValues[field.name] || ''}
                          onChange={e => onChange(e.target.name, e.target.value)}
                          multiline={field.type === InputTypes.TEXT_AREA}
                          error={isError}
                          rows={field.rows}
                          inputProps={{
                            maxLength: field.maxTextLength
                          }}
                          helperText={isError ? fieldErrors[field.name] : ''}
                          onBlur={e => onBlur(e.target.name)}
                        />
                      )}
                    </>
                  </FieldContainer>
                </div>
              )
            })}
      </RdnaModal>
      <RdnaText variant="body2" component="p">
        Scheduling pages allow you to create events with preset configurations to offer a wide range of bookings.
      </RdnaText>
      <div className="scheduling-page-cards-container">
        <AddSchedulingPageCard onAddSchedulingPage={onAddSchedulingPage} />
        {schedulingPages.map((schedulingPage, idx) => (
          <EditSchedulingCard
            key={schedulingPage.id}
            pageIndex={idx}
            schedulingPages={schedulingPages}
            onEditSchedulingPage={onEditSchedulingPage}
            refetchSchedulingPages={refetchSchedulingPages}
          />
        ))}
      </div>
    </StyledSchedulingPageEditor>
  )
}

const StyledSchedulingPageEditor = styled.div`
  & > p {
    padding-bottom: ${({ theme }) => theme.spacing * 3}px;
  }
  .scheduling-page-cards-container {
    display: flex;
    flex-wrap: wrap;
    margin: ${({ theme: { spacing } }) => `${spacing * -2.5}px 0 ${spacing * 3}px ${spacing * -4}px`};
    & > div {
      width: 25%;
      padding: ${({ theme: { spacing } }) => `${spacing * 4}px 0 0 ${spacing * 4}px`};
      ${({ theme }) => theme.breakpoints.lg.mediaQuery} {
        width: 33.33%;
      }
      ${({ theme }) => theme.breakpoints.md.mediaQuery} {
        width: 33.33%;
      }
      ${({ theme }) => theme.breakpoints.sm.mediaQuery} {
        width: 50%;
      }
      ${({ theme }) => theme.breakpoints.xs.mediaQuery} {
        width: 100%;
      }
    }
  }
}
`

const FieldContainer = styled.div`
  min-width: 480px;
  padding-top: ${({ theme }) => theme.spacing * 3}px;
  &.event-link-name {
    padding-top: 0;
    overflow: hidden;
    display: flex;
  }
  &.event-link-name > span {
    flex: 1;
    padding-top: ${({ theme }) => theme.spacing * 3.5}px;
    padding-right: ${({ theme }) => theme.spacing}px;
    white-space: nowrap;
  }
  &.event-link-name label {
    display: none;
  }
}
`
