import { tabPathProps } from './types'

export const SERVER_PAGE_OFFSET = 1
export const PAGE_SIZE = 10
export const EDIT = 'Edit'
export const DELETE = 'Delete'
export const RESTORE = 'Restore'
export const COPYEDIT = 'Copy & Edit'
export const CALL_FLOWS: tabPathProps = {
  title: 'Call Flows',
  path: '/call-flows'
}
export const CALL_QUEUES: tabPathProps = {
  title: 'Call Queues',
  path: '/call-queues'
}
export const HOLIDAY_CALENDAR: tabPathProps = {
  title: 'Holiday Calendar',
  path: '/holiday-calendar'
}
export const USER_SKILLS: tabPathProps = {
  title: 'User Skills',
  path: '/user-skills'
}

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }
