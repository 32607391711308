import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const Etiquette = ({ color = Colors.N100, size = 30 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M13.5559 7.58484C13.5559 9.26631 12.1928 10.6294 10.5114 10.6294C8.8299 10.6294 7.4668 9.26631 7.4668 7.58484C7.4668 5.90338 8.8299 4.54028 10.5114 4.54028C12.1928 4.54028 13.5559 5.90338 13.5559 7.58484Z"
      stroke={color}
    />
    <path
      d="M13.5707 18.5609H4.88299L3.78174 16.5052C3.78174 16.5052 5.43362 13.4217 10.3893 13.4217C11.6566 13.4217 12.7078 13.6233 13.5707 13.9235"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9941 12.8099H20.9998"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9941 14.8789H20.9998"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0947 16.9148H19.265"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Etiquette
