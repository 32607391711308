import React, { useMemo } from 'react'
import { useIntegrations } from '../../../store/api/user-integrations-api'
import styled from 'styled-components'
import { useActiveUser } from '../../../hooks/useActiveUser'
import PersonalizeSettings from './PersonalizeSettings'
import SchedulingPages from './SchedulingPages'
import RdnaNotification from '@ringdna/common/src/components/RdnaNotification'
import RdnaTabRouter from '@ringdna/common/src/components/RdnaTabRouter'
import EmptyCalendarBooking from '@ringdna/common/src/assets/illustrations/EmptyCalendarBooking'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'

const ErrorContainer = styled.div`
  margin: 0 0 20px;
`

const NoResults = styled.div`
  text-align: center;
  padding-bottom: 20px;

  svg {
    display: block;
    margin: 0 auto 20px auto;
  }
`

const NYLAS_VALID_STATUSES = ['RUNNING', 'DOWNLOADING', 'INITIALIZING']

export default function SchedulingTabs() {
  const { activeUserId } = useActiveUser()
  const [integrationsPayload = { nylasUsers: [] }, , integrationsLoading] = useIntegrations({
    meta: {
      id: activeUserId
    }
  })

  const displayErrorNotification =
    integrationsLoading && integrationsPayload.nylasUsers
      ? false
      : integrationsPayload.nylasUsers.filter(nylasUser => !NYLAS_VALID_STATUSES.includes(nylasUser.status)).length !==
        0

  const gotoIntegrations = () => {
    window.location.href = `/#users/${activeUserId}/integrations`
  }

  const ROUTES = useMemo(() => {
    return [
      { path: '/scheduling-pages', label: 'Scheduling Pages', component: SchedulingPages },
      { path: '/personalize', label: 'Personalize', component: PersonalizeSettings }
    ]
  }, [])

  return (
    <RdnaLoader
      data={integrationsPayload.nylasUsers}
      loading={!!integrationsLoading}
      noResultsMsg={
        <NoResults>
          <EmptyCalendarBooking />
          <RdnaText variant={'h2'}>{'No Calendar Found'}</RdnaText>
          <RdnaText>{'Add a Calendar on the Integration tab to enable Calendar Booking.'}</RdnaText>
        </NoResults>
      }
    >
      {displayErrorNotification && (
        <ErrorContainer>
          <RdnaNotification
            message={'The default calendar is currently unavailable.'}
            buttonText="Fix it"
            onButtonClick={gotoIntegrations}
          />
        </ErrorContainer>
      )}
      <RdnaTabRouter
        tabWidth="200px"
        redirectRoute="scheduling-pages"
        baseRoute={`/users/${activeUserId}/calendar`}
        routes={ROUTES}
      />
    </RdnaLoader>
  )
}
