export const CUSTOM_LINKS_STR = {
  delete: 'Delete',
  edit: 'Edit',
  header: 'List of all Call Custom Links (icons on the right panel of RingDNA) that give access to a website or visual force page.',
  name: 'label',
  url: 'url'
}

export const customLinksColumns = [
  {
    Header: 'Name',
    accessor: CUSTOM_LINKS_STR.name
  },
  {
    Header: 'URL',
    accessor: CUSTOM_LINKS_STR.url
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }

export const initCustomLink = {
  label: '',
  url: '',
  id: 0
}
