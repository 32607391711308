Handlebars = require('handlebars/runtime').default
require('helpers/url-helper')
BaseModel = require('base/model')

class StepModel extends BaseModel
  urlRoot: -> "callFlows/#{@get('callflowId')}/step"

  parse: (json, options) ->
    attrs = _.clone(json)
    attrs.callflowId = options.callflowId

    attrs.args = JSON.parse(attrs.argsStorage)
    delete attrs.argsStorage

    attrs.parentId = attrs.parentStep?.id ? 0
    delete attrs.parentStep

    attrs

  toJSON: ->
    fields = {}

    apiAttrs = [ 'id', 'name', 'type' ]
    fields["step.#{key}"] = @get(key) for key in apiAttrs
    fields['step.argsStorage'] = JSON.stringify(@args())
    fields['step.callFlow.id'] = @get('callflowId')
    fields['step.parentStep.id'] = @get('parentId') if @get('parentId') isnt '#'

    fields

  createStepsCollection: (сreateSteps, assignments, callback) ->
    steps = for model in сreateSteps
      'argsStorage': JSON.stringify(model.args())
      'name'  : model.get('name')
      'type'  : model.get('type')
      'callFlow.id': model.get('callflowId')
      'parentStep.id': model.get('parentId') if model.get('parentId') isnt '#'
    if steps.length > 0
      callflowId = @get('callflowId')
      App.api.post(
        path    : "callFlows/#{callflowId}/steps"
        data    : steps : steps
        success : (response) =>
          for model in response
            model = new StepModel(model, parse: true)
            model.set(callflowId : @get('callflowId'))
            @collection.add(model)
            assignment = assignments.find((assignment) -> assignment.id is model.parent().id)
            assignments = _.without(assignments, assignment)
            model.parent().updateAssignment(model.id, assignment.name)
          callback()
      )
    else
      callback()

  saveStep: (callback) ->
    @persist()
    .done((response) =>
      @set(callflowId : response.callFlow.id)
      callback(@) if callback
    )

  deleteStep: (additionallyRemovable) ->
    @delete(data: additionallyRemovable: additionallyRemovable).done()

  args: ->
    result = @get('args')
    if not result
      result = {}
      @set(args: result)
    result

  assignment: ->
    parent = @parent()
    if parent
      result = (value for value in (key for key, val of parent.args() when String(val) is String(@id)) when value)
      result[0] if result

  speechAssignment: ->
    parent = @parent()
    if parent
      result = (value for value in (key for key, val of parent.args() when String(val) is String(@id)) when value and isNaN(value))
      result[0] if result

  assignedChild: (assignedChildKey, assignedChildVal) ->
    if @parent()
      result = (value for value in (for key, val of @parent().args()
        @_intersection = _.intersection(key.split(','), assignedChildKey.split(','))
        if @_intersection.length then val else null
      ) when value)
      childId = result[0]
      @.collection.get(childId) if childId and String(childId) isnt String(assignedChildVal)

  audioId: (prefix = 'audio') ->
    @args()["#{prefix}Id"]

  audioType: (prefix = 'audio') ->
    @args()["#{prefix}Type"]

  defaultPlaceholderLabel: ->
    'Drop a function here'

  errors: ->
    null

  audioErrors: (audioPrefixes) ->
    messages = []
    selectors = []
    for key in audioPrefixes
      if not @audioType(key) or @audioType(key) is '0' or ((@audioType(key) is '2' or @audioType(key) is '3' or @audioType(key) is '4') and not @audioId(key))
        messages.push('Audio is required')
        selectors.push(".#{key}-input")

      if not @args().audioUserId and not @args().invalidAudioUserId and @audioType(key) is '5'
        messages.push('User is required')
        selectors.push(".#{key}-input")
    _.zip(messages, selectors)

  icon: =>
    Handlebars.helpers.imageUrl("call-flow/mini-#{@get('type').toLowerCase()}.png")

  isFixedPlaceholder: ->
    !!(index for value, index in @parent().get('fixedPlaceholders') when value is @assignment())?.length

  isInteger: (value) ->
    /^\d*$/.test(value)

  isPlaceholder: ->
    @get('type') is 'Placeholder'

  nonFixedPlaceholder: =>
    placeholderSteps = @collection?.where({parentId: @id, type: 'Placeholder'})
    (step for step in placeholderSteps when not step.isFixedPlaceholder()) if placeholderSteps

  label: =>
    assign = @assignment()
    stepError = if @errors()?.length > 0 then 'step-error' else ''
    if @isPlaceholder()
      label = @defaultPlaceholderLabel()
    else
      n = @get('name')
      if not n
        n = @get('type')
      label = n
    isBranch = @parent()?.get('type') is 'Branch'
    if assign and assign != '_'
      if assign.length == 1 and not isBranch
        "<span class='keypress'>#{_.escape(assign)}</span><span class='step-label #{_.escape(stepError)}'>&nbsp;#{_.escape(label)}</span>"
      else
        "<span class='step-label #{_.escape(stepError)}'>#{_.escape(assign)} - #{_.escape(label)}</span>"
    else
      "<span class='step-label #{_.escape(stepError)}'>#{_.escape(label)}</span>"

  nextPlaceholder: ->
    return null if @nonFixedPlaceholder()?.length
    skip = false
    result = (value for value in (for key in @placeholders()
      break if skip
      if not @args() or not @args()[key]
        key
      else if @collection.get(@args()[key])?.isPlaceholder()
        skip = true; null
    ) when value) if @placeholders()
    result[0] if result and not skip

  parent: ->
    if @collection then @collection.get(@get('parentId')) else null

  placeholders: ->
    @get('placeholders')

  replaceAssignment: (step, newAssignment) ->
    oldAssignment = step.assignment()
    return if newAssignment is oldAssignment
    oldStep = step.assignedChild(newAssignment, step.id)
    parent = step.parent()
    if oldStep
      replacementAssignment = step.parent().placeholders()[0]
      parent.updateAssignment(oldStep.id, replacementAssignment, oldStep.assignment())
      @trigger('updateTreeLabel', oldStep)
      parent.updateAssignment(step.id, newAssignment, oldAssignment)
      @trigger('updateTreeLabel', step)
    else
      parent.updateAssignment(step.id, newAssignment, oldAssignment)
      @trigger('updateTreeLabel', step)

  swapAssignment: (assignmentValue, oldAssignmentValue) ->
    args = @args()
    tmp = args[oldAssignmentValue]
    args[oldAssignmentValue] = args[assignmentValue]
    args[assignmentValue] = tmp
    @set('args', args)

  unassigned: ->
    parent = @parent()
    if parent
      allPlaceholders = _.union(parent.placeholders(), parent.get('fixedPlaceholders'))
      (value for value in (for key in allPlaceholders
        key if !parent.get('args') or !parent.get('args')[key] or @collection.get(parent.get('args')[key])?.isPlaceholder()
      ) when value)

  updateAssignment: (assignmentId, assignmentValue, oldAssignmentValue) ->
    args = @args()
    delete args[oldAssignmentValue] if oldAssignmentValue
    args[assignmentValue] = assignmentId
    @set('args', args)

  updateSpeechAssignment: (assignmentId, assignmentValue) ->
    parent = @parent()
    args = parent.args()
    result = (value for value in (key for key, val of args when String(val) is String(assignmentId)) when value and isNaN(value))
    for value in result
      delete args[value]
    args[assignmentValue] = assignmentId
    parent.set('args', args)

  deleteAssignment: (assignmentValue) ->
    args = @args()
    delete args[assignmentValue] if assignmentValue
    @set('args', args)

module.exports = StepModel
