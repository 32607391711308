import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import React, { useCallback, useMemo, useState } from 'react'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE, REASSIGN, REMOVE, VIEW_USER, PAGE_SIZES } from '../const'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import { Role, User } from '../types'
import ReassignUserModal from './ReassignUserModal'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import { useUserRoleDelete } from '../api'
import { createRoleOptions } from '../utils'
import { usersColumns } from '../columns'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'

export type UserTableProps = {
  payload: any | undefined
  refetch: any
  loading: boolean
  roleId: number
  roleDefinitions: Role[]
}

export type DeleteModalProps = {
  open: boolean
  onClose: any
  userName?: string
  onConfirm: any
  isLoadingDelete: boolean
}

export default function UsersTable({ payload, loading, refetch, roleId, roleDefinitions }: UserTableProps) {
  const { error } = useRdnaNotification()
  const [isReassignUserModalOpen, setIsReassignUserModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [user, setUser] = useState<User>()
  const deleteUserRole = useUserRoleDelete()

  const handleActionMenu = useCallback((action: string, user: User) => {
    if (action === VIEW_USER) {
      window.location.href = `#users/${user.id}`
    } else if (action === REASSIGN) {
      setUser(user)
      setIsReassignUserModalOpen(true)
    } else if (action === REMOVE) {
      setUser(user)
      setIsDeleteModalOpen(true)
    }
  }, [])

  const memoPayload = useMemo(() => {
    return payload?.listUsers?.map((template: User) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu
            options={[{ text: VIEW_USER }, { text: REASSIGN }, { text: REMOVE }]}
            onSelect={item => handleActionMenu(item, template)}
          />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  async function handleDelete() {
    setIsLoadingDelete(true)
    try {
      await deleteUserRole({ meta: { userId: user?.id } })
      setIsDeleteModalOpen(false)
      refetch()
    } catch (e) {
      error(getErrorMessage(e))
    }
    setIsLoadingDelete(false)
  }

  const getRoleOptions = useMemo(() => {
    return createRoleOptions(roleDefinitions)
  }, [roleDefinitions])

  return (
    <>
      <RdnaText bold>Users</RdnaText>
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={usersColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        isFetching={loading}
        paginationSize={DEFAULT_PAGE_SIZE}
        paginationSizes={PAGE_SIZES}
      />
      <ReassignUserModal
        open={isReassignUserModalOpen}
        onClose={() => setIsReassignUserModalOpen(false)}
        user={user}
        currentRoleId={roleId}
        refetchUsers={refetch}
        roleOptions={getRoleOptions}
      />
      <ConfirmUserRoleDeleteModal
        open={isDeleteModalOpen}
        onClose={setIsDeleteModalOpen}
        userName={user?.displayName}
        onConfirm={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  )
}

const ConfirmUserRoleDeleteModal = ({ open, onClose, onConfirm, userName, isLoadingDelete }: DeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Role`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`Are you sure you want to delete role from User: ${userName}. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}
