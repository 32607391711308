BaseView = require('base/view')
ReactComponent  = require('src/ReactComponent').default
Salesforce = require('src/components/integrations/salesforce').default

class SalesforceView extends BaseView
  template: require('./template')
  className: 'salesforce-view'

  regions:
    container : '.salesforce'

  initialize: (options) =>
    { @qs } = options
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @qs.setFragment('integrations/salesforce')

  onRender: ->
    @showChildView('container', new ReactComponent({component: Salesforce, props: {accountId: @accountId}}))

module.exports = SalesforceView
