/*
const { isFlagged, flags } = useFeatureFlag()
const flagValue = isFlagged(flags.FLAG_ID)
*/
import React, { createContext, useContext, useState, useEffect } from 'react'
import { init, isFlagged, getAllFlagValues, AllFlagValues } from '@ringdna/common/src/utils/feature-flag/featureFlag'
import { availableFlags } from '@ringdna/common/src/utils/feature-flag/available-flags'
import { userSettingsStaticAtom } from '../../store/api/user-settings'
import { useAtomValue } from 'jotai'
import Config from '../../config'

import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'

type ContextType = {
  isFlagged: (key: string, defaultValue?: string | boolean) => any
  flags: typeof availableFlags
  getAllFlagValues: () => AllFlagValues
}

const FeatureFlagContext = createContext<ContextType | undefined>(undefined)

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    /* istanbul ignore next */
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider')
  }
  return context
}

type Props = {
  children: React.ReactNode
}

export const FeatureFlagProvider = ({ children }: Props) => {
  const [featureFlagStarted, setFeatureFlagStarted] = useState<boolean>(false)
  const userSettings = useAtomValue(userSettingsStaticAtom)

  useEffect(() => {
    const initFeatureFlag = async () => {
      await init({
        apiKey: Config.launchDarklyKey,
        user: {
          id: userSettings.userId,
          email: userSettings.userEmail,
          name: userSettings.userName,
          accountId: userSettings.accountId,
          username: userSettings.userUserName,
          firstName: userSettings.firstName,
          lastName: userSettings.lastName,
          accountName: userSettings.accountName
        }
      })
      setFeatureFlagStarted(true)
    }
    if (!featureFlagStarted) initFeatureFlag()
  }, [userSettings, featureFlagStarted, setFeatureFlagStarted])

  return (
    <FeatureFlagContext.Provider value={{ isFlagged, flags: availableFlags, getAllFlagValues }}>
      <RdnaLoader data="data" loading={!featureFlagStarted} top="130px">
        {children}
      </RdnaLoader>
    </FeatureFlagContext.Provider>
  )
}
