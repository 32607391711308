BaseModel = require('base/model')

class ReportAdWordsModel extends BaseModel
  defaults:
    adWordCampaignId: null
    adgroups: ''
    adGroupId: null
    conversions: null
    cost: null
    ctr: ''
    clicks: null
    campaigns: ''
    firstPageCPC: null
    adsId: ''
    impressions: null
    keywords: ''
    keywordId: null
    matchType: ''

module.exports = ReportAdWordsModel
