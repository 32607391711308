export const handleStream = (response: Response, onMessage: (message: any) => void, onComplete: () => void) => {
  const reader = response?.body?.getReader()
  reader?.read().then(function process(result): any {
    if (result.done) {
      reader?.cancel('canceled')
      reader?.releaseLock()
      onComplete()
      return
    }
    onMessage(result.value)
    return reader.read().then(process)
  })
}
