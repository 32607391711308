import { DefaultApi, ApiConfigs, OnRequestFail } from '@ringdna/client/src/constants'
import prepareFormData from '@ringdna/client/src/utils/prepareFormData'
import createWebCache from '@ringdna/client/src/cache/createWebCache'

import config, { ProductDomains } from '../config'
import { authenticator, logout } from './authenticator'
import createClient from '@ringdna/client/src/createClient'
import { captureException } from '@ringdna/common/src/utils/sentry'

const CLIENT_INTERNAL_ERROR_FINGERPRINT = 'client-internal-error'

const makeOnRequestFail =
  (logout?: () => void): OnRequestFail =>
  async (resource, client, err, response) => {
    if (process.env.NODE_ENV !== 'test') {
      console.log('[client] onRequestFail', resource, err, response)
    }
    const status = resource.fail?.status as number

    // no error -> there was a response with a status >=400. Attempt to parse an appropriate error message.
    let message = resource.fail?.payload || resource.fail?.message
    try {
      // some endpoints return error message as a json string, try to parse it as such
      if (resource.fail?.payload) message = JSON.parse(resource.fail?.payload).message || message
    } catch (err) {} // noop, if we could not extract message, use the existing text
    const error = new Error(`[API Error: ${status}] ${message}`)

    // if 401, trigger auth flow
    if (status === 401 && logout) {
      return logout()
    }

    // otherwise report to sentry
    if (status > 500 || status === -1) {
      const formattedSentryError = new Error(`api-client(${resource.methodOptions.key}): ${error}`)
      const fingerprint = [err ? CLIENT_INTERNAL_ERROR_FINGERPRINT : resource.methodOptions.key]
      captureException(formattedSentryError, { resource, client, err, response }, fingerprint)
    }
  }

const apis: ApiConfigs = {
  [DefaultApi]: {
    authenticator,
    basePath: config[ProductDomains.API],
    onRequestFail: makeOnRequestFail(logout),
    debug: !!config.debugApiClient,
    prepareBody: async params => {
      if (params && params.json) {
        return JSON.stringify(params.json)
      }
      if (params && params.body) {
        if (!(params.body instanceof FormData)) {
          return prepareFormData(params.body)
        }
        return params.body
      }
    }
  },
  mlFeedback: {
    headers: {
      'Content-Type': 'application/json'
    },
    basePath: config.mlFeedbackDomain,
    onRequestFail: makeOnRequestFail(),
    debug: !!config.debugApiClient
  },
  apiLabs: {
    authenticator,
    basePath: config.apiLabs,
    onRequestFail: makeOnRequestFail(),
    debug: !!config.debugApiClient
  },
  apiGpu: {
    authenticator,
    basePath: config.apiGpu,
    onRequestFail: makeOnRequestFail(),
    debug: !!config.debugApiClient
  }
}

export default createClient(apis, createWebCache())
