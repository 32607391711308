LoginView = require('./view')

class LoginController extends Marionette.Object
  initialize: (options) ->
    @app = options.app
    @model = options.model
    @app.radio.on('user:logout', @logout)

  show: (@model) =>
    @app.view.showLogin(new LoginView(app: @app, model: @model))

  logout: =>
    console.info('Logout')
    sessionStorage.clear()
    @app.session
      .logout()
      .always(=>
        @app.navigate('')
        @app.session.login()
    )

module.exports = LoginController
