import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/common/src/client/constants'

export const useSmartNumbers = createUseFetch<
  any,
  { meta: { callflowId: string | undefined; page: number; limit: number } }
>({
  key: 'get-smart-numbers',
  independent: true,
  path: params =>
    `/api/v3/call-flows/${params.meta.callflowId}/smart-numbers?page=${params.meta.page}&limit=${params.meta.limit}`
})

export const useDeleteSmartNumber = createUseFetchAction<{ meta: { callflowId: number; id: number } }, any>({
  key: 'delete-smart-numbers',
  method: HttpMethods.Delete,
  path: params => `/api/v3/call-flows/${params.meta.callflowId}/smart-numbers/${params.meta.id}`
})
