import React from 'react'
import styled from 'styled-components'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'

type Props = {
  children: React.ReactElement | React.ReactElement[]
  className?: string
}

export default function CardWrapper({ children, className }: Props) {
  return (
    <div>
      <StyledCard width="fill" className={className || ''}>
        {children}
      </StyledCard>
    </div>
  )
}

const StyledCard = styled(RdnaCard)`
  text-align: center;
`
