BaseModel = require('base/model')
BaseView = require('base/view')
TagModel = require('./model')

class Picklist extends Backbone.Collection
  url: 'tags/legacy/picklist'

class TagsView extends BaseView
  template: require('./template')

  ui:
    tags: '.tags'

  initialize: (options) ->
    @accountId = options.accountId
    @collection = options.collection
    @selected = @collection.selectized()

  onRender: =>
    @ui.tags.selectize(
      options          : @selected
      items            : (value.value for value in @selected)
      closeAfterSelect : true
      persist          : false

      onItemAdd: (value) =>
        @trigger('added', value)

      onItemRemove: (value, item) =>
        @selectize.removeOption(value)
        @trigger('removed', value)

      create: (tag, callback) =>
        model = new TagModel(
          data: tag
          accountId: @accountId
        )
        model.persist(wait: true).done((response) =>
          item = { text: response.data, value: response.id }
          callback(item)
        )
        @collection.add(model)
        undefined

      load: (query, callback) =>
        return if query?.length < 2
        new Picklist().fetch(
          data:
            name: query
            accountId: @accountId
        ).done((response) =>
          callback(response)
        )
    )
    @selectize = @ui.tags[0].selectize

module.exports = TagsView
