import React, { useEffect, useState } from 'react'
import { formatMessage } from '../utils'
import { useAtom } from 'jotai'
import { streamAtom } from '../../index'
import { ChatProps } from '../index'
import StreamSources from './StreamSources'
import { handleStream } from './utils'

export type StreamProps = {
  response: Response
  name: string
  input: string
} & Pick<ChatProps, 'queryParams' | 'apiPath' | 'apiBase'>

export default function StreamMessage({ response, name, apiPath, apiBase, queryParams, input }: StreamProps) {
  const [streams, setStreams] = useAtom(streamAtom)
  const streamString = streams[name]
  const [streamComplete, setStreamComplete] = useState(!!streamString)

  useEffect(() => {
    if (!streamString) {
      let streamStringCombined = ''
      const onMessage = (message: AllowSharedBufferSource) => {
        const textChunk = new TextDecoder().decode(message)
        const formattedTextChunk = textChunk.replace('</s>', '').replace('\n', '\n\n')
        streamStringCombined += formattedTextChunk
        setStreams(prev => ({ ...prev, [name]: streamStringCombined }))
      }

      const onComplete = () => {
        setStreamComplete(true)
      }
      handleStream(response, onMessage, onComplete)
    }
  }, [])
  return (
    <div>
      {formatMessage(streamString)}
      <br />{' '}
      {streamComplete && apiBase !== 'apiPlay' && (
        <StreamSources name={name} apiPath={apiPath} queryParams={queryParams} input={input} />
      )}
    </div>
  )
}
