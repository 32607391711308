export const SALES_SUPPORT_FILTERED_ROUTE_GROUPS = ['Call Tracking', 'System', 'Call Routing', 'Integrations']

export const SALES_SUPPORT_FILTERED_ROUTE_PATHS = [
  'accounts',
  'cdm',
  'salesforce-profiles',
  'teams',
  'users',
  'roles',
  'roles(/)(/:id)',
  'dialer/settings',
  'dialer/support-settings',
  'dialer/sms-settings',
  'call-queues',
  'call-flows',
  'call-routing/:tab',
  'call-routing/call-flows',
  'call-routing/call-queues',
  'call-routing/holiday-calendar',
  'call-routing/user-skills',
  'dialer/call-recording',
  'smart-numbers',
  'dialer/skills',
  'dialer/blocked-numbers',
  'calls',
  'call-actions',
  'sales-methodology',
  'tcpa'
]
