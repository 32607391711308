import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function CallDurationByDayIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="call-duration-by-day-icon">
      <g clipPath="url(#clip0_9043_75586)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5262 52.0963C24.6793 52.0453 24.7826 51.902 24.7826
        51.7406V43.7439C24.7826 43.5718 24.6655 43.4218 24.4986 43.3801L20.0319 42.2634C19.9414 42.2408 19.8642
        42.1875 19.8164 42.1074C19.4056 41.4191 17.4456 37.7909 17.4456 31.2228C17.4456 24.6547 19.4056 21.0265
        19.8164 20.3382C19.8642 20.2581 19.9414 20.2048 20.0319 20.1822L24.4986 19.0656C24.6655 19.0238 24.7826
        18.8738 24.7826 18.7018V10.7051C24.7826 10.5436 24.6793 10.4003 24.5262 10.3493L17.6471 8.05626C17.5221
        8.0146 17.3902 8.03676 17.2941 8.12682C16.1479 9.20053 8.88586 16.5807 8.88586 31.2228C8.88586 45.865
        16.1479 53.2451 17.2941 54.3188C17.3902 54.4089 17.5221 54.431 17.6471 54.3894L24.5262 52.0963Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M30.4891 24.5652H29.1586C28.993 24.5652 28.8586 24.6995 28.8586 24.8652V38.1239C28.8586 38.2896
        28.993 38.4239 29.1586 38.4239H43.2326C43.3982 38.4239 43.5326 38.2896 43.5326 38.1239V24.8652C43.5326
        24.6995 43.3982 24.5652 43.2326 24.5652H41.9021"
          stroke={color}
          strokeLinecap="round"
        />
        <path d="M33.7499 24.5652H38.6412" stroke={color} strokeLinecap="round" />
        <path d="M32.1195 23.75V25.3804" stroke={color} strokeLinecap="round" />
        <path d="M40.2716 23.75V25.3804" stroke={color} strokeLinecap="round" />
        <path d="M28.8586 27.8261H43.5326" stroke={color} strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_9043_75586">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
