import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { callQueuesLiveColumns } from './const'
import { DELETE, NO_RESULT_STATE } from '../../const'
import React, { useCallback, useMemo, useState } from 'react'
import { CallQueueLiveProps } from '../../types'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import {useCallQueueLiveDelete, useCallQueuesLive} from '../../api'

export type CallQueueLiveTableProps = {
  accountId: number
}

export default function CallQueuesLiveTable({ accountId }: CallQueueLiveTableProps) {
  const [payload, , loading, refetch] = useCallQueuesLive({
    query: { accountId: accountId }
  })
  const deleteCallQueueLive = useCallQueueLiveDelete()
  const [deletedSids, setDeletedSids] = useState<string[]>([])
  const handleActionMenu = useCallback(
    (action: string, callQueue: CallQueueLiveProps) => {
      deleteCallQueueLive({ meta: { sid: callQueue.sid, accountId: accountId } })
      setDeletedSids([...deletedSids, callQueue.sid]) // We have some delay between start deleting members and get new state of queue on Twilio. This workaround will fix problem when we get the same active queues right after start removing members from queue.
    },
    [deleteCallQueueLive, deletedSids]
  )

  const memoPayload = useMemo(() => {
    return payload?.map((template: CallQueueLiveProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={[{ text: DELETE }]} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))?.filter((queue: CallQueueLiveProps) => !deletedSids?.includes(queue.sid))
  }, [payload, handleActionMenu, deletedSids])

  return (
    <>
      <RdnaSmartTable
        searchPlaceholder="Search"
        // @ts-ignore
        columns={callQueuesLiveColumns}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        isFetching={!!loading}
        paginationSize={10}
      />
    </>
  )
}
