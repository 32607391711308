import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'
import {TCPAEntity, PostParams} from '../types'


export const useTCPA = createUseFetch<TCPAEntity, { meta: { accountId: string } }>({
  key: 'get-tcpa',
  independent: true,
  path: params => `/api/v2/app/tcpa?accountId=${params.meta.accountId}`
})

export const useTCPAInsert = createUseFetchAction<any, PostParams>({
  key: 'post-tcpa',
  path: `/api/v2/app/tcpa`,
  method: HttpMethods.Post
})
