CheckboxHeaderBaseCell = class extends Backgrid.HeaderCell
  initialize: (options) ->
    super(options)
    { @column } = options
    @viewModel = new Backbone.Model(
      label: @column.get('label')
      checked: true
    )

  render: ->
    $(@el).html("""<a>#{@viewModel.get('label')}<b class="sort-caret"></b></a><input type="checkbox" />""")
    @$('input').prop('checked', @viewModel.get('checked'))
    @

class HeaderCell extends CheckboxHeaderBaseCell
  events:
    'click a': 'onClick'
    'click input': 'selectedAll'

  initialize: (options) ->
    super(options)
    @setCheckboxValues()
    @listenTo(@collection, "change:#{@column.get('name')} reset", @setCheckboxValues)

  selectedAll: (e) =>
    checked = e.target.checked
    @collection.fullCollection.models.forEach((model) => model.set("#{@column.get('name')}": checked))
    @setCheckboxValues()

  setCheckboxValues: =>
    checked = @collection.fullCollection.models.every((model) => model.get(@column.get('name')))
    @viewModel.set(checked: checked)
    @$('input').prop('checked', @viewModel.get('checked'))

CheckboxCell = class extends Backgrid.BooleanCell
  events: ->
    'change input': (e) ->
      @model.set(@column.get('name'), e.target.checked)

module.exports = class
  @HeaderCell: HeaderCell
  @CheckboxCell: CheckboxCell
