AdwordsModel = require('features/call-tracking/adwords/adwords-model')
BackgridRow     = require('helpers/backgrid-row')
BaseCollection  = require('base/collection')
BaseGrid = require('base/grid')
BaseModel = require('base/model')
BaseView = require('base/view')
ReportCollection = require('features/call-tracking/adwords/report-collection')
GoogleAccountCollection = require('features/call-tracking/adwords/google-account-collection')

class Step1Modal extends BaseView
  template: require('./step-1-template')

  regions:
    grid: 'div.grid'

  ui:
    managersPicker: '.managers'
    customersPicker: '.customers'

  bindings:
    '.view-type'    : 'value:uiViewTracking'
    '.segment-type' : 'value:segment, disabled: keywords'
    '.managers'     : 'value:managerId'
    '.customers'    : 'value:customerId'

  events:
    'change .view-type'    : 'reloadReport'
    'change .segment-type' : 'reloadReport'

  computeds:
    keywords:
      deps: ['viewTracking']
      get: (viewTracking) ->
        viewTracking is 'keywords'
    uiViewTracking:
      deps: ['viewTracking']
      get: (viewTracking) -> viewTracking
      set: (value) ->
        @viewModel.set(segment: 'none') if value is 'keywords'
        @viewModel.set(viewTracking: value)

  initialize: (options) =>
    { @accountId, @viewModel } = options
    @googleAccounts = new GoogleAccountCollection()
    @report = new ReportCollection()

  onRender: =>
    @reloadReport() if @viewModel.get('customerId')
    @listenTo(@viewModel, 'change:customerId', =>
      @collection.reset()
      @reloadReport()
    )
    @showSpinner($(@el), left: '45%')
    @googleAccounts.fetch(data: email: @viewModel.get('selectedEmail'))
      .done(@initGoogleManagerAccount)
      .always(=> @hideSpinner($(@el))
    )

  initGoogleManagerAccount: =>
    managers = @googleAccounts.models.map((account) -> {
      value: account.get('parentId')
      text: if account.get('parentName').length then account.get('parentName') else 'Manager Id: ' + account.get('parentId')
    })
    managers = _.uniq(managers, false, (model) => model.value)
    managers = _.sortBy(managers, 'text')
    @ui.managersPicker.selectize(
      allowEmptyOption: false
      selectOnTab: true
      maxItems: 1
      onChange: (value) =>
        return unless value
        @viewModel.set(managerId: value)
        @initGoogleCustomerAccount(value)
    )
    selectize = @ui.managersPicker.selectize()[0].selectize
    selectize.clear()
    selectize.clearOptions()
    selectize.addOption(managers)
    selectize.addItem(@selectAcoountId(managers, @viewModel.get('managerId')))

  initGoogleCustomerAccount: (managerId) =>
    managerCustomers = @googleAccounts.groupBy('parentId')
    customers = managerCustomers[managerId]?.map((account) -> {
      value: account.get('childId')
      text: if account.get('childName').length then account.get('childName') else 'Customer Id: ' + account.get('childId')
    })
    customers = _.sortBy(customers, 'text')
    @ui.customersPicker.selectize(
      allowEmptyOption: false
      selectOnTab: true
      maxItems: 1
      onChange: (value) =>
        return unless value
        @viewModel.set(customerId: value)
    )
    selectize = @ui.customersPicker.selectize()[0].selectize
    selectize.clear()
    selectize.clearOptions()
    selectize.addOption(customers)
    selectize.addItem(@selectAcoountId(customers, @viewModel.get('customerId')))

  selectAcoountId: (accounts, accountId) =>
    return unless accounts?.length
    if accounts.filter((model) => model.value is accountId).length
      accountId
    else accounts[0].value

  viewReport: =>
    @showSpinner($(@el), left: '45%')
    @report.reset()
    @report.persist(
      data:
        customerId: @viewModel.get('customerId')
        googleEmail: @viewModel.get('selectedEmail')
        viewTracking: @viewModel.get('viewTracking')
        segment: if @viewModel.get('segment') is 'none' then null else @viewModel.get('segment')
        reportIds: @collection.pluck('id')
    ).done().always(=>
      @hideSpinner($(@el))
      $('[data-toggle="tooltip"]').tooltip()
    )
    @showChildView('grid', @buildGrid(@report))

  reloadReport: =>
    return unless @viewModel.get('customerId')
    @detachChildView('grid')
    @viewReport()

  hasAdGroups: =>
    return @viewModel?.get('viewTracking') is 'adgroups' or
        @viewModel?.get('viewTracking') isnt 'adgroups' and @viewModel?.get('viewTracking') is 'keywords'

  hasKeywords: =>
    return @viewModel?.get('viewTracking') is 'keywords'

  hasSegment: =>
    return @viewModel.get('segment') is 'none'

  buildGrid: =>
    mainClass = @

    addTrackCell = class extends Backgrid.Cell
      events:
        'click a.add-tracking': 'add'
        'click a.remove-tracking': 'removeTrack'

      add: ->
        mainClass.collection.add(new AdwordsModel(
          adGroupId: @model.get('adGroupId')
          adWordCampaignId: @model.get('adWordCampaignId')
          channel: @model.get(mainClass.viewModel.get('viewTracking'))
          id: @model.get('adsId')
          googleAccountEmail: mainClass.viewModel.get('selectedEmail')
          extraInfo: @extraInfo()
          keywordId: @model.get('keywordId')
          matchTypeKeyword: @model.get('matchType')
          type: mainClass.viewModel.get('viewTracking')
        ))
        @showHtml(true)

      removeTrack: ->
        mainClass.collection.remove(mainClass.collection.findWhere(id: @model.get('adsId')))
        @showHtml(false)

      render: ->
        model = mainClass.collection.findWhere(id: @model.get('adsId'))
        @showHtml(model?)

      showHtml: (added) ->
        if not @model.get('isDeleted')
          html = if not added then """<a class='add-tracking'><span class='glyphicon glyphicon-plus-sign'></span></a>""" else
            """<a class='remove-tracking'><span class='glyphicon glyphicon-remove-sign'></span></a>"""
        else
          html = if @model.get('tracked') then """<span class='glyphicon glyphicon-ok'></span>""" else
            """<span class='glyphicon glyphicon-info-sign blue' data-toggle="tooltip" data-placement="top"
                    data-original-title="#{@selectedTooltip()}"/>"""
        $(@el).html(html)
        @

      selectedTooltip: =>
        switch mainClass.viewModel.get('viewTracking')
          when 'campaigns'
            return 'A child segment is already being tracked.'
          when 'adgroups'
            return 'A parent or child segment is already being tracked.'
          when 'keywords'
            return 'A parent segment is already being tracked.'

      extraInfo: =>
        info =
          campaign: @model.get('campaigns')
          customerId: mainClass.viewModel.get('customerId')
        if @model.get('adGroupId')
          info.adWordCampaignId = @model.get('adWordCampaignId')
          info.adGroup = @model.get('adgroups')
        if @model.get('keywordId')
          info.adGroupId = @model.get('adGroupId')
          info.keyword = @model.get('keywords')
          info.keywordMatchType = @model.get('matchType')
        return JSON.stringify(info)

    columns = [
      {label: 'Campaign',    name: 'campaigns',    cell: 'string'}
      {label: 'Segment',     name: 'segment',      cell: 'string',     renderable: not @hasSegment()}
      {label: 'Ad Group',    name: 'adgroups',     cell: 'string',     renderable: @hasAdGroups()}
      {label: 'Keyword',     name: 'keywords',     cell: 'string',     renderable: @hasKeywords()}
      {label: 'Cost',        name: 'cost',         cell: 'string'}
      {label: 'Conversions', name: 'conversions',  cell: 'string'}
      {label: 'Clicks',      name: 'clicks',       cell: 'string'}
      {label: 'Impressions', name: 'impressions',  cell: 'string'}
      {label: 'CTR',         name: 'ctr',          cell: 'string'}
      {label: 'First Page CPC', name: 'firstPageCPC', cell: 'string',  renderable: @hasKeywords()}
      {label: 'Match Type',  name: 'matchType',    cell: 'string',     renderable: @hasKeywords()}
      {label: 'Track',       name: 'track',        cell: addTrackCell, renderable: @hasSegment()}
    ]

    new BaseGrid.Grid(
      collection : @report,
      columns    : columns,
      emptyText  : 'No data found.'
      row        : BackgridRow.deleted()
    )

module.exports = Step1Modal
