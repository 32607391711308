import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PicklistData, usePlayPicklist } from './api'
import { useAtomValue } from 'jotai'
import { userDataAtom } from './index'

import { AlertType, ConversationEtiquette, NotificationCategories, NotifyType, TimerType } from '../../types/moments'
import { ReportFilterType } from './types'
import { inputStrings } from './strings'
import { InputTypes } from '../../constants/input-types'

import { createSimpleOptions } from '../RdnaFormElements/_helpers'
import { addAllOption, picklistDataToSelectOptions, WILDCARD, getDateString, getBlankValues } from './utils/inputUtils'
import { camelCaseToTitleCase } from '../../utils/stringUtils'
import { localTimeZone } from './utils/metabase'

import RdnaSelect, { RdnaSelectOptions } from '../RdnaFormElements/RdnaSelect'
import RdnaAutoComplete from '../RdnaFormElements/RdnaAutoComplete'
import RdnaDatePicker from '../RdnaFormElements/RdnaDatePicker'
import RdnaTextInput from '../RdnaFormElements/RdnaTextInput'
import RdnaSlider from '../RdnaFormElements/RdnaSlider'
import RdnaCheckbox from '../RdnaFormElements/RdnaCheckbox'
import RdnaText from '../RdnaText'
import { NotificationRuleData } from '../RdnaMomentsDrawer/utils/types'

export const CUSTOM_DATE_OPTION_DELIMITER = '~'

type SetValueFn = (name: ReportFilterType, value: unknown) => void

type OnChangeInputProps = {
  value?: any
  onChange: (value: any) => void
  name: ReportFilterType
  label: string
}

type SingleSelectInputProps = OnChangeInputProps & { options: RdnaSelectOptions<any>[] }

type SetValueInputProps = {
  value?: any
  setValue: SetValueFn
  name: ReportFilterType
}

type SearchInputProps = SetValueInputProps & { placeholder: string; label: string }

type SliderInputProps = SetValueInputProps & {
  label: string
  defaultValueKey: ReportFilterType
}

const CheckboxAutocomplete = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: RdnaSelectOptions<any>,
  { selected }: { selected: boolean }
) => {
  return (
    <li {...props} key={`${option.value}-${props.id}`}>
      <StyledListItem>
        <RdnaCheckbox value={selected} />
      </StyledListItem>
      <RdnaText>{option.label}</RdnaText>
    </li>
  )
}

interface MultipleAutocompleteInputProps extends SetValueInputProps {
  label: string
  placeholder: string
  apiType: string
  mapData?: (data: any) => PicklistData
  momentsEndpoint?: boolean
}

const MultipleAutocompleteInput = ({
  name,
  apiType,
  value,
  setValue,
  placeholder,
  label,
  mapData,
  momentsEndpoint
}: MultipleAutocompleteInputProps) => {
  const [options, setOptions] = useState<RdnaSelectOptions<any>[]>([])
  const userData = useAtomValue(userDataAtom)
  const [picklistData, , , refetch] = usePlayPicklist(
    {
      meta: { accountId: userData?.supportAccountId || userData?.accountId, name: '', type: apiType, momentsEndpoint }
    },
    { paused: true }
  )

  const normalizedData = mapData && picklistData ? mapData(picklistData) : picklistData

  useEffect(() => {
    if (normalizedData) {
      const selectOptions = picklistDataToSelectOptions(normalizedData, !value ? [] : value)
      setOptions(selectOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picklistData])

  const sortOptions = useCallback(() => {
    if (normalizedData) {
      const selectOptions = picklistDataToSelectOptions(normalizedData, value)
      setOptions(selectOptions)
    }
  }, [picklistData, setOptions, value])

  useEffect(() => {
    if (userData) {
      refetch()
    }
  }, [userData, refetch])
  return (
    <RdnaAutoComplete
      options={options}
      id={name}
      disableCloseOnSelect
      value={value ? (typeof value === 'string' ? [value] : value) : []}
      // @ts-ignore
      onChange={(e, newValue: RdnaSelectOptions[] | string[]) => {
        const mappedValue = newValue.map(item => (typeof item === 'string' ? item : item.value))
        setValue(name, mappedValue)
      }}
      onClose={sortOptions}
      placeholder={placeholder}
      label={label}
      multiple
      isOptionEqualToValue={(option: RdnaSelectOptions<any>, value: any) => {
        return option.value === value
      }}
      // @ts-ignore - TODO
      renderOption={CheckboxAutocomplete}
    />
  )
}

const SingleApiAutocompleteInput = ({
  name,
  apiType,
  value,
  setValue,
  placeholder,
  label
}: MultipleAutocompleteInputProps) => {
  const [options, setOptions] = useState<RdnaSelectOptions<any>[]>([])
  const userData = useAtomValue(userDataAtom)
  const [picklistData, , , refetch] = usePlayPicklist(
    {
      meta: { accountId: userData?.supportAccountId || userData?.accountId, name: '', type: apiType }
    },
    { paused: true }
  )

  useEffect(() => {
    if (picklistData) {
      const selectOptions = picklistDataToSelectOptions(picklistData, [])
      setOptions(selectOptions)
    }
  }, [picklistData, setOptions])

  useEffect(() => {
    if (userData) {
      refetch()
    }
  }, [userData, refetch])

  const mappedValue = value === WILDCARD ? '' : value
  return (
    <RdnaAutoComplete
      options={options}
      id={name}
      value={mappedValue || ''}
      onChange={(e, newValue: RdnaSelectOptions<any> | null) => {
        setValue(name, newValue?.value || WILDCARD)
      }}
      placeholder={placeholder}
      label={label}
      isOptionEqualToValue={(option: RdnaSelectOptions<any>, value: any) => {
        return option.value === value
      }}
    />
  )
}

const SingleSelectInput = ({ value, onChange, name, options, label }: SingleSelectInputProps) => (
  <RdnaSelect options={options} name={name} value={value} onChange={onChange} label={label} />
)

interface SingleAutoCompleteProps extends SetValueInputProps {
  label: string
  placeholder: string
  options: RdnaSelectOptions<any>[]
}

const SingleAutoComplete = ({ value, setValue, name, options, label, placeholder }: SingleAutoCompleteProps) => {
  const mappedValue = value === WILDCARD ? '' : value
  return (
    <RdnaAutoComplete
      options={options}
      id={name}
      value={mappedValue || ''}
      onChange={(e, newValue: RdnaSelectOptions<any> | null) => {
        setValue(name, newValue?.value || WILDCARD)
      }}
      placeholder={placeholder}
      label={label}
      isOptionEqualToValue={(option: RdnaSelectOptions<any>, value: any) => {
        return option.value === value
      }}
    />
  )
}

const SearchInput = ({ value, setValue, name, label, placeholder }: SearchInputProps) => {
  const mappedValue = value === WILDCARD ? '' : value
  return (
    <RdnaTextInput
      name={name}
      placeholder={placeholder}
      label={label}
      onChange={e => setValue(name, e.target.value || WILDCARD)}
      value={mappedValue || ''}
    />
  )
}

const SliderInput = ({ value, setValue, name, label, defaultValueKey }: SliderInputProps) => {
  const userData = useAtomValue(userDataAtom)
  const mapValues = (stringValues: string) => stringValues.split(',').map(element => parseInt(element))
  const [sliderMinValue, sliderMaxValue] = mapValues(
    Object.values(getBlankValues([defaultValueKey], false, userData))[0]
  )
  const mappedValues = mapValues(value as string)
  return (
    <StyledSlider
      inputData={{
        label,
        minValue: {
          value: sliderMinValue
        },
        maxValue: {
          value: sliderMaxValue
        },
        name: name,
        type: InputTypes.SLIDER
      }}
      value={mappedValues}
      onChange={(event, value) => {
        const stringValue = value.join()
        setValue(name, stringValue)
      }}
    />
  )
}

const DateInput = ({
  value,
  setValue,
  type,
  name
}: SetValueInputProps & {
  type: ReportFilterType.START_DATE | ReportFilterType.START_DATE_QUARTER | ReportFilterType.END_DATE
}) => {
  return (
    <RdnaDatePicker
      name={name}
      label={camelCaseToTitleCase(type.replace('Quarter', ''))}
      value={value}
      onChange={value => {
        if (value) {
          setValue(type, getDateString(value))
        }
      }}
    />
  )
}

const CallDisposition = ({ value, setValue }: SetValueInputProps) => {
  const [options, setOptions] = useState<RdnaSelectOptions<any>[]>([])
  const userData = useAtomValue(userDataAtom)
  const [picklistData, , , refetch] = usePlayPicklist(
    { meta: { accountId: userData?.supportAccountId || userData?.accountId, name: '', type: 'callDispositions' } },
    { paused: true }
  )

  useEffect(() => {
    if (picklistData) {
      const selectOptions = picklistDataToSelectOptions(picklistData, !value ? [] : value)
      setOptions(selectOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picklistData])

  const sortOptions = useCallback(() => {
    if (picklistData) {
      const selectOptions = picklistDataToSelectOptions(picklistData, value)
      setOptions(selectOptions)
    }
  }, [picklistData, setOptions, value])

  useEffect(() => {
    if (userData) {
      refetch()
    }
  }, [userData, refetch])
  return (
    <RdnaAutoComplete
      label={'Call Dispositions'}
      id={ReportFilterType.CALL_DISPOSITION_MULTIPLE}
      disableCloseOnSelect
      options={options}
      value={value || []}
      // @ts-ignore
      onChange={(e, newValue: RdnaSelectOptions<any>[] | string[]) => {
        const mappedValue = newValue.map(item => (typeof item === 'string' ? item : item.value))
        setValue(ReportFilterType.CALL_DISPOSITION_MULTIPLE, mappedValue)
      }}
      multiple
      onClose={sortOptions}
      placeholder={'Enter Call Disposition Here'}
      // @ts-ignore - TODO
      renderOption={CheckboxAutocomplete}
      isOptionEqualToValue={(option: RdnaSelectOptions<any>, value: any) => {
        return option.value === value
      }}
    />
  )
}

const getInputByName = (
  name: ReportFilterType,
  value: any,
  onChange: (value: any) => void,
  setValue: SetValueFn
): JSX.Element => {
  switch (name) {
    case ReportFilterType.AGENT:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'users'}
          placeholder={inputStrings.users.placeholderSingle}
          label={inputStrings.users.label}
        />
      )
    case ReportFilterType.AGENT_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'users'}
          placeholder={inputStrings.users.placeholder}
          label={inputStrings.users.label}
        />
      )
    case ReportFilterType.CALL_CATEGORY:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Category"
          options={addAllOption(
            createSimpleOptions([
              'Audio Hardware Issue',
              'Audio Latency',
              'Audio Software Issue',
              'Call Quality',
              'Choppy Robotic',
              'Dropped Call',
              'Dual tone multi-frequency (DTMF)',
              'Echo',
              'Incorrect Caller ID',
              'Invalid Number',
              'Low Volume',
              'No Connectivity Issue',
              'No Quality Issue',
              'Number Reachability',
              'One Way Audio',
              'Post-Dial Delay',
              'Static Noise',
              'Unsolicited Callers',
              'Other'
            ]),
            'All Categories'
          )}
        />
      )
    case ReportFilterType.CALL_DISPOSITION:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'callDispositions'}
          placeholder={inputStrings.callDisposition.placeholderSingle}
          label={inputStrings.callDisposition.label}
        />
      )
    case ReportFilterType.CALL_DISPOSITION_MULTIPLE:
      return <CallDisposition value={value} setValue={setValue} name={name} />
    case ReportFilterType.CALL_DURATION:
      return (
        <SliderInput
          value={value}
          setValue={setValue}
          name={name}
          label="Duration (minutes)"
          defaultValueKey={ReportFilterType.CALL_DURATION}
        />
      )
    case ReportFilterType.CALL_TYPE:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Call Type"
          options={addAllOption(createSimpleOptions(['Voicemail', 'Non Voicemail']), 'All')}
        />
      )
    case ReportFilterType.CALL_QUEUE_NAME:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'callQueues'}
          placeholder={inputStrings.callQueueName.placeholderSingle}
          label={inputStrings.callQueueName.label}
        />
      )
    case ReportFilterType.CALLER:
      return (
        <SearchInput
          value={value}
          setValue={setValue}
          name={name}
          label="Caller Name"
          placeholder="Enter Caller Name"
        />
      )
    case ReportFilterType.COACHING_DIRECTION:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Coaching Direction"
          options={[
            {
              value: 'coaching given',
              label: 'Coaching Given'
            },
            {
              value: 'coaching received',
              label: 'Coaching Received'
            }
          ]}
        />
      )
    case ReportFilterType.COACHING_TYPE:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Coaching Type"
          options={addAllOption(
            [
              {
                value: 'annotation',
                label: 'Annotation'
              },
              {
                value: 'play',
                label: 'Conversation Played'
              },
              {
                value: 'realtime-listen',
                label: 'Realtime Listen'
              }
            ],
            'All Type'
          )}
        />
      )
    case ReportFilterType.CONVERSATION_TYPE:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Conversation Type"
          options={addAllOption(
            [
              {
                value: 'audio',
                label: 'Audio Conversations'
              },
              {
                value: 'video',
                label: 'Video Conversations'
              }
            ],
            'All Conversations'
          )}
        />
      )
    case ReportFilterType.DIRECTION:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Direction"
          options={addAllOption(createSimpleOptions(['Inbound', 'Outbound']), 'All Directions')}
        />
      )
    case ReportFilterType.DISPOSITION_OUTCOME:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Disposition Outcome"
          options={addAllOption(createSimpleOptions(['Positive', 'Negative', 'Neutral']), 'All Outcomes')}
        />
      )
    case ReportFilterType.END_DATE:
      return <DateInput value={value} setValue={setValue} type={ReportFilterType.END_DATE} name={name} />
    case ReportFilterType.LOCATION:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'locations'}
          placeholder={inputStrings.locations.placeholder}
          label={inputStrings.locations.label}
        />
      )
    case ReportFilterType.FISCAL_PERIOD:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'fiscalPeriod'}
          placeholder={inputStrings.fiscalPeriod.placeholder}
          label={inputStrings.fiscalPeriod.label}
        />
      )
    case ReportFilterType.FROM_NUMBER:
      return (
        <SearchInput
          value={value}
          setValue={setValue}
          name={name}
          label="From Number"
          placeholder="Enter From Number"
        />
      )
    case ReportFilterType.KEYWORD_GROUP:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'keywordGroups'}
          placeholder={inputStrings.keywordGroup.placeholderSingle}
          label={inputStrings.keywordGroup.label}
        />
      )
    case ReportFilterType.MOMENTS_CONTENT_TYPE:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Content Type"
          options={addAllOption(createSimpleOptions(Object.values(NotifyType)), 'All Types')}
        />
      )
    case ReportFilterType.MOMENTS_CATEGORY:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Category"
          options={addAllOption(createSimpleOptions(Object.values(NotificationCategories)), 'All Categories')}
        />
      )
    case ReportFilterType.NOTIFICATION_NAMES:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'notification-rules'}
          mapData={data => {
            return data.map((item: NotificationRuleData) => {
              return { value: item.name, text: item.name }
            })
          }}
          placeholder={inputStrings.notificationNames.placeholder}
          label={inputStrings.notificationNames.label}
          momentsEndpoint
        />
      )
    case ReportFilterType.OPPORTUNITY_AMOUNT:
      return (
        <SliderInput
          value={value}
          setValue={setValue}
          name={name}
          label="Opportunity Amount"
          defaultValueKey={ReportFilterType.OPPORTUNITY_AMOUNT}
        />
      )
    case ReportFilterType.OPPORTUNITY_CLOSE_DATE: {
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Fiscal Period"
          options={addAllOption(
            createSimpleOptions([
              'This Fiscal Quarter',
              'Last Fiscal Quarter',
              'Next Fiscal Quarter',
              'This Month',
              'Next Month',
              'Last Month',
              'This Month + Next Month',
              'This Month + Next 2 Months',
              'Last + Current + Next Month',
              'This Month + Last 2 Months'
            ]),
            'All'
          )}
        />
      )
    }
    case ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE}
          placeholder={inputStrings[ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE].placeholder}
          label={inputStrings[ReportFilterType.OPPORTUNITY_FORECAST_CATEGORY_MULTIPLE].label}
        />
      )
    case ReportFilterType.OPPORTUNITY_NAME:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'opportunityNames'}
          placeholder="Enter Opportunity Name"
          label="Opportunity Name"
        />
      )
    case ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE}
          placeholder={inputStrings[ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE].placeholder}
          label={inputStrings[ReportFilterType.OPPORTUNITY_OWNER_MULTIPLE].label}
        />
      )
    case ReportFilterType.OPPORTUNITY_STAGE_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'opportunityStage'}
          placeholder={inputStrings.opportunityStage.placeholder}
          label={inputStrings.opportunityStage.label}
        />
      )
    case ReportFilterType.OPPORTUNITY_TYPE:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'opportunityType'}
          placeholder={inputStrings.opportunityType.placeholder}
          label={inputStrings.opportunityType.label}
        />
      )
    case ReportFilterType.OPPORTUNITY_TYPE_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'opportunityType'}
          placeholder={inputStrings.opportunityType.placeholder}
          label={inputStrings.opportunityType.label}
        />
      )
    case ReportFilterType.OPPORTUNITY_WIN_PROBABILITY:
      return (
        <SliderInput
          value={value}
          setValue={setValue}
          name={name}
          label="SF Win Probability"
          defaultValueKey={ReportFilterType.OPPORTUNITY_WIN_PROBABILITY}
        />
      )
    case ReportFilterType.RELATIONSHIP:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Relationship"
          options={addAllOption(
            [{ value: 'Manager', label: 'Supervisor' }, ...createSimpleOptions(['Peer', 'Self'])],
            'All'
          )}
        />
      )
    case ReportFilterType.SPEAKER_TYPE:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Speaker"
          options={addAllOption(createSimpleOptions(['Agent', 'Participant']), 'Anyone')}
        />
      )
    case ReportFilterType.START_DATE:
      return <DateInput value={value} setValue={setValue} type={ReportFilterType.START_DATE} name={name} />
    case ReportFilterType.START_DATE_QUARTER:
      return <DateInput value={value} setValue={setValue} type={ReportFilterType.START_DATE_QUARTER} name={name} />
    case ReportFilterType.TEAM:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'teams'}
          placeholder={inputStrings.teams.placeholderSingle}
          label={inputStrings.teams.label}
        />
      )
    case ReportFilterType.SUPERVISOR_NAME:
      return (
        <SingleApiAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'supervisor'}
          placeholder={inputStrings.supervisor.placeholder}
          label={inputStrings.supervisor.label}
        />
      )
    case ReportFilterType.TEAM_MULTIPLE:
      return (
        <MultipleAutocompleteInput
          value={value}
          setValue={setValue}
          name={name}
          apiType={'teams'}
          placeholder={inputStrings.teams.placeholder}
          label={inputStrings.teams.label}
        />
      )
    case ReportFilterType.TO_NUMBER:
      return (
        <SearchInput value={value} setValue={setValue} name={name} label="To Number" placeholder="Enter To Number" />
      )
    case ReportFilterType.TRANSFER_NUMBER:
      return (
        <SearchInput
          value={value}
          setValue={setValue}
          name={name}
          label="Transfer Number"
          placeholder="Enter Transfer Number"
        />
      )
    case ReportFilterType.TRIGGERING_CRITERIA:
      return (
        <SingleSelectInput
          value={value || WILDCARD}
          onChange={onChange}
          name={name}
          label="Triggering Criteria"
          options={[
            {
              value: WILDCARD,
              label: 'All'
            },
            {
              value: AlertType.INCLUDES,
              label: 'Phrases ARE Mentioned'
            },
            {
              value: AlertType.EXCLUDES,
              label: 'Phrases are NOT Mentioned'
            },
            {
              value: ConversationEtiquette.AGENT_TALK_RATE,
              label: 'Agent Talk Rate'
            },
            {
              value: ConversationEtiquette.AGENT_INTERRUPTIONS,
              label: 'Agent Interruptions'
            },
            {
              value: ConversationEtiquette.AGENT_MONOLOGUES,
              label: 'Agent Monologues'
            },
            {
              value: TimerType.TIME,
              label: 'Time'
            }
          ]}
        />
      )
    case ReportFilterType.TIMEZONE:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Time Zone"
          options={[
            { label: 'User Time Zone', value: localTimeZone },
            { label: 'UTC', value: 'UTC' }
          ]}
        />
      )
    case ReportFilterType.USER_STATUS:
      return (
        <SingleAutoComplete
          value={value}
          setValue={setValue}
          name={name}
          label={inputStrings.userStatus.label}
          placeholder={inputStrings.userStatus.placeholder}
          options={createSimpleOptions(['Available', 'OnCall', 'Busy', 'Offline'])}
        />
      )
    case ReportFilterType.VOICEMAIL:
      return (
        <SingleSelectInput
          value={value}
          onChange={onChange}
          name={name}
          label="Call Type"
          options={[
            { label: 'Non Voicemail', value: '0' },
            { label: 'Voicemail', value: '1' }
          ]}
        />
      )
    default:
      return <div></div>
  }
}

export default getInputByName

const StyledListItem = styled.div`
  .MuiFormControl-root {
    height: ${({ theme }) => theme.spacing * 4}px;
    margin-top: 0;
  }
  .MuiCheckbox-root {
    padding: ${({ theme }) => `${theme.spacing * 0.5}px ${theme.spacing * 1.5}px`};
  }
  svg {
    font-size: 1rem;
  }
`

const StyledSlider = styled(RdnaSlider)`
  legend {
    transform: scale(0.75);
    transform-origin: left;
    top: ${({ theme }) => theme.spacing * -2}px;
  }
  .MuiFormGroup-root {
    margin-top: ${({ theme }) => theme.spacing * -3}px;
  }
`
