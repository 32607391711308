var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<select class=\"selector ugv\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":57}}}) : helper)))
    + "\" data-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"nameId") || (depth0 != null ? lookupProperty(depth0,"nameId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameId","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":79}}}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"nameType") || (depth0 != null ? lookupProperty(depth0,"nameType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameType","hash":{},"data":data,"loc":{"start":{"line":1,"column":92},"end":{"line":1,"column":104}}}) : helper)))
    + "\"></select>";
},"useData":true});