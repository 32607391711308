import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function UserIcon({ size = 30, color = Colors.N100, title, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="user-icon">
      <title>{title}</title>
      <path d="M16 7.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" stroke={color} strokeWidth={strokeWidth} />
      <path
        clipRule="evenodd"
        d="M4.5 21h15l1.5-2.8S18.75 14 12 14s-9 4.2-9 4.2L4.5 21Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default UserIcon
