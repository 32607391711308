# FIXME: Reconcile with helpers/backgrid

Grid = class extends Backgrid.Grid
  initialize: (options) =>
    super(options)

    @channel = Backbone.Radio.channel("grid-#{@cid}")
    @channel.on('add', options.onAdd)
    @channel.on('edit', options.onEdit)
    @channel.on('remove', options.onRemove)
    @channel.on('moveOne', options.onMoveOne)
    @channel.on('moveBound', options.onMoveBound)

    channelize = (e) => e.channel = @channel
    @columns.each(channelize)
    @header.columns.each(channelize)

  destroy: (options) =>
    @channel.reset()

AddCell = class extends Backgrid.HeaderCell
  events:
    'click a.add': 'add'

  add: =>
    @column.channel.trigger('add', { collection: @collection })

  render: ->
    $(@el).html('''
      <a class="add">
        <span class="glyphicon glyphicon-plus-sign"></span>
      </a>
    ''')
    @

DeleteCell = class extends Backgrid.Cell
  className: 'action-cell'
  events:
    'click a.delete': 'delete'

  delete: ->
    bootbox.confirm('Are you sure you want to delete this?', (ok) =>
      if ok
        response = @model.destroy(data: accountId: @model.get('accountId'))
        if response then response.done(=> @deleteCallback(@column)) else @deleteCallback(@column)
      true
    )

  render: ->
    deletable = @model.get('deletable') ? true
    if deletable
      $(@el).html('''
        <a class="delete">
          <span class="glyphicon glyphicon-remove-sign"></span>
        </a>
      ''')
    @

  deleteCallback: (column) =>
    column.channel?.trigger('remove')

EditCell = class extends Backgrid.Cell
  className: 'action-cell'
  events:
    'click a.edit': 'edit'

  edit: =>
    @column.channel.trigger('edit', { model: @model })

  render: ->
    $(@el).html('''
      <a class="edit">
        <span class="glyphicon glyphicon-edit"></span>
      </a>
    ''')
    @

ListenAudioCell = class extends Backgrid.Cell
  className: 'action-cell'
  events:
    'click [data-action="play"]': 'togglePlay'

  togglePlay: ->
    audio = @$("#audio-#{@model.id}")[0]
    @stopPlayers() if audio.paused
    button = @$('button[data-action="play"]')[0]
    button.className = ''
    if audio.paused
      audio.play()
      button.className += 'btn btn-danger stop'
    else
      audio.pause()
      audio.currentTime = 0
      button.className += 'btn btn-success play'

    audio.onpause = ->
      button.className = 'btn btn-success play'

  stopPlayers: ->
    audios = $('audio')
    for audio in audios
      audio.pause()
      audio.currentTime = 0

  render: ->
    $(@el).html('''
      <audio id="audio-''' + @model.id + '''" preload="none" src="''' + @model.get('url') + '''">
      </audio>
      <button class="btn btn-success play update-voicemail" data-action="play"></button>
    ''')
    @

EditAudioCell = class extends Backgrid.Cell
  className: 'action-cell'
  events:
    'click button[data-action="update"]': 'edit'

  edit: =>
    @column.channel.trigger('edit', { model: @model })

  render: ->
    if not @model.get('isDefault')
      $(@el).html('''
        <button class="btn btn-primary update-voicemail" data-action="update" data-toggle="modal" data-target="#''' + @column.get('name') + '''">
          Update
        </button>
      ''')
    @

OrderCell = class extends Backgrid.Cell
  className: 'order-cell'

  initialize: (options) =>
    super(options)
    @viewModel = new Backbone.Model(
      mouseIsDown: false
      moveOneVMDrops: true
    )
    # force re-render to ensure order bounds are respected
    if @column.get('bounds')
      @listenTo(@model.collection, 'add', @render)
      @listenTo(@model.collection, 'remove', @render)

  events:
    'click span[data-action="arrow-up"]': 'up'
    'click span[data-action="arrow-down"]': 'down'
    'mousedown span[data-action="arrow-up"]': 'pressUp'
    'mouseup span[data-action="arrow-up"]': 'notPress'
    'mousedown span[data-action="arrow-down"]': 'pressDown'
    'mouseup span[data-action="arrow-down"]': 'notPress'

  up: ->
    @moveOne(true)

  down: ->
    @moveOne(false)

  pressUp: =>
    @press(true)

  pressDown: =>
    @press(false)

  notPress: =>
    @viewModel.set('mouseIsDown', false)

  moveOne: (move) =>
    if @column.get('bounds')
      idx = @model.collection.indexOf(@model)
      [startIdx, endIdx] = @column.get('bounds')
      return if (idx < startIdx || idx > endIdx)
    @column.channel.trigger('moveOne', { model: @model, move: move }) if @viewModel.get('moveOneVMDrops')
    @viewModel.set('moveOneVMDrops', true)

  moveTopOrBottomOfList: (move, popover) =>
    @column.channel.trigger('moveBound', { model: @model, move: move })
    @$(popover).popover('hide')
    @viewModel.set('moveOneVMDrops', true)

  press: (move) =>
    popover = "[data-toggle=\"#{if move then 'popoverTop' else 'popoverBottom'}\"]"
    @viewModel.set('mouseIsDown', true)
    setTimeout(=>
      if @viewModel.get('mouseIsDown')
        @viewModel.set('moveOneVMDrops', false)
        @$(popover).popover('show')
        $('.popover').click(=> @moveTopOrBottomOfList(move, popover))
      else
        @$(popover).popover('destroy')
    , 500)

  render: =>
    upDisabledAttr = ''
    downDisabledAttr = ''
    if @column.get('bounds')
      idx = @model.collection.indexOf(@model)
      [startIdx, endIdx] = @column.get('bounds')
      upDisabledAttr = if idx == startIdx then 'disabled' else ''
      downDisabledAttr = if idx == endIdx then 'disabled' else ''
    html = '''<div class="row"><div class="col-md-3 drops order-button" ''' + upDisabledAttr + '''>
               <span class='glyphicon glyphicon-menu-up' data-action="arrow-up" aria-hidden="true"
                data-placement="top" data-toggle="popoverTop" data-content='<a>Send to top</a>'
                data-html="true"></span></div>
              <div class="col-md-3 drops order-button" ''' + downDisabledAttr + '''>
                <span class="glyphicon glyphicon-menu-down" data-action="arrow-down" aria-hidden="true"
                  data-toggle="popoverBottom" data-placement="bottom" data-content="<a>Send to bottom</a>"
                  data-html="true"></span></div></div>'''
    $(@el).html(html)
    @

module.exports = class
  @Grid       : Grid
  @AddCell    : AddCell
  @DeleteCell : DeleteCell
  @EditCell   : EditCell
  @OrderCell  : OrderCell
  @ListenAudioCell: ListenAudioCell
  @EditAudioCell  : EditAudioCell
