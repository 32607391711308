BaseView        = require('base/view')
BaseCollection  = require('base/collection')
BaseGrid        = require('base/grid')

class NewNumbersCollection extends BaseCollection
  url: 'localPresence/numbers'

  parse: (response) ->
    for number in response
      number.twilioType = number.type
    response

class ProvidedAddressesCollection extends BaseCollection
  url: 'smartNumbers/providedAddresses'

class Step2CreatingModal extends BaseView
  template: require('./step-2-template')

  regions:
    newNumbersGrid : '.new-numbers-list'

  bindings:
    '.next' : 'disabled: disabledNext'

  computeds:
    disabledNext:
      deps: ['selectedNumbers']
      get: (selectedNumbers) ->
        not selectedNumbers.length

  onRender: ->
    @model.set('addressRequired', false)
    @availableNewNumbers = @model.get('availableNewNumbers')
    if @availableNewNumbers?.length
      @detachChildView('newNumbersGrid')
      @showChildView('newNumbersGrid', @buildGrid())
    else
      @availableNewNumbers = new NewNumbersCollection()
      @availableNewNumbers.fetch(
        data:
          accountId   : @model.get('accountId')
          areaCode    : @model.get('areaCode')
          capability  : @model.get('capability')
          country     : @model.get('country')
          searchTerm  : @model.get('searchTerm')
      ).done( (response) =>
        return unless @getRegion('newNumbersGrid')
        @model.set('availableNewNumbers': @availableNewNumbers)
        @detachChildView('newNumbersGrid')
        @showChildView('newNumbersGrid', @buildGrid())
      )
    @providedAddresses = new ProvidedAddressesCollection()
    @providedAddresses.fetch(
      data:
        accountId   : @model.get('accountId')
    ).done( =>
      @model.set('providedAddresses', @providedAddresses)
    )

  buildGrid: =>
    selectNewCell = @selectNewCell(@model.get('selectedNumbers'))
    cols = [
      {label: 'Number',               name: 'number',              cell: 'string'}
      {label: 'Voice Enabled',        name: 'voiceEnabled',        cell: 'string'}
      {label: 'SMS Enabled',          name: 'smsEnabled',          cell: 'string'}
      {label: 'Domestic Calls Only',  name: 'domesticCallsOnly',   cell: 'string'}
      {label: 'Domestic SMS Only',    name: 'domesticSMSOnly',     cell: 'string'}
      {label: 'Address Requirements', name: 'addressRequirements', cell: 'string'}
      {label: '',                     name: '',                    cell: selectNewCell}
    ]

    new BaseGrid.Grid(
      collection : @availableNewNumbers
      columns    : cols
      emptyText  : 'No numbers found.'
    )

  selectNewCell: (selectedNewNumbers) =>
    mainClass = @
    class extends Backgrid.Cell
      className: 'select-new-cell'
      events:
        'click .add': 'addToSelected'
        'click .remove': 'removeFromSelected'
      addToSelected: ->
        @$('.add').hide()
        @$('.remove').show()
        model = @model.clone()
        switch mainClass.model.get('type')
          when 'account'
            type = 'CentralNumber'
            break
          when 'call-flow'
            type = 'Tracking'
          when 'queue'
            $('.add').prop('disabled', true)
            type = 'Default'
          else
            type = ''
        model.set(type: type)
        selectedNewNumbers.add(model)
        mainClass.checkRequiredAddress(model)
        mainClass.applyBindings()
      removeFromSelected: ->
        @$('.remove').hide()
        @$('.add').show()
        selectedNewNumbers.remove(selectedNewNumbers.findWhere(number: @model.get('number')))
        $('.add').prop('disabled', false)
        mainClass.applyBindings()
      render: ->
        $(@el).html('''
            <button class="btn btn-xs btn-primary add">Add</button>
            <button class="btn btn-xs btn-danger remove">Remove</button>
        ''')
        selected = selectedNewNumbers.findWhere(number: @model.get('number'))
        @$('.add').prop('disabled', mainClass.model.get('type') is 'queue' and mainClass.model.get('selectedNumbers').length)
        if selected then @$('.add').hide() else @$('.remove').hide()
        @

  checkRequiredAddress: (number) =>
    switch number.get('addressRequirements')
      when 'local'
        @model.set(addressLocalRequired: true) unless @providedAddresses.findWhere(countryCode: @model.get('country'))
      when 'foreign'
        @model.set(addressForeignRequired: true) unless @providedAddresses.filter((address) => address.get('countryCode') isnt @model.get('country'))?.length
      when 'any'
        @model.set(addressAnyRequired: true) if (not @providedAddresses.length and
          (not @model.get('addressLocalRequired') or not @model.get('addressForeignRequired')))

module.exports = Step2CreatingModal
