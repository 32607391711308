import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function SmsIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 20" fill="none">
      <rect x={6.375} y={6.375} width={11.25} height={0.75} rx={0.375} stroke={color} strokeWidth={0.75} />
      <path d="M6.5 10.75a.25.25 0 01.25-.25h6.5a.25.25 0 110 .5h-6.5a.25.25 0 01-.25-.25z" stroke={color} />
      <path
        clipRule="evenodd"
        d="M8.117 1h7.765C19.813 1 23 4.283 23 8.333c0 4.05-3.187 7.334-7.118 7.334L12.647 19v-3.333h-4.53C4.187 15.667 1 12.383 1 8.333S4.186 1 8.117 1z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmsIcon
