BackgridCell        = require('helpers/backgrid-cell')
BaseGrid            = require('base/grid')
BaseView            = require('base/view')
CampaignModal       = require('./modals/main-view')
CampaignModel       = require('./model')

class CampaignView extends BaseView

  template: require('./template')
  className: 'campaign-view'

  regions:
    sfCampaign: 'div.sf-campaign'
    modal: 'div.sf-campaigns-modals-container'

  ui:
    addSfCampaign: 'a.add-sf-campaign'

  bindings:
    'a.add-sf-campaign': "classes:{'disabled':hasCampaign}"

  events:
    'click @ui.addSfCampaign': 'addSfCampaign'

  computeds:
    hasCampaign:
      get: ->
        @campaignCollection.length isnt 0

  initialize: (options) ->
    { @campaignCollection, @accountId, @smartNumberId} = options
    @listenTo(@campaignCollection, 'add remove reset', ->
      @applyBindings()
    )
    @viewModel = new Backbone.Model(
      campaignId: null
    )

  onRender: =>
    @campaignView = @buildCallFlowGrid(@campaignCollection)
    @showChildView('sfCampaign', @campaignView)

  addSfCampaign: =>
    view = new CampaignModal(
      accountId: @accountId
      viewModel: @viewModel
      model: new CampaignModel()
      smartNumberId: @smartNumberId
    )
    @showChildView('modal', view)
    @listenTo(view, 'save', (campaignId) =>
      @trigger('addCampaign', campaignId)
    )

  buildCallFlowGrid: (collection) ->
    CampaignDeleteCell = class extends BaseGrid.DeleteCell
      delete: ->
        bootbox.confirm('Are you sure you want to remove this Salesforce Campaign from this smart number?', (ok) =>
          @model.destroy() if ok
          true
        )
    cols = [
      {label: 'Name',         name: 'name',         cell: 'string'}
      {label: 'Status',       name: 'status',       cell: 'string'}
      {label: 'Delete',       name: '',             cell: CampaignDeleteCell}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'There are no Salesforce Campaigns assigned.'
    )


module.exports = CampaignView