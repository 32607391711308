class ToastView extends Marionette.View
  template: require('./toasts-template')
  tagName: 'li'

class ToastsView extends Marionette.CollectionView
  tagName: 'ul'
  className: 'list-unstyled mb-0'
  childView: ToastView
  initialize: (options) ->
    @populate(options.user)

  populate: (user) ->
    @collection = new Backbone.Collection(user?.get('alerts'))

module.exports = ToastsView
