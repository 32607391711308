import React from 'react'
import { TabProps } from '../../types'
import CallDispositions from './sections/CallDispositions'
import ManageCallDispositions from './sections/ManageCallDispositions'
import {useCallDispositions} from '../../api'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'

export default function ManageCallDispositionsTab({ accountId, accountSettings, setAccountSettings }: TabProps) {
  const [payload, , loading, refetch] = useCallDispositions({meta:{accountId: accountId.toString()}})

  return (
    <RdnaLoader loading={!!loading} data="data" top="150px">
      <CallDispositions
        accountId={accountId}
        accountSettings={accountSettings}
        setAccountSettings={setAccountSettings}
        dispositions={payload || []}
        refetchDispositions={refetch}
      />
      <ManageCallDispositions
        accountId={accountId}
        accountSettings={accountSettings}
        setAccountSettings={setAccountSettings}
        dispositions={payload || []}
        refetchDispositions={refetch}
      />
    </RdnaLoader>
  )
}
