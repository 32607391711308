//
// This code is courtesy of our Bootstrap theme.  Meth squirrel would be proud.
//

const $BODY = $('body'),
  $MENU_TOGGLE = $('#menu-toggle'),
  $MENU_TOGGLE_NEW = $('#menu-toggle-new'),
  $SIDEBAR_MENU = $('#sidebar-menu'),
  $LEFT_COL = $('.left-col'),
  $RIGHT_COL = $('.right-col'),
  $TOP_NAV = $('.top-nav'),
  $FOOTER = $('footer'),
  windowHeight = $(window).height(),
  navHeight = $('.nav-menu').height(),
  footerHeight = $FOOTER.outerHeight(),
  ACTIVE_CLASS = 'active',
  ACTIVE_SMALL_CLASS = 'active-sm',
  CHEVRON_UP_CLASS = 'fa-chevron-up',
  CHEVRON_DOWN_CLASS = 'fa-chevron-down',
  OPEN_GROUP_CLASS = 'open-group'

// Sidebar
$(document).ready(function () {
  const isSmallMenu = function () {
    return !$BODY.hasClass('nav-md')
  }

  const setBackgridHeight = function () {
    let totalHeight = 0
    const backcontHeight = $('.main-grid').outerHeight()

    $('#main > *').each(function () {
      totalHeight = totalHeight + $(this).height()
    })
    totalHeight = totalHeight - backcontHeight + navHeight + footerHeight
    backgridHeight =
      windowHeight <= $LEFT_COL.eq(1).height()
        ? $LEFT_COL.eq(1).height() - (totalHeight + 20)
        : windowHeight - (totalHeight + 20)

    if ($('.backgrid-container').length) {
      $('.backgrid-container').css('height', backgridHeight)
    }
  }

  const openGroupMenu = function (element) {
    element.find('span.fa').toggleClass(`${CHEVRON_DOWN_CLASS} ${CHEVRON_UP_CLASS}`)
    element.addClass(OPEN_GROUP_CLASS)
    $('ul:first', element).slideDown(function () {
      setBackgridHeight()
      setContentHeight()
    })
  }

  const closeAllGroupMenu = function () {
    $SIDEBAR_MENU.find('span.fa').removeClass(`${CHEVRON_UP_CLASS}`).addClass(`${CHEVRON_DOWN_CLASS}`)
    $SIDEBAR_MENU.find('li').removeClass(OPEN_GROUP_CLASS)
    $SIDEBAR_MENU.find('li ul').slideUp()
  }

  const closeGroupMenu = function (element) {
    element.find('span.fa').toggleClass(`${CHEVRON_DOWN_CLASS} ${CHEVRON_UP_CLASS}`)
    element.removeClass(OPEN_GROUP_CLASS)
    $('ul:first', element).slideUp(function () {
      setBackgridHeight()
      setContentHeight()
    })
  }

  // TODO: This is some kind of easy fix, maybe we can improve this
  const setContentHeight = function () {
    const leftColHeight = $LEFT_COL.eq(1).height()
    const contentHeight = windowHeight < leftColHeight ? leftColHeight : windowHeight - 61
    $RIGHT_COL.css('min-height', contentHeight)
  }

  const setActiveTab = function () {
    const hash = window.location.hash.split('?')[0]
    let href = hash.split('/')
    if (isNaN(href[1])) {
      href = hash
    } else {
      href = href[0] //the hash contains an identifier, get the first element
    }
    let parent = $(`a[href="${href}"]`).parent()
    if (parent[0] == null && href.includes('accounts')) {
      parent = $(`a[href="#accounts/me"]`).parent()
    }
    if (href.includes('call-routing')) {
      parent = $('a[href^="#call-routing"]').parent()
    }
    if (hash.includes('moments-notifications')) {
      parent = $(`a[href="#moments"]`).parent()
    }
    const selectedClass = isSmallMenu() ? ACTIVE_SMALL_CLASS : ACTIVE_CLASS
    if (parent[0] != null && !parent.hasClass(selectedClass)) {
      closeAllGroupMenu()
      $SIDEBAR_MENU.find('li').removeClass(`${ACTIVE_CLASS} ${ACTIVE_SMALL_CLASS}`)
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const routes = require('@ringdna/common/src/constants/routes.json')
      const finder = _.findWhere(routes, { path: href.slice(1) })
      const groupLi = parent.parent().parent()
      if (finder != null && finder.group != null && !groupLi.hasClass(OPEN_GROUP_CLASS) && !isSmallMenu()) {
        openGroupMenu(groupLi)
      }
      parent.addClass(selectedClass)
    }
  }

  $SIDEBAR_MENU.find('a').on('click', function () {
    if ($(this).hasClass('external-link')) return
    const $li = $(this).parent()
    let selectedClass = ACTIVE_CLASS
    if (isSmallMenu()) {
      selectedClass = ACTIVE_SMALL_CLASS
    }
    if ($li.find('ul').is('.child-menu')) {
      if ($li.is(`.${OPEN_GROUP_CLASS}`)) {
        closeGroupMenu($li)
      } else {
        closeAllGroupMenu()
        openGroupMenu($li)
      }
    } else if (!$li.hasClass(selectedClass)) {
      $SIDEBAR_MENU.find('li').removeClass(`${ACTIVE_CLASS} ${ACTIVE_SMALL_CLASS}`)
      // prevent closing menu if we are on child menu
      if (!$li.parent().is('.child-menu')) {
        closeAllGroupMenu()
      } else if (isSmallMenu()) {
        $SIDEBAR_MENU.find('li').removeClass(OPEN_GROUP_CLASS)
        $SIDEBAR_MENU.find('li ul').slideUp()
      }
      $li.addClass(selectedClass)
    }
  })

  $RIGHT_COL.on('click', function () {
    if (isSmallMenu()) {
      closeAllGroupMenu()
    }
  })

  $TOP_NAV.on('click', function () {
    if (isSmallMenu()) {
      closeAllGroupMenu()
    }
  })

  // toggle small or large menu
  $MENU_TOGGLE.on('click', function () {
    if (!$BODY.hasClass('conversation-ai')) {
      if (!isSmallMenu()) {
        $SIDEBAR_MENU.find('ul.child-menu').find(`li.${ACTIVE_CLASS}`).parent().hide()
        $SIDEBAR_MENU.find(`li.${ACTIVE_CLASS}`).toggleClass(`${ACTIVE_SMALL_CLASS} ${ACTIVE_CLASS}`)
      } else {
        $SIDEBAR_MENU.find('ul.child-menu').find(`li.${ACTIVE_SMALL_CLASS}`).parent().show()
        $SIDEBAR_MENU.find(`li.${ACTIVE_SMALL_CLASS}`).toggleClass(`${ACTIVE_CLASS} ${ACTIVE_SMALL_CLASS}`)
      }
      $BODY.toggleClass('nav-md nav-sm')
    } else {
      _.defer(function () {
        $(window).trigger('resize.vbs_resize')
        window.dispatchEvent(new Event('resize'))
      })

      $LEFT_COL.eq(0).toggle()
      $RIGHT_COL.toggleClass('no-global-nav')
      $TOP_NAV.toggleClass('no-global-nav')
      $FOOTER.toggleClass('no-global-nav')
    }

    setBackgridHeight()
    setContentHeight()
  })

  // toggle small or large menu
  $MENU_TOGGLE_NEW.on('click', function () {
    if (!isSmallMenu()) {
      $SIDEBAR_MENU.find('ul.child-menu').find(`li.${ACTIVE_CLASS}`).parent().hide()
      $SIDEBAR_MENU.find(`li.${ACTIVE_CLASS}`).toggleClass(`${ACTIVE_SMALL_CLASS} ${ACTIVE_CLASS}`)
    } else {
      $SIDEBAR_MENU.find('ul.child-menu').find(`li.${ACTIVE_SMALL_CLASS}`).parent().show()
      $SIDEBAR_MENU.find(`li.${ACTIVE_SMALL_CLASS}`).toggleClass(`${ACTIVE_CLASS} ${ACTIVE_SMALL_CLASS}`)
    }
    $BODY.toggleClass('nav-md nav-sm')
    if ($BODY.hasClass('conversation-ai')) {
      _.defer(function () {
        $(window).trigger('resize.vbs_resize')
        window.dispatchEvent(new Event('resize'))
      })
    }

    setBackgridHeight()
    setContentHeight()
  })

  $(window).resize(function () {
    setContentHeight()
    setBackgridHeight()
  })

  $(window).bind('hashchange', function (e) {
    setContentHeight()
    setBackgridHeight()
    $(window).scrollTop(0)
  })

  $(window).bind('popstate', function () {
    setActiveTab()
  })

  // fixed sidebar
  if ($.fn.mCustomScrollbar) {
    $('.menu_fixed').mCustomScrollbar({
      autoHideScrollbar: true,
      theme: 'minimal',
      mouseWheel: { preventDefault: true }
    })
  }
  setBackgridHeight()
  setContentHeight()
  setActiveTab()
})
