// @ts-nocheck
import React, { useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import {
  TableOptions,
  CellProps,
  Column,
  FilterProps,
  Filters,
  HeaderGroup,
  Row,
  SortingRule,
  TableState,
  UsePaginationInstanceProps,
  UseFiltersInstanceProps,
  UseRowSelectInstanceProps,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  UseSortByInstanceProps
} from 'react-table'

import { TableProps } from './RdnaTable'
import { useDebounce } from '../../hooks/useDebounce'
import useId from '../../hooks/useId'

import CircularProgress from '@mui/material/CircularProgress'
import TableSortLabel from '@mui/material/TableSortLabel'
import EmptyTranscript from '../../assets/illustrations/EmptyTranscript'
import Pagination from '../RdnaPagination'
import RdnaSelect, { RdnaSelectProps } from '../RdnaFormElements/RdnaSelect'
import RdnaTextInput from '../RdnaFormElements/RdnaTextInput'
import RdnaText from '../RdnaText'
import RdnaRowCollapseEnhancer from './RdnaRowCollapseEnhancer'
import RdnaLoader from '../RdnaLoader'
import { StyledCheckbox } from './styles'
import RdnaButton from '../RdnaButton'
import SixDots from '../../assets/icons/svg/SixDots'
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from '../RdnaDragNDrop'

export * from 'react-table'

export type RuleSort<T> = SortingRule<T>

export type RuleFilters<T> = Filters<T>

export type TableFilterProps<T> = FilterProps<T>

export type TableCellProps<T> = CellProps<T>

export type TableSortingRule<T> = SortingRule<T>

export type Columns<T extends Record<string, unknown>> = (Column<T> & {
  disableSortBy?: boolean
  disableFilters?: boolean
  disableGlobalFilter?: boolean
  sortType?: string
  styles?: {
    align?: string
  }
})[]
type BaseProps<T extends Record<string, unknown>> = {
  /** Composable content for custom server side filters for the table */
  children?: (({ headerGroups }: { headerGroups: Array<HeaderGroup<T>> }) => React.ReactNode) | React.ReactNode
  /** Column Headers that also include key for data for that column.
   * @type {
      Header: Label for column,
      accessor: Key for data object that belongs in this column,
      disableSortBy: Disable sorting of this column,
      className: Custom class name to do custom styling of that specific column.
      width: flexWidth: Flex grow size of column
      styles: {
        align: Content alignment of column,
      }
   }
   *
   * */
  columns: Columns<T>
  /** Provide a label for a global search input. if client then the table will handle the rest */
  globalSearchLabel?: string
  /** Upon table initialization, this object is merged over the table's defaultState object */
  initialTableState?: any
  /** Loading indicator on initial load, If not set, make sure to set a loading indicator in the parent component */
  isFetching?: boolean
  /** Provide indicator for fetching in the background */
  isBackgroundFetching?: boolean
  /** Event fired when checkbox selected for a row. Returns all rows original data currently selected
   * NOTE: this function should be memoized.
   * */
  onRowSelect?: (originalRows: T[], rowIds: number[]) => void
  /** Event fired when filtering
   * @param page
   */
  onFilter?: (filters: RuleFilter<T>) => void
  /** Event fired when sorting of columns is changed
   * @param page
   */
  onRowClick?: (row: Row<T>) => void

  onSort?: (rules: RuleSort<T>[]) => void
  /** Event fired when pagination button is clicked
   * @param page
   */
  onPagination?: (pageIndex: number) => void
  /** Show pagination buttons on top of the table, bottom of the table or both.
   * @default both
   */
  paginationLocation?: 'both' | 'top' | 'bottom'
  /** A placeholder for the search input */
  searchPlaceholder?: string | null
  dragNDrop?: boolean
  reorderData?: (draggableRow: any, startIndex: number, endIndex: number) => void
  /** If set to true display the sorted by details of our client side filters */
  showSortByDetails?: boolean
  /** Function that renders a react component for sub row content. NOTE: Adding this will trigger all rows to be expandable */
  renderRowSubComponent?: (row: Row<T>) => React.ReactNode
  /** Function that renders a react component for table header when selection event is triggered. */
  renderHeaderButtons?: (selectedRows: T[], selectedFlatRows: Row<T>[]) => React.ReactNode
  /** State of content when no results are shown */
  noResultState?: { title?: string; subtitle?: string; renderCustom?: () => React.ReactNode }
  /** This should match how our data is sorted in our database or database query by default. (Visual change only) */
  defaultSortByLabel?: string
  /** Set height of each row */
  rowHeight?: string
  /** Set table height */
  height?: number
  /** If set true hides the above container with filters and pagination */
  hideAboveContainer?: boolean
  /** Set page sizes to select */
  paginationSizes?: number[]
  /** Disable scroll to top after pageCount change */
  disableScrollTop?: boolean
  /** Enable clear all filter button */
  clearAllFiltersBtn?: boolean
} & TableOptions<T>

type ClientProps<T extends Record<string, unknown>> = BaseProps<T> & {
  /** Size of each page. If not set, no pagination controls will be visible and the list will be forever */
  paginationSize?: number

  // disable server specific props
  setQueryState?: undefined
  totalItems?: undefined
}

type ServerProps<T extends Record<string, unknown>> = BaseProps<T> & {
  /** Server call to grab next set of data. Main prop to trigger server props */
  setQueryState?: ({
    pageIndex,
    pageSize,
    sortBy,
    filters,
    globalFilter
  }: {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<T>>
    filters: Filters<T>
    globalFilter: any
  }) => void
  /** Total number of results for list for pagination calculation */
  totalItems: number
  /** Size of each page. */
  paginationSize: number
}

export type RdnaSmartTableRef = {
  gotoPage: UsePaginationInstanceProps['gotoPage']
  setPageSize: UsePaginationInstanceProps['setPageSize']
  setFilter: UseFiltersInstanceProps['setFilter']
  setAllFilters: UseFiltersInstanceProps['setAllFilters']
  setSortBy: UseSortByInstanceProps['setSortBy']
  toggleAllRowsSelected: UseRowSelectInstanceProps['toggleAllRowsSelected']
}

export const customInsensitiveCompare = (rowA: Row, rowB: Row, columnId: string, desc: boolean) => {
  const valueA = rowA.values[columnId].toLowerCase()
  const valueB = rowB.values[columnId].toLowerCase()

  if (desc) {
    return valueA.localeCompare(valueB) > 0 ? 1 : -1
  }
  return valueB.localeCompare(valueA) > 0 ? -1 : 1
}

/* A pagination helper for delete functions */
export const getActivePageForDelete = (total: number, pageIndex: number) => {
  const lastPage = Math.ceil((total - 1) / 10)
  const currentPage = pageIndex
  return lastPage <= currentPage ? lastPage - 1 : currentPage
}

const ReorderCell = props => {
  return (
    <DragNDropContainer>
      <StyledDNDIcon {...props} />
    </DragNDropContainer>
  )
}

/* --- FILTERS --- */
export function DropdownFilter<T extends Record<string, unknown>>({
  options: optionsOverride,
  label,
  column: { Header, filterValue, setFilter, preFilteredRows, id }
}: FilterProps<T> & {
  options?: RdnaSelectProps['options']
  label?: string
}) {
  const headerText = Header && typeof Header === 'string' ? Header : id

  /* This is more accurate, but potentially more expensive than passing options into props. Please consider your use case.
  ex. we could have filters 1,2,3,4 but we only have 2 data sets in our table with filters: 1 and 2.
  If you pass the options prop, it will display filters 1,2,3,4 even though only 1 and 2 exist in the table.
  * */
  const getOptions = React.useCallback(() => {
    const optionValues = new Set()
    preFilteredRows.forEach(row => {
      optionValues.add(row.values[id])
    })
    return [...optionValues.values()].map(option => ({ label: option, value: option })) as RdnaSelectProps['options']
  }, [id, preFilteredRows])

  const options: RdnaSelectProps['options'] = optionsOverride || getOptions()
  const ALL = 'All'

  return (
    <RdnaSelect
      name={headerText}
      label={label || `Filter By ${headerText}`}
      options={[{ label: ALL, value: ALL }, ...options]}
      value={typeof filterValue === 'undefined' ? ALL : filterValue}
      onChange={(event: any) => setFilter(event.target.value === ALL ? undefined : event.target.value)}
    />
  )
} /* --- end of FILTERS --- */

/* --- STYLES --- */
const StyledClearAllBtn = styled(RdnaButton)`
  width: ${props => props.theme.spacing * 13}px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledTable = styled.div<TableProps>`
  border-radius: ${props => `${props.theme.spacing}px`};
  ${props => ({ ...props.theme.typography.body1 })};
  overflow: hidden;
  min-width: unset !important;
  min-height: ${props => (props.height ? `${props.height}px` : 'auto')};
  max-height: ${props => (props.height ? `${props.height}px` : 'auto')};

  .ringdna-table-row:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ringdna-table-row:last-child {
    margin-bottom: 0;
  }

  .clickable {
    cursor: pointer;
  }
`
const AboveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.spacing * 2}px;
`
const FilterContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 300px);
  grid-gap: 0 ${props => `${props.theme.spacing * 2}px`};
  align-items: center;
  width: 80%;

  .MuiFormControl-root {
    margin-bottom: 0;
  }

  .MuiFormHelperText-root {
    margin-top: 0;
  }
`
const PaginationGroup = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  .MuiFormControl-root {
    margin-top: 0;
  }
`

const StyledHead = styled.div`
  & > div {
    background: ${props => props.theme.palette.primary.light};
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
type CellStyles = {
  align?: 'center' | 'right'
}
const StyledTableCell = styled(RdnaText)<{ $styles?: CellStyles; $rowHeight?: string }>`
  height: ${props => `${props.$rowHeight ? props.$rowHeight : `${6 * props.theme.spacing}px`}`};
  align-items: center;
  display: inline-flex !important;
  overflow: hidden;

  ${props =>
    props.$styles?.align &&
    css`
      justify-content: ${props.$styles.align === 'center' ? 'center' : 'flex-end'};
    `}

  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`
const StyledRow = styled.div`
  padding: ${props => `${props.theme.spacing * 2}px ${3 * props.theme.spacing}px`};
  border-radius: ${props => `${props.theme.spacing}px`};
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.theme.palette.background.light};
  align-items: center;
`
const NoResults = styled.div`
  text-align: center;
  padding: 90px 0;

  svg {
    display: block;
    margin: 0 auto 20px auto;
  }
`
const DNDIcon = props => (
  <span {...props.dragHandleProps} className={props.className} aria-label="move">
    <SixDots />
  </span>
)
const StyledDNDIcon = styled(DNDIcon)`
  display: inline;
`

const DragNDropContainer = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`
/* --- end of STYLES --- */

const RdnaSmartTable = <T extends Record<string, unknown>>(
  {
    expandedState,
    children,
    initialTableState = {},
    isBackgroundFetching = false,
    onRowSelect,
    paginationSize,
    paginationLocation = 'both',
    onPagination,
    onFilter,
    onSort,
    clearAllFiltersBtn,
    searchPlaceholder,
    showSortByDetails = false,
    renderRowSubComponent,
    renderHeaderButtons,
    noResultState = {
      title: 'No Results Found.',
      subtitle: 'Please check your network connection or revise your search.'
    },
    globalSearchLabel,
    onRowClick,
    setQueryState,
    isFetching = false,
    totalItems,
    defaultSortByLabel = 'Created Date',
    dragNDrop = false,
    reorderData,
    height,
    rowHeight,
    hideAboveContainer = false,
    paginationSizes,
    disableScrollTop = false,
    // Table Option Props
    columns,
    data,
    ...restOfTableOptions
  }: ClientProps<T> | ServerProps<T>,
  ref: RdnaSmartTableRef
) => {
  const SCROLL_TO_TOP_ID = useId('scroll-to-top-')
  const totalRows: number = totalItems || data.length

  const defaultColumn = useMemo(
    () => ({
      Filter: (props: FilterProps<T>) => <DropdownFilter<T> {...props} />,
      filter: 'equals',
      disableFilters: true,
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200 // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const initialState: Partial<TableState<T>> = {
    pageIndex: 0
  }

  if (paginationSize) {
    initialState.pageSize = paginationSize
  }

  const isServerSide = !!setQueryState

  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    headers,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    setGlobalFilter,
    setFilter,
    setSortBy,
    setPageSize,
    toggleAllRowsSelected,
    setAllFilters,
    state: { filters, pageSize, selectedRowIds, sortBy, pageIndex, globalFilter }
  } = useTable<T>(
    {
      columns,
      data,
      sortTypes: {
        caseInsensitive: customInsensitiveCompare
      },
      defaultColumn,
      reorderData,
      dragNDrop,
      initialState: { ...initialState, ...initialTableState },
      manualPagination: isServerSide,
      manualSortBy: isServerSide,
      manualFilters: isServerSide,
      manualGlobalFilter: isServerSide,
      pageCount: Math.ceil(totalRows / (paginationSize || 10)),
      resizable: false,
      autoResetPage: true,
      autoResetSelectedRows: false,
      autoResetExpanded: isServerSide,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      ...restOfTableOptions
    },
    useFlexLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      if (onRowSelect || renderHeaderButtons) {
        hooks.allColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
              const props = getToggleAllPageRowsSelectedProps()
              return (
                <StyledCheckbox
                  title={props.title}
                  value={props.checked}
                  indeterminate={props.indeterminate}
                  onChange={(value, e) => props.onChange(e)}
                />
              )
            },
            Cell: ({ row }: { row: Row<T> }) => {
              const props = row.getToggleRowSelectedProps()
              return (
                <StyledCheckbox
                  title={props.title}
                  value={props.checked}
                  indeterminate={props.indeterminate}
                  onChange={(value, e) => props.onChange(e)}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                />
              )
            },
            disableFilters: true,
            maxWidth: 30
          },
          ...columns
        ])
      }
      if (dragNDrop) {
        hooks.allColumns.push(columns => [
          {
            Header: '',
            id: 'reorder',
            maxWidth: 40,
            disableSortBy: true,
            Cell: ReorderCell
          },
          ...columns
        ])
      }
    }
  )
  useImperativeHandle(ref, () => ({
    gotoPage,
    setPageSize,
    setFilter,
    toggleAllRowsSelected,
    setAllFilters,
    setSortBy
  }))

  const debouncedGlobalFilter = useDebounce(globalFilter, 500)
  useEffect(() => {
    setQueryState && setQueryState({ pageIndex, pageSize, sortBy, filters, globalFilter: debouncedGlobalFilter })
  }, [setQueryState, pageIndex, pageSize, sortBy, filters, debouncedGlobalFilter])

  useEffect(() => {
    if (onSort) {
      onSort(sortBy)
    }
  }, [onSort, sortBy])

  useEffect(() => {
    if (onFilter) {
      onFilter(filters)
    }
  }, [onFilter, filters])

  useEffect(() => {
    if (onPagination) {
      onPagination(pageIndex + 1)
    }
  }, [onPagination, pageIndex])

  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(
        selectedFlatRows.map(row => row.original),
        Object.keys(selectedRowIds).map(key => parseInt(key))
      )
    }
    /*
     "selectedFlatRows" re-renders on every row change causing extra re-renders.
     "selectedRowIds" is stateful and only changes when the row is selected.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRowSelect, selectedRowIds])

  const displaySortByText = useCallback((): string => {
    const selectedFilters = filters
      .map(filter => headers.find(header => header.id === filter.id)?.Header || filter.id)
      .join(', ')
    const selectedSortBy = sortBy.length
      ? sortBy.map(column => headers.find(header => header.id === column.id)?.Header || column.id).join(', ')
      : defaultSortByLabel

    return `${
      selectedFilters.length || globalFilter?.length ? `${rows.length} out of ` : ''
    }${totalRows} Items • Sorted by ${selectedSortBy}${
      selectedFilters.length ? ` • Filtered by ${selectedFilters}` : ''
    }`
  }, [filters, sortBy, defaultSortByLabel, globalFilter, rows.length, totalRows, headers])

  const renderReplaceHeaders = useCallback(
    (headerGroup: HeaderGroup<T>) => {
      const selectionColumn = headerGroup.headers.find(column => column.id === 'selection')

      return (
        <>
          <StyledTableCell
            $styles={selectionColumn.styles}
            key={selectionColumn.id}
            {...selectionColumn?.getHeaderProps()}
          >
            {selectionColumn?.render('Header')}
          </StyledTableCell>
          <StyledTableCell $styles={{ align: 'right' }}>
            {renderHeaderButtons ? (
              renderHeaderButtons(
                selectedFlatRows.map(row => row.original),
                selectedFlatRows
              )
            ) : (
              <></>
            )}
          </StyledTableCell>
        </>
      )
    },
    [renderHeaderButtons, selectedFlatRows]
  )

  const handleDragEnd = result => {
    const draggableRow = rows.find(row => {
      return row.id === result.draggableId
    })
    const { source, destination } = result
    if (!destination || !draggableRow || source.index === destination.index) return
    reorderData(draggableRow.original, source.index, destination.index)
  }

  const renderBodyTable = (provided: DroppableProvided) => {
    return (
      <div ref={provided != null ? provided.innerRef : null} {...getTableBodyProps()} {...provided?.droppableProps}>
        {(paginationSize ? page : rows).map((row: Row<T>, index) => {
          prepareRow(row)
          return dragNDrop ? renderDraggable(row, index) : renderRow(row, index)
        })}
        {provided?.placeholder}
      </div>
    )
  }

  const renderRow = (row: Row<T>, index: number, provided: DraggableProvided) => {
    return (
      <RdnaRowCollapseEnhancer
        key={row.original?.id + '-' + row.id + '-' + index}
        OriginalWrapper={StyledRow}
        wrapperProps={{
          ...provided?.draggableProps,
          ...row.getRowProps(),
          onClick: onRowClick ? () => onRowClick(row) : undefined,
          className: `ringdna-table-row ${onRowClick ? 'clickable' : ''}`,
          style: { ...provided?.draggableProps.style, ...row.getRowProps().style }
        }}
        draggableRef={provided?.innerRef}
        controlledState={
          expandedState
            ? {
                isOpen: expandedState.isExpandedById[row.original.id],
                setIsOpen: isOpen => {
                  expandedState.setIsExpandedById(row.original.id, isOpen)
                }
              }
            : { isOpen: row.isExpanded, setIsOpen: row.toggleRowExpanded }
        }
        details={renderRowSubComponent && renderRowSubComponent(row)}
        expandable={!!renderRowSubComponent}
        unmountOnExit
      >
        {row.cells.map(cell => {
          return (
            <StyledTableCell
              $styles={cell.column.styles}
              $rowHeight={rowHeight}
              className={cell.column.className}
              {...cell.getCellProps()}
              key={cell.column.id}
            >
              {cell.render('Cell', provided != null ? { dragHandleProps: provided.dragHandleProps } : {})}
            </StyledTableCell>
          )
        })}
      </RdnaRowCollapseEnhancer>
    )
  }

  const renderDragNDrop = () => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ringdna-droppable-table">{provided => <>{renderBodyTable(provided)}</>}</Droppable>
      </DragDropContext>
    )
  }

  const renderDraggable = (row: Row<T>, indexRow) => {
    return (
      <Draggable draggableId={row.id.toString()} key={row.id} index={row.index}>
        {provided => {
          return renderRow(row, indexRow, provided)
        }}
      </Draggable>
    )
  }

  const renderPaginationButtons = (location: 'top' | 'bottom') =>
    paginationSize && (paginationLocation === 'both' || paginationLocation === location) ? (
      <Pagination
        // @ts-ignore - should never be undefined based on exclusive type props
        defaultReset={true}
        listLength={setQueryState ? totalItems : rows.length}
        page={pageIndex + 1}
        itemsPerPage={pageSize}
        onClick={({ page }) => {
          gotoPage(page - 1)
        }}
      />
    ) : null

  return (
    <>
      {!hideAboveContainer && (
        <AboveContainer>
          <FilterContainer>
            {typeof children !== 'function' ? children : null}
            {(globalSearchLabel || searchPlaceholder) && (
              <RdnaTextInput
                search
                placeholder={searchPlaceholder}
                name="global-search-filter"
                label={globalSearchLabel}
                onChange={(event: any) => {
                  gotoPage(0)
                  setGlobalFilter(event.target.value || undefined)
                }}
                value={globalFilter || ''}
              />
            )}
            {headerGroups.map(headerGroup =>
              headerGroup.headers.map(
                column => column.canFilter && column.render('Filter', { ...column.getHeaderProps() })
              )
            )}
            {typeof children === 'function' ? children({ headerGroups }) : null}
            {showSortByDetails && <RdnaText>{displaySortByText()}</RdnaText>}
            {clearAllFiltersBtn && (
              <StyledClearAllBtn
                text="Clear All"
                onClick={() => {
                  setAllFilters(initialTableState.filters || [])
                  setGlobalFilter('')
                }}
              />
            )}
          </FilterContainer>
          <PaginationGroup>
            {isBackgroundFetching ? <CircularProgress size={22} style={{ marginRight: '1rem' }} /> : null}
            {renderPaginationButtons('top')}
          </PaginationGroup>
        </AboveContainer>
      )}
      <StyledTable {...getTableProps()} height={height} id={SCROLL_TO_TOP_ID}>
        <StyledHead>
          {headerGroups.map((headerGroup, index) => (
            <StyledRow {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}-${index}`}>
              {selectedFlatRows.length && !!renderHeaderButtons
                ? renderReplaceHeaders(headerGroup)
                : headerGroup.headers.map(column => (
                    <StyledTableCell
                      $styles={column.styles}
                      className={column.className}
                      {...(column.id === 'selection'
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      key={column.id}
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
                      ) : null}
                    </StyledTableCell>
                  ))}
            </StyledRow>
          ))}
        </StyledHead>
        <RdnaLoader
          data={rows}
          loading={isFetching}
          noResultsMsg={
            noResultState.renderCustom ? (
              noResultState.renderCustom()
            ) : (
              <NoResults>
                <EmptyTranscript />
                <RdnaText variant={'h2'}>{noResultState.title}</RdnaText>
                <RdnaText>{noResultState.subtitle}</RdnaText>
              </NoResults>
            )
          }
          top="50px"
        >
          {dragNDrop ? renderDragNDrop() : renderBodyTable()}
        </RdnaLoader>
      </StyledTable>
      {(paginationSizes || paginationSize) && (
        <PaginationGroup>
          {paginationSizes ? (
            <RdnaSelect
              name="pagination-sizes"
              options={paginationSizes.map(size => ({ label: 'Show ' + size, value: size }))}
              value={pageSize}
              onChange={(event: any) => {
                setPageSize(parseInt(event.target.value))
                !disableScrollTop && setTimeout(() => document.getElementById(SCROLL_TO_TOP_ID)?.scrollIntoView(), 0)
              }}
            />
          ) : (
            <div />
          )}
          {renderPaginationButtons('bottom')}
        </PaginationGroup>
      )}
    </>
  )
}

const ForwardRefRdnaSmartTable = forwardRef(RdnaSmartTable) as <T extends Record<string, unknown>>(
  props: (ClientProps<T> | ServerProps<T>) & { ref?: Ref<RdnaSmartTableRef> }
) => ReactElement

export default React.memo(ForwardRefRdnaSmartTable) as typeof ForwardRefRdnaSmartTable
