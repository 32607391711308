import React from 'react'
import RdnaText from '../../RdnaText'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

export const formatMessage = (paragraph: string) => {
  return (
    <StyledText data-testid={'whole-message'}>
      <ReactMarkdown>{paragraph}</ReactMarkdown>
    </StyledText>
  )
}

const StyledText = styled(RdnaText)`
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  li {
    margin: ${({ theme }) => theme.spacing * 2}px 0;
  }
`
