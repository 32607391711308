/* eslint-disable */
const { cookieUtils, SESSION_ID_KEY } = require('../../src/utils/cookieUtils')

/**
 * Centralized service for managing cookie reads and writes across packages.
 */

/* eslint-disable */
const ONE_DAY = 1

module.exports = class CommonCookieHelper {
  constructor(config) {
    this.config = config

    Cookies.defaults.secure = true
    Cookies.defaults.httpOnly = false
    Cookies.defaults.SameSite = 'None'
  }

  /**
   * Restrict a cookie value to a domain or subdomain
   */
  buildDomainOptions(domain) {
    return { domain: domain || this.config.domain }
  }

  /**
   * Set a time to expire a cookie value (in days)
   */
  buildExpiresOptions(expires) {
    return expires ? { expires } : {}
  }

  get(key) {
    return cookieUtils.getCookie(key)
  }

  set(key, val, expires) {
    return cookieUtils.setCookie(key, val, { ...this.buildExpiresOptions(expires), ...this.buildDomainOptions() })
  }

  remove(key) {
    return cookieUtils.removeCookie(key, {...this.buildDomainOptions()})
  }

  getSessionId() {
    return cookieUtils.getCookie(SESSION_ID_KEY)
  }

  setSessionId(sessionId) {
    cookieUtils.setCookie(SESSION_ID_KEY, sessionId)
  }

  removeSessionId() {
    cookieUtils.removeCookie(SESSION_ID_KEY)
  }

  id(key) {
    let env = 'dev'

    if (this.config.isQA) {
      env = 'qa'
    }

    if (this.config.isProd) {
      env = 'production'
    }

    return `rdna-${key}-${env}`
  }
}
