import React, { useCallback, useEffect, useState } from 'react'

import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import CloseIcon from '@ringdna/common/src/assets/icons/svg/Close'
import styled from 'styled-components'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import { AvailableField, Field, useSalesforceAvailableFields } from '../../../../../store/api/salesforce-fields-api'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import EditSettings from './EditSettings'
import RdnaAutoComplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { Colors } from '@ringdna/common/src/constants/colors'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import useToast from '../../../../common/hooks/useToast'
import { DataListItem } from '@ringdna/common/src/api/account-data-api'

type Props = {
  accountId: number
  onClose: (reload: boolean) => void
  type: string
  field?: Field
}

type AvailableFields = {
  label: string
  name: string
  value: string
  groupId: string
}
const MAX_NAME_LENGTH = 30
const DESCRIPTION =
  'Define which of your Salesforce Profiles can see and edit this Field in the RingDNA ' +
  'Communications Hub by checking the corresponding boxes in the table below. For control of the order in which ' +
  'this field will appear in the Dialer UI, click on any Profile Name and adjust the field order ' +
  'on the Manage Profile page.'

const emptyField: Field = { dataType: '', name: '', sfdcName: '' }
const emptyOption = { label: '', name: '', value: '', groupId: '' }

export default SideFieldEditor
function SideFieldEditor({ onClose, accountId, type, field }: Props) {
  const [selectedOption, setSelectedOption] = useState(emptyOption)
  const [loadingAvailableFields, setLoadingAvailableFields] = useState(false)
  const [availableFields, setAvailableFields] = useState<AvailableField[]>([])
  const [selectedField, setSelectedField] = useState<Field>(field || emptyField)
  const fetchAvailableFields = useSalesforceAvailableFields()
  const toastr = useToast()

  useEffect(() => {
    if (selectedOption?.value) {
      const availableField = availableFields.find(f => f.sfdcName === selectedOption.value)
      if (availableField && selectedField.sfdcName !== selectedOption.value) {
        const nameArray = availableField.sfdcName.split('.')
        const suffix = nameArray.length > 1 ? ` (${nameArray[0]})` : ''
        setSelectedField({ ...availableField, name: availableField.name + suffix })
      }
    }
  }, [availableFields, selectedOption, selectedField])

  useEffect(() => {
    const getAvailableFields = async () => {
      try {
        setLoadingAvailableFields(true)
        const response = await fetchAvailableFields({ meta: { id: accountId, type: type } })
        setAvailableFields(response.success?.payload || [])
      } catch (e) {
        toastr.error(getErrorMessage(e))
      } finally {
        setLoadingAvailableFields(false)
      }
    }
    !selectedField.sfdcName && availableFields.length === 0 && getAvailableFields()
  }, [accountId, availableFields.length, fetchAvailableFields, selectedField, toastr, type])

  const getGroupId = (parentType: string, sfdcName: string) => {
    const nameArray = sfdcName.split('.')
    return nameArray?.length > 1 ? `${parentType} ${nameArray[0]}` : parentType
  }

  const listAvailableFields: AvailableFields[] =
    availableFields
      .map(f => ({
        label: `${f.name} (${f.sfdcName})`,
        name: f.name,
        value: f.sfdcName,
        groupId: getGroupId(f.parentType, f.sfdcName)
      }))
      .sort((a, b) => (a.value < b.value ? -1 : 1)) || []

  const isError: () => boolean = useCallback(() => {
    return selectedField?.name.length > MAX_NAME_LENGTH
  }, [selectedField])

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: AvailableFields, { inputValue }: { inputValue: string }) => {
      const matches = match(option.name, inputValue, { insideWords: true })
      const parts = parse(option.name, matches)
      const value = `(${option.value})`
      const matchesSf = match(value, inputValue, { insideWords: true })
      const partsSf = parse(value, matchesSf)
      return (
        <li {...props} style={{ display: 'block' }} key={value}>
          <div>
            {parts.map((part, index) => (
              <RdnaText key={index} style={{ backgroundColor: part.highlight ? Colors.S20 : Colors.TRANSPARENT }}>
                {part.text}
              </RdnaText>
            ))}
          </div>
          <div>
            {partsSf.map((part, index) => (
              <RdnaText
                key={index}
                variant="meta"
                style={{ backgroundColor: part.highlight ? Colors.S20 : Colors.TRANSPARENT }}
              >
                {part.text}
              </RdnaText>
            ))}
          </div>
        </li>
      )
    },
    []
  )

  return (
    <Body $loadingAvailableFields={loadingAvailableFields}>
      <SectionHeader
        headingText={`${selectedField.id ? 'Edit' : 'Add'} ${type} Field`}
        rightColumn={
          <IconHelper
            icon={CloseIcon}
            title={'Close'}
            iconSize={20}
            size={30}
            testId="close-side"
            onClick={() => onClose(false)}
          />
        }
      />
      {!selectedField.id && (
        <RdnaAutoComplete
          className={'salesforce-field-select'}
          placeholder={loadingAvailableFields ? 'Loading...' : `Select`}
          label={'Salesforce Field'}
          id={'select-available-field'}
          options={listAvailableFields}
          groupBy={option => option.groupId}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={selectedOption}
          optionHeight={80}
          onChange={(event, value) => {
            if (value) {
              setSelectedOption(value)
            } else {
              setSelectedOption(emptyOption)
              setSelectedField(emptyField)
            }
          }}
          renderOption={renderOption}
          loading={loadingAvailableFields}
        />
      )}
      {selectedField.id && (
        <RdnaTextInput
          value={selectedField.sfdcName}
          inputData={{
            name: 'field-sfdcName',
            type: InputTypes.TEXT,
            label: 'Salesforce Field',
            className: 'salesforce-field-sfdcName'
          }}
          onChange={() => {}}
          isDisabled={true}
        ></RdnaTextInput>
      )}
      <RdnaTextInput
        value={selectedField?.name || ''}
        inputData={{
          name: 'field-name',
          type: InputTypes.TEXT,
          label: 'Name',
          placeholder: 'Name',
          className: 'salesforce-field-name'
        }}
        onChange={e => setSelectedField({ ...selectedField, name: e.target.value })}
        isError={isError()}
        errorMessage={'Name must be 30 characters or less'}
      ></RdnaTextInput>
      <RdnaText bold style={{ marginTop: 18 }}>
        Profiles
      </RdnaText>
      <RdnaText style={{ marginBottom: 12 }}>{DESCRIPTION}</RdnaText>
      <EditSettings field={selectedField} onClose={onClose} accountId={accountId} type={type} />
    </Body>
  )
}

const Body = styled.div<{ $loadingAvailableFields?: boolean }>`
  display: flex;
  flex-direction: column;
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiAutocomplete-inputRoot.MuiInputBase-formControl {
        padding-right: ${({ theme }) => theme.spacing * 2}px;
      }
    }
  }
  .MuiAutocomplete-endAdornment {
    ${props => props.$loadingAvailableFields && `display: none;`}
  }
  .checkbox-setting {
    align-content: center;
    flex-wrap: wrap;
    margin-left: ${({ theme }) => theme.spacing * 5 - 3}px;
  }
  [role='row'] {
    span:nth-child(3) {
      justify-content: center;
    }
  }
  [role='columnheader']:nth-child(n + 3) {
    height: auto;
    align-self: flex-end;
  }
  .tooltip-setting {
    margin-left: ${({ theme }) => theme.spacing * 2 + 1}px;
    padding-left: ${({ theme }) => theme.spacing * 2}px;
  }
  .salesforce-field-sfdcName,
  .salesforce-field-select {
    margin-top: ${({ theme }) => theme.spacing * 2}px;
    margin-bottom: ${({ theme }) => theme.spacing * 4}px;
  }
  .salesforce-field-select,
  .salesforce-field-sfdcName,
  .salesforce-field-name {
    width: 50%;
  }
  .salesforce-field-sfdcName {
    label {
      color: ${props => props.theme.palette.text.neutral};
    }
  }
  .header-setting label {
    span:last-child {
      color: ${({ theme }) => theme.palette.text.primary};
      text-align: center;
      white-space: normal;
      word-break: break-word;
    }
  }
`
