import React from 'react'

import { Colors } from '../../../constants/colors'

type Props = {
  size?: number
  color?: Colors
  borderColor?: Colors
  backgroundColor?: Colors
  checkColor?: Colors
}

function CircleCheck({
  size = 24,
  color = Colors.BLACK,
  borderColor,
  backgroundColor = Colors.WHITE,
  checkColor
}: Props) {
  borderColor = borderColor || color
  checkColor = checkColor || color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      data-testid="circle-check-icon"
      // @ts-ignore
      style={{ overflow: 'visible' }}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        stroke={borderColor}
        fill={backgroundColor}
      />
      <path
        d="M6.485 13.521l3.394 3.395 7.636-7.637"
        stroke={checkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CircleCheck
