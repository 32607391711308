import React, { useEffect, useState } from 'react'
import { Refetch } from '@ringdna/client/src/constants'
import { useUpdateTeamsUser, useUpdateZoomUser, AccountUser, AccountIdQuery } from './api'
import useToast from '../../common/hooks/useToast'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import { VideoAccountType } from './VideoUsersContainer'

import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaApiAutoComplete from '@ringdna/common/src/components/RdnaFormElements/RdnaApiAutoComplete'

type EditProviderModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  refetchAccountUsers: Refetch<AccountUser[], AccountIdQuery>
  accountUser: AccountUser
  accountId: string
  accountType: VideoAccountType
  isConnectedUser: boolean
}

const CONFIRM_BUTTON_CTA = 'Save'

export default function EditProviderModal({
  accountId,
  accountUser,
  accountType,
  isConnectedUser,
  isOpen,
  onRequestClose,
  refetchAccountUsers
}: EditProviderModalProps) {
  const toastr = useToast()
  const defaultVideoUserEmail = accountUser.msTeamsEmail || accountUser.zoomEmail || ''
  const [selectedVideoUserEmail, setSelecteVideoUserEmail] = useState<string>(defaultVideoUserEmail)
  const [disabled, setDisabled] = useState(true)
  const isTeams = accountType === VideoAccountType.TEAMS

  const updateTeamsUser = useUpdateTeamsUser()
  const updateZoomUser = useUpdateZoomUser()

  useEffect(() => {
    setDisabled(defaultVideoUserEmail === selectedVideoUserEmail)
  }, [defaultVideoUserEmail, selectedVideoUserEmail])

  const onSubmit = async () => {
    try {
      setDisabled(true)
      if (isTeams) {
        await updateTeamsUser({
          meta: { userId: accountUser.userId },
          json: {
            msTeamsEmail: selectedVideoUserEmail,
            msTeamsAccountId: accountUser.msTeamsAccountId
          }
        })
      } else {
        await updateZoomUser({
          meta: { userId: accountUser.userId },
          json: {
            zoomEmail: selectedVideoUserEmail
          }
        })
      }
      await refetchAccountUsers()
      setDisabled(false)
      onRequestClose()
    } catch (err) {
      toastr.error(getErrorMessage(err))
    }
  }

  return (
    <RdnaModal
      open={isOpen}
      minWidth={480}
      onClose={onRequestClose}
      heading={`${accountType} User Integration`}
      onConfirm={onSubmit}
      confirmButtonText={CONFIRM_BUTTON_CTA}
      confirmButtonDisabled={disabled}
    >
      <RdnaFormLayout container>
        <RdnaFormLayout>
          <RdnaApiAutoComplete
            name="name"
            defaultSearchTerm={selectedVideoUserEmail}
            label={`${accountType} User`}
            placeholder={`Select ${accountType} User`}
            onChange={({ value }) => setSelecteVideoUserEmail(value)}
            searchQuery={{ accountId }}
            searchUrl={`/api/v3/app/${isTeams ? 'msteams' : 'zoom'}/options`}
          />
        </RdnaFormLayout>
        {isConnectedUser && (
          <RdnaCheckbox
            label={`Unlink ${accountType} User`}
            value={!selectedVideoUserEmail}
            onChange={checked => setSelecteVideoUserEmail(checked ? '' : defaultVideoUserEmail)}
            data-testid="edit-provider-modal.unlink"
          />
        )}
      </RdnaFormLayout>
    </RdnaModal>
  )
}
