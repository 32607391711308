import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Configuration, Field, Topic } from './Models'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import styled from 'styled-components'
import RdnaAutoComplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Colors } from '@ringdna/common/src/constants/colors'

type Props = {
  topic: Topic
  objectFields: Field[]
  selectedType?: string
  onSelectField: (select: Configuration) => void
  configuredTopics: Configuration[]
}

type ListField = {
  label: string
  name: string
  value: string
}
const emptyOption = { label: '', name: '', value: '' }

export default function MethodologyTopic({
  topic,
  objectFields,
  selectedType,
  onSelectField,
  configuredTopics
}: Props) {
  const [selectedOption, setSelectedOption] = useState(emptyOption)

  const listFields: ListField[] = useMemo(
    () =>
      objectFields
        .filter(field => field.parentType.toLowerCase() === selectedType)
        .map(f => ({
          label: `${f.name} (${f.sfdcName})`,
          name: f.name,
          value: f.sfdcName
        }))
        .sort((a, b) => (a.value < b.value ? -1 : 1)) || [],
    [objectFields, selectedType]
  )

  useEffect(() => {
    const found = configuredTopics.find(c => c.parentType === selectedType && c.topic.id === topic.id)
    const select = found && listFields.find(field => field.value === found.sfdcName)
    setSelectedOption(select || emptyOption)
  }, [configuredTopics, listFields, selectedOption, selectedType, topic.id])

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: ListField, { inputValue }: { inputValue: string }) => {
      const matches = match(option.name, inputValue, { insideWords: true })
      const parts = parse(option.name, matches)
      const value = `(${option.value})`
      const matchesSf = match(value, inputValue, { insideWords: true })
      const partsSf = parse(value, matchesSf)
      return (
        <li {...props} style={{ display: 'block' }} key={value}>
          <div>
            {parts.map((part, index) => (
              <RdnaText key={index} style={{ backgroundColor: part.highlight ? Colors.S20 : Colors.TRANSPARENT }}>
                {part.text}
              </RdnaText>
            ))}
          </div>
          <div>
            {partsSf.map((part, index) => (
              <RdnaText
                key={index}
                variant="meta"
                style={{ backgroundColor: part.highlight ? Colors.S20 : Colors.TRANSPARENT }}
              >
                {part.text}
              </RdnaText>
            ))}
          </div>
        </li>
      )
    },
    []
  )

  return (
    <TopicContainer>
      <RdnaText bold>{topic.name}</RdnaText>
      <RdnaText>{topic.description}</RdnaText>
      <RdnaAutoComplete
        id={`topic-${topic.id}`}
        className={'rdna-select'}
        disabled={!selectedType}
        placeholder={'Select'}
        options={listFields}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={selectedOption}
        optionHeight={80}
        onChange={(event, value) => {
          const option = value ? value : emptyOption
          setSelectedOption(option)
          selectedType &&
            onSelectField({ topic: { id: topic.id }, sfdcName: option?.value || '', parentType: selectedType })
        }}
        renderOption={renderOption}
        getOptionDisabled={option => {
          return (
            configuredTopics.filter(
              c => c.sfdcName === option.value && selectedType === c.parentType && c.topic.id !== topic.id
            ).length > 0
          )
        }}
      />
    </TopicContainer>
  )
}

export const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing * 4}px;
  .rdna-select {
    max-width: ${({ theme }) => theme.spacing * 60}px;
  }
`
