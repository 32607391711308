import React from 'react'
import { FormDateRange, FormSection } from './EditHolidayContent'
import { EventProps } from '../../types'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import RdnaDateRangePicker from '@ringdna/common/src/components/RdnaFormElements/RdnaDatePicker/RdnaDateRangePicker'
import { getFormattedInputDate } from '@ringdna/common/src/utils/timeUtils'

type Props = {
  event: EventProps
  setEvent: any
  setOrderError: any
}

export default function EditHolidayContent({ event, setEvent, setOrderError }: Props) {
  const onChange = (dates: any) => {
    const [startDate, endDate] = dates as Date[]
    setEvent({
      ...event,
      fromDate: getFormattedInputDate(startDate),
      toDate: getFormattedInputDate(endDate)
    })
  }

  return (
    <>
      <FormSection>
        <RdnaTextInput
          inputData={{
            label: 'Holiday Event',
            name: 'name-event',
            type: InputTypes.TEXT,
            placeholder: 'Event Name'
          }}
          value={event.name}
          onChange={e => {
            setEvent({ ...event, name: e.target.value })
          }}
        />
      </FormSection>
      <FormSection>
        <FormDateRange container spacing={3}>
          <RdnaDateRangePicker
            name={'standard'}
            onChange={onChange}
            value={`${event.fromDate}|${event.toDate}`}
            hasOrderError={setOrderError}
          />
        </FormDateRange>
      </FormSection>
    </>
  )
}
