BaseView = require('base/view')

class Step1Modal extends BaseView
  template: require('./step-1-template')

  bindings:
    '.name': 'value:name'
    '.description': 'value:description'
    '.data': 'value:data'

module.exports = Step1Modal
