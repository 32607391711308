import React, { useState } from 'react'
import { Role, User } from '../types'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { DEFAULT_PAGE_SIZE, NO_RESULT_STATE, PAGE_SIZES, SUB_HEADING_1, SUB_HEADING_2, SUB_HEADING_3 } from '../const'
import { Footer, RoleDrawer } from '../styles'
import { createRoleOptions } from '../utils'
import { useUserRolesBulkUpdate } from '../api'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import SuccessRoleAssignmentPanel from './SuccessRoleAssignmentPanel'
import UserRoleAssignmentPanel from './UserRoleAssignmentPanel'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { unassignedUsersColumns } from '../columns'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'

export type AssignmentUserRoleDrawerProps = {
  isOpen: boolean
  onClose: () => void
  refetch?: any
  accountId: number
  roleId?: number
  roleDefinitions: Role[]
  listUnassignedUsers: User[]
}

export default function AssignmentUserRoleDrawer({
  isOpen,
  onClose,
  refetch,
  accountId,
  roleId,
  roleDefinitions,
  listUnassignedUsers
}: AssignmentUserRoleDrawerProps) {
  const { error } = useRdnaNotification()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [userList, setUserList] = useState<User[]>([])
  const updateUsersRole = useUserRolesBulkUpdate()
  const subHeading = () => {
    if (page == 1) {
      return SUB_HEADING_1
    } else if (page == 2) {
      return SUB_HEADING_2
    } else {
      return SUB_HEADING_3
    }
  }

  const closeDrawer = () => {
    onClose()
    setPage(1)
    setUserList([])
  }

  function handleDone() {
    if (refetch) {
      refetch()
    }
    closeDrawer()
  }

  async function handleSave() {
    setIsLoading(true)
    try {
      await updateUsersRole({ json: { users: userList, accountId } })
      setPage(3)
    } catch (e) {
      error(getErrorMessage(e))
    }
    setIsLoading(false)
  }

  return (
    <RoleDrawer
      open={isOpen}
      heading={page !== 3 ? 'User Role Assignment' : 'Success'}
      subHeading={subHeading()}
      onClose={closeDrawer}
      onBack={page == 2 ? () => setPage(page - 1) : undefined}
    >
      {page == 1 && (
        <RdnaSmartTable
          searchPlaceholder="Search"
          // @ts-ignore
          columns={unassignedUsersColumns(userList, setUserList)}
          data={listUnassignedUsers}
          noResultState={NO_RESULT_STATE}
          paginationSize={DEFAULT_PAGE_SIZE}
          paginationSizes={PAGE_SIZES}
        />
      )}
      {page == 2 && (
        <UserRoleAssignmentPanel
          listUser={userList}
          roleOptions={createRoleOptions(roleDefinitions)}
          roleId={roleId}
          setDisableSaveButton={setDisableSaveButton}
        />
      )}
      {page == 3 && <SuccessRoleAssignmentPanel roles={roleDefinitions} listUser={userList} />}
      <Footer>
        {page != 3 && (
          <RdnaButton
            text={'Cancel'}
            color="neutral"
            variant="text"
            onClick={page !== 1 ? () => setPage(page - 1) : closeDrawer}
            className={'button-cancel'}
          />
        )}
        {page == 1 && (
          <RdnaButton
            text={'Next'}
            onClick={() => setPage(2)}
            className={'button-next'}
            disabled={userList.length === 0}
          ></RdnaButton>
        )}
        {page == 2 && (
          <RdnaButton
            text={isLoading ? 'Saving...' : 'Save'}
            onClick={handleSave}
            className={'button-save'}
            disabled={disableSaveButton || isLoading}
          ></RdnaButton>
        )}
        {page == 3 && <RdnaButton text={'Done'} onClick={handleDone} className={'button-done'}></RdnaButton>}
      </Footer>
    </RoleDrawer>
  )
}
