import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'
import { getThemes } from '@ringdna/common/src/constants/themes'
import { RdnaNotificationProvider } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
// v3 client
import client from './client'
import { ClientProvider } from '@ringdna/client/src/react/context'
// deprecated v2 client (to remove soon)
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from '@ringdna/common/src/client/store'
import { SnackbarProvider } from 'notistack'
import { PageAudioProvider } from '@ringdna/common/src/components/contexts/PageAudioContext'
import { Products } from '@ringdna/common/src/constants/products'

// @ts-ignore
export default class ReactComponent extends Marionette.View {
  template() {
    return `<div class="component" />`
  }
  onRender() {
    // @ts-ignore
    const Component = this.getOption('component')
    // @ts-ignore
    const props = this.getOption('props')
    // @ts-ignore
    const theme = this.getOption('theme')
    const themes = getThemes(theme || Products.ADMIN)
    // @ts-ignore
    const root = ReactDOM.createRoot(this.$el[0])
    root.render(
      <ClientProvider client={client}>
        <PersistGate persistor={persistor}>
          <PageAudioProvider>
            <ThemeProvider theme={themes.scTheme}>
              <MaterialThemeProvider theme={themes.muiTheme}>
                <RdnaNotificationProvider globalZIndex={themes.muiTheme.zIndex.tooltip + 1}>
                  <SnackbarProvider maxSnack={1}>
                    <Component {...props} />
                  </SnackbarProvider>
                </RdnaNotificationProvider>
              </MaterialThemeProvider>
            </ThemeProvider>
          </PageAudioProvider>
        </PersistGate>
      </ClientProvider>
    )
  }
  onBeforeDestroy() {
    // @ts-ignore
    const root = ReactDOM.createRoot(this.$el[0])
    root.unmount()
  }
}
