AccountPickerView = require('widgets/account-picker/view')
BackgridCell = require('helpers/backgrid-cell')
BaseView = require('base/view')

class OverviewView extends BaseView

  template: require('./template')
  className: 'split-account-overview'

  regions:
    accountPicker        : 'span.account-picker'
    usersGrid            : 'div.users-grid'
    callQueuesGrid       : 'div.call-queues-grid'
    teamsGrid            : 'div.teams-grid'
    callflowsGrid        : 'div.callflows-grid'
    skillsGrid           : 'div.skills-grid'
    smartnumbersGrid     : 'div.smartnumbers-grid'
    holidayScheduleGrid  : 'div.holidayschedule-grid'

  ui:
    split   : 'button.split'
    sfOrgId : 'input.sfOrgId'
    results : '.results'

  events:
    'click @ui.split'    : 'split'
    'input @ui.sfOrgId' : 'checksfOrgId'

  onRender: =>
    App.pubsub.bind('SplitStatus', @onStatusUpdate)
    @usersCollection = new Backbone.Collection(@model.get('users'))
    @callQueuesCollection = new Backbone.Collection(@model.get('callQueues'))
    @teamsCollection = new Backbone.Collection(@model.get('teams'))
    @callFlowsCollection = new Backbone.Collection(@model.get('callFlows'))
    @skillsCollection = new Backbone.Collection(@model.get('skills'))
    @smartNumbersCollection = new Backbone.Collection(@model.get('smartNumbers'))
    @holidayScheduleCollection = new Backbone.Collection(@model.get('holidaySchedule'))

    @showChildView('usersGrid', @buildUsersGrid(@usersCollection))
    @showChildView('callQueuesGrid', @buildCallQueuesGrid(@callQueuesCollection))
    @showChildView('teamsGrid', @buildTeamsGrid(@teamsCollection))
    @showChildView('callflowsGrid', @buildCallFlowsGrid(@callFlowsCollection))
    @showChildView('skillsGrid', @buildSkillsGrid(@skillsCollection))
    @showChildView('smartnumbersGrid', @buildSmartNumbersGrid(@smartNumbersCollection))
    @showChildView('holidayScheduleGrid', @buildHolidayScheduleGrid(@holidayScheduleCollection))

    @accountPicker = new AccountPickerView()
    @showChildView('accountPicker', @accountPicker)
    @accountPicker.selectize.on('change', @checkAccount)

  onDestroy: =>
    App.pubsub.unbind('SplitStatus')

  buildUsersGrid: (collection) ->
    datetime = BackgridCell.dateTime()
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Name',           name: 'displayName',  cell: 'string'}
      {label: 'SF UserId',      name: 'sfUserId',     cell: 'string'}
      {label: 'Email',          name: 'email',        cell: 'string'}
      {label: 'State',          name: 'state',        cell: 'string'}
      {label: 'Status',         name: 'status',       cell: 'string'}
      {label: 'Created date',   name: 'createdDate',  cell: datetime}
      {label: 'Smart numbers',  name: 'smartNumbers', cell: @smartNumbersCell()}
      {label: 'New SF UserId',  name: 'newSfUserId',  cell: BackgridCell.placeHolder('(click to edit)'), editable: true}
      {label: 'New user name',  name: 'newUserName',  cell: BackgridCell.placeHolder('(click to edit)'), editable: true}
    ]

    @usersGrid = new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No users found.'
    )

  buildCallQueuesGrid: (collection) ->
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Name',           name: 'name',         cell: 'string'}
      {label: 'Type',           name: 'type',         cell: 'string'}
      {label: 'Smart numbers',  name: 'smartNumbers', cell: @smartNumbersCell()}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No call queues found.'
    )

  buildTeamsGrid: (collection) ->
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Name',           name: 'name',         cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No teams found.'
    )

  buildCallFlowsGrid: (collection) ->
    datetime = BackgridCell.dateTime()
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Name',           name: 'name',         cell: 'string'}
      {label: 'Paused',         name: 'paused',       cell: 'string'}
      {label: 'Type',           name: 'type',         cell: 'string'}
      {label: 'Created date',   name: 'createdDate',  cell: datetime}
      {label: 'Smart numbers',  name: 'smartNumbers', cell: @smartNumbersCell()}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No call flows found.'
    )

  buildSkillsGrid: (collection) ->
    callFlowsCell = class extends Backgrid.Cell
      render: ->
        callFlowSteps = @model.get('callFlowSteps')
        if callFlowSteps.length
          uniqCallFlowSteps = _.uniq(callFlowSteps, false, (step) =>
            step.callFlow.id
          )
          sortedUniqCallFlowSteps = _.sortBy(uniqCallFlowSteps, (step) =>
            step.callFlow.name
          )
          html = ''
          for step in sortedUniqCallFlowSteps
            href = '#call-flows/' + step.callFlow.id
            html += "<div><a href='#{href}'>#{step.callFlow.name}</a></div>"
        else
          html = '<span>none</span>'
        $(@el).html(html)
        @

    cols = [
      {label: 'Select',           name: 'selected',           cell: BackgridCell.checkbox(), editable: true}
      {label: 'Name',             name: 'name',               cell: 'string'}
      {label: 'Id',               name: 'id',                 cell: 'string'}
      {label: 'Number of Users',  name: 'userSkills.length',  cell: 'string',         sortable: true }
      {label: 'Call Flows',       name: '',                   cell: callFlowsCell,    sortable: false }
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No skills found.'
    )

  buildSmartNumbersGrid: (collection) ->
    datetime = BackgridCell.dateTime()
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Number',         name: 'number',       cell: 'string'}
      {label: 'Type',           name: 'type',         cell: 'string'}
      {label: 'Created date',   name: 'dateCreated',  cell: datetime}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart numbers found.'
    )

  buildHolidayScheduleGrid: (collection) ->
    datetime = BackgridCell.dateTime()
    cols = [
      {label: 'Select',         name: 'selected',     cell: BackgridCell.checkbox(), editable: true}
      {label: 'Id',             name: 'id',           cell: 'string'}
      {label: 'Name',           name: 'name',         cell: 'string'}
      {label: 'Description',    name: 'description',  cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No holiday schedules found.'
    )

  smartNumbersCell: ->
    class extends Backgrid.StringCell
      formatter:
        fromRaw: (values) ->
          return '' unless values
          numberList = []
          numberList.push(value.number) for value in values
          return numberList.join()

  checkAccount: =>
    if @accountPicker.val()
      @usersGrid.columns.models[10].attributes.renderable = false
      @usersGrid.columns.models[11].attributes.renderable = false
      @usersGrid.body.refresh()
      @ui.sfOrgId.prop('disabled', true)
    else
      @usersGrid.columns.models[10].attributes.renderable = true
      @usersGrid.columns.models[11].attributes.renderable = true
      @usersGrid.body.refresh()
      @ui.sfOrgId.prop('disabled', false)

  checksfOrgId: =>
    if @ui.sfOrgId.val()
      @accountPicker.selectize.disable()
    else
      @accountPicker.selectize.enable()

  split: =>
    users = @usersCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => {
        id: model.get('id').toString()
        sfUserId: model.get('newSfUserId')
        userName: model.get('newUserName')
       })

    callQueues = @callQueuesCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    teams = @teamsCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    callFlows = @callFlowsCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    skills = @skillsCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    smartNumbers = @smartNumbersCollection.models
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    holidaySchedules = @holidayScheduleCollection
      .filter((model) => model.get('selected'))
      .map((model) => model.get('id'))

    return unless @validate(users)

    @ui.results.text('')
    @ui.results.show()

    @trigger('split', @model.get('account').id, @accountPicker.val(), @ui.sfOrgId.val(), users, callQueues, teams, callFlows, skills, smartNumbers, holidaySchedules)

  validate: (users) =>
    valid = true
    if @accountPicker.val()
      if @ui.sfOrgId.val()
        toastr.warning('Cannot select both a ringDNA account and a new SFDC org ID - pick one')
        valid = false
      valid
    else
      if (not @ui.sfOrgId.val() or @ui.sfOrgId.val().length isnt 18)
        toastr.warning('Salesforce Org ID is not correct.')
        valid = false
      for value in users when not value.sfUserId or not value.userName or value.sfUserId.length isnt 18
        toastr.warning('\'New SF UserId\' or \'New user name\' are not correct.')
        valid = false
      valid

  onStatusUpdate: (status) =>
    $(@ui.results).append("<p class=#{status.type}>#{status.message}</p>")

module.exports = OverviewView
