import React from 'react'
import {MANAGE_DISPOSITIONS_STR} from './const'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import {useCallDispositionsSync} from '../../../api'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'

export default function ConfirmSyncCallDispositionModal({ open, onClose, accountId }: { open: boolean; onClose: any; accountId: number }) {
  const { error, success } = useRdnaNotification()
  const syncCallDispositions = useCallDispositionsSync()
  async function handleSyncSalesforce() {
    try {
      await syncCallDispositions({body: {accountId: accountId}})
      success('Call dispositions synced to Salesforce successfully.')
    } catch (e) {
      error(getErrorMessage(e))
    }
  }
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Continue'}
      heading={`Warning`}
      onClose={() => onClose(false)}
      onConfirm={() => {
        onClose(false)
        handleSyncSalesforce()
      }}
    >
      <RdnaText>{MANAGE_DISPOSITIONS_STR.syncText}</RdnaText>
    </RdnaModal>
  )
}
