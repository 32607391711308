import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function UsersIcon({ size = 30, color = Colors.N100, strokeWidth = 1.25 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" data-testid="users-icon">
      <path
        d="M13.125 10a3.125 3.125 0 11-6.25 0 3.125 3.125 0 016.25 0zM23.125 10a3.125 3.125 0 11-6.25 0 3.125 3.125 0 016.25 0z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        clipRule="evenodd"
        d="M3.75 22.5h12.5L17.5 20s-1.875-3.75-7.5-3.75S2.5 20 2.5 20l1.25 2.5z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
      />
      <path
        d="M18.75 22.5h8.75V20c-1.25-2.5-3.75-3.75-7.5-3.75-.912 0-1.75.074-2.514.222"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UsersIcon
