import React, { useEffect, useState } from 'react'
import { HashRouter as Router, NavLink, Redirect, Route, Switch } from 'react-router-dom'

import styled from 'styled-components'

type StyleProps = {
  width: any
}

const StyledDiv = styled.div<StyleProps>`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width || '100%'};
  border-bottom: 1px solid ${props => props.theme.palette.border};
  margin-bottom: ${({ theme }) => theme.spacing * 4}px;

  a {
    color: ${props => props.theme.palette.meta};
    text-decoration: none;
    padding-bottom: 10px;
    font-weight: bold;
  }

  .active {
    color: ${props => props.theme.palette.primary.dark};
    border-bottom: 2px solid ${props => props.theme.palette.primary.dark};
  }
`

type RouteTypes = {
  path: string
  label: string
  component?: any
  render?: any
}

type Props = {
  redirectRoute: string
  baseRoute: string
  routes: RouteTypes[]
  tabWidth?: string
  onTabChange?: (activeTabKey: string) => void
}

/**
 * Reusable Tab Styled Routing
 *
 * <RdnaTabRouter
 *    redirectRoute="availability"
 *    routes={ROUTES}
 *    baseRoute={`/users/${activeUserId}/calendar`}
 *    tabLabels={CALENDAR_ROUTES}
 * />
 *
 * @param baseRoute - basename for the Router
 * @param redirectRoute - default route to redirect to
 * @param children - react children
 * @param routes - the Route components, label, and path of the tabs
 * @constructor
 */
export default function RdnaTabRouter({ baseRoute, redirectRoute, routes, tabWidth, onTabChange }: Props) {
  const [activeTab, setActiveTab] = useState<string>(redirectRoute)

  useEffect(() => {
    if (onTabChange) onTabChange(activeTab)
  }, [activeTab, onTabChange])

  return (
    <Router hashType="noslash" basename={baseRoute}>
      <StyledDiv width={tabWidth}>
        <Redirect from="/" to={redirectRoute} />
        {routes.map((route: RouteTypes) => (
          <NavLink key={route.label} activeClassName="active" to={route.path} onClick={() => setActiveTab(route.path)}>
            {route.label}
          </NavLink>
        ))}
      </StyledDiv>
      <Switch>
        {routes.map(item =>
          item.component ? (
            <Route key={item.label} path={item.path} component={item.component} />
          ) : (
            <Route key={item.label} path={item.path} render={item.render} />
          )
        )}
      </Switch>
    </Router>
  )
}
