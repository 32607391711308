import React, {useState} from 'react'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import {MANAGE_DISPOSITIONS_STR} from './const'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import {useCallDispositionsImport} from '../../../api'

export default function ConfirmEnableCallDispositionModal({ open, onClose, onConfirm, accountId }: { open: boolean; onClose: any; onConfirm: any; accountId: number }) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error } = useRdnaNotification()
  const [isImport, setIsImport] = useState(false)
  const [isAccept, setIsAccept] = useState(false)
  const importCallDispositions = useCallDispositionsImport()
  async function handleConfirm(isImport: boolean) {
    setProcessing(true)
    try {
      if (isImport) {
        await importCallDispositions({body: {accountId: accountId}})
      }
    } catch (e) {
      error(getErrorMessage(e))
    }
    setIsImport(false)
    setIsAccept(false)
    setProcessing(false)
    onConfirm()
  }

  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Save'}
      confirmButtonDisabled={!isAccept}
      heading={`Manage Call Disposition`}
      confirmButtonProcessing={processing}
      onClose={() => {
        onClose()
        setIsImport(false)
        setIsAccept(false)
      }}
      onConfirm={() => {
        handleConfirm(isImport)
      }}
    >
      <RdnaText>{MANAGE_DISPOSITIONS_STR.enableText}</RdnaText>
      <RdnaCheckbox
        label={'Import call dispositions from Salesforce'}
        labelPlacement={'end'}
        value={isImport}
        onChange={(value: boolean) => {
          setIsImport(value)
        }}
      />
      <RdnaCheckbox
        label={'I accept'}
        labelPlacement={'end'}
        value={isAccept}
        onChange={(value: boolean) => {
          setIsAccept(value)
        }}
      />
    </RdnaModal>
  )
}
