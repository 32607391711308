import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function MessageVolumeIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="message-volume-icon">
      <g clipPath="url(#clip0_9122_72728)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4706 7H40.5294C50.1781 7 58 15.2081 58 25.3333C58 35.4586 50.1781 43.6667 40.5294 43.6667L32.5882
              52V43.6667H21.4706C11.8218 43.6667 4 35.4586 4 25.3333C4 15.2081 11.8218 7 21.4706 7Z"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
        <path
          d="M12 30H21.8667L30.5 25.7143L39.75 27.1429L49 15"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9122_72728">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
