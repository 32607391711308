BaseGrid                = require('base/grid')
BaseView                = require('base/view')
BaseModel               = require('base/model')
BaseCollection          = require('base/collection')
BackgridCell            = require('helpers/backgrid-cell')
BackgridRow             = require('helpers/backgrid-row')
PagedCollection         = require('base/paged-collection')
PagerView               = require('widgets/pager/view')
LocationCollection      = require('widgets/location-picker/collection')

class ListNewUsersView extends BaseView
  template: require('./template')

  regions:
    grid           : 'div.grid'

  ui:
    content        : '.content'

  initialize: (options) =>
    { @collection } = options
    App.pubsub.bind('CreateSalesforceUsersStatus', @onProgress)
    App.pubsub.bind('CreateSalesforceUserstFinished', @onFinish)

  onDestroy: =>
    App.pubsub.unbind('CreateSalesforceUsersStatus')
    App.pubsub.unbind('CreateSalesforceUserstFinished')

  onProgress: (data) =>
    @collection?.add(data.message)

  onFinish: =>
    @hideSpinner($(@el))
    @showAlert()

  onRender: =>
    @showSpinner($(@el), left: '45%')
    @showChildView('grid', @buildGrid(@collection))

  buildGrid: (collection) =>
    userCell = BackgridCell.usersCell('userId', 'userDisplayName')

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:numberId',
      label : ':number',
      attrs : ['numberId', 'number'])

    columns = [
      {label: 'Name',         name: 'name',      cell: userCell}
      {label: 'Email',        name: 'email',     cell: 'string'}
      {label: 'Title',        name: 'title',     cell: 'string'}
      {label: 'Smart Number', name: 'number',    cell: numbCell}
      {label: 'Extension',    name: 'extensionCode', cell: 'string'}
      {label: 'Location',     name: 'locationName',  cell: 'string'}
      {label: 'User Description',   name: 'userDescription',    cell: 'string'}
      {label: 'Number Description', name: 'numberDescription',  cell: 'string'}
    ]

    new BaseGrid.Grid(
      collection : collection,
      columns    : columns,
      emptyText  : 'No users found.'
    )

  showAlert: =>
    fewFailed = false
    allFailed = true
    @collection.each((model) =>
      fewFailed = true unless model.get('userCreated')
      allFailed = false if model.get('userCreated')
    )
    if allFailed
      toastr.error('Users were not created')
    else if fewFailed
      toastr.warning('A few users were not created')
    else
      toastr.info('The create has completed.', 'User Create')
      @$('.finish-message').removeClass('hidden')

module.exports = ListNewUsersView
