BaseView                      = require('base/view')
BaseModel                     = require('base/model')
ConfirmDeletionModal          = require('./modals/confirm-deletion-view')
ReassignAdditionalNumberModal = require('./modals/reassign-additional-number-view')
UserTeamView                  = require('./user-team/view')
AccountModel                  = require('../../../accounts/model')
adminTemplate = require('./template-admin.hbs')
agentTemplate = require('./template-agent.hbs')
supportTemplate = require('./template-support.hbs')

class SettingsModel extends BaseModel
  urlRoot: 'users'

class OverviewView extends BaseView
  className: 'user-overview'

  regions:
    userTeam: 'div.user-team-container'
    modal: 'div.modal-container'

  ui:
    deleteButton          : 'button.delete'
    undeleteButton        : 'button.undelete'
    location              : 'select.location'
    sfUserId              : 'input.sfUserId'
    userTitle             : 'input.userTitle'
    conversationAI        : 'input.conversationAI'
    admin                 : 'input.adminUsers'
    intelligentDialer     : 'input.dialerUsers'
    calendar              : 'input.nylasUser'
    moments               : 'input.moments'
    guidedSelling         : 'input.guidedSelling'
    transcriptionLanguage : 'select.transcriptionLanguage'
    conversationAnalyticsManager   : 'input.conversationAnalyticsMgr'
    role                  : 'select.role'

  bindings:
    '.delete'              : 'toggle:not(isDeleted)'
    '.undelete'            : 'toggle:isDeleted'
    '.cdm-user'            : 'checked:cdm'
    '.cdm-user-container'  : 'toggle:showCDM'
    '.adminUsers'          : 'checked:adminLicense'
    '.dialerUsers'         : 'checked:intelligentDialerLicense'
    '.nylasUser'           : 'checked:calendarLicense'
    '.conversationAI'      : 'checked:conversationAI'
    '.moments'             : 'checked:moments'
    '.guidedSelling'       : 'checked:guidedSelling'
    '.role-container'      : 'toggle:showRole'
    '.transcriptionLanguage'      : 'value:transcriptionLanguage'
    '.conversationAnalyticsMgr'   : 'checked:conversationAnalyticsManager'

  computeds:
    showCDM:
      deps: ['user']
      get: (user) ->
        user.account?.settings?.cdm

    conversationAI:
      deps: ['user']
      get: (user) ->
        user.licenses?.conversationAI

    conversationAnalyticsManager:
      deps: ['user']
      get: (user) ->
        user.settings?.conversationAnalyticsManager

    moments:
      deps: ['user']
      get: (user) -> user.licenses?.moments

    guidedSelling:
      deps: ['user']
      get: (user) -> user.licenses?.guidedSelling

    transcriptionLanguage:
      deps: ['user']
      get: (user) ->
        user.settings?.transcriptionLanguage

    calendarLicense:
      deps: ['user']
      get: (user) -> user.licenses?.calendar

    intelligentDialerLicense:
      deps: ['user']
      get: (user) -> user.licenses?.intelligentDialer

    adminLicense:
      deps: ['user']
      get: (user) -> user.licenses?.admin

    showRole:
      get: -> App.featureFlag.isFlagged(App.featureFlag.flags.ROLES)

  events:
    'click @ui.deleteButton'             : 'confirmDeletion'
    'click @ui.undeleteButton'           : 'undeleteUser'
    'click a.reassign-additional-number' : 'reassignAdditionalNumber'
    'click a.add-outbound-number'        : 'addOutboundNumber'
    'click a.delete-outbound-number'     : 'deleteOutboundNumber'
    'click a.add-voicemail-drop'         : 'addVoicemailDrop'

  childViewEvents:
    reload: -> @render()

  getTemplate: ->
    return supportTemplate if @user().isSupport() and !@user().isSalesSupport()
    return adminTemplate if @user().isAdmin()
    agentTemplate

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()
    @refreshToggle()

  refreshToggle: ->
    $('.toggle-switch').bootstrapToggle('destroy')
    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  initialize: (options) ->
    @userModel = options.user
    @modal = @getRegion('modal')

    @setUserAccountAuthorizations()

    @listenTo(@model, 'change', -> @render())

  onRender: =>
    @showChildView('userTeam', new UserTeamView(user: @model.get('user')))
    @initSelectize()

  onSaveClicked: (button) =>
    adapter = new SettingsModel(
      'id'             : @model.userId
      'user.id'        : @model.userId
      'user.location'  :
        id  : @ui.location.val()
      'user.title'     : @ui.userTitle.val()
      'settings.id'    : @model.get('settingsId')
      'settings.transcriptionLanguage' :  @ui.transcriptionLanguage.val()
    )
    unless @user().isAgent()
      adapter.set(
        'user.sfUserId'  : @ui.sfUserId.val()
        'user.roleId'    : @ui.role[0].selectize?.getValue()
        'settings.cdm'   : @model.get('cdm')
        'settings.conversationAnalyticsManager'   :  @ui.conversationAnalyticsManager[0].checked
        'licenses.id'                 : @model.get('user').licenses?.id
        'licenses.admin'              : @ui.admin[0].checked
        'licenses.intelligentDialer'  : @ui.intelligentDialer[0].checked
        'licenses.calendar'           : @ui.calendar[0].checked
        'licenses.conversationAI'     : @ui.conversationAI[0].checked
        'licenses.guidedSelling'      : @ui.guidedSelling[0].checked
        'licenses.moments'            : @ui.moments[0].checked
      )

    adapter
      .persist(ladda: button)
      .done?(=>
        toastr.info('User saved.')
      )

  initSelectize: () =>
    selectOptions = []
    optGroups = []
    @model.get('roles').forEach((item) =>
      group = item.department
      optGroups.push({
        label: group
        value: group
      })

      selectOptions.push({
        text: item.name
        value: item.id
        optgroup: group
      })
    )
    @ui.role.selectize(
      options: selectOptions
      optgroups: optGroups
      placeholder: 'Select Role'
      valueField: 'value'
      searchField: 'text'
      persist: false
      items: [@model.get('user').roleId]
    )

  deleteUser: (numberIds, numbers) =>
    @userModel
      .delete(ladda: @ui.deleteButton[0], data: {'numberIds': numberIds})
      .done(=>
        @model.fetch() # FIXME: Should be able to render() here instead of re-fetching.
        if numbers?.length is 1
          message = "The user <b>#{_.escape(@model.get('user').displayName)}</b> and smart number <b>#{numbers[0]}</b> has been deleted."
        else if numbers?.length > 1
          message = "The user <b>#{_.escape(@model.get('user').displayName)}</b> and smart numbers "
          for number in numbers
            message = message + "<b>#{number}</b>,"
          message = message + ' have been deleted.'
        else
          message = "The user <b>#{_.escape(@model.get('user').displayName)}</b> has been deleted."
        toastr.info(message)
      )

  undeleteUser: =>
    @userModel
      .undelete(ladda: @ui.undeleteButton[0])
      .done(=> @model.fetch()) # FIXME: Should be able to render() here instead of re-fetching.

  confirmDeletion: (e) =>
    view = new ConfirmDeletionModal(
      user: @model.get('user')
    )
    @showChildView('modal', view)
    @listenTo(view, 'confirmDeletion', (numberIds, numbers) ->
      @deleteUser(numberIds, numbers)
    )

  setUserAccountAuthorizations: =>
    accounts = []

    for userAccountAuthorization in @model.get('user.userAccountAuthorizations')
      account = new AccountModel(userAccountAuthorization)
      accounts.push(account)

    @model.set('userAccountAuthorizations', new Backbone.Collection(accounts))

module.exports = OverviewView
