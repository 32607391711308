BaseModel = require('base/model')

module.exports = class NumberModel extends BaseModel

  defaults:
    number              : ''
    country             : null
    addressRequirements : 'none'
    selected            : false
    errorDesc           : null
