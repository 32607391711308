import React, { useCallback, useState } from 'react'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { RtnPagination } from '../../types'
import { useCallQueues } from '../../api'
import { PAGE_SIZE, SERVER_PAGE_OFFSET } from '../../const'
import CallQueuesTable from './CallQueuesTable'
import CallQueuesLiveTable from './CallQueuesLiveTable'
import { TableSortingRule } from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'

type tabProps = {
  abilities: { [key: string]: any }
  accountSettings: { [key: string]: any }
  setAccountSetting: (setting: { [key: string]: any }) => void
}

export default function CallQueuesTab({ accountSettings, setAccountSetting, abilities }: tabProps) {
  const [queryState, setQueryState] = useState<RtnPagination>({
    globalFilter: '',
    sortBy: [{ id: 'id', desc: true }],
    pageIndex: 0
  })
  const [payload, , loading, refetch] = useCallQueues({
    query: {
      accountId: accountSettings['account.id'],
      searchTerm: queryState.globalFilter,
      'pager.pageSize': PAGE_SIZE,
      'pager.currentPage': queryState.pageIndex + SERVER_PAGE_OFFSET,
      'pager.sortBy': queryState.sortBy[0]?.id,
      'pager.sortDirection': queryState.sortBy[0]?.desc ? 'desc' : 'asc'
    }
  })

  const handleQueryState = useCallback(
    ({ pageIndex, sortBy, globalFilter }: {pageIndex: number, sortBy: TableSortingRule<any>[], globalFilter: string}) => {
      const state = {
        globalFilter,
        pageIndex,
        sortBy
      }
      setQueryState(state)
    },
    [setQueryState]
  )

  return (
    <RdnaFormLayout container>
      <RdnaFormLayout>
        <RdnaHeaderSection rightColumn={CreateCallQueueButton(!accountSettings['account.id'])} />
        <CallQueuesTable
          payload={payload}
          refetch={refetch}
          onQueryState={handleQueryState}
          loading={!!loading}
          isSupport={abilities['users:support']}
        />
      </RdnaFormLayout>
      <RdnaFormLayout>
        <RdnaHeaderSection headingText={'Live Queues'} />
        <CallQueuesLiveTable accountId={accountSettings['account.id']} />
      </RdnaFormLayout>
    </RdnaFormLayout>
  )
}

const CreateCallQueueButton = (disabled: boolean) => {
  return (
    <RdnaButton
      data-analyticsid="add-call-queue"
      disabled={disabled}
      text={'Add Call Queue'}
      onClick={() => {
        window.location.href = '#call-queue/add-new'
      }}
    />
  )
}
