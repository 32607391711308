AdvancedModel = require('features/call-tracking/advanced/model')
BaseView = require('base/view')
BaseGrid = require('base/grid')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')
UnassignedNumbersGrid = require('../../__components/unassigned-numbers-grid/view')

class AdvancedCallTrackingModal extends BaseView
  template: require('./template')
  className: 'advanced-editor'

  regions:
    grid: 'div.grid'
    numbersGrid: 'div.numbers-grid'

  ui:
    popup: 'div.popup'

  events:
    'click .add-advanced' : 'addAdvanced'
    'click .finish'     : 'finish'

  bindings:
    '.add-call-tracking-grid': 'classes:{"unset-main-grid":hasUnset}'

  initialize: (options) =>
    { @accountId, @collection } = options
    @viewModel = new Backbone.Model(hasUnset: @collection.length <= 3)
    @activeModelForUnassignedNumber = null
    @listenTo(@collection, 'change:typeNumber', (model) => @checkChangedTypeNumber(model))

  onRender: =>
    @showSpinner($(@el), left: '45%')
    CallTrackingWidgets.getInfo(@accountId, (campaigns, callflows, countries) =>
      @hideSpinner($(@el))
      @showChildView('grid', @buildGrid(campaigns, callflows, countries)) if @getRegion('grid')
      $('[data-toggle="tooltip"]').tooltip()
    )

  checkChangedTypeNumber: (changedModel) =>
    if changedModel.get('typeNumber') is 'Existing'
      @activeModelForUnassignedNumber = changedModel
      @showChildView('numbersGrid', new UnassignedNumbersGrid(accountId: @accountId, changedModel: changedModel))
    else
      if @activeModelForUnassignedNumber?.cid == changedModel.cid
        @getRegion('numbersGrid').destroy() if @getRegion('numbersGrid')

  buildGrid: (campaigns, callflows, countries) =>
    labelTrackingCell = class extends Backgrid.Cell
      events:
        'change .label-tracking-cell': 'labelTrackingChanged'

      labelTrackingChanged: (e) =>
        @model.set('labelTracking', $(e.target).val())

      render: =>
        $(@el).html("""<input type='text' class='form-control label-tracking-cell' maxlength='255' value=\"#{@model.get('labelTracking')}\"/>""")
        @

    channelCell = class extends Backgrid.Cell
      events:
        'change .channel-cell': 'channelChanged'

      channelChanged: (e) =>
        @model.set('channel', $(e.target).val())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:channel', =>
          @showHtml(not @model.get('channel'))
        )
        @listenTo(@model, 'checkChannel', =>
          @showHtml(true)
        )

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control channel-cell' maxlength='255' value=\"#{@model.get('channel')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    deleteCell = class extends Backgrid.Cell
      events:
        'click a.delete': 'delete'

      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          @model.destroy(data: accountId: @model.get('accountId')) if ok
          true
        )
      render: =>
        @showHtml()
        @listenTo(@model.collection, 'update', =>
          @showHtml()
        )

      showHtml: =>
        html = """<a class="delete"><span class='glyphicon glyphicon-remove-sign'></span></a>""" if @model.collection.length > 1
        $(@el).html(if html then html else '')
        @

    customParamHeaderCell = class extends Backgrid.HeaderCell
      render: ->
        $(@el).html('<label>Custom Parameter Value <span class="glyphicon glyphicon-question-sign blue"
                    data-toggle="tooltip" data-placement="top"
                    data-original-title="Create your own descriptive naming convention to easily
                      identify Smart Numbers for specific campaigns."></span></label>')
        @

    columns = [
      {label: 'Name',                   name: 'labelTracking',    cell: labelTrackingCell,                              sortable: false}
      {label: 'Custom Parameter Value', name: 'channel',          cell: channelCell, headerCell: customParamHeaderCell, sortable: false}
      {label: 'Country',                name: 'country',          cell: CallTrackingWidgets.countryCell(countries),     sortable: false}
      {label: 'Smart Number',           name: 'type',             cell: CallTrackingWidgets.typeSmartNumberCell(),      sortable: false}
      {label: '',                       name: 'smartNumberId',    cell: CallTrackingWidgets.typeSmartNumberValueCell(), sortable: false}
      {label: 'Area Code',              name: 'code',             cell: CallTrackingWidgets.areaCodeCell(),             sortable: false}
      {label: 'Call Flow',              name: 'callFlow',         cell: CallTrackingWidgets.callFlowCell(callflows),    sortable: false}
      {label: 'SFDC Campaign',          name: 'campaign',         cell: CallTrackingWidgets.campaignCell(campaigns),    sortable: false}
      {label: '',                       name: '',                 cell: deleteCell,                                     sortable: false}
    ]

    new BaseGrid.Grid(
      collection : @collection,
      columns    : columns
    )

  addAdvanced: =>
    @collection.unshift(new AdvancedModel())
    @viewModel.set(hasUnset: @collection.length <= 3)

  finish: =>
    return if @validate()
    @collection.each((model) =>
      extraInfo =
        label: model?.get('channel')

      model.set('extraInfo', JSON.stringify(extraInfo))
    )
    @createAdvancesCallTrackings()

  createAdvancesCallTrackings: =>
    ladda = Ladda.create(@$('.finish')[0])
    ladda.start()
    @collection.createAdvances(@accountId, null, (hasNeedAddress) =>
      ladda.stop()
      @trigger('openAddressStepModal') if hasNeedAddress
      @ui.popup.modal('hide'))

  validate: =>
    subjectError = CallTrackingHelper.findErrors(@collection)
    @collection.each((model) =>
      if not model.get('channel')
        model.trigger('checkChannel')
        subjectError = true
    )
    toastr.warning('Please correct the highlighted errors.') if subjectError
    return subjectError

module.exports = AdvancedCallTrackingModal
