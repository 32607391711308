Handlebars = require('handlebars/runtime').default
BaseModel                   = require('base/model')
VoicemailGreetingCollection = require('./voicemail-greeting/collection')

class UserModel extends BaseModel
  urlRoot: 'users'

  defaults:
    forwardingNumber: ''
    disabledOffline: false

  parse: (response) =>
    settings = response.settings
    settings.calendarEnabled = response.calendarEnabled
    settings[key + 'Locked'] = value for key, value of response.account.lockedSetting when key isnt 'id'
    @featureVisibilitySettings = new Backbone.Collection(response.account.featureVisibilities)

    @forwardingNumberId = null
    @settingsId = settings.id
    @apiAttrs =
      user: ['id', 'role', 'sfUserId', 'countryCode', 'extension']
      settings: (key for key of settings)

    delete response.settings
    delete response.account.lockedSetting
    delete response.account.featureVisibilities
    response.role = response.role?.name

    @voicemailGreetings = new VoicemailGreetingCollection(response.voicemailGreetings)
    delete response.voicemailGreetings
    for model in response.forwardingNumbers when model.selected
      @forwardingNumberId = model.id
      settings.forwardingNumber = model.number
      settings.disabledOffline = model.disabledOffline
    settings.orderIdsVoicemailDrops = settings.orderIdsVoicemailDrops.split(',').map(Number) if settings.orderIdsVoicemailDrops
    _.extend(settings, response)

  toJSON: =>
    fields = {}
    fields["user.#{key}"] = @get(key) for key in @apiAttrs.user
    fields["settings.#{key}"] = @get(key) for key in @apiAttrs.settings
    fields['settings.id'] = @settingsId
    for model in @get('forwardingNumbers')
      @forwardingNumberId = model.id if Handlebars.helpers.formatPhoneNumber(@get('forwardingNumber')) is Handlebars.helpers.formatPhoneNumber(model.number)
    fields['forwardingNumber.id'] = @forwardingNumberId
    fields['forwardingNumber.number'] = Handlebars.helpers.formatPhoneNumber(@get('forwardingNumber'))
    fields['forwardingNumber.disabledOffline'] = @get('disabledOffline')
    fields

  validate: (attrs) =>
    error = null
    if attrs.noAnswer and attrs.noAnswerNumber?.trim().length < 3
      error = 'no answer'
    else if attrs.callForwarding and attrs.forwardingNumber?.trim().length < 3
      error = 'call forwarding'
    else if attrs.callNotification and attrs.callNotificationNumbers?.trim().length < 3
      error = 'call notification'
    if error
      return "Please enter a #{error} number"


module.exports = UserModel
