import React from 'react'
import { StyledTable } from './styles'

export type TableProps = {
  children: React.ReactNode
  $shadedRows?: boolean
  $shadedHead?: boolean
  className?: string
}

export const RdnaTable = ({ children, $shadedRows = false, $shadedHead = false, className = '' }: TableProps) => {
  return (
    <StyledTable
      className={`ringdna-table ${className}`}
      data-testid="ringdna-table"
      $shadedRows={$shadedRows}
      $shadedHead={$shadedHead}
    >
      {children}
    </StyledTable>
  )
}
