import React, { useEffect } from 'react'
import { useGetSources } from '../api'
import { streamAtom } from '../../index'
import { formatMessage } from '../utils'
import { useAtom } from 'jotai'
import { StreamProps } from './index'

type SourceProps = Omit<StreamProps, 'response' | 'apiBase'>
export default function StreamSources({ name, apiPath, queryParams, input }: SourceProps) {
  const [streams, setStreams] = useAtom(streamAtom)
  const sourceString = streams[`source-${name}`]
  const [sourceData] = useGetSources({
    meta: {
      apiPath: `${apiPath}/sources`
    },
    query: {
      input,
      ...queryParams
    }
  })

  useEffect(() => {
    if (sourceData) {
      const sourceUrls = sourceData.sources
      if (sourceUrls) {
        let sourceString = `Sources:\n\n`
        sourceUrls.forEach(sourceUrl => {
          sourceString += `\n - ${sourceUrl}`
        })
        setStreams(prev => ({ ...prev, [`source-${name}`]: sourceString }))
      }
    }
  }, [sourceData, name, setStreams])

  return <>{formatMessage(sourceString)}</>
}
