import { API_BASE_URL } from '../constants'
import { HttpMethods } from '@ringdna/common/src/client/constants'
import { NylasUser, UseNylasUsersParam, DeleteNylasUserResponse } from '../model'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

export const useNylasUsers = createUseFetch<Array<NylasUser>, UseNylasUsersParam>({
  key: 'fetch-nylas-users',
  path: API_BASE_URL,
  method: HttpMethods.Get,
  independent: true
})

type DeleteNylasUserParam = {
  id: number
  body: any
}

export const useDeleteNylasUser = createUseFetchAction<DeleteNylasUserResponse, DeleteNylasUserParam>({
  key: 'delete-nylas-user',
  path: params => `${API_BASE_URL}/${params.id}`,
  method: HttpMethods.Delete
})
