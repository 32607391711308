import React from 'react'
import styled from 'styled-components'

import CheckmarkIcon from '../../../assets/icons/svg/Checkmark'
import { Colors } from '../../../constants/colors'

import { FormControlTypeMap } from '@mui/material/FormControl/FormControl'
import { FormControl, FormControlLabel, FormHelperText, CheckboxProps, Checkbox } from '@mui/material'
import IconHelper from '../../../assets/icons/IconHelper'

type RdnaCheckboxProps = {
  label?: React.ReactNode
  helperText?: React.ReactNode
  required?: boolean
  error?: boolean
  shape?: 'square' | 'circle'
  value: boolean
  labelPlacement?: 'end' | 'start' | 'top'
  margin?: FormControlTypeMap['props']['margin']
  // we are reversing the params of this event to better match our form api
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
} & Omit<CheckboxProps, 'onChange'>

export default function RdnaCheckbox({
  label,
  helperText,
  required = false,
  error = false,
  disabled = false,
  shape = 'square',
  value,
  labelPlacement,
  onChange,
  className,
  ...restOfCheckboxProps
}: RdnaCheckboxProps) {
  return (
    <FormControl required={required} component="fieldset" error={error} disabled={disabled} className={className}>
      <FormControlLabel
        control={<RdnaCheckboxBare value={value} shape={shape} onChange={onChange} {...restOfCheckboxProps} />}
        label={label}
        labelPlacement={labelPlacement}
      />
      {!!helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const RdnaCheckboxBare = ({
  value,
  onChange,
  shape = 'square',
  ...restOfCheckboxProps
}: { onChange: RdnaCheckboxProps['onChange']; shape?: RdnaCheckboxProps['shape'] } & Omit<
  CheckboxProps,
  'onChange'
>) => {
  return (
    <StyledCheckbox
      value={value}
      // @ts-ignore
      checked={value}
      icon={shape === 'circle' ? <UncheckedIcon /> : undefined}
      checkedIcon={shape === 'circle' ? <CheckedIcon /> : undefined}
      onChange={
        onChange
          ? (event, checked) => {
              onChange(checked, event)
            }
          : undefined
      }
      {...restOfCheckboxProps}
    />
  )
}

const UncheckedIcon = () => {
  return <IconHelper size={18} shape={'circle'} variant={'outlined'} color={Colors.N80} />
}
const CheckedIcon = () => {
  return (
    <IconHelper icon={CheckmarkIcon} size={18} iconSize={18} shape={'circle'} variant={'filled'} color={Colors.V50} />
  )
}
