/* istanbul ignore file */
import {
  AlertsTargetingType,
  CallDirection,
  NotificationCategories,
  NotificationTriggeringType,
  Notify,
  NotifyType,
  TriggeringScope
} from '../../../types/moments'
import { UserLicenses } from '../../../types/user-settings'

export type PicklistItem = {
  id: number
  displayName?: string
  name?: string
  email?: string
  userName?: string
}

export type SFPicklistItem = {
  id: number
  name?: string
}
export type ChecklistItem = {
  label: string
  order?: number
  uuid?: string
  // eslint-disable-next-line camelcase
  completion_phrases?: string[]
}

export type TargetingItem = {
  id: number
  name: string
}

// PLAY APIS
export type Users = TargetingItem & {
  is_excluded: boolean
}
export type NotificationRuleData = {
  account_id: number
  applicable_call_direction: CallDirection
  contents: ContentData[]
  created_at: string
  created_by: TargetingItem
  duration: number
  editable: boolean
  enabled: boolean
  id?: number
  name: string
  phrases: string
  pin_order: number | null
  problem_statement: string | null
  profiles: TargetingItem[]
  receiver: Notify
  roles: TargetingItem[]
  scenarios: CoachScenario[]
  spoken_by: TriggeringScope
  target: AlertsTargetingType | null
  teams: TargetingItem[]
  template: boolean
  trigger_method: NotificationTriggeringType
  updated_at: string
  updated_by: TargetingItem
  users: Users[]
  // client added fields
  parsedScenarios?: ParsedScenario[]
  excludeUsers: Users[]
  hasSalesforceScenario?: boolean
  salesforceScenarioTypes?: SalesforceParentType[]
  textContent?: ContentFormData | null
}

export type ContentData = {
  account_id: number
  categories: { name: NotificationCategories | ''; id?: number | null }[]
  checklist: ChecklistItem[] | null
  completion_phrases: string[] | null
  created_at: string
  created_by?: TargetingItem
  editable: boolean
  id?: number
  link: string | null
  link_label: string | null
  name: string
  notification_rules: TargetingItem[]
  severity: NotifyType
  template: boolean
  text: string
  updated_at: string
  updated_by: TargetingItem
  // client added fields
  category: NotificationCategories | ''
}

export type ContentFormData = Omit<
  ContentData,
  'updated_by' | 'created_at' | 'account_id' | 'template' | 'editable' | 'updated_at' | 'categories'
> & {
  checklist: ChecklistItem[]
}
export type NotificationFormData = Omit<
  NotificationRuleData,
  'updated_by' | 'created_at' | 'account_id' | 'template' | 'editable' | 'created_by'
>

export type Conditions = {
  formula: Formula | string
  criteria: Criteria[]
}

export type CoachScenario = {
  id?: number
  account_id?: number
  name: string
  notification_rules?: TargetingItem[]
  conditions: string
}

export type ParsedScenario = Omit<CoachScenario, 'conditions'> & {
  conditions: Conditions
}

export enum Step {
  NOTIFICATION = 'notification',
  CONTENT_FORM = 'contentForm',
  CONTENT_LIST = 'contentList',
  SCENARIOS_FORM = 'scenariosForm',
  SCENARIOS_LIST = 'scenariosList',
  TRIGGERING_CRITERIA = 'triggeringCriteria'
}

export type MomentsUserSettings = {
  userId: number
  userEmail: string
  userName: string
  accountId: number
  licenses: UserLicenses
  accountName: string
}

export enum TriggeringType {
  PHRASES = 'Phrases',
  CONVERSATION_ETIQUETTE = 'Conversation Etiquette',
  TIME = 'Time'
}

export type Criteria = {
  fieldParentType: SalesforceParentType
  fieldDataType: SalesforceDataType | ''
  fieldName: string
  operation: string
  value: string | string[]
  id?: number
}

export enum Formula {
  AND = 'AND',
  OR = 'OR',
  CUSTOM = 'CUSTOM'
}

export enum SalesforceParentType {
  ACCOUNT = 'Account',
  CAMPAIGN = 'Campaign',
  CASE = 'Case',
  EVENT = 'Event',
  LEAD = 'Lead',
  CONTACT = 'Contact',
  OPPORTUNITY = 'Opportunity',
  TASK = 'Task'
}

export enum SalesforceDataType {
  ADDRESS = 'address',
  BOOLEAN = 'boolean',
  CURRENCY = 'currency',
  DATE = 'date',
  DATE_TIME = 'datetime',
  DOUBLE = 'double',
  EMAIL = 'email',
  INTEGER = 'int',
  MULTI_PICKLIST = 'multipicklist',
  NUMBER = 'number',
  PERCENT = 'percent',
  PHONE = 'phone',
  PICKLIST = 'picklist',
  REFERENCE = 'reference',
  STRING = 'string',
  TEXTAREA = 'textarea',
  TIME = 'time',
  URL = 'url'
}

export enum SalesforceDataOperators {
  AFTER = 'after',
  BEFORE = 'before',
  BETWEEN = 'between',
  BLANK = 'is-blank',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  GREATER_THAN = 'greater',
  GREATER_THAN_EQUAL = 'greater-equal',
  INCLUDES = 'includes',
  LESS_THAN = 'less',
  LESS_THAN_EQUAL = 'less-equal',
  NOT_CONTAIN = 'not-contain',
  NOT_EQUAL = 'not-equal',
  NOT_INCLUDE = 'not-include',
  NOT_WITHIN = 'not-within',
  ON_OR_AFTER = 'on-or-after',
  ON_OR_BEFORE = 'on-or-before',
  WITHIN = 'within'
}

export type OptionData = { label: string; value: string }

export type AutoCompleteOptionData = { label: string; value: number }
