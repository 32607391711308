import React, { useMemo } from 'react'
import styled from 'styled-components'

// utils
import { useAccount } from '../../../../store/api/accounts-api'
import { useUser } from '../../../common/services/UserContext'
import {
  isCallRecordingDisabledSelector,
  inboundCallRecordingAnnouncementUrlSelector,
  isInboundCallRecordingAnnouncementSelector,
  isOutboundCallRecordingAnnouncementSelector,
  outboundCallRecordingAnnouncementUrlSelector,
  useCallRecordingState,
  isOutboundCallRecordingAnnouncementAutomaticSelector
} from '../../utils/contextReducer'

// components
import RdnaText from '@ringdna/common/src/components/RdnaText'
import CallRecordingWarningBox from './components/CallRecordingWarningBox'
import AnnouncementFilePicker from './components/AnnouncementFilePicker'
import CallTypeDropdown from './components/CallTypeDropdown'
import CallRecordingToggle from './components/CallRecordingToggle'
import SaveAllSettingsButton from '../SaveAllSettingsButton'
import { ToggleRowContainer } from '../styles'

type OverviewSubTabProps = {
  accountId: string
  onSave: (callRecordingOverrideOption: string, accountCallRecordingLocked: boolean) => void
}

export default React.memo(OverviewSubTab)
function OverviewSubTab({ accountId, onSave }: OverviewSubTabProps) {
  useAccount({ meta: { id: accountId } })

  return (
    <Container>
      <CallRecordingWarningBox />
      <RdnaText variant="body2">
        Enable the creation of call recordings for inbound calls, outbound calls, both or none. Additionally, you may
        upload custom announcements to be played at the start of your inbound and outbound calls (e.g. “This call will
        be recorded for training purposes”).
      </RdnaText>
      <CallTypeDropdown />
      <InBoundToggle />
      <OutBoundToggle />
      <SaveAllSettingsButton onSaveProp={onSave} />
    </Container>
  )
}

const InBoundToggle = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const inboundCallRecordingAnnouncement = useMemo(() => isInboundCallRecordingAnnouncementSelector(state), [state])
  const inboundCallRecordingAnnouncementUrl = useMemo(() => inboundCallRecordingAnnouncementUrlSelector(state), [state])

  return (
    <Container>
      <MainText variant="body2">Would you like to use an inbound call recording announcement?</MainText>
      <SubText variant="body1">
        You can use our default recording or select custom to upload your own recording.
      </SubText>
      <ToggleRowContainer>
        <CallRecordingToggle
          isDisabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          isChecked={inboundCallRecordingAnnouncement}
          accountSetting={'inboundCallRecordingAnnouncement'}
        />
        <AnnouncementFilePicker
          isDisabled={!isEditingAllowed || !inboundCallRecordingAnnouncement || isCallRecordingDisabledSelector(state)}
          accountSetting="inboundCallRecordingAnnouncementUrl"
          existingFile={inboundCallRecordingAnnouncementUrl}
          mbFileSizeLimit={10}
        />
      </ToggleRowContainer>
    </Container>
  )
})

const OutBoundToggle = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const outboundCallRecordingAnnouncement = useMemo(() => isOutboundCallRecordingAnnouncementSelector(state), [state])
  const outboundCallRecordingAnnouncementUrl = useMemo(
    () => outboundCallRecordingAnnouncementUrlSelector(state),
    [state]
  )
  const outboundCallRecordingAnnouncementAutomatic = useMemo(
    () => isOutboundCallRecordingAnnouncementAutomaticSelector(state),
    [state]
  )

  return (
    <Container>
      <MainText variant="body2">Would you like to use an outbound call recording announcement?</MainText>
      <SubText variant="body1">
        You can use our default recording or select custom to upload your own recording.
      </SubText>
      <ToggleRowContainer>
        <CallRecordingToggle
          isDisabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          isChecked={outboundCallRecordingAnnouncement}
          accountSetting={'outboundCallRecordingAnnouncement'}
        />
        <AnnouncementFilePicker
          isDisabled={!isEditingAllowed || !outboundCallRecordingAnnouncement || isCallRecordingDisabledSelector(state)}
          accountSetting="outboundCallRecordingAnnouncementUrl"
          existingFile={outboundCallRecordingAnnouncementUrl}
          mbFileSizeLimit={10}
        />
      </ToggleRowContainer>
      <MainText variant="body2">Do you want to play outbound announcement automatically?</MainText>
      <CallRecordingToggle
        isDisabled={!isEditingAllowed || isCallRecordingDisabledSelector(state) || !outboundCallRecordingAnnouncement}
        isChecked={outboundCallRecordingAnnouncementAutomatic}
        accountSetting={'outboundCallRecordingAnnouncementAutomatic'}
      />
    </Container>
  )
})

const Container = styled.div`
  padding: 0;
`

const MainText = React.memo(styled(RdnaText)`
  margin: 38px 0 0;
  display: block;
`)
const SubText = React.memo(styled(RdnaText)`
  margin: 0 0 10px;
  display: block;
`)
