AddressCollection  = require('widgets/smart-numbers-widget/address-collection')
AddressStepModal   = require('features/call-tracking/address-step/address-step-view')
BackgridCell       = require('helpers/backgrid-cell')
BackgridRow        = require('helpers/backgrid-row')
BaseView           = require('base/view')
BaseCollection     = require('base/collection')
CallflowPickerView = require('widgets/callflow-picker/view')
CallTrackingHelper = require('features/call-tracking/helper')
OfflineModal       = require('./editor/view')
OfflineCallTrackingСollection = require('./collection')
OfflineCallTrackingModel      = require('./model')
PagedCollection    = require('base/paged-collection')
PagerView          = require('widgets/pager/view')
SourcePickerView   = require('widgets/source-picker/view')

class OfflinePagedCollection extends PagedCollection
  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    result.push(offline) for offline in response.results
    result

class OfflineView extends BaseView
  template: require('./template')

  regions:
    grid    : 'div.grid'
    pager   : 'span.pager'
    modal   : 'div.modal-container'
    callFlowPicker  : '.call-flow-picker'
    sourcePicker    : '.source-picker'
    addressStepModal: 'div.address-step-container'

  ui:
    search  : 'button.search'
    searchName  : 'input.searchName'

  events:
    'click @ui.search' : 'search'
    'click a.add' : 'create'

  bindings:
     '.add': "classes:{'disabled':addButtonDisabled}"

  computeds:
    isSupport:
      get: -> @user().isSupport()

  initialize: (options) =>
    { @qs, @accountId } = options
    @qs.setFragment('call-tracking/offline')
    @viewModel = new Backbone.Model(
      addButtonDisabled: false
    )

  onRender: =>
    @offlineCollection = new OfflineCallTrackingСollection()
    @callFlowPickerView = new CallflowPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      label: 'none'
    )
    @sourcePickerView = new SourcePickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
    )
    @showChildView('callFlowPicker', @callFlowPickerView)
    @showChildView('sourcePicker', @sourcePickerView)
    @viewModel.set('addButtonDisabled', @user().getSelectedSupportAccountId(true)?.toString() isnt @accountId.toString())

    @qs.bind(
      searchName: @ui.searchName
      source: @sourcePickerView
      callFlowId: @callFlowPickerView
    )
    @qs.restore()
    @search()

  onDestroy: =>
    @stopListening()

  search: =>
    @collection = new OfflinePagedCollection(
      page: @qs.get('page')
      queryParams:
        searchName: @ui.searchName.val().trim()
        source: @sourcePickerView.val()
        callFlowId: @callFlowPickerView.val()
        accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      url: 'offline'
    )

    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, qs: @qs))
    @collection.fetch(reset: true)

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:smartNumber.id',
      label : ':smartNumber.number',
      attrs : ['smartNumber.id', 'smartNumber.number'])

    flowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    columns = [
      {label: 'Account',         name: 'account.name',   cell: 'string', renderable: @getBinding('isSupport')}
      {label: 'Name',            name: 'name',           cell: 'string'}
      {label: 'Source',          name: 'source',         cell: 'string'}
      {label: 'Call Flow',       name: 'callFlow',       cell: flowCell}
      {label: 'Number',          name: 'number',         cell: numbCell}
      {label: 'Campaign',        name: 'campaign',       cell: 'string'}
      {label: 'Date Created',    name: 'createdDate',    cell: datetime}
      {label: 'Date Last Used',  name: 'smartNumber.dateLastUsed', cell: datetime}
    ]

    new Backgrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No offline call tracking found.'
    )

  initModal: =>
    @offlineCollection.reset([new OfflineCallTrackingModel()])
    @offlineModal = new OfflineModal(
      accountId: @accountId
      collection: @offlineCollection
    )
    @listenTo(@offlineCollection, 'newCallTracking', (offline) =>
      offline['name'] = offline.channel
      @collection?.unshift(offline)
      @collection?.pop() if @collection.length > 30
      @collection.state.totalRecords += 1
      @collection.trigger('pager:state:changed', @collection.state)
      toastr.info('Offline Call Tracking saved.')
      @search() if @collection.length > 30
    ) if not @offlineCollection?._events?.newCallTracking
    @listenTo(@offlineModal, 'openAddressStepModal', => @initAddressStepModal())

  create: =>
    @initModal()
    @showChildView('modal', @offlineModal)

  initAddressStepModal: =>
    @addressCollection = new AddressCollection()
    addressStepModal = new AddressStepModal(
      accountId: @accountId
      addressCollection: @addressCollection
      title: 'Add Offline Call Tracking'
      collection: @offlineCollection
    )
    @showChildView('addressStepModal', addressStepModal)
    $('#address-step-modal').modal()
    @listenTo(addressStepModal, 'repeatCreateCallTrackings', (ladda, notCreatedAddresses) =>
      notAddressesNumbers =
        CallTrackingHelper.filterRepeatCollection(notCreatedAddresses, @offlineCollection) if notCreatedAddresses.length isnt 0
      @offlineCollection.createOfflines(@accountId, notAddressesNumbers, =>
        ladda.stop()
        createdAddresses = @addressCollection.where(created: true)
        @addressCollection.remove(createdAddresses) if createdAddresses.length isnt 0
        $('#address-step-modal').modal('hide') if not notAddressesNumbers or notAddressesNumbers.length is 0
      ))

module.exports = OfflineView
