import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { isFeatureVisibilitiesSelector, useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader, SectionSubHeader } from '../styles'
import CallsTelephonyIcon from '@ringdna/common/src/assets/icons/svg/CallsTelephony'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import { FeatureType } from '@ringdna/common/src/types/dialer-settings'
import SupportSettingFileRow from '../components/SupportSettingFileRow'
import SupportSettingInputToggleRow from '../components/SupportSettingInputToggleRow'

export default CallsTelephonySettings
function CallsTelephonySettings() {
  const state = useSupportSettingsState()

  const conference = 'settings.conference'
  const directConnect = 'settings.directConnect'
  const dynamicCallFlows = 'settings.dynamicCallFlows'
  const hideQueueCalls = 'settings.hideQueueCalls'
  const callQueueCheck = 'settings.callQueueCheckEnabled'
  const callQueueTimeout = 'settings.callQueueCheckTimeout'
  const soundDisconnect = 'settings.soundDisconnect'
  const soundIncoming = 'settings.soundIncoming'
  const soundOutgoing = 'settings.soundOutgoing'
  const waitOnConferenceEnter = 'settings.waitOnConferenceEnter'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={CallsTelephonyIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>Calls & Telephony Settings</RdnaText>
      </SectionHeader>
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          Call Flow and Call Queue Settings
        </RdnaText>
      </SectionSubHeader>
      <SupportSettingToggleRow
        title={'Dynamic Call Flows'}
        isChecked={state.accountSettings[dynamicCallFlows]}
        setting={dynamicCallFlows}
      />
      <SupportSettingToggleRow
        title={'Call Queues'}
        description={'Enable the use of Call Queues.'}
        isChecked={isFeatureVisibilitiesSelector(state, FeatureType.CallQueues)}
        setting={FeatureType.CallQueues}
      />
      <SupportSettingFileRow
        title={'Queues Announcement'}
        existingFile={state.accountSettings['settings.waitUrlForQueues']}
        accountSetting={'waitUrlForQueues'}
      />
      <SupportSettingFileRow
        title={'Inbound Call Tone'}
        description={'Override the default call tone.'}
        existingFile={state.accountSettings['settings.inboundCallTone']}
        accountSetting={'inboundCallTone'}
      />
      <SupportSettingToggleRow
        title={'Hide Queue Call History'}
        isChecked={state.accountSettings[hideQueueCalls]}
        setting={hideQueueCalls}
      />
      <SupportSettingInputToggleRow
        title={'Call Queue Check'}
        isChecked={state.accountSettings[callQueueCheck]}
        inputPlaceholder={'Input Call Queue Check Timeout'}
        settingInput={callQueueTimeout}
        settingToggle={callQueueCheck}
        inputValue={state.accountSettings[callQueueTimeout]}
      />
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          Telephony Settings
        </RdnaText>
      </SectionSubHeader>
      <SupportSettingToggleRow
        title={'Conference'}
        isChecked={state.accountSettings[conference]}
        setting={conference}
      />
      <SupportSettingToggleRow
        title={'Wait On Conference Enter'}
        isChecked={state.accountSettings[waitOnConferenceEnter]}
        setting={waitOnConferenceEnter}
      />
      <SupportSettingToggleRow
        title={'Direct Connect'}
        isChecked={state.accountSettings[directConnect]}
        setting={directConnect}
      />
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          Softphone Sound Settings
        </RdnaText>
      </SectionSubHeader>
      <SupportSettingToggleRow
        title={'Disconnect'}
        isChecked={state.accountSettings[soundDisconnect]}
        setting={soundDisconnect}
      />
      <SupportSettingToggleRow
        title={'Incoming'}
        isChecked={state.accountSettings[soundIncoming]}
        setting={soundIncoming}
      />
      <SupportSettingToggleRow
        title={'Outgoing'}
        isChecked={state.accountSettings[soundOutgoing]}
        setting={soundOutgoing}
      />
    </Section>
  )
}
