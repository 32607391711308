import React, { useMemo, useState } from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { SectionSubHeader } from '../../styles'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import {
  AdditionalFilter,
  useAdditionalFilters,
  usePutAdditionalFilters
} from '../../../../../store/api/account-integrations-api'
import { Column } from 'react-table'
import { IconButton } from '@mui/material'
import DeleteIcon from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'
import CreateFilterModal from './CreateFilterModal'

type Props = {
  accountId: number
}

export default AdditionalFilters
function AdditionalFilters({ accountId }: Props) {
  const [filters, , loading, refetch] = useAdditionalFilters({ meta: { id: accountId } })
  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false)
  const [filterToDelete, setFilterToDelete] = useState<AdditionalFilter>()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const updateAdditionalFilters = usePutAdditionalFilters()

  function openDeleteDialog(filter: AdditionalFilter) {
    setFilterToDelete(filter)
    setDeleteModalIsOpen(true)
  }

  const onDeleteModalClose = (confirmed: boolean) => {
    if (confirmed && filterToDelete) {
      filterToDelete.deleted = true
      removeFilter(filterToDelete)
    }
    setDeleteModalIsOpen(false)
  }

  const onAddModalClose = (reload: boolean) => {
    setAddModalIsOpen(false)
    reload && refetch()
  }

  const removeFilter = async (filter: AdditionalFilter) => {
    try {
      await updateAdditionalFilters({
        meta: { id: accountId },
        body: serializeJsonIntoPlay({ filters: [{ ...filter, 'account.id': accountId }] })
      })
      refetch()
    } catch (error) {
      toastr.error(error.message)
    }
  }

  const columns = useMemo<Column<AdditionalFilter>[]>(
    () => [
      { Header: 'Record Type', accessor: 'sObjectType' },
      { Header: 'Query', accessor: 'query' },
      {
        Header: 'Remove',
        accessor: filter => filter,
        width: 30,
        Cell: function createdCell(data: { value: AdditionalFilter }) {
          return (
            <IconButton aria-label="remove-filter" onClick={() => openDeleteDialog(data.value)}>
              <DeleteIcon title={'Remove Filter'} />
            </IconButton>
          )
        }
      }
    ],
    []
  )

  return (
    <>
      <SectionSubHeader>
        <RdnaText bold>Additional Filters</RdnaText>
        <RdnaButton text={'Add Filter'} onClick={() => setAddModalIsOpen(true)} />
      </SectionSubHeader>
      <RdnaSmartTable
        columns={columns}
        data={filters || []}
        isFetching={!!loading}
        globalSearchLabel={'Search'}
        paginationSize={10}
        noResultState={{ title: 'No filters have been defined for this account.', subtitle: '' }}
      />
      <CreateFilterModal accountId={accountId} isModalOpen={addModalIsOpen} closeModal={onAddModalClose} />
      <RdnaModal
        open={deleteModalIsOpen}
        heading="Warning"
        onClose={() => onDeleteModalClose(false)}
        confirmButtonText="Delete"
        onConfirm={() => onDeleteModalClose(true)}
      >
        {filterToDelete && <RdnaText>{`You are about to remove the ${filterToDelete.sObjectType} filter?`}</RdnaText>}
      </RdnaModal>
    </>
  )
}
