BaseCollection = require('base/collection')
AddressModel = require('./address-model')

class AddressCollection extends BaseCollection
  url: -> 'smartNumbers/addresses'

  setParams: (options) ->
    { @accountId } = options
    @

  comparator: (model) =>
    model.get('countryCode')

  toJSON: =>
    fields = @toPlayJSON('addresses')
    fields['accountId'] = @accountId
    fields
  model: AddressModel

module.exports = AddressCollection