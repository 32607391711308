BaseView = require('base/view')
UserModel = require('features/users/show/settings/model')

DEFAULT_PLACEHOLDER = 'User name'

# There are a decent amount of methods that could be put in BaseView
# or otherwise extracted.

class Picklist extends Backbone.Collection
  url: 'users/picklist'

class SupervisorPicklist extends Backbone.Collection
  url: 'users/supervisorPicklist'

class UserPickerView extends BaseView
  template: require('./template')
  renderListOnSelect: false
  isSupervisorList: false

  ui:
    picker: '.selector'

  events:
    'change @ui.picker': 'go'

  bindings:
    '.selector': 'classes:{"hide-arrow": not(fullList)}'

  computeds:
    fullList:
      get: -> @preloadUser

  initialize: (options) =>
    {@accountId, @preloadUser, @forceLoad, @placeholder} = options
    @placeholder = DEFAULT_PLACEHOLDER unless @placeholder
    @picklist = null

  onRender: =>
    picklist = if @isSupervisorList
                 new SupervisorPicklist()
               else
                 new Picklist()

    @ui.picker.selectize(
      maxItems: 1
      preload: true
      placeholder: @placeholder
      load: (query, callback) =>
        return if query?.length < 2 and not @forceLoad

        unless @renderListOnSelect
          if @preloadUser
            return if @picklist

        query = '  ' if @forceLoad and query?.length < 2
        @forceLoad = false
        @picklist = picklist
        @picklist
          .fetch(data: {name: query, accountId: @accountId})
          .done((response) => callback(response))

      render:
        option: (item, escape) ->
          "<div>#{escape(item.text)} <span class='picker-value'>#{escape(item.value)}</span></div>"
    )
    @selectize = @ui.picker[0].selectize
    @selectize.on('blur', => @trigger('blur'))

  go: =>
    if @ui.picker.val()
      @trigger('selected', @ui.picker.val())
    else
      @trigger('deselected')

  addNoneValue: =>
    return if @ui.picker.val()
    @selectize.addOption(text: '(none)', value: '')
    @selectize.addItem('', true)

  addDefaultValue: =>
    return if @ui.picker.val()
    @selectize.addOption(text: 'Default', value: '')
    @selectize.addItem('', true)

  disable: =>
    @selectize.disable()

  enable: =>
    @selectize.enable()

  # TODO: Remove this.
  populate: (user) =>
    return if @ui.picker.val()
    @selectize.addOption(text: "#{user.get('displayName')}", value: user.id)
    @selectize.addItem(user.id, true)

  # HACK: This method does two different things. When passed no args, it'll return the selected userId. When passed
  # a userId, it'll look up the user name and populate the widget.  A little wonky, but it's convenient to mimic # the standard form element val() api.
  val: (userId) =>
    if arguments.length is 0
      return @ui.picker.val?()
    else
      @selectize.addOption(text: '', value: userId)
      @selectize.addItem(userId, true)

      user = new UserModel(id: userId)
      user.fetch().done(=>
        label = "#{user.get('displayName')}"
        @selectize.updateOption(userId, {text: label, value: userId})
      )

  clearOptions: =>
    @selectize.clearOptions()

module.exports = UserPickerView
