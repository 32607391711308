BaseView = require('base/view')

class OutboundNumberItemView extends BaseView

  template: require('./item-template')

  bindings:
    '.account-id'      : 'text:accountId'
    '.message'         : 'text:message'
    '.number-label'    : 'text:label'
    '.number'          : 'text:number'
    '.status'          : 'text:status'
    '.user-id'         : 'text:userId'

  className: =>
    if @model.get('status') is 'Failed'
      'row number-item failed'
    else
      'row number-item'

module.exports = OutboundNumberItemView
