import React from 'react'
import styled from 'styled-components'

// utils
import { SmsTemplateModalProps } from '../types'
import { SMS_TEMPLATE_STR } from '../strings'

// components
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { getSmsCount } from '@ringdna/common/src/utils/smsUtils'
import RdnaAutoComplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import { usePlayPicklist } from '@ringdna/common/src/components/RdnaReports/api'
import FilePicker from './FilePicker'
import { SmsTemplateMedia } from '@ringdna/common/src/types/sms-template'

const SmsTemplateModal = ({
  accountId,
  open,
  onClose,
  onConfirm,
  formData,
  setFormData,
  confirmButtonDisabled
}: SmsTemplateModalProps) => {
  const smsCountWarning = `${SMS_TEMPLATE_STR.characters}: ${formData.template.length} ${
    SMS_TEMPLATE_STR.smsCount
  }: ${getSmsCount(formData.template) + formData.files.length}`

  const [teamsPayload, , loading] = usePlayPicklist({
    meta: { accountId: parseInt(accountId), name: '', type: 'teams', pageSize: 1000 }
  })

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: { value: string; text: string }) => {
    return <li {...props} key={option.value}> {option.text} </li>
  }

  const handleOnFileChange = (files: SmsTemplateMedia[]) => {
    setFormData({ ...formData, files: files })
  }

  return (
    <RdnaModal
      open={open}
      cancelButtonText={SMS_TEMPLATE_STR.cancel}
      confirmButtonText={SMS_TEMPLATE_STR.submit}
      heading={SMS_TEMPLATE_STR.smsTemplate}
      onClose={onClose}
      onConfirm={() => {
        onConfirm()
      }}
      confirmButtonDisabled={!formData.name || !formData.template || confirmButtonDisabled}
    >
      <StyledSection>
        <RdnaTextInput
          name={'sms-template-name'}
          label={SMS_TEMPLATE_STR.templateName}
          placeholder={SMS_TEMPLATE_STR.enterTemplateName}
          required
          onChange={e => {
            setFormData({ ...formData, name: e.target.value })
            if (!e.target.value.trim()) {
              toastr.error(SMS_TEMPLATE_STR.nameFieldCannotBeBlank)
            }
          }}
          value={formData.name}
        />
      </StyledSection>
      <StyledSection>
        <RdnaTextInput
          required
          label={SMS_TEMPLATE_STR.theSmsMessage}
          placeholder={SMS_TEMPLATE_STR.enterTheSmsMessage}
          multiline
          name={'template'}
          inputProps={{
            maxLength: 10000
          }}
          onChange={e => {
            setFormData({ ...formData, template: e.target.value })
            if (!e.target.value.trim()) {
              toastr.error(SMS_TEMPLATE_STR.theNotificationFieldCannotBeBlank)
            }
          }}
          value={formData.template}
        />
        <FilePicker
          name="file-picker"
          onChange={handleOnFileChange}
          onError={error => toastr.error(error)}
          existingFiles={formData.files}
        />
        <RdnaText variant="body1">{smsCountWarning}</RdnaText>
      </StyledSection>
      <StyledSection>
        <RdnaAutoComplete
          className={'teams-search-field'}
          placeholder={'Default (All teams)'}
          label={'Teams'}
          id={'teams-search-field'}
          data-testid={'autocomplete-Teams'}
          options={teamsPayload || []}
          multiple={true}
          showChips={true}
          onChipRemove={value =>
            setFormData({ ...formData, teams: formData.teams.filter(e => e.value !== value.value) })
          }
          showOnlyMultipleCount={true}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={option => option.text}
          optionHeight={80}
          inputHelperText={SMS_TEMPLATE_STR.selectWhichTeams}
          onChange={(event, value) => setFormData({ ...formData, teams: value })}
          value={formData.teams}
          renderOption={renderOption}
          loading={!!loading}
        />
      </StyledSection>
    </RdnaModal>
  )
}

const StyledSection = styled.section`
  margin: 0 0 18px;
`

export default SmsTemplateModal
