import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Colors } from '../../constants/colors'
import LeftNavContent from './LeftNavContent'
import { UserLicense, AppLicense, UserSettings } from '../../types/user-settings'
import { flags } from '../../utils/feature-flag/featureFlag'

export type NavContextValue = {
  /** Controls which route in the nav is active  */
  activeRouteId: string | number
  /** Controls which subroute in the nav is active  */
  activeSubrouteId?: string | number
  /** Controls which route in the nav is clicked  */
  clickedRouteId?: string | number
  /** List of all of the nav items in the nav */
  routes: NavigationItem[]
  /** Whether to use react-router for navigation or just fire an event of the id onClick */
  enableRouting?: boolean
  /** Event fired when clicking a route to notify parent what route was clicked. */
  onClickRoute?: (id: string | number) => void
  /** Event fired when selecting a route to notify parent what route was selected. */
  onRouteSelect?: (id: string | number, title: string) => void
  /** Event fired when selecting a route to notify parent which subroute was selected. */
  onSubrouteSelect?: (id: string | number, title: string, parentTitle: string) => void
  /** Hide top level of navigation with icons. This is a temp prop until we can fully use nav for all routes */
  hideMainNav?: boolean
  /** Whether to automatically expand subnav to show subroutes. Otherwise will only expand when that route is selected */
  showAllSubroutes?: boolean
  /** On load, load the first subroute or wait til user action to navigate to first route. */
  initToFirstSubroute?: boolean
  /** If true nav will use react router's Link element  */
  isReactBrowserRouter?: boolean
  minimizedNav?: boolean
  setMinimizedNav?: (isCollapsed: boolean) => void
  noMargin?: boolean
  /** Settings for the current user */
  userSettings?: NavUserSettings
  showVerticalLines?: boolean
  showHorizontalLines?: boolean
  isCollapsed?: boolean
  useTogglePropInsteadOfState?: boolean
  onToggle?: (isCollapsed: boolean) => void
}

export type NavUserSettings = Pick<UserSettings, 'hasNewProfiles'>

export type NavigationItem = {
  /** String that is displayed in the nav  */
  title: string
  /** String that is displayed on page below title **/
  subTitle?: ReactNode
  /** Id for each route to be identified and also used in the url  */
  id: string | number
  /** Icon displayed next to title. Used for top level of navigation only.  */
  Icon?: (props: { size?: number; color?: Colors }) => JSX.Element
  AfterLabelIcon?: () => JSX.Element
  dataTab?: string
  /** Subroutes of this nav item. This is recursive so the nav can be many levels deep.  */
  subroutes?: NavigationItem[]
  /** Full url of nav item */
  fullUrl?: string
  /** The name of the section for a group of routes */
  sectionTitle?: boolean
  caiManager?: boolean
  // gate route by app allowed to see route
  appLicenses?: AppLicense[]
  // gate route by role allowed to see route
  userLicenses?: UserLicense[]
  ldFlag?: keyof typeof flags
  // hide when ld flag is true
  invertLdFlagValue?: boolean
  nonLicensed?: AppLicense
  clickableLink?: boolean
}

const NavContext = React.createContext<NavContextValue | undefined>(undefined)

const RdnaLeftNav = ({
  isReactBrowserRouter = false,
  activeRouteId,
  activeSubrouteId,
  clickedRouteId,
  enableRouting,
  onClickRoute,
  onSubrouteSelect,
  onRouteSelect,
  routes,
  noMargin,
  hideMainNav,
  showAllSubroutes,
  initToFirstSubroute,
  userSettings,
  showVerticalLines,
  showHorizontalLines,
  isCollapsed,
  useTogglePropInsteadOfState,
  onToggle
}: NavContextValue) => {
  const [minimizedNav, setMinimizedNav] = useState(isCollapsed)

  useEffect(() => {
    if (useTogglePropInsteadOfState) {
      setMinimizedNav(isCollapsed)
      onToggle && onToggle(isCollapsed as boolean)
    }
  }, [isCollapsed, useTogglePropInsteadOfState, setMinimizedNav, onToggle])

  const value = useMemo(() => {
    return {
      isReactBrowserRouter,
      activeRouteId,
      activeSubrouteId,
      clickedRouteId,
      enableRouting,
      onClickRoute,
      onSubrouteSelect,
      onRouteSelect,
      routes,
      hideMainNav,
      showAllSubroutes,
      minimizedNav,
      setMinimizedNav,
      userSettings,
      showVerticalLines,
      showHorizontalLines
    }
  }, [
    isReactBrowserRouter,
    activeRouteId,
    activeSubrouteId,
    clickedRouteId,
    enableRouting,
    onClickRoute,
    onSubrouteSelect,
    onRouteSelect,
    routes,
    hideMainNav,
    showAllSubroutes,
    minimizedNav,
    setMinimizedNav,
    userSettings,
    showVerticalLines,
    showHorizontalLines
  ])

  return (
    <NavContext.Provider value={value}>
      <LeftNavContent onToggle={onToggle} margin={noMargin} initToFirstSubroute={initToFirstSubroute} />
    </NavContext.Provider>
  )
}

export default RdnaLeftNav

export const useNavContext = () => {
  const value = useContext(NavContext)
  /* istanbul ignore next */
  if (!value) {
    /* istanbul ignore next */
    throw new Error('useNavContext must be used within NavProvider!')
  }
  return value
}
