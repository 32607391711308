PagedCollection   = require('base/paged-collection')

class NumbersCollection extends PagedCollection
  url: 'smartNumbers/reassign'

  state:
    pageSize: 5

  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    for number in response.results
      number.labelColumn = number.label
      result.push(number)
    result

module.exports = NumbersCollection
