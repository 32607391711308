BaseView  = require('base/view')
BaseModel = require('base/model')

class TimeZoneModel extends Backbone.Collection
  url: 'callFlows/timezones'

class TimeStepView extends BaseView

  template: require('./template')

  ui:
    pickerTimezone  : 'select.timezone'
    pickerHoliday   : 'select.holiday-schedule'

  bindings:
    'input[data-key="useHolidaySchedule"]'    : 'checked:useHoliday'
    'div.selectize-control.holiday-schedule'  : 'toggle:holidaySelector'
    'a[data-action="add-time"]'               : 'disabled:readOnly'
    'a[data-action="clear-time"]'             : 'disabled:readOnly'

  computeds:
    useHoliday:
      deps: ['args']
      get: (args) -> args['useHolidaySchedule'] is 'true'
    holidaySelector:
      get: -> @$('.use-holiday').is(':checked')
    readOnly:
      get: -> @callflow.get('readOnly')

  events:
    'click a[data-action="add-time"]'            : 'toggleTime'
    'click a[data-action="clear-time"]'          : 'toggleTime'
    'click input[data-key="useHolidaySchedule"]' : 'toggleUseHoliday'

  initialize: (options) ->
    @callflow = options.callflow

  onRender: (view) =>
    @ui.pickerTimezone.selectize(
      preload: true
      sortField: 'text'
      load: (query, callback) ->
        @settings.load = null
        picklist = new TimeZoneModel()
        picklist
          .fetch()
          .done((response) =>
            list = for value in response
              {
                value: value
                text: value
              }
            selectedTz = view.model.args().timezone
            if selectedTz and response.indexOf(selectedTz) is -1
              list.unshift({value: selectedTz, text: selectedTz})
            callback(list)
            @setValue(view.model.args().timezone)
          )
    )
    @ui.pickerHoliday.selectize(
      options      : view.callflow.get('account')?.holidaySchedules
      items        : [view.model.args()['holidayScheduleId']]
      valueField   : 'id'
      labelField   : 'name'
    )
    for key, value of @model.args()
      if value and value.toString().trim().length > 0 and (key.indexOf('from') > -1 or key.indexOf('to') > -1)
        p = @$("input[data-key=\"#{key}\"]").parent()
        p.removeClass('hidden')
        p.siblings('.closed').addClass('hidden')
    @applyBindings()
    @$('select.timezone')[0].selectize.disable() if @callflow.get('readOnly')
    @$('select.holiday-schedule')[0].selectize.disable() if @callflow.get('readOnly')

  toggleTime: (e) ->
    time = $(e.target).closest('div')
    time.addClass('hidden')
    day = time.data()['day']
    @model.args()["#{day}-from"] = ''
    @model.args()["#{day}-to"] = ''
    time.siblings("[data-day=#{day}]").removeClass('hidden')
    time.children('input').each((idx, child) ->
      $(child).val('')
    )

  toggleUseHoliday: (e) ->
    holidaySelector = @$('.selectize-control.holiday-schedule')
    if $(e.target).is(':checked') then holidaySelector.show() else holidaySelector.hide()

module.exports = TimeStepView