import defaultConfig, { Config } from './config.default'
import localConfig from './config.local'
import { ProductDomains } from '@ringdna/common/src/constants/productDomains'

const config: Config = {
  ...defaultConfig,
  ...localConfig
}

export { ProductDomains }
export default config
export { Env } from './config.default'
