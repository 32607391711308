BaseView = require('base/view')
BaseModel = require('base/model')

class CallDispositionModel extends BaseModel
  urlRoot: 'callDispositions'

  defaults:
    disposition: ''

  validate: (attrs) ->
    if not attrs.disposition.trim()
      return 'Please enter disposition.'
    return

class CallDispositionCollection extends Backbone.Collection
  model: CallDispositionModel
  url: 'callDispositions'

class AutoDispositionVoicemailDropsView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  bindings:
    '.selector': 'value:disposition'

  computeds:
    disposition:
      get: -> @accountSettings.get('voicemailDropDisposition')
      set: (e) ->
        id = if e == '' then null else parseInt(e)
        @accountSettings.set('voicemailDropDisposition', id: id)

  initialize: (options) ->
    { @dispositions, @accountSettings } = options
    @dispositions.on('add', (model) => @addDispositionOption(model))
    @dispositions.on('remove', (model) => @checkRemovedDisposition(model.get('id')))

  addDispositionOption: (model) =>
    @selectize.addOption(value: model.get('id'), text: model.get('disposition'))

  checkRemovedDisposition: (removedDispositionId) =>
    dropDispositionId = @accountSettings.get('voicemailDropDisposition') and @accountSettings.get('voicemailDropDisposition').id
    if removedDispositionId is dropDispositionId
      @accountSettings.set('voicemailDropDisposition', null)
      @selectize.addItem('', true)
    @selectize.removeOption(removedDispositionId)

  onRender: =>
    @initSelectize()

  initSelectize: =>
    @ui.picker.selectize(
      maxItems: 1
      onInitialize: =>
        selectize = @ui.picker[0].selectize

        @addNoneValue(selectize)
        selectize.addOption(value: model.get('id'), text: model.get('disposition') for model in @dispositions.models)
        selectize.addItem(@accountSettings.get('voicemailDropDisposition').id) if @accountSettings.get('voicemailDropDisposition')
    )
    @selectize = @ui.picker[0].selectize

  addNoneValue: (selectize) =>
    selectize.addOption(text: '(none)', value: '')
    selectize.addItem('', true)



module.exports = AutoDispositionVoicemailDropsView

