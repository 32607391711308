import {
  localProductDomains,
  devProductDomains,
  qaProductDomains,
  prodProductDomains,
  EnvDomains
} from '@ringdna/common/src/constants/productDomains'

export enum Env {
  Local = 'local',
  Dev = 'dev',
  Qa = 'qa',
  Prod = 'prod'
}

type EnvConfig = EnvDomains & {
  amplitudeKey: string
  apiDomain: string
  launchDarklyKey: string
  appDomain: string
  rootDomain: string
  pendoKey: string
  pubnubKey: string
  deployTarget: string
  salesforceId: string
  rdEnv: Env
}

export type Config = EnvConfig & {
  debugApiClient: boolean
  apiLabs: string
  apiGpu: string
}
// @ts-ignore
const envName = import.meta.env.MODE
// @ts-ignore
const isBuild = import.meta.env.PROD

let envDefaults: EnvConfig
if (envName === 'development' || envName === 'test') {
  envDefaults = {
    ...localProductDomains,
    appDomain: 'localhost',
    rootDomain: 'localhost',
    amplitudeKey: 'f4959f66a242577576bc485278c65bb8',
    launchDarklyKey: '5de5ad7ecd152607f833aee4',
    pendoKey: '1e0b9cf8-26ce-4c69-48dd-20a58e1c9154',
    pubnubKey: 'sub-c-4ed829d2-7621-11e9-90ac-5a6b801e0231',
    deployTarget: 'local',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Local
  }
} else if (envName === 'dev') {
  envDefaults = {
    ...devProductDomains,
    amplitudeKey: 'b7283c6c0675e28775bf732b1bf77a1e',
    launchDarklyKey: '60a2d7d682a78e0e8dc64e01',
    pendoKey: '1e0b9cf8-26ce-4c69-48dd-20a58e1c9154',
    appDomain: 'analytics-dev.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-47d02a56-b745-11eb-8cd6-ee35b8e5702f',
    deployTarget: 'dev',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Dev
  }
} else if (envName === 'qa') {
  envDefaults = {
    ...qaProductDomains,
    amplitudeKey: 'b7283c6c0675e28775bf732b1bf77a1e',
    launchDarklyKey: '5de5c3c74d6d4808450f184a',
    pendoKey: '1e0b9cf8-26ce-4c69-48dd-20a58e1c9154',
    appDomain: 'analytics-qa.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-51d5713a-7621-11e9-bb2b-0e7d3ff647bd',
    deployTarget: 'qa',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Qa
  }
} else if (envName === 'prod') {
  envDefaults = {
    ...prodProductDomains,
    amplitudeKey: '19a22afdee26365840631209759fdf3f',
    launchDarklyKey: '5de5ad7ecd152607f833aee5',
    pendoKey: '1e0b9cf8-26ce-4c69-48dd-20a58e1c9154',
    appDomain: 'analytics.revenue.io',
    rootDomain: 'revenue.io',
    pubnubKey: 'sub-c-55a444b2-7621-11e9-81d5-56c3556875f9',
    deployTarget: 'prod',
    salesforceId: 'https://na132.salesforce.com',
    rdEnv: Env.Prod
  }
} else {
  throw new Error(`Invalid RD_ENV value ${envName}`)
}

const defaultConfig: Config = {
  ...envDefaults,
  debugApiClient: !isBuild,
  apiLabs: 'https://lab.ringdna.net',
  apiGpu: 'https://gpu.ringdna.net'
}

export default defaultConfig
