BaseView = require('base/view')

class ConfirmDeletionModal extends BaseView

  template: require('./confirm-deletion-template')
  className: 'confirm-deletion-modal'

  ui:
    modal:  '#confirm-deletion-modal'

  bindings:
    'div.numbers'  : 'toggle:hasNumbers'

  computeds:
    hasNumbers:
      deps: ['numbers']
      get: (numbers) -> numbers.length > 0

  events:
    'click button.confirm-delete': 'confirmDelete'


  initialize: (options) =>
    numbers = []
    for number in options.user.smartNumbers
      numbers.push(
        id: number.id
        number: number.number
        type: number.type
      ) unless number.isDeleted
    @model = new Backbone.Model(
      displayName: options.user.displayName
      numbers: numbers
    )

  confirmDelete: =>
    numberIds = []
    numbers = []
    for number in @model.get('numbers')
      if @$("input[data-key=\"#{number.id}\"]").prop('checked')
        numberIds.push(number.id)
        numbers.push(number.number)
    @trigger('confirmDeletion', numberIds, numbers)
    @ui.modal.modal('hide')

module.exports = ConfirmDeletionModal
