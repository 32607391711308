import * as React from 'react'

import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function CallsTelephonyIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1581 18.864C12.3623 18.7959 12.5 18.6048 12.5 18.3896V16.5154C12.5 16.286 12.3439 16.086 12.1213 16.0303L11.1875 15.7969C11.0669 15.7667 10.962 15.6927 10.9048 15.5823C10.7029 15.1925 10.25 14.1234 10.25 12.375C10.25 10.6266 10.7029 9.55746 10.9048 9.16771C10.962 9.05726 11.0669 8.98328 11.1875 8.95312L12.1213 8.71968C12.3439 8.66404 12.5 8.46405 12.5 8.23461V6.36038C12.5 6.14516 12.3623 5.95409 12.1581 5.88604L10.5186 5.33952C10.3519 5.28398 10.1702 5.3192 10.0489 5.4462C9.43471 6.0892 7.625 8.32724 7.625 12.375C7.625 16.4228 9.43471 18.6608 10.0489 19.3038C10.1702 19.4308 10.3519 19.466 10.5186 19.4105L12.1581 18.864Z"
        stroke={color}
        strokeWidth="0.75"
      />
      <path
        d="M14.586 14.418L15.622 12.962L16.686 14.418L17.526 13.802L16.476 12.402L18.058 11.898L17.75 10.918L16.154 11.436V9.77H15.118V11.45L13.522 10.918L13.2 11.898L14.796 12.402L13.732 13.802L14.586 14.418Z"
        fill={color}
      />
    </svg>
  )
}

export default CallsTelephonyIcon
