import React from 'react'
import styled from 'styled-components'
import RdnaNotification from '@ringdna/common/src/components/RdnaNotification'
import { getAllQueryParams } from '@ringdna/common/src/utils/windowUtils'
import { capitalize } from 'lodash-es'

const OauthNotification = () => {
  const { action, provider, status, msg } = getAllQueryParams()
  if (!(status && provider && action)) return null

  const type = status !== 'success' ? 'error' : 'success'
  const _provider = capitalize(provider)
  const message =
    status !== 'success'
      ? !msg
        ? `There was a problem connecting the ${_provider} account.`
        : decodeURI(msg)
      : `The ${_provider} account was successfully connected.`

  return (
    <NotificationContainer>
      <RdnaNotification type={type} message={message} />
    </NotificationContainer>
  )
}

const NotificationContainer = styled.div`
  margin-bottom: 20px;
`

export default OauthNotification
