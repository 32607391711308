import React from 'react'
import styled from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export const SettingsItem = ({
  name,
  value,
  children,
  boldHeading = false
}: {
  name: string
  value?: string
  children?: JSX.Element
  boldHeading?: boolean
}) => (
  <StyledSetting>
    <RdnaText component="p" variant={boldHeading ? 'body2' : 'meta'} bold={boldHeading}>
      {name}
    </RdnaText>
    {children}
    {!children && (
      <RdnaText component="p" variant="body2">
        {value}
      </RdnaText>
    )}
  </StyledSetting>
)

const StyledSetting = styled.div`
  max-width: 440px;
  position: relative;
  padding-bottom: ${props => props.theme.spacing * 1.5}px;
  & > p:first-child {
    margin-bottom: ${props => props.theme.spacing * 0.5}px;
  }
  & > p:last-child {
    min-height: ${props => props.theme.typography.body2.lineHeight};
  }
  button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: transparent;
    padding: 0;
  }
  a {
    text-decoration: none;
    font-size: ${props => props.theme.typography.body2.fontSize}px;
    color: ${props => props.theme.palette.text.link};
    padding-bottom: ${props => props.theme.spacing * 1.5}px;
    display: block;
  }
`
