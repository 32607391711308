import React from 'react'
import {DeleteModalProps} from '../../types'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import {useCallNoteTemplatesDelete} from '../../api'

export default function ConfirmCallNotesTemplateDeleteModal({ open, onClose, id }: DeleteModalProps) {
  const { error } = useRdnaNotification()
  const deleteCallNoteTemplate = useCallNoteTemplatesDelete()
  async function handleDelete() {
    try {
      await deleteCallNoteTemplate({ meta: { id: id } })
    } catch (e) {
      error(getErrorMessage(e))
    }
    onClose(true)
  }
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Delete'}
      heading={`Delete Call Notes Template`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={handleDelete}
    >
      <RdnaText>{`Are you sure you want to delete this Call Notes Template. This action cannot be undone`}</RdnaText>
    </RdnaModal>
  )
}
