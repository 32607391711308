BaseCollection  = require('base/collection')
BaseModel       = require('base/model')

class CallFlowModel extends BaseModel

  url: -> "callFlows/#{@get('id')}/smartNumbers/#{@get('entityId')}"

class CallFlowCollection extends BaseCollection
  model: CallFlowModel

  setEntity: (entityId) =>
    _.each(@models, (model) ->
      model.set('entityId', entityId)
    )

module.exports = CallFlowCollection
