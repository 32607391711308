import React, { useMemo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import {CallScript, CallScriptEntity} from '../../types'
import {NO_RESULT_STATE} from './const'
import {useCallScriptsEntities} from '../../api'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { Column } from 'react-table'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import {
  useCallScriptsUpdate,
  useCallScriptsCreate,
} from '../../api'

type CallScriptProps = {
  open: boolean
  accountId: number
  callScript: CallScript
  setCallScript: (value: CallScript) => void
  onClose: (isFetch: boolean) => void
}

export default function CallScriptModal({open, accountId, callScript, setCallScript, onClose }: CallScriptProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error } = useRdnaNotification()
  const [data, setData] = useState<CallScriptEntity[]>([])
  const updateCallScript = useCallScriptsUpdate()
  const createCallScript = useCallScriptsCreate()
  const [payload,,loading,] = useCallScriptsEntities({meta:{accountId: accountId, scriptId: callScript.id}})

  useEffect(() => {
    if (payload && open) {
      const entities = payload.map((scriptEntity: CallScriptEntity) => ({
        ...scriptEntity,
        enabled: scriptEntity.resources.filter(entity => entity.type === 'Script').length > 0,
        originalEnabled: scriptEntity.resources.filter(entity => entity.type === 'Script').length > 0,
        number: scriptEntity.smartNumber.number
      }))
      setData(entities)
    }
  }, [payload, open])

  const onChangeCheckbox = useCallback((script: CallScript, scriptEntity: CallScriptEntity, toggle: boolean) => {
    setData(data.map(model => model.id === scriptEntity.id ? {...model, enabled: toggle } : model))
    if (toggle != scriptEntity.originalEnabled) {
      if (toggle) {
        setCallScript({ ...script, entityIdsAdd: [ ... script.entityIdsAdd, scriptEntity.id] })
      } else {
        setCallScript({ ...script, entityIdsDelete: [ ... script.entityIdsDelete, scriptEntity.id] })
      }
    } else {
      if (toggle) {
        setCallScript({ ... script, entityIdsDelete: script.entityIdsDelete.filter(id => id != scriptEntity.id) })
      } else {
        setCallScript({ ... script, entityIdsAdd: script.entityIdsAdd.filter(id => id != scriptEntity.id) })
      }
    }
  }, [data])

  const onChangeHeaderCheckbox = useCallback((toggle: boolean) => {
    if (toggle) {
      setCallScript({ ...callScript, entityIdsDelete: [], entityIdsAdd: data.filter(model => !model.originalEnabled).map(model => model.id)})
    } else {
      setCallScript({ ...callScript, entityIdsAdd: [], entityIdsDelete: data.filter(model => model.originalEnabled).map(model => model.id)})
    }
    setData(data.map(model => { return {...model, enabled: toggle }}))
  }, [data, callScript])

  const columns = useMemo<Column<CallScriptEntity>[]>(
    () => [
      {
        accessor: 'enabled',
        disableSortBy: true,
        Header: function headerCell(headerProp) {
          return (
            <RdnaCheckbox
              data-testid={'header-script-entity-enabled'}
              className={'header-script-enabled'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(checked)}
              value={!loading && headerProp.data.every(value => value.enabled)}
              disabled={!!loading}
              indeterminate={!loading && !headerProp.data.every(value => value.enabled) && !headerProp.data.every(value => !value.enabled)}
            />
          )
        },
        Cell: function createdCell(cellProp) {
          return (
            <RdnaCheckbox
              data-testid={'cell-script-entity-enabled'}
              className={'checkbox-enabled'}
              onChange={(checked: boolean) => onChangeCheckbox(callScript, cellProp.row.original, checked)}
              value={cellProp.value}
            />
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Source',
        accessor: 'source'
      },
      {
        Header: 'Number',
        accessor: 'number'
      }
    ],
    [callScript, data, loading]
  )

  async function handleUpdate() {
    setProcessing(true)
    try {
      await updateCallScript({
        meta: { id: callScript.id }, body: {
          name: callScript.name,
          data: callScript.data,
          description: callScript.description,
          entityIdsAdd: callScript.entityIdsAdd,
          entityIdsDelete: callScript.entityIdsDelete
        }
      })
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
    onClose(true)
  }

  async function handleCreate() {
    setProcessing(true)
    try {
      await createCallScript({
        body: {
          accountId: accountId,
          name: callScript.name,
          data: callScript.data,
          description: callScript.description,
          entityIdsAdd: callScript.entityIdsAdd,
          entityIdsDelete: callScript.entityIdsDelete
        }
      })
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
    onClose(true)
  }

  return (
    <CallScriptDrawer
      open={open}
      heading={!callScript?.id ? 'Add Call Script' : 'Edit Call Script'}
      onClose={() => onClose(false)}
    >
      <StyledSection>
        <StyledDiv>
          <RdnaTextInput
            name={'call-script-name'}
            label={'Name'}
            placeholder={'Call Script Name'}
            required
            onChange={e => {
              setCallScript({ ...callScript, name: e.target.value })
            }}
            value={callScript?.name}
            style={{marginBottom: 30}}
          />
          <RdnaTextInput
            name={'call-script-description'}
            label={'Description'}
            placeholder={'Description'}
            onChange={e => {
              setCallScript({ ...callScript, description: e.target.value })
            }}
            value={callScript?.description}
            style={{marginBottom: 30}}
          />
          <RdnaTextInput
            name={'call-script-script'}
            label={'Script'}
            placeholder={'Script copy'}
            rows={5}
            multiline
            required
            onChange={e => {
              setCallScript({ ...callScript, data: e.target.value })
            }}
            value={callScript?.data}
            style={{marginBottom: 30}}
          />
        </StyledDiv>
        <RdnaText variant="h5">Smart Numbers</RdnaText>
        <RdnaText>Select the smart numbers you&apos;d like associate with your call script.</RdnaText>
        <RdnaSmartTable
          searchPlaceholder="Search"
          columns={columns}
          data={data}
          noResultState={NO_RESULT_STATE}
          isFetching={!!loading}
          paginationSize={10}>
        </RdnaSmartTable>
      </StyledSection>
      <Footer>
        <RdnaButton
          text={'Cancel'}
          color="neutral"
          variant="text"
          onClick={() => onClose(false)}
        />
        <RdnaButton
          text={'Save'}
          onClick={!callScript?.id ? handleCreate : handleUpdate}
          disabled={!callScript?.name || !callScript.data}
          data-testid={'save-edit-call-script-button'}
          processing={processing}
        ></RdnaButton>
      </Footer>
    </CallScriptDrawer>
  )
}

const StyledSection = styled.section`
  margin: 0 0 ${({ theme }) => 3 * theme.spacing}px;
`

const StyledDiv = styled.div`
  max-width: 50%;
`

const CallScriptDrawer = styled(RdnaDrawer)`
  > div {
    min-width: 50%;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: end;
`
