BaseGrid = require('base/grid')
BaseView = require('base/view')
BaseCollection  = require('base/collection')
LockedSettingModel = require('widgets/locked-setting/model')
LockedSettingView = require('widgets/locked-setting/view')
WedLeadsCollection = require('./collection')
WebLeadsModel = require('./model')

class WebLeadsView extends BaseView

  template: require('./template')

  className: 'webLeadsMapping'

  regions:
    grid: 'div.grid'
    webLeadsLocked: '.webLeadsEnabledLocked'

  bindings:
    '.field-value'      : 'value:fieldValue'
    '.grid'             : 'toggle:webLeadsEnabled'
    '.recordVisibility' : 'value:recordVisibility'
    '.webLeadsEnabled'  : 'checked:webLeadsEnabled'
    '.web-leads-mapping': 'toggle:webLeadsEnabled'

  ui:
    add  : 'button.add'
    fieldName: '.field-name'
    refresh: '.refresh'
    teams: '.teams'

  computeds:
    recordVisibility:
      get: -> @accountSettings?.get('webLeadsRecordVisibility') ? 'All'
      set: (value) -> @accountSettings?.set(webLeadsRecordVisibility: value, {silent: true})
    webLeadsEnabled:
      get: -> @accountSettings?.get('WebLeads')

  events:
    'click .add' : 'create'
    'click .refresh' : 'refresh'
    'change .webLeadsEnabled' : 'webLeadsEnabledChanged'

  initialize: (options) ->
    { @accountId, @accountSettings } = options
    @parseToSelectorPairs(options.leadFields, options.teams)
    @model = new WebLeadsModel()
    @collection = new WedLeadsCollection()
    @viewModel = new Backbone.Model(fieldValue: '')
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))

  onRender: ->
    @ui.fieldName.selectize(
      options: @leadFieldsList
      placeholder: 'Field Name'
      persist: false
    )
    @ui.teams.selectize(
      options: @teamsList
      placeholder: 'Team'
      persist: false
    )
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )
    @initLockedSettings()

  initLockedSettings: =>
    @lockedSettingModel = new LockedSettingModel(accountId: @accountId)
    @lockedSettingModel.set(@accountSettings.get('lockedSetting'))
    @webLeadsLocked = new LockedSettingView(
      basicSettings: @accountSettings
      model: @lockedSettingModel
      setting: 'webLeads')
    @showChildView('webLeadsLocked', @webLeadsLocked) if @getRegion('webLeadsLocked')

  buildGrid: =>
    @isEdit = false
    mainClass = @

    nameCell = class extends Backgrid.Cell
      className: 'wl-name-cell'
      events:
        'change .field-name': 'nameChanged'
      nameChanged: (e) =>
        @model.set('name', $(e.target).val())
      render: =>
        @showHtml(false)
        @listenTo(@model, 'read edit', => @showHtml(false))
        @listenTo(@model, 'checkError', => @showHtml(true))
      showHtml: (hasError) =>
        if mainClass.isEdit or hasError
          html = '''<select class='form-control field-name'>'''
          options = ''
          _.each(mainClass.leadFieldsList, (field) =>
            selected = if field.value is @model.get('name') then 'selected' else ''
            options = options + """<option value=#{field.value} #{selected}>#{field.text}</option>"""
          )
          html = html + options + '</select>'
        else
          fields = mainClass.leadFieldsList.filter((field) => field.value is @model.get('name'))
          html = '''<span></span>''' + _.escape(fields[0]?.text)
        $(@el).html(html)
        @

    valueCell = class extends Backgrid.Cell
      className: 'wl-value-cell'
      events:
        'change .field-value': 'valueChanged'
      valueChanged: (e) =>
        @model.set('value', $(e.target).val())
      render: =>
        @showHtml(false)
        @listenTo(@model, 'read edit', => @showHtml(false))
        @listenTo(@model, 'checkValue', => @showHtml(true))
      showHtml: (hasError) =>
        if mainClass.isEdit or hasError
          html = """<input type="text" class="form-control field-value" placeholder="Field Value" value=#{_.escape(@model.get('value'))}>"""
          html = '''<div class='has-error'>''' + html + '</div>' if hasError
        else
          html = '''<span></span>''' + _.escape(@model.get('value'))
        $(@el).html(html)
        @

    teamCell = class extends Backgrid.Cell
      className: 'wl-team-cell'
      events:
        'change .teams': 'teamChanged'
      teamChanged: (e) =>
        team = _.filter(mainClass.teamsList, (g) =>
          g.value.toString() is $(e.target).val()
        )
        @model.set('team', {id: _.escape(team[0].value)})
        @model.set('teamName', name: _.escape(team[0].text))
      render: =>
        @showHtml(false)
        @listenTo(@model, 'read edit', => @showHtml(false))
        @listenTo(@model, 'checkError', => @showHtml(true))
      showHtml: (hasError) =>
        if mainClass.isEdit or hasError
          html = '''<select class='form-control teams'>'''
          options = ''
          _.each(mainClass.teamsList, (field) =>
            selected = if field.value is @model.get('team').id then 'selected' else ''
            options = options + """<option value=#{_.escape(field.value)} #{selected}>#{_.escape(field.text)}</option>"""
          )
          html = html + options + '</select>'
        else
          fields = mainClass.teamsList.filter((field) => field.value is @model.get('team').id)
          html = '''<span></span>''' + _.escape(fields[0]?.text)
        $(@el).html(html)
        @

    editSaveCell = class extends Backgrid.Cell
      events:
        'click button[data-action="edit"]': 'edit'
        'click button[data-action="save"]': 'save'
      edit: =>
        mainClass.isEdit = true
        @model.trigger('edit')
      save: =>
        mainClass.isEdit = false
        @model.trigger('read')
        mainClass.update(@model)
      render: ->
        @showHtml()
        @listenTo(@model, 'read edit', => @showHtml())
        @listenTo(@model, 'checkError', =>
          mainClass.isEdit = true
          @showHtml())
      showHtml: =>
        if mainClass.isEdit then html = '''<button class="btn btn-success" data-action="save">Save</button>'''
        else html = '''<button class="btn btn-primary" data-action="edit">Edit</button>'''
        $(@el).html(html)
        @

    columns = [
      { name: 'name',       label: 'Field Name',  cell: nameCell },
      { name: 'value',      label: 'Field Value', cell: valueCell },
      { name: 'teamName',   label: 'Team',        cell: teamCell },
      { name: '',           label: 'Edit',        cell: editSaveCell,        sortable: false },
      { name: '',           label: 'Delete',      cell: BaseGrid.DeleteCell, sortable: false }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No data found.'
      onEdit     : @edit
    )

  edit: (options) =>
    @model = options.model?.clone()

  create: =>
    @model.set(
      team: {id: @ui.teams.selectize()[0].selectize.items[0]}
      name: @ui.fieldName.selectize()[0].selectize.items[0]
      value: @viewModel.get('fieldValue')
    )
    data = @model.toJSON()
    data['accountId'] = @accountId
    data['teamId'] = @ui.teams.selectize()[0].selectize.items[0]
    @model
      .persist(
        data: data
        ladda: @ui.add[0]
      ).done?( =>
        @collection.add(@model, { at: 0, merge: true })
        @ui.fieldName.selectize()[0].selectize.clear()
        @ui.teams.selectize()[0].selectize.clear()
        @viewModel.set(fieldValue: '')
        @model = new WebLeadsModel()
        toastr.info('Hot Lead Mapping Saved.')
    )

  update: (model) =>
    return if @error(model)
    exists = _.filter(@collection.models, (item) =>
      item.get('name') is model.get('name') and item.get('value') is model.get('value') and
        item.get('team').id.toString() is model.get('team').id.toString() and item.get('id') isnt model.get('id')
    )
    if exists.length
      @isEdit = true
      model.trigger('edit')
      return toastr.warning('Hot Lead mapping already exists.')
    data = {
      accountId: @accountId
      teamId: model.get('team').id
    }
    data['mapping'] = {
      id: model.get('id')
      team: {id: model.get('team').id}
      name: model.get('name')
      value: model.get('value')
    }
    model.persist(data: data).done?( =>
      toastr.info('Hot Lead Mapping Saved.')
    )

  parseToSelectorPairs: (leadFields, teams) =>
    @leadFieldsList = if leadFields then {text: key, value: value} for key, value of leadFields else {}
    @teamsList = if teams then teams.map((item) -> {text: item.get('name'), value: item.get('id')}) else {}

  refresh: =>
    App.api.get(
      path: '/api/v2/app/webLeadsMappingPickLists/' + @accountId
      success: (response) =>
        @parseToSelectorPairs(response.leadFields, new BaseCollection(response.teams))
        @ui.fieldName.selectize()[0].selectize.clearOptions()
        @ui.fieldName.selectize()[0].selectize.addOption(@leadFieldsList)
        @ui.teams.selectize()[0].selectize.clearOptions()
        @ui.teams.selectize()[0].selectize.addOption(@teamsList)
        toastr.info('Custom Fields refreshed.')
    )

  error: (model) =>
    if not model.get('value').trim()
      model.trigger('checkValue')
      model.trigger('checkError')
      toastr.warning('Please correct the highlighted errors.')
      return true
    return false

  webLeadsEnabledChanged: (e) =>
    @accountSettings?.set(WebLeads: e.target.checked)
    @applyBindings()

  updateLockedSettings: =>
    @lockedSettingModel.persist(data: @lockedSettingModel.toPlayJSON('lockedSetting'))

module.exports = WebLeadsView
