import React, { useState } from 'react'

import { Configuration } from './Models'
import styled, { useTheme } from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import getSalesforceRecordIcon from '@ringdna/common/src/components/RdnaSalesforceRecordIcon'
import { SalesforceRecord } from '@ringdna/common/src/types/salesforce'
import MethodologyTopic from './MethodologyTopic'
import RdnaCard from '@ringdna/common/src/components/RdnaCard'
import { capitalizeFirstLetter } from '@ringdna/common/src/utils/stringUtils'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { MethodologyDetails, useTopicConfiguration } from '../../store/api/salesmethodology-api'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'

type Props = {
  methodologyId: number
  methodologyDetails: MethodologyDetails
  accountId: number
  onSave: (reload: boolean) => void
}

const TYPES = ['lead', 'contact', 'opportunity', 'account', 'case']

export default function DrawerPage({ methodologyId, methodologyDetails, accountId, onSave }: Props) {
  const { palette } = useTheme()
  const [configuredTopics, setConfiguredTopics] = useState<Configuration[]>(methodologyDetails.configurations)
  const [selectedType, setSelectedType] = useState<string>(methodologyDetails.configurations[0]?.parentType || '')
  const isSelectedType = (type: string) => selectedType === type
  const [isSaving, setIsSaving] = useState(false)
  const topicConfiguration = useTopicConfiguration()

  const onSelectObjectType = (type: string) => {
    setSelectedType(type)
  }

  const onSaveMethodology = async () => {
    const configurations = configuredTopics.filter(c => c.parentType === selectedType)
    setIsSaving(true)
    const params = {
      body: serializeJsonIntoPlay({
        accountId: accountId,
        methodologyId: methodologyId,
        configurations: [...configurations]
      })
    }
    try {
      await topicConfiguration(params)
      toastr.info('Sales Methodology saved.')
      setIsSaving(false)
      onSave(true)
    } catch (error) {
      toastr.error(error.message)
      setIsSaving(false)
    }
  }

  const onSelectTopicField = (configuration: Configuration) => {
    const configured = configuredTopics.filter(
      c => !(c.topic.id === configuration.topic.id && c.parentType === selectedType)
    )
    configuration.sfdcName.length && configured.push(configuration)
    setConfiguredTopics(configured)
  }

  return (
    <>
      <Column>
        <RdnaText bold>{'Salesforce Object'}</RdnaText>
        <RdnaText>{'Select a Salesforce Object that will prescribe this methodology.'}</RdnaText>
      </Column>
      <Inline>
        {TYPES.map((type, index) => {
          const [Icon, color] = getSalesforceRecordIcon(type as SalesforceRecord)
          return (
            <StyledCard
              key={`${type}-${index}`}
              width="fill"
              onClick={() => onSelectObjectType(type)}
              backgroundcolor={isSelectedType(type) ? color : palette.neutral.contrastText}
              bordercolor={isSelectedType(type) ? color : palette.inactive}
            >
              <>
                <Icon color={isSelectedType(type) ? palette.primary.contrastText : palette.inactive} />
                <RdnaText
                  style={{ marginTop: 3, marginLeft: 12 }}
                  color={isSelectedType(type) ? 'contrast' : 'primary'}
                >
                  {capitalizeFirstLetter(type)}
                </RdnaText>
              </>
            </StyledCard>
          )
        })}
      </Inline>
      <RdnaText bold>{'Methodology Topics'}</RdnaText>
      {methodologyDetails.topics.map(topic => (
        <MethodologyTopic
          topic={topic}
          objectFields={methodologyDetails.objectFields}
          key={topic.id}
          selectedType={selectedType}
          onSelectField={onSelectTopicField}
          configuredTopics={configuredTopics}
        ></MethodologyTopic>
      ))}
      <Actions>
        <RdnaButton text={'Cancel'} color="neutral" variant="text" onClick={() => onSave(false)} />
        <RdnaButton
          disabled={
            !selectedType || configuredTopics.filter(c => c.parentType === selectedType).length === 0 || isSaving
          }
          processing={isSaving}
          text={'Save'}
          onClick={onSaveMethodology}
        />
      </Actions>
    </>
  )
}

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCard = styled(RdnaCard)`
  width: 20%;
  margin: ${props => props.theme.spacing * 2}px ${props => props.theme.spacing * 2}px
    ${props => props.theme.spacing * 4}px 0;
  > button,
  > button > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    svg {
      flex-shrink: 0;
    }
  }
`
export const Actions = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: ${props => 10 * props.theme.spacing}px;
`
