var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-panel\">\n  <div class=\"x-title\">\n    <h2>Account Details</h2>\n    <div class=\"navbar-right\">\n      <button class=\"btn btn-info cleanup\">Clean up recordings</button>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Id</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n       <label class=\"col-md-4\">Name</label>\n       <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n       <label class=\"col-md-4\">sfOrgId</label>\n       <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"sfOrgId") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n       <label class=\"col-md-4\">Date Created</label>\n       <span class=\"col-md-8\">"
    + alias2((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"createdDate") : stack1),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":24,"column":30},"end":{"line":24,"column":68}}}))
    + "</span>\n    </div>\n  </div>\n  <div class=\"col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Is Deleted</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"isDeleted") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Released From Twilio</label>\n      <span class=\"col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"isReleasedFromTwilio") : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"col-md-4\">Recordings Count</label>\n      <span class=\"col-md-8\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"recordingsCount") || (depth0 != null ? lookupProperty(depth0,"recordingsCount") : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"recordingsCount","hash":{},"data":data,"loc":{"start":{"line":38,"column":29},"end":{"line":38,"column":48}}}) : helper)))
    + "</span>\n    </div>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n";
},"useData":true});