import { RdnaTeam } from '../types/team'

export function formatTeamsStringForColumns(teams: RdnaTeam[], displayTeamLimit = 2) {
  if (teams.length === 0) return 'All'

  if (teams.length <= displayTeamLimit) {
    return teams.map((team: RdnaTeam) => team.name).join(', ')
  } else {
    return `${teams
      .slice(0, displayTeamLimit)
      .map((team: RdnaTeam) => team.name)
      .join(', ')}, +${teams.length - displayTeamLimit} more`
  }
}
