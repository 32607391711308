CsvUserModel = require('./csv-model')

module.exports = class CsvUserCollection extends Backbone.Collection

  model: CsvUserModel

  toJSON: =>
    result = []
    result.push(model.attributes) for model in @models when not model.get('errorDesc')
    result
