BaseView  = require('base/view')
BaseModel = require('base/model')

class ConfirmCallDispositionModal extends BaseView
  template: require('./confirm-template')
  className: 'confirm-call-disposition-modal'

  bindings:
    'button.accept' : 'disabled:not(checkboxAccept)'
    'input.accept'  : 'checked:checkboxAccept'
    '.import-data'  : 'checked:importData'

  events:
    'click button.accept' : 'acceptAction'

  initialize: (options) ->
    @viewModel = new Backbone.Model(
      checkboxAccept: false
      buttonAccept: false
      importData: false
    )

  acceptAction: =>
    @viewModel.set('buttonAccept', true)
    @trigger('agree', @viewModel.get('importData'))

  onDestroy: ->
    @trigger('disagree') unless @viewModel.get('buttonAccept') and @viewModel.get('checkboxAccept')


module.exports = ConfirmCallDispositionModal