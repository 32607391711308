import * as React from 'react'

import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function CallListIcon({ color = Colors.N100, size = 24 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M4.5 2.5L15.7929 2.5L17.6464 4.35355L19.5 6.20711V21.5H4.5V2.5Z" stroke={color} strokeLinejoin="round" />
      <path d="M16 13L8 13" stroke={color} strokeLinecap="round" />
      <path d="M12 17H8" stroke={color} strokeLinecap="round" />
      <path d="M16 9L8 9" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default CallListIcon
