import React, { useState, useEffect } from 'react'

type Rect = {
  x?: number
  y?: number
  width: number
  height: number
}

export const useHTMLElementDimensions = (ref: React.MutableRefObject<HTMLElement | null>, trigger?: any) => {
  const [rect, setRect] = useState<Rect>({ width: 0, height: 0 })
  useEffect(() => {
    const getDimensions = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()
        setRect(rect)
      }
    }
    getDimensions()
    window.addEventListener('resize', getDimensions)
  }, [ref, trigger])

  return rect
}
