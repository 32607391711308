import React from 'react'
import { SipRoute } from '@ringdna/common/src/types/sip-routing'
import { useDeleteSipRoute } from '../../store/api/sip-route-api'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaText from '@ringdna/common/src/components/RdnaText'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  onSubmit: () => void
  sipRoute: SipRoute
}

function SipRouteDeleteModal(props: Props) {
  const { onRequestClose, onSubmit, sipRoute, isOpen } = props

  const deleteSipRoute = useDeleteSipRoute()
  const _delete = async () => {
    try {
      await deleteSipRoute({ meta: { id: sipRoute.id } })
      onSubmit()
      onRequestClose()
    } catch (err) {
      toastr.warning(err.message)
    }
  }

  return (
    <RdnaModal open={isOpen} onClose={onRequestClose} onConfirm={_delete} confirmAsync>
      <RdnaText>Are you sure you want to delete this?</RdnaText>
    </RdnaModal>
  )
}

export default SipRouteDeleteModal
