import React from 'react'
import styled from 'styled-components'
import RdnaText from '../RdnaText'

type Props = {
  /** The headings primary text */
  headingText?: string
  /** Description for header section */
  descriptionText?: React.ReactNode
  /** Divider line added at bottom of header section */
  sectionDivider?: boolean
  /** Additional classname on header section */
  className?: string
  /** Content to position in top right corner of header */
  rightColumn?: React.ReactNode
  /** width of section header */
  width?: string
  /** Additional elements to add to header section */
  children?: React.ReactNode
}

export const SectionDivider = styled.div`
  border-bottom: 2px solid ${props => props.theme.palette.main};
`

const StyledContainer = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.spacing * 3}px;
  width: ${props => props.width || '100%'};
`

export const SectionHeading = ({ headingText }: { headingText: string }) => (
  <RdnaText component="h2" variant="h3">
    {headingText}
  </RdnaText>
)

export const SectionDescription = ({ descriptionText }: { descriptionText: React.ReactNode }) => (
  <RdnaText variant="subtitle2">{descriptionText}</RdnaText>
)

export const RdnaHeaderSection = ({
  headingText,
  descriptionText,
  sectionDivider = false,
  className,
  rightColumn,
  width,
  children
}: Props) => (
  <div data-testid="section-header" className={className}>
    <StyledContainer width={width}>
      <div>
        {headingText && <SectionHeading headingText={headingText} />}
        {descriptionText && <SectionDescription descriptionText={descriptionText} />}
        {sectionDivider && <SectionDivider />}
        {children}
      </div>
      {rightColumn}
    </StyledContainer>
  </div>
)

export default RdnaHeaderSection
