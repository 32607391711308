import * as React from 'react'
import { BaseIconProps } from '../types'
import { Colors } from '../../../constants/colors'

function NoSymbolIcon({ size = 12, color = Colors.F50 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none">
      <circle cx={6} cy={6} r={5.5} stroke={color} />
      <path stroke={color} strokeLinejoin="round" d="M2.046 10.447l8.4-8.4" />
    </svg>
  )
}

export default NoSymbolIcon
