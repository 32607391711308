BaseModel = require('base/model')

###
  This model is unusual, so there's i don't extends BaseCallTrackingModel
  TODO: check can we use it
###
class AdWordsCallTrackingModel extends BaseModel
  defaults:
    adGroupId: null
    adWordCampaignId: null
    areaCode: ''
    channel: ''
    callFlow: ''
    campaignId: null
    extraInfo: ''
    googleAccountEmail: ''
    id: ''
    matchTypeKeyword: ''
    labelTracking: ''
    source: 'Google AdWords'
    type: ''
    typeNumber: ''
    smartNumberId: ''
    number: ''
    keywordId: null

  addNumber: (smartNumberId, number) =>
    @set('smartNumberId', smartNumberId)
    @set('number', number)

  removeNumber: =>
    @set('typeNumber', 'none')
    @set('number', null)
    @set('smartNumberId', null)

  updateNumberType: (newType) =>
    if (@get('typeNumber') == 'Existing' && newType != 'Existing')
      @set('smartNumberId', null)
      @set('number', null)
    @set('typeNumber', if newType is 'none' then null else newType)

module.exports = AdWordsCallTrackingModel
