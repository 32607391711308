import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function LogoutIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M19 22H5.3C5.13431 22 5 21.8657 5 21.7V2.3C5 2.13431 5.13431 2 5.3 2H19M19 22V2M19 22L11.1947 19.073C11.0776 19.0291 11 18.9172 11 18.7921V5.2079C11 5.08285 11.0776 4.97091 11.1947 4.927L19 2"
        stroke={color}
        strokeWidth="1.33"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogoutIcon
