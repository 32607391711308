import React, { useState } from 'react'

// utils
import { RelatedRecordsModalProps } from '../types'

// components
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaSelect from '../../../../../common/src/components/RdnaFormElements/RdnaSelect'
import RdnaAutoComplete from '../../../../../common/src/components/RdnaFormElements/RdnaAutoComplete'
import { createSimpleOptions } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
import { hasNoOptions, createOptions, isCustomRecord, createCustomObjectsOptions } from '../utils/contextReducer'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaSection from '../../../../../common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import styled from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'

const RelatedRecordsModal = ({
  open,
  onClose,
  relatedRecord,
  setRelatedRecord,
  usedRelatedRecords
}: RelatedRecordsModalProps) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const ALL_RECORDS = 'All'

  if (!relatedRecord) return null

  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Save'}
      confirmButtonDisabled={isCustomRecord(relatedRecord) && relatedRecord.apiName === null}
      maxWidth={'sm'}
      minWidth={480}
      heading={
        relatedRecord.typeRecord
          ? `Manage ${relatedRecord.typeRecord} Related Records`
          : 'Create New Related Record Type'
      }
      onClose={() => onClose(false)}
      onConfirm={() => onClose(hasChanges)}
    >
      {isCustomRecord(relatedRecord) ? (
        <>
          <FormSection sectionTitle={'Object'}>
            <RdnaAutoComplete
              placeholder={`Select Object`}
              id={'custom-related-record-select'}
              options={createCustomObjectsOptions(
                relatedRecord.salesforceRecords ? relatedRecord.salesforceRecords : {},
                usedRelatedRecords
              )}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value
              }}
              value={
                relatedRecord.apiName
                  ? {
                      label: `${relatedRecord.typeRecord} (${relatedRecord.apiName})`,
                      value: relatedRecord.apiName,
                      disabled: true
                    }
                  : { label: '', value: '', disabled: true }
              }
              getOptionDisabled={option => option.disabled}
              onChange={(event, value) => {
                if (value === null) {
                  setRelatedRecord({ ...relatedRecord, apiName: null, typeRecord: null })
                } else {
                  setRelatedRecord({
                    ...relatedRecord,
                    apiName: value.value,
                    typeRecord: relatedRecord.salesforceRecords ? relatedRecord.salesforceRecords[value.value] : null
                  })
                }
                setHasChanges(true)
              }}
              renderOption={(props, option: { label: string; value: string }) => {
                return (
                  <li {...props} data-testid={`option-${option.value}`} key={`${option.label} (${option.value})`}>
                    <Item option={option} />
                  </li>
                )
              }}
            />
          </FormSection>
          <FormSection sectionTitle={'Permissions'}>
            <RdnaCheckbox
              value={relatedRecord.views.length > 0}
              label="View Records"
              onChange={checked => {
                setRelatedRecord({
                  ...relatedRecord,
                  views: checked ? ALL_RECORDS : []
                })
                setHasChanges(true)
              }}
            />
            <RdnaCheckbox
              value={relatedRecord.suggests.length > 0}
              label="Suggest Records"
              onChange={checked => {
                setRelatedRecord({
                  ...relatedRecord,
                  suggests: checked ? ALL_RECORDS : []
                })
                setHasChanges(true)
              }}
            />
            <RdnaCheckbox
              value={relatedRecord.searches.length > 0}
              label="Search Records"
              onChange={checked => {
                setRelatedRecord({
                  ...relatedRecord,
                  searches: checked ? ALL_RECORDS : []
                })
                setHasChanges(true)
              }}
            />
          </FormSection>
        </>
      ) : (
        <>
          <FormSection sectionTitle={`Search ${relatedRecord.apiName} Permission`}>
            <RdnaSelect<string, true>
              multiple
              name={'search-related-record'}
              disabled={hasNoOptions(relatedRecord.listAvailableSearches)}
              placeholder={`Select ${relatedRecord.apiName} Related Records`}
              options={createOptions(relatedRecord.listAvailableSearches)}
              value={relatedRecord.searches}
              onChange={event => {
                setRelatedRecord({
                  ...relatedRecord,
                  searches: event.target.value
                })
                setHasChanges(true)
              }}
            />
          </FormSection>
          <FormSection sectionTitle={`Suggest ${relatedRecord.apiName} Permission`}>
            <RdnaSelect<string, true>
              multiple
              name={'suggest-related-record'}
              disabled={hasNoOptions(relatedRecord.listAvailableSuggests)}
              placeholder={`Select ${relatedRecord.apiName} Related Records`}
              options={createOptions(relatedRecord.listAvailableSuggests)}
              value={relatedRecord.suggests}
              onChange={event => {
                setRelatedRecord({
                  ...relatedRecord,
                  suggests: event.target.value
                })
                setHasChanges(true)
              }}
            />
          </FormSection>
          <FormSection sectionTitle={`View ${relatedRecord.apiName} Permission`}>
            <RdnaSelect<string, true>
              multiple
              name={'view-related-record'}
              disabled={hasNoOptions(relatedRecord.listAvailableViews)}
              placeholder={`Select ${relatedRecord.apiName} Related Records`}
              options={createOptions(relatedRecord.listAvailableViews)}
              value={relatedRecord.views}
              onChange={event => {
                setRelatedRecord({
                  ...relatedRecord,
                  views: event.target.value
                })
                setHasChanges(true)
              }}
            />
          </FormSection>
        </>
      )}
      <FormSection sectionTitle={'View results'}>
        <RdnaSelect
          name={'sort-related-record'}
          placeholder={`Sort Results By`}
          options={createOptions(relatedRecord.listAvailableOrders)}
          value={relatedRecord.orderBy}
          onChange={event => {
            setRelatedRecord({
              ...relatedRecord,
              orderBy: event.target.value
            })
            setHasChanges(true)
          }}
        />
        <RdnaSelect
          name={'number-related-record'}
          placeholder={`Number of Results`}
          options={createSimpleOptions(['5', '10', '20'])}
          value={relatedRecord.limit.toString()}
          onChange={event => {
            setRelatedRecord({
              ...relatedRecord,
              limit: +event.target.value
            })
            setHasChanges(true)
          }}
        />
      </FormSection>
    </RdnaModal>
  )
}

const Item = ({ option }: { option: { label: string; value: string } }) => {
  return (
    <StyledItem>
      <RdnaText> {option.label} </RdnaText>
      <RdnaText color={'neutral'} variant={'meta'}>
        {option.value}
      </RdnaText>
    </StyledItem>
  )
}

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
`

const FormSection = styled(RdnaSection)`
  > div {
    margin-bottom: 0;
  }
`

export default RelatedRecordsModal
