BaseView          = require('base/view')

class AbandonedCallsView extends BaseView

  template: require('./template')
  bindings:
    '.subject'  : "value:abandonedSubject, events:['keyup']"
    '.comments' : "value:abandonedComments, events:['keyup']"

module.exports = AbandonedCallsView
