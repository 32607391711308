import {OptionCategory} from '../../../types'

export const MANAGE_DISPOSITIONS_STR = {
  delete: 'Delete',
  edit: 'Edit',
  header: 'All account Call Dispositions are visible in this table. Click the "Add Disposition" button to add new Call Dispositions. Optionally, assign "Outcome Categories" (which facilitate reporting on the trends of call outcomes over time).',
  name: 'disposition',
  category: 'outcomeCategory',
  assignedInbound: 'inbound',
  assignedOutbound: 'outbound',
  syncText: 'Sync to Salesforce will overwrite your Salesforce call disposition picklist. Do you want to continue?',
  enableText: 'When the “Manage Call Dispositions” feature is activated, all call dispositions will be managed in Revenue. They will no longer be managed in Salesforce. Going forward, any additions or changes to call dispositions will be made in Revenue. This allows disposition values to be added to specific teams. This can impact reporting options. Please make sure to manually add any dispositions to Salesforce to be used in reports. NOTE: there is a one time sync option. This will pull all the current disposition values from Salesforce into Revenue.'
}

export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }

export const optionsOutcomeCategory: OptionCategory[] = [
  {
    label: 'Uncategorized',
    value: 'Uncategorized'
  },
  {
    label: 'Neutral',
    value: 'Neutral'
  },
  {
    label: 'Positive',
    value: 'Positive'
  },
  {
    label: 'Negative',
    value: 'Negative'
  }
]

export const initDisposition = {
  id: 0,
  disposition: '',
  outcomeCategory: null,
  inbound: 0,
  outbound: 0,
  teams: []
}
