import React from 'react'

export default function EmptyMomentsTranscripts() {
  return (
    <svg width="400" height="300" viewBox="0 0 400 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.5243 165L187.476 165L190.434 165L190.434 165.091C215.303 166.618 235 187.271 235 212.524C235 237.778 215.303 258.431 190.434 259.958L190.434 260.049L187.476 260.049L71.5243 260.049L71.327 260.049L71.327 260.048C45.1708 259.942 24 238.706 24 212.524C24 186.343 45.1708 165.107 71.327 165L71.327 165L71.5243 165Z"
        fill="#E5EDF8"
      />
      <mask
        id="mask0_3418_12315"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="24"
        y="165"
        width="211"
        height="96"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.5243 165L187.476 165L190.434 165L190.434 165.091C215.303 166.618 235 187.271 235 212.524C235 237.778 215.303 258.431 190.434 259.958L190.434 260.049L187.476 260.049L71.5243 260.049L71.327 260.049L71.327 260.048C45.1708 259.942 24 238.705 24 212.524C24 186.343 45.1708 165.107 71.327 165L71.327 165L71.5243 165Z"
          fill="#E6F9F3"
        />
      </mask>
      <g mask="url(#mask0_3418_12315)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.9759 241.372C76.9759 241.372 80.9978 239.033 82.4721 234.503C83.9464 229.974 85.0265 216.747 85.0265 216.747L106.89 219.15L108.009 244.54C108.009 244.54 76.3989 240.717 76.9759 241.372Z"
          fill="#EAC7AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.908 323.296C133.908 323.296 141.559 272.224 127.5 239C121.178 237.695 115.893 237.561 109.17 236.807C106.465 236.503 106.847 241.762 97.0531 242.314C84.1739 243.041 82.9479 237.887 78.4873 238.753C59.9561 239.043 57.5002 245 57.5002 245L66.124 325.171L68.5215 346.285C84.187 349.167 121.33 351.359 130.649 346.052C134.055 344.112 133.019 332.001 133.908 323.296Z"
          fill="#779497"
        />
        <path
          d="M92.761 249.831L78.0587 238.687L82.9584 231.124L99.4678 239.427L107.919 232.225L110.085 236.628L106.392 247.563L99.7881 241.462L92.761 249.831Z"
          fill="#E6F9F3"
        />
        <path
          d="M78.1709 238.652L92.7668 249.832L99.7939 241.463L106.469 247.812L110.162 236.735"
          stroke="#497174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.636 326.586C156.357 331.072 146.832 328.977 142.108 321.851L113.321 272.205C107.619 263.607 105.537 246.748 114.32 241.336C123.105 235.923 134.624 238.861 139.741 247.82L169.085 305.227C173.325 312.652 170.915 322.101 163.636 326.586Z"
          fill="#779497"
        />
        <path
          d="M126.875 253.621C129.145 263.391 128.106 273.641 128.205 278.701"
          stroke="#497174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M48.1622 256.549L58.665 274.323" stroke="#B3C4D9" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M81.6772 214.748C89.4061 223.872 113.223 233.526 123.6 227.584C127.596 225.296 119.908 204.89 121.921 197.491C124.76 187.048 102.217 173.924 83.481 184.651C73.1048 190.588 73.9482 205.625 81.6772 214.748Z"
          fill="#EAC7AD"
        />
        <path
          d="M117.154 197.192C117.252 196.475 118.154 196.213 118.62 196.767L127.439 207.226C129.084 209.176 127.48 212.133 124.95 211.815L117.832 210.925C116.448 210.752 115.472 209.482 115.661 208.099L117.154 197.192Z"
          fill="#EAC7AD"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M119.752 217.673C119.752 217.673 116.454 218.135 112.932 216.98"
            stroke="#0052CC"
            strokeWidth="0.417554"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M117.188 204.02C116.655 204.02 116.223 203.228 116.223 202.25C116.223 201.272 116.655 200.48 117.188 200.48C117.72 200.48 118.152 201.272 118.152 202.25C118.152 203.228 117.72 204.02 117.188 204.02Z"
            fill="#0052CC"
          />
          <path
            d="M118.499 200.296C118.499 200.296 116.603 199.949 113.685 202.4"
            stroke="#0052CC"
            strokeWidth="0.417554"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M119.752 196.856C119.548 195.179 117.647 194.217 116.218 194.497C115.022 194.642 114.075 195.364 113.173 196.169C112.391 196.866 112.533 197.092 113.509 196.709C114.625 196.271 115.939 195.783 116.278 195.776C117.467 195.701 118.724 196.099 119.643 196.908L119.752 196.856Z"
          fill="#995C1F"
        />
        <path d="M113.246 202.099L99.3178 203.707" stroke="#E6F9F3" strokeWidth="0.417554" />
        <path
          d="M123.721 202.267C123.005 202.034 122.606 201.599 121.633 202.463"
          stroke="#E6F9F3"
          strokeWidth="0.417554"
        />
        <path
          d="M113.324 201.409C113.324 203.101 113.804 204.624 114.571 205.718C115.338 206.813 116.377 207.463 117.502 207.463C118.626 207.463 119.666 206.813 120.433 205.718C121.199 204.624 121.68 203.101 121.68 201.409C121.68 199.717 121.199 198.194 120.433 197.1C119.666 196.005 118.626 195.354 117.502 195.354C116.377 195.354 115.338 196.005 114.571 197.1C113.804 198.194 113.324 199.717 113.324 201.409Z"
          stroke="#E6F9F3"
          strokeWidth="0.417554"
        />
        <path
          d="M72.7681 196.333C71.6747 186.528 75.0129 181.67 87.0864 180.323C91.3124 179.852 101.068 174.312 104.518 172.543C107.304 171.279 107.841 170.017 109.53 175.57C112.98 173.801 113.773 174.053 115.06 174.699C117.814 176.08 118.86 177.396 119.297 180.11C119.547 181.656 117.703 186.129 116.883 187.278C114.435 185.181 113.125 183.522 106.409 185.691L108.166 193.157L104.221 196.186C102.093 197.818 101.538 200.864 102.938 203.209L103.652 204.404C104.11 205.78 103.663 205.57 101.688 206.413L99.7739 204.196L95.8369 208.102L94.8352 209.154C86.8223 215.466 91.9477 224.701 86.3142 225.901L74.95 202.577C74.3924 201.679 73.9283 200.732 73.5681 199.741C73.2856 198.966 73.0657 198.166 72.9163 197.342C72.8554 197.009 72.806 196.672 72.7681 196.333Z"
          fill="#995C1F"
        />
        <path
          d="M101.244 204.444C101.244 204.444 99.7782 201.976 97.3396 201.88C94.897 201.784 93.6276 203.834 93.3353 206.176C93.043 208.519 94.9429 211.939 101.386 213.012"
          fill="#EAC7AD"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M101.244 204.444C101.244 204.444 99.7782 201.976 97.3396 201.88C94.897 201.784 93.6276 203.834 93.3353 206.176C93.043 208.519 94.9429 211.939 101.386 213.012"
            stroke="#0052CC"
            strokeWidth="0.417554"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M96.1453 205.918C96.1453 205.918 96.7591 204.331 97.7654 204.331C98.7717 204.331 100.066 205.124 100.066 207.458C100.066 209.792 101.01 209.692 101.01 209.692"
            stroke="#0052CC"
            strokeWidth="0.417554"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <circle cx="98.2715" cy="206.45" r="1.60549" fill="white" />
        <path d="M98.2715 207.654V211.234" stroke="white" strokeWidth="0.802746" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.9527 333.465C49.8741 336.683 58.9204 333.039 62.3987 325.229L82.5646 271.501C86.7628 262.077 86.0235 245.106 76.4652 241.223C66.9054 237.34 56.0323 242.146 52.4694 251.829L33.0411 313.304C30.0895 321.328 34.0312 330.248 41.9527 333.465Z"
          fill="#779497"
        />
        <ellipse cx="92.2515" cy="245.912" rx="0.899986" ry="0.940901" fill="#779497" />
        <ellipse
          cx="105.417"
          cy="243.673"
          rx="0.725648"
          ry="0.867393"
          transform="rotate(-25.2139 105.417 243.673)"
          fill="#779497"
        />
        <path
          d="M82.5278 253.621C84.797 263.391 83.7583 273.641 83.8576 278.701"
          stroke="#497174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle cx="47.6023" cy="47.6023" r="47.6023" transform="matrix(-1 0 0 1 357.204 40.0005)" fill="#85E0C2" />
      <mask
        id="mask1_3418_12315"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="262"
        y="40"
        width="96"
        height="96"
      >
        <circle cx="47.6023" cy="47.6023" r="47.6023" transform="matrix(-1 0 0 1 357.205 40)" fill="#E6F9F3" />
      </mask>
      <g mask="url(#mask1_3418_12315)">
        <path
          d="M324.2 95.02L304.022 91.4384L304.413 116.776C304.498 122.458 299.384 126.811 293.793 125.818L293.541 127.24L324.507 132.736L324.758 131.319L324.2 95.02Z"
          fill="#DBA270"
        />
        <path
          d="M326.645 90.6937C319.346 100.642 295.523 112.294 284.414 106.925C280.135 104.857 286.58 83.2973 283.975 75.8255C280.301 65.2797 302.563 50.1472 322.622 59.841C333.731 65.2064 333.945 80.7455 326.645 90.6937Z"
          fill="#DBA270"
        />
        <path
          d="M280.644 89.037C283.879 91.3658 288.41 89.2081 288.641 85.2292L289.162 76.2566C289.211 75.4116 288.14 75.0096 287.621 75.6783L280.32 85.0858C279.315 86.2705 279.464 88.0396 280.644 89.037Z"
          fill="#DBA270"
        />
        <path
          d="M282.14 107.131C276.627 102.981 278.61 91.18 283.293 90.2045C283.293 90.2045 283.348 90.1902 283.441 90.1724C283.953 90.0594 284.312 89.9632 284.883 90.2273C284.933 90.248 284.981 90.2719 285.027 90.2991C286.858 91.3928 298.157 89.2923 301.439 83.5363L302.946 77.5471C303.037 77.1856 303.35 76.9236 303.721 76.8976L305.41 76.7796C305.919 76.744 306.35 77.1492 306.359 77.6594C306.473 83.8022 308.577 101.626 303.707 106.702C298.554 112.073 285.114 109.514 282.14 107.131Z"
          fill="#663D14"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M287.657 96.4464C287.657 96.4464 291.086 96.6855 294.628 95.2426"
            stroke="#661C1C"
            strokeWidth="0.430886"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M289.313 82.2064C289.862 82.1681 290.249 81.321 290.179 80.3145C290.108 79.308 289.607 78.5231 289.058 78.5614C288.51 78.5998 288.122 79.4468 288.193 80.4534C288.263 81.4599 288.765 82.2448 289.313 82.2064Z"
            fill="#661C1C"
          />
          <path
            d="M287.696 78.4667C287.696 78.4667 289.622 77.9735 292.803 80.2865"
            stroke="#661C1C"
            strokeWidth="0.430886"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M286.156 75.0176C286.245 73.2763 288.133 72.1493 289.624 72.3346C290.866 72.3982 291.892 73.0734 292.879 73.8369C293.734 74.4987 293.604 74.741 292.572 74.4172C291.392 74.0467 290.004 73.6386 289.654 73.6555C288.425 73.6642 287.159 74.1645 286.272 75.0631L286.156 75.0176Z"
          fill="#663D14"
        />
        <path d="M293.233 79.9448L307.687 80.5974" stroke="#663D14" strokeWidth="0.430886" />
        <path
          d="M282.463 80.8715C283.183 80.581 283.563 80.1042 284.627 80.9231"
          stroke="#663D14"
          strokeWidth="0.430886"
        />
        <path
          d="M293.105 79.2402C293.226 80.9819 292.841 82.5841 292.131 83.7658C291.42 84.9481 290.397 85.6926 289.239 85.7736C288.082 85.8545 286.965 85.2597 286.097 84.1877C285.229 83.1164 284.624 81.5834 284.503 79.8417C284.381 78.1 284.766 76.4977 285.476 75.3161C286.187 74.1338 287.21 73.3893 288.368 73.3083C289.526 73.2274 290.642 73.8222 291.511 74.8942C292.378 75.9654 292.983 77.4985 293.105 79.2402Z"
          stroke="#663D14"
          strokeWidth="0.430886"
        />
        <path
          d="M305.765 81.4955C305.765 81.4955 307.097 78.8497 309.6 78.5753C312.108 78.3006 313.562 80.3197 314.031 82.7101C314.501 85.1004 312.791 88.7575 306.236 90.326"
          fill="#DBA270"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M305.765 81.4955C305.765 81.4955 307.097 78.8497 309.6 78.5753C312.108 78.3006 313.562 80.3197 314.031 82.7101C314.501 85.1004 312.791 88.7575 306.236 90.326"
            stroke="#661C1C"
            strokeWidth="0.430886"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M311.12 82.6466C311.12 82.6466 310.374 81.0574 309.338 81.1298C308.302 81.2022 307.027 82.1121 307.195 84.5149C307.363 86.9177 306.384 86.8824 306.384 86.8824"
            stroke="#661C1C"
            strokeWidth="0.430886"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M338.122 77.5199C334.955 87.6762 330.858 102.761 317.356 98.5506C303.854 94.3406 284.613 67.2443 285.37 59.6314C288.537 49.4752 304.698 45.4201 318.2 49.6301C331.701 53.8401 341.289 67.3637 338.122 77.5199Z"
          fill="#992929"
        />
        <path
          d="M293.5 65.7336C297.163 72.8204 309.964 84.3651 323.307 87.4058"
          stroke="#661C1C"
          strokeWidth="0.430886"
          strokeLinecap="round"
        />
        <path
          d="M297.547 58.755C305.227 65.6329 321.897 79.5994 327.139 80.4423"
          stroke="#661C1C"
          strokeWidth="0.430886"
          strokeLinecap="round"
        />
        <path
          d="M309.419 85.9663C306.411 90.6891 298.315 100.248 289.993 100.702"
          stroke="#333333"
          strokeWidth="0.53354"
        />
        <path
          d="M313.05 54.2318C313.1 69.5408 312.305 81.954 311.273 81.9574C310.242 81.9607 309.366 69.553 309.316 54.244C309.29 46.4045 310.127 46.7473 311.158 46.7439C312.19 46.7405 313.025 46.3923 313.05 54.2318Z"
          fill="#979797"
        />
        <circle cx="311.01" cy="83.0257" r="8.0031" transform="rotate(-0.187225 311.01 83.0257)" fill="#333333" />
        <rect
          x="287.054"
          y="99.6441"
          width="4.80186"
          height="1.60062"
          rx="0.80031"
          transform="rotate(-0.187225 287.054 99.6441)"
          fill="#333333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M371.147 191.494C365.712 194.171 359.137 191.936 356.46 186.501L330.367 133.541C327.69 128.107 329.926 121.53 335.36 118.853C340.795 116.176 352.265 119.941 354.942 125.376L376.14 176.806C378.817 182.24 376.582 188.817 371.147 191.494Z"
          fill="#FFC999"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.502 179.417L357.665 128.437C353.113 114.368 341.707 113.45 336.157 116.393C329.448 119.951 327.415 127.533 329.975 133.157L357.521 190.35L377.502 179.417Z"
          fill="#CC7A29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.395 179.527C244.837 183.646 251.777 183.385 255.897 178.943L296.045 135.656C300.164 131.214 299.903 124.273 295.461 120.153C291.019 116.033 278.951 116.362 274.831 120.804L239.811 164.024C235.691 168.466 235.953 175.407 240.395 179.527Z"
          fill="#FFC999"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.764 166.138L271.351 122.96C279.737 110.78 289.817 114.167 294.293 118.574C299.704 123.902 300.597 130.745 296.535 135.401L253.783 182.328L237.764 166.138Z"
          fill="#E8EDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.062 194.113C276.062 194.113 271.915 148.478 278.393 116.139C283.68 114.429 293.633 112.308 302.896 112.308C305.135 112.565 303.524 113.869 313.993 114.412C324.461 114.956 322.337 111.479 327.291 111.736C345.529 112.021 351.053 118.686 351.053 118.686L342.774 195.958L340.415 216.739C324.997 219.575 288.442 221.731 279.27 216.509C275.918 214.6 276.937 202.68 276.062 194.113Z"
          fill="#E8EDF4"
        />
        <path
          d="M313.99 114.412C303.522 113.869 305.041 112.562 302.801 112.304L276.06 194.113C276.935 202.681 275.916 214.6 279.268 216.509C288.44 221.732 324.995 219.575 340.413 216.739L342.772 195.958L327.289 111.737C322.335 111.479 324.458 114.956 313.99 114.412Z"
          fill="#E8EDF4"
        />
        <path
          d="M306.129 119.053C310.524 121.375 315.551 121.634 322.137 118.193"
          stroke="#B3C4D9"
          strokeWidth="0.985708"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M345.559 132.62C347.217 145.576 347.217 168.068 347.217 168.068"
          stroke="#663D14"
          strokeWidth="0.985708"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.659 159.258C274.659 158.015 277.768 130.029 277.768 130.029"
          stroke="#663D14"
          strokeWidth="0.985708"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <mask id="path-61-inside-1_3418_12315" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187 150C170.431 150 157 163.431 157 180C157 187.066 159.443 193.562 163.531 198.688L157 210L171.993 205.983C176.407 208.538 181.533 210 187 210H267C283.569 210 297 196.569 297 180C297 163.431 283.569 150 267 150H187Z"
        />
      </mask>
      <path
        d="M163.531 198.688L166.059 200.148L167.055 198.425L165.814 196.868L163.531 198.688ZM157 210L154.471 208.54L150.946 214.645L157.756 212.82L157 210ZM171.993 205.983L173.456 203.455L172.407 202.849L171.237 203.162L171.993 205.983ZM159.92 180C159.92 165.044 172.044 152.92 187 152.92V147.08C168.819 147.08 154.08 161.819 154.08 180H159.92ZM165.814 196.868C162.124 192.24 159.92 186.381 159.92 180H154.08C154.08 187.751 156.763 194.884 161.248 200.509L165.814 196.868ZM159.529 211.46L166.059 200.148L161.002 197.228L154.471 208.54L159.529 211.46ZM171.237 203.162L156.244 207.179L157.756 212.82L172.749 208.803L171.237 203.162ZM187 207.08C182.06 207.08 177.437 205.76 173.456 203.455L170.53 208.51C175.377 211.315 181.006 212.92 187 212.92V207.08ZM267 207.08H187V212.92H267V207.08ZM294.08 180C294.08 194.956 281.956 207.08 267 207.08V212.92C285.181 212.92 299.92 198.181 299.92 180H294.08ZM267 152.92C281.956 152.92 294.08 165.044 294.08 180H299.92C299.92 161.819 285.181 147.08 267 147.08V152.92ZM187 152.92H267V147.08H187V152.92Z"
        fill="#7549EA"
        mask="url(#path-61-inside-1_3418_12315)"
      />
      <mask id="path-63-inside-2_3418_12315" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224 51C240.569 51 254 64.4315 254 81C254 88.0663 251.557 94.562 247.469 99.6885L254 111L239.007 106.983C234.593 109.538 229.467 111 224 111H144C127.431 111 114 97.5685 114 81C114 64.4315 127.431 51 144 51H224Z"
        />
      </mask>
      <path
        d="M247.469 99.6885L244.941 101.148L243.945 99.4245L245.186 97.8681L247.469 99.6885ZM254 111L256.529 109.54L260.054 115.645L253.244 113.82L254 111ZM239.007 106.983L237.544 104.455L238.593 103.849L239.763 104.162L239.007 106.983ZM251.08 81C251.08 66.0441 238.956 53.92 224 53.92V48.08C242.181 48.08 256.92 62.8188 256.92 81H251.08ZM245.186 97.8681C248.876 93.2401 251.08 87.3814 251.08 81H256.92C256.92 88.7512 254.237 95.8839 249.752 101.509L245.186 97.8681ZM251.471 112.46L244.941 101.148L249.998 98.2285L256.529 109.54L251.471 112.46ZM239.763 104.162L254.756 108.179L253.244 113.82L238.251 109.803L239.763 104.162ZM224 108.08C228.94 108.08 233.563 106.76 237.544 104.455L240.47 109.51C235.623 112.315 229.994 113.92 224 113.92V108.08ZM144 108.08H224V113.92H144V108.08ZM116.92 81C116.92 95.9559 129.044 108.08 144 108.08V113.92C125.819 113.92 111.08 99.1812 111.08 81H116.92ZM144 53.92C129.044 53.92 116.92 66.0441 116.92 81H111.08C111.08 62.8188 125.819 48.08 144 48.08V53.92ZM224 53.92H144V48.08H224V53.92Z"
        fill="#7549EA"
        mask="url(#path-63-inside-2_3418_12315)"
      />
    </svg>
  )
}
