const queryParamDelimiter = '?'
const multiParamDelimiter = '&'
const keyValueDelimiter = '='

// Backbone query params were double-encoded due to a bug:
// https://github.com/ringdna/ui/blob/7538a638a10a96113775065763982b72734ab855/packages/webapp/app/helpers/query-string.coffee#L17-L22
export function doubleDecode(doublyEncodedStr: string) {
  return decodeURIComponent(decodeURIComponent(doublyEncodedStr))
}

/**
 * Get the query param value from the window url,
 * whether it's a hash or regular search param
 */

type Options = {
  doubleDecode?: boolean
  multiParamDelimiter?: string
}

type Query = { paramName: string; paramValue: string }

export const getQueries = (base: string, options: Options = {}): Query[] => {
  const decodedBase = options.doubleDecode ? doubleDecode(base) : base
  const queryArr = decodedBase.split(queryParamDelimiter)

  // we can remove the first item in the array, which will either be an empty string or the hash string
  queryArr.splice(0, 1)

  const delimiter = options.multiParamDelimiter || multiParamDelimiter

  const isMultiOrSingleQueryArr =
    queryArr[0] && queryArr[0].includes(delimiter) ? queryArr[0].split(delimiter) : queryArr

  const queries = isMultiOrSingleQueryArr.map(keyValue => {
    const paramKeyValueArr = keyValue.split(keyValueDelimiter)
    const paramName = paramKeyValueArr[0]
    const paramValue = paramKeyValueArr[1]
    return { paramName, paramValue }
  })

  return queries
}

type Param = string

export const getQueryParam = (param: string, options: Options = {}): Param | null => {
  const prop = window.location.hash ? 'hash' : 'search'
  const base = window.location[prop]
  const queries = getQueries(base, options)
  const query = queries.find(_query => param === _query.paramName)
  return query?.paramValue || null
}

export const getAllQueryParams = () => {
  const prop = window.location.hash ? 'hash' : 'search'
  const base = window.location[prop]
  const queries = getQueries(base)
  const queryObjArray = queries.map(query => ({ [query.paramName]: query.paramValue }))
  return Object.assign({}, ...queryObjArray)
}
