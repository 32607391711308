import React from 'react'
import styled from 'styled-components'

const marginMultiple = 5

const StyledContainer = styled.div<{
  width?: string
  $top?: boolean
  $right?: boolean
  $bottom?: boolean
  $left?: boolean
}>`
  ${props => props.$top && `margin-top: ${props.theme.spacing * marginMultiple}px;`}
  ${props => props.$right && `margin-right: ${props.theme.spacing * marginMultiple}px;`}
  ${props => props.$bottom && `margin-bottom: ${props.theme.spacing * marginMultiple}px;`}
  ${props => props.$left && `margin-left: ${props.theme.spacing * marginMultiple}px;`}
  ${props => props.width && `width: ${props.width};`}
`

type Props = {
  children: React.ReactNode
  width?: string
  top?: boolean
  right?: boolean
  bottom?: boolean
  left?: boolean
}

export default function GutterContainer({
  children,
  width,
  top = true,
  right = true,
  left = true,
  bottom = false
}: Props) {
  return (
    <StyledContainer $top={top} $right={right} $bottom={bottom} $left={left} width={width}>
      {children}
    </StyledContainer>
  )
}
