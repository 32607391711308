BaseView  = require('base/view')
BaseModel = require('base/model')
Picklist  = require('./collection')

class AddCampaignModal extends BaseView
  template: require('./add-step-template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    { @accountId, @viewModel } = options
    @picklist = null
    @picklistOnLoad = null

  onRender: =>
    @ui.picker.selectize(
      selectOnTab: true
      maxItems: 1
      preload: true
      placeholder  : 'Select Campaign'
      loadThrottle: 1000
      load: (query, callback) =>
        return if query?.length < 2 and @picklist
        @picklist = new Picklist()
        @picklist.fetch(
          data:
            accountId: @accountId
            filter: query
        ).done((response) =>
          @picklistOnLoad = new Backbone.Collection(response)
          callback(response)
        )
      onChange: (value) =>
        if value then @setCampaign(value) else @viewModel.set('campaignId', null)
    )

  setCampaign: (campaignId) =>
    campaign = @picklistOnLoad.findWhere(value: campaignId)
    if campaign
      if campaign.get('isAssigned')
        bootbox.confirm('This campaign is already associated with another smart number. Do you want to continue?',
          (ok) =>
            @viewModel.set('campaignId', campaignId) if ok
            true
        )
      else
        @viewModel.set('campaignId', campaignId)


module.exports = AddCampaignModal
