BaseGrid = require('base/grid')
BaseView = require('base/view')
BackgridCell = require('helpers/backgrid-cell')

class CountriesView extends BaseView
  template: require('./template')

  regions:
    naGrid: '.north-america .grid'
    saGrid: '.south-america .grid'
    euGrid: '.europe .grid'
    asGrid: '.asia .grid'
    ocGrid: '.oceania .grid'
    afGrid: '.africa .grid'

  events:
    'click .toggle-region'        : 'toggleRegion'
    'click .toggle-country input' : 'toggleCountry'

  initialize: (options) ->
    { @accountId } = options

  onRender: =>
    @collection
      .fetch(data: {accountId: @accountId, isUS: false})
      .done((data) =>
        return unless @getRegion('naGrid')
        @showChildView('naGrid', @buildGrid(NorthAmericaCountryRow))
        @showChildView('saGrid', @buildGrid(SouthAmericaCountryRow))
        @showChildView('euGrid', @buildGrid(EuropeCountryRow))
        @showChildView('asGrid', @buildGrid(AsiaCountryRow))
        @showChildView('ocGrid', @buildGrid(OceaniaCountryRow))
        @showChildView('afGrid', @buildGrid(AfricaCountryRow))
        @setRegionToggle('north-america')
        @setRegionToggle('south-america')
        @setRegionToggle('europe')
        @setRegionToggle('asia')
        @setRegionToggle('oceania')
        @setRegionToggle('africa')
      )

  buildGrid: (row) =>
    editable = @user().can('call-recording-regions:edit')

    enableCell = class extends Backgrid.BooleanCell
      className: 'toggle-country'
      render:  ->
        region = @model.get('continent').toLowerCase().replace(/ /g, '-')
        html = "<input tabindex='-1' type='checkbox' data-region='#{region}' #{if @model.get('setting').voicemailTranscriptionEnabled then 'checked'}>"
        $(@el).html(_.template(html))
        @

      events: ->
        'change input': (e) ->
          @model.get('setting').voicemailTranscriptionEnabled = e.target.checked
          @column.channel.trigger('edit', { model: @model }) if @column?.channel

    columns = [
      { name: 'setting.voicemailTranscriptionEnabled', label: 'Enabled',  cell: enableCell, editable: editable, sortable: false, className: 'action-cell'},
      { name: 'name',    label: 'Country',  cell: 'string', sortable: false }
    ]

    new BaseGrid.Grid(
      row        : row
      columns    : columns
      collection : @collection
      emptyText  : 'No countries found.'
    )

  toggleRegion: (e) ->
    region = $(e.target).attr('id').replace('toggle-', '')
    checked = $(e.target).prop('checked')
    $("[data-region='#{region}']").prop('checked', checked).trigger('change')

  toggleCountry: (e) ->
    region = @$(e.target).attr('data-region')
    @setRegionToggle(region)

  setRegionToggle: (region) ->
    regionCheckbox = $("#toggle-#{region}")
    numCountries = $("[data-region='#{region}']").length
    numChecked = $("[data-region='#{region}']:checked").length
    if numChecked is 0
      regionCheckbox.prop('indeterminate', false)
      regionCheckbox.prop('checked', false)
    else if numChecked is numCountries
      regionCheckbox.prop('indeterminate', false)
      regionCheckbox.prop('checked', true)
    else
      regionCheckbox.prop('indeterminate', true)

class NorthAmericaCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'North America' then @$el.hide() else super
    @

class SouthAmericaCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'South America' then @$el.hide() else super
    @

class EuropeCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'Europe' then @$el.hide() else super
    @

class AsiaCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'Asia' then @$el.hide() else super
    @

class OceaniaCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'Oceania' then @$el.hide() else super
    @

class AfricaCountryRow extends Backgrid.Row
  render: =>
    if @model.get('continent') isnt 'Africa' then @$el.hide() else super
    @

module.exports = CountriesView
