BaseView = require('base/view')
EmailCalendar = require('src/components/integrations/email-calendar').default
ProductConstant = require('@ringdna/common/src/constants/products')
ReactComponent  = require('src/ReactComponent').default

class EmailCalendarView extends BaseView
  template: require('./template')
  className: 'email-calendar-view'

  regions:
    container : '.email-calendar'

  initialize: (options) =>
    { @qs } = options
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @qs.setFragment('integrations/email-calendar')

  onRender: =>
    @showChildView('container', new ReactComponent({
      component: EmailCalendar
      theme: ProductConstant.Products.ADMIN
      props: {
        accountId: @accountId
      }}))

module.exports = EmailCalendarView
