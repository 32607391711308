BaseModel = require('base/model')

module.exports = class ResultUserModel extends BaseModel

  defaults:
    sfUserId              : ''
    numberCreated         : false
    numberDescription     : ''
    userCreated           : false
    userDescription       : ''
    dialerLicense         : false
    adminLicense          : false
    conversationAILicense : false
    guidedSellingLicense  : false
    yodaAILicense         : false
    calendarBookingLicense: false
