BaseView  = require('base/view')
ReactComponent  = require('src/ReactComponent').default
ProductPortalComponent = require('src/components/product-portal').default
ProductConstant = require('@ringdna/common/src/constants/products')

class ProductPortal extends BaseView
  template: => '<div class="product-portal-react"></div>'

  regions:
    'product-portal-react': 'div.product-portal-react'

  onRender: ->
    minHeight = 400
    if $('.right-col')?.css('min-height')?.length > 3
      minHeight = parseInt($('.right-col').css('min-height').substr(0, $('.right-col').css('min-height')?.length - 2))
    @showChildView('product-portal-react', new ReactComponent({ component: ProductPortalComponent, theme: ProductConstant.Products.CONVERSATION_AI, props: { minHeight: minHeight }}))

module.exports = ProductPortal
