import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48">
      <g id="01-admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="01b_account_connected_accounts-(enabled)"
          transform="translate(-831.000000, -1136.000000)"
          fill="#0072C6"
        >
          <g id="Hotmail" transform="translate(675.000000, 1093.000000)">
            <g id="Outlook.com_icon" transform="translate(156.000000, 42.000000)">
              <path
                d="M29.984914,11.313686 L29.984914,20.5048 L33.1968626,22.5272689 C33.2815734,22.5519761 33.4651131,22.5537419 33.5498239,22.5272689 L47.3735541,13.2073239 C47.3735541,12.1043194 46.3446712,11.313686 45.7640523,11.313686 L29.984914,11.313686 Z"
                id="path3"
              ></path>
              <path
                d="M29.984914,23.9338198 L32.9162581,25.9474644 C33.329223,26.2510113 33.8268986,25.9474644 33.8268986,25.9474644 C33.3309878,26.2510113 47.3753198,16.9222423 47.3753198,16.9222423 L47.3753198,33.8149734 C47.3753198,35.6539023 46.1981937,36.4251225 44.8745896,36.4251225 L29.9831491,36.4251225 L29.9831491,23.9338198 L29.984914,23.9338198 Z"
                id="path5"
              ></path>
              <g id="g23" transform="translate(0.122617, 0.357765)">
                <path
                  d="M14.6849578,19.3387534 C13.6860771,19.3387534 12.8901492,19.808192 12.3024685,20.7453045 C11.7147878,21.6824169 11.4200651,22.9230761 11.4200651,24.4672821 C11.4200651,26.0344306 11.7147878,27.273325 12.3024685,28.1839653 C12.8901492,29.0963705 13.6613698,29.5499258 14.6143655,29.5499258 C15.5973629,29.5499258 16.3774075,29.1069593 16.9527346,28.2210263 C17.5280616,27.3350933 17.8174899,26.1050229 17.8174899,24.53258 C17.8174899,22.8930744 17.5386504,21.6171191 16.9792067,20.7047139 C16.4197629,19.7940736 15.6556015,19.3387534 14.6849578,19.3387534 Z"
                  id="path25"
                ></path>
                <path
                  d="M0.778279819,6.2880068 L0.778279819,42.2459463 L28.1327859,47.9815686 L28.1327859,0.993586255 L0.778279819,6.2880068 Z M19.0828564,30.3458537 C17.926908,31.8671172 16.4197629,32.6295138 14.5596565,32.6295138 C12.7471999,32.6295138 11.2718213,31.8918245 10.1299913,30.4182108 C8.98992608,28.9428323 8.41812866,27.0227224 8.41812866,24.6543517 C8.41812866,22.1536203 8.99698531,20.1311517 10.1564634,18.5869457 C11.3159415,17.0427397 12.8513235,16.2697543 14.7626093,16.2697543 C16.5680067,16.2697543 18.0292667,17.0074436 19.1428599,18.4863517 C20.2582178,19.9652599 20.8158968,21.9136066 20.8158968,24.3331568 C20.8176616,26.8197697 20.2388049,28.8245902 19.0828564,30.3458537 Z"
                  id="path27"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
