BaseView = require('base/view')

class NumberItemView extends BaseView

  template: require('./item-template')

  bindings:
    '.message' : 'text:message'
    '.number'  : 'text:number'
    '.status'  : 'text:status'

  className: =>
    if @model.get('status') is 'Failed'
      'row number-item failed'
    else
      'row number-item'

module.exports = NumberItemView
