var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<audio id=\"videojsplayer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"prefix") || (depth0 != null ? lookupProperty(depth0,"prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prefix","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":35}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"instance") || (depth0 != null ? lookupProperty(depth0,"instance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"instance","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":48}}}) : helper)))
    + "\" class=\"video-js vjs-default-skin\" controls preload=\"auto\" height=\"30\">\n    <source type='audio/mp3'>\n    <p class=\"vjs-no-js\">\n        To listen to this recording please enable JavaScript, and consider upgrading to a web browser that\n        <a href=\"http://videojs.com/html5-video-support/\" target=\"_blank\">supports HTML5 video</a>\n    </p>\n</audio>\n<div class=\"spinner center\">\n    <span class=\"icon-spinner\"></span>\n</div>\n";
},"useData":true});