BaseView = require('base/view')

class Picklist extends Backbone.Collection
  url: 'users/salesforce/picklist'

class ProfileView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  events:
    'change @ui.picker': 'go'

  onRender: =>
    @ui.picker.selectize(
      load: (query, callback) ->
        return if query?.length < 2

        picklist = new Picklist()
        picklist.fetch(data: profile: query).done((response) =>
          callback(response))

      render:
        option: (item, escape) ->
          "<div>#{escape(item.text)}</div>"
    )
    @selectize = @ui.picker[0].selectize

  val: =>
    @ui.picker.val()

module.exports = ProfileView
