import React from 'react'
import animationData from './rdna-icon-animation.json'
import { Lottie, ReactLottieConfig } from '@crello/react-lottie'

interface Props {
  /**
   * Size of logo
   *
   * @default 200
   * */
  size?: number
}

const animationOptions: ReactLottieConfig = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: animationData
}

const RdnaLogoAnimated = ({ size = 200 }: Props) => (
  <Lottie config={animationOptions} height={`${size}px`} width={`${size}px`} />
)

export default RdnaLogoAnimated
