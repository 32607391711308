import { createUseFetch } from '@ringdna/client/src/react/createUseFetch'
import { MetabaseReportParams } from './types'

type DashboardUrlData = {
  iframeUrl: string
}

export type UseDashboardUrlParams = MetabaseReportParams | undefined

export const useDashboardUrl = createUseFetch<DashboardUrlData, UseDashboardUrlParams>({
  key: 'get-metabase-dashboard-url-hook',
  path: params => `/api/v2/app/reports/urlFor${params?.isDashboard ? 'Dashboard' : 'Question'}`
})

type PickListParam = {
  accountId?: number
  name?: string
  momentsEndpoint?: boolean
  type: string
  pageSize?: number
}

export type UsePicklistParams = {
  query?: PickListParam
  meta: PickListParam
}

type PicklistItem = { text: string; value: string }
export type PicklistData = PicklistItem[]

export const v3Picklist = ['keywordGroups', 'opportunityOwners', 'opportunityNames']

export const usePlayPicklist = createUseFetch<PicklistData, UsePicklistParams>({
  key: 'get-play-picklist-search',
  path: params =>
    params.meta.momentsEndpoint
      ? `/api/v3/moments/${params.meta.accountId}/${params.meta.type}`
      : `/api/v${v3Picklist.includes(params.meta.type) ? 3 : 2}/app/${
          params.meta.type
        }/picklist?pager.currentPage=1&pager.pageSize=${
          params.meta.pageSize || 30
        }&userStatus=all&criteria=&accountId=${params.meta.accountId}&name=${params.meta.name}`
})
