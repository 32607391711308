export default class UserService {
  private app: any

  constructor(app: any) {
    this.app = app
  }

  get user(): any {
    return this.app.session.user
  }

  get isSupport(): boolean {
    return this.user.isSupport()
  }

  // Authorized admins are admins across several accounts
  get isAuthorizedAdmin(): boolean {
    return this.user.get('userAccountAuthorizations').length > 0
  }

  get isAdmin(): boolean {
    return this.user.isAdmin()
  }

  get accountId(): string {
    return this.get('accountId')
  }

  get userName(): string {
    return this.get('userName')
  }

  get accountName(): string {
    return this.get('accountName')
  }

  /**
   * Generic getter for backwards compatability.  We should not use this in newer Typescript code
   * to avoid coupling to backbone model object. We should instead favor new methods that indirectly
   * access the older model.  RDNA-20649
   * @deprecated
   */
  get(key: string) {
    return this.user.get(key)
  }

  getAll() {
    return this.user.toJSON()
  }
}
