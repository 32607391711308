import React, { useState } from 'react'
import styled from 'styled-components'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import { CallCustomLink } from '../../types'
import {useCallCustomLinkCreate, useCallCustomLinkUpdate} from '../../api'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'

type CallCustomLinkProps = {
  open: boolean
  onClose: (isFetch: boolean) => void
  accountId: number
  customLink: CallCustomLink
  setCustomLink: (value: CallCustomLink) => void
}

export default function CallCustomLinkModal({ open, onClose, accountId, customLink, setCustomLink }: CallCustomLinkProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error } = useRdnaNotification()
  const updateCallCustomLink = useCallCustomLinkUpdate()
  const createCallCustomLink = useCallCustomLinkCreate()

  async function handleConfirm() {
    setProcessing(true)
    try {
      if (customLink.id) {
        await updateCallCustomLink({
          meta: { id: customLink.id }, body: { accountId: accountId, name: customLink.label, url: customLink.url }
        })
      } else {
        await createCallCustomLink({
          body: { accountId: accountId, name: customLink.label, url: customLink.url }
        })
      }
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
    onClose(true)
  }

  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Save'}
      heading={!customLink.id ? 'Add Custom Link' : 'Edit Custom Link'}
      onClose={() => onClose(false)}
      onConfirm={handleConfirm}
      confirmButtonDisabled={!customLink.label || !customLink.url}
      confirmButtonProcessing={processing}
    >
      <StyledSection>
        <RdnaTextInput
          name={'call-custom-link-label'}
          label={'Name'}
          placeholder={'Custom Link Name'}
          required
          onChange={e => {
            setCustomLink({ ...customLink, label: e.target.value })
          }}
          value={customLink.label}
          style={{marginBottom: 30}}
        />
        <RdnaTextInput
          name={'call-custom-link-url'}
          label={'URL'}
          placeholder={'e.g. https://www.revenue.io'}
          required
          onChange={e => {
            setCustomLink({ ...customLink, url: e.target.value })
          }}
          value={customLink.url}
        />
      </StyledSection>
    </RdnaModal>
  )
}

const StyledSection = styled.section`
  min-width: 300px;
`
