import React, { useContext } from 'react'
import styled from 'styled-components'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

import { AccountIntegration, InitIntegrationsContext } from '../../../model'
import { integrationItems, IntegrationsActions } from '../../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { IntegrationsContext } from '../../../store/IntegrationsProvider'
import {
  useIntegrationRequest,
  useOauthUrl,
  useOauthUrl2,
  useHooversOauthUrl,
  useZoomOauthUrl
} from '../../../../../../store/api/user-integrations-api'
import useToast from '../../../../../common/hooks/useToast'

import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'

const StyledBox = styled.div`
  border: 1px solid #ccc;
  height: 206px;
  max-width: 280px;
  text-align: center;
  position: relative;
  margin: ${({ theme }) => theme.spacing}px;
  padding: ${({ theme }) => theme.spacing * 3}px;
  .rdna-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`
const Text = styled(RdnaText)`
  display: block;
  padding-top: ${({ theme }) => theme.spacing * 2}px;
`

export default function SelectIntegrationItem({ integration }: { integration: AccountIntegration }) {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const integrationDetails = integrationItems[integration.provider]
  const Icon = integrationDetails.icon

  const isZoomOauth = integration.provider === IntegrationProviderTypes.ZOOM
  const isHooversOauth = integration.provider === IntegrationProviderTypes.HOOVERS
  const NYLAS_API_V3 = isFlagged(flags.NYLAS_API_V3)

  const getZoomOauthUrl = useZoomOauthUrl()
  const getOauthUrl = useOauthUrl()
  const getOauthUrl2 = useOauthUrl2()
  const getHooversOauthUrl = useHooversOauthUrl()
  const postIntegrationRequest = useIntegrationRequest()

  const connectOauthIntegration = async () => {
    try {
      const res =
        integration.provider === IntegrationProviderTypes.ZOOM
          ? await getZoomOauthUrl({
              query: { userId: activeUser.userId, redirectUrl: window.location.href }
            })
          : integration.provider === IntegrationProviderTypes.HOOVERS
          ? await getHooversOauthUrl({
              query: { userId: activeUser.userId }
            })
          : !NYLAS_API_V3
          ? await getOauthUrl({
              query: { userId: activeUser.userId, service: integration.provider.toLowerCase() }
            })
          : await getOauthUrl2({
              query: { userId: activeUser.userId, service: integration.provider.toLowerCase() }
            })
      if (res?.success?.payload) {
        window.location.href = res.success.payload.oauth_url
      }
    } catch (error) {
      toastr.error(error.message)
    }
  }

  const connectIntegration = () => {
    if (
      isZoomOauth ||
      isHooversOauth ||
      integration.provider === IntegrationProviderTypes.EXCHANGE ||
      (NYLAS_API_V3 && integration.provider === IntegrationProviderTypes.GMAIL)
    ) {
      connectOauthIntegration()
    } else {
      dispatch({ type: IntegrationsActions.SET_SELECTED_INTEGRATION, payload: integration })
    }
  }

  const requestIntegration = async () => {
    if (integration.provider) {
      const formData = {
        body: {
          userId: activeUser.userId,
          provider: integration.provider as IntegrationProviderTypes
        }
      }
      try {
        const res: any = await postIntegrationRequest(formData)
        if (res) {
          toastr.info('Access requested to admin.')
        }
      } catch (error) {
        toastr.error(error.message)
      }
    }
  }

  return (
    <div className={`${integration.provider.toLowerCase()}-connect`}>
      <StyledBox>
        <Icon />
        <Text>{integrationDetails.description}</Text>
        {!integration.enabled && !isZoomOauth && (
          <RdnaButton
            name="request-connection"
            variant="outlined"
            text="Request Connection"
            onClick={requestIntegration}
          />
        )}
        {(integration.enabled || isZoomOauth) && !integration.connected && (
          <RdnaButton name="connect-integration" variant="outlined" text="Connect" onClick={connectIntegration} />
        )}
        {integration.enabled && integration.connected && (
          <RdnaButton name="connected-integration" variant="outlined" text="Connected" disabled />
        )}
      </StyledBox>
    </div>
  )
}
