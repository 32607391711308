import React from 'react'
import styled from 'styled-components'

// components
import RdnaText from '@ringdna/common/src/components/RdnaText'

const CallRecordingTermsModal = () => {
  return (
    <>
      <Text>
        There are various U.S. Federal & State and country-by-country statutes governing the use of call recording.
        Unlawful use of call recording can lead to civil suits and also criminal prosecution. Customers need to ensure
        that their company&apos;s call recording procedures comply with all applicable laws and regulations. ringDNA is
        not responsible for Customer compliance.
      </Text>
      <Text>
        By using the ringDNA call recording feature, Customer is required and agrees to maintain compliance with all
        applicable laws and regulations. Customer understands and agrees that Customer is solely responsible and liable
        for compliance with such laws and regulations and under no circumstances shall ringDNA be responsible or held
        liable for such compliance. Customer agrees to indemnify ringDNA for any violations of such laws and
        regulations.
      </Text>
      <Text>
        In some countries (including certain U.S. States) obtaining consent from all parties to record a phone call is
        required. As a result, Customer may need to inform its employees and third-parties called through the ringDNA
        service that their calls are being recorded. Customer is responsible for obtaining any and all legally-required
        consents when making a call with call recording enabled.
      </Text>
      <Text>
        Customer should consult with an attorney prior to recording any call. The information above does not constitute
        legal advice.
      </Text>
    </>
  )
}

export default React.memo(CallRecordingTermsModal)

const Text = styled(RdnaText)`
  margin: 0 0 20px;
`
