BaseView      = require('base/view')

class StepDetailsView extends BaseView

  template: require('./step-details-template')

  regions:
    moreInfo: 'div#more-info'

  bindings:
    '.menuAssignment'   : 'toggle:menuAssignment'
    '.promptAssignment' : 'toggle:promptAssignment'
    '.branchAssignment' : 'toggle:branchAssignment'
    'select.assignment' : 'options:unassigned,optionsDefault:assignment'
    'input.assignment'  : 'value:assignment, disabled:fixedAssignment'
    'input.speechAssignment': 'value:speechAssignment'
    '.speech'           : 'toggle:not(reservedAssignment)'
    'input.title'       : 'value: stepName'

  computeds:
    stepName:
      get: ->
        stepName = @model.get('name')
        if stepName?.length > 0 then stepName else @model.get('type')
    reservedAssignment:
      get: ->
        'Any Response' == @model.assignment() or 'No Response' == @model.assignment()
    fixedAssignment:
      get: ->
        return true if @callflow.get('readOnly')
        fixedPlaceholders = @model?.parent()?.get('fixedPlaceholders')
        if fixedPlaceholders
          return true for placeholder in fixedPlaceholders when placeholder is @model.assignment()
        false

  initialize: (options) ->
    @callflow = options.callflow
    @viewModel = new Backbone.Model(
      menuAssignment: @model.parent()?.get('type') is 'Menu'
      promptAssignment: @model.parent()?.get('type') is 'Prompt'
      branchAssignment: @model.parent()?.get('type') is 'Branch'
      assignment: @model.assignment() ? ''
      speechAssignment: @model.speechAssignment() ? ''
      unassigned: @model.unassigned() ? []
    )
    @model.set('maxDigits', @model.parent().args()['maxDigits']) if @viewModel.get('promptAssignment')

  onRender: =>
    newArgsStepView = require("./step-models/#{@model.get('type')}/view")
    @showChildView('moreInfo', new newArgsStepView(model: @model, callflow: @callflow))

module.exports = StepDetailsView
