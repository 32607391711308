BackgridCell      = require('helpers/backgrid-cell')
BackgridRow       = require('helpers/backgrid-row')
BaseView          = require('base/view')
PagedCollection   = require('base/paged-collection')
PagerView         = require('widgets/pager/view')
UserPickerView    = require('widgets/user-picker/view')
BaseGrid          = require('base/grid')
NumberTransferModal = require('./modals/transfer-number-view')

class NumberCollection extends PagedCollection
  parseRecords: (response, options) =>
    @trigger('pager:state:changed', @state)
    result = []
    result.push(number) for number in response.results
    result

module.exports = class NumberTransferView extends BaseView

  template: require('./template')
  className: 'number-transfer'

  regions:
    userPicker    : 'span.user-picker'
    grid          : 'div.grid'
    pager         : 'span.pager'
    modal         : 'div.modal-container'

  ui:
    phoneNumber : 'input.phone-number'
    search      : 'button.search'

  events:
    'click @ui.search' : 'search'
    'keyup .titlebar input:text' : 'keyPressed'

  childViewEvents:
    reload: -> @render()

  initialize: (options) ->
    @qs = options.qs

  onRender: =>
    @userPicker = @getRegion('userPicker')
    @userPicker.show(new UserPickerView(accountId: @user().getSelectedSupportAccountId()))
    @qs.bind(
      phoneNumber : @ui.phoneNumber
      userId      : @userPicker.currentView
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    return unless @validate()

    @qs.navigate('system/number-transfer')
    collection = new NumberCollection(
      queryParams:
        number    : @ui.phoneNumber.val()
        accountId : @user().getSelectedSupportAccountId()
        userId    : @userPicker.currentView.val()
      url: 'smartNumbers'
    )

    @detachChildView('grid')
    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection))

    collection.fetch(reset: true)

  buildGrid: (collection) ->
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a.edit': 'edit'
      edit: =>
        @column.channel.trigger('edit', { model: @model })
      render: ->
        allowedType = ['Default', 'Additional', 'Tracking'].indexOf(@model.get('type')) > -1
        sidExist = @model.get('sid') isnt 'UNKNOW-LEGACY'
        if allowedType and sidExist and not @model.get('isDeleted') and not @model.get('group')
          $(@el).html('''
            <a class="edit">
              <span class="glyphicon glyphicon-share"></span>
            </a>
          ''')
        @

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:id',
      label : ':number',
      attrs : ['id', 'number'])

    acctCell = BackgridCell.accountCell('account.id', 'account.name')

    userCell = BackgridCell.usersCell('user.id', 'user.displayName')

    cols = [
      {label: '',               name: '',                 cell: actionCell}
      {label: 'Number',         name: 'number',           cell: numbCell}
      {label: 'Type',           name: 'type',             cell: 'string'}
      {label: 'Account',        name: 'account.name',     cell: acctCell}
      {label: 'User',           name: 'user.displayName', cell: userCell}
      {label: 'Group',          name: 'group.name',       cell: 'string'}
      {label: 'SID',            name: 'sid',              cell: 'string'}

    ]

    new BaseGrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart numbers found.'
      row        : BackgridRow.deleted()
      onEdit     : @numberTransfer
    )

  numberTransfer: (options) =>
    model = options.model?.clone()
    @showChildView('modal', new NumberTransferModal(model: model))
    $('#transfer-number-modal').modal('show')

  validate: =>
    if @ui.phoneNumber.val() and @ui.phoneNumber.val().length < 3
      toastr.warning('Phone number must be at least three characters.')
      return false
    return true

