class CallDispositionEditorModal extends Backbone.Modal

  template: _.template('<div class="modals-container"></div>')

  viewContainer: '.modals-container'

  submitEl: '.save-btn'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1')
    'click #step2':
      view: require('./step-2')

  events:
    'click .previous': 'previousStep'
    'click .next': 'nextStep'

  initialize: (options) ->
    { @accountId, @collection, @type } = options

  beforeSubmit: (e) =>
    return false if @currentIndex isnt 1 or e.keyCode is 13
    ladda = Ladda.create(@$('.save-btn')[0])
    ladda.start()
    @currentView.callDispositionTeams.persist(@currentView.settingsCollection.shadowCollection).done(=>
      @collection.trigger('reloadCallDispositions', (success) =>
        if success
          toastr.info('Disposition saved.')
          ladda.stop()
          @destroy()
      )
    ).fail( => ladda.stop())
    false

  nextStep: (e) ->
    e.preventDefault()
    switch @currentIndex
      when 0
        @next()

  previousStep: (e) =>
    e.preventDefault()
    switch @currentIndex
      when 1
        @previous()

module.exports = CallDispositionEditorModal
