AddressModel = require('widgets/smart-numbers-widget/address-model')
AddressCollectionView = require('./address-collection-view')
BaseView = require('base/view')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')

class AddressStepModal extends BaseView
  template: require('./address-step-template')

  regions:
    addresses: '.addresses'

  bindings:
    'h4.modal-title': 'text:title'

  computeds:
    title:
      get: -> @title

  events:
    'keyup .provide': 'createAddress'
    'click .provide': 'createAddress'
    'click .close'  : 'close'

  initialize: (options) =>
    { @accountId, @addressCollection, @title } = options
    @createAddressCollection()

  onRender: =>
    @showChildView('addresses', new AddressCollectionView(collection: @addressCollection, countries: CallTrackingWidgets.countryCollection))

  filterCountries: =>
    countries = []
    anyTypeCollection = @collection.where(addressRequirements: 'any')
    for model in @collection.models
      existCountries = countries.filter((country) => country.code is model.get('countryCode'))
      if existCountries.length is 0
        hasAnyType = model.get('addressRequirements') is 'any'
        countries.push(
          code: model.get('countryCode')
          type: model.get('addressRequirements')
        ) if not hasAnyType or @collection.length is 1 and hasAnyType or anyTypeCollection.length is @collection.length
    countries

  createAddressCollection: =>
    countries = @filterCountries()
    for country in countries
      countryTotalInfo = CallTrackingWidgets.countryCollection.findWhere({countryCode: country.code})
      if countryTotalInfo
        addressModel = new AddressModel(
          countryCode: country.code
          countryName: countryTotalInfo?.get('countryName')
          addressRequirements: country.type
        )
        @addressCollection.add(addressModel)

  createAddress: ->
    return if CallTrackingHelper.errorAddresses(@addressCollection)

    ladda = Ladda.create(@$('.provide')[0])
    ladda.start()
    @addressCollection
      .setParams(accountId: @accountId).create()
      .done( =>
        notCreatedAddresses = @addressCollection.where(created: false)
        address.trigger('createdError') for address in notCreatedAddresses when notCreatedAddresses.length isnt 0
        if @addressCollection.where(created: true).length isnt 0 and @collection.length isnt 0
          @trigger('repeatCreateCallTrackings', ladda, notCreatedAddresses)
        else
          ladda.stop()
    )

  close: ->
    $('#address-step-modal').modal('hide')

module.exports = AddressStepModal
