BaseView      = require('base/view')

class SmartNumberRowView extends BaseView
  template: require('./smart-number-row-template')
  tagName: 'tr'

  computeds:
    isSupport:
      get: -> @user().isSupport()
    isHidden:
      deps: ['isDeleted', 'isSupport']
      get: (isDeleted, isSupport) -> isDeleted and not isSupport
    isDisabled:
      deps: ['isDefault', 'isDeleted']
      get: (isDefault, isDeleted) -> isDefault || isDeleted

  bindings:
    ':el'                     :  "classes:{'hidden':isHidden}"
    'a.remove-smart-number'   :  "classes:{'disabled':isDisabled}"
    '.is-deleted'             :  "classes:{'hidden':not(isSupport)}"

module.exports = SmartNumberRowView
