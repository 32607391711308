BaseView = require('base/view')
BaseGrid = require('base/grid')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')
OfflineModel = require('features/call-tracking/offline/model')
UnassignedNumbersGrid = require('../../__components/unassigned-numbers-grid/view')

class OfflineCallTrackingModal extends BaseView
  template: require('./template')
  className: 'offline-editor'

  regions:
    grid: 'div.grid'
    numbersGrid: 'div.numbers-grid'

  ui:
    popup: 'div.popup'

  events:
    'click .add-offline' : 'addOffline'
    'click .finish'     : 'finish'

  bindings:
    '.add-call-tracking-grid': 'classes:{"unset-main-grid":hasUnset}'

  initialize: (options) =>
    { @accountId, @collection } = options
    @viewModel = new Backbone.Model(hasUnset: @collection.length <= 3)
    @activeModelForUnassignedNumber = null
    @listenTo(@collection, 'change:typeNumber', (model) => @checkChangedTypeNumber(model))

  onRender: =>
    @showSpinner($(@el), left: '45%')
    CallTrackingWidgets.getInfo(@accountId, (campaigns, callflows, countries) =>
      @hideSpinner($(@el))
      @showChildView('grid', @buildGrid(campaigns, callflows, countries)) if @getRegion('grid')
      $('[data-toggle="tooltip"]').tooltip()
    )

  checkChangedTypeNumber: (changedModel) =>
    if changedModel.get('typeNumber') is 'Existing'
      @activeModelForUnassignedNumber = changedModel
      @showChildView('numbersGrid', new UnassignedNumbersGrid(accountId: @accountId, changedModel: changedModel))
    else
      if @activeModelForUnassignedNumber?.cid == changedModel.cid
        @getRegion('numbersGrid').destroy() if @getRegion('numbersGrid')

  buildGrid: (campaigns, callflows, countries) =>
    mainClass = @

    channelCell = class extends Backgrid.Cell
      events:
        'change .channel-cell': 'channelChanged'

      channelChanged: (e) =>
        @model.set('channel', $(e.target).val())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'change:channel', => @showHtml(not @model.get('channel')))
        @listenTo(@model, 'checkChannel', => @showHtml(true))

      showHtml: (hasError) =>
        html = """<input type='text' class='form-control channel-cell' maxlength='255' value=\"#{@model.get('channel')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    sourceCell = class extends Backgrid.Cell
      events:
        'change .source': 'sourceChanged'

      sourceChanged: (e) =>
        @model.set('source', $(e.target).val())

      render: =>
        @showHtml(false)
        @listenTo(@model, 'checkSource', => @showHtml(true))
        @listenTo(@model, 'change:source', => @showHtml(not @model.get('source')))

      showHtml: (hasError) =>
        html = """<input class='source form-control' list='sources' value=\"#{@model.get('source')}\"><datalist id='sources'>
                  <option value='Print'>Print</option>
                  <option value='TV'>TV</option>
                  <option value='Radio'>Radio</option>
                  </datalist>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

    deleteCell = class extends Backgrid.Cell
      events:
        'click a.delete': 'delete'

      delete: ->
        bootbox.confirm('Are you sure you want to delete this?', (ok) =>
          @model.destroy(data: accountId: @model.get('accountId')) if ok
          true
        )
      render: =>
        @showHtml()
        @listenTo(mainClass.collection, 'update', => @showHtml())

      showHtml: =>
        html = """<a class="delete"><span class='glyphicon glyphicon-remove-sign'></span></a>""" if mainClass.collection.length > 1
        $(@el).html(if html then html else '')
        @

    columns = [
      {label: 'Name',          name: 'channel',  cell: channelCell,                                         sortable: false}
      {label: 'Source',        name: 'source',   cell: sourceCell,                                          sortable: false}
      {label: 'Country',       name: 'country',  cell: CallTrackingWidgets.countryCell(countries),          sortable: false}
      {label: 'Smart Number',  name: 'type',     cell: CallTrackingWidgets.typeSmartNumberCell(),           sortable: false}
      {label: '',              name: 'smartNumberId', cell: CallTrackingWidgets.typeSmartNumberValueCell(), sortable: false}
      {label: 'Area Code',     name: 'code',     cell: CallTrackingWidgets.areaCodeCell(),                  sortable: false}
      {label: 'Call Flow',     name: 'callFlow', cell: CallTrackingWidgets.callFlowCell(callflows),         sortable: false}
      {label: 'SFDC Campaign', name: 'campaign', cell: CallTrackingWidgets.campaignCell(campaigns),         sortable: false}
      {label: '',              name: '',         cell: deleteCell,                                          sortable: false}
    ]

    new BaseGrid.Grid(
      collection : @collection,
      columns    : columns
    )

  addOffline: =>
    @collection.unshift(new OfflineModel())
    @viewModel.set(hasUnset: @collection.length <= 3)

  finish: =>
    return if @validate()

    ladda = Ladda.create(@$('.finish')[0])
    ladda.start()
    @collection.createOfflines(@accountId, null, (hasNeedAddress) =>
      ladda.stop()
      @trigger('openAddressStepModal') if hasNeedAddress
      @ui.popup.modal('hide'))

  validate: =>
    subjectError = CallTrackingHelper.findErrors(@collection)
    @collection.each((model) =>
      model.trigger('checkChannel') if not model.get('channel')
      model.trigger('checkSource') if not model.get('source')
      subjectError = true if not model.get('channel') or not model.get('source')
    )
    toastr.warning('Please correct the highlighted errors.') if subjectError
    return subjectError

module.exports = OfflineCallTrackingModal
