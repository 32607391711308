import UserService from './user.service'

const userService = new UserService(App)

export type UserType = {
  accountId: number
  caiLocationEnabled: boolean
  caiManager: boolean
  userId: number
  isSupport: boolean
  isAuthorizedAdmin: boolean
  userName: string
  userEmail: string
  pubsub?: any
  licenses: {
    admin: boolean
    intelligentDialer: boolean
    calendar: boolean
    conversationAI: boolean
    guidedSelling: boolean
    moments: boolean
    salesSupport: boolean
    supervisor: boolean
    support: boolean
  }
}

export const user: UserType = {
  accountId: userService.accountId,
  caiLocationEnabled: userService.caiLocationEnabled,
  caiManager: userService.caiManager,
  userId: userService.userId,
  isSupport: userService.isSupport,
  isAuthorizedAdmin: userService.isAuthorizedAdmin,
  userName: userService.userName,
  userEmail: userService.userEmail,
  pubsub: userService.pubsub,
  licenses: userService.licenses
}
