import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function UserPresenceIcon({ color = Colors.N100, size = 60, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" data-testid="user-presence-icon">
      <g clipPath="url(#clip0_9043_75796)">
        <path
          d="M33.0134 15.9374C33.0134 21.7304 28.4342 26.4107 22.8048 26.4107C17.1754 26.4107 12.5962 21.7304
        12.5962 15.9374C12.5962 10.1445 17.1754 5.46423 22.8048 5.46423C28.4342 5.46423 33.0134 10.1445 33.0134 15.9374Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M1.63757 41.7322C1.63757 41.7322 6.92938 31.6072 22.8048 31.6072C30.7566 31.6072 36.0531 34.1474
        39.3593 36.6831C34.5192 40.7439 35.7575 46.2411 36.9816 48.4822H5.16545L1.63757 41.7322Z"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
        <ellipse cx={48.9373} cy={45} rx={8.36237} ry={8.57143} stroke={color} strokeWidth={strokeWidth} />
        <path
          d="M52.0732 42.2755C52.0732 42.6938 52.0118 43.0561 51.8892 43.3622C51.7665 43.6683 51.608 43.9387
        51.4138 44.1734C51.2196 44.4081 51.01 44.6224 50.7851 44.8163C50.5602 45.0102 50.3404 45.1989 50.1257
        45.3826C49.8599 45.6173 49.635 45.8571 49.451 46.102C49.2772 46.3469 49.1903 46.6428 49.1903
        46.9898H47.9022C47.9022 46.653 47.9482 46.3571 48.0402 46.102C48.1322 45.8469 48.2549 45.6173 48.4082
        45.4132C48.5616 45.1989 48.7303 45.0051 48.9143 44.8316C49.1085 44.6581 49.3028 44.4898 49.497 44.3265C49.8343
        44.051 50.1308 43.7704 50.3864 43.4847C50.6522 43.1887 50.7851 42.8265 50.7851 42.3979C50.7851 41.8877 50.6011
        41.4949 50.233 41.2194C49.8752 40.9438 49.3692 40.8061 48.7149 40.8061C48.5207 40.8061 48.3111 40.8265 48.0862
        40.8673C47.8715 40.9081 47.6517 40.9591 47.4268 41.0204C47.2122 41.0816 46.9975 41.153 46.7828 41.2347C46.5681
        41.3163 46.3688 41.403 46.1848 41.4949L45.8014 40.5153C46.793 39.9336 47.8204 39.6428 48.8836 39.6428C49.3845
        39.6428 49.8292 39.7143 50.2177 39.8571C50.6164 39.9898 50.9538 40.1785 51.2298 40.4234C51.5058 40.6581 51.7154
        40.9387 51.8585 41.2653C52.0016 41.5816 52.0732 41.9183 52.0732 42.2755ZM47.5495 49.3775C47.5495 49.1122 47.6364
        48.8826 47.8102 48.6887C47.9942 48.4847 48.2293 48.3826 48.5156 48.3826C48.8018 48.3826 49.0318 48.4796 49.2056
        48.6734C49.3896 48.8673 49.4817 49.0969 49.4817 49.3622C49.4817 49.6275 49.3896 49.8622 49.2056 50.0663C49.0318
        50.2602 48.8018 50.3571 48.5156 50.3571C48.2293 50.3571 47.9942 50.2602 47.8102 50.0663C47.6364 49.8724 47.5495
        49.6428 47.5495 49.3775Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9043_75796">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
