ReactComponent = require('src/ReactComponent').default
SipRoutes      = require('src/components/sip-routing/SipRoutes').default

class SipRoutingView extends Marionette.View
  template: => '<div class="react"></div>'
  regions:
    react           : 'div.react'

  onRender: =>
    @showChildView('react', new ReactComponent({ component: SipRoutes }))

module.exports = SipRoutingView
