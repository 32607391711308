import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { callQueueLink, callQueuesColumns } from './const'
import { DELETE, EDIT, NO_RESULT_STATE, PAGE_SIZE, RESTORE } from '../../const'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { CallQueueProps, DeleteModalProps, RtnPagination } from '../../types'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useCallQueueDelete, useCallQueueRestore } from '../../api'
import useToast from '../../../common/hooks/useToast'

export type CallQueueTableProps = {
  payload: any | undefined
  refetch: any
  onQueryState: (state: RtnPagination) => void
  loading: boolean
  isSupport: boolean
}

export default function CallQueuesTable({ payload, onQueryState, loading, refetch, isSupport }: CallQueueTableProps) {
  const toastr = useToast()
  const [isCallQueueDeleteModalOpen, setIsCallQueueDeleteModalOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [callQueue, setCallQueue] = useState<CallQueueProps | undefined>()
  const deleteCallQueue = useCallQueueDelete()
  const restoreCallQueue = useCallQueueRestore()
  const totalRowsRef = useRef(0)
  if (payload?.total) totalRowsRef.current = payload?.total

  const menuOptions = (callQueue: CallQueueProps) => {
    const options = [{ text: EDIT }]
    if (!callQueue.isDeleted) {
      options.push({ text: DELETE })
    } else {
      options.push({ text: RESTORE })
    }
    return options
  }

  const handleActionMenu = useCallback(
    (action: string, callQueue: CallQueueProps) => {
      if (action === DELETE) {
        setCallQueue(callQueue)
        setIsCallQueueDeleteModalOpen(true)
      } else if (action === EDIT) {
        window.location.href = `${callQueueLink}${callQueue.id}`
      } else if (action === RESTORE) {
        handleRestore(callQueue.id)
      }
    },
    [handleRestore]
  )

  const memoPayload = useMemo(() => {
    return payload?.results?.map((template: CallQueueProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={menuOptions(template)} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
  }, [payload, handleActionMenu])

  async function handleDelete() {
    setIsLoadingDelete(true)
    try {
      await deleteCallQueue({ meta: { id: callQueue?.id } })
      setIsCallQueueDeleteModalOpen(false)
      refetch()
    } catch (e) {
      toastr.error(e)
    }
    setIsLoadingDelete(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleRestore(id: number) {
    try {
      await restoreCallQueue({ meta: { id: id } })
      refetch()
    } catch (e) {
      toastr.error(e)
    }
  }

  return (
    <>
      <RdnaSmartTable
        searchPlaceholder="Search by name or number"
        initialTableState={{
          globalFilter: '',
          sortBy: [{ id: 'id', desc: true }],
          pageIndex: 0,
          pageSize: PAGE_SIZE
        }}
        // @ts-ignore
        columns={callQueuesColumns(isSupport)}
        data={memoPayload || []}
        noResultState={NO_RESULT_STATE}
        setQueryState={onQueryState}
        isFetching={loading}
        totalItems={totalRowsRef?.current}
        paginationSize={PAGE_SIZE}
      />
      <ConfirmCallQueueDeleteModal
        open={isCallQueueDeleteModalOpen}
        onClose={setIsCallQueueDeleteModalOpen}
        onConfirm={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  )
}

const ConfirmCallQueueDeleteModal = ({ open, onClose, onConfirm, isLoadingDelete }: DeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={isLoadingDelete ? 'Deleting...' : 'Delete'}
      confirmButtonDisabled={isLoadingDelete}
      heading={`Delete Call Queue`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>{`You are about to delete this queue. Deleted queues may be recovered for up to 30 days from the original delete date.`}</RdnaText>
    </RdnaModal>
  )
}
