import React, { ReactElement, ReactNode } from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem, PopoverOrigin, TooltipProps } from '@mui/material'
import Kebab from '../../assets/icons/svg/Kebab'
import IconButton from '@mui/material/IconButton'
import styled, { css } from 'styled-components'
import RdnaTooltip from '../RdnaTooltip'
import { Colors } from '../../constants/colors'

type Tooltip = {
  title: string
  placement: TooltipProps['placement']
}

export enum Target {
  BLANK = '_blank',
  SELF = '_self',
  PARENT = '_parent',
  TOP = '_top'
}

export type MenuOption = {
  /** Text displayed for menu item */
  text?: string
  /** Icon displayed for menu item */
  icon?: ReactElement
  /** Url & target for direct link of menu option */
  url?: string
  target?: Target
  /** Tooltip for menu item */
  tooltip?: Tooltip
  /** Disable menu item */
  disabled?: boolean

  /** Component rendered for menu item instead of text. Must have URL. Can have tooltip and be disabled.
   */
  component?: ReactElement
}

export type MenuProps<M extends string> = {
  /** Clickable element that triggers the menu to open. If none provided a kebab icon is used */
  children?: ReactNode
  /** List of options for menu dropdown */
  options: MenuOption[]
  /** Icon position for menu item (default: before) */
  iconPlacement?: 'before' | 'after'
  /** Event fired on click of selecting menu item */
  onSelect: (menuItem: M, event: React.MouseEvent<HTMLElement>) => void
  /** Event fired on opening of menu */
  onOpen?: () => void
  /** Disable the component from being interacted with */
  disabled?: boolean
  selectedItem?: string
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  className?: string
}

const ToggleContainer = styled.div<{ disabled: boolean }>`
  display: inline-block;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(0, 0, 0, 0.38);
      pointer-events: none;
    `}
`

const MenuToggle = styled.div`
  margin-bottom: ${props => props.theme.spacing}px;
  margin-top: ${props => props.theme.spacing}px;
`

const defaultAnchor: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
}
const defaultTransform: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
}

const RdnaMenu = <M extends string>({
  children,
  options,
  iconPlacement = 'before',
  onSelect,
  onOpen,
  anchorOrigin = defaultAnchor,
  transformOrigin = defaultTransform,
  disabled = false,
  className,
  selectedItem
}: MenuProps<M>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (onOpen) onOpen()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleSelect = (menuItemName: M, event: React.MouseEvent<HTMLElement>) => {
    onSelect(menuItemName, event)
    setAnchorEl(null)
  }

  return (
    <div className={className}>
      <ToggleContainer role="button" aria-label="rdna-menu-trigger" onClick={handleClick} disabled={disabled}>
        {children ? (
          <MenuToggle className="rdna-menu-toggle">{children}</MenuToggle>
        ) : (
          <IconButton disabled={disabled} size="medium">
            <Kebab color={disabled ? Colors.N50 : Colors.N80} />
          </IconButton>
        )}
      </ToggleContainer>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {options.map(option => {
          return option.component ? (
            <span key={option.url}>
              <TooltipContainer tooltip={option.tooltip}>
                <MenuItem
                  style={{ padding: 0 }}
                  component={option.url ? 'a' : 'li'}
                  href={option.url}
                  target={option.target}
                  disabled={option.disabled}
                  disableGutters
                >
                  {option.component}
                </MenuItem>
              </TooltipContainer>
            </span>
          ) : (
            <span key={option.text}>
              <TooltipContainer tooltip={option.tooltip}>
                <MenuItem
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation()
                    handleSelect(option.text as M, event)
                  }}
                  component={option.url ? 'a' : 'li'}
                  href={option.url}
                  target={option.target}
                  disabled={option.disabled}
                  selected={selectedItem === option.text}
                  data-analyticsid={`menu-item-${option.text}`}
                >
                  {option.icon && iconPlacement === 'before' && <ListItemIcon>{option.icon}</ListItemIcon>}
                  <ListItemText primary={option.text} />
                  {option.icon && iconPlacement === 'after' && (
                    <ListItemIcon style={{ justifyContent: 'flex-end' }}>{option.icon}</ListItemIcon>
                  )}
                </MenuItem>
              </TooltipContainer>
            </span>
          )
        })}
      </StyledMenu>
    </div>
  )
}

const TooltipContainer = ({ tooltip, children }: { tooltip?: Tooltip; children: React.ReactElement }) => {
  return tooltip ? (
    <RdnaTooltip title={tooltip?.title} placement={tooltip.placement}>
      <div>{children}</div>
    </RdnaTooltip>
  ) : (
    children
  )
}

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`

export default React.memo(RdnaMenu) as typeof RdnaMenu
