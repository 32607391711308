MetabaseViewModel = require('features/reports/metabase/view-model')
CookieHelper    = require('@ringdna/common/app/helpers/cookie-helper')
cookieConfig = require('../../../src/config/legacy').cookieConfig

class NavController extends Marionette.Object

  initialize: (options) ->
    {@app} = options
    @cookies = new CookieHelper(cookieConfig)
    @lastReactRoute = ''

  # FIXME: Move somewhere else.
  start: (url = '') =>
    @app.view.login.currentView?.destroy()

    Backbone.history.start()
    @app.navigate(url, {trigger: true, replace: true})

  show: (view, reactRoute, subtab) =>
    return if reactRoute and subtab && reactRoute is @lastReactRoute
    @lastReactRoute = reactRoute
    @app.view.showPage(view)

  #
  # Accounts
  #
  showAccountSearch: (qs) =>
    View = require('features/accounts/search/view')
    @show(new View(qs: qs))

  showAccountTabs: (id, tab) =>
    View = require('features/accounts/show/tabs-view')
    @show(new View(accountId: id, tab: tab))

  showMyAccountTabs: =>
    accountId = @app.session.user.get('accountId') # TODO: This is wonky.

    View = require('features/accounts/show/tabs-view')
    @show(new View(accountId: accountId, tab: 'overview'))

  showMyAccountMomentsTab: =>
    accountId = @app.session.user.get('accountId')

    View = require('features/accounts/show/tabs-view')
    @show(new View(accountId: accountId, tab: 'moments-notifications'))

  showRingDNADialerMenu: =>
    View = require('features/accounts/show/tabs-separate-view')
    @show(new View())

  showSupportSettings: (qs) =>
    View = require('features/support-settings/view')
    @show(new View(qs: qs))

  showCallActions: (qs) =>
    accountId = @app.session.user.getSelectedSupportAccountId(true)
    View = require('features/call-actions/view')
    @show(new View(accountId: accountId, qs: qs))

  showTCPA: (qs) =>
    accountId = @app.session.user.getSelectedSupportAccountId(true)
    View = require('features/tcpa/view')
    @show(new View(accountId: accountId, qs: qs))

  showCallRouting: (tab, qs) =>
    accountId = @app.session.user.getSelectedSupportAccountId(true)
    View = require('features/call-routing/view')
    @show(new View(accountId: accountId, qs: qs, tab: tab))

  showSmsSettings: (qs) =>
    View = require('features/sms-settings/view')
    @show(new View(qs: qs))

  #
  # Integrations
  #
  showIntegrationsEmailCalendar: (qs) =>
    View = require('features/integrations/email-calendar/view')
    @show(new View(qs: qs))

  showIntegrationsVideo: (qs) =>
    View = require('features/integrations/video/view')
    @show(new View(qs: qs))

  showIntegrationsSalesforce: (qs) =>
    View = require('features/integrations/salesforce/view')
    @show(new View(qs: qs))

  #
  # Zoom
  #
  showZoom: =>
    View = require('core/zoom/view')
    @show(new View())

  #
  # Calls
  #
  showCallInspector: (id) =>
    View = require('features/calls/inspector/view')
    @show(new View(callId: id))

  showCallSearch: (qs) =>
    View = require('features/calls/inspector/view')
    @show(new View(qs: qs))

  #
  # Call Recordings
  #
  showCallRecordings: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-recordings/view')
    @show(new View(qs: qs, accountId: accountId))

  #
  # Call Flows
  #
  addNewCallFlow: =>
    View = require('features/call-flows/overview/view')
    @show(new View(addNew: true))

  showCallFlowOverview: (id) =>
    View = require('features/call-flows/overview/view')
    @show(new View(flowId: id))

  #
  # Call Tracking
  #
  showDynamicNumberInsertion: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/dynamic-number-insertion/view')
    @show(new View(qs: qs, accountId: accountId))

  showBasicSearch: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/basic/view')
    @show(new View(qs: qs, accountId: accountId))

  showCustomSearch: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/custom/view')
    @show(new View(qs: qs, accountId: accountId))

  showAdWordsSearch: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/adwords/view')
    @show(new View(qs: qs, accountId: accountId))

  showOfflineSearch: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/offline/view')
    @show(new View(qs: qs, accountId: accountId))

  showAdvancedSearch: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/call-tracking/advanced/view')
    @show(new View(qs: qs, accountId: accountId))

  #
  # Queues
  #
  addNewCallQueue: =>
    View = require('features/call-queues/overview/view')
    @show(new View(addNew: true))

  showCallQueueOverview: (id) =>
    View = require('features/call-queues/overview/view')
    @show(new View(queueId: id))

  #
  # Home
  #
  showHome: =>
    View = require('core/home/view')
    @show(new View())

  #
  # Import
  #
  showBlockedNumberImport: =>
    View = require('features/import/blocked-numbers/main-view')
    @show(new View())

  showOutboundNumberImport: =>
    View = require('features/import/outbound-numbers/main-view')
    @show(new View())

  showUserImport: =>
    View = require('features/import/users/main-view')
    @show(new View())

  showBulkNumberDeleter: =>
    View = require('features/system/bulk-number-deleter/main-view')
    @show(new View())

  showPhoneNumberImport: =>
    View = require('features/import/phone-numbers/main-view')
    @show(new View())

  #
  # Messages
  #
  showMessageInspector: (id) =>
    View = require('features/messages/inspector/view')
    @show(new View(messageId: id))

  showMessageSearch: (qs) =>
    View = require('features/messages/search/view')
    @show(new View(qs: qs))

  #
  # Numbers
  #
  showSmartNumberSearch: (qs) =>
    View = require('features/smart-numbers/search/view')
    @show(new View(qs: qs))

  showSmartNumberOverview: (id) =>
    View = require('features/smart-numbers/overview/view')
    @show(new View(id: id))

  showSalesMethodology: (qs) =>
    View = require('features/sales-methodology/view')
    @show(new View(qs: qs))

  #
  # Salesforce Profiles
  #
  showSalesforceProfileSearch: (qs) =>
    View = require('features/salesforce-profiles/search/view')
    @show(new View(qs: qs))

  showSalesforceProfile: (id, tab) =>
    View = require('features/salesforce-profiles/show/view')
    @show(new View(id: id, tab: tab))

  #
  # Reports
  #
  showReports: =>
    View = require('features/reports/board/view')
    @show(new View())

  showBilling: =>
    View = require('features/reports/billing/view')
    @show(new View())

  showMessageVolume: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'messages'
      title: 'Message Volume'
    )
    @_showMetabaseView(viewModel: viewModel)

  showMissedRingRates: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'missed-ring-rates'
      title: 'Missed ring rates'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallDurations: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-duration'
      title: 'Call Durations'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallEfficiency: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-efficiency'
      title: 'Call Efficiency'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallEvents: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'hold-mute-pause-report'
      title: 'Call Events'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallReports: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-reports'
      title: 'Call Reports'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallTransfer: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-transfer'
      title: 'Call Transfer'
    )
    @_showMetabaseView(viewModel: viewModel)

  showEmailsSentReport: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'emails-sent'
      title: 'Emails Sent by Revenue.io'
    )
    @_showMetabaseView(viewModel: viewModel)

  showQBRReport: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'qbr'
      title: 'QBR Report'
    )
    @_showMetabaseView(viewModel: viewModel)

  showSupervisor: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'supervisor'
      title: 'Supervisor Dashboard'
    )
    @_showMetabaseView(viewModel: viewModel)

  showAvgCallDurDay: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'avg-call-dur-day'
      title: 'Average Call Duration by Day'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallDurDayTab: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-dur-day-tab'
      title: 'Call Duration by Day (Tabular)'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallerTypesDay: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'caller-types-day'
      title: 'Caller Types by Day'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallLog: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-log'
      title: 'Call Log'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallVolume: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-volume'
      title: 'Call Volume Dashboard'
    )
    @_showMetabaseView(viewModel: viewModel)

  showConferenceCalls: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'conference-calls'
      title: 'Conference Calls'
    )
    @_showMetabaseView(viewModel: viewModel)

  showQueueMetrics: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'queue-metrics'
      title: 'Queue Metrics'
    )
    @_showMetabaseView(viewModel: viewModel)

  showUserBusyPresenceSummary: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'user-busy-presence-summary'
      title: 'User Busy Presence Summary'
    )
    @_showMetabaseView(viewModel: viewModel)

  showUserBusyPresenceSummaryDay: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'user-busy-presence-summary-day'
      title: 'User Busy Presence Summary By Day'
    )
    @_showMetabaseView(viewModel: viewModel)

  showUserPresenceDailyTotals: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'user-presence-daily-totals'
      title: 'User Presence Daily Totals'
    )
    @_showMetabaseView(viewModel: viewModel)

  showSpamCalls: (qs) =>
    user = App.session.user
    if not user.isSupport()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'spam-calls'
      title: 'Spam Calls'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallDistribution: (qs) =>
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-distribution'
      title: 'Call Distribution'
    )
    @_showMetabaseView(viewModel: viewModel)

  showCallQualityDashboard: (qs) =>
    user = App.session.user
    if user.isAgent() and not user.isSupervisor()
      alert('You cannot access this report.')
      return
    viewModel = new MetabaseViewModel(
      qs: qs
      name: 'call-quality'
      title: 'Call Quality Dashboard'
    )
    @_showMetabaseView(viewModel: viewModel)

  #
  # System
  #
  showCallArchiver: =>
    View = require('features/system/call-archiver/view')
    @show(new View())

  showClearCache: =>
    View = require('features/system/clear-cache/view')
    @show(new View())

  showDeployments: =>
    View = require('features/system/deployments/view')
    @show(new View())

  showDomainBlacklist: =>
    View = require('features/system/domain-blacklist/main-view')
    @show(new View())

  showDowntimeEvents: (qs) =>
    View = require('features/system/downtime-events/view')
    @show(new View(qs: qs))

  showNumberInspector: (qs) =>
    View = require('features/system/number-inspector/view')
    @show(new View(qs: qs))

  showNumberTransfer: (qs) =>
    View = require('features/system/number-transfer/view')
    @show(new View(qs: qs))

  showRecordings: =>
    View = require('features/system/recordings/view')
    @show(new View())

  showServiceUser: =>
    View = require('features/system/service-user/view')
    @show(new View())

  showSipRouting: =>
    View = require('features/system/sip-routing/view')
    @show(new View())

  showSplitAccount: =>
    View = require('features/system/split-account/view')
    @show(new View())

  showTwilioTester: =>
    View = require('features/system/twilio-tester/view')
    @show(new View())

  #
  # Team
  #
  addNewTeam: =>
    View = require('features/team/overview/view')
    @show(new View(addNew: true))

  showTeamOverview: (id) =>
    View = require('features/team/overview/view')
    @show(new View(teamId: id))

  showTeamSearch: (qs) =>
    View = require('features/team/search/view')
    @show(new View(qs: qs))

  #
  # Users
  #
  listSalesforceUsers: (qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/users/salesforce/view')
    @show(new View(qs: qs, accountId: accountId))

  showUserSearch: (qs) =>
    View = require('features/users/search/view')
    @show(new View(qs: qs))

  showRoles: (id, qs) =>
    View = require('features/roles/view')
    subtab = id ? 'main'
    reactRoute = "roles-#{@app.session.user.getSelectedSupportAccountId(true)}"
    @show(new View(qs: qs), reactRoute, subtab)

  showUserTabs: (id, tab, qs) =>
    accountId = @app.session.user.get('accountId')
    View = require('features/users/show/tabs-view')
    @show(new View(userId: id, tab: tab, accountId: accountId, qs: qs))

  showCalendarBooking: (qs) =>
    userId = @app.session.user.get('userId')
    @showUserTabs(userId, 'calendar', qs)

  _showMetabaseView: (params) =>
    View = require('features/reports/metabase/view')
    @show(new View(params))

  #
  # Manage Customer Data
  #
  showCDMSearch: =>
    View = require('features/cdm/search/view')
    @show(new View())

  showCDMView: (id) =>
    View = require('features/cdm/overview/view')
    @show(new View(recordId: id))

  showProductPortal: =>
    View = require('features/product-portal/view')
    @show(new View())

  emptyCallback: =>

module.exports = NavController
