import store from '../store'
import { CachePolicy, ApiConfig, ActionTypes, DefaultApi, SuccessAction } from '../constants'
import { methodOptionsMap, resourceUseCounts, apiConfigMap } from '../caches'

let cachePolicy: undefined | CachePolicy
// @TODO figure out a proper DEFAULT_MAX_BUCKET_SIZE
const DEFAULT_MAX_BUCKET_SIZE = 500000

function configureApi(api: any, apiConfig: ApiConfig) {
  store.dispatch({
    type: ActionTypes.ApiConfig,
    api,
    apiConfig
  })
}

function configureClient(config: { cachePolicy?: CachePolicy; defaultApi: ApiConfig }) {
  if (apiConfigMap.get(DefaultApi)) {
    console.log('Updating Api Client:', config)
  }
  configureApi(DefaultApi, config.defaultApi)
  cachePolicy = config.cachePolicy
}

// [immediate, 10s, 20s, 40s, ...]
const defaultRetryThreshold = (count: number) => {
  return count === 1 ? 0 : Math.pow(2, count - 1) * 5000
}
const THRESHOLD_UNMET = Symbol('The retry threshold has not yet been met for this action')
function processLatentRequests(): Array<Promise<SuccessAction<any>> | typeof THRESHOLD_UNMET> {
  const state = store.getState()
  return state.queues.latent.map(action => {
    const retryThreshold =
      (apiConfigMap.get(action.api || DefaultApi) || ({} as any)).retryThreshold || defaultRetryThreshold
    const ageThreshold = Date.now() - retryThreshold(action.retry ? action.retry.count : 0)
    return action.timestamp < ageThreshold
      ? store.dispatch({ ...action, timestamp: Date.now() }).catch(err => err) // catch any err and resolve, to avoid unhandled promise rejections but still allow callsite to process results
      : THRESHOLD_UNMET
  })
}

type ResourceCacheIndex = [number, string, number] // priority, resourceId, size
// @TODO make this a recursive async process
// @TODO cleanup all non-persisted resources that are no longer active
function processCachePolicy() {
  const cache = store.getState().persist
  const channelDict: { [key: string]: Array<ResourceCacheIndex> } = {}
  for (const prop in cache) {
    const resource = cache[prop]
    const { resourceId, key } = resource
    const methodOptions = key && methodOptionsMap.get(key)
    const cacheClassifier = (methodOptions && methodOptions.cacheClassifier) || ['default-cache-channel', 1]
    const [channel, priority] = typeof cacheClassifier === 'function' ? cacheClassifier(resource) : cacheClassifier
    channelDict[channel] = channelDict[channel] || []
    channelDict[channel].push([priority, resourceId, JSON.stringify(resource).length])
  }
  for (const channel in channelDict) {
    const list = channelDict[channel]
    const channelLimit = (cachePolicy && cachePolicy.channelLimits[channel]) || DEFAULT_MAX_BUCKET_SIZE
    list.sort(([priority]) => priority)
    let culmulativeSize = 0
    const maxIndex = list.findIndex(index => (culmulativeSize += index[2]) > channelLimit)
    // noop if we are not over our quota
    if (maxIndex === -1) return
    for (let i = maxIndex; i < list.length; i++) {
      const resourceId = list[i][1]
      // only delete items from the cache that are not currently depended on
      if (!resourceUseCounts.get(resourceId)) delete cache[resourceId]
    }
  }
}

function resetClient() {
  store.dispatch({ type: ActionTypes.Reset })
}

export { configureClient, configureApi, processLatentRequests, processCachePolicy, resetClient }
