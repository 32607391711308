import React from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../styles'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import SupportSettingInputRow from '../components/SupportSettingInputRow'
import RecordingIcon from '@ringdna/common/src/assets/icons/svg/Recording'

export default AdvancedCallRecordingSettings
function AdvancedCallRecordingSettings() {
  const state = useSupportSettingsState()

  const directConnect = 'settings.directConnect'
  const dualChannelRecording = 'settings.dualChannelRecording'
  const s3RecordingBucket = 'settings.s3RecordingBucket'
  const s3RecordingBucketConfirmation = 's3RecordingBucketConfirmation'
  const s3RecordingRole = 'settings.s3RecordingRole'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={RecordingIcon} iconSize={IconSize} variant="filled" />
        <RdnaText className={'rdna-text'}>Advanced Call Recording Settings</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Dual Channel Recording'}
        isChecked={state.accountSettings[dualChannelRecording]}
        disabled={!state.accountSettings[directConnect]}
        setting={dualChannelRecording}
      />
      <SupportSettingInputRow
        title={'S3 Recording Bucket'}
        value={state.accountSettings[s3RecordingBucket]}
        setting={s3RecordingBucket}
      />
      <SupportSettingInputRow
        title={'S3 Recording Role'}
        value={state.accountSettings[s3RecordingRole]}
        setting={s3RecordingRole}
      />
      <SupportSettingToggleRow
        title={'Apply S3 changes?'}
        isChecked={state[s3RecordingBucketConfirmation]}
        setting={s3RecordingBucketConfirmation}
      />
    </Section>
  )
}
