import React from 'react'
import { TabProps } from '../../types'
import { Section, SectionRow, SectionHeader } from '../../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import SettingsToggle from '../SettingsToggleSwitch'
import {
  callDispositionPromptSettings,
  callDispositionRequiredStateSettings,
  postCallPromptSettings,
  relatedRecordsSettings
} from '../../const'
import CallPromptIcon from '@ringdna/common/src/assets/icons/svg/CallPrompt'

export default function PostCallPromptTab({ accountSettings, setAccountSettings }: TabProps) {
  const disabledSetting =
    !accountSettings[callDispositionPromptSettings] &&
    accountSettings[callDispositionRequiredStateSettings] === 'None' &&
    !accountSettings[relatedRecordsSettings]
  return (
    <div data-testid="post-call-prompt-tab-section">
      <Section>
        <SectionRow>
          <SectionHeader>
            <IconHelper icon={CallPromptIcon} variant="filled" />
            <RdnaText className={'rdna-text'}>Show &quot;Post-Call Prompt&quot;</RdnaText>
          </SectionHeader>
          <SettingsToggle
            isChecked={accountSettings[postCallPromptSettings] && !disabledSetting}
            setting={postCallPromptSettings}
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
            disabled={disabledSetting}
          />
        </SectionRow>
      </Section>
    </div>
  )
}
