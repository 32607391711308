import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import { HttpMethods } from '@ringdna/client/src/constants'
import {
  CallCustomLink,
  CallNoteTemplate,
  CallScript,
  CallScriptEntity,
  Disposition
} from '../types'

export const useCallCustomLinks = createUseFetch<CallCustomLink[], { meta: { accountId: string } }>({
  key: 'get-call-custom-links',
  independent: true,
  path: params => `/api/v2/app/callCustomLinks?accountId=${params.meta.accountId}`
})

export const useCallCustomLinkDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-custom-links',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callCustomLinks/${params.meta.id}`
})

export const useCallCustomLinkCreate = createUseFetchAction<object, {
  body: { accountId: number; name: string; url: string}
}>({
  key: 'create-call-custom-links',
  method: HttpMethods.Post,
  path: `/api/v2/app/callCustomLinks`
})

export const useCallCustomLinkUpdate = createUseFetchAction<object, {
  meta: { id: number | undefined }; body: { accountId: number; name: string; url: string }
}
>({
  key: 'update-call-custom-links',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/callCustomLinks/${params.meta.id}`
})

export const useCallNoteTemplates = createUseFetch<CallNoteTemplate[], { meta: { accountId: string } }>({
  key: 'get-call-note-templates',
  independent: true,
  path: params => `/api/v2/app/callNoteTemplates?accountId=${params.meta.accountId}`
})

export const useCallNoteTemplatesDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-note-templates',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callNoteTemplates/${params.meta.id}`
})

export const useCallNoteTemplatesCreate = createUseFetchAction<object, {
  body: { accountId: number; name: string; template: string; enabled: boolean; teamIds: number[] }
}>({
  key: 'create-call-note-templates',
  method: HttpMethods.Post,
  path: `/api/v2/app/callNoteTemplates`
})

export const useCallNoteTemplatesUpdate = createUseFetchAction<object, {
  meta: { id: number | undefined }; body: { accountId: number; name: string; template: string; enabled: boolean; teamIds: number[] }
}
>({
  key: 'update-call-note-templates',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/callNoteTemplates/${params.meta.id}`
})

export const useCallScripts = createUseFetch<CallScript[], { meta: { accountId: string } }>({
  key: 'get-call-scripts',
  independent: true,
  path: params => `/api/v2/app/callScripts?accountId=${params.meta.accountId}`
})

export const useCallScriptsDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-scripts',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callScripts/${params.meta.id}`
})

export const useCallScriptsCreate = createUseFetchAction<object, {
  body: { accountId: number; name: string; description: string; data: string; entityIdsAdd: number[]; entityIdsDelete: number[] }
}>({
  key: 'create-call-scripts',
  method: HttpMethods.Post,
  path: `/api/v2/app/callScripts`
})

export const useCallScriptsUpdate = createUseFetchAction<object, {
  meta: { id: number | undefined }; body: { name: string; description: string; data: string; entityIdsAdd: number[]; entityIdsDelete: number[] }
}
>({
  key: 'update-call-scripts',
  method: HttpMethods.Put,
  path: params => `/api/v2/app/callScripts/${params.meta.id}`
})

export const useCallScriptsEntities = createUseFetch<CallScriptEntity[], { meta: { accountId: number, scriptId: number } }>({
  key: 'get-call-scripts-entities',
  independent: true,
  path: params => `/api/v2/app/callScripts/entities?accountId=${params.meta.accountId}${params.meta.scriptId != 0 ? '&scriptId=' + params.meta.scriptId : ''}`
})

export const useCallDispositions = createUseFetch<Disposition[], { meta: { accountId: string } }>({
  key: 'get-call-dispositions',
  independent: true,
  path: params => `/api/v2/app/callDispositions?accountId=${params.meta.accountId}`
})

export const useCallDispositionsDelete = createUseFetchAction<{ meta: { id: number } }, any>({
  key: 'delete-call-dispositions',
  method: HttpMethods.Delete,
  path: params => `/api/v2/app/callDispositions/${params.meta.id}`
})

export const useCallDispositionTeams = createUseFetch<any, { meta: { accountId: number, dispositionId: number } }>({
  key: 'get-call-disposition-teams',
  independent: true,
  path: params => `/api/v2/app/callDisposition/teams?accountId=${params.meta.accountId}${params.meta.dispositionId != 0 ? '&dispositionId=' + params.meta.dispositionId : ''}`
})

type PutParams = {
  body: Record<string, any>
}

export const useCallDispositionsUpdate = createUseFetchAction<any, PutParams>({
  key: 'put-call-disposition',
  path: `/api/v2/app/callDisposition/teams`,
  method: HttpMethods.Put
})

export const useCallDispositionsSync = createUseFetchAction<object, { body: { accountId: number} }>({
  key: 'sync-call-disposition-to-sf',
  method: HttpMethods.Post,
  path: `/api/v2/app/callDispositions/syncToSalesforce`
})

export const useCallDispositionsImport = createUseFetchAction<object, { body: { accountId: number} }>({
  key: 'import-call-disposition-from-sf',
  method: HttpMethods.Post,
  path: `/api/v2/app/callDispositions/import`
})
