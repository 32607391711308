class EntityModel extends Backbone.Model
  defaults:
    'name': ''
    'source': ''
    'number': ''
    'tags': ''

  parse: (response) ->
    response.extraInfo = JSON.stringify(response.extraInfo) if response.extraInfo
    response

class EntityCollection extends Backbone.PageableCollection
  model: EntityModel
  url: 'callScripts/entities'
  state:
    pageSize: 10
  mode: 'client'

module.exports = EntityCollection