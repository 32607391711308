import styled from 'styled-components'
import { RdnaTableRow } from '@ringdna/common/src/components/RdnaTable'

export const StyledRow = styled(RdnaTableRow)`
  height: inherit;
  .MuiFormControl-root {
    margin-top: 0;
  }
`

export const ToggleRowContainer = styled.div<{ $align?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${({ $align }) => $align || 'normal'};
`
