export enum InputTypes {
  ANY = 'any',
  AUTOCOMPLETE = 'autocomplete',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  DROPDOWN = 'select',
  EMAIL = 'email',
  HIDDEN = 'hidden',
  NUMBER = 'number',
  PASSWORD = 'password',
  PHONE = 'tel',
  RADIO = 'radio',
  SEARCH = 'search',
  SLIDER = 'slider',
  TEXT = 'text',
  TEXT_AREA = 'text area',
  TOGGLE_SWITCH = 'toggle switch'
}
