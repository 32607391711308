import React, { useContext } from 'react'
import { InitIntegrationsContext } from '../../model'
import { IntegrationsContext } from '../../store/IntegrationsProvider'
import { Grid, Box } from '@mui/material'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import SectionHeader, { SectionDivider } from '@ringdna/common/src/components/RdnaHeaderSection'
import ZoomAttribute from './ZoomAttribute'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import styled from 'styled-components'

const readOnlySettings = true

const StyledSwitch = styled(RdnaToggleSwitch)`
  margin-bottom: ${({ theme }) => theme.spacing * -3}px;
  pointer-events: none;
  opacity: 0.5;
`
export default function ZoomUserManager() {
  const {
    integrationsState: { zoomMetaModel }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const settings = zoomMetaModel?.zoomSettings

  return (
    <>
      <Grid container alignItems="flex-end">
        <Grid item xs={9}>
          <SectionHeader headingText="Recording Settings"></SectionHeader>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end" pb={2}>
            {!readOnlySettings && <RdnaButton text="Update Recording Settings" name="update-recording-settings" />}
          </Box>
        </Grid>
      </Grid>
      <SectionDivider />
      <Box pt={2} pb={3}>
        <Grid container>
          <Grid item xs={6}>
            <ZoomAttribute name="Cloud Recording">
              <StyledSwitch value={settings.recording.cloud_recording} name="cloud-recording" />
            </ZoomAttribute>
            <ZoomAttribute name="Record Active Speaking View">
              <StyledSwitch value={settings.recording.record_speaker_view} name="record_speaker_view" />
            </ZoomAttribute>
            <ZoomAttribute name="Record gallery view">
              <StyledSwitch value={settings.recording.record_gallery_view} name="record_gallery_view" />
            </ZoomAttribute>
            <ZoomAttribute name="Record an audio only file">
              <StyledSwitch value={settings.recording.record_audio_file} name="record_audio_file" />
            </ZoomAttribute>
          </Grid>
          <Grid item xs={6}>
            <ZoomAttribute name="Save chat">
              <StyledSwitch value={settings.recording.save_chat_text} name="save_chat_text" />
            </ZoomAttribute>
            <ZoomAttribute name="Automatic Recording">
              <StyledSwitch value={settings.recording.auto_recording} name="auto_recording" />
            </ZoomAttribute>
            <ZoomAttribute name="Host Video">
              <StyledSwitch value={settings.scheduleMeeting.host_video} name="host_video" />
            </ZoomAttribute>
            <ZoomAttribute name="Participants Video">
              <StyledSwitch value={settings.scheduleMeeting.participants_video} name="participants_video" />
            </ZoomAttribute>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
