import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

export default function RibbonIcon({ color = Colors.N100, size = 60 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" data-testid="ribbon-icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5419 15.8924C12.2063 15.6997 11.7937 15.6997 11.4581 15.8924V15.8924C10.9862 16.1634 10.3866 16.0442 10.0544 15.6132V15.6132C9.8181 15.3068 9.43684 15.1488 9.05305 15.1985V15.1985C8.51337 15.2683 8.0051 14.9286 7.86306 14.4033V14.4033C7.76204 14.0298 7.47023 13.738 7.09667 13.6369V13.6369C6.57136 13.4949 6.23175 12.9866 6.30154 12.4469V12.4469C6.35117 12.0632 6.19324 11.6819 5.88677 11.4456V11.4456C5.45581 11.1134 5.33655 10.5138 5.60756 10.0419V10.0419C5.80028 9.70634 5.80028 9.29366 5.60756 8.95808V8.95808C5.33655 8.48619 5.45581 7.88665 5.88677 7.55439V7.55439C6.19324 7.3181 6.35117 6.93684 6.30154 6.55305V6.55305C6.23175 6.01337 6.57136 5.5051 7.09667 5.36306V5.36306C7.47023 5.26204 7.76204 4.97023 7.86306 4.59667V4.59667C8.0051 4.07136 8.51337 3.73175 9.05305 3.80154V3.80154C9.43684 3.85117 9.8181 3.69324 10.0544 3.38677V3.38677C10.3866 2.95581 10.9862 2.83655 11.4581 3.10756V3.10756C11.7937 3.30028 12.2063 3.30028 12.5419 3.10756V3.10756C13.0138 2.83655 13.6134 2.95581 13.9456 3.38677V3.38677C14.1819 3.69324 14.5632 3.85117 14.9469 3.80154V3.80154C15.4866 3.73175 15.9949 4.07136 16.1369 4.59667V4.59667C16.238 4.97023 16.5298 5.26204 16.9033 5.36306V5.36306C17.4286 5.5051 17.7683 6.01337 17.6985 6.55305V6.55305C17.6488 6.93684 17.8068 7.3181 18.1132 7.55439V7.55439C18.5442 7.88665 18.6634 8.48619 18.3924 8.95808V8.95808C18.1997 9.29366 18.1997 9.70634 18.3924 10.0419V10.0419C18.6634 10.5138 18.5442 11.1134 18.1132 11.4456V11.4456C17.8068 11.6819 17.6488 12.0632 17.6985 12.4469V12.4469C17.7683 12.9866 17.4286 13.4949 16.9033 13.6369V13.6369C16.5298 13.738 16.238 14.0298 16.1369 14.4033V14.4033C15.9949 14.9286 15.4866 15.2683 14.9469 15.1985V15.1985C14.5632 15.1488 14.1819 15.3068 13.9456 15.6132V15.6132C13.6134 16.0442 13.0138 16.1634 12.5419 15.8924V15.8924Z"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.5C14.2091 13.5 16 11.7091 16 9.5C16 7.29086 14.2091 5.5 12 5.5C9.79086 5.5 8 7.29086 8 9.5C8 11.7091 9.79086 13.5 12 13.5Z"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40192 13.1962L7.26794 13.6962L4.51794 18.4593L6.50044 18.5783C6.65754 18.5877 6.80103 18.6706 6.88774 18.8019L7.98204 20.4593L10.732 15.6962L11.5981 16.1962L8.34077 21.838C8.22927 22.0311 7.95349 22.0394 7.83061 21.8533L6.24999 19.4593L3.38645 19.2874C3.16384 19.2741 3.03311 19.0311 3.14462 18.838L6.40192 13.1962Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4019 16.1962L13.2679 15.6962L16.0179 20.4593L17.1122 18.8019C17.199 18.6706 17.3424 18.5877 17.4995 18.5783L19.482 18.4593L16.732 13.6962L17.5981 13.1962L20.8554 18.838C20.9669 19.0311 20.8361 19.2741 20.6135 19.2874L17.75 19.4593L16.1694 21.8533C16.0465 22.0394 15.7707 22.0311 15.6592 21.838L12.4019 16.1962Z"
        fill={color}
      />
    </svg>
  )
}
