BaseCollection = require('base/collection')

class GranularControlSettingCollection extends BaseCollection
  url: -> 'callRecordingSettings'
  setParams: (options) ->
    {@accountId, @isUS} = options
  toJSON: =>
    fields = {}
    @each((item, index) =>
      if @isUS
        fields["items[#{index}].id"] = item.get('setting.stateId')
      else
        fields["items[#{index}].id"] = item.get('setting.countryId')
      fields["items[#{index}].enabled"] = item.get('setting.enabled')
      fields["items[#{index}].outboundAnnouncement"] = item.get('setting.outboundAnnouncement')
      fields["items[#{index}].voicemailTranscriptionEnabled"] = item.get('setting.voicemailTranscriptionEnabled')
    )
    fields['accountId'] = @accountId
    fields['isUS'] = @isUS
    fields
  persist: (options) =>
    defaults =
      data        : @toJSON()
      emulateJSON : true
      url         : @url()

    options = _.extend(defaults, options)
    Backbone.sync('update', @, options)

module.exports = GranularControlSettingCollection