BaseModel                = require('base/model')
BaseView                 = require('base/view')
UploaderView             = require('./uploader/view')
supportTemplate = require('./template-support.hbs')

class ConversationAiView extends BaseView
  className: 'conversation-ai'

  regions:
    uploader : '.uploader-region'

  getTemplate: ->
    supportTemplate

  initialize: (options) ->
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    {@userId} = options

  onRender: =>
    @showChildView('uploader', new UploaderView(userId: @userId))

  childViewEvents:
    reload: -> @render()

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

module.exports = ConversationAiView
