import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const CallToolsIcon = ({ color = Colors.N100, size = 18}: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74359 15.8355C8.89671 15.7844 9 15.6411 9 15.4797V13.4178C9 13.2457 8.88289 13.0957 8.71595 13.054L7.64065 12.7852C7.55015 12.7625 7.47163 12.7072 7.4274 12.6251C7.23918 12.2755 6.75 11.1921 6.75 9.375C6.75 7.55786 7.23918 6.4745 7.4274 6.12491C7.47163 6.04278 7.55015 5.98746 7.64065 5.96484L8.71595 5.69601C8.88289 5.65428 9 5.50428 9 5.33221V3.27028C9 3.10887 8.89671 2.96557 8.74359 2.91453L6.95143 2.31714C6.82646 2.27549 6.69042 2.30161 6.59803 2.3955C6.03844 2.96414 4.125 5.21285 4.125 9.375C4.125 13.5371 6.03844 15.7859 6.59803 16.3545C6.69042 16.4484 6.82646 16.4745 6.95143 16.4329L8.74359 15.8355Z"
      stroke={color}
      strokeWidth="0.75"
    />
    <path
      d="M13.2422 9.51172L11.7422 9.51172"
      stroke={color}
      strokeWidth="0.75"
      strokeLinecap="round"
    />
    <path
      d="M11.9883 12.6998L10.6892 11.9498"
      stroke={color}
      strokeWidth="0.75"
      strokeLinecap="round"
    />
    <path
      d="M11.9883 6.32476L10.6892 7.07476"
      stroke={color}
      strokeWidth="0.75"
      strokeLinecap="round"
    />
  </svg>
)

export default CallToolsIcon