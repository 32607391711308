import { HttpMethods } from '@ringdna/common/src/client'

import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

const API_BASE = '/api/v2/app/salesforceInspector'

export enum Action {
  Cache = 'cache',
  Package = 'package',
  Connect = 'connect'
}

type putActionParams = {
  body: {
    action: string
    accountId: number
  }
}

export type PackageCheck = {
  accountId: number
  advancedSolutionPackageVersion: string
  cadencePackageVersion: string
  hotLeadsPackageVersion: string
  message: string
  packageVersion: string
}

export const useUpdateSalesforceInspector = createUseFetchAction<void, putActionParams>({
  key: 'put-salesforce-inspector',
  path: `${API_BASE}`,
  method: HttpMethods.Put
})

type UseAccountsParams = {
  meta: {
    id: number
  }
}

export type SalesforceInspector = {
  listSfRunAsUsers: User[]
  connectUser: User
}

export type User = {
  id: number
  displayName: string
  sfUserId: string
}

export const useSalesforceInspector = createUseFetch<SalesforceInspector, UseAccountsParams>({
  key: 'use-salesforce-inspector',
  path: ({ meta }) => `${API_BASE}?accountId=${meta.id}`,
  independent: true
})

type postActionParams = {
  body: {
    userId: number
    accountId: number
  }
}

export const useUpdateSalesforceRunUsUser = createUseFetchAction<void, postActionParams>({
  key: 'post-salesforce-run-us-user',
  path: `${API_BASE}/sfRunAsUser`,
  method: HttpMethods.Post
})

type postInvalidParams = {
  body: {
    userId: string
    accountId: number
    sendInvalidNotification: boolean
  }
}
export const useUpdateInvalidNotifyUser = createUseFetchAction<void, postInvalidParams>({
  key: 'post-invalid-notify-user',
  path: `${API_BASE}/invalidNotifyUser`,
  method: HttpMethods.Post
})
