import React, { createContext, useContext } from 'react'
import PubNub from 'pubnub'
import config from '../../config'
import { userSettingsStaticAtom } from '../../store/api/user-settings'
import { useAtomValue } from 'jotai'

export enum PubNubChannels {
  INCREASE_INBOX_ITEM = 'IncreaseInboxItem',
  SAVED_SEARCH_TOTAL_CALLS = 'SavedSearchTotalCalls'
}

export type MessageEvent = {
  channel: string
  message: any
  userMetadata: string
}

type ContextType = {
  pubnub: any
}

const PubNubContext = createContext<ContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const usePubNub = () => {
  const context = useContext(PubNubContext)
  if (context === undefined) {
    throw new Error('usePubNub must be used within a PubNubProvider')
  }
  return context
}

export const PubNubProvider = ({ children }: Props) => {
  const { userId, channel } = useAtomValue(userSettingsStaticAtom)
  if (userId) {
    const pubnub = new PubNub({
      subscribeKey: config.pubnubKey,
      logVerbosity: true,
      uuid: `${userId}`
    })
    pubnub.subscribe({ channels: [channel] })

    return <PubNubContext.Provider value={{ pubnub }}>{children}</PubNubContext.Provider>
  } else {
    return <>{children}</>
  }
}
