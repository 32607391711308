import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ratio = 16 / 15
const SavedSearchIcon = ({ color = Colors.SKY, size = 15 }: BaseIconProps) => (
  <svg width={size} height={size * ratio} viewBox="0 0 15 16" fill="none">
    <circle cx={6} cy={6} r={5} stroke={color} />
    <path d="M6 4v4M8 6H4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 11.5L14 15" stroke={color} strokeLinecap="round" />
  </svg>
)

export default SavedSearchIcon
