import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const InfoIcon = ({ color = Colors.N50, size = 14 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" data-testid={'info-icon'}>
    <path clipRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14z" stroke={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.975 5.75a.625.625 0 100-1.25.625.625 0 000 1.25z" fill={color} />
    <path d="M8 7v5" stroke={color} />
  </svg>
)

export default InfoIcon
