import React, { ReactNode } from 'react'
import { useAtomValue } from 'jotai'

import { userSettingsStaticAtom } from '../../store/api/user-settings'
import { ReportFilterType, CustomReportType } from '@ringdna/common/src/components/RdnaReports/types'
import { useAnalytics } from '../contexts/AnalyticsContext'

import ReportFilters from '@ringdna/common/src/components/RdnaReports/ReportFilters'
import RdnaReports from '@ringdna/common/src/components/RdnaReports'
import MetabaseView from '@ringdna/common/src/components/RdnaReports/MetabaseView'

const MAX_FILTERS_SHOW_DEFAULT = 3

type Props = {
  id: string
  title: string
  description?: ReactNode
  filters: ReportFilterType[]
  CustomReport: CustomReportType
  isDashboard?: boolean
  maxFiltersShow?: number
}

export const getFilterDefaultValues = (reportName: string) => {
  switch (reportName) {
    case 'moments-outcomes':
      return { [ReportFilterType.DISPOSITION_OUTCOME]: 'Positive', [ReportFilterType.CALL_DURATION]: '3,120' }
    default:
      return {}
  }
}

export const getPageHeadline = (title: string): string => {
  switch (title) {
    case 'Compliance':
      return 'Compliance with "Exclude" Notifications'
    default:
      return title
  }
}

export default function Report({
  id,
  title,
  description,
  filters,
  CustomReport,
  isDashboard = true,
  maxFiltersShow = MAX_FILTERS_SHOW_DEFAULT
}: Props) {
  const { analytics } = useAnalytics()
  const {
    accountId,
    isAuthorizedAdmin,
    caiRecordingVisibility,
    currentFiscalQuarter,
    licenses,
    userName,
    opportunityAmount,
    opportunityWinProbability
  } = useAtomValue(userSettingsStaticAtom)
  return (
    <RdnaReports
      headline={getPageHeadline(title)}
      subHeadline={description}
      userData={{
        accountId,
        licenses,
        userName,
        caiRecordingVisibility,
        currentFiscalQuarter,
        opportunityAmount,
        opportunityWinProbability,
        isAuthorizedAdmin
      }}
    >
      <>
        <ReportFilters
          filters={filters}
          key={id}
          reportName={id}
          maxFiltersShow={maxFiltersShow}
          defaultFilterValues={getFilterDefaultValues(id)}
        />
        <MetabaseView reportName={id} isDashboard={isDashboard} CustomReport={CustomReport} analytics={analytics} />
      </>
    </RdnaReports>
  )
}
