class CallTrackingHelper

  @filterCallTracking: (collection, callTrackings) =>
    needAddresses = []
    for callTracking in callTrackings
      switch callTracking.numberDescription
        when 'The address is not provided.'
          callTracking.phoneNumber = callTracking.phoneNumberToProvision
          needAddresses.push(callTracking)
        when 'The number was created.'
          collection.trigger('newCallTracking', callTracking) if callTracking.entityCreated and callTracking.numberCreated
        when 'The number was updated.'
          collection.trigger('newCallTracking', callTracking) if callTracking.entityCreated and callTracking.numberCreated
        else
          toastr.info(callTracking.entityDescription, callTracking.numberDescription)
    needAddresses

  @filterRepeatCollection: (addresses, collection) =>
    notAddressesNumbers = new Backbone.Collection()
    for address in addresses
      numbers = collection.where(
        addressRequirements: address.get('addressRequirements')
        countryCode: address.get('countryCode'))
      if numbers.length isnt 0
        collection.remove(numbers)
        notAddressesNumbers.push(numbers)
    notAddressesNumbers

  @findErrors: (collection) =>
    countryError = false
    areaCodeError = false
    callFlowError = false
    typeNumberError = false
    collection.each((model) =>
      if not model.get('countryCode')
        model.trigger('checkCountry')
        countryError = true
      if not model.get('areaCode') and model.get('typeNumber') isnt 'Free' and model.get('countryCode') is 'US' and model.get('typeNumber') isnt 'Existing'
        model.trigger('checkAreaCode')
        areaCodeError = true
      if not model.get('callFlow').id
        model.trigger('checkCallFlow')
        callFlowError = true
      if not model.get('typeNumber')
        model.trigger('checkTypeNumber')
        typeNumberError = true
    )
    countryError or areaCodeError or callFlowError or typeNumberError

  @errorAddresses: (addresses) =>
    error = false
    addresses.each((address) =>
      error = true if not address.get('businessName') or not address.get('addressCountry') or not address.get('city') or
        not address.get('postalCode') or not address.get('region') or not address.get('street')
      address.trigger('checkErrors') if error)
    toastr.warning('Please correct the highlighted errors.') if error
    error

module.exports = CallTrackingHelper
