import React, { useContext, useState } from 'react'
import styled from 'styled-components'

// constants
import { integrationItems, getInputFields, IntegrationsActions } from '../../constants'

// types
import { InitIntegrationsContext } from '../../model'
import { InputField } from '@ringdna/common/src/types/form-inputs'

// utils
import useToast from '../../../../common/hooks/useToast'
import { usePostIntegrationConnection } from '../../../../../store/api/user-integrations-api'
import { IntegrationsContext } from '../../store/IntegrationsProvider'

// styled
import { Grid, Box } from '@mui/material'
import RdnaForm from '@ringdna/common/src/components/RdnaForm'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

const StyledGrid = styled(Grid)`
  .MuiGrid-item {
    text-align: center;
    overflow: hidden;
  }
`

export default function ConnectIntegrationModal() {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { selectedIntegration, activeIntegrations, activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [loading, setLoading] = useState(false)
  const postIntegrationConnection = usePostIntegrationConnection()

  if (!selectedIntegration) return null

  const integration = integrationItems[selectedIntegration.provider]
  const Icon = integration.icon
  let inputFields: InputField[] = getInputFields(selectedIntegration.provider)
  const connectedIntegration = activeIntegrations.filter(
    integration => integration.provider === selectedIntegration.provider
  )
  const connectedEmail = connectedIntegration.length ? connectedIntegration[0].email : null
  inputFields = inputFields.map(field => {
    if (field.name === 'email') field.defaultValue = connectedEmail || ''
    return field
  })

  const formSubmit = async (values: any) => {
    setLoading(true)
    const data: any = {
      userId: activeUser.userId,
      provider: selectedIntegration.provider
    }

    if (connectedIntegration.length && isFlagged(flags.NYLAS_RECONNECT, false)) {
      data.reconnect = true
      data.nylasId = connectedIntegration[0].nylasId
    }

    Object.keys(values).forEach(key => {
      data[`model[${key}]`] = values[key]
    })
    try {
      const res: any = await postIntegrationConnection({ body: data })
      setLoading(false)
      if (res) {
        dispatch({ type: IntegrationsActions.SET_CONNECTED_INTEGRATION, payload: selectedIntegration.provider })
      }
    } catch (error) {
      toastr.error(error.message)
      setLoading(false)
    }
  }

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <Icon />
        <Box pt={2}>
          <RdnaText variant="h2">{`Connect ${integration.label} Account`}</RdnaText>
        </Box>
        <Box py={2}>
          <RdnaText>{`Enter the following credentials to connect your ${integration.label} account to ringDNA to leverage your email and calendars.`}</RdnaText>
        </Box>
        <RdnaForm
          submitText="Connect Your Account"
          onSubmit={formSubmit}
          inputFields={inputFields}
          alignElements="center"
          isLoading={loading}
        />
      </Grid>
    </StyledGrid>
  )
}
