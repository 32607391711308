import { HttpMethods } from '@ringdna/common/src/client'
import { createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

const API_BASE = '/api/v2/app/'

export const useUploadFile = createUseFetchAction<any, any>({
  key: 'post-upload-file',
  path: params => `${API_BASE}accounts/${params.accountId}/audio`,
  method: HttpMethods.Post
})
