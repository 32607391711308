BaseModel = require('base/model')

class LocationModel extends BaseModel
  urlRoot: 'locations'

  defaults:
    name: ''
    description: ''
    address: ''

  validate: (attrs) ->
    if not attrs.name.trim()
      return 'Please enter a location.'
    return

module.exports = LocationModel