import { InputField, InputGrid, FormSelectOption } from '@ringdna/common/src/types/form-inputs'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import { IntegrationsState, InitIntegrationsContext, IntegrationItems, IntegrationFieldTypes } from './model'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import {
  SalesforceIcon,
  AolIcon,
  MicrosoftIcons,
  GmailIcon,
  HooversIcon,
  HotmailIcon,
  ImapIcon,
  IcloudIcon,
  YahooIcon,
  ZoomIcon
} from '@ringdna/common/src/assets/logos/integrations'
import { user as activeUser } from '../../common/services/user'

export enum IntegrationsActions {
  SET_ACTIVE_USER = 'SET_ACTIVE_USER',
  SET_INTEGRATIONS = 'SET_INTEGRATIONS',
  SET_GMAIL_AUTH_URL = 'SET_GMAIL_AUTH_URL',
  SET_SELECTED_INTEGRATION = 'SET_SELECTED_INTEGRATION',
  SET_CONNECTED_INTEGRATION = 'SET_CONNECTED_INTEGRATION',
  SET_PRIMARY_INTEGRATION = 'SET_PRIMARY_INTEGRATION',
  DELETE_INTEGRATION = 'DELETE_INTEGRATION',
  DISPLAY_ZOOM_USER_MANAGER = 'DISPLAY_ZOOM_USER_MANAGER'
}

export const initIntegrationsState: IntegrationsState = {
  activeUser: {
    userId: activeUser.userId,
    accountId: activeUser.accountId,
    userName: activeUser.userName,
    userEmail: activeUser.userEmail,
    licenses: activeUser.licenses
  },
  allIntegrations: [],
  activeIntegrations: [],
  zoomMetaModel: null,
  primaryIntegration: null,
  gmailAuthUrl: '',
  displayZoomManager: false
}

export const initIntegrationsContext: InitIntegrationsContext = {
  integrationsState: initIntegrationsState,
  dispatch: () => console.log('called before init')
}

export const integrationItems: IntegrationItems = {
  [IntegrationProviderTypes.SALESFORCE]: {
    label: 'Salesforce',
    icon: SalesforceIcon,
    description: ''
  },
  [IntegrationProviderTypes.AOL]: {
    label: 'AOL',
    icon: AolIcon,
    description: 'Connect to AOL to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.EXCHANGE]: {
    label: 'Microsoft',
    icon: MicrosoftIcons,
    description: 'Connect to Office365 or Exchange to access your email and calendar within Revenue.'
  },
  [IntegrationProviderTypes.GMAIL]: {
    label: 'Gmail',
    icon: GmailIcon,
    description: 'Connect to Gmail to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.HOOVERS]: {
    label: 'Hoovers',
    icon: HooversIcon,
    description: 'Connect to Hoovers to access contact and account information within Revenue.'
  },
  [IntegrationProviderTypes.HOTMAIL]: {
    label: 'Hotmail',
    icon: HotmailIcon,
    description: 'Connect to Hotmail to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.IMAP]: {
    label: 'IMAP',
    icon: ImapIcon,
    description: 'Connect to IMAP to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.ICLOUD]: {
    label: 'iCloud',
    icon: IcloudIcon,
    description: 'Connect to iCloud to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.YAHOO]: {
    label: 'Yahoo!',
    icon: YahooIcon,
    description: 'Connect to Yahoo! to access your email, contacts and calendar within Revenue.'
  },
  [IntegrationProviderTypes.ZOOM]: {
    label: 'Zoom',
    icon: ZoomIcon,
    description: 'Connect with anyone, anywhere. On any device.'
  }
}

export enum IntegrationInputTypes {
  EMAIL = 'email',
  PASSWORD = 'password',
  EXCHANGE_NAME = 'nameExchange',
  EXCHANGE_SERVER = 'easServerHost',
  IMAP_USERNAME = 'username',
  IMAP_SERVER = 'imapServer',
  IMAP_PORT = 'imapPort',
  SMTP_SERVER = 'smtpServer',
  SMTP_PORT = 'smtpPort'
}

const GRID_SIZE: InputGrid = { xs: 6 }

const integrationFieldTypes: IntegrationFieldTypes = {
  [IntegrationInputTypes.EXCHANGE_NAME]: {
    label: 'Name',
    name: IntegrationInputTypes.EXCHANGE_NAME,
    required: true,
    type: InputTypes.TEXT
  },
  [IntegrationInputTypes.EMAIL]: {
    label: 'Email',
    name: IntegrationInputTypes.EMAIL,
    required: true,
    type: InputTypes.EMAIL
  },
  [IntegrationInputTypes.PASSWORD]: {
    label: 'Password',
    name: IntegrationInputTypes.PASSWORD,
    required: true,
    type: InputTypes.PASSWORD
  },
  [IntegrationInputTypes.EXCHANGE_SERVER]: {
    label: 'Server',
    name: IntegrationInputTypes.EXCHANGE_SERVER,
    required: true,
    type: InputTypes.TEXT
  },
  [IntegrationInputTypes.IMAP_USERNAME]: {
    label: 'Username',
    name: IntegrationInputTypes.IMAP_USERNAME,
    required: true,
    type: InputTypes.TEXT
  },
  [IntegrationInputTypes.IMAP_SERVER]: {
    label: 'iMap Server',
    name: IntegrationInputTypes.IMAP_SERVER,
    required: true,
    type: InputTypes.TEXT,
    gridLayout: GRID_SIZE
  },
  [IntegrationInputTypes.IMAP_PORT]: {
    label: 'Port',
    name: IntegrationInputTypes.IMAP_PORT,
    required: true,
    type: InputTypes.TEXT,
    gridLayout: GRID_SIZE
  },
  [IntegrationInputTypes.SMTP_SERVER]: {
    label: 'SMTP Server',
    name: IntegrationInputTypes.SMTP_SERVER,
    required: true,
    type: InputTypes.TEXT,
    gridLayout: GRID_SIZE
  },
  [IntegrationInputTypes.SMTP_PORT]: {
    label: 'Port',
    name: IntegrationInputTypes.SMTP_PORT,
    required: true,
    type: InputTypes.TEXT,
    gridLayout: GRID_SIZE
  }
}

export const integrationInputs: InputField[] = [
  integrationFieldTypes[IntegrationInputTypes.EMAIL],
  integrationFieldTypes[IntegrationInputTypes.PASSWORD]
]

export const integrationExchangeInputs: InputField[] = [
  integrationFieldTypes[IntegrationInputTypes.EXCHANGE_NAME],
  integrationFieldTypes[IntegrationInputTypes.EMAIL],
  integrationFieldTypes[IntegrationInputTypes.PASSWORD],
  integrationFieldTypes[IntegrationInputTypes.EXCHANGE_SERVER]
]

export const integrationImapInputs: InputField[] = [
  integrationFieldTypes[IntegrationInputTypes.EMAIL],
  integrationFieldTypes[IntegrationInputTypes.PASSWORD],
  integrationFieldTypes[IntegrationInputTypes.IMAP_USERNAME],
  integrationFieldTypes[IntegrationInputTypes.IMAP_SERVER],
  integrationFieldTypes[IntegrationInputTypes.IMAP_PORT],
  integrationFieldTypes[IntegrationInputTypes.SMTP_SERVER],
  integrationFieldTypes[IntegrationInputTypes.SMTP_PORT]
]

export const getInputFields = (provider: IntegrationProviderTypes) => {
  switch (provider) {
    case IntegrationProviderTypes.EXCHANGE:
      return integrationExchangeInputs
    case IntegrationProviderTypes.IMAP:
      return integrationImapInputs
    default:
      return integrationInputs
  }
}

export const integrationDisconnectedFlags = ['INVALID_CREDENTIALS', 'STOPPED']

export enum ZoomUserTypes {
  Basic = 1,
  Pro,
  Corp
}

export enum ConnectZoomUserInputTypes {
  EMAIL = 'email',
  USER_TYPE = 'userType',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName'
}

export const connectZoomUserInputs: InputField[] = [
  {
    label: 'Email',
    name: ConnectZoomUserInputTypes.EMAIL,
    required: true,
    type: InputTypes.EMAIL
  },
  {
    label: 'User Type',
    name: ConnectZoomUserInputTypes.USER_TYPE,
    required: true,
    type: InputTypes.DROPDOWN,
    options: [{ value: '2', label: ZoomUserTypes[2] }]
  },
  {
    label: 'First Name',
    name: ConnectZoomUserInputTypes.FIRST_NAME,
    required: true,
    type: InputTypes.TEXT
  },
  {
    label: 'Last Name',
    name: ConnectZoomUserInputTypes.LAST_NAME,
    required: true,
    type: InputTypes.TEXT
  }
]

export const setPrimaryAccountField = (defaultValue: string | null, options: FormSelectOption[]) => ({
  disabled: options.length < 2,
  label: 'Default Email Account',
  type: InputTypes.DROPDOWN,
  multipleValues: false,
  name: 'primaryIntegration',
  options,
  defaultValue,
  required: false,
  gridLayout: {
    sm: 4
  }
})
