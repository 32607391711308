class SettingsPagedCollection extends Backbone.PageableCollection

  url: 'callDisposition/teams'
  mode: 'client'

  initialize: (options) ->
    @initAdditionalParameters()
    _.extend(@queryParams, options?.queryParams)
    @listenTo(@, 'pageable:state:change', => @trigger('pager:state:changed', @state))

  parseRecords: (response, options) =>
    @trigger('pager:state:changed', @state)
    response.results

  initAdditionalParameters: =>
    @state = {
      pageSize: 10
      sortKey: 'name'
      order: -1
    }
    @queryParams = {}

module.exports = SettingsPagedCollection
