import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function EarIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="ear-icon">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M6.132 10.273a5.972 5.972 0 0 1-.1-1.869c.34-3.303 3.284-5.709 6.578-5.373 3.294.336 5.694 3.288 5.359 6.596a6.02 6.02 0 0 1-.44 1.729l-3.524 7.874c-.2.39-.48.746-.84 1.037a3.24 3.24 0 0 1-4.569-.466 3.268 3.268 0 0 1-.16-3.904"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M14.673 10.716c.77-1.637.135-3.644-1.417-4.424-1.399-.702-3.117-.097-3.873 1.364-.688 1.33-.414 2.943.544 3.932.392.405.473 1.053.225 1.579L9.761 14"
      />
    </svg>
  )
}

export default EarIcon
