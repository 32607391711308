import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { integrationDisconnectedFlags, setPrimaryAccountField, IntegrationsActions } from '../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { usePostPrimaryIntegration } from '../../../../store/api/user-integrations-api'
import { UserContext, UserContextType } from '../../../common/services/UserContext'
import { IntegrationsContext } from '../store/IntegrationsProvider'
import { FormSelectOption } from '@ringdna/common/src/types/form-inputs'
import RdnaForm from '@ringdna/common/src/components/RdnaForm'
import useToast from '../../../common/hooks/useToast'

export default function SetPrimaryAccount() {
  const toastr = useToast()
  const { userId } = useContext<UserContextType>(UserContext)
  const { dispatch, integrationsState } = useContext(IntegrationsContext)
  const [accountFields, setAccountFields] = useState<any>(null)
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationProviderTypes | undefined>()
  const { primaryIntegration, activeIntegrations } = integrationsState
  const postPrimaryIntegration = usePostPrimaryIntegration()

  useEffect(() => {
    const defaultValue = primaryIntegration
    const options: FormSelectOption[] = activeIntegrations
      .filter(
        integration =>
          integration.provider !== IntegrationProviderTypes.SALESFORCE &&
          integration.provider !== IntegrationProviderTypes.ZOOM &&
          integration.status &&
          !integrationDisconnectedFlags.includes(integration.status)
      )
      .map(integration => ({ label: integration.email, value: integration.provider }))
    if (options.length) setAccountFields([setPrimaryAccountField(defaultValue, options)])
  }, [activeIntegrations, primaryIntegration])

  const savePrimaryIntegration = async () => {
    if (selectedIntegration !== primaryIntegration) {
      try {
        await postPrimaryIntegration({
          body: { userId, provider: selectedIntegration as IntegrationProviderTypes }
        })
        dispatch({ type: IntegrationsActions.SET_PRIMARY_INTEGRATION, payload: selectedIntegration })
      } catch (error) {
        toastr.error(error.message)
      }
    }
  }

  const setPrimaryIntegration = async (event: { primaryIntegration: string }) => {
    setSelectedIntegration(event.primaryIntegration as IntegrationProviderTypes)
  }

  return (
    <>
      {accountFields && (
        <StyledForm>
          <RdnaForm
            inputFields={accountFields}
            submitText={'Save'}
            disableSubmit={!selectedIntegration || selectedIntegration === primaryIntegration}
            inlineButtons={true}
            onChange={setPrimaryIntegration}
            onSubmit={savePrimaryIntegration}
          />
        </StyledForm>
      )}
    </>
  )
}

const StyledForm = styled.div`
  margin-top: ${props => props.theme.spacing * 6}px;
`
