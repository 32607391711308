import { GenericObject } from '../../types/generic'
import { InputTypes } from '../constants/input-types'

const abilities: { [key: string]: string[] } = {
  'activity-feed:view': [],
  'accounts:edit:*': ['support'],
  'accounts:edit:me': ['admin'],
  'accounts:search': ['support', 'admin'],
  'analytics:view': [InputTypes.ANY],
  'cai-settings:view': [],
  'calls:search': ['support'],
  'calls:view': ['support'],
  'call-flows:edit:*': ['support'],
  'call-flows:edit:me': [],
  'call-flows:search:*': ['support'],
  'call-flows:search:me': ['admin'],
  'call-flows:callouts:edit': ['support'],
  'call-recordings:view': ['admin', 'intelligentDialer', 'support'],
  'call-recording-regions:edit': ['admin'],
  'call-tracking:view': [],
  'cdm:search': [],
  'cdm:view': [],
  'calendar:view': [InputTypes.ANY],
  'call-queues:edit:*': ['support'],
  'call-queues:edit:me': ['admin'],
  'call-queues:search:*': ['support'],
  'call-queues:search:me': ['admin'],
  'dashboard:view': [InputTypes.ANY],
  'dialer:edit:me': [InputTypes.ANY],
  'integrations:view:email': ['admin', 'support'],
  'integrations:view:video': ['admin', 'support'],
  'integrations:view:salesforce': ['admin', 'support'],
  'guidedSelling:view': [InputTypes.ANY],
  'libraries:edit': ['conversationAI'],
  'messages:search': ['support'],
  'messages:view': ['support'],
  'moments:view': [InputTypes.ANY],
  'reports:view:billing': ['support'],
  'reports:view:call-durations': ['admin', 'support'],
  'reports:view:call-efficiency': ['admin', 'support', 'supervisor'],
  'reports:view:emails-sent': ['admin', 'support', 'supervisor'],
  'reports:view:messages:*': ['support'],
  'reports:view:messages:me': ['admin', 'supervisor'],
  'reports:view:missed-ring-rates': ['admin', 'support', 'supervisor'],
  'reports:view:hold-mute-pause-report:*': ['support'],
  'reports:view:hold-mute-pause-report:me': ['admin', 'supervisor'],
  'reports:view:call-reports:*': ['support'],
  'reports:view:call-reports:me': ['admin'],
  'reports:view:callTransfer:*': ['support'],
  'reports:view:callTransfer:me': ['admin'],
  'reports:view:qbr:*': ['support'],
  'reports:view:qbr:me': ['admin'],
  'reports:view:avgCallDurDay:*': ['support'],
  'reports:view:avgCallDurDay:me': ['admin'],
  'reports:view:callDurDay:*': ['support'],
  'reports:view:callDurDay:me': ['admin'],
  'reports:view:callerTypesDay:*': ['support'],
  'reports:view:callerTypesDay:me': ['admin'],
  'reports:view:callLog:*': ['support'],
  'reports:view:callLog:me': ['admin', 'supervisor'],
  'reports:view:callVolume:*': ['support'],
  'reports:view:callVolume:me': ['admin', 'supervisor'],
  'reports:view:spamCalls:*': ['support'],
  'reports:view:supervisor:*': ['support'],
  'reports:view:supervisor:me': ['admin', 'supervisor'],
  'reports:view:conferenceCalls:*': ['support'],
  'reports:view:conferenceCalls:me': ['admin'],
  'reports:view:queueMetrics:*': ['support'],
  'reports:view:queueMetrics:me': ['admin', 'supervisor'],
  'reports:view:call-quality:*': ['support'],
  'reports:view:call-quality:me': ['admin', 'supervisor'],
  'sales-methodology:search:*': ['support'],
  'sales-methodology:search:me': ['admin'],
  'salesforce-profiles:search:*': ['support'],
  'salesforce-profiles:search:me': ['admin'],
  'salesforce-profiles:view:*': ['support'],
  'salesforce-profiles:view:me': ['admin'],
  'sfdc-users:location:edit': [],
  'smart-numbers:search:*': ['support'],
  'smart-numbers:search:me': ['admin'],
  'smart-numbers:setup': ['admin', 'support'],
  'smart-numbers:view:*': ['support'],
  'smart-numbers:view:me': ['admin'],
  'smart-recordings:view': [],
  'system:manage': ['support'],
  'teams:edit:*': ['support'],
  'teams:edit:me': ['admin'],
  'teams:search:*': ['support'],
  'teams:search:me': ['admin'],
  'users:edit:*': ['support'],
  'users:edit:me': ['admin'],
  'users:search:*': ['support'],
  'users:search:me': ['admin'],
  'users:support': ['support'],
  'reports:view:busyPresenceSummary:*': ['support'],
  'reports:view:busyPresenceSummary:me': ['admin', 'supervisor'],
  'reports:view:busyPresenceSummaryDay:*': ['support'],
  'reports:view:busyPresenceSummaryDay:me': ['admin', 'supervisor'],
  'reports:view:presenceDailyTotals:*': ['support'],
  'reports:view:presenceDailyTotals:me': ['admin'],
  'reports:view:busyPresenceDetail:*': ['support'],
  'reports:view:busyPresenceDetail:me': ['admin'],
  'reports:view:call-distribution:*': ['support'],
  'reports:view:call-distribution:me': ['admin'],
  'product-portal:view': ['admin', 'support', 'intelligentDialer', 'conversationAI', 'supervisor']
}

export const getAbilitiesForRole = (
  licenses?: {
    admin: boolean
    calendar: boolean
    intelligentDialer: boolean
    conversationAI: boolean
    guidedSelling: boolean
    moments: boolean
    salesSupport: boolean
    supervisor: boolean
    support: boolean
  },
  overrides?: GenericObject
) => {
  if (!licenses) return {}
  const results: { [key: string]: boolean } = {}
  for (const [ability, requiredRoles] of Object.entries(abilities)) {
    if (requiredRoles.indexOf(InputTypes.ANY) > -1) results[ability] = true
    // @ts-ignore
    else results[ability] = requiredRoles?.some(role => licenses[role])
  }

  if (results['accounts:edit:*'] && results['accounts:edit:me']) results['accounts:edit:me'] = false

  return { ...results, ...overrides }
}
