import React, { Suspense } from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { userSettingsStaticAtom } from '../store/api/user-settings'
import { useAtomValue } from 'jotai'
import { UserLicense, AppLicense } from '@ringdna/common/src/types/user-settings'
import { useFeatureFlag } from './contexts/FeatureFlagContext'
import { flags } from '@ringdna/common/src/utils/feature-flag/featureFlag'

import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import InsightsRouter from './insights'
import { NavigationItem } from '@ringdna/common/src/components/RdnaNav/RdnaLeftNav'
import { RoutePaths } from './LeftNav'
import { getQueryParam } from '@ringdna/common/src/utils/windowUtils'
import { SESSION_ID_KEY } from '@ringdna/common/src/utils/cookieUtils'

const UserProfile = React.lazy(() => import('./UserProfile'))
const Conversations = React.lazy(() => import('./Calls'))
const Inbox = React.lazy(() => import('./inbox/InboxList'))
const Library = React.lazy(() => import('./Libraries'))
const LibraryDetail = React.lazy(() => import('./Libraries/LibrariesDetail'))
const Live = React.lazy(() => import('./Live'))
const SavedSearch = React.lazy(() => import('./SavedSearch'))
const RecordingPlayer = React.lazy(() => import('./smart-recordings/v2/RecordingPlayer'))
const Settings = React.lazy(() => import('./settings'))
const Insights = React.lazy(() => import('./insights'))
const RouteNotFound = React.lazy(() => import('./RouteNotFound'))
const OpportunitiesList = React.lazy(() => import('./Revenue/OpportunitiesList'))
const OpportunityDetail = React.lazy(() => import('./Revenue/OpportunitiesDetail'))
const DynamicLeaderboard = React.lazy(() => import('./Revenue/DynamicLeaderboard'))
const MomentsBehavior = React.lazy(() => import('./Moments/Behavior'))
const MomentsNotifications = React.lazy(() => import('./Moments/Notifications'))
const MomentsChecklists = React.lazy(() => import('./Moments/Checklists'))
const MomentsContent = React.lazy(() => import('./Moments/Content'))
const MomentsTemplates = React.lazy(() => import('./Moments/Templates'))
const MomentsNoLicense = React.lazy(() => import('./Moments/NoLicense'))
const Participant = React.lazy(() => import('./Participant'))
const CaiNoLicense = React.lazy(() => import('./NoLicense'))
const Recipes = React.lazy(() => import('./Recipes'))
const Scorecards = React.lazy(() => import('./Scorecards'))
const Highcharts = React.lazy(() => import('./Highcharts'))
const GetRevenueIntelligence = React.lazy(() => import('./Revenue/GetRevenueIntelligence'))

export const INITIAL_ROUTE: NavigationItem = {
  title: 'Audio Conversations',
  id: '/conversation-etiquette-phone',
  appLicenses: ['moments', 'conversationAI']
}

export enum RouteName {
  HOME = 'home',
  USERPROFILE = 'userProfile',
  CONVERSATIONS = 'conversations',
  GETREVENUEINTELLIGENCE = 'getRevenueIntelligence',
  INBOX = 'inbox',
  ENGAGEMENT = 'engagement',
  INSIGHTS = 'insights',
  SEARCH = 'search',
  LIBRARY = 'library',
  LIBRARYDETAIL = 'libraryDetail',
  LIVE = 'live',
  RECIPES = 'recipes',
  SAVEDSEARCHDEFAULT = 'savedsearchdefault',
  SAVEDSEARCH = 'savedsearch',
  SAVEDSEARCHDETAIL = 'savedsearchDetail',
  SCORECARDS = 'scorecards',
  SETTINGS = 'settings',
  CALLKEY = 'callKey',
  ROUTENOTFOUND = 'routeNotFound',
  OPPORTUNITIES = 'opportunities',
  OPPORTUNITYDETAIL = 'opportunityDetail',
  DYNAMICLEADERBOARD = 'dynamicLeaderboard',
  CAI_NO_LICENSE = 'caiNoLicense',
  MOMENTS_NO_LICENSE = 'momentsNoLicense',
  MOMENTS_BEHAVIOR = 'momentsBehavior',
  MOMENTS_NOTIFICATIONS = 'momentsNotifications',
  MOMENTS_CHECKLISTS = 'momentsChecklists',
  MOMENTS_CONTENT = 'momentsContent',
  MOMENTS_TEMPLATES = 'momentsTemplates',
  PARTICIPANT = 'participant'
}

export type RouteProps = {
  [key in RouteName]: {
    path: string
    component: any
    exact?: boolean
    // gate route by app licenses allowed to see route
    appLicenses?: AppLicense[]
    // gate route by licenses allowed to see route
    userLicenses?: UserLicense[]
    // gate route by ld flag
    ldFlag?: keyof typeof flags
  }
}

const Home = () => {
  const { licenses } = useAtomValue(userSettingsStaticAtom)
  const { isFlagged, flags } = useFeatureFlag()
  const isLiveEnabled = licenses.intelligentDialer && licenses.supervisor && isFlagged(flags.v291_REV_1806)
  const routes = getRoutes()
  const path = licenses.conversationAI
    ? routes[RouteName.INBOX].path
    : licenses.moments
      ? routes[RouteName.MOMENTS_NOTIFICATIONS].path
      : isLiveEnabled
        ? routes[RouteName.LIVE].path
        : routes[RouteName.CAI_NO_LICENSE].path
  return <Redirect to={path} />
}

const Search = () => {
  const { search } = useLocation()
  const routes = getRoutes()
  return (
    <Redirect
      to={{
        pathname: routes[RouteName.CONVERSATIONS].path,
        search // NOTE: Redirect is *supposed* to preserve search params, not sure why it isn't working
      }}
    />
  )
}

export const getRoutes = (): RouteProps => {
  return {
    [RouteName.HOME]: {
      path: '/',
      exact: true,
      component: Home
    },
    [RouteName.GETREVENUEINTELLIGENCE]: {
      path: RoutePaths.GET_REVENUE,
      component: GetRevenueIntelligence,
      appLicenses: ['conversationAI']
    },
    [RouteName.USERPROFILE]: {
      path: '/users/:id',
      component: UserProfile,
      appLicenses: ['moments', 'conversationAI'],
      ldFlag: 'v288_REV_1410'
    },
    [RouteName.INBOX]: {
      path: '/inbox',
      component: Inbox,
      appLicenses: ['conversationAI']
    },
    [RouteName.RECIPES]: {
      path: '/recipes',
      component: Recipes,
      appLicenses: ['moments', 'conversationAI'],
      userLicenses: ['admin', 'support'],
      ldFlag: 'v287_REV_1264'
    },
    [RouteName.SCORECARDS]: {
      path: '/scorecards',
      component: Scorecards,
      appLicenses: ['moments', 'conversationAI'],
      userLicenses: ['admin', 'support'],
      ldFlag: 'v288_REV_1410'
    },
    [RouteName.CONVERSATIONS]: {
      path: '/conversations',
      exact: true,
      component: Conversations,
      appLicenses: ['conversationAI']
    },
    [RouteName.ENGAGEMENT]: {
      path: '/engagement',
      component: Highcharts,
      appLicenses: ['moments', 'conversationAI'],
      ldFlag: 'v294_REV_2034'
    },
    [RouteName.INSIGHTS]: {
      path: '/insights',
      component: Insights,
      appLicenses: ['moments', 'conversationAI']
    },
    [RouteName.SEARCH]: {
      path: '/search',
      component: Search,
      appLicenses: ['conversationAI']
    },
    [RouteName.LIBRARY]: {
      path: '/library',
      exact: true,
      component: Library,
      appLicenses: ['conversationAI']
    },
    [RouteName.LIBRARYDETAIL]: {
      path: '/library/:id',
      exact: true,
      component: LibraryDetail,
      appLicenses: ['conversationAI']
    },
    [RouteName.LIVE]: {
      path: '/live',
      exact: true,
      component: Live,
      userLicenses: ['supervisor'],
      appLicenses: ['intelligentDialer'],
      ldFlag: 'v291_REV_1806'
    },
    [RouteName.SAVEDSEARCHDEFAULT]: {
      path: '/saved-search/my-saved-searches',
      exact: true,
      component: SavedSearch,
      appLicenses: ['conversationAI']
    },
    [RouteName.SAVEDSEARCH]: {
      path: '/saved-search/:searchType',
      exact: true,
      component: SavedSearch,
      appLicenses: ['conversationAI']
    },
    [RouteName.SAVEDSEARCHDETAIL]: {
      path: '/saved-search/:searchType/:searchId',
      exact: true,
      component: SavedSearch,
      appLicenses: ['conversationAI']
    },
    [RouteName.OPPORTUNITIES]: {
      path: '/opportunities',
      exact: true,
      component: OpportunitiesList,
      appLicenses: ['conversationAI']
    },
    [RouteName.DYNAMICLEADERBOARD]: {
      path: '/dynamic-leaderboards',
      exact: true,
      component: DynamicLeaderboard,
      appLicenses: ['conversationAI']
    },
    [RouteName.OPPORTUNITYDETAIL]: {
      path: '/opportunities/:opportunityId',
      exact: true,
      component: OpportunityDetail,
      appLicenses: ['conversationAI']
    },
    [RouteName.SETTINGS]: {
      path: '/settings',
      component: Settings,
      appLicenses: ['moments', 'conversationAI']
    },
    [RouteName.CALLKEY]: {
      path: '/conversations/:callKey',
      exact: true,
      component: RecordingPlayer
    },
    [RouteName.CAI_NO_LICENSE]: {
      path: RoutePaths.CAI_NO_LICENSE,
      component: CaiNoLicense
    },
    [RouteName.MOMENTS_NO_LICENSE]: {
      path: RoutePaths.MOMENTS_NO_LICENSE,
      component: MomentsNoLicense
    },
    [RouteName.MOMENTS_BEHAVIOR]: {
      path: RoutePaths.MOMENTS_BEHAVIOR,
      component: MomentsBehavior,
      appLicenses: ['moments'],
      userLicenses: ['admin', 'support']
    },
    [RouteName.MOMENTS_NOTIFICATIONS]: {
      path: RoutePaths.MOMENTS_NOTIFICATIONS,
      component: MomentsNotifications,
      appLicenses: ['moments', 'conversationAI']
    },
    [RouteName.MOMENTS_CHECKLISTS]: {
      path: RoutePaths.MOMENTS_CHECKLISTS,
      component: MomentsChecklists,
      appLicenses: ['moments']
    },
    [RouteName.MOMENTS_CONTENT]: {
      path: RoutePaths.MOMENTS_CONTENT,
      component: MomentsContent,
      appLicenses: ['moments']
    },
    [RouteName.MOMENTS_TEMPLATES]: {
      path: RoutePaths.MOMENTS_TEMPLATES,
      component: MomentsTemplates,
      appLicenses: ['moments'],
      userLicenses: ['admin', 'support']
    },
    [RouteName.PARTICIPANT]: {
      path: '/participant/:salesforceId',
      exact: true,
      component: Participant
    },
    // callKey route must be the last route, otherwise it won't work
    [RouteName.ROUTENOTFOUND]: {
      path: '/:route',
      component: RouteNotFound
    }
  }
}

export const useFilteredRoutes = (): RouteProps => {
  const { licenses } = useAtomValue(userSettingsStaticAtom)
  const { isFlagged, flags } = useFeatureFlag()
  const routes = getRoutes()

  const filteredRoutes = Object.keys(routes).reduce((acc: any, key: string) => {
    const route = routes[key as RouteName]
    if (route.appLicenses && !route.appLicenses.some(role => licenses[role])) return acc
    if (route.ldFlag && !isFlagged(flags[route.ldFlag])) return acc
    if (route.userLicenses && !route.userLicenses.some(role => licenses[role])) return acc
    acc[key] = route
    return acc
  }, {})

  return filteredRoutes
}

const Routing = () => {
  const filteredRoutes = useFilteredRoutes()
  const sessionIdQuery = getQueryParam(SESSION_ID_KEY)
  const history = useHistory()
  if (sessionIdQuery) {
    history.replace(history.location.pathname)
  }

  return (
    <Suspense
      fallback={
        <RdnaLoader data loading>
          {null}
        </RdnaLoader>
      }
    >
      <Switch>
        {Object.keys(filteredRoutes).map(key => {
          const route = filteredRoutes[key as RouteName]
          return <Route key={key} path={route.path} exact={!!route.exact} component={route.component} />
        })}
        <Redirect exact path={'/'} to={String(INITIAL_ROUTE.id)} />
        <InsightsRouter />
      </Switch>
    </Suspense>
  )
}

export default Routing
