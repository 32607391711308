import { HttpMethods } from '@ringdna/common/src/client/constants'
import { PicklistEnabledRoutes, SipRoute, SipRouteEditables } from '@ringdna/common/src/types/sip-routing'
import { createUseFetch, createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'
import prefixObjectKeys from '@ringdna/common/src/utils/prefixObjectKeys'

const API_BASE = '/api/v2/app/sipRouting'

type DeleteSipRouteParam = {
  meta: {
    id: number
  }
}
export const useDeleteSipRoute = createUseFetchAction<void, DeleteSipRouteParam>({
  key: 'delete-sip-route',
  path: params => `${API_BASE}/${params.meta.id}`,
  method: HttpMethods.Delete
})

export const useSipRoutes = createUseFetch<Array<SipRoute>>({
  key: 'get-sip-routes',
  path: `${API_BASE}`
})

export const useSipEnabledRoutes = createUseFetch<Array<PicklistEnabledRoutes>>({
  key: 'get-sip-enabled-routes-list',
  path: `${API_BASE}/list`
})

type PostSipRouteParam = {
  query: SipRouteEditables
}
const _usePostSipRoute = createUseFetchAction<void, any>({
  key: 'post-sip-route',
  path: `${API_BASE}`,
  method: HttpMethods.Post
})
// small wrapper to apply query prefix and maintain strong typing
export const usePostSipRoute = () => {
  const postSipRoute = _usePostSipRoute()
  return (params: PostSipRouteParam) => {
    return postSipRoute({
      query: prefixObjectKeys(params.query, 'sipRouting.')
    })
  }
}
