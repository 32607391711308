BaseModel = require('base/model')

class CampaignModel extends BaseModel
  urlRoot: 'campaigns'

  defaults:
    active: false
    description: ''
    endDate: ''
    name: ''
    status: null
    startDate: ''
    type: null

module.exports = CampaignModel