ChannelCollection = require('widgets/channel-picker/collection')
CallTrackingHelper = require('features/call-tracking/helper')

class BasicCallTrackingModal extends Backbone.Modal
  template: _.template('<div class="modals-container"></div>')

  viewContainer: '.modals-container'

  submitEl: '.done'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1-view')
    'click #step2':
      view: require('./step-2-view')
    'click #step3':
      view: require('./step-3-view')

  events:
    'keyup .stepPrevious': 'keyPressedPrevious'
    'keyup .stepNext': 'keyPressedNext'
    'click .stepPrevious': 'previousStep'
    'click .stepNext': 'nextStep'

  initialize: (options) =>
    { @accountId } = options

  keyPressedPrevious: (e) =>
    @previousStep(e) if e.keyCode is 13

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  previousStep: (e) ->
    e.preventDefault()
    @previous()

  nextStep: (e) ->
    e.preventDefault()
    return unless @validate()

    switch @currentIndex
      when 0
        @getExistingChannels()
      when 1
        @next()
      when 2
        @createBasicCallTrackings()

  createBasicCallTrackings: =>
    ladda = Ladda.create(@$('.next')[0])
    ladda.start()
    @collection.createBasics(@accountId, @model?.get('domain'), null, (hasNeedAddress) =>
      ladda.stop()
      @trigger('openAddressStepModal') if hasNeedAddress
      @destroy())

  getExistingChannels: =>
    return unless @model.get('enableNavigation')

    @model.set(enableNavigation: false)
    channels = new ChannelCollection()
    channels
      .fetch(
        data:
          accountId: @accountId
          domain: @model?.get('domain')
      )
      .done((existed) =>
        @model?.set(
          existingChannels: existed
          enableNavigation: true
        )
        @next()
      )

  validate: =>
    switch @currentIndex
      when 0
        error = @errorDomain()
      when 1
        error = 'Please select a channels' if @collection.length is 0
      when 2
        error = 'Please correct the highlighted errors.' if CallTrackingHelper.findErrors(@collection)
    if error
      toastr.warning(error)
      return false
    true

  errorDomain: =>
    error = null
    if not @model?.get('domain')
      error = 'Please enter a destination domain.'
    else if not validator.isURL(@model?.get('domain'), { require_protocol: false, require_host: true })
      error = "#{@model?.get('domain')} is invalid. Please enter a valid destination domain."
    error

module.exports = BasicCallTrackingModal
