import { BaseIconProps } from '@ringdna/common/src/assets/icons/types'
import CallDurationsIcon from '@ringdna/common/src/assets/icons/svg/CallDurations'
import CallDurationByDayIcon from '@ringdna/common/src/assets/icons/svg/CallDurationByDay'
import CallEfficiencyIcon from '@ringdna/common/src/assets/icons/svg/CallEfficiency'
import CallLogIcon from '@ringdna/common/src/assets/icons/svg/CallLog'
import CallVolumeByDayIcon from '@ringdna/common/src/assets/icons/svg/CallVolumeByDay'
import MissedRingRatesIcon from '@ringdna/common/src/assets/icons/svg/MissedRingRates'
import SupervisorDashboardIcon from '@ringdna/common/src/assets/icons/svg/SupervisorDashboard'
import UserPresenceIcon from '@ringdna/common/src/assets/icons/svg/UserPresence'
import RibbonIcon from '@ringdna/common/src/assets/icons/svg/Ribbon'
import MessageVolumeIcon from '@ringdna/common/src/assets/icons/svg/MessageVolume'
import BillingIcon from '@ringdna/common/src/assets/icons/svg/Billing'
import MessagesIcon from '@ringdna/common/src/assets/icons/svg/Messages'
import SpamCallsIcon from '@ringdna/common/src/assets/icons/svg/SpamCalls'
import UserIcon from '@ringdna/common/src/assets/icons/svg/User'
import UsersIcon from '@ringdna/common/src/assets/icons/svg/Users'
import FlowChartIcon from '@ringdna/common/src/assets/icons/svg/FlowChart'
import CallForwarding from '@ringdna/common/src/assets/icons/svg/CallForwarding'
import CalendarIcon from '@ringdna/common/src/assets/icons/svg/Calendar'
import CallRecordIcon from '@ringdna/common/src/assets/icons/svg/CallRecord'
import InsightsIcon from '@ringdna/common/src/assets/icons/svg/Insights'
import ThreeArrowsIcon from '@ringdna/common/src/assets/icons/svg/ThreeArrows'

export type ReportDetail = {
  icon: (props: BaseIconProps) => JSX.Element
  thinner?: boolean
  path: string
  title: string
  description: string
  licenses: string[]
}

const callMetrics: ReportDetail[] = [
  {
    icon: CallDurationsIcon,
    path: 'reports/avg-call-dur-day',
    title: 'Average Call Duration by Day',
    description: 'Average call duration for any given day between two selected dates.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: FlowChartIcon,
    thinner: true,
    path: 'reports/call-distribution',
    title: 'Call Distribution',
    description: 'Number and percentage of calls associated with Salesforce Objects.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: CallDurationsIcon,
    path: 'reports/call-durations',
    title: 'Call Durations',
    description: 'View total durations of calls and durations of each call leg.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: CallDurationByDayIcon,
    path: 'reports/call-dur-day-tab',
    title: 'Call Duration by Day',
    description: 'Total Call Duration (in minutes) by day.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: CallEfficiencyIcon,
    path: 'reports/call-efficiency',
    title: 'Call Efficiency',
    description: 'Understand rep efficiency in reaching specific call dispositions.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: CalendarIcon,
    thinner: true,
    path: 'reports/hold-mute-pause-report',
    title: 'Call Events',
    description: 'Hold, mute and recording pause times per call for a date range.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: CallLogIcon,
    path: 'reports/call-log',
    title: 'Call Log',
    description: 'Details on all logged calls between a given date range.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: CallRecordIcon,
    thinner: true,
    path: 'call-recordings',
    title: 'Call Recordings',
    description: 'Table of call recordings with links to SFDC Tasks & audio recordings.',
    licenses: ['support', 'admin', 'intelligentDialer', 'supervisor']
  },
  {
    icon: CallVolumeByDayIcon,
    path: 'reports/call-volume',
    title: 'Call Volume Dashboard',
    description: 'Call volume for a range of dates in stacked bar graph and tabular views.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: UserIcon,
    thinner: true,
    path: 'reports/caller-types-day',
    title: 'Caller Types by Day',
    description: 'Count of Salesforce record types that were spoken to in a given day.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: UsersIcon,
    thinner: true,
    path: 'reports/conference-calls',
    title: 'Conference Calls',
    description: 'Report detailing all warm transfer calls in the account',
    licenses: ['support', 'salesSupport', 'admin']
  }
]

const callRouting: ReportDetail[] = [
  {
    icon: CallForwarding,
    thinner: true,
    path: 'reports/call-transfer',
    title: 'Call Transfer',
    description: 'Report of calls that include a warm or a cold call transfer.',
    licenses: ['support', 'salesSupport', 'admin']
  },
  {
    icon: MissedRingRatesIcon,
    path: 'reports/missed-ring-rates',
    title: 'Missed Ring Rates',
    description: "Understand team's effectiveness in answering inbound calls.",
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: ThreeArrowsIcon,
    path: 'reports/queue-metrics',
    title: 'Queue Metrics',
    description: 'Dashboard of metrics on inbound call queues.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  }
]

const performance: ReportDetail[] = [
  {
    icon: SupervisorDashboardIcon,
    path: 'reports/supervisor',
    title: 'Supervisor Dashboard',
    description: 'View all designated supervisor activity within a certain time range.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: UserPresenceIcon,
    path: 'reports/user-busy-presence-summary',
    title: 'User Busy Presence Summary',
    description: 'Total minutes spent by users in Oncall, Busy, or Custom statuses.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: UserPresenceIcon,
    path: 'reports/user-busy-presence-summary-day',
    title: 'User Busy Presence Summary By Day',
    description: 'Total minutes spent by users in Oncall, Busy, or Custom statuses.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  }
]

const callQuality: ReportDetail[] = [
  {
    icon: RibbonIcon,
    path: 'reports/call-quality',
    title: 'Call Quality Dashboard',
    description: 'Insights into call quality issues that users may be experiencing.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  },
  {
    icon: InsightsIcon,
    thinner: true,
    path: 'reports/call-reports',
    title: 'Call Reports',
    description: 'All call reports submitted by RingDNA Dialer users.',
    licenses: ['support', 'salesSupport', 'admin']
  }
]

const messaging: ReportDetail[] = [
  {
    icon: MessageVolumeIcon,
    path: 'reports/messages',
    title: 'Message Volume',
    description: 'Dashboard of inbound and outbound SMS message volume.',
    licenses: ['support', 'salesSupport', 'admin', 'supervisor']
  }
]

const support: ReportDetail[] = [
  {
    icon: BillingIcon,
    path: 'reports/billing',
    title: 'Billing',
    description: 'Generate CSVs of Billing information for accounts.',
    licenses: ['support']
  },
  {
    icon: MessagesIcon,
    path: 'messages',
    title: 'Messages',
    description: 'Report of all SMS messages logged in the account.',
    licenses: ['support', 'salesSupport']
  },
  {
    icon: SpamCallsIcon,
    path: 'reports/spam-calls',
    title: 'Spam Calls',
    description: 'Report showing calls marked as "spam".',
    licenses: ['support', 'salesSupport']
  }
]

export type ReportGroup = {
  title: string
  reports: ReportDetail[]
}

export function getReportGroups(license: string) {
  return [
    { title: 'Call Metrics', reports: callMetrics.filter(report => report.licenses.includes(license)) },
    { title: 'Call Routing', reports: callRouting.filter(report => report.licenses.includes(license)) },
    { title: 'Performance', reports: performance.filter(report => report.licenses.includes(license)) },
    { title: 'Call Quality', reports: callQuality.filter(report => report.licenses.includes(license)) },
    { title: 'Messaging', reports: messaging.filter(report => report.licenses.includes(license)) },
    { title: 'Support', reports: support.filter(report => report.licenses.includes(license)) }
  ]
}
