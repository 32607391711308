BaseCollection  = require('base/collection')
BaseModel       = require('base/model')

class CallScriptModel extends BaseModel
  urlRoot: -> "smartNumbers/#{@get('numberId')}/script"

class CallScriptCollection extends BaseCollection
  model: CallScriptModel

  setNumber: (numberId) =>
    _.each(@models, (model) ->
      model.set('numberId', numberId)
    )

module.exports = CallScriptCollection
