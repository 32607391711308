BaseModel = require('base/model')

class TeamModel extends BaseModel
  urlRoot: 'teams'

  defaults:
    name: ''
    inactive: false
    isDeleted: false
    description: ''
    type: 'Default'

  initialize: =>
    @teams = [ 'id', 'name', 'description', 'inactive', 'isDeleted', 'type', 'account']
    @settings = [ 'id', 'openBarge', 'callRecordingLocked', 'callRecordingOption', 'callDispositionRequiredState']

  toJSON: =>
    fields = @toPlayJSON('team', _.pick(@attributes, @teams))
    fields["settings.#{key}"] = @get('settings')[key] for key in @settings
    fields

module.exports = TeamModel
