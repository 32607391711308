import { TableFilterProps } from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import { GenericObject } from '@ringdna/common/types/generic'
import { Role, SelectEvent, Team, User, UserRoleAssign } from './types'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import { Link } from 'react-router-dom'
import CaretIcon from '@ringdna/common/src/assets/icons/svg/Caret'
import { Direction } from '@ringdna/common/src/assets/icons/types'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import React, { useCallback } from 'react'
import { createSimpleOptions } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
import RdnaAutocomplete from '@ringdna/common/src/components/RdnaFormElements/RdnaAutoComplete'
import styled, { useTheme } from 'styled-components'
import { RdnaRouterLink } from '@ringdna/common/src/components/RdnaRouterLink'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'

type RowData = {
  values: {
    Team: string
  }
}

type RolesRowData = {
  original: Role
}

export const rolesColumns = [
  {
    Header: 'Role',
    accessor: 'name',
    Cell: (data: { value: string; row: RolesRowData }) => RoleNameCell(data.value, data.row.original.id)
  },
  {
    Header: 'Methodology',
    accessor: 'methodology.name',
    disableFilters: false,
    Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
      return Filter(filterProps)
    },
    Cell: (data: { value: string }) => (data.value == null ? '-' : data.value)
  },
  {
    Header: 'Department',
    accessor: 'department',
    disableFilters: false,
    Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
      return Filter(filterProps)
    }
  },
  {
    Header: 'Number of Users',
    accessor: 'numberOfUsers',
    width: 80,
    Cell: (data: { value: number }) => (data.value === 0 ? '-' : data.value)
  },
  {
    Header: '',
    accessor: 'id' as const,
    disableSortBy: true,
    width: 30,
    styles: {
      align: 'right'
    },
    Cell: (data: { value: number }) => RoleMenuCell(data.value)
  }
]

export const roleDefinitionsColumns = [
  {
    Header: 'Role',
    accessor: 'name',
    width: 80
  },
  {
    Header: 'Department',
    accessor: 'department',
    width: 80,
    disableFilters: false,
    Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
      return Filter(filterProps)
    }
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (data: { value: string }) => DescriptionCell(data.value)
  }
]

export const methodologiesColumns = [
  {
    Header: 'Methodology',
    accessor: 'name',
    width: 80
  },
  {
    Header: 'Department',
    accessor: 'type',
    width: 80
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (data: { value: string }) => DescriptionCell(data.value)
  }
]

export const usersColumns = [
  {
    Header: 'User',
    accessor: 'displayName',
    Cell: (data: { value: string }) => TextCell(data.value)
  },
  {
    Header: 'Salesforce Title',
    accessor: 'title',
    Cell: (data: { value: string }) => TextCell(data.value)
  },
  {
    Header: 'Salesforce Department',
    accessor: 'sfDepartment',
    Cell: (data: { value: string }) => TextCell(data.value)
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export function unassignedUsersColumns(userList: User[], setUserList: any) {
  return [
    {
      accessor: 'id',
      width: 40,
      disableSortBy: true,
      Header: function headerCell(headerProp: { data: User[] }) {
        return (
          <RdnaCheckbox
            onChange={(checked: boolean) => {
              checked ? setUserList(headerProp.data) : setUserList([])
            }}
            value={headerProp.data.length === userList.length && headerProp.data.length != 0}
            indeterminate={headerProp.data.length != userList.length && userList.length != 0}
          />
        )
      },
      Cell: function selectUserCell(row: { row: { original: User } }) {
        return (
          <RdnaCheckbox
            onChange={(checked: boolean) => {
              checked
                ? setUserList([...userList.values(), row.row.original])
                : setUserList(userList.filter(user => user.id !== row.row.original.id))
            }}
            value={userList.find(user => user.id === row.row.original.id) != null}
          />
        )
      }
    },
    {
      Header: 'Name',
      accessor: 'displayName',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Salesforce Title',
      accessor: 'title',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Salesforce Department',
      accessor: 'sfDepartment',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Team',
      accessor: ({userTeams}: { userTeams: Team[] }) => extractItemTeamNames(userTeams),
      disableSortBy: true,
      minWidth: 0,
      width: 0,
      disableFilters: false,
      Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
        return UserTeamsFilter(filterProps)
      },
      filter: function fn(rows: RowData[], columnIds: string[], filterValue: string) {
        return filterValue ? rows.filter(row => row.values.Team?.includes(filterValue)) : rows
      }
    },
    {
      Header: 'Salesforce Profile',
      accessor: 'sfProfile',
      disableSortBy: true,
      minWidth: 0,
      width: 0,
      disableFilters: false,
      Filter: function fn(filterProps: TableFilterProps<GenericObject>) {
        return Filter(filterProps)
      }
    }
  ]
}
export function assignedUsersColumns(page: number) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'displayName',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Salesforce Title',
      accessor: 'title',
      Cell: (data: { value: string }) => TextCell(data.value)
    },
    {
      Header: 'Salesforce Department',
      accessor: 'sfDepartment',
      Cell: (data: { value: string }) => TextCell(data.value)
    }
  ]
  if (page == 2) {
    return [
      ...columns,
      {
        Header: 'Role',
        accessor: 'selectRole',
        Cell: function selectRole({ value }: { value: UserRoleAssign }) {
          const { user, roleOptions, bulkRole, bulkRoleId, onChangeRole } = value
          return RoleSelectCell(user, bulkRole, bulkRoleId, roleOptions, onChangeRole)
        }
      }
    ]
  } else {
    return [
      ...columns,
      {
        Header: 'Role',
        accessor: 'roleName'
      }
    ]
  }
}

const DescriptionCell = (description: string) => {
  const descriptionSplit = description.split('\n')
  return (
    <Description>
      {descriptionSplit.map((item, index) => {
        return (
          <span key={`${index}-${item}`}>
            <RdnaText>{item}</RdnaText>
            <br />
          </span>
        )
      })}
    </Description>
  )
}

const RoleNameCell = (name: string, id: number) => {
  return <RdnaRouterLink to={`/${id}`}>{name}</RdnaRouterLink>
}

const RoleMenuCell = (id: number) => {
  return (
    <RoleMenu>
      <Link to={`/${id}`}>
        <CaretIcon direction={Direction.RIGHT} />
      </Link>
    </RoleMenu>
  )
}

const RoleSelectCell = (user: User, bulkRole: boolean, bulkRoleId: number, roleOptions: [], onChangeRole: any) => {
  return (
    <RdnaSelect
      name={'user-role'}
      placeholder={'Role'}
      options={roleOptions}
      value={user.roleId ? user.roleId : ''}
      onChange={e => onChangeRole(e as SelectEvent, user)}
      disabled={bulkRole}
    />
  )
}

const TextCell = (value: string) => {
  const { spacing } = useTheme();
  return <RdnaText style={{ padding: `0 ${spacing}px` }}>{value}</RdnaText>
}

const Filter = ({
  // @ts-ignore
  column: { Header, filterValue, setFilter, preFilteredRows, id }
}: TableFilterProps<GenericObject>) => {
  const getOptions = useCallback(() => {
    const optionValues = new Set()
    // @ts-ignore
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        optionValues.add(row.values[id])
      }
    })
    return createSimpleOptions([...optionValues])
  }, [id, preFilteredRows])
  return (
    <RdnaAutocomplete
      id={`${(Header as string).toLowerCase().replaceAll(' ', '-')}-filter`}
      onChange={(event: any, newValue: any) => setFilter(newValue ? newValue.value : null)}
      options={getOptions()}
      isOptionEqualToValue={(option, value) => option.value === value}
      placeholder={Header as string}
      value={typeof filterValue === 'undefined' ? null : filterValue}
    />
  )
}

const UserTeamsFilter = ({
  // @ts-ignore
  column: { Header, filterValue, setFilter, preFilteredRows, id }
}: TableFilterProps<GenericObject>) => {
  const getOptions = useCallback(() => {
    const optionValues = new Set()
    // @ts-ignore
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        // @ts-ignore
        row.values[id].split(',').map(item => optionValues.add(item))
      }
    })
    return createSimpleOptions([...optionValues])
  }, [id, preFilteredRows])
  return (
    <RdnaAutocomplete
      id={`${(Header as string).toLowerCase().replaceAll(' ', '-')}-filter`}
      onChange={(event: any, newValue: any) => setFilter(newValue ? newValue.value : null)}
      options={getOptions()}
      isOptionEqualToValue={(option, value) => option.value === value}
      placeholder={Header as string}
      value={typeof filterValue === 'undefined' ? null : filterValue}
    />
  )
}

const extractItemTeamNames = (items: Team[]): string => {
  return items.map(item => item.team.name).join(',')
}

const RoleMenu = styled.div`
  display: flex !important;
  cursor: pointer;
  align-items: center;
  > a {
    height: ${({ theme }) => theme.spacing * 4}px;
  }
`

const Description = styled.div`
  display: block;
  white-space: unset;
`
