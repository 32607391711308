import { isBefore, isSameDay } from 'date-fns'
import { FieldErrors, FieldValues, RegisterOptions, FieldError } from 'react-hook-form'
import React from 'react'

// used primarily for RdnaSelect when label and value attributes are the same value
export const createSimpleOptions = <T>(options: readonly T[]) =>
  options.map(option => ({ label: option, value: option }))
export type PicklistPayload = { text: string; value: string }
export const parsePicklist = (picklist: PicklistPayload[]) =>
  picklist.map(item => ({ label: item.text, value: item.value }))

export const createOptionsFromObject = (obj: { [key: string]: string }) =>
  Object.entries(obj).map(([value, label]) => ({
    label,
    value
  }))

const ruleByName: { [key: string]: RegisterOptions } = {
  required: { required: 'This field is required.' },
  hour: {
    min: { value: 0, message: 'Must be greater than 0' },
    max: { value: 24, message: 'Must be less than 25' }
  },
  day: {
    min: { value: 0, message: 'Must be greater than 0' },
    max: { value: 365, message: 'Must be less than 366' }
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address'
    }
  },
  phone: {
    pattern: {
      value: /((\(\d{3}\) ?)|((\d{3}-))?\d{3}-\d{4})|^[0-9]{10}$/i,
      message: 'Invalid phone number'
    }
  },
  negative: { max: { value: 0, message: 'Must be a negative number' } },
  positive: { min: { value: 0, message: 'Must be a positive number' } },
  notAllWhiteSpace: {
    validate: (value: string) => {
      if (value.length > 0 && !value.trim().length) {
        return 'Must contain one character'
      }
    }
  },
  booleanRequired: {
    validate: (value: boolean) => {
      if (value === undefined) {
        return 'This field is required.'
      }
    }
  },
  termsAndConditions: { required: 'You must agree to the terms & conditions.' }
}

export const getRules = (rules: (keyof typeof ruleByName)[]): RegisterOptions => {
  return rules.reduce(
    (ruleMap, rule) => {
      if (ruleByName[rule] === undefined) {
        console.error('rule does not exist: ' + rule)
      }
      ruleMap = { ...ruleMap, ...ruleByName[rule] }

      return ruleMap
    },
    {} as unknown as RegisterOptions
  )
}

export const isNotBetween = (startDate: string, endDate: string): boolean => {
  return (
    isBefore(new Date(endDate as string), new Date(startDate as string)) &&
    !isSameDay(new Date(endDate as string), new Date(startDate as string))
  )
}

// Make sure start date is not after end date
export const correctDateOrder = (startDate: Date, endDate: Date): boolean => {
  return isBefore(endDate, startDate) && !isSameDay(endDate, startDate)
}

// already passed through RdnaForm props
export const useGetMuiProps = <V extends FieldValues>(errors: FieldErrors<V>) =>
  React.useCallback(
    (renderProps: any) => {
      let error: undefined | FieldErrors<V> | FieldError = errors

      const nestedNames = renderProps.name?.split('.') || ''

      for (let i = 0; i < nestedNames.length; i += 1) {
        let formFieldName = nestedNames[i]
        let foundIndex

        // this is checking if it is a fieldArray. we can tell because we use [brackets]
        const fieldArrayMatches = formFieldName.match(/\[([^\]]+)]/g)

        // if it is, there should only be one value [${index}]
        if (fieldArrayMatches && fieldArrayMatches.length) {
          const bracketedIndex: string = fieldArrayMatches[0]
          // lets remove bracketed index from the formFieldName
          formFieldName = formFieldName.replace(bracketedIndex, '')
          // lets grab the index value by removing the brackets and converting to number
          foundIndex = +bracketedIndex.substring(1, bracketedIndex.length - 1)
        }

        error = error?.[formFieldName as never]

        if (error === undefined) break

        // if it is a fieldArray and the above wasn't undefined, then there SHOULD be something at the index
        if (foundIndex !== undefined) {
          error = error?.[foundIndex as never]
        }
      }

      return {
        value: renderProps.value,
        onChange: renderProps.onChange,
        onBlur: renderProps.onBlur,
        name: renderProps.name,
        error: !!error,
        helperText: error?.message
      }
    },
    [errors]
  )
