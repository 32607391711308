import { getFormattedDate } from '@ringdna/common/src/utils/timeUtils'
import { settingProps } from '../../types'
import { NameLinkCell, ValidIconCell } from '../../cells'

export const PLACEHOLDER = 'Call Flow'
export const lockedUnavailableFlow = 'lockedSetting.unavailableFlow'
export const agentUnavailableFlowIdSetting = 'settings.agentUnavailableFlow.id'
export const localPresenceFallbackFlowIdSetting = 'settings.localPresenceFallbackFlow.id'
export const callFlowLink = `/#call-flows/`

export const NONE_VALUE = [
  {
    label: '(none)',
    value: ''
  }
]

export const UnavailableCallFlow: settingProps = {
  title: 'Unavailable Call Flow',
  description:
    'Specify a call flow for inbound calls to agent Smart Numbers in which an agent is unavailable. The call flow is executed instead of sending the caller to voicemail.'
}

export const DefaultLocalPresenceFlow: settingProps = {
  title: 'Default Local Presence Flow',
  description: 'Specify a call flow for inbound local presence calls if no agents are available.'
}

export function callFlowsColumns(isSupport: boolean) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, row }: { value: string; row: any }) => NameLinkCell(value, row, callFlowLink)
    },
    {
      Header: 'Account',
      accessor: 'account.name'
    },
    {
      Header: 'Valid',
      accessor: 'valid',
      width: 80,
      Cell: (data: { value: boolean }) => ValidIconCell(data.value)
    },
    {
      Header: 'Status',
      accessor: 'paused',
      width: 80,
      Cell: (data: { value: boolean }) => (data.value ? 'Inactive' : 'Active')
    },
    {
      Header: 'Date Created',
      accessor: 'createdDate',
      width: 150,
      Cell: (data: { value: string }) => getFormattedDate(data.value).join(' ')
    },
    {
      Header: 'Date Updated',
      accessor: 'updatedDate',
      width: 150,
      Cell: (data: { value: string }) => getFormattedDate(data.value).join(' ')
    },
    {
      Header: 'Actions',
      accessor: 'menu',
      width: 80,
      disableSortBy: true,
      styles: {
        align: 'right'
      }
    }
  ]
  if (!isSupport) {
    columns.splice(1, 1)
  }
  return columns
}

export const getCallFlowLockedSettingsContent = (str: string) => {
  return {
    title: '"Unavailable Flow" Account Setting',
    tooltip: {
      lock: 'Click to unlock this setting and allow users to set individual Unavailable Flows.',
      unlock: 'Click to lock this setting and disable users from setting individual Unavailable Flows.'
    },
    modalText: {
      lock: `The call flow: ${str} will be used as the unavailable flow for all users.
        Users will not be able to set a different unavailable flow.`,
      unlock: `Users will be able to set their own Unavailable Flow. The call flow:
         ${str} will be used as the default Unavailable Flow for all users, but users can choose to set a new Unavailable Flow for themselves as desired.`
    }
  }
}
