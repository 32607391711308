BaseView  = require('base/view')
AudioView = require('../../widgets/audio/view')
UQView    = require('../../widgets/usersAndQueues/view')

class VoicemailStepView extends BaseView

  template: require('./template')

  regions:
    audio : '.audio-view'
    uv    : '.vm-user-assignment'
    uqv   : 'div.uqv-view'

  bindings:
    '.voicemail-assignment':'toggle:showVoicemailAssignment'

  initialize: (options) ->
    @callflow = options.callflow
    @viewModel = new Backbone.Model(
      showVoicemailAssignment: false
    )

  onRender: =>
    @showChildView('audio', new AudioView(model: @model, prefix: 'audio', callflow: @callflow))
    @showChildView('uqv', new UQView(
      step      : @model
      type      : 'Voicemail'
      accountId : @callflow.get('account')?.id
      readOnly  : @callflow.get('readOnly'))
    )
    @listenTo(@model, 'selectedUQ', (selected) =>
      @viewModel.set(showVoicemailAssignment: selected?.type is 'group')
      return if not @viewModel.get('showVoicemailAssignment')
      @voicemailAssignmentView = new UQView (
        step      : @model
        type      : 'VoicemailAssignment'
        accountId : @callflow.get('account')?.id
        readOnly  : @callflow.get('readOnly')
        restriction : 'users'
      )
      @showChildView('uv', @voicemailAssignmentView)
    )

module.exports = VoicemailStepView
