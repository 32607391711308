BackgridCell      = require('helpers/backgrid-cell')
BackgridRow       = require('helpers/backgrid-row')
BaseView          = require('base/view')
PagedCollection   = require('base/paged-collection')
PagerView         = require('widgets/pager/view')
UserPickerView    = require('widgets/user-picker/view')
ReactComponent    = require('src/ReactComponent').default
BacklinkButton    = require('src/components/reports/BacklinkButton').default

class MessageSearchView extends BaseView

  template: require('./template')
  className: 'message-search'

  regions:
    userPicker    : 'span.user-picker'
    grid          : 'div.grid'
    pager         : 'span.pager'
    backlinkButton: 'div.backlink-button'

  ui:
    messageSid : 'input.message-sid'
    search     : 'button.search'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  initialize: (options) ->
    {@qs} = options
    @qs.setFragment('messages')

  onRender: =>
    @showChildView('backlinkButton', new ReactComponent(component: BacklinkButton))

    @userPicker = @getRegion('userPicker')
    @userPicker.show(new UserPickerView(accountId: @user().getSelectedSupportAccountId()))

    @qs.bind(
      messageSid : @ui.messageSid
      userId     : @userPicker.currentView
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    collection = new PagedCollection(
      page: @qs.get('page')
      queryParams:
        messageSid : @ui.messageSid.val()
        accountId  : @user().getSelectedSupportAccountId()
        userId     : @userPicker.currentView.val()
      url: 'messages'
    )

    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))
    collection.fetch(reset: true)

  buildGrid: (collection) ->
    msgCell = BackgridCell.href(
      url   : '#messages/:id',
      label : ':sid',
      attrs : ['id', 'sid'])

    acctCell = BackgridCell.accountCell('account.id', 'account.name')

    userCell = BackgridCell.usersCell('owner.id', 'owner.displayName')

    datetime = BackgridCell.dateTime()

    cols = [
      {label: 'SID',          name: 'sid',               cell: msgCell}
      {label: 'Account',      name: 'account.name',      cell: acctCell}
      {label: 'Owner',        name: 'owner.displayName', cell: userCell}
      {label: 'Direction',    name: 'direction',         cell: 'string'}
      {label: 'To Number',    name: 'toNumber',          cell: 'string'}
      {label: 'From Number',  name: 'fromNumber',        cell: 'string'}
      {label: 'Created Date', name: 'createdAt',         cell: datetime}
      {label: 'ID',           name: 'id',                cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No messages found.'
      row        : BackgridRow.deleted()
    )

module.exports = MessageSearchView
