import React, { useCallback, useMemo, useRef, useState } from 'react'
import RdnaSmartTable, { RdnaSmartTableRef } from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaMenu from '@ringdna/common/src/components/RdnaMenu'
import RelatedRecordsModal from './components/RelatedRecordsModal'
import { RelatedRecordsProps, RelatedRecordsRulesProps, RelatedRecordsDeleteModalProps } from './types'
import styled from 'styled-components'
import { relatedRecordsColumns, NO_RESULT_STATE } from './const'
import {
  useCustomSalesforceRecords,
  useRecordsDelete,
  useRelatedRecordCreate,
  useRelatedRecords,
  useRelatedRecordUpdate,
  useRelatedRecordUpdatePriority
} from './api'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import SvgDelete from '@ringdna/common/src/assets/icons/svg/Delete'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { isCustomRecord } from './utils/contextReducer'

const RelatedRecordsRules = ({ accountId }: RelatedRecordsRulesProps) => {
  const [isRelatedRecordsModalOpen, setIsRelatedRecordsModalOpen] = useState(false)
  const [isRelatedRecordsDeleteModalOpen, setIsRelatedRecordsDeleteModalOpen] = useState(false)
  const [relatedRecord, setRelatedRecord] = useState<RelatedRecordsProps>()
  const [usedRelatedRecords, setUsedRelatedRecords] = useState<string[]>([])
  const smartTableRef = useRef<RdnaSmartTableRef | undefined>()
  const [loadingAdd, setLoadingAdd] = useState(false)
  const relatedRecordUpdate = useRelatedRecordUpdate()
  const relatedRecordCreate = useRelatedRecordCreate()
  const customSalesforceRecords = useCustomSalesforceRecords()
  const relatedRecordUpdatePriority = useRelatedRecordUpdatePriority()
  const deleteRelatedRecord = useRecordsDelete()
  const [payload, , loading, refetch] = useRelatedRecords({
    meta: {
      accountId
    }
  })
  const [relatedRecordList, setRelatedRecordList] = useState(payload)

  const menuOptions = (record: RelatedRecordsProps) => {
    const options = [{ text: 'Edit' }]
    if (isCustomRecord(record)) {
      options.push({ text: 'Delete' })
    }
    return options
  }

  const handleActionMenu = useCallback((action: string, relatedRecord: RelatedRecordsProps) => {
    setRelatedRecord(relatedRecord)
    if (action === 'Delete') {
      setIsRelatedRecordsDeleteModalOpen(true)
    } else {
      setIsRelatedRecordsModalOpen(true)
    }
  }, [])

  async function handleDelete() {
    try {
      await deleteRelatedRecord({ meta: { relatedRecordId: relatedRecord?.id } })
      setIsRelatedRecordsDeleteModalOpen(false)
      refetch()
    } catch (e) {
      toastr.error(e)
    }
  }

  async function handleReorder(draggableRow: RelatedRecordsProps, startIndex: number, endIndex: number) {
    const newData = [...relatedRecordList]
    const [movedRow] = newData.splice(startIndex, 1)
    newData.splice(endIndex, 0, movedRow)
    setRelatedRecordList(newData)
    await relatedRecordUpdatePriority({
      relatedRecordId: draggableRow.id,
      body: {
        accountId: accountId,
        newPriority: endIndex,
        oldPriority: startIndex
      }
    })
  }

  async function closeRelatedRecordsModal(hasChanges: boolean) {
    setIsRelatedRecordsModalOpen(false)
    if (hasChanges && relatedRecord) {
      handleSave(relatedRecord, true)
    }
  }

  async function handleSave(relatedRecord: RelatedRecordsProps, reload: boolean) {
    try {
      const body = {
        'relatedRecord.account.id': accountId,
        'relatedRecord.apiName': relatedRecord.apiName,
        'relatedRecord.typeRecord': relatedRecord.typeRecord,
        'relatedRecord.orderBy': relatedRecord.orderBy,
        'relatedRecord.limitRows': relatedRecord.limit,
        'relatedRecord.priority': relatedRecord.id ? relatedRecord.priority : relatedRecordList?.length,
        searchesRules: relatedRecord.searches,
        suggestsRules: relatedRecord.suggests,
        viewsRules: relatedRecord.views
      }
      if (relatedRecord.id) {
        await relatedRecordUpdate({
          relatedRecordId: relatedRecord.id,
          body: {
            ...body,
            'relatedRecord.id': relatedRecord.id
          }
        })
      } else {
        await relatedRecordCreate({
          body: body
        })
      }
    } catch (err) {
      console.error(err.fail?.message || err.fail?.payload.message || err)
    } finally {
      if (reload) {
        refetch()
      }
    }
  }

  const onAdd = async () => {
    try {
      setLoadingAdd(true)
      const response = await customSalesforceRecords({ meta: { accountId } })
      setRelatedRecord(response?.success?.payload as RelatedRecordsProps)
      setLoadingAdd(false)
      setIsRelatedRecordsModalOpen(true)
    } catch (error) {
      toastr.error(JSON.parse(error.fail.message).message)
      setLoadingAdd(false)
    }
  }

  useMemo(() => {
    setUsedRelatedRecords(
      payload?.map((item: RelatedRecordsProps) => {
        return item.apiName
      })
    )
    const memoPayload = payload?.map((template: RelatedRecordsProps) => ({
      ...template,
      menu: (
        <>
          <RdnaMenu options={menuOptions(template)} onSelect={item => handleActionMenu(item, template)} />
        </>
      )
    }))
    setRelatedRecordList(memoPayload)
  }, [payload, handleActionMenu])

  return (
    <Container>
      <Top>
        <RdnaText bold>{'Related Records'}</RdnaText>
        <CreateButton
          data-analyticsid="custom-related-record-create"
          text={'Add Object'}
          onClick={() => {
            onAdd()
          }}
          disabled={loadingAdd}
        />
      </Top>
      <RdnaSmartTable
        ref={smartTableRef}
        hideAboveContainer={true}
        dragNDrop={true}
        reorderData={handleReorder}
        // @ts-ignore - TODO
        columns={relatedRecordsColumns}
        data={relatedRecordList || []}
        noResultState={NO_RESULT_STATE}
        isFetching={!!loading}
      />
      <RelatedRecordsModal
        open={isRelatedRecordsModalOpen}
        onClose={closeRelatedRecordsModal}
        relatedRecord={relatedRecord}
        setRelatedRecord={setRelatedRecord}
        usedRelatedRecords={usedRelatedRecords}
      />
      <ConfirmRelatedRecordsDeleteModal
        open={isRelatedRecordsDeleteModalOpen}
        onClose={setIsRelatedRecordsDeleteModalOpen}
        relatedRecordName={relatedRecord?.apiName}
        onConfirm={handleDelete}
      />
    </Container>
  )
}

const ConfirmRelatedRecordsDeleteModal = ({
  open,
  onClose,
  onConfirm,
  relatedRecordName
}: RelatedRecordsDeleteModalProps) => {
  return (
    <RdnaModal
      open={open}
      cancelButtonText={'Cancel'}
      confirmButtonText={'Delete'}
      heading={`Delete ${relatedRecordName}`}
      headingIcon={<SvgDelete />}
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
    >
      <RdnaText>Are you sure you want to delete this Related Record?</RdnaText>
    </RdnaModal>
  )
}

const Container = styled.div`
  .rules-column {
    height: auto !important;
    min-height: ${({ theme }) => theme.spacing * 6}px;
    padding: 0 6px;
  }
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing * 5}px 0;
  text-align: left;
  align-items: center;
`
const CreateButton = styled(RdnaButton)`
  justify-content: flex-end;
  margin-right: 0;
`

export default React.memo(RelatedRecordsRules)
