Model = require('./model')
PagedCollection = require('base/paged-collection')

class TeamsCollection extends PagedCollection
  model: Model
  
  initialize: (models, options) =>

  state:
    pageSize: 10000

  url: =>
    "teams"


module.exports = TeamsCollection
