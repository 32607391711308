import React from 'react'
import { HeaderTitle, SectionHeader, SettingSelect } from '../../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import CallFlowsIcon from '@ringdna/common/src/assets/icons/svg/CallFlows'
import { CALL_FLOWS } from '../../const'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import {
  agentUnavailableFlowIdSetting,
  DefaultLocalPresenceFlow,
  getCallFlowLockedSettingsContent,
  localPresenceFallbackFlowIdSetting,
  lockedUnavailableFlow,
  PLACEHOLDER,
  UnavailableCallFlow
} from './const'
import RdnaLockedSetting from '@ringdna/common/src/components/RdnaLockedSetting'
import { RdnaSelectOptions } from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export type CallFlowSettingsProps = {
  accountSettings: { [key: string]: any }
  setAccountSetting: (setting: { [key: string]: any }) => void
  lockedSettings: { [key: string]: any }
  setLockedSetting: (setting: { [key: string]: any }) => void
  optionsCallFlows: RdnaSelectOptions<any>[]
  selectedUnavailableFlow: any
}

export default function CallFlowsSettings({
  accountSettings,
  setAccountSetting,
  setLockedSetting,
  lockedSettings,
  optionsCallFlows,
  selectedUnavailableFlow
}: CallFlowSettingsProps) {
  const saveLocked = () => {
    setLockedSetting({ ...lockedSettings, ['lockedSetting.unavailableFlow']: !lockedSettings[lockedUnavailableFlow] })
  }

  const handleChange = (setting: string) => (event: React.SyntheticEvent<Element, Event>, option: any) => {
    setAccountSetting({ ...accountSettings, [setting]: option ? option.value : null })
  }

  const agentUnavailableFlowId = accountSettings[agentUnavailableFlowIdSetting]
  const localPresenceFallbackFlowId = accountSettings[localPresenceFallbackFlowIdSetting]

  return (
    <>
      <SectionHeader>
        <IconHelper icon={CallFlowsIcon} variant="filled" />
        <HeaderTitle>{CALL_FLOWS.title}</HeaderTitle>
      </SectionHeader>
      <RdnaSettingRow heading={UnavailableCallFlow.title} description={UnavailableCallFlow.description}>
        <div className={'locked-content'}>
          <RdnaLockedSetting
            content={getCallFlowLockedSettingsContent(selectedUnavailableFlow())}
            locked={lockedSettings[lockedUnavailableFlow]}
            saveSetting={saveLocked}
          />
        </div>
        <SettingSelect
          placeholder={'Call Flow'}
          id={'unavailableCallFlow'}
          options={optionsCallFlows}
          onChange={handleChange(agentUnavailableFlowIdSetting)}
          isOptionEqualToValue={(option: any, value) => {
            return option.value === value
          }}
          getOptionLabel={(option: any) => {
            if (typeof option === 'object') {
              return option.label
            } else {
              const field = optionsCallFlows?.find(callFlow => callFlow.value == option)
              return field?.label || '(none)'
            }
          }}
          value={agentUnavailableFlowId || ''}
          renderOption={(props, option: any) => {
            return (
              <li {...props} data-testid={`option-${option.value}`} key={option.value}>
                <RdnaText> {option.label} </RdnaText>
              </li>
            )
          }}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={DefaultLocalPresenceFlow.title} description={DefaultLocalPresenceFlow.description}>
        <SettingSelect
          placeholder={PLACEHOLDER}
          id={'localPresenceFallbackFlow'}
          options={optionsCallFlows}
          onChange={handleChange(localPresenceFallbackFlowIdSetting)}
          isOptionEqualToValue={(option: any, value) => {
            return option.value === value
          }}
          getOptionLabel={(option: any) => {
            if (typeof option === 'object') {
              return option.label
            } else {
              const field = optionsCallFlows?.find(callFlow => callFlow.value == option)
              return field?.label || '(none)'
            }
          }}
          value={localPresenceFallbackFlowId || ''}
          renderOption={(props, option: any) => {
            return (
              <li {...props} data-testid={`option-${option.value}`} key={option.value}>
                <RdnaText> {option.label} </RdnaText>
              </li>
            )
          }}
        />
      </RdnaSettingRow>
    </>
  )
}
