import React, { useState } from 'react'
import styled from 'styled-components'
import RdnaTooltip from '../RdnaTooltip'

// constants
import { Colors } from '../../constants/colors'

// utils
import { getInitials } from '../../utils/initialsUtils'
import { stringToColor } from '../../utils/colorUtils'

// styled
import RdnaText from '../RdnaText'
import QuestionIcon from '../../assets/icons/svg/Question'
import XmarkIcon from '../../assets/icons/svg/Xmark'
import CheckmarkIcon from '../../assets/icons/svg/Checkmark'

export enum UserStatuses {
  PRIMARY = 'primary',
  OFFLINE = 'neutral',
  ONLINE = 'action',
  BUSY = 'alert'
}

export interface AvatarProps {
  /** Image URL of user. If none provided will fallback to initials */
  imageUrl?: string
  /** Size of Avatar */
  size?: number
  /** Name of user. Component will automatically determine initials */
  name?: string
  /** Will display circle to signify status of user */
  status?: UserStatuses
  /** Will display icon within circle of status */
  showStatusIcon?: boolean
  /** Override color of avatar. Otherwise color is derived from name */
  color?: Colors
  /** String shown in tooltip on hover. Defaults to name if none provided */
  tooltipText?: string
}

interface FallbackProps extends SizeProp {
  $bgColor: Colors
}

interface RingProps extends SizeProp {
  status: UserStatuses
}

interface SizeProp {
  size: number
}

const BASE_SIZE = 24
const STATUS_RATIO = 7 / 24

const AvatarContainer = styled.div`
  position: relative;
  width: fit-content;
`

const Image = styled.img<{ size: number }>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 100%;
  display: inherit;
  object-fit: cover;
`

const Fallback = styled.div<FallbackProps>`
  ${({ size, $bgColor }) => `
    background-color: ${$bgColor};
    min-height: ${size}px;
    max-height: ${size}px;
    min-width: ${size}px;
    max-width: ${size}px;
`}
  border-radius: 100%;
  text-align: center;
  color: white;
  > span {
    font-size: ${props => props.size / 2}px;
    line-height: ${props => props.size}px;
    display: block;
    vertical-align: middle;
  }
`

const Status = styled.div<RingProps>`
  border-radius: 100%;
  border-style: solid;
  border-width: ${props => props.size / BASE_SIZE}px;
  border-color: ${props => props.theme.palette.primary.contrastText};
  background-color: ${props => props.theme.palette[props.status].main};
  height: ${props => props.size * STATUS_RATIO}px;
  width: ${props => props.size * STATUS_RATIO}px;
  position: absolute;
  right: ${props => props.size / -BASE_SIZE}px;
  bottom: ${props => props.size / -BASE_SIZE}px;
`

const IconContainer = styled.div<RingProps>`
  height: ${props => props.size * STATUS_RATIO}px;
  width: ${props => props.size * STATUS_RATIO}px;
  display: flex;
  justify-content: center;
  align-items: center;
  path {
    color: ${props => props.theme.palette[props.status].dark};
    stroke: ${props => props.theme.palette[props.status].dark};
    fill: ${props => (props.status === UserStatuses.OFFLINE ? props.theme.palette[props.status].dark : '')};
  }
`

const StatusIcon = ({ size, status }: RingProps) => {
  const getStatusIcon = (status: UserStatuses) => {
    switch (status) {
      case UserStatuses.OFFLINE:
        return <QuestionIcon size={size * 0.2} />
      case UserStatuses.ONLINE:
        return <CheckmarkIcon size={size * 0.17} />
      case UserStatuses.BUSY:
        return <XmarkIcon size={size * 0.21} />
    }
  }

  return (
    <IconContainer size={size} status={status} data-testid={`${status}-icon`}>
      {getStatusIcon(status)}
    </IconContainer>
  )
}

export default function RdnaAvatar({
  imageUrl,
  size = 24,
  name,
  status,
  showStatusIcon = false,
  tooltipText,
  color = name ? stringToColor(name) : Colors.MIDNIGHT
}: AvatarProps) {
  const [loaded, setLoaded] = useState(true)

  const handleImageLoaded = () => {
    setLoaded(true)
  }

  const handleImageErrored = () => {
    setLoaded(false)
  }

  return (
    <AvatarContainer data-testid="ringdna-avatar">
      {loaded && imageUrl ? (
        <Image src={imageUrl} size={size} alt="user avatar" onLoad={handleImageLoaded} onError={handleImageErrored} />
      ) : (
        <Fallback $bgColor={color} size={size}>
          <RdnaTooltip title={tooltipText || name || ''}>
            <RdnaText variant="meta" color="contrast">
              {getInitials(name)}
            </RdnaText>
          </RdnaTooltip>
        </Fallback>
      )}
      {status && (
        <Status size={size} status={status}>
          {showStatusIcon && <StatusIcon size={size} status={status} />}
        </Status>
      )}
    </AvatarContainer>
  )
}
