BackgridCell      = require('helpers/backgrid-cell')
BaseGrid          = require('base/grid')
BaseView          = require('base/view')
MemberCollection  = require('../collection')
MemberModel       = require('../model')
PagedCollection   = require('base/paged-collection')
PagerView         = require('widgets/pager/view')

class AddCallQueueMembersModal extends BaseView

  template: require('./template')
  className: 'add-call-queue-members'

  regions:
    users : 'div.users'
    pager : 'span.pager'

  ui:
    username  : 'input.user-name'
    search    : 'button.search'

  events:
    'click button.save': 'save'
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  initialize: (options) ->
    @accountId = options.accountId
    @queueId = options.queueId
    @type = options.type
    @selectedMembers = new MemberCollection([], queueId: @queueId, type: @type)

  onRender: =>
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  buildUsersGrid: (collection) ->
    cols = [
      {label: 'Add',      name: 'add',          cell: BackgridCell.checkbox(),  editable: true, sortable: false}
      {label: 'Name',     name: 'displayName',  cell: 'string'}
      {label: 'Email',    name: 'email',        cell: 'string'}
      {label: 'ID',       name: 'id',           cell: 'string'}
    ]

    new BaseGrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No users found.',
      onEdit     : @onEdit
    )

  save: =>
    if @selectedMembers?.length
      @trigger('save', @selectedMembers)

  search: =>
    return unless @validate()

    @collection = new PagedCollection(
      queryParams:
        name         : @ui.username.val()
        accountId    : @accountId
      url: "callQueues/#{@queueId}/members/available"
    )

    @detachChildView('users')
    @showChildView('users', @buildUsersGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, disableDownload: true))
    @collection.fetch(reset: true).done(=>
      model.set('add', true) for model in @collection.models when @selectedMembers.where({id: model.get('id')}).length
    )

  validate: =>
    if @ui.username.val() and @ui.username.val().length < 3
      toastr.warning('User name must be at least three characters.')
      return false
    true

  onEdit: (options) =>
    return unless @selectedMembers
    {model} = options
    if model.get('add')
      @selectedMembers.add(new MemberModel(_.extend(model.attributes, queueId: @queueId)))
    else
      @selectedMembers.remove(@selectedMembers.where({id: model.get('id')}))

module.exports = AddCallQueueMembersModal