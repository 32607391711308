import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { FormikValues } from './index'

type OnChangeEffectProps = {
  onChange: (values: FormikValues, dirty: boolean) => void
}

export const OnChangeEffect = ({ onChange }: OnChangeEffectProps) => {
  const { values, dirty } = useFormikContext()

  useEffect(() => {
    onChange(values as FormikValues, dirty)
  }, [values, onChange, dirty])

  return null
}
