BaseView              = require('base/view')
SmartNumberRowView    = require('./smart-number-row-view')

class SmartNumberEmptyView extends BaseView
  tagName    :  'span'
  className  :  'not-found'
  template   :  _.template('No smart numbers found.')

class SmartNumberBodyView extends Marionette.CollectionView
  tagName    :   'tbody'
  childView  :   SmartNumberRowView
  emptyView  :   SmartNumberEmptyView

class SmartNumberTableView extends BaseView
  tagName: 'table'
  template: require('./smart-number-table-template')
  className: 'table table-stripe'
  childView: SmartNumberRowView

  regions:
    body:
      el: 'tbody'
      replaceElement: true

  computeds:
    isAdmin:
      get: -> App.session.user.isAdmin()

  bindings:
    '.is-deleted' : "classes:{'hidden':isAdmin}"

  onRender: ->
    @showChildView('body', new SmartNumberBodyView(collection: @collection))

module.exports = SmartNumberTableView
