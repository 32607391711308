import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import React, { useState } from 'react'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { createSimpleOptions } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
import { useUser } from '../../../common/services/UserContext'
import styled from 'styled-components'

interface Props {
  onUpdate: (value: string | null) => void
  agreementCheck: boolean
  disabled: boolean
}

export default function SelectDurationDropdown({ onUpdate, agreementCheck, disabled }: Props) {
  const [value, setValue] = useState('')

  const timeVariants = createSimpleOptions(['1 day', '3 days', '1 week', '1 month'])

  return (
    <>
      <RdnaSelect
        name={'select-duration'}
        style={{ width: 300 }}
        label={'Duration'}
        placeholder={'Select'}
        showPlaceholderAsOption={false}
        value={value}
        options={timeVariants}
        onChange={({ target }) => setValue(target.value)}
        disabled={!agreementCheck || disabled}
      />
      <AllowAccess
        text={'Allow Access'}
        color={'primary'}
        disabled={!agreementCheck || value === '' || disabled}
        onClick={() => onUpdate(value)}
      />
    </>
  )
}

const AllowAccess = styled(RdnaButton)`
  white-space: nowrap;
`
