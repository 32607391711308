import React from 'react'
import styled from 'styled-components'

import { SelectProps } from '@mui/material/Select/Select'
import { SelectChangeEvent } from '@mui/material'
import { uniqueId } from 'lodash-es'
import { Colors } from '../../../constants/colors'

import { Box, Checkbox, FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material'
import CaretIcon from '../../../assets/icons/svg/CaretFilled'

import RdnaChip from '../../RdnaChip'
import RdnaText from '../../RdnaText'
import RdnaLoader from '../../RdnaLoader'

export type RdnaSelectChangeEvent<T> = SelectChangeEvent<T>
export type RdnaSelectOptions<Value> = { label: React.ReactNode; value: Value; groupId?: string; disabled?: boolean }

export type RdnaSelectProps<Value, IsMulti extends boolean = false> = {
  options: RdnaSelectOptions<Value>[]
  label?: React.ReactNode
  helperText?: string
  placeholderProps?: React.ComponentProps<typeof MenuItem>
  showPlaceholderAsOption?: boolean
  numOfRows?: number
  displayChips?: boolean
  multipleLimit?: number
  autoListItemHeight?: boolean
  customRenderValue?: (value: RdnaSelectProps<Value, IsMulti>['value']) => React.ReactNode
  AfterOptionsElement?: React.ReactNode
  onDeleteChip?: (value: any) => void
  isLoading?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
} & Omit<SelectProps<IsMulti extends true ? Value[] : Value>, 'variant'>

const THEME_SPACING = 6

export default function RdnaSelect<Value, IsMulti extends boolean = false>({
  options,
  helperText,
  label,
  placeholderProps,
  numOfRows = 4,
  displayChips = true,
  multipleLimit,
  // SelectProps
  required, // overriding default 'required' prop to only add the asterisk. validate required in parent component
  value,
  name,
  labelId,
  error,
  placeholder,
  showPlaceholderAsOption = true,
  AfterOptionsElement,
  onDeleteChip,
  multiple,
  inputProps,
  MenuProps,
  customRenderValue,
  autoListItemHeight,
  variant = 'outlined',
  isLoading = false,
  ...restOfSelectProps
}: RdnaSelectProps<Value, IsMulti>): JSX.Element {
  if (multiple && !Array.isArray(value)) {
    // @ts-ignore - we do an array check before this
    value = value ? (value as string).split(',') : []
  }
  const LIST_ITEM_HEIGHT = (multiple ? 8 : 7) * THEME_SPACING

  const _uniqueId = uniqueId('')
  const _selectId = name + '-select_' + _uniqueId
  const _inputId = name + '-input_' + _uniqueId

  const renderValue = (value: RdnaSelectProps<Value, IsMulti>['value']) => {
    return customRenderValue && value
      ? customRenderValue(value)
      : options?.find(option => option.value === value)?.label || <RdnaText color="neutral">{placeholder}</RdnaText>
  }

  const isGrouped: boolean = options.some(option => !!option?.groupId)

  const renderOptions = (selectOptions: RdnaSelectProps<Value>['options']) => {
    function parsedSelectedOptions() {
      if (multiple && options.length > 10 && selectOptions) {
        // @ts-ignore
        const selectedOptions = selectOptions.filter(a => (value as string[]).toString().indexOf(a.value) > -1)
        // @ts-ignore
        const unselectedOptions = selectOptions.filter(a => (value as string[]).toString().indexOf(a.value) <= -1)

        return [...selectedOptions, ...unselectedOptions]
      } else {
        return selectOptions
      }
    }

    return parsedSelectedOptions().map(({ value: optionValue, label, disabled }, index) => {
      const isMultiLimitReached = multiple && multipleLimit !== undefined && (value as string[]).length >= multipleLimit
      // @ts-ignore
      const isChecked = multiple && (value as string[]).indexOf(optionValue) > -1
      const isDisabled = disabled || (isMultiLimitReached && !isChecked)

      return (
        // @ts-ignore
        <MenuItem
          key={`${optionValue}-${index}`}
          data-testid={`option-${optionValue}`}
          data-analyticsid={`option-${optionValue}`}
          style={{ height: autoListItemHeight ? 'auto' : LIST_ITEM_HEIGHT }}
          disabled={isDisabled}
          value={optionValue}
        >
          {multiple ? <Checkbox color="primary" checked={isChecked} disabled={isDisabled} /> : null}
          {label}
        </MenuItem>
      )
    })
  }

  const renderGroupedOptions = () => {
    const optionsByGroupId = options.reduce<{ [key: string]: RdnaSelectProps<Value>['options'] }>((map, option) => {
      if (!option?.groupId) {
        console.error('either have all groupIds or none at all')
        return map
      }

      if (!map[option.groupId]) {
        map[option.groupId] = [option]
      } else {
        map[option.groupId].push(option)
      }

      return map
    }, {})

    return Object.keys(optionsByGroupId)
      .sort()
      .map(groupId => [
        <ListSubheader key={groupId} style={{ pointerEvents: 'none' }} disableSticky>
          {groupId}
        </ListSubheader>,
        renderOptions(optionsByGroupId[groupId])
      ])
  }

  return (
    <FormControl variant={variant} error={error}>
      <InputLabel required={required} htmlFor={_inputId} id={labelId} shrink={true}>
        {label}
      </InputLabel>
      {/* @ts-ignore  - StyledComponents doesnt support passed generics */}
      <StyledSelect<Value>
        data-analyticsid={`${name}-input`}
        data-testid={`${name}-input`}
        id={_selectId}
        name={`${name}-select`}
        labelId={labelId}
        multiple={multiple}
        margin={'dense'}
        displayEmpty
        variant={variant}
        value={value}
        renderValue={renderValue}
        IconComponent={isLoading ? Loader : DropdownArrow}
        inputProps={{ name, id: _inputId, ...inputProps }}
        // @ts-ignore
        SelectDisplayProps={{ 'data-testid': `${name}-select-trigger` }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          PaperProps: {
            style: {
              maxHeight: LIST_ITEM_HEIGHT * numOfRows + THEME_SPACING * 2, // limit height of dropdown to 4 rows
              marginTop: THEME_SPACING
            },
            className: `${options.length > numOfRows ? 'styled-scrollbar' : ''} ${multiple ? 'multi-select' : ''}`
          },
          PopoverClasses: {
            root: `${name}-popover`
          },
          ...MenuProps
        }}
        {...restOfSelectProps}
      >
        {placeholder && showPlaceholderAsOption && (
          <MenuItem value="" disabled {...placeholderProps}>
            {placeholder}
          </MenuItem>
        )}
        {isGrouped ? renderGroupedOptions() : renderOptions(options)}
        {AfterOptionsElement}
      </StyledSelect>
      <FormHelperText>{helperText}</FormHelperText>
      <ChipContainer className={'chip-container'}>
        {multiple &&
          displayChips &&
          options.length > 0 &&
          (value as string[]).map(value => {
            const chipLabel = options?.find(o => o.value === value)?.label || value
            return (
              <Box key={value} mr={1} mt={1} display="inline-block" position="relative">
                <RdnaChip
                  label={chipLabel}
                  onDelete={
                    onDeleteChip
                      ? () => {
                          onDeleteChip && onDeleteChip(value)
                        }
                      : undefined
                  }
                />
              </Box>
            )
          })}
      </ChipContainer>
    </FormControl>
  )
}

const ChipContainer = styled.div`
  flex-direction: row;
`

const StyledSelect = styled(Select)`
  &.Mui-focused {
    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
  &.Mui-error {
    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.alert.dark};
    }
  }
`

export const MenuItemLabel = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: min-content min-content;
  grid-gap: 0 ${({ theme }) => theme.spacing * 1.5}px;
`

export const ARROW_SIZE = 14
export const DropdownArrow = ({ className }: { className?: string }) => {
  return (
    <IconHolder className={className}>
      <DropdownArrowIcon />
    </IconHolder>
  )
}

export const DropdownArrowIcon = () => {
  return <CaretIcon color={Colors.N80} size={ARROW_SIZE} />
}

const Loader = ({ ...props }: { props: any }) => {
  return (
    <IconHolder {...props}>
      <RdnaLoader data loading size={15}>
        {null}
      </RdnaLoader>
    </IconHolder>
  )
}

const IconHolder = styled.div`
  position: absolute;
  height: ${ARROW_SIZE}px;
  right: ${ARROW_SIZE * 1.5}px !important;
  top: ${({ theme }) => theme.spacing * 2}px;
  align-items: center;
  display: flex;
`
