import React from 'react'
import styled from 'styled-components'
import RdnaText, { colorpalettes } from '../RdnaText'
import { Dialog, IconButton } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import RdnaButton from '../RdnaButton'
import CloseIcon from '../../assets/icons/svg/Close'

type BorderOptions = 'colored'

const Heading = styled(RdnaText)`
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.theme.spacing * 3}px;
`
const Icon = styled.div`
  margin-right: ${props => props.theme.spacing * 2}px;
`

const ModalHeader = styled.div<{ $subHeading: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.spacing * (props.$subHeading ? 3 : 2)}px;
`

const ModalContainer = styled.div<{ $minWidth: number }>`
  flex: 1;
  display: flex;
  flex-flow: column;
  min-width: ${({ $minWidth }) => `${$minWidth}px`};
  max-height: -webkit-fill-available;
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: ${({ theme }) => theme.spacing * 2}px;
  right: ${({ theme }) => theme.spacing * 2}px;
`

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;
  button {
    margin-right: ${({ theme }) => theme.spacing * 2}px;
  }
  button:last-child {
    margin-right: 0;
  }
`

export interface RdnaModalProps extends DialogProps {
  /** Controls whether modal is shown or closed. */
  open: boolean
  /** Content to go within the modal, can be anything. */
  children: React.ReactNode
  /** Heading text of modal. We should try to use this for the heading at all times
   *  to remain consistent vs having heading in actual content  */
  heading?: string | React.ReactNode
  /** Subheader for modal */
  subHeading?: string | React.ReactNode
  /** Icon to go at the beginning of the header */
  headingIcon?: React.ReactNode
  /** Event fired when user clicks on back cover of modal so parent can always control state of open prop */
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  /** Event fired when user clicks on the secondary cancel button. */
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void
  /** Event fired when user clicks on primary confirm button
   * * @default The required onClose method.
   * */
  onConfirm?: (event: React.MouseEvent<HTMLElement>) => void
  /** If onConfirm event is an async function
   * * @default false
   * */
  confirmAsync?: boolean
  /** If onConfirm event is async reset button when complete
   * * @default false
   * */
  confirmResetPending?: boolean
  /** Should confirm button be disabled
   * * @default false
   * */
  confirmButtonDisabled?: boolean
  /** Text for primary button
   * * @default Ok
   * */
  confirmButtonText?: string
  confirmButtonColor?: keyof typeof colorpalettes
  /** Text for secondary button
   * * @default Cancel
   * */
  cancelButtonText?: string
  /** Min Width of the content */
  minWidth?: number
  /** Max Width the content will be. This is inherited from Material-UI
   * xs = 444, sm = 600, md = 960, lg = 1280, xl = 1920
   * */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  padding?: number
  border?: BorderOptions
  /** If hideDefaultButtons is passed do not display default buttons
   * * @default false
   * */
  hideDefaultButtons?: boolean
  /** If continuePropagation is passed do correct working click on back cover of modal
   * * @default false
   * */
  continuePropagation?: boolean
  /** If confirmButtonProcessing is passed true display confirm button is processing
   * * @default false
   * */
  confirmButtonProcessing?: boolean
}

const RdnaModal = ({
  children,
  open,
  heading,
  subHeading,
  headingIcon,
  maxWidth,
  minWidth = 0,
  onClose,
  onCancel,
  onConfirm,
  confirmAsync = false,
  confirmResetPending = false,
  confirmButtonDisabled = false,
  confirmButtonText = 'Ok',
  confirmButtonColor = 'primary',
  cancelButtonText = 'Cancel',
  hideDefaultButtons = false,
  continuePropagation = false,
  confirmButtonProcessing = false,
  ...modalProps
}: RdnaModalProps) => {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      scroll="body"
      // @ts-ignore
      BackdropProps={{ 'data-testid': 'modal.backdrop' }}
      {...(!continuePropagation ? { onClick: event => event.stopPropagation() } : {})}
      {...modalProps}
    >
      {onClose ? (
        <CloseButton data-testid="modal.close" onClick={onClose}>
          <CloseIcon title={'Close Modal'} />
        </CloseButton>
      ) : null}
      <ModalHeader $subHeading={subHeading}>
        {heading && (
          <Heading variant="h4">
            {headingIcon && <Icon>{headingIcon}</Icon>}
            {heading}
          </Heading>
        )}
        {subHeading && <RdnaText variant="subtitle2">{subHeading}</RdnaText>}
      </ModalHeader>
      <ModalContainer $minWidth={minWidth}>
        {children}
        {!hideDefaultButtons && (onConfirm || onCancel || onClose) && (
          <ModalButtons>
            {onCancel || onClose ? (
              <RdnaButton
                text={cancelButtonText}
                color="neutral"
                variant="text"
                onClick={onCancel || onClose}
                data-testid="ui-dialog.secondary-btn"
              />
            ) : null}
            {onConfirm && (
              <RdnaButton
                text={confirmButtonText}
                disabled={confirmButtonDisabled}
                onClick={onConfirm}
                resetPending={confirmResetPending}
                asyncButton={confirmAsync}
                color={confirmButtonColor}
                data-testid="ui-dialog.primary-btn"
                processing={confirmButtonProcessing}
              />
            )}
          </ModalButtons>
        )}
      </ModalContainer>
    </Dialog>
  )
}

export default RdnaModal
