import * as React from 'react'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const ratio = 159.5 / 228.7

function UnderBlocks({ size = 399 }: { size?: number }) {
  const themeContext = useContext(ThemeContext)
  return (
    <svg width={size} height={size * ratio} id="prefix__Layer_1" data-name="Layer 1" viewBox="0 0 300 200">
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
            d="M61.17 169.71c.3-.31-.75-3.15-2.55-5a8 8 0 00-1.48-1.23 1.32 1.32 0 00-.53-.79c-.56-.47-2.1-2-2.1-2a4.6 4.6 0 00-1.94 1.3c-5 4.73-16.7 16.89-18.65 19.47-3.35 4.43-1.77-4.92-1.8-4.22 0 .51 1 5.87 1.93 6.24s2.37 2.36 4.17.87c2.15-1.75 5.75-1.32 9.09-2s4.82-1 5.32-2.28.93-3.9 2.85-6.53c2.64-3.65 5.04-3.16 5.69-3.83z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
            d="M23 169.41c.15-.42-2-2.56-4.42-3.52a8.24 8.24 0 00-1.86-.5 1.3 1.3 0 00-.81-.49c-.7-.2-2.73-.92-2.73-.92a4.5 4.5 0 00-1.23 2c-2.53 6.38-8.12 22.32-8.81 25.48-1.19 5.42-1.26 5.89-1 6.54a3.34 3.34 0 002.53 1c1 0 2.46-1.55 3.48-3.65a30.69 30.69 0 017.51-8.7c2.74-2 3.94-2.95 3.88-4.3s-.78-3.93-.14-7.11c.89-4.41 3.27-4.96 3.6-5.83z"
          />
        </clipPath>
        <style>{'..prefix__cls-9{fill:#806ddb}'}</style>
      </defs>
      <ellipse cx={235.78} cy={193.16} rx={62.22} ry={3.84} fill="#ccc" />
      <rect
        fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
        x={173.09}
        y={127.25}
        width={129.89}
        height={36.05}
        rx={18.03}
        transform="rotate(39.87 238.017 145.249)"
      />
      <rect
        fill={themeContext?.palette?.illustrations[60] || '#5E3ABB'}
        x={201.14}
        y={67.34}
        width={94.65}
        height={36.05}
        rx={18.03}
        transform="rotate(3.78 248.7 85.384)"
      />
      <rect
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        x={249.26}
        y={104.72}
        width={36.05}
        height={36.05}
        rx={18.03}
      />
      <rect
        fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
        x={214.42}
        y={30.15}
        width={36.05}
        height={36.05}
        rx={18.03}
      />
      <path
        fill="#d9ad8f"
        d="M169 121.4s8.31-2.62 10.84-3.69a16.25 16.25 0 015.59-1.5l-4.15-9.53s-3.54 3.48-6 4.47c-2.19.88-9.57 2.11-9.57 2.11"
      />
      <path
        fill="#d9ad8f"
        d="M184.41 116.27h.25l.59.11c.51.11 1 .24 1.52.38a29.21 29.21 0 012.88.92 1.13 1.13 0 00.87-2.06l-.39-.23-.37-.24-.23-.16-.12-.09h.07l-.46-.4c-.15-.14-.27-.33-.43-.47.06 0 .15.21 0 .06l-.09-.13-.14-.26c-.07-.14.05.08.05.11a.78.78 0 00-.05-.14v-.14.08a1.14 1.14 0 00-1.13-1.13 1.16 1.16 0 00-1.13 1.13 4.06 4.06 0 001.65 2.8 10.38 10.38 0 001.6 1.11l.87-2.07c-1-.37-2-.67-2.95-.94a11.92 11.92 0 00-2.89-.56 1.14 1.14 0 00-1.13 1.13 1.16 1.16 0 001.13 1.13z"
      />
      <path
        fill="#d9ad8f"
        d="M184.41 116.27h.25l.59.11c.51.11 1 .24 1.52.38a29.21 29.21 0 012.88.92 1.13 1.13 0 00.87-2.06l-.39-.23-.37-.24-.23-.16-.12-.09h.07l-.46-.4c-.15-.14-.27-.33-.43-.47.06 0 .15.21 0 .06l-.09-.13-.14-.26c-.07-.14.05.08.05.11a.78.78 0 00-.05-.14v-.14.08a1.14 1.14 0 00-1.13-1.13 1.16 1.16 0 00-1.13 1.13 4.06 4.06 0 001.65 2.8 10.38 10.38 0 001.6 1.11l.87-2.07c-1-.37-2-.67-2.95-.94a11.92 11.92 0 00-2.89-.56 1.14 1.14 0 00-1.13 1.13 1.16 1.16 0 001.13 1.13zM188.53 112.28c.51-.38 1-.78 1.5-1.17l.3-.24c.2-.16-.21.16 0 0l.17-.13c.2-.15.41-.3.63-.44a11.22 11.22 0 011.51-.85l.25-.11s.31-.14.08 0l.46-.18c.43-.17.86-.31 1.3-.45a1.13 1.13 0 00-.6-2.18 16.42 16.42 0 00-4.08 1.82c-.51.33-1 .7-1.47 1.08l-.61.49-.36.28s-.24.2 0 0l-.2.14a1.16 1.16 0 00-.4 1.55 1.14 1.14 0 001.54.4z"
      />
      <path
        fill="#d9ad8f"
        d="M186.29 111.11a37.47 37.47 0 013.6-2.92 17.06 17.06 0 014.89-2.19 1.13 1.13 0 00-.6-2.18 19 19 0 00-5.43 2.42 41.15 41.15 0 00-4.05 3.28 1.12 1.12 0 001.59 1.59z"
      />
      <path
        fill="#d9ad8f"
        d="M184.88 109a35.3 35.3 0 004-2.34c.73-.46 1.47-.91 2.22-1.34.35-.19.7-.38 1.06-.56l.48-.23c-.12.06-.14.06 0 0l.25-.09a1.17 1.17 0 00.79-1.39 1.13 1.13 0 00-1.39-.79c-3.09 1.09-5.55 3.43-8.51 4.78a1.13 1.13 0 101.14 2z"
      />
      <path
        fill="#d9ad8f"
        d="M183 108.25l.37-.29.17-.14c.06 0 .06 0 0 0l.09-.07.81-.6c.64-.45 1.29-.89 2-1.3a21.72 21.72 0 014.12-2.06 1.17 1.17 0 00.79-1.39 1.14 1.14 0 00-1.39-.79 29.33 29.33 0 00-8.5 5 1.13 1.13 0 101.59 1.6z"
      />
      <path
        fill="#d9ad8f"
        d="M184.13 104.59l-2.4 1.74a5.41 5.41 0 00-2.27 2.46A5.66 5.66 0 00180 113c.36.82.71 1.64 1 2.47a1.17 1.17 0 001.39.79c.94-.27 2.16.23 3.18.2a3.24 3.24 0 002.4-1.16 1.61 1.61 0 00.41-1.63c-.06-.19 0 .19 0 0a1.64 1.64 0 000-.22v-.35a2 2 0 01.52-1.12 1.14 1.14 0 00-.79-1.93 2.16 2.16 0 00-1.58.92c-.18.21-.36.43-.55.63l-.25.26c-.11.12-.06 0 0 0s.08-.06 0 0 0 .05.4-.1c.28 0 .22.17.17 0s-.09-.22-.14-.39a4.22 4.22 0 00-.52-1.19 3 3 0 00-1-.87l-.4-.23a.4.4 0 01.1.32l-2.11.57a3.88 3.88 0 01.38.69 3 3 0 01.12.4v.16c0 .24.06.22.05-.07l.52-.67a.32.32 0 01.56.15 1.15 1.15 0 00-1.38-.79 1.13 1.13 0 00-.79 1.39 2.11 2.11 0 001.16 1.27 1.64 1.64 0 001.82-.25c.86-.86 0-2.59-.53-3.42a1.15 1.15 0 00-1.27-.52 1.13 1.13 0 00-.83 1.09 2 2 0 00.69 1.38c.29.24.79.46 1 .62s.25.58.36.88a2.79 2.79 0 001 1.45 1.76 1.76 0 002.39-.4c.24-.24.45-.51.68-.77l.3-.3c.12-.13.09 0 0 0 .19-.08-.31.08-.32.08l-.8-1.93a4.27 4.27 0 00-1 4.11l.12-.87-.09.15c-.11.14.14-.15 0 0l-.13.15c-.05.06-.22.18-.07.08a1.48 1.48 0 01-.27.16c.14-.06-.07 0-.12 0a1 1 0 01-.24 0c-1.25 0-2.46-.48-3.7-.12l1.39.79c-.31-.77-.63-1.53-1-2.29s-1-2-.67-3c.22-.63.94-1 1.49-1.41l2.19-1.58a1.16 1.16 0 00.41-1.55 1.15 1.15 0 00-1.55-.4z"
      />
      <path
        fill="#d9ad8f"
        d="M187.1 112.82c1-2 1.56-4.13.18-5.68-.07-.08-1-2-1.09-2.06a6.17 6.17 0 00-1.18.47 17.65 17.65 0 00-3.51 2.32c-.35.42-.16 1.06-.32 1.58-.44 1.4 1.09 2.61 1.92 3.82a.9.9 0 00.39.36c.22.11.21 1 .45.91.86-.26 3.24.76 2.43-2.45-.12-.47.51-.83.71-1.28s2.45-.9 2-2c-.16-.4-2.54.32-3 .27a2.15 2.15 0 01-1.23-.34c-.14 0-.18.21-.18.35v5.65a14.66 14.66 0 011.48-4.6 17.2 17.2 0 00-2.27 0 1.94 1.94 0 001.83.65"
      />
      <path
        fill="#323232"
        d="M187.86 113.52h-.06a.22.22 0 01-.16-.28 3.41 3.41 0 011.47-1.79.23.23 0 01.31.09.21.21 0 01-.09.31 3 3 0 00-1.25 1.52.22.22 0 01-.22.15zM189.11 109.05a.23.23 0 01-.12-.43l.54-.32a23.45 23.45 0 014.68-2.31.22.22 0 01.28.15.23.23 0 01-.15.29 23 23 0 00-4.58 2.27l-.54.32a.2.2 0 01-.11.03zM187.46 107.27a.23.23 0 01-.12-.42 61.25 61.25 0 016-3.21.23.23 0 01.3.12.23.23 0 01-.11.3 57.19 57.19 0 00-6 3.18.24.24 0 01-.07.03zM186.09 105.51a.24.24 0 01-.2-.11.25.25 0 01.08-.32c.19-.1.4-.23.63-.37a27.77 27.77 0 014.4-2.29.22.22 0 01.29.13.25.25 0 01-.13.3 29.13 29.13 0 00-4.33 2.25l-.62.38a.27.27 0 01-.12.03z"
      />
      <path
        fill="#d9ad8f"
        d="M122.4 178c.62 3.35 4.73 13 6 12.93s6.89-.17 8 .27-1.88 7-1.88 7-7.86.31-9.41-.37a6.53 6.53 0 01-2.19-1.75c-.89-.95-1.06-1.3-2.58-4.26-1.34-2.52-6.34-12.55-6.34-12.55"
      />
      <path
        fill="#d9ad8f"
        d="M129.42 192.76a17.43 17.43 0 012.37-.58h.96c.11 0 .08 0-.1-.05a.91.91 0 00.17.09h.05c.1.07.07.05-.07-.06 0 .08.24.22.3.28l.26.25a2.6 2.6 0 00.92.61 1.13 1.13 0 001.39-.79 1.16 1.16 0 00-.79-1.39c.35.14.2.09.12 0 .3.2.11.09 0 0l-.29-.29a5.53 5.53 0 00-.79-.66 3.74 3.74 0 00-2.6-.17 18.07 18.07 0 00-2.54.61 1.13 1.13 0 00.6 2.18z"
      />
      <path
        fill="#d9ad8f"
        d="M135.13 193.23a36.91 36.91 0 014.58.39c1.19.13 2.39.27 3.58.42a1.14 1.14 0 001.13-1.13 1.16 1.16 0 00-1.13-1.13l-3.58-.42a35.07 35.07 0 00-4.58-.38 1.13 1.13 0 100 2.25z"
      />
      <path
        fill="#d9ad8f"
        d="M136.64 195.11a43.92 43.92 0 018.26.5 1.17 1.17 0 001.39-.79 1.14 1.14 0 00-.79-1.39 46.91 46.91 0 00-8.86-.58 1.14 1.14 0 00-1.13 1.13 1.16 1.16 0 001.13 1.13z"
      />
      <path
        fill="#d9ad8f"
        d="M135.48 196.1H135.9c.13 0-.25-.05 0 0s.51.09.76.15c.68.14 1.36.31 2 .46a29.36 29.36 0 005.36.78 1.13 1.13 0 100-2.25 27.32 27.32 0 01-5-.76 15.15 15.15 0 00-3.53-.65 1.13 1.13 0 000 2.26z"
      />
      <path
        fill="#d9ad8f"
        d="M131.41 197.89c.39 0 .79.06 1.18.1h.51l.7.1c.92.15 1.84.32 2.76.5s2.13.43 3.19.68a1.16 1.16 0 001.39-.79 1.14 1.14 0 00-.79-1.39c-1.09-.26-2.19-.48-3.29-.7a41 41 0 00-5.61-.82 1.13 1.13 0 100 2.26z"
      />
      <path
        fill="#323232"
        d="M136 191.3a31.18 31.18 0 00-5.68-.19h-1.93a.23.23 0 01-.21-.24.21.21 0 01.24-.21h1.89a31.41 31.41 0 015.78.2.22.22 0 01.18.26.22.22 0 01-.27.18zM144.17 193.38l-7.28-.6a.23.23 0 01-.21-.25.22.22 0 01.24-.2l7.28.6a.22.22 0 01.21.24.23.23 0 01-.24.21zM145 195.77h-.05c-.86-.18-2.35-.37-3.92-.57a41.06 41.06 0 01-5.17-.83.22.22 0 01-.14-.28.22.22 0 01.28-.15 41.05 41.05 0 005.08.81c1.59.2 3.08.39 4 .58a.22.22 0 01.17.27.23.23 0 01-.25.17zM141 197.52h-.09a19.68 19.68 0 00-3.95-.8 18.47 18.47 0 01-2.82-.5.24.24 0 01-.15-.29.23.23 0 01.29-.14 19.48 19.48 0 002.74.49 19.31 19.31 0 014.06.82.23.23 0 01.13.29.23.23 0 01-.21.13z"
      />
      <path
        fill="#d9ad8f"
        d="M79.53 175.46c-4.69-1.39-17.53-2.78-19.9-6.72-1.17-2-9.74 11.46-9.74 11.46a31.92 31.92 0 0127.45 3.49"
      />
      <path
        fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
        d="M61.17 169.71c.3-.31-.75-3.15-2.55-5a8 8 0 00-1.48-1.23 1.32 1.32 0 00-.53-.79c-.56-.47-2.1-2-2.1-2a4.6 4.6 0 00-1.94 1.3c-5 4.73-16.7 16.89-18.65 19.47-3.35 4.43-1.77-4.92-1.8-4.22 0 .51 1 5.87 1.93 6.24s2.37 2.36 4.17.87c2.15-1.75 5.75-1.32 9.09-2s4.82-1 5.32-2.28.93-3.9 2.85-6.53c2.64-3.65 5.04-3.16 5.69-3.83z"
      />
      <g clipPath="url(#prefix__clip-path)">
        <path
          fill={themeContext?.palette?.illustrations[20] || '#CBC0FF'}
          d="M50.51 179.66h-.06a.25.25 0 01-.21-.25.7.7 0 010-.14c0-.08.17-.35.36-.77a.26.26 0 01.36-.13.27.27 0 01.14.36c-.2.42-.33.69-.36.77a.31.31 0 01-.23.16zm.93-2a.2.2 0 01-.11 0 .26.26 0 01-.14-.35c.17-.38.35-.8.54-1.25a.29.29 0 01.36-.14.28.28 0 01.14.36l-.55 1.25a.26.26 0 01-.24.11zm1.06-2.5a.18.18 0 01-.1 0 .28.28 0 01-.15-.35l.13-.31c.13-.32.27-.64.42-1a.27.27 0 01.36-.13.27.27 0 01.13.36c-.15.3-.28.61-.41.93l-.12.31a.29.29 0 01-.26.17zm1.18-2.44a.27.27 0 01-.14 0 .26.26 0 01-.09-.37 11 11 0 01.8-1.14.28.28 0 01.38 0 .26.26 0 010 .38 10.85 10.85 0 00-.75 1.08.27.27 0 01-.2.03zm1.71-2.09a.27.27 0 01-.18-.47 9.27 9.27 0 011.1-.86.26.26 0 01.37.07.27.27 0 01-.07.38 8.27 8.27 0 00-1 .81.24.24 0 01-.22.05zm2.24-1.51a.26.26 0 01-.24-.16.27.27 0 01.12-.36 11.44 11.44 0 011.28-.54.26.26 0 01.34.16.27.27 0 01-.16.35 12.42 12.42 0 00-1.22.52.27.27 0 01-.12.01z"
        />
        <path
          fill={themeContext?.palette?.illustrations[20] || '#CBC0FF'}
          d="M37.39 184.69h-.14a.27.27 0 110-.54h.12a4.62 4.62 0 00.79-.07l.35-.08a.26.26 0 01.33.19.27.27 0 01-.19.33l-.4.09a4.64 4.64 0 01-.86.08zm2.48-.63a.28.28 0 01-.25-.16.28.28 0 01.14-.36c.39-.17.8-.36 1.22-.57a.26.26 0 01.36.12.27.27 0 01-.12.36c-.43.22-.84.42-1.24.59a.2.2 0 01-.11.02zm2.6-1.25l-.16-.22-.13-.24 1.21-.62a.28.28 0 01.25.49zm2.27-1.15a.28.28 0 01-.25-.16.27.27 0 01.14-.36l.28-.12 1-.39a.27.27 0 01.35.17.26.26 0 01-.16.34l-1 .38-.27.12a.22.22 0 01-.09.02zm2.54-.95a.26.26 0 01-.25-.18.27.27 0 01.17-.35l1.29-.43a.27.27 0 01.34.18.27.27 0 01-.17.34l-1.29.43zm2.59-.84a.27.27 0 01-.26-.19.26.26 0 01.17-.34l1.3-.42a.28.28 0 01.34.18.27.27 0 01-.18.34l-1.29.41zm2.58-.83a.28.28 0 01-.26-.19.28.28 0 01.18-.34c.65-.21 1.05-.35 1.26-.44a.27.27 0 01.36.14.28.28 0 01-.15.36c-.23.09-.64.24-1.3.46zM36.83 186.18a.29.29 0 01-.28-.28.27.27 0 01.27-.27 4.59 4.59 0 001.26-.2.28.28 0 01.34.19.27.27 0 01-.19.33 5.4 5.4 0 01-1.4.23zm-1.33-.28a.22.22 0 01-.11 0 1.66 1.66 0 01-.49-.28.27.27 0 010-.38.26.26 0 01.38 0 2 2 0 00.34.18.27.27 0 01-.1.52zm3.94-.37a.28.28 0 01-.26-.17.27.27 0 01.16-.35l1.25-.51a.27.27 0 11.21.5c-.43.18-.86.36-1.26.51a.2.2 0 01-.1.02z"
        />
        <path
          fill={themeContext?.palette?.illustrations[40] || '#916DEE'}
          d="M61.18 166.46l-2 .46a.62.62 0 00-.45.81l1.09 3.27 2.83-1zM33.33 189.42c-.73-.3-1.27-.22.05-1.95s7.27-8.94 8.94-10.22 8-7.92 10-9.85a28 28 0 015.43-4.28L53.89 159l-25.11 27.71 2.49 3.12z"
        />
      </g>
      <path
        fill={themeContext?.palette?.illustrations[40] || '#916DEE'}
        d="M49 182.14a.76.76 0 01-1-.15 1.22 1.22 0 01-.07-1.12"
      />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M48.64 182.5a1 1 0 01-.76-.34 1.43 1.43 0 01-.1-1.39.27.27 0 01.34-.14.27.27 0 01.13.34s-.23.55 0 .86a.51.51 0 00.63.09.26.26 0 01.35.09.25.25 0 01-.1.35 1 1 0 01-.49.14z"
      />
      <path
        fill={themeContext?.palette?.illustrations[40] || '#916DEE'}
        d="M46.81 182.82a.91.91 0 01-.7-.12 1 1 0 01-.39-.63"
      />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M46.59 183.1a1.31 1.31 0 01-.61-.17 1.22 1.22 0 01-.51-.82.26.26 0 01.21-.3.27.27 0 01.32.19.75.75 0 00.27.46.64.64 0 00.51.09.26.26 0 01.31.19.27.27 0 01-.19.31 1.27 1.27 0 01-.31.05z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M45 183.48a1.28 1.28 0 01-1.46-.67" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M44.71 183.77a1.47 1.47 0 01-1.37-.85.26.26 0 01.11-.34.27.27 0 01.35.11 1 1 0 001.17.53.27.27 0 01.31.2.26.26 0 01-.2.31 2.21 2.21 0 01-.37.04z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M43.22 184.21a2.2 2.2 0 01-1.6-.31" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M42.82 184.51a2.55 2.55 0 01-1.34-.39.26.26 0 01-.07-.36.24.24 0 01.35-.07 2 2 0 001.41.27.25.25 0 01.3.2.25.25 0 01-.2.3 2.3 2.3 0 01-.45.05z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M41.14 185a1.68 1.68 0 01-1.7-.22" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M40.48 185.36a1.94 1.94 0 01-1.2-.41.26.26 0 11.32-.4 1.4 1.4 0 001.43.18.26.26 0 01.34.13.26.26 0 01-.13.34 1.82 1.82 0 01-.76.16z"
      />
      <path
        fill="#d9ad8f"
        d="M40.55 172.85c-4.84.69-15.58-1.1-19.37-3.69-1.88-1.28-4.07 14.49-4.07 14.49s2.94-4.62 19.46 1.29"
      />
      <path
        fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
        d="M23 169.41c.15-.42-2-2.56-4.42-3.52a8.24 8.24 0 00-1.86-.5 1.3 1.3 0 00-.81-.49c-.7-.2-2.73-.92-2.73-.92a4.5 4.5 0 00-1.23 2c-2.53 6.38-8.12 22.32-8.81 25.48-1.19 5.42-1.26 5.89-1 6.54a3.34 3.34 0 002.53 1c1 0 2.46-1.55 3.48-3.65a30.69 30.69 0 017.51-8.7c2.74-2 3.94-2.95 3.88-4.3s-.78-3.93-.14-7.11c.89-4.41 3.27-4.96 3.6-5.83z"
      />
      <g clipPath="url(#prefix__clip-path-2)">
        <path
          fill={themeContext?.palette?.illustrations[20] || '#CBC0FF'}
          d="M17.34 182.93a.27.27 0 01-.27-.27v-.88a.27.27 0 01.27-.27.28.28 0 01.28.27v.88a.27.27 0 01-.28.27zm0-2.23a.27.27 0 01-.27-.27v-1.36a.28.28 0 01.27-.28.27.27 0 01.28.27v1.36a.27.27 0 01-.26.28zm-.08-2.7a.27.27 0 01-.27-.26v-.33-1.04a.27.27 0 01.27-.27.26.26 0 01.26.28v1.34a.26.26 0 01-.26.28zm.06-2.71a.26.26 0 01-.23-.3 9.77 9.77 0 01.25-1.37.27.27 0 11.52.14 10.18 10.18 0 00-.23 1.29.28.28 0 01-.31.22zm.67-2.61h-.1a.28.28 0 01-.15-.36 9.11 9.11 0 01.64-1.24.27.27 0 01.46.28 9.23 9.23 0 00-.6 1.17.26.26 0 01-.24.13zm1.41-2.31a.27.27 0 01-.22-.44 12.7 12.7 0 01.94-1 .28.28 0 01.38 0 .27.27 0 010 .39 10 10 0 00-.89 1 .32.32 0 01-.21.03z"
        />
        <path
          fill={themeContext?.palette?.illustrations[20] || '#CBC0FF'}
          d="M7.4 193a.27.27 0 01-.25-.17.28.28 0 01.14-.36 4 4 0 00.8-.44l.29-.22a.27.27 0 01.38 0 .28.28 0 010 .39l-.32.25a5.13 5.13 0 01-.9.5zm2.11-1.67a.26.26 0 01-.18-.07.27.27 0 010-.38c.28-.31.57-.66.86-1a.27.27 0 01.38 0 .26.26 0 010 .38c-.29.37-.59.73-.88 1.05a.25.25 0 01-.18.05zm1.71-2.1a.27.27 0 01-.17-.06.28.28 0 010-.38l.84-1.07a.27.27 0 11.42.34c-.28.35-.56.7-.83 1.06a.29.29 0 01-.26.14zm1.71-2.11a.26.26 0 01-.18-.07.27.27 0 010-.38l.2-.23c.21-.23.46-.49.75-.77a.28.28 0 01.38 0 .27.27 0 010 .39c-.28.27-.52.53-.73.75l-.2.22a.25.25 0 01-.22.12zm1.91-1.93a.27.27 0 01-.18-.47l1-.93a.28.28 0 01.39 0 .27.27 0 010 .38l-1 .93a.26.26 0 01-.21.12zm2-1.84a.25.25 0 01-.2-.09.27.27 0 010-.38l1-.92a.27.27 0 01.38 0 .26.26 0 010 .38l-1 .92a.31.31 0 01-.18.12zm2-1.84a.27.27 0 01-.2-.08.28.28 0 010-.39c.49-.45.81-.76 1-.92a.27.27 0 11.4.36c-.17.18-.48.49-1 1a.26.26 0 01-.2.06zM6.2 194.86a1.46 1.46 0 01-.45-.05.26.26 0 01-.18-.34.28.28 0 01.34-.18 1.11 1.11 0 00.29 0h.09a.25.25 0 01.28.26.26.26 0 01-.26.28zm1.42-.3a.27.27 0 01-.11-.52 5 5 0 001.06-.71.27.27 0 01.38 0 .28.28 0 010 .39 5.94 5.94 0 01-1.19.79zm2.11-1.68a.27.27 0 01-.19-.08.26.26 0 010-.38l.92-1a.26.26 0 01.38 0 .27.27 0 010 .38l-.94 1a.27.27 0 01-.17.08z"
        />
        <path
          fill={themeContext?.palette?.illustrations[40] || '#916DEE'}
          d="M21.63 166.44L20 167.72a.62.62 0 00-.07.92l2.41 2.52 2.17-2zM5.92 198.94c-.79 0-1.24.33-.77-1.79S8 186 9 184.12s3.94-10.52 5-13.12a28.63 28.63 0 013.15-6.16l-5.2-2.12-11.3 35.66 3.56 1.79z"
        />
      </g>
      <path
        fill={themeContext?.palette?.illustrations[50] || '#7549EA'}
        d="M17.15 185.76a.75.75 0 01-.93.27 1.19 1.19 0 01-.53-1"
      />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M16.51 186.35a.9.9 0 01-.4-.09 1.47 1.47 0 01-.68-1.22.26.26 0 01.25-.26.24.24 0 01.26.25s0 .59.39.77a.52.52 0 00.61-.18.25.25 0 01.36-.06.25.25 0 01.06.35 1.08 1.08 0 01-.85.44z"
      />
      <path
        fill={themeContext?.palette?.illustrations[40] || '#916DEE'}
        d="M15.41 187.31a.88.88 0 01-.68.19 1 1 0 01-.63-.42"
      />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M14.85 187.76h-.15a1.24 1.24 0 01-.81-.52.26.26 0 01.43-.29.69.69 0 00.43.3.65.65 0 00.5-.13.26.26 0 11.32.4 1.09 1.09 0 01-.72.24z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M14.06 188.65a1.26 1.26 0 01-1.6 0" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M13.23 189.2a1.51 1.51 0 01-.93-.34.26.26 0 11.32-.4 1 1 0 001.29 0 .25.25 0 11.3.41 1.68 1.68 0 01-.98.33z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M12.73 190.08a2.21 2.21 0 01-1.58.38" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M11.45 190.74a2 2 0 01-.34 0 .26.26 0 01-.22-.29.25.25 0 01.29-.21 2 2 0 001.4-.34.26.26 0 01.36.06.27.27 0 01-.06.36 2.52 2.52 0 01-1.43.42z"
      />
      <path fill={themeContext?.palette?.illustrations[40] || '#916DEE'} d="M11.15 191.63a1.66 1.66 0 01-1.63.51" />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M9.9 192.45a1.61 1.61 0 01-.44-.06.26.26 0 01-.19-.31.26.26 0 01.31-.18 1.41 1.41 0 001.38-.44.26.26 0 01.36 0 .25.25 0 010 .36 2 2 0 01-1.42.63zM92.45 128.8l-7.68-1.24-.11 15.05 7 33.54-28.35-6.83-2.87 13.78s0 .17 31.32 9c12.77 3.59 19.14-6.23 18.2-17-.57-6.62-3.26-35.87-3.26-35.87"
      />
      <path
        fill={themeContext?.palette?.illustrations[70] || '#462C8C'}
        d="M91.61 145.18s-8.38 31.2-10 37.64c-2.71 10.49-12.65 15.59-22.87 12.73-8.87-2.49-35.39-11.85-35.39-11.85l3.89-13.64 36.73 7.68 4.19-30.59a30.63 30.63 0 012.82-9.3l1.11-2.27a18.32 18.32 0 015.46-6.64l1.45-1.15"
      />
      <path
        fill={themeContext?.palette?.illustrations[60] || '#5E3ABB'}
        d="M115.94 119l-22.21 3.28a36.27 36.27 0 00-13.07 4.6l-1.62 1c-2.92 1.71-7.78 6.66-8.94 11.93l32.58 5.19s2.56 15.51 3.24 19.73 2.64 7.69 4.59 11.42l7 13.42 9.56-4.28-8.31-17.53-.64-22.65L133.05 130l14.22-1.45a99.63 99.63 0 0013.27-2.43c11.47-3.69 17.18-5.79 17.18-5.79l-2.83-10.81-22.74 6.48z"
      />
      <path fill="#d9ad8f" d="M112.36 126.67c2.11 3.72 7.53 5.8 11.55 2.76l.76-4.76-10.06-4.67z" />
      <path
        fill="#d9ad8f"
        d="M119.48 126.29l5.52 2c3.67.89 5.9-1.48 7.86-5 1.3-2.3 2.19-8.15 2.19-8.15 1.28-5.25-1.05-10.71-5.69-12.8a17.34 17.34 0 00-6.89-1.29c-5 .21-8.71 3.07-9.91 8a35.32 35.32 0 00-.79 5.44c.39 6.43 2.23 9.79 7.71 11.8z"
      />
      <path
        fill="#323232"
        d="M118.62 107.9c-.27.75-.53 1.5-.79 2.26 0 0-.19.48-.1.3s0-.08 0 0 0 .1 0 0 0 0-.14 0-.26.13-.38.21a4.38 4.38 0 00-1.52 1.65c-.26.47-.43 1-.72 1.44-.07.11-.26.24 0 .06a3.22 3.22 0 00-.32.32 3.37 3.37 0 00-.75 1 4.48 4.48 0 00-.35 1c-.06.31 0-.05.15-.15l.4-.25-.36.14a2.37 2.37 0 00-1.51 2.17 3.57 3.57 0 00.15 1 1.72 1.72 0 00.1.39c-.13-.12 0-.56.1-.67a1.78 1.78 0 003.07 1.8 3.07 3.07 0 00.19-2.34c0-.08-.13-.31-.09-.38v.23a1.15 1.15 0 01-.67 1.08c-.13.06-.1 0 .06 0l.43-.18a2.58 2.58 0 00.6-.44 2.9 2.9 0 00.68-1.13c.05-.14.09-.29.13-.43v-.15c.06-.17.07-.19 0-.07s-.23.25 0 0a4.75 4.75 0 00.4-.38 5.47 5.47 0 00.67-.91c.26-.46.44-1 .71-1.44.06-.1.31-.35.06-.12 0 0 .32-.27.16-.16s.22-.11.25-.13a4.83 4.83 0 00.45-.26c1.42-1 1.75-3 2.29-4.5a1.78 1.78 0 00-1.24-2.18 1.81 1.81 0 00-2.18 1.24z"
      />
      <path
        fill="#323232"
        d="M115.63 102.45c-3.59.71-4.75 4.52-5 7.7-.27 3.54-.1 7.21 1.58 10.41.68 1.31 2.66.15 2-1.16-1.36-2.58-1.47-5.56-1.32-8.41.12-2.38.51-5.77 3.36-6.33 1.44-.29.83-2.5-.61-2.21z"
      />
      <path
        fill="#323232"
        d="M136.12 108.87a4.5 4.5 0 00-.56-3.15 4 4 0 00-1-1.09 8.72 8.72 0 01-1.38-.92c-.37-.43-.57-1-.93-1.49a5.19 5.19 0 00-1.25-1.13 7.16 7.16 0 00-3.4-.87 17.1 17.1 0 01-3.51-1A8 8 0 00121 99a8.27 8.27 0 00-5.45 2.95c-.92 1.14.69 2.77 1.62 1.62a6.16 6.16 0 014-2.29c1.9-.26 3.37.76 5.16 1.08a16.8 16.8 0 012.93.4 3.34 3.34 0 011.75 1.72 4.73 4.73 0 002 1.8 2 2 0 011 2c-.22 1.44 2 2.06 2.21.61z"
      />
      <path
        fill="#323232"
        d="M132.47 107.66v.17c0-.12 0 .19 0 0-.07.1-.08.12 0 .07s-.18.17-.07.08a1.12 1.12 0 01-.37.21 5.58 5.58 0 01-.58.18 1.92 1.92 0 01-1.78-.36c.09.07.05 0 0 0s0 .09-.09-.11-.17-.32-.26-.48a2.93 2.93 0 00-3.83-1.39 1.15 1.15 0 00-.41 1.57 1.18 1.18 0 001.57.41c-.07 0-.26 0 0 0h.26a1.35 1.35 0 01.26.15c-.17-.12.14.16 0 0a1.76 1.76 0 01.14.23c.1.17.18.35.27.53a3.84 3.84 0 002.39 1.67c1.84.49 5.16-.6 4.88-3a1.18 1.18 0 00-1.15-1.15c-.56 0-1.22.51-1.14 1.15z"
      />
      <path
        fill="#323232"
        d="M131.37 107.32a1.4 1.4 0 01.17-.88c.13-.13.4-.08.49-.2h.08-.19c.15.06-.1-.07-.12-.08-.32-.21-.78-.68-1.21-1a6 6 0 00-3.55-1.22 7.61 7.61 0 00-3.48 1 2.51 2.51 0 01-.49.19s-.5.09-.26.07-.15 0-.15 0-.1 0 0 0-.35-.07-.14 0-.11-.13 0 0 .25.34.1 0a1.78 1.78 0 00-3.07 1.8 3.74 3.74 0 004.24 1.59 7.62 7.62 0 001.26-.48l.45-.21.3-.14a4.91 4.91 0 01.88-.26h.33c.04 0 .55.07.28 0a3.45 3.45 0 01.46.12c.07 0 .39.16.19.07a2.19 2.19 0 01.75.5 6 6 0 002.26 1.48 3.12 3.12 0 002.77-.46 2.78 2.78 0 001-2.82 1.78 1.78 0 00-2.18-1.24 1.81 1.81 0 00-1.24 2.19z"
      />
      <path
        fill="#323232"
        d="M120.35 108.73v.86c0 .29.07-.26 0 .07l-.12.45c-.08.31.08-.08 0 .07s-.13.23-.2.33c.14-.21 0-.05-.06.06l-.28.3c-.19.21-.38.43-.58.64l-.29.26c.29-.23-.13 0-.16.07l-.14.07c.24-.11-.11 0-.16.06a10.57 10.57 0 00-1 .43 3.18 3.18 0 00-1.36 1.33 4.27 4.27 0 00-.28 2.29v.43c0 .39.1-.17 0 .2l-.06.2c-.12.36.19-.34 0 0a2.69 2.69 0 00-.16.29c-.1.18-.14.19 0 0a2.16 2.16 0 01-.25.27l.17-.15a1 1 0 01.52-.12 1.82 1.82 0 00-1.78 1.77 1.8 1.8 0 001.78 1.78c1.66-.14 2.62-1.48 3.15-2.95a5.1 5.1 0 00.14-2.2v-.51c0-.01-.2.45-.26.51.26-.25-.29.13 0 0a3.84 3.84 0 00.4-.21l-.12.05.19-.07.67-.29a4.26 4.26 0 00.87-.52 12.33 12.33 0 001.45-1.44 5.8 5.8 0 001.46-4.35 1.78 1.78 0 00-3.56 0zM115.92 101.3a6.06 6.06 0 00-3.73 1.85 4.89 4.89 0 00-1.6 4.15 5.2 5.2 0 01-.7 1.76 6.49 6.49 0 00-.5 2.53 6.75 6.75 0 00.64 2.73c.29.64.79 1.32 1 1.9a1.77 1.77 0 103.42-.94 10.22 10.22 0 00-.93-2A4.46 4.46 0 01113 112a2.89 2.89 0 01.18-1.59 16.67 16.67 0 00.84-1.78 8.84 8.84 0 00.15-2.12c0-.15 0-.15 0 0v-.14c.08-.06.09-.17 0 0 .07-.12.14-.22.2-.34s-.16.18 0 0a5.58 5.58 0 01.53-.56l.23-.18.16-.11a5.49 5.49 0 01.63-.34c-.42.17.66 0 0 0a1.82 1.82 0 001.78-1.78 1.79 1.79 0 00-1.78-1.77z"
      />
      <path
        fill="#323232"
        d="M113.87 114l-.52-2.45a2.2 2.2 0 01-.06-1 2.69 2.69 0 01.73-1.08 5.49 5.49 0 001.17-2.16 14.58 14.58 0 01.63-2.4 3.15 3.15 0 012.29-1.71 5.23 5.23 0 001.63-.3c.54-.28.89-.89 1.48-1s1.29.42 1.94.68c1.2.49 2.6-.08 3.87.21a5.27 5.27 0 011.63.76 42 42 0 013.56 2.49c-.84.61-2 .29-3 0a22.78 22.78 0 00-6.67-.78 6.3 6.3 0 00-3.76 1.07 9.91 9.91 0 00-2.1 3 4.06 4.06 0 01-2.73 2.26"
      />
      <path
        fill="#323232"
        d="M112.3 113.07c-.17-.82.89-2.12.71-2.94a2.35 2.35 0 01-.06-.95 2.73 2.73 0 01.73-1.07c.55-.63-.36-1.07-.14-1.87s1.59-1.95 1.95-2.7a3.18 3.18 0 012.28-1.71 5.47 5.47 0 001.63-.29c.54-.28.89-.9 1.49-1s1.28.42 1.93.68c1.21.5 2.61-.08 3.88.21a5.85 5.85 0 011.62.76c1.23.77 3.1 1.68 4.24 2.57-.34 2.35-2.69.21-3.7-.06a22.43 22.43 0 00-6.67-.77c-1.32 0-1.06 3.3-2.1 4.12a29.87 29.87 0 00-3.73 5.09 4.18 4.18 0 01-2.82 2.22"
      />
      <path
        fill="#323232"
        d="M125.69 106v.09l.17-.43a.44.44 0 01-.08.15l.28-.36a.69.69 0 01-.13.11l.36-.27a1.17 1.17 0 01-.27.15l.42-.18a3.48 3.48 0 01-.78.22l.47-.07a2.48 2.48 0 01-.61 0l.48.06a2.09 2.09 0 01-.43-.11l.43.18a2.12 2.12 0 01-.36-.21l.36.28a1.54 1.54 0 01-.25-.26l.27.36a1.43 1.43 0 01-.15-.27l.18.42v-.13a1.77 1.77 0 00-2.18-1.24 1.79 1.79 0 00-1.24 2.19 2.46 2.46 0 00.38.82 3.23 3.23 0 00.92.92 3.34 3.34 0 00.83.4 3.81 3.81 0 001 .16 5 5 0 001-.11 3.06 3.06 0 00.59-.17 3.54 3.54 0 001.13-.65 1.91 1.91 0 00.41-.49.3.3 0 00.06-.11 2.49 2.49 0 00.21-.51 1.92 1.92 0 00-.18-1.37 1.78 1.78 0 00-3.25.42z"
      />
      <path
        fill="#323232"
        d="M122.27 109.3l.18.42a.42.42 0 010 .1c.08.16 0 .11 0-.09a1.39 1.39 0 00.12.32c0 .13.07.27.1.4a.69.69 0 010 .13v-.1a.7.7 0 010 .29 3.37 3.37 0 010 .55v.13a2.86 2.86 0 01-.07.28c0 .13-.08.25-.12.38s0 .13 0 0l-.07.16a6.32 6.32 0 01-.47.79 1 1 0 01-.11.17c-.19.15.24-.14.07 0s-.52.31-.77.48a3.49 3.49 0 00-1 1 2.55 2.55 0 00-.25 1.78 1.15 1.15 0 001.41.8 1.18 1.18 0 00.8-1.41c.06.37 0-.1 0-.11v.16a.52.52 0 01.11-.19c-.11.14-.13.17-.05.07a.94.94 0 00.1-.1l.11-.09-.07.05.17-.12.39-.23a4.91 4.91 0 001.87-2.63 5.3 5.3 0 00.2-2.79 3.93 3.93 0 00-.23-.76l-.21-.49c0-.12-.19-.42 0 .06a1.18 1.18 0 00-1.41-.8 1.15 1.15 0 00-.8 1.41z"
      />
      <path fill="#d9ad8f" d="M120.27 118.89a2.45 2.45 0 11-2.11-4.42 2 2 0 012.82 1.1c.47 1.17-.44 3.78-.44 3.78" />
      <path d="M131 120a.69.69 0 000-1.38.69.69 0 000 1.38z" fill="#653e15" />
      <path
        fill="#323232"
        d="M130.54 126.65a.18.18 0 01-.1 0l-2.58-1.29a.23.23 0 01-.1-.31.22.22 0 01.3-.1l2.59 1.29a.24.24 0 01.1.31.22.22 0 01-.21.1z"
      />
      <path
        fill="#d9ad8f"
        d="M131 123.86c.1.62 2.89 1.15 3.3.94.61-.31.3-1.18.27-1.56-.12-1.26-.39-4.23-.44-4.54-.12-.82-.35-.49-.91-.42s-2.37 4.57-2.22 5.58z"
      />
      <path d="M125.3 123.47c-2.72-1.8.08-6 2.81-4.24s-.08 6-2.81 4.24z" fill="#d8a391" />
      <path
        d="M123.93 128.28a.33.33 0 01-.13 0l-3.61-2.47a.23.23 0 01.26-.38l3.61 2.47a.23.23 0 01-.13.42z"
        fill="#cd9383"
      />
      <path
        fill="#323232"
        d="M118.12 145.31a.24.24 0 01-.23-.22l-.15-5.15a.23.23 0 01.45 0l.16 5.14a.22.22 0 01-.22.24zM103.28 147.18a.23.23 0 01-.22-.18l-1.25-5.53a.23.23 0 11.45-.1l1.25 5.53a.23.23 0 01-.18.27zM88 159a.24.24 0 01-.16-.29l1.67-5.62a.23.23 0 01.21-.16l3.87-.09c.13 0 .23.09.23.22a.23.23 0 01-.22.24l-3.7.09-1.62 5.45a.23.23 0 01-.28.16zM133.05 130.2h-.1a.23.23 0 01-.1-.31l1.18-2.25a.23.23 0 01.31-.09.22.22 0 01.1.31l-1.18 2.24a.22.22 0 01-.21.1zM143.81 197.72l-6.29-.92a.23.23 0 11.07-.45l6.29.92a.23.23 0 010 .45zM63.93 178l-2.29-.48a.24.24 0 01-.18-.27.24.24 0 01.27-.18l2.05.43 1-7.61a.23.23 0 01.26-.2.23.23 0 01.19.26l-1.07 7.86a.21.21 0 01-.09.15.23.23 0 01-.14.04zM79.08 188.75a.24.24 0 01-.13-.05.22.22 0 010-.32c.73-1 2-5.19 3.06-8.89.62-2.11 1.21-4.09 1.65-5.3a.22.22 0 01.29-.14.23.23 0 01.14.29c-.43 1.2-1 3.18-1.64 5.28-1.15 3.92-2.35 8-3.13 9a.23.23 0 01-.24.13z"
      />
      <path fill="#d9ad8f" d="M187.26 113.67l-2.85 1.47" />
      <path
        fill="#323232"
        d="M118.06 172.89h-.11a.25.25 0 01-.11-.32c1.7-3.45 1.14-4.05 1.14-4.06a.41.41 0 00-.32-.11 2.42 2.42 0 00-1.13.83c-.31.43-.69.93-1.14 1.52a.25.25 0 01-.33.05.24.24 0 01-.06-.32 11.12 11.12 0 011.17-1.57 7.37 7.37 0 001.19-2 .6.6 0 00-.18.07 9.12 9.12 0 01-2.29 1.65.26.26 0 01-.21 0 .26.26 0 01-.14-.17c0-.05 0-.14.13-.24a13.88 13.88 0 012.15-1.56c.17-.23.16-.35.15-.39-.11-.36-1.48-.52-2.7-.44a.22.22 0 01-.25-.22.23.23 0 01.22-.25c.68-.05 2.91-.13 3.18.78a.69.69 0 010 .32.42.42 0 01.28.14c.14.17.19.45-.4 1.43a.73.73 0 01.24-.07.81.81 0 01.68.22c.2.1.85.85-1 4.63a.24.24 0 01-.16.08zM62.32 181.63a.25.25 0 01-.24-.21c-.21-1.67-.44-4.52.4-4.74.29-.08.67.06 1.31 1.19-.12-.72-.09-1.16.11-1.31a.46.46 0 01.57 0 8.15 8.15 0 011.6 1.71c.69.88 1.49 1.86 2 1.85a.23.23 0 01.25.23.25.25 0 01-.23.25c-.76 0-1.57-1-2.41-2a8.79 8.79 0 00-1.49-1.6 12.33 12.33 0 00.81 3.39l.18.43a.22.22 0 010 .22.23.23 0 01-.2.11c-.15 0-.21-.06-.36-.5-1.45-3.38-1.94-3.49-2-3.49-.29.15-.31 2.12-.05 4.22a.24.24 0 01-.2.27zm1.81-4.63zM92.45 180.29a.28.28 0 01-.17-.07c-.08-.08-2.08-2.05-1.33-3.91a.24.24 0 01.27-.15h.17a.67.67 0 01-.17-.52.37.37 0 01.29-.3 4.89 4.89 0 013.72.48.23.23 0 01.09.32.25.25 0 01-.33.09 4.42 4.42 0 00-3.28-.45 3.56 3.56 0 00.84.89 13.66 13.66 0 011.68 1.36.2.2 0 01.08.17.24.24 0 01-.15.22.23.23 0 01-.25 0l-.18-.14c-.62-.47-1.09-.85-1.45-1.17a2.41 2.41 0 00-.93-.42c-.44 1.53 1.26 3.21 1.28 3.23a.24.24 0 010 .33.25.25 0 01-.18.04zM150.62 120.33a.24.24 0 01-.22-.25c.08-1.64.34-4.4 1.14-4.46.31 0 .64.2 1.09 1.6 0-.77 0-1.17.22-1.31a.32.32 0 01.35 0 7.65 7.65 0 011.65 2 10.8 10.8 0 001.39 1.76.22.22 0 01.16.29.23.23 0 01-.29.16c-.31-.09-.74-.66-1.64-1.94a15.76 15.76 0 00-1.36-1.76 16.68 16.68 0 00.15 2.64c.12 1 .13 1.13 0 1.24a.25.25 0 01-.22 0 .24.24 0 01-.18-.18c-.76-3.7-1.21-4-1.29-4-.27.14-.6 2-.7 4a.24.24 0 01-.25.21z"
      />
    </svg>
  )
}

export default UnderBlocks
