import React, { useState, useEffect, useMemo } from 'react'
import { AutoSizer, Column, SortDirection, SortDirectionType, Table } from 'react-virtualized'
import 'react-virtualized/styles.css'
import { css } from 'styled-components'
import _orderBy from 'lodash/orderBy'

import { useSipRoutes } from '../../store/api/sip-route-api'
import SipRouteDeleteModal from './SipRouteDeleteModal'
import SipRouteEditModal from './SipRouteEditModal'
import { UnstyledButton } from '@ringdna/common/src/components/Button'
import { SipRoute } from '@ringdna/common/src/types/sip-routing'

const styles = css`
  .cell-class {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 5px;
  }

  .cell-first {
    padding-left: 28px;
  }

  .header-class {
    color: #23527c;
    margin-right: 0px;
    padding: 10px 5px;
    text-transform: none;
  }

  .row-class {
    border-bottom-color: #ddd;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .row-event {
    background-color: #fafafa;
  }

  .no-rows {
    font-style: italic;
  }

  .no-rows-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
`

const NewRoute = Symbol('new route')

const NoData = () => (
  <div className="no-rows-container no-row row-event">
    <span className="no-row">No SIP routing entries found.</span>
  </div>
)

type SortType = { sortBy: string | undefined; sortDirection: SortDirectionType }

const SipRoutes = () => {
  const [data = [], error, , refetch] = useSipRoutes()

  useEffect(() => {
    error && toastr.warning(error.message || '')
  }, [error])

  const [editId, setEditId] = useState<null | number | typeof NewRoute>(null)
  const [deleteId, setDeleteId] = useState<null | number | typeof NewRoute>(null)

  const editRoute = data.find(sipRoute => sipRoute.id === editId)
  const deleteRoute = data.find(sipRoute => sipRoute.id === deleteId)

  const columns = [
    {
      CellComponent({ sipRoute }: { sipRoute: SipRoute }) {
        return (
          <UnstyledButton className="edit" onClick={() => setEditId(sipRoute.id)}>
            <span className="glyphicon glyphicon-edit" />
          </UnstyledButton>
        )
      }
    },
    { name: 'domain', label: 'Domain' },
    { name: 'username', label: 'Username' },
    { name: 'password', label: 'Password' },
    { name: 'enabled', label: 'Enabled?' },
    { name: 'assigned', label: 'Assigned?' },
    { name: 'id', label: 'ID' },
    { name: 'params', label: 'Params' },
    {
      CellComponent({ sipRoute }: { sipRoute: SipRoute }) {
        return !sipRoute.assigned ? (
          <UnstyledButton className="delete" onClick={() => setDeleteId(sipRoute.id)}>
            <span className="glyphicon glyphicon-remove-sign" />
          </UnstyledButton>
        ) : null
      },
      HeaderComponent() {
        return (
          <UnstyledButton className="add" onClick={() => setEditId(NewRoute)}>
            <span className="glyphicon glyphicon-plus-sign" />
          </UnstyledButton>
        )
      }
    }
  ]

  const [sort, setSort] = useState<SortType>({
    sortBy: undefined,
    sortDirection: SortDirection.ASC
  })

  const sortedData = useMemo(() => {
    return _orderBy(data, [sort.sortBy], [sort.sortDirection === SortDirection.DESC ? 'desc' : 'asc'])
  }, [sort, data])

  return (
    <div>
      {/*@ts-ignore*/}
      <style>{styles}</style>
      <div className="page-title">
        <div className="title-left">
          <h1>SIP Routing</h1>
          <div className="clearfix" />
        </div>
      </div>
      <div className="clearfix" />

      <div className="x-panel">
        <br />
        <div className="col-md-12">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Table
                headerHeight={32}
                headerClassName="header-class"
                height={500}
                noRowsRenderer={NoData}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 0 ? 'row-event' : 'row-class')}
                rowCount={data.length}
                rowGetter={({ index }) => sortedData[index]}
                rowHeight={44}
                sort={setSort}
                sortBy={sort.sortBy}
                sortDirection={sort.sortDirection}
                width={width || 100}
              >
                {columns.map((column, index) => (
                  <Column
                    key={index}
                    cellRenderer={({ cellData, rowIndex }) =>
                      (column.CellComponent && <column.CellComponent sipRoute={sortedData[rowIndex]} />) ||
                      (cellData !== null && cellData.toString())
                    }
                    className={index === 0 ? 'cell-first' : 'cell-class'}
                    dataKey={column.name || ''}
                    disableSort={!column.label}
                    flexGrow={1}
                    label={(column.HeaderComponent && <column.HeaderComponent />) || column.label}
                    width={40}
                  />
                ))}
              </Table>
            )}
          </AutoSizer>
          {(editId === NewRoute || editRoute) && (
            <SipRouteEditModal
              sipRoute={editRoute}
              isOpen={!!editId}
              onRequestClose={() => setEditId(null)}
              onSubmit={refetch}
            />
          )}
          {deleteRoute && (
            <SipRouteDeleteModal
              sipRoute={deleteRoute}
              isOpen={!!deleteId}
              onRequestClose={() => setDeleteId(null)}
              onSubmit={refetch}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SipRoutes
