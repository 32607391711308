BaseView = require('base/view')
BaseModel = require('base/model')
UserPickerView = require('widgets/user-picker/view')

class EditorView extends BaseView
  template: require('./template')
  className: 'blocked-number-editor'

  regions:
    userPicker: '.user-picker'

  ui:
    save: 'button.persist'
    popup: 'div.popup'
    modal: '#edit-blocked-number-modal'

  events:
    'click @ui.save': 'save'

  bindings:
    '.number': 'value:number'
    '.type': 'value:type'
    '.direction': 'value:direction'
    '.user-picker-div': 'toggle:allowBlockCall'
    '.direction-container': 'toggle:isMessage'


  computeds:
    isMessage:
      deps: ['type']
      get: (type) ->
        type is 'Message'
    allowBlockCall:
      deps: ['type']
      get: (type) -> (type is 'Call' and @allowBlockCall) or type is 'Message'

  initialize: (options) ->
    {@allowBlockCall} = options
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @userPicker = @getRegion('userPicker')
    @reloadOnClose = false
    @lastMessageDirection = if @model.get('type') is 'Message' then @model.get('direction') else 'Inbound'
    @listenTo(@model, 'change:type', =>
      switch @model.get('type')
        when 'Message'
          direction = @lastMessageDirection
        when 'Call'
          direction = 'Inbound'
        when 'Recording'
          direction = null
      @model.set(direction: direction)
    )
    @listenTo(@model, 'change:direction', =>
      if @model.get('type') is 'Message'
        @lastMessageDirection = @model.get('direction')
    )

  onRender: =>
    @userPicker.show(new UserPickerView(accountId: @model.get('accountId')))
    @listenTo(@userPicker.currentView, 'blur', =>
      _.defer(=>
        @userPicker.currentView.populate(new BaseModel(id: '', displayName: 'All')) unless @userPicker.currentView.val()
      )
    )
    if @model.get('user')
      @userPicker.currentView.populate(new BaseModel(id: @model.get('user').id, displayName: @model.get('user').displayName))
    else
      @userPicker.currentView.populate(new BaseModel(id: '', displayName: 'All'))

  onAttach: =>
    @ui.modal.on('hidden.bs.modal', (e) =>
      @triggerMethod('reload') if @reloadOnClose
    )

  validate: ->
    @model.set('number', @model.get('number')?.replace(/[^\d+]/g, ''))
    if @model.get('number')?.length < 5
      toastr.warning('Please enter a phone number')
      return false
    true

  save: =>
    return unless @validate()

    userId = @userPicker.currentView.val()
    @model.set(userId: if userId and ((@allowBlockCall and @model.get('type') is 'Call') or @model.get('type') is 'Message') then userId else null)
    if @model.hasChanged()
      @model
        .persist(ladda: @ui.save[0])
        .done?(=>
          @reloadOnClose = true
          @ui.popup.modal('hide')
        )
    else
      @ui.popup.modal('hide')

module.exports = EditorView
