import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} fill="#6001D2" viewBox="0 0 24 24">
      <path d="M18.86 1.56L14.27 11.87H19.4L24 1.56H18.86M0 6.71L5.15 18.27L3.3 22.44H7.83L14.69 6.71H10.19L7.39 13.44L4.62 6.71H0M15.62 12.87C13.95 12.87 12.71 14.12 12.71 15.58C12.71 17 13.91 18.19 15.5 18.19C17.18 18.19 18.43 16.96 18.43 15.5C18.43 14.03 17.23 12.87 15.62 12.87Z" />
    </svg>
  )
}

export default SvgComponent
