var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"sub-subtitle\">Save Response</div>\n    <div>What key should be used to save the response?</div>\n    <div class=\"center\"><input type=\"text\" class=\"input\" data-key=\"storageKey\" maxlength=\"40\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"storageKey") : stack1), depth0))
    + "\"/></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"block menu\">\n  <div class=\"sub-subtitle\">Audio</div>\n  <div>1. Play a message at the beginning of the prompt</div>\n  <div class=\"audio-view\" data-prefix=\"audio\"></div>\n  <div class=\"center\">\n    <span class=\"error-step audio-input\"></span>\n  </div>\n  <div>2. Play a message for invalid responses</div>\n  <div class=\"invalid-audio-view\" data-prefix=\"invalidAudio\"></div>\n  <div class=\"center\">\n    <span class=\"error-step invalidAudio-input\"></span>\n  </div>\n  <div class=\"sub-subtitle\">Spacing</div>\n  <div>How long should ringDNA wait for a caller input before repeating the prompt options?</div>\n  <div class=\"center\"><input type=\"text\" class=\"input\" data-key=\"spacing\" maxlength=\"2\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"spacing") : stack1), depth0))
    + "\"/>seconds\n    <span class=\"error-step spacing\"></span>\n  </div>\n  <div class=\"sub-subtitle\">Repeat Prompt</div>\n  <div>How many times can this prompt be repeated? (enter 0 for no repeat)</div>\n  <div class=\"center\"><input type=\"text\" class=\"input\" data-key=\"repeatPrompt\" maxlength=\"2\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"repeatPrompt") : stack1), depth0))
    + "\"/>times\n    <span class=\"error-step repeatPrompt\"></span>\n  </div>\n  <div class=\"sub-subtitle\">Max Digits</div>\n  <div>How many digits can be entered in Prompt maximum?  (enter from 1 to 20)</div>\n  <div class=\"center\"><input type=\"text\" class=\"input max-digits\" data-key=\"maxDigits\" maxlength=\"2\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"args") : depth0)) != null ? lookupProperty(stack1,"maxDigits") : stack1), depth0))
    + "\"/>\n    <span class=\"error-step maxDigits\"></span>\n  </div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"dynamicCallFlows") || (depth0 != null ? lookupProperty(depth0,"dynamicCallFlows") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"dynamicCallFlows","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":32,"column":23}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"dynamicCallFlows")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});