BaseView = require('base/view')
BaseCollection  = require('base/collection')
CountryCollection = require('./../country-collection')

class Step1CreatingModal extends BaseView
  template: require('./step-1-template')

  bindings:
    '.countries'          : 'options:countryList,optionsDefault:selectedCountry'
    '.area-code'          : 'toggle:USorCACountry'
    '.number-area-code'   : 'value:areaCode'
    '.number-search-term' : 'value:searchTerm'
    'option[value="dualCapability"]'  : 'disabled:not(dualCapability)'
    'option[value="voiceOnly"]'       : 'disabled:not(voiceOnly)'
    'option[value="smsOnly"]'         : 'disabled:not(smsOnly)'

  computeds:
    USorCACountry:
      deps: ['country']
      get: (country) ->
        if country is 'US' or country is 'CA' then true else false
    selectedCountry:
      deps: ['country', 'countryList']
      get: (country, countryList) ->
        for value in countryList when value.value is country
          selectedValue = value
        @model.set('country': 'US') if not selectedValue and countryList.length
        @setCapability(selectedValue?.value ? 'US')
        selectedValue ? 'US'

  events:
    'click .next'         : 'clickNext'
    'change .countries'   : (e) -> @model.set('country': e.target.value)
    'change .capabilities': (e) -> @model.set('capability': e.target.value)
    'keypress .number-search-term': 'keypress'

  ui:
    searchTerm : '.number-search-term'
    areaCode   : '.number-area-code'

  initialize: ->
    availableCountries = new CountryCollection()
    availableCountries.fetch(
      data:
        accountId: @model.get('accountId')
    ).done((response) =>
      countries = ({label: country.countryName, value: country.countryCode} for country in response)
      @model.set(
        countryList: _.sortBy(countries, 'label')
        countries: new Backbone.Collection(response)
      )
    ) unless @model.get('countryList').length
    @resetRequirements()

  keypress: (e) ->
    key = e.keyCode
    if (not((key >= 48 && key <= 57) or (key >= 65 && key <= 90) or (key >= 97 && key <= 122)) and key != 8 and key != 42)
      e.preventDefault()

  clickNext: ->
    @model.set(
      searchTerm  : @ui.searchTerm.val()
      areaCode    : if @getBinding('USorCACountry') then @ui.areaCode.val() else null
    )

  resetRequirements: ->
    @model.get('selectedNumbers').reset()
    @model.set('availableNewNumbers': [])
    @model.set(addressLocalRequired: false)
    @model.set(addressForeignRequired: false)
    @model.set(addressAnyRequired: false)

  setCapability: (countryCode) =>
    country = @model.get('countries')?.findWhere(countryCode: countryCode)
    return unless country
    capability = country.get('capability')
    @model.set(
      capability: @model.defaults.capability
      dualCapability: capability.dualCapability
      voiceOnly: capability.voiceOnly
      smsOnly: capability.smsOnly
    )

module.exports = Step1CreatingModal
