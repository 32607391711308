import React, { useState } from 'react'
import RdnaForm from '@ringdna/common/src/components/RdnaFormElements'
import { getRules } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaSection from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaDatePicker from '@ringdna/common/src/components/RdnaFormElements/RdnaDatePicker'
import { getMonth, getYear } from 'date-fns'
import RdnaApiAutoComplete, {
  AutoCompleteApiData
} from '@ringdna/common/src/components/RdnaFormElements/RdnaApiAutoComplete'
import { useBillingReport } from '../../store/api/billing-report-api'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import BacklinkButton from './BacklinkButton'

type FormType = {
  accountId: AutoCompleteApiData | null
  date: Date
  email: string
  fromTwilio: boolean
}

export default function BillingReports() {
  const getReport = useBillingReport()
  const [isInternational, setIsInternational] = useState<boolean>(false)
  const requestReport = (formValues: FormType) => {
    if (formValues.accountId) {
      const month = getMonth(formValues.date) + 1
      const year = getYear(formValues.date)
      getReport({
        body: {
          month: month,
          year: year,
          email: formValues.email,
          international: isInternational,
          fromTwilio: formValues.fromTwilio,
          accountId: formValues.accountId.value
        }
      })
    }
  }
  return (
    <>
      <BacklinkButton />
      <RdnaSection formTitle={`Billing`} subtitle={'Generate CSVs of Billing information for accounts.'}>
        <RdnaForm<FormType> defaultValues={{ fromTwilio: false, email: '', accountId: null, date: new Date() }}>
          {({ handleSubmit }, { RdnaFormController, getMuiProps }) => (
            <RdnaFormLayout container spacing={3} alignItems="center">
              <RdnaFormLayout xs={3}>
                <RdnaFormController
                  rules={getRules(['required'])}
                  name="accountId"
                  render={props => (
                    <RdnaApiAutoComplete
                      name={'accountId'}
                      searchUrl="/api/v2/app/accounts/picklist"
                      placeholder={'Account Name'}
                      {...getMuiProps(props)}
                    />
                  )}
                />
              </RdnaFormLayout>
              <RdnaFormLayout xs={2}>
                <RdnaFormController
                  name="date"
                  render={props => (
                    <RdnaDatePicker views={['year', 'month']} inputFormat="MM/yyyy" {...getMuiProps(props)} />
                  )}
                />
              </RdnaFormLayout>
              <RdnaFormLayout xs={3}>
                <RdnaFormController
                  name="email"
                  rules={getRules(['required', 'notAllWhiteSpace', 'email'])}
                  render={props => (
                    <RdnaTextInput type={InputTypes.EMAIL} placeholder={'Recipient email'} {...getMuiProps(props)} />
                  )}
                />
              </RdnaFormLayout>
              <RdnaFormLayout xs={2}>
                <RdnaSelect
                  options={[
                    { label: 'US', value: false },
                    { label: 'International', value: true }
                  ]}
                  name={'international'}
                  value={isInternational}
                  onChange={({ target }) => setIsInternational(target.value as boolean)}
                />
              </RdnaFormLayout>
              <RdnaFormLayout xs={2}>
                <RdnaFormController
                  name="fromTwilio"
                  render={props => <RdnaCheckbox label="From Twilio" {...getMuiProps(props)} />}
                />
              </RdnaFormLayout>
              <RdnaButton
                type="submit"
                text="Generate"
                onClick={handleSubmit(requestReport)}
                style={{ marginLeft: 18 }}
              />
            </RdnaFormLayout>
          )}
        </RdnaForm>
      </RdnaSection>
    </>
  )
}
