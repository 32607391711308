import { multiValueFilters, sliderFilters, WILDCARD, getBlankValues, dateFormat } from './inputUtils'
import { UserData } from '../index'
import { ReportFilterType, FilterFormValues } from '../types'
import { UserLicenses } from '../../../types/user-settings'
import { capitalizeFirstLetter } from '../../../utils/stringUtils'
import { copyJsonData } from '../../../utils/objectUtils'
import { isBefore, subYears, startOfDay, addDays } from 'date-fns'
import { CUSTOM_DATE_OPTION_DELIMITER } from '../FilterInputs'
import { Analytics } from '../MetabaseView'

export const setEmptyValues = (filters: Record<string, unknown>) => {
  Object.keys(filters).forEach(function (key) {
    if (multiValueFilters.indexOf(key as ReportFilterType) > -1) {
      const value = filters[key] as string[]
      filters[`${key}[]`] = value
      if (!value?.length) {
        filters[`${key}[]`] = WILDCARD
      }
      delete filters[key]
    }
  })
  return filters
}

const DATE_FILTERS = [ReportFilterType.START_DATE, ReportFilterType.START_DATE_QUARTER, ReportFilterType.END_DATE]
const FILTER_PART_MIN = 'minimum'
const FILTER_PART_MAX = 'maximum'

export const mapFilterValues = (filters: Record<string, unknown>) => {
  Object.keys(filters).forEach(function (key) {
    if (DATE_FILTERS.includes(key as ReportFilterType)) {
      filters[key] = dateFormat(filters[key] as string)
    }
    if (sliderFilters.includes(key as ReportFilterType)) {
      const [min, max] = (filters[key] as string).split(',')
      const filterKeyBase = capitalizeFirstLetter(key)
      filters[`${FILTER_PART_MIN}${filterKeyBase}`] = min
      filters[`${FILTER_PART_MAX}${filterKeyBase}`] = max
      delete filters[key]
    }
  })
  return filters
}

// For certain reports, if specific filters are applied,
// the Metabase dashboard that is shown changes.
export const filterDependentReportNames = [
  'moments-notifications-by-agent',
  'activity-call-directions',
  'activity-calling-productivity',
  'activity-dispositions',
  'activity-local-presence',
  'activity-record-percent',
  'activity-talk-time',
  'cai-coaching',
  'conversation-etiquette-phone',
  'conversation-etiquette-video',
  'moments-exclude-notifications'
]

export const keywordDependentReportNames = [
  'over-time-keyword-groups',
  'first-speaker-keyword-groups',
  'keyword-group-heatmap'
]

export const mapReportName = (filters: Record<string, unknown>, reportName: string, caiRecordingVisibility: string) => {
  if (keywordDependentReportNames.includes(reportName) && filters.keywordGroup !== WILDCARD) {
    filters.name = reportName.replace('keyword-groups', 'keywords').replace('keyword-group', 'keyword')
    return filters
  }
  if (filterDependentReportNames.indexOf(reportName) > -1) {
    if (
      (filters.agentNames && (filters.agentNames as string[]).length > 0) ||
      (filters.agentName && filters.agentName !== WILDCARD)
    ) {
      filters.name = reportName + '-agent'
      delete filters.teamNames
      delete filters.teamName
      delete filters.locationName
    } else if (
      (filters.teamNames && (filters.teamNames as string[]).length > 0) ||
      (filters.teamName && filters.teamName !== WILDCARD) ||
      (filters.locationName && filters.locationName !== WILDCARD && caiRecordingVisibility === 'All')
    ) {
      filters.name = reportName + '-team'
      delete filters.agentNames
      delete filters.agentName
    } else {
      filters.name = reportName + '-org'
      delete filters.teamNames
      delete filters.agentNames
      delete filters.teamName
      delete filters.agentName
      delete filters.locationName
    }
  } else {
    filters.name = reportName
  }
  return filters
}

export const triggerAnalytics = (filters: any, userData: UserData | undefined, analytics: Analytics) => {
  Object.keys(filters).forEach(key => {
    const value = filters[key]
    const defaultValue = getBlankValues([key as ReportFilterType], false, userData)[key as keyof FilterFormValues]
    if (key !== 'name' && value !== defaultValue)
      analytics.trackEvent(`${filters.name} filtered by ${key}`, { filter: value })
  })
}

export const localTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

export const parseFilters = (
  filters: Record<string, unknown>,
  reportName: string,
  userData: UserData | undefined,
  analytics: Analytics
) => {
  const caiRecordingVisibility = userData?.caiRecordingVisibility || ''
  const copiedFilters = copyJsonData(filters)
  mapReportName(copiedFilters, reportName, caiRecordingVisibility)
  analytics && triggerAnalytics(copiedFilters, userData, analytics)
  mapFilterValues(copiedFilters)
  setEmptyValues(copiedFilters)
  return copiedFilters
}

export const validateDates = (filters: Record<string, unknown>): boolean => {
  const startDateFilterValue = (filters.startDate || filters.startDateQuarter) as string
  if (!startDateFilterValue) return true
  const startDate = new Date(startDateFilterValue)
  const endDate = new Date(filters.endDate as string)
  const tmrwDate = addDays(startOfDay(new Date()), 1)
  if (!isBefore(startDate, tmrwDate) || !isBefore(endDate, tmrwDate) || !isBefore(startDate, addDays(endDate, 1)))
    return false
  return !isBefore(startDate, subYears(endDate, 1))
}

export const validateSupervisorFilters = (filters: Record<string, unknown>, licenses?: UserLicenses): boolean => {
  if (!licenses) return false
  const hasTeamFilter =
    (!!filters['teamNames[]'] && filters['teamNames[]'] !== '%') ||
    (!!filters['teamName'] && filters['teamName'] !== '%')
  const hasAgentFilter =
    (!!filters['agentNames[]'] && filters['agentNames[]'] !== '%') ||
    (!!filters['agentName'] && filters['agentName'] !== '%')
  const requiredSupervisorFilter = hasTeamFilter || hasAgentFilter
  if (!(licenses.support || licenses.admin) && !requiredSupervisorFilter) return false
  return true
}
