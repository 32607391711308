import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function ThoughtBubbleIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        clipRule="evenodd"
        d="M8.824 8h6.353C18.393 8 21 10.554 21 13.704s-2.607 5.703-5.823 5.703L12.529 22v-2.593H8.824C5.607 19.407 3 16.854 3 13.704 3 10.554 5.607 8 8.824 8z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path d="M12 2v2M18.25 3.675l-1 1.732M5.75 3.675l1 1.732" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default ThoughtBubbleIcon
