BaseView           = require('base/view')
BaseModel          = require('base/model')
AccountPickerView  = require('widgets/account-picker/view')
CallflowPickerView = require('widgets/callflow-picker/view')
UserPickerView     = require('widgets/user-picker/view')

class TransferNumber extends BaseModel
  url: => "smartNumbers/#{@id}/transfer"

class TransferNumberModal extends BaseView
  template: require('./transfer-number-template')
  className: 'transfer-number-modal'

  regions:
    accountPicker : 'span.account-picker'
    userPicker: 'span.user-picker'
    flowPicker: 'span.flow-picker'

  ui:
    entityName : 'input.entity-name'
    modal : '#transfer-number-modal'
    next : 'button.next'
    previous : 'button.previous'
    transfer : 'button.transfer'


  bindings:
    '.additional-default-type': 'toggle:not(isTracking)'
    '.account-selector' : 'toggle:accountTabActive'
    '.entity-name' : 'value:entityName'
    '.next' : 'toggle:accountTabActive'
    '.number' : 'value:number'
    '.sid' : 'value:sid'
    '.previous' : 'toggle:typeTabActive'
    '.tracking-type' : 'toggle:isTracking'
    '.transfer' : 'toggle:typeTabActive'
    '.type' : 'value:type'
    '.type-selector' : 'toggle:typeTabActive'

  events:
    'click @ui.next' : 'showTypeTab'
    'click @ui.previous' : 'showAccountTab'
    'click @ui.transfer' : 'transferNumber'

  computeds:
    accountTabActive:
      deps: ['activeTab']
      get: (activeTab) ->
        activeTab is 'account'
    typeTabActive:
      deps: ['activeTab']
      get: (activeTab) ->
        activeTab is 'type'
    isTracking:
      deps: ['type']
      get: (type) ->
        type is 'Tracking'


  initialize: (options) ->
    {@model} = options
    @viewModel = new BaseModel(
      activeTab: 'account'
      number: options.model.get('number')
      sid: options.model.get('sid')
      type: 'Additional'
      entityName: ''
    )
    @reloadOnClose = false

  onAttach: =>
    @ui.modal.on('hidden.bs.modal', (e) =>
      @triggerMethod('reload') if @reloadOnClose
    )

  onRender: =>
    @accountPicker = new AccountPickerView()
    @showChildView('accountPicker', @accountPicker)

  showTypeTab: =>
    return unless @validate()
    @userPicker = new UserPickerView(accountId: @accountPicker.val())
    @showChildView('userPicker', @userPicker)
    @flowPicker = new CallflowPickerView(accountId: @accountPicker.val())
    @showChildView('flowPicker', @flowPicker)
    @viewModel.set(activeTab: 'type')

  showAccountTab: =>
    @viewModel.set(
      activeTab: 'account'
      type: 'Additional'
      entityName: ''
    )
    @detachChildView('userPicker')
    @detachChildView('flowPicker')

  transferNumber: =>
    return unless @validate()

    transferNumber = new TransferNumber(
      id: @model.get('id')
      accountId: @accountPicker.val()
      type: @viewModel.get('type')
      userId: if @getBinding('isTracking') then null else @userPicker.val()
      flowId: if @getBinding('isTracking') then @flowPicker.val() else null
      entityName: if @getBinding('isTracking') then @viewModel.get('entityName') else null
    )
    transferNumber
      .persist(ladda: @ui.transfer[0])
      .done( =>
        @reloadOnClose = true
        @ui.modal.modal('hide')
        toastr.info('Number successfully transfered.')
      )

  validate: =>
    if @viewModel.get('activeTab') is 'account'
      if not @accountPicker.val()
        toastr.warning('Please select new account.')
        return false
    else
      if @viewModel.get('type') is 'Tracking'
        if not @ui.entityName.val()
          toastr.warning('Please enter entity name.')
          return false
        if @ui.entityName.val().length < 3
          toastr.warning('Entity name must be at least three characters.')
          return false
      else
        if not @userPicker.val()
          toastr.warning('Please select user.')
          return false
    return true

module.exports = TransferNumberModal
