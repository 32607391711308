BaseView = require('base/view')
SourceCollection = require('./collection')

class SourcePickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    {@accountId} = options

  onRender: =>
    @ui.picker.selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      preload: true
      load: (query, callback) =>
        return if query?.length < 2

        picklist = new SourceCollection()
        picklist
          .fetch(
            data:
              source: query
              accountId: @accountId
            )
          .done((response) =>
            items = []
            items.push(text: source, value: source) for source in response
            callback(items)
          )
    )
    @selectize = @ui.picker[0].selectize

  val: =>
    @ui.picker.val()

module.exports = SourcePickerView
