import React, { useState } from 'react'
import styled from 'styled-components'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import { useUpdateCallRecodingSupportAccess } from '../../../../store/api/call-recording-api'
import { useCallRecordingDispatch, useCallRecordingState } from '../../utils/contextReducer'

import AccessGrantedInput from './AccessGrantedInput'
import SelectDurationDropdown from './SelectDurationDropdown'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import CallRecord from '@ringdna/common/src/assets/icons/svg/CallRecord'
import AgreementCheck from './AgreementCheck'
import {useUser} from "../../../common/services/UserContext";

export default React.memo(SupportAccessTab)

function SupportAccessTab() {
  const { accountSettings } = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = !licenses.support && licenses.admin && accountSettings['account.id'] === accountId

  const dispatch = useCallRecordingDispatch()
  const [timeDefined, setTimeDefined] = useState<boolean>(!!accountSettings['settings.supportRecordingAccessDate'])
  const [agreementCheck, setAgreementCheck] = useState<boolean>(timeDefined)
  const [requestLoading, setRequestLoading] = useState(false)

  const updateCallRecodingSupportAccess = useUpdateCallRecodingSupportAccess()

  async function onUpdate(value: string | null) {
    setRequestLoading(true)
    try {
      await updateCallRecodingSupportAccess({
        meta: {
          accountId: accountSettings['account.id']
        },
        body: {
          timeStr: value
        }
      }).then(e => {
        if (e.success) {
          dispatch({
            type: 'STORE_ACCOUNT_SETTINGS',
            payload: { ...accountSettings, 'settings.supportRecordingAccessDate': e.success.payload }
          })
          setTimeDefined(!!e.success.payload)
          setAgreementCheck(!!e.success.payload)
        }
      })
    } catch (err) {
      toastr.error(getErrorMessage(err))
    } finally {
      setRequestLoading(false)
    }
  }

  return (
    <div>
      <Header>
        <IconHelper icon={CallRecord} iconSize={18} strokeWidth={1.4} variant="filled" />
        Support Access
      </Header>

      <RdnaSettingRow
        heading={'Allow Revenue Support users to access call recordings'}
        description={
          <div>
            Grant temporary access to our Support team for playback and download of call recordings in your account.
            <br />
            Easily manage access duration and revoke permissions at any time.
            <AgreementCheck
              agreementCheck={agreementCheck}
              setAgreementCheck={setAgreementCheck}
              disabled={!isEditingAllowed}
            />
          </div>
        }
      >
        <Setting>
          {timeDefined ? (
            <AccessGrantedInput onUpdate={onUpdate} disabled={!isEditingAllowed || requestLoading} />
          ) : (
            <SelectDurationDropdown
              onUpdate={onUpdate}
              agreementCheck={agreementCheck}
              disabled={!isEditingAllowed || requestLoading}
            />
          )}
        </Setting>
      </RdnaSettingRow>
    </div>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing * 2}px;
  margin-bottom: 20px;

  color: black;
`
const Setting = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 5px;
  gap: 10px;
`
