BaseModel = require('base/model')

class NumberModel extends BaseModel
  initialize: (options) =>
    @queueId = options.queueId

  urlRoot: =>
    'smartNumbers'

  defaults:
    number: ''
    type: 'Default'
    dateCreated: null
    dateLastUsed: null

module.exports = NumberModel