import React from 'react'


type Props = {
  size?: number
  color?: string
}

const SvgComponent = ({ size = 50, color = '#2D8CFF' }: Props) => {
  return (
    <svg width={size} height={size * 0.7} viewBox="0 0 111 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.322 7.492c.427.737.561 1.57.61 2.508l.062 1.25V20l.062 1.249c.123 2.044 1.622 3.558 3.678 3.689L95.98 25V11.25l.062-1.25c.049-.927.184-1.778.614-2.517a4.982 4.982 0 018.628.016c.424.735.556 1.585.605 2.501l.063 1.249V20l.062 1.249c.13 2.056 1.619 3.569 3.678 3.689l1.246.062V10c0-5.523-4.465-10-9.972-10a9.937 9.937 0 00-7.48 3.385A9.936 9.936 0 0086.009 0c-2.07 0-3.992.632-5.585 1.714C79.453.632 77.283 0 76.036 0v25l1.246-.063c2.082-.137 3.596-1.611 3.677-3.686L81.022 20v-8.75l.062-1.25c.053-.94.183-1.774.612-2.512a4.981 4.981 0 018.626.004zM4.986 24.937L6.232 25H24.93l-.063-1.249c-.166-2.058-1.62-3.56-3.677-3.689L19.944 20H8.725L23.683 5l-.062-1.248c-.099-2.075-1.612-3.58-3.677-3.69L18.697.002 0 0l.062 1.249C.224 3.283 1.7 4.819 3.738 4.937L4.986 5h11.218L1.246 20l.063 1.249c.123 2.06 1.607 3.552 3.677 3.689zM71.138 3.662c4.868 4.882 4.868 12.796 0 17.678-4.868 4.881-12.76 4.881-17.628 0-4.867-4.882-4.867-12.796 0-17.678A12.407 12.407 0 0162.318 0a12.416 12.416 0 018.821 3.661zm-3.525 3.536a7.516 7.516 0 010 10.606 7.464 7.464 0 01-10.577 0 7.515 7.515 0 010-10.606 7.464 7.464 0 0110.577 0zM36.156 0a12.407 12.407 0 018.806 3.661c4.868 4.882 4.868 12.796 0 17.678-4.868 4.881-12.76 4.881-17.628 0-4.868-4.882-4.868-12.796 0-17.678A12.407 12.407 0 0136.14 0h.016zm5.28 7.197a7.515 7.515 0 010 10.606 7.464 7.464 0 01-10.576 0 7.515 7.515 0 010-10.606 7.464 7.464 0 0110.577 0z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
