class CallScriptModal extends Backbone.Modal
  template: _.template('<div class="modals-container"></div>')

  viewContainer: '.modals-container'

  submitEl: '.done'

  cancelEl: '.close'

  views:
    'click #step1':
      view: require('./step-1-view')
    'click #step2':
      view: require('./step-2-view')
    'click #step3':
      view: require('./step-3-view')

  events:
    'keyup .stepNext': 'keyPressedPrevious'
    'keyup .stepPrevious': 'keyPressedNext'
    'click .previous': 'previousStep'
    'click .next': 'nextStep'
    
  initialize: (options) ->
    { @accountId } = options
    @checkKey = (e) =>
      if @active
        switch e.keyCode
          when 27
            @triggerCancel(e)

  keyPressedPrevious: (e) =>
    @previousStep(e) if e.keyCode is 13

  keyPressedNext: (e) =>
    @nextStep(e) if e.keyCode is 13

  previousStep: (e) ->
    e.preventDefault()
    @previous()

  nextStep: (e) ->
    e.preventDefault()
    if @currentIndex is 0
      error = @model.validate(@model.attributes)
      if error then toastr.warning(error) else @next()
    else
      @next()

module.exports = CallScriptModal
