import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import styled from 'styled-components'

import { Collapse, CollapseProps } from '@mui/material'

type RdnaCollapseProps = {
  children: React.ReactNode
} & CollapseProps
export default function RdnaCollapse({ children, ...restOfCollapseProps }: RdnaCollapseProps) {
  return (
    <Collapse timeout="auto" {...restOfCollapseProps}>
      {children}
    </Collapse>
  )
}

type RdnaCollapseEnhancerProps = {
  children: React.ReactNode // the content that will be visible
  details: React.ReactNode // the content that will be collapsed
  className?: string
  isExpanded?: boolean
  setIsExpanded?: Dispatch<SetStateAction<boolean>>
} & RdnaCollapseProps
export function RdnaCollapseEnhancer({
  children,
  details,
  className = '',
  isExpanded,
  setIsExpanded,
  ...restOfCollapseProps
}: RdnaCollapseEnhancerProps) {
  const [_isExpanded, _setIsExpanded] = useState(false)
  const toggleDetailOpen = useCallback(() => {
    setIsExpanded ? setIsExpanded(isOpen => !isOpen) : _setIsExpanded(isOpen => !isOpen)
  }, [setIsExpanded])

  return (
    <CollapseContainer data-testid="collapse-container" className={className} onClick={toggleDetailOpen}>
      {children}
      <RdnaCollapse in={isExpanded !== undefined ? isExpanded : _isExpanded} {...restOfCollapseProps}>
        {details}
      </RdnaCollapse>
    </CollapseContainer>
  )
}

const CollapseContainer = styled.div`
  cursor: pointer;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  margin-bottom: ${({ theme }) => `${2 * theme.spacing}px`};
  background-color: ${({ theme }) => theme.palette.background.light};
  border: 1px solid ${({ theme }) => theme.palette.background.light}};
`
