import React from 'react'

import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import { ActionType, useSupportSettingsDispatch } from '../../../utils/contextReducer'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'

type SupportSettingToggleRowProps = {
  title: string
  settingInput: string
  inputPlaceholder: string
  inputValue: any
  description?: string
  settingToggle: string
  isChecked: boolean
}

export default function SupportSettingInputToggleRow({
  title,
  description = '',
  isChecked,
  settingToggle,
  settingInput,
  inputPlaceholder,
  inputValue
}: SupportSettingToggleRowProps) {
  const dispatch = useSupportSettingsDispatch()

  const handleToggleChange = (value: boolean) => {
    if (settingToggle.startsWith('settings.')) {
      dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { [settingToggle]: value } })
    }
  }

  function handleInputChange(value: string) {
    dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { [settingInput]: value } })
  }

  return (
    <RdnaSettingRow heading={title} description={description}>
      <RdnaTextInput
        isDisabled={!isChecked}
        inputData={{
          name: settingInput,
          type: InputTypes.NUMBER,
          placeholder: inputPlaceholder
        }}
        value={inputValue}
        onChange={({ target: { value } }) => handleInputChange(value)}
      />
      <RdnaToggleSwitch
        testId={`${settingInput}-toggle`}
        controlledState={{
          isChecked: isChecked,
          setIsChecked: handleToggleChange
        }}
      />
    </RdnaSettingRow>
  )
}
