import React from 'react'

function SvgComponent({ size = 40 }) {
  const height = size * 0.8709
  return (
    // prettier-ignore
    <svg width={size} height={height} viewBox="0 0 62 54" fill="none">
      <g clipPath="url(#clip0)">
      <path d="M57.9485 2.43182e-08H44.2156C43.7 -5.73759e-05 43.1894 0.101501 42.713 0.29887C42.2367 0.496239 41.8039 0.78555 41.4394 1.15027L9.02527 33.5644C8.66056 33.9289 8.37125 34.3617 8.17388 34.838C7.97651 35.3144 7.87495 35.8249 7.875 36.3406V50.0734C7.87497 50.5891 7.97651 51.0997 8.17382 51.5761C8.37114 52.0525 8.66036 52.4854 9.02498 52.85C9.38959 53.2146 9.82246 53.5038 10.2989 53.7012C10.7753 53.8985 11.2859 54 11.8015 54H25.5344C26.05 54.0001 26.5606 53.8985 27.037 53.7011C27.5133 53.5038 27.9461 53.2144 28.3106 52.8497L60.7253 20.4356C61.4615 19.6993 61.875 18.7007 61.875 17.6594V3.92655C61.875 3.41091 61.7735 2.9003 61.5762 2.4239C61.3789 1.9475 61.0896 1.51463 60.725 1.15001C60.3604 0.785392 59.9275 0.496164 59.4511 0.298844C58.9747 0.101524 58.4641 -2.3616e-05 57.9485 2.43182e-08Z" fill="#28A8EA"/>
      <path d="M57.9487 54H44.2156C43.1743 54 42.1756 53.5863 41.4393 52.85L34.875 46.2857V34.02C34.875 32.1582 35.6146 30.3726 36.9311 29.0561C38.2476 27.7396 40.0332 27 41.895 27H54.1607L60.725 33.5644C61.4613 34.3007 61.875 35.2993 61.875 36.3406V50.0737C61.875 51.1151 61.4613 52.1137 60.725 52.85C59.9887 53.5863 58.9901 54 57.9487 54Z" fill="#0078D4"/>
      <path d="M11.8012 0H25.5344C26.5757 1.5911e-06 27.5744 0.41366 28.3107 1.14997L34.875 7.71428V19.98C34.875 21.8418 34.1354 23.6274 32.8189 24.9439C31.5024 26.2604 29.7168 27 27.855 27H15.5893L9.02497 20.4357C8.28866 19.6994 7.875 18.7007 7.875 17.6594V3.92625C7.875 2.88494 8.28866 1.88629 9.02497 1.14997C9.76129 0.413658 10.7599 0 11.8012 0Z" fill="#50D9FF"/>
      <path opacity="0.2" d="M32.625 13.8713V43.5037C32.6275 43.6549 32.6124 43.8058 32.58 43.9535C32.4777 44.5622 32.1625 45.1146 31.6906 45.5124C31.2187 45.9101 30.6208 46.1272 30.0037 46.125H7.875V11.25H30.0037C30.3481 11.2494 30.6892 11.3168 31.0075 11.4483C31.3258 11.5798 31.615 11.7729 31.8586 12.0164C32.1021 12.26 32.2952 12.5492 32.4267 12.8675C32.5582 13.1858 32.6256 13.5269 32.625 13.8713Z" fill="black"/>
      <path opacity="0.1" d="M33.75 13.8713V41.2537C33.7407 42.2444 33.343 43.1919 32.6425 43.8925C31.9419 44.593 30.9944 44.9907 30.0037 45H7.875V10.125H30.0037C30.9962 10.1285 31.9471 10.5243 32.6489 11.2261C33.3507 11.9279 33.7465 12.8788 33.75 13.8713Z" fill="black"/>
      <path opacity="0.2" d="M32.625 13.8713V41.2537C32.6256 41.5981 32.5582 41.9392 32.4267 42.2575C32.2952 42.5758 32.1021 42.865 31.8586 43.1086C31.615 43.3521 31.3258 43.5452 31.0075 43.6767C30.6892 43.8082 30.3481 43.8756 30.0037 43.875H7.875V11.25H30.0037C30.3481 11.2494 30.6892 11.3168 31.0075 11.4483C31.3258 11.5798 31.615 11.7729 31.8586 12.0164C32.1021 12.26 32.2952 12.5492 32.4267 12.8675C32.5582 13.1858 32.6256 13.5269 32.625 13.8713Z" fill="black"/>
      <path opacity="0.1" d="M31.5 13.8713V41.2537C31.5006 41.5981 31.4332 41.9392 31.3017 42.2575C31.1702 42.5758 30.9771 42.865 30.7336 43.1086C30.49 43.3521 30.2008 43.5452 29.8825 43.6767C29.5642 43.8082 29.2231 43.8756 28.8787 43.875H7.875V11.25H28.8787C29.2231 11.2494 29.5642 11.3168 29.8825 11.4483C30.2008 11.5798 30.49 11.7729 30.7336 12.0164C30.9771 12.26 31.1702 12.5492 31.3017 12.8675C31.4332 13.1858 31.5006 13.5269 31.5 13.8713Z" fill="black"/>
      <path d="M28.8752 11.25H2.62524C1.1755 11.25 0.000244141 12.4253 0.000244141 13.875V40.125C0.000244141 41.5748 1.1755 42.75 2.62524 42.75H28.8752C30.325 42.75 31.5002 41.5748 31.5002 40.125V13.875C31.5002 12.4253 30.325 11.25 28.8752 11.25Z" fill="#0078D4"/>
      <path d="M20.9085 21.2414H13.5429V25.5485H20.4564V28.309H13.5429V32.7826H21.301V35.5314H10.1995V18.4686H20.9085L20.9085 21.2414Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="61.875" height="54" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
