import { SMART_NUMBER_TABLE } from './strings'

export const SMART_NUMBER_COLUMNS = [
  {
    Header: SMART_NUMBER_TABLE.id,
    accessor: 'id',
    width: '60',
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.name,
    accessor: 'name',
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.campaign,
    accessor: 'campaign',
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.campaignStatus,
    accessor: 'campaignStatus',
    width: '120',
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.CampaignSalesforceId,
    accessor: 'sfCampaignId',
    styles: {
      align: 'center'
    },
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.extraInfo,
    accessor: 'extraInfo',
    styles: {
      align: 'center'
    },
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.number,
    accessor: 'number',
    styles: {
      align: 'center'
    },
    disableSortBy: true
  },
  {
    Header: SMART_NUMBER_TABLE.remove,
    accessor: 'menu',
    width: '60',
    styles: {
      align: 'right'
    },
    disableSortBy: true
  }
]
