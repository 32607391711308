BaseModel = require('base/model')
BaseCollection = require('base/collection')

class SalesforceFieldSetting extends BaseModel
  urlRoot: 'salesforce/profile/fields'

  defaults:
    parentType: ''
    dataType: ''
    sfdcName: ''
    name: ''
    view: true
    visible: false
    editable: false
    required: false

  toJSON: =>
    settingAttrs = ['view', 'visible', 'required', 'editable', 'sortOrder']
    fieldAttrs = ['name', 'sfdcName', 'parentType', 'dataType']
    target = {
      accountId: @get('accountId')
      profileId: @get('profileId')
    }
    for attr in settingAttrs
      target["setting.#{attr}"] = @get(attr)
    for attr in fieldAttrs
      target["field.#{attr}"] = @get(attr)
    target

class SalesforceFieldSettingCollection extends BaseCollection
  url: -> 'salesforce/profile/fields'

  toJSON: =>
    attrsToJSON = ['id', 'view', 'visible', 'editable', 'required', 'sortOrder', 'salesforceField']
    target = {}
    @models.forEach((model, index) =>
      for attr in attrsToJSON
        value = model.get(attr)
        if attr is 'sortOrder' and model.get('isDefault')
          value = 0
        target["settings[#{index}].#{attr}"] = value
    )
    target

class SalesforceFieldsGridModel extends BaseModel
  defaults: ->
    fields: new SalesforceFieldSettingCollection()
    availableFields: new Backbone.Collection()

  url: -> 'salesforce/profile/fields'

  parse: (response) ->
    response.defaultFields.forEach((setting) => setting.isDefault = true)
    response.additionalFields.forEach((setting) => setting.isDefault = false)
    fields = response.defaultFields.concat(response.additionalFields)
    {
      fields: new SalesforceFieldSettingCollection(fields)
      availableFields: new Backbone.Collection(response.availableFields)
    }

module.exports = class
  @GridModel         : SalesforceFieldsGridModel
  @SettingCollection : SalesforceFieldSettingCollection
  @SettingModel      : SalesforceFieldSetting
