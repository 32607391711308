import React, { useState } from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../../styles'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import CloudIcon from '@ringdna/common/src/assets/icons/svg/Cloud'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import AdditionalFilters from './AdditionalFilters'

type Props = {
  dialerSettings: DialerSettings
  setSettings: (settings: { [key: string]: any }) => void
}

export default Support
function Support({ dialerSettings, setSettings }: Props) {
  const [local, setLocal] = useState<{ [key: string]: any }>({})
  const [sfdcEnhancedAnalytics, setSfdcEnhancedAnalytics] = useState(
    dialerSettings.account.settings.sfdcEnhancedAnalytics
  )
  const [cadencePackageVersion, setCadencePackageVersion] = useState(
    dialerSettings.account.cadencePackageVersion ? dialerSettings.account.cadencePackageVersion.toString() : ''
  )
  const [sfAccountId, setSfAccountId] = useState(dialerSettings.account.sfAccountId || '')

  const update = (key: string, value: any) => {
    const updated = { ...local, [key]: value }
    setLocal(updated)
    setSettings(updated)
  }

  return (
    <>
      <Section>
        <SectionHeader>
          <IconHelper icon={CloudIcon} iconSize={IconSize} variant="filled" />
          <RdnaText bold className={'rdna-text'}>
            Salesforce Settings
          </RdnaText>
        </SectionHeader>
        <RdnaSettingRow heading={'Enhanced Analytics'} description={''}>
          <RdnaToggleSwitch
            value={sfdcEnhancedAnalytics}
            onChange={(checked: boolean) => {
              setSfdcEnhancedAnalytics(checked)
              update('settings.sfdcEnhancedAnalytics', checked)
            }}
          />
        </RdnaSettingRow>
        <RdnaSettingRow heading={'Guided Selling Package Version'} description={''}>
          <RdnaTextInput
            inputData={{ name: 'cadence-package-version', type: InputTypes.TEXT, className: 'support-input' }}
            value={cadencePackageVersion}
            onChange={({ target: { value } }) => {
              setCadencePackageVersion(value)
              update('account.cadencePackageVersion', value)
            }}
          />
        </RdnaSettingRow>
        <RdnaSettingRow heading={'ringDNA SFDC Account ID'} description={''}>
          <RdnaTextInput
            inputData={{ name: 'sf-account-id', type: InputTypes.TEXT, className: 'support-input' }}
            value={sfAccountId}
            onChange={({ target: { value } }) => {
              setSfAccountId(value)
              update('account.sfAccountId', value)
            }}
            isDisabled={!sfAccountId && dialerSettings.account.isSfSandbox}
          />
        </RdnaSettingRow>
      </Section>
      <AdditionalFilters accountId={dialerSettings.account.id} />
    </>
  )
}
