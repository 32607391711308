import React from 'react'
import { useTheme } from 'styled-components'
import { Colors } from '../../constants/colors'

type Props = {
  icon: ({ color }: { color?: Colors }) => JSX.Element
  color?: string
  size?: number
  title?: string
}

export default function IconWithThemeColor({ icon, color = 'primary', ...rest }: Props) {
  const Icon = icon
  const { palette } = useTheme()
  return <Icon color={palette.icon[color]} {...rest} />
}
