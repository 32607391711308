import { GmailIcon, MicrosoftIcon } from '@ringdna/common/src/assets/logos/integrations'
import { Provider } from '../accounts/nylas-users/constants'

export type AccountIntegrations = {
  nylasSettings: NylasSettingModel[]
  availableNylasLicenses: number
  nylasUserCountByProvider: any
}

export type NylasSettingModel = {
  id: number
  enabled: boolean
  provider: Provider
}

export type ProviderItem = {
  label: string
  icon: any
  description: string
}

export type ProviderItems = {
  [name: string]: ProviderItem
}

export const integrationItems: ProviderItems = {
  [Provider.EXCHANGE]: {
    label: 'Microsoft',
    icon: MicrosoftIcon,
    description: 'Connect to Office365 or Exchange to access your email, contacts and calendar within Revenue.'
  },
  [Provider.GMAIL]: {
    label: 'Google',
    icon: GmailIcon,
    description: 'Connect to Gmail to access your email, contacts and calendar within Revenue.'
  }
}
