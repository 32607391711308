import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps, Direction } from '../types'
import { getRotateStyle } from '../iconUtils'

const ratio = 6 / 14

type CaretFilledIconProps = { direction?: Direction; style?: React.CSSProperties } & BaseIconProps

function CaretFilledIcon({
  size = 12,
  color = Colors.N100,
  direction = Direction.DOWN,
  style = {},
  ...props
}: CaretFilledIconProps) {
  return (
    <svg
      width={size * ratio}
      height={size}
      viewBox="0 0 6 14"
      fill="none"
      // @ts-ignore
      style={{ ...style, ...getRotateStyle(direction) }}
      className={`caret-filled ${direction}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.278911 7.3254C0.118415 7.13815 0.118415 6.86185 0.278911 6.6746L5.12037 1.02623C5.42247 0.673784 6 0.887426 6 1.35163L6 12.6484C6 13.1126 5.42247 13.3262 5.12037 12.9738L0.278911 7.3254Z"
        fill={color}
      />
    </svg>
  )
}

export default CaretFilledIcon
