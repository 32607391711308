BaseView = require('base/view')

class HomeView extends BaseView

  render: =>
    user = @user()

    if user.isSalesSupport()
      App.navigate("#users/#{user.get('userId')}", true)
    else if user.isSupport()
      App.navigate('#accounts', true)
    else if user.isAdmin()
      if (user.hasUserAccountAuthorizations())
        App.navigate('#accounts', true)
      else
        App.navigate('#accounts/me', true)
    else
      userId = @user().get('userId')
      App.navigate("#users/#{userId}", true)

module.exports = HomeView
