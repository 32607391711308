BaseView = require('base/view')

class TeamsPickerView extends BaseView
  template: require('./template')

  ui:
    picker: '.selector'

  initialize: (options) ->
    { @accountId, @list, @placeholder, @addedTeams } = options

  onRender: =>
    @initSelectize()

  initSelectize: =>
    @ui.picker.selectize(
      closeAfterSelect: true
      maxItems: null
      plugins: ['remove_button']
      placeholder: @placeholder
      onInitialize: =>
        selectize = @ui.picker[0].selectize
        return unless selectize
        selectize.addOption(value: model.get('id'), text: model.get('name')) for model in @list.models

        if @addedTeams && @addedTeams.length > 0
          selectize.addItem(model.get('id'), true) for model in @addedTeams.models

        selectize.on('item_add', (value) =>
          @trigger('select', value)
        )
        selectize.on('item_remove', (value) =>
          @trigger('deselect', value)
        )
      onDropdownClose: (dropdown) ->
        $(dropdown).prev().find('input').blur()
    )

module.exports = TeamsPickerView
