BaseView = require('base/view')

class AudioPlayerView  extends BaseView
  template: require('./audio-player-template')

  bindings:
    '.spinner': 'toggle: spinner'
    'div.video-js': 'toggle: jpAudio'

  computeds:
    spinner:
      deps: ['spinnerShow']
      get: (spinnerShow) ->
        spinnerShow
    jpAudio:
      deps: ['jsAudioShow']
      get: (jsAudioShow) ->
        jsAudioShow

  initialize: (options) ->
    { @prefix, @instance, @audioModel }  = options
    @model = new Backbone.Model(
      prefix: options.prefix
      instance: options.instance
      spinnerShow: true
      jsAudioShow: false
    )

  onDestroy: ->
    @player.dispose()

  updateMedia: (prefix) =>
    if @prefix != prefix
      return

    @player.pause()
    @player.reset()
    if @audioModel.get('url') != null
      @player.src(
        src: @audioModel.get('url')
        type: 'audio/mp3'
      )
      @model.set(
        spinnerShow: false
        jsAudioShow: true
      )
    else
      @model.set(
        spinnerShow: false
        jsAudioShow: false
      )

  onAttach: =>
    @player = videojs("#videojsplayer-#{@prefix}-#{@instance}",
      controlBar :
        fullscreenToggle: false
      , =>
        @updateMedia(@prefix)
    )
    @player.one('loadeddata', =>
      @model.set(
        spinnerShow: false
        jsAudioShow: true
      )
    ).one('loadstart', =>
      @model.set(
        spinnerShow: false
        jsAudioShow: true
      )
    )
module.exports = AudioPlayerView