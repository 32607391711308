import React, { useContext, useEffect } from 'react'
import { IntegrationsContext } from './store/IntegrationsProvider'
import { IntegrationsActions } from './constants'
import { ActiveUser } from './model'
import { useFetchUser } from '../../../store/api/user-integrations-api'
import IntegrationsContainer from './IntegrationsContainer'
import useToast from '../../common/hooks/useToast'

export default function ActiveUserContainer() {
  const toastr = useToast()
  const {
    dispatch,
    integrationsState: { activeUser }
  } = useContext(IntegrationsContext)

  const activeUserId = parseInt(window.location.href.split('/')[4])
  const isManagedUser = activeUserId !== activeUser.userId
  const fetchUser = useFetchUser()

  useEffect(() => {
    const loadUser = async () => {
      try {
        const response = await fetchUser({
          meta: {
            id: activeUserId
          }
        })
        const payload = response?.success?.payload
        if (payload) {
          const activeUser: ActiveUser = {
            userId: payload.id,
            accountId: payload.account.id,
            userName: payload.displayName,
            userEmail: payload.email,
            licenses: payload.licenses
          }
          dispatch({ type: IntegrationsActions.SET_ACTIVE_USER, payload: activeUser })
        }
      } catch (error) {
        toastr.error(error.message)
      }
    }
    if (activeUserId && isManagedUser) loadUser()
  }, [activeUserId, isManagedUser, dispatch, toastr, fetchUser])

  if (isManagedUser) return null
  return <IntegrationsContainer />
}
