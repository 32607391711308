import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps, Direction } from '../types'
import { getRotateStyle } from '../iconUtils'

type ArrowProps = { direction?: Direction } & BaseIconProps

const ArrowIcon = ({ size = 24, color = Colors.N100, direction = Direction.LEFT }: ArrowProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      data-testid={`arrow-${direction}-icon`}
      // @ts-ignore
      style={getRotateStyle(direction)}
    >
      <path
        d="M11 4L3.21213 11.7879C3.09497 11.905 3.09497 12.095 3.21213 12.2121L11 20"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 12H21" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default ArrowIcon
