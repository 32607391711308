var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-title\">\n  <div class=\"title-left\">\n    <h1>Dynamic Number Insertion</h1>\n  </div>\n</div>\n\n<div class=\"clearfix\"></div>\n\n<p>\n  In order to dynamically display ringDNA Smart Numbers on your site, insert this javascript snippet between the 	&lt;head	&gt; tags on each page of your site that you'd like to track calls from.\n</p>\n\n<div class=\"x-panel snippet\">\n  &lt;script type=\"text/javascript\"&gt;\n  <br />\n    &nbsp;&nbsp&nbsp;&nbsp;var _rdna_acct_id = &#39;"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":16,"column":52},"end":{"line":16,"column":65}}}) : helper)))
    + "&#39;;\n  <br />\n  &lt;/script&gt;\n  <br />\n  &lt;script type=\"text/javascript\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"host") || (depth0 != null ? lookupProperty(depth0,"host") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"host","hash":{},"data":data,"loc":{"start":{"line":20,"column":41},"end":{"line":20,"column":49}}}) : helper)))
    + "/public/js/include/app.js\"&gt;&lt;/script&gt;\n  <br />\n</div>\n\n<button class=\"btn btn-success copy pull-right\">Copy to Clipboard</button>\n\n<div class=\"clearfix\"></div>\n\n<div class=\"text-section\">\n  Once the javascript above has been added, identify which phone numbers you'd like to dynamically change by placing a div\n  or a span around the phone numbers.\n  <br />\n  The class for each method must be set to ringdna-smartnumber.\n  <br />\n  Examples:\n  <br />\n  &lt;div class=\"ringdna-smartnumber\"&gt;1-800-111-2222&lt;div&gt;\n  <br />\n  or\n  <br />\n  &lt;span class=\"ringdna-smartnumber\"&gt;1-800-111-2222&lt;span&gt;\n  <br />\n</div>\n\n<div class=\"title-left text-section\">\n  <h1>Tag AdWords Destination URLs</h1>\n</div>\n\n<div>\n  To manually append the ringDNA tracking variables, just add the following snippet to the end of your ad or keyword destination URLs.\n  Make sure to replace the campaign, ad group and keyword variables with the corresponding names from your\n  Google AdWords account.\n  <br />\n  <br />\n  &#123;<b>websiteurl.com</b>&#125;/?rdnaCampaign=&#123;<b>campaign</b>&#125;&rdnaAdGroup=&#123;<b>adgroup</b>&#125;&rdnaKeyword=&#123;<b>keyword</b>&#125;&rdnaKeywordType=&#123;<b>keywordMatchType</b>&#125;\n</div>\n";
},"useData":true});