import React from 'react'
import styled from 'styled-components'

import Switch, { SwitchProps } from '@mui/material/Switch'
import RdnaText from '../../RdnaText'

type Spacing = 'normal' | 'none'

type RdnaToggleSwitchProps = {
  color?: SwitchProps['color']
  title?: string
  description?: string
  helperText?: string
  error?: boolean
  margin?: Spacing
  value: boolean
  // we are reversing the params of this event to better match our form api
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
} & Omit<SwitchProps, 'onChange'>

export default function RdnaToggleSwitch({
  color = 'primary',
  title,
  description,
  margin = 'normal',
  helperText,
  error,
  onChange,
  value,
  ...restOfSwitchProps
}: RdnaToggleSwitchProps): JSX.Element {
  return (
    <Container $margin={margin}>
      <Switch
        // @ts-ignore
        inputProps={{ 'data-testid': `toggle-${title || 'default'}` }}
        color={color}
        onClick={e => {
          e.stopPropagation()
        }}
        onChange={
          onChange
            ? (event, checked) => {
                onChange(checked, event)
              }
            : undefined
        }
        value={value}
        checked={value}
        {...restOfSwitchProps}
      />
      {(title || description) && (
        <LabelContainer data-testid="rdna-toggle" $description={!!description}>
          {title && <RdnaText variant="h6">{title}</RdnaText>}
          {description && <RdnaText variant="body1">{description}</RdnaText>}
          {helperText && error && <RdnaText variant="meta">{helperText}</RdnaText>}
        </LabelContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ $margin: Spacing }>`
  display: flex;
  margin-bottom: ${props => props.theme.spacing * (props.$margin === 'none' ? 0 : 3)}px;
  // fix for mui specificity issue where MuiIconButton overrides PrivateSwitchBase padding
  .MuiIconButton-root {
    padding: 9px;
  }
`
const LabelContainer = styled.div<{ $description: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.$description && 'margin-top: 9px;'}
`
