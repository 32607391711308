import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import React, { useEffect, useMemo, useState } from 'react'
import { NO_RESULT_STATE, DEFAULT_PAGE_SIZE, ROLE_BULK_TEXT } from '../const'
import { SelectEvent, User } from '../types'
import RdnaSection from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout/RdnaSection'
import { RdnaCheckboxBare } from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { SelectContainer, StyledContainer } from '../styles'
import { assignedUsersColumns } from '../columns'

export type UserRoleAssignmentPanelProps = {
  roleOptions: { groupId: string; label: string; value: number }[]
  roleId?: number
  listUser: User[]
  setDisableSaveButton: any
}

export default function UserRoleAssignmentPanel({
  listUser,
  roleId,
  roleOptions,
  setDisableSaveButton
}: UserRoleAssignmentPanelProps) {
  const [bulkRole, setBulkRole] = useState<boolean>(false)
  const [bulkRoleId, setBulkRoleId] = useState<number | undefined>()

  const onBulkRoleChange = (event: SelectEvent) => {
    setBulkRoleId(event.target.value)
  }

  useEffect(() => {
    if (roleId != null && bulkRoleId == null) {
      setBulkRoleId(roleId)
      listUser.forEach(user => (user.roleId = roleId))
    }
    if (bulkRole && bulkRoleId != null) {
      listUser.forEach(user => (user.roleId = bulkRoleId))
      setDisableSaveButton(false)
    } else if (bulkRole && bulkRoleId == null) {
      setDisableSaveButton(true)
    } else {
      const filledUserRoles = listUser.every(item => item.roleId != null)
      setDisableSaveButton(!filledUserRoles)
    }
  }, [bulkRole, bulkRoleId, listUser, roleId, setDisableSaveButton])

  const tableData = useMemo(() => {
    const onChangeRole = (selectedRole: SelectEvent, user: User) => {
      user.roleId = selectedRole.target.value
      const filledUserRoles = listUser.every(item => item.roleId != null)
      setDisableSaveButton(!filledUserRoles)
    }
    return listUser.map((user: User) => ({
      ...user,
      selectRole: { user, roleOptions, bulkRole, bulkRoleId, onChangeRole }
    }))
  }, [listUser, setDisableSaveButton, roleOptions, bulkRole, bulkRoleId])

  return (
    <>
      <RdnaSection sectionTitle={ROLE_BULK_TEXT}>
        <StyledContainer style={{ width: 'fit-content' }}>
          <RdnaCheckboxBare
            value={bulkRole}
            onChange={checked => {
              setBulkRole(checked)
            }}
          />
          <RdnaText>{'Assign all users this role'}</RdnaText>
          <SelectContainer>
            <RdnaSelect
              name={'bulk-role'}
              placeholder={'Role'}
              options={roleOptions}
              value={bulkRoleId ? bulkRoleId : ''}
              onChange={e => onBulkRoleChange(e as SelectEvent)}
              disabled={!bulkRole}
            ></RdnaSelect>
          </SelectContainer>
        </StyledContainer>
      </RdnaSection>
      <RdnaSmartTable
        // @ts-ignore
        columns={assignedUsersColumns(2)}
        data={tableData}
        noResultState={NO_RESULT_STATE}
        paginationSize={DEFAULT_PAGE_SIZE}
        rowHeight={'100%'}
      />
    </>
  )
}
