import * as React from 'react'


type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M4 18.134V5.974L14.756 2 20.6 3.87v16.368L14.756 22 4 18.134l10.756 1.294V5.274L7.74 6.91v9.586L4 18.134z"
        fill="#EB3C00"
      />
    </svg>
  )
}

export default SvgComponent
