import React, { useState } from 'react'

import { RecordType, usePutAdditionalFilters } from '../../../../../store/api/account-integrations-api'

import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import RdnaTextInput from '@ringdna/common/src/components/RdnaForm/RdnaTextInput'
import { useTheme } from 'styled-components'
import { createSimpleOptions } from '@ringdna/common/src/components/RdnaFormElements/_helpers'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'

type Props = {
  accountId: number
  isModalOpen: boolean
  closeModal: (reload: boolean) => void
}

export default CreateFilterModal
function CreateFilterModal({ accountId, isModalOpen, closeModal }: Props) {
  const [query, setQuery] = useState('')
  const [recordType, setRecordType] = useState('')
  const [loading, setLoading] = useState(false)
  const { spacing } = useTheme()
  const recordTypeOptions = createSimpleOptions(Object.keys(RecordType))
  const updateAdditionalFilters = usePutAdditionalFilters()

  const onSubmitChanges = async () => {
    setLoading(true)
    try {
      await updateAdditionalFilters({
        meta: { id: accountId },
        body: serializeJsonIntoPlay({ filters: [{ sObjectType: recordType, 'account.id': accountId, query: query }] })
      })
      toastr.info(`Additional filter created.`)
      onCloseModal(true)
    } catch (err) {
      toastr.error(getErrorMessage(err))
    } finally {
      setLoading(false)
    }
  }

  const onCloseModal = (reload: boolean) => {
    closeModal(reload)
    setQuery('')
    setRecordType('')
  }

  return (
    <RdnaModal
      open={isModalOpen}
      heading="Additional Filters"
      onClose={() => onCloseModal(false)}
      confirmButtonText="Save"
      onConfirm={onSubmitChanges}
      confirmButtonDisabled={loading || !recordType || !query}
      minWidth={300}
    >
      <RdnaSelect
        name={'record-type'}
        label="Record Type"
        onChange={({ target: { value } }) => setRecordType(value as string)}
        options={recordTypeOptions}
        placeholder="Select"
        value={recordType}
        style={{ marginBottom: spacing * 6 }}
      />
      <RdnaTextInput
        inputData={{
          name: 'query',
          type: InputTypes.TEXT,
          className: 'query',
          label: 'Query String',
          placeholder: 'Query String'
        }}
        value={query}
        onChange={({ target: { value } }) => setQuery(value)}
      />
    </RdnaModal>
  )
}
