BaseModel = require('base/model')
BaseView = require('base/view')

class TokenModel extends BaseModel
  url: 'twilioTester/token'

class TwilioTesterView extends BaseView
  template: require('./template')
  className: 'twilio-tester'

  ui:
    registerBtn   : 'button.register-me'
    dialBtn       : 'button.dial-btn'
    hangupBtn     : 'button.hangup-btn'
    registerInput : 'input.register-me-text'
    dialToInput   : 'input.dial-to'
    numberRadio   : 'input[name=dial-client][value="number"]'
    deviceReady   : 'span.device-ready'

  events:
    'click @ui.registerBtn' : 'register'
    'click @ui.dialBtn'     : 'dial'
    'click @ui.hangupBtn'   : 'hangup'

  initialize: ->
    @twilioConnection = null
    @setupCallbacks()

  register: =>
    token = new TokenModel(clientName: @ui.registerInput.val())
    token
      .persist()
      .done((token) =>
        @ui.registerInput.attr('disabled', true)
        @ui.registerBtn.attr('disabled', true)

        toastr.info('Twilio token successfully generated.')
        Twilio.Device.setup(token, {debug: true, rtc: true})
      )

  dial: =>
    @twilioConnection = Twilio.Device.connect(
      DialClient : not @ui.numberRadio.is(':checked')
      To         : @ui.dialToInput.val()
    )
    @ui.dialBtn.hide()
    @ui.hangupBtn.show()

  hangup: =>
    @twilioConnection.disconnect()
    @ui.dialBtn.show()
    @ui.hangupBtn.hide()

  setupCallbacks: =>
    Twilio.Device.cancel((conn) =>
      console.log('Twilio callback event: cancel')
    )

    Twilio.Device.connect((conn) =>
      console.log('Twilio callback event: connect')
    )

    Twilio.Device.disconnect((conn) =>
      console.log('Twilio callback event: disconnect')
    )

    Twilio.Device.error((error) =>
      console.warn('Twilio callback event: error', error)
      @hangup()
    )

    Twilio.Device.incoming((conn) =>
      console.log('Twilio callback event: incoming')
      conn.cancel()
    )

    Twilio.Device.offline((device) =>
      console.log('Twilio callback event: offline', device)
    )

    Twilio.Device.ready((device) =>
      console.log('Twilio callback event: ready', device)
      @ui.deviceReady.text('Device is ready to make calls.').css('color', 'green')
    )

module.exports = TwilioTesterView
