import styled from 'styled-components'
import { Colors } from '@ringdna/common/src/constants/colors'

export const Section = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: ${({ theme }) => theme.spacing}px;
  box-shadow: ${({ theme }) => theme.palette.boxShadow.primary};
  padding: ${({ theme }) => theme.spacing * 3}px;
  .count {
    padding: 0 ${({ theme }) => theme.spacing * 4}px;
    color: #5a738e;
  }
`

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing * 10}px;
`

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing * 4}px;
  .availableLicenses {
    color: ${Colors.WHITE};
    background-color: ${Colors.V60};
    border-color: ${Colors.V60};
  }
  .noLicenses {
    color: ${Colors.WHITE};
    background-color: ${Colors.RED};
    border-color: ${Colors.RED};
  }
`

export const Settings = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ConnectedUsers = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 5}px;
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`
