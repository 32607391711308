BaseCallTrackingModel = require('../__components/base-call-tracking-model')

class AdvancedCallTrackingModel extends BaseCallTrackingModel

  defaults: ->
    newDefaults =
      source: 'Bing Ads'

    _.extend(BaseCallTrackingModel.prototype.defaults(), newDefaults)


module.exports = AdvancedCallTrackingModel
