import React, { useEffect, useMemo, useState } from 'react'
import { MainProps, Team } from './types'
import { TABS } from './const'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import SectionHeader from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaHorizontalTab from '@ringdna/common/src/components/RdnaHorizontalTab'
import ManageCallDispositionsTab from './components/ManageCallDispositionsTab/_ManageCallDispositionsTab'
import CustomLinksTab from './components/CustomLinksTab/_CustomLinksTab'
import CallToolsTab from './components/CallToolsTab/_CallToolsTab'
import CallScriptsTab from './components/CallScriptsTab/_CallScriptsTab'
import CallNotesTemplatesTab from './components/CallNotesTemplatesTab/_CallNotesTemplatesTab'
import { useAccountDialerSettings } from '../../store/api/accounts-api'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import { Feature } from '@ringdna/common/src/types/dialer-settings'
import SaveButton from './SaveButton'
import PostCallPromptTab from './components/PostCallPromt/_PostCallPrompt'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

export default function CallActions({ accountId }: MainProps) {
  const [activeTab, setActiveTab] = useState(TABS[0])
  const [dialerSettings, , loading, refetch] = useAccountDialerSettings({ meta: { id: accountId?.toString() } })
  const [accountSettings, setAccountSettings] = useState<{ [key: string]: any }>({})
  const [featureVisibilities, setFeatureVisibilities] = useState<Feature[]>([])
  const [teams, setTeams] = useState<Team[]>([])

  useEffect(() => {
    if (dialerSettings) {
      storeSettings(dialerSettings)
    }
  }, [dialerSettings])

  const storeSettings = (dialerSettings: DialerSettings) => {
    const accSettings: { [key: string]: any } = {
      'account.id': dialerSettings.account.id,
      'settings.id': dialerSettings.account.settings.id,
      'settings.callDispositionPrompt': dialerSettings.account.settings.callDispositionPrompt,
      'settings.manageCallDispositionRequiredByTeam':
        dialerSettings.account.settings.manageCallDispositionRequiredByTeam,
      'settings.manageCallDispositions': dialerSettings.account.settings.manageCallDispositions,
      'settings.callDispositionRequiredState': dialerSettings.account.settings.callDispositionRequiredState,
      // @ts-ignore
      'settings.voicemailDropDisposition': dialerSettings.account.settings['voicemailDropDisposition'],
      'settings.callNoteTemplates': dialerSettings.account.settings.callNoteTemplates,
      'settings.appendTransferInfo': dialerSettings.account.settings.appendTransferInfo,
      'settings.postCallPrompt': dialerSettings.account.settings.postCallPrompt,
      'settings.relatedRecords': dialerSettings.account.settings.relatedRecords
    }
    setAccountSettings(accSettings)
    setFeatureVisibilities(dialerSettings.features)
    // @ts-ignore
    setTeams(dialerSettings['teams'])
  }

  const tabs = useMemo(() => {
    const actionTabs = [
      {
        id: TABS[0].key,
        title: TABS[0].title,
        Content: (
          <ManageCallDispositionsTab
            accountId={accountId}
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
          />
        )
      },
      {
        id: TABS[1].key,
        title: TABS[1].title,
        Content: (
          <CallNotesTemplatesTab
            accountId={accountId}
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
            teams={teams}
          />
        )
      },
      {
        id: TABS[2].key,
        title: TABS[2].title,
        Content: <CustomLinksTab accountId={accountId}/>
      },
      {
        id: TABS[3].key,
        title: TABS[3].title,
        Content: (
          <CallToolsTab
            accountId={accountId}
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
            featureVisibilities={featureVisibilities}
            setFeatureVisibilities={setFeatureVisibilities}
          />
        )
      },
      {
        id: TABS[4].key,
        title: TABS[4].title,
        Content: <CallScriptsTab accountId={accountId} />
      }
    ]
    if (isFlagged(flags.PROMPT_MODAL)) {
      const postCallPromptTab = {
        id: TABS[5].key,
        title: TABS[5].title,
        Content: (
          <PostCallPromptTab
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
            accountId={accountId}
          />
        )
      }
      actionTabs.push(postCallPromptTab)
    }
    return actionTabs
  }, [accountId, accountSettings, setAccountSettings, featureVisibilities, setFeatureVisibilities])

  const onTabChange = (selectedTab: string) => {
    const routeTab = TABS.find(tab => tab.key === selectedTab)
    routeTab && setActiveTab(routeTab)
  }

  return (
    <RdnaLoader loading={!!loading} top="150px" data="data">
      <SectionHeader
        headingText={`RingDNA: ${activeTab.title}`}
        rightColumn={
          !loading && (
            <SaveButton
              accountSettings={accountSettings}
              featureVisibilities={featureVisibilities}
              refetchSettings={refetch}
              activeTab={activeTab.key}
              dialerSettings={dialerSettings}
            />
          )
        }
      />
      {!loading && (
        <RdnaHorizontalTab renderIndividually tabs={tabs} onTabChange={onTabChange} activeTab={activeTab.key} />
      )}
    </RdnaLoader>
  )
}
