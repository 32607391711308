BaseView = require('base/view')
BaseModel = require('base/model')
BaseCollection  = require('base/collection')
CreateUserModal = require('./modals/create-user-view')
ImportNumberModal = require('./modals/import-number-view')
LocationsView = require('./locations/view')
DeletionPolicyView = require('./deletion-policy/view')
adminTemplate = require('./template-admin.hbs')
supportTemplate = require('./template-support.hbs')

class AccountOverviewView extends BaseView

  className: 'account-overview'

  regions:
    modal: 'div.modal-container'
    locations: '.locations-container'
    deletionPolicy: '.deletion-policy-container'

  ui:
    deleteButton: 'button.delete'
    undeleteButton: 'button.undelete'
    updateShakenStirButton: 'button.update-shaken-stir'

  bindings:
    '.delete'                         : 'toggle:not(isDeleted)'
    '.undelete'                       : 'toggle:isDeleted, disabled:isReleasedFromTwilio'
    '.accountId'                      : 'value:id'
    '.callDispositionRequired'        : 'checked:callDispositionRequired'
    '.callRecordingAnnouncement'      : 'checked:callRecordingAnnouncement'
    '.callRecordingAnnouncementUrl'   : 'value:callRecordingAnnouncementUrl, disabled:not(callRecordingAnnouncement)'
    '.callRecordingByState'           : 'checked:callRecordingByState'
    '.callTrackingEnabled'            : 'checked:callTrackingEnabled'
    '.cdm-account'                    : 'checked:cdm'
    '.sendFeedback'                   : 'checked:sendFeedback'
    '.send-feedback-group'            : 'toggle:ffFSEnabled'
    '.disableEmailTracking'           : 'checked:disableEmailTracking'
    '.name'                           : 'value:name'
    '.accountAlias'                   : 'value:alias'
    '.primaryEmail'                   : 'value:primaryEmail'
    '.ssoUrl'                         : 'value:ssoUrl'
    '.type'                           : 'value:type'
    '.locationRequired'               : 'checked:locationRequired, disabled:isLocation'

  events:
    'click @ui.deleteButton'          : 'deleteAccount'
    'click @ui.undeleteButton'        : 'undeleteAccount'
    'click button.create-user'        : 'createUser'
    'click button.import-number'      : 'importNumber'
    'click @ui.updateShakenStirButton': 'updateShakenStir'

  computeds:
    agentUnavailableFlowId:
      deps: ['agentUnavailableFlow']
      get: (agentUnavailableFlow) ->
        agentUnavailableFlow?.id

  getTemplate: ->
    return supportTemplate if @user().isSupport()
    adminTemplate

  onAttach: =>
    $('input[type=text], select').addClass('form-control input-sm')

    $('.toggle-switch').bootstrapToggle(
      offstyle : 'default'
      onstyle  : 'info'
      size     : 'small'
      width    : '60px'
    )

  initialize: (options) =>
    @viewModel = new Backbone.Model(
      locationsView: null
      isLocation: false
      ffFSEnabled: window.rdnaEnableFullstory
      )

  onRender: =>
    @locations = new LocationsView(accountId: @model.id)
    @listenTo(@locations, 'LocationsView', (model) -> @viewModel.set('isLocation', @checkLocation(model)))
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @showChildView('locations', @locations)
    if @user().isSupport()
      @deletionPolicyView = new DeletionPolicyView(accountId: @model.id)
      @showChildView('deletionPolicy', @deletionPolicyView)

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  checkLocation: (collectionLocation) =>
    if collectionLocation.models?.length is 0
      @model.set('locationRequired', false)
      @model
      .persist()
      .done()
      @$('.locationRequired').bootstrapToggle('off')
    return collectionLocation.models?.length is 0

  onSaveClicked: (button) =>
    if @deletionPolicyView
      if @deletionPolicyView.validate()
        @deletionPolicyView.save()
      else
        return
    ladda = Ladda.create(button)
    ladda.start()

    @model.persist().done((response) =>
      if response
        toastr.info('Account saved.')
      ladda.stop()
      return
    )

  deleteAccount: =>
    @model
      .delete(ladda: @ui.deleteButton[0])
      .done(=> @model.fetch()) # FIXME: Should be able to render() here instead of re-fetching.

  undeleteAccount: =>
    @model
      .undelete(ladda: @ui.undeleteButton[0])
      .done(=> @model.fetch()) # FIXME: Should be able to render() here instead of re-fetching.

  createUser: =>
    @showChildView('modal', new CreateUserModal(
      accountId: @model.id
      locations: @locations.collection
      locationRequired: @model.get('locationRequired')
      ))

  importNumber: =>
    @showChildView('modal', new ImportNumberModal(accountId: @model.id))

  updateShakenStir: =>
    App.api.post(
      path: "/api/v2/app/accounts/#{@model.id}/updateShakenStir"
      success: (response) =>
        alert("Started TWILIO TRUST PRODUCTS update job for account #{@model.id}")
    )

module.exports = AccountOverviewView
