import config, { ProductDomains } from '../../config'
import React from 'react'
import styled from 'styled-components'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export default function BacklinkButton() {
  return <Backlink href={`${config[ProductDomains.WEB_ADMIN]}/#reports`}>{'< Back to Reports'}</Backlink>
}

const Backlink = styled(RdnaText)`
  text-decoration: none !important;
  padding: ${({ theme }) => theme.spacing * 2}px 0;
  display: block;
  width: fit-content;
`
