import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import React from 'react'
import { format } from 'date-fns'
import { useCallRecordingState } from '../../utils/contextReducer'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useUser } from '../../../common/services/UserContext'
import styled from 'styled-components'

interface Props {
  onUpdate: (value: string | null) => void
  disabled: boolean
}

export default function AccessGrantedInput({ onUpdate, disabled }: Props) {
  const { accountSettings } = useCallRecordingState()
  const readableDate = format(new Date(accountSettings['settings.supportRecordingAccessDate']), 'MMMM d, yyyy h:mm a')

  return (
    <>
      <RdnaTextInput
        name={'access-granted'}
        disabled={true}
        value={readableDate}
        label={'Access granted until'}
        style={{ width: 300 }}
      />
      <RevokeButton text={'Revoke Access'} color={'alert'} disabled={disabled} onClick={() => onUpdate(null)} />
    </>
  )
}

const RevokeButton = styled(RdnaButton)`
  white-space: nowrap;
`
