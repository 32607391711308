import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import { InputTypes } from '@ringdna/common/src/constants/input-types'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'
import React, { useState } from 'react'
import { useUserExport } from './api'
import {Props, UserStatus} from './ExportUsersButton'
import validator from 'validator'
import {
  CANCEL_BUTTON_TEXT,
  CONFIRM_BUTTON_TEXT,
  DETAIL_SALESFORCE_ID_TEXT,
  DETAIL_STATUS_TEXT,
  DETAIL_USER_EMAIL_TEXT,
  DETAIL_USER_NAME_TEXT,
  DETAILS_HEADER_TEXT,
  EMAIL_DESCRIPTION_TEXT,
  EMAIL_INPUT_PLACEHOLDER,
  HEADER_TEXT
} from './const'

type ModalProps = {
  data: Props
  isOpen: boolean
  onClose: () => void
}

export default function ExportModal({ data, isOpen, onClose }: ModalProps) {
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)

  const userExport = useUserExport()

  async function onConfirm() {
    await userExport({
      query: {
        accountId: data.accountId,
        email,
        name: data.userName || null,
        salesforceId: data.salesforceId || null,
        userStatus: data.userStatus,
        userEmail: data.userEmail || null
      }
    })
      .then(() => onClose())
  }

  function onEnterEmail(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setEmail(e.target.value)
    setEmailValid(validator.isEmail(e.target.value))
  }

  const Detail = ({ label, value }: { label: string; value: string }) => {
    return (
      <span>
        <RdnaText bold={true}>{label}:</RdnaText> <RdnaText>{value}</RdnaText>
      </span>
    )
  }

  function getUserStatusLabel(userStatus: UserStatus) {
    switch (userStatus) {
      case UserStatus.ALL:
        return 'All Users'
      case UserStatus.ACTIVE:
        return 'Active'
      case UserStatus.DELETED:
        return 'Deleted'
    }
  }

  return (
    <RdnaModal
      data-testid={'export-modal'}
      open={isOpen}
      heading={HEADER_TEXT}
      confirmButtonDisabled={!emailValid}
      onConfirm={onConfirm}
      confirmButtonText={CONFIRM_BUTTON_TEXT}
      cancelButtonText={CANCEL_BUTTON_TEXT}
      confirmAsync={true}
      onCancel={onClose}
      onClose={onClose}
      maxWidth={'xs'}
    >
      <RdnaText>{EMAIL_DESCRIPTION_TEXT}</RdnaText>
      <RdnaText variant={'subtitle1'} bold={true}>
        {DETAILS_HEADER_TEXT}
      </RdnaText>
      {!!data.userStatus && <Detail label={DETAIL_STATUS_TEXT} value={getUserStatusLabel(data.userStatus)} />}
      {!!data.userName && <Detail label={DETAIL_USER_NAME_TEXT} value={data.userName} />}
      {!!data.userEmail && <Detail label={DETAIL_USER_EMAIL_TEXT} value={data.userEmail} />}
      {!!data.salesforceId && <Detail label={DETAIL_SALESFORCE_ID_TEXT} value={data.salesforceId} />}

      <br />

      <RdnaTextInput
        placeholder={EMAIL_INPUT_PLACEHOLDER}
        name={'email'}
        label={EMAIL_INPUT_PLACEHOLDER}
        type={InputTypes.TEXT}
        value={email}
        onChange={e => onEnterEmail(e)}
      />
    </RdnaModal>
  )
}
