BaseModel = require('base/model')

class VoicemailDropModel extends BaseModel
  urlRoot: -> 'voicemailDrops'

  getFilename: ->
    @get('url').split('/').pop()

  parse: (voicemail) ->
    voicemail['name'] = voicemail.label
    voicemail

module.exports = VoicemailDropModel
