import React, { useCallback, useMemo, useState } from 'react'
import { FormLayout } from '../../styles'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { useCallFlows, useCallFlowsPicklist } from '../../api'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import { PAGE_SIZE, SERVER_PAGE_OFFSET } from '../../const'
import { RtnPagination } from '../../types'
import CallFlowsTable from './CallFlowTable'
import CallFlowSettings from './CallFlowSettings'
import { agentUnavailableFlowIdSetting, NONE_VALUE } from './const'
import { TableSortingRule } from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'

type tabProps = {
  accountSettings: { [key: string]: any }
  setAccountSetting: (setting: { [key: string]: any }) => void
  lockedSettings: { [key: string]: any }
  setLockedSetting: (setting: { [key: string]: any }) => void
  abilities: { [key: string]: any }
}

export default function CallFlowsTab({
  abilities,
  accountSettings,
  setAccountSetting,
  lockedSettings,
  setLockedSetting
}: tabProps) {
  const [queryState, setQueryState] = useState<RtnPagination>({
    globalFilter: '',
    sortBy: [{ id: 'id', desc: true }],
    pageIndex: 0
  })

  const [payload, , loading, refetch] = useCallFlows({
    query: {
      accountId: accountSettings['account.id'],
      searchTerm: queryState.globalFilter,
      'pager.pageSize': PAGE_SIZE,
      'pager.currentPage': queryState.pageIndex + SERVER_PAGE_OFFSET,
      'pager.sortBy': queryState.sortBy[0]?.id,
      'pager.sortDirection': queryState.sortBy[0]?.desc ? 'desc' : 'asc'
    }
  })

  const [callFlowsPicklist] = useCallFlowsPicklist({
    meta: {
      id: accountSettings['account.id']
    }
  })

  const handleQueryState = useCallback(
    ({ pageIndex, sortBy, globalFilter }: {pageIndex: number, sortBy: TableSortingRule<any>[], globalFilter: string}) => {
      const state = {
        globalFilter,
        pageIndex,
        sortBy
      }
      setQueryState(state)
    },
    [setQueryState]
  )

  const getOptionsCallFlows = useMemo(() => {
    const picklist = callFlowsPicklist?.map(callFlow => ({ label: callFlow.text, value: parseInt(callFlow.value) }))
    if (picklist) {
      return [...NONE_VALUE, ...picklist]
    } else {
      return [...NONE_VALUE]
    }
  }, [callFlowsPicklist])

  function getSelectedUnavailableFlow() {
    const agentUnavailableFlow = accountSettings[agentUnavailableFlowIdSetting]
    if (agentUnavailableFlow) {
      const unavailableFlow = callFlowsPicklist?.find(callFlow => callFlow.value == agentUnavailableFlow)
      return unavailableFlow ? unavailableFlow.text : 'None'
    }
    return 'None'
  }

  return (
    <RdnaFormLayout container>
      <FormLayout>
        <CallFlowSettings
          accountSettings={accountSettings}
          setAccountSetting={setAccountSetting}
          lockedSettings={lockedSettings}
          setLockedSetting={setLockedSetting}
          optionsCallFlows={getOptionsCallFlows}
          selectedUnavailableFlow={getSelectedUnavailableFlow}
        />
      </FormLayout>
      <RdnaFormLayout>
        <RdnaHeaderSection rightColumn={CreateCallFlowButton(!accountSettings['account.id'])} />
        <CallFlowsTable
          payload={payload}
          refetch={refetch}
          onQueryState={handleQueryState}
          loading={!!loading}
          isSupport={abilities['users:support']}
        />
      </RdnaFormLayout>
    </RdnaFormLayout>
  )
}

const CreateCallFlowButton = (disabled: boolean) => {
  return (
    <RdnaButton
      data-analyticsid="add-call-flow"
      disabled={disabled}
      text={'Add Call Flow'}
      onClick={() => {
        window.location.href = '#call-flow/add-new'
      }}
    />
  )
}
