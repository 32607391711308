var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-title\">\n  <div class=\"title-left\">\n    <h1>User : <span style=\"color: #777\" class=\"user-name\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":67}}}) : helper)))
    + "</span></h1>\n  </div>\n  <div class=\"navbar-right\">\n    <button class=\"ladda-button btn btn-success save\">Save</button>\n  </div>\n  <div class=\"clearfix\" ></div>\n</div>\n\n<div class=\"clearfix\" ></div>\n\n<ul class=\"nav nav-tabs\">\n  <li><a data-tab=\"overview\" data-testid=\"usersettings-tab.overview\">Overview</a></li>\n  <li><a data-tab=\"settings\" data-testid=\"usersettings-tab.dialer\">RingDNA Settings</a></li>\n  <li><a data-tab=\"conversation-ai\" data-testid=\"usersettings-tab.cai\" class=\"admin\">Conversation AI</a></li>\n  <li><a data-tab=\"integrations\" data-testid=\"usersettings-tab.integrations\">Integrations</a></li>\n  <li><a data-tab=\"calendar\" data-testid=\"usersettings-tab.calendar\">Calendar Booking</a></li>\n</ul>\n\n<div class=\"x-panel\">\n  <div class=\"content-tabs\" ></div>\n  <div class=\"content-tabs2\" ></div>\n</div>\n";
},"useData":true});