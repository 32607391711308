BaseView = require('base/view')

class Details
  @tooltipTexts = {
    callForwarding: {
      locked: 'Click to unlock this setting and allow users to set individual preferences for Call Forwarding.'
      unlocked: 'Click to lock this setting and disable users from setting individual preferences for Call Forwarding.'
      disabled: 'Call Forwarding cannot be locked ON for all users while WebRTC is turned ON.
        To lock call forwarding settings, either Call Forwarding or WebRTC must be turned OFF.'
      offSetting: 'Call Forwarding must be turned ON while WebRTC is turned OFF. To unlock or turn OFF Call Forwarding, WebRTC must be turned ON.'
    },
    callForwardingTimeout: {
      locked: 'Click to unlock this setting and allow individual users to set their own Call Forwarding Timeout.'
      unlocked: 'Click to lock this setting and disable users from setting individual Call Forwarding Timeout preferences.'
      disabled: 'Call Forwarding Timeout cannot be locked OFF. To lock a Call Forwarding Timeout,
        a Call Forwarding Timeout value must be entered.'
    },
    callForwardingPrompt: {
      locked: 'Click to unlock this setting and allow users to set individual Call Forwarding Prompt preferences.'
      unlocked: 'Click to lock this setting and disable users from setting individual Call Forwarding Prompt preferences.'
    },
    callForwardingDisabledOffline: {
      locked: 'Click to unlock this setting and allow users to set individual offline forwarding preferences.'
      unlocked: 'Click to lock this setting and disable users from setting individual offline forwarding preferences.'
    },
    callRecording: {
      locked: 'Click to unlock this setting and allow teams and users to set individual Call Recording preferences.'
      unlocked: 'Click to lock this setting and disable teams and users from setting individual Call Recording preferences.'
    },
    callRecordingTeam: {
      locked: 'Click to unlock this setting and allow members of this team to set individual Call Recording preferences.'
      unlocked: 'Click to lock this setting and disable members of this team from setting individual Call Recording preferences.'
    },
    unavailableFlow: {
      locked: 'Click to unlock this setting and allow users to set individual Unavailable Flows.'
      unlocked: 'Click to lock this setting and disable users from setting individual Unavailable Flows.'
    },
    webLeads: {
      locked: 'Click to unlock this setting and allow admins to set Hot Leads preferences for individual users.'
      unlocked: 'Click to lock this setting and disable admins from setting Hot Leads preferences for individual users.'
      disabled: 'Enable Hot Leads cannot be unlocked while it is turned OFF. To unlock the "Enable Web Leads" setting, it must first be turned ON.'
    }
  }

  @modalTexts = {
    callForwarding: {
      locked: 'Call Forwarding will be disabled for all users and cannot be modified.'
      unlocked: 'Users will be able to set their own preferences for Call Forwarding.
        Call Forwarding will be turned OFF by default for all users, but users have the ability to turn ON
        Call Forwarding as desired.'
    },
    callForwardingTimeout: {
      locked: "The Call Forwarding Timeout will be set to <b>::info</b> seconds for all users, and cannot be modified.
        Users will not be able to set individual Call Forwarding Timeouts."
      unlocked: "Users will be able to set their own Call Forwarding Timeout values.
        The Call Forwarding Timeout will be set by default to <b>::info</b> seconds for all users,
        but users can choose to set a new Call Forwarding Timeout for themselves as desired."
    },
    callForwardingPrompt: {
      locked: 'The "Call Forwarding Prompt" feature will be turned ::info for all users, and cannot be modified. '
      unlocked: 'Users will be able to set their own Call Forwarding Prompt preferences.
        Call Forwarding Prompt will be turned ::info by default, but individual users will be able to turn '
    },
    callForwardingDisabledOffline: {
      locked: 'The "Disable Offline Forwarding" feature will be turned ::info for all users, and cannot be modified.'
      unlocked: 'Users will be able to set their own offline forwarding preferences.'
    },
    callRecording: {
      locked: "Call Recording will be turned <b>::info</b> calls and cannot be modified by teams or users."
      unlocked: "Teams and users will be able to set their own call recording preferences.
                By default, all teams and users will have Call Recording turned <b>::info</b> calls,
                but teams and users can set new preferences as desired."
    },
    callRecordingTeam: {
      locked: "Call Recording will be turned <b>::info</b> calls and cannot be modified for members of this team."
      unlocked: "Members of this team will be able to set their own call recording preferences.
                By default, all members of this team will have Call Recording turned <b>::info</b> calls,
                but they can set new preferences as desired."
    },
    unavailableFlow: {
      locked: "The call flow: <b>::info</b> will be used as the unavailable flow for all users.
        Users will not be able to set a different unavailable flow."
      unlocked: "Users will be able to set their own Unavailable Flow. The call flow: <b>::info</b> will be used as the default Unavailable Flow
        for all users, but users can choose to set a new Unavailable Flow for themselves as desired."
    },
    webLeads: {
      locked: 'The "Enable Hot Leads" setting will be turned ON for all users and cannot be modified.
        Hot Leads will be visible for all users within the intelligent dialer,
        and users will not have the ability to suppress hot leads for themselves.'
      unlocked: 'Users will be able to set their own preferences for the "Enable Hot Leads" setting.
        Hot Leads will be turned ON for all users by default, but users will have the ability to turn OFF their "Enable Hot Leads" setting.'
    }
  }

  @tooltip: (setting, locked, disabled) =>
    lock = if locked then 'locked' else 'unlocked'
    if disabled
      @tooltipTexts[setting]['disabled']
    else
      @tooltipTexts[setting][lock]

  @modal: (setting, locked, additionalInfo) =>
    if locked
      text = @modalTexts[setting]['locked']
    else
      text = @modalTexts[setting]['unlocked']
    text = text.replace("::info", additionalInfo) if additionalInfo
    text

  @addTextToModal: (setting, locked, additionalInfo, basicSettings) =>
    text = ''
    switch setting
      when 'callForwardingPrompt'
        text = 'Users will be prompted to press a key to accept all forwarded calls.'
        if locked
          if not basicSettings.get(setting)
            text = 'Forwarded calls will not require a prompt to be accepted.'
        else
          text = "#{if not basicSettings.get('callForwardingPrompt') then 'ON' else 'OFF'} the call forwarding prompt as desired."
      when 'callForwardingDisabledOffline'
        if locked
          text = ' Calls will not be forwarded to any users while they are not logged into the RingDNA Dialer.'
          if not basicSettings.get(setting)
            text = ' Calls will be forwarded to any users regardless of whether or not they are not logged into the RingDNA Dialer.'
        else
          text = " By default, calls will #{ if basicSettings.get('callForwardingDisabledOffline') then 'NOT' else ' ' }
            be forwarded to users while they are not logged into the Intelligent Dialer."
    "#{@modal(setting, locked, additionalInfo)} #{text}"

  @upperCaseTitle: (input) =>
    result = input.replace(/([A-Z]+)/g, ',$1').replace(/^,/, '')
    feature = result.split(',').join(' ')
    feature.replace(/\b\w/g, (l) => l.toUpperCase())

  @selectLockedSetting: (locked, setting, basicSettings) =>
    viewModel = new Backbone.Model(
      additionalInfo: null
      locked: locked ? false)
    header = @upperCaseTitle(setting)
    switch setting
      when 'callForwarding'
        viewModel.set(disabled: basicSettings.get(setting))
      when 'callForwardingTimeout'
        viewModel.set(
          additionalInfo: basicSettings.get('callForwardingTimeout')
          disabled: not basicSettings.get(setting))
      when 'callForwardingPrompt'
        viewModel.set(additionalInfo: if basicSettings.get('callForwardingPrompt') then 'ON' else 'OFF')
      when 'callForwardingDisabledOffline'
        header = 'Disable Offline Forwarding'
        viewModel.set(additionalInfo: if basicSettings.get('callForwardingDisabledOffline') then 'ON' else 'OFF')
      when 'callRecording'
        viewModel.set(additionalInfo: @callRecordingAdditionalInfo(basicSettings.get('callRecordingOverrideOption')))
      when 'callRecordingTeam'
        header = 'Call Recording'
        viewModel.set(
          additionalInfo: @callRecordingAdditionalInfo(basicSettings.get('settings').callRecordingOption))
      when 'unavailableFlow'
        viewModel.set(
          additionalInfo: basicSettings.get('agentUnavailableFlow')?.name ? 'None'
          disabled: false)
      when 'webLeads'
        header = 'Enable Hot Leads'
        viewModel.set(disabled: not basicSettings.get('WebLeads'))

    accountOrTeamTitle = if setting is 'callRecordingTeam' then 'Team' else 'Account'
    viewModel.set('header', "\"#{header}\" #{accountOrTeamTitle}")
    viewModel

  @changeLockedSetting: (viewModel, setting, basicSettings) =>
    switch setting
      when 'callForwarding'
        viewModel.set(
          locked: not (basicSettings.get('webRTCEnabled') and basicSettings.get('callForwarding'))
          disabled: basicSettings.get('callForwarding')) if basicSettings?.changed?.hasOwnProperty('webRTCEnabled') or
            basicSettings?.changed?.hasOwnProperty('callForwarding')
      when 'callForwardingTimeout'
        viewModel.set(
          additionalInfo: basicSettings.get('callForwardingTimeout')
          disabled: not basicSettings.get(setting))
        viewModel.set(locked: false) if not basicSettings.get(setting)
      when 'callForwardingPrompt'
        viewModel.set(additionalInfo: if basicSettings.get('callForwardingPrompt') then 'ON' else 'OFF')
      when 'callForwardingDisabledOffline'
        viewModel.set(additionalInfo: if basicSettings.get('callForwardingDisabledOffline') then 'ON' else 'OFF')
      when 'callRecording'
        viewModel.set(additionalInfo: @callRecordingAdditionalInfo(basicSettings.get('callRecordingOverrideOption')))
      when 'callRecordingTeam'
        viewModel.set(additionalInfo: @callRecordingAdditionalInfo(basicSettings.get('settings').callRecordingOption))
      when 'unavailableFlow'
        viewModel.set(additionalInfo: basicSettings.get('agentUnavailableFlow')?.name ? 'None')
      when 'webLeads'
        viewModel.set(
          locked: not basicSettings.get('WebLeads')
          disabled: not basicSettings.get('WebLeads'))

  @callRecordingAdditionalInfo: (value) ->
    switch value
      when 'All' then 'ON for all'
      when 'Inbound' then 'ON for all inbound'
      when 'Outbound' then 'ON for all outbound'
      when 'None' then 'OFF for all'

module.exports = Details
