import React from 'react'
import {CallNoteTeamTemplate, Team} from '../types'
import { Feature, FeatureType } from '@ringdna/common/src/types/dialer-settings'

export function formatTeamsStringForColumns(teams: CallNoteTeamTemplate[], displayTeamLimit = 2) {
  if (teams.length === 0) return 'All'
  if (teams.length <= displayTeamLimit) {
    return teams.map((teamTemplate: CallNoteTeamTemplate) => teamTemplate.team.name).join(', ')
  } else {
    return `${teams
      .slice(0, displayTeamLimit)
      .map((teamTemplate: CallNoteTeamTemplate) => teamTemplate.team.name)
      .join(', ')}, +${teams.length - displayTeamLimit} more`
  }
}

export function getTeamOptions(teams: Team[]) {
  const options = teams.map((team: Team) => {
    return { text: team.name ? team.name : '', value: team.id }
  })
  return options
}

export const isFeatureVisibilitiesSelector = (featureVisibilities: Feature[], featureType: FeatureType): boolean => {
  const feature = featureVisibilities.find(feature => feature.feature === featureType)
  if (!feature) {
    throw new Error(`featureVisibilities not found for type: ${featureType}`)
  }
  return feature.visible
}

export const renderTeamOption = (props: React.HTMLAttributes<HTMLLIElement>, option: { value: number; text: string }) => {
  return <li {...props} key={option.value}> {option.text} </li>
}
