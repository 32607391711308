import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function QuestionIcon({ size = 20, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 6 9" fill="none">
      <path
        d="M5.752 3.112c0 .264-.033.495-.099.693a1.978 1.978 0 01-.264.528c-.103.154-.22.293-.352.418a8.523 8.523 0 01-.385.34c-.213.177-.4.353-.561.529a.925.925 0 00-.242.649H2.386c0-.272.033-.51.099-.715a2.46 2.46 0 01.264-.54c.11-.153.231-.289.363-.406.14-.118.275-.228.407-.33.103-.081.198-.158.286-.231.095-.074.18-.15.253-.231.073-.088.128-.176.165-.264a.752.752 0 00.066-.32c0-.256-.092-.454-.275-.593-.176-.147-.436-.22-.781-.22-.147 0-.3.018-.462.055-.154.029-.312.07-.473.12-.154.052-.308.11-.462.177-.147.066-.282.128-.407.187L1 1.825c.403-.235.8-.411 1.188-.528a3.937 3.937 0 011.21-.187c.367 0 .697.055.99.165.293.102.539.245.737.429.205.183.36.396.462.638.11.242.165.498.165.77zM2.166 7.886c0-.264.088-.488.264-.671.176-.191.4-.286.671-.286.279 0 .506.095.682.286a.933.933 0 01.264.67c0 .257-.088.481-.264.672a.903.903 0 01-.682.275.89.89 0 01-.671-.275.957.957 0 01-.264-.671z"
        fill={color}
        stroke={color}
        strokeWidth={0.25}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QuestionIcon
