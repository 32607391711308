import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function DashboardIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <mask id="path-1-inside-1_9997_7632" fill="white">
        <rect x="2" y="5" width="20" height="14" rx="0.5" />
      </mask>
      <rect
        x="2"
        y="5"
        width="20"
        height="14"
        rx="0.5"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_9997_7632)"
      />
      <path d="M3 8H21" stroke={color} />
      <path d="M6 8V18" stroke={color} />
      <path d="M19.75 10.5L7.75 10.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.75 13L7.75 13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.75 16L16.75 16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.25 16L12.25 16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.75 16L7.75 16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default DashboardIcon
