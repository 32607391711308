import { getFormattedDate } from '@ringdna/common/src/utils/timeUtils'
import { NameLinkCell, TextCell } from '../../cells'

export const callQueueLink = '/#call-queues/'
export function callQueuesColumns(isSupport: boolean) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, row }: { value: string; row: any }) => NameLinkCell(value, row, callQueueLink)
    },
    {
      Header: 'Account',
      accessor: 'account.name',
      Cell: ({ value, row }: { value: string; row: any }) => TextCell(value, row)
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 300,
      Cell: ({ value, row }: { value: string; row: any }) => TextCell(value, row)
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      width: 150,
      Cell: ({ value, row }: { value: string; row: any }) => TextCell(getFormattedDate(value).join(' '), row)
    },
    {
      Header: 'Date Updated',
      accessor: 'updatedAt',
      width: 150,
      Cell: ({ value, row }: { value: string; row: any }) => TextCell(getFormattedDate(value).join(' '), row)
    },
    {
      Header: 'Actions',
      accessor: 'menu',
      width: 80,
      disableSortBy: true,
      styles: {
        align: 'right'
      }
    }
  ]
  if (!isSupport) {
    columns.splice(1, 1)
  }
  return columns
}

export const callQueuesLiveColumns = [
  {
    Header: 'Name',
    accessor: 'friendlyName'
  },
  {
    Header: 'Average Wait Time',
    accessor: 'averageWaitTime'
  },
  {
    Header: 'Current Size',
    accessor: 'currentSize',
    width: 80
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated.millis',
    width: 150,
    Cell: (data: { value: string }) => getFormattedDate(data.value).join(' ')
  },
  {
    Header: 'SID',
    accessor: 'sid',
    width: 80
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]
