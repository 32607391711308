import React from 'react'
import styled from 'styled-components'

const StyledIframe = styled.iframe`
  border: none;
  height: calc(100vh - 325px);
  width: 100%;
`

export type Props = {
  url: string
}

export default function ReportIframe({ url }: Props) {
  return <StyledIframe title="user profile report" src={url} />
}
