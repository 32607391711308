import { CallFlowSteps } from '../../types'
import { CallFlowLinkCell, NameLinkCell } from '../../cells'
import { callFlowLink } from '../CallFlowsTab/const'

export const userSkillsSetting = 'settings.userSkills'
export const HEADER_TEXT =
  'Skills-based routing ensures prospects and potential customers speak with the best reps that can handle the call. Create a Skill Name and assign reps to each skill.'

export const userSkillsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value, row }: { value: string; row: any }) => NameLinkCell(value, row)
  },
  {
    Header: 'Number of Users',
    accessor: 'userSkills.length'
  },
  {
    Header: 'Call Flows',
    accessor: 'callFlowSteps',
    width: 300,
    Cell: ({ value, row }: { value: CallFlowSteps[]; row: any }) => CallFlowLinkCell(value, row, callFlowLink)
  },
  {
    Header: 'Actions',
    accessor: 'menu',
    width: 80,
    disableSortBy: true,
    styles: {
      align: 'right'
    }
  }
]

export const initSkill = {
  id: undefined,
  name: '',
  callFlowSteps: [],
  userSkills: []
}
