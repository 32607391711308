BackgridCell = require('helpers/backgrid-cell')
BaseView = require('base/view')

class CDMSearchView extends BaseView
  template: require('./template')
  className: 'customer-data-search'

  regions:
    grid  : 'div.grid'

  ui:
    search : 'button.search'
    email : 'input.email'
    salesforceId : 'input.salesforce-id'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    email = @ui.email.val().trim().toLowerCase()
    sfId =  @ui.salesforceId.val().trim()
    return if not sfId and not email
    App.api.get(
      path    : 'cdm/customer'
      data    :
        email : email
        salesforceId: sfId
      success : (response) =>
        @showChildView('grid', @buildGrid(new Backbone.Collection(response))) if @getRegion('grid')
    )

  buildGrid: (collection) ->
    customerCell = BackgridCell.href(
      url   : '#cdm/:id',
      label : ':id',
      attrs : ['id'])

    cols = [
      {label: 'Name',           name: 'name',         cell: 'string'}
      {label: 'Email',          name: 'email',        cell: 'string'}
      {label: 'Account ID',     name: 'accountId',    cell: 'string'}
      {label: 'Salesforce ID',  name: 'salesforceId', cell: 'string'}
      {label: 'Type',           name: 'type',         cell: 'string'}
      {label: 'ID',             name: 'id',           cell: customerCell}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No customers found.'
    )

module.exports = CDMSearchView