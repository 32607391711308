AddressCollection  = require('widgets/smart-numbers-widget/address-collection')
AddressStepModal   = require('features/call-tracking/address-step/address-step-view')
BackgridCell       = require('helpers/backgrid-cell')
BackgridRow        = require('helpers/backgrid-row')
BaseView           = require('base/view')
BaseCollection     = require('base/collection')
BasicCallTrackingModal      = require('./modals/main-view')
BasicCallTrackingModel      = require('./model')
BasicCallTrackingСollection = require('./collection')
CallflowPickerView = require('widgets/callflow-picker/view')
CallTrackingHelper = require('features/call-tracking/helper')
ChannelPickerView = require('widgets/channel-picker/view')
PagedCollection    = require('base/paged-collection')
PagerView          = require('widgets/pager/view')

class BasicPagedCollection extends PagedCollection

  parseRecords: (response) =>
    @trigger('pager:state:changed', @state)
    result = []
    result.push(basic) for basic in response.results
    result

class BasicView extends BaseView
  template: require('./template')

  regions:
    addressStepModal   : 'div.address-step-container'
    grid    : 'div.grid'
    pager   : 'span.pager'
    callFlowPicker: '.call-flow-picker'
    channelPicker: '.channel-picker'
    modal   : 'div.main-modal-container'

  ui:
    search : 'button.search'
    domain : 'input.domain'

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'
    'click a.add'      : 'addBasicCallTracking'

  bindings:
    '.add': "classes:{'disabled':addButtonDisabled}"

  computeds:
    isSupport:
      get: -> @user().isSupport()

  initialize: (options) =>
    { @qs, @accountId } = options
    @qs.setFragment('call-tracking/basic')
    @model = new Backbone.Model(
      enableNavigation: true
      hasCustomChannel: false
      domain: null
      existingChannels: [])
    @viewModel = new Backbone.Model(
      addButtonDisabled: false
    )

  onRender: =>
    @channelPickerView = new ChannelPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
    )
    @callflowPickerView = new CallflowPickerView(
      accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
    )
    @basicCollection = new BasicCallTrackingСollection()
    @callFlowPicker = @getRegion('callFlowPicker')
    @callFlowPicker.show(@callflowPickerView)
    @channelPicker = @getRegion('channelPicker')
    @channelPicker.show(@channelPickerView)
    @viewModel.set('addButtonDisabled', @user().getSelectedSupportAccountId(true)?.toString() isnt @accountId.toString())
    @qs.bind(
      channel: @channelPicker.currentView
      domain: @ui.domain
      callFlowId: @callFlowPicker.currentView
    )
    @qs.restore()
    @search()

  onDestroy: =>
    @stopListening()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    @collection = new BasicPagedCollection(
      page: @qs.get('page')
      queryParams:
        channel: @channelPicker.currentView.val() ? ''
        domain: @ui.domain.val().trim() ? ''
        callFlowId: @callFlowPicker.currentView.val()
        accountId: if @user().accountPickerRenderable() then @user().getSelectedSupportAccountId(true) else @accountId
      url: 'basic'
    )
    @showChildView('grid', @buildGrid(@collection))
    @showChildView('pager', new PagerView(collection: @collection, qs: @qs))
    @collection.fetch(reset: true)

  buildGrid: =>
    datetime = BackgridCell.dateTime()

    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:smartNumber.id',
      label : ':smartNumber.number',
      attrs : ['smartNumber.id', 'smartNumber.number'])

    flowCell = BackgridCell.href(
      url   : '#call-flows/:callFlow.id',
      label : ':callFlow.name',
      attrs : ['callFlow.id', 'callFlow.name'])

    domainCell = class extends Backgrid.Cell
      render: ->
        info = JSON.parse(@model.get('extraInfo') ? '{}')
        $(@el).html(info?.domain)
        @

    domainHeaderCell = class extends Backgrid.HeaderCell
      onClick: ->
        return
      render: ->
        $(@el).html('<a>Destination Domain</a>')
        @

    columns = [
      {label: 'Account',            name: 'account.name',   cell: 'string', renderable: @getBinding('isSupport')}
      {label: 'Channel',            name: 'name',           cell: 'string'}
      {label: 'Number',             name: 'smartNumber',    cell: numbCell}
      {label: 'Call Flow',          name: 'callFlow',       cell: flowCell}
      {label: 'Destination Domain', name: 'extraInfo',      cell: domainCell, headerCell: domainHeaderCell}
      {label: 'Campaign',           name: 'campaign',       cell: 'string'}
      {label: 'Date Created',       name: 'createdDate',    cell: datetime}
      {label: 'Date Last Used',     name: 'smartNumber.dateLastUsed', cell: datetime}
    ]

    new Backgrid.Grid(
      collection : @collection,
      columns    : columns,
      emptyText  : 'No basic call tracking found.'
    )

  initModal: =>
    @basicCallTrackingModal = new BasicCallTrackingModal(
      accountId: @accountId
      addedChannels: new BaseCollection()
      collection: @basicCollection
      model: @model
    )
    @listenTo(@basicCollection, 'newCallTracking', (basic) =>
      basic['name'] = basic.channel
      @collection?.unshift(basic)
      @collection?.pop()  if @collection.length > 30
      @collection.state.totalRecords += 1
      @collection.trigger('pager:state:changed', @collection.state)
      toastr.info('Basic Call Tracking saved.')
      @channelPickerView.add(basic.name) if basic?.name
      @search() if @collection.length > 30
    ) if not @basicCollection?._events?.newCallTracking
    @listenTo(@basicCallTrackingModal, 'openAddressStepModal', => @initAddressStepModal())

  addBasicCallTracking: =>
    @initModal()
    @showChildView('modal', @basicCallTrackingModal)

  initAddressStepModal: () =>
    @addressCollection = new AddressCollection()
    addressStepModal = new AddressStepModal(
      accountId: @accountId
      addressCollection: @addressCollection
      title: 'Add Basic Call Tracking'
      collection: @basicCollection
    )
    @showChildView('addressStepModal', addressStepModal)
    $('#address-step-modal').modal()
    @listenTo(addressStepModal, 'repeatCreateCallTrackings', (ladda, notCreatedAddresses) =>
      notAddressesNumbers =
        CallTrackingHelper.filterRepeatCollection(notCreatedAddresses, @basicCollection) if notCreatedAddresses.length isnt 0
      @basicCollection.createBasics(@accountId, @model?.get('domain'), notAddressesNumbers, =>
        ladda.stop()
        createdAddresses = @addressCollection.where(created: true)
        @addressCollection.remove(createdAddresses) if createdAddresses.length isnt 0
        $('#address-step-modal').modal('hide') if not notAddressesNumbers or notAddressesNumbers.length is 0
      ))

module.exports = BasicView
