BaseView = require('base/view')
BaseGrid = require('base/grid')
CustomModel = require('features/call-tracking/custom/model')
CallTrackingHelper = require('features/call-tracking/helper')
CallTrackingWidgets = require('features/call-tracking/widgets')
UnassignedNumbersGrid = require('../../__components/unassigned-numbers-grid/view')
CollectionGrid = require('./collection-grid/view')

class CustomCallTrackingModal extends BaseView
  template: require('./template')
  className: 'custom-editor'

  regions:
    collectionGrid: 'div.grid'
    numbersGrid: 'div.numbers-grid'

  ui:
    popup: 'div.popup'

  events:
    'click .add-custom' : 'addCustom'
    'click .finish'     : 'finish'

  bindings:
    '.grid': 'classes:{"unset-main-grid":hasUnset}'

  initialize: (options) =>
    { @accountId, @collection } = options
    @viewModel = new Backbone.Model(hasUnset: @collection.length <= 3)
    @listenTo(@collection, 'change:typeNumber', (model) => @checkChangedTypeNumber(model))
    @activeModelForUnassignedNumber = null

  onRender: =>
    @showSpinner($(@el), left: '45%')
    CallTrackingWidgets.getInfo(@accountId, (campaigns, callflows, countries) =>
      @hideSpinner($(@el))
      @showChildView('collectionGrid', new CollectionGrid(campaigns: campaigns, callflows: callflows, countries: countries, collection: @collection)) if @getRegion('collectionGrid')
    )

  checkChangedTypeNumber: (changedModel) =>
    if changedModel.get('typeNumber') is 'Existing'
      @activeModelForUnassignedNumber = changedModel
      @showChildView('numbersGrid', new UnassignedNumbersGrid(accountId: @accountId, changedModel: changedModel))
    else
      if @activeModelForUnassignedNumber?.cid == changedModel.cid
        @getRegion('numbersGrid').destroy() if @getRegion('numbersGrid')

  addCustom: =>
    @collection.unshift(new CustomModel())
    @viewModel.set(hasUnset: @collection.length <= 3)

  finish: =>
    return if @validate()
    @collection.each((model) =>
      channel = model?.get('channel')

      if channel.startsWith('https://')
        channel = channel.slice('https://'.length)
      if channel.startsWith('http://')
        channel = channel.slice('http://'.length)
      if channel.startsWith('www.')
        channel = channel.slice('www.'.length)

      extraInfo =
        domain: model?.get('destinationURL')
      if channel.startsWith('twitter.com') or channel.startsWith('t.co')
        channel = 'twitter.com'
      else if channel.includes('linkedin.com')
        channel = 'linkedin.com'
      else if channel.includes('facebook.com')
        channel = 'facebook.com'
      type = 'Basic' if channel.startsWith('twitter.com') or channel.startsWith('t.co') or
        channel.includes('linkedin.com') or channel.includes('facebook.com')

      model.set('extraInfo', JSON.stringify(extraInfo))
      model.set('channel', channel)
      model.set('type', type)
    )
    @createCustomCallTrackings()

  createCustomCallTrackings: =>
    ladda = Ladda.create(@$('.finish')[0])
    ladda.start()
    @collection.createCustoms(@accountId, null, (hasNeedAddress) =>
      ladda.stop()
      @trigger('openAddressStepModal') if hasNeedAddress
      @ui.popup.modal('hide'))

  validate: =>
    subjectError = CallTrackingHelper.findErrors(@collection)
    @collection.each((model) =>
      model.trigger('checkLabel') if not model.get('labelTracking')
      model.trigger('checkOriginURL') if not model.get('channel')
      model.trigger('checkDestinationURL') if not model.get('destinationURL')
      subjectError = true if not model.get('labelTracking') or not model.get('channel') or not model.get('destinationURL')
    )
    toastr.warning('Please correct the highlighted errors.') if subjectError
    return subjectError

module.exports = CustomCallTrackingModal
