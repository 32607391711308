BackgridCell      = require('helpers/backgrid-cell')
BackgridRow       = require('helpers/backgrid-row')
BaseView          = require('base/view')
PagedCollection   = require('base/paged-collection')
PagerView         = require('widgets/pager/view')

class NumberCollection extends PagedCollection

  parseRecords: (response, options) =>
    @trigger('pager:state:changed', @state)
    result = []
    for number in response.results
      number.source = null
      number.callflow = null
      number.status = if number.type is 'LocalPresence' then 'Active' else ''
      number.labelColumn = number.label
      if number.entities.length
        number.source = number.entities[0].source
        number.callflow = number.entities[0].callFlow
        number.status = if number.entities[0].inactive then 'Inactive' else 'Active'
      else if number.user
        number.status = 'Active'
      else if number.queue
        number.status = if number.queue.inactive then 'Inactive' else 'Active'
      number.status = 'Deleted' if number.isDeleted
      result.push(number)
    result

module.exports = class SmartNumberSearchView extends BaseView

  template: require('./template')
  className: 'smart-number-search'

  regions:
    grid          : 'div.grid'
    pager         : 'span.pager'

  ui:
    phoneNumber : 'input.phone-number'
    search      : 'button.search'
    searchField : 'div.search-field'
    typeValue   : '.type-of-number'

  computeds:
    isSupport:
      get: ->
        @user().can('smart-numbers:search:*')

  events:
    'click @ui.search' : 'search'
    'keyup input:text' : 'keyPressed'

  initialize: (options) ->
    @qs = options.qs
    @qs.setFragment('smart-numbers')

  onRender: =>
    @qs.bind(
      phoneNumber : @ui.phoneNumber
    )
    @qs.restore()
    @search()

  keyPressed: (e) =>
    @search() if e.keyCode is 13

  search: =>
    return unless @validate()

    collection = new NumberCollection(
      page: @qs.get('page')
      queryParams:
        number    : @ui.phoneNumber.val()
        accountId : @getAccountORNull()
        reassigningNumbers  : false
        type: @ui.typeValue.val() if @ui.typeValue.val() isnt 'All'
      url: 'smartNumbers'
    )

    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))

    collection.fetch(reset: true)

  buildGrid: (collection) ->
    numbCell = BackgridCell.href(
      url   : '#smart-numbers/:id',
      label : ':number',
      attrs : ['id', 'number'])

    acctCell = BackgridCell.accountCell('account.id', 'account.name')

    nameCell = class extends Backgrid.BooleanCell
      render: ->
        if @model.get('user')
          user = @model.get('user')
          url = "#users/#{user.id}"
          label = "#{_.escape(user.displayName)}"
          html = "<a href='#{url}'>#{label}</a>"
        else if @model.get('queue')
          queue = @model.get('queue')
          url = "#call-queues/#{queue.id}"
          label = "#{_.escape(queue.name)}"
          html = "<a href='#{url}'>#{label}</a>"
        else if @model.get('type') is 'Tracking' and @model.get('entities')?.length
          entity = @model.get('entities')[0]
          label = if entity.name then "#{_.escape(entity.name)}" else ''
          html = "<span>#{label}</span>"
        else
          html = '<span></span>'
        $(@el).html(_.template(html))
        @

    callFlowCell = BackgridCell.href(
      url   : '#call-flows/:callflow.id',
      label : ':callflow.name',
      attrs : ['callflow.id', 'callflow.name'])

    datetime = BackgridCell.dateTime()

    renderable = @user().canByUserRole('accounts:edit:*')

    cols = [
      {label: 'Name',       name: 'name',         cell: nameCell}
      {label: 'Type',       name: 'type',         cell: 'string'}
      {label: 'Label',      name: 'labelColumn',  cell: 'string'}
      {label: 'Account',    name: 'account.name', cell: acctCell, renderable: renderable}
      {label: 'Number',     name: 'number',       cell: numbCell}
      {label: 'Source',     name: 'source',       cell: 'string'}
      {label: 'Call Flow',  name: 'callflow',     cell: callFlowCell}
      {label: 'Last Used',  name: 'dateLastUsed', cell: datetime}
      {label: 'Status',     name: 'status',       cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No smart numbers found.'
      row        : BackgridRow.deleted()
    )

  validate: =>
    if @ui.phoneNumber.val() and @ui.phoneNumber.val().length < 3
      toastr.warning('Phone number must be at least three characters.')
      return false

    true
