BaseGrid = require('base/grid')
BaseView = require('base/view')
BaseModel = require('base/model')
BaseCollection  = require('base/collection')
BasicModel = require('features/call-tracking/basic/model')

class Step2Modal extends BaseView
  template: require('./step-2-template')

  regions:
    grid    : 'div.grid'

  events:
    'click .logos': 'selected'
    'click .btn.add-channel': 'addCustomChannel'
    'keyup input.channel' : 'keyPressed'

  ui:
    channel: 'input.channel'

  bindings:
    '.btn.add-channel': 'enabled: hasCustomChannel'
    '.domain': 'text:domain'

  initialize: (options) =>
    { @addedChannels } = options

  onRender: =>
    @searchSelectedChannels()
    @searchExistChannels()
    @showChildView('grid', @buildGrid())

  searchExistChannels: =>
    _.each(@model.get('existingChannels'), (channel) =>
      @$("*[data-name='#{channel}']").addClass('existed')) if @model?.get('existingChannels').length isnt 0

  searchSelectedChannels: =>
    @collection.each((model) =>
      @$("*[data-name='#{model.get('channel')}']").addClass('selected')
    )

  selected: (e) =>
    if e.currentTarget.classList.contains('selected')
      e.currentTarget.classList.remove('selected')
      @removeChannel(e.target.getAttribute('data-name')) if e.target.getAttribute('data-name')
    else
      @addChannel(e.target.getAttribute('data-name')) if e.target.getAttribute('data-name')
      e.currentTarget.classList.add('selected')

  keyPressed: =>
    @model.set('hasCustomChannel', @ui.channel.val().trim().length > 0)

  addCustomChannel: =>
    channel = @collection.findWhere(channel: @ui.channel.val().trim())
    if channel
      toastr.warning('This channel is exist.')
      return
    else if not validator.isURL(@ui.channel.val().trim(), { require_protocol: false, require_host: true })
      toastr.warning("#{ @ui.channel.val().trim() } is invalid. Please enter a valid channel.")
      return
    @addedChannels.add(@addChannel(@ui.channel.val().trim()))

  addChannel: (channel) =>
    model = new BasicModel(
      channel: channel
    )
    @collection.add(model)
    return model

  removeChannel: (channel) =>
    @collection.remove(@collection.findWhere(channel: channel))

  buildGrid: =>

    columns = [
      { name: 'channel', cell: 'string'}
      { name: 'delete',  cell: BaseGrid.DeleteCell}
    ]

    new BaseGrid.Grid(
      collection : @addedChannels,
      columns    : columns
    )

module.exports = Step2Modal
