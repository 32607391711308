var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"x-title\">\n  <h2> Details</h2>\n  <div class=\"navbar-right\">\n    <button class=\"ladda-button btn btn-xs btn-success edit hidden\">Edit</button>\n    <button class=\"ladda-button btn btn-xs btn-success save\" style=\"display:none;\">Save</button>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">User Name</label>\n    <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":42}}}) : helper)))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Account</label>\n    <span class=\"col-md-8\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Title</label>\n    <div class=\"col-md-8\">\n      <input type=\"text\" class=\"form-control userTitle\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":22,"column":63},"end":{"line":22,"column":72}}}) : helper)))
    + "\"/>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Email</label>\n    <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":27,"column":27},"end":{"line":27,"column":36}}}) : helper)))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Email Invalid?</label>\n    <span class=\"col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"emailInvalid") || (depth0 != null ? lookupProperty(depth0,"emailInvalid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emailInvalid","hash":{},"data":data,"loc":{"start":{"line":31,"column":27},"end":{"line":31,"column":43}}}) : helper)))
    + "</span>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\"\">None</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"locations") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":10},"end":{"line":75,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":74,"column":27},"end":{"line":74,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":74,"column":35},"end":{"line":74,"column":43}}}) : helper)))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "</div>\n<div class=\"col-md-6\">\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Time Zone</label>\n    <span class=\"col-md-8\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"timeZoneSidKey") || (depth0 != null ? lookupProperty(depth0,"timeZoneSidKey") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"timeZoneSidKey","hash":{},"data":data,"loc":{"start":{"line":85,"column":27},"end":{"line":85,"column":45}}}) : helper)))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Created Date</label>\n    <span class=\"col-md-8\">"
    + alias3((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"createdDate") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":89,"column":27},"end":{"line":89,"column":57}}}))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Login Date</label>\n    <span class=\"col-md-8\">"
    + alias3((lookupProperty(helpers,"formatUnixTime")||(depth0 && lookupProperty(depth0,"formatUnixTime"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"loginDate") : depth0),{"name":"formatUnixTime","hash":{},"data":data,"loc":{"start":{"line":93,"column":27},"end":{"line":93,"column":55}}}))
    + "</span>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Tags</label>\n    <span class=\"col-md-8\"></span>\n  </div>\n</div>\n<div class=\"clearfix\"></div>\n<br />\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"user") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":33,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"form-group\">\n    <label class=\"col-md-4\">Transcription Language <span class=\"glyphicon glyphicon-question-sign blue\" data-toggle=\"tooltip\" data-original-title=\"Select the preferred language to use when transcribing calls in Conversation AI and Moments™.\"></span></label>\n    <div class=\"col-md-8\">\n      <select class=\"transcriptionLanguage form-control\">\n        <option value=\"zh-CN\">Chinese (China)</option>\n        <option value=\"zh-TW\">Chinese (Taiwan)</option>\n        <option value=\"nl\">Dutch</option>\n        <option value=\"en-US\">English (US)</option>\n        <option value=\"en-AU\">English (Australia)</option>\n        <option value=\"en-GB\">English (Great Britain)</option>\n        <option value=\"en-IN\">English (India)</option>\n        <option value=\"en-NZ\">English (New Zealand)</option>\n        <option value=\"fr\">French</option>\n        <option value=\"fr-CA\">French (Canada)</option>\n        <option value=\"de\">German</option>\n        <option value=\"hi\">Hindi</option>\n        <option value=\"hi-Latn\">Hindi (Roman Script)</option>\n        <option value=\"id\">Indonesian</option>\n        <option value=\"it\">Italian</option>\n        <option value=\"ja\">Japanese</option>\n        <option value=\"ko\">Korean</option>\n        <option value=\"pl\">Polish</option>\n        <option value=\"pt-BR\">Portuguese (Brazil)</option>\n        <option value=\"pt-PT\">Portuguese (Portugal)</option>\n        <option value=\"ru\">Russian</option>\n        <option value=\"es\">Spanish</option>\n        <option value=\"es-419\">Spanish (Latin America)</option>\n        <option value=\"sv\">Swedish</option>\n        <option value=\"tr\">Turkish</option>\n        <option value=\"uk\">Ukrainian</option>\n      </select>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"col-md-4\">Location</label>\n    <div class=\"col-md-8\">\n      <select class=\"location form-control\">\n"
    + ((stack1 = (lookupProperty(helpers,"select")||(depth0 && lookupProperty(depth0,"select"))||container.hooks.helperMissing).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"location") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"select","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":8},"end":{"line":76,"column":19}}})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"user") : depth0),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":0},"end":{"line":103,"column":9}}})) != null ? stack1 : "")
    + "\n<div class=\"user-team-container\"></div>\n<div class=\"clearfix\"></div>\n<br />\n\n<div class=\"clearfix\"></div>\n<div class=\"modal-container\"></div>\n";
},"useData":true});