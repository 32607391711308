import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 50">
      <defs>
        <path
          d="M48.2954545,20.4545455 L25,20.4545455 L25,30.1136364 L38.4090909,30.1136364 C37.1590909,36.25 31.9318182,39.7727273 25,39.7727273 C16.8181818,39.7727273 10.2272727,33.1818182 10.2272727,25 C10.2272727,16.8181818 16.8181818,10.2272727 25,10.2272727 C28.5227273,10.2272727 31.7045455,11.4772727 34.2045455,13.5227273 L41.4772727,6.25 C37.0454545,2.38636364 31.3636364,0 25,0 C11.1363636,0 0,11.1363636 0,25 C0,38.8636364 11.1363636,50 25,50 C37.5,50 48.8636364,40.9090909 48.8636364,25 C48.8636364,23.5227273 48.6363636,21.9318182 48.2954545,20.4545455 Z"
          id="path-1"
        ></path>
        <path
          d="M48.2954545,20.4545455 L25,20.4545455 L25,30.1136364 L38.4090909,30.1136364 C37.1590909,36.25 31.9318182,39.7727273 25,39.7727273 C16.8181818,39.7727273 10.2272727,33.1818182 10.2272727,25 C10.2272727,16.8181818 16.8181818,10.2272727 25,10.2272727 C28.5227273,10.2272727 31.7045455,11.4772727 34.2045455,13.5227273 L41.4772727,6.25 C37.0454545,2.38636364 31.3636364,0 25,0 C11.1363636,0 0,11.1363636 0,25 C0,38.8636364 11.1363636,50 25,50 C37.5,50 48.8636364,40.9090909 48.8636364,25 C48.8636364,23.5227273 48.6363636,21.9318182 48.2954545,20.4545455 Z"
          id="path-3"
        ></path>
        <path
          d="M48.2954545,20.4545455 L25,20.4545455 L25,30.1136364 L38.4090909,30.1136364 C37.1590909,36.25 31.9318182,39.7727273 25,39.7727273 C16.8181818,39.7727273 10.2272727,33.1818182 10.2272727,25 C10.2272727,16.8181818 16.8181818,10.2272727 25,10.2272727 C28.5227273,10.2272727 31.7045455,11.4772727 34.2045455,13.5227273 L41.4772727,6.25 C37.0454545,2.38636364 31.3636364,0 25,0 C11.1363636,0 0,11.1363636 0,25 C0,38.8636364 11.1363636,50 25,50 C37.5,50 48.8636364,40.9090909 48.8636364,25 C48.8636364,23.5227273 48.6363636,21.9318182 48.2954545,20.4545455 Z"
          id="path-5"
        ></path>
        <path
          d="M48.2954545,20.4545455 L25,20.4545455 L25,30.1136364 L38.4090909,30.1136364 C37.1590909,36.25 31.9318182,39.7727273 25,39.7727273 C16.8181818,39.7727273 10.2272727,33.1818182 10.2272727,25 C10.2272727,16.8181818 16.8181818,10.2272727 25,10.2272727 C28.5227273,10.2272727 31.7045455,11.4772727 34.2045455,13.5227273 L41.4772727,6.25 C37.0454545,2.38636364 31.3636364,0 25,0 C11.1363636,0 0,11.1363636 0,25 C0,38.8636364 11.1363636,50 25,50 C37.5,50 48.8636364,40.9090909 48.8636364,25 C48.8636364,23.5227273 48.6363636,21.9318182 48.2954545,20.4545455 Z"
          id="path-7"
        ></path>
      </defs>
      <g id="01-admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01b_account_connected_accounts-(enabled)" transform="translate(-432.000000, -912.000000)">
          <g id="Gmail" transform="translate(276.000000, 869.000000)">
            <g id="Google__G__Logo" transform="translate(156.000000, 43.000000)">
              <g id="Clipped">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="a"></g>
                <polygon
                  id="path"
                  fill="#FBBC05"
                  mask="url(#mask-2)"
                  points="-2.27272727 39.7727273 -2.27272727 10.2272727 17.0454545 25"
                ></polygon>
              </g>
              <g id="Clipped">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="a"></g>
                <polygon
                  id="path"
                  fill="#EA4335"
                  mask="url(#mask-4)"
                  points="-2.27272727 10.2272727 17.0454545 25 25 18.0681818 52.2727273 13.6363636 52.2727273 -2.27272727 -2.27272727 -2.27272727"
                ></polygon>
              </g>
              <g id="Clipped">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <g id="a"></g>
                <polygon
                  id="path"
                  fill="#34A853"
                  mask="url(#mask-6)"
                  points="-2.27272727 39.7727273 31.8181818 13.6363636 40.7954545 14.7727273 52.2727273 -2.27272727 52.2727273 52.2727273 -2.27272727 52.2727273"
                ></polygon>
              </g>
              <g id="Clipped">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7" />
                </mask>
                <g id="a"></g>
                <polygon
                  id="path"
                  fill="#4285F4"
                  mask="url(#mask-8)"
                  points="52.2727273 52.2727273 17.0454545 25 12.5 21.5909091 52.2727273 10.2272727"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
