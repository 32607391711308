import React from 'react'

import { ActionType, useSupportSettingsDispatch } from '../../../utils/contextReducer'
import RdnaFilePicker from '@ringdna/common/src/components/RdnaFilePicker'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'

type SupportSettingFileRowProps = {
  accountSetting: string
  description?: string
  existingFile: string
  title: string
}

const SupportSettingFileRow = ({
  title,
  description = '',
  existingFile,
  accountSetting
}: SupportSettingFileRowProps) => {
  const dispatch = useSupportSettingsDispatch()

  const handleChange = (e: React.SetStateAction<any>) => {
    if (!e.fileName) {
      dispatch({ type: ActionType.CHANGE_ACCOUNT_SETTINGS, payload: { ['settings.' + accountSetting]: '' } })
      dispatch({ type: ActionType.REMOVE_FROM_AUDIO_UPLOAD_SETTINGS, payload: accountSetting })
    } else {
      const data = {
        file: e.fileBinary,
        fileName: e.fileName,
        setting: accountSetting
      }
      dispatch({ type: ActionType.REMOVE_FROM_AUDIO_UPLOAD_SETTINGS, payload: accountSetting })
      dispatch({ type: ActionType.ADD_TO_AUDIO_UPLOAD_SETTINGS, payload: data })
    }
  }

  return (
    <RdnaSettingRow heading={title} description={description}>
      <RdnaFilePicker
        fileType=".mp3"
        name="picker"
        existingFile={existingFile}
        onChange={handleChange}
        placeholderText="File can be no more than 10 MB"
        mbFileSizeLimit={10}
        onError={error => toastr.error(error)}
        variant="outlined"
        color="action"
      />
    </RdnaSettingRow>
  )
}
export default React.memo(SupportSettingFileRow)
