BaseGrid = require('base/grid')
BaseView = require('base/view')
UserCallQueueModal = require('./editor/view')
UserCallQueueModel = require('./model')
UserCallQueueCollection = require('./collection')
BackgridCell      = require('helpers/backgrid-cell')

class UserCallQueueView extends BaseView

  template: require('./template')

  className: 'userCallQueue'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.edit-user-call-queue': 'showEditor'

  bindings:
    '.edit-user-call-queue .glyphicon-plus-sign' : 'toggle:any(isSupport,isAdmin)'

  computeds:
    isAdmin:
      get: -> App.session.user.isAdmin()
    isSupport:
      get: -> App.session.user.isSupport()
    isAgent:
      get: -> App.session.user.isAgent()

  initialize: (options) ->
    { @user } = options
    @collection = new UserCallQueueCollection(@user.get('userCallQueues').filter((ucqs) -> not ucqs.queue.isDeleted))

  onRender: ->
    @showChildView('grid', @buildGrid())

  buildGrid: =>
    SubscribedCell = class extends Backgrid.StringCell
      render: ->
        $(@el).empty()
        subscription = if @model.get('rememberSubscription') then 'Yes' else 'No'
        $(@el).text(subscription)
        @delegateEvents()
        @

    linkToQueueCell = if @getBinding('isAgent') then 'String' else BackgridCell.href(
      url   : '#call-queues/:queue.id',
      label : ':queue.name',
      attrs : ['queue.id', 'queue.name'])

    columns = [
      { name: 'queue.name',           label: 'Name',        cell: linkToQueueCell },
      { name: 'queue.description',    label: 'Description', cell: 'String' },
      { name: 'rememberSubscription', label: 'Subscribed',  cell: SubscribedCell },
      { name: '',                     label: 'Delete',      cell: BaseGrid.DeleteCell, renderable: @getBinding('isSupport') or @getBinding('isAdmin') }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No User Call Queues Found.'
      onEdit     : @showEditor
    )

  showEditor: =>
    @showChildView('modal', new UserCallQueueModal(
      userCallQueueCollection: @collection
      user: @user
    ))

module.exports = UserCallQueueView
