import React, { useState } from 'react'
import styled from 'styled-components'
import { Refetch } from '@ringdna/client/src/constants'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import useToast from '../../common/hooks/useToast'
import { usePostMsTeamsAccountId, TeamsScriptResponse, AccountIdQuery } from './api'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'

export const SUPPORT_EMAIL = 'support@revenue.io'
export const BODY =
  'To enable the Microsoft Teams integration with Revenue.io, contact your Customer Success Manager or email'
const TEAMS_INPUT_LABEL = 'Tenant Id'
const TEAMS_INPUT_PLACEHOLDER = 'Enter Tenant Id or Meeting Url'
const SUBMIT_CTA = 'Submit'
const TENANT_ID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const TENANT_URL_REGEX = /"Tid":"([^"]+)/

const extractTenantIdFromUrl = (val: string) => {
  const match = decodeURIComponent(val).match(TENANT_URL_REGEX)
  return match ? match[1] : ''
}

const getTenantId = (val: string) => (TENANT_ID_REGEX.test(val) ? val : extractTenantIdFromUrl(val))

type Props = {
  accountId: string
  refetchScriptEnabled: Refetch<TeamsScriptResponse, AccountIdQuery>
}

export default function TeamsEnablement({ accountId, refetchScriptEnabled }: Props) {
  const toastr = useToast()
  const [idInput, setIdInput] = useState('')
  const msTeamsAccountId = getTenantId(idInput)

  const postMsTeamsAccountId = usePostMsTeamsAccountId()

  const onSubmitHandler = () => {
    const submitMsTeamsAccountId = async () => {
      try {
        await postMsTeamsAccountId({ json: { accountId, msTeamsAccountId } })
        refetchScriptEnabled()
      } catch (err) {
        /* istanbul ignore next */
        toastr.error(getErrorMessage(err))
      }
    }
    submitMsTeamsAccountId()
  }

  return (
    <Container>
      <RdnaText>{BODY}&nbsp;</RdnaText>
      <RdnaText href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</RdnaText>
      <RdnaText>.</RdnaText>
      {isFlagged(flags.TEAMS_ACCOUNT_FORM) && (
        <>
          <RdnaTextInput
            label={TEAMS_INPUT_LABEL}
            name="name"
            onChange={({ target: { value } }) => setIdInput(value)}
            placeholder={TEAMS_INPUT_PLACEHOLDER}
            value={idInput}
          />
          <RdnaButton text={SUBMIT_CTA} onClick={onSubmitHandler} disabled={!msTeamsAccountId} />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing * 3}px 0;
  & > .MuiFormControl-root {
    margin-top: ${({ theme }) => theme.spacing * 4}px;
    max-width: 25%;
    display: block;
  }
`
