import React from 'react'

import { Colors } from '../../../constants/colors'

type Props = {
  size?: number
  color?: Colors
  borderColor?: Colors
  backgroundColor?: Colors
  checkColor?: Colors
}

function CirclePlus({
  size = 24,
  color = Colors.BLACK,
  borderColor,
  backgroundColor = Colors.WHITE,
  checkColor
}: Props) {
  borderColor = borderColor || color
  checkColor = checkColor || color

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={backgroundColor}>
      <path
        d="M23.5 12c0 6.351-5.149 11.5-11.5 11.5S.5 18.351.5 12 5.649.5 12 .5 23.5 5.649 23.5 12z"
        stroke={checkColor}
      />
      <path d="M12 6v12M18 12H6" stroke={borderColor} strokeLinecap="round" />
    </svg>
  )
}

export default CirclePlus
