import React, { useEffect, useState } from 'react'

// utils
import { Dispatch, useCallRecordingDispatch } from '../../utils/contextReducer'
import { CallRecordingSetting } from '@ringdna/common/src/types/call-recording'
import { useCallRecordingSettings } from '../../../../store/api/call-recording-api'

// components
import { FixedSizeList, areEqual, ListChildComponentProps } from 'react-window'
import { RdnaTable, RdnaTableCell, RdnaTableHead } from '@ringdna/common/src/components/RdnaTable'
import RdnaLoader, { NoResultsMsg } from '@ringdna/common/src/components/RdnaLoader'
import { BinocularsIconLeaves } from '@ringdna/common/src/assets/illustrations'
import InternationalTableRows from './InternationalTableRows'
import RdnaAccordion from '@ringdna/common/src/components/RdnaAccordion'
import RdnaAccordionRow from '@ringdna/common/src/components/RdnaAccordion/RdnaAccordionRow'

const continents = ['North America', 'South America', 'Europe', 'Asia', 'Oceania', 'Africa'] as const

type InternationalCardsProps = {
  accountId: string
}

export default React.memo(InternationalCards)
function InternationalCards({ accountId }: InternationalCardsProps) {
  const [settings, , , ,] = useCallRecordingSettings({ meta: { id: accountId, isUS: false } })
  const continentMap = createContinentMap(settings as CallRecordingSetting[])
  const dispatch = useCallRecordingDispatch()
  const [haveData, setHaveData] = useState(false)

  useEffect(() => {
    if (settings) {
      storeContinentSettings(dispatch, settings as CallRecordingSetting[], accountId)
      setHaveData(true)
    }
  }, [settings, dispatch, accountId])

  return (
    <RdnaLoader
      data={settings}
      noResultsMsg={
        <NoResultsMsg
          title="Something went wrong, try refreshing!"
          subtitle="We couldn't find your call recording settings."
          Icon={<BinocularsIconLeaves />}
        />
      }
      loading={!haveData}
      top="100px"
    >
      <RdnaAccordion border={'all'}>
        {continents.map(continent => (
          <RdnaAccordionRow key={continent} headContent={continent}>
            <RdnaTable $shadedHead>
              <RdnaTableHead>
                <RdnaTableCell>Record Calls</RdnaTableCell>
                <RdnaTableCell>Play Outbound Recording</RdnaTableCell>
                <RdnaTableCell>Country</RdnaTableCell>
              </RdnaTableHead>
              <FixedSizeList
                height={520}
                width="100%"
                itemSize={50}
                itemCount={continentMap[continent].length}
                className={'styled-scrollbar'}
              >
                {React.memo(function Rows({ index, style }: ListChildComponentProps) {
                  return (
                    // @ts-ignore
                    <div style={style}>
                      <InternationalTableRows country={continentMap[continent][index]} />
                    </div>
                  )
                }, areEqual)}
              </FixedSizeList>
            </RdnaTable>
          </RdnaAccordionRow>
        ))}
      </RdnaAccordion>
    </RdnaLoader>
  )
}

// helpers
const createContinentMap = (settings: CallRecordingSetting[] | undefined) => {
  const continentMap = {
    'North America': [],
    'South America': [],
    Europe: [],
    Asia: [],
    Oceania: [],
    Africa: []
  } as any

  if (!settings) return continentMap

  settings.forEach(country => {
    if (continentMap[country.continent]) {
      continentMap[country.continent].push(country)
    }
  })

  return continentMap
}

const storeContinentSettings = (dispatch: Dispatch, settings: CallRecordingSetting[], accountId: string) => {
  const continentRecordingSettings = { accountId, isUS: false }

  settings.forEach(country => {
    const { setting } = country

    // @ts-ignore
    continentRecordingSettings[`items[${setting.countryId - 1}].id`] = setting.countryId
    // @ts-ignore
    continentRecordingSettings[`items[${setting.countryId - 1}].recordType`] = setting.recordType
    // @ts-ignore
    continentRecordingSettings[`items[${setting.countryId - 1}].outboundAnnouncement`] = setting.outboundAnnouncement
  })

  // This is a temp fix, we need to inject this hardcoded data into the reducer to be a placeholder for
  // the United States in the International table
  // @ts-ignore
  continentRecordingSettings[`items[229].id`] = 230
  // @ts-ignore
  continentRecordingSettings[`items[229].recordType`] = 'NoRecording'
  // @ts-ignore
  continentRecordingSettings[`items[229].outboundAnnouncement`] = false

  dispatch({ type: 'STORE_CONTINENT_RECORDING_SETTINGS', payload: continentRecordingSettings })
}
