import React from 'react'
import SalesforceTabs from './Salesforce'

type SalesforceProviderProps = {
  accountId: number
}

export default SalesforceProvider
export function SalesforceProvider({ accountId }: SalesforceProviderProps) {
  return <SalesforceTabs accountId={accountId} />
}
