import { Colors } from '../../colors'
import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseTransitions from '../baseTransitions'

const palette = {
  ...basePalette,
  primary: {
    contrastText: Colors.N0,
    main: Colors.V30,
    dark: Colors.V50,
    light: Colors.N30
  },
  text: {
    ...basePalette.text,
    link: Colors.V50
  },
  icon: {
    ...basePalette.icon,
    link: Colors.V50
  },
  illustrations: {
    20: Colors.V20,
    30: Colors.V30,
    40: Colors.V40,
    50: Colors.V50,
    60: Colors.V60,
    70: Colors.V70,
    80: Colors.V80
  }
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const zIndex = {
  appBar: 1200,
  drawer: 1100
}

const theme = {
  palette,
  typography,
  transitions,
  zIndex,
  spacing: 6
}

export default theme
