BaseGrid = require('base/grid')
BaseView = require('base/view')
LocationModal = require('./editor/view')
LocationModel = require('./model')
LocationCollection = require('./collection')

class LocationsView extends BaseView

  template: require('./template')

  className: 'locations'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add' : 'createLocation'

  initialize: (options) ->
    { @accountId } = options
    @collection = new LocationCollection()
    @collection.bind('change add remove reset', @locationCollectionChanged)

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        return unless @getRegion('grid')
        @showChildView('grid', @buildGrid())
        @trigger('LocationsView', @collection)
      )

  locationCollectionChanged: =>
    @trigger('LocationsView', @collection)

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click button[data-action="update"]': 'edit'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      render: ->
        $(@el).html('''
          <button class="btn btn-primary update-btn" data-action="update" data-toggle="modal" data-target="#edit-location-modal">
            Update
          </button>
        ''')
        @

    columns = [
      { name: 'name',        label: 'Location',     cell: 'string' },
      { name: 'description', label: 'Description',  cell: 'string' },
      { name: 'address',     label: 'Address',      cell: 'string' },
      { name: '',             label: 'Update',      cell: actionCell },
      { name: '',            label: 'Delete',       cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No locations found.'
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    model = options.model.clone()
    @showChildView('modal', new LocationModal(
      model: model
      collection: @collection
    ))

  createLocation: =>
    addLocationModal = new LocationModal(
      accountId: @accountId
      model: new LocationModel()
      collection: @collection
    )
    @showChildView('modal', addLocationModal)

module.exports = LocationsView