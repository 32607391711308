import React, { useEffect, useState, useContext } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import useToast, { PredefinedOptions } from '../../../common/hooks/useToast'
import { useNylasUsers, useDeleteNylasUser } from '../api/api'
import { UserContext, UserContextType } from '../../../common/services/UserContext'
import { NylasUser } from '../model'
import { Provider } from '../constants'
import UiCenteredBox from '@ringdna/common/src/components/layout/UiCenteredBox'
import CircularProgress from '@mui/material/CircularProgress'
import NylasUsersTable from './NylasUsersTable'

type NylasUsersContainerProps = {
  selectedProvider: Provider
  handleChange: (providerValue: Provider) => void
  accountId: number
  refresh: () => void
}

export default function NylasUsersContainer({
  selectedProvider = Provider.ALL,
  accountId,
  handleChange,
  refresh
}: NylasUsersContainerProps) {
  const toastr = useToast()
  const { isSupport } = useContext<UserContextType>(UserContext)
  const [provider, setProvider] = useState<Provider>(selectedProvider)
  const [filteredNylasUsers, setFilteredNylasUsers] = useState<NylasUser[]>([])
  const [nylasUserPayload = [], error, loading, refetch] = useNylasUsers({
    query: { accountId: accountId, provider: Provider.ALL }
  })
  const deleteNylasUser = useDeleteNylasUser()

  useDeepCompareEffect(() => {
    setFilteredNylasUsers(
      !provider
        ? nylasUserPayload.filter(user => user.provider === Provider.GMAIL || user.provider === Provider.EXCHANGE)
        : nylasUserPayload.filter(user => user.provider === provider)
    )
  }, [nylasUserPayload, setFilteredNylasUsers, provider])

  useEffect(() => {
    setProvider(selectedProvider)
  }, [selectedProvider])

  useEffect(() => {
    error && toastr.warning(error.message)
  }, [error, toastr])

  const handleProviderChange = (providerValue: Provider) => {
    setProvider(providerValue)
    handleChange(providerValue)
  }

  const removeUser = (user: NylasUser) => {
    removeNylasUser(user.id)
  }

  const removeNylasUser = async (id: number) => {
    try {
      await deleteNylasUser({ id, body: {} })
      refetch()
      refresh()
    } catch (error) {
      toastr.error(error.message, PredefinedOptions.centered)
    }
  }

  return (
    <>
      {loading && (
        <UiCenteredBox>
          <CircularProgress />
        </UiCenteredBox>
      )}
      {!loading && (
        <>
          <NylasUsersTable
            handleProviderChange={handleProviderChange}
            provider={provider}
            nylasUsers={filteredNylasUsers}
            removeUser={removeUser}
            isSupport={isSupport}
          />
        </>
      )}
    </>
  )
}
