import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const Coaching = ({ color = Colors.N100, size = 30 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 4H13.5C16.5376 4 19 6.46243 19 9.5C19 12.5376 16.5376 15 13.5 15L11 17.5V15H7.5C4.46243 15 2 12.5376 2 9.5C2 6.46243 4.46243 4 7.5 4Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M20.5181 7.74438C21.4373 8.72741 22 10.048 22 11.5C22 14.5376 19.5376 17 16.5 17L14 19.5V17"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Coaching
