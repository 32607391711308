class BaseModel extends Backbone.Epoxy.Model

  delete: (options) =>
    @destroy(options)
    .done((response) => @set(response))

  persist: (options) =>
    defaults = data: @toJSON(), emulateJSON: true
    options = _.extend(defaults, options)

    @save({}, options)

  undelete: (options) =>
    defaults = data: {}, url: "#{@urlRoot}/#{@id}/undelete"
    options = _.extend(defaults, options)

    @persist(options)
    .done((response) => @set(response))

  upload: (options) =>
    defaults = data: @toJSON(), emulateJSON: true, upload: true
    options = _.extend(defaults, options)

    @save({}, options)

  # Prefix attribute names as expected by the Play framework.
  toPlayJSON: (prefix, attrs = @attributes) =>
    target = {}
    target["#{prefix}.#{key}"] = value for key, value of attrs
    target

module.exports = BaseModel
