BaseView = require('base/view')
BaseCollection = require('base/collection')
TeamsPicker = require('widgets/teams-picker/view')

class UserStatusModal extends BaseView
  template: require('./template')
  className: 'user-status-editor'

  ui:
    modal: '#user-status-modal'
    go: 'button.persist'

  regions:
    teamPicker: 'span.team-picker'

  events:
    'click @ui.go': 'save'

  bindings:
    '.name': 'value:name'
    '.description': 'value:description'
    '.busy': 'value:busy,options:busyOptions'
    '.teamId': 'value:teamId'
    '.defaultExpiration': 'value:defaultExpiration'
    '.add': 'text:buttonText'
    '.modal-title': 'text:modalTitle'

  computeds:
    busyOptions:
      get: ->
        [
          {label: 'Yes', value: 'true'}
          {label: 'No', value: 'false'}
        ]

    buttonText:
      get: -> if @isEdit then 'Update' else 'Add'

    modalTitle:
      get: -> if @isEdit then 'Edit User Status' else 'Create New User Status'

  initialize: (options) =>
    { @accountId, @isEdit, @existingTeams } = options
    @listenTo(@model, 'invalid', (model, error) -> toastr.warning(error))
    @addedTeams = new BaseCollection(@model?.get('teams').slice(0))

  onRender: =>
    @teamPicker = new TeamsPicker(
      list: @existingTeams
      addedTeams: @addedTeams
      placeholder: 'All (Default)'
    )
    @listenTo(@teamPicker, 'select', (value) =>
      model = @existingTeams.findWhere(id: parseInt(value)) if value
      @addedTeams.add(model) if model
    )
    @listenTo(@teamPicker, 'deselect', (value) =>
      @addedTeams.remove(parseInt(value)) if value
    )
    @showChildView('teamPicker', @teamPicker)

  save: =>
    data = @model.toJSON()
    data['accountId'] = @accountId
    data['teamIds'] = @addedTeams.pluck('id')

    @model
      .persist(
        data: data
        ladda: @ui.go[0]
      ).done?(=>
        @ui.modal.modal('hide')
        @model.set(teams: @addedTeams)
        @collection.add(@model, { at: @collection.models.length, merge: true })
        toastr.info(if @isEdit then 'User status updated.' else 'User status added.')
      )

module.exports = UserStatusModal
