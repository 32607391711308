import React, { useState } from 'react'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import RdnaHeaderSection from '@ringdna/common/src/components/RdnaHeaderSection'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaFormLayout from '@ringdna/common/src/components/RdnaFormElements/RdnaFormLayout'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RolesTable from './RolesTable'
import { useRoles } from '../api'
import { UNASSIGNED_USERS_TEXT } from '../const'
import { Role } from '../types'
import RoleDefinitionsPanel from './RoleDefinitionsPanel'
import AutoMapRolesPanel from './AutoMapRolesPanel'
import { RoleDrawer } from '../styles'
import AssignmentUserRoleDrawer from './AssignmentUserRoleDrower'

export type RolesProps = {
  accountId: number
  roleDefinitions: Role[]
}

export default function Main({ accountId, roleDefinitions }: RolesProps) {
  const [isRoleDrawerOpen, setIsRoleDrawerOpen] = useState(false)
  const [isAutoMapDrawerOpen, setIsAutoMapDrawerOpen] = useState(false)
  const [isAssignUsersDrawerOpen, setIsAssignUsersDrawerOpen] = useState(false)
  const [payload, , loading, refetch] = useRoles({
    meta: { accountId: accountId }
  })

  const closeDrawer = () => {
    setIsRoleDrawerOpen(false)
    setIsAutoMapDrawerOpen(false)
    setIsAssignUsersDrawerOpen(false)
  }

  const openRoleDefinition = () => {
    setIsRoleDrawerOpen(true)
  }

  const onAutoMap = async () => {
    setIsAutoMapDrawerOpen(true)
  }

  const onAssignUsers = async () => {
    setIsAssignUsersDrawerOpen(true)
  }

  return (
    <RdnaLoader loading={!!loading} top="150px" data="data">
      <RdnaHeaderSection
        headingText={`Roles`}
        rightColumn={
          <div>
            <RdnaButton text={'Auto Map SFDC Roles'} variant="outlined" onClick={onAutoMap}></RdnaButton>
            <RdnaButton text={'Assign Users'} onClick={onAssignUsers}></RdnaButton>
          </div>
        }
      />
      <RdnaFormLayout>
        <RdnaText style={{ display: 'block' }}>
          Set &quot;
          <RdnaText color="link" onClick={openRoleDefinition}>
            Role
          </RdnaText>
          &quot; values for users in your account.
        </RdnaText>
        <RdnaText bold>{`${UNASSIGNED_USERS_TEXT}`}</RdnaText>
        <RdnaText>{`${payload?.listUnassignedUsers != null ? payload?.listUnassignedUsers.length : 0}`}</RdnaText>
      </RdnaFormLayout>
      <RolesTable payload={payload?.listRoles || []} loading={!!loading} />
      <RoleDefinitionsPanel open={isRoleDrawerOpen} onClose={closeDrawer} roleDefinitions={roleDefinitions} />
      <RoleDrawer open={isAutoMapDrawerOpen} onClose={closeDrawer}>
        <AutoMapRolesPanel
          onClose={closeDrawer}
          roleDefinitions={roleDefinitions}
          accountId={accountId}
          refetchRoleTable={refetch}
        />
      </RoleDrawer>
      <AssignmentUserRoleDrawer
        isOpen={isAssignUsersDrawerOpen}
        onClose={closeDrawer}
        refetch={refetch}
        roleDefinitions={roleDefinitions}
        listUnassignedUsers={payload?.listUnassignedUsers || []}
        accountId={accountId}
      />
    </RdnaLoader>
  )
}
