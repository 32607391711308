import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import RdnaTextInput from '@ringdna/common/src/components/RdnaFormElements/RdnaTextInput'
import RdnaSmartTable from '@ringdna/common/src/components/RdnaTable/RdnaSmartTable'
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import {CallDispositionTeam, Disposition, DispositionSetting} from '../../../types'
import {NO_RESULT_STATE, optionsOutcomeCategory} from './const'
import {useCallDispositionsUpdate, useCallDispositionTeams} from '../../../api'
import RdnaCheckbox from '@ringdna/common/src/components/RdnaFormElements/RdnaCheckbox'
import { Column } from 'react-table'
import { getErrorMessage } from '@ringdna/common/src/utils/errorUtils'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaDrawer from '@ringdna/common/src/components/RdnaDrawer'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import { serializeJsonIntoPlay } from '@ringdna/common/src/utils/serverUtils'
import { useRdnaNotification } from '@ringdna/common/src/components/RdnaNotification/RdnaNotificationContext'

type DispositionProps = {
  open: boolean
  accountId: number
  disposition: Disposition
  setDisposition: (value: Disposition) => void
  onClose: (isFetch: boolean) => void
}

export default function ManageCallDispositionsModal({ open, accountId, disposition, setDisposition, onClose }: DispositionProps) {
  const [processing, setProcessing] = useState<boolean>(false)
  const { error } = useRdnaNotification()
  const updateCallDisposition = useCallDispositionsUpdate()
  const [payload,,loading,] = useCallDispositionTeams({meta:{accountId: accountId, dispositionId: disposition.id}})

  useEffect(() => {
    if (payload && open) {
      setDisposition({...disposition, teams: payload.results})
    }
  }, [payload, open])

  const onChangeCheckbox = useCallback(
    (inbound: boolean, checked: boolean, id: number) => {
      if (inbound) {
        setDisposition({...disposition, teams: disposition.teams.map(model => model.id === id ? {...model, inbound: checked } : model)})
      } else {
        setDisposition({...disposition, teams: disposition.teams.map(model => model.id === id ? {...model, outbound: checked } : model)})
      }
    },
    [disposition]
  )

  const onChangeHeaderCheckbox = useCallback(
    (inbound: boolean, checked: boolean) => {
      if (inbound) {
        setDisposition({...disposition, teams: disposition.teams.map(model => { return  {...model, inbound: checked }})})
      } else {
        setDisposition({...disposition, teams: disposition.teams.map(model => { return  {...model, outbound: checked }})})
      }
    },
    [disposition]
  )

  const columns = useMemo<Column<CallDispositionTeam>[]>(
    () => [
      {
        Header: 'Team Name',
        accessor: 'name'
      },
      {
        accessor: 'inbound',
        disableSortBy: true,
        Header: function headerCell(headerProp) {
          return (
            <RdnaCheckbox
              data-testid={'header-setting-disposition-inbound'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(true, checked)}
              value={!loading && headerProp.data.every(value => value.inbound)}
              disabled={!!loading}
              label={'Inbound'}
              labelPlacement={'end'}
              indeterminate={!loading && !headerProp.data.every(value => value.inbound) && !headerProp.data.every(value => !value.inbound)}
            />
          )
        },
        Cell: function createdCell(cellProp) {
          return (
            <RdnaCheckbox
              data-testid={'cell-setting-disposition-inbound'}
              className={'checkbox-inbound'}
              onChange={(checked: boolean) => onChangeCheckbox(true, checked, cellProp.row.original.id)}
              value={cellProp.value}
            />
          )
        }
      },
      {
        accessor: 'outbound',
        disableSortBy: true,
        Header: function headerCell(headerProp) {
          return (
            <RdnaCheckbox
              data-testid={'header-setting-disposition-outbound'}
              onChange={(checked: boolean) => onChangeHeaderCheckbox(false, checked)}
              value={!loading && headerProp.data.every(value => value.outbound)}
              disabled={!!loading}
              label={'Outbound'}
              labelPlacement={'end'}
              indeterminate={!loading && !headerProp.data.every(value => value.outbound) && !headerProp.data.every(value => !value.outbound)}
            />
          )
        },
        Cell: function createdCell(cellProp) {
          return (
            <RdnaCheckbox
              data-testid={'cell-setting-disposition-outbound'}
              className={'checkbox-outbound'}
              onChange={(checked: boolean) => onChangeCheckbox(false, checked, cellProp.row.original.id)}
              value={cellProp.value}
            />
          )
        }
      }
    ],
    [disposition, loading]
  )

  async function handleInsert() {
    setProcessing(true)
    try {
      const dispositionSettings: DispositionSetting[] = disposition.teams.map(dteam => {
        return {id: dteam.callDispositionTeamId, team: {id : dteam.id}, inbound: dteam.inbound, outbound: dteam.outbound }
      })
      const playDisposition = disposition.id != 0 ? {
        id: disposition.id,
        disposition: disposition.disposition,
        outcomeCategory: disposition.outcomeCategory === 'Uncategorized' || disposition.outcomeCategory === null ? '' : disposition.outcomeCategory
      } : {
        disposition: disposition.disposition,
        outcomeCategory: disposition.outcomeCategory === 'Uncategorized' || disposition.outcomeCategory === null ? '' : disposition.outcomeCategory
      }
      await updateCallDisposition({body: serializeJsonIntoPlay({ accountId: accountId,  settings: JSON.stringify(dispositionSettings), disposition: playDisposition})})
    } catch (e) {
      error(getErrorMessage(e))
    }
    setProcessing(false)
    onClose(true)
  }

  return (
    <CallDispositionsDrawer
      open={open}
      heading={!disposition?.id ? 'Add Call Disposition' : 'Edit Call Disposition'}
      onClose={() => onClose(false)}
    >
      <StyledSection>
        <StyledDiv>
          <RdnaTextInput
            name={'call-disposition-name'}
            label={'Name'}
            placeholder={'Disposition Name'}
            required
            disabled={disposition.id != 0}
            onChange={e => {
              setDisposition({ ...disposition, disposition: e.target.value })
            }}
            value={disposition?.disposition}
            style={{marginBottom: 30}}
          />
          <RdnaSelect
            name={'call-disposition-outcome-category'}
            label={'Outcome Category'}
            options={optionsOutcomeCategory}
            value={disposition.outcomeCategory ? disposition.outcomeCategory : 'Uncategorized'}
            onChange={event => {
              setDisposition({ ...disposition, outcomeCategory: event.target.value })
            }}
            style={{marginBottom: 30}}
          />
        </StyledDiv>
        <RdnaText bold>Assign Disposition to Teams</RdnaText>
        <RdnaSmartTable
          searchPlaceholder="Search"
          columns={columns}
          data={disposition.teams}
          noResultState={NO_RESULT_STATE}
          isFetching={!!loading}
          paginationSize={10}
        />
      </StyledSection>
      <Footer>
        <RdnaButton
          text={'Cancel'}
          color="neutral"
          variant="text"
          onClick={() => onClose(false)}
        />
        <RdnaButton
          data-testid={'save-edit-call-dispositions-button'}
          text={'Save'}
          onClick={handleInsert}
          disabled={!disposition?.disposition || !!loading}
          processing={processing}
        ></RdnaButton>
      </Footer>
    </CallDispositionsDrawer>
  )
}

const StyledSection = styled.section`
  margin: 0 0 ${({ theme }) => 3 * theme.spacing}px;
`

const StyledDiv = styled.div`
  max-width: 50%;
`

const CallDispositionsDrawer = styled(RdnaDrawer)`
  > div {
    min-width: 50%;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: end;
`
