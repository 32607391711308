Handlebars = require('handlebars/runtime').default
Handlebars.registerHelper('formatPhoneNumber', (val, options) ->
  phoneRegEx = /(?:\+?1[-. ]?)?\(?(?:[1-9]{1}[0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})|(?:\+|00|011[-. ]?)?[0-9\.\- \(\)]{7,}/g
  if val
    val = new String(val)
    return val if val.trim().startsWith('sip:')
    res = phoneRegEx.exec(val)
    if /\s*(x|ext|,)\s*\d+$/i.test(val) and res
      ext = s.strRight(val, res[0])
    val = res[0] if res and not /^\d+$/.test(s.trim(s.strRight(val, res[0])))
    val = val.replace(/[^\d+,]/g, '')
    start = 0
    if val.indexOf('+1') == 0
      start = 2
    else if val.indexOf('1') == 0
      start = 1
    val = val.substring(start, val.length)
    if val.length == 10 and val[0] != '+'
      result = val.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    else
      result = val
    if ext then s.join(' ', s.trim(result), s.trim(ext)) else result
  else
    val
)

Handlebars.registerHelper('formatCheckboxValue', (val, options) ->
  if (typeof val is 'string' and val == 'true') or (typeof val is 'boolean' and val)
    'checked'
  else
    ''
)

Handlebars.registerHelper('standardizePhoneNumber', (val, options) ->
  if val
    val = new String(val).replace(/[^\d+]/g, '')
    return val if val.charAt(0) == '+'
    if val.length == 10 and val.charAt(0) != '1'
      "+1#{val}"
    else
      "+#{val}"
  else
    val
)

# Workaround for rendering booleans. https://github.com/wycats/handlebars.js/issues/827
Handlebars.registerHelper('formatBoolean', (x) ->
  if x? then x.toString() else 'undefined'
)

Handlebars.registerHelper('formatSeconds', (seconds) ->
  moment()
    .startOf('day')
    .seconds(seconds)
    .format('HH:mm:ss')
)

Handlebars.registerHelper('formatPercent', (pct) ->
  pct.toFixed(1) + '%'
)

# TODO: This repeats the epoxy helper. Can we be more DRY?
Handlebars.registerHelper('formatUnixTime', (timestamp) ->
  moment(timestamp).format('MM/DD/YYYY hh:mm a') if timestamp
)

Handlebars.registerHelper('counter', (index) ->
  index + 1
)

Handlebars.registerHelper('concat', ->
  new Array(arguments).join('')
)

Handlebars.registerHelper('select', (value, options) ->
  $el = $('<select ></select>').html(options.fn(@))
  $el.find("[value='#{value}']").attr({'selected': 'selected'})
  $el.html()
)

Handlebars.registerHelper('formatJson', (val) ->
  try
    JSON.stringify(JSON.parse(val), null, 2)
  catch
    val
)

Handlebars.registerHelper('sentimentIcon', (sentiment) ->
  return '' if sentiment is 'NONE'
  icons =
    'POSITIVE' : 'thumbs-up'
    'NEGATIVE' : 'thumbs-down'
    'NONE'     : 'remove'

  glyph = icons[sentiment] ? 'question-sign'

  "<span class='glyphicon glyphicon-#{glyph}'></span>"
)

Handlebars.registerHelper('tagWithSentiment', (tag) ->
  return '' unless tag
  name = tag.split(':')[0]
  sentiment = tag.split(':')[1]
  "<span>#{name}</span>&nbsp;#{Handlebars.helpers.sentimentIcon(sentiment)}"
)

Handlebars.registerHelper('ifEquals', (v1, v2, options) ->
  if v1 is v2 then options.fn(@) else options.inverse(@)
)
