import React from 'react'
import styled from 'styled-components'
import RdnaAvatar, { AvatarProps } from '../RdnaAvatar'
import RdnaText, { colorpalettes } from '../RdnaText'

interface LabelProps extends AvatarProps {
  showAvatar?: boolean
  showName?: boolean
  avatarSize?: number
  email?: string
  account?: string
  nameColor?: keyof typeof colorpalettes
  title?: string
}

const Initials = styled.div`
  float: left;
  margin-right: 12px;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  span:first-child:nth-last-child(2) {
    position: relative;
    top: 2px;
  }
  span:nth-child(2) {
    position: relative;
    top: -2px;
  }
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const RdnaUserLabel = ({
  name,
  email,
  imageUrl,
  account,
  status,
  showAvatar = true,
  showName = true,
  avatarSize,
  nameColor,
  title
}: LabelProps) => {
  return (
    <LabelContainer>
      {showAvatar && (
        <Initials>
          <RdnaAvatar size={avatarSize} name={name} imageUrl={imageUrl} status={status} />
        </Initials>
      )}
      <UserInfo data-testid={`autocomplete-option-${name}`}>
        {showName && (
          <RdnaText variant={'body2'} color={nameColor} align="left">
            {name}
          </RdnaText>
        )}
        {title && (
          <RdnaText variant={'body1'} align="left">
            {title}
          </RdnaText>
        )}
        {account && (
          <RdnaText variant={'meta'} color={'neutral'} align="left">
            {account}
          </RdnaText>
        )}
        {email && <RdnaText variant={'meta'}>{email}</RdnaText>}
      </UserInfo>
    </LabelContainer>
  )
}

export default RdnaUserLabel
