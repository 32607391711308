import { Colors } from '../constants/colors'

const PALETTE = [
  Colors.C20,
  Colors.C30,
  Colors.C40,
  Colors.C60,
  Colors.C70,
  Colors.C80,
  Colors.P20,
  Colors.P30,
  Colors.P50,
  Colors.P60,
  Colors.P70,
  Colors.P80,
  Colors.PY20,
  Colors.PY30,
  Colors.PY40,
  Colors.PY50,
  Colors.PY60,
  Colors.PY70,
  Colors.PY80,
  Colors.V20,
  Colors.V30,
  Colors.V40,
  Colors.V50,
  Colors.V60,
  Colors.V70,
  Colors.V80,
  Colors.S20,
  Colors.S30,
  Colors.S40,
  Colors.S50,
  Colors.S60,
  Colors.S70,
  Colors.S80,
  Colors.F20,
  Colors.F30,
  Colors.F40,
  Colors.F50,
  Colors.F60,
  Colors.F70,
  Colors.F80,
  Colors.SG20,
  Colors.SG30,
  Colors.SG40,
  Colors.SG50,
  Colors.SG60,
  Colors.SG70,
  Colors.SG80
]

export const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return PALETTE[Math.abs(hash % PALETTE.length)]
}

// Returns color that is a given pct between two hex colors
// Adapted from https://stackoverflow.com/a/27709336/5176883
export const getGradientColor = function (startColor: string, endColor: string, percent: number) {
  // strip the leading # if it's there
  startColor = startColor.replace(/^\s*#|\s*$/g, '')
  endColor = endColor.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (startColor.length === 3) {
    startColor = startColor.replace(/(.)/g, '$1$1')
  }

  if (endColor.length === 3) {
    endColor = endColor.replace(/(.)/g, '$1$1')
  }

  // get colors
  const startRed = parseInt(startColor.substr(0, 2), 16)
  const startGreen = parseInt(startColor.substr(2, 2), 16)
  const startBlue = parseInt(startColor.substr(4, 2), 16)

  const endRed = parseInt(endColor.substr(0, 2), 16)
  const endGreen = parseInt(endColor.substr(2, 2), 16)
  const endBlue = parseInt(endColor.substr(4, 2), 16)

  // calculate new color
  const diffRed = endRed - startRed
  const diffGreen = endGreen - startGreen
  const diffBlue = endBlue - startBlue

  let diffRedStr = (diffRed * percent + startRed).toString(16).split('.')[0]
  let diffGreenStr = (diffGreen * percent + startGreen).toString(16).split('.')[0]
  let diffBlueStr = (diffBlue * percent + startBlue).toString(16).split('.')[0]

  // ensure 2 digits by color
  if (diffRedStr.length === 1) diffRedStr = '0' + diffRed
  if (diffGreenStr.length === 1) diffGreenStr = '0' + diffGreen
  if (diffBlueStr.length === 1) diffBlueStr = '0' + diffBlue

  return `#${diffRedStr}${diffGreenStr}${diffBlueStr}`
}

export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.replace('#', ''), 16)
  return `${(bigint >> 16) & 255}, ${(bigint >> 8) & 255}, ${bigint & 255}`
}
