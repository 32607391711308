import React, { useMemo } from 'react'
import styled from 'styled-components'
import { action } from '@ringdna/common/src/utils/storeUtils'
import { flags, isFlagged } from '@ringdna/common/src/utils/feature-flag/featureFlag'

// utils
import { useAccount } from '../../../../store/api/accounts-api'
import { useUser } from '../../../common/services/UserContext'
import {
  isCallRecordingDisabledSelector,
  recordingPauseTypeDropDownSelector,
  isRecordingStatusEnabledSelector,
  isRecordingVisibilitySelector,
  isCallRecordingDownloadForbiddenSelector,
  callRecordingAgentOnlySelector,
  callRecordingExternalCallerSelector,
  useCallRecordingDispatch,
  useCallRecordingState
} from '../../utils/contextReducer'

// components
import RdnaSelect from '@ringdna/common/src/components/RdnaFormElements/RdnaSelect'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import SaveAllSettingsButton from '../SaveAllSettingsButton'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'

enum pauseEnabledTypes {
  NO = 'No (Default)',
  START_RECORDING_MANUALLY = 'Start Recording Manually',
  START_RECORDING_AUTOMATICALLY = 'Start Recording Automatically'
}

const pauseEnabledOptions = [
  { label: pauseEnabledTypes.NO, value: 'false' },
  { label: pauseEnabledTypes.START_RECORDING_MANUALLY, value: 'Manually' },
  { label: pauseEnabledTypes.START_RECORDING_AUTOMATICALLY, value: 'Automatically' }
]

enum recStatusEnabledTypes {
  ALL_USERS = 'All Users (Default)',
  ADMINS_ONLY = 'Admins Only'
}

const recStatusEnabledOptions = [
  { label: recStatusEnabledTypes.ALL_USERS, value: recStatusEnabledTypes.ALL_USERS },
  { label: recStatusEnabledTypes.ADMINS_ONLY, value: recStatusEnabledTypes.ADMINS_ONLY }
]

const recVisibilityOptions = [
  { label: 'All Users (Default)', value: 'All' },
  { label: 'Agent and team members with listen-in capabilities', value: 'AgentAndTeamListenIn' }
]

type AdvancedSubTabProps = {
  accountId: string
}

export default React.memo(AdvancedSubTab)
function AdvancedSubTab({ accountId }: AdvancedSubTabProps) {
  useAccount({ meta: { id: accountId } })
  return (
    <Container>
      <PauseEnabledDropDown />
      <DownloadForbiddenDropDown />
      <RecordingStatusEnabledToggle />
      <RecordingVisibilityDropDown />
      <AgentCallRecordingsEnabledToggle />
      <ExternalCallerRecordingsEnabledToggle />
      <SaveAllSettingsButton />
    </Container>
  )
}

const PauseEnabledDropDown = React.memo(() => {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()

  const handlePauseEnabledOptions = (e: any) => {
    const payload =
      e.target.value !== 'false'
        ? { 'settings.recordingPauseType': e.target.value, 'settings.recordingPauseEnabled': true }
        : { 'settings.recordingPauseEnabled': false }
    dispatch({
      type: 'CHANGE_ACCOUNT_SETTINGS',
      payload: payload
    })
  }

  return (
    <FormContainer>
      <RdnaText variant="body2">
        Configure advanced Call Recording settings such as the ability for Agents to start and stop call recordings or
        restricting the ability to download call recordings.
      </RdnaText>
      <SubText component="p">Would you like to allow your users to start, stop, and resume recordings?</SubText>
      <DropdownContainer>
        <RdnaSelect
          value={recordingPauseTypeDropDownSelector(state)}
          name={'pause-enabled'}
          options={pauseEnabledOptions}
          disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          onChange={handlePauseEnabledOptions}
        />
      </DropdownContainer>
    </FormContainer>
  )
})

const SubText = styled(RdnaText)`
  margin: 20px 0 10px;
`

const DownloadForbiddenDropDown = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const isCallRecordingDownloadForbidden = useMemo(() => isCallRecordingDownloadForbiddenSelector(state), [state])

  const recordingDownloadForbiddenValue = !isCallRecordingDownloadForbidden
    ? recStatusEnabledTypes.ALL_USERS
    : recStatusEnabledTypes.ADMINS_ONLY

  const handleRecordingDownloadForbidden = (e: any) => {
    const settingValue = e.target.value === 'Admins Only'
    dispatch(action('CHANGE_ACCOUNT_SETTINGS', { 'settings.callRecordingDownloadForbidden': settingValue }))
  }
  return (
    <FormContainer>
      <RdnaText variant="body1" component="p">
        Which users should be allowed to download recordings?
      </RdnaText>
      <DropdownContainer>
        <RdnaSelect
          value={recordingDownloadForbiddenValue}
          name={'status-enabled'}
          options={recStatusEnabledOptions}
          disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          onChange={handleRecordingDownloadForbidden}
        />
      </DropdownContainer>
    </FormContainer>
  )
})

const RecordingVisibilityDropDown = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const isRecordingVisibility = useMemo(() => isRecordingVisibilitySelector(state), [state])

  const handleRecordingVisibility = (e: any) => {
    dispatch(action('CHANGE_ACCOUNT_SETTINGS', { 'settings.recordingVisibility': e.target.value }))
  }

  return (
    <FormContainer>
      <RdnaText variant="body1" component="p">
        Call recording pages are accessible by default to anyone in your organization with login credentials for
        ringDNA. If you wish to prohibit access to these pages, then select “Agent and all team members with listen-in
        capabilities” from the dropdown. This will ensure that access to the call recording is limited to the Agent on
        the call, the Agent’s Supervisor(s), account Admins, and all team members with listen-in capabilities.
      </RdnaText>
      <DropdownContainer>
        <RdnaSelect
          value={isRecordingVisibility}
          name={'record-visibility'}
          options={recVisibilityOptions}
          disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
          onChange={handleRecordingVisibility}
        />
      </DropdownContainer>
    </FormContainer>
  )
})

const RecordingStatusEnabledToggle = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const isRecordingStatusEnabled = useMemo(() => isRecordingStatusEnabledSelector(state), [state])

  const handleChange = (value: boolean) => {
    dispatch(action('CHANGE_ACCOUNT_SETTINGS', { 'settings.recordingStatusEnabled': value }))
  }

  return (
    <FormContainer>
      <RdnaText variant="body1">
        Would you like to enable the display of the recording status on the Softphone?
      </RdnaText>
      <RdnaToggleSwitch
        disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
        value={isRecordingStatusEnabled}
        onChange={handleChange}
      />
    </FormContainer>
  )
})

const AgentCallRecordingsEnabledToggle = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const callRecordingAgentOnly = callRecordingAgentOnlySelector(state)

  const handleChange = (value: boolean) => {
    dispatch(action('CHANGE_ACCOUNT_SETTINGS', { 'settings.callRecordingAgentOnly': value }))
  }

  return (
    <FormContainer>
      <RdnaText variant="body1">Would you like to enable single-channel Agent-only call recordings?</RdnaText>
      <RdnaToggleSwitch
        disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
        value={callRecordingAgentOnly}
        onChange={handleChange}
      />
    </FormContainer>
  )
})

const ExternalCallerRecordingsEnabledToggle = React.memo(function Component() {
  const state = useCallRecordingState()
  const { accountId, licenses } = useUser()
  const isEditingAllowed = licenses.admin && state.accountSettings['account.id'] === accountId
  const dispatch = useCallRecordingDispatch()
  const callRecordingExternalCaller = callRecordingExternalCallerSelector(state)

  const handleChange = (value: boolean) => {
    dispatch(action('CHANGE_ACCOUNT_SETTINGS', { 'settings.callRecordingExternalCaller': value }))
  }

  return (
    <FormContainer>
      <RdnaText variant="body1">Create Recordings Based on External Callers Only</RdnaText>
      <SubText component="p">
        When enabled, calls will be recorded based on the state-by-state or the country-by-country granular controls of
        the external caller. The jurisdiction of the Revenue.io agent will not be used to validate if a recording is
        generated.
      </SubText>
      <RdnaToggleSwitch
        disabled={!isEditingAllowed || isCallRecordingDisabledSelector(state)}
        value={callRecordingExternalCaller}
        onChange={handleChange}
      />
    </FormContainer>
  )
})

const Container = styled.div`
  padding: 0;
`

const FormContainer = styled.div`
  margin: 20px 0 0;
`

const DropdownContainer = styled.div`
  max-width: 400px;
`
