import React, {useState}  from 'react'
import { Section, SectionHeader, SectionSubHeader, SettingSelect } from '../../../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import CallDispositionsIcon from '@ringdna/common/src/assets/icons/svg/CallDispositions'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import ConfirmDisableTeamDispositionModal from './ConfirmDisableTeamDispositionModal'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaForm/RdnaToggleSwitch'
import {
  callDispositionPromptSettings,
  callDispositionRequiredStateSettings,
  DispositionPrompt,
  DispositionsRequired,
  ManageByTeam,
  manageCallDispositionRequiredByTeamSettings,
  VoicemailDrop,
  voicemailDropDispositionSettings
} from '../../../const'
import SettingsToggle from '../../SettingsToggleSwitch'
import {DispositionProps} from '../../../types'
import { SelectChangeEvent } from '@mui/material'

export default function CallDispositions ({ accountId, accountSettings, setAccountSettings, dispositions, refetchDispositions }: DispositionProps) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const dispositionsRequiredOptions = [
    { label: 'All', value: 'All' },
    { label: 'Inbound', value: 'Inbound' },
    { label: 'Outbound', value: 'Outbound' },
    { label: 'None', value: 'None' }
  ]

  const dispositionsOptions = dispositions && [{ label: 'None', value: 0 }, ...dispositions.map(disposition => ({ label: disposition.disposition, value: disposition.id }))]

  const handleDropDispositionChange = () => (event: SelectChangeEvent<unknown>) => {
    if (event.target.value == 0) {
      setAccountSettings({ ...accountSettings, [voicemailDropDispositionSettings]: null })
    }
    if (!dispositions) {
      return
    }
    const selectedDisposition = dispositions.find(disposition => disposition.id === event.target.value)
    setAccountSettings({ ...accountSettings, [voicemailDropDispositionSettings]: selectedDisposition })
  }

  const handleTeamDispositionChange = (value: boolean) => {
    if (accountSettings && setAccountSettings) {
      if (!value) {
        setIsConfirmModalOpen(true)
      }
      setAccountSettings({ ...accountSettings, [manageCallDispositionRequiredByTeamSettings]: value })
    }
  }

  const handleNotConfirm = () => {
    if (accountSettings && setAccountSettings) {
      setAccountSettings({ ...accountSettings, [manageCallDispositionRequiredByTeamSettings]: true })
      setIsConfirmModalOpen(false)
    }
  }

  const handleConfirm= () => {
    setIsConfirmModalOpen(false)
  }

  const handleDispositionRequiredStateChange = () => (event: SelectChangeEvent<unknown>) => {
    setAccountSettings({ ...accountSettings, [callDispositionRequiredStateSettings]: event.target.value })
  }

  return (
    <Section style={{marginBottom: 20}}>
      <SectionHeader style={{marginBottom: 20}}>
        <IconHelper icon={CallDispositionsIcon} variant="filled" />
        <RdnaText className={'rdna-text'}>Call Dispositions</RdnaText>
      </SectionHeader>
      <RdnaSettingRow heading={DispositionPrompt.title} description={DispositionPrompt.description}>
        <SettingsToggle
          isChecked={accountSettings[callDispositionPromptSettings]}
          setting={callDispositionPromptSettings}
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={DispositionsRequired.title} description={DispositionsRequired.description}>
        <SettingSelect
          name={'call-disposition-required-toggle'}
          options={dispositionsRequiredOptions}
          value={accountSettings[callDispositionRequiredStateSettings]}
          onChange={handleDispositionRequiredStateChange()}
        />
      </RdnaSettingRow>
      <RdnaSettingRow heading={ManageByTeam.title} description={ManageByTeam.description}>
        <RdnaToggleSwitch
          controlledState={{ isChecked: accountSettings[manageCallDispositionRequiredByTeamSettings], setIsChecked: handleTeamDispositionChange }}
        />
      </RdnaSettingRow>
      <SectionSubHeader>
        <RdnaText className={'rdna-text'} bold={true}>
          Automatic Disposition
        </RdnaText>
      </SectionSubHeader>
      <RdnaSettingRow heading={VoicemailDrop.title} description={VoicemailDrop.description}>
        {dispositionsOptions &&
          <SettingSelect
            name={'automatic-disposition-voicemail-drop-toggle'}
            options={dispositionsOptions}
            value={accountSettings[voicemailDropDispositionSettings] ? accountSettings[voicemailDropDispositionSettings].id : 0}
            onChange={handleDropDispositionChange()}
          />
        }
      </RdnaSettingRow>
      <ConfirmDisableTeamDispositionModal
        open={isConfirmModalOpen}
        onClose={handleNotConfirm}
        onConfirm={handleConfirm}
        dispositionState={accountSettings[callDispositionRequiredStateSettings] as string}
      />
    </Section>
  )
}
