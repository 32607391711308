import styled, { css } from 'styled-components'
import { TableProps } from './RdnaTable'
import RdnaCheckbox from '../RdnaFormElements/RdnaCheckbox'

export const StyledTable = styled.div<TableProps>`
  border-radius: 6px;
  overflow: hidden;
  .ringdna-table-head,
  .ringdna-table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.palette.border};
  }

  .ringdna-table-head {
    padding: ${props =>
      `${props.$shadedHead ? 3 * props.theme.spacing : props.theme.spacing}px ${3 * props.theme.spacing}px`};
    color: ${props => props.theme.palette.text.primary};
    background: ${props => props.$shadedHead && props.theme.palette.primary.light};
  }

  .ringdna-table-row {
    padding: ${props => `${3 * props.theme.spacing}px`};
    color: ${props => props.theme.palette.text.primary};
    background: ${props => props.theme.palette.background.light};
  }

  .ringdna-table-row a {
    color: ${props => props.theme.palette.text.primary};
  }

  .ringdna-table-row:last-child {
    border-bottom: 0;
  }

  ${props =>
    props.$shadedRows &&
    css`
      .ringdna-table-row:nth-child(even) {
        background: ${props => props.theme.palette.background.main};
      }
    `}

  .ringdna-table-cell {
    overflow: hidden;
    padding-left: ${props => `${3 * props.theme.spacing}px`};
  }

  .ringdna-table-row .ringdna-table-cell {
    word-wrap: break-word;
  }

  .ringdna-table-cell:first-child {
    padding-left: 0;
  }
`

export const StyledTableCell = styled.div`
  &.centered {
    text-align: center;
  }
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

const Arrow = styled.div`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  position: relative;
`

export const ArrowUp = styled(Arrow)`
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 1px;
`

export const ArrowDown = styled(Arrow)`
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: -2px;
`

export const StyledCheckbox = styled(RdnaCheckbox)`
  &.MuiFormControl-root {
    margin-top: 0;
  }
`
