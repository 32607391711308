import React, { useState } from 'react'

import RdnaText from '@ringdna/common/src/components/RdnaText'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import { IconSize, Section, SectionHeader } from '../../styles'
import RdnaSettingRow from '@ringdna/common/src/components/RdnaSettings/RdnaSettingRow'
import RdnaToggleSwitch from '@ringdna/common/src/components/RdnaFormElements/RdnaToggleSwitch'
import CloudIcon from '@ringdna/common/src/assets/icons/svg/Cloud'
import { DialerSettings } from '@ringdna/common/src/types/dialer-settings'
import RelatedRecordsRules from '../../../../related-records'

type Props = {
  dialerSettings: DialerSettings
  setSettings: (settings: { [key: string]: any }) => void
}

export default RelatedRecords
function RelatedRecords({ dialerSettings, setSettings }: Props) {
  const [local, setLocal] = useState<{ [key: string]: any }>({})
  const [relatedRecords, setRelatedRecords] = useState(dialerSettings.account.settings.relatedRecords)

  const update = (key: string, value: any) => {
    const updated = { ...local, [key]: value }
    setLocal(updated)
    setSettings(updated)
  }

  return (
    <>
      <Section>
        <SectionHeader>
          <IconHelper icon={CloudIcon} iconSize={IconSize} variant="filled" />
          <RdnaText bold className={'rdna-text'}>
            Related Records
          </RdnaText>
        </SectionHeader>
        <RdnaSettingRow heading={'Related Records'} description={''}>
          <RdnaToggleSwitch
            value={relatedRecords}
            onChange={(checked: boolean) => {
              setRelatedRecords(checked)
              update('settings.relatedRecords', checked)
            }}
          />
        </RdnaSettingRow>
      </Section>
      {relatedRecords && <RelatedRecordsRules accountId={dialerSettings.account.id} />}
    </>
  )
}
