import styled from 'styled-components'
import { Colors } from '../constants/colors'

// this is being used for dropdown menu items and should be turned in to a dropdown menu component
// see: webapp/src/components/smart-recordings/ActionsDropdown.tsx

// also being used as a wrapper for clickable icons, maybe call it ButtonIcon or merge this in w/ RdnaButton

const UnstyledButton = styled.button`
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: #262626;
  font-family: Lato;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
`

const Button = styled(UnstyledButton)`
  border: ${Colors.BLUE} solid 1px;
  color: ${Colors.BLUE};
  padding: 7px 28px;
  border-radius: 6px;
  margin: 10px 0;
  &:hover {
    color: ${Colors.WHITE};
    background-color: ${Colors.BLUE};
  }
  &:focus {
    outline: none;
  }
`

export default Button
export { UnstyledButton }
