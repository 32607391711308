/**
 * @deprecated for `RdnaFormElements/RdnaCheckbox`.
 */
import React from 'react'
import { FormControlLabel } from '@mui/material'
import MaterialUiCheckbox, { CheckboxProps as MaterialUiCheckboxProps } from '@mui/material/Checkbox'

type CheckboxProps = MaterialUiCheckboxProps & {
  label?: React.ReactNode
  ref?: React.Ref<HTMLInputElement>
}

const Checkbox = ({ label = '', ref, ...rest }: CheckboxProps) => {
  return (
    <FormControlLabel
      control={<MaterialUiCheckbox color="primary" value={label} ref={ref} {...rest} />}
      label={label}
    />
  )
}

export default Checkbox
