import { useEffect, useState } from 'react'
import { user, UserType } from '../components/common/services/user'
import { AccountAuthorizations } from '@ringdna/common/src/types/user'
import { createUseFetchAction } from '@ringdna/client/src/react/createUseFetch'

/*
USE
//not async, returns id of the active user (logged in user or managed user)
const { activeUserId } = useActiveUser()

//not async, if user is logged in user, returns logged in users details
const { activeUser } = useActiveUser()

//async, if managed user, fires of request for managed user's properties along with loading and error status
const { activeUser, activeUserLoading, activeUserError } = useActiveUser()
*/

export type ActiveUserId = number

export const useActiveUser = () => {
  const [activeUserLoading, setActiveUserLoading] = useState<boolean>(false)
  const [activeUserError, setActiveUserError] = useState<string | undefined>()
  const [activeUser, setActiveUser] = useState<UserType | undefined>()
  const activeUserId = parseInt(window.location.href.split('/')[4])
  const isManagedUser = user.userId !== activeUserId
  const fetchUserOverview = useUserOverview()
  useEffect(() => {
    const getManagedUser = async () => {
      setActiveUserLoading(true)
      try {
        const res = await fetchUserOverview({ meta: { id: activeUserId } })
        const payload = res?.success?.payload
        if (payload) {
          setActiveUserLoading(false)
          setActiveUser({
            accountId: payload.user.account.id,
            caiLocationEnabled: payload.user.settings.conversationAnalyticsLocationRestriction,
            caiManager: payload.user.settings.conversationAnalyticsManager,
            userId: payload.user.id,
            isSupport: payload.user.licenses.support,
            isAuthorizedAdmin: payload.user.userAccountAuthorizations.length > 0,
            userName: payload.user.displayName,
            userEmail: payload.user.email,
            licenses: payload.user.licenses
          })
        }
      } catch (error) {
        setActiveUserLoading(false)
        setActiveUserError(error.message)
      }
    }
    if (!isManagedUser) {
      setActiveUser(user)
    } else {
      getManagedUser()
    }
  }, [isManagedUser, setActiveUser, setActiveUserLoading, setActiveUserError, activeUserId, fetchUserOverview])
  return {
    activeUserId,
    activeUser,
    activeUserLoading,
    activeUserError
  }
}

type UserPayload = {
  user: {
    id: number
    displayName: string
    email: string
    settings: {
      conversationAnalyticsManager: boolean
      conversationAnalyticsLocationRestriction: boolean
    }
    licenses: {
      admin: boolean
      intelligentDialer: boolean
      calendar: boolean
      conversationAI: boolean
      guidedSelling: boolean
      moments: boolean
      salesSupport: boolean
      supervisor: boolean
      support: boolean
    }
    userAccountAuthorizations: AccountAuthorizations[]
    account: {
      id: number
    }
  }
}

const useUserOverview = createUseFetchAction<UserPayload, { meta: { id: number } }>({
  key: 'get-user-overview',
  path: params => `/api/v2/app/users/${params.meta.id}/overview`
})
