import CircleCheck from '@ringdna/common/src/assets/icons/svg/CircleCheck'
import { Colors } from '@ringdna/common/src/constants/colors'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import Alert from '@ringdna/common/src/assets/icons/svg/Alert'
import React from 'react'
import { CellText } from './styles'
import { CallFlowSteps } from './types'
import RdnaText from '@ringdna/common/src/components/RdnaText'

export const ValidIconCell = (value: boolean) => {
  return value ? (
    <CircleCheck checkColor={Colors.WHITE} backgroundColor={Colors.GREEN} borderColor={Colors.GREEN} size={24} />
  ) : (
    <IconHelper icon={Alert} color={Colors.RED} iconSize={24} variant="filled" />
  )
}

export const NameLinkCell = (value: string, row: any, link: string = '') => {
  return (
    <CellText className={row.original.isDeleted ? 'deleted' : ''} href={link ? `${link}${row.original.id}` : ''}>
      {value}
    </CellText>
  )
}

export const CallFlowLinkCell = (value: CallFlowSteps[], row: any, link: string) => {
  return (
    <>
      {value.map((callFlowStep: CallFlowSteps, index) => (
        <CellText key={callFlowStep.callFlow.id}>
          <RdnaText href={`${link}${callFlowStep.callFlow.id}`}>{callFlowStep.callFlow.name}</RdnaText>
          {index !== value.length - 1 && <RdnaText>{', '}&nbsp;</RdnaText>}
        </CellText>
      ))}
    </>
  )
}

export const TextCell = (value: string, row: any) => {
  return <CellText className={row.original.isDeleted ? 'deleted' : ''}>{value}</CellText>
}
