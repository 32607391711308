import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function PhoneIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        clipRule="evenodd"
        d="M19.647 16.75a.5.5 0 00-.093-.577L17.61 14.23a.5.5 0 00-.611-.075l-1.267.76a.438.438 0 01-.352.05c-.507-.152-1.99-.712-3.703-2.425-1.713-1.713-2.274-3.196-2.426-3.703a.438.438 0 01.05-.352l.76-1.267a.5.5 0 00-.074-.611L8.043 4.662a.5.5 0 00-.578-.094L5.212 5.695a.453.453 0 00-.259.407c.009 1.063.325 4.988 4.249 8.912 3.924 3.924 7.848 4.24 8.912 4.248a.453.453 0 00.407-.259l1.126-2.253z"
        stroke={color}
      />
    </svg>
  )
}

export default PhoneIcon
