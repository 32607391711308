BaseView = require('base/view')
ReactComponent  = require('src/ReactComponent').default
NylasUsers = require('src/components/accounts/video-users').default

class VideoView extends BaseView
  template: require('./template')
  className: 'video-view'

  regions:
    container : '.video'

  initialize: (options) =>
    { @qs } = options
    @accountId  = @user().getSelectedSupportAccountId() ? @user().get('accountId')
    @qs.setFragment('integrations/video')

  onRender: ->
    @showChildView('container', new ReactComponent({component: NylasUsers, props: {accountId: @accountId}}))

module.exports = VideoView
