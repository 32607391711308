import React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const EditIcon = ({ color = Colors.N100, title = 'edit-icon', size = 24 }: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="edit-icon">
    <title>{title}</title>
    <path
      d="M17.3489 3.59155C18.2608 2.67967 19.7392 2.67967 20.6511 3.59155C21.563 4.50342 21.563 5.98186 20.6511 6.89374L8.05342 19.4914L4.19216 20.4567C3.94681 20.5181 3.72458 20.2958 3.78592 20.0505L4.75123 16.1892L17.3489 3.59155ZM8.09776 19.4803L8.09754 19.4804C8.09761 19.4804 8.09768 19.4803 8.09776 19.4803L8.13778 19.6404L8.09776 19.4803Z"
      stroke={color}
      strokeWidth="1.33"
      strokeLinejoin="round"
    />
    <path d="M15 6.19238L18 9.19238" stroke={color} strokeWidth="1.33" strokeLinecap="round" />
  </svg>
)

export default EditIcon
