import React from 'react'
import styled from 'styled-components'
import RdnaText from '../RdnaText'
import { Drawer, DrawerProps, IconButton } from '@mui/material'
import CloseIcon from '../../assets/icons/svg/Close'
import RdnaButton from '../RdnaButton'

export interface RdnaDrawerProps extends RdnaDrawerBareProps {
  /** Heading text of modal. We should try to use this for the heading at all times
   *  to remain consistent vs having heading in actual content  */
  heading?: string | React.ReactNode
  /** Subheader for modal */
  subHeading?: string | React.ReactNode
  /** Icon to go at the beginning of the header */
  headingIcon?: React.ReactNode
  /** Event fired when user clicks on back button to navigate back a page within the drawer */
  onBack?: () => void
  backText?: string
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>
}

export default function RdnaDrawer({
  // Custom Props
  heading,
  subHeading,
  headingIcon,
  onBack,
  backText,
  // Drawer Props
  children,
  open,
  onClose,
  contentContainerProps,
  ...restOfDrawerBareProps
}: RdnaDrawerProps) {
  return (
    <RdnaDrawerBare
      onClose={onClose}
      open={open}
      anchor="right"
      // @ts-ignore
      BackdropProps={{ 'data-testid': 'drawer-backdrop' }}
      {...restOfDrawerBareProps}
    >
      <DrawerContent {...contentContainerProps}>
        {onBack && (
          <BackButton
            type="button"
            data-testid={'back-button-drawer'}
            variant="text"
            text={`< ${backText || 'Back'}`}
            onClick={onBack}
          />
        )}
        <Header $subHeading={subHeading} data-testid={`drawer-header`}>
          {heading && (
            <Heading variant="h4">
              {headingIcon && <Icon>{headingIcon}</Icon>}
              {heading}
            </Heading>
          )}
          {subHeading && <RdnaText variant="subtitle2">{subHeading}</RdnaText>}
        </Header>
        <CloseButton data-testid="drawer-close" onClick={e => onClose && onClose(e, 'backdropClick')}>
          <CloseIcon title={'Close Drawer'} />
        </CloseButton>
        {children}
      </DrawerContent>
    </RdnaDrawerBare>
  )
}

export type RdnaDrawerBareProps = DrawerProps

export function RdnaDrawerBare({ ...restOfDrawerProps }: RdnaDrawerBareProps) {
  return <Drawer disableEnforceFocus {...restOfDrawerProps} />
}

export const isX = (anchor: RdnaDrawerBareProps['anchor']) => {
  return anchor === 'left' || anchor === 'right'
}

const Heading = styled(RdnaText)`
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.theme.spacing * 3}px;
`
const Icon = styled.div`
  margin-right: ${props => props.theme.spacing * 2}px;
`

const Header = styled.div<{ $subHeading: any }>`
  padding-bottom: ${props => props.theme.spacing * (props.$subHeading ? 3 : 2)}px;
  min-height: ${({ theme }) => theme.spacing * 3}px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
`

const CloseButton = styled(IconButton)`
  right: ${({ theme }) => theme.spacing * 2}px;
  top: ${({ theme }) => theme.spacing * 2}px;
  position: absolute !important;
`
const DrawerContent = styled.div`
  padding: ${({ theme }) => theme.spacing * 4}px;
`

const BackButton = styled(RdnaButton)`
  left: ${({ theme }) => theme.spacing * -2}px;
  top: ${({ theme }) => theme.spacing * -1.5}px;
  position: relative;
`
