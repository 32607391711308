import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function SystemCheckIcon({ size = 24, color = Colors.N100 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid={'system-check-icon'}>
      <rect x={8} y={18} width={8} height={1} rx={0.5} fill={color} />
      <mask id="prefix__a" fill="#fff">
        <rect x={2} y={5} width={20} height={12} rx={0.5} />
      </mask>
      <rect x={2} y={5} width={20} height={12} rx={0.5} stroke={color} strokeWidth={2} mask="url(#prefix__a)" />
      <path stroke={color} strokeWidth={0.5} d="M10.25 16.25h.5v2.5h-.5zM13.25 16.25h.5v2.5h-.5z" />
      <path d="M9 11l2 2 4-4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default SystemCheckIcon
