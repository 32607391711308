BaseView = require('base/view')

# FIXME: Re-architect this to listen to changes so we don't need to call destroy.
class DomainBlacklistItemView extends BaseView

  template: require('./item-template')

  className: 'row domain-blacklist-item'

  bindings:
    '.domain'    : 'value:name'
    '.cancel'    : 'toggle:isNew'
    '.delete'    : 'toggle:not(isNew)'
    '.save'      : 'toggle:isNew'
    'input:text' : "classes:{'no-border':not(isNew)},disabled:not(isNew)"

  events:
    'click button.delete' : 'delete'
    'click button.cancel' : 'cancel'
    'click button.save'   : 'save'

  initialize: (options) ->
    @collection = options.collection
    @model = options.model

  delete: =>
    domain = @model.get('name')
    return if not confirm("Are you sure you want to delete #{_.escape(domain)}?")
    @model.destroy().done(=> @destroy())

  cancel: ->
    @destroy()

  save: =>
    domain = @model.get('name')
    if not domain
      toastr.warning('Please enter a domain name.')
      return

    # FIXME: this is wonky -- it shadows Backbone's builtin isNew behavior
    @model.persist().done(=>
      @model.set('isNew', false)
      @collection.add(@model)
    )
    @destroy()

module.exports = DomainBlacklistItemView
