BaseView = require('base/view')
SmartNumberModel = require('features/smart-numbers/overview/model')

class SmartNumberImportView extends BaseView

  template: require('./import-number-template')
  className: 'smart-number-import'

  ui:
    import   : 'button.import'
    open     : 'button.open'
    messages : 'span.modal-message'
    sid      : 'input.sid'

  events:
    'click @ui.import' : 'import'

  initialize: (options) =>
    @accountId = options.accountId

  onAttach: =>
    $('#import-number-modal').on('hidden.bs.modal', (e) =>
      return unless @smartNumberId
      url = "#smart-numbers/#{@smartNumberId}"
      @smartNumberId = null
      App.navigate(url, trigger: true)
    )

  import: =>
    return unless @validate()

    @model = new SmartNumberModel(
      accountId: @accountId
      sid: @ui.sid.val()
    )

    @model
      .persist(ladda: @ui.import[0])
      .done((response) =>
        @ui.messages.text(response.message)
        if response.status is 'ok'
          @smartNumberId = response.smartNumberId
          @ui.import.hide()
          @ui.open.show()
      )

  validate: =>
    if not @ui.sid.val()
      toastr.warning('Please enter a sid.')
      return false

    true

module.exports = SmartNumberImportView
