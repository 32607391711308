BaseView = require('base/view')
QueryString = require('helpers/query-string')
config = require('../../../src/config').default

class LoginView extends BaseView
  template: require('./template')

  events:
    'click button[data-action="salesforce-login"]': 'salesforceLogin'

  bindings:
    '.flash': 'html:flash,toggle:flash'
    '.connection': 'value:connection'
    '.custom-url': 'toggle:showCustomUrl,value:customUrl'

  computeds:
    connection:
      get: ->
        val = @$('.connection').val()
        @viewModel.set(showCustomUrl: val is 'custom')
        val

  initialize: (options) ->
    @app = options.app
    @qs = new QueryString('&', window.location.search)
    @viewModel = new Backbone.Model(
      flash: null
      showCustomUrl: false
    )
    App.api.get(
      anon : true
      path: '/salesforceoauth/error'
      data: id: @qs.get('flash')
      success: (response) =>
        @viewModel.set('flash', response.details) if response?.details
    ) if @qs.get('flash')

  onRender: ->
    connection = @getConnectionFromLocalStorage()
    if connection
      @$('.connection').val(connection)
      if connection is 'custom'
        customUrl = @getCustomUrlFromLocalStorage()
        @model.set('customUrl', customUrl) if customUrl
        @viewModel.set(showCustomUrl: true)

  salesforceLogin: ->
    connection = @$('.connection').val()
    if connection is 'custom'
      customUrl = @model.get('customUrl')?.trim()
      if not customUrl
        alert('Please enter the custom URL')
        return
    else
      @model.set('customUrl', null)
      @model.set('sandbox', connection is 'sandbox')

    @saveConnectionToLocalStorage(connection, customUrl)

    window.location.href = @model.salesforceLoginUrl()

  saveConnectionToLocalStorage: (connection, customUrl) ->
    key = "loginConnection"
    localStorage.setItem(key, connection)
    if customUrl
      key = "loginCustomUrl-#{config.appDomain}"
      customUrl = customUrl.replace('http://', 'https://')
      customUrl = 'https://' + customUrl if not customUrl.startsWith('https://')
      localStorage.setItem(key, customUrl)

  getConnectionFromLocalStorage: ->
    key = "loginConnection"
    localStorage.getItem(key)

  getCustomUrlFromLocalStorage: ->
    key = "loginCustomUrl-#{config.appDomain}"
    localStorage.getItem(key)

module.exports = LoginView
