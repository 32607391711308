import React, { useContext, useState, useEffect, useCallback } from 'react'
import { IntegrationsActions, ZoomUserTypes } from '../../constants'
import { IntegrationProviderTypes } from '@ringdna/common/src/types/nylas'
import { InitIntegrationsContext } from '../../model'
import { IntegrationsContext } from '../../store/IntegrationsProvider'
import { useDeleteZoomIntegrationRequest } from '../../../../../store/api/user-integrations-api'
import { Grid, Box } from '@mui/material'
import SectionHeader, { SectionDivider } from '@ringdna/common/src/components/RdnaHeaderSection'
import ZoomAttribute from './ZoomAttribute'
import ConnectZoomUserModal from '../AddIntegration/ConnectZoomUserModal'
import RdnaButton from '@ringdna/common/src/components/RdnaButton'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import RdnaModal from '@ringdna/common/src/components/RdnaModal'

export default function AccountDetails() {
  const {
    dispatch,
    integrationsState: { zoomMetaModel, connectedIntegration, activeUser }
  } = useContext<InitIntegrationsContext>(IntegrationsContext)
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false)
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false)
  const deleteZoomIntegrationRequest = useDeleteZoomIntegrationRequest()
  const user = zoomMetaModel?.zoomUser

  const updateUser = () => {
    setConnectModalOpen(true)
  }

  const closeConnectModal = useCallback(() => {
    setConnectModalOpen(false)
    dispatch({ type: IntegrationsActions.SET_CONNECTED_INTEGRATION, payload: undefined })
  }, [dispatch])

  const openDeleteConfirmModal = () => {
    setDeleteConfirmModalOpen(true)
  }

  const onDeleteConfirmModalClose = (userConfirmed: boolean) => {
    if (userConfirmed) unlinkUser()
    setDeleteConfirmModalOpen(false)
  }

  const unlinkUser = async () => {
    if (user.rdnaZoomUserId) {
      try {
        await deleteZoomIntegrationRequest({ meta: { id: user.rdnaZoomUserId } })
        dispatch({ type: IntegrationsActions.DELETE_INTEGRATION, payload: IntegrationProviderTypes.ZOOM })
      } catch (error) {
        toastr.error(error.message)
      }
    }
  }

  useEffect(() => {
    if (connectedIntegration) closeConnectModal()
  }, [connectedIntegration, closeConnectModal])

  return (
    <>
      <RdnaModal
        open={deleteConfirmModalOpen}
        maxWidth="md"
        heading="Unlink User"
        onClose={() => onDeleteConfirmModalClose(false)}
        onConfirm={() => onDeleteConfirmModalClose(true)}
        confirmButtonText="Confirm"
      >
        <RdnaText>{`Are you sure you want to unlink the ${user.firstName} ${user.lastName} Zoom account from the ${activeUser.userName} account. Once unlinked zoom conference integration functionality will no longer be present in the user's softphone. This action can be restored by creating a new link between users at the account or user level.`}</RdnaText>
      </RdnaModal>
      <RdnaModal open={connectModalOpen} maxWidth="md" onClose={closeConnectModal}>
        <ConnectZoomUserModal />
      </RdnaModal>
      <Grid container alignItems="flex-end">
        <Grid item xs={9}>
          <SectionHeader headingText="Account Details"></SectionHeader>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end" pb={2}>
            <Box mr={2}>
              <RdnaButton name="unlink-user" text="Unlink User" onClick={openDeleteConfirmModal} />
            </Box>
            <RdnaButton name="update-user" text="Update User" onClick={updateUser} />
          </Box>
        </Grid>
      </Grid>
      <SectionDivider />
      <Box pt={1} pb={3}>
        <Grid container>
          <Grid item xs={6}>
            <ZoomAttribute name="Name">{`${user.firstName} ${user.lastName}`}</ZoomAttribute>
            <ZoomAttribute name="Personal Meeting ID">{user.pmi}</ZoomAttribute>
            <ZoomAttribute name="Personal Link">{user.personal_meeting_url}</ZoomAttribute>
            <ZoomAttribute name="Sign-In Email">{user.email}</ZoomAttribute>
          </Grid>
          <Grid item xs={6}>
            <ZoomAttribute name="User Type">{ZoomUserTypes[user.type]}</ZoomAttribute>
            <ZoomAttribute name="TimeZone">{user.timezone}</ZoomAttribute>
            <ZoomAttribute name="Host Key">{user.host_key}</ZoomAttribute>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
