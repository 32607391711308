BaseView = require('base/view')
UsersQueuesSkillsView = require('../../widgets/usersQueuesSkills/view')

class DialStepView extends BaseView

  template: require('./template')

  ui:
    file: 'input.fileupload'
    dialType: '.dialType'
    userQueueSkillTypeSelector: '.user-queue-skill-type-selector'

  events:
    'change @ui.dialType': 'changeDialType'
    'change input.fileupload': 'uploadFile'

  regions:
    usersQueuesSkills: 'div.users-queues-skills-view'

  bindings:
    '#dialUserQueueSkill'                                 : 'value:userQueueSkillType'
    'input.dialType'                                      : 'checked:dialType'
    'input[data-key="record-call-flow"]'                  : 'checked:all(recordCallFlow, not(disableRecording)), disabled: any(not(isAdmin),readOnly, disableRecording)'
    'input[data-key="dial-call-disposition-not-required"]': 'checked:dialCallDispositionNotRequired, disabled: any(not(isAdmin),readOnly)'
    'input[data-key="skip-call-recording-notification"]'  : 'checked:skipCallRecordingNotification'
    'input[data-key="gotoNextStepIfNoSubscribers"]'       : 'checked:gotoNextStepIfNoSubscribers'
    '.dial-queue-settings'                                : 'toggle: isQueueDial'
    '.spinner'                                            : 'toggle: spin'
    '.selected-file'                                      : 'text: selectedFile'

  computeds:
    readOnly:
      get: ->
        @callflow.get('readOnly')
    isAdmin:
      get: ->
        @user().isAdmin()
    disableRecording:
      get: ->
        @callflow.get('account').settings.callRecordingOverrideOption is 'None'
    isQueueDial:
      deps: ['args']
      get: (args) ->
        args.dialType is 'group'
    dialType:
      deps: ['args']
      get: (args) ->
        args.dialType
    recordCallFlow:
      deps: ['args']
      get: (args) -> args['record-call-flow'] is 'true'
    dialCallDispositionNotRequired:
      deps: ['args']
      get: (args) -> args['dial-call-disposition-not-required'] is 'true'
    skipCallRecordingNotification:
      deps: ['args']
      get: (args) -> args['skip-call-recording-notification'] is 'true'
    gotoNextStepIfNoSubscribers:
      deps: ['args']
      get: (args) -> args['gotoNextStepIfNoSubscribers'] is 'true'

  initialize: (options) ->
    @callflow = options.callflow
    @viewModel = new Backbone.Model(
      spin: false
      selectedFile: @model.args()['hold-music-name'] ? 'Select an MP3 file from your computer'
      userQueueSkillType: if @model.dialType() is 'multiNumber' then '' else @model.dialType()
    )

  onRender: =>
    @showChildView('usersQueuesSkills', new UsersQueuesSkillsView(
      step      : @model
      accountId : @callflow.get('account')?.id
      readOnly  : @callflow.get('readOnly')
      skillsEnabled: @callflow.get('skillsEnabled')
      )
    )
    @listenTo(@model, 'ugsTypeChanged', (type) =>
      @viewModel.set(userQueueSkillType: type)
    )
    @listenTo(@model, 'ugsIdChanged', (selected) =>
      isAcd = selected?.type is 'group' and (selected?.queueType is 'LongestWaitingAgent' or selected?.queueType is 'SequentialDial' or selected?.queueType is 'LongestWaitingAgentCallParking')
      @viewModel.set('isAcd': isAcd)
      @viewModel.set('isQueue': selected?.type is 'group')
      dialTimeout = if selected?.type is 'group' and selected?.queueType is 'LongestWaitingAgentCallParking' then selected?.maximumHoldTime else selected?.dialTimeout
      @viewModel.set('dialTimeout': dialTimeout)
      @updateTimeout(false)
    )

  updateTimeout: (dialNumber) =>
    isAcd = @viewModel.get('isAcd')
    isQueue = @viewModel.get('isQueue')
    dialTimeout = @viewModel.get('dialTimeout')
    @$('.dial-queue-settings').toggle(isQueue and not dialNumber)
    @$('.queueTimeoutTooltip').toggle(isAcd and not dialNumber)
    @$('input[data-key="timeout"]').toggle(not isAcd or dialNumber)
    @$('.queue-timeout').toggle(isAcd and not dialNumber)
    @$('.queue-timeout').text(dialTimeout)

  changeDialType: (e) =>
    newDialType = $(e.currentTarget).val()
    @updateTimeout(newDialType is 'multiNumber')

  onDomRefresh: =>
    $('[data-toggle="tooltip"]').tooltip()

  uploadFile: (e) =>
    return unless @ui.file.val()

    oldFileId = @model.args()['hold-music-id']
    @model.args()['hold-music-id'] = ''
    @model.args()['hold-music-name'] = ''
    @viewModel.set(spin: true)

    App.api.post(
      path: 'callFlows/holdAudio'
      data:
        accountId   : @callflow.get('account')?.id
        audioFile   : @ui.file[0].files[0]
        deletableId : oldFileId
      upload: true
      success: (response) =>
        @model.args()['hold-music-id'] = response.id
        @model.args()['hold-music-name'] = response.filename
        @viewModel.set(selectedFile: response.filename)
        @model.saveStep()
      complete: =>
        @viewModel.set(spin: false)
      failure: =>
        toastr.error('Sorry, failed to upload file. Please try it later.')
    )
module.exports = DialStepView
