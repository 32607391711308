import React from 'react'
import { useSupportSettingsState } from '../../../utils/contextReducer'
import { Section, SectionHeader } from '../styles'
import IconHelper from '@ringdna/common/src/assets/icons/IconHelper'
import RdnaText from '@ringdna/common/src/components/RdnaText'
import SupportSettingToggleRow from '../components/SupportSettingToggleRow'
import MessagesIcon from '@ringdna/common/src/assets/icons/svg/Messages'

export default SmsSettings
function SmsSettings() {
  const state = useSupportSettingsState()

  const smsForAccount = 'settings.messagingFeaturesEnabled'
  const validateNumbers = 'settings.validateSMSNumber'

  return (
    <Section>
      <SectionHeader>
        <IconHelper icon={MessagesIcon} iconSize={60} strokeWidth={1.6} variant="filled" />
        <RdnaText className={'rdna-text'}>SMS</RdnaText>
      </SectionHeader>
      <SupportSettingToggleRow
        title={'Enable SMS messaging in this account'}
        isChecked={state.accountSettings[smsForAccount]}
        setting={smsForAccount}
      />
      <SupportSettingToggleRow
        title={'Validate mobile phone numbers'}
        isChecked={state.accountSettings[validateNumbers]}
        setting={validateNumbers}
      />
    </Section>
  )
}
