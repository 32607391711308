/**
 * Centralized service for managing analytics tracking across packages.
 */
import { getFormattedPendoDateTime } from './timeUtils'

export type TrackEvent = (name: string, data?: Record<string, any>, type?: string) => void

// Declare globals (could be undefined)
declare global {
  interface Window {
    pendo: Pendo
  }
}

type Pendo = {
  initialize: ({ visitor, account }: { visitor: Record<string, any>; account: Record<string, any> }) => void
  track: (name: string, data?: Record<string, any>) => void
}

interface AnalyticsWrapper {
  init(user: Record<string, any>, account: Record<string, any>): void
  track: TrackEvent
}

const pendoWrapper = (pendoAnalytics: Pendo): AnalyticsWrapper => ({
  init: (user, account) =>
    pendoAnalytics.initialize({
      visitor: user,
      account
    }),
  track: (name, data) => pendoAnalytics.track(name, data)
})

export default class AnalyticsUtils {
  private analyticsWrappers: AnalyticsWrapper[]
  private userData: Record<string, any>
  private user: Record<string, any>
  constructor(user: Record<string, any>) {
    const isUserService = user.constructor.name !== 'Object'
    this.userData = isUserService ? {} : user
    this.user = isUserService ? user : this
    // Check for each global analytics tool here since they load asynchronously
    this.analyticsWrappers = this.isAccountBlacklisted
      ? []
      : [window.pendo && pendoWrapper(window.pendo)].filter((aWrapper): aWrapper is AnalyticsWrapper => !!aWrapper)
    this.init()
  }

  private get(val: string) {
    return this.userData[val]
  }

  private get accountName() {
    return this.user.get('accountName')
  }

  private get accountId() {
    return this.user.get('accountId')
  }

  private get isAccountBlacklisted() {
    return this.blacklistedAccounts.includes(this.accountId)
  }

  private get blacklistedAccounts() {
    const AWS_ONE = 10000012
    const AWS_TWO = 10002423

    return [AWS_ONE, AWS_TWO]
  }

  private createUserObject() {
    //TODO: in case updating user object, please update on server side too
    const keys = [
      'acceptedTerms',
      'accountName',
      'address',
      'caiManager',
      'caiRecordingVisibility',
      'calendarBooking',
      'city',
      'coachingFeedbackEnabled',
      'continuousBridgeEnabled',
      'conversationSummaryEnabled',
      'countryCode',
      'displayName',
      'exchangeIntegrationEnabled',
      'exchangeIntegrationStatus',
      'extension',
      'firstName',
      'followUpEmailEnabled',
      'googleIntegrationEnabled',
      'googleIntegrationStatus',
      'isSupervisor',
      'lastName',
      'locationId',
      'sfAlias',
      'sfCompany',
      'sfDepartment',
      'sfDivision',
      'sfLanguage',
      'sfLocale',
      'sfManager',
      'sfNickname',
      'sfProfile',
      'sfRole',
      'sfRunAsUser',
      'sfUserId',
      'state',
      'title',
      'userName',
      'zip',
      'zoomIntegrationEnabled',
      'sfdcEnhancedAnalytics'
    ]

    const settingsKeys = [
      'callForwardingEnabled',
      'recordCalls',
      'clickToCall',
      'dialNextPreviewEnabled',
      'localPresence',
      'localPresenceSMS',
      'webLeads',
      'webRTCEnabled'
    ]

    const userObject: Record<string, any> = {}
    for (const value of keys) {
      userObject[value] = this.user.get(value)
    }

    if (this.user.get('settings')) {
      for (const setting of settingsKeys) {
        userObject[setting] = this.user.get('settings')[setting]
      }
      userObject.email2sfdc = this.user.get('settings').emailToSalesforce.enabled
      if (typeof this.user.get('settings').sip.enabled !== 'undefined') {
        userObject.sipEnabled = this.user.get('settings').sip.enabled
      } else {
        userObject.sipEnabled = this.user.get('settings').sip.get('enabled')
      }
      userObject.sipId = this.user.get('settings').sip.id
    }

    userObject.id = this.user.get('shareId') || this.user.get('userId')
    userObject.email = this.user.get('userEmail')
    userObject.isdeleted = this.user.get('isDeleted')

    userObject.loginDate = getFormattedPendoDateTime(this.user.get('loginDate'))
    userObject.createdDate = getFormattedPendoDateTime(this.user.get('createdDate'))
    userObject.deletedDate = getFormattedPendoDateTime(this.user.get('deletedDate'))

    userObject.administrator = this.user.get('licenses')?.admin
    userObject.calendarLicense = this.user.get('licenses')?.calendar
    userObject.conversationAiEnabled = this.user.get('licenses')?.conversationAI
    userObject.ringDNADialer = this.user.get('licenses')?.intelligentDialer
    userObject.guidedSellingLicense = this.user.get('licenses')?.guidedSelling
    userObject.momentsLicense = this.user.get('licenses')?.moments
    userObject.supervisorLicense = this.user.get('licenses')?.supervisor
    userObject.supportLicense = this.user.get('licenses')?.support

    return userObject
  }

  private createAccountObject() {
    //TODO: in case updating account object, please update on server side too
    const keys = [
      'accountCreatedByUserId',
      'accountIsDeleted',
      'accountLicenseId',
      'accountEmail',
      'accountName',
      'accountSfOrgId',
      'accountAdvancedSearch',
      'accountCallDispositionPrompt',
      'accountCallNoteTemplates',
      'accountCallNotification',
      'accountCallRecordingByCountry',
      'accountCallRecordingByState',
      'accountClickToVoicemail',
      'accountConference',
      'accountDynamicCallFlows',
      'accountEmailSendViaSalesforce',
      'accountGenAIEnabled',
      'accountLocalPresence',
      'accountManageCallDispositions',
      'accountMessaging',
      'accountMessagingFeaturesEnabled',
      'accountRecordingPauseEnabled',
      'accountRecordingStatusEnabled',
      'accountRelatedRecords',
      'accountSfdcCustomCallObject',
      'accountSfdcEnhancedAnalytics',
      'accountDateEnabledSfdcEnhancedAnalytics',
      'accountSmsTemplates',
      'accountVoicemailEnabled',
      'accountWebRtcEnabled',
      'accountType',
      'advancedSolutionPackageVersion',
      'basePackageVersion',
      'cadencePackageVersion',
      'allowedAdminLicenses',
      'usedDialerLicenses',
      'allowedDialerLicenses',
      'usedConversationAILicenses',
      'allowedConversationAILicenses',
      'usedAdminLicenses',
      'allowedAdminLicenses',
      'usedCalendarLicenses',
      'allowedCalendarLicenses',
      'usedGuidedSellingLicenses',
      'allowedGuidedSellingLicenses',
      'usedMomentsLicenses',
      'allowedMomentsLicenses'
    ]

    const accountObject: Record<string, any> = {}
    for (const value of keys) {
      accountObject[value] = this.user.get(value)
    }

    accountObject.id = this.user.get('accountId')
    accountObject.issfsandbox = this.user.get('accountIsSfSandbox')
    accountObject.name = this.accountName
    accountObject.parentGroupId = this.user.get('sfAccountId')
    accountObject.sforgid = this.user.get('accountSfOrgId')

    accountObject.accountCreatedDate = getFormattedPendoDateTime(this.user.get('accountCreatedDate'))
    accountObject.accountDateDeleted = getFormattedPendoDateTime(this.user.get('accountDateDeleted'))

    return accountObject
  }

  private init() {
    const userObj = this.createUserObject()
    const accountObj = this.createAccountObject()
    this.analyticsWrappers.forEach(({ init }) => init(userObj, accountObj))
  }

  public trackEvent: TrackEvent = (name: string, data?: Record<string, any>, type = 'Engagement') => {
    this.analyticsWrappers.forEach(({ track }) => track(name, data, type))
  }
}
