BackgridCell    = require('helpers/backgrid-cell')
BackgridRow     = require('helpers/backgrid-row')
BaseView        = require('base/view')
PagedCollection = require('base/paged-collection')
PagerView       = require('widgets/pager/view')

class AccountSearchView extends BaseView
  template: require('./template')
  className: 'account-search'

  regions:
    grid  : 'div.grid'
    pager : 'span.pager'

  initialize: (options) =>
    @qs = options.qs
    @qs.setFragment('accounts')

  onAttach: =>
    @qs.restore()
    @search()

  search: =>
    collection = new PagedCollection(
      page: @qs.get('page')
      queryParams:
        accountId    : @user().getSelectedSupportAccountId() if @user().accountPickerRenderable()
      url: 'accounts'
    )

    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))
    $(window).trigger('resize')
    collection.fetch(reset: true)

  buildGrid: (collection) ->
    viewCell = BackgridCell.accountCell('id', 'name')

    sfdcCell = BackgridCell.href(
      url   : '#accounts/:id/salesforce',
      label : ':sfOrgId',
      attrs : ['id', 'sfOrgId'])

    datetime = BackgridCell.dateTime()

    cols = [
      {label: 'Name',          name: 'name',        cell: viewCell}
      {label: 'Salesforce ID', name: 'sfOrgId',     cell: sfdcCell}
      {label: 'Sandbox',       name: 'isSfSandbox', cell: 'string'}
      {label: 'Date Created',  name: 'createdDate', cell: datetime}
      {label: 'Date Updated',  name: 'updatedDate', cell: datetime}
      {label: 'Date Deleted',  name: 'dateDeleted', cell: datetime}
      {label: 'ID',            name: 'id',          cell: 'string'}
    ]

    new Backgrid.Grid(
      collection : collection,
      columns    : cols,
      emptyText  : 'No accounts found.'
      row        : BackgridRow.deleted()
    )

module.exports = AccountSearchView
