import React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 35 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 128 128">
      <path fill="#FDFEFE" opacity="1.000000" stroke="none" d="
M69.000000,129.000000
	C46.000000,129.000000 23.500000,129.000000 1.000000,129.000000
	C1.000000,86.333336 1.000000,43.666668 1.000000,1.000000
	C43.666668,1.000000 86.333336,1.000000 129.000000,1.000000
	C129.000000,43.666668 129.000000,86.333336 129.000000,129.000000
	C109.166664,129.000000 89.333336,129.000000 69.000000,129.000000
M78.536339,107.039391
	C80.698685,104.292122 82.861031,101.544853 85.181442,98.596756
	C90.139229,103.624153 93.830811,107.367561 97.477493,111.065437
	C100.296066,108.536652 102.833031,106.260513 105.512741,103.856316
	C81.158775,78.635063 57.179470,53.801815 33.200169,28.968569
	C32.798153,29.215370 32.396137,29.462170 31.994120,29.708969
	C31.994120,33.998703 31.838406,38.297604 32.090054,42.572525
	C32.164478,43.836819 33.161278,45.256950 34.118660,46.227676
	C48.928661,61.244091 63.801884,76.198158 78.524109,91.029312
	C74.506020,95.255348 71.059486,99.310951 67.167015,102.880234
	C60.103901,109.356941 49.862843,108.837532 43.427788,101.992317
	C37.180206,95.346527 37.375935,85.405602 44.010002,78.607140
	C46.416000,76.141518 49.071606,73.919464 52.050858,71.184631
	C49.037209,68.520081 46.589291,66.355736 43.604721,63.716892
	C40.204586,67.339317 36.078930,70.820107 33.081791,75.092545
	C22.418978,90.292458 29.661921,111.358170 47.399052,116.828506
	C59.520836,120.567017 69.625366,116.604362 78.536339,107.039391
M67.461823,39.396076
	C65.010368,42.492283 62.558914,45.588490 59.599663,49.326050
	C61.967358,51.332325 64.555023,53.524998 67.210068,55.774757
	C76.272308,49.016899 81.365501,40.306400 78.445007,28.699669
	C75.920158,18.665302 68.913940,12.811131 58.713566,11.384116
	C48.012974,9.887118 40.607426,15.781828 33.269409,23.592875
	C34.821449,24.803823 36.279526,25.526596 37.142765,26.690533
	C40.069241,30.636410 42.509159,30.299557 45.799133,26.826025
	C50.758804,21.589640 56.254536,20.644445 61.550640,23.309439
	C66.977943,26.040449 68.989784,30.999390 67.461823,39.396076
M90.240067,41.970268
	C88.764343,41.576061 87.288620,41.181854 85.709015,40.759895
	C84.754562,49.510731 84.754562,49.510735 90.632759,54.904114
	C95.614906,59.475349 97.613380,66.580467 95.209511,72.658966
	C94.084694,75.503220 92.213623,78.052338 90.592697,80.889359
	C92.710220,83.183136 94.908516,85.564423 97.069832,87.905640
	C110.000031,79.187843 112.509819,53.088924 90.240067,41.970268
z"/>
      <path fill="#145574" opacity="1.000000" stroke="none" d="
M78.271812,107.275482
	C69.625366,116.604362 59.520836,120.567017 47.399052,116.828506
	C29.661921,111.358170 22.418978,90.292458 33.081791,75.092545
	C36.078930,70.820107 40.204586,67.339317 43.604721,63.716892
	C46.589291,66.355736 49.037209,68.520081 52.050858,71.184631
	C49.071606,73.919464 46.416000,76.141518 44.010002,78.607140
	C37.375935,85.405602 37.180206,95.346527 43.427788,101.992317
	C49.862843,108.837532 60.103901,109.356941 67.167015,102.880234
	C71.059486,99.310951 74.506020,95.255348 78.524109,91.029312
	C63.801884,76.198158 48.928661,61.244091 34.118660,46.227676
	C33.161278,45.256950 32.164478,43.836819 32.090054,42.572525
	C31.838406,38.297604 31.994120,33.998703 31.994120,29.708969
	C32.396137,29.462170 32.798153,29.215370 33.200169,28.968569
	C57.179470,53.801815 81.158775,78.635063 105.512741,103.856316
	C102.833031,106.260513 100.296066,108.536652 97.477493,111.065437
	C93.830811,107.367561 90.139229,103.624153 85.181442,98.596756
	C82.861031,101.544853 80.698685,104.292122 78.271812,107.275482
z"/>
      <path fill="#3897B4" opacity="1.000000" stroke="none" d="
M67.615005,39.015518
	C68.989784,30.999390 66.977943,26.040449 61.550640,23.309439
	C56.254536,20.644445 50.758804,21.589640 45.799133,26.826025
	C42.509159,30.299557 40.069241,30.636410 37.142765,26.690533
	C36.279526,25.526596 34.821449,24.803823 33.269409,23.592875
	C40.607426,15.781828 48.012974,9.887118 58.713566,11.384116
	C68.913940,12.811131 75.920158,18.665302 78.445007,28.699669
	C81.365501,40.306400 76.272308,49.016899 67.210068,55.774757
	C64.555023,53.524998 61.967358,51.332325 59.599663,49.326050
	C62.558914,45.588490 65.010368,42.492283 67.615005,39.015518
z"/>
      <path fill="#3A98B5" opacity="1.000000" stroke="none" d="
M90.601578,42.113445
	C112.509819,53.088924 110.000031,79.187843 97.069832,87.905640
	C94.908516,85.564423 92.710220,83.183136 90.592697,80.889359
	C92.213623,78.052338 94.084694,75.503220 95.209511,72.658966
	C97.613380,66.580467 95.614906,59.475349 90.632759,54.904114
	C84.754562,49.510735 84.754562,49.510731 85.709015,40.759895
	C87.288620,41.181854 88.764343,41.576061 90.601578,42.113445
z"/>
</svg>
  )
}

export default SvgComponent
