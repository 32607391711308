BaseCollection        = require('base/collection')
DispositionTeamModel = require('./model')

class DispositionTeamCollection extends BaseCollection
  model: DispositionTeamModel
  url: =>
    'callDispositions/team'

  toJSON: =>
    @toPlayJSON('callDispositionTeams')

module.exports = DispositionTeamCollection