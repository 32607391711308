BaseView       = require('base/view')

class DowntimeEventEditorModal extends BaseView
  template: require('./downtime-event-editor-template')
  className: 'downtime-event-editor-modal'

  ui:
    modal   : '#downtime-event-editor-modal'
    save    : 'button.save-downtime-event'
    start   : 'input.start'
    end     : 'input.end'
    reason  : 'select.reason'
    comment : 'textarea.comment'
    delete  : 'button.delete-downtime-event'

  bindings:
    '.reason' : 'value:reason'
    '.comment' : 'value:comment'
    '.modal-title' : 'text:title'
    '.save-downtime-event' : 'text:saveButtonText'
    '.delete-downtime-event' : 'toggle:showDelete'

  events:
    'click @ui.save' : 'save'
    'click @ui.delete' : 'delete'

  computeds:
    title:
      deps: ['id']
      get: (id) ->
        if id then 'Edit Downtime Event' else 'Add Downtime Event'
    saveButtonText:
      deps: ['id']
      get: (id) ->
        if id then 'Save' else 'Add'
    showDelete:
      deps: ['id']
      get: (id) ->
        if id then true else false

  initialize: (options) ->
    {@model} = options
    @reloadOnClose = false

  onAttach: =>
    @ui.modal.on('hide.bs.modal', (e) =>
      @triggerMethod('reload') if @reloadOnClose
    )

  onRender: =>
    datetimepickerOpts =
      defaultDate: new Date().setHours(0, 0, 0, 0)
      ignoreReadonly: true
      format: 'MM/DD/YYYY hh:mm a'
      keepOpen: true
      viewMode: 'days'
    datetimepickerOpts.defaultDate = new Date(@model.get('startDate')) if @model.get('startDate')
    @ui.start.datetimepicker(datetimepickerOpts)
    datetimepickerOpts.defaultDate = new Date(@model.get('endDate')) if @model.get('endDate')
    @ui.end.datetimepicker(datetimepickerOpts)

  save: =>
    return unless @validate()

    startDate = @ui.start.data('DateTimePicker').date().unix() * 1000
    endDate = @ui.end.data('DateTimePicker').date().unix() * 1000
    @model.set(
      startDate: startDate
      endDate: endDate
    )
    @model
      .persist(ladda: @ui.save[0])
      .done( =>
        @reloadOnClose = true
        toastr.info('New record added.')
        @ui.modal.modal('hide')
      )

  delete: =>
    @ui.save.attr('disabled', 'disabled')
    @model
      .delete(ladda: @ui.delete[0])
      .done( =>
        @reloadOnClose = true
        toastr.info('Record deleted.')
        @ui.modal.modal('hide')
      )

  validate: =>
    if @ui.start.data('DateTimePicker').date() >= @ui.end.data('DateTimePicker').date()
      toastr.warning('End time cannot be equals or earlier than the Start time!', 'Oops!')
      return false

    return true

module.exports = DowntimeEventEditorModal
