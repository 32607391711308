import * as React from 'react'

import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function TaskIcon({ size = 20, color = Colors.N100, ...props }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M1.2 2h17.6c.11 0 .2.09.2.2v16.6a.2.2 0 01-.2.2H1.2a.2.2 0 01-.2-.2V2.2c0-.11.09-.2.2-.2zM5 1v2M15 1v2"
        stroke={color}
        strokeLinecap="round"
      />
      <path d="M15.05 6.93l-6.787 6.788L6 11.455" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default TaskIcon
