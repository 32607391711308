import * as React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function CallRecordIcon({ size = 24, color = Colors.N100, strokeWidth = 1 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" data-testid="call-record-icon">
      <path
        d="M13.018 8c.189-.53.38-.89.493-1.08a.438.438 0 01.283-.199l1.725-.395a.5.5 0 00.389-.488V2.923a.5.5 0 00-.354-.479l-2.841-.868a.457.457 0 00-.446.095c-.556.51-2.16 2.17-3.105 5.092-.126.39-.24.802-.338 1.237m-.057 7.738c.11.532.244 1.032.395 1.499.944 2.922 2.549 4.582 3.105 5.092.12.11.29.142.446.095l2.84-.869a.5.5 0 00.355-.478v-2.916a.5.5 0 00-.389-.487l-1.725-.395a.438.438 0 01-.283-.2 6.52 6.52 0 01-.58-1.341"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.42 9.8c.293 0 .549.056.769.168.22.108.39.264.51.468.124.2.186.434.186.702 0 .28-.074.526-.222.738a1.192 1.192 0 01-.594.45L9.957 14h-.768l-.798-1.524h-.936V14h-.69V9.8H8.42zm-.965 2.076h.912c.248 0 .444-.066.588-.198a.698.698 0 00.222-.54.68.68 0 00-.222-.534c-.144-.136-.34-.204-.588-.204h-.912v1.476zm3.06-2.076h2.742v.612h-2.052v1.164h1.692v.594h-1.692v1.218h2.124V14h-2.814V9.8zm3.09 2.1c0-.424.082-.796.246-1.116.168-.324.404-.574.708-.75a2.09 2.09 0 011.074-.27c.316 0 .6.06.852.18.256.12.464.292.624.516.16.22.262.476.306.768h-.69a1.116 1.116 0 00-.396-.63 1.149 1.149 0 00-.726-.228c-.26 0-.488.066-.684.198a1.225 1.225 0 00-.45.54 1.89 1.89 0 00-.156.792c0 .296.052.56.156.792.104.232.254.414.45.546.196.128.424.192.684.192.288 0 .528-.076.72-.228.196-.152.328-.362.396-.63h.69a1.725 1.725 0 01-.306.774c-.156.22-.36.39-.612.51s-.538.18-.858.18c-.408 0-.766-.088-1.074-.264a1.815 1.815 0 01-.708-.744 2.466 2.466 0 01-.246-1.128z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default CallRecordIcon
