import * as React from 'react'

type Props = {
  size?: number
}

const SvgComponent = ({ size = 24 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_8860_67288)">
        <path d="M0 0H24V24H0V0Z" fill="#F3F3F3" />
        <path d="M1.04297 1.04297H11.4778V11.4778H1.04297V1.04297Z" fill="#F35325" />
        <path d="M12.5234 1.04297H22.9582V11.4778H12.5234V1.04297Z" fill="#81BC06" />
        <path d="M1.04297 12.5215H11.4778V22.9563H1.04297V12.5215Z" fill="#05A6F0" />
        <path d="M12.5234 12.5215H22.9582V22.9563H12.5234V12.5215Z" fill="#FFBA08" />
      </g>
      <defs>
        <clipPath id="clip0_8860_67288">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
