import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useCalendarSettings, usePutCalendarWelcomeMessage } from '../../../../store/api/personal-calendar-api'
import { defaultWelcomeMessage } from './constants'
import { useActiveUser } from '../../../../hooks/useActiveUser'
import useToast from '../../../common/hooks/useToast'
import RdnaLoader from '@ringdna/common/src/components/RdnaLoader'
import Settings from './Settings'
import { FormValues } from './model'

const ERROR_MESSAGE =
  'We apologize, but we’re having trouble loading this page. Please wait a few minutes and try again. ' +
  'If you’re still experiencing issues, please reach out to our ' +
  "<a href='https://support.revenue.io/hc/en-us/requests/new' target='_blank' rel='noopener noreferrer'>" +
  'Support</a> team.'

export default function PersonalizeSettings() {
  const toastr = useToast()
  const { activeUserId } = useActiveUser()
  const [welcomeMessage, setWelcomeMessage] = useState<string>(defaultWelcomeMessage)
  const [calendarSettings, error, loading] = useCalendarSettings({ meta: { id: activeUserId } })
  const [isSaving, setSaving] = useState<boolean>(false)
  const putCalendarWelcomeMessage = usePutCalendarWelcomeMessage()

  useEffect(() => {
    if (error && error.message) toastr.error(ERROR_MESSAGE)
  }, [error, toastr])

  useEffect(() => {
    if (calendarSettings && calendarSettings.settings.welcomeMessage)
      setWelcomeMessage(calendarSettings.settings.welcomeMessage)
  }, [calendarSettings])

  const submitForm = async ({ welcomeMessage }: FormValues) => {
    if (!calendarSettings) return
    setSaving(true)
    try {
      await putCalendarWelcomeMessage({
        id: activeUserId,
        body: {
          'settings.welcomeMessage': welcomeMessage,
          'settings.id': calendarSettings.settings.id
        }
      })
      setSaving(false)
      setWelcomeMessage(welcomeMessage || defaultWelcomeMessage)
    } catch (error) {
      setWelcomeMessage(welcomeMessage || defaultWelcomeMessage) // toastr.error(error.message) <- until endpoint done
      setSaving(false)
    }
  }

  return (
    <Container>
      <RdnaLoader
        data={calendarSettings && [calendarSettings]}
        noResultsMsg="Something went wrong, try refreshing!"
        loading={!!loading}
        top="130px"
      >
        {calendarSettings && (
          <Settings
            userSettings={calendarSettings.settings.user}
            welcomeMessage={welcomeMessage}
            calendarBookingUrl={calendarSettings.calendarBookingUrl}
            isSaving={isSaving}
            submitForm={submitForm}
          />
        )}
      </RdnaLoader>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 ${props => props.theme.spacing * 3}px;
  min-height: 190px;
`
