BaseGrid = require('base/grid')
BaseView = require('base/view')
UserStatusModal = require('./editor/view')
UserStatusModel = require('./model')
UserStatusCollection = require('./collection')

class CustomUserStatusView extends BaseView
  template: require('./template')

  className: 'custom-user-status'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  bindings:
    '.custom-user-status-control': 'toggle:showCustomUserStatusControl'
    '.custom-user-status-enabled': 'checked:customUserStatusEnabled'
    '.custom-user-status-body': 'toggle:customUserStatusEnabled'
    '.custom-user-status-auto-switch': 'checked:customUserStatusAutoSwitch'
    '.custom-user-status-time-override': 'checked:customUserStatusTimeOverride'

  events:
    'click a.add' : 'createUserStatus'

  computeds:
    showCustomUserStatusControl:
      get: -> @user().isSupport()

  initialize: (options) ->
    { @accountId, @teams } = options
    @viewModel = new Backbone.Model(
      customUserStatusEnabled: options.customUserStatusEnabled
      customUserStatusAutoSwitch: options.customUserStatusAutoSwitch
      customUserStatusTimeOverride: options.customUserStatusTimeOverride
    )
    @listenTo(@viewModel, 'change:customUserStatusEnabled', =>
      @trigger('customUserStatusEnabledChange', @viewModel.get('customUserStatusEnabled'))
    )
    @listenTo(@viewModel, 'change:customUserStatusAutoSwitch', =>
      @trigger('customUserStatusAutoSwitchChange', @viewModel.get('customUserStatusAutoSwitch'))
    )
    @listenTo(@viewModel, 'change:customUserStatusTimeOverride', =>
      @trigger('customUserStatusTimeOverrideChange', @viewModel.get('customUserStatusTimeOverride'))
    )
    @collection = new UserStatusCollection()

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId)
      .done(=>
        return if not @getRegion('grid')
        @showChildView('grid', @buildGrid())
    )

  buildGrid: =>
    actionCell = class extends Backgrid.Cell
      className: 'action-cell'
      events:
        'click a[data-action="update"]': 'edit'
        'click a.delete': 'delete'

      edit: =>
        @column.channel.trigger('edit', { model: @model })

      delete: ->
        inProgress = false
        name = @model.get('name')

        dialog = bootbox.dialog(
          title: 'Delete User Status'
          message: "Are you sure you want to delete the status, <b>#{_.escape(name)}</b>? This will not affect historical reporting. Any changes will not affect users who are actively using the status."
          onEscape: =>
            return false if inProgress
            true
          buttons:
            cancel:
              label: 'Cancel'
              className: 'btn btn-default pull-left'
              callback: =>
                return false if inProgress
                true
            ok:
              label: 'Delete'
              className: 'btn btn-danger delete-ok'
              callback: =>
                inProgress = true
                @model
                  .delete(ladda: $('.delete-ok')[0], wait: true)
                  .done( => dialog.modal('hide'))
                  .fail( => dialog.modal('hide'))
                false
            )

      render: ->
        name = @model.get('name')
        if (name isnt 'Available' and name isnt 'Busy')
          $(@el).html('''
            <a class="update" data-action="update" data-toggle="modal" data-target="#user-status-modal">
              <span class="glyphicon glyphicon-pencil"></span>
            </a>
            <a class="delete">
              <span class="glyphicon glyphicon-remove-sign"></span>
            </a>
          ''')
        else
          $(@el).html('')
        @

    busyCell = class extends Backgrid.Cell
      render: ->
        @listenTo(@model, 'change', -> @render())
        busyText = if JSON.parse(@model.get('busy')) then 'Yes' else 'No'
        $(@el).html(busyText)
        @

    defaultExpirationCell = class extends Backgrid.Cell
      render: ->
        @listenTo(@model, 'change', -> @render())
        defaultExpiration = @model.get('defaultExpiration')
        defaultExpiration = if defaultExpiration then defaultExpiration else 'No Expiration'
        $(@el).html(defaultExpiration)
        @

    teamsCell = class extends Backgrid.Cell
      render: ->
        @listenTo(@model, 'change', -> @render())
        teams = @model.get('teams')
        if teams?.models.length
          links = []
          for team in teams.models
            links.push("<a target='blank' href='#teams/#{_.escape(team.get('id'))}'>#{_.escape(team.get('name'))}</a>")
          $(@el).html(links.join(', '))
        else
          $(@el).html('All')
        @

    columns = [
      { name: 'name',        label: 'Name',        cell: 'string' },
      { name: '',            label: 'Busy',        cell: busyCell },
      { name: '',            label: 'Time',        cell: defaultExpirationCell },
      { name: 'description', label: 'Description', cell: 'string' },
      { name: '',            label: 'Team',        cell: teamsCell },
      { name: '',            label: 'Actions',     cell: actionCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No user statuses found.'
      onEdit     : @showEditor
    )

  createUserStatus: =>
    @showChildView('modal', new UserStatusModal(
      accountId: @accountId
      model: new UserStatusModel()
      collection: @collection
      existingTeams: @teams
      isEdit: false
    ))

  showEditor: (options) =>
    @showChildView('modal', new UserStatusModal(
      accountId: @accountId
      model: options.model
      collection: @collection
      existingTeams: @teams
      isEdit: true
    ))

module.exports = CustomUserStatusView
