BaseCollection = require('base/collection')
CallTrackingHelper = require('features/call-tracking/helper')
Model = require('./model')

class CustomCallTrackingCollection extends BaseCollection
  url: -> 'custom'

  toJSON: =>
    fields = @toPlayJSON('customCallTracking')
    fields['accountId'] = @accountId
    fields

  model: Model

  createCustoms: (accountId, notAddressesCollection, callback) =>
    @accountId = accountId
    @create().done((customs) =>
      for custom in customs
        custom['account'] = { name: custom.accountName }
      needAddresses = CallTrackingHelper.filterCallTracking(@, customs)
      @reset(needAddresses) if needAddresses.length isnt 0
      @reset(notAddressesCollection.models) if notAddressesCollection and notAddressesCollection.length isnt 0
      callback(needAddresses.length isnt 0) if callback
    )

module.exports = CustomCallTrackingCollection