import React, { useMemo } from 'react'
import { useUser } from '../contexts/UserContext'
import ReportFilters from '@ringdna/common/src/components/RdnaReports/ReportFilters'
import MetabaseView from '@ringdna/common/src/components/RdnaReports/MetabaseView'
import RdnaReports from '@ringdna/common/src/components/RdnaReports'
import { ReportFilterType } from '@ringdna/common/src/components/RdnaReports/types'
import RdnaHorizontalTab from '@ringdna/common/src/components/RdnaHorizontalTab'
import GraphIcon from '@ringdna/common/src/assets/icons/svg/Graph'
import TableIcon from '@ringdna/common/src/assets/icons/svg/TableIcon'
import AnalyticsUtils from '@ringdna/common/src/utils/analyticsUtils'
import BacklinkButton from './BacklinkButton'

const questions = [
  'avg-call-dur-day',
  'avg-call-dur-day-tab',
  'call-dur-day-tab',
  'call-distribution',
  'call-duration',
  'call-log',
  'call-recordings',
  'call-reports',
  'call-transfer',
  'caller-types-day',
  'caller-types-day-tab',
  'conference-calls',
  'new-accounts',
  'spam-calls',
  'user-presence-daily-totals',
  'yoda-notifications-by-disposition'
]

const getFiltersForReport = (id: string): ReportFilterType[] => {
  switch (id) {
    case 'call-distribution':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.LOCATION,
        ReportFilterType.CALL_QUEUE_NAME,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'call-efficiency':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.TEAM,
        ReportFilterType.CALL_DISPOSITION_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'call-log':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.TEAM,
        ReportFilterType.FROM_NUMBER,
        ReportFilterType.TO_NUMBER,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'call-recordings':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.CALLER,
        ReportFilterType.VOICEMAIL,
        ReportFilterType.DIRECTION
      ]
    case 'call-reports':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.LOCATION,
        ReportFilterType.DIRECTION,
        ReportFilterType.CALL_CATEGORY,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE,
        ReportFilterType.TIMEZONE
      ]
    case 'call-transfer':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.CALL_DISPOSITION,
        ReportFilterType.TRANSFER_NUMBER,
        ReportFilterType.FROM_NUMBER,
        ReportFilterType.TO_NUMBER,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'call-volume':
      return [
        ReportFilterType.AGENT_MULTIPLE,
        ReportFilterType.TEAM_MULTIPLE,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'conference-calls':
      return [ReportFilterType.TO_NUMBER, ReportFilterType.START_DATE, ReportFilterType.END_DATE]
    case 'call-quality':
    case 'user-busy-presence-summary':
    case 'user-busy-presence-summary-day':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'emails-sent':
      return [ReportFilterType.AGENT, ReportFilterType.TEAM, ReportFilterType.START_DATE, ReportFilterType.END_DATE]
    case 'hold-mute-pause-report':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION,
        ReportFilterType.CALL_QUEUE_NAME,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'missed-ring-rates':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.USER_STATUS,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION,
        ReportFilterType.CALL_QUEUE_NAME,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'messages':
      return [
        ReportFilterType.AGENT,
        ReportFilterType.TEAM,
        ReportFilterType.TO_NUMBER,
        ReportFilterType.FROM_NUMBER,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'qbr':
      return [ReportFilterType.TEAM]
    case 'queue-metrics':
      return [
        ReportFilterType.CALL_QUEUE_NAME,
        ReportFilterType.TEAM,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    case 'spam-calls':
      return [ReportFilterType.AGENT, ReportFilterType.START_DATE, ReportFilterType.END_DATE]
    case 'supervisor':
      return [
        ReportFilterType.SUPERVISOR_NAME,
        ReportFilterType.TEAM,
        ReportFilterType.LOCATION,
        ReportFilterType.START_DATE,
        ReportFilterType.END_DATE
      ]
    default:
      return [ReportFilterType.START_DATE, ReportFilterType.END_DATE]
  }
}

const accountReports = ['emails-sent', 'qbr']
const includeTabsList = ['avg-call-dur-day', 'caller-types-day']

type Props = {
  headline: string
  name: string
  subHeadline?: string
}

export default function MetabaseReports({ headline, subHeadline, name }: Props) {
  const isDashboard = questions.indexOf(name) === -1
  const includeTabs = includeTabsList.indexOf(name) > -1
  const userSettings = useUser()
  const analytics = useMemo(() => new AnalyticsUtils(userSettings), [userSettings])

  const tabs = useMemo(
    () => [
      {
        id: 'graph',
        title: '',
        Icon: GraphIcon,
        Content: <MetabaseView reportName={name} isDashboard={isDashboard} analytics={analytics} />
      },
      {
        id: 'tabular',
        title: '',
        Icon: TableIcon,
        Content: <MetabaseView reportName={name + '-tab'} isDashboard={isDashboard} analytics={analytics} />
      }
    ],
    [isDashboard, name, analytics]
  )

  return (
    <>
      {!accountReports.includes(name) && <BacklinkButton />}
      <RdnaReports
        headline={headline}
        subHeadline={subHeadline}
        userData={{
          accountId: userSettings.accountId,
          licenses: userSettings.licenses,
          userName: userSettings.userName,
          isAuthorizedAdmin: userSettings.isAuthorizedAdmin
        }}
      >
        <>
          <ReportFilters filters={getFiltersForReport(name)} key={name} reportName={name} />
          {includeTabs ? (
            <RdnaHorizontalTab<string>
              tabs={tabs}
              headerStyle={{ maxWidth: 130, marginLeft: 'auto' }}
              showHorizontalLine={false}
              tabLabel="View:"
            />
          ) : (
            <MetabaseView reportName={name} isDashboard={isDashboard} analytics={analytics} />
          )}
        </>
      </RdnaReports>
    </>
  )
}
