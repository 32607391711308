CallflowCollection = require('widgets/callflow-picker/collection')
CampaignCollection = require('features/smart-numbers/overview/campaign/modals/collection')
CountryCollection = require('widgets/smart-numbers-widget/country-collection')

class CallTrackingWidgets

  @getInfo: (accountId, cb) =>
    campaignCollection = new CampaignCollection()
    callflowCollection = new CallflowCollection()
    @countryCollection = new CountryCollection()
    $.when(
      campaignCollection.fetch(data: accountId: accountId)
      callflowCollection.fetch(data: accountId: accountId)
      @countryCollection.fetch(data: accountId: accountId))
    .done((campaigns, callflows, countries) =>
      if campaigns?[0] and callflows?[0] and countries?[0]
        sortCountries = _.sortBy(countries[0], 'countryName')
        @countryCollection.reset(sortCountries)
        cb(campaigns[0], callflows[0], sortCountries)
    )

  @initCallflowSelectize: (model, callflows) =>
    $(".callflows-#{model.cid}").selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      placeholder: 'Call Flow'
      options: callflows
      items: [model.get('callFlow').id]
      onChange: (value) =>
        model.set(
          callFlow:
            id: value)
        if value
          $(".error-container-#{model.cid}").removeClass('has-error')
        else
          model.trigger('checkCallFlow')
    )

  @initCampaignSelectize: (model, campaigns) =>
    $(".campaigns-#{model.cid}").selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      placeholder: 'SFDC Campaign'
      options: campaigns
      items: [model.get('campaignId')]
      onChange: (value) =>
        if value
          model.trigger('campaignAlert', value)
        else
          model.set('campaignId', null))

  @initCountrySelectize: (model, countries) =>
    $(".countries-#{model.cid}").selectize(
      allowEmptyOption: true
      selectOnTab: true
      maxItems: 1
      placeholder: 'Country'
      onInitialize: =>
        selectize = $(".countries-#{model.cid}")[0].selectize
        return unless selectize
        selectize.addOption(value: country.countryCode, text: country.countryName) for country in countries
        selectize.addItem(if model.get('countryCode') then model.get('countryCode') else 'US')
      onChange: (value) =>
        model.set('countryCode', value)
        if value
          $(".error-container-#{model.cid}").removeClass('has-error')
        else
          model.trigger('checkCountry'))

  @areaCodeCell: =>
    class extends Backgrid.Cell
      events:
        'change .area-code-cell': 'areaCodeChanged'
      areaCodeChanged: (e) =>
        value = $(e.target).val()
        $(e.target).val(value.replace(/[^0-9]/g, ''))
        @model.set('areaCode', $(e.target).val())
      render: =>
        @showHtml(false, '')
        @listenTo(@model, 'change:typeNumber', => @showHtml(false, if @model.get('typeNumber') isnt 'Local' then 'disabled' else ''))
        @listenTo(@model, 'change:areaCode', =>
          @showHtml(not @model.get('areaCode') and @model.get('typeNumber') isnt 'Free',
            if @model.get('typeNumber') isnt 'Local' then 'disabled' else '')
        )
        @listenTo(@model, 'checkAreaCode', => @showHtml(true, ''))
        @listenTo(@model, 'change:countryCode', => @showHtml(false, ''))
      showHtml: (hasError, disabled) =>
        disabled = 'disabled' if @model.get('countryCode') and @model.get('countryCode') isnt 'US'
        html = """<input type='text' class='form-control area-code-cell' #{disabled} value=\"#{@model.get('areaCode')}\"/>"""
        html = """<div class='has-error'>""" + html + '</div>' if hasError
        $(@el).html(html)
        @

  @countryCell: (countries) =>
    class extends Backgrid.Cell
      className: 'country-cell'
      render: =>
        $(@el).html("<div class='error-container-#{@model.cid}'><select class='form-control countries-#{@model.cid}'></select></div>")
        _.defer(=> CallTrackingWidgets.initCountrySelectize(@model, countries))
        @listenTo(@model, 'checkCountry', => @$(".error-container-#{@model.cid}").addClass('has-error'))
        @

  @callFlowCell: (callflows) =>
    class extends Backgrid.Cell
      className: 'call-flow-cell'
      render: =>
        $(@el).html("<div class='error-container-#{@model.cid}'><select class='form-control callflows-#{@model.cid}'></select></div>")
        _.defer( => CallTrackingWidgets.initCallflowSelectize(@model, callflows))
        @listenTo(@model, 'checkCallFlow', => @$(".error-container-#{@model.cid}").addClass('has-error'))
        @

  @campaignCell: (campaigns) =>
    class extends Backgrid.Cell
      className: 'campaign-cell'
      render: =>
        $(@el).html("<select class='form-control campaigns-#{@model.cid}'></select>")
        _.defer(=> CallTrackingWidgets.initCampaignSelectize(@model, campaigns))
        @listenTo(@model, 'campaignAlert', (value) => @showCampaignAlert(value)) if not @model._events['campaignAlert']
        @
      showCampaignAlert: (id) =>
        campaign = campaigns.find((e) -> e.value is id)
        if campaign
          if campaign.isAssigned
            bootbox.confirm('This campaign is already associated with another smart number. Do you want to continue?', (ok) =>
              @model.set('campaignId', if ok then id else null)
              @render() if not ok
            )
          else
            @model.set('campaignId', id)

  @typeSmartNumberValueCell: =>
    class extends Backgrid.Cell
      className: 'smart-number-value-column'

      events:
        'click .remove' : -> @model.removeNumber()

      render: =>
        @showHtml()
        @listenTo(@model, 'change:number', => @showHtml())

      showHtml: =>
        if @model.get('number')
          $(@el).html("<span class='label label-default label-column'>
            <span class='smart-number-value'>#{@model.get('number')}</span>
            <span class='glyphicon glyphicon-remove-sign remove'></span>
          </span>")
        else
          $(@el).html("<div></div>")
        @

  @typeSmartNumberCell: =>
    class extends Backgrid.Cell

      events:
        'change .number-type': 'typeChanged'

      render: =>
        @showHtml(false)
        @listenTo(@model, 'checkTypeNumber', => @showHtml(true))
        @listenTo(@model, 'change:countryCode', => @showHtml(false))

        @listenTo(@model.collection, 'change:typeNumber', => @showHtml(false))
        @listenTo(@model.collection, ' change:number', => @showHtml(false))

      typeChanged: (e) =>
        @model.updateNumberType($(e.target).val())

      showHtml: (hasError) =>
        unassignedNumbersOpened = @model.collection.find(typeNumber: 'Existing', number: null)
        disabled = ''
        if @model.get('countryCode') and @model.get('countryCode') isnt 'US'
          disabled = 'disabled'
          @model.set('typeNumber', 'Local')

        html = "<select class='form-control number-type' #{disabled}><option value='none'>Select</option>
                  <option value='Free' #{@checkIsSelected('Free')}>Toll Free</option>
                  <option value='Local' #{@checkIsSelected('Local')}>Local</option>
                  <option value='Existing' #{@checkIsSelected('Existing')} #{if unassignedNumbersOpened != undefined then 'disabled' else ''}>Use Existing</option>
                </select>"
        html = "<div class='has-error'>#{html}</div>" if hasError
        $(@el).html(html)
        @

      checkIsSelected: (type) => if @model.get('typeNumber') is type then 'selected' else ''

module.exports = CallTrackingWidgets
