// X05 Colors are .12 of their respective X20 peer color.
export enum Colors {
  TRANSPARENT = 'transparent',

  // NEUTRAL
  N0 = '#FFFFFF',
  N10 = '#F7F7F7',
  N20 = '#F0F0F0',
  N30 = '#EAEAEA',
  N40 = '#DBDBDB',
  N50 = '#CCCCCC',
  N60 = '#A9A9A9',
  N70 = '#979797',
  N80 = '#5C5C5C',
  N90 = '#333333',
  N100 = '#000000',

  // COBALT
  C05 = 'rgba(153, 194, 255, .12)',
  C20 = '#99C2FF',
  C30 = '#66A3FF',
  C40 = '#3385FF',
  C50 = '#0066FF', // @NOTE: reserved for agents
  C60 = '#0052CC',
  C70 = '#003D99',
  C80 = '#002966',

  // MIDNIGHT
  M05 = 'rgba(179, 196, 217, .12)',
  M20 = '#B3C4D9',
  M30 = '#4D75A5',
  M40 = '#265892',
  M50 = '#003A7F',
  M60 = '#002E66',
  M70 = '#00234C',
  M80 = '#001733',

  // COBALT GREY <-- added because these colors being used by material-ui theme
  CG60 = '#546e7a',
  CG90 = '#263238',

  // PURPLE
  P05 = 'rgba(200, 182, 247, .12)',
  P20 = '#C8B6F7',
  P30 = '#AC92F2',
  P40 = '#916DEE', // @NOTE: reserved for clients / users other than agents
  P50 = '#7549EA',
  P60 = '#5E3ABB',
  P70 = '#462C8C',
  P80 = '#2F1D5E',

  // POPPY
  PY05 = '#FFF1E3',
  PY20 = '#FFD6AD',
  PY30 = '#FFC285',
  PY40 = '#FFAD5C',
  PY50 = '#FF9933',
  PY60 = '#CC7A29',
  PY70 = '#995C1F',
  PY80 = '#663D14',

  // VERDANT
  V05 = '#E6F9F3',
  V20 = '#ADEBD6',
  V30 = '#85E0C2',
  V40 = '#5CD6AD',
  V50 = '#33CC99',
  V60 = '#29A37A',
  V70 = '#1F7A5C',
  V80 = '#14523D',

  // SUNBURST
  S05 = 'rgba(254, 239, 174, .12)',
  S20 = '#FEEFAE',
  S30 = '#FEE886',
  S40 = '#FDE05D',
  S50 = '#FDD835',
  S60 = '#CAAD2A',
  S70 = '#988220',
  S80 = '#655615',

  // FLAME
  F05 = '#FFE9E9',
  F20 = '#FFB5B5',
  F30 = '#FF8F8F',
  F40 = '#FF6A6A',
  F50 = '#FF4545',
  F60 = '#CC3737',
  F70 = '#992929',
  F80 = '#661C1C',

  // Moments
  Y05 = 'rgba(137, 155, 249, .12)',
  Y20 = '#899BF9',
  Y30 = '#7689F1',
  Y40 = '#576FF3',
  Y50 = '#3B58F5',
  Y60 = '#2F46C4',
  Y70 = '#235593',
  Y80 = '#182362',

  // SAGE
  SG05 = 'rgba(164, 184, 185, .12)',
  SG20 = '#A4B8B9',
  SG30 = '#779497',
  SG40 = '#497174',
  SG50 = '#1C4D51',
  SG60 = '#163E41',
  SG70 = '#112E31',
  SG80 = '#0B1F20',

  SALESFORCE_ACCOUNT = '#818EDB',
  SALESFORCE_CASE = '#EDCF6E',
  SALESFORCE_CONTACT = '#9D97E7',
  SALESFORCE_CAMPAIGN = '#E79A62',
  SALESFORCE_CUSTOM = '#5A9CDD',
  // eslint-disable-next-line
  SALESFORCE_FAVORITE = '#FDD835',
  SALESFORCE_LEAD = '#EA8F6A',
  SALESFORCE_EVENT = '#EB7092',
  // eslint-disable-next-line
  SALESFORCE_MULTIMATCH = '#0066FF',
  SALESFORCE_OPPORTUNITY = '#F3BA6B',
  SALESFORCE_TASK = '#8066f3',
  SALESFORCE_BLUE = '#139CD8',

  BLACK = N100,
  GREY = N70,
  LIGHT_GREY = N30,
  LIGHTER_GREY = N10,
  MID_GREY = N50,
  WHITE = N0,
  BLUE = C50,
  PURPLE = P50,
  GREEN = V50,
  ORANGE = PY50,
  YELLOW = S50,
  RED = F50,
  DARK_PURPLE = P70,
  MIDNIGHT = M50,
  SKY = C20,
  LAVENDAR = P20
}

export enum ThemePalette {
  PRIMARY = Colors.BLUE,
  NEUTRAL = Colors.LIGHT_GREY,
  NEUTRAL_DARK = Colors.MID_GREY,
  NEUTRAL_LIGHT = Colors.LIGHTER_GREY,
  SUCCESS = Colors.GREEN,
  ERROR = Colors.RED
}

export const ColorPalettes = {
  primary: 'primary',
  neutral: 'neutral',
  action: 'action',
  alert: 'alert'
}
