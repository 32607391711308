BaseView            = require('base/view')
LocationCollection  = require('widgets/location-picker/collection')
LocationPickerView  = require('widgets/location-picker/view')
CallFlowPickerView  = require('widgets/callflow-picker/view')

class EmergencyEditorModal extends BaseView

  template: require('./template')
  className: 'emergency-editor'

  regions:
    locationPicker : 'div.location-picker'
    callFlowPicker : 'div.call-flow-picker'

  ui:
    modal : '#emergency-editor-modal'
    save  : 'button.persist'

  events:
    'click @ui.save': 'save'

  bindings:
    '.flows'     : 'classes:{"has-error":flowError}'

  initialize: (options) =>
    { @accountId, @locations, @model, @collection} = options
    @viewModel = new Backbone.Model(flowError: false)
    selectedLocations = []
    for emergencyRouteLocation in @model.get('emergencyRouteLocations')
      selectedLocations.push(emergencyRouteLocation.location)
    @selectedLocations = new LocationCollection(selectedLocations)

  onRender: =>
    @locationPicker = new LocationPickerView(
      accountId: @accountId
      fullList: true
      locations: @locations
      selectedLocations: @selectedLocations
      placeholder: 'Default (All Locations)'
    )
    @showChildView('locationPicker', @locationPicker)
    @callFlowPicker = new CallFlowPickerView(
      accountId: @accountId
      placeholder: 'Search or Select Phone Number/Call Flow'
      showNumbers: true
      selectedFlowId: @model.get('callFlow')?.id
    )
    @listenTo(@callFlowPicker, 'change', (val) =>
      @viewModel.set(flowError: val is '')
    )
    @showChildView('callFlowPicker', @callFlowPicker)

  save: =>
    return unless @validate()
    locations = []
    locations.push(model.get('id')) for model in @selectedLocations.models
    @model.set(
      locations: locations
      callFlow: parseInt(@callFlowPicker.val())
    )
    @model
      .persist(ladda: @ui.save[0])
      .done( =>
        @collection.trigger('add')
        @ui.modal.modal('hide')
      )

  validate: =>
    unless @callFlowPicker.val()
      @viewModel.set(flowError: true)
      toastr.warning('Please correct the highlighted errors.', '')
      return false
    true

module.exports = EmergencyEditorModal
