TagModel = require('./model')

class TagCollection extends Backbone.Collection
  model: TagModel

  selectized: ->
    for tag in @models
      text: tag.get('data')
      value: tag.get('id')

module.exports = TagCollection