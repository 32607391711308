import React from 'react'
import { Tooltip, TooltipProps } from '@mui/material'

type RdnaTooltipProps = Omit<TooltipProps, 'ref'>

const RdnaTooltip = React.forwardRef<JSX.Element, RdnaTooltipProps>(
  ({ children, className, placement = 'top', ...restOfTooltipProps }, ref) => (
    <Tooltip placement={placement} {...restOfTooltipProps} ref={ref} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)

RdnaTooltip.displayName = 'RdnaTooltip'
export default RdnaTooltip
