BaseGrid                = require('base/grid')
BaseView                = require('base/view')
VoicemailModal          = require('widgets/voicemail-modals/main-view')
VoicemailDropModel      = require('widgets/voicemail-modals/model')
VoicemailDropCollection = require('widgets/voicemail-modals/collection')

class VoicemailDropsView extends BaseView

  template: require('./template')

  className: 'voicemail-drops'

  regions:
    grid: 'div.grid'
    modal: 'div.modal-container'

  events:
    'click a.add': 'openVoicemailDropModal'

  initialize: (options) ->
    { @accountId } = options
    @collection = new VoicemailDropCollection()

  onRender: ->
    @collection
      .fetch(data: accountId: @accountId, teamId: null, userId: null)
      .done(=>
        model.set('accountId', @accountId) for model in @collection.models
        @showChildView('grid', @buildGrid()) if @getRegion('grid')
      )

  buildGrid: =>

    columns = [
      { name: 'name',                 label: 'Label',  cell: 'string' },
      { name: 'url',                  label: 'URL',    cell: 'uri' },
      { name: 'id',                   label: 'ID',     cell: 'string' }
      { name: '',                     label: 'Listen', cell: BaseGrid.ListenAudioCell }
      { name: 'voicemail-drop-modal', label: 'Update', cell: BaseGrid.EditAudioCell},
      { name: '',                     label: 'Delete', cell: BaseGrid.DeleteCell }
    ]

    new BaseGrid.Grid(
      columns    : columns
      collection : @collection
      emptyText  : 'No voicemail drops found.'
      onEdit     : @openVoicemailDropModal
    )

  openVoicemailDropModal: (options) =>
    model = options.model?.clone()
    model ?= new VoicemailDropModel()
    @showChildView('modal', new VoicemailModal(
      accountId   : @accountId
      collection  : @collection
      model       : model
      modalTitle  : 'Voicemail Drop'
    ))

module.exports = VoicemailDropsView