/*
  @file - handle incoming api request actions
  - execute the fetch
  - return a promise for the result
  - dispatch success / error actions accordingly
  */

import { ActionTypes, ClientAction, DefaultApi, SuccessAction, ErrorAction, RequestAction } from '../constants'
import { RequestFail, RequestPending } from '../Classes'
import { Dispatch } from 'redux'
import store from './'
import { methodOptionsMap, apiConfigMap } from '../caches'
import sleep from '../../utils/sleep'

const TimeoutError = new Error('Api Fetch Timeout')
async function fetchTimeout() {
  await sleep(60000)
  throw TimeoutError
}

function findPendingAction(queue: Array<RequestAction>, requestId: string): RequestAction | undefined {
  return queue.find(a => {
    // check each action, and its dependent actions
    return a.requestId === requestId || findPendingAction(a.dependentActions, requestId)
  })
}

function formatParam(param: any) {
  return param === null ? '' : typeof param === 'object' ? JSON.stringify(param) : param
}

// type any because redux middleware assumes all actions are plain objects
const apiMiddleware: any = () => (next: Dispatch<ClientAction>) => (action: ClientAction) => {
  if (action.type === ActionTypes.ApiConfig) {
    apiConfigMap.set(action.api, action.apiConfig)
    return next(action)
  } else if (action.type === ActionTypes.Request) {
    const { path, method, params, dependency, cursor } = action
    const methodOptions = methodOptionsMap.get(action.key)
    next(action) // forward action for logging / consistency

    if (dependency) {
      const dependeningRequestId = dependency.requestId
      const { queues } = store.getState()
      const queue = [...queues.active, ...queues.latent]
      const dependingRequest = findPendingAction(queue, dependeningRequestId)
      // if this happens it suggest developer error. Perhaps we need to also search success and fail queues?
      if (!dependingRequest) throw new Error('request dependency could not be found')
      dependingRequest.dependentActions.push(action) // simply mutate, no need to dispatch
      const error = new RequestPending(dependingRequest)
      return Promise.reject(error)
    }

    const baseAction = {
      requestId: action.requestId,
      resourceId: action.resourceId,
      key: action.key,
      path: action.path,
      cache: action.cache,
      request: action,
      timestamp: Date.now(),
      cursor
    }

    return new Promise(async (resolve, reject) => {
      const apiConfig = apiConfigMap.get(action.api || DefaultApi)
      if (!apiConfig) throw new Error('Invariant: cannot call api method before setting the api config.')
      try {
        const formData = new FormData()
        let searchParams = params && params.urlSearchParams
        if (methodOptions && methodOptions.pager) {
          searchParams = { ...searchParams, ...methodOptions.pager.applyCursor(cursor).urlSearchParams }
        }
        const urlSearchParams = new URLSearchParams()
        if (searchParams) {
          const prefix = params && params.urlSearchParamsKeyPrefix ? `${params.urlSearchParamsKeyPrefix}.` : ''
          Object.entries(searchParams).forEach(([key, param]) => {
            // undefined values will be omit, null values become empty string
            if (param !== undefined) {
              const prefixedKey = prefix + key
              if (Array.isArray(param)) {
                param.forEach(v => {
                  urlSearchParams.append(prefixedKey, formatParam(v))
                })
              } else {
                urlSearchParams.append(prefixedKey, formatParam(param))
              }
            }
          })
        }
        if (params && params.formData) {
          Object.entries(params.formData).map(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach(v => formData.append(`${key}[]`, String(v)))
            } else if (value instanceof File) {
              formData.append(key, value)
            } else if (value == null) {
              formData.append(key, '')
            } else {
              formData.append(key, String(value))
            }
            return formData
          })
        }

        const sessionId = apiConfig.getSessionId && (await apiConfig.getSessionId())
        const headers = Object.assign(
          {},
          sessionId && {
            sessionId
          },
          methodOptions && methodOptions.headers
        )

        const url = `${apiConfig.basePath}${path}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`
        const body = (params && params.formData && formData) || undefined
        if (apiConfig.debug) console.log(`## client fetch ${url}`, { headers, method, body })
        const requestPromise = fetch(url, {
          headers,
          method,
          body
        })
        // apply a fetchTimeout race to avoid broken fetch fail scenarios in RN https://github.com/facebook/react-native/issues/19709
        const response = (await Promise.race([requestPromise, fetchTimeout()])) as Response // cast because ts does not know fetchTimeout only throws

        if (response.ok) {
          const payload = await response.json()

          const successAction: SuccessAction<any> = {
            ...baseAction,
            type: ActionTypes.Success,
            payload,
            response,
            status: response.status || 0
          }
          if (methodOptions && methodOptions.pager) {
            successAction.cursorTerminal = methodOptions.pager.isTerminal(action.cursor, payload)
          }
          // terminal 1: success - dispatch success action and resolve promise
          next(successAction)
          resolve(successAction)
        } else {
          throw response
        }
      } catch (errOrResponse) {
        // duck type response by checking for status property
        const response = errOrResponse.status ? errOrResponse : undefined
        // message will either be the response text if a response exists or the error message if we have no response.
        const message = response ? await response.text().catch((e: Error) => e.message) : errOrResponse.message
        const status = response ? response.status : -1 // where -1 indicates it is not an http error.
        const error = new RequestFail(message, errOrResponse)

        // error is considered terminal if we received a 400 from the server. In the future this can be pluggable via a callback config.
        const terminal = status < 500 && status >= 400
        const errorAction: ErrorAction = {
          ...baseAction,
          type: ActionTypes.Error,
          error,
          response,
          status: status,
          terminal
        }
        // terminal 2: error - dispatch error action and reject promise
        next(errorAction)
        apiConfig.onRequestFail(error, errorAction)
        reject(error)
      }
    })
  } else if (action.type === ActionTypes.Success) {
    const methodOptions = methodOptionsMap.get(action.key)
    action.request.dependentActions.forEach(a => {
      // @TODO should we use immer here?
      methodOptions && methodOptions.onDependencySuccess && methodOptions.onDependencySuccess(a, action)
      next(a)
    })
  } else if (action.type === ActionTypes.Error) {
    if (action.request.dependentActions.length) {
      // @TODO this warning can probably go away once we have full failed action handling
      console.log(
        'action failed. The following dependent actions will be ignored',
        action,
        action.request.dependentActions
      )
    }
  }
  return next(action)
}

export default apiMiddleware
