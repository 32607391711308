import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

function SearchIcon({ size = 24, color = Colors.N80 }: BaseIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <title>search-icon</title>
      <path d="M9.5 16a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM15.51 16.203l4.95 4.95" stroke={color} />
    </svg>
  )
}

export default SearchIcon
