import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import RdnaButton from '../RdnaButton'
import styled from 'styled-components'
import { Alignment } from './index'

type ButtonContainerProps = {
  inline: boolean
  alignElements: Alignment
  cancelText?: string
  resetText?: string
  submitText?: string
  disableSubmit?: boolean
  isLoading?: boolean

  handleCancel?: () => void
  handleReset?: () => void
}

const LOADER_SIZE = 24

interface StyleProps {
  $alignElements: Alignment
  $inline: boolean
}

const ButtonWrapper = styled.div<StyleProps>`
  position: relative;
  display: inline-block;
  text-align: ${props => props.$alignElements};
  width: ${props => (props.$inline ? 'auto' : '100%')};
  left: ${props => (props.$inline ? '0' : `-${props.theme.spacing}px`)};
`

const StyledLoader = withStyles({
  root: {
    marginLeft: `-${LOADER_SIZE / 2}px`,
    marginTop: `-${LOADER_SIZE / 2}px`,
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
})(CircularProgress)

const ButtonsContainer = ({
  cancelText,
  resetText,
  submitText,
  disableSubmit,
  handleReset,
  handleCancel,
  isLoading,
  alignElements,
  inline
}: ButtonContainerProps) => {
  return (
    <ButtonWrapper $inline={inline} $alignElements={alignElements} className="form-buttons">
      <Box mt={2}>
        {!!cancelText && (
          <Box mx={1} display="inline-block">
            <RdnaButton
              type="button"
              name="buttons-container-cancel"
              variant="text"
              color="neutral"
              onClick={handleCancel}
              text={cancelText}
            />
          </Box>
        )}
        {!!resetText && (
          <Box mx={1} display="inline-block">
            <RdnaButton name="buttons-container-reset" variant="text" onClick={handleReset} text={resetText} />
          </Box>
        )}
        {!!submitText && (
          <Box mx={1} position="relative" display="inline-block">
            <RdnaButton
              name="buttons-container-submit"
              type="submit"
              disabled={isLoading || disableSubmit}
              text={submitText}
              data-testid="form-submit-btn"
            />
            {isLoading && <StyledLoader size={LOADER_SIZE} />}
          </Box>
        )}
      </Box>
    </ButtonWrapper>
  )
}

export default ButtonsContainer
