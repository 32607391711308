import { Colors } from '../../colors'
import basePalette from '../basePalette'
import baseTypography from '../baseTypography'
import baseTransitions from '../baseTransitions'

const palette = {
  ...basePalette,
  primary: {
    contrastText: Colors.N0,
    main: Colors.Y30,
    dark: Colors.Y50,
    light: Colors.N30
  },
  text: {
    ...basePalette.text,
    link: Colors.Y50
  },
  icon: {
    ...basePalette.icon,
    link: Colors.Y50
  },
  illustrations: {
    20: Colors.Y20,
    30: Colors.Y30,
    40: Colors.Y40,
    50: Colors.Y50,
    60: Colors.Y60,
    70: Colors.Y70,
    80: Colors.Y80
  },
  background: {
    ...basePalette.background,
    mainTheme: Colors.Y50,
    dark: Colors.Y60,
    darkNeutral: Colors.N30
  }
}

const typography = {
  ...baseTypography
}

const transitions = {
  ...baseTransitions
}

const zIndex = {
  appBar: 1200,
  clipDrag: 1201,
  drawer: 5555, // to go on top of pendo guide
  modal: 5556,
  tooltip: 5557,
  snackbar: 5558
}

const theme = {
  palette,
  typography,
  transitions,
  zIndex,
  spacing: 6,
  constrainedWidth: 1400
}

export default theme
