export default function sleep(timeout = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout)
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const waitFor = async (seconds: number, callback?: Function) => {
  await new Promise(resolve => setTimeout(resolve, 1000 * seconds))
  callback && callback()
}
