import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useInboxUnreadCount } from '../../store/api/smart-recording'
import { usePubNub } from '../contexts/PubNubContext'
import { PubNubChannels, MessageEvent } from '../contexts/PubNubContext'
import { useAtomValue } from 'jotai'
import { caiAccountAtom } from '../../store/api/user-settings'

import RdnaCountBubble from '@ringdna/common/src/components/RdnaCountBubble'

export default function InboxLabel() {
  const { accountId } = useAtomValue(caiAccountAtom)
  const [inboxUnreadCount, , , refetchInboxUnreadCount] = useInboxUnreadCount({
    query: { includeZoomData: true, accountId }
  })

  const { pubnub } = usePubNub()

  useEffect(() => {
    const listener = {
      message: (e: MessageEvent) => {
        if (e.userMetadata === PubNubChannels.INCREASE_INBOX_ITEM) {
          refetchInboxUnreadCount()
        }
      }
    }
    pubnub.addListener(listener)
    return () => {
      pubnub.removeListener(listener)
    }
  }, [pubnub, refetchInboxUnreadCount])

  return (
    <InboxLabelContainer>
      <div className="badge-container">
        {!inboxUnreadCount ||
          (inboxUnreadCount.unreadCount !== 0 && (
            <RdnaCountBubble count={`${inboxUnreadCount.unreadCount}`} size={20} color="alert" />
          ))}
      </div>
    </InboxLabelContainer>
  )
}

const InboxLabelContainer = styled.div`
  .badge-container > div {
    position: absolute;
    top: ${({ theme }) => 2 * theme.spacing}px;
    right: 0;
  }
`
