BackgridCell             = require('helpers/backgrid-cell')
BackgridRow              = require('helpers/backgrid-row')
BaseGrid                 = require('base/grid')
BaseView                 = require('base/view')
BaseModel                = require('base/model')
PagedCollection          = require('base/paged-collection')
PagerView                = require('widgets/pager/view')
DowntimeEventEditorModal = require('./modals/downtime-event-editor-view')

class DowntimeEventModel extends BaseModel
  urlRoot: 'downtimeEvents'

class DowntimeEventCollection extends PagedCollection
  model: DowntimeEventModel
  url: 'downtimeEvents'

module.exports = class DowntimeEventsView extends BaseView

  template: require('./template')
  className: 'downtime-events'

  regions:
    grid          : 'div.grid'
    pager         : 'span.pager'
    modal         : 'div.modal-container'

  ui:
    reason : '.filter-reason'
    from   : '.filter-from'
    to     : '.filter-to'
    search : '.search'

  events:
    'click @ui.search' : 'search'

  childViewEvents:
    reload: -> @render()

  initialize: (options) ->
    @qs = options.qs
    @qs.setFragment('system/downtime-events')

  onRender: =>
    datePickerOpts =
      autoclose   : true
      format      : 'mm/dd/yyyy'
      dateFormat  : 'mm/dd/yyyy'
      minViewMode : 'days'
      maxViewMode : 'months'
      orientation : 'bottom'
      clearBtn    : true
    @ui.to.datepicker(datePickerOpts)
    @ui.from.datepicker(datePickerOpts)

    @qs.bind(
      reason : @ui.reason
      from   : @ui.from
      to     : @ui.to
    )
    @qs.restore()
    @ui.from.datepicker('update')
    @ui.to.datepicker('update')
    @search()

  search: =>
    collection = new DowntimeEventCollection(
      page: @qs.get('page')
      queryParams:
        reason : @ui.reason.val()
        from   : @ui.from.datepicker('getDate')?.getTime()
        to     : @ui.to.datepicker('getDate')?.getTime()
    )
    @detachChildView('grid')
    @showChildView('grid', @buildGrid(collection))
    @showChildView('pager', new PagerView(collection: collection, qs: @qs))
    if @ui.from.val() and @ui.to.val() and @ui.from.datepicker('getDate') >= @ui.to.datepicker('getDate')
      toastr.warning('"From" date cannot be equals or earlier than "To" date!', 'Oops!')
      collection.reset()
      return
    collection.fetch(reset: true)

  buildGrid: (collection) ->
    datetime = BackgridCell.dateTime()

    userCell = BackgridCell.usersCell('modifiedByUserId.id', 'modifiedByUserId.displayName')

    cols = [
      {label: '',            name: '',                 cell: BaseGrid.EditCell}
      {label: 'Reason',      name: 'reason',           cell: 'string'}
      {label: 'Modified by', name: 'modifiedByUserId', cell: userCell}
      {label: 'Start Date',  name: 'startDate',        cell: datetime}
      {label: 'End Date',    name: 'endDate',          cell: datetime}
      {label: 'ID',          name: 'id',               cell: 'string'}
      {label: '',            name: '',                 cell: BaseGrid.DeleteCell, headerCell: BaseGrid.AddCell}
    ]

    new BaseGrid.Grid(
      collection : collection
      columns    : cols
      emptyText  : 'No Downtime Events Found.'
      onAdd      : @showEditor
      onEdit     : @showEditor
    )

  showEditor: (options) =>
    model = options.model?.clone()
    model ?= new DowntimeEventModel(
      startDate: null
      endDate: null
      reason: 'ScheduledMaintenance'
      comment: ''
    )
    @showChildView('modal', new DowntimeEventEditorModal(model: model))
    $('#downtime-event-editor-modal').modal('show')
