require('helpers/format-helper')
require('helpers/url-helper')

BaseView   = require('base/view')
HeaderView = require('./header/view')
NavView    = require('./nav/view')
ToastsView = require('./toasts/view')

config = require('../../src/config').default

class AppView extends BaseView
  template: require('./template')
  el: 'body'

  bindings:
    '.main-container': 'toggle: sessionStarted'

  regions:
    error   : '#error'
    header  : '#header'
    toasts  : '#toasts'
    login   : '#login'
    main    : '#main'
    nav     : '#nav'

  initialize: (options) ->
    @app = options.app
    @viewModel = new Backbone.Model(sessionStarted: false)
    @app.radio.on(
      'featureflag:started' : @featureFlagStarted
      'session:ended'   : @sessionEnded
    )

    # FIXME: these regions are accessed frequently from outside this view, so this is a temporary shim.
    @error  = @getRegion('error')
    @header = @getRegion('header')
    @login  = @getRegion('login')
    @main   = @getRegion('main')
    @nav    = @getRegion('nav')
    @toasts    = @getRegion('toasts')

  featureFlagStarted: =>
    [path, querystring] = window.location.hash.split('?')
    if(path.includes('#smart-recordings'))
      @caiSmartRecordingRedirect(path, querystring)
      return
    @viewModel.set(sessionStarted: true)
    @header.show(new HeaderView(app: @app))

    user = @app.session.user
    @nav.show(new NavView(user: user))
    $('[data-toggle="tooltip"]').tooltip()
    @toasts.show(new ToastsView(user: user))
    @workspaceOpen()

    # TODO: Refactor the sidebar code in core/template.hbs into its own folder so we use proper bindings here. Note that
    # this also reaches into header/template.hbs.
    @$('.username').text(user.get('userName'))
    @$('.user-photo').attr('src', user.photoUrl())
    @$("a.user-profile-url").attr('href', "#users/" + user.get('userId'))
    require('./theme.js')

  caiSmartRecordingRedirect: (path, querystring) =>
    pathMap = { setup: 'settings', calls: 'conversations' }
    # remove the smart-recordings URL and grab the proceeding route
    paths = path.split('/').slice(1)
    smartRecordingIdPath = 'rc'
    # if the route includes the smartRecordingIdPath route to /conversations/{smartRecordingIdPath}
    if(paths[0].includes(smartRecordingIdPath))
      newPaths = "conversations/#{paths[0]}"
    else if(paths[0])
      # if the path isn't a smart-recording-id, route to either
      # settings, conversations, or the child route of #smart-recordings
      newPaths = pathMap[paths[0]] || paths[0]
    # if the path has a child route, construct the appropriate path with the newPath from the previous condition
    if(paths[1])
      newPaths = "#{newPaths}/#{paths.slice(1).join('/')}"
    # if the path includes a query param, construct the new path with the query param
    if(querystring)
      newPaths = "#{newPaths}?#{querystring}"
    window.location.assign("#{config.caiDomain}/#{newPaths || pathMap.calls}")

  sessionEnded: =>
    @viewModel.set(sessionStarted: true)
    @reset()

  showLogin: (view) =>
    @reset()
    @login.currentView?.destroy()
    @login.show(view)

  showPage: (view) =>
    @main.currentView?.destroy()
    @main.show(view)

  workspaceBlocked: =>
    ws = @$('.overlay-workspace')
    ws.show()
    @showSpinner(ws, { scale: 2, top: '20%' })

  workspaceOpen: =>
    ws = @$('.overlay-workspace')
    ws.hide()
    @hideSpinner(ws)

  reset: =>
    @header.currentView?.destroy()
    @error.currentView?.destroy()
    @nav.currentView?.destroy()
    @main.currentView?.destroy()
    @workspaceOpen()

module.exports = AppView
