export const UNASSIGNED_USERS_TEXT = 'Current unassigned users: '
export const HEAD_AUTOMAPPING = 'SFDC Roles Auto Mapping'
export const SUB_HEAD_AUTOMAPPING =
  'Mapping tool for SFDC Roles to Revenue.io Roles. Any user mapped will be automatically assigned the associated role. If you don’t want to assign them a role, you can keep them as unassigned.'
export const SALESFORCE_ROLE_TEXT = 'Salesforce Role'
export const REVENUE_ROLE_TEXT = 'Revenue.io Role'
export const DEFAULT_PAGE_SIZE = 10
export const PAGE_SIZES = [10, 20, 30]
export const VIEW_USER = 'View User'
export const REASSIGN = 'Reassign'
export const REMOVE = 'Remove'
export const SUB_HEADING_1 =
  'The users presented in this table do not currently have a Role value assigned to them in Revenue. Select the users that you want to assign Roles to. Each user’s current Salesforce “Title” and “Department” values are displayed to assist you.'
export const SUB_HEADING_2 =
  'Assign Roles to the users. Check the “Role bulk assignment” checkbox to assign all users to the same Role or uncheck it to set Role values for users individually.'
export const SUB_HEADING_3 = 'You’ve successfully added Roles to the following users.'
export const ROLE_BULK_TEXT = 'Role bulk assignment'
export const NO_RESULT_STATE = { title: 'No results to display', subtitle: '' }
