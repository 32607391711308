import React from 'react'
import { Colors } from '../../../constants/colors'
import { BaseIconProps } from '../types'

const ShieldIcon = ({ color = Colors.N100, size = 18}: BaseIconProps) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" data-testid="shield-icon">
    <path
      d="M2.34214 3.73408C5.39663 3.44837 7.88935 1.81599 8.70955 1.2189C8.88129 1.0937 9.11403 1.0937 9.28578 1.2189L2.34214 3.73408ZM2.34214 3.73408C1.97457 3.7678 1.69208 4.07277 1.68566 4.44194L2.34214 3.73408ZM15.8125 4.44845L15.8126 4.44925C15.8299 5.62914 15.7421 8.20642 14.833 10.6969C13.9274 13.1775 12.2319 15.5159 9.05267 16.3691C9.01657 16.3787 8.97848 16.3786 8.94239 16.369C5.76329 15.515 4.06791 13.1766 3.16257 10.6963C2.25377 8.20653 2.1663 5.63018 2.18434 4.45024C2.18653 4.33499 2.27436 4.24114 2.38771 4.23075L2.38859 4.23066C5.57073 3.93302 8.14799 2.24382 8.99767 1.62606C9.84815 2.24329 12.4257 3.93297 15.6083 4.23066L15.6092 4.23074C15.7245 4.24133 15.8111 4.33657 15.8125 4.44845Z"
      stroke={color}
      strokeWidth="0.9975"
    />
  </svg>
)

export default ShieldIcon