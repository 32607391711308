export enum SalesforceRecord {
  ACCOUNT = 'account',
  CAMPAIGN = 'campaign',
  CASE = 'case',
  CONTACT = 'contact',
  CUSTOM = 'custom',
  EVENT = 'event',
  FAVORITE = 'favorite',
  LEAD = 'lead',
  MULTIMATCH = 'multimatch',
  NONE = 'none',
  OPPORTUNITY = 'opportunity',
  TASK = 'task',
  HOOVERS = 'D&B Hoovers'
}

/**
 * Tasks
 */

export enum SalesforceTaskType {
  EMAIL = 'Email',
  SMS = 'Sms',
  CALL = 'Call',
  TASK = 'Task',
  CADENCE = 'Cadence'
}

export const recordTypes = ['Lead', 'Contact', 'Account', 'Opportunity', 'Campaign', 'Case'] as const

export type SalesforceTask = {
  class: string
  created: number
  isClosed: boolean
  recCompany: string
  recId: string | null
  recName: string
  recTitle: string
  recType: (typeof recordTypes)[number]
  relatedRecId: string
  subject: string
  taskId: string
  taskType: SalesforceTaskType
}

export type SalesforceItem = {
  id: string
  phone: string
  title: string
  name: string
  company: string
  status: string
  mobilePhone: string
  account: {
    id: string
    name: string
  }
  listItemId?: string
}
