import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { filtersAtom, userDataAtom } from './index'
import { useDashboardUrl } from './api'
import { CustomReportType } from './types'
import { NoResultsMsg } from '../RdnaLoader'
import { EmptyTranscript } from '../../assets/illustrations'
import { parseFilters, validateSupervisorFilters, validateDates } from './utils/metabase'
import { TrackEvent } from '../../utils/analyticsUtils'

const ERROR_SUPERVISOR = (typeFilter: string) => `Enter a ${typeFilter} Name or Team Name above and click "Refresh"`
const ERROR_DATES = 'Dates may not be in future. Start date must be less than a year before end date.'

export type Analytics = { trackEvent: TrackEvent }

type Props = {
  reportName: string
  isDashboard: boolean
  analytics: Analytics
  CustomReport?: CustomReportType
}

export default function MetabaseView({ reportName, isDashboard, CustomReport, analytics }: Props) {
  const [iFrameUrl, setIFrameUrl] = useState<string | undefined>()
  const rawFilters = useAtomValue(filtersAtom)
  const [showError, setShowError] = useState('')
  const [parsedFilters, setParsedFilters] = useState({})
  const [customReportQuery, setCustomReportQuery] = useState<{ [key: string]: string } | undefined>()
  const userData = useAtomValue(userDataAtom)
  const licenses = userData?.licenses
  const accountId = userData?.supportAccountId?.toString() || userData?.accountId.toString() || ''

  const CustomReportWithProps =
    CustomReport && customReportQuery ? <CustomReport isDashboard={isDashboard} query={customReportQuery} /> : undefined

  const [iframeData, , , refetch] = useDashboardUrl(
    { isDashboard, query: { ...parsedFilters, accountId } },
    { paused: true }
  )

  useEffect(() => {
    if (iframeData?.iframeUrl) setIFrameUrl(iframeData.iframeUrl)
  }, [iframeData])

  const filtersString = JSON.stringify(rawFilters)
  useEffect(() => {
    const filtersParsed = parseFilters(rawFilters, reportName, userData, analytics)
    const validDates = validateDates(filtersParsed)
    const validSupervisor = validateSupervisorFilters(filtersParsed, licenses)
    if (validSupervisor && validDates) {
      setShowError('')
      setParsedFilters(filtersParsed)
    } else {
      setShowError(!validSupervisor ? ERROR_SUPERVISOR(reportName == 'queue-metrics' ? 'Queue' : 'User') : ERROR_DATES)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersString])

  const parsedFiltersString = JSON.stringify(parsedFilters)
  useEffect(() => {
    if (Object.keys(parsedFilters).filter(filter => filter !== 'name').length && accountId) {
      CustomReport ? setCustomReportQuery({ ...parsedFilters, accountId }) : refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersString])

  return (
    <>
      {showError ? (
        <NoResultsMsg title={'No results'} subtitle={showError} Icon={<EmptyTranscript size={240} />} />
      ) : (
        CustomReportWithProps || <ReportFrame data-testid="report-frame" src={iFrameUrl} />
      )}
    </>
  )
}

const ReportFrame = styled.iframe`
  height: calc(100vh - 325px);
  min-height: 400px;
  display: block;
  width: 100%;
  border: 0;
`
