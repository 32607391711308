import React, { useEffect, useState } from 'react'
import RdnaDatePicker, { RdnaDatePickerProps } from './index'
import { isBefore, parseISO, isValid } from 'date-fns'

type RdnaTimeFramePickerProps = RdnaDatePickerProps<string> & {
  startLabel?: string
  endLabel?: string
  onChange: (arg0: Date[]) => void
  hasOrderError?: (error: boolean) => void
}

export const DATE_RANGE_SEPARATOR = '|'

export default function RdnaDateRangePicker({
  value,
  onChange,
  hasOrderError,
  name,
  startLabel = 'Start Date',
  endLabel = 'End Date',
  ...restOfProps
}: RdnaTimeFramePickerProps) {
  const [startValue, endValue] = (value as string)
    ? (value as string).split(DATE_RANGE_SEPARATOR)
    : [undefined, undefined]
  const parsedStartValue = startValue ? parseISO(startValue) : null
  const parsedEndValue = endValue ? parseISO(endValue) : null
  const [dates, setDates] = useState<Date[]>([
    parsedStartValue && isValid(parsedStartValue) ? parsedStartValue : new Date(),
    parsedEndValue && isValid(parsedEndValue) ? parsedEndValue : new Date()
  ])
  const [orderError, setOrderError] = useState(false)

  const [startDate, endDate] = dates

  const onDateChange = (value: Date | null, id: number) => {
    if (value) {
      setDates(prevDate => {
        const newDates = [...prevDate]
        newDates[id] = value
        return newDates
      })
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      setOrderError(isBefore(endDate, startDate))
      onChange([startDate, endDate])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {
    if (hasOrderError) {
      hasOrderError(orderError)
    }
  }, [hasOrderError, orderError])

  return (
    <>
      <RdnaDatePicker
        name={`${name}-start`}
        onChange={(value: Date | null) => onDateChange(value, 0)}
        value={parsedStartValue}
        {...restOfProps}
        label={startLabel}
        error={orderError}
        helperText={orderError ? 'Start date must be before end date' : undefined}
      />
      <RdnaDatePicker
        name={`${name}-end`}
        onChange={(value: Date | null) => onDateChange(value, 1)}
        value={parsedEndValue}
        {...restOfProps}
        label={endLabel}
        error={orderError}
        helperText={orderError ? 'End date must be after start date' : undefined}
      />
    </>
  )
}
